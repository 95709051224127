import type {
	Relation,
	Sequence,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { UpdateActionPayload as UpdateOriginalIssueActionPayload } from '../../state/domain/original-issues/actions.tsx';
import type { CommitResponse } from '../commit/types.tsx';
import type * as http from '../http/index.tsx';

export const ADD_ISSUE = 'command.issue.ADD_ISSUE' as const;
export const UPDATE_ISSUE = 'command.issue.UPDATE_ISSUE' as const;
export const UPDATE_ISSUE_DATES_OPTIMISTIC = 'command.issue.UPDATE_ISSUE_DATES_OPTIMISTIC' as const;
export const UPDATE_MULTIPLE_ISSUES = 'command.issue.UPDATE_MULTIPLE_ISSUES' as const;
export const RANK_ISSUE = 'command.issue.RANK_ISSUE' as const;
export const MOVE_ISSUE = 'command.issue.MOVE_ISSUE' as const;
export const UPDATE_ORIGINAL_ISSUE = 'command.issue.UPDATE_ORIGINAL_ISSUE' as const;
export const FETCH_HISTORICAL_DONE_ISSUES = 'command.issue.FETCH_HISTORICAL_DONE_ISSUES' as const;
export const HANDLE_COMMIT_RESPONSE = 'command.issue.HANDLE_COMMIT_RESPONSE' as const;
export const REMOVE_SINGLE_ISSUE = 'command.issue.REMOVE_SINGLE_ISSUE' as const;
export const REMOVE_SELECTED_ISSUES = 'command.issue.REMOVE_SELECTED_ISSUES' as const;
export const BULK_ISSUES_UPDATE = 'command.issue.BULK_ISSUES_UPDATE' as const;
export const BULK_ISSUES_CPR_UPDATE = 'command.issue.BULK_ISSUES_CPR_UPDATE' as const;
export const BULK_RANK_ISSUES = 'command.issue.BULK_RANK_ISSUES' as const;
export const EXPAND_ALL_ANALYTICS_ACTION = 'command.issue.EXPAND_ALL_ANALYTICS_ACTION' as const;
export const COLLAPSE_ALL_ANALYTICS_ACTION = 'command.issue.COLLAPSE_ALL_ANALYTICS_ACTION' as const;
export const FAIL_COMMIT_REQUEST = 'command.issue.FAIL_COMMIT_REQUEST' as const;
export const REFRESH_SUBTASK = 'command.issue.REFRESH_SUBTASK' as const;
export const DELETE_SINGLE_ISSUE = 'command.issue.DELETE_SINGLE_ISSUE' as const;

// Possible static description values
export const DESCRIPTION_FETCH_ERROR = 'DESCRIPTION_FETCH_ERROR' as const;

// Level is internal to FE and lexoRank comes from backend, so we shouldn't supply them when creating.
export type NewIssue = Flow.Diff<
	Issue,
	{
		id: string;
		lexoRank: string;
	}
>;

export type AddIssuePayload = {
	issue: NewIssue;
	sibling?: string | null;
	skipRanking?: boolean;
	batchRanking?: boolean;
};

// NOTE Usually we use $Shape for such cases, but it doesn't play well with covariant keys/$ReadOnly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UpdateIssuePayload = any;
export type UpdateMultipleIssuesPayload = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[issueId: string]: any;
};
export type UpdateIssueDatesPayload = {
	id: string;
	baselineStart?: number | undefined;
	baselineEnd?: number | undefined;
};

export type RankIssuePayload = {
	anchor?: string;
	itemKeys: string[];
	relation: Relation;
	// dragAndDrop used for analytics to know if issue was reranked via drag and drop.
	dragAndDrop?: boolean;
};

export type Operation = {
	anchor?: string;
	itemKeys: string[];
	operationType: Relation;
};
export type RankRequest = {
	operations: Operation[];
	planId?: number;
	scenarioId?: number;
};

export type RankRequestResponse = {
	ranked: number;
	failed: number;
	failuresById?: Record<number, string>;
	change: { sequence: Sequence };
};

export type RemoveSingleIssuePayload = { issueId: string };

export type RemoveSingleIssueAction = {
	type: typeof REMOVE_SINGLE_ISSUE;
	payload: RemoveSingleIssuePayload;
};

export type RemoveIssuesPayload = {
	itemKeys: string[];
	planId: number;
	scenarioId: number;
};

export type DeleteSingleIssuePayload = { issueId: string };

export type DeleteSingleIssueAction = {
	type: typeof DELETE_SINGLE_ISSUE;
	payload: DeleteSingleIssuePayload;
};

export type DeleteIssuesPayload = {
	itemKeys: string[];
	planId: number;
	scenarioId: number;
};

export type UpdateIssueRequest = {
	itemKeys: string[];
	planId?: number;
	scenarioId?: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	description?: any;
};

export type MoveIssuePayload = {
	id: string;
	relation: Relation;
	// dragAndDrop used for analytics to know if issue was reranked via drag and drop.
	dragAndDrop?: boolean;
	anchor: string | null | undefined;
	parentId: string | null | undefined;
	group?: string | null | undefined;
};

export type HiddenIssuesBody = {
	planId: number;
	scenarioId: number;
	issueIds: number[];
};

export type ExpandOrCollapseAllAnalyticsPayload = {
	hierarchyLevels: number;
};

export type ExpandAllAnalyticsAction = {
	type: typeof EXPAND_ALL_ANALYTICS_ACTION;
	payload: ExpandOrCollapseAllAnalyticsPayload;
};

export type CollapseAllAnalyticsAction = {
	type: typeof COLLAPSE_ALL_ANALYTICS_ACTION;
	payload: ExpandOrCollapseAllAnalyticsPayload;
};

export type AddIssueAction = {
	type: typeof ADD_ISSUE;
	payload: AddIssuePayload;
};

export type UpdateIssueAction = {
	type: typeof UPDATE_ISSUE;
	payload: UpdateIssuePayload;
};

export type UpdateIssueDatesAction = {
	type: typeof UPDATE_ISSUE_DATES_OPTIMISTIC;
	payload: UpdateIssueDatesPayload;
};

export type UpdateMultipleIssuesAction = {
	type: typeof UPDATE_MULTIPLE_ISSUES;
	payload: UpdateMultipleIssuesPayload;
};

export type BulkUpdateIssueAction = {
	type: typeof BULK_ISSUES_UPDATE;
	payload: UpdateIssuePayload;
};

export type BulkUpdateIssueCPRAction = {
	type: typeof BULK_ISSUES_CPR_UPDATE;
	cprId: string;
};

export type RankIssueAction = {
	type: typeof RANK_ISSUE;
	payload: RankIssuePayload;
};

export type BulkRankIssueActionPayload = {
	anchor?: string;
	operationType: Relation;
};

export type BulkRankIssueAction = {
	type: typeof BULK_RANK_ISSUES;
	payload: BulkRankIssueActionPayload;
};

export type MoveIssueAction = {
	type: typeof MOVE_ISSUE;
	payload: MoveIssuePayload;
};

export type UpdateOriginalIssueAction = {
	type: typeof UPDATE_ORIGINAL_ISSUE;
	payload: UpdateOriginalIssueActionPayload;
};

export type CommitResponseAction = {
	type: typeof HANDLE_COMMIT_RESPONSE;
	payload: CommitResponse;
};

export type FailCommitRequestAction = {
	type: typeof FAIL_COMMIT_REQUEST;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload: http.JsonResponse<any>;
};
export type RefreshSubtaskPayload = {
	issueId: string;
	fields?: {
		team?: boolean;
		sprint?: boolean;
	};
};
export type RefreshSubtaskAction = {
	type: typeof REFRESH_SUBTASK;
	payload: RefreshSubtaskPayload;
};

export type SubtaskUpdate = {
	id: string;
	team?: string;
	sprint?: string;
};

export type UpdateIssueKeyResponse = {
	success: boolean;
	change: {
		sequence: {
			issuelinks: { before: number; current: number };
			issues: { before: number; current: number };
		};
	};
};

export type ActionWithExternalPromise<T> = T & {
	payload: {
		promise?: {
			resolve: Function;
			reject: Function;
		};
	};
};
