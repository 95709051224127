import { EPIC_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	type HierarchyFilterValue,
	type HierarchyFilter,
	HIERARCHY_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { HierarchyRange } from '../../hierarchy/index.tsx';
import { getFiltersViewSettings } from '../../view-settings/index.tsx';
import { getHierarchyRangeFilter } from '../hierarchy-range-filter/index.tsx';

export type HierarchyFilterState = {
	id: typeof HIERARCHY_FILTER_ID;
	value: HierarchyFilterValue;
};

type HierarchyFilterStateWithFallback = Omit<HierarchyFilterState, 'value'> & {
	value: HierarchyFilterValue | undefined;
};

export const getHierarchyFilterState = (state: State): HierarchyFilterStateWithFallback => {
	const { value } = getFiltersViewSettings(state)[HIERARCHY_FILTER_ID] || {};

	return {
		id: HIERARCHY_FILTER_ID,
		value,
	};
};

export const getHierarchyFilterPure = (
	hierarchyFilter: HierarchyFilterStateWithFallback,
	hierarchyRange: HierarchyRange,
	isCalendarView: boolean,
): HierarchyFilter => {
	const { min, max } = hierarchyRange;
	const currentValue: Partial<HierarchyFilterValue> = hierarchyFilter.value || {
		start: undefined,
		end: undefined,
	};

	if (!isDefined<number>(currentValue.start)) {
		currentValue.start = isCalendarView ? EPIC_LEVEL : max;
	}

	if (!isDefined<number>(currentValue.end)) {
		currentValue.end = isCalendarView ? EPIC_LEVEL : min;
	}

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const clamp = (val: number, min: number, max: number) => Math.min(Math.max(val, min), max);
	const clampedStart = clamp(currentValue.start, min, max);
	const clampedEnd = isCalendarView ? clampedStart : clamp(currentValue.end, min, clampedStart);

	const newValue = {
		start: clampedStart,
		end: clampedEnd,
	};
	return {
		...hierarchyFilter,
		value: newValue,
	};
};

// all usages of "getHierarchyFilter" selector will be replaced soon by "getHierarchyRangeFilter"
// https://hello.jira.atlassian.cloud/browse/JPO-29447
export const getHierarchyFilter = getHierarchyRangeFilter;

export const applyFilter = (issue: Issue, filter: HierarchyFilter): boolean => {
	const { start, end } = filter.value;
	return start >= issue.level && issue.level >= end;
};
