import React, { useMemo, createContext, useContext } from 'react';
import type { ContextType, Props } from './types.tsx';

const context = createContext<ContextType>({
	row: -1,
	height: 0,
});

export const RowProvider = ({ row, height, children }: Props) => {
	const value = useMemo(() => ({ row, height }), [row, height]);
	return <context.Provider value={value}>{children}</context.Provider>;
};

export const useRow = () => useContext(context);
