import {
	isReadOnly,
	isOptimizedMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import {
	getIssueBeingEdited,
	getIsLoadingIssueDescription,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-modal/index.tsx';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getAllIssuesWithOriginalAndOptimizedById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export default createStructuredSelector<State, OwnProps, StateProps>({
	isLoadingIssueDescription: getIsLoadingIssueDescription,
	isOptimizedMode,
	isReadOnly,
	issueBeingEdited: getIssueBeingEdited,
	issuesById: getAllIssuesWithOriginalAndOptimizedById,
	projectsById: getProjectsById,
});
