.container {
	position: fixed;
	height: inherit;
	display: flex;
	justify-content: center;
	overflow: hidden;
}

.imageGroup {
	text-align: center;
	max-width: 360px;
	min-width: 280px;
	padding: calc(var(--jpo-common-base-spacing) * 5) calc(var(--jpo-common-base-spacing) * 3) 0;
}

.image {
	max-width: 100%;
	min-width: 100%;
	height: auto;
}

.header {
	margin-top: var(--jpo-common-base-spacing);
}

.text {
	padding: 0 calc(var(--jpo-common-base-spacing) * 2);
}

.dropdown {
	margin: calc(var(--jpo-common-base-spacing) * 2) 0;
	display: flex;
	justify-content: center;
}
