/* as we will add flyout for the kanban sprint so we can just use this class instead of scrumSprint and kanbanSprint */
.sprint {
	display: inline-block;
	outline: none;
	/* This is required to target the element rendered by the inline-dialog component */
	& > div {
		width: 100%;
	}
}

.bar {
	height: 20px;
	min-width: 5px;
	margin: 0 5px;
	border-radius: 3px;
	display: flex;
	align-items: flex-end;
	background-color: var(--ds-surface, var(--adg3-color-N0));

	& > div {
		width: 100%;
		border-radius: 5px;
		&.okay {
			background-color: var(--ds-background-success-bold, var(--adg3-color-G300));
		}
		&.overbooked {
			background-color: var(--ds-background-danger-bold, var(--adg3-color-R300));
		}
	}
}

.isContiguousWithNextSprint::before {
	border-top: 15px solid var(--ds-surface, var(--adg3-color-N0));
	border-bottom: 15px solid var(--ds-surface, var(--adg3-color-N0));
	border-left: 8px solid;
	content: '';
	position: absolute;
	right: 2px;
	top: 5px;
}

.sprintBoxWrapper {
	background-color: var(--ds-surface, var(--adg3-color-N0));
}

.sprintBox {
	align-items: center;
	border-radius: 3px;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	height: 30px;
	margin: 5px 2px;

	/* future sprints */
	background-color: var(--ds-background-accent-gray-subtler, var(--adg3-color-N40));
	color: var(--ds-text-subtle, var(--adg3-color-N400));

	&.isContiguousWithNextSprint::before {
		border-left-color: var(--ds-background-accent-gray-subtler, var(--adg3-color-N40));
	}

	&:hover {
		background-color: var(--ds-background-neutral-pressed, var(--adg3-color-N60));
	}

	&.overbooked {
		background-color: var(--ds-background-danger, var(--adg3-color-R50));
		color: var(--ds-text-danger, var(--adg3-color-R500));

		&.isContiguousWithNextSprint::before {
			border-left-color: var(--ds-background-danger, var(--adg3-color-R50));
		}

		&:hover {
			background-color: var(--ds-background-danger-hovered, var(--adg3-color-R75));
		}
	}

	&.inconsistent {
		background-color: var(--ds-background-warning, var(--adg3-color-Y75));
		color: var(--ds-text-warning, var(--jpo-warning-color));

		&.isContiguousWithNextSprint::before {
			border-left-color: var(--ds-background-warning, var(--adg3-color-Y75));
		}

		&:hover {
			background-color: var(--ds-background-warning-hovered, var(--adg3-color-Y100));
		}
	}

	&.activeSprint {
		background-color: var(--ds-background-selected, var(--adg3-color-B50));
		color: var(--ds-text-selected, var(--adg3-color-B500));

		&.isContiguousWithNextSprint::before {
			border-left-color: var(--ds-background-selected, var(--adg3-color-B50));
		}

		&:hover {
			background-color: var(--ds-background-selected-hovered, var(--adg3-color-B75));
		}
	}

	&.pastSprint,
	&.closedSprint {
		background-color: var(--ds-background-neutral, var(--adg3-color-N20));
		color: var(--ds-text-disabled, var(--adg3-color-N60));

		&.isContiguousWithNextSprint::before {
			border-left-color: var(--ds-background-neutral, var(--adg3-color-N20));
		}

		&:hover {
			background-color: var(--ds-background-neutral-hovered, var(--adg3-color-N30));
		}
	}
}

.sprintTooltip {
	display: flex;
	text-align: center;
	flex-direction: column;
}

.sprintName {
	flex: 1 1 auto;
	font-size: 12px;
	font-weight: 700;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.filtered {
	display: inline-block;
	flex: 0 0 auto;
	outline: none;
}

.changeIndicator {
	position: relative;
	top: 5px;
	z-index: 1;
}
