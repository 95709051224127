import type { Dispatch } from 'redux';
import {
	setIsExpanded,
	type SetIsExpandedActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/issue-expansions/actions.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch) => ({
	onToggle: (payload: SetIsExpandedActionPayload) => dispatch(setIsExpanded(payload)),
});
