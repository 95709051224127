import { getHierarchyLevelsByLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy/index.tsx';
import { getScope } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import { getVisualisationViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';

const getRankDigits = createSelector(
	[getScope],
	(scope) => scope.startLevelIssues.rootIssuesCount.toString().length,
);

const getGrouping = createSelector([getVisualisationViewSettings], ({ grouping }) => grouping);

export default createSelector(
	[getHierarchyLevelsByLevel, getRankDigits, getGrouping],
	(hierarchyLevelsByLevel, rankDigits, grouping) => ({
		hierarchyLevelsByLevel,
		rankDigits,
		grouping,
	}),
);
