.content {
	display: flex;
	margin: var(--jpo-common-base-spacing) 0;
}

.startDate {
	flex: 1;
	border-right: 1px solid var(--ds-border, var(--adg3-color-N50));
	padding-right: calc(2 * var(--jpo-common-base-spacing));
}

.endDate {
	flex: 1;
	margin-left: calc(2 * var(--jpo-common-base-spacing));
}

.dateTitle {
	font-size: 12px;
	font-weight: 600;
	color: var(--ds-text, var(--adg3-color-N800));
	text-transform: uppercase;
	line-height: calc(2 * var(--jpo-common-base-spacing));
	margin-top: var(--jpo-common-base-spacing);
	margin-bottom: var(--jpo-common-base-spacing);
}

.values {
	display: flex;
	margin-bottom: var(--jpo-common-base-spacing);
}

.currentValue {
	flex: 1.75;
}

.valueTitle {
	display: flex;
	font-size: 11px;
	font-weight: bold;
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	margin-bottom: var(--jpo-common-base-spacing);
}

.title {
	flex: 1;
	padding-top: 4px;
}

.newValue {
	flex: 3;
	margin-left: var(--jpo-common-base-spacing);
}

.arrow {
	flex: 1;
	text-align: right;
}

.newValueForm > div:first-child {
	margin-left: -11px;
}
