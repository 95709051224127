import { toggleShowFullHierarchy } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filter-options/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	toggleShowFullHierarchy: () => {
		dispatch(toggleShowFullHierarchy());
	},
});

export default mapDispatchToProps;
