import * as R from 'ramda';
import {
	RESET,
	ADD_VIEW,
	SET_ACTIVE_VIEW,
	UPDATE_VIEW,
	MODIFY_VIEW,
	DISCARD_CHANGES,
	SAVE_VIEW,
	CHANGE_ID,
	REMOVE_VIEW,
	MARK_AS_DEFAULT_VIEW,
	type ResetAction,
	type AddViewAction,
	type SetActiveViewAction,
	type UpdateViewAction,
	type ModifyViewAction,
	type DiscardChangesAction,
	type ChangeIdAction,
	type SaveViewAction,
	type RemoveViewAction,
	type MarkAsDefaultViewAction,
} from './actions.tsx';
import { UNTITLED_VIEW_ID, type View } from './types.tsx';
import * as utils from './utils.tsx';

type Action =
	| ResetAction
	| AddViewAction
	| SetActiveViewAction
	| UpdateViewAction
	| ModifyViewAction
	| DiscardChangesAction
	| SaveViewAction
	| ChangeIdAction
	| RemoveViewAction
	| MarkAsDefaultViewAction;
const initialState: View[] = [];

const update = (state: View[], id: number, updater: (arg1: View) => View): View[] =>
	state.map<View>((view) => (view.id === id ? updater(view) : view));

const reducer = (state: View[] = initialState, action: Action): View[] => {
	switch (action.type) {
		case RESET:
			return action.payload;

		case SET_ACTIVE_VIEW: {
			const { id: viewId } = action.payload;
			return state.map<View>((view) => R.assoc('active', view.id === viewId)(view));
		}

		case MARK_AS_DEFAULT_VIEW: {
			const { id: viewId } = action.payload;
			// setting all views as NOT default ones
			const nextState = state.map(R.assoc('isDefault', false));

			// setting current view as the default one
			return update(nextState, viewId, R.mergeLeft({ isDefault: true }));
		}

		case ADD_VIEW: {
			const newView = action.payload;
			let nextState: View[] = state;

			// There should be only 1 default view
			if (newView.isDefault) {
				nextState = nextState.map(R.assoc('isDefault', false));
			}

			return [...nextState, action.payload];
		}

		case UPDATE_VIEW:
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return update(state, action.payload.id as number, R.mergeLeft(action.payload));

		case MODIFY_VIEW: {
			const { id, preferences } = action.payload;

			return update(state, id, (view) => {
				const brandNewUpdate = !view.original;

				return {
					...view,
					preferences,
					persisted: brandNewUpdate ? { preferences } : view.persisted,
					original: brandNewUpdate && id !== UNTITLED_VIEW_ID ? view : view.original,
					modified: true,
				};
			});
		}

		case DISCARD_CHANGES: {
			const { id } = action.payload;
			const view = state.find((one) => one.id === id);

			if (!view || !view.original) {
				return state;
			}

			// eslint-disable-next-line @typescript-eslint/no-shadow
			return update(state, view.id, (view) => {
				if (view.original) {
					return R.mergeRight(view.original, {
						modified: false,
						active: view.active,
						isDefault: view.isDefault,
					});
				}

				return view;
			});
		}

		case SAVE_VIEW: {
			const { id, version, name } = action.payload;
			return update(state, id, (viewFromState) => {
				let modified = false;
				let original = null;
				if (viewFromState.version === version) {
					modified = viewFromState.modified;
					original = viewFromState.original;
				}
				return R.mergeLeft(
					{
						name,
						version,
						modified,
						original,
					},
					viewFromState,
				);
			});
		}

		case CHANGE_ID: {
			const { previous, next } = action.payload;
			return update(state, previous, R.assoc('id', next));
		}

		case REMOVE_VIEW: {
			const { id } = action.payload;
			return R.pipe<View[], View[], View[]>(
				R.filter((one: View) => one.id !== id),
				utils.trySetActiveView,
			)(state);
		}

		default:
			return state;
	}
};

export default reducer;
