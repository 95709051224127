import React, { Component, Fragment, type ReactNode } from 'react';
import * as R from 'ramda';
import ContentLoader from 'react-content-loader';
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	DEFAULT_CONTENT_LOADER_PROPS,
	RECT_BORDER_RADIUS,
	RECT_ROW_HEIGHT,
} from './common/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { IssueHierarchyProps } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export default class IssueHierarchySkeleton extends Component<IssueHierarchyProps> {
	// eslint-disable-next-line react/sort-comp
	checkboxSize = 16;

	static defaultProps = {
		numOfIssues: 10,
		rowHeight: RECT_ROW_HEIGHT,
		showIssueWithoutParent: false,
		showIssueSelectCheckbox: false,
		sidePadding: 8,
		spaceInBetween: 8,
		topPadding: 8,
		animate: true,
	};

	getCheckboxes = (): ReactNode[] => {
		const { numOfIssues, sidePadding, spaceInBetween, rowHeight, topPadding } = this.props;

		const extraSpaceToCenter = Math.floor((rowHeight - this.checkboxSize) / 2);

		return R.range(0, numOfIssues).map((index) => (
			<Fragment key={index}>
				<rect
					x={sidePadding}
					y={index * (rowHeight + spaceInBetween) + topPadding + extraSpaceToCenter}
					{...RECT_BORDER_RADIUS}
					width={this.checkboxSize}
					height={this.checkboxSize}
				/>
			</Fragment>
		));
	};

	getIssues = (index: number, width: number) => {
		const { sidePadding, spaceInBetween, rowHeight, topPadding, showIssueSelectCheckbox } =
			this.props;
		const spaceBetweenIssueAndCheckbox = 44;
		const indentToShowHierarchy = 20;

		let xCordinate = showIssueSelectCheckbox
			? sidePadding + (this.checkboxSize + spaceBetweenIssueAndCheckbox)
			: sidePadding;
		xCordinate = index % 2 === 0 ? xCordinate : xCordinate + indentToShowHierarchy;

		return (
			<Fragment key={index}>
				<rect
					x={xCordinate}
					y={index * (rowHeight + spaceInBetween) + topPadding}
					{...RECT_BORDER_RADIUS}
					width={width / 2}
					height={rowHeight}
				/>
			</Fragment>
		);
	};

	getIssueWithoutParentBlock = (width: number): ReactNode[] => {
		const { numOfIssues, sidePadding, spaceInBetween, rowHeight, topPadding } = this.props;
		const indentToShowPerHierarchyIssueCount = 30;

		return R.range(numOfIssues, numOfIssues + 3).map((index) => (
			<Fragment key={index}>
				<rect
					x={index === numOfIssues ? sidePadding : sidePadding + indentToShowPerHierarchyIssueCount}
					y={index * (rowHeight + spaceInBetween) + topPadding}
					{...RECT_BORDER_RADIUS}
					width={index === numOfIssues ? width / 2 : width / 3}
					height={rowHeight}
				/>
			</Fragment>
		));
	};

	render() {
		const { ariaLabel, numOfIssues, showIssueSelectCheckbox, showIssueWithoutParent, animate } =
			this.props;

		return (
			<UFOLoadHold name="issue-hierarchy-skeleton">
				<AutoSizer>
					{({ width, height }) => (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.skeleton} style={{ width, height }}>
							<ContentLoader
								ariaLabel={ariaLabel}
								width={width}
								height={height}
								{...(ff('com.atlassian.rm.jpo.transposition') ? { animate } : {})}
								{...DEFAULT_CONTENT_LOADER_PROPS}
							>
								{showIssueSelectCheckbox && this.getCheckboxes()}
								{R.range(0, numOfIssues).map((index) => this.getIssues(index, width))}
								{showIssueWithoutParent && this.getIssueWithoutParentBlock(width)}
							</ContentLoader>
						</div>
					)}
				</AutoSizer>
			</UFOLoadHold>
		);
	}
}
