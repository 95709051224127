/**
 * Calculates the quarter (1-4) that a month falls within.
 * Can provide optional fyStart month, which defaults to 1 (January).
 * @param month calendar month (1-12)
 * @param fyStartMonth the month that FY starts (1-12)
 * @returns the quarter that the month falls within
 */
export const calculateQuarter = (month: number, fyStartMonth = 1): number => {
	// to avoid negative values, we'll add 12 months first
	const sameMonth = month + 12;
	const monthsSinceFYStart = sameMonth - fyStartMonth;
	const monthsInAQuarter = 3;
	// because we floored here, we'll need to +1 at the end
	const fullQuartersSinceFYStart = Math.floor(monthsSinceFYStart / monthsInAQuarter);
	// only 4 quarters, e.g. Q5 wraps back to Q1
	const quarter = (fullQuartersSinceFYStart % 4) + 1;
	return quarter;
};
