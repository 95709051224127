import React, { useState, useCallback, useMemo } from 'react';
import * as R from 'ramda';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalTransition,
} from '@atlaskit/modal-dialog';
import type { ModeDescriptor as TimelineModeDescriptor } from '@atlassian/jira-aais-timeline-toolbar/src/ui/timeline-mode-switcher/types.tsx';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';
import { useIntl } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { CUSTOM_TIME_RANGE_TYPES } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { getRelativeDates } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { CustomDateRange } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/types.tsx';
import { CustomDateRangeModalContent } from '@atlassian/jira-portfolio-3-custom-date-range/src/ui/modal-content/index.tsx';
import { getStringFromDates } from '@atlassian/jira-portfolio-3-custom-date-range/src/ui/modal-content/relative-time-range/utils.tsx';
import { UseShortcuts } from '@atlassian/jira-portfolio-3-keyboard-shortcuts/src/controllers/index.tsx';
import type { ModeOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/time-scale/types.tsx';
import { TIMELINE_MODES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const LazyTimelineModeSwitcher = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-timeline-mode-switcher" */ '@atlassian/jira-aais-timeline-toolbar/src/ui/timeline-mode-switcher/index.tsx'
		),
);

export default function TimeScale({
	mode = TIMELINE_MODES.MONTHS,
	onModeChange,
	customDateRange = {},
	initialCustomDateRange = {},
	onTimeScaleCustomDatesChange,
}: Props) {
	const intl = useIntl();

	const [isCustomRangeDialogOpen, setCustomRangeDialogOpen] = useState(false);

	const onItemClicked = useCallback(
		(value: ModeOption) => {
			if (value === TIMELINE_MODES.CUSTOM) {
				setCustomRangeDialogOpen((prev) => !prev);
				return;
			}
			onModeChange(value);
		},
		[onModeChange],
	);

	const onCustomDatesApplied = (range: CustomDateRange) => {
		setCustomRangeDialogOpen(false);
		onTimeScaleCustomDatesChange(range);
		onModeChange(TIMELINE_MODES.CUSTOM, range);
	};

	const getCustomRangeText = useCallback(() => {
		const { fromDate, toDate, typeOfCustomDateRange } = customDateRange;

		if (typeOfCustomDateRange === CUSTOM_TIME_RANGE_TYPES.RELATIVE) {
			const { start, end } = getRelativeDates(customDateRange, new Date());
			return getStringFromDates(start, end, intl);
		}

		return getStringFromDates(fromDate, toDate, intl);
	}, [customDateRange, intl]);

	const changeToWeeks = useCallback(() => {
		onItemClicked(TIMELINE_MODES.WEEKS);
	}, [onItemClicked]);

	const changeToMonths = useCallback(() => {
		onItemClicked(TIMELINE_MODES.MONTHS);
	}, [onItemClicked]);

	const changeToQuarters = useCallback(() => {
		onItemClicked(TIMELINE_MODES.QUARTERS);
	}, [onItemClicked]);

	const changeToYears = useCallback(() => {
		onItemClicked(TIMELINE_MODES.YEARS);
	}, [onItemClicked]);

	const changeToCustomRange = useCallback(() => {
		onItemClicked(TIMELINE_MODES.CUSTOM);
	}, [onItemClicked]);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const changeMode = (mode: string) => {
		switch (mode) {
			case TIMELINE_MODES.WEEKS:
				onItemClicked(TIMELINE_MODES.WEEKS);
				break;
			case TIMELINE_MODES.MONTHS:
				onItemClicked(TIMELINE_MODES.MONTHS);
				break;
			case TIMELINE_MODES.QUARTERS:
				onItemClicked(TIMELINE_MODES.QUARTERS);
				break;
			case TIMELINE_MODES.YEARS:
				onItemClicked(TIMELINE_MODES.YEARS);
				break;
			case TIMELINE_MODES.CUSTOM:
				onItemClicked(TIMELINE_MODES.CUSTOM);
				break;
			default:
				break;
		}
	};

	const modes: TimelineModeDescriptor[] = useMemo(
		() => [
			{ type: TIMELINE_MODES.WEEKS, title: messages.timescaleWeeks, keyBoardShortcut: 'W' },
			{ type: TIMELINE_MODES.MONTHS, title: messages.timescaleMonths, keyBoardShortcut: 'M' },
			{
				type: TIMELINE_MODES.QUARTERS,
				title: messages.timescaleQuarters,
				keyBoardShortcut: 'Q',
			},
			{ type: TIMELINE_MODES.YEARS, title: messages.timescaleYears, keyBoardShortcut: 'Y' },
			{
				type: TIMELINE_MODES.CUSTOM,
				title: messages.timescaleCustom,
				buttonTitle: getCustomRangeText(),
				keyBoardShortcut: 'X',
				group: 'Custom',
			},
		],
		[getCustomRangeText],
	);

	return (
		<>
			<Placeholder
				fallback={<Skeleton width="84px" height="32px" />}
				name="lazy-timeline-mode-switcher"
			>
				<LazyTimelineModeSwitcher modes={modes} onModeChange={changeMode} currentMode={mode}>
					<UseShortcuts shortcutKey="KeyW" onKeyDown={changeToWeeks} />
					<UseShortcuts shortcutKey="KeyM" onKeyDown={changeToMonths} />
					<UseShortcuts shortcutKey="KeyQ" onKeyDown={changeToQuarters} />
					<UseShortcuts shortcutKey="KeyY" onKeyDown={changeToYears} />
					<UseShortcuts shortcutKey="KeyX" onKeyDown={changeToCustomRange} />
				</LazyTimelineModeSwitcher>
			</Placeholder>
			<ModalTransition>
				{isCustomRangeDialogOpen && (
					<ShortcutScope>
						<ModalDialog
							width="716px"
							autoFocus={false}
							onClose={() => setCustomRangeDialogOpen(false)}
						>
							<ModalHeader>
								<ModalTitle>{intl.formatMessage(messages.modalHeader)}</ModalTitle>
							</ModalHeader>
							<ModalBody>
								<CustomDateRangeModalContent
									dateRange={R.isEmpty(customDateRange) ? initialCustomDateRange : customDateRange}
									onConfirm={onCustomDatesApplied}
									onCancel={() => setCustomRangeDialogOpen(false)}
								/>
							</ModalBody>
						</ModalDialog>
					</ShortcutScope>
				)}
			</ModalTransition>
		</>
	);
}
