import {
	changeQuickSelect,
	selectChange,
	deselectChange,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/actions.tsx';
import { QuickSelectOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	select: (changeId: string, category: string) => {
		dispatch(selectChange(changeId, category));
		dispatch(changeQuickSelect(QuickSelectOption.NONE));
	},
	deselect: (changeId: string, category: string) => {
		dispatch(deselectChange(changeId, category));
		dispatch(changeQuickSelect(QuickSelectOption.NONE));
	},
});

export default mapDispatchToProps;
