import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getFlags } from '../../query/flags/index.tsx';
import { getVisualisationSorting } from '../../query/visualisations/index.tsx';
import type { State } from '../../state/types.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export default createStructuredSelector<State, OwnProps, StateProps>({
	flags: getFlags,
	sorting: getVisualisationSorting,
});
