import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	noStartDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.date-field.no-start-date',
		defaultMessage: 'No start date',
		description: 'Message to display on issue bar on hover when there is no start date',
	},
	noEndDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.date-field.no-end-date',
		defaultMessage: 'No end date',
		description: 'Message to display on issue bar on hover when there is no end date',
	},
	rolledUpLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.date-field.rolled-up-label',
		defaultMessage: 'Rolled up: ',
		description:
			'Message to display on issue bar on hover when the dates are rolled up from children',
	},
});

export default messages;
