import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { FormattedMessage, type MessageDescriptor } from '@atlassian/jira-intl';

type Props = {
	letter: string;
	message?: MessageDescriptor;
};

const KeyboardShortcutTooltip = ({ letter, message }: Props) => (
	<Box xcss={tooltipContainerStyles}>
		{message ? (
			<Box xcss={textContainerStyles}>
				<FormattedMessage {...message} />
			</Box>
		) : null}
		<Box xcss={shortcutContainerStyles}>{letter}</Box>
	</Box>
);

const tooltipContainerStyles = xcss({
	display: 'flex',
	paddingTop: 'space.025',
	paddingBottom: 'space.025',
	paddingLeft: '0',
	paddingRight: '0',
});

const shortcutContainerStyles = xcss({
	backgroundColor: 'color.background.neutral',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: 'color.border.inverse',
	borderRadius: '2px',
	paddingTop: '0',
	paddingBottom: '0',
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
	marginTop: 'space.025',
	marginBottom: 'space.025',
	marginRight: '0',
	marginLeft: '0',
});

const textContainerStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	marginRight: 'space.050',
});

export default KeyboardShortcutTooltip;
