import { useCallback, useMemo, useEffect } from 'react';
import dropRight from 'lodash/fp/dropRight';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { useViewport } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import type { Props } from './types.tsx';
import { getTopLevelColumnWidths, getSqueezedWidths, useColumnWidths } from './utils.tsx';
import { getColumnDefaultWidths, getColumnMinWidths } from './utils/metadata.tsx';
import { makeResizer, withScrollableTimeline } from './utils/resizer.tsx';

export const ColumnWidthsProvider = ({
	viewMode,
	viewId,
	columnIds,
	columns,
	children,
	isExportMode,
	hasScrollableTimeline,
	timelineExportWidth,
	updateTopLevelColumnWidths,
}: Props) => {
	const [viewport] = useViewport();
	const allowOverflowed = viewMode === VIEW_MODES.LIST;
	const allowSqueezing = viewMode === VIEW_MODES.TIMELINE;
	const isTimelineExport = isExportMode && viewMode === VIEW_MODES.TIMELINE;

	const defaultWidths = useMemo(
		() => getColumnDefaultWidths(columns, viewMode, hasScrollableTimeline ?? true),
		[columns, viewMode, hasScrollableTimeline],
	);

	const minWidths = useMemo(
		() => getColumnMinWidths(columns, viewMode, hasScrollableTimeline ?? true, isExportMode),
		[columns, viewMode, hasScrollableTimeline, isExportMode],
	);

	/** The columns can be possibly squeezed past their min-widths. */
	const squeezedWidths = useMemo(
		() => (allowSqueezing ? getSqueezedWidths(minWidths) : undefined),
		[allowSqueezing, minWidths],
	);

	const [columnWidths, setColumnWidths] = useColumnWidths(
		viewId,
		viewMode,
		columnIds,
		defaultWidths,
		minWidths,
		squeezedWidths,
	);

	/** Determines whether the resizer can go from minWidths to squeezedWidths */
	const canSqueeze = useCallback(
		(columnBeingResized: number) => allowSqueezing && columnBeingResized === columnIds.length - 2, // The last field column
		[allowSqueezing, columnIds.length],
	);

	/** Determines whether the resizer should try restoring other column to right first. */
	const prioritizeRestoring = useCallback(
		(columnBeingResized: number) => columnBeingResized > 0, // Other than the scope column
		[],
	);

	const resizer = useMemo(
		() =>
			(hasScrollableTimeline ? withScrollableTimeline(makeResizer) : makeResizer)(
				viewport,
				(columnBeingResized, widths) => {
					if (allowSqueezing && canSqueeze(columnBeingResized)) {
						return [widths[0], ...minWidths.slice(1)];
					}
					return minWidths;
				},
				() => squeezedWidths,
				canSqueeze,
				prioritizeRestoring,
				allowOverflowed,
			),
		[
			hasScrollableTimeline,
			viewport,
			canSqueeze,
			prioritizeRestoring,
			allowOverflowed,
			allowSqueezing,
			minWidths,
			squeezedWidths,
		],
	);

	const withViewportConstraint = useMemo(
		() =>
			// Call the resizer here before providing the widths to ensure they suits the current viewport.
			resizer({ column: -1, delta: 0 })(
				isTimelineExport ? [...dropRight(1, columnWidths), timelineExportWidth] : columnWidths,
			),
		[columnWidths, resizer, isTimelineExport, timelineExportWidth],
	);

	const { scope: scopeWidth, fields: fieldsWidth } = getTopLevelColumnWidths(
		withViewportConstraint,
		viewMode,
	);

	useEffect(() => {
		updateTopLevelColumnWidths({
			scope: scopeWidth,
			fields: fieldsWidth,
			timeline: 0,
		});
	}, [fieldsWidth, scopeWidth, updateTopLevelColumnWidths]);

	if (viewport.width === 0) {
		return null;
	}

	return children({
		value: withViewportConstraint,
		onChange: setColumnWidths,
		resizer,
		defaultValue: defaultWidths,
	});
};
