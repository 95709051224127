import { createMigrate, incremental } from '../common/migrate.tsx';
import type { FilterOptionsStateV0, FilterOptionsStateV1 } from './types.tsx';

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: FilterOptionsStateV0): FilterOptionsStateV0 {
				return prevState;
			},
			V1: function fromV0ToV1(prevState: FilterOptionsStateV0): FilterOptionsStateV1 {
				return {
					includeAncestors: true,
					includeDescendants: prevState.showFullHierarchy,
				};
			},
		},
		{ prefix: 'filterOptions', from: 'V0' },
	),
);
