import type { RefObject } from 'react';
import { useDragAndDropAutoScroll } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/bindings/index.tsx';

type Props = {
	scrollRef: RefObject<HTMLElement>;
};

export const AutoScrollObserver = ({ scrollRef }: Props) => {
	useDragAndDropAutoScroll(scrollRef, 'vertical');

	return null;
};
