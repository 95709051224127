import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/index.tsx';
import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { COMPONENT_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { StateProps } from './types.tsx';

const mapStateToProps: MapStateToProps<StateProps> = (state) => {
	const projects = getProjects(state);
	const {
		[COMPONENT_FILTER_ID]: { id, value },
	} = getFilters(state);

	return {
		id,
		value,
		projects,
	};
};

export default mapStateToProps;
