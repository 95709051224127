.description {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	margin-left: 25px;
	font-size: 13px;
}

.checkboxWrapper {
	margin-left: -8px;
}

.option + .option {
	margin-top: calc(1.5 * var(--jpo-common-base-spacing));
}
