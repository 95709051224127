// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tryParseJson = (str?: string | null): any => {
	if (str) {
		try {
			return JSON.parse(str);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			// Swallow exception
		}
	}

	return undefined;
};

export { tryParseJson };
