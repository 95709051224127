import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

const BORDER_RADIUS = 2;
export const RECT_BORDER_RADIUS = {
	rx: `${BORDER_RADIUS}`,
	ry: `${BORDER_RADIUS}`,
};

export const RECT_ROW_HEIGHT = 28;

const CONTENT_LOADER_COLORS = {
	primaryColor: token('color.background.neutral', colors.N30),
	secondaryColor: token('color.background.neutral.subtle', colors.N20),
};

const CONTENT_LOADER_ANIMATION_SPEED = 1;

export const DEFAULT_CONTENT_LOADER_PROPS = {
	...CONTENT_LOADER_COLORS,
	speed: CONTENT_LOADER_ANIMATION_SPEED,
};
