.filtersDialog {
	width: 496px;
}

.filtersHeader {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	padding: calc(var(--jpo-common-base-spacing) / 2) calc(var(--jpo-common-base-spacing) * 2);
}

/* Delete this class when tidying getWillShowNav4 */
.filtersList {
	margin-top: 0;
	/* (60px (Jira nav) + 100 px (ARJ header) + 40px (Clear all filters) + 41px (Show Full hierarchy) + 8px (bottom padding) + 64px (bottom offset) = 313px */
	max-height: calc(100vh - 313px);
	/* This removes scrollbars that appear from overlapped elements in IE11 */
	-ms-overflow-style: auto;
	overflow-y: auto;
	padding: 0 calc(var(--jpo-common-base-spacing) * 3) var(--jpo-common-base-spacing);
}

.filterOptions {
	align-items: center;
	border-top: 1px solid var(--ds-border, var(--adg3-color-N40));
	display: flex;
	height: 40px;
	margin-top: 0;
	padding-left: var(--jpo-common-base-spacing);
}

.filterRow {
	align-items: center;
	display: flex;
	margin-bottom: var(--jpo-common-base-spacing);

	&:first-child {
		margin-bottom: calc(var(--jpo-common-base-spacing) * 3);
	}
}

/*
* the inlineDialog width is 496px minus 48px padding = 448px
* labels get 170px width including 16px of right padding
*/
.filterRowLabel {
	flex: 0 0 154px;
	padding-right: calc(var(--jpo-common-base-spacing) * 2);
}

/* special treatment for the hierarchy filter label - it needs to be aligned to the hierarchy level fields */
.hierarchyFilterLabel {
	align-self: flex-end;
	line-height: 32px;
}

.filterRowFilter {
	flex: 1;
}
