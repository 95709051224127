import { getAssigneesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees/index.tsx';
import { getCurrentUser } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/permissions/index.tsx';
import { getUserFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/filters.tsx';
import { getReviewChanges } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/index.tsx';
import type { Change } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types.tsx';
import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/assignees/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';

export const getUserFilterOptionsPure = (
	reviewChanges: Change[],
	assigneesById: {
		[key: string]: Person;
	},
): Person[] => {
	const result: Array<Person> = [];
	for (const { lastModified } of reviewChanges) {
		if (!result.some((el) => el && lastModified && el.personId === lastModified.personId)) {
			const { personId } = lastModified || {};
			if (personId && assigneesById[personId]) {
				result.push(assigneesById[personId]);
			}
		}
	}

	return result;
};

export const getUserFilterOptions = createSelector(
	[getReviewChanges, getAssigneesById],
	getUserFilterOptionsPure,
);

const mapStateToProps = createSelector(
	[getUserFilterOptions, getUserFilter, getReviewChanges, getCurrentUser],
	(users, { value }, reviewChanges, currentUser) => ({
		users,
		value,
		reviewChanges,
		currentUser,
	}),
);

export default mapStateToProps;
