import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.label-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchLabelsPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.label-filter.search-labels-placeholder',
		defaultMessage: 'Show issues from labels',
		description: 'This is a placeholder for search',
	},
	noLabelsFilterText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.label-filter.no-labels-filter-text',
		defaultMessage: 'No label',
		description: 'Text for selecting issues without labels',
	},
	searchLabelsAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.label-filter.search-labels-aria-label',
		defaultMessage: 'Choose from labels',
		description: 'Aria label for labels search filed that appears on activating Filters button',
	},
});
