.textMatch {
	background-color: var(--ds-background-accent-purple-subtle, rgba(135, 119, 217, 0.7));
	border-radius: 2px;
	color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.textMatch.isActive {
	background-color: var(--ds-background-accent-purple-bolder, var(--adg3-color-P300));
}

.ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
