.container {
	min-width: 350px;
}

.spinnerWrapper {
	display: flex;
	margin-right: 4px;
}

.labelWrapper {
	display: flex;
	align-items: center;
}
