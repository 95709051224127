import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	searchIssueTypePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-types-key-filter.search-issue-type-placeholder',
		defaultMessage: 'Show issues from issue types',
		description: 'This is a placeholder for search',
	},
	searchIssueTypeLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-types-key-filter.search-issue-type-label',
		defaultMessage: 'Choose from issue types',
		description:
			'Aria label for issue types search filed that appears on activating Filters button',
	},
});
