import kebabCase from 'lodash/kebabCase';
import type { ReactRouterCompatibleHistory } from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';
import type { PlanInfo } from '../../app-simple-plans/state/domain/plan/types.tsx';
import { proxyContextSafeUrl } from '../api/index.tsx';
import {
	EXPORT_VIEW_DEFAULT_SCOPE_WIDTH,
	EXPORT_VIEW_DEFAULT_FIELDS_WIDTH,
	type ConfluenceMacroType,
	DEPENDENCY_REPORT_SHARE_TYPE,
} from '../view/constant.tsx';

export const ROOT_PATHS = {
	PLAN: 'plan',
	SETTINGS: 'settings',
} as const;

export const PLAN_PAGES = {
	ROADMAP: 'backlog',
	RELEASES: 'releases',
	TEAMS: 'teams',
	DEPENDENCIES_REPORT: 'dependencies',
} as const;

export const PLAN_PATHS = {
	ROADMAP: `${ROOT_PATHS.PLAN}/${PLAN_PAGES.ROADMAP}`,
	RELEASES: `${ROOT_PATHS.PLAN}/${PLAN_PAGES.RELEASES}`,
	TEAMS: `${ROOT_PATHS.PLAN}/${PLAN_PAGES.TEAMS}`,
	DEPENDENCIES_REPORT: `${ROOT_PATHS.PLAN}/${PLAN_PAGES.DEPENDENCIES_REPORT}`,
} as const;

export const PLAN_SETTINGS_PAGES = {
	CUSTOM_FIELDS: 'customFields',
	EXCLUSION_RULES: 'setExclusionRules',
	ISSUE_SOURCES: 'issueSources',
	PERMISSIONS: 'permissions',
	REMOVED_ISSUES: 'removedIssues',
	SAVED_VIEWS: 'savedViews',
	SCENARIOS: 'scenarios',
	AUTO_SCHEDULER: 'auto-scheduler',
	SCHEDULING: 'scheduling',
	SOURCES: 'sources',
	FIND_YOUR_ISSUE: 'findYourIssue',
} as const;

export const PLAN_SETTINGS_PATHS = {
	CUSTOM_FIELDS: `/${ROOT_PATHS.SETTINGS}/${PLAN_SETTINGS_PAGES.CUSTOM_FIELDS}`,
	EXCLUSION_RULES: `/${ROOT_PATHS.SETTINGS}/${PLAN_SETTINGS_PAGES.EXCLUSION_RULES}`,
	ISSUE_SOURCES: `/${ROOT_PATHS.SETTINGS}/${PLAN_SETTINGS_PAGES.ISSUE_SOURCES}`,
	PERMISSIONS: `/${ROOT_PATHS.SETTINGS}/${PLAN_SETTINGS_PAGES.PERMISSIONS}`,
	REMOVED_ISSUES: `/${ROOT_PATHS.SETTINGS}/${PLAN_SETTINGS_PAGES.REMOVED_ISSUES}`,
	SAVED_VIEWS: `/${ROOT_PATHS.SETTINGS}/${PLAN_SETTINGS_PAGES.SAVED_VIEWS}`,
	SCENARIOS: `/${ROOT_PATHS.SETTINGS}/${PLAN_SETTINGS_PAGES.SCENARIOS}`,
	AUTO_SCHEDULER: `/${ROOT_PATHS.SETTINGS}/${PLAN_SETTINGS_PAGES.AUTO_SCHEDULER}`,
	SCHEDULING: `/${ROOT_PATHS.SETTINGS}/${PLAN_SETTINGS_PAGES.SCHEDULING}`,
	SOURCES: `/${ROOT_PATHS.SETTINGS}/${PLAN_SETTINGS_PAGES.SOURCES}`,
	FIND_YOUR_ISSUE: `/${ROOT_PATHS.SETTINGS}/${PLAN_SETTINGS_PAGES.FIND_YOUR_ISSUE}`,
} as const;

// After cleaning up isSidebarVisible, can replace constants above with kebab case to skip redundant transformation
export const buildManageViewsPath = (planId: number, scenarioId: number) =>
	`/jira/plans/${planId}/scenarios/${scenarioId}/settings/${kebabCase(
		PLAN_SETTINGS_PAGES.SAVED_VIEWS,
	)}`;

export const buildRemoveIssuePath = (planId: number, scenarioId: number) =>
	`/jira/plans/${planId}/scenarios/${scenarioId}/settings/${kebabCase(
		PLAN_SETTINGS_PAGES.REMOVED_ISSUES,
	)}`;

export const redirect = (url: string) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.href = url;
};

export const openInNewTab = (url: string) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.open(url, '_blank')?.focus();
};

export const reload = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.reload();
};

export const redirectToPlan = (planId: number, scenarioId: number) => {
	redirect(proxyContextSafeUrl(`/jira/plans/${planId}/scenarios/${scenarioId}`));
};

export const redirectToSettings = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.hash = `#${ROOT_PATHS.SETTINGS}`;
};

export const redirectToSettingsSubPage = (page: string) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.hash = `#${ROOT_PATHS.SETTINGS}/${page}`;
};

export const redirectToPlanPage = (tab: string) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.hash = `#${ROOT_PATHS.PLAN}${tab}`;
};

export const redirectToIssueSourceSetup = (planId: number) => {
	redirect(proxyContextSafeUrl(`/secure/PortfolioPlanSetup.jspa?id=${planId}&3.0plan`));
};

export const userAgent = globalThis.navigator?.userAgent || 'unknown';

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const getLocation = () => window.location;

export type QueryParams = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};
type PathParams = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

export const parseQueryParams = (rawParams?: string | null): QueryParams => {
	if (!rawParams) {
		return {};
	}

	const result: QueryParams = {};
	rawParams
		.slice(1) // ignore '?'
		.split('&')
		.map((queryParam) => {
			const keyValue = queryParam.split('=');
			return { key: keyValue[0], value: keyValue[1] };
		})
		.forEach((keyValue) => {
			result[keyValue.key] = keyValue.value;
		});
	return result;
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const getQueryParameters = () => parseQueryParams(window.location.search);

export const parsePathParams = (path: string): PathParams => {
	const pathparams = ['id', 'sid', 'r'] as const;
	const paramToPathNameMap = {
		id: 'plans',
		sid: 'scenarios',
		r: 'reports',
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
	const params: PathParams = {} as { [key: string]: any };
	pathparams.forEach((param) => {
		const regex = new RegExp(`/${paramToPathNameMap[param]}/(\\w+)`, 'g');
		const matches = Array.from(path.matchAll(regex), (match) => match[1]);

		params[param] = matches[0];
	});
	return params;
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const getPathParams = (): PathParams => parsePathParams(window.location.pathname);

export const updateQueryString = (locationSearch: string, newParams: QueryParams) => {
	const currentParams = parseQueryParams(locationSearch);
	const updatedParams = { ...currentParams, ...newParams };
	const searchString = Object.keys(updatedParams)
		.filter((key) => updatedParams[key] !== undefined)
		.map((key) => `${key}=${updatedParams[key]}`)
		.join('&');
	return `?${searchString}`;
};

export const redirectToNewScenario = (
	browserHistory: ReactRouterCompatibleHistory,
	sid: number,
) => {
	const regex = new RegExp('(/scenarios/)\\w+', 'g');
	/* eslint-disable jira/jira-ssr/no-unchecked-globals-usage */
	const url = `${window.location.pathname.replace(regex, (_, $1) => `${$1}${sid}`)}${
		window.location.search
	}${window.location.hash}`;
	/* eslint-enable jira/jira-ssr/no-unchecked-globals-usage */
	browserHistory.push(url);
};

export const isReportView = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (process.env.NODE_ENV === 'development' && window?.STORYBOOK_ENV) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const url = new URL(window.location.href);
		if (url.searchParams.has('enforceReportMode')) {
			return true;
		}
	}

	return (
		window &&
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		(window.location.pathname.includes('reports') ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.pathname.includes('PortfolioEmbeddedReportView') ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.pathname.includes('PlanEmbeddedReport') ||
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.location.pathname.includes('PortfolioRoadmapConfluence'))
	);
};

export const isEmbedReportView = () =>
	window &&
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	(window.location.pathname.includes('PortfolioEmbeddedReportView') ||
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.location.pathname.includes('PlanEmbeddedReport'));

export const isConfluenceReportView = () =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window && window.location.pathname.indexOf('PortfolioRoadmapConfluence') !== -1;

export const isConfluenceReportProxyView = () =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window && window.location.pathname.indexOf('/plugins/servlet/PortfolioRoadmapConfluence') !== -1;

export const isExportView = () =>
	Boolean(
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window?.location.pathname.indexOf('plans') !== -1 &&
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window?.location.pathname.indexOf('scenarios') !== -1 &&
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window?.location.pathname.indexOf('/export') !== -1,
	) ||
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	Boolean(window?.location.href.indexOf('forceExportMode=1') !== -1);

export const isExportPreview = () =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	Boolean(window?.location.href.indexOf('startInPreviewMode=1') !== -1);

export const getScopeWidth = () => {
	const params = getQueryParameters();
	const width = parseInt(params.scopeWidth || EXPORT_VIEW_DEFAULT_SCOPE_WIDTH, 10);
	return Number.isNaN(width) ? EXPORT_VIEW_DEFAULT_SCOPE_WIDTH : width;
};

export const getFieldsWidth = () => {
	const params = getQueryParameters();
	const width = parseInt(params.fieldsWidth || EXPORT_VIEW_DEFAULT_FIELDS_WIDTH, 10);
	return Number.isNaN(width) ? EXPORT_VIEW_DEFAULT_FIELDS_WIDTH : width;
};

export const getNonTimelineWidth = () => getScopeWidth() + getFieldsWidth();

export const getExportTimelineDateRange = (): {
	fromDate?: number;
	toDate?: number;
} => {
	const params = getQueryParameters();
	const fromDate = parseInt(params.fromDate || '', 10);
	const toDate = parseInt(params.toDate || '', 10);
	return {
		fromDate: Number.isNaN(fromDate) ? undefined : fromDate,
		toDate: Number.isNaN(toDate) ? undefined : toDate,
	};
};

// Firefox has a bad habit of terminating network requests on the unloading page before stopping
// JS execution. As a result fetch throws an error which we don't know what to do with except as
// to show to the user as an error flag and try to log it.
// We workaround it by swallowing any fetch failures if the page started to unload,
// as well as not reporting unhandled exceptions via Flags.

let isPageUnloadingFlag = false;

if (typeof window !== 'undefined') {
	window.addEventListener('beforeunload', () => {
		isPageUnloadingFlag = true;
	});
}

export const isPageUnloading = () => isPageUnloadingFlag;

type GetPlanUrl = {
	planId: NonNullable<PlanInfo['id']>;
	scenarioId?: PlanInfo['currentScenarioId'];
	confluenceMacroType?: ConfluenceMacroType;
};

export const getPlanUrl = ({ planId, scenarioId, confluenceMacroType }: GetPlanUrl) => {
	const baseUrl =
		scenarioId != null
			? `/jira/plans/${planId}/scenarios/${scenarioId}/`
			: `/jira/plans/${planId}/`;

	switch (confluenceMacroType) {
		case DEPENDENCY_REPORT_SHARE_TYPE:
			return `${baseUrl}${PLAN_PAGES.DEPENDENCIES_REPORT}`;
		default:
			return `${baseUrl}${PLAN_PAGES.ROADMAP}`;
	}
};
