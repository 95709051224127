import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saving-dialog.heading',
		defaultMessage: 'Save changes to {viewName}',
		description: 'Label for view saving confirming dialog heading',
	},
	message: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saving-dialog.message',
		defaultMessage:
			'Everyone who uses <strong>{viewName}</strong> to view this plan will see your changes. Are you sure you want to continue?',
		description: 'The message for view saving confirming dialog',
	},
});
