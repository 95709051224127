import {
	OPEN_HIDDEN_ISSUES_DIALOG,
	CLOSE_HIDDEN_ISSUES_DIALOG,
	type OpenDialogAction,
	type CloseDialogAction,
} from './actions.tsx';
import type { HiddenIssues } from './types.tsx';

const initialState = {
	isDialogOpen: false,
} as const;

type Action = OpenDialogAction | CloseDialogAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: HiddenIssues = initialState, action: Action) => {
	switch (action.type) {
		case OPEN_HIDDEN_ISSUES_DIALOG:
			return {
				isDialogOpen: true,
			};
		case CLOSE_HIDDEN_ISSUES_DIALOG:
			return {
				isDialogOpen: false,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
