// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { setSaveViewAsDialogStatus, removeError } from '../../../top/view-bar/actions';
export const SET_RENAME_SAVING = 'state.ui.settings.views.row.SET_RENAME_SAVING' as const;

export type SetRenameSavingAction = {
	type: typeof SET_RENAME_SAVING;
	payload: boolean;
};

export const setRenameSaving = (saving: boolean) => ({
	type: SET_RENAME_SAVING,
	payload: saving,
});
