import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';

type Target = {
	instance: Symbol;
	index: number;
};

export type DragTarget = Target;

export type DropTarget = Target & {
	closestEdge: Edge | null;
};

export const DROP_INDICATOR = 'DROP_INDICATOR';
export const DRAG_INDICATOR = 'DRAG_INDICATOR';
export const PARENT_INDICATOR = 'PARENT_INDICATOR';

export type DragIndicator = {
	type: typeof DRAG_INDICATOR;
	index: number;
};

export type DropIndicator = {
	type: typeof DROP_INDICATOR;
	index: number;
	edge: Edge;
	blocked: boolean;
};

export type UseParentIndicatorOptions = {
	index?: number;
	id?: string;
};

export type ParentIndicator = {
	type: typeof PARENT_INDICATOR;
	index?: number;
	id?: string;
	blocked: boolean;
};

export type Indicator = DragIndicator | DropIndicator | ParentIndicator;

export type Data = {
	instance: Symbol;
	dragTarget?: DragTarget;
	dropTarget?: DropTarget;
	indicators?: Indicator[];
};

export type Callbacks = {
	onDragStart?: (dragTarget: DragTarget) => void;
	onDrag?: (dragTarget: DragTarget, dropTarget?: DropTarget) => void;
	onDrop?: (dragTarget: DragTarget, dropTarget?: DropTarget) => void;
	getIndicators?: (dragTarget: DragTarget, dropTarget?: DropTarget) => Indicator[] | undefined;
};

export type State = Data & Callbacks;
