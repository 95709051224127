import React from 'react';
import * as R from 'ramda';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, GetContent } from './types.tsx';

export const getContent: GetContent = (content) => {
	if (!content) {
		return undefined;
	}
	if (typeof content === 'string') {
		return content;
	}
	if (Array.isArray(content)) {
		return R.join(', ', content);
	}
	return content;
};

export default function SelectWireframe(props: Props) {
	const { isReadOnly, content } = props;
	const classNames = isReadOnly ? styles.readOnly : '';
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={`${styles.wireframe} ${classNames}`}>
			<div>{getContent(content)}</div>
		</div>
	);
}

SelectWireframe.defaultProps = {
	isReadOnly: false,
};
