import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	header: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.empty.header',
		defaultMessage: 'Add a column to the view',
		description:
			"A sub header inside of the Advanced Roadmap's table for when no columns are visible. Provides context as to why the view is empty.",
	},
	callToAction: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.empty.call-to-action',
		defaultMessage: 'Add columns (or fields) to this view and start planning in more details',
		description:
			"A call to action inside the Advanced Roadmap's table for when no columns are visible. Provides a suggestion to populate the empty view.",
	},
	addFields: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.empty.add-fields',
		defaultMessage: 'Add fields',
		description: 'Label for add fields dropdown button',
	},
});

export default messages;
