import { useState, useEffect, useLayoutEffect } from 'react';

export const getDelta = ({ deltaX, deltaY, shiftKey }: WheelEvent) => {
	// On windows, holding shift then using wheel doesn't result in deltaX change.
	if (shiftKey && deltaY !== 0) {
		return {
			deltaX: deltaY,
			deltaY: deltaX,
		};
	}

	return {
		deltaX,
		deltaY,
	};
};

/**
 * Returns the native horizontal scrollbar height
 * This can return 0 with MacOS scrollbars auto-hide settings
 */
export const useNativeScrollbarHeight = () => {
	const [result, setResult] = useState<number>();
	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const outerEl = document.createElement('div');

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const innerEl = document.createElement('div');
		Object.assign(outerEl.style, {
			overflowX: 'scroll',
			width: '50px',
		});
		Object.assign(innerEl.style, {
			width: '100px',
		});
		outerEl.appendChild(innerEl);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.body?.appendChild(outerEl);

		setResult(outerEl.offsetHeight - outerEl.clientHeight);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.body?.removeChild(outerEl);
	}, []);

	return result;
};

/** useResizeObserver invokes `callback` when:
 *  Element is mounted.
 *  Element is resized.
 *  Callback is changed.
 *
 * Returns a ref to attach to the element.
 */
export function useResizeObserver<T extends HTMLElement>(
	elRef: React.RefObject<T | null>,
	callback: (el: T) => void,
) {
	useLayoutEffect(() => {
		const el = elRef.current;

		if (!el) {
			return;
		}

		callback(el);

		const observer = new ResizeObserver(() => callback(el));
		observer.observe(el);

		return () => {
			observer.unobserve(el);
			observer.disconnect();
		};
	}, [callback, elRef]);
}
