import { createMigrate, incremental } from '../common/migrate.tsx';
import type { VisualisationsStateV0 } from './types.tsx';

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: VisualisationsStateV0): VisualisationsStateV0 {
				return {
					...prevState,
				};
			},
		},
		{ prefix: 'visualisations', from: 'V0' },
	),
);
