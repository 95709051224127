import type { OriginalIssues } from './types.tsx';

export const RESET = 'state.domain.original-issues.RESET' as const;
export const UPDATE = 'state.domain.original-issues.UPDATE' as const;
export const REVERT_FIELDS = 'state.domain.original-issues.REVERT_FIELDS' as const;
export const REMOVE = 'state.domain.original-issues.REMOVE' as const;

export type ResetActionPayload = OriginalIssues;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UpdateActionPayload = any;
export type RevertFieldActionPayload = {
	id: string;
	fields: string[];
};
export type RemoveActionPayload = string[];

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};

export type RevertFieldsAction = {
	type: typeof REVERT_FIELDS;
	payload: RevertFieldActionPayload;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: RemoveActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const update = (payload: UpdateActionPayload) => ({
	type: UPDATE,
	payload,
});

export const revertFields = (payload: RevertFieldActionPayload) => ({
	type: REVERT_FIELDS,
	payload,
});
export const remove = (payload: RemoveActionPayload) => ({
	type: REMOVE,
	payload,
});
