import {
	cancelTimelinePreview,
	commitTimelinePreview,
	hoverTimelineIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/timeline-preview/index.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps, OwnProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps, OwnProps> = (
	dispatch,
	ownProps,
) => ({
	commitTimelinePreview: () => {
		dispatch(commitTimelinePreview());
	},
	cancelTimelinePreview: () => {
		dispatch(cancelTimelinePreview());
	},
	hoverTimelineIssue: (defaultStart: number, defaultEnd: number) => {
		dispatch(hoverTimelineIssue(ownProps.issue.id, defaultStart, defaultEnd));
	},
});

export default mapDispatchToProps;
