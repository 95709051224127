export type UpdateColumnWidthsActionPayload = {
	scope: number;
	fields: number;
	timeline: number;
	timelineExport?: number;
};

export type ColumnWidthsState = {
	scope: number;
	fields: number;
	timeline: number;
	timelineExport: number;
};

export const UPDATE_COLUMN_WIDTHS = 'state.ui.column-widths.UPDATE_COLUMN_WIDTHS' as const;

export type UpdateColumnWidthsAction = {
	type: typeof UPDATE_COLUMN_WIDTHS;
	payload: UpdateColumnWidthsActionPayload;
};
