import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	hideRefinementOptions: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.header-toggle.hide-refinement-options',
		defaultMessage: 'Hide refinement options',
		description: 'Tooltip content to collapse the refinement options',
	},
	showRefinementOptions: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.header-toggle.show-refinement-options',
		defaultMessage: 'Show refinement options',
		description: 'Tooltip content to expand the refinement options',
	},
	hideHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.header-toggle.hide-header',
		defaultMessage: 'Hide header',
		description: 'Tooltip content to collapse header',
	},
	showHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.header-toggle.show-header',
		defaultMessage: 'Show header',
		description: 'Tooltip content to expand header',
	},
});
