// Note: We use block comment at top of the file because we get strange error in `SSR Image Build`
/* eslint-disable @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard  */
/* eslint-disable @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors,  @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766 */

import React, { forwardRef, memo } from 'react';
import { styled } from '@compiled/react';
import Button, { type ButtonProps, IconButton, type IconButtonProps } from '@atlaskit/button/new';

export const DraggableButton = memo(
	forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }: ButtonProps, ref) => (
		<CompactWrapper>
			<Button ref={ref} {...props}>
				{children}
			</Button>
		</CompactWrapper>
	)),
);

export const DraggableIconButton = memo(
	forwardRef<HTMLButtonElement, IconButtonProps>((props: IconButtonProps, ref) => (
		<Wrapper>
			<IconButton ref={ref} {...props} />
		</Wrapper>
	)),
);

const Wrapper = styled.div({
	display: 'inline-flex',
	maxWidth: '100%',
	'& > button:focus:not(:focus-visible)': {
		outline: 'none',
	},
});

const CompactWrapper = styled(Wrapper)({
	'& > button': {
		paddingInline: '6px !important',
	},
});
