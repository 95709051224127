import React, { forwardRef } from 'react';
import type { PopupComponentProps } from '@atlaskit/popup';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, ...props }, ref) => (
		<div ref={ref}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div {...props} className={styles['afm-custom-popup-container']}>
				{children}
			</div>{' '}
			<div data-role="afm-dropdown-menu-drag-handle" key="afm-dropdown-menu-drag-handle" />
		</div>
	),
);

export default CustomPopupContainer;
