import {
	change,
	clear,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters/index.tsx';
import {
	TEAM_FILTER_ID,
	type AttributeFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';

import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	changeTeamFilter: (payload: AttributeFilterValue) => {
		dispatch(change({ id: TEAM_FILTER_ID, value: payload }));
	},
	clearTeamFilter: () => {
		dispatch(clear(TEAM_FILTER_ID));
	},
});

export default mapDispatchToProps;
