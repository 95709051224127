.modalHeader {
	position: relative;
	padding: calc(var(--jpo-spacing) * 2) calc(var(--jpo-spacing) * 3);
	display: flex;
	align-items: center;
}

.breadcrumbs {
	flex-grow: 1;
}

.summary {
	margin: 0 calc(var(--jpo-spacing) * -1);

	& > form > div {
		/* this is done to offset the weird 8px top margin of the Inline Edit read view */
		margin-top: 0;
	}
}

.summaryBeingEdited {
	max-width: 390px;
	margin-left: 0;
	margin-top: var(--jpo-spacing);
	margin-bottom: var(--jpo-spacing);
}

.summaryReadView {
	padding: var(--jpo-spacing) 6px;
	position: relative;
	word-break: break-word;
}

.description {
	margin: calc(var(--jpo-spacing) * 3) 0 10px;
}

.descriptionLabel {
	margin-bottom: var(--jpo-spacing);
}

.descriptionLabelInner {
	position: relative;
	display: inline-block;
	padding-right: var(--jpo-spacing);
}

.descriptionSpinner {
	margin-bottom: calc(var(--jpo-spacing) * 3);
}

.hiddenButton {
	visibility: hidden;
}
