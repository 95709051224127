import React, { useState, useEffect } from 'react';
import type { Props, DragSource } from './types.tsx';
import { context, useHoverableIssueRect, useDropHandling } from './utils.tsx';

function DragPreviewDepLineProvider({
	addIssueLink,
	width,
	children,
	outgoingLinks,
	dependencySettings,
}: Props) {
	const { hovered, removeIssueRect, updateIssueRect, handleDragMove, handleDragEnd } =
		useHoverableIssueRect();
	const [dragSource, setDragSource] = useState<DragSource | null | undefined>();
	useEffect(() => {
		if (dragSource == null) {
			handleDragEnd();
		}
	}, [dragSource, handleDragEnd]);

	const handleDrop = useDropHandling(
		hovered,
		dragSource,
		addIssueLink,
		outgoingLinks,
		dependencySettings,
	);

	return (
		<context.Provider
			value={{
				width,
				dragSource,
				setDragSource,
				dragHovered: hovered,
				updateIssueRect,
				removeIssueRect,
				handleDragMove,
				handleDrop,
			}}
		>
			{children}
		</context.Provider>
	);
}

export default DragPreviewDepLineProvider;
