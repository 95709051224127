import { STORY_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type {
	IssueLink,
	Sprint,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	UNDEFINED_KEY,
	EXTERNAL_SPRINT,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { State } from '../../state/types.tsx';
import { GroupBy } from '../../state/ui/main/tabs/dependencies/filters/types.tsx';
import { ROLLUP_BY_OPTION } from '../../view/main/tabs/dependencies/types.tsx';
import {
	getInternalOutgoingLinks,
	getInternalIncomingLinks,
	type IssueLinksByIssueId,
} from '../issue-links/index.tsx';
import { getWholeHierarchyParentAndDescendant } from '../issues/index.tsx';
import { getIssues, getIssueMapById } from '../raw-issues/index.tsx';
import {
	getSprintsIdsByState,
	getExternalSprintsIds,
	getExternalSprints,
} from '../sprints/index.tsx';
import { getSprintStatesCategoriesPure } from '../sprints/utils.tsx';
import type { DependencyLinks, GraphLinks } from './types.tsx';

export const NO_SPRINT_ID = UNDEFINED_KEY;

export const getDependencyFilters = (state: State) => state.ui.Main.Tabs.Dependencies.Filters;

// Start of Dependency Filter settings queries

export const getGroupBy = (state: State) => state.ui.Main.Tabs.Dependencies.Filters.groupBy;

export const getDerivedRollupBy = (state: State) =>
	getGroupBy(state) === GroupBy.EPIC_LEVEL
		? ROLLUP_BY_OPTION.HIERARCHY
		: state.ui.Main.Tabs.Dependencies.Filters.rollupBy;

export const getDerivedRollupHierarchyLevel = (state: State) =>
	getGroupBy(state) === GroupBy.EPIC_LEVEL
		? STORY_LEVEL
		: state.ui.Main.Tabs.Dependencies.Filters.rollupHierarchyLevel;

export const getIssueInFocus = (state: State) =>
	state.ui.Main.Tabs.Dependencies.Filters.issueInFocus;

export const getIssueFilter = (state: State) => state.ui.Main.Tabs.Dependencies.Filters.issueFilter;

export const getProjectFilter = (state: State) =>
	state.ui.Main.Tabs.Dependencies.Filters.projectFilter;

export const getSprintFilter = (state: State) =>
	state.ui.Main.Tabs.Dependencies.Filters.sprintFilter;

export const getIssueLinkTypeFilter = (state: State) =>
	state.ui.Main.Tabs.Dependencies.Filters.issueLinkTypeFilter;

export const getDependenciesFilters = (state: State) => state.ui.Main.Tabs.Dependencies.Filters;

// End of Dependency Filter settings queries

export const getStagePosAndScale = (state: State) =>
	state.ui.Main.Tabs.Dependencies.StagePosAndScale;

const getIssueFilterSafePure = (
	issuesById: ReturnType<typeof getIssueMapById>,
	issueFilter: ReturnType<typeof getIssueFilter>,
): string | null => {
	if (issueFilter !== null && issuesById[issueFilter]) {
		return issueFilter;
	}
	return null;
};

export const getIssueFilterSafe = createSelector(
	[getIssueMapById, getIssueFilter],
	getIssueFilterSafePure,
);

export const getSpecificIssueLinks = (
	issueId: string,
	incomingLinks: IssueLinksByIssueId,
	outgoingLinks: IssueLinksByIssueId,
): Set<string> => {
	const specificIssueLinks: Set<string> = new Set();
	// Collect all the issues which are linked to the specific one via dependencies,
	// both immediate and transitive.
	// Handling cycles: skip met issues, but don't interrupt queue traversal.
	const queue = [issueId];
	while (queue.length > 0) {
		const id = queue.pop();
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		for (const link of incomingLinks[id!] || []) {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const id = link.sourceItemKey;
			if (!specificIssueLinks.has(id)) {
				specificIssueLinks.add(id);
				queue.push(id);
			}
		}
	}

	const outgoingQueue = [issueId];
	while (outgoingQueue.length > 0) {
		const outgoingQueueId = outgoingQueue.pop();
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		for (const outgoingLink of outgoingLinks[outgoingQueueId!] || []) {
			const outgoingId = outgoingLink.targetItemKey;
			if (!specificIssueLinks.has(outgoingId)) {
				specificIssueLinks.add(outgoingId);
				outgoingQueue.push(outgoingId);
			}
		}
	}

	return specificIssueLinks;
};

export const matchesSprintFilter = (issue: Issue, sprintFilter: Set<string>) => {
	const issueSprints = [...(issue.completedSprints || []), issue.sprint].filter(isDefined);
	return (
		(sprintFilter.has(NO_SPRINT_ID) && issueSprints.length === 0) ||
		issueSprints.some((sprint) => sprintFilter.has(sprint))
	);
};

export const getRolledUpIssuesLinksPure = (
	internalOutgoingLinks: {
		[key: string]: IssueLink[];
	},
	internalIncomingLinks: {
		[key: string]: IssueLink[];
	},
	parentsAndDescendant: {
		[key: string]: {
			ancestors: Issue[];
			descendants: Issue[];
		};
	},
	rollupBy: string | null,
	rollupHierarchyLevel: number,
	issueMap: {
		[key: string]: Issue;
	},
	issueInFocus: string | null,
	issueFilter: string | null,
	issues: Issue[],
	projectFilter: string[] | null,
	issueLinkTypeFilter: string[] | null,
	sprintFilter: string[] | null,
): DependencyLinks => {
	const results: GraphLinks = {};
	let hasDependency = false;
	let issuesToHighlight = new Set();
	let activeIssue = issueInFocus;

	if (isDefined(issueFilter)) {
		const issueMatchesFilter = issues.find((issue) => issue.id === issueFilter);
		if (issueMatchesFilter) {
			activeIssue = issueMatchesFilter.id;
		}
	}

	if (isDefined(activeIssue)) {
		const chain = getSpecificIssueLinks(activeIssue, internalIncomingLinks, internalOutgoingLinks);

		issuesToHighlight = new Set([...chain, activeIssue]);
	}

	// using a set to avoid duplicate issues in the "Issues without parent / team" group
	const otherIssues = new Set();

	Object.keys(internalOutgoingLinks).forEach((key) => {
		const links = internalOutgoingLinks[key];
		if (links.length > 0) {
			hasDependency = true;
		}

		links.forEach((link) => {
			const sourceIssue = issueMap[link.sourceItemKey];
			const targetIssue = issueMap[link.targetItemKey];

			if (sourceIssue && targetIssue) {
				// Project filter
				if (
					Array.isArray(projectFilter) &&
					projectFilter.length > 0 &&
					!(
						projectFilter.includes(sourceIssue.project.toString()) ||
						projectFilter.includes(targetIssue.project.toString())
					)
				) {
					return;
				}

				// Issue Link Type filter
				if (
					Array.isArray(issueLinkTypeFilter) &&
					issueLinkTypeFilter.length > 0 &&
					!issueLinkTypeFilter.includes(link.type.toString())
				) {
					return;
				}

				// Sprint filter
				if (Array.isArray(sprintFilter) && sprintFilter.length > 0) {
					const filterSprints = new Set(sprintFilter);
					if (
						!(
							matchesSprintFilter(sourceIssue, filterSprints) ||
							matchesSprintFilter(targetIssue, filterSprints)
						)
					) {
						return;
					}
				}
			}

			let sourceItemKey = null;
			let targetItemKey = null;
			let isSourceRolledUp = false;
			let isTargetRolledUp = false;

			if (rollupBy !== 'team') {
				sourceItemKey = link.sourceItemKey;
				targetItemKey = link.targetItemKey;

				if (sourceIssue.level < rollupHierarchyLevel && parentsAndDescendant[link.sourceItemKey]) {
					const ancestors = parentsAndDescendant[link.sourceItemKey].ancestors;
					const ancestorMatchesLevel = ancestors.find(
						(ancestor) => ancestor.level === rollupHierarchyLevel,
					);
					isSourceRolledUp = true;

					if (ancestorMatchesLevel) {
						sourceItemKey = ancestorMatchesLevel.id;
					} else {
						otherIssues.add(sourceItemKey);
						sourceItemKey = 'other';
					}
				}

				if (targetIssue.level < rollupHierarchyLevel && parentsAndDescendant[link.targetItemKey]) {
					const ancestors = parentsAndDescendant[link.targetItemKey].ancestors;
					const ancestorMatchesLevel = ancestors.find(
						(ancestor) => ancestor.level === rollupHierarchyLevel,
					);
					isTargetRolledUp = true;
					if (ancestorMatchesLevel) {
						targetItemKey = ancestorMatchesLevel.id;
					} else {
						otherIssues.add(targetItemKey);
						targetItemKey = 'other';
					}
				}
			}

			if (rollupBy === 'team') {
				if (!isDefined(sourceIssue.team)) {
					otherIssues.add(sourceIssue.id);
					sourceItemKey = 'other';
				} else {
					sourceItemKey = sourceIssue.team;
				}

				if (!isDefined(targetIssue.team)) {
					otherIssues.add(targetIssue.id);
					targetItemKey = 'other';
				} else {
					targetItemKey = targetIssue.team;
				}

				isSourceRolledUp = true;
				isTargetRolledUp = true;
			}

			if (
				isDefined(issueFilter) &&
				(!issuesToHighlight.has(sourceItemKey) || !issuesToHighlight.has(targetItemKey))
			) {
				return;
			}

			if (isDefined(sourceItemKey) && !results[sourceItemKey]) {
				results[sourceItemKey] = {};
			}

			if (
				isDefined(sourceItemKey) &&
				isDefined(targetItemKey) &&
				!results[sourceItemKey][targetItemKey]
			) {
				results[sourceItemKey][targetItemKey] = {
					type: rollupBy === 'team' ? 'team' : 'issue',
					links: [],
					highlight: false,
					rollup: false,
				};
			}

			if (
				isDefined(sourceItemKey) &&
				isDefined(targetItemKey) &&
				results[sourceItemKey] &&
				results[sourceItemKey][targetItemKey]
			) {
				results[sourceItemKey][targetItemKey].links.push(link);

				if (isTargetRolledUp && isSourceRolledUp) {
					results[sourceItemKey][targetItemKey].rollup = true;
				}

				if (issuesToHighlight.has(sourceItemKey) && issuesToHighlight.has(targetItemKey)) {
					results[sourceItemKey][targetItemKey].highlight = true;
				}
			}
		});
	});

	return {
		links: results,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		otherIssues: [...otherIssues] as string[],
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		activeIssue: activeIssue as string,
		hasDependency,
	};
};

export const getRolledUpIssuesLinks = createSelector(
	[
		getInternalOutgoingLinks,
		getInternalIncomingLinks,
		getWholeHierarchyParentAndDescendant,
		getDerivedRollupBy,
		getDerivedRollupHierarchyLevel,
		getIssueMapById,
		getIssueInFocus,
		getIssueFilterSafe,
		getIssues,
		getProjectFilter,
		getIssueLinkTypeFilter,
		getSprintFilter,
	],
	getRolledUpIssuesLinksPure,
);

const getShowResetPure = (
	groupBy: GroupBy | null,
	rollupBy: string | null,
	issueFilter: string | null,
	projectFilter: string[] | null,
	issueLinkTypeFilter: string[] | null,
	sprintFilter: string[] | null,
) =>
	(isDefined(rollupBy) && rollupBy !== ROLLUP_BY_OPTION.NONE) ||
	(isDefined(groupBy) && groupBy !== GroupBy.NONE) ||
	(isDefined(issueFilter) && issueFilter !== '') ||
	(Array.isArray(projectFilter) && projectFilter.length > 0) ||
	(Array.isArray(issueLinkTypeFilter) && issueLinkTypeFilter.length > 0) ||
	(Array.isArray(sprintFilter) && sprintFilter.length > 0);

export const getShowReset = createSelector(
	[
		getGroupBy,
		getDerivedRollupBy,
		getIssueFilter,
		getProjectFilter,
		getIssueLinkTypeFilter,
		getSprintFilter,
	],
	getShowResetPure,
);

export const getUniqueFilterSprints = createSelector(
	[getSprintFilter],
	(sprintIds: string[] | null) => new Set(isDefined(sprintIds) ? sprintIds : []),
);

export const getSprintStatesCategories = createSelector(
	[getSprintsIdsByState, getExternalSprintsIds, getUniqueFilterSprints],
	getSprintStatesCategoriesPure,
);

const getExternalSprintsForDependencyReportPure = (externalSprints: Sprint[]) => {
	const _externalSprints = externalSprints;
	_externalSprints.forEach((_, index) => {
		_externalSprints[index].state = EXTERNAL_SPRINT;
	});
	return _externalSprints;
};

export const getExternalSprintsForDependencyReport = createSelector(
	[getExternalSprints],
	getExternalSprintsForDependencyReportPure,
);

export const getShowAddDependency = (state: State) =>
	state.ui.Main.Tabs.Dependencies.AddDependency.show;

export const getAddDependencyFromIssueId = (state: State) =>
	state.ui.Main.Tabs.Dependencies.AddDependency.fromIssueId;

export const getAddDependencySettings = (state: State) =>
	state.ui.Main.Tabs.Dependencies.AddDependency;

export const getDependencyReportStatus = (state: State) =>
	state.ui.Main.Tabs.Dependencies.ReportState.status;
