.icon {
	width: var(--jpo-icon-size-medium);
	min-width: var(--jpo-icon-size-medium);
	height: var(--jpo-icon-size-medium);
	padding: 0;
	cursor: pointer;
	display: inline-block;
}

.toggle {
	outline: none;
	border: none;
	background-color: transparent;
	vertical-align: middle;
	margin-left: -7px;
	font-size: unset;
	color: var(--ds-text, var(--adg3-color-N900));
}

.label {
	line-height: 24px;
	vertical-align: middle;
	display: inline-block;
}

.label:hover {
	text-decoration: underline;
}

.multiple {
	cursor: pointer;
	color: var(--ds-text-brand, var(--adg3-color-B400));
	outline-offset: 0 !important;
}

.attribute-with-parenthetical {
	display: inline-flex;
	max-width: 100%;
	white-space: nowrap;
}

.ellipsed {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.parenthetical {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	flex: 1 1 auto;
	display: inline-flex;
	overflow: hidden;

	& > .ellipsed {
		flex: 1 1 auto;
	}
}
