import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Team } from '../../state/domain/teams/types.tsx';
import type { State } from '../../state/types.tsx';
import { getExistingTeams } from '../teams/index.tsx';

export const getPersons = (state: State) => state.domain.persons;

export const getPersonByItemKey = (persons: Person[], itemKey: string) =>
	persons.find((person) => person.itemKey === itemKey);

export const getTeamsPersonsPure = (teams: Team[], persons: Person[]): Person[] => {
	const resources = teams.flatMap((team) => team.resources);
	const personItemKeys = new Set(resources.map((resource) => resource.personItemKey));

	return persons.filter((person) => personItemKeys.has(person.itemKey));
};

export const getTeamsPersons = createSelector<State, Team[], Person[], Person[]>(
	[getExistingTeams, getPersons],
	getTeamsPersonsPure,
);

export const getTeamsAssigneeAccountIdsPure = (persons: Person[]): string[] =>
	persons.reduce<string[]>((acc, person) => {
		if (person.jiraUser) {
			acc.push(person.jiraUser.accountId);
		}
		return acc;
	}, []);

export const getTeamsAssigneeAccountIds = createSelector<State, Person[], string[]>(
	[getTeamsPersons],
	getTeamsAssigneeAccountIdsPure,
);
