import React, { useMemo } from 'react';
import { xcss } from '@atlaskit/primitives';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useCellOld } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import { useViewport } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';
import { ResizeHandle as TreegridResizeHandle } from '@atlassian/jira-portfolio-3-treegrid/src/ui/resize-handle/index.tsx';
import { useIsColumnDragging } from '../draggable-column/hooks/use-is-column-dragging.tsx';

const ResizeHandleNew = () => {
	const [viewport] = useViewport();
	let row;
	if (fg('plans_performance_improvements_3')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ row } = useRow());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ row }] = useCellOld();
	}
	const style = useMemo(() => {
		switch (row) {
			case 0:
				return { height: viewport.height };
			case 1:
				return { height: viewport.height - 50 };
			default:
				return undefined;
		}
	}, [viewport.height, row]);

	// Disable pointer events on the resize handle when a column is being dragged, so that the handle doesn't interfere
	// with the drag interaction.
	const isColumnDragging = useIsColumnDragging();

	return (
		<TreegridResizeHandle
			xcss={[resizeHandleStyles, isColumnDragging && disabledStyles]}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Style need to be passed
			style={style}
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.utils.resize-handle.separator-fields-column"
		/>
	);
};

const ResizeHandleOld = () => {
	const [viewport] = useViewport();
	let row;
	if (fg('plans_performance_improvements')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ row } = useRow());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ row }] = useCellOld();
	}
	const style = useMemo(() => {
		switch (row) {
			case 0:
				return { height: viewport.height };
			case 1:
				return { height: viewport.height - 50 };
			default:
				return undefined;
		}
	}, [viewport.height, row]);

	return (
		<TreegridResizeHandle
			xcss={resizeHandleStyles}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Style need to be passed
			style={style}
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.utils.resize-handle.separator-fields-column"
		/>
	);
};

export const ResizeHandle = componentWithCondition(
	() => fg('plan_timeline_drag_and_drop_field_columns'),
	ResizeHandleNew,
	ResizeHandleOld,
);

const resizeHandleStyles = xcss({
	position: 'absolute',
	top: 'space.0',
	right: '-0.5px' /* Make sure it overlap the cell right border */,
	// @ts-expect-error - TS2322: Type number is not assignable to type
	zIndex: 1,
});

const disabledStyles = xcss({
	pointerEvents: 'none',
});
