import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	componentsLabel: {
		id: 'portfolio-3-portfolio.common.cells.components.components-label',
		defaultMessage: 'Choose a component',
		description: 'Label for unselected components cell',
	},
	noComponents: {
		id: 'portfolio-3-portfolio.common.cells.components.no-components',
		defaultMessage: 'There are no components in this project',
		description: 'Message to show when there is no components configured',
	},
	noComponentsLeft: {
		id: 'portfolio-3-portfolio.common.cells.components.no-components-left',
		defaultMessage: 'No available components left',
		description: 'Message to show when all components are already used up',
	},
	noMatch: {
		id: 'portfolio-3-portfolio.common.cells.components.no-match',
		defaultMessage: 'No results match your search',
		description: 'Message to show when there is no components matching search query',
	},
});
