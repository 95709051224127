import { getCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import { getIssuePrioritiesIdList } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-priorities/index.tsx';
import {
	getDateConfiguration,
	getPlanningUnit,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import { getVisualisationSorting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, StateProps>({
	customFields: getCustomFields,
	sorting: getVisualisationSorting,
	dateConfiguration: getDateConfiguration,
	planningUnit: getPlanningUnit,
	issuePrioritiesIdList: getIssuePrioritiesIdList,
});
