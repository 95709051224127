import type { ComponentPropsWithoutRef } from 'react';
import { connect, type ConnectedComponent } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query.tsx';
import View from './view.tsx';

// typecast is required to avoid error "TS2742: The inferred type of 'default' cannot be named without a reference to '../../../../../../../../../../../../../../../platform/packages/design-system/popper/node_modules/@popperjs/core'."
const DependenciesFlyoutComponent: ConnectedComponent<
	typeof View,
	ComponentPropsWithoutRef<typeof View>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
> = connect(mapStateToProps, mapDispatchToProps)(View) as any;

export default DependenciesFlyoutComponent;
