import {
	isOptimizedMode,
	isReadOnly,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getOnlyEnabledIssueId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/index.tsx';
import { getWarnings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/warnings/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export default createStructuredSelector<State, OwnProps, StateProps>({
	isReadOnly,
	warnings: getWarnings,
	showOptimizations: isOptimizedMode,
	onlyEnabledIssueId: getOnlyEnabledIssueId,
});
