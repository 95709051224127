import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';

export const getIssueIdToProjectPure = (
	issues: Issue[],
	projectById: Record<number, Project>,
): Record<string, Project> =>
	issues.reduce<Record<string, Project>>((acc, { id, project: projectId }) => {
		const project = projectById[projectId];
		if (project !== undefined) {
			acc[id] = project;
		}
		return acc;
	}, {});

export const getIsSomeSelectedIssuesFromTMPPure = (
	selectedIssues: Issue[],
	issueIdToProject: Record<string, Project>,
): boolean => selectedIssues.some(({ id }) => issueIdToProject[id]?.isSimplified === true);

export const getIsSelectedIssuesFromMultipleProjectsPure = (selectedIssues: Issue[]): boolean =>
	new Set(selectedIssues.map(({ project }) => project)).size > 1;
