import React from 'react';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { token } from '@atlaskit/tokens';
import { AkFlag as Flag } from '@atlassian/jira-flags';
import { FormattedMessage } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import {
	MODAL,
	fireScreenAnalytics,
	ContextualAnalyticsData,
	MountEvent,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';

export const getJSWNoAccessErrorFlag = () => (
	<Flag
		key="jsw-no-access-error"
		id="jsw-no-access-error"
		title={
			<ContextualAnalyticsData sourceType={MODAL} sourceName="nswNoAccessError">
				<MountEvent onMount={fireScreenAnalytics} />
				<FormattedMessage {...messages.title} />
			</ContextualAnalyticsData>
		}
		description={<FormattedMessage {...messages.descriptionSpork} />}
		icon={<ErrorIcon label="" primaryColor={token('color.icon.danger', colors.R300)} />}
	/>
);
