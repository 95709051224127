.issueLength {
	user-select: none;
	white-space: nowrap;
	background-color: var(--ds-surface-sunken, var(--adg3-color-N20));
	border-radius: 3px;
	border: 1px solid var(--ds-surface-sunken, var(--adg3-color-N20)); /* Same as background color */
	padding: 0 5px;
}

.withSprint {
	display: flex;
}

.sprintDetails {
	display: flex;
}

.separator {
	background-color: var(--ds-background-neutral-bold, var(--adg3-color-N600));
	border-radius: 50%;
	height: 5px;
	width: 5px;
	display: flex;
	align-self: center;
	margin-left: 5px;
	margin-right: 5px;
}
