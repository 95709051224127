import performance from '../performance-measurement/index.tsx';
import type { Timer as TimerType } from './types.tsx';

export default class Timer {
	timer: TimerType;

	constructor() {
		this.timer = {
			startTime: undefined,
			endTime: undefined,
		};
	}

	start(): void {
		if (this.isStopped()) {
			throw new Error('Timer has already been stopped');
		}

		if (this.isStarted()) {
			throw new Error('Timer has already been started');
		}

		this.timer.startTime = performance.now();
	}

	stop(): void {
		if (!this.isStarted()) {
			throw new Error("Timer hasn't been started");
		}

		if (this.isStopped()) {
			throw new Error('Timer has already been stopped');
		}

		this.timer.endTime = performance.now();
	}

	duration(): number {
		// Checking properties directly here so that Flow can refine on the final calculation.
		if (this.timer.startTime === undefined) {
			throw new Error("Timer hasn't been started");
		}

		if (this.timer.endTime === undefined) {
			throw new Error("Timer hasn't been stopped");
		}

		return this.timer.endTime - this.timer.startTime;
	}

	isStarted(): boolean {
		return this.timer.startTime !== undefined;
	}

	isStopped(): boolean {
		return this.timer.endTime !== undefined;
	}
}
