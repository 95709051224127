import { expandIssueWithAncestors } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/scope/index.tsx';
import {
	searchIconClick,
	setActiveSearchResultIndex,
	setSearchQuery,
	startedSearchResultNavigation,
	toggleIssueSearch,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/header/search/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	expandHierarchy: () => {
		dispatch(expandIssueWithAncestors());
	},
	searchIconClick: () => {
		dispatch(searchIconClick());
	},
	setActiveSearchResultIndex: (payload: number) => {
		dispatch(setActiveSearchResultIndex(payload));
	},
	setSearchQuery: (query: string) => {
		dispatch(setSearchQuery(query));
	},
	startedSearchResultNavigation: () => {
		dispatch(startedSearchResultNavigation());
	},
	toggleIssueSearch: (isOpen: boolean) => {
		dispatch(toggleIssueSearch(isOpen));
	},
});

export default mapDispatchToProps;
