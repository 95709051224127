import { CttOnboardingStage, type State } from './types.tsx';

const assertUnreachable = (_: never): never => {
	throw new Error("Didn't expect to get here");
};

export const selectCttOnboardingTour = ({ cttOnboarding, isTakingOnboardingTour }: State) => {
	const getVisibility = () => {
		if (!cttOnboarding) return false;
		try {
			switch (cttOnboarding.stage) {
				case CttOnboardingStage.CREATE_INITATIVE:
				case CttOnboardingStage.NAVIGATE_ISSUE_SOURCES:
				case CttOnboardingStage.SHOW_ISSUE_SOURCES:
				case CttOnboardingStage.FINISH_FLAG:
				case CttOnboardingStage.NAVIGATE_PLAN:
				case CttOnboardingStage.EPIC_REPARENT:
				case CttOnboardingStage.REVIEW_CHANGES:
					return true;
				case CttOnboardingStage.INPUT_TITLE:
				case CttOnboardingStage.INPUT_EPIC_REPARENT:
				case CttOnboardingStage.CLOSED:
					return false;
				default:
					assertUnreachable(cttOnboarding.stage);
					return false;
			}
		} catch (e) {
			return false;
		}
	};

	return {
		stage: cttOnboarding?.stage,
		planId: cttOnboarding?.planId,
		visible: getVisibility(),
		isTakingOnboardingTour: isTakingOnboardingTour ?? false,
	};
};

export const selectCttSpotlight = ({ spotlight }: State) => spotlight;
