import { createMigrate, incremental } from '../common/migrate.tsx';
import type { ViewModeStateV0 } from './types.tsx';

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: ViewModeStateV0): ViewModeStateV0 {
				return prevState;
			},
		},
		{ prefix: 'viewMode', from: 'V0' },
	),
);
