.issuesWithoutParentHeader {
	padding-left: 10px;
	color: var(--ds-text-subtlest, var(--adg3-color-subtleText-light));
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	height: 40px;
	box-sizing: border-box;
	margin: 0;
	background-color: var(--ds-surface, var(--adg3-color-N0));
	display: flex;
	align-items: center;
}

.issuesWithoutParentText {
	padding: 12px 0 12px 0px;
	display: flex;
	align-items: center;
}

.toggleButton {
	cursor: pointer;
	outline: none;
}

.toggleButton h2 {
	font-size: 1em;
	line-height: 1.1428571428571428;
	font-weight: var(--ds-font-weight-semibold, 600);
	letter-spacing: -0.003em;
	padding-left: 10px;
	color: var(--ds-text-subtlest, var(--adg3-color-subtleText-light));
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	height: 40px;
	box-sizing: border-box;
	margin: 0;
	background-color: var(--ds-surface, var(--adg3-color-N0));
	display: flex;
	align-items: center;
}
