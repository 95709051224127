import * as R from 'ramda';
import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/index.tsx';
import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { PROJECT_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';

export default createSelector(
	[getProjects, getFilters],
	(projects, { [PROJECT_FILTER_ID]: { id, value } }) => ({
		id,
		value,
		projects: R.sortBy(R.compose(R.toLower, R.prop('name')))(projects),
	}),
);
