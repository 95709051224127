import { getFilterableCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import { getIssuePrioritiesIdList } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-priorities/index.tsx';
import { getViewMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import {
	getColorByValue,
	getColourMaps,
	getColourPriorities,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export default createStructuredSelector<State, OwnProps, StateProps>({
	colourByValue: getColorByValue,
	colourMaps: getColourMaps,
	colourPriorities: getColourPriorities,
	filterableCustomFields: getFilterableCustomFields,
	issuePrioritiesIdList: getIssuePrioritiesIdList,
	viewMode: getViewMode,
});
