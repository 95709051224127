import {
	type IssueGoalsActions,
	FETCH_GOALS_START,
	FETCH_GOALS_SUCCESS,
	FETCH_GOALS_FAIL,
	SEARCH_GOALS_START,
	SEARCH_GOALS_SUCCESS,
	SEARCH_GOALS_FAIL,
} from './actions.tsx';
import type { GoalsState } from './types.tsx';

const initialState: GoalsState = {
	lazyGoalsByARI: {},
	searchesInProgress: 0,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: GoalsState = initialState, action: IssueGoalsActions): GoalsState => {
	switch (action.type) {
		case FETCH_GOALS_START:
			return {
				...state,
				lazyGoalsByARI: {
					// Putting the new lazy goals first to avoid overwriting the existing ones.
					...action.payload,
					...state.lazyGoalsByARI,
				},
			};

		case FETCH_GOALS_SUCCESS:
			return {
				...state,
				lazyGoalsByARI: {
					...state.lazyGoalsByARI,
					...action.payload,
				},
			};

		case FETCH_GOALS_FAIL:
			return {
				...state,
				lazyGoalsByARI: {
					...state.lazyGoalsByARI,
					...action.payload,
				},
			};

		case SEARCH_GOALS_START:
			return {
				...state,
				searchesInProgress: state.searchesInProgress + 1,
			};

		case SEARCH_GOALS_SUCCESS:
			return {
				...state,
				lazyGoalsByARI: {
					...state.lazyGoalsByARI,
					...action.payload,
				},
				searchesInProgress: Math.min(state.searchesInProgress - 1, 0),
			};

		case SEARCH_GOALS_FAIL:
			return {
				...state,
				searchesInProgress: Math.min(state.searchesInProgress - 1, 0),
			};

		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
