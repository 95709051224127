/* tag name is provided to make selector more specific than one which sets initial background-color */
div.isDragging {
	background-color: var(--ds-background-selected, var(--adg3-color-B50));
	border-color: var(--ds-border, var(--adg3-color-N60A));
	border-width: 0 0 1px;
	box-shadow: 0 4px 8px -2px var(--ds-shadow-overlay, var(--adg3-color-N50A));
	z-index: 800;
}

div.isDragging.isInvalid {
	background-color: var(--ds-background-danger, var(--adg3-color-R50));
}

.drop-sibling {
	background-color: var(--ds-background-selected-hovered, var(--adg3-color-B75));
}

.drop-invalid-sibling {
	background-color: var(--ds-background-danger-hovered, var(--adg3-color-R75));
}

.drop-container {
	transition-property: background;
	transition-duration: 500ms;
}

.drop-child-odd {
	background: linear-gradient(
		0.25turn,
		var(--ds-background-neutral-hovered, var(--adg3-color-N30)),
		var(--ds-background-neutral-hovered, var(--adg3-color-N30)) 16px,
		var(--ds-background-selected-hovered, var(--adg3-color-B75)) 16px,
		var(--ds-background-selected-hovered, var(--adg3-color-B75))
	);
}

.drop-child-even {
	background: linear-gradient(
		0.25turn,
		var(--ds-background-inverse-subtle, var(--adg3-color-N0)),
		var(--ds-background-inverse-subtle, var(--adg3-color-N0)) 16px,
		var(--ds-background-selected-hovered, var(--adg3-color-B75)) 16px,
		var(--ds-background-selected-hovered, var(--adg3-color-B75))
	);
}

.drop-invalid-child-odd {
	background: linear-gradient(
		0.25turn,
		var(--ds-background-neutral-hovered, var(--adg3-color-N30)),
		var(--ds-background-neutral-hovered, var(--adg3-color-N30)) 16px,
		var(--ds-background-danger-hovered, var(--adg3-color-R75)) 16px,
		var(--ds-background-danger-hovered, var(--adg3-color-R75))
	);
}

.drop-invalid-child-even {
	background: linear-gradient(
		0.25turn,
		var(--ds-background-inverse-subtle, var(--adg3-color-N0)),
		var(--ds-background-inverse-subtle, var(--adg3-color-N0)) 16px,
		var(--ds-background-danger-hovered, var(--adg3-color-R75)) 16px,
		var(--ds-background-danger-hovered, var(--adg3-color-R75))
	);
}

.unselected {
	user-select: none;
}

.blankRow {
	height: 100%;
	background-color: var(--ds-surface, var(--adg3-color-N0));
}

.group-team-avatar {
	width: 24px;
	display: inline-block;
}

.row-with-indentation {
	padding-left: 40px;
	background-color: var(--ds-surface, var(--adg3-color-N0));
}
