import React from 'react';
import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import MenuContent from '../menu-content/view.tsx';
import type { MenuProps } from '../nested-menu/types.tsx';
import ProjectOptionsProvider from '../options-providers/project/index.tsx';
import type { Option } from '../options-providers/types.tsx';
import type { CreateIssueOptions } from '../types.tsx';

const ProjectMenu = ({ renderOption }: MenuProps<CreateIssueOptions, Option<GroupCombination>>) => (
	<ProjectOptionsProvider>
		{(result) => (
			<MenuContent
				renderOption={renderOption}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.project-menu"
				{...result}
			/>
		)}
	</ProjectOptionsProvider>
);

export default ProjectMenu;
