import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query.tsx';
import SelectableIssue from './view.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { TargetTypes } from '../../../../../../../state/ui/main/tabs/roadmap/scope/issues/selectable-issue/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { TargetType } from '../../../../../../../state/ui/main/tabs/roadmap/scope/issues/selectable-issue/types.tsx';

export default connect(mapStateToProps, mapDispatchToProps)(SelectableIssue);
