import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	blankSummaryInputErrorMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.blank-summary-input-error-message',
		defaultMessage: 'You must specify a summary of the issue.',
		description: 'Error message displayed when the issue summary is blank',
	},
	invalidSummaryInput: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.invalid-summary-input',
		defaultMessage: 'Summary must be less than 256 characters.',
		description:
			'Error message displayed when the user enters an issue summary longer than 255 characters',
	},
	summaryPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.summary-placeholder',
		defaultMessage: 'Issue summary ...',
		description: 'Placeholder message for empty issue summary input',
	},
	descriptionLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.description-label',
		defaultMessage: 'Description',
		description: 'Label for the description field',
	},
	noDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.no-description',
		defaultMessage: 'Add a description...',
		description:
			'The default message to be displayed when there is no description and user can click to add one',
	},
	noDescriptionReadOnlyMode: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.no-description-read-only-mode',
		defaultMessage: 'No description',
		description:
			'The default message to be displayed when there is no description and the user CANNOT add one',
	},
	descriptionParsingError: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue-modal.description-parsing-error',
		defaultMessage: 'There was an error loading the description for this issue.',
		description: 'Message displayed if the issue description failed to load or be parsed.',
	},
});
