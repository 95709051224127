import {
	getMode,
	isReadOnly,
	isArchived,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import Toolbar from './toolbar/index.tsx';
import type { StateProps } from './types.tsx';
import { PageHeader } from './view.tsx';

export const getPlanTitle = (state: State) => getPlan(state).title;

const mapStateToProps = createStructuredSelector<State, {}, StateProps>({
	mode: getMode,
	isReadOnly,
	isArchived,
	plan: getPlan,
});

export default connect(mapStateToProps, {})(PageHeader);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { Toolbar };
