import React from 'react';
import * as R from 'ramda';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { NO_CUSTOM_FIELD_VALUE_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/custom-field-filter/index.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import ClearFilterButton from '../../common/clear-filter/index.tsx';
import FilterText from '../../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../../common/index.tsx';
import NoMatchFound from '../../common/no-match-text/index.tsx';
import TriggerButton from '../../common/trigger-button/index.tsx';
import messages from '../messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '../styles.module.css';
import type { Props } from './types.tsx';

const Label = ({
	value = [],
	onOpenChange,
	isOpen,
	customField,
	searchQuery,
	onQueryChange,
	onRequestClear,
	options = [],
	onChange,
}: Props) => {
	const intl = useIntl();

	const constructFilterText = () => {
		const noValueOption = R.contains(NO_CUSTOM_FIELD_VALUE_ID, value)
			? [intl.formatMessage(messages.noValue)]
			: [];
		const optionsText = options.filter((label) => R.contains(label, value));
		return noValueOption.concat(optionsText).join(', ');
	};

	const renderFilterText = () => {
		const filterText = constructFilterText();
		return value.length > 0 ? (
			<FilterText text={filterText} />
		) : (
			<FormattedMessage {...messages.emptyPlaceholder} />
		);
	};

	const isOptionSelected = (optionId: string) => R.contains(optionId, value);

	const handleOptionClick = (label: string): void => {
		const filtered = value.filter((opt) => opt !== label);

		if (isOptionSelected(label)) {
			onChange(filtered);
		} else {
			onChange([...filtered, label]);
		}
	};

	const renderOptions = () => {
		const filteredOptions = R.sort(
			(a, b) => a.localeCompare(b),
			options.filter((option) => option.toLowerCase().includes(searchQuery.toLowerCase())),
		);

		return (
			<>
				{filteredOptions.length > 0 && !searchQuery ? (
					<DialogMenuItem key={NO_CUSTOM_FIELD_VALUE_ID}>
						<Checkbox
							id={`${NO_CUSTOM_FIELD_VALUE_ID}`}
							key={NO_CUSTOM_FIELD_VALUE_ID}
							isChecked={isOptionSelected(`${NO_CUSTOM_FIELD_VALUE_ID}`)}
							onChange={() => handleOptionClick(`${NO_CUSTOM_FIELD_VALUE_ID}`)}
							label={
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<span className={styles.optionLabel}>{intl.formatMessage(messages.noValue)}</span>
							}
						/>
					</DialogMenuItem>
				) : null}
				{filteredOptions.map((label) => (
					<DialogMenuItem key={label}>
						<Checkbox
							id={label}
							key={label}
							isChecked={isOptionSelected(label)}
							onChange={() => handleOptionClick(label)}
							label={
								<EllipsedWithTooltip id={label} content={label}>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<span className={styles.optionLabel}>{label}</span>
								</EllipsedWithTooltip>
							}
						/>
					</DialogMenuItem>
				))}
				{filteredOptions.length === 0 && <NoMatchFound />}
			</>
		);
	};

	return (
		<InlineDialog
			noPaddings
			maxWidth={FILTER_MAX_WIDTH}
			minWidth={FILTER_WIDTH}
			onClose={onOpenChange}
			isOpen={isOpen}
			position="bottom left"
			content={
				<DialogMenuContainer>
					<ClearFilterButton isVisible={!!value.length} onClearClick={onRequestClear} />
					<SearchField
						placeholder={intl.formatMessage(messages.searchPlaceholder, {
							fieldName: customField.title,
						})}
						searchQuery={searchQuery}
						onQueryChange={onQueryChange}
					/>
					{renderOptions()}
				</DialogMenuContainer>
			}
		>
			<TriggerButton
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				triggerButtonText={renderFilterText()}
				testId={`portfolio-3-portfolio.app-simple-plans.top.filter-bar.custom-field-filter.label.trigger-btn.${customField.id}`}
				ariaLabel={customField.title}
			/>
		</InlineDialog>
	);
};

export default Label;
