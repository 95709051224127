import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import { getTeamPermissions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/permissions/index.tsx';
import {
	getTeamById,
	getTeams,
	getDialogMode,
	getEditedTeam,
	getAddedTWPInfo,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';

export const getRequestStatus = (state: State) =>
	state.ui.Main.Tabs.Teams.TeamsList.isProcessingRequest;

const isCreateTwpFormLoading = (state: State) =>
	state.ui.Main.Tabs.Teams.CreateTeam.isCreateTwpFormLoading;

const getAddedTWPInfoMemoized = () => createSelector(getAddedTWPInfo, (res) => res);
const mapStateToPropsFactory = () => {
	const getTeamByIdFactory = memoizeOne(
		(state: State) => (teamId: string) => getTeamById(state, teamId),
	);
	const getAddedTWPInfoInstance = getAddedTWPInfoMemoized();
	return (state: State) => ({
		dialogMode: getDialogMode(state),
		editedTeam: getEditedTeam(state),
		getTeamById: getTeamByIdFactory(state),
		isProcessingRequest: getRequestStatus(state),
		teams: getTeams(state),
		addedTWPInfo: getAddedTWPInfoInstance(state),
		isCreateTwpFormLoading: isCreateTwpFormLoading(state),
		teamPermissions: getTeamPermissions(state),
	});
};

const mapStateToProps = mapStateToPropsFactory;
export default mapStateToProps;
