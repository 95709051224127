import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	arrowRightLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.arrow-right-label',
		defaultMessage: 'Arrow right icon',
		description: 'Label for arrow right icon',
	},
	setHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.set-header',
		defaultMessage: 'Update labels',
		description: 'Modal dialog header for dialog labels',
	},
	variousValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.various-values',
		defaultMessage: 'Various labels',
		description: 'Label for various label values',
	},
	noValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.no-values',
		defaultMessage: 'No labels',
		description: 'Label for no label values',
	},
});
