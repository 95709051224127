import * as R from 'ramda';
import { getEstimateFromHistoryIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/breakdown/index.tsx';
import type { HistoryIssueTotalEstimationByParentIdMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/breakdown/types.tsx';
import { getIssueStatusById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/index.tsx';
import type { IssueStatusesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/types.tsx';
import { getIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import {
	getDescendantIdsByParent,
	getHistoryIssues,
	isLoadingHistory,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import type {
	IssueIdToDescendantsIdsMap,
	IssueMap,
	IssuesStatusBreakdown,
	IssuesBreakdownByEstimate,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/types.tsx';
import { getPlanningUnit } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getIssueMapById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import { getChildrenIdsByParent } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/issues-tree.tsx';
import { getJiraHoursPerDay } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import type { HistoryIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/history-issues/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import {
	getIssuesStatusBreakdown,
	getEstimateBreakdown,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/issue-helper.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import { ISSUE_STATUS_CATEGORIES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { StateProps } from './types.tsx';

const { TODO, INPROGRESS, DONE } = ISSUE_STATUS_CATEGORIES;

export const getIssueIdsWithDoneFromHistoryIssues = (
	parentIssueId: string,
	historyIssues: HistoryIssue[],
	issueMap: IssueMap,
): string[] =>
	historyIssues
		.filter((historyIssue) => historyIssue.parent === parentIssueId)
		// we ignore issues that are in the plan
		.filter((historyIssue) => !issueMap[historyIssue.id])
		.map((issue) => issue.id);

export const getIssuesIdsWithDone = (issues: Issue[], issueStatuses: IssueStatusesById): string[] =>
	issues
		.filter((issue) => {
			const categoryId = R.path([issue.status || '', 'categoryId'], issueStatuses);
			return categoryId === DONE;
		})
		.map((issue) => issue.id);

export const getDoneIssueCount = (
	issues: Issue[],
	issueStatuses: IssueStatusesById,
	parentIssueId: string,
	historyIssues: HistoryIssue[],
	issueMap: IssueMap,
) => {
	const issuesIdsInDoneStatus =
		issues.length > 0 ? getIssuesIdsWithDone(issues, issueStatuses) : [];
	const issueIdsInDoneFromHistory = getIssueIdsWithDoneFromHistoryIssues(
		parentIssueId,
		historyIssues,
		issueMap,
	);
	const uniqueIssueIdsInDoneStatus = new Set([
		...issuesIdsInDoneStatus,
		...issueIdsInDoneFromHistory,
	]);
	return uniqueIssueIdsInDoneStatus.size;
};

export const getBreakdown = (
	issue: ScopeIssue,
	issueIdToChildIssuesMap: IssueIdToDescendantsIdsMap,
	issueStatuses: IssueStatusesById,
	issueMap: IssueMap,
	historyIssues: HistoryIssue[],
): IssuesStatusBreakdown => {
	let todoCount = 0;
	let inProgressCount = 0;
	let doneCount = 0;

	const { id } = issue;
	const childIds = issueIdToChildIssuesMap[id];

	// We need to defensively code against there being no childIds array defined in the case where
	// a new issue is being created (because this will not exist in the map)...
	let childIssues: Array<Issue> = [];
	if (childIds) {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		childIssues = childIds.map((id) => issueMap[id]);
		const breakdown = getIssuesStatusBreakdown(childIssues, issueStatuses);
		todoCount += breakdown.byCategoryId[String(TODO)];
		inProgressCount += breakdown.byCategoryId[String(INPROGRESS)];
	}
	// we ignore the done count returned by the status breakdown method
	// and re-calculate the done count including the history issues as well
	doneCount = getDoneIssueCount(childIssues, issueStatuses, id, historyIssues, issueMap);

	return {
		byCategoryId: {
			[String(TODO)]: todoCount,
			[String(INPROGRESS)]: inProgressCount,
			[String(DONE)]: doneCount,
		},
		total: todoCount + inProgressCount + doneCount,
	};
};

export const getBreakdownByEstimate = (
	issue: Issue | ScopeIssue,
	issueIdToDescendantsIdsMap: IssueIdToDescendantsIdsMap,
	issueStatuses: IssueStatusesById,
	issueMap: IssueMap,
	planningUnit: string,
	workingHours: number,
	estimateFromHistoryIssues: HistoryIssueTotalEstimationByParentIdMap,
): IssuesBreakdownByEstimate => {
	const { id } = issue;
	const descendantIds = issueIdToDescendantsIdsMap[id] || [];
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const childIssues = descendantIds.map((id) => issueMap[id]);
	const estimateBreakdown = getEstimateBreakdown(
		issue,
		childIssues,
		issueStatuses,
		planningUnit,
		workingHours,
	);

	let doneEstimate = 0;
	let noOfDoneIssues = 0;
	let noOfUnestimatedInDone = 0;
	const estimateFromDoneIssues = estimateFromHistoryIssues[id];
	if (estimateFromDoneIssues) {
		doneEstimate = estimateFromDoneIssues.estimate;
		noOfDoneIssues = estimateFromDoneIssues.numberOfIssues;
		noOfUnestimatedInDone = estimateFromDoneIssues.unEstimatedCount;
	}

	return {
		...estimateBreakdown,
		unestimated: estimateBreakdown.unestimated + noOfUnestimatedInDone,
		byCategoryId: {
			...estimateBreakdown.byCategoryId,
			[String(DONE)]: estimateBreakdown.byCategoryId[String(DONE)] + doneEstimate,
		},
		total: estimateBreakdown.total + doneEstimate,
		// @ts-expect-error - TS2532 - Object is possibly 'undefined'.
		issueCount: estimateBreakdown.issueCount + noOfDoneIssues,
	};
};

export const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	issueIdToChildIdsMap: getChildrenIdsByParent(state),
	issueIdToDescendantsIdMap: getDescendantIdsByParent(state),
	estimateFromHistoryIssues: getEstimateFromHistoryIssues(state),
	issueStatuses: getIssueStatusById(state),
	issueMapById: getIssueMapById(state),
	historyIssues: getHistoryIssues(state),
	isLoading: isLoadingHistory(state),
	planningUnit: getPlanningUnit(state),
	workingHours: getJiraHoursPerDay(state),
	projects: getProjects(state),
	issueTypes: getIssueTypes(state),
});

export default mapStateToProps;
