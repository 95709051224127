import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-associated-issues/src/common/types.tsx';
import { IDEA_NAME } from './constants.tsx';

type Values = {
	[IDEA_NAME]: string[];
};

const getIdeaValues = (
	issueIds: string[],
	associatedIssues: Record<string, AssociatedIssue>,
): Values =>
	issueIds.reduce<Values>(
		(values, issueGoalARI) => {
			const issue = associatedIssues[issueGoalARI];
			if (issue) {
				values[IDEA_NAME].push(issue.summary);
			}
			return values;
		},
		{ [IDEA_NAME]: [] },
	);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { IDEA_NAME, getIdeaValues };
