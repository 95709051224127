/* eslint-disable max-classes-per-file */

import type { View } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/views/types.tsx';
import type { ViewUserInfo } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';

export class UpdateErrorNotExists {
	viewId: View['id'];

	viewName: View['name'];

	constructor({ viewId, viewName }: { viewId: View['id']; viewName: View['name'] }) {
		this.viewId = viewId;
		this.viewName = viewName;
	}
}

export class UpdateErrorConflicted {
	viewId: View['id'];

	viewName: View['name'];

	modifiedBy: ViewUserInfo;

	modifiedAt: Date;

	constructor({
		viewId,
		viewName,
		modifiedBy,
		modifiedAt,
	}: {
		viewId: View['id'];
		viewName: View['name'];
		modifiedBy: ViewUserInfo;
		modifiedAt: Date;
	}) {
		this.viewId = viewId;
		this.viewName = viewName;
		this.modifiedBy = modifiedBy;
		this.modifiedAt = modifiedAt;
	}
}

export class SaveAsErrorDuplicateView {
	viewId: View['id'];

	viewName: View['name'];

	newName: View['name'];

	constructor({ viewId, viewName, newName }: SaveAsErrorDuplicateView) {
		this.viewId = viewId;
		this.viewName = viewName;
		this.newName = newName;
	}
}

export class DeleteErrorDefaultView {
	viewId: View['id'];

	viewName: View['name'];

	constructor({ viewId, viewName }: DeleteErrorDefaultView) {
		this.viewId = viewId;
		this.viewName = viewName;
	}
}

export type Error =
	| UpdateErrorNotExists
	| UpdateErrorConflicted
	| SaveAsErrorDuplicateView
	| DeleteErrorDefaultView;

export type SaveViewAsDialogStatus = 'OPEN' | 'CLOSED' | 'SAVING';

export type State = {
	errors: Error[];
	saveViewAsDialogStatus: SaveViewAsDialogStatus;
};
