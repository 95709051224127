import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	defaultWarningHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.planned-capacities.default-warning-header',
		defaultMessage: 'Could not be committed for unknown reasons.',
		description: 'Message to show in the header for unknown commit failures',
	},
	defaultWarningContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.planned-capacities.default-warning-content',
		defaultMessage:
			'Sprint capacity could not be committed for unknown reasons. Please contact your administrator.',
		description: 'Message to show in the content for unknown commit failures',
	},
});

export const warning = {
	header: messages.defaultWarningHeader,
	message: messages.defaultWarningContent,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
	messageValues: {} as Record<any, any>,
} as const;

export default messages;
