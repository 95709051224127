import React, { type KeyboardEvent, type MouseEvent } from 'react';
import noop from 'lodash/noop';
import * as R from 'ramda';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import type { Props } from './types.tsx';

export default function QuickFilter({
	ariaLabel,
	children,
	clearable,
	clearFilter,
	clearText,
	filterValueFor,
	onFilterApply = noop,
	quickFilter,
	value: filter,
}: Props) {
	const currentValue = filterValueFor(filter.id);
	const hasSameValue = R.equals(currentValue, filter.value);

	if (hasSameValue && clearable === true) {
		const handleClick = () => clearFilter();

		return (
			<Button appearance="subtle-link" spacing="none" onClick={handleClick}>
				{clearText}
			</Button>
		);
	}

	const handleClick = (
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		_: KeyboardEvent<any> | MouseEvent<any>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		quickFilter(filter.id, filter.value);
		onFilterApply(analyticsEvent);
	};

	return (
		<Button appearance="link" spacing="none" onClick={handleClick} ariaLabel={ariaLabel}>
			{children}
		</Button>
	);
}
