import React, { useCallback } from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { GREY } from '@atlassian/jira-issue-epic-color/src/common/types.tsx';
import type {
	DependencyLine,
	DrawableLine,
} from '@atlassian/jira-portfolio-3-dependency-lines/src/common/types.tsx';
import { useDependencySettings } from '@atlassian/jira-portfolio-3-dependency-lines/src/controllers/index.tsx';
import { DependencyLinesOverlay } from '@atlassian/jira-portfolio-3-dependency-lines/src/ui/index.tsx';
import { getIssueLinkIfOverlapping } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/util.tsx';
import { TABLE_ISSUE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import {
	DEPENDENCY_SETTINGS,
	SCOPE_ROW_HEIGHT,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { toIssueId } from '@atlassian/jira-shared-types/src/general.tsx';
import { DEFAULT_TIMELINE_OFFSET_Y } from '../constants.tsx';
import { getPositionsForBar } from '../utils.tsx';
import type { Props } from './types.tsx';

export default function DependencyLinesLayer({
	isOptimizedMode,
	issueStatusById,
	scope,
	width,
	startIndex,
	stopIndex,
	syncStartEnabled,
	timelineRange,
}: Props) {
	const [dependencySettings] = useDependencySettings();
	const getDrawableLine = useCallback(
		(dependencyLine: DependencyLine): DrawableLine | typeof undefined => {
			const { fromIssue, toIssue, drawable } = dependencyLine;

			if (!drawable) {
				return;
			}

			const { indexOnScope: fromItemScopeIndex, id: sourceItemKey } = fromIssue;
			const fromItem = scope[fromItemScopeIndex];

			if (!fromItem || fromItem.tag !== TABLE_ISSUE) {
				return;
			}

			const { indexOnScope: toItemScopeIndex, id: targetItemKey } = toIssue;
			const toItem = scope[toItemScopeIndex];

			if (!toItem || toItem.tag !== TABLE_ISSUE) {
				return;
			}

			// we want to render a dependency line if the to or from row is in the visible scope,
			// or if the line will vertically traverse the entirety of the visible scope
			const fromItemInLimitedScope =
				fromItemScopeIndex >= startIndex && fromItemScopeIndex < stopIndex;
			const toItemInLimitedScope = toItemScopeIndex >= startIndex && toItemScopeIndex < stopIndex;
			const dependencyLineTraversesVisibleArea =
				(fromItemScopeIndex < startIndex && toItemScopeIndex > stopIndex) ||
				(fromItemScopeIndex > stopIndex && toItemScopeIndex < startIndex);

			if (fromItemInLimitedScope || toItemInLimitedScope || dependencyLineTraversesVisibleArea) {
				const { baselineStart: fromBaselineStart, baselineEnd: fromBaselineEnd } =
					isOptimizedMode && !isEmpty(fromItem.value.optimized)
						? fromItem.value.optimized
						: fromItem.value;
				const fromPositions = getPositionsForBar(
					{
						baselineStart: fromBaselineStart,
						baselineEnd: fromBaselineEnd,
					},
					timelineRange,
				);

				const { baselineStart, baselineEnd } =
					isOptimizedMode && !isEmpty(toItem.value.optimized)
						? toItem.value.optimized
						: toItem.value;
				const toPositions = getPositionsForBar(
					{
						baselineStart,
						baselineEnd,
					},
					timelineRange,
				);

				const isOverlapping = getIssueLinkIfOverlapping(
					fromItem.value,
					toItem.value,
					issueStatusById,
					syncStartEnabled,
				);

				return {
					to: {
						id: toIssueId(targetItemKey),
						indexOnScope: toItemScopeIndex,
						y: DEFAULT_TIMELINE_OFFSET_Y + scope[toItemScopeIndex].topOffset - SCOPE_ROW_HEIGHT / 2,
						x: (toPositions.leftPositionPercentage * width) / 100,
						color: GREY,
					},
					from: {
						id: toIssueId(sourceItemKey),
						indexOnScope: fromItemScopeIndex,
						y:
							DEFAULT_TIMELINE_OFFSET_Y +
							scope[fromItemScopeIndex].topOffset -
							SCOPE_ROW_HEIGHT / 2,
						x: ((100 - fromPositions.rightPositionPercentage) * width) / 100,
						color: GREY,
					},
					itemHeight: SCOPE_ROW_HEIGHT,
					isOverlapping,
				};
			}
		},
		[
			isOptimizedMode,
			issueStatusById,
			scope,
			startIndex,
			stopIndex,
			syncStartEnabled,
			timelineRange,
			width,
		],
	);

	if (dependencySettings.display !== DEPENDENCY_SETTINGS.LINES) {
		return null;
	}

	return <DependencyLinesOverlay getDrawableLine={getDrawableLine} />;
}
