import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	issueLength: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.issue-length.issue-length',
		defaultMessage: '{numDays, plural, one {# day} other {# days}}',
		description:
			'Message used to indicate the length of an issue in days when the issue bar is hovered on the timeline',
	},
	sprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.issue-length.sprint',
		defaultMessage: 'Sprint: {activeSprint}',
		description: 'Name of the active sprint',
	},
	completeSprints: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.issue-length.complete-sprints',
		defaultMessage: ', +{count} Completed',
		description: 'Name of the active sprint',
	},
});

export default messages;
