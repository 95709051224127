import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	cannotLoadTransitions: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.issue-status.issue-status-field.cannot-load-transitions',
		defaultMessage: "We couldn't load the statuses and transitions",
		description: 'Message shown when we failed to load transitions from backend',
	},
	changeStatus: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.issue-status.issue-status-field.change-status',
		defaultMessage: 'Change status',
		description: 'Label for change status button',
	},
	originalTransitionStatusName: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.issue-status.issue-status-field.original-transition-status-name',
		defaultMessage: 'Transition to',
		description: 'Default golabel status transition name',
	},
});
