import type { ReactElement } from 'react';
import type { FlagKey } from './types.tsx';

// Remove flag removes the specified flag from state, dismiss flag dismisses the topmost flag from state
export const ADD_FLAG = 'state.domain.flags.ADD_FLAG' as const;
export const PUSH_FLAG = 'state.domain.flags.PUSH_FLAG' as const;
export const REMOVE_FLAG = 'state.domain.flags.REMOVE_FLAG' as const;
export const DISMISS_FLAG = 'state.domain.flags.DISMISS_FLAG' as const;

export type FlagActionPayload = {
	key: FlagKey;
	context?: Record<string, string | ReactElement>;
};
export type FlagAction = {
	type: typeof ADD_FLAG | typeof REMOVE_FLAG | typeof DISMISS_FLAG | typeof PUSH_FLAG;
	payload: FlagActionPayload;
};

export type DismissFlagActionPayload = {
	key: FlagKey;
};
export type DismissFlagAction = {
	type: typeof DISMISS_FLAG;
	payload: DismissFlagActionPayload;
};

export const addFlag = (payload: FlagActionPayload): FlagAction => ({
	type: ADD_FLAG,
	payload,
});

// pushFlag is the action called in the saga which watches the addFlag action
// the saga checks edit permissions of the user
// another action is needed to prevent an infinite loop
export const pushFlag = (payload: FlagActionPayload): FlagAction => ({
	type: PUSH_FLAG,
	payload,
});

export const removeFlag = (payload: FlagActionPayload): FlagAction => ({
	type: REMOVE_FLAG,
	payload,
});

export const dismissFlag = (payload: DismissFlagActionPayload): DismissFlagAction => ({
	type: DISMISS_FLAG,
	payload,
});
