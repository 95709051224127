import React, { useState, useEffect, type ChangeEvent } from 'react';
import { styled } from '@compiled/react';
import { HelperMessage, Field } from '@atlaskit/form';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import TextField from '@atlassian/jira-portfolio-3-common/src/textfield/index.tsx';
import { CustomRangeDateFormat } from '../../../../constants.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// DD/MM/YYYY
const DATE_REGEX = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/\d{4}$/;

const isValidFormat = (dateString: string): boolean => DATE_REGEX.test(dateString);
const DateInput = ({ id, label, date, onChange, setInvalidFormat }: Props) => {
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const formatDate = (date: Date) => formatDateUTC(date.getTime(), CustomRangeDateFormat);
	const [value, setValue] = useState<string>(formatDate(date));
	const { formatMessage } = useIntl();

	const setDateValue = (e: ChangeEvent<HTMLInputElement>) => {
		const newDateString = e.target.value;
		setValue(newDateString);
		if (isValidFormat(newDateString)) {
			// At this point newDateString is 'DD/MM/YYYY'
			const parts = newDateString.split('/');
			const newDate = new Date(
				Date.UTC(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10)),
			);
			setInvalidFormat(false);
			onChange(newDate);
		} else {
			setInvalidFormat(true);
		}
	};

	useEffect(() => {
		if (date) {
			setValue(formatDate(date));
			setInvalidFormat(false);
		}
	}, [date, setInvalidFormat]);

	const getInvalidFormatMessage = () => {
		if (!isValidFormat(value)) {
			return (
				<HelperMessage>
					<FormattedMessage {...messages.wrongFormatErrorMessageLocalised} />
				</HelperMessage>
			);
		}
		return null;
	};

	return (
		<>
			<Field id={id} label={label} name="command" defaultValue="">
				{() => (
					<TextField
						id={id}
						testId="portfolio-3-custom-date-range.ui.modal-content.fixed-date-range.date-input.date-input"
						onChange={setDateValue}
						value={value}
						placeholder={CustomRangeDateFormat}
						elemBeforeInput={
							<Box xcss={iconWrapperStyles}>
								<CalendarIcon label={formatMessage(messages.calendarIcon)} />
							</Box>
						}
						aria-describedby="date-input-field-validation-error-message"
					/>
				)}
			</Field>
			<ErrorMessageWrapper id="date-input-field-validation-error-message">
				{getInvalidFormatMessage()}
			</ErrorMessageWrapper>
		</>
	);
};

export default DateInput;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorMessageWrapper = styled.div({
	padding: `0 ${token('space.100', '8px')}`,
	marginTop: token('space.050', '4px'),
	height: '20px',
});

const iconWrapperStyles = xcss({
	paddingLeft: 'space.100',
});
