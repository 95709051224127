import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyViewMessageReadOnly: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.no-issues-empty-state.empty-view-message-read-only',
		defaultMessage: "There aren't any issues in this plan yet.",
		description:
			'Message to be shown in scope table when there are no issues in the plan and the plan is read-only',
	},
	emptyViewMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.no-issues-empty-state.empty-view-message',
		defaultMessage:
			"There aren't any issues in your plan yet. Add <button>issue sources</button> to form the scope of your plan.",
		description: 'Message to be shown in scope table when there are no issues in the plan',
	},
});
