import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.reparent-tmp-to-external-warning-flag.title',
		defaultMessage: "Issue can't be moved",
		description:
			'This is a header for warning flag which is shown when the user attempts to reparent a team-managed project issue to another project.',
	},
	description: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.reparent-tmp-to-external-warning-flag.description',
		defaultMessage:
			"{issueKey} is part of a team-managed project, and can't be moved outside of {projectName}.",
		description:
			'This is a description for warning flag which is shown when the user attempts to reparent a team-managed project issue to another project.',
	},
});

export default messages;
