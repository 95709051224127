import { createContext } from 'react';

type ContextType = {
	isDialogLocked: boolean;
	toggleDialogLocked: (arg1: string) => (arg1: boolean) => void;
};

export const DependenciesDialogContext = createContext<ContextType>({
	isDialogLocked: false,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	toggleDialogLocked: () => () => {},
});
