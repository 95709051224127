.hierarchyFilter {
	align-items: center;
	display: inline-flex;

	/* Menu Triggers */
	& button[aria-haspopup] {
		text-align: left;
		width: 135px;
	}

	/* Menu items */
	& button {
		min-width: 135px;
	}
}

.separator {
	padding: 0 calc(var(--jpo-common-base-spacing) / 2);
}
