export const ENABLE_ONLY_ISSUE_ID =
	'state.ui.main.tabs.roadmap.timeline.schedule.ENABLE_ONLY_ISSUE_ID' as const;

export type EnableOnlyIssueIdAction = {
	type: typeof ENABLE_ONLY_ISSUE_ID;
	payload: string | null | undefined;
};

export const enableOnlyIssueId = (payload?: string | null): EnableOnlyIssueIdAction => ({
	type: ENABLE_ONLY_ISSUE_ID,
	payload,
});
