import { groupSortComparator } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import type {
	Group,
	ScopeIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { SelectOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/select-options/types.tsx';
import type { Grouping } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

const createGroupOptionFromCustomField = (
	grouping: Grouping,
	groups: Group<ScopeIssue>[],
	selectOption: SelectOption,
): GroupOption => ({
	group: `${grouping}-${selectOption.id}`,
	groupCombination: { [grouping]: selectOption.id },
	grouping,
	groupName: selectOption.value,
	startInlineCreateInEmptyGroup: !groups.some(
		(group: Group<ScopeIssue>) =>
			group.groupCombination &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			group.groupCombination[grouping as keyof typeof group.groupCombination] === selectOption.id,
	),
});

export const getGroupOptionsFromCustomField = (
	grouping: Grouping,
	groups: Group<ScopeIssue>[],
	selectOptions: SelectOption[],
	customFieldId: number,
): GroupOption[] => {
	const filteredOptions = selectOptions
		.filter((selectOption) => selectOption.fieldId === customFieldId)
		.map((option) => ({ ...option, id: String(option.id) }));
	const groupOptions = filteredOptions.map((option) =>
		createGroupOptionFromCustomField(grouping, groups, option),
	);

	return [...groupOptions.values()].sort(groupSortComparator);
};
