import React, {
	Component,
	type ComponentType,
	type SyntheticEvent,
	type BaseSyntheticEvent,
	type ReactNode,
	createRef,
} from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import Icon from '@atlaskit/icon';
import CloseIcon from '@atlaskit/icon/core/close';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalFooter } from '@atlaskit/modal-dialog';
import { Box, xcss, Text, Stack } from '@atlaskit/primitives';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import refreshSvg from '@atlassian/jira-portfolio-3-portfolio/src/common/icons/refresh.raw.svg';
import { injectIntlWithDefaultProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/inject-intl-with-default-props/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import DocLink from '@atlassian/jira-portfolio-3-portfolio/src/common/view/doc-link/index.tsx';
import { reload } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	FireScreenAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import withFilterItem from '../../filter-bar/filter-item-hoc/index.tsx';
import HiddenIssues from '../hidden-issues/index.tsx';
import ChangeStatusDialog from './change-status-dialog/index.tsx';
import ChangesCountMonitor from './changes-count-monitor/index.tsx';
import CommitWarningNotification from './changes-table/commit-warning-notification/view.tsx';
import { ROW_HEIGHT } from './changes-table/constants.tsx';
import ChangesTable from './changes-table/index.tsx';
import DiscardConfirmation from './discard-confirmation/index.tsx';
import EmptyDialog from './empty-dialog/index.tsx';
import UserFilter from './filters/user-filter/index.tsx';
import messages from './messages.tsx';
import Footer from './modal-footer/index.tsx';
import type { Action } from './modal-footer/types.tsx';
import ModalHeader from './modal-header/index.tsx';
import { NotificationToggle } from './notification-toggle/index.tsx';
import QuickSelectors from './quick-selectors/index.tsx';
import RequiredFieldsDialog from './required-fields-dialog/index.tsx';
import ReviewChangesButton from './review-changes-button-wrapper/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { InjectedComponents, PropsWithIntlAndDefaults, State } from './types.tsx';
import { getAttributesForIssuesWithTimeframeChanges } from './utils.tsx';

const DIALOG_NON_TABLE_HEIGHT = 200;
const DIALOG_MIN_HEIGHT = 500;

export const getFooterActions = (
	props: PropsWithIntlAndDefaults,
	handleStopAction: () => void,
	openDiscardConfirmation: (
		e: SyntheticEvent<HTMLElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => void,
): Action[] => {
	const {
		intl,
		isLoading,
		isSaving,
		isSavingInterrupted,
		isReadOnly,
		isReverting,
		isRevertingInterrupted,
		isOutOfSync,
		updateJira,
		selectedChangesCount,
		isNonPublishingEditor,
		isGicModalOpen = false,
		clearSummaryPageCache,
		changesCount,
	} = props;

	const discardAction: Action = {
		text: intl.formatMessage(messages.updateJiraDialogRevertButtonText),
		onClick: openDiscardConfirmation,
		appearance: 'subtle',
		isDisabled: isOutOfSync || isLoading || selectedChangesCount === 0 || isReverting,
		isLoading: false,
		isVisible: !(isSaving || isReadOnly || isReverting),
		testId:
			'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.discard-selected-changes',
	};

	if (!(isSaving || isReverting)) {
		return [
			discardAction,
			{
				text: intl?.formatMessage(messages.updateJiraDialogUpdateButtonText),
				type: 'save',
				onClick: (e: SyntheticEvent<HTMLElement, Event>, analyticsEvent: UIAnalyticsEvent) => {
					updateJira(analyticsEvent, props.shouldNotifyWatchers);
					clearSummaryPageCache();
					const [actionSubject, action] =
						PRODUCT_ANALYTICS_EVENT_NAMES.SAVE_SELECTED_CHANGES_IN_JIRA_CLICKED.split(' ');
					fireUIAnalytics(
						analyticsEvent.update({ action, actionSubject }),
						PRODUCT_ANALYTICS_EVENT_NAMES.SAVE_SELECTED_CHANGES_IN_JIRA_CLICKED,
						{ changesCount },
					);
				},
				appearance: 'primary',
				isDisabled:
					isOutOfSync ||
					isLoading ||
					selectedChangesCount === 0 ||
					isNonPublishingEditor ||
					isGicModalOpen,
				isVisible: !isReadOnly,
				autoFocus: true,
				tooltip: isGicModalOpen
					? intl?.formatMessage(messages.disabledTooltipForUpdateJiraWhenGicIsOpen)
					: intl?.formatMessage(messages.disabledTooltipForUpdateJira),
				testId:
					'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.save-selected-changes',
			},
		];
	}

	return [
		{
			text: !(isSavingInterrupted || isRevertingInterrupted)
				? intl.formatMessage(messages.updateJiraDialogStopButtonText)
				: intl.formatMessage(messages.updateJiraDialogStoppingButtonText),
			onClick: () => {
				handleStopAction();
			},
			isLoading: isSavingInterrupted || isRevertingInterrupted,
			isDisabled: isSavingInterrupted || isRevertingInterrupted,
		},
		discardAction,
	];
};

// eslint-disable-next-line jira/react/no-class-components
class UpdateJira extends Component<PropsWithIntlAndDefaults, State> {
	static defaultProps: InjectedComponents = {
		ChangesTable,
		Footer,
		QuickSelectors,
		RequiredFieldsDialog,
		ChangeStatusDialog,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		UserFilter: withFilterItem(UserFilter) as ComponentType<Record<any, any>>,
	};

	// eslint-disable-next-line react/sort-comp
	buttonRef = createRef<HTMLButtonElement>();

	state: State = {
		isDiscardConfirmationOpen: false,
		openedDialogWithChanges: false,
	};

	toggleNotification = (next: boolean) => {
		this.props.updateNotificationPreference(next);
	};

	openDiscardConfirmation = (_: SyntheticEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		const { changesCount } = this.props;

		this.setState({
			isDiscardConfirmationOpen: true,
		});
		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.DISCARD_SELECTED_CHANGES_CLICKED.split(' ');

		fireUIAnalytics(
			analyticsEvent.update({ action, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.DISCARD_SELECTED_CHANGES_CLICKED,
			{ changesCount },
		);
	};

	closeDiscardConfirmation = () => {
		this.setState({
			isDiscardConfirmationOpen: false,
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleDiscardConfirmation = (_: any, analyticsEvent: UIAnalyticsEvent) => {
		this.setState({
			isDiscardConfirmationOpen: false,
		});
		this.props.revertChanges();

		const [actionSubject, action] = PRODUCT_ANALYTICS_EVENT_NAMES.REVERT_SCENARIO.split(' ');
		fireUIAnalytics(
			analyticsEvent.update({ action, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.REVERT_SCENARIO,
			{
				changesCount: this.props.selectedChangesCount,
			},
		);
	};

	refresh = (e: BaseSyntheticEvent) => {
		if (e.target.getAttribute('role') === 'button') {
			reload();
		}
	};

	handleStopAction = () => {
		const { isSaving, interruptCommitting, interruptReverting } = this.props;

		if (isSaving) {
			interruptCommitting();
		} else {
			interruptReverting();
		}
	};

	handleCloseDialog = (
		_: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		const { closeDialog, changesCount } = this.props;

		if (isDefined(analyticsEvent)) {
			const [actionSubject, action] =
				PRODUCT_ANALYTICS_EVENT_NAMES.REVIEW_CHANGES_MODAL_CANCEL_CLICKED.split(' ');
			fireUIAnalytics(
				analyticsEvent.update({ action, actionSubject }),
				PRODUCT_ANALYTICS_EVENT_NAMES.REVIEW_CHANGES_MODAL_CANCEL_CLICKED,
				{ changesCount },
			);
		}

		closeDialog();
		this.buttonRef.current?.focus();
	};

	renderDialog = (
		headerText: string,
		dialogContent: React.ReactNode,
		width?: number | string,
		height?: number | string,
	) => {
		const {
			intl,
			selectedChangesCount,
			RequiredFieldsDialog: RequiredFieldsDialogComponent,
			ChangeStatusDialog: ChangeStatusDialogComponent,
			Footer: FooterComponent,
			changesCount,
			shouldNotifyWatchers,
			isSaving,
			isReverting,
		} = this.props;

		return (
			<>
				<FireScreenAnalytics attributes={{ changesCount }} />
				<ShortcutScope>
					<ModalDialog
						autoFocus
						shouldReturnFocus
						shouldCloseOnEscapePress={!(isSaving || isReverting)}
						onClose={this.handleCloseDialog}
						shouldCloseOnOverlayClick={false}
						width={width}
						height={height}
					>
						<Box xcss={headerStyles}>
							<ModalHeader>{headerText}</ModalHeader>
							<IconButton
								appearance="subtle"
								label={intl.formatMessage(messages.closeDialog)}
								spacing="compact"
								icon={CloseIcon}
								onClick={this.handleCloseDialog}
								testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.selected-field-remove-button"
							/>
						</Box>

						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.body}>
							<div
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								className={styles.dialogContent}
								data-testid="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-dialog-content"
							>
								{dialogContent}
							</div>
							<RequiredFieldsDialogComponent />
							<ChangeStatusDialogComponent />
						</div>
						<div
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={styles.footerParent}
							data-testid="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-dialog-footer"
						>
							<ModalFooter>
								<FooterComponent
									leftActions={
										changesCount !== 0 ? (
											<NotificationToggle
												active={shouldNotifyWatchers}
												onChange={this.toggleNotification}
											/>
										) : null
									}
									actions={getFooterActions(
										this.props,
										this.handleStopAction,
										this.openDiscardConfirmation,
									)}
									selectedChangesCount={selectedChangesCount}
								/>
							</ModalFooter>
						</div>
					</ModalDialog>
				</ShortcutScope>
			</>
		);
	};

	handleOpenDialog = (e: KeyboardEvent | React.MouseEvent<Element, MouseEvent>) => {
		const { openDialog, changesCount } = this.props;

		this.setState({
			openedDialogWithChanges: changesCount !== 0,
		});

		openDialog(e);
	};

	render() {
		const {
			intl,
			changes,
			changesCount,
			isDialogOpen,
			isLoading,
			isSaving,
			isReadOnly,
			isOptimizing,
			isOptimized,
			isReverting,
			isOutOfSync,
			onChangeListModified,
			hiddenIssuesDialogIsOpen,
			closeCommitWarningFlag,
			commitWarningMeta: {
				commitWarningsExists,
				shouldShowCommitWarningFlag,
				successfulCommitCount,
				failedCommitCount,
			},
			// eslint-disable-next-line @typescript-eslint/no-shadow
			ChangesTable,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			QuickSelectors,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			UserFilter,
			currentScenarioColor,
			dateConfiguration,
			typeToLevel,
			isIssuesLoading,
			hasPlanOnlyTeams,
		} = this.props;

		if (isIssuesLoading) {
			return null;
		}

		let width = 'x-large';
		let height;
		let dialogContent;
		switch (true) {
			case isOutOfSync: {
				/* eslint-disable jsx-a11y/no-static-element-interactions */
				dialogContent = (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles['out-of-sync']}>
						<Icon label="" dangerouslySetGlyph={refreshSvg} />
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles['out-of-sync-message']} onClick={this.refresh}>
							{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
							<p>{intl.formatMessage(messages.outOfSyncMessageConcurrentEditing)}</p>
							<p>
								{intl.formatMessage(messages.refreshCtaOld, {
									span: (chunks: ReactNode) => (
										<span role="button" tabIndex={0}>
											{chunks}
										</span>
									),
								})}
							</p>
						</div>
					</div>
				);
				/* eslint-enable */
				break;
			}
			case isLoading: {
				dialogContent = (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.spinner}>
						<Spinner size="large" />
					</div>
				);
				break;
			}
			default:
				// NOTE The problem is putting virtualized content (Table) into a growable Modal
				//
				// Contradictions posed by the problem:
				//
				// 1. Virtualized Table requires explicit height to be set on it, because it's the
				// essence of virtualization: render only content which is visible in the given area
				// and don't push parent / don't create scroll in parent by excessive content.
				//
				// 2. In case of small rows count we want our Modal to shrink and otherwise to grow
				// until it fills a viewport (sans padding).
				//
				// To cut this Gordian knot without overcomplications we manage Modal height not by
				// pushing it with Table's element (which is tricky because of [1]), but just doing
				// a rough estimation of desired size based on typical dialog styling and number of
				// changes to display. Growth is constrained by max-height in .body style. Table
				// then uses AutoSizer to fit into actual available space for it.
				width = '90vw';
				height = Math.max(DIALOG_NON_TABLE_HEIGHT + ROW_HEIGHT * changes.length, DIALOG_MIN_HEIGHT);
				dialogContent = (
					<>
						<Stack space="space.100" xcss={dialogContentContainerStyles}>
							<Text as="p">
								<FormattedMessage
									{...messages.updateJiraDialogBodyTop}
									values={{
										link: (chunks) => (
											<HelpPanelLink
												articleId="5jV03VyuxP6cfYQwcKWxWO"
												fallbackComponent={
													<DocLink href={DocLink.URLs.committingChangesToJira}>
														<FormattedMessage {...messages.updateJiraDialogReadMoreText} />
													</DocLink>
												}
												appearance="link"
												spacing="none"
												onClick={this.handleCloseDialog}
											>
												{chunks}
											</HelpPanelLink>
										),
									}}
								/>
							</Text>
							{hasPlanOnlyTeams && (
								<Text as="p">
									<FormattedMessage {...messages.updateJiraDialogBodyBottom} />
								</Text>
							)}
						</Stack>

						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.filters}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
							<div style={{ flexGrow: 1 }}>
								<QuickSelectors changes={changes} />
							</div>
							<UserFilter />
						</div>
						<ChangesTable
							changes={changes}
							commitWarningsExists={commitWarningsExists}
							isUpdating={isSaving || isReverting}
							isReadOnly={isReadOnly}
							changesCount={changesCount}
						/>
					</>
				);
		}

		const headerText = intl.formatMessage(messages.updateJiraDialogHeader, { changesCount });

		const shouldIncludeTimeframePlanningAnalyticsAttributes =
			changesCount > 0 && isDialogOpen && !isLoading && !isSaving && !isReverting;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.updateJiraButton}>
				{isDefined(onChangeListModified) && isDialogOpen && (
					<ChangesCountMonitor
						onChangeListModified={onChangeListModified}
						changesCount={changesCount}
						isSaving={isSaving}
					/>
				)}
				<ContextualAnalyticsData
					sourceName="reviewChanges"
					sourceType={MODAL}
					attributes={
						shouldIncludeTimeframePlanningAnalyticsAttributes
							? {
									roadmapChangeDetails: getAttributesForIssuesWithTimeframeChanges(
										changes,
										dateConfiguration,
										typeToLevel,
									),
									changesCount,
								}
							: {}
					}
				>
					<ReviewChangesButton
						currentScenarioColor={currentScenarioColor}
						changesCount={changesCount}
						isOptimizing={isOptimizing}
						isOptimized={isOptimized}
						isReadOnly={isReadOnly}
						openDialog={this.handleOpenDialog}
						ref={this.buttonRef}
					/>
					{isDialogOpen && (
						<>
							{this.state.openedDialogWithChanges ? (
								this.renderDialog(headerText, dialogContent, width, height)
							) : (
								<EmptyDialog handleCloseDialog={this.handleCloseDialog} />
							)}
						</>
					)}
					{hiddenIssuesDialogIsOpen && <HiddenIssues />}
					<CommitWarningNotification
						successfulCommitCount={successfulCommitCount}
						failedCommitCount={failedCommitCount}
						show={shouldShowCommitWarningFlag}
						onClose={() => {
							closeCommitWarningFlag();
						}}
					/>
					<ContextualAnalyticsData sourceName="discardChanges" sourceType={MODAL}>
						<DiscardConfirmation
							isOpen={this.state.isDiscardConfirmationOpen}
							onConfirm={this.handleDiscardConfirmation}
							onCancel={this.closeDiscardConfirmation}
						/>
					</ContextualAnalyticsData>
				</ContextualAnalyticsData>
			</div>
		);
	}
}

const headerStyles = xcss({
	paddingTop: 'space.250',
	paddingRight: 'space.250',
	paddingLeft: 'space.250',
	paddingBottom: 'space.100',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
});

const dialogContentContainerStyles = xcss({
	marginTop: 'space.075',
	marginBottom: 'space.150',
});

export default injectIntlWithDefaultProps(UpdateJira);
