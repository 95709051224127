import React, { useCallback, useEffect } from 'react';
import type FetchError from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { performGetRequest, performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useCttOnboardingTour } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-onboarding/index.tsx';
import ReviewChangesSpotlight from './review-changes-spotlight/index.tsx';
import type { Props, ReviewChangesUserProperty } from './types.tsx';

export const REVIEW_CHANGES_ONBOARDING_KEY = 'jira.user.plans.review-changes.onboarding' as const;
export const getUserPropertyEndpoint = (accountId?: string) =>
	`/rest/api/3/user/properties/${REVIEW_CHANGES_ONBOARDING_KEY}?accountId=${accountId}`;

const ReviewChangesSpotlightManager = ({
	closeSpotlight,
	activateSpotlight,
	isSpotlightActive,
	toggleSetHasCountChanged,
}: Props) => {
	const {
		data: {
			user: { accountId },
		},
	} = useCurrentUser();

	const [cttOnboarding] = useCttOnboardingTour();

	const getUserProperty = useCallback(
		() => performGetRequest<ReviewChangesUserProperty>(getUserPropertyEndpoint(accountId ?? '')),
		[accountId],
	);

	const updateUserProperty = useCallback(
		(timestamp: number) =>
			performPutRequest(getUserPropertyEndpoint(accountId ?? ''), {
				body: JSON.stringify({ timestamp }),
			}),
		[accountId],
	);

	useEffect(() => {
		getUserProperty()
			.then((userProperty) => {
				if (!userProperty?.value?.timestamp) {
					activateSpotlight();
				}
			})
			.catch((error: FetchError) => {
				if (error?.statusCode === 404) {
					activateSpotlight();
				}
			});
	}, [activateSpotlight, getUserProperty]);

	useEffect(() => {
		if (cttOnboarding.visible && isSpotlightActive) {
			// if user makes a change during ctt onboarding avoid showing unsaved changes spotlight afterwards
			toggleSetHasCountChanged(false);
		}
	}, [isSpotlightActive, cttOnboarding.visible, toggleSetHasCountChanged]);

	return isSpotlightActive && !(cttOnboarding.isTakingOnboardingTour || cttOnboarding.visible) ? (
		<ReviewChangesSpotlight
			closeSpotlight={closeSpotlight}
			updateUserProperty={updateUserProperty}
		/>
	) : null;
};

export default ReviewChangesSpotlightManager;
