import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.team.placeholder',
		defaultMessage: 'Choose a team',
		description: 'The placeholder for the team selector',
	},
	teamSelectorLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.team.team-selector-label',
		defaultMessage: 'Choose team for issue {issue}',
		description: 'The label for the team selector',
	},
	issueIsSubtaskHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.team.issue-is-subtask-header',
		defaultMessage: 'Cannot change the team of sub-tasks',
		description: 'This is a header of a message when an issue is a subtask',
	},
	issueIsSubtaskContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.team.issue-is-subtask-content',
		defaultMessage:
			'A sub-task is assigned to the same team as its parent story. To change this team, reassign the parent story instead.',
		description: 'This is a content of a message when an issue is a subtask',
	},
	externalTeamTooltipTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.team.external-team-tooltip-title',
		defaultMessage: 'Team currently not in this plan',
		description: 'Tooltip title for the external team',
	},
	externalTeamTooltipContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.team.external-team-tooltip-content',
		defaultMessage:
			"While this team isn't in this plan, it's appearing because it's assigned to issues in the plan.",
		description: 'Tooltip content for the external team',
	},
});
