import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useHorizontalScrolling } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import HorizontalScrollBar from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/ui/scroll-bar/index.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { useViewport } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import { useColumnWidths } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';

interface Props {
	bottom: number;
}

export const ScrollBar = ({ bottom }: Props) => {
	const [viewport] = useViewport();
	const [{ viewport: horizontalScrollingViewport }] = useHorizontalScrolling();
	const [columnWidths] = useColumnWidths({ preview: true });

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getTimelineLeftOffset = (columnWidths: number[]) =>
		columnWidths.reduce(
			(totalWidth, curWidth, index) =>
				index < columnWidths.length - 1 ? totalWidth + curWidth : totalWidth,
			0,
		);

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getTimelineWidth = (columnWidths: number[]) =>
		viewport.width - getTimelineLeftOffset(columnWidths);

	return (
		<ZIndex layer={Z_INDEX_LAYER.HORIZONTAL_SCROLL_BAR}>
			{(zIndex) => (
				<Box
					xcss={wrapperStyles}
					style={{ left: getTimelineLeftOffset(columnWidths), bottom, zIndex }}
				>
					<Box
						xcss={containerStyles}
						role="scrollbar"
						aria-valuenow={horizontalScrollingViewport.offset}
						style={{ width: getTimelineWidth(columnWidths) }}
					>
						<HorizontalScrollBar />
					</Box>
				</Box>
			)}
		</ZIndex>
	);
};

const wrapperStyles = xcss({
	position: 'sticky',
});

const containerStyles = xcss({
	position: 'relative',
});
