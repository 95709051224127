.tagContainer {
	display: flex;
	align-items: center;
}

.item {
	min-width: calc(4.5 * var(--jpo-common-base-spacing));
	margin-left: calc(0.5 * var(--jpo-common-base-spacing));
}

.item:first-child {
	margin-left: 0;
}

.item > span {
	max-width: 100%;
}

.componentsContainer {
	display: flex;
	flex-direction: row;
	padding: 0 var(--jpo-common-base-spacing);
	cursor: pointer;
	width: 100%;
	box-sizing: border-box;
	& > div {
		max-width: calc(100% - var(--jpo-common-base-spacing));
	}
}

.componentsContainerDisabled {
	cursor: default;
	&:active,
	&:focus {
		outline: none;
	}
}

.hiddenContainer {
	display: none;
}

.disabled span {
	color: var(--ds-text-disabled, var(--adg3-color-N70));
}

.labelsContainer {
	& > .itemsWrapper > div {
		width: 100%;
		padding: 0 var(--jpo-common-base-spacing);
	}
}

.itemsWrapper {
	min-height: 40px;
	width: 100%;
	display: flex;
	align-items: center;
}
