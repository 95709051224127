.optimizeButtonAfterIcons {
	align-items: center;
	display: flex;
}

.spinnerContainer {
	margin-left: calc(var(--jpo-common-base-spacing) / 2);
}

.optimizeButtonIconOnly {
	display: flex;
}

.optimizeButtonLabelOnly {
	display: none;
}

@media (min-width: 1366px) {
	.optimizeButtonIconOnly {
		display: none;
	}
	.optimizeButtonLabelOnly {
		display: flex;
	}
}
