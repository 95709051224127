import {
	getMode,
	isReadOnly,
	isReportMode,
	getPlanModeForAnalytics,
	getAnalyticsEvent,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getAssociatedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/associated-issues/index.tsx';
import { getDependencyReportStatus } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/dependencies/index.tsx';
import { getDefinedGoals } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-goals/index.tsx';
import { getIsIssuesLoading } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import { getPlanTitle } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import { getShareAsState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/share-report/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getEnhancedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/query.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export const getDialogVisibilityState = (state: State) =>
	state.ui.Top.TitleBar.ShareReport.isDialogOpen;

export const isLoading = (state: State) => state.ui.Top.TitleBar.ShareReport.isLoading;

export const getShareReportUrl = (state: State) => state.ui.Top.TitleBar.ShareReport.url;

export default createStructuredSelector<State, StateProps>({
	isLoading,
	isOpen: getDialogVisibilityState,
	mode: getMode,
	noIssuesInPlan: (state: State) => getEnhancedIssues(state).length === 0,
	readOnly: isReadOnly,
	isReportMode,
	planModeForAnalytics: getPlanModeForAnalytics,
	dependencyReportStatus: getDependencyReportStatus,
	shareAs: getShareAsState,
	url: getShareReportUrl,
	analyticsEvent: getAnalyticsEvent,
	isIssuesLoading: getIsIssuesLoading,
	planTitle: getPlanTitle,
	definedGoalsByARI: getDefinedGoals,
	associatedIssues: getAssociatedIssues,
});
