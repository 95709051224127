import type { Issue } from '../issues/types.tsx';
import {
	RESET,
	type ResetAction,
	ADD,
	type AddAction,
	BULK_ADD,
	type BulkAddAction,
	REMOVE,
	type RemoveAction,
	BULK_REMOVE,
	type BulkRemoveAction,
} from './actions.tsx';

const initialState: Issue[] = [];

type Action = ResetAction | AddAction | BulkAddAction | RemoveAction | BulkRemoveAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Issue[] = initialState, action: Action): Issue[] => {
	switch (action.type) {
		case RESET:
			return [...action.payload];
		case ADD: {
			return [...state, action.payload];
		}
		case BULK_ADD: {
			return [...state, ...action.payload];
		}
		case REMOVE: {
			return state.filter(({ id }) => id !== action.payload);
		}
		case BULK_REMOVE: {
			return state.filter(({ id }) => !action.payload.includes(id));
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
