import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	assigneeMenuTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.assignee.assignee-menu-title',
		defaultMessage: 'Choose assignee',
		description: 'This is a title for assignee menu',
	},
	assigneeSearchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.assignee.assignee-search-placeholder',
		defaultMessage: 'Find assignees...',
		description: 'This is a placeholder for search',
	},
});
