.crossProjectReleaseOption {
	display: flex;
	padding-right: var(--jpo-common-base-spacing);
	/*
        450 - it's max-width of filter container
        calc(1.5 * var(--jpo-icon-size-small)) - it's the icon size
        calc(6 * var(--jpo-common-base-spacing)) - paddings and one margin
    */
	max-width: calc(
		450px - calc(6 * var(--jpo-common-base-spacing)) - calc(1.5 * var(--jpo-icon-size-small))
	);
}

.crossProjectReleaseName {
	flex: 1 1 0%;
	margin-right: var(--jpo-common-base-spacing);
	min-width: 50px;
}

.crossProjectReleaseLozenge {
	flex: 0 1 0%;
}
