import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	where: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.dialog-content.where',
		defaultMessage: 'Where',
		description: 'Title for create issue group',
	},
	noMatchFoundText: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.add-issue.nested-menu.dialog-content.no-match-found-text',
		defaultMessage: 'No matches',
		description:
			'This is a text is displayed when none of the version name matches the search query.',
	},
});
