/**
 * Calculates the financial year that a date falls within. All calculations are done using UTC TZ.
 * @param time timestamp from epoch
 * @param fyStartMonth the month that FY starts (1-12)
 * @returns the financial year that the month falls within
 */
export const calculateFinancialYear = (time: number, fyStartMonth = 1): number => {
	const date = new Date(time);
	const year = date.getUTCFullYear();
	const month = date.getUTCMonth() + 1; // zero-based
	if (fyStartMonth > 1 && month >= fyStartMonth) {
		return year + 1;
	}
	return year;
};

export const formatFY = (year: number | string): string => `${year}`.slice(-2);
