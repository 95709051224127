import React, {
	Fragment,
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useState,
	type ForwardedRef,
} from 'react';
import VisuallyHidden from '@atlaskit/visually-hidden';
import type { DelayedAnnouncerProps, DelayedAnnouncerRef } from './types.tsx';

export const DelayedAnnouncer = forwardRef(
	(
		{ label, waitBeforeAnnouncement = 300 }: DelayedAnnouncerProps,
		ref: ForwardedRef<DelayedAnnouncerRef>,
	) => {
		const [isAnnounced, setIsAnnounced] = useState(false);

		const announcementTrigger = useCallback(() => {
			setIsAnnounced(false);
			const timer = setTimeout(() => {
				setIsAnnounced(true);
			}, waitBeforeAnnouncement);
			return () => clearTimeout(timer);
		}, [waitBeforeAnnouncement]);

		useImperativeHandle(ref, () => ({
			announce: announcementTrigger,
		}));

		useEffect(announcementTrigger, [waitBeforeAnnouncement, announcementTrigger, label]);

		return (
			<VisuallyHidden>
				<div role="status" aria-live="polite" aria-atomic="false">
					<Fragment key={String(isAnnounced)}>{label}</Fragment>
				</div>
			</VisuallyHidden>
		);
	},
);
