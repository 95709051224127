import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	spotlightGotIt: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-create-initative.spotlight-got-it',
		defaultMessage: 'Got it',
		description: 'The got it button for cross team templates onboarding spotlight card.',
	},
	spotlightCreateInitativeTitle: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-create-initative.spotlight-create-initative-title',
		defaultMessage: 'Take the initiative!',
		description: 'The title for cross team templates onboarding create issues card.',
	},
	spotlightCreateInitativeBodyP1: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-create-initative.spotlight-create-initative-body-p-1',
		defaultMessage:
			'Start by defining your first Initiative, which sits on the hierarchy level above Epics. They represent big goals like “Build a rocket”, “Launch a new app”, or “Take over the world”.',
		description: 'Part one of body for cross team templates onboarding create issues card.',
	},
	spotlightCreateInitativeBodyP2: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-create-initative.spotlight-create-initative-body-p-2',
		defaultMessage: 'So what are your goals?',
		description: 'Part 2 of body for cross team templates onboarding create issues card.',
	},
});
