import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	// Dark colours
	r400: {
		id: 'portfolio-3-portfolio.common.colours.r-400',
		defaultMessage: 'Red',
		description: 'Red colour',
	},
	p400: {
		id: 'portfolio-3-portfolio.common.colours.p-400',
		defaultMessage: 'Purple',
		description: 'Purple colour',
	},
	b400: {
		id: 'portfolio-3-portfolio.common.colours.b-400',
		defaultMessage: 'Blue',
		description: 'Blue colour',
	},
	t400: {
		id: 'portfolio-3-portfolio.common.colours.t-400',
		defaultMessage: 'Teal',
		description: 'Teal colour',
	},
	g400: {
		id: 'portfolio-3-portfolio.common.colours.g-400',
		defaultMessage: 'Green',
		description: 'Green colour',
	},
	y400: {
		id: 'portfolio-3-portfolio.common.colours.y-400',
		defaultMessage: 'Orange',
		description: 'Orange colour',
	},

	// Light colours
	r100: {
		id: 'portfolio-3-portfolio.common.colours.r-100',
		defaultMessage: 'Light red',
		description: 'Light red colour',
	},
	p75: {
		id: 'portfolio-3-portfolio.common.colours.p-75',
		defaultMessage: 'Light purple',
		description: 'Light purple colour',
	},
	b100: {
		id: 'portfolio-3-portfolio.common.colours.b-100',
		defaultMessage: 'Light blue',
		description: 'Light blue colour',
	},
	t100: {
		id: 'portfolio-3-portfolio.common.colours.t-100',
		defaultMessage: 'Light teal',
		description: 'Light teal colour',
	},
	g100: {
		id: 'portfolio-3-portfolio.common.colours.g-100',
		defaultMessage: 'Light green',
		description: 'Light green colour',
	},
	y100: {
		id: 'portfolio-3-portfolio.common.colours.y-100',
		defaultMessage: 'Light yellow',
		description: 'Light yellow colour',
	},

	// Grey colour
	n100: {
		id: 'portfolio-3-portfolio.common.colours.n-100',
		defaultMessage: 'Gray',
		description: 'Grey colour',
	},
});
