import React, { useEffect } from 'react';
import { SpotlightTransition } from '@atlaskit/onboarding';
import { useCttOnboardingTour } from '../../controllers/ctt-onboarding/index.tsx';
import { CttOnboardingStage } from '../../controllers/ctt-onboarding/types.tsx';
import { FinishFlag } from './finish-flag/index.tsx';
import { CreateInitativeSpotlight } from './spotlight-create-initative/index.tsx';
import { EpicReparentSpotlight } from './spotlight-epic-reparent/index.tsx';
import { NavigateIssueSourcesSpotlight } from './spotlight-navigate-issue-sources/index.tsx';
import { ReviewChangesSpotlight } from './spotlight-review-changes/index.tsx';
import { StepInputEpicReparent } from './step-input-epic-reparent/index.tsx';
import { StepInputTitle } from './step-input-title/index.tsx';
import type { Props } from './types.tsx';

export const CttOnboarding = ({
	planId,
	variant,
	hasAnyInitiatives,
	hasEpicWithParent,
	isRoadmapGroupedByNone,
	setInlineCreate,
	setClearInlineCreate,
	setRedirectToSpaPlanPage,
	setPrimeRoadmap,
	setOpenHorizontalPlanActionMenu,
}: Props) => {
	const [{ stage }, { initCttOnboarding }] = useCttOnboardingTour();

	useEffect(() => {
		if (planId) initCttOnboarding(planId);
	}, [initCttOnboarding, planId]);

	const assertUnreachable = (_: never): never => {
		throw new Error("Didn't expect to get here");
	};

	if (!planId) return null;
	if (!stage) return null;

	const propsForNavigateIssueSources = {
		planId,
		setRedirectToSpaPlanPage,
		hasAnyInitiatives,
		setOpenHorizontalPlanActionMenu,
	};

	const propsStepInputTitle = {
		setInlineCreate,
		setClearInlineCreate,
		setPrimeRoadmap,
		variant,
		hasAnyInitiatives,
	};

	const propsStepInputEpicReparent = {
		variant,
		hasEpicWithParent,
	};

	const propsForEpicReparentSpotlight = {
		planId,
		variant,
		isRoadmapGroupedByNone,
		setPrimeRoadmap,
		setRedirectToSpaPlanPage,
		setOpenHorizontalPlanActionMenu,
	};

	const getSpotlightStage = () => {
		switch (stage) {
			case CttOnboardingStage.CREATE_INITATIVE:
				return <CreateInitativeSpotlight variant={variant} />;
			case CttOnboardingStage.INPUT_TITLE:
				return <StepInputTitle {...propsStepInputTitle} />;
			case CttOnboardingStage.NAVIGATE_ISSUE_SOURCES:
				return <NavigateIssueSourcesSpotlight {...propsForNavigateIssueSources} />;
			case CttOnboardingStage.SHOW_ISSUE_SOURCES:
			case CttOnboardingStage.NAVIGATE_PLAN:
				// for these two cases we mount the spotlight directly on the sidebar.
				return null;
			case CttOnboardingStage.EPIC_REPARENT:
				return <EpicReparentSpotlight {...propsForEpicReparentSpotlight} />;
			case CttOnboardingStage.INPUT_EPIC_REPARENT:
				return <StepInputEpicReparent {...propsStepInputEpicReparent} />;
			case CttOnboardingStage.REVIEW_CHANGES:
				return <ReviewChangesSpotlight variant={variant} />;
			case CttOnboardingStage.FINISH_FLAG:
				return null;
			case CttOnboardingStage.CLOSED:
				return null;
			default:
				assertUnreachable(stage);
				return null;
		}
	};

	return (
		<>
			<SpotlightTransition>{getSpotlightStage()}</SpotlightTransition>
			{stage === CttOnboardingStage.FINISH_FLAG && <FinishFlag />}
		</>
	);
};
