import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

// Transparent color equivalent to ADG's N20 (#F4F5F7)
const N20_TRANSPARENT = token('color.background.neutral', 'rgba(35, 55, 95, 0.05)');

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	...colors,
	N20_TRANSPARENT,
};
