import { getEnrichedCrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/index.tsx';
import { getTimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/index.tsx';
import {
	getSolutionVersionsById,
	getEnrichedVersionsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/index.tsx';
import { getHighlightedVersionsViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, StateProps>({
	solutionVersionsById: getSolutionVersionsById,
	timelineRange: getTimelineRange,
	crossProjectVersionsById: getEnrichedCrossProjectVersionsById,
	versionsById: getEnrichedVersionsById,
	highlightedVersionIds: (state: State) => getHighlightedVersionsViewSettings(state).versions,
});
