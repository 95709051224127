/** @jsx jsx */
import { jsx } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { Box, xcss, Inline } from '@atlaskit/primitives';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-associated-issues/src/common/types.tsx';

type Props = {
	option?: AssociatedIssue;
};

const Option = ({ option }: Props) => {
	if (!option) return null;
	return (
		<Box xcss={outerWrapperStyles}>
			<Inline space="space.050" alignBlock="center" alignInline="start">
				<Box xcss={avatar}>
					<Avatar src={option.iconUrl} size="xsmall" />
				</Box>
				<Box xcss={summary}>{option.summary}</Box>
			</Inline>
		</Box>
	);
};

const outerWrapperStyles = xcss({
	display: 'inline',
	minWidth: '38px',
});

const avatar = xcss({
	paddingLeft: 'space.050',
	flexShrink: 0,
});

const summary = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

export default Option;
