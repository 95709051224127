import padStart from 'lodash/padStart';
import { addMonths, format as formatDate, parse } from 'date-fns';
import * as R from 'ramda';
import { CUSTOM_TIME_RANGE_TYPES } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import {
	getDayLabel,
	getMonthLabel,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import {
	ONE_DAY,
	buildEndOfUtcQuarter,
	buildEndOfUtcYear,
	buildStartOfUtcQuarter,
	buildStartOfUtcYear,
	endOfUtcDay,
	endOfUtcMonth,
	endOfUtcWeek,
	startOfUtcDay,
	startOfUtcMonth,
	startOfUtcWeek,
	getRelativeDates,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { CustomDateRange } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/types.tsx';
import type { ZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/types.tsx';
import {
	getContainerDuration,
	getContainerWidth,
	getZoomLevel as getZoomLevelUtil,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/utils/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { TimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import {
	TIMELINE_MODES,
	ZOOM_LEVELS,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { TimeScaleState as TimeScale } from '../../state/domain/view-settings/time-scale/types.tsx';
import type { State } from '../../state/types.tsx';
import type { Units } from '../../view/main/tabs/roadmap/timeline/unit/types.tsx';
import { getTimeScaleViewSettings } from '../view-settings/index.tsx';
import { calculateQuarter } from './quarter/index.tsx';

export const getZoomLevel = (state: State): ZoomLevel | undefined => {
	const { mode } = getTimeScaleViewSettings(state);

	return getZoomLevelUtil(mode);
};

export const getOnlyEnabledIssueId = (state: State): string | null | undefined =>
	state.ui.Main.Tabs.Roadmap.Timeline.Schedule.onlyEnabledIssueId;

export const getTimelineContainerWidth = (state: State, viewportWidth?: number | null) => {
	if (viewportWidth == null) {
		return viewportWidth;
	}

	const { mode } = getTimeScaleViewSettings(state);
	switch (mode) {
		case ZOOM_LEVELS.WEEKS:
		case ZOOM_LEVELS.MONTHS:
		case ZOOM_LEVELS.QUARTERS:
		case ZOOM_LEVELS.YEARS:
			return getContainerWidth(mode);
		default:
			return viewportWidth;
	}
};

export const getTodayDate = () => {
	const todayDate = new Date();
	return todayDate;
};

export const getISODate = (date: Date) => date.toISOString().substr(0, 10);

export const get3mthTimeScale = (today: Date) => {
	const in3Months = addMonths(today, 3);
	const start = startOfUtcDay(getISODate(today));
	const end = endOfUtcDay(getISODate(in3Months));
	return {
		start,
		end,
	};
};

const getCustomFixedDates = ({ fromDate, toDate }: CustomDateRange) => {
	const getFormattedDate = (date: number | string) => {
		if (!R.is(Number, date)) {
			// if date is already a number, we keep it as is as the startOfUtcDay also accepts timestamp
			return formatDate(
				typeof date === 'string' ? parse(date, 'yyyy/MM/dd', new Date()) : date,
				'yyyy-MM-dd',
			);
		}
		return date;
	};

	return {
		start: startOfUtcDay(getFormattedDate(fromDate ?? '')),
		end: endOfUtcDay(getFormattedDate(toDate ?? '')),
	};
};

export const getTimelineRangePure = (timeScale: TimeScale, today: Date): TimelineRange => {
	const { mode } = timeScale;
	let start;
	let end;

	switch (mode) {
		case ZOOM_LEVELS.WEEKS:
		case ZOOM_LEVELS.MONTHS:
		case ZOOM_LEVELS.QUARTERS:
		case ZOOM_LEVELS.YEARS: {
			start = startOfUtcDay(getISODate(today));
			end = start + getContainerDuration(mode);
			return { start, end };
		}
		case TIMELINE_MODES.CUSTOM: {
			const { customDateRange } = timeScale;

			if (customDateRange) {
				// In theory, it should only be possible to set a custom date range with some
				// actual dates, but we are defensively coding to ensure that we don't try to
				// calculate a custom date range using empty strings
				const {
					fromDate = '',
					toDate = '',
					toNowUnitCount,
					fromNowUnitCount,
					toNowUnit,
					fromNowUnit,
					typeOfCustomDateRange = CUSTOM_TIME_RANGE_TYPES.FIXED,
				} = customDateRange;

				if (
					typeOfCustomDateRange === CUSTOM_TIME_RANGE_TYPES.FIXED &&
					fromDate !== '' &&
					toDate !== ''
				) {
					const timescale = getCustomFixedDates(customDateRange);
					start = timescale.start;
					end = timescale.end;
				} else if (
					typeOfCustomDateRange === CUSTOM_TIME_RANGE_TYPES.RELATIVE &&
					isDefined(toNowUnitCount) &&
					isDefined(fromNowUnitCount) &&
					toNowUnit &&
					fromNowUnit
				) {
					const timescale = getRelativeDates(customDateRange, today);
					start = timescale.start;
					end = timescale.end;
				} else {
					const timescale = get3mthTimeScale(today);
					start = timescale.start;
					end = timescale.end;
				}

				// customDateRange should always be defined when the user selected a range, but just in case it's undefined we set the timeline to 3 months
			} else {
				const timescale = get3mthTimeScale(today);
				start = timescale.start;
				end = timescale.end;
			}
			break;
		}

		// default is set to "months" in "app-simple-plans/state/domain/view-settings/time-scale/reducer.js"
		default: {
			start = startOfUtcDay(getISODate(today));
			end = start + getContainerDuration(ZOOM_LEVELS.MONTHS);
			return { start, end };
		}
	}

	if (!isDefined(start) || !isDefined(end)) {
		throw new Error('Both start and end must be calculated');
	}

	return {
		start,
		end,
	};
};

export const getTimelineRange = createSelector(
	[getTimeScaleViewSettings, getTodayDate],
	getTimelineRangePure,
);

export const getScrollableTimelineRangePure = (
	zoomLevel: ZoomLevel | undefined,
	containerTimelineRange: TimelineRange,
): TimelineRange => {
	if (zoomLevel === undefined) {
		return containerTimelineRange;
	}

	return { start: -Infinity, end: Infinity };
};

export const getScrollableTimelineRange = createSelector(
	[getZoomLevel, getTimelineRange],
	getScrollableTimelineRangePure,
);

// month is UTC (indexed from 0)
export const getQuarterNumber = (month: number): number => Math.floor(month / 3);

export const getQuarterLabel = (month: number, fiscalMonth = 1): string =>
	`${calculateQuarter(month + 1, fiscalMonth)}`;

export type GetUnitLabel = (value: number) => string;

export const getDayUnitLabel: GetUnitLabel = (value) => {
	const date = new Date(value);

	const day = getDayLabel(date.getUTCDay());
	const month = getMonthLabel(date.getUTCMonth());
	const dayOfMonth = padStart(`${date.getUTCDate()}`, 2, '0');

	return `${day} ${dayOfMonth}/${month}`;
};

// This is used for ranges up to 14 days.
export const getDayUnits = (startTime: number, totalDays: number) => {
	const days: Units = [];
	let start = startTime;
	for (let day = 0; day < Math.round(totalDays); day++) {
		days.push({
			startTime: start,
			widthPercentage: 100 / totalDays,
		});
		start += ONE_DAY;
	}
	return days;
};

// Given a start date and a number of days, return an array of arrays split by month
export const getDayUnitsSpa = (startTime: number, totalDays: number) => {
	const daysInUnits = [];
	const widthPercentage = 100 / totalDays;
	let days: Units = [];
	let date = new Date(startTime);
	let currStartTime = startTime;
	let startMonth = date.getUTCMonth();
	for (let day = 0; day < Math.round(totalDays); day++) {
		date = new Date(currStartTime);
		if (date.getUTCMonth() !== startMonth) {
			daysInUnits.push(days);
			days = [];
			startMonth = date.getUTCMonth();
		}
		days.push({
			startTime: currStartTime,
			widthPercentage,
		});
		currStartTime += ONE_DAY;
	}
	daysInUnits.push(days);
	return daysInUnits;
};

export const getWeekUnitLabel: GetUnitLabel = (value) => {
	const date = new Date(value);
	const month = getMonthLabel(date.getUTCMonth());
	const dayOfMonth = padStart(date.getUTCDate().toString(), 2, '0');
	return `${dayOfMonth}/${month}`;
};

export const getMonthUnitLabel: GetUnitLabel = (value) =>
	getMonthLabel(new Date(value).getUTCMonth());

export const getQuarterUnitLabel: GetUnitLabel = (value) => {
	const date = new Date(value);
	const month = getMonthLabel(date.getUTCMonth());
	const quarter = getQuarterLabel(date.getUTCMonth());
	const year = date.getUTCFullYear().toString().substr(2);
	return `${month} Q${quarter}/${year}`;
};

export const getYearUnitLabel: GetUnitLabel = (value) =>
	new Date(value).getUTCFullYear().toString();

// This function is used to calculate the units for a specific date range. The elements of the function
// are calculating the widths of each of the units (typically the first and last units might have a width
// that is smaller than the other units because they MAY not represent an entire unit - for example when
// the start date is halfway through a month). However all units are labelled from the start of that unit
export const getUnits = (
	startTime: number,
	endTime: number,
	startOfUnit: (date: number) => Date,
	endOfUnit: (date: number) => Date,
) => {
	const units: Units = [];
	const timelineLength = endTime - startTime;

	const calcWidthPercentage = (start: number, end: number, rangeLength: number) => {
		const unitLength = end - start;
		return (unitLength / rangeLength) * 100;
	};

	let lastTime = startTime;
	let leftOffset = 0;
	while (endTime > lastTime) {
		const unitStartDate = startOfUnit(lastTime);
		const unitEndDate = endOfUnit(lastTime);

		const unitStartTime = unitStartDate.getTime();
		const unitEndTime = unitEndDate.getTime();

		let widthPercentage = 0;
		let unitTime;

		if (startTime > unitStartTime) {
			// Handle partial (should only be for first displayed unit)
			widthPercentage = calcWidthPercentage(startTime, unitEndTime, timelineLength);
			unitTime = startTime;
		} else if (endTime < unitEndTime) {
			// Handle partial (should only be for last displayed unit)
			widthPercentage = calcWidthPercentage(unitStartTime, endTime, timelineLength);
			unitTime = unitStartTime;
		} else {
			// Handle full unit
			widthPercentage = calcWidthPercentage(unitStartTime, unitEndTime, timelineLength);
			unitTime = unitStartTime;
		}

		units.push({
			startTime: unitTime,
			widthPercentage,
			leftOffset,
		});

		// Since we know that the current unit end time is a single millisecond before the start time of the
		// next unit we can simply add 1 (millisecond) to figure out the time for the start of the next unit
		lastTime = unitEndTime + 1;
		// increment leftOffset of the next unit by the current width
		leftOffset += widthPercentage;
	}
	return units;
};

// This is used for ranges between 2 weeks and 3 months.
export const getWeekUnits = (startDate: number, endDate: number) =>
	getUnits(startDate, endDate, startOfUtcWeek, endOfUtcWeek);

// This is used for ranges between 3 and 12 months.
export const getMonthUnits = (startDate: number, endDate: number) =>
	getUnits(startDate, endDate, startOfUtcMonth, endOfUtcMonth);

// This is used for ranges between 1 and 5 years.
export const getQuarterUnits = (startDate: number, endDate: number, fiscalMonth = 1) =>
	getUnits(
		startDate,
		endDate,
		buildStartOfUtcQuarter(fiscalMonth),
		buildEndOfUtcQuarter(fiscalMonth),
	);

// This is used for ranges greater than 5 years.
export const getYearUnits = (startDate: number, endDate: number, fiscalMonth = 1) =>
	getUnits(startDate, endDate, buildStartOfUtcYear(fiscalMonth), buildEndOfUtcYear(fiscalMonth));

export const getTimelineUnitsPure = (timelineRange: TimelineRange) => {
	const { start, end } = timelineRange;
	const daysInRange = (end - start) / ONE_DAY;

	if (daysInRange <= 14) {
		return getDayUnits(start, daysInRange);
	}
	if (daysInRange <= 93) {
		return getWeekUnits(start, end);
	}
	if (daysInRange <= 364) {
		return getMonthUnits(start, end);
	}
	if (daysInRange <= 1825) {
		return getQuarterUnits(start, end);
	}
	return getYearUnits(start, end);
};

export const getTimelineUnits = createSelector([getTimelineRange], getTimelineUnitsPure);
