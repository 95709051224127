import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	projectMenuTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.project.project-menu-title',
		defaultMessage: 'Choose project',
		description: 'This is a title for project menu',
	},
	projectSearchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.project.project-search-placeholder',
		defaultMessage: 'Find projects...',
		description: 'This is a placeholder for search',
	},
});
