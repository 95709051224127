import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { isEmbedSmartLinkView } from '@atlassian/jira-portfolio-3-common/src/window/index.tsx';
import {
	isReportView,
	isExportView,
	isExportPreview,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import { createTestEvent } from '@atlassian/jira-product-analytics-bridge/src/test-utils';
import { SET_PLAN_INFO, type SetPlanInfoAction } from '../actions.tsx';
import {
	REPORT_INITIAL_LOAD_ANALYTICS,
	SET_MODE,
	SET_ANALYTICS_EVENT,
	SET_IS_EXPORT_PREVIEW,
	SET_BROWSER_HISTORY,
	type ReportInitialLoadAnalyticsAction,
	type SetModeAction,
	type SetAnalyticsEventAction,
	type SetExportPreviewAction,
	type SetBrowserHistoryAction,
} from './actions.tsx';
import { EDIT, REPORT, READONLY, EXPORT, type App } from './types.tsx';

type Action =
	| ReportInitialLoadAnalyticsAction
	| SetModeAction
	| SetAnalyticsEventAction
	| SetPlanInfoAction
	| SetExportPreviewAction
	| SetBrowserHistoryAction;

export const getInitialMode = () => {
	if (isReportView()) {
		return REPORT;
	}
	if (isExportView()) {
		return EXPORT;
	}
	if (fg('smart_links_for_plans')) {
		if (isEmbedSmartLinkView()) {
			return READONLY;
		}
	}
	return EDIT;
};

const getInitialState = (): App => ({
	mode: getInitialMode(),
	analyticsEvent: createTestEvent(),
	hasReportedInitialLoadAnalytics: false,
	isPlanInfoReady: false,
	isExportPreview: isExportPreview(),
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: App = getInitialState(), action: Action): App => {
	switch (action.type) {
		case REPORT_INITIAL_LOAD_ANALYTICS: {
			return { ...state, hasReportedInitialLoadAnalytics: true };
		}
		case SET_MODE: {
			const mode = action.payload;
			if (state.mode === READONLY) {
				return state;
			}
			return R.assoc('mode', mode, state);
		}
		case SET_ANALYTICS_EVENT: {
			const analyticsEvent = action.payload;
			return R.assoc('analyticsEvent', analyticsEvent, state);
		}
		case SET_PLAN_INFO: {
			return R.assoc('isPlanInfoReady', true, state);
		}
		case SET_IS_EXPORT_PREVIEW: {
			return R.assoc('isExportPreview', action.payload, state);
		}
		case SET_BROWSER_HISTORY: {
			const browserHistory = action.payload;
			return R.assoc('browserHistory', browserHistory, state);
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
