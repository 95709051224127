import React from 'react';
import Anchor from '@atlaskit/primitives/anchor';
import type { Props } from './types.tsx';
import URLs from './urls.tsx';

function DocLink({ href, ariaLabel, children, tagRef }: Props) {
	return (
		<Anchor href={href} ref={tagRef} target="_blank" aria-label={ariaLabel}>
			{children}
		</Anchor>
	);
}

DocLink.URLs = URLs;
export default DocLink;
