import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	removedFromActiveSprintHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.removed-from-active-sprint-header',
		defaultMessage: 'This issue has been removed from an active sprint',
		description: 'Message to show in the header when issue was removed from the active sprint',
	},
	removedFromActiveSprintMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.removed-from-active-sprint-message',
		defaultMessage:
			"Saving this change will remove the issue from the scope of a sprint that's in progress.",
		description: 'Message to show in the content when issue was removed from the active sprint',
	},
	addedToActiveSprintHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.added-to-active-sprint-header',
		defaultMessage: 'This issue will be added to an active sprint',
		description: 'Message to show in the header when issue was added to the active sprint',
	},
	addedToActiveSprintMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.update-jira.added-to-active-sprint-message',
		defaultMessage: "Saving this change will add the issue to a sprint that's already in progress.",
		description: 'Message to show in the content when issue was added to the active sprint',
	},
});
