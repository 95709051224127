.issueTitle {
	display: flex;
	background-color: var(--ds-background-neutral, var(--adg3-color-N10));
	border-radius: 3px;
	margin-top: 5px;
	margin-bottom: 5px;
	padding: 8px;
	cursor: pointer;
}

.issueTitle:hover {
	background-color: var(--ds-background-neutral-subtle-hovered, var(--adg3-color-N20));
}

.issueIcon {
	width: var(--jpo-icon-size-small);
	min-width: var(--jpo-icon-size-small);
	height: var(--jpo-icon-size-small);
	margin-top: 2px;
	margin-right: 5px;
}

.issueSummary {
	margin-left: 5px;
	min-width: 250px;
	word-break: break-all;
}
