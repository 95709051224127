import React, { type RefObject, useMemo } from 'react';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { useColumnWidths } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { DragAutoScroll, type MonitorCallback } from '../../drag-auto-scroll/index.tsx';
import { COLUMN } from '../constants.tsx';

type Props = {
	containerRef: RefObject<HTMLElement | null>;
};

export const ColumnDragAutoScroll = ({ containerRef }: Props) => {
	const [columnWidths] = useColumnWidths({ preview: false });

	// This component is only used in the list view, since that is the only place the field columns are scrollable.
	// The first column is the sticky scope column, so we need to offset the scroll hit box by the width of that column.
	const marginLeft = columnWidths[0];

	const monitors = useMemo<MonitorCallback[]>(
		() => [
			({ start, update, end }) =>
				monitorForElements({
					canMonitor: ({ source }) => source.data.type === COLUMN,
					onDragStart: start,
					onDropTargetChange: ({ location }) => update(location.current.input),
					onDrag: ({ location }) => update(location.current.input),
					onDrop: end,
				}),
		],
		[],
	);

	return (
		<DragAutoScroll
			allowedAxis="horizontal"
			containerRef={containerRef}
			insetHitbox={{ top: 50, left: marginLeft }}
			monitors={monitors}
		/>
	);
};
