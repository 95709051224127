import type { Effect } from 'redux-saga';
import difference from 'lodash/difference';
import { put, call, select, takeEvery, fork } from 'redux-saga/effects';
import { update as updateIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/actions.tsx';
import { revertFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/original-issues/actions.tsx';
import { getLazyGoalsByARI } from '../../query/issue-goals/index.tsx';
import { getPlan } from '../../query/plan/index.tsx';
import { isAtlasConnectInstalled as getIsAtlasConnectInstalled } from '../../query/system/index.tsx';
import { addFlag } from '../../state/domain/flags/actions.tsx';
import { GOALS_ERROR } from '../../state/domain/flags/types.tsx';
import {
	fetchGoalsStart,
	fetchGoalsSuccess,
	fetchGoalsFail,
	searchGoalsStart,
	searchGoalsSuccess,
	searchGoalsFail,
} from '../../state/domain/issue-goals/actions.tsx';
import type { LazyGoalsByARI } from '../../state/domain/issue-goals/types.tsx';
import { getCloudId } from '../../util/cloud-id.tsx';
import { doResetIssueRequest } from '../issue/index.tsx';
import { fetchGoalsByARI, searchGoalsByName } from './api/index.tsx';
import type { FetchGoalsByARIResult, SearchGoalsByNameResult } from './api/types.tsx';
import { createLazyGoals } from './utils.tsx';

export const FETCH_GOALS = 'command.issue-goals.FETCH_GOALS' as const;
export const SEARCH_GOALS = 'command.issue-goals.SEARCH_GOALS' as const;
export const RESET_GOALS = 'command.issue-goals.RESET_GOALS' as const;

export type FetchGoalsCommand = {
	type: typeof FETCH_GOALS;
	payload: string[];
};

export type SearchGoalsCommand = {
	type: typeof SEARCH_GOALS;
	payload: string;
};

export type ResetGoalsPayload = {
	issueId: string;
	goals: string[];
};
export type ResetGoalsCommand = {
	type: typeof RESET_GOALS;
	payload: ResetGoalsPayload;
};
export const fetchGoals = (goalARIs: string[]): FetchGoalsCommand => ({
	type: FETCH_GOALS,
	payload: goalARIs,
});

export const searchGoals = (payload: string): SearchGoalsCommand => ({
	type: SEARCH_GOALS,
	payload,
});

export const resetGoals = (payload: ResetGoalsPayload): ResetGoalsCommand => ({
	type: RESET_GOALS,
	payload,
});

export function* doResetGoals({
	payload: { issueId, goals },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ResetGoalsCommand): Generator<Effect, any, any> {
	const { id: planId, currentScenarioId: scenarioId } = yield select(getPlan);
	const body = {
		itemKeys: [issueId],
		planId,
		scenarioId,
		description: { goals: { reset: true } }, // hardly reset the issue status in the BE
	};
	const resetStatusResponse = yield call(doResetIssueRequest, body);
	if (resetStatusResponse?.ok) {
		yield put(updateIssue({ id: issueId, goals }));
		yield put(
			revertFields({
				id: issueId,
				fields: ['goals'],
			}),
		);
	}
}
export function* doFetchGoals({
	payload: goalARIs,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: FetchGoalsCommand): Generator<Effect, any, any> {
	const isAtlasConnectInstalled: boolean = yield select(getIsAtlasConnectInstalled);
	const lazyGoals: LazyGoalsByARI = yield select(getLazyGoalsByARI);
	const existingGoalARIs = Object.keys(lazyGoals);
	const newGoalARIs = difference(goalARIs, existingGoalARIs);

	if (isAtlasConnectInstalled && newGoalARIs.length > 0) {
		yield put(fetchGoalsStart(createLazyGoals(newGoalARIs)));

		const { lazyGoalsByARI, isError }: FetchGoalsByARIResult = yield call(
			fetchGoalsByARI,
			newGoalARIs,
		);

		if (!isError) {
			yield put(fetchGoalsSuccess(lazyGoalsByARI));
		} else {
			yield put(fetchGoalsFail({ ...createLazyGoals(newGoalARIs, false), ...lazyGoalsByARI }));
			yield put(addFlag({ key: GOALS_ERROR }));
		}
	}
}

export function* doSearchGoals({
	payload: query,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: SearchGoalsCommand): Generator<Effect, any, any> {
	yield put(searchGoalsStart());

	const cloudId: string = yield call(getCloudId);

	try {
		const { lazyGoalsByARI }: SearchGoalsByNameResult = yield call(
			searchGoalsByName,
			query,
			cloudId,
		);

		yield put(searchGoalsSuccess(lazyGoalsByARI));

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(searchGoalsFail());
		yield put(addFlag({ key: GOALS_ERROR }));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchFetchGoals(): Generator<Effect, any, any> {
	yield takeEvery(FETCH_GOALS, doFetchGoals);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchSearchGoals(): Generator<Effect, any, any> {
	yield takeEvery(SEARCH_GOALS, doSearchGoals);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchResetGoals(): Generator<Effect, any, any> {
	yield takeEvery(RESET_GOALS, doResetGoals);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchFetchGoals);
	yield fork(watchSearchGoals);
	yield fork(watchResetGoals);
}
