import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';

export const getDestinationColumnId = (
	sourceColumnId: string,
	targetColumnId: string,
	columnIds: string[],
	closestEdge: Edge,
) => {
	const sourceIndex = columnIds.indexOf(sourceColumnId);
	const targetIndex = columnIds.indexOf(targetColumnId);

	const destinationIndex = getReorderDestinationIndex({
		startIndex: sourceIndex,
		indexOfTarget: targetIndex,
		closestEdgeOfTarget: closestEdge,
		axis: 'horizontal',
	});

	return columnIds[destinationIndex];
};
