export const ALL_OTHER_ISSUES = 'all_other_issues' as const;

export type CustomFieldValuesGroupMap = { [key: string]: string[] | string | boolean };

export type CustomFieldValuesGroupV0 = {
	id: string;
	name: string;
	isExpanded: boolean;
} & CustomFieldValuesGroupMap;

export type CustomFieldValuesGroup = CustomFieldValuesGroupV0;

export type CustomFieldValuesGroupsStateV0 = { [id: string]: CustomFieldValuesGroupV0[] };
export type CustomFieldValuesGroupsState = CustomFieldValuesGroupsStateV0;
