import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import { clear as clearFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/actions.tsx';
import { clear as clearCustomFieldFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/custom-field-filter/actions.tsx';
import type { FilterId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	clearCustomFieldFilter: (customFields: CustomField[]) => {
		customFields.forEach((customField) => dispatch(clearCustomFieldFilter({ customField })));
	},
	clearFilter: (filterId: FilterId) => {
		dispatch(clearFilter(filterId));
	},
});

export default mapDispatchToProps;
