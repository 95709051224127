import React, { type ReactNode, useRef, useEffect, useContext } from 'react';
import {
	useHorizontalScrolling,
	useTimelineRuler,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { context as StickyHeaderContext } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/sticky-header/view.tsx';

type Props = {
	children: ReactNode;
};

function useFixedContainer() {
	const elRef = useRef<HTMLDivElement>(null);
	const { sticky } = useContext(StickyHeaderContext);
	const [{ zoomLevel, viewport }] = useHorizontalScrolling();
	const [{ msToPx }] = useTimelineRuler();

	useEffect(() => {
		const el = elRef.current;

		if (el == null) {
			return;
		}

		el.style.left = `${msToPx(viewport.offset)}px`;
	}, [sticky, zoomLevel, msToPx, viewport.offset]);

	return elRef;
}

export default function SprintsPlaceholderLegacy({ children }: Props) {
	const containerRef = useFixedContainer();
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div style={{ position: 'absolute' }} ref={containerRef}>
			{children}
		</div>
	);
}
