import type { Effect } from 'redux-saga';
import { select } from 'redux-saga/effects';
import type { Resource } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { getPersons, getPersonByItemKey } from '../../query/persons/index.tsx';
import { getAllResources } from '../../query/resource/index.tsx';
import messages from './messages.tsx';

export const defaultWarning = {
	header: messages.defaultWarningHeader,
	message: messages.defaultWarningContent,
	messageValues: {},
} as const;

export function getTeamErrorWarning({ error }: { error: string }) {
	switch (error) {
		case 'ENVIRONMENT_ENTITY_NOT_FOUND': {
			return {
				header: messages.teamNotFoundHeader,
				message: messages.teamNotFoundContent,
				messageValues: {},
			};
		}
		default:
			return defaultWarning;
	}
}

export function getResourceErrorWarning(
	{
		error,
	}: {
		error: string;
	},
	personTitle: string,
) {
	switch (error) {
		case 'ENVIRONMENT_ENTITY_NOT_FOUND': {
			return {
				header: messages.memberNotFoundHeader,
				message: messages.memberNotFoundContent,
				messageValues: { personTitle },
			};
		}
		case 'ENVIRONMENT_RELATED_ENTITY_NOT_FOUND': {
			return {
				header: messages.memberOrTeamNotFoundHeader,
				message: messages.memberOrTeamNotFoundContent,
				messageValues: { personTitle },
			};
		}
		default:
			return defaultWarning;
	}
}

export function inspectForCommitWarnings(
	error?: {
		error: string;
	} | null,
) {
	if (error) {
		return [getTeamErrorWarning(error)];
	}

	return [];
}

export function* inspectForResourceCommitWarnings(
	error:
		| {
				error: string;
		  }
		| null
		| undefined,
	resourceId: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	if (error) {
		const resources: Resource[] = yield select(getAllResources);
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const resource = resources.find((resource) => resource.itemKey === resourceId);

		if (resource) {
			const persons = yield select(getPersons);
			const person = getPersonByItemKey(persons, resource.personItemKey);
			const personTitle = (person && person.values.title) || '';

			return [getResourceErrorWarning(error, personTitle)];
		}
		return [getResourceErrorWarning(error, '')];
	}

	return [];
}
