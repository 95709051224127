import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import {
	mapDispatchSelect,
	mapDispatchAsyncSelect,
	mapDispatchAsyncRollupSelect,
	mapDispatchRollupSelect,
} from './command.tsx';
import { mapStateRollupSelect, mapStateToProps } from './query.tsx';
import Select, {
	SelectForColumnCell as ColumnCellSelect,
	AsyncSelectForColumnCell as ColumnCellAsyncSelect,
} from './view.tsx';
import ColumnCellSelectWithRollup, { AsyncSelectWithRollup } from './with-roll-up.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { selectUtils } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export default Select;
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { AsyncSelect, MIN_SELECT_WIDTH } from './view';

export const SelectForColumnCell = componentWithFG(
	'smart_links_for_plans',
	connect(mapStateToProps, mapDispatchSelect)(ColumnCellSelect),
	connect(null, mapDispatchSelect)(ColumnCellSelect),
);

export const CellSelectWithRollup = connect(
	mapStateRollupSelect,
	mapDispatchRollupSelect,
)(ColumnCellSelectWithRollup);

export const AsyncSelectForColumnCell = componentWithFG(
	'smart_links_for_plans',
	connect(mapStateToProps, mapDispatchAsyncSelect)(ColumnCellAsyncSelect),
	connect(null, mapDispatchAsyncSelect)(ColumnCellAsyncSelect),
);

export const AsyncCellSelectWithRollup = connect(
	mapStateRollupSelect,
	mapDispatchAsyncRollupSelect,
)(AsyncSelectWithRollup);
