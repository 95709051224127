import React, { type CSSProperties, type ReactNode } from 'react';
import { xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import IssueHierarchySkeleton from '@atlassian/jira-portfolio-3-common/src/skeleton/issue-hierarchy.tsx';
import ListSkeleton from '@atlassian/jira-portfolio-3-common/src/skeleton/list.tsx';
import TimelineSkeleton from '@atlassian/jira-portfolio-3-common/src/skeleton/timeline.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import type {
	ColumnConfig,
	RowConfig,
	RangeExtractor,
} from '@atlassian/jira-portfolio-3-treegrid/src/common/types.tsx';
import { Cell } from '@atlassian/jira-portfolio-3-treegrid/src/ui/cell/index.tsx';
import { Grid } from '@atlassian/jira-portfolio-3-treegrid/src/ui/grid/index.tsx';
import { Row } from '@atlassian/jira-portfolio-3-treegrid/src/ui/row/index.tsx';
import { EnhancedContainer } from '../utils/enhanced-container/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

type ColumnType = {
	tag: string;
	name: string;
};

const HeaderRow = ({ index, columns }: { index: number; columns: Array<ColumnType> }) => {
	const intl = useIntl();
	return (
		<Row
			index={index}
			style={
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766, but this should not be injecting a CSS property this way
					'--plan-grid-row-background': token('color.background.neutral', colors.N20A),
				} as CSSProperties
			}
		>
			{
				// eslint-disable-next-line @typescript-eslint/no-shadow
				columns.map((column, index) => {
					switch (column.tag) {
						case 'SCOPE':
							return (
								<Cell key={index} column={index} xcss={[cellStyles, headerCellStyles]}>
									{intl.formatMessage(messages.issue)}
								</Cell>
							);
						case 'FIELDS':
							return (
								<Cell key={index} column={index} xcss={[cellStyles, headerCellStyles]}>
									{intl.formatMessage(messages.fields)}
								</Cell>
							);
						case 'TIMELINE':
							return <Cell key={index} column={index} xcss={[cellStyles, headerCellStyles]} />;
						default:
							return null;
					}
				})
			}
		</Row>
	);
};

const SubheaderRow = ({ index, columns }: { index: number; columns: Array<ColumnType> }) => (
	<Row index={index}>
		{
			// eslint-disable-next-line @typescript-eslint/no-shadow
			columns.map((column, index: number) => (
				<Cell key={index} column={index} xcss={[cellStyles, headerCellStyles]} />
			))
		}
	</Row>
);

const BodyRow = ({
	index,
	columns,
	animate,
}: {
	index: number;
	columns: Array<ColumnType>;
	animate: boolean;
}) => {
	const intl = useIntl();
	return (
		<Row index={index}>
			{
				// eslint-disable-next-line @typescript-eslint/no-shadow
				columns.map((column, index) => {
					switch (column.tag) {
						case 'SCOPE':
							return (
								<Cell key={index} column={index} xcss={cellStyles}>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
									<div style={{ width: '100%', height: '600px' }}>
										<IssueHierarchySkeleton
											ariaLabel={intl.formatMessage(commonMessages.loadingIssuesMessage)}
											numOfIssues={11}
											sidePadding={16}
											showIssueSelectCheckbox
											showIssueWithoutParent
											animate={animate}
										/>
									</div>
								</Cell>
							);
						case 'FIELDS':
							return (
								<Cell key={index} column={index} xcss={cellStyles}>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
									<div style={{ width: '100%', height: '600px' }}>
										<ListSkeleton
											ariaLabel={intl.formatMessage(commonMessages.loadingIssuesMessage)}
											numOfRows={13}
											spaceInBetween={12}
											animate={animate}
										/>
									</div>
								</Cell>
							);
						case 'TIMELINE':
							return (
								<Cell key={index} column={index} xcss={cellStyles}>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
									<div style={{ width: '100%', height: '600px' }}>
										<TimelineSkeleton
											ariaLabel={intl.formatMessage(commonMessages.loadingIssuesMessage)}
											animate={animate}
										/>
									</div>
								</Cell>
							);
						default:
							return null;
					}
				})
			}
		</Row>
	);
};

export const TableSkeleton = ({ animate = true }: Props) => {
	const columnConfigs = [
		{
			id: 'scope',
			sticky: false,
		},
		{
			id: 'fields',
			sticky: false,
		},
		{
			id: 'timeline',
			sticky: false,
		},
	];

	const columnWidths: number[] = [200, ...Array(1).fill(300), undefined];

	const rowConfigs = [
		/* Header */
		{
			sticky: false,
			height: 50,
			thead: true,
			top: 0,
		},
		/* SubHeader */
		{
			sticky: false,
			height: 40,
			thead: true,
			top: 50,
		},
		{
			sticky: false,
			height: 550,
			thead: false,
			top: 90,
		},
	];

	const rows = [
		{
			tag: 'HEADER',
			content: 'header',
		},
		{
			tag: 'SUBHEADER',
			content: 'subheader',
		},
		{
			tag: 'BODY',
			content: 'body',
		},
	];

	const columns = [
		{
			tag: 'SCOPE',
			name: 'scope',
		},
		{
			tag: 'FIELDS',
			name: 'fields',
		},
		{
			tag: 'TIMELINE',
			name: 'timeline',
		},
	];

	const stickyOffset = {
		vertical: 0,
		horizontal: 0,
	};

	const uniq = (a: number[]) => Array.from(new Set(a));
	const rangeExtractor: RangeExtractor = ({ above, within, below }) =>
		uniq([0, 1, ...above, ...within, ...below]);

	const renderRow = (_row: RowConfig, _columns: ColumnConfig[], index: number): ReactNode => {
		const row = rows[index];

		if (row.tag === 'HEADER') {
			return <HeaderRow key={index} index={index} columns={columns} />;
		}

		if (row.tag === 'SUBHEADER') {
			return <SubheaderRow key={index} index={index} columns={columns} />;
		}

		return <BodyRow key={index} index={index} columns={columns} animate={animate} />;
	};

	const renderEmpty = (height?: number): ReactNode => (
		<Row index={-1} height={height}>
			{columns.map((_, index) => (
				<Cell key={index} column={index} xcss={cellStyles} />
			))}
		</Row>
	);

	return (
		<EnhancedContainer>
			<Grid
				columnConfigs={columnConfigs}
				columnWidths={columnWidths}
				rowConfigs={rowConfigs}
				row={renderRow}
				empty={renderEmpty}
				overscan={20}
				stickyOffset={stickyOffset}
				rangeExtractor={rangeExtractor}
				onColumnWidthsChange={() => null}
			>
				{(table) => table}
			</Grid>
		</EnhancedContainer>
	);
};

const cellStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderRight: `1px solid ${token('color.background.accent.gray.subtler', colors.N50)}`,
	backgroundColor: 'elevation.surface',
	paddingTop: 'space.0',
	paddingBottom: 'space.0',
	paddingLeft: 'space.250',
	boxSizing: 'border-box',
	verticalAlign: 'middle',
	fontWeight: '500',
	// @ts-expect-error - TS2322: Type number is not assignable to type
	zIndex: 0,

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	':nth-of-type(1)': {
		paddingLeft: 'space.250',
	},

	'::after': {
		content: '""',
		position: 'absolute',
		inset: 'space.0',
		background: 'var(--plan-grid-row-background)',
		// @ts-expect-error - TS2322: Type number is not assignable to type
		zIndex: -1,
	},
});

const headerCellStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.border', colors.N30)}`,
});
