import { SET, type SetActionPayload, type SetAction } from './actions.tsx';
import type { Team } from './types.tsx';

const initialState: Team[] = [];

type Action = SetAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Team[] = initialState, action: Action) => {
	switch (action.type) {
		case SET: {
			const payload: SetActionPayload = action.payload;
			return [...payload];
		}
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
