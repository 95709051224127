import { useEffect } from 'react';
import { ISSUE_SEARCH_WARNING } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/flags/types.tsx';
import type { Props } from './types.tsx';

const IssueSearchWarningFlag = ({
	issueSearchResults: { resultsHiddenByFilter },
	onAddFlag,
	onRemoveFlag,
}: Props) => {
	useEffect(() => {
		if (resultsHiddenByFilter.length > 0) {
			onAddFlag({ key: ISSUE_SEARCH_WARNING });
		} else if (resultsHiddenByFilter.length === 0) {
			onRemoveFlag({ key: ISSUE_SEARCH_WARNING });
		}
	}, [resultsHiddenByFilter, onAddFlag, onRemoveFlag]);

	return null;
};

export default IssueSearchWarningFlag;
