import type { Effect } from 'redux-saga';
import { fork, takeLatest, call, put, select } from 'redux-saga/effects';
import { getMode } from '../../query/app/index.tsx';
import { getCustomFields } from '../../query/custom-fields/index.tsx';
import { getIssueStatuses } from '../../query/issue-statuses/index.tsx';
import { getPlan } from '../../query/plan/index.tsx';
import { getProjects } from '../../query/projects/index.tsx';
import { getIssues } from '../../query/raw-issues/index.tsx';
import { getIsSolutionValid } from '../../query/solution/index.tsx';
import { getSprints, getSprintsWithFutureDates } from '../../query/sprints/index.tsx';
import { getAllTeamsById } from '../../query/teams/index.tsx';
import { getDisabledWarnings } from '../../query/warnings/index.tsx';
import { SET_MODE } from '../../state/domain/app/actions.tsx';
import { SET_VALIDITY } from '../../state/domain/solution/actions.tsx';
import { SET_SHOW_ROLLED_UP_DATE as NEW_SET_SHOW_ROLLED_UP_DATE } from '../../state/domain/view-settings/rollup-settings/actions.tsx';
import { TOGGLE_WARNING_TYPE } from '../../state/domain/view-settings/warning-settings/actions.tsx';
import { setWarnings } from '../../state/domain/warnings/actions.tsx';
import { MISCONFIGURED_CHILDREN } from '../../state/domain/warnings/types.tsx';
import { genericError } from '../errors/index.tsx';
import { calculateAllWarnings } from './engine.tsx';
import { headerMessages, bodyMessages } from './messages.tsx';

const CALCULATE_WARNINGS = 'command.warnings.CALCULATE_WARNINGS' as const;

export const calculateWarnings = () => ({
	type: CALCULATE_WARNINGS,
});

export const misconfiguredChildrenWarning = () => ({
	type: MISCONFIGURED_CHILDREN,
	header: headerMessages.misconfiguredChildren,
	message: bodyMessages.misconfiguredChildren,
	messageValues: {},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doCalculateWarnings(): Generator<Effect, any, any> {
	const isSolutionValid = yield select(getIsSolutionValid);
	const mode = yield select(getMode);
	const issues = yield select(getIssues);
	const projects = yield select(getProjects);
	const sprints = yield select(getSprints);
	const issueStatuses = yield select(getIssueStatuses);
	const customFields = yield select(getCustomFields);
	const sprintsWithFutureDates = yield select(getSprintsWithFutureDates);
	const plan = yield select(getPlan);
	const disabledWarnings = yield select(getDisabledWarnings);
	const teamsById = yield select(getAllTeamsById);

	try {
		const warnings = yield call(calculateAllWarnings, {
			isSolutionValid,
			mode,
			issues,
			projects,
			sprints,
			issueStatuses,
			customFields,
			sprintsWithFutureDates,
			plan,
			disabledWarnings,
			teamsById,
		});
		yield put(setWarnings(warnings));
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchCalculateWarnings(): Generator<Effect, any, any> {
	yield takeLatest(
		[
			CALCULATE_WARNINGS,
			SET_MODE,
			SET_MODE,
			SET_VALIDITY,
			NEW_SET_SHOW_ROLLED_UP_DATE,
			TOGGLE_WARNING_TYPE,
		],
		doCalculateWarnings,
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchCalculateWarnings);
}
