import * as R from 'ramda';
import { updateRefs } from '../util.tsx';
import {
	RESET,
	type ResetAction,
	UPDATE,
	type UpdateAction,
	UPDATE_REFERENCES,
	type UpdateReferencesAction,
} from './actions.tsx';
import type { OriginalVersions } from './types.tsx';

type Action = ResetAction | UpdateAction | UpdateReferencesAction;

const initialState: OriginalVersions = {};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: OriginalVersions = initialState, action: Action) => {
	switch (action.type) {
		case RESET:
			return { ...action.payload };
		case UPDATE: {
			const { id, values } = action.payload;
			return {
				...state,
				[id]: R.merge(values, state[id]),
			};
		}
		case UPDATE_REFERENCES:
			return updateRefs(state, action.payload);
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
