import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ReactRouterCompatibleHistory } from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';
import type { Mode } from './types.tsx';

export const SET_MODE = 'state.domain.app.SET_MODE' as const;
export const SET_ANALYTICS_EVENT = 'state.domain.app.SET_ANALYTICS_EVENT' as const;
export const REPORT_INITIAL_LOAD_ANALYTICS =
	'state.domain.app.REPORT_INITIAL_LOAD_ANALYTICS' as const;
export const SET_IS_EXPORT_PREVIEW = 'state.domain.app.SET_IS_EXPORT_PREVIEW' as const;
export const SET_BROWSER_HISTORY = 'state.domain.app.SET_BROWSER_HISTORY' as const;

export type SetModeAction = {
	type: typeof SET_MODE;
	payload: Mode;
};

export type SetAnalyticsEventAction = {
	type: typeof SET_ANALYTICS_EVENT;
	payload: UIAnalyticsEvent;
};

export type ReportInitialLoadAnalyticsAction = {
	type: typeof REPORT_INITIAL_LOAD_ANALYTICS;
};

export type SetExportPreviewAction = {
	type: typeof SET_IS_EXPORT_PREVIEW;
	payload: boolean;
};

export type SetBrowserHistoryAction = {
	type: typeof SET_BROWSER_HISTORY;
	payload: ReactRouterCompatibleHistory;
};

export const setMode = (payload: Mode): SetModeAction => ({
	type: SET_MODE,
	payload,
});

export const setAnalyticsEvent = (payload: UIAnalyticsEvent): SetAnalyticsEventAction => ({
	type: SET_ANALYTICS_EVENT,
	payload,
});

export const reportInitialLoadAnalytics = (): ReportInitialLoadAnalyticsAction => ({
	type: REPORT_INITIAL_LOAD_ANALYTICS,
});

export const setIsExportPreview = (payload: boolean): SetExportPreviewAction => ({
	type: SET_IS_EXPORT_PREVIEW,
	payload,
});

export const setBrowserHistory = (
	payload: ReactRouterCompatibleHistory,
): SetBrowserHistoryAction => ({
	type: SET_BROWSER_HISTORY,
	payload,
});
