import { defineMessages } from '@atlassian/jira-intl';
import { PlanningUnits } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

export default defineMessages({
	setHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-estimate.set-header',
		defaultMessage: 'Update estimate',
		description: 'Modal dialog header for dialog estimate',
	},
	variousValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-estimate.various-values',
		defaultMessage: 'Various estimates',
		description: 'Label for various estimates values',
	},
	newEstimate: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-estimate.new-estimate',
		defaultMessage: 'New estimate ({planningUnit})',
		description: 'Label for the new estimate value',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	[PlanningUnits.storyPoints]: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-estimate-dialog.story-points',
		defaultMessage: 'story points',
		description: 'New estimate unit label for story points',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	[PlanningUnits.days]: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-estimate-dialog.days',
		defaultMessage: 'days',
		description: 'New estimate unit label for days',
	},
	// eslint-disable-next-line jira/i18n/id-named-by-package
	[PlanningUnits.hours]: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.set-estimate-dialog.hours',
		defaultMessage: 'hours',
		description: 'New estimate unit label for hours',
	},
	outOfRange: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-estimate.out-of-range',
		defaultMessage: 'The estimate must be a positive number.',
		description: 'Validation error message when the value is ≤0',
	},
	clearEstimates: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-estimate.clear-estimates',
		defaultMessage: 'Clear estimates',
		description: 'Checkbox to clear estimates in bulk',
	},
});
