import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-show-and-tell.heading',
		defaultMessage: 'A view to remember',
		description: 'The title for the spotlight',
	},
	description: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-show-and-tell.description',
		defaultMessage:
			"Filter issues based on what's relevant to you, then save it to tell a unique story. Make multiple views to curate your plan for different audiences.",
		description: 'The content for the spotlight',
	},
});
