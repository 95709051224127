import type { Dispatch } from 'redux';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/index.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { MapDispatchToProps } from './types.tsx';

const dispatchMethods: MapDispatchToProps = (dispatch: Dispatch) => ({
	onChange: (issue: ScopeIssue, customFieldId: string, option: OptionType | null) => {
		dispatch(
			updateIssueOrInlineCreate({
				id: issue.id,
				customFields: {
					[customFieldId]: option ? option.value : undefined,
				},
			}),
		);
	},
});

export default dispatchMethods;
