.projectTitleContainer {
	display: flex;
	height: calc(1.5 * var(--jpo-icon-size-small));
	align-items: center;
}

.xsmall-spacing {
	padding-left: calc(0.5 * var(--jpo-common-base-spacing));
}

.small-spacing {
	padding-left: var(--jpo-common-base-spacing);
}

.projectTitle {
	box-sizing: border-box;
	padding-right: calc(3 * var(--jpo-common-base-spacing));
	flex: 1 0 auto;
	/*
        450px - max-width of filter container
        calc(1.5 * var(--jpo-icon-size-small)) - it's the icon size
        calc(5.5 * var(--jpo-common-base-spacing)) - paddings and one margin
    */
	max-width: calc(
		450px - calc(5.5 * var(--jpo-common-base-spacing)) - calc(1.5 * var(--jpo-icon-size-small))
	);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
