.option {
	display: flex;
	align-items: center;
	line-height: 1.1;
}

.optionAvatar {
	display: flex;
	margin-right: var(--jpo-common-base-spacing);
}

.optionEmail {
	font-size: 12px;
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
}

.optionUnassigned {
	display: flex;
	align-items: center;
}
