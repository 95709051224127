.content {
	padding: calc(3 * var(--jpo-common-base-spacing));
	flex: 1;
}

.shareButton {
	margin-left: var(--jpo-common-base-spacing);
}

.contentHeading {
	margin-bottom: calc(3 * var(--jpo-common-base-spacing));
}

.contentBody {
	margin: 10px 0;
}

.contentBodyIntro {
	margin-bottom: calc(4 * var(--jpo-common-base-spacing));
}

.contentFooter {
	text-align: right;
	margin-top: calc(3 * var(--jpo-common-base-spacing));
}

.urlForm {
	margin: 10px 0;
	display: flex;
	align-items: center;
}

.urlTextfield {
	flex-grow: 1;
	margin-right: 5px;
}

.copyButton {
	flex-grow: 0;
}

.copiedMessage {
	margin-left: 10px;
	flex-grow: 0;
	width: 50px;
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	font-weight: 600;
}

.accessControl {
	margin-top: 20px;
	display: flex;
}

.accessControlMessage {
	flex-grow: 1;
	margin-left: 10px;
}

.accessControlMacroIcon {
	margin-top: -2px;
}

.modalBody {
	border-radius: 3px;
	background: var(--ds-surface-overlay, var(--adg3-color-N0));
	display: flex;
}

.generateLinkButton {
	margin-right: var(--jpo-common-base-spacing);
}

.sideNavigation {
	width: 240px;
}

.shareButtonIconOnly {
	display: flex;
}

.shareButtonLabelOnly {
	display: none;
}

/* this applies only to plan NOT in REPORT or READONLY modes */
@media (min-width: 1366px) {
	.shareButtonIconOnly {
		display: none;
	}

	.shareButtonLabelOnly {
		display: flex;
	}
}
