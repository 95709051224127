.container {
	height: 40px;
	display: flex;
	align-items: center;
	padding-left: 10px;
	white-space: nowrap;

	& a {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
