import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fixedTabLabel: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.fixed-tab-label',
		defaultMessage: 'Fixed',
		description: 'Label for "fixed" tab title',
	},
	relativeTabLabel: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.relative-tab-label',
		defaultMessage: 'Relative',
		description: 'Label for "relative" tab title',
	},
	apply: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.apply',
		defaultMessage: 'Apply',
		description: 'It is a label for a apply button.',
	},
	cancel: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.cancel',
		defaultMessage: 'Cancel',
		description: 'It is a label for a cancel button.',
	},
});
