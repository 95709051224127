import type { ComponentType } from 'react';
import type { IntlShape } from '@atlassian/jira-intl';
import type { Change } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types.tsx';
import type { ExpandedChanges } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types.tsx';
import type { OwnProps as AssigneeOwnProps } from './assignee/types.tsx';
import type { OwnProps as AssociatedIssuesOwnProps } from './associated-issues/types.tsx';
import type { OwnProps as CrossProjectReleaseOwnProps } from './cross-project-release/types.tsx';
import type { OwnProps as CustomFieldOwnProps } from './custom-field/types.tsx';
import type { OwnProps as EstimateOwnProps } from './estimate/types.tsx';
import type { OwnProps as GoalsOwnProps } from './goals/types.tsx';
import type { OwnProps as IssueLinkOwnProps } from './issue-link/types.tsx';
import type { OwnProps as PriorityOwnProps } from './priority/types.tsx';
import type { OwnProps as ProjectOwnProps } from './project/types.tsx';
import type { OwnProps as ReleaseOwnProps } from './release/types.tsx';
import type { Props as PlannedCapacityProps } from './sprint-velocity/types.tsx';
import type { OwnProps as SprintOwnProps } from './sprint/types.tsx';
import type { OwnProps as StatusOwnProps } from './status/types.tsx';
import type { OwnProps as TeamOwnProps } from './team/types.tsx';

export const VALUE_TYPE = {
	CURRENT: 'current',
	NEW: 'new',
} as const;

export type StateProps = {
	expandedChanges: ExpandedChanges;
};

export type FromDefaultProps = {
	AssigneeComponent: ComponentType<AssigneeOwnProps>;
	CrossProjectReleaseComponent: ComponentType<CrossProjectReleaseOwnProps>;
	EstimateComponent: ComponentType<EstimateOwnProps>;
	IssueLinkComponent: ComponentType<IssueLinkOwnProps>;
	ProjectComponent: ComponentType<ProjectOwnProps>;
	PriorityComponent: ComponentType<PriorityOwnProps>;
	ReleaseComponent: ComponentType<ReleaseOwnProps>;
	SprintComponent: ComponentType<SprintOwnProps>;
	TeamComponent: ComponentType<TeamOwnProps>;
	CustomFieldComponent: ComponentType<CustomFieldOwnProps>;
	PlannedCapacityComponent: ComponentType<PlannedCapacityProps>;
	StatusComponent: ComponentType<StatusOwnProps>;
	GoalsComponent: ComponentType<GoalsOwnProps>;
	AssociatedIssuesComponent: ComponentType<AssociatedIssuesOwnProps>;
};

export type Props = StateProps &
	FromDefaultProps & {
		intl: IntlShape;
		change: Change;
		valueType: 'current' | 'new';
	};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type State = Record<any, any>;
