import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorIconLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.error-notification.error-icon-label',
		defaultMessage: 'Error icon',
		description: 'Label for error icon',
	},
	errorMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.error-notification.error-message',
		defaultMessage:
			'Wait a few seconds, then try again. If this keeps happening, let us know at {emailLink}.',
		description: 'Label for view log button',
	},
	errorTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.error-notification.error-title',
		defaultMessage: 'Something went wrong.',
		description: 'Title for error flag',
	},
	stackTraceNotFoundMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.error-notification.stack-trace-not-found-message',
		defaultMessage: 'There is no stacktrace available.',
		description: 'Message when no stacktrace is found',
	},
	viewLog: {
		id: 'portfolio-3-portfolio.app-simple-plans.error-notification.view-log',
		defaultMessage: 'View Log',
		description: 'Label for view log button',
	},
	dismiss: {
		id: 'portfolio-3-portfolio.app-simple-plans.error-notification.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Label for dismiss button',
	},
	dismissAll: {
		id: 'portfolio-3-portfolio.app-simple-plans.error-notification.dismiss-all',
		defaultMessage: 'Dismiss all',
		description: 'Label for dismiss all button',
	},
});
