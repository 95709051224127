.inline-dialog-child {
	min-width: 250px;
}

.option {
	display: flex;
}

.avatar {
	display: inline-flex;
	margin-right: var(--jpo-common-base-spacing);
}

.label {
	display: inline-flex;
	align-items: center;
}

.no-matches {
	padding: var(--jpo-common-base-spacing) calc(2 * var(--jpo-common-base-spacing));
	color: var(--ds-text-disabled, var(--adg3-color-N200));
	text-align: center;
}

.spinner {
	min-width: 200px;
	margin: calc(2 * var(--jpo-common-base-spacing));
	text-align: center;
}
