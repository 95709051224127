import React, { Component } from 'react';
import StandardButton from '@atlaskit/button/standard-button';
import EditorCloseIcon from '@atlaskit/icon/glyph/editor/close';
import CrossIcon from '@atlaskit/icon/utility/cross';
import { Bleed, Box, Text, xcss } from '@atlaskit/primitives';
import SectionMessage from '@atlaskit/section-message';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import IssueLink from '@atlassian/jira-portfolio-3-common/src/issue-link/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class InfoMessage extends Component<Props, State> {
	static defaultProps = {
		// using for story
		isDefaultFlyoutOpen: false,
	};

	state = {
		lowerIssuesFlyout: this.props.isDefaultFlyoutOpen,
		higherIssuesFlyout: false,
	};

	toggleFlyout = (flyoutType: string) => {
		if (flyoutType === 'lowerIssuesFlyout' && !this.state.lowerIssuesFlyout) {
			this.setState(() => ({
				lowerIssuesFlyout: true,
				higherIssuesFlyout: false,
			}));
		} else if (flyoutType === 'higherIssuesFlyout' && !this.state.higherIssuesFlyout) {
			this.setState(() => ({
				higherIssuesFlyout: true,
				lowerIssuesFlyout: false,
			}));
		} else {
			this.closeFlyout();
		}
	};

	closeFlyout = () => {
		this.setState(() => ({
			lowerIssuesFlyout: false,
			higherIssuesFlyout: false,
		}));
	};

	issueOptionRenderer = (issue: Issue, i: number) => {
		const { projectsById, issueTypesById } = this.props;
		const projectKey: string =
			(issue.project && projectsById[issue.project] && projectsById[issue.project].key) || '';
		const issueTypeIconUrl = issueTypesById[issue.type] && issueTypesById[issue.type].iconUrl;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles['issue-option-wrapper']} key={`${i}-option`}>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles['issue-type-icon']}
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						backgroundSize: 'contain',
						backgroundImage: `url(${issueTypeIconUrl})`,
					}}
				/>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.link}>
					<IssueLink issueKey={issue.issueKey} projectKey={projectKey} />
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles['issue-label']} title={issue.summary}>
					{issue.summary}
				</div>
			</div>
		);
	};

	renderFlyout = (issues: Array<Issue>) => {
		const { intl } = this.props;
		return (
			<Box
				xcss={flyoutStyles}
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.info-message.div"
			>
				<Bleed block="space.100" inline="space.200" xcss={closeFlyoutStyles}>
					{fg('jira-portfolio-bulk-actions-button-name-a11y-fix') ? (
						<StandardButton
							appearance="subtle-link"
							iconBefore={<CrossIcon label="" />}
							onClick={() => this.closeFlyout()}
							aria-label={intl.formatMessage(commonMessages.close)}
						/>
					) : (
						<Button
							appearance="subtle-link"
							iconBefore={
								<EditorCloseIcon
									label=""
									testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.info-message.editor-close-icon"
									primaryColor={token('color.text.subtle', colors.N200)}
								/>
							}
							onClick={() => this.closeFlyout()}
							spacing="none"
							aria-label={intl.formatMessage(commonMessages.close)}
						/>
					)}
				</Bleed>
				{issues.map(this.issueOptionRenderer)}
			</Box>
		);
	};

	renderInlineDialog = (issues: Array<Issue>, flyoutType: keyof typeof this.state) => {
		const { intl } = this.props;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles['inline-dialog-container']}>
				<InlineDialog
					content={this.renderFlyout(issues)}
					isOpen={this.state[flyoutType]}
					placement="bottom"
					noHorizontalScroll
					classNames="inline-dialog"
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.info-message.inline-dialog"
				>
					<Button
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.info-message.button"
						appearance="link"
						spacing="none"
						onClick={() => this.toggleFlyout(flyoutType)}
					>
						{intl.formatMessage(messages.linkLabel, { issueCount: issues.length })}
					</Button>
				</InlineDialog>
			</div>
		);
	};

	sectionMessageRenderer = () => {
		const { issuesWithLowerPosition, issuesWithHigherPosition } = this.props;
		if (!issuesWithLowerPosition.length && !issuesWithHigherPosition.length) {
			return null;
		}

		if (issuesWithLowerPosition.length && issuesWithHigherPosition.length) {
			return (
				<SectionMessage appearance="information">
					<>
						<Text as="span">
							<FormattedMessage {...messages.flagMessageTitlePart} />
						</Text>
						<Box as="ul">
							<Box as="li">
								<FormattedMessage
									{...messages.flagMessageLowerPositionPart}
									values={{
										issuesWithLowerPosition: this.renderInlineDialog(
											issuesWithLowerPosition,
											'lowerIssuesFlyout',
										),
									}}
								/>
							</Box>
							<Box as="li">
								<FormattedMessage
									{...messages.flagMessageHigherPositionPart}
									values={{
										issuesWithHigherPosition: this.renderInlineDialog(
											issuesWithHigherPosition,
											'higherIssuesFlyout',
										),
									}}
								/>
							</Box>
						</Box>
					</>
				</SectionMessage>
			);
		}

		if (issuesWithLowerPosition.length) {
			return (
				<SectionMessage appearance="information">
					<FormattedMessage {...messages.flagMessageTitlePart} />
					{', '}
					<FormattedMessage
						{...messages.flagMessageLowerPositionPart}
						values={{
							issuesWithLowerPosition: this.renderInlineDialog(
								issuesWithLowerPosition,
								'lowerIssuesFlyout',
							),
						}}
					/>
				</SectionMessage>
			);
		}

		if (issuesWithHigherPosition.length) {
			return (
				<SectionMessage appearance="information">
					<FormattedMessage {...messages.flagMessageTitlePart} />
					{', '}
					<FormattedMessage
						{...messages.flagMessageHigherPositionPart}
						values={{
							issuesWithHigherPosition: this.renderInlineDialog(
								issuesWithHigherPosition,
								'higherIssuesFlyout',
							),
						}}
					/>
				</SectionMessage>
			);
		}
	};

	render() {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		return <section className={styles['section-message']}>{this.sectionMessageRenderer()}</section>;
	}
}

export default injectIntl(InfoMessage);

const flyoutStyles = xcss({
	maxWidth: '480px',
	position: 'relative',
	paddingTop: 'space.100',
	paddingRight: 'space.150',
	paddingBottom: 'space.050',
	paddingLeft: '0',
});

const closeFlyoutStyles = xcss({
	position: 'absolute',
	top: '0',
	right: '0',
});
