import React, { type ComponentType, Component } from 'react';
import type { BREAKDOWN_SPRINT_CAPACITY_BY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapStateToProps from './query.tsx';
import View from './view.tsx';

export default connect(mapStateToProps, {})(View);

export const createBreakdownCell = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	EstimateCellComponent: ComponentType<any>,
	breakdownBy: BREAKDOWN_SPRINT_CAPACITY_BY,
) =>
	// eslint-disable-next-line jira/react/no-class-components, @typescript-eslint/no-explicit-any
	class extends Component<any, any> {
		render() {
			return <EstimateCellComponent breakdownBy={breakdownBy} {...this.props} />;
		}
	};
