import * as R from 'ramda';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	RESET,
	type ResetAction,
	CLEAR_ALL,
	type ClearAllAction,
	ADD,
	type AddAction,
	UPDATE,
	type UpdateAction,
	REMOVE,
	type RemoveAction,
} from './actions.tsx';
import { type LabelGroupsState, ALL_OTHER_ISSUES } from './types.tsx';

export type Action = ResetAction | ClearAllAction | AddAction | UpdateAction | RemoveAction;

const initialState: LabelGroupsState = [
	{
		id: ALL_OTHER_ISSUES,
		name: '',
		isExpanded: false,
		labels: [],
	},
];

// V0 initial state can not change. So if you are updating the initial state, please copy the previous state to here
export const initialStateV0: LabelGroupsState = initialState;

const reducer = (state: LabelGroupsState = initialState, action: Action): LabelGroupsState => {
	switch (action.type) {
		case RESET: {
			const initialGroup = action.payload.find(({ id }) => id === ALL_OTHER_ISSUES);
			if (isDefined(initialGroup)) {
				return [...action.payload];
			}
			return [...initialState, ...action.payload];
		}
		case CLEAR_ALL: {
			return initialState;
		}
		case ADD: {
			return [...state, action.payload];
		}
		case UPDATE: {
			const { payload } = action;
			const index = state.findIndex(({ id }) => id === payload.id);
			return R.adjust(index, (label) => R.merge(label, payload.patch), state);
		}
		case REMOVE: {
			const { payload } = action;
			return state.filter(({ id }) => id !== payload);
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

export default reducer;
