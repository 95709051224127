import { styled } from '@compiled/react';
import Button, { type ButtonProps } from '@atlaskit/button';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
/**
 * Below styles have migrated from Emotion are needed to override the styles from the AKButton component.
 */

const getButtonStyles = () => {
	if (isVisualRefreshEnabled()) {
		return {
			fontWeight: token('font.weight.semibold'),
			'::after': {
				border: 'none',
			},
		};
	}
	return {
		fontWeight: token('font.weight.semibold'),
	};
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DefaultButton = styled(Button)(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		'&&': getButtonStyles(),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral', colors.N20A)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.N400)} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral.hovered', colors.N30A)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.N400)} !important`,
			},
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ToDoButton = styled(Button)(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		'&&': getButtonStyles(),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral', colors.N40)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.N800)} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.neutral.hovered', colors.N50)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.N800)} !important`,
			},
		},
);
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const InProgressComponentVisualRefresh = styled(Button)(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		'&&': getButtonStyles(),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.accent.blue.subtle', colors.B600)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.T30)} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.accent.blue.subtle', colors.B700)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.T30)} !important`,
			},
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const InProgressComponentCurrent = styled(Button)(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		'&&': getButtonStyles(),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.information.bold', colors.B400)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text.inverse', colors.N0)} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.information.bold.hovered', colors.B500)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text.inverse', colors.N0)} !important`,
			},
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles
export const InProgressButton = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1'),
	InProgressComponentVisualRefresh,
	InProgressComponentCurrent,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DoneComponentVisualRefresh = styled(Button)(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		'&&': getButtonStyles(),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.accent.lime.subtle', colors.L50)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.T30)} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.accent.lime.subtle', colors.L75)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text', colors.T30)} !important`,
			},
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DoneComponentCurrent = styled(Button)(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values
		'&&': getButtonStyles(),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props: ButtonProps) =>
		!props.isSelected && {
			'&&:not(:active), &&:disabled': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.success.bold', colors.G400)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text.inverse', colors.N0)} !important`,
			},
			'&&:hover:not(:active)': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				background: `${token('color.background.success.bold.hovered', colors.G500)} !important`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				color: `${token('color.text.inverse', colors.N0)} !important`,
			},
		},
);

export const DoneButton = componentWithCondition(
	() => isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1'),
	DoneComponentVisualRefresh,
	DoneComponentCurrent,
);
