import { createMigrate, incremental } from '../common/migrate.tsx';
import type { ComponentGroupsStateV0 } from './types.tsx';

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: ComponentGroupsStateV0): ComponentGroupsStateV0 {
				return prevState;
			},
		},
		{ prefix: 'componentGroups', from: 'V0' },
	),
);
