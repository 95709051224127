import type { ServerRestStatus } from '@atlassian/jira-issue-shared-types/src/common/types/status-type.tsx';
import {
	DUE_DATE_PORTFOLIO_TYPE,
	TARGET_START_TYPE,
	TARGET_END_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import type { ColumnId } from '../../app-simple-plans/state/domain/view-settings/field-columns/types.tsx';
import type { Warning } from '../../app-simple-plans/state/domain/warnings/types.tsx';
import type {
	IssueId,
	ProjectId,
	TeamKey,
	SprintId,
	IterationId,
	Timestamp,
} from '../types/index.tsx';
import type {
	ScenarioType,
	RELEASE_STATUSES,
	SCHEDULE_MODE,
	PlanningUnit,
	IssueLinkDirectionType,
	PROJECT_TYPE_KEY,
} from '../view/constant.tsx';

export type SchedulingMode = (typeof SCHEDULE_MODE)[keyof typeof SCHEDULE_MODE];
export type StaticScheduleWarnings = Record<
	| 'not-fully-scheduled'
	| 'horizon-reached'
	| 'release-shifted'
	| 'release-conflict'
	| 'assignee-ignored'
	| 'external-team'
	| 'subtask-violation'
	| 'subtask-assignment-violation'
	| 'sprint-exceeded'
	| 'invalid-sprint'
	| 'earliest-start-ignored'
	| 'overbooked'
	| 'availability-violation'
	| 'violation-in-dependency'
	| 'resource-limit-violation'
	| 'dependencies-ignored-sprint'
	| 'dependencies-ignored-release'
	| 'cyclic-dependency'
	| 'subtask-cyclic-dependency-ignored'
	| 'subtask-no-skilled-resource'
	| 'missing-skills'
	| 'too-small-estimates'
	| 'completed-parent'
	| 'calculation-complexity',
	Warning
>;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	ASSIGNEE_TYPE as ASSIGNEE_FIELD,
	REPORTER_TYPE as REPORTER_FIELD,
	STATUS_TYPE as STATUS_FIELD,
	TEAM_TYPE as TEAM_FIELD,
	PRIORITY_TYPE as PRIORITY_FIELD,
	ESTIMATE_TYPE as ESTIMATE,
} from '@atlassian/jira-platform-field-config';

export type AutoScheduleConfiguration = {
	ignoreSprints: boolean;
	ignoreReleases: boolean;
	ignoreTeams: boolean;
};

export type CrossProjectVersionValues = {
	name: string;
};

export type CrossProjectVersion = {
	itemKey: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	originals: Record<any, any>;
	scenarioType?: string;
	values: CrossProjectVersionValues;
};

export type CustomField = {
	description: string;
	id: number;
	title: string;
	type: {
		key: string;
		title: string;
	};
	filteringAllowed: boolean;
	defaultStartDateCustomField?: boolean;
	projectId: number;
	issueTypeIds?: number[];
};

export type IssueStatus = {
	categoryId: number;
	id: string;
	name: string;
};

export type IssueStatusCategory = {
	id: number;
	key: string;
	name: string;
	color: string;
};

export type IssueStatusCategoryInfo = {
	issueStatusCategories: IssueStatusCategory[];
};

export type IssueType = {
	id: number;
	name: string;
	iconUrl: string;
	level: number;
	subTask?: boolean;
};

export type JiraUser = {
	accountId: string;
	avatarUrl: string;
	email?: string;
	title: string;
};

export type Person = {
	itemKey: string;
	jiraUser?: JiraUser;
	originals: {
		title?: string;
		avatarUrl?: string;
	};
	values: {
		title: string;
		avatarUrl: string;
	};
};

export type IssueSourceType = 'Board' | 'Project' | 'Filter';

export type IssueSource = {
	id: number;
	title?: string;
	type: IssueSourceType;
	value: string;
	sprintIds?: number[];
	hasNextGenProjects?: boolean;
	isUsingStoryPoints?: boolean;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { PlanningUnit } from '../view/constant';

export const ISSUE_INFERRED_DATE_SELECTION = {
	/** This ENUM is mainly used to represent options for date inference. This options are
	 *  provided by backend. Currently there only three options; 0 - None, 1 - Sprint, 2 - Release.
	 *  We are using this same ENUM to support rolling up of the dates as well. We are using
	 *  -1 to represent rolling up option, because in future we can introduce more options
	 *  for date inference and it could take value such as 2, 3, etc. Hence to avoid collision
	 *  with such options, we are using -1 for rolling up.
	 */
	ROLL_UP: -1,
	NONE: 0,
	SPRINT: 1,
	RELEASE: 2,
} as const;

export type IssueInferredDateSelection =
	(typeof ISSUE_INFERRED_DATE_SELECTION)[keyof typeof ISSUE_INFERRED_DATE_SELECTION];

export type ApiDateFieldAvailability = {
	available: boolean;
	unavailabilityReason?: 'out-of-scope' | 'read-only';
};

export type ApiDateField = {
	availability: ApiDateFieldAvailability;
	id: number;
	projectId: number;
	title: string;
	type: {
		key: string;
		title: string;
	};
	description?: string;
};

export const DUE_DATE = DUE_DATE_PORTFOLIO_TYPE;
export const TARGET_START_FIELD = TARGET_START_TYPE;
export const TARGET_END_FIELD = TARGET_END_TYPE;

export const TARGET_START_END_API_MAP: {
	[key: string]: string;
} = Object.freeze({
	[TARGET_START_FIELD]: 'baselineStart',
	[TARGET_END_FIELD]: 'baselineEnd',
});

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const BUILT_IN_DATE_FIELDS = Object.freeze([
	DUE_DATE,
	TARGET_START_FIELD,
	TARGET_END_FIELD,
]) as ColumnId[];

export const DATEFIELD_TYPES = Object.freeze({
	BUILT_IN: 'BuiltIn',
	CUSTOM: 'Custom',
});

export type DateFieldTypes = (typeof DATEFIELD_TYPES)[keyof typeof DATEFIELD_TYPES];

export const DATEFIELD_WARNINGS = Object.freeze({
	NOT_EXIST: 'NOT_EXIST',
	UNAVAILABLE: 'VALUE_NO_LONGER_AVAILABLE',
});

export type DateFieldWarnings = {
	code: (typeof DATEFIELD_WARNINGS)[keyof typeof DATEFIELD_WARNINGS];
	apiDateField?: ApiDateField;
};

export type DateField = {
	id: string;
	key: string;
	type: DateFieldTypes;
	warning?: DateFieldWarnings;
};

export const DUE_DATE_FIELD: DateField = {
	id: DUE_DATE,
	key: DUE_DATE,
	type: DATEFIELD_TYPES.BUILT_IN,
};

export const TARGET_START_DATE_FIELD: DateField = {
	id: TARGET_START_FIELD,
	key: TARGET_START_FIELD,
	type: DATEFIELD_TYPES.BUILT_IN,
};

export const TARGET_END_DATE_FIELD: DateField = {
	id: TARGET_END_FIELD,
	key: TARGET_END_FIELD,
	type: DATEFIELD_TYPES.BUILT_IN,
};

export type Plan = {
	id: number;
	issueSources: IssueSource[];
	multiScenarioEnabled: boolean;
	planningUnit: PlanningUnit;
	title: string;
	includeCompletedIssuesFor?: number;
	calculationConfiguration: AutoScheduleConfiguration;
	syncStartEnabled: boolean;
	issueInferredDateSelection: IssueInferredDateSelection;
	baselineStartField: DateField;
	baselineEndField: DateField;
	createdTimestamp?: Timestamp;
	creatorAccountId?: string;
	leadAccountId?: string;
};

export type Component = {
	id: number;
	name: string;
	projectId: number;
	lead: string;
	assigneeType: number;
};

export type ReleaseStatusValue = (typeof RELEASE_STATUSES)[keyof typeof RELEASE_STATUSES];

export type ReleaseStatus = Readonly<{
	id: ReleaseStatusValue;
	name: string;
}>;

export type VersionValues = {
	name: string;
	start: Timestamp | null | undefined;
	end: Timestamp | null | undefined;
	delta: Timestamp | null | undefined;
	description: string | null | undefined;
	lexoRank?: string;
	crossProjectVersion: string | null | undefined;
	releaseStatusId: ReleaseStatusValue;
};

export type Version = {
	itemKey: string;
	originals: Partial<VersionValues>;
	scenarioType?: string;
	values: Readonly<VersionValues>;
};

export type Project = {
	avatarUrl: string;
	components: Component[];
	id: number;
	issueTypeIds: string[];
	issueStatusIds: string[];
	key: string;
	name: string;
	versions: Version[];
	isSimplified: boolean;
	projectTypeKey: (typeof PROJECT_TYPE_KEY)[keyof typeof PROJECT_TYPE_KEY];
	isReleasesEnabled: boolean;
};

export type Scenario = {
	planId: number;
	color?: string;
	id: number;
	title: string;
};

export type Sprint = {
	id: SprintId;
	title: string;
	state: string;
	issueSources: number[];
	startDate?: Timestamp | null | undefined;
	endDate?: Timestamp | null | undefined;
	completeDate?: Timestamp | null | undefined;
	goal?: string;
	planned?: boolean;
	rawStartDate?: Timestamp | null | undefined;
	rawEndDate?: Timestamp | null | undefined;
	isDisabled?: boolean | undefined;
	reportType?: 'USE_CHARTS' | 'USE_REPORTS' | 'DISABLED' | null | undefined;
	agileBoardId?: number;
	boardName?: string;
};

export type WRMPlannedCapacityValues = {
	sprintId: SprintId;
	teamKey: TeamKey;
	schedulingMode: SchedulingMode;
	capacity: number | null | undefined;
	planningUnit: PlanningUnit;
	iterationId: number;
};

export type WRMPlannedCapacity = {
	itemKey: string;
	scenarioType?: string;
	values: WRMPlannedCapacityValues;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	originals: WRMPlannedCapacityValues | Record<PropertyKey, any>;
};

export type PlannedCapacity = {
	itemKey?: string | null;
	iterationId: IterationId;
	teamId: TeamKey;
	schedulingMode: SchedulingMode;
	capacity?: number | null;
	planningUnit: PlanningUnit;
};

export type Resource = {
	itemKey: string;
	scenarioType?: ScenarioType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	originals: Record<string, any>;
	personItemKey: string;
	values: {
		weeklyHours?: number | null;
	};
};

export type TeamValues = {
	title?: string | null;
	avatarUrl?: string | null;
	schedulingMode?: string | null;
	velocity?: number | null;
	weeklyHours?: number | null;
	iterationLength?: number | null;
	issueSource?: number | null;
};

export type Team = {
	id: TeamKey;
	itemKey: string;
	originals: TeamValues;
	scenarioType?: ScenarioType;
	resources: Resource[];
	shareable: boolean;
	values: TeamValues;
	externalId?: string;
	isPlanTeam: boolean;
};

export type HierarchyLevel = {
	issueTypes?: string[];
	level: number;
	title: string;
};

export type Hierarchy = {
	levels: HierarchyLevel[];
};

export type SequenceValue = {
	before?: number;
	current: number;
};

export type Sequence = {
	issues?: SequenceValue;
	versions?: SequenceValue;
	teams?: SequenceValue;
	persons?: SequenceValue;
	resources?: SequenceValue;
	xprojectversions?: SequenceValue;
	themes?: SequenceValue;
	skills?: SequenceValue;
	stages?: SequenceValue;
	abilities?: SequenceValue;
	issuelinks?: SequenceValue;
	plannedcapacity?: SequenceValue;
};

export type IssuePriority = {
	id: string;
	name: string;
	iconUrl: string;
	sequence: number;
};

export type IssuePriorityScheme = {
	id: string;
	issuePriorityIds: string[];
	defaultIssuePriorityId: string;
};

type IssuePriorityInformationProjectId = string;
type IssuePriorityInformationPrioritySchemeId = string;

export type IssuePriorityInformation = {
	issuePrioritySchemes: {
		[key: string]: IssuePriorityScheme;
	};
	issuePriorities: {
		[key: string]: IssuePriority;
	};
	projectToPrioritySchemeAssociations: {
		[key: IssuePriorityInformationProjectId]: IssuePriorityInformationPrioritySchemeId;
	};
};

export type ApiPlanType = 'CROSS_TEAM' | 'CROSS_TEAM_PLANNING' | 'SAFE';

export type PlanInfo = {
	crossProjectVersions: CrossProjectVersion[];
	currentScenarioId: number;
	customFields: CustomField[];
	issuePriorityInformation: IssuePriorityInformation;
	issueStatuses: IssueStatus[];
	issueTypes: IssueType[];
	persons: Person[];
	plan: Plan;
	planType?: ApiPlanType;
	projects: Project[];
	readOnly?: boolean;
	releaseStatuses: ReleaseStatus[];
	scenarios: Scenario[];
	sequence?: Sequence;
	sprints: Sprint[];
	plannedCapacities: WRMPlannedCapacity[];
	teams: Team[];
	isSamplePlan: boolean;
	autoSchedulerEnabled: boolean;
	dateFieldStatuses?: DateFieldStatuses;
	planStatus?: string;
};

export type DateFieldStatuses = {
	startFieldStatus: DateFieldStatus;
	endFieldStatus: DateFieldStatus;
};

export const DateFieldStatus = {
	OK: 'ok',
	NOT_EXIST: "doesn't exist",
	UNAVAILABLE: 'unavailable',
} as const;

export type DateFieldStatus = (typeof DateFieldStatus)[keyof typeof DateFieldStatus];

export type HierarchyInfo = Hierarchy;

export type LoadingLimitsInfoType = {
	absoluteIssueLimit: number;
	defaultAbsoluteIssueLimit: number;
	defaultHierarchyIssueLimit: number;
	projectLimit: number;
	defaultProjectLimit: number;
};

export type Annotation = {
	type: keyof StaticScheduleWarnings;
	issue: string;
};

export type Assignment = {
	interval?: number;
	load?: number;
	resource?: string;
	skill?: string;
	team?: TeamKey;
	version?: string;
	start?: Timestamp;
	end?: Timestamp;
};

export type IssueLink = {
	itemKey: string;
	type: number;
	sourceItemKey: IssueId;
	targetItemKey: IssueId;
	pendingKey?: string;
};

export type IssueLinkData = {
	itemKey: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	originals: any;
	scenarioType: 'ADDED' | 'DELETED';
	sourceItemKey: IssueId;
	targetItemKey: IssueId;
	values: {
		type: number;
	};
};

export type MultiSelectCustomIssueTypeValue = {
	id: number;
	value: string[];
	typeKey: string;
};

export type CustomIssueTypeValue = {
	// This can be number or string due to the way we currently process custom fields. As mentioned
	// below this data type does not come from the backend directly
	id: number | string;
	value?: string | number | string[];
	typeKey?: string;
};

export type IssueLinksData = IssueLinkData & {
	direction: IssueLinkDirectionType;
};

export type CustomFieldValue = string | number | string[];

// All optional types should be treated as nullable here as well due to how the backend serializes
// GsonValueField. Some fields are missing the null typing due to a mismatch with types in the
// create modal package
export type IssueValues = {
	reporter?: string | null;
	assignee?: string | null;
	// field to update data in FE store
	associatedIssueIds?: IssueId[] | null;
	// field to update data in BE (associatedIssues instead of associatedIssueIds) - to be cleaned https://pi-dev-sandbox.atlassian.net/browse/POL-11795
	associatedIssues?: IssueId[] | null;
	baselineEnd?: Timestamp | null;
	baselineStart?: Timestamp | null;
	color?: string | null;
	components?: number[] | null;
	created?: Timestamp | null;
	customFields?: Record<PropertyKey, CustomFieldValue>;
	description?: string | null;
	dueDate?: Timestamp | null;
	earliestStart?: Timestamp | null;
	excluded: boolean;
	fixVersions?: string[] | null;
	goals?: string[];
	issueLinks?: IssueLink[] | null;
	labels?: string[] | null;
	lexoRank: string;
	originalStoryPoints?: number | null;
	originalTimeEstimate?: number | null;
	parent?: string;
	priority?: string | null;
	project: number;
	sprint?: string | null;
	completedSprints?: string[];
	status?: string;
	storyPoints?: number | null;
	summary: string;
	targetEnd?: Timestamp | null;
	targetStart?: Timestamp | null;
	team?: string | null;
	timeEstimate?: number | null;
	timeSpent?: number | null;
	theme?: number | null;
	type: number;
	version?: string | null;
	distribution?: number | null;
	level?: number | null;
	statusTransition?: string | null;
	// These are not returned by the API and are instead added in the spreadCustomFieldChanges
	// function which is called by a selector
	[key: `customFieldValueAdded-${number}`]: MultiSelectCustomIssueTypeValue;
	[key: `customFieldValueRemoved-${number}`]: MultiSelectCustomIssueTypeValue;
	[key: `customField-${number | string}`]: CustomIssueTypeValue;
	[key: `issueLink-${string}`]: IssueLinksData | null;
};

export type OriginalIssueValues = Partial<IssueValues>;

export type Issue = {
	annotations?: Annotation[];
	assignments?: Assignment[];
	id: string;
	issueKey?: number;
	issueSources: number[];
	originals: OriginalIssueValues;
	values: IssueValues;
};

// NOTE Solution type definition is intentionally incomplete because it's huge,
// tedious to spell out manually and we have no schema to automatically convert from
// but we use only small part of it

export type Interval = {
	start: Timestamp;
	end: Timestamp;
	sprintId?: string;
	title?: string;
};

export type SolutionTeam = {
	intervals: Interval[];
};

export type SolutionVersion = {
	key: string;
	isLater: boolean;
	delta?: number;
	fixedStart?: Timestamp;
	fixedEnd?: Timestamp;
	start?: Timestamp;
	end?: Timestamp;
};

export type SolutionProject = {
	versions: SolutionVersion[];
};

export type Solution = {
	configuration: {
		planningUnit: PlanningUnit;
	};
	projects: Record<ProjectId, SolutionProject>;
	teams: Record<TeamKey, SolutionTeam>;
};

export type CalculationConfigurationForSolution = {
	ignoreReleases: boolean;
	ignoreSprints: boolean;
	ignoreTeams: boolean;
	selectedIssueIds?: string[];
};

export type CalculationResultError = {
	error: string;
	errorMessages: string[];
	// This is a complex type that the frontend doesn't use see GsonException in backend
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	exception: any;
};

export type CalculationResult = {
	id: number;
	solution?: Solution;
	calculationConfiguration?: CalculationConfigurationForSolution;
	error?: CalculationResultError;
};

export type Backlog = {
	calculationResult?: CalculationResult;
	more: boolean;
	scenarioRemovedIssues?: Issue[];
	assigneeProfiles: Record<string, JiraUser>;
	reporterProfiles?: Record<string, JiraUser>;
	teams: Team[];
	sprints?: Sprint[];
	// Timestamp for when the data was actually loaded from jira in milliseconds
	loadTimestampMs?: Timestamp;
	issues: Issue[];
};

export type ExternalIssue = {
	id: number;
	issueKey: string;
	summary: string;
	baselineStart: Timestamp;
	baselineEnd: Timestamp;
	// project
	projectId: number;
	projectKey: string;
	// issue type
	typeId: string;
	typeIconUrl: string;
	// status
	statusId: string;
	statusName: string;
	statusCategoryId: number;
	// assignee
	userAvatarUrl: string | null | undefined;
	userId: string | null | undefined;
	userTitle: string | null | undefined;
};

// Api call does not support RANK_TOP value for API call it should be replaced with RANK_BEFORE and anchor issue with the lowest rank
export const RANK_TOP = 'TOP' as const;
export const RANK_LAST = 'LAST' as const;
export const RANK_BEFORE = 'BEFORE' as const;
export const RANK_AFTER = 'AFTER' as const;
export type Relation = typeof RANK_LAST | typeof RANK_BEFORE | typeof RANK_AFTER;

export type ViewUserInfo = {
	jiraUserId: string;
	jiraUsername: string;
	title: string;
	email: string;
	avatarUrl: string;
};

export type View = {
	id: number;
	version: number;
	name: string;
	isDefault: boolean;
	createdBy?: ViewUserInfo | null | undefined;
	createdTimestamp?: Timestamp;
	lastModifiedBy?: ViewUserInfo | null | undefined;
	lastModifiedTimestamp?: Timestamp;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	preferences?: Record<any, any> | null | undefined;
};

export type ModifiedView = Pick<View, 'id' | 'version' | 'preferences'>;

export type SavedViewsInfo = {
	savedViews: View[];
	modifiedSavedViews: ModifiedView[];
	activeViewId: number;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ServerRestStatus as Status };

export type StatusTransition = {
	id: string;
	isConditional: boolean;
	isGlobal: boolean;
	isInitial: boolean;
	hasScreen: boolean;
	isLooped?: boolean;
	name: string;
	to: ServerRestStatus;
	isDisabled?: boolean;
	unavailableReasonText?: string;
};
