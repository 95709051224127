import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-limit-warning-flag.title',
		defaultMessage: 'Issue limit exceeded',
		description: 'The title for issue-limit warning flag',
	},
	updateEditIssueSources: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-limit-warning-flag.update-edit-issue-sources',
		defaultMessage: 'Edit issue sources',
		description: 'Label for "Update issues sources" button',
	},
	setExclusionRules: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.issue-limit-warning-flag.set-exclusion-rules',
		defaultMessage: 'Set exclusion rules',
		description: 'Label for "set exclusion rules" button',
	},
});

export default messages;
