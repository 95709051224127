import type { Dispatch } from 'redux';
import {
	addPriorityColour,
	removePriorityColour,
	type RemovePriorityColourActionPayload,
	type UpdatePriorityColourActionPayload,
	updatePriorityColour,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/actions.tsx';
import type { Action, DispatchProps } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action>): DispatchProps => ({
	addPriorityColour: () => {
		dispatch(addPriorityColour());
	},
	removePriorityColour: (payload: RemovePriorityColourActionPayload) => {
		dispatch(removePriorityColour(payload));
	},
	updatePriorityColour: (payload: UpdatePriorityColourActionPayload) => {
		dispatch(updatePriorityColour(payload));
	},
});
