import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import Header from './header/reducer.tsx';
import InlineCreate from './inline-create/reducer.tsx';
import Issues from './issues/reducer.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	Issues,
	InlineCreate,
	Header,
});
