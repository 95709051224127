import React, { useCallback, useState, type ComponentType } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { toErrorID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/util.tsx';
import type { StatusTransition } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import {
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import messages from './messages.tsx';
import type { OwnProps, DispatchProps, Props } from './types.tsx';
import {
	getFilteredOrderedTransitions,
	addOriginalTransitions,
	removeTransitionsToCurrentAndOriginalStatus,
} from './utils.tsx';

export const transitionsProvider = (ComposedComponent: ComponentType<Props>) =>
	function (props: OwnProps & DispatchProps) {
		const { genericError, issueKey, issueStatuses, issue } = props;
		const { formatMessage } = useIntl();
		const [loading, setLoading] = useState(false);
		const [transitions, setTransitions] = useState<StatusTransition[] | null>(null);
		const fetchTransitions = useCallback(() => {
			if (loading) {
				return;
			}
			setLoading(true);
			setTransitions(null);

			fetch(`/rest/api/2/issue/${issueKey}/transitions?sortByOpsBarAndStatus=true`, {
				method: 'GET',
			})
				.then((response) => {
					setLoading(false);
					if (response.ok) {
						return response.json();
					}
					throw new Error(`Response not ok. Statuscode: ${response.status}`);
				}) // eslint-disable-next-line @typescript-eslint/no-explicit-any
				.then((result: any) => {
					const transitionsFromServer = getFilteredOrderedTransitions(
						result.transitions || [],
						issue.status,
					);
					setTransitions(
						addOriginalTransitions(
							issue,
							removeTransitionsToCurrentAndOriginalStatus(
								issue,
								transitionsFromServer,
								issueStatuses,
							),
							issueStatuses,
							formatMessage(messages.originalTransitionStatusName),
						),
					);
				})
				.catch((err) => {
					setLoading(false);
					if (fg('improve_redux_saga_error_reporting_plans_batch_1')) {
						fireErrorAnalytics({
							error: err,
							meta: {
								id: toErrorID(err, 'transitions-fetch-failed'),
								packageName: PACKAGE_NAME,
								teamName: ERROR_REPORTING_TEAM,
							},
							sendToPrivacyUnsafeSplunk: true,
						});
					} else {
						genericError({ message: err.message, stackTrace: err.stack });
					}
					setTransitions(null);
				});
		}, [issueKey, issue, issueStatuses, loading, genericError, formatMessage]);

		return (
			<ComposedComponent
				{...props}
				transitions={transitions}
				loading={loading}
				fetchTransitions={fetchTransitions}
			/>
		);
	};
