import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { STORY_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import AddIssue from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/header/add-issue/index.tsx';
import MenuItem from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/header/add-issue/menu-item/view.tsx';
import NestedMenu from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/header/add-issue/nested-menu/index.tsx';
import BulkActions from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/header/bulk-actions/index.tsx';
import CollapseMenu from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/header/collapse-menu/index.tsx';
import CreateIssue from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/header/create-issue/index.tsx';
import type { HierarchyItem, Props } from './types.tsx';

function ScopeHeader({ end, hierarchy, isExportMode, isReadOnly, isSelected, start }: Props) {
	const currentHierarchyLevels = hierarchy.reduce<HierarchyItem[]>((acc, { level, title }) => {
		if (level >= STORY_LEVEL) {
			acc.push({
				id: level,
				title,
				value: level,
				visible: level <= start && level >= Math.max(STORY_LEVEL, end), // Don't create a subtask without a parent
			});
		}
		return acc;
	}, []);

	return (
		<Box xcss={containerStyles}>
			{!isExportMode && <CollapseMenu />}
			{!isReadOnly &&
				!isSelected &&
				currentHierarchyLevels.length > 0 &&
				(fg('plan_timeline_issue_type_create_dropdown') ? (
					<CreateIssue />
				) : (
					<AddIssue
						currentHierarchyLevels={currentHierarchyLevels}
						isDefaultOpen={false}
						MenuItem={MenuItem}
						NestedMenu={NestedMenu}
					/>
				))}
			{isSelected && <BulkActions />}
		</Box>
	);
}

export default ScopeHeader;

const containerStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	boxSizing: 'border-box',
});
