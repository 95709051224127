import * as R from 'ramda';
import { getCrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/index.tsx';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getRollupMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/issues-tree.tsx';
import {
	getVersions,
	getVersionsById,
	getSortByHighestRank,
	getSortByLowestRank,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/index.tsx';
import type { Version } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/versions/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, MapStateToProps } from './types.tsx';

export function collectVersionsByProjectIdPure(versions: Version[]) {
	return R.reduce<Version, Record<number, Version[]>>(
		(acc, version) => {
			for (const projectId of version.projects) {
				if (R.isNil(acc[projectId])) {
					acc[projectId] = [version];
				} else {
					acc[projectId].push(version);
				}
			}

			return acc;
		},
		{},
		versions,
	);
}

const getReleaseRollUpValueForIssue = (state: State, props: OwnProps) =>
	isDefined(getRollupMap(state)[props.issue.id])
		? getRollupMap(state)[props.issue.id].release
		: null;

const collectVersionsByProjectId = createSelector([getVersions], collectVersionsByProjectIdPure);

const mapStateToProps: MapStateToProps = createStructuredSelector({
	crossProjectVersionsById: getCrossProjectVersionsById,
	versionsById: getVersionsById,
	projectsById: getProjectsById,
	releaseRollUpForIssue: getReleaseRollUpValueForIssue,
	versionsByProjectId: collectVersionsByProjectId,
	sortVersionsByHighestRank: getSortByHighestRank,
	sortVersionsByLowestRank: getSortByLowestRank,
});

export default mapStateToProps;
