import type { IssueTypeWithLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/hierarchy/types.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import {
	type NumberAttributeFilterValue,
	type IssueTypeKeyFilterValue,
	type IssueTypesFilter,
	ISSUE_TYPES_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';

const issueTypeIdsToIssueTypeKeys = (
	issueTypeIds: NumberAttributeFilterValue,
	issueTypesById: Record<number, IssueTypeWithLevel>,
): IssueTypeKeyFilterValue => {
	const issueTypeNamesByLevel = issueTypeIds.reduce<Record<number, Set<string>>>((acc, id) => {
		const issueType = issueTypesById[id];
		if (issueType !== undefined) {
			const names = acc[issueType.level];
			if (names !== undefined) {
				names.add(issueType.name);
			} else {
				const newNames = new Set<string>();
				newNames.add(issueType.name);
				acc[issueType.level] = newNames;
			}
		}
		return acc;
	}, {});
	return Object.entries(issueTypeNamesByLevel).reduce<IssueTypeKeyFilterValue>(
		(acc, [level, names]) => {
			names.forEach((name) => acc.push({ level: Number(level), name }));
			return acc;
		},
		[],
	);
};

const issueTypeKeysToIssueTypeIds = (
	issueTypeKeys: IssueTypeKeyFilterValue,
	issueTypesByHierarchy: Record<number, IssueType[]>,
): NumberAttributeFilterValue =>
	issueTypeKeys.reduce<NumberAttributeFilterValue>((acc, { level, name: keyName }) => {
		const issueTypesMatchingLevelAndName = (issueTypesByHierarchy[level] ?? []).filter(
			({ name }) => name === keyName,
		);
		return acc.concat(issueTypesMatchingLevelAndName.map(({ id }) => id));
	}, []);

export const getIssueTypeKeyValuesPure = (
	oldIssueTypeFilterValue: NumberAttributeFilterValue,
	newIssueTypeKeyFilterValue: IssueTypeKeyFilterValue,
	issueTypesById: Record<number, IssueTypeWithLevel>,
): IssueTypeKeyFilterValue => {
	if (newIssueTypeKeyFilterValue.length > 0) {
		return newIssueTypeKeyFilterValue;
	}
	// transform to issue type ids to keys (aggregated)
	return issueTypeIdsToIssueTypeKeys(oldIssueTypeFilterValue, issueTypesById);
};

export const getIssueTypeKeyFilterPure = (
	issueTypeKeyValue: IssueTypeKeyFilterValue,
	issueTypesByHierarchy: Record<number, IssueType[]>,
): IssueTypesFilter => ({
	id: ISSUE_TYPES_FILTER_ID,
	value: issueTypeKeysToIssueTypeIds(issueTypeKeyValue, issueTypesByHierarchy),
});
