import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	previewModeTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.preview-mode-title',
		defaultMessage: 'Export png preview mode',
		description: 'Title for the preview mode iframe',
	},

	downloadModeTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.download-mode-title',
		defaultMessage: 'Export png download mode',
		description: 'Title for the download mode iframe',
	},
	tooManyIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.too-many-issues',
		defaultMessage:
			'Your plan view is currently showing {visibleIssues, plural, one {# issue} other {# issues}}.',
		description: 'Alert for when the user has too many visible issues',
	},
	noIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.no-issues',
		defaultMessage: 'Your plan view is not showing any issues.',
		description: 'Alert for when the user has no visible issues',
	},
	plansMaxIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.plans-max-issues',
		defaultMessage:
			'Plans can only export {maxIssues, plural, one {# issue} other {# issues}} to a PNG at one time. Please use filters to hide issues from your plan view.',
		description: 'Sentence describing the maximum number of issues that can be exported',
	},
	maxIssuesSuggestion: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.max-issues-suggestion',
		defaultMessage:
			'If you want a snapshot of your data that contains more than {maxIssues, plural, one {# issue} other {# issues}}, we recommend exporting your issues as a .csv file.',
		description:
			'Suggestion for users when the maximum number of issues that can be exported is exceeded',
	},
	previewLozenge: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.preview-lozenge',
		defaultMessage:
			'Previewing {previewIssues} of {visibleIssues, plural, one {# issue} other {# issues}}',
		description: 'Text for the preview lozenge in the preview panel',
	},
	previewLozengeBasic: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.preview-lozenge-basic',
		defaultMessage: 'Preview',
		description: 'Basic version of text for the preview lozenge in the preview panel',
	},
	downloading: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.downloading',
		defaultMessage: 'Downloading...',
		description: 'The button label while the image is downloading.',
	},
	downloadButtonLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.download-button-label',
		defaultMessage: 'Download',
		description: 'Label of the download button',
	},
	customValueErrorMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.custom-value-error-message',
		defaultMessage: 'Timeline width must be between 400 and 5000 pixels',
		description: 'Error text when the custom timeline width value is invalid',
	},
	loadingMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.loading-message',
		defaultMessage: 'Generating preview...',
		description: 'Text that appears when the preview is loading',
	},
	exportingMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.exporting-message',
		defaultMessage: 'Exporting {visibleIssues, plural, one {# issue} other {# issues}}...',
		description: 'Text that appears when the full export is loading',
	},
	warningMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.warning-message',
		defaultMessage: 'Your export could take a few minutes.',
		description: 'Text that appears when the number of issues to export reaches the warning limit',
	},
	filterIssuesMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.filter-issues-message',
		defaultMessage:
			'Filter issues from your timeline and hide unnecessary columns to make it faster.',
		description: 'Suggestion tooltip to improve the export download speed.',
	},
	pngExportName: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.png-export-name',
		defaultMessage: 'Plans {planTitle}',
		description: 'Filename for the export png',
	},
	pngExportImageAlt: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.png-export-image-alt',
		defaultMessage: '{planTitle} preview',
		description: 'Alt text for PNG preview image of created plan.',
	},
});

export default messages;
