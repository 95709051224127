import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { getContainerWidth } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/utils/index.tsx';
import { useZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import Arrows from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/arrows/index.tsx';
import { SCOPE_ROW_HEIGHT } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { useViewport } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import { useColumnWidths } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { useVirtualized } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/virtualized/index.tsx';
import type { Props } from './types.tsx';

export const ArrowsContainer = ({ scope }: Props) => {
	const [columnWidths] = useColumnWidths({ preview: true });
	const [viewport] = useViewport();
	const [zoomLevel] = useZoomLevel();
	const containerWidth = zoomLevel ? getContainerWidth(zoomLevel) : columnWidths.at(-1) ?? 0;
	const virtualizedRows = useVirtualized();

	const marginLeft = columnWidths.reduce(
		(totalWidth, curWidth, index) =>
			index < columnWidths.length - 1 ? totalWidth + curWidth : totalWidth,
		0,
	);

	const width = viewport.width - marginLeft;

	const rowHeight = (index: number) => (scope[index]?.height ?? 1) * SCOPE_ROW_HEIGHT;

	return (
		<ZIndex layer={Z_INDEX_LAYER.ISSUE_BARS_ARROWS}>
			{(zIndex) => (
				<Box
					xcss={zoomLevel ? containerStyles : containerStylesFixed}
					style={{ zIndex, left: marginLeft }}
					// Temporarily disabling tab access for easier row access, will unblock in gridification work
					tabIndex={-1}
				>
					<Box
						xcss={layerAlignedStyles}
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.overlay.arrows.arrows"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={{ width }}
					>
						<Arrows
							containerWidth={containerWidth}
							scope={scope}
							rowHeight={rowHeight}
							/* Remove verticalRenderingWindow when cleaning up FF com.atlassian.rm.jpo.transposition.m2' */
							verticalRenderingWindow={{
								overscanStartIndex: 0,
								overscanStopIndex: 999,
							}}
							virtualizedRows={virtualizedRows}
						/>
					</Box>
				</Box>
			)}
		</ZIndex>
	);
};

const containerStyles = xcss({
	position: 'sticky',
	left: 'space.0',
	right: 'space.0',
	pointerEvents: 'none',
	height: 'space.0',
});

const containerStylesFixed = xcss({
	position: 'absolute',
	left: 'space.0',
	right: 'space.0',
	pointerEvents: 'none',
	height: 'space.0',
});

const layerAlignedStyles = xcss({
	position: 'sticky',
	height: '100%',
});
