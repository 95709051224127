import { type ResetAction, type AddAction, ADD, RESET } from './actions.tsx';
import type { ExternalSprint } from './types.tsx';

const initialState: ExternalSprint[] = [];

type Action = ResetAction | AddAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ExternalSprint[] = initialState, action: Action): ExternalSprint[] => {
	switch (action.type) {
		case RESET:
			return action.payload.map((sprint) => ({ ...sprint, id: sprint.id.toString() }));
		case ADD:
			return [
				...state,
				...action.payload.map((sprint) => ({ ...sprint, id: sprint.id.toString() })),
			];
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
