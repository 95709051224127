import { ONE_DAY } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { TTI_SLO } from '@atlassian/jira-portfolio-3-common/src/analytics/performance';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { INCLUDE_COMPLETED_ISSUE_FOR_DAYS } from '@atlassian/jira-portfolio-3-create-new-plan-wizard-drawer/src/common/constants';

export const DefaultDateFormat = 'DD/MMM/YY';
export const DefaultDateFormatDateFns = 'dd/MMM/yy';
export const IsoDateFormatDateFns = 'yyyy-MM-dd';
export const defaultDateWithTimeFormat = 'DD MMM YYYY h:mma';
export const DateFormatForSprintWithoutYear = 'D MMM';
export const DateFormatForSprintWithYear = 'D MMM YYYY';
export const EXPORT_FILE_DATE_FORMAT = 'DDMMYYYY';
export const SCENARIO_ISSUE_ID_PREFIX = 'SCEN-';

export const DIRECTIONS = {
	AFTER: 'after',
	BEFORE: 'before',
} as const;

export type Direction = (typeof DIRECTIONS)[keyof typeof DIRECTIONS];

export const PLANNING_UNITS = {
	STORY_POINTS: 'StoryPoints',
	DAYS: 'Days',
	HOURS: 'Hours',
} as const;

export const ASSIGNEE = 'ASSIGNEE' as const;
export const REPORTER = 'REPORTER' as const;
export const COMPONENT = 'COMPONENT' as const;
export const GOALS = 'GOALS' as const;
export const IDEAS = 'IDEAS' as const;
export const CROSS_PROJECT_RELEASE = 'CROSS_PROJECT_RELEASE' as const;
export const ISSUE = 'ISSUE' as const;
export const LABEL = 'LABEL' as const;
export const NONE = 'NONE' as const;
export const PROJECT = 'PROJECT' as const;
export const RELEASE = 'RELEASE' as const;
export const RESOURCE = 'RESOURCE' as const;
export const TEAM = 'TEAM' as const;
export const RANK = 'RANK' as const;
export const ASCENDING = 'ASCENDING' as const;
export const DESCENDING = 'DESCENDING' as const;
export const SPRINT = 'SPRINT' as const;
export const PLANNED_CAPACITY = 'PLANNED_CAPACITY' as const;
export const DEFAULT = 'default' as const;
export const LINES = 'lines' as const;
export const COUNT = 'count' as const;

export const ENTITY = {
	ISSUE,
	RELEASE,
	CROSS_PROJECT_RELEASE,
	TEAM,
	RESOURCE,
	PLANNED_CAPACITY,
	SPRINT,
	NONE,
} as const;

export type Entity = (typeof ENTITY)[keyof typeof ENTITY];

export const SCENARIO_TYPE = {
	ADDED: 'ADDED',
	UPDATED: 'UPDATED',
	DELETED: 'DELETED',
	DELETEDFROMJIRA: 'DELETEDFROMJIRA',
	NONE: 'NONE',
	MULTIPLE: 'MULTIPLE',
	EXCLUDED: 'EXCLUDED',
	ADDEDEXISTING: 'ADDEDEXISTING',
	UNKNOWN: 'UNKNOWN',
} as const;

export type ScenarioType = keyof typeof SCENARIO_TYPE | undefined;

export const GROUPING = {
	NONE,
	ASSIGNEE,
	COMPONENT,
	GOALS,
	IDEAS,
	LABEL,
	PROJECT,
	RELEASE,
	REPORTER,
	TEAM,
	SPRINT,
} as const;

export type Grouping = (typeof GROUPING)[keyof typeof GROUPING] | `customfield_${string}`;

export const STRING = 'string' as const;
export const DATE = 'date' as const;
export const NUMBER = 'number' as const;

export const ISSUE_FIELD_TYPES = {
	DATE,
	STRING,
	NUMBER,
} as const;

export type IssueFieldTypes = (typeof ISSUE_FIELD_TYPES)[keyof typeof ISSUE_FIELD_TYPES];

export const SORT_DIRECTION = {
	ASCENDING,
	DESCENDING,
} as const;

export type SortDirection = (typeof SORT_DIRECTION)[keyof typeof SORT_DIRECTION];

export const COMPLETED_SPRINTS_GROUP = 'COMPLETED_SPRINTS_GROUP';
export const COMPLETED_RELEASES_GROUP = 'COMPLETED_RELEASES_GROUP';

const UNRELEASED = '0' as const;
const RELEASED = '1' as const;
const OFFTRACK = '2' as const;
const ONTRACK = '3' as const;

export const RELEASE_STATUSES = {
	UNRELEASED,
	RELEASED,
	OFFTRACK,
	ONTRACK,
} as const;

export type ReleaseStatusType = (typeof RELEASE_STATUSES)[keyof typeof RELEASE_STATUSES];

export const RELEASE_STATUSES_APPEARANCE_SPA = {
	[UNRELEASED]: 'inprogress',
	[RELEASED]: 'success',
	[OFFTRACK]: 'removed',
	[ONTRACK]: 'inprogress',
} as const;

export const CustomFieldTypes = {
	AssetObject: 'com.atlassian.jira.plugins.cmdb:cmdb-object-cftype',
	TextField: 'com.atlassian.jira.plugin.system.customfieldtypes:textfield',
	SingleSelect: 'com.atlassian.jira.plugin.system.customfieldtypes:select',
	MultiSelect: 'com.atlassian.jira.plugin.system.customfieldtypes:multiselect',
	DatePicker: 'com.atlassian.jira.plugin.system.customfieldtypes:datepicker',
	NumberField: 'com.atlassian.jira.plugin.system.customfieldtypes:float',
	MultiCheckboxes: 'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes',
	RadioButtons: 'com.atlassian.jira.plugin.system.customfieldtypes:radiobuttons',
	UserPicker: 'com.atlassian.jira.plugin.system.customfieldtypes:userpicker',
	Url: 'com.atlassian.jira.plugin.system.customfieldtypes:url',
	Labels: 'com.atlassian.jira.plugin.system.customfieldtypes:labels',
} as const;

export const CustomFieldTypesToDataType = {
	[CustomFieldTypes.DatePicker]: ISSUE_FIELD_TYPES.DATE,
	[CustomFieldTypes.NumberField]: ISSUE_FIELD_TYPES.NUMBER,
	[CustomFieldTypes.SingleSelect]: ISSUE_FIELD_TYPES.STRING,
} as const;

export const CustomFields: {
	WritableFields: readonly string[];
	ReadOnlyFields: readonly string[];
	FilterableFields: readonly string[];
	GroupableSingleValueFields: readonly string[];
	GroupableMultiValueFields: readonly string[];
} = {
	WritableFields: [
		CustomFieldTypes.DatePicker,
		CustomFieldTypes.Labels,
		CustomFieldTypes.MultiCheckboxes,
		CustomFieldTypes.MultiSelect,
		CustomFieldTypes.NumberField,
		CustomFieldTypes.RadioButtons,
		CustomFieldTypes.SingleSelect,
		CustomFieldTypes.TextField,
		CustomFieldTypes.UserPicker,
	],
	ReadOnlyFields: [CustomFieldTypes.Url, CustomFieldTypes.AssetObject],
	FilterableFields: [
		CustomFieldTypes.SingleSelect,
		CustomFieldTypes.Labels,
		CustomFieldTypes.MultiCheckboxes,
		CustomFieldTypes.MultiSelect,
		CustomFieldTypes.RadioButtons,
		CustomFieldTypes.AssetObject,
	],
	GroupableSingleValueFields: [CustomFieldTypes.SingleSelect, CustomFieldTypes.UserPicker],
	GroupableMultiValueFields: [
		CustomFieldTypes.Labels,
		CustomFieldTypes.MultiSelect,
		CustomFieldTypes.AssetObject,
	],
} as const;

export type CustomFieldType = (typeof CustomFieldTypes)[keyof typeof CustomFieldTypes];

export const PREVIEW_OPACITY = 0.5;

export const ISSUE_STATUS_CATEGORIES_CONST = {
	TODO: 2,
	INPROGRESS: 4,
	DONE: 3,
} as const;

export const ISSUE_STATUS_CATEGORIES = {
	TODO: 2,
	INPROGRESS: 4,
	DONE: 3,
};

export const ISSUE_STATUS_APPEARANCE = {
	[ISSUE_STATUS_CATEGORIES.TODO]: 'default',
	[ISSUE_STATUS_CATEGORIES.INPROGRESS]: 'inprogress',
	[ISSUE_STATUS_CATEGORIES.DONE]: 'success',
} as const;

export type IssueStatusType = number;

export const SPRINT_STATES = {
	CLOSED: 'CLOSED',
	ACTIVE: 'ACTIVE',
	FUTURE: 'FUTURE',
} as const;

export const EXTERNAL_SPRINT = 'EXTERNAL_SPRINT';
export const INCLUDE_COMPLETED_ISSUE_FOR_DAYS_MAX_LIMIT = 3000;

export const DEFAULT_FIELD_WIDTH = {
	SMALL: 64,
	MEDIUM: 128,
	LARGE: 160,
	EXTRALARGE: 240,
} as const;

export const SCOPE_ROW_HEIGHT = 40;

export const EXPORT_VIEW_DEFAULT_SCOPE_WIDTH = 440;
export const EXPORT_VIEW_DEFAULT_FIELDS_WIDTH = 600;
export const EXPORT_VIEW_DEFAULT_TIMELINE_WIDTH = 900;
export const NUM_ISSUES_IN_EXPORT_PREVIEW = 20;

// Auto-scheduling options
export const ALL_VALUES = 'ALL_VALUES' as const;
export const EMPTY_VALUES_ONLY = 'EMPTY_VALUES_ONLY' as const;

export const AUTO_SCHEDULE_OVERWRITE = {
	ALL_VALUES,
	EMPTY_VALUES_ONLY,
} as const;

export type AutoScheduleOverwriteOptions =
	(typeof AUTO_SCHEDULE_OVERWRITE)[keyof typeof AUTO_SCHEDULE_OVERWRITE];

export const ISSUE_VALUES = {
	SPRINT,
	RELEASE,
	TEAM,
} as const;

export type IssueValues = (typeof ISSUE_VALUES)[keyof typeof ISSUE_VALUES];

export const PlanningUnits: {
	storyPoints: 'StoryPoints';
	days: 'Days';
	hours: 'Hours';
} = {
	storyPoints: 'StoryPoints',
	days: 'Days',
	hours: 'Hours',
};
export type PlanningUnit = (typeof PlanningUnits)[keyof typeof PlanningUnits];

// This has been added to be used as a key for properties of issues that have no value.
export const UNDEFINED_KEY = 'UNDEFINED';

export const STICKY_HEADER_CONTAINER_PREFIX = 'sticky-header-cell';

export const CHECKBOX_STATES = {
	CHECKED: 'checked',
	UNCHECKED: 'unchecked',
	INDETERMINATE: 'indeterminate',
} as const;

export const PLUGIN_PERMISSION = {
	VIEWER: 'Viewer',
	FULL_EDITOR: 'FullEditor',
	NON_PUBLISHING_EDITOR: 'NonPublishingEditor',
} as const;

export const SHARE_AS = {
	LINK: 'Link',
	EMBED: 'Embed',
	CONFLUENCE: 'Confluence',
	CSV: 'Csv',
	PNG: 'Png',
	DEPENDENCY_REPORT_CONFLUENCE: 'DependencyReportConfluence',
} as const;

export type ShareAs = (typeof SHARE_AS)[keyof typeof SHARE_AS];

export type PluginPermission = (typeof PLUGIN_PERMISSION)[keyof typeof PLUGIN_PERMISSION];

export const SCHEDULE_MODE = {
	kanban: 'Kanban',
	scrum: 'Scrum',
} as const;

export type SchedulingMode = (typeof SCHEDULE_MODE)[keyof typeof SCHEDULE_MODE];

export const INSTANCE_USER_ROLE = {
	ADMIN: 'Admin',
	EDITOR: 'Editor',
	VIEWER: 'Viewer',
	RESTRICTED_USER: 'Restricted',
} as const;

export type InstanceUserRole = (typeof INSTANCE_USER_ROLE)[keyof typeof INSTANCE_USER_ROLE];

export const PLAN_USER_ROLE = {
	EDITOR: 'Editor',
	VIEWER: 'Viewer',
	REPORT_CONSUMER: 'ReportConsumer',
	CONFLUENCE_CONSUMER: 'ConfluenceConsumer',
} as const;

export type PlanUserRole = (typeof PLAN_USER_ROLE)[keyof typeof PLAN_USER_ROLE];

export const ISSUE_LINK_DIRECTION = {
	OUTWARD: 'outward',
	INWARD: 'inward',
} as const;

export const ISSUE_SOURCE_TYPES = {
	PROJECT: 'Project',
	BOARD: 'Board',
	FILTER: 'Filter',
} as const;

export type IssueLinkDirectionType =
	(typeof ISSUE_LINK_DIRECTION)[keyof typeof ISSUE_LINK_DIRECTION];

/**
 * Performance analytics marks and keys
 */

// We have to provide appName when timing initial render.
export const APP_NAME = 'portfolio-3' as const;
export const CREATE_PLAN_WIZARD_APP_NAME = 'portfolio-3.create-plan-wizard' as const;

// Keys used to identify tasks/interactions. Must be unique across Jira.
// When using Monitor API they are automatically prefixed, thus need to be unique across ARJ only.
// If you use BrowserMetrics directly make sure to prefix manually.
export const PERFORMANCE_KEYS = {
	COMMIT_CHANGES_TIME_TO_SAVE: 'commit-changes.time-to-save',
	COMMIT_CHANGES_TIME_TO_PERSIST: 'commit-changes.time-to-persist',
	RENDER_APP: 'page-plan.render-app',
	EXPORT_PNG: {
		INITIAL_PREVIEW: 'page-plan.export-png.initial-preview',
		SUBSEQUENT_PREVIEW: 'page-plan.export-png.subsequent-preview',
		EXPORT: 'page-plan.export-png.export',
	},
} as const;

// Keys used to identify execution time stats within tasks. Must be unique across ARJ.
export const PROFILE_KEYS = {
	RENDER: {
		SCOPE_TABLE: 'renderScopeTable',
	},
	EXPORT_PNG: {
		INITIAL_LOAD: 'page-plan.export-png.profile.initial-load',
		REACT_RENDER: 'page-plan.export-png.profile.react-render',
		PREPARE_FOR_EXPORT: 'page-plan.export-png.profile.prepare-for-export',
		CANVAS_RENDER: 'page-plan.export-png.profile.canvas-render',
	},
} as const;

/**
 * Error analytics
 */

/**
 * PACKAGE_NAME has to be the camelCase name of the name provided in the package.json
 * "name": "@atlassian/jira-portfolio-3-portfolio"
 */
export const PACKAGE_NAME = 'jiraPortfolio3Portfolio' as const;
export const ERROR_REPORTING_PACKAGE = {
	APP: 'app',
	ROADMAP: 'roadmapTab',
	TEAMS: 'teamsTab',
	RELEASES: 'releasesTab',
	DEPENDENCIES: 'dependenciesTab',
	SETTINGS: 'settings',
	REDUX: 'redux',
	REDUX_SAGA: 'reduxSaga',
	PLAN_WIZARD: 'planWizard',
	UNHANDLED: 'unhandled',
	ENVIRONMENT: 'environment',
	GIC: 'GIC',
	SUMMARY: 'summaryTab',
} as const;

/**
 * ERROR_REPORTING_TEAM has to be a kebab-case variant of the team declared in package.json
 */
export const ERROR_REPORTING_TEAM = 'advanced-roadmaps';

export const MAX_BULK_CHANGE_ISSUES = 100;

/**
 * DEFAULT = badges with link icons on issue bars
 * COUNT = badges with dependencies count on issue bars
 * LINES = dependency lines between issue bars
 */
export const DEPENDENCY_SETTINGS = {
	DEFAULT,
	LINES,
	COUNT,
} as const;

export type DependencySettings = (typeof DEPENDENCY_SETTINGS)[keyof typeof DEPENDENCY_SETTINGS];

export const HORIZONTAL_SCROLL_OFFSET = 1e6;

export const PROJECT_TYPE_KEY = {
	SOFTWARE: 'software',
	WORK_MANAGEMENT: 'business',
	SERVICE_MANAGEMENT: 'service_desk',
} as const;

export const ZOOM_LEVELS = {
	WEEKS: 'weeks',
	MONTHS: 'months',
	QUARTERS: 'quarters',
	YEARS: 'years',
} as const;

export const TIMELINE_MODES = {
	...ZOOM_LEVELS,
	CUSTOM: 'custom',
} as const;

export const CUSTOM_DATE_RANGES = {
	SMALL: 14,
	MEDIUM: 93,
	LARGE: 364,
} as const;

// If story A blocks story B,  the startTimeOfStoryB - endTiemOfStoryA <= VALID_LEADING_TIME_FOR_DEPENDENCIES would be viewed as valid lead time buff. Currently we adopt to 1 day as a valid time buff. Refer to https://jdog.jira-dev.com/browse/JPO-20525
export const VALID_LEADING_TIME_FOR_DEPENDENCIES = ONE_DAY;

// Calculate percentage width of uniform days
export const DAY_WIDTH = (1 / 7) * 100;

// User profile key for 'Send update notifications' checkbox on UpdateJira modal
export const NOTIFY_WATCHERS_KEY = 'jira.user.arj.notify.watchers' as const;

export const TIMELINE_SHARE_TYPE = 'timeline';
export const DEPENDENCY_REPORT_SHARE_TYPE = 'dependency-report';

export type ConfluenceMacroType = typeof TIMELINE_SHARE_TYPE | typeof DEPENDENCY_REPORT_SHARE_TYPE;

/**
 * MAIN = main section in timeline mode that contains all columns
 * FIELD = field section in timeline mode that contains all field sub-columns
 * LIST_MAIN = main section in list mode that contains all columns
 * LIST_FIELD = field section in list mode that contains all field sub-columns
 */
export const SECTION_KEYS = {
	MAIN: 'main-sections',
	FIELD: 'fields-section',
	LIST_MAIN: 'list-main-sections',
	LIST_FIELD: 'list-fields-section',
} as const;

export const SECTION_ITEM_KEYS = {
	SCOPE: 'scope-column',
	FIELDS: 'fields-column',
	TIMELINE: 'timeline-column',
} as const;

export const ORIGINAL_STATUS_TRANSITION = {
	id: '__original_status_transition_id__',
	name: '__original_status_transition_name__',
};
export const BREAKDOWN_SPRINT_CAPACITY_BY = {
	STATUS: 'status',
	ESTIMATE: 'estimate',
} as const;
export type BREAKDOWN_SPRINT_CAPACITY_BY =
	(typeof BREAKDOWN_SPRINT_CAPACITY_BY)[keyof typeof BREAKDOWN_SPRINT_CAPACITY_BY];

export const IPH_IDS = {
	AUTO_SCHEDULE: '72BaKCMhh1aBiQprnER3pL',
	SHARE_CONFLUENCE: '8xdMiPoU4Fr4KWxzGJdXL',
	WARNING_CENTER: '5NX3sX9eqNEXSGhi4cMQ4L',
	SAVED_VIEWS: '636NO7TUPjErcJvohzMo9f',
	MANAGE_SAVED_VIEWS: '59mAhBw1bktXGV50c5c3ax',
	DEPENDENCIES: '2HQv4dgTi14DCpGY1YU2ZF',
	SUMMARY: '2lAziig1qpzuPCelr0CWB3',
	TEAMS: '30yplH4RxkjUZwmNwOxT2k',
	TEAM_CAPACITY: '1iT8GgcEZ2tikuwlnTq8Ij',
	DEPENDENCIES_REPORT: '2N5Dc7LkkoTjytZVPp9PQV',
	ISSUE_SOURCE: '1HTUp4iLEAeRVWDAI6pCaB',
	EXCLUSION_RULES: '2okV00HG5KCXjcw9oxv2kv',
	PERMISSIONS: '2GnX12bqw32MnTSVnDooC',
};
