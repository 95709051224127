export const SET_SHOW_ROLLED_UP_DATE =
	'state.domain.view-settings.rollup-settings.SET_SHOW_ROLLED_UP_DATE' as const;
export const SET_SHOW_ROLLED_UP_OTHERS =
	'state.domain.view-settings.rollup-settings.SET_SHOW_ROLLED_UP_OTHERS' as const;

export type SetShowRolledUpDatePayload = {
	shouldShow: boolean;
};

export type SetShowRolledUpOthersPayload = {
	shouldShow: boolean;
};

export type SetShowRolledUpDateAction = {
	type: typeof SET_SHOW_ROLLED_UP_DATE;
	payload: SetShowRolledUpDatePayload;
};

export type SetShowRolledUpOthersAction = {
	type: typeof SET_SHOW_ROLLED_UP_OTHERS;
	payload: SetShowRolledUpOthersPayload;
};

export type SetRollupSettingAction = SetShowRolledUpDateAction | SetShowRolledUpOthersAction;

export const setShowRolledUpDates = (payload: SetShowRolledUpDatePayload) => ({
	type: SET_SHOW_ROLLED_UP_DATE,
	payload,
});

export const setShowRolledUpOthers = (payload: SetShowRolledUpOthersPayload) => ({
	type: SET_SHOW_ROLLED_UP_OTHERS,
	payload,
});
