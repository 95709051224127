import {
	ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE,
	ROUTE_NAMES_SOFTWARE_PLAN_DEPENDENCIES,
	ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR,
	ROUTE_NAMES_SOFTWARE_PLAN_INCREMENT,
	ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import type { MessageDescriptor } from '@atlassian/jira-intl';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants.tsx';
import messages from './messages.tsx';

type RouteMappingObject = {
	integrationType: string;
	shareTitle: MessageDescriptor;
};

export const routeMapping: Record<string, RouteMappingObject> = {
	[ROUTE_NAMES_SOFTWARE_PLAN_TIMELINE]: {
		integrationType: integrationTypes.TIMELINE,
		shareTitle: messages.timelineTitle,
	},
	[ROUTE_NAMES_SOFTWARE_PLAN_DEPENDENCIES]: {
		integrationType: integrationTypes.DEPENDENCIES,
		shareTitle: messages.dependenciesTitle,
	},
	[ROUTE_NAMES_SOFTWARE_PLAN_CALENDAR]: {
		integrationType: integrationTypes.CALENDAR,
		shareTitle: messages.calendarTitle,
	},
	[ROUTE_NAMES_SOFTWARE_PLAN_INCREMENT]: {
		integrationType: integrationTypes.INCREMENT,
		shareTitle: messages.programTitle,
	},
	[ROUTE_NAMES_SOFTWARE_PLAN_SUMMARY]: {
		integrationType: integrationTypes.SUMMARY,
		shareTitle: messages.summaryTitle,
	},
};
