import {
	type ViewMode,
	VIEW_MODES,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import {
	ConfigurableDateTypes,
	type ConfigurableDate,
} from '@atlassian/jira-portfolio-3-common/src/sections/types.tsx';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import {
	defaultWidthOfColumnList,
	defaultWidthOfColumnTimeline,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/field-columns/types.tsx';
import { DEFAULT_FIELD_WIDTH } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

export const getMatchingConfiguredDate =
	({ baselineStartField, baselineEndField }: DateConfiguration) =>
	(id?: string | null): ConfigurableDate | null | undefined => {
		if (!id) return;

		if (baselineStartField && baselineStartField.key === id) {
			return ConfigurableDateTypes.start;
		}
		if (baselineEndField && baselineEndField.key === id) {
			return ConfigurableDateTypes.end;
		}
	};

export const getDefaultWidth = (id: string | undefined, viewMode: ViewMode) => {
	const defaultWidthOfColumn =
		viewMode === VIEW_MODES.TIMELINE ? defaultWidthOfColumnTimeline : defaultWidthOfColumnList;

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const columnId = (id as keyof typeof defaultWidthOfColumn) ?? 'DEFAULT';
	const columnWidth = defaultWidthOfColumn[columnId];

	return columnWidth ?? defaultWidthOfColumn.DEFAULT;
};

export const getMinWidth = (id: string | undefined, isCollapsible: boolean, viewMode: ViewMode) => {
	if (isCollapsible) return 24;
	if (viewMode === VIEW_MODES.TIMELINE) return DEFAULT_FIELD_WIDTH.MEDIUM;

	return getDefaultWidth(id, viewMode);
};
