import type { Store } from 'redux';
import { setValidity as setSolutionValidity } from '../../state/domain/solution/actions.tsx';
import type { State } from '../../state/types.tsx';
import { relaxedScheduler } from '../util.tsx';

/*

This observer checks for any changes to the state that would invalidate the solution cache.

*/

const createObserver = (store: Store<State>) => {
	let currentState: State;
	const observer = () => {
		const previousState = currentState;
		currentState = store.getState();

		if (!previousState) {
			store.dispatch(setSolutionValidity(false));
		} else if (currentState.domain.solution.isValid) {
			if (
				previousState.domain.sequence !== currentState.domain.sequence ||
				previousState.domain.plan !== currentState.domain.plan ||
				previousState.ui.Main.Tabs.Roadmap.Scope.Issues.selected !==
					currentState.ui.Main.Tabs.Roadmap.Scope.Issues.selected
			) {
				store.dispatch(setSolutionValidity(false));
			}
		}
	};
	return store.subscribe(relaxedScheduler(observer));
};

export default createObserver;
