import { Component } from 'react';
import withViewExperienceTracker from '@atlassian/jira-portfolio-3-portfolio/src/common/view/experience-tracking/with-view-experience-tracker/index.tsx';
import type { Props, StateProps } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class ExperienceTracker extends Component<Props> {
	componentDidUpdate(prevProps: Props) {
		const { isLoading, onExperienceSuccess } = this.props;

		if (prevProps.isLoading && !isLoading) {
			onExperienceSuccess?.();
		}
	}

	render() {
		return null;
	}
}

export default withViewExperienceTracker<StateProps>(
	ExperienceTracker,
	'portfolio-3.simple-plans.backlog.request',
);
