import React, { Component, type ReactNode } from 'react';
import Lozenge from '@atlaskit/lozenge';
import Popup from '@atlaskit/popup';
import { fg } from '@atlassian/jira-feature-gating';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
	DialogMenuGroup,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
// Remove InlineDialog when cleaning up FG 'migrate_plan_filter_to_popup'
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { NO_SPRINT_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/sprint-filter/index.tsx';
import {
	getSprintStateLozengeContent,
	getSprintStateLabel,
	getLozengeAppearance,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/utils.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import { SPRINT_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import {
	CHECKBOX_STATES,
	EXTERNAL_SPRINT,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import FilterText from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { PropsWithInjectedIntl as Props } from './types.tsx';

export const { CHECKED, UNCHECKED, INDETERMINATE } = CHECKBOX_STATES;

// eslint-disable-next-line jira/react/no-class-components
class SprintFilter extends Component<Props> {
	constructFilterText = () => {
		const { filterSprints, intl, sprintsByIdMap } = this.props;

		const filteredSprintsTitles: Array<string> = [];

		// the "No sprint" filter is displayed first in the filter button text
		if (filterSprints.has(NO_SPRINT_ID)) {
			filteredSprintsTitles.push(intl.formatMessage(commonMessages.noSprintLabel));
		}
		[...filterSprints].forEach((sprintId) => {
			if (sprintsByIdMap[sprintId] && sprintsByIdMap[sprintId].title) {
				filteredSprintsTitles.push(sprintsByIdMap[sprintId].title);
			}
		});
		return filteredSprintsTitles.join(', ');
	};

	renderFilterText = () => {
		if (this.props.filterSprints.size > 0) {
			return <FilterText text={this.constructFilterText()} />;
		}
		return <FormattedMessage {...messages.emptyPlaceholder} />;
	};

	ariaText = () => {
		const { filterSprints, intl } = this.props;
		const filterText = this.constructFilterText();
		// Sprints, All
		return `${intl.formatMessage(filterMessages[SPRINT_FILTER_ID])}, ${
			filterSprints.size > 0 ? filterText : intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	filterSprintsWithSearchQuery = (sprint: Sprint): boolean =>
		sprint.title.toLowerCase().includes(this.props.searchQuery.toLowerCase());

	renderLozenge = (sprintState: string) => {
		const { intl } = this.props;
		const lozengeContent = getSprintStateLozengeContent(intl);

		return (
			<Lozenge appearance={getLozengeAppearance(sprintState)}>
				{lozengeContent(sprintState)}
			</Lozenge>
		);
	};

	onItemGroupClick = (sprintIds: string[], checkboxState: string) => {
		const newFilterSprint = new Set(this.props.filterSprints);
		if (checkboxState === UNCHECKED) {
			for (const id of sprintIds) {
				newFilterSprint.add(id);
			}
		} else {
			for (const id of sprintIds) {
				newFilterSprint.delete(id);
			}
		}
		this.props.changeFilter(Array.from(newFilterSprint));
	};

	isSelected = (id: string) => this.props.filterSprints.has(id);

	onItemClick = (id: string): void => {
		const { filterSprints, changeFilter } = this.props;
		const newFilterSprint = new Set(filterSprints);
		if (this.isSelected(id)) {
			newFilterSprint.delete(id);
		} else {
			newFilterSprint.add(id);
		}
		changeFilter(Array.from(newFilterSprint));
	};

	// this function renders a "group" of sprints i.e. either:
	// - the group(s) of sprints associated with a team
	// - the (unique) group of sprints not associated with a team
	// each group rendered includes a heading and a list of sprints
	renderSprintsGroup = (
		heading: string,
		sprints: Array<Sprint>,
		renderSprintStateLozenge: (sprintState: string) => ReactNode,
	) => (
		<DialogMenuGroup heading={heading} key={heading}>
			{sprints.map((sprint) => (
				<DialogMenuItem key={sprint.id}>
					<Checkbox
						isChecked={this.props.filterSprints.has(sprint.id)}
						label={
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							<div className={styles['sprint-title-container']}>
								<EllipsedWithTooltip content={sprint.title}>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<span className={styles['sprint-title']}>{sprint.title}</span>
								</EllipsedWithTooltip>
								{renderSprintStateLozenge(sprint.state)}
							</div>
						}
						onChange={() => this.onItemClick(sprint.id)}
					/>
				</DialogMenuItem>
			))}
		</DialogMenuGroup>
	);

	// this function renders the main categories of sprints, right below the "No sprint" filter
	// i.e. "All [COMPLETED / ACTIVE / FUTURE / EXTERNAL] sprints"
	renderSprintStateCategories = () => {
		const { sprintStatesCategories } = this.props;

		if (!sprintStatesCategories.length) {
			return null;
		}

		const { intl } = this.props;
		const checkboxContent = getSprintStateLabel(intl);

		return sprintStatesCategories.map(({ checkboxState, sprintIds, sprintState }) => (
			<DialogMenuItem key={`${sprintState}-stateCheckbox`}>
				<Checkbox
					isChecked={checkboxState !== UNCHECKED}
					isDisabled={!sprintIds.length}
					isIndeterminate={checkboxState === INDETERMINATE}
					label={checkboxContent(sprintState)}
					onChange={() => this.onItemGroupClick(sprintIds, checkboxState)}
				/>
			</DialogMenuItem>
		));
	};

	// this function renders all sprints associated with a Team, for each Team
	renderSprintsWithTeams = () => {
		const {
			searchQuery,
			sprintsAndTeamsByIssueSources: { sprintsWithTeams },
		} = this.props;

		if (sprintsWithTeams.length) {
			return sprintsWithTeams.map((sprintsInGroup) => {
				// filtering sprints based on search query
				const sprints = searchQuery
					? sprintsInGroup.sprints.filter(this.filterSprintsWithSearchQuery)
					: sprintsInGroup.sprints;
				if (!sprints.length) {
					return null;
				}
				return this.renderSprintsGroup(sprintsInGroup.teams.join(', '), sprints, (sprintState) =>
					this.renderLozenge(sprintState),
				);
			});
		}
		return null;
	};

	// this function renders all sprints which are not associated with a Team, in one unique group
	renderSprintsWithoutTeams = () => {
		const {
			intl: { formatMessage },
			searchQuery,
			sprintsAndTeamsByIssueSources: { sprintsWithoutTeams },
		} = this.props;

		if (sprintsWithoutTeams.length) {
			// filtering sprints based on search query
			const sprints = searchQuery
				? sprintsWithoutTeams.filter(this.filterSprintsWithSearchQuery)
				: sprintsWithoutTeams;
			if (!sprints.length) {
				return null;
			}
			return this.renderSprintsGroup(
				formatMessage(commonMessages.noAssociatedTeams),
				sprints,
				(sprintState) => this.renderLozenge(sprintState),
			);
		}
		return null;
	};

	// this function renders all external sprints in one unique group
	renderExternalSprints = () => {
		const {
			externalSprints,
			intl: { formatMessage },
			searchQuery,
		} = this.props;

		// filtering external sprints based on search query
		const filteredExternalSprints = searchQuery
			? externalSprints.filter(this.filterSprintsWithSearchQuery)
			: externalSprints;

		if (!filteredExternalSprints.length) {
			return null;
		}

		return this.renderSprintsGroup(
			formatMessage(commonMessages.otherSprints),
			filteredExternalSprints,
			(sprintState) => this.renderLozenge(EXTERNAL_SPRINT || sprintState),
		);
	};

	render() {
		const {
			allSprints,
			clearFilter,
			filterSprints,
			intl,
			isOpen,
			onOpenChange,
			onQueryChange,
			searchQuery,
		} = this.props;

		if (!fg('migrate_plan_filter_to_popup')) {
			return (
				<InlineDialog
					noPaddings
					onClose={onOpenChange}
					isOpen={isOpen}
					maxWidth={FILTER_MAX_WIDTH}
					minWidth={FILTER_WIDTH}
					content={
						<DialogMenuContainer>
							<ClearFilterButton isVisible={filterSprints.size > 0} onClearClick={clearFilter} />
							<SearchField
								placeholder={intl.formatMessage(messages.searchSprintPlaceholder)}
								searchQuery={searchQuery}
								onQueryChange={onQueryChange}
								ariaLabel={intl.formatMessage(messages.searchSprintLabel)}
							/>
							<DialogMenuItem key={`${NO_SPRINT_ID}-checkbox`}>
								<Checkbox
									id={NO_SPRINT_ID}
									key={NO_SPRINT_ID}
									isChecked={this.isSelected(NO_SPRINT_ID)}
									label={<FormattedMessage {...commonMessages.noSprintLabel} />}
									onChange={() => this.onItemClick(NO_SPRINT_ID)}
								/>
							</DialogMenuItem>
							{(searchQuery && allSprints.some(this.filterSprintsWithSearchQuery)) ||
							allSprints.length > 0 ? (
								<>
									{this.renderSprintStateCategories()}
									{this.renderSprintsWithTeams()}
									{this.renderSprintsWithoutTeams()}
									{this.renderExternalSprints()}
								</>
							) : (
								<NoMatchFound />
							)}
						</DialogMenuContainer>
					}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.sprint-filter"
				>
					<TriggerButton
						isOpen={isOpen}
						onOpenChange={onOpenChange}
						testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.sprint-filter.trigger-btn"
						triggerButtonText={this.renderFilterText()}
						ariaLabel={this.ariaText()}
					/>
				</InlineDialog>
			);
		}
		return (
			<Popup
				isOpen={isOpen}
				placement="bottom-start"
				shouldUseCaptureOnOutsideClick
				autoFocus
				onClose={() => {
					onOpenChange({ isOpen: false });
				}}
				content={(contentProps) => (
					<DialogMenuContainer>
						<ClearFilterButton isVisible={filterSprints.size > 0} onClearClick={clearFilter} />
						<SearchField
							placeholder={intl.formatMessage(messages.searchSprintPlaceholder)}
							searchQuery={searchQuery}
							onQueryChange={onQueryChange}
							ariaLabel={intl.formatMessage(messages.searchSprintLabel)}
							setInitialFocusRef={contentProps.setInitialFocusRef}
						/>
						<DialogMenuItem key={`${NO_SPRINT_ID}-checkbox`}>
							<Checkbox
								id={NO_SPRINT_ID}
								key={NO_SPRINT_ID}
								isChecked={this.isSelected(NO_SPRINT_ID)}
								label={<FormattedMessage {...commonMessages.noSprintLabel} />}
								onChange={() => this.onItemClick(NO_SPRINT_ID)}
							/>
						</DialogMenuItem>
						{(searchQuery && allSprints.some(this.filterSprintsWithSearchQuery)) ||
						allSprints.length > 0 ? (
							<>
								{this.renderSprintStateCategories()}
								{this.renderSprintsWithTeams()}
								{this.renderSprintsWithoutTeams()}
								{this.renderExternalSprints()}
							</>
						) : (
							<NoMatchFound />
						)}
					</DialogMenuContainer>
				)}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.sprint-filter"
				trigger={(triggerProps) => (
					<TriggerButton
						{...triggerProps}
						isOpen={isOpen}
						onOpenChange={onOpenChange}
						testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.sprint-filter.trigger-btn"
						triggerButtonText={this.renderFilterText()}
						ariaLabel={this.ariaText()}
					/>
				)}
			/>
		);
	}
}

export default injectIntl(SprintFilter);
