import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	colorBy: {
		id: 'portfolio-3-portfolio.common.color-by-legend.color-by',
		defaultMessage: 'Color by',
		description: 'Title text of the colour by legend',
	},
	team: {
		id: 'portfolio-3-portfolio.common.color-by-legend.team',
		defaultMessage: 'Team',
		description: 'Title for colour by team',
	},
	priority: {
		id: 'portfolio-3-portfolio.common.color-by-legend.priority',
		defaultMessage: 'Priority',
		description: 'Title for colour by priority',
	},
	project: {
		id: 'portfolio-3-portfolio.common.color-by-legend.project',
		defaultMessage: 'Project',
		description: 'Title for colour by project',
	},
	component: {
		id: 'portfolio-3-portfolio.common.color-by-legend.component',
		defaultMessage: 'Component',
		description: 'Title for colour by component',
	},
	status: {
		id: 'portfolio-3-portfolio.common.color-by-legend.status',
		defaultMessage: 'Status',
		description: 'Title for colour by status',
	},
	// this message is there only to make Flow happy, but is actually not rendered in the frontend
	none: {
		id: 'portfolio-3-portfolio.common.color-by-legend.none',
		defaultMessage: 'None',
		description: 'Title for colour by none',
	},
});
