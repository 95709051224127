import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	requiredFieldsDialogTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.required-fields-dialog.uncontrolled-legacy-dialog.required-fields-dialog-title',
		defaultMessage: 'Set required fields',
		description:
			'The title of the dialog used to input required fields when committing an issue through the "Review changes" dialog',
	},
});
