export const LOAD = 'state.domain.update-jira.required-fields.LOAD' as const;

export const CANCEL = 'state.domain.update-jira.required-fields.CANCEL' as const;

export const SUBMIT = 'state.domain.update-jira.required-fields.SUBMIT' as const;

export const FAIL = 'state.domain.update-jira.required-fields.FAIL' as const;

export type LoadError = { error: string; errorMessages?: string[] };

type LoadActionPayload = {
	issueId: string;
	error?: LoadError;
};

export type LoadAction = {
	type: typeof LOAD;
	payload: LoadActionPayload;
};

export type CancelAction = {
	type: typeof CANCEL;
};

type SubmitActionPayload = {
	generatedItemId: string;
};

export type SubmitAction = {
	type: typeof SUBMIT;
	payload: SubmitActionPayload;
};

export type FailAction = {
	type: typeof FAIL;
};

export type Action = LoadAction | CancelAction | SubmitAction | FailAction;

export const load = (payload: LoadActionPayload) => ({
	type: LOAD,
	payload,
});

export const cancel = () => ({
	type: CANCEL,
});

export const submit = (payload: SubmitActionPayload) => ({
	type: SUBMIT,
	payload,
});

export const fail = () => ({
	type: FAIL,
});
