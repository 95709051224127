import React, { Component } from 'react';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import type { SelectedChanges } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query.tsx';
import type { Props, Item } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class SelectChangeCell extends Component<Props> {
	isSelected = () => {
		const { items, selectedChanges } = this.props;

		return items.length > 0 && items.every((item) => this.isItemSelected(item, selectedChanges));
	};

	isItemSelected = ({ id, category }: Item, selectedChanges: SelectedChanges) =>
		selectedChanges[category] && selectedChanges[category].includes(id);

	handleChange = () => {
		const { items, select, deselect } = this.props;
		const isSelected = this.isSelected();

		if (isSelected) {
			items.forEach((item) => deselect(item.id, item.category));
		} else {
			items.forEach((item) => select(item.id, item.category));
		}
	};

	render() {
		const { id, isUpdating } = this.props;
		const isSelected = this.isSelected();

		return (
			<Checkbox
				aria-labelledby={id}
				isChecked={isSelected}
				isDisabled={isUpdating}
				onChange={this.handleChange}
				testId={`portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.select-change.cell.checkbox-${id}`}
			/>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectChangeCell);
