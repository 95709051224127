import { isExportMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import {
	getGoalSearchesInProgress,
	getLazyGoalsByARI,
	getAllLazyGoals,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-goals/index.tsx';
import { getHasAtlasPermissions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { StateProps } from './types.tsx';

const mapStateToProps = (state: State): StateProps => ({
	isExportMode: isExportMode(state),
	hasAtlasPermissions: getHasAtlasPermissions(state),
	lazyGoalsByARI: getLazyGoalsByARI(state),
	searchesInProgress: getGoalSearchesInProgress(state),
	allLazyGoals: getAllLazyGoals(state),
});

export default mapStateToProps;
