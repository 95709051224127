import React, { type PropsWithChildren } from 'react';
import { Fieldset } from '@atlaskit/form';
import { Bleed } from '@atlaskit/primitives';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl, type IntlShape } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const FieldsetWrapper = ({
	isVisuallyRefreshed,
	intl,
	children,
}: PropsWithChildren<{ isVisuallyRefreshed: boolean; intl: IntlShape }>) => {
	if (isVisuallyRefreshed) {
		return (
			<fieldset>
				<legend>
					<VisuallyHidden>{intl.formatMessage(messages.rollupGroupLabel)}</VisuallyHidden>
				</legend>
				{children}
			</fieldset>
		);
	}

	return (
		<Bleed block="space.150">
			<Fieldset
				legend={<VisuallyHidden>{intl.formatMessage(messages.rollupGroupLabel)}</VisuallyHidden>}
			>
				{children}
			</Fieldset>
		</Bleed>
	);
};

const EstimateRollup = ({
	rollupSettings: { showingRolledUpDate, showRolledUpOthers },
	setShowingRolledUpDates,
	setShowRolledUpOthers,
}: Props) => {
	const intl = useIntl();
	const othersMessage = messages.showRolledUpOthers;

	return (
		<FieldsetWrapper
			isVisuallyRefreshed={isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_2')}
			intl={intl}
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.option}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.checkboxWrapper}>
					<Checkbox
						name="roll-up"
						aria-describedby="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.rollup-dates"
						isChecked={showingRolledUpDate}
						label={intl.formatMessage(commonMessages.dates)}
						onChange={(_, analyticsEvent) => {
							const nextValue = !showingRolledUpDate;

							const [actionSubject, action, actionSubjectId] =
								PRODUCT_ANALYTICS_EVENT_NAMES.ROLL_UP_DATES_CHANGED.split(' ');

							fireUIAnalytics(analyticsEvent.update({ actionSubject, action }), actionSubjectId, {
								newPlanSettingValue: nextValue,
							});
							setShowingRolledUpDates(nextValue);
						}}
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.rollup-dates"
					/>
				</div>
				<div
					id="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.rollup-dates"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.description}
				>
					{intl.formatMessage({
						...messages.showingRolledUpDatesDescription,
					})}
				</div>
			</div>

			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.option}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.checkboxWrapper}>
					<Checkbox
						name="roll-up"
						aria-describedby="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.rollup-others"
						isChecked={showRolledUpOthers}
						label={intl.formatMessage({ ...othersMessage })}
						onChange={(_, analyticsEvent) => {
							const nextValue = !showRolledUpOthers;

							const [actionSubject, action, actionSubjectId] =
								PRODUCT_ANALYTICS_EVENT_NAMES.ROLL_UP_OTHERS_CHANGED.split(' ');

							fireUIAnalytics(analyticsEvent.update({ actionSubject, action }), actionSubjectId, {
								newPlanSettingValue: nextValue,
							});
							setShowRolledUpOthers(nextValue);
						}}
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.rollup-others"
					/>
				</div>

				<div
					id="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.rollup-others"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.description}
				>
					{intl.formatMessage({
						...messages.showRolledUpOthersDescription,
					})}
				</div>
			</div>
		</FieldsetWrapper>
	);
};

export default EstimateRollup;
