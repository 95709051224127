import { TIMELINE_MODES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { createMigrate, incremental } from '../common/migrate.tsx';
import type {
	ModeOptionV0,
	TimeScaleOptionV0,
	TimeScaleStateV0,
	TimeScaleStateV1,
} from './types.tsx';

/**
 * maps the V0 selection value to the rough equivalent of the V1 mode value
 */
const mapSelection = (selection?: TimeScaleOptionV0): ModeOptionV0 => {
	switch (selection) {
		case '1yr':
			return TIMELINE_MODES.QUARTERS;
		case '3mth':
			return TIMELINE_MODES.MONTHS;
		case 'custom':
			return selection;
		default:
			return TIMELINE_MODES.MONTHS;
	}
};

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: TimeScaleStateV0): TimeScaleStateV0 {
				return prevState;
			},
			V1: function fromV0ToV1(prevState: TimeScaleStateV0): TimeScaleStateV1 {
				const mode = mapSelection(prevState.selection);
				return {
					...prevState,
					mode,
				};
			},
		},
		{ prefix: 'timeScale', from: 'V0' },
	),
);
