import {
	// eslint-disable-next-line jira/ff/no-alias
	ff as originalGetBooleanFeatureFlag,
	// eslint-disable-next-line jira/ff/no-alias
	getMultivariateFeatureFlag as originalGetMultivariateFeatureFlag,
} from '@atlassian/jira-feature-flagging';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getFeatureFlagValue } from '@atlassian/jira-feature-flagging';

type CallbackFn = (flag: string, defaultValue: boolean, storybookDefault: boolean) => void;

const callbacks: CallbackFn[] = [];

export const injectCallbackIntoGetBooleanFeatureFlag = (callback: CallbackFn) => {
	callbacks.push(callback);
};

export const getFlagOverrideFromURLParams = (flag: string) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const urlParams = new URLSearchParams(window.location.search);
	const ffOverrides = urlParams.get('ffOverrides');

	if (ffOverrides === undefined || ffOverrides === null) {
		return null;
	}

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const item = ffOverrides.split(',').find((item) => item.startsWith(`${flag}:`));
	return (item !== undefined && item.split(':')[1]) || null;
};

/**
 * @param defaultValue No other values allowed other than false go/ff-default
 */
export const getBooleanFeatureFlag = (
	flag: string,
	defaultValue: false = false,
	storybookDefault = true,
) => {
	for (const cb of callbacks) {
		cb(flag, defaultValue, storybookDefault);
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window?.STORYBOOK_ENV) {
		const overrideValue = getFlagOverrideFromURLParams(flag);
		if (overrideValue !== null) {
			return overrideValue === 'true';
		}
		return storybookDefault;
	}

	return originalGetBooleanFeatureFlag(flag, defaultValue);
};

export const getMultivariateFeatureFlag = (
	featureFlagName: string,
	defaultValue: string,
	possibleValues: string[] = [],
	storybookDefault?: string,
): string => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window?.STORYBOOK_ENV) {
		const overrideValue = getFlagOverrideFromURLParams(featureFlagName);
		if (overrideValue !== null) {
			return overrideValue;
		}
		return typeof storybookDefault === 'string' ? storybookDefault : defaultValue;
	}

	return originalGetMultivariateFeatureFlag(featureFlagName, defaultValue, possibleValues);
};
