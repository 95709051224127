import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	toggleButtonsLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.view-mode-switcher.toggle-buttons-label',
		defaultMessage: 'Select view mode',
		description:
			'The label which is attached to the view mode switcher for selecting between timeline and list view. This is not visible in the UI, but is read aloud by assistive technology like screen-readers.',
	},
	listOptionLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.view-mode-switcher.list-option-label',
		defaultMessage: 'List',
		description: 'The label which is displayed on the view mode switcher button for the list view',
	},
});

export default messages;
