import {
	SET_DELETE_DIALOG_VISIBILITY,
	REDIRECT_TO_SETTINGS,
	ENABLE_DELETE,
	DISABLE_DELETE,
	type SetDeleteDialogVisibilityAction,
	type DisableDeleteAction,
	type EnableDeleteAction,
	type RedirectToSettingsAction,
} from './actions.tsx';
import type { PlanSettings } from './types.tsx';

const initialState = {
	planTitle: 'This is a fake plan title',
	planId: 11,
	deleteDialogOpen: false,
	deleteButtonEnabled: true,
	redirectFrom: null,
} as const;

type Action =
	| SetDeleteDialogVisibilityAction
	| EnableDeleteAction
	| DisableDeleteAction
	| RedirectToSettingsAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: PlanSettings = initialState, action: Action) => {
	switch (action.type) {
		case SET_DELETE_DIALOG_VISIBILITY:
			return { ...state, deleteDialogOpen: action.payload };
		case REDIRECT_TO_SETTINGS:
			return { ...state, redirectFrom: action.payload };
		case ENABLE_DELETE:
			return { ...state, deleteButtonEnabled: true };
		case DISABLE_DELETE:
			return { ...state, deleteButtonEnabled: false };
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
