import React, { useEffect, type ReactNode } from 'react';
import { Text, Stack } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	MODAL,
	fireUIAnalytics,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useCttOnboardingTour } from '../../../controllers/ctt-onboarding/index.tsx';
import { CttSpotlights } from '../../../controllers/ctt-spotlights/index.tsx';
import { messages } from './messages.tsx';

interface Props {
	planId: number;
	setRedirectToSpaPlanPage: (route: string) => void;
	hasAnyInitiatives: boolean;
	setOpenHorizontalPlanActionMenu?: () => void; // remove optional when cleaning getWillShowNav4
}

export const NavigateIssueSourcesSpotlight = ({
	planId,
	setRedirectToSpaPlanPage,
	hasAnyInitiatives,
	setOpenHorizontalPlanActionMenu,
}: Props) => {
	const { formatMessage } = useIntl();
	const [, { nextCttStage }] = useCttOnboardingTour();

	useEffect(() => {
		if (getWillShowNav4()) {
			setOpenHorizontalPlanActionMenu?.();
		}
	}, [setOpenHorizontalPlanActionMenu, planId]);

	return (
		<ContextualAnalyticsData
			sourceName="topLevelPlanningNavigateIssueSourcesSpotlight"
			sourceType={MODAL}
		>
			<JiraSpotlight
				actions={[
					{
						text: formatMessage(messages.spotlightGotToIssueSources),
						onClick: (_, analyticsEvent) => {
							fireUIAnalytics(analyticsEvent, 'topLevelPlanningNavigateToIssueSources', {
								isInitiativeCreated: hasAnyInitiatives,
							});
							setRedirectToSpaPlanPage('/settings/issue-sources');
							nextCttStage();
						},
					},
				]}
				heading={formatMessage(messages.spotlightNavigateIssueSourcesTitle)}
				target={CttSpotlights.Settings}
				key={CttSpotlights.Settings}
				targetRadius={3}
				targetBgColor={token('elevation.surface', colors.N0)}
				messageId="portfolio-3-onboarding.ui.ctt-onboarding.spotlight-navigate-issue-sources.jira-spotlight"
				messageType="engagement"
			>
				<Stack space="space.100">
					<Text>
						<FormattedMessage {...messages.spotlightNavigateIssueSourcesBodyP1} />
					</Text>
					<Text>
						{formatMessage(messages.spotlightNavigateIssueSourcesBodyP2, {
							b: (chunks: ReactNode[]) => <Text weight="bold">{chunks}</Text>,
						})}
					</Text>
				</Stack>
			</JiraSpotlight>
			<FireScreenAnalytics
				attributes={{
					isInitiativeCreated: hasAnyInitiatives,
				}}
			/>
		</ContextualAnalyticsData>
	);
};
