import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { useScrollY } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';

interface Props {
	hasGroups: boolean;
}

const HEADERS_HEIGHT = 90;
const GROUP_HEADER_HEIGHT = 40;

export const ScrollShadowOverlay = ({ hasGroups }: Props) => {
	const [scrollY] = useScrollY();

	return (
		<>
			<ZIndex layer={Z_INDEX_LAYER.STICKY_HEADER_SCROLL_SHADOW}>
				{(zIndex) => (
					/* Table headers shadow */
					<Box
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.overlay.scroll-shadow.sticky"
						xcss={[stickyStyles, scrollY > 0 && activeStickyStyles]}
						style={{ zIndex }}
					>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
						<Box xcss={[cardStyles, raisedCardStyles]} style={{ height: HEADERS_HEIGHT }} />
					</Box>
				)}
			</ZIndex>
			{hasGroups && (
				// Group header bottom border
				<ZIndex layer={Z_INDEX_LAYER.STICKY_GROUP_SCROLL_SHADOW}>
					{(zIndex) => (
						<Box
							testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.overlay.scroll-shadow.sticky"
							xcss={[stickyStyles, scrollY > 0 && activeStickyStyles]}
							style={{ zIndex }}
						>
							<Box
								xcss={[cardStyles, loweredCardStyles]}
								style={{ height: HEADERS_HEIGHT + GROUP_HEADER_HEIGHT }}
							/>
						</Box>
					)}
				</ZIndex>
			)}
		</>
	);
};

const stickyStyles = xcss({
	position: 'sticky',
	top: 'space.0',
	left: 'space.0',
	width: '100%',
	height: '0',
	pointerEvents: 'none',
	transition: 'opacity 0.1s',
	opacity: 0,
});

const activeStickyStyles = xcss({
	transition: 'opacity 0.2s',
	opacity: 1,
});

const cardStyles = xcss({
	boxSizing: 'content-box',
	position: 'absolute',
	top: 'space.0',
	left: 'space.0',
	right: 'space.0',
});

const loweredCardStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderBottom: `1px solid ${token('color.background.accent.gray.subtler', colors.N50)}`,
});

const raisedCardStyles = xcss({
	boxShadow: 'elevation.shadow.overflow',
});
