import React, { memo, useRef } from 'react';
import isEqual from 'lodash/fp/isEqual';
import omit from 'lodash/fp/omit';
import type { Props } from './types.tsx';

const withoutTopOffset = omit('topOffset');

/** Provides the item by index by looking at items at getTableItems() */
export const ItemProvider = ({ item, children }: Props) => {
	const memoedRef = useRef(item);
	// Performance note:
	// The table item, or even the issue object (if any) in the item, is changed every time it's calculated at getTableItems()
	// This method is trying not the change the ref of the item when it's not necessary to avoid re-render for inner components
	memoedRef.current = isEqual(withoutTopOffset(item), withoutTopOffset(memoedRef.current))
		? memoedRef.current
		: item;

	return <MemoedItemProvider item={memoedRef.current}>{children}</MemoedItemProvider>;
};

export const MemoedItemProvider = memo(({ item, children }: Pick<Props, 'item' | 'children'>) =>
	children(item),
);
