.actionGroup {
	margin-top: calc(var(--jpo-common-base-spacing) * 2);
}

.actionGroupHeader {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-weight: 600;
	font-size: 12px;
}

.actionGroupRadios {
	margin-top: calc(var(--jpo-common-base-spacing) / 2);
}
