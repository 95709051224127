import React from 'react';
import { Text } from '@atlaskit/primitives';
import prsLocaleMapper from '@atlaskit/prs-locale-mapper';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { isDataExtra } from '@atlassian/jira-platform-services-user-current/src/types.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import DocLink from '@atlassian/jira-portfolio-3-portfolio/src/common/view/doc-link/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { PeopleAndTeamsConfigurationProvider } from '@atlassian/people-teams-configuration-client';
import { lazy } from '@atlassian/react-loosely-lazy';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { TwpTeamCreationContent } from './twp-create-content.tsx';
import type { Props } from './types.tsx';

export default function CreateTWPTeamForm({
	addAtlassianTeam,
	isProcessingRequest,
	onClose,
	initiateRequest,
	resetRequest,
}: Props) {
	const cloudId = useCloudId();
	const { locale } = useIntl();
	const { data: orgId } = useOrgId();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	const AsyncAkTeamCreateContent = lazy<typeof TwpTeamCreationContent>(
		() =>
			import(
				/* webpackChunkName: "async-portfolio-3-create-twp-team-dialog" */ './twp-create-content'
			).then(({ TwpTeamCreationContent }) => {
				// show the dialog
				resetRequest();
				return TwpTeamCreationContent;
			}),
		{ ssr: false },
	);

	const {
		data: { user },
	} = useCurrentUser();

	initiateRequest();

	return (
		<Placeholder name="create-twp-team-dialog" fallback={<></>}>
			<PeopleAndTeamsConfigurationProvider product="jira" locale={prsLocaleMapper(locale)}>
				<AsyncAkTeamCreateContent
					cloudId={cloudId}
					orgId={orgId || undefined}
					currentUser={{
						id: user.accountId || '',
						fullName: user.userFullname,
						avatarUrl: isDataExtra(user) ? user.avatarUrls['48x48'] : undefined,
					}}
					principalId={user.accountId || ''}
					product="jira"
					onClose={() => {
						// Prevent people team's form from triggering onClose when addAtlassianTeam request still runs
						// This is also to ensure the success confirmation dialog can be displayed
						if (!isProcessingRequest) {
							onClose();
						}
					}}
					onCreateTeamSuccess={(team, members) => {
						const teamMembers = (members || []).map((member) => member.id);

						addAtlassianTeam({
							id: team.id,
							title: team.displayName,
							members: teamMembers,
						});

						// Fire analytics event
						const [actionSubject, action] =
							PRODUCT_ANALYTICS_EVENT_NAMES.ATLAS_TEAM_CREATED.split(' ');
						fireUIAnalytics(
							createAnalyticsEvent({
								action,
								actionSubject,
							}),
						);
					}}
					customMessages={{
						modalTitle: <FormattedMessage {...messages.title} />,
						submitButtonLabel: <FormattedMessage {...messages.createTeam} />,
						descriptionContent: (
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							<div className={styles['description-paragraphs']}>
								<>
									<Text as="p">
										<FormattedMessage {...messages.descriptionParagraph1} />
									</Text>
									<Text as="p">
										<FormattedMessage {...messages.descriptionParagraph2} />{' '}
										<DocLink href={DocLink.URLs.manageTeamInJira}>
											<FormattedMessage {...messages.docLink} />
										</DocLink>
									</Text>
								</>
							</div>
						),
					}}
				/>
			</PeopleAndTeamsConfigurationProvider>
		</Placeholder>
	);
}
