import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	removeScenarioIssueTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.remove-issue-dialog.remove-scenario-issue-title',
		defaultMessage: 'Delete draft issue',
		description: 'Remove scenario issue dialog title',
	},
	removeSavedIssueTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.remove-issue-dialog.remove-saved-issue-title',
		defaultMessage: 'Remove issue from plan',
		description: 'Remove saved issue dialog title',
	},
	removeScenarioIssueMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.remove-issue-dialog.remove-scenario-issue-message',
		defaultMessage: 'This issue will be permanently deleted from the plan.',
		description: 'Remove scenario issue message',
	},
	removeSavedIssueMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.remove-issue-dialog.remove-saved-issue-message',
		defaultMessage: `{issueIdentifier, select,
			undefined {This issue will no longer appear in the plan but will be stored in <b>Removed issues</b> within <b>Plan settings</b>.}
			other {{issueIdentifier} will no longer appear in the plan but will be stored in <b>Removed issues</b> within <b>Plan settings</b>.}}`,
		description: 'Remove saved issue message',
	},
	removeScenarioIssueActionButton: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.remove-issue-dialog.remove-scenario-issue-action-button',
		defaultMessage: 'Delete',
		description: 'Remove scenario issue action button',
	},
	removeSavedIssueActionButton: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.remove-issue-dialog.remove-saved-issue-action-button',
		defaultMessage: 'Remove',
		description: 'Remove saved issue action button',
	},
});
