import React, { Component } from 'react';
import { StatusLozenge as IssueStatusFieldReadonly } from '@atlassian/jira-issue-field-status/src/ui/status-lozenge/index.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export default class Status extends Component<Props> {
	render() {
		const { statusId, issueStatusesById } = this.props;
		const status = statusId && issueStatusesById && issueStatusesById[statusId];
		if (!status) {
			return <div>-</div>;
		}
		const { id, name, categoryId } = status;
		return (
			<div data-testid={`status-changed-${id}`}>
				<IssueStatusFieldReadonly value={{ id, name, statusCategory: { id: categoryId } }} />
			</div>
		);
	}
}
