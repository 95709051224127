import { IsoDateFormat } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { startOfUtcDay } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { DateRangeFilterValueV0 } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';

export const convertToDateString = (timestamp: number | undefined): string =>
	timestamp !== undefined ? formatDateUTC(timestamp, IsoDateFormat) : '';

export const convertToTimestamp = (date: string | undefined): number | undefined =>
	date !== undefined && date !== '' ? startOfUtcDay(date) : undefined;

export const createAndHandleDateRangeChange = (
	newStartDate: string | undefined,
	newEndDate: string | undefined,
): DateRangeFilterValueV0 => {
	const startDateTmp = convertToTimestamp(newStartDate);
	const endDateTmp = convertToTimestamp(newEndDate);

	return {
		...(startDateTmp !== undefined && { startDate: startDateTmp }),
		...(endDateTmp !== undefined && { endDate: endDateTmp }),
	};
};
