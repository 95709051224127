import React, { type PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import { Box } from '@atlaskit/primitives';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { Placement } from '../types.tsx';

const DEFAULT_OFFSET = 24;

type Props = {
	placement?: Placement;
};

type Offset = {
	top: number;
	bottom: number;
};

const ShrinkToFitNew = ({ children, placement = 'top-end' }: PropsWithChildren<Props>) => {
	const [offset, setOffset] = useState<Offset>({
		top: DEFAULT_OFFSET,
		bottom: DEFAULT_OFFSET,
	});
	const rootRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		/**
		 * Wait until animation frame before taking measurement, because popup does not position correctly straight
		 * away for *some reason*.
		 */
		const animationFrame = requestAnimationFrame(() => {
			if (!rootRef.current) return;

			const rect = rootRef.current.getBoundingClientRect();

			const distanceFromTop = rect.top;
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const distanceFromBottom = window.innerHeight - rect.bottom;

			if (placement.startsWith('top'))
				setOffset({
					top: DEFAULT_OFFSET,
					bottom: distanceFromBottom,
				});
			else
				setOffset({
					top: distanceFromTop,
					bottom: DEFAULT_OFFSET,
				});
		});

		return () => cancelAnimationFrame(animationFrame);
	}, [placement]);

	return (
		<Box
			ref={rootRef}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ maxHeight: `calc(100vh - ${offset.bottom}px - ${offset.top}px)` }}
		>
			{children}
		</Box>
	);
};

const ShrinkToFitOld = ({ children }: PropsWithChildren<{}>) => {
	const [bottomOffset, setBottomOffset] = useState<number>(DEFAULT_OFFSET);
	const rootRef = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		/**
		 * Wait until animation frame before taking measurement, because popup does not position correctly straight
		 * away for *some reason*.
		 */
		const animationFrame = requestAnimationFrame(() => {
			if (!rootRef.current) return;

			const rect = rootRef.current.getBoundingClientRect();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const distanceFromBottom = window.innerHeight - rect.bottom;

			setBottomOffset(distanceFromBottom);
		});

		return () => cancelAnimationFrame(animationFrame);
	}, []);

	return (
		<Box
			ref={rootRef}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ maxHeight: `calc(100vh - ${bottomOffset}px - ${DEFAULT_OFFSET}px)` }}
		>
			{children}
		</Box>
	);
};

export default componentWithFG('smart_links_for_plans', ShrinkToFitNew, ShrinkToFitOld);
