import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	optionDisabledInListView: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.view-setting-select.option-disabled-in-list-view',
		defaultMessage: 'This option is unavailable in list view',
		description:
			'Label for dependency settings disabled tooltip message in view settings for the list view',
	},
	selectAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.view-setting-select.select-aria-label',
		defaultMessage: '{label} {selectedValue}',
		description:
			'AriaLabel for select component in viewSettings dropdown, where {label} is the label associated with the particular input and {selectedValue} is the value selected',
	},
});
