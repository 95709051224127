import React, { type ReactNode } from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { AkFlag as Flag, FlagGroup } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

function DeleteErrorNotification({ dismissFlag, viewName }: Props) {
	const { formatMessage } = useIntl();

	const title = formatMessage(messages.title);
	const description = formatMessage(messages.description, {
		viewName,
		strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
	});
	const icon = (
		<WarningIcon
			label={formatMessage(messages.deleteErrorIconLabel)}
			primaryColor={token('color.icon.warning', colors.Y300)}
		/>
	);

	return (
		<FlagGroup onDismissed={dismissFlag}>
			<Flag
				id="delete-error-notification-flag"
				title={title}
				description={description}
				icon={icon}
			/>
		</FlagGroup>
	);
}

export default DeleteErrorNotification;
