import type { Dispatch } from 'redux';
import type {
	AtlassianTeamPayload,
	EditTeamPayload,
	TeamPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams/api.tsx';
import {
	type EditedTeam,
	addAtlassianTeam,
	createTeam,
	editTeam,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/teams/index.tsx';
import {
	openTeamsDialog,
	setMode,
	EDIT_MODE,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/teams/list/actions.tsx';
import type { Action, DispatchProps } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action>): DispatchProps => ({
	createTeam: (payload: TeamPayload) => {
		dispatch(createTeam(payload));
	},
	editTeam: (payload: EditTeamPayload) => {
		dispatch(editTeam(payload));
	},
	onAddAtlassianTeam: (payload: AtlassianTeamPayload) => {
		dispatch(addAtlassianTeam(payload));
	},
	setEditMode: (team: EditedTeam) => {
		dispatch(openTeamsDialog(team));
		dispatch(setMode(EDIT_MODE));
	},
});
