import type { SchedulingMode } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';

export const OPEN_TEAMS_DIALOG = 'state.ui.main.tabs.teams.list.OPEN_TEAMS_DIALOG' as const;

export const CLOSE_TEAMS_DIALOG = 'state.ui.main.tabs.teams.list.CLOSE_TEAMS_DIALOG' as const;

export const INITIATE_REQUEST = 'state.ui.main.tabs.teams.list.INITIATE_REQUEST' as const;

export const RESET_REQUEST = 'state.ui.main.tabs.teams.list.RESET_REQUEST' as const;

export const SET_MODE = 'state.ui.main.tabs.teams.list.SET_MODE' as const;

export const CREATE_MODE = 'state.ui.main.tabs.teams.list.CREATE_MODE' as const;

export const EDIT_MODE = 'state.ui.main.tabs.teams.list.EDIT_MODE' as const;

export const SHOW_SHARED_TEAM_SUCCESS_FLAG =
	'state.ui.main.tabs.teams.list.SHOW_SHARED_TEAM_SUCCESS_FLAG' as const;

export type DialogMode = typeof CREATE_MODE | typeof EDIT_MODE;

export type EditedTeam = {
	teamId: string;
	title: string;
	recommendedSchedulingMode?: SchedulingMode;
};

export type SetModeAction = {
	type: typeof SET_MODE;
	payload: DialogMode;
};

export const setMode = (payload: DialogMode): SetModeAction => ({
	type: SET_MODE,
	payload,
});

export type OpenTeamsDialogAction = {
	type: typeof OPEN_TEAMS_DIALOG;
	payload: {
		editedTeam?: EditedTeam;
	};
};

export type CloseTeamsDialogAction = {
	type: typeof CLOSE_TEAMS_DIALOG;
};

export type ShowSharedTeamSuccessFlagAction = {
	type: typeof SHOW_SHARED_TEAM_SUCCESS_FLAG;
	payload: {
		showSharedTeamSuccessFlag: boolean;
	};
};

export type InitiateRequestAction = {
	type: typeof INITIATE_REQUEST;
};

export type ResetRequestAction = {
	type: typeof RESET_REQUEST;
};

export const openTeamsDialog = (editedTeam?: EditedTeam): OpenTeamsDialogAction => ({
	type: OPEN_TEAMS_DIALOG,
	payload: {
		editedTeam,
	},
});

export const closeTeamsDialog = (): CloseTeamsDialogAction => ({
	type: CLOSE_TEAMS_DIALOG,
});

export const showSharedTeamSuccessFlag = (
	// eslint-disable-next-line @typescript-eslint/no-shadow
	showSharedTeamSuccessFlag: boolean,
): ShowSharedTeamSuccessFlagAction => ({
	type: SHOW_SHARED_TEAM_SUCCESS_FLAG,
	payload: {
		showSharedTeamSuccessFlag,
	},
});

export const initiateRequest = () => ({
	type: INITIATE_REQUEST,
});

export const resetRequest = () => ({
	type: RESET_REQUEST,
});
