import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';

export const projectToGroupMapComparator = (
	a: [number | null, GroupOption[]],
	b: [number | null, GroupOption[]],
) => {
	// Separated cases to handle null case for flow
	// null case should be rendered at the top
	if (a[0] === null) {
		return -1;
	}
	if (b[0] === null) {
		return 1;
	}
	if (a[0] < b[0]) {
		return -1;
	}
	if (a[0] > b[0]) {
		return 1;
	}
	return 0;
};
