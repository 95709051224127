import prop from 'lodash/fp/prop';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import { getHierarchyLevelsByLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy/index.tsx';
import { getIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getScope } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import {
	getDisableDnD,
	getTableItems,
	TABLE_GROUP_HEADER,
	TABLE_ISSUE,
	type TableItem,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import { getVisualisationSorting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type {
	GroupCombination,
	ScopeIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import { DEFAULT_SCRATCH_ISSUE_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/util.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/issue/drop-menu/create-issue/types.tsx';
import type {
	Issues,
	EnhancedGroupHeaderData,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/types.tsx';
import type { HierarchyLevel } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';

export const getEnhancedIssuesPureFunc = (
	tableItems: TableItem[],
	issueTypes: IssueType[],
	projects: Project[],
	hierarchyLevelsByLevel: {
		[level: number]: HierarchyLevel;
	},
): Issues => {
	const id = prop('id');
	const issueTypesById = indexBy(id, issueTypes);
	const projectsById = indexBy(id, projects);
	// NOTE `enhance` is written in ill-typed and mutable style for the sake of performance
	// this code turned out to be a hot spot and we want to reduce GC and computational load
	const enhance = (issue: ScopeIssue): Issue => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const result: Record<string, any> = {};
		for (const key of Object.keys(issue)) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			result[key] = issue[key as keyof ScopeIssue];
		}
		result.type = issueTypesById[issue.type] || DEFAULT_SCRATCH_ISSUE_TYPE; // May not exist if issue sources have changed.
		result.project = projectsById[issue.project];
		// unsafe cast, but scope of `result` is small which helps to ensure it's proper structure
		// by inspecting the code above
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
		return result as any;
	};
	return tableItems.map((item) => {
		switch (item.tag) {
			case TABLE_ISSUE: {
				const { tag, value: issue, group, parentGroup, groupCombination } = item;
				const enhancedIssue: {
					tag: typeof TABLE_ISSUE;
					value: Issue;
					group?: string;
					parentGroup?: string | null | undefined;
					groupCombination?: GroupCombination;
				} = {
					tag,
					value: enhance(issue),
					group,
					parentGroup,
					groupCombination,
				};
				return enhancedIssue;
			}
			case TABLE_GROUP_HEADER: {
				const {
					tag,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					value: { id, isExpanded, level, rootIssuesCount },
					group,
					parentGroup,
				} = item;
				const enhancedHeader: {
					tag: typeof TABLE_GROUP_HEADER;
					value: EnhancedGroupHeaderData;
					group?: string;
					parentGroup?: string | null | undefined;
				} = {
					tag,
					value: {
						id,
						isExpanded,
						level: hierarchyLevelsByLevel[level],
						rootIssuesCount,
					},
					group,
					parentGroup,
				};
				return enhancedHeader;
			}
			default:
				return item;
		}
	});
};

export const getEnhancedIssuesPure = monitor.timeFunction<
	Parameters<typeof getEnhancedIssuesPureFunc>,
	Issues
>(getEnhancedIssuesPureFunc, 'roadmap_scope_issues_query_getEnhancedIssuesPure');

export const getEnhancedIssues = createSelector(
	[getTableItems, getIssueTypes, getProjects, getHierarchyLevelsByLevel],
	getEnhancedIssuesPure,
);

export default createSelector(
	[getEnhancedIssues, getDisableDnD, getScope, getVisualisationSorting],
	(issues, disableDnD, scope, sorting) => ({
		disableDnD,
		issues,
		startLevel: scope.startLevelIssues.level,
		sorting,
	}),
);
