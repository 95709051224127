import * as R from 'ramda';
import type { ReleaseStatusValue } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { RELEASE_STATUSES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { EnrichedCrossProjectVersion } from '../../state/domain/cross-project-versions/types.tsx';

export const getReleaseStatus = ({ versions }: EnrichedCrossProjectVersion): ReleaseStatusValue => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	if (R.all(R.propEq('releaseStatusId', RELEASE_STATUSES.RELEASED as string))(versions)) {
		return RELEASE_STATUSES.RELEASED;
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	if (R.any(R.propEq('releaseStatusId', RELEASE_STATUSES.OFFTRACK as string))(versions)) {
		return RELEASE_STATUSES.OFFTRACK;
	}
	return RELEASE_STATUSES.ONTRACK;
};
