import React from 'react';
import * as R from 'ramda';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import {
	groupSortComparator,
	UNASSIGNED_GROUP,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import type {
	Group,
	ScopeIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Grouping } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { UserSelectItem } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/user-picker/index.tsx';

const createGroupOptionFromUser = (
	grouping: Grouping,
	groups: Group<ScopeIssue>[],
	user: OptionType,
): GroupOption => {
	const { email, icon, label, value } = user;
	const isUnassigned = R.toLower(label) === UNASSIGNED_GROUP;
	return {
		formattedTitle: <UserSelectItem email={email} label={label} icon={icon} />,
		group: `${grouping}-${value}`,
		groupCombination: { [grouping]: value },
		grouping,
		groupName: isUnassigned ? UNASSIGNED_GROUP : [label, email].join(' '),
		startInlineCreateInEmptyGroup: !groups.some(
			(group: Group<ScopeIssue>) =>
				group.groupCombination &&
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				group.groupCombination[grouping as keyof typeof group.groupCombination] === value,
		),
	};
};

export const getGroupOptionsFromUsers = (
	grouping: Grouping,
	groups: Group<ScopeIssue>[],
	userList: OptionType[],
): GroupOption[] =>
	userList
		.map((user) => createGroupOptionFromUser(grouping, groups, user))
		.sort(groupSortComparator);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createOption = ({ jiraUser: { email, title, avatarUrl, accountId } }: any) => ({
	email,
	value: accountId,
	label: title,
	icon: avatarUrl,
});
