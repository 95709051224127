import React from 'react';
import IssuesWithoutParentHeader from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/issues-without-parent-header/index.tsx';
import type { Props } from './types.tsx';

export const WithoutParentHeader = ({ item, onToggle }: Props) => (
	<IssuesWithoutParentHeader
		issuesCount={item.value.issueCount}
		isExpanded={item.value.isExpanded}
		id={item.value.id}
		onToggle={onToggle}
	/>
);
