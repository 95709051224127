import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import {
	getOriginalCapacityForTeamAndIteration,
	getCapacityForTeamAndIteration,
	getSprintsCapacityOnTimeline,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/index.tsx';
import { getJiraHoursPerDay } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import { getTeamsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/index.tsx';
import { getZoomLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { OwnProps, StateProps } from './types.tsx';

const getPlannedCapacity = (state: State, { sprint, team }: OwnProps) =>
	getCapacityForTeamAndIteration(state, {
		teamId: team,
		iterationId: sprint,
	});

const getOriginalPlannedCapacity = (state: State, { sprint, team }: OwnProps) =>
	getOriginalCapacityForTeamAndIteration(state, {
		teamId: team,
		iterationId: sprint,
	});

const mapStateToProps: MapStateToProps<StateProps, OwnProps> = (state, ownProps) => ({
	teamObject: getTeamsById(state)[ownProps.team],
	plan: getPlan(state),
	sprintsCapacityOnTimeline: getSprintsCapacityOnTimeline(state),
	workingHours: getJiraHoursPerDay(state),
	isUpdated: !!getOriginalPlannedCapacity(state, ownProps),
	plannedCapacity: getPlannedCapacity(state, ownProps),
	zoomLevel: getZoomLevel(state),
});

export default mapStateToProps;
