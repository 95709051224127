import {
	getAllIssuesWithOriginalAndOptimized,
	getIssuesWithOriginalsAndOptimized,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import {
	getApplyingProgress,
	getIsSolutionLatest,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/solution/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, {}, StateProps>({
	allIssues: getAllIssuesWithOriginalAndOptimized,
	filteredIssues: getIssuesWithOriginalsAndOptimized,
	isLatest: getIsSolutionLatest,
	progress: getApplyingProgress,
});
