import { defineMessages } from '@atlassian/jira-intl';

// eslint-disable-next-line formatjs/blocklist-elements
export default defineMessages({
	currentValuePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.set-rank-form.current-value-placeholder',
		defaultMessage: 'Various ranks',
		description: 'Label for current rank cell',
	},
	chooseValuePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.set-rank-form.choose-value-placeholder',
		defaultMessage: 'Choose an issue',
		description: 'Placeholder for issue select',
	},
	rankTop: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.set-rank-form.rank-top',
		defaultMessage: 'Top of plan',
		description: 'Option Label for rank to top',
	},
	rankLast: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.set-rank-form.rank-last',
		defaultMessage: 'Bottom of plan',
		description: 'Option Label for rank to bottom',
	},
	rankBefore: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.set-rank-form.rank-before',
		defaultMessage: 'Above issue',
		description: 'Option Label for rank Above issue',
	},
	rankAfter: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.set-rank-form.rank-after',
		defaultMessage: 'Below issue',
		description: 'Option Label for rank Below issue',
	},
	currentValue: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.set-rank-form.current-value',
		defaultMessage: '{currentValue, selectordinal, one {#st} two {#nd} few {#rd} other{#th}}',
		description: 'Label for current value',
	},
	variousValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.set-rank-form.various-values',
		defaultMessage: 'Various ranks',
		description: 'Label for various rank values',
	},
	optionRankLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-rank.set-rank-form.option-rank-label',
		defaultMessage:
			'Rank {currentIndex, selectordinal, one {#st} two {#nd} few {#rd} other{#th}} in plan',
		description: 'Label for current value',
	},
});
