import * as R from 'ramda';
import {
	STORY_LEVEL,
	SUB_TASK_LEVEL,
} from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import {
	chain,
	fromPairs,
	indexBy,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { IssueType } from '../issue-types/types.tsx';
import type { EnrichedHierarchyLevel, HierarchyLevel, TypeToLevel } from './types.tsx';

export const getTypeToLevelPure = (
	levels: HierarchyLevel[] = [],
	issueTypes: IssueType[],
): TypeToLevel => {
	const issueTypesById = indexBy(R.prop('id'), issueTypes);
	const typeToLevelMap = fromPairs(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		chain(({ level, issueTypes = [] }) => issueTypes.map((type) => [type, level]), levels),
	);
	return (type: number) =>
		R.defaultTo(
			issueTypesById[type] && issueTypesById[type].subTask === true ? SUB_TASK_LEVEL : STORY_LEVEL,
			typeToLevelMap[type],
		);
};

export const getEnrichedHierarchyLevelsPure = (
	levels: HierarchyLevel[] = [],
	issueTypes: IssueType[],
): EnrichedHierarchyLevel[] => {
	const typeToLevel = getTypeToLevelPure(levels, issueTypes);

	const enriched = R.map(
		({ level, title }): { level: number; title: string; issueTypes: IssueType[] } => ({
			level,
			title,
			issueTypes: [],
		}),
		levels,
	);

	for (const type of issueTypes) {
		const level = typeToLevel(type.id);
		const enrichedLevelIndex = enriched.findIndex((enrichedLevel) => enrichedLevel.level === level);
		enriched[enrichedLevelIndex].issueTypes.push(type);
	}
	return enriched;
};
