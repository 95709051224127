import * as R from 'ramda';
import type { JiraUser, Person } from '../../state/domain/reporters/types.tsx';

const sortByPersonTitle = R.sortBy((el) => R.toLower(el.jiraUser.title));

export const sortPerson: (arg1: Person[]) => Person[] = R.pipe(sortByPersonTitle);

const getFullText = R.pipe(
	R.prop<'jiraUser', JiraUser>('jiraUser'),
	(user) => [user?.email ?? '', user.title],
	R.join(' '),
	R.toLower,
);

const matchQuery = R.curry((query: string, person: Person): boolean =>
	R.includes(R.toLower(query))(getFullText(person)),
);

export const filter: (arg1: string, arg2: Person[]) => Person[] = R.curry(
	(query: string, people: Person[]): Person[] => R.filter(matchQuery(query))(people),
);
