import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import EmptyFields from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/columns/empty/index.tsx';
import { getMatchingConfiguredDate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/utils.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { useColumnWidths } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import type { Props } from './types.tsx';

export const EmptyListModeContainer = ({ columns, dateConfiguration }: Props) => {
	const [columnWidths] = useColumnWidths({ preview: true });

	const marginLeft = columnWidths.reduce(
		(totalWidth, curWidth, index) =>
			index < columnWidths.length - 1 ? totalWidth + curWidth : totalWidth,
		0,
	);

	const emptyFieldWidth = columnWidths.at(-1);

	return (
		<ZIndex layer={Z_INDEX_LAYER.EMPTY_LIST_OVERLAY}>
			{(zIndex) => (
				<Box xcss={containerStyles} style={{ zIndex }}>
					<Box xcss={layerAlignedStyles} style={{ width: emptyFieldWidth, marginLeft }}>
						<Box xcss={emptyStyles}>
							<EmptyFields
								columns={columns}
								getMatchingConfiguredDate={getMatchingConfiguredDate(dateConfiguration)}
								width={emptyFieldWidth}
							/>
						</Box>
					</Box>
				</Box>
			)}
		</ZIndex>
	);
};

const containerStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	pointerEvents: 'none',
});

const layerAlignedStyles = xcss({
	position: 'relative',
	height: '100%',
	top: '90px',
	pointerEvents: 'initial',
});

const emptyStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	width: '100%',
	height: '100%',
	transition: 'opacity 0.2s ease-in-out',
	overflow: 'visible',
});
