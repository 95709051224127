import {
	type InitiateFiltersAction,
	SET_ROLLUP_FILTER,
	SET_ISSUE_IN_FOCUS,
	SET_GROUP_BY,
	SET_ISSUE_FILTER,
	SET_PROJECT_FILTER,
	SET_ISSUE_LINK_TYPE_FILTER,
	SET_SPRINT_FILTER,
	RESET_FILTERS,
	type SetRollUpFilterAction,
	type SetIssueInFocusAction,
	type SetGroupBy,
	type SetIssueFilter,
	type SetProjectFilter,
	type SetIssueLinkTypeFilter,
	type SetSprintFilter,
	type ResetFiltersAction,
	INITIATE_FILTERS,
} from './actions.tsx';
import type { Filters } from './types.tsx';

const initialState: Filters = {
	rollupBy: null,
	rollupHierarchyLevel: -1,
	groupBy: null,
	issueInFocus: null,
	issueFilter: null,
	projectFilter: null,
	sprintFilter: null,
	issueLinkTypeFilter: null,
};

type Action =
	| SetRollUpFilterAction
	| SetIssueInFocusAction
	| SetGroupBy
	| SetIssueFilter
	| SetProjectFilter
	| SetIssueLinkTypeFilter
	| SetSprintFilter
	| ResetFiltersAction
	| InitiateFiltersAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Filters = initialState, action: Action) => {
	switch (action.type) {
		case SET_ROLLUP_FILTER: {
			return {
				...state,
				rollupBy: action.payload.rollupBy,
				rollupHierarchyLevel: action.payload.rollupHierarchyLevel,
				issueFilter: null,
			};
		}
		case SET_ISSUE_IN_FOCUS:
			return {
				...state,
				issueInFocus: action.payload,
			};
		case SET_GROUP_BY: {
			return {
				...state,
				groupBy: action.payload,
			};
		}
		case SET_ISSUE_FILTER: {
			return {
				...state,
				issueFilter: action.payload,
			};
		}
		case SET_PROJECT_FILTER: {
			return {
				...state,
				projectFilter: action.payload,
			};
		}
		case SET_ISSUE_LINK_TYPE_FILTER: {
			return {
				...state,
				issueLinkTypeFilter: action.payload,
			};
		}
		case SET_SPRINT_FILTER: {
			return {
				...state,
				sprintFilter: action.payload,
			};
		}
		case RESET_FILTERS:
			return {
				...state,
				...initialState,
			};
		case INITIATE_FILTERS:
			return {
				...state,
				...action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
