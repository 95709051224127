import {
	isConfluenceMacro,
	isEmbed,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import { isAtlasConnectInstalled } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import { getVisualisationGrouping } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Props, StateProps } from './types.tsx';

export const mapStateToProps = createStructuredSelector<State, Props, StateProps>({
	customFields: getCustomFields,
	grouping: getVisualisationGrouping,
	isAtlasConnectInstalled,
	isEmbedMode: isEmbed,
	isConfluenceMacroMode: isConfluenceMacro,
});
