import React from 'react';
import {
	withSlots,
	slots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { Props } from './types.tsx';

export const ReporterCell = ({ issue, issueReporterMap, FieldUserAvatarCell }: Props) => {
	if (isDefined(issue.reporter) && isDefined(issueReporterMap[issue.id])) {
		const reporter = issueReporterMap[issue.id];
		return (
			<FieldUserAvatarCell
				title={reporter.jiraUser.title}
				avatarUrl={reporter.jiraUser.avatarUrl}
			/>
		);
	}
	return null;
};

export default withSlots({ FieldUserAvatarCell: slots.FieldUserAvatarCell })(ReporterCell);
