export const SET_LOADING_ISSUE_DESCRIPTION =
	'state.domain.issueModal.SET_LOADING_ISSUE_DESCRIPTION' as const;

export const SET_ISSUE_BEING_EDITED = 'state.domain.issueModal.SET_ISSUE_BEING_EDITED' as const;

export type SetLoadingIssueDescriptionPayload = boolean;

export type SetLoadingIssueDescriptionAction = {
	type: typeof SET_LOADING_ISSUE_DESCRIPTION;
	payload: SetLoadingIssueDescriptionPayload;
};

export const setLoadingIssueDescription = (
	payload: SetLoadingIssueDescriptionPayload,
): SetLoadingIssueDescriptionAction => ({
	type: SET_LOADING_ISSUE_DESCRIPTION,
	payload,
});

export type SetIssueBeingEditedPayload = string | null;

export type SetIssueBeingEditedAction = {
	type: typeof SET_ISSUE_BEING_EDITED;
	payload: SetIssueBeingEditedPayload;
};

export const setIssueBeingEdited = (
	payload: SetIssueBeingEditedPayload,
): SetIssueBeingEditedAction => ({
	type: SET_ISSUE_BEING_EDITED,
	payload,
});
