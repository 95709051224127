.triangle {
	position: absolute;
	width: 10px;
	top: -5px;
	right: 0;
	z-index: 0;
	overflow: hidden;
}

.bar {
	position: absolute;
	width: 5px;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 0;
}
