import {
	isOptimizedMode,
	getMode,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { EDIT } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getColumns } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/query.tsx';
import type { OwnProps } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State, { columnId }: OwnProps) => ({
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	column: getColumns(state).find((column) => column.id === columnId)!,
	isReadOnly: getMode(state) !== EDIT,
	showOptimizations: isOptimizedMode(state),
});
