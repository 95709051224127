import { type MessageDescriptor, defineMessages } from '@atlassian/jira-intl';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default defineMessages({
	issueTypes: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.issue-types-legend.issue-types',
		defaultMessage: 'Issue types',
		description: 'Label for issue types legend section',
	},
}) as {
	issueTypes: MessageDescriptor;
};
