import { isFedRamp } from '@atlassian/atl-context';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { TriggerPointKeyType } from '@atlassian/jira-issue-transition-common-types/src/common/types/index.tsx';

export const getTriggerPointsFlag = (triggerPointKey: TriggerPointKeyType): boolean => {
	const triggerPointsFlags = {
		'issue-transition-issue-view': fg('issue-transition-issue-view'),
		'issue-transition-issue-view-shortcut-dialog': fg('issue-transition-issue-view-shortcut'),
		'issue-transition-board-card-transition': fg('issue-transition-board-card-transition'),
		'issue-transition-advanced-roadmaps-jira-transition': fg(
			'issue-transition-advanced-roadmaps-jira-transition',
		),
		'issue-transition-command-palette-status-field-transition': fg(
			'issue-transition-command-palette-status',
		),
		'issue-transition-issue-results-shortcut-transition': fg(
			'issue-transition-issue-results-shortcut-transition',
		),
		'issue-transition-backlog-inline-transition': fg('issue-transition-backlog-inline-transition'),
		'issue-transition-status-field-trigger': fg('issue-transition-status-field-trigger'),
		'trigger-point-key-not-defined': fg('trigger-point-key-not-defined'),
	};

	return triggerPointsFlags[triggerPointKey];
};

export const isNewTransitionScreenEnabled = (
	preferNewIssueTransitionScreen: boolean,
	triggerPointKey?: TriggerPointKeyType,
) => {
	if (isFedRamp()) {
		return true;
	}

	if (!ff('render-modern-issue-transition_cxt0z')) return false;

	/* DEFAULT VALUE IS FALSE FOR ANY TRIGGER POINT NOT AVAILABLE IN THE MAP ABOVE
	 *  If trigger key is not present, then by default overall ITS will be false.
	 *  For any new trigger point, it must be necessary to list the trigger point in the map above.
	 *  When we cleanup FF for trigger points, then this should be also removed.
	 */
	if (!triggerPointKey || !getTriggerPointsFlag(triggerPointKey)) return false;

	return preferNewIssueTransitionScreen || fg('issue-transition-customisation-for-srb');
};
