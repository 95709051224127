import * as R from 'ramda';
import {
	START as COMMIT_START,
	STOP as COMMIT_STOP,
	type StartAction,
	type StopAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/update-jira/commit/actions.tsx';
import { ENTITY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	OPEN_UPDATE_JIRA_DIALOG,
	CLOSE_UPDATE_JIRA_DIALOG,
	EXPAND_UPDATE_JIRA_DIALOG_CHANGE,
	COLLAPSE_UPDATE_JIRA_DIALOG_CHANGE,
	CLOSE_COMMIT_WARNING_FLAG,
	SELECT_CHANGE,
	SELECT_ALL_CHANGES,
	DESELECT_CHANGE,
	DESELECT_ALL_CHANGES,
	REPLACE_SELECTED_CHANGES,
	SORT_CHANGES,
	CHANGE_FILTER,
	CLEAR_FILTER,
	UPDATE_NOTIFICATION_PREFERENCE,
	CHANGE_QUICK_SELECT,
	type OpenDialogAction,
	type CloseDialogAction,
	type ExpandChangeAction,
	type CollapseChangeAction,
	type CloseCommitWarningFlagAction,
	type SelectChangeAction,
	type SelectAllChangesAction,
	type DeselectChangeAction,
	type DeselectAllChangesAction,
	type ReplaceSelectedChangesAction,
	type SortChangesAction,
	type ClearFilterAction,
	type ChangeFilterAction,
	type UpdateNotificationPreferenceAction,
	type ChangeQuickSelectAction,
} from './actions.tsx';
import {
	QuickSelectOption,
	type SelectedChanges,
	type UpdateJira,
	USER_FILTER_ID,
} from './types.tsx';

export const initialState: UpdateJira = {
	isDialogOpen: false,
	expandedChanges: {
		[ENTITY.ISSUE]: [],
		[ENTITY.RELEASE]: [],
		[ENTITY.CROSS_PROJECT_RELEASE]: [],
		[ENTITY.TEAM]: [],
		[ENTITY.RESOURCE]: [],
		[ENTITY.SPRINT]: [],
		[ENTITY.PLANNED_CAPACITY]: [],
		[ENTITY.NONE]: [],
	},
	isCommitWarningFlagClosed: true,
	selectedChanges: {
		[ENTITY.ISSUE]: [],
		[ENTITY.RELEASE]: [],
		[ENTITY.CROSS_PROJECT_RELEASE]: [],
		[ENTITY.TEAM]: [],
		[ENTITY.RESOURCE]: [],
		[ENTITY.SPRINT]: [],
		[ENTITY.PLANNED_CAPACITY]: [],
		[ENTITY.NONE]: [],
	},
	filters: {
		[USER_FILTER_ID]: {
			id: USER_FILTER_ID,
			value: [],
		},
	},
	sortBy: 'lastModified',
	sortDirection: 'ASC',
	shouldNotifyWatchers: true,
	quickSelectOption: QuickSelectOption.MINE,
};

type Action =
	| OpenDialogAction
	| CloseDialogAction
	| ExpandChangeAction
	| CollapseChangeAction
	| CloseCommitWarningFlagAction
	| StartAction
	| StopAction
	| SelectChangeAction
	| SelectAllChangesAction
	| DeselectChangeAction
	| DeselectAllChangesAction
	| ReplaceSelectedChangesAction
	| SortChangesAction
	| ChangeFilterAction
	| ClearFilterAction
	| UpdateNotificationPreferenceAction
	| ChangeQuickSelectAction;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addId = (id: string) => (ids: any) => {
	const result = new Set(ids);
	result.add(id);
	return Array.from(result);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: UpdateJira = initialState, action: Action) => {
	switch (action.type) {
		case OPEN_UPDATE_JIRA_DIALOG:
			return {
				...state,
				isDialogOpen: true,
				isCommitWarningFlagClosed: true,
			};
		case CLOSE_UPDATE_JIRA_DIALOG:
			return {
				...state,
				isDialogOpen: false,
			};

		case EXPAND_UPDATE_JIRA_DIALOG_CHANGE: {
			const { id, category } = action.payload;
			return R.evolve(
				{
					expandedChanges: {
						[category]: addId(id),
					},
				},
				state,
			);
		}

		case COLLAPSE_UPDATE_JIRA_DIALOG_CHANGE: {
			const { id, category } = action.payload;
			return R.evolve({ expandedChanges: { [category]: R.reject(R.equals(id)) } }, state);
		}

		case CLOSE_COMMIT_WARNING_FLAG:
		case COMMIT_START: {
			return {
				...state,
				isCommitWarningFlagClosed: true,
			};
		}
		case COMMIT_STOP: {
			return {
				...state,
				isCommitWarningFlagClosed: false,
			};
		}
		case SELECT_CHANGE: {
			const { id, category } = action.payload;
			return R.evolve(
				{
					selectedChanges: {
						[category]: addId(id),
					},
				},
				state,
			);
		}
		case SELECT_ALL_CHANGES:
			return {
				...state,
				selectedChanges: R.merge(state.selectedChanges, { ...action.payload }),
			};
		case DESELECT_CHANGE: {
			const { id, category } = action.payload;
			return R.evolve({ selectedChanges: { [category]: R.reject(R.equals(id)) } }, state);
		}
		case DESELECT_ALL_CHANGES:
			return { ...state, selectedChanges: { ...initialState.selectedChanges } };

		case REPLACE_SELECTED_CHANGES:
			return {
				...state,
				selectedChanges: { ...initialState.selectedChanges, ...action.payload },
			};

		case SORT_CHANGES: {
			const { sortBy, sortDirection } = action.payload;
			return {
				...state,
				sortBy,
				sortDirection,
			};
		}
		case CHANGE_FILTER: {
			const { id, value, visibleIssueIds } = action.payload;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const isVisible = (el: any) => R.contains(el, visibleIssueIds);

			const result: SelectedChanges = visibleIssueIds.length
				? R.map(R.filter(isVisible), state.selectedChanges)
				: state.selectedChanges;

			return {
				...state,
				filters: R.merge(state.filters, { [id]: { id, value } }),
				selectedChanges: R.merge(state.selectedChanges, result),
			};
		}
		case CLEAR_FILTER: {
			const id = action.payload;
			return {
				...state,
				filters: {
					...state.filters,
					[id]: { ...initialState.filters[id] },
				},
			};
		}
		case UPDATE_NOTIFICATION_PREFERENCE: {
			const { shouldNotifyWatchers } = action.payload;
			return {
				...state,
				// We want to default to true if the user has not previously set this user preferences value
				shouldNotifyWatchers: shouldNotifyWatchers ?? true,
			};
		}
		case CHANGE_QUICK_SELECT: {
			return {
				...state,
				quickSelectOption: action.payload,
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
