import {
	ISSUE_LIMIT_WARNING,
	REPARENT_TMP_TO_EXTERNAL_WARNING,
	REPARENT_CMP_TO_TMP_WARNING,
} from '../../state/domain/flags/types.tsx';

/**
 * The dismissed flag comes with a key. It needs to be transformed to one of our
 * flow-supported strings.
 * @param {string} flagKey
 * @returns {string}
 */
export const transformDismissedFlagName = (flagKey: string): string => {
	switch (flagKey) {
		case 'issue-limit-warning':
			return ISSUE_LIMIT_WARNING;
		case 'reparent-tmp-to-external-warning':
			return REPARENT_TMP_TO_EXTERNAL_WARNING;
		case 'reparent-cmp-to-tmp-warning':
			return REPARENT_CMP_TO_TMP_WARNING;
		default:
			return '';
	}
};
