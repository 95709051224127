import {
	type Transform,
	type PersistConfig,
	persistReducer as reduxPersistReducer,
} from 'redux-persist';
import { promiseStorage } from '@atlassian/jira-portfolio-3-common/src/storage/index.tsx';

type PartialPersistConfig = {
	key: string;
	whitelist?: Array<string>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	transforms?: Array<Transform<any, any>>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Reducer = (State: any, Action: any) => any;

export const persistReducer = (persistConfig: PartialPersistConfig, reducer: Reducer) => {
	const fullPersistConfig: PersistConfig = {
		storage: promiseStorage,
		...persistConfig,
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return reduxPersistReducer<any, any>(fullPersistConfig, reducer);
};
