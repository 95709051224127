import React from 'react';
import { TABLE_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import Capacities from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/capacities/index.tsx';
import Sprints from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/schedule/sprints/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { GROUPING } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Props } from './types.tsx';

export function TimelineTableGroup({ item, showCapacity, timelineRange }: Props) {
	if (item.tag !== TABLE_GROUP) {
		throw new Error(`renderGroup could only render ${TABLE_GROUP} items, but got ${item.tag}`);
	}

	return (
		<>
			{showCapacity && item.grouping === GROUPING.TEAM && isDefined(item.groupCombination.team) && (
				<Sprints team={item.groupCombination.team} timelineRange={timelineRange} />
			)}
			{showCapacity &&
				item.grouping === GROUPING.SPRINT &&
				isDefined(item.groupCombination.team) &&
				isDefined(item.groupCombination.sprint) && (
					<Capacities
						team={item.groupCombination.team}
						sprint={item.groupCombination.sprint}
						timelineRange={timelineRange}
					/>
				)}
		</>
	);
}
