import type { Dispatch } from 'redux';
import {
	setColourByValue,
	setColourMap,
	setPriorityColourMap,
	type SetColourByValueActionPayload,
	type SetColourMapActionPayload,
	type SetPriorityColourMapActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/actions.tsx';
import type { Action, DispatchProps } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action>): DispatchProps => ({
	setColourByValue: (payload: SetColourByValueActionPayload) => {
		dispatch(setColourByValue(payload));
	},
	setColourMap: (payload: SetColourMapActionPayload) => {
		dispatch(setColourMap(payload));
	},
	setPriorityColourMap: (payload: SetPriorityColourMapActionPayload) => {
		dispatch(setPriorityColourMap(payload));
	},
});
