import type { Adjustments } from '../../state/domain/lexorank/types.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Adjustments } from '../../state/domain/lexorank/types';

export const getIssueLexoRankAdjustments = (state: State): Adjustments =>
	state.domain.lexorank.issueAdjustments;

export const getVersionLexoRankAdjustments = (state: State): Adjustments =>
	state.domain.lexorank.versionAdjustments;
