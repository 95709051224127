import { useCallback, useRef } from 'react';

export const useScheduler = <TArgs,>(
	onFrame: (timeSinceLastFrame: number, args: TArgs) => void,
) => {
	const lastFrameTimestampRef = useRef<number>();
	const latestArgs = useRef<TArgs>();
	const frameIdRef = useRef<number>();

	const loop = useCallback(() => {
		const now = Date.now();

		if (latestArgs.current !== undefined && lastFrameTimestampRef.current !== undefined) {
			onFrame(now - lastFrameTimestampRef.current, latestArgs.current);
		}
		lastFrameTimestampRef.current = Date.now();
		frameIdRef.current = requestAnimationFrame(loop);
	}, [onFrame]);

	const start = useCallback(() => {
		lastFrameTimestampRef.current = Date.now();
		frameIdRef.current = requestAnimationFrame(loop);
	}, [loop]);

	const update = useCallback((args: TArgs) => {
		latestArgs.current = args;
	}, []);

	const end = useCallback(() => {
		lastFrameTimestampRef.current = undefined;

		if (frameIdRef.current !== undefined) {
			cancelAnimationFrame(frameIdRef.current);
		}

		frameIdRef.current = undefined;
		latestArgs.current = undefined;
	}, []);

	return { start, update, end };
};
