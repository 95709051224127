import React, { useRef, useEffect, useCallback, useState, useLayoutEffect } from 'react';
import {
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	Track as ScrollTrack,
	// eslint-disable-next-line jira/styled/no-styled-import-alias
	Thumb as ScrollThumb,
} from '@atlassian/jira-portfolio-3-common/src/custom-scrollbar/styled.tsx';
import { SCROLL_BUTTON_STEP } from '../../common/constants/index.tsx';
import {
	useHorizontalScrolling,
	useTimelineRuler,
	useZoomLevel,
} from '../../controllers/index.tsx';

/** Returns the content bound, which can be combined with the viewport bound to calculate the scrollbar UIs. */
function useContentBound(useDefaultBound: boolean) {
	const [{ viewport }] = useHorizontalScrolling();
	const [minimumViewportOffset, setMinimumViewportOffset] = useState<number>(1e10);
	const [maximumViewportOffset, setMaximumViewportOffset] = useState<number>(-1e10);

	const date = new Date();
	date.setHours(0, 0, 0, 0);

	const today = new Date(date.getTime());

	date.setFullYear(new Date().getFullYear() - 1);
	const oneYearAgo = new Date(date.getTime());

	date.setFullYear(new Date().getFullYear() + 2);
	const twoYearsLater = new Date(date.getTime());

	// today
	date.setFullYear(new Date().getFullYear());

	const [{ msToPx, pxToMs }] = useTimelineRuler();

	/** The minimum content bound, which is from 1 year ago to 2 years later. */
	const minimumBound = {
		offset: oneYearAgo.getTime() - today.getTime(),
		width: msToPx(twoYearsLater.getTime() - oneYearAgo.getTime()),
	};

	/** Values used to calculate the viewport offset from */
	const offsetFromValues = [viewport.offset, minimumBound.offset];

	/** Values used to calculate the viewport offset to */
	const offsetToValues = [
		viewport.offset + pxToMs(viewport.width),
		minimumBound.offset + pxToMs(minimumBound.width),
	];

	// If we don't use the default bound, then we add the minimum and maximum values for offset
	if (!useDefaultBound) {
		offsetFromValues.push(minimumViewportOffset);
		offsetToValues.push(maximumViewportOffset);
	}

	const offsetFrom = Math.min(...offsetFromValues);
	const offsetTo = Math.max(...offsetToValues);

	const duration = offsetTo - offsetFrom;

	// We update the values for maximum and minimum offset every time they change
	useEffect(() => {
		setMinimumViewportOffset(offsetFrom);
		setMaximumViewportOffset(offsetTo);
	}, [offsetFrom, offsetTo]);

	return {
		offset: offsetFrom,
		width: msToPx(duration),
	};
}

/**
 * Hook to enable drag handling for the drag handler element sent via parameter.
 * @param {HTMLElement} dragHandlerEl
 * @returns {boolean} wether we are dragging at this moment or not
 */
function useDragHandling(
	trackBarEl: HTMLElement | null,
	dragHandlerEl: HTMLElement | null,
): boolean {
	const [{ smooth, viewport }, { setViewportOffset, setScrollingWithScrollBar, setSmooth }] =
		useHorizontalScrolling();
	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [{ pxToMs }] = useTimelineRuler();
	const triggeredStepRef = useRef(false);

	/** The offsetX of the mouse, used for calculating how far the drag would have gone. */
	const mouseDownOffsetRef = useRef<number>();
	const originalViewportOffsetRef = useRef<number>(0);
	const dragHandlerWidthRef = useRef<number>(0);

	const handleMouseDownInThumb = useCallback(
		(e: MouseEvent) => {
			if (dragHandlerEl === null) {
				return;
			}

			if (e.target !== dragHandlerEl) {
				return;
			}

			setIsDragging(true);
			setScrollingWithScrollBar(true);

			e.preventDefault(); // prevent the default behavior (such as text selection)
			e.stopPropagation(); // prevent propagating it to the track
			dragHandlerWidthRef.current = dragHandlerEl.getBoundingClientRect().width;
			mouseDownOffsetRef.current = e.clientX;
			originalViewportOffsetRef.current = viewport.offset;
		},
		[dragHandlerEl, viewport.offset, setScrollingWithScrollBar],
	);

	const handleMouseDownInTrack = useCallback(
		(e: MouseEvent) => {
			if (trackBarEl === null || dragHandlerEl === null) {
				return;
			}

			if (e.target !== trackBarEl) {
				return;
			}

			const { left: thumbX } = dragHandlerEl.getBoundingClientRect();
			const directionMultiplier = e.clientX < thumbX ? -1 : 1;
			const distance = viewport.width * SCROLL_BUTTON_STEP * directionMultiplier;

			setSmooth(true);
			setViewportOffset((offset) => offset + pxToMs(distance), 'scrollbar');

			triggeredStepRef.current = true;
			e.preventDefault();
		},
		[dragHandlerEl, pxToMs, setSmooth, setViewportOffset, trackBarEl, viewport.width],
	);

	const handleMouseDown = useCallback(
		(e: MouseEvent) => {
			if (dragHandlerEl === null) {
				return;
			}

			if (e.target === dragHandlerEl) {
				return handleMouseDownInThumb(e);
			}

			if (e.target === trackBarEl) {
				return handleMouseDownInTrack(e);
			}
		},
		[dragHandlerEl, handleMouseDownInThumb, handleMouseDownInTrack, trackBarEl],
	);

	const handleMouseMove = useCallback(
		(e: MouseEvent) => {
			// Skip if there was no preceed mousedown
			if (mouseDownOffsetRef.current == null) {
				return;
			}

			if (originalViewportOffsetRef.current == null) {
				return;
			}

			const currentMouseOffset = e.clientX;
			const mouseDeltaX = currentMouseOffset - mouseDownOffsetRef.current;
			const distanceInPx = (mouseDeltaX / dragHandlerWidthRef.current) * viewport.width;
			const nextViewportOffset = originalViewportOffsetRef.current + pxToMs(distanceInPx);

			setViewportOffset(nextViewportOffset, 'scrollbar');

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.dispatchEvent(
				new WheelEvent('wheel', {
					deltaX: mouseDeltaX,
				}),
			);
		},
		[setViewportOffset, viewport.width, pxToMs],
	);

	const handleMouseUp = useCallback(() => {
		mouseDownOffsetRef.current = undefined;
		setIsDragging(false);
		setScrollingWithScrollBar(false);
	}, [setScrollingWithScrollBar]);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('mousedown', handleMouseDown);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return () => document.removeEventListener('mousedown', handleMouseDown);
	}, [handleMouseDown]);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('mouseup', handleMouseUp);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return () => document.removeEventListener('mouseup', handleMouseUp);
	}, [handleMouseUp]);

	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('mousemove', handleMouseMove);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return () => document.removeEventListener('mousemove', handleMouseMove);
	}, [handleMouseMove]);

	// disable "smooth" scroll after
	useLayoutEffect(() => {
		if (smooth && triggeredStepRef.current) {
			setSmooth(false);
			triggeredStepRef.current = false;
		}
	}, [setSmooth, smooth]);

	return isDragging;
}

const useDragHandlerStyle = (isDragging: boolean) => {
	const [{ viewport }] = useHorizontalScrolling();
	const contentBound = useContentBound(!isDragging);
	const scale = viewport.width / contentBound.width;
	const [{ msToPx }] = useTimelineRuler();

	if (viewport.width === 0 /** uninitialized */) {
		return {
			width: 0,
			left: 0,
		};
	}

	return {
		width: viewport.width * scale - 7,

		left: msToPx(viewport.offset - contentBound.offset) * scale,
	};
};

export function HorizontalScrollBarView() {
	const trackRef = useRef<HTMLDivElement>(null);
	const thumbRef = useRef<HTMLDivElement>(null);
	const isDragging = useDragHandling(trackRef.current, thumbRef.current);
	const dragHandlerStyle = useDragHandlerStyle(isDragging);

	return (
		<ScrollTrack ref={trackRef}>
			<ScrollThumb ref={thumbRef} {...dragHandlerStyle} />
		</ScrollTrack>
	);
}

export default function HorizontalScrollBar() {
	const [zoomLevel] = useZoomLevel();
	if (zoomLevel === undefined) {
		return null;
	}

	return <HorizontalScrollBarView />;
}
