import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	successLabel: {
		id: 'portfolio-3-onboarding.ctt-onboarding.finish-flag.success-label',
		defaultMessage: 'Success',
		description: 'Label for success icon',
	},
	spotlightFinishedFlagTitle: {
		id: 'portfolio-3-onboarding.ctt-onboarding.finish-flag.spotlight-finished-flag-title',
		defaultMessage: "You've made your first plan!",
		description: 'The title for cross team templates onboarding flag card.',
	},
	spotlightFinishedFlagBodyPlans: {
		id: 'portfolio-3-onboarding.ctt-onboarding.finish-flag.spotlight-finished-flag-body-plans',
		defaultMessage: 'But why stop here? Learn how to take your planning to the next level!',
		description: 'The title for cross team templates onboarding flag card.',
	},
	spotlightFinishedFlagTakeFullTour: {
		id: 'portfolio-3-onboarding.ctt-onboarding.finish-flag.spotlight-finished-flag-take-full-tour',
		defaultMessage: 'Take the full tour',
		description: 'The title for cross team templates onboarding flag card.',
	},
	spotlightFinishedFlagReadTheDocumentation: {
		id: 'portfolio-3-onboarding.ctt-onboarding.finish-flag.spotlight-finished-flag-read-the-documentation',
		defaultMessage: 'Read the documentation',
		description: 'The title for cross team templates onboarding flag card.',
	},
});
