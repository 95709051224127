import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	setReleaseHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-versions.set-release-header',
		defaultMessage: 'Update release',
		description: 'Modal dialog header for dialog release',
	},
	variousValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-versions.various-values',
		defaultMessage: 'Various releases',
		description: 'Label for various release values',
	},
	released: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-versions.released',
		defaultMessage: 'Released',
		description: 'A label for versions which are already released',
	},
	unreleased: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-versions.unreleased',
		defaultMessage: 'Unreleased',
		description: 'A label for versions which are not yet released',
	},
});
