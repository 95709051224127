import {
	add as addLabelGroup,
	update as updateLabelGroup,
	remove as removeLabelGroup,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/label-groups/actions.tsx';
import type { LabelGroup } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/label-groups/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	addGroup: (name: string) => {
		dispatch(
			addLabelGroup({
				id: name.toLowerCase(),
				name,
				isExpanded: true,
				labels: [],
			}),
		);
	},
	updateGroup: (groupId: string, patch: Partial<LabelGroup>) => {
		dispatch(
			updateLabelGroup({
				id: groupId,
				patch,
			}),
		);
	},
	removeGroup: (groupId: string) => {
		dispatch(removeLabelGroup(groupId));
	},
});

export default mapDispatchToProps;
