import { useEffect, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { useKeyboardShortcutsToggle } from '@atlassian/jira-common-components-keyboard-shortcuts/src/controller/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { ANALYTICS_SHORTCUT_PRESSED } from '../common/constants.tsx';
import { isRelevantKeyEvent } from '../utils.tsx';
import type { Props } from './types.tsx';

export const useShortcuts = (key: string, callback: (e: KeyboardEvent) => void) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [actionSubject, action] = ANALYTICS_SHORTCUT_PRESSED.split(' ');
	const analyticsEvent = createAnalyticsEvent({ action, actionSubject });
	const [{ isEnabled: isKeyboardShortcutsEnabled }] = useKeyboardShortcutsToggle();
	const handleKeyDown = useCallback(
		(e: KeyboardEvent) => {
			if (!isRelevantKeyEvent(e) || !isKeyboardShortcutsEnabled) {
				return;
			}

			if (e.code === key) {
				e.preventDefault();
				fireUIAnalytics(analyticsEvent.update({ action, actionSubject }), { key: e.code });
				callback(e);
			}
		},
		[action, actionSubject, analyticsEvent, callback, isKeyboardShortcutsEnabled, key],
	);
	const debouncedHandleKeyDown = useRef(debounce(handleKeyDown, 1000, { leading: true }));

	useEffect(() => {
		debouncedHandleKeyDown.current = debounce(handleKeyDown, 1000, { leading: true });
	}, [isKeyboardShortcutsEnabled, handleKeyDown]);

	useEffect(() => {
		const handler = (e: KeyboardEvent) => debouncedHandleKeyDown.current(e);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('keydown', handler);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return () => document.removeEventListener('keydown', handler);
	}, []);
};

export const UseShortcuts = ({ shortcutKey, onKeyDown }: Props) => {
	useShortcuts(shortcutKey, onKeyDown);
	return null;
};
