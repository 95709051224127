import memoizeOne from 'memoize-one';
import { getIssueTypesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import {
	getSelectedIssues,
	getAllSortedIssues,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getAncestors } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline-preview/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';

const mapStateToPropsFactory = () => {
	const getAncestorsFactory = memoizeOne(
		(state: State) => (issueId: string) => getAncestors(state, { issueId }),
	);
	return (state: State) => ({
		allSortedIssues: getAllSortedIssues(state),
		getAncestors: getAncestorsFactory(state),
		issueTypesById: getIssueTypesById(state),
		projectsById: getProjectsById(state),
		selectedIssues: getSelectedIssues(state),
	});
};

const mapStateToProps = mapStateToPropsFactory;
export default mapStateToProps;
