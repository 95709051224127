import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import changeStatus from './change-status/reducer.tsx';
import changesWarnings from './changes-warnings/reducer.tsx';
import changes from './changes/reducer.tsx';
import commit from './commit/reducer.tsx';
import hiddenIssues from './hidden-issues/reducer.tsx';
import requiredFields from './required-fields/reducer.tsx';
import revert from './revert/reducer.tsx';
import warnings from './warnings/reducer.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	changes,
	changesWarnings,
	commit,
	revert,
	hiddenIssues,
	warnings,
	requiredFields,
	changeStatus,
});
