import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	clearButtonLabel: {
		id: 'portfolio-3-portfolio.common.search-field.clear-button-label',
		defaultMessage: 'clear',
		description:
			'Aria label text when focus is switched to clear button that clears the text of a text field',
	},
});
