import type { Dispatch } from 'redux';
import { CLOSE_HIDDEN_ISSUES_DIALOG } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/hidden-issues/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch: Dispatch) => ({
	closeDialog: () => {
		dispatch({
			type: CLOSE_HIDDEN_ISSUES_DIALOG,
		});
	},
});

export default mapDispatchToProps;
