.form-group {
	display: flex;
}

.current-value {
	padding-top: calc(var(--jpo-common-base-spacing) + 2px);
}

.current-value-title {
	max-width: calc(var(--jpo-common-base-spacing) * 24);
	overflow: hidden;
	text-overflow: ellipsis;
}

.icon {
	vertical-align: -5px;
	padding-left: calc(var(--jpo-common-base-spacing) * 2);
	margin-top: 36px;
	width: 80px;
	text-align: center;
}

.new-value {
	min-width: 280px;
	margin-left: calc(var(--jpo-common-base-spacing) * 2);
}

.value-label {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	font-weight: 600;
}

.value-placeholder {
	padding-top: calc(var(--jpo-common-base-spacing) / 2);
	line-height: 40px;
	overflow: hidden;
	text-overflow: ellipsis;
	box-sizing: border-box;
	white-space: nowrap;
	display: flex;
	align-items: center;
	min-width: 136px;
	max-width: 216px;
}

.should-fit-full-width .value-placeholder {
	max-width: 220px;
}

.should-fit-full-width .new-value {
	flex: 1 0 auto;
}

.form-footer {
	display: flex;
	padding: 16px 0px 20px;
	justify-content: flex-end;
	& button {
		margin: 0 4px;
	}
}

.issue-type-icon {
	display: inline-block;
	vertical-align: middle;
	width: 16px;
	height: 16px;
	margin-right: var(--jpo-common-base-spacing);
}

.avatar {
	display: flex;
	align-items: center;
	margin-right: var(--jpo-common-base-spacing);
}
