// This module contains counterparts to Ramda functions missing/ill-typed in flow-typed
// It's okay if they are less generic (non-curried, work only over array etc.)
import * as R from 'ramda';

// flow-typed declaration is missing
export function chain<T, U>(callback: (arg1: T) => U[], array: T[]): U[] {
	const result: Array<U> = [];
	for (const elements of array.map(callback)) {
		Array.prototype.push.apply(result, elements);
	}
	return result;
}

// flow-typed declaration is restricted to string keys
export function fromPairs<T extends number | string, U>(pairs: [T, U][]): Record<T, U> {
	const result: Record<number | string, U> = {};

	for (const [k, v] of pairs) {
		result[k] = v;
	}

	return result;
}

// Flow+Ramda fight in R.values because a {[number]:T} is really a {[string]:T} -- because JS -- but Flow doesn't want to believe us :/
// TODO(tim / JPOS-1375) fix Object.values dodgy typing.
export const values = <K extends number | string, V>(object: Record<K, V>): V[] =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	Object.values(object) as V[];

// flow-typed declaration is restricted to string keys
export function groupBy<T, U extends number | string>(
	keyFn: (arg1: T) => U,
	array: T[],
): Record<U, T[]> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<number | string, any> = {};
	for (const element of array) {
		const key = keyFn(element);

		if (!result[key]) {
			result[key] = [];
		}

		result[key].push(element);
	}

	return result;
}

export function indexBy<T, U extends PropertyKey>(
	keyFn: (arg1: T) => U,
	array: ReadonlyArray<T>,
): Record<U, T> {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const result: Record<U, T> = {} as Record<U, T>;
	for (const element of array) {
		const key = keyFn(element);
		result[key] = element;
	}
	return result;
}

// flow-typed declaration is missing
export function sortWith<T>(comparators: ((arg1: T, arg2: T) => number)[]): (arg1: T[]) => T[] {
	return (array: T[]) =>
		[...array].sort((a, b) => {
			let comparison = 0;
			for (const comparator of comparators) {
				comparison = comparator(a, b);
				if (comparison !== 0) {
					return comparison;
				}
			}
			return comparison;
		});
}

// flow-typed declaration is missing
export function ascend<T, U extends number | string>(
	keyFn: (arg1: T) => U,
): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const keyA = keyFn(a);
		const keyB = keyFn(b);
		if (typeof keyA === 'number' && typeof keyB === 'number') {
			if (keyA < keyB) {
				return -1;
			}
			if (keyA > keyB) {
				return 1;
			}
			return 0;
		}
		if (typeof keyA === 'string' && typeof keyB === 'string') {
			if (keyA < keyB) {
				return -1;
			}
			if (keyA > keyB) {
				return 1;
			}
			return 0;
		}
		throw new Error('keyFn must return number or string consistently');
	};
}

// flow-typed declaration is missing
export function descend<T, U extends number | string>(
	keyFn: (arg1: T) => U,
): (a: T, b: T) => number {
	return (a: T, b: T) => {
		const keyA = keyFn(a);
		const keyB = keyFn(b);
		if (typeof keyA === 'number' && typeof keyB === 'number') {
			if (keyA > keyB) {
				return -1;
			}
			if (keyA < keyB) {
				return 1;
			}
			return 0;
		}
		if (typeof keyA === 'string' && typeof keyB === 'string') {
			if (keyA > keyB) {
				return -1;
			}
			if (keyA < keyB) {
				return 1;
			}
			return 0;
		}
		throw new Error('keyFn must return number or string consistently');
	};
}

// not in ramda but could be ;-)
export function shuffle<T>(array: T[]): T[] {
	let counter = array.length;
	const result = [...array];

	while (counter > 0) {
		const index = Math.floor(Math.random() * counter);
		counter -= 1;
		const temp = result[counter];
		result[counter] = result[index];
		result[index] = temp;
	}

	return result;
}

export function reverse<T>(array: T[]): T[] {
	const reversed: Array<T> = [];
	for (let i = array.length - 1; i >= 0; i -= 1) {
		reversed.push(array[i]);
	}
	return reversed;
}

// Not in ramda but could be! ;)
export function isDefined<T>(thing: T | null | undefined): thing is T {
	return thing !== undefined && thing !== null;
}

// Makes flow happy.
export function filterDefined<T>(array: (T | null | undefined)[]): T[] {
	const filtered: Array<T> = [];
	for (const e of array) {
		if (isDefined(e)) {
			filtered.push(e);
		}
	}
	return filtered;
}

// More performant than composition, useful because we are not leveraging transducers
export function filterMap<T, U>(
	filter: (arg1: T) => boolean,
	map: (arg1: T, arg2: number) => U,
	array: T[],
): U[] {
	const result: Array<U> = [];
	for (let i = 0; i < array.length; i++) {
		const element = array[i];
		if (filter(element)) {
			result.push(map(element, i));
		}
	}
	return result;
}

// Shorthand to count items in commonly used structure of map of arrays
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function valuesLength(object: Record<any, any[]>): number {
	let result = 0;
	for (const array of Object.values(object)) {
		result += array.length;
	}
	return result;
}

// Shorthand to count items in commonly used structure of map of maps
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function valuesKeysLength(object: Record<any, Record<any, any>>): number {
	let result = 0;
	for (const child of Object.values(object)) {
		result += Object.keys(child).length;
	}
	return result;
}

export function mapValues<K extends number | string, V, U>(
	f: (arg1: V) => U,
	object: Record<K, V[]>,
): Record<K, U[]> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<number | string, any> = {};

	for (const k of Object.keys(object)) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		result[k] = object[k as K].map(f);
	}

	return result;
}

// The actual typing is not flexible enough so we are re-implementing it
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pickBy<O extends Record<string, any>>(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	f: (value: any, key: keyof O) => boolean,
	obj: O,
) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return Object.entries(obj).reduce<O>((acc, [key, value]: [keyof O, any]) => {
		if (f(value, key)) {
			acc[key] = value;
			return acc;
		}
		return acc;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	}, {} as O);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function pluck(prop: string, array: any[]): any[] {
	return array.map((x) => x[prop]);
}

export function mapObject<V, U>(
	f: (arg1: string, arg2: V) => U,
	object: {
		[key: string]: V;
	},
): {
	[key: string]: U;
} {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};
	for (const k of Object.keys(object)) {
		result[k] = f(k, object[k]);
	}
	return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function move(fromIndex: number, toIndex: number, array: any[]) {
	return R.move(fromIndex, toIndex, array);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapGroupsToIds(object: { [key: string]: any[] }): {
	[key: string]: string[];
} {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return mapValues<string, { id: string } & { [key: string]: any[] }, string>(R.prop('id'), object);
}
