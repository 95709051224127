import { useCallback, useMemo } from 'react';
import type { SubscriptionError } from '@atlassian/jira-ai-work-breakdown/src/services/issue-breakdown/types.tsx';
import {
	useAnalyticsEvents,
	fireTrackAnalytics,
	type ActionSubjectAndAction,
} from '@atlassian/jira-product-analytics-bridge';
import type { AnalyticsAttributesUntyped } from '@atlassian/jira-product-analytics-bridge/src/types';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge/src/utils/fire-analytics';
import {
	useApplication,
	useEdition,
	useProjectKey,
} from '@atlassian/jira-project-context-service/src/main.tsx';

export const aiwbAnalyticsAttrs = {
	aiFeatureName: 'plansAiIssueBreakdown',
	isAIFeature: 1,
	proactiveAIGenerated: 0,
	userGeneratedAI: 1,
} as const;

type Params = {
	channelId?: string | null;
};
export const useAiWorkBreakDownAnalytics = (params: Params = {}) => {
	const { channelId } = params;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const actions = useMemo(() => {
		const analyticsEvent = createAnalyticsEvent({});
		const defaultAttrs = { ...aiwbAnalyticsAttrs, singleInstrumentationID: channelId };
		return {
			analyticsEvent,
			fireTrack: (name: ActionSubjectAndAction, id: string, attrs?: AnalyticsAttributesUntyped) =>
				fireTrackAnalytics(analyticsEvent, name, id, { ...defaultAttrs, ...attrs }),
		};
	}, [channelId, createAnalyticsEvent]);
	return actions;
};

type Fns = { sendSuccessEvent: Function; sendFailedEvent: Function };
export const useAiWorkBreakDownExperienceAnalytics = (issueKey: string): Fns => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const projectKey = useProjectKey(issueKey);
	const application = useApplication(projectKey, true);
	const edition = useEdition(projectKey, true);

	const analyticsSource = 'jiraPortfolio3AiWorkBreakdown';
	const experience = 'plansAiIssueBreakdown';

	// Currently we are sending notEnoughInformation, noFurtherSuggestions and unethicalContent as success event.
	// We want to track these valid errors.
	const sendSuccessEvent = useCallback(
		(validError: SubscriptionError) => {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'plansAiIssueBreakdownSuccessEvent sent', {
				analyticsSource,
				experience,
				wasExperienceSuccesful: true,
				application: application ?? null,
				edition: edition ?? null,
				additionalAttributes: { validError },
			});
		},
		[application, createAnalyticsEvent, edition],
	);

	const sendFailedEvent = useCallback(
		(error: SubscriptionError) => {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'plansAiIssueBreakdownFailedEvent sent', {
				experience,
				wasExperienceSuccesful: false,
				analyticsSource,
				application: application ?? null,
				edition: edition ?? null,
				additionalAttributes: { error },
			});
		},
		[application, createAnalyticsEvent, edition],
	);

	return { sendSuccessEvent, sendFailedEvent };
};
