.noMatchFoundText {
	margin: var(--jpo-spacing) 0;
	padding: var(--jpo-spacing) calc(2 * var(--jpo-spacing));
	color: var(--ds-text-disabled, var(--adg3-color-N200));
	text-align: center;
}

.optionLabel {
	display: inline-block;
	max-width: 236px;
	word-break: break-all;
	text-overflow: ellipsis;
	overflow: hidden;
}
