import type { Dispatch } from 'redux';
import {
	toggleWarningByType,
	toggleShowWarning,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/warning-settings/actions.tsx';
import {
	setSearchQuery,
	toggleIssueSearch,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/header/search/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { Action, DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (
	dispatch: Dispatch<Action>,
) => ({
	setSearchQuery: (value: string) => {
		dispatch(setSearchQuery(value));
		dispatch(toggleIssueSearch(true));
	},
	toggleShowWarning: () => {
		dispatch(toggleShowWarning());
	},
	toggleWarningByType: (type: string) => {
		dispatch(toggleWarningByType(type));
	},
});

export default mapDispatchToProps;
