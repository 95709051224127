import React from 'react';
import SprintIcon from '@atlaskit/icon/glyph/sprint';
import { N300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import LegendItem from '@atlassian/jira-aais-timeline-legend/src/ui/legend-item/index.tsx';
import LegendSection from '@atlassian/jira-aais-timeline-legend/src/ui/legend-section/index.tsx';
import LegendComponent from '@atlassian/jira-aais-timeline-legend/src/ui/legend/index.tsx';
import ChangeIndicatorIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/change-indicator-icon/index.tsx';
import DependenciesIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/dependencies-icon/index.tsx';
import DependencyCountIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/dependency-count-icon/index.tsx';
import IssueBarIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/issue-bar-icon/index.tsx';
import {
	SprintDateIcon,
	ReleaseDateIcon,
	DateFieldIcon,
} from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/letter-icon/index.tsx';
import ReleaseStatusIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/release-status-icon/index.tsx';
import RollupIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/rollup-icon/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import ColorByLegend from './color-by-legend/index.tsx';
import IssueTypesLegend from './issue-types-legend/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const LegendInternal = ({
	currentScenarioColor,
	viewMode,
	isConfluenceMacro,
	isEmbed,
}: Props) => {
	const { formatMessage } = useIntl();

	const sprintsMessage = formatMessage(messages.sprints);
	const rollupMessage = formatMessage(messages.rollup);
	const sprintDatesMessage = formatMessage(messages.sprintDates);
	const releaseDatesMessage = formatMessage(messages.releaseDates);
	const dateFieldsMessage = formatMessage(messages.dateFields);
	const unsavedChangeMessage = formatMessage(messages.unsavedChange);

	const noEndDateMessage = formatMessage(messages.noEndDate);
	const noStartDateMessage = formatMessage(messages.noStartDate);
	const rollupBarMessage = formatMessage(messages.rollupBar);

	const releasedMessage = formatMessage(messages.released);
	const onTrackMessage = formatMessage(messages.onTrack);
	const offTrackMessage = formatMessage(messages.offTrack);

	const dependencyMessage = formatMessage(messages.dependency);
	const dependencyWarningMessage = fg('dependency_visualisation_program_board_fe_and_be')
		? formatMessage(messages.dependencyOffTrack)
		: formatMessage(messages.dependencyWarning);
	const numberOfDependenciesMessage = formatMessage(messages.numberOfDependencies);

	return (
		<>
			<LegendSection title={formatMessage(messages.general)}>
				<LegendItem
					symbol={
						<SprintIcon
							size="medium"
							label={sprintsMessage}
							primaryColor={token('color.text.subtle', N300)}
						/>
					}
					label={sprintsMessage}
				/>
				<LegendItem symbol={<RollupIcon label={rollupMessage} />} label={rollupMessage} />
				<LegendItem symbol={<SprintDateIcon />} label={sprintDatesMessage} />
				<LegendItem symbol={<ReleaseDateIcon />} label={releaseDatesMessage} />
				<LegendItem symbol={<DateFieldIcon />} label={dateFieldsMessage} />
				<LegendItem
					symbol={<ChangeIndicatorIcon label={unsavedChangeMessage} color={currentScenarioColor} />}
					label={unsavedChangeMessage}
				/>
			</LegendSection>

			{(isConfluenceMacro || isEmbed) && <IssueTypesLegend />}

			{viewMode === VIEW_MODES.TIMELINE && (
				<>
					{(isConfluenceMacro || isEmbed) && <ColorByLegend />}

					<LegendSection title={formatMessage(messages.issueBars)}>
						<LegendItem symbol={<IssueBarIcon type="noEndDate" />} label={noEndDateMessage} />
						<LegendItem symbol={<IssueBarIcon type="noStartDate" />} label={noStartDateMessage} />
						<LegendItem symbol={<IssueBarIcon type="rollupDates" />} label={rollupBarMessage} />
					</LegendSection>

					<LegendSection title={formatMessage(messages.releases)}>
						<LegendItem symbol={<ReleaseStatusIcon type="released" />} label={releasedMessage} />
						<LegendItem symbol={<ReleaseStatusIcon type="unreleased" />} label={onTrackMessage} />
						<LegendItem symbol={<ReleaseStatusIcon type="overdue-arj" />} label={offTrackMessage} />
					</LegendSection>

					<LegendSection title={formatMessage(messages.dependencies)}>
						<LegendItem
							symbol={<DependenciesIcon label={dependencyMessage} />}
							label={dependencyMessage}
						/>
						<LegendItem
							symbol={<DependenciesIcon label={dependencyMessage} withWarning />}
							label={dependencyWarningMessage}
						/>
						<LegendItem symbol={<DependencyCountIcon />} label={numberOfDependenciesMessage} />
					</LegendSection>
				</>
			)}
		</>
	);
};

const Legend = ({ currentScenarioColor, viewMode, isConfluenceMacro, isEmbed }: Props) => {
	return (
		<LegendComponent>
			<LegendInternal
				currentScenarioColor={currentScenarioColor}
				viewMode={viewMode}
				isConfluenceMacro={isConfluenceMacro}
				isEmbed={isEmbed}
			/>
		</LegendComponent>
	);
};

export default Legend;
