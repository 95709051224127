import React, { type ReactNode } from 'react';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import SelectWireframe from '@atlassian/jira-portfolio-3-common/src/select-wireframe/index.tsx';
import { selectComponents } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import { LIMIT_OF_USERS } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/assignees/api.tsx';
import { transformPersons } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees/utils.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { UserPickerOption as User } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/user-picker-options/types.tsx';
import { getOptimizedValue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util.tsx';
import {
	withSlots,
	slots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { MIN_SELECT_WIDTH } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/view.tsx';
import Cell from '../../column/cell/view.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import { getGroupedAssignees, findAssigneeItem, getUserTitle } from './utils.tsx';

export const makeHandleUserChange =
	({
		assigneeList,
		onChange,
		issue,
	}: {
		assigneeList: Props['assigneeList'];
		onChange: Props['onChange'];
		issue: Props['issue'];
	}) =>
	(userId?: string | null) => {
		if (isDefined(userId)) {
			const assignee = findAssigneeItem(assigneeList, userId);
			onChange(issue, assignee);
		} else {
			// This path covers the case where the user clears the select value. If nothing is assigned then
			// we need to force the value back to the 'Unassigned' state. This works in tandem with using the
			// value rather than defaultValue for the prop passed to Select
			const assignee = findAssigneeItem(assigneeList, 'unassigned');
			onChange(issue, assignee);
		}
	};

export const Assignee = ({
	fetchAssigneeList,
	onChange,
	currentAssignees,
	isScrolling = false,
	assigneeList,
	issue,
	showOptimizations = false,
	issue: { assignee, summary },
	isReadOnly = false,
	assigneeListFetchStatus,
	issueIdForFetchAssigneeListProgress,
	UserPicker,
}: Props) => {
	const { formatMessage } = useIntl();

	if (isScrolling || assigneeList.length === 0) {
		const { optimized } = issue;
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const assignee =
			showOptimizations === true && optimized && optimized.assignee
				? optimized.assignee
				: issue.assignee;
		const items = transformPersons(assigneeList);
		const selectedItem = items.find((item) => item.value === assignee);
		return (
			<SelectWireframe
				content={selectedItem && selectedItem.label}
				isReadOnly={showOptimizations}
			/>
		);
	}

	const mapUserIdToTitle = (_: ScopeIssue, value: string): string =>
		getUserTitle(assigneeList, value);

	const attribute = 'assignee';
	let value = assignee;
	if (showOptimizations) {
		value = getOptimizedValue(issue, attribute);
	}

	const userList = getGroupedAssignees({ assigneeList, currentAssignees }).map((option) => {
		if (option.label && typeof option.label === 'object') {
			return { ...option, label: formatMessage(option.label) };
		}
		return option;
	});

	const user = userList
		.flatMap<User>(({ options }) => options)
		// eslint-disable-next-line @typescript-eslint/no-shadow
		.find((user) => user.value === value);
	/* Handlers */

	const handleUserChange = makeHandleUserChange({ assigneeList, onChange, issue });

	const onFetchUserList = (query?: string | null) => {
		fetchAssigneeList({
			query: query || '',
			issueId: issue.id,
		});
	};

	const getTooltipContent = () => {
		if (!user) return '';

		return user.value === 'unassigned'
			? formatMessage(messages.unassignedTooltipMessage)
			: user.email;
	};

	const getControlComponent = (props: {
		children: ReactNode;
		selectProps: { menuIsOpen: boolean };
	}) => {
		const {
			children,
			selectProps: { menuIsOpen },
		} = props;

		const tooltipContent = menuIsOpen ? '' : getTooltipContent();

		return (
			<Tooltip content={tooltipContent}>
				{/* @ts-expect-error - TS2604 - JSX element type 'selectComponents.Control' does not have any construct or call signatures. */}
				<selectComponents.Control {...props}>{children}</selectComponents.Control>
			</Tooltip>
		);
	};

	return (
		<Cell
			attribute={attribute}
			issue={issue}
			valueMapper={mapUserIdToTitle}
			isScrolling={isScrolling}
			showOptimizations={showOptimizations}
		>
			<UserPicker
				placeholder=""
				userList={userList}
				maxOptionsPerGroup={LIMIT_OF_USERS}
				onUserChange={handleUserChange}
				onFetchUserList={onFetchUserList}
				user={user}
				selectProps={{
					'aria-label': formatMessage({ ...messages.assigneeCellLabel }, { issue: summary }),
					isClearable: value !== 'unassigned',
					isDisabled: isReadOnly,
					minSelectWidth: MIN_SELECT_WIDTH,
					isLoading:
						isDefined(issueIdForFetchAssigneeListProgress) &&
						issue.id === issueIdForFetchAssigneeListProgress &&
						assigneeListFetchStatus,
					components: {
						Control: getControlComponent,
					},
				}}
				issueId={issue.id}
				attribute={attribute}
			/>
		</Cell>
	);
};

export default withSlots({ UserPicker: slots.UserPicker })(Assignee);
