import {
	type FetchUserStartAction,
	type FetchUserStopAction,
	FETCH_USER_START,
	FETCH_USER_STOP,
} from './actions.tsx';
import type { UserPicker } from './types.tsx';

const initialState: UserPicker = {
	isFetchUserListInProgress: false,
	issueId: '',
};

type Action = FetchUserStartAction | FetchUserStopAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: UserPicker = initialState, action: Action) => {
	switch (action.type) {
		case FETCH_USER_START: {
			return { isFetchUserListInProgress: true, issueId: action.payload.issueId };
		}
		case FETCH_USER_STOP: {
			return { isFetchUserListInProgress: false, issueId: action.payload.issueId };
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
