import { createStore, createHook, createContainer } from '@atlassian/react-sweet-state';
import { loadMissingAssociatedIssues } from './actions/index.tsx';
import { getAssociatedIssues, getIsInitialized } from './selectors/index.tsx';
import type { State, ContainerProps } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { type State } from './types';

export const AssociatedIssuesContainer = createContainer<ContainerProps>();

const handlers = {
	onInit: loadMissingAssociatedIssues,
	onContainerUpdate: loadMissingAssociatedIssues,
};

export const initialState: State = {
	associatedIssues: undefined,
	lastAssociatedIssueIds: undefined,
};

const Store = createStore<State, {}, ContainerProps>({
	containedBy: AssociatedIssuesContainer,
	initialState,
	actions: {},
	handlers,
});

export const useAssociatedIssues = createHook(Store, {
	selector: getAssociatedIssues,
});

export const useAssociatedIssuesInitialized = createHook(Store, {
	selector: getIsInitialized,
});

export const useAssociatedIssuesState = createHook(Store);
