import type { Dispatch } from 'redux';
import { setOpenLocked } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/actions.tsx';
import type { MapDispatchToPropsSimple } from '../../types/redux.tsx';
import type {
	DispatchProps,
	AsyncSelectForColumnCellOwnProps,
	SelectForColumnCellOwnProps,
	CellSelectWithRollupOwnProps,
	AsyncSelectWithRollupOwnProps,
} from './types.tsx';

const mapDispatchBase = (dispatch: Dispatch, ownProps: { attribute: string }) => ({
	lockInlineCreate: (isLocked: boolean) => {
		dispatch(setOpenLocked({ attribute: ownProps.attribute, isLocked }));
	},
});

// the typing of the connect in index does better type if ownProps is the entire component props,
// even though all three components share the attributes field, so wrapping here for TS
export const mapDispatchSelect: MapDispatchToPropsSimple<
	DispatchProps,
	SelectForColumnCellOwnProps
> = mapDispatchBase;

export const mapDispatchAsyncSelect: MapDispatchToPropsSimple<
	DispatchProps,
	AsyncSelectForColumnCellOwnProps
> = mapDispatchBase;

export const mapDispatchRollupSelect: MapDispatchToPropsSimple<
	DispatchProps,
	CellSelectWithRollupOwnProps
> = mapDispatchBase;

export const mapDispatchAsyncRollupSelect: MapDispatchToPropsSimple<
	DispatchProps,
	AsyncSelectWithRollupOwnProps
> = mapDispatchBase;
