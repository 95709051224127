import { defineMessages, type MessageDescriptor } from '@atlassian/jira-intl';

const messages: { [key: string]: MessageDescriptor } = defineMessages({
	dialogLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dialog-label',
		defaultMessage: 'Filter dialog',
		description: 'It is a label for the "Filter" popup dialog',
	},
	header: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.header',
		defaultMessage: 'Filter',
		description: 'It is a label for the "Filter" button which opens / closes the "Filter" menu',
	},
	headerOld: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.header-old',
		defaultMessage: 'Filters',
		description: 'It is a label for the "Filters" button which opens / closes the "Filters" menu',
	},
	clearAllFiltersLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.clear-all-filters-label',
		defaultMessage: 'Clear all filters',
		description: 'It is a label for the button to clear all filters',
	},
	selected: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.selected',
		defaultMessage: 'selected',
		description: 'Indicates the type of filter selected',
	},
	/* eslint-disable jira/i18n/id-named-by-package */
	'state.domain.view-settings.filters.HIERARCHY_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter-id',
		defaultMessage: 'Hierarchy',
		description: 'The title for the hierarchy filter menu',
	},
	'state.domain.view-settings.filters.PROJECT_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.project-filter-id',
		defaultMessage: 'Projects',
		description: 'The title for the project filter menu',
	},
	'state.domain.view-settings.filters.ISSUE_TYPE_KEY_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-type-key-filter-id',
		defaultMessage: 'Issue types',
		description: 'The title for the issue types filter menu',
	},
	'state.domain.view-settings.filters.ISSUE_PRIORITIES_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-priorities-filter-id',
		defaultMessage: 'Priorities',
		description: 'The title for the issue priorities filter menu',
	},
	'state.domain.view-settings.filters.RELEASE_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.release-filter-id',
		defaultMessage: 'Releases',
		description: 'The title for release filter menu',
	},
	'state.domain.view-settings.filters.SUMMARY_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.summary-filter-id',
		defaultMessage: 'Issue details',
		description: 'It is a title for summary filter',
	},
	'state.domain.view-settings.filters.TEAM_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.team-filter-id',
		defaultMessage: 'Teams',
		description: 'It is a title for team filter',
	},
	'state.domain.view-settings.filters.ASSIGNEE_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.assignee-filter-id',
		defaultMessage: 'Assignees',
		description: 'It is a title for assignee filter',
	},
	'state.domain.view-settings.filters.REPORTER_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.reporter-filter-id',
		defaultMessage: 'Reporters',
		description: 'It is a title for reporter filter',
	},
	'state.domain.view-settings.filters.DEPENDENCIES_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter-id',
		defaultMessage: 'Dependencies',
		description: 'It is a title for dependencies filter',
	},
	'state.domain.view-settings.filters.LABEL_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.label-filter-id',
		defaultMessage: 'Labels',
		description: 'It is a title for label filter',
	},
	'state.domain.view-settings.filters.STATUS_KEY_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter-id',
		defaultMessage: 'Statuses',
		description: 'It is a title for status filter',
	},
	'state.domain.view-settings.filters.COMPONENT_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.component-filter-id',
		defaultMessage: 'Components',
		description: 'It is a title for component filter',
	},
	'state.domain.view-settings.filters.ISSUE_WARNING_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.issue-warning-filter-id',
		defaultMessage: 'Warnings',
		description: 'It is a title for warning filter',
	},
	'state.domain.view-settings.filters.SPRINT_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.sprint-filter-id',
		defaultMessage: 'Sprints',
		description: 'It is a title for sprint filter',
	},
	'state.domain.view-settings.filters.GOAL_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.goal-filter-id',
		defaultMessage: 'Goals',
		description: 'It is a title for goal filter',
	},
	'state.domain.view-settings.filters.IDEA_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.idea-filter-id',
		defaultMessage: 'Ideas',
		description: 'It is a title for JPD ideas filter',
	},
	'state.domain.view-settings.filters.DATE_RANGE_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.date-range-filter-id',
		defaultMessage: 'Date range',
		description: 'It is a title for date range filter',
	},
	'state.domain.view-settings.filters.HIERARCHY_RANGE_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-range-filter-id',
		defaultMessage: 'Hierarchy',
		description: 'It is a title for the hierarchy range filter',
	},
	'state.domain.view-settings.filters.CROSS_PROJECT_RELEASE_FILTER_ID': {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.cross-project-release-filter-id',
		defaultMessage: 'Cross project release',
		description: 'It is a title for the cross project release filter',
	},
	/* eslint-enable jira/i18n/id-named-by-package */
});

export default messages;
