import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Props } from './types.tsx';

export default createSelector(
	[(_: State, ownProps: Props) => ownProps],
	(ownProps): Props => ({
		...ownProps,
	}),
);
