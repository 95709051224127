import React, { memo, useMemo, useRef } from 'react';
import isNil from 'lodash/fp/isNil';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { IssueBreakdownEntryPointContextProvider } from '@atlassian/jira-portfolio-3-ai-work-breakdown/src/controllers/entrypoint-context.tsx';
import { SUB_TASK_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import Issue from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/issue/index.tsx';
import SelectableIssue from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/selectable-issue/index.tsx';
import {
	getCurrentValue,
	hasValueChanged,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import DraggableIssue from './draggable-issue/index.tsx';
import type { Props } from './types.tsx';

export const Scope = memo(
	({
		enhancer,
		item,
		warnings,
		readonly,
		onToggle,
		isGroupingByMultiSelectCustomField,
		rankDigits,
		startLevel,
		isOptimizedMode,
		isScrolling,
	}: Props) => {
		const noopRef = useRef<HTMLDivElement>(null);
		const issue = useMemo(() => enhancer(item.value), [enhancer, item.value]);
		const hasIndentation = !!item.parentGroup;

		const isOptimizedItem = useMemo(() => {
			let isOptimized = false;

			if (isOptimizedMode && isDefined(issue.optimized)) {
				for (const [optimizedKey, optimizedValue] of Object.entries(issue.optimized)) {
					if (hasValueChanged(getCurrentValue(issue, optimizedKey), optimizedValue)) {
						isOptimized = true;
						break;
					}
				}
			}

			return isOptimized;
		}, [isOptimizedMode, issue]);

		const shouldHideIssueRank = () => {
			// note: "startLevel" is the top hierarchy level ("From") selected in the hierarchy filter
			if (isNil(issue.level)) {
				return SUB_TASK_LEVEL < startLevel;
			}
			return issue.level < startLevel;
		};
		const content = (
			<Box xcss={[isOptimizedItem && optimizedContainerStyles]}>
				<SelectableIssue
					issueId={issue.id}
					group={issue.group ?? ''}
					toggleSelectedIssues={() => null}
				>
					{({ isSelected, hasChildSelected, toggleSelected }) => (
						<DraggableIssue>
							{(draggableRef, isDragging) => (
								<Issue
									outerRef={readonly ? noopRef : draggableRef}
									disabled={readonly}
									onToggle={onToggle}
									hideRank={shouldHideIssueRank()}
									issue={issue}
									isReadOnly={readonly}
									isDragging={isDragging}
									isScrolling={isScrolling}
									rankDigits={rankDigits}
									isSelected={isSelected}
									hasChildSelected={hasChildSelected}
									toggleSelected={toggleSelected}
									warnings={warnings}
									hasIndentation={hasIndentation}
									groupCombination={item.groupCombination}
									isGroupingByMultiSelectCustomField={isGroupingByMultiSelectCustomField}
								/>
							)}
						</DraggableIssue>
					)}
				</SelectableIssue>
			</Box>
		);
		if (fg('enable-ai-work-breakdown-in-plans')) {
			return (
				<IssueBreakdownEntryPointContextProvider>{content}</IssueBreakdownEntryPointContextProvider>
			);
		}
		return content;
	},
);

const stripesLayer = `repeating-linear-gradient(
    135deg,
    transparent,
    ${token('color.background.neutral', 'rgba(101, 84, 192, 0.1)')} 1px,
    ${token('color.background.neutral', 'rgba(101, 84, 192, 0.1)')} 16px,
    transparent 17px,
    transparent 28px
)`;

const optimizedContainerStyles = xcss({
	background: 'elevation.surface',
	backgroundImage: stripesLayer,
});
