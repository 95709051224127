import React, { Component } from 'react';
import classNames from 'classnames';
import { IconTile } from '@atlaskit/icon';
import AddIcon from '@atlaskit/icon/core/migration/add--add-circle';
import { token } from '@atlaskit/tokens';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import {
	ONE_DAY,
	endOfUtcDay,
	startOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import DragObserver from '@atlassian/jira-portfolio-3-common/src/drag-observer/index.tsx';
import type { Position } from '@atlassian/jira-portfolio-3-common/src/drag-observer/types.tsx';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { getPositionsForBar } from '../bar/utils.tsx';
import Bar from '../bar/view.tsx';
import DateField from '../date-field/index.tsx';
import DateTooltip from '../date-tooltips/index.tsx';
import IssueLength from '../issue-length/index.tsx';
import Overflowed from '../overflowed/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type {
	Props,
	State,
	CalculatePreviewSize,
	CalculateBaselines,
	CalculateBaselineEnd,
	CalculateHorizontalScrollingBaselines,
	UnitToTime,
} from './types.tsx';

const snapWithFloor = (val: number, snap: number) => Math.floor(val / snap) * snap;

// Where the hover preview should be in relation to the cursor
// 0   = The preview will be to the right of the cursor
// 0.5 = The preview will be centered around the cursor
// 1   = The preview will be to the left of the cursor
const HOVER_PREVIEW_CURSOR_OFFSET_RATIO = 0;

export const unitToTime: UnitToTime = (unit, timelineSize, timelineRange) =>
	unit * timelineSize + timelineRange.start;

export const calculatePreviewSize: CalculatePreviewSize = (
	relativeMouseX,
	viewWidth,
	timelineSize,
	timelineUnits,
) => {
	const fromXPercentage = (relativeMouseX / viewWidth) * 100;
	let accumulatedUnitPercentage = 0;
	let previewUnit = timelineUnits[0];
	for (let i = 0; i < timelineUnits.length; i++) {
		accumulatedUnitPercentage += timelineUnits[i].widthPercentage;
		if (accumulatedUnitPercentage > fromXPercentage || i === timelineUnits.length - 1) {
			previewUnit = timelineUnits[i];
			break;
		}
	}
	const widthPercentage: number = previewUnit ? previewUnit.widthPercentage : 100;

	const previewSize = snapWithFloor((widthPercentage / 100) * timelineSize, ONE_DAY);
	return previewSize;
};

export const calculateBaselines: CalculateBaselines = (
	fromX,
	toX,
	timelineRange,
	timelineUnits,
	rect,
	calculateBaselineEnd,
) => {
	const relativeStartX = fromX - rect.left;
	const relativeToX = toX - rect.left;

	const timelineSize = timelineRange.end - timelineRange.start;

	const previewSize = calculatePreviewSize(relativeStartX, rect.width, timelineSize, timelineUnits);

	const startUnit = relativeStartX / rect.width;
	const baselineStart = snapWithFloor(
		unitToTime(startUnit, timelineSize, timelineRange) -
			previewSize * HOVER_PREVIEW_CURSOR_OFFSET_RATIO,
		ONE_DAY,
	);
	const endUnit = relativeToX / rect.width;

	const unroundedBaselineEnd = unitToTime(endUnit, timelineSize, timelineRange);
	const baselineEnd = calculateBaselineEnd(unroundedBaselineEnd, previewSize, baselineStart);

	return {
		baselineStart: startOfUtcDay(baselineStart),
		baselineEnd: endOfUtcDay(baselineEnd),
	};
};

export const calculateHorizontalScrollingBaselines: CalculateHorizontalScrollingBaselines = (
	fromX,
	timelineRange,
	rect,
	getPreview,
) => {
	const relativeStartX = fromX - rect.left;

	const timelineSize = timelineRange.end - timelineRange.start;

	const previewSize = getPreview(relativeStartX);

	const startUnit = relativeStartX / rect.width;
	const baselineStart = snapWithFloor(
		unitToTime(startUnit, timelineSize, timelineRange) -
			previewSize * HOVER_PREVIEW_CURSOR_OFFSET_RATIO,
		ONE_DAY,
	);
	const baselineEnd = baselineStart + previewSize;

	return {
		baselineStart: startOfUtcDay(baselineStart),
		baselineEnd: startOfUtcDay(baselineEnd),
	};
};

export const calculateBaselineEndOnDrag: CalculateBaselineEnd = (
	unroundedBaselineEnd,
	_,
	baselineStart,
) => {
	let baselineEnd = snapWithFloor(unroundedBaselineEnd, ONE_DAY);
	baselineEnd = Math.max(baselineStart, baselineEnd);
	return baselineEnd;
};

export const calculateBaselineEndOnMouseMove: CalculateBaselineEnd = (
	unroundedBaselineEnd,
	previewSize,
) => {
	const baselineEnd = snapWithFloor(
		unroundedBaselineEnd + previewSize * (1 - HOVER_PREVIEW_CURSOR_OFFSET_RATIO),
		ONE_DAY,
	);
	return baselineEnd;
};

// eslint-disable-next-line jira/react/no-class-components
export default class NoDatesRowView extends Component<Props, State> {
	// @ts-expect-error - TS2564 - Property 'ref' has no initializer and is not definitely assigned in the constructor.
	// eslint-disable-next-line react/sort-comp
	ref: Element;

	static defaultProps = {
		isReadOnly: false,
		Bar,
	};

	state = {
		dragging: false,
		hovered: false,
	};

	onMouseMove = (e: React.MouseEvent) => {
		if (this.props.isReadOnly) {
			return;
		}

		if (this.state.dragging) {
			// This method handles hovering only. Drag and drop is handled in onDrag.
			return;
		}

		if (!(this.ref instanceof Element)) {
			return;
		}

		const { timelineRange, timelineUnits, zoomLevel, getPreviewSizeInMs } = this.props;

		if (zoomLevel === undefined || !getPreviewSizeInMs) {
			const { baselineStart, baselineEnd } = calculateBaselines(
				e.pageX,
				e.pageX,
				timelineRange,
				timelineUnits,
				this.ref.getBoundingClientRect(),
				calculateBaselineEndOnMouseMove,
			);
			this.updatePreview(baselineStart, baselineEnd);
		} else {
			const { baselineStart, baselineEnd } = calculateHorizontalScrollingBaselines(
				e.pageX,
				timelineRange,
				this.ref.getBoundingClientRect(),
				getPreviewSizeInMs,
			);
			this.updatePreview(baselineStart, baselineEnd);
		}
	};

	onMouseDown = () => {
		if (this.props.isReadOnly) {
			return;
		}
		// We act on onMouseDown instead of onDragStart to prevent the date from jumping
		// while we're still moving within the drag threshold
		this.setState({
			dragging: true,
		});

		this.props.disableOtherRows(this.props.issue.id);
	};

	onDrag = (_: Position, to: Position, start: Position) => {
		if (this.props.isReadOnly) {
			return;
		}

		if (!(this.ref instanceof Element)) {
			return;
		}

		//  drag right to left, the first date is the end date
		//  drag left to right, the first date is the start date
		const startX = to.x - start.x < 0 ? to.x : start.x;
		const toX = to.x - start.x < 0 ? start.x : to.x;
		const { timelineRange } = this.props;
		const { baselineStart, baselineEnd } = calculateBaselines(
			startX,
			toX,
			timelineRange,
			this.props.timelineUnits,
			this.ref.getBoundingClientRect(),
			calculateBaselineEndOnDrag,
		);
		this.updatePreview(baselineStart, baselineEnd);
	};

	onDragEnd = () => {
		if (this.props.isReadOnly) {
			return;
		}
		this.props.commitTimelinePreview();
		this.props.reEnableOtherRows();
	};

	onClick = () => {
		if (this.props.isReadOnly) {
			return;
		}
		this.props.commitTimelinePreview();
		this.props.reEnableOtherRows();
	};

	updatePreview = (baselineStart: Timestamp, baselineEnd: Timestamp) => {
		this.props.hoverTimelineIssue(baselineStart, baselineEnd);
	};

	onHoverChanged = (hover: boolean) => {
		if (this.props.isReadOnly) {
			return;
		}
		this.setState({ hovered: hover });
		if (!hover) {
			this.props.cancelTimelinePreview();
		}
	};

	render() {
		const {
			barColor,
			barColors,
			timelineRange,
			preview,
			issue,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			Bar,
			topOffset,
		} = this.props;

		let bar;
		let startDate;
		let endDate;
		let dates;
		let numDays;

		if (preview) {
			const { leftPositionPercentage, rightPositionPercentage } = getPositionsForBar(
				preview,
				timelineRange,
			);

			bar = (
				<Bar
					leftPositionPercentage={leftPositionPercentage}
					rightPositionPercentage={rightPositionPercentage}
					leftAnchorPercentage={0}
					rightAnchorPercentage={100}
					color={barColor}
					colors={barColors}
					opacity={0.5}
					id={issue.id}
					additionalStyles={{ zIndex: 0 }}
					topOffset={topOffset}
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.spaBarContent}>
						{isVisualRefreshEnabled() ? (
							<IconTile label="" size="16" appearance="gray" shape="circle" icon={AddIcon} />
						) : (
							<AddIcon
								color={token('color.icon.inverse')}
								label=""
								LEGACY_size="small"
								LEGACY_primaryColor={token('elevation.surface.raised', 'rgba(255, 255, 255, 0.8)')}
								LEGACY_secondaryColor={barColor || token('color.text.subtle', colors.N500)}
							/>
						)}
					</div>
				</Bar>
			);

			if (this.state.hovered || this.state.dragging) {
				startDate = <DateField startOrEnd="start" date={preview.baselineStart} />;
				endDate = <DateField startOrEnd="end" date={preview.baselineEnd} />;
				numDays = <IssueLength startDate={preview.baselineStart} endDate={preview.baselineEnd} />;
			}

			if (this.ref instanceof Element) {
				const rect = this.ref.getBoundingClientRect();

				dates = (
					<DateTooltip
						datePosition={{ leftPositionPercentage, rightPositionPercentage }}
						width={rect.width}
					>
						{startDate}
						{numDays}
						{endDate}
					</DateTooltip>
				);
			}
		}

		return (
			<HoverObserver onHoverChanged={this.onHoverChanged}>
				<DragObserver
					dragHandler={{
						onMouseDown: this.onMouseDown,
						onDrag: this.onDrag,
						onDragEnd: this.onDragEnd,
						onClick: this.onClick,
					}}
				>
					<div
						data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.no-dates.row-${issue.id}`}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={classNames(styles.spaNoDatesRow, {
							[styles.readOnly]: this.props.isReadOnly,
						})}
						onMouseMove={this.onMouseMove}
						ref={(ref) => {
							if (ref) {
								this.ref = ref;
							}
						}}
					>
						{bar}
						{dates}
						<Overflowed />
					</div>
				</DragObserver>
			</HoverObserver>
		);
	}
}
