import React, { createRef, useState, useEffect } from 'react';
import { xcss } from '@atlaskit/primitives';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import IssueLink from '@atlassian/jira-portfolio-3-common/src/issue-link/index.tsx';
import {
	splitString,
	equalsIgnoreCase,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { HighlightIssueLinkProps, HighlightSummaryProps } from './types.tsx';

export function HighlightSummary({
	numberOfIssueLinkChunks,
	summary,
	searchQuery,
	id,
	activeSearchResultIndex,
	searchMatches,
	group,
}: HighlightSummaryProps) {
	const activeElRef = createRef<HTMLSpanElement>();
	const [ellipsisInfo, setEllipsisInfo] = useState({
		chunks: splitString(searchQuery, summary),
		offsetLeft: 0,
	});
	const [prevQuery, setPrevQuery] = useState('');
	const activeMatch = searchMatches[activeSearchResultIndex] || {};
	const isIssueWithActiveElement = activeMatch.id === id;
	const getInternalIndex = (index: number) => (index > 0 ? index - numberOfIssueLinkChunks : index);
	const prevActiveSearchResultIndex = usePrevious(activeSearchResultIndex);
	const prevIsIssueWithActiveElement = usePrevious(isIssueWithActiveElement);
	const prevActiveMatchId = usePrevious(activeMatch.id);
	// the previous summary is used to track changes made to the issue summary using the inline edit field
	const prevSummary = usePrevious(summary);

	const isActiveElVisible = (ref: { current: null | HTMLSpanElement }) => {
		if (ref && ref.current && ref.current.parentElement instanceof HTMLElement) {
			return (
				// reducing the ref.current[offsetLeft + offsetWidth] by 1 to cope with "..." added in front of matching chunks which are initially hidden
				ref.current.offsetLeft + ref.current.offsetWidth - 1 <=
				ref.current.parentElement.offsetLeft + ref.current.parentElement.offsetWidth
			);
		}
	};

	useEffect(() => {
		// resets string to initial chunks
		const isNavigationBackAtTheSameEl =
			isDefined(prevActiveSearchResultIndex) &&
			prevActiveSearchResultIndex > activeSearchResultIndex &&
			prevActiveMatchId === activeMatch.id;
		if (
			(searchQuery && searchQuery !== prevQuery) ||
			(!isIssueWithActiveElement && !!prevIsIssueWithActiveElement) ||
			isNavigationBackAtTheSameEl ||
			prevSummary !== summary
		) {
			setPrevQuery(searchQuery);
			setEllipsisInfo({ chunks: splitString(searchQuery, summary), offsetLeft: 0 });
		}
	}, [
		activeElRef,
		activeMatch.id,
		activeSearchResultIndex,
		isIssueWithActiveElement,
		prevActiveMatchId,
		prevActiveSearchResultIndex,
		prevIsIssueWithActiveElement,
		prevQuery,
		prevSummary,
		searchQuery,
		summary,
	]);

	useEffect(() => {
		// useEffect does main stuff with cutting string and adding ellipsis
		const getWordStart = (restArray: string[]) => {
			const lastEl = restArray[restArray.length - 1];
			const index = lastEl.lastIndexOf(' ');
			if (index > 0) {
				return lastEl.substring(index + 1);
			}
		};

		const matchIndex = activeMatch.internalIndex - numberOfIssueLinkChunks;

		if (isActiveElVisible(activeElRef) === false && matchIndex > 0) {
			const chunksArray = splitString(searchQuery, summary);
			let chunks = chunksArray.splice(matchIndex);
			const wordStart = getWordStart(chunksArray) || '';

			if (wordStart) {
				chunks = ['...', wordStart].concat(chunks);
			} else {
				chunks = ['...'].concat(chunks);
			}
			setEllipsisInfo({
				chunks,
				// when we have wordStart we make array longer by 1 element so we need to reduce offsetLeft by 2
				offsetLeft: !wordStart ? matchIndex - 1 : matchIndex - 2,
			});
		}
	}, [activeElRef, activeMatch.internalIndex, numberOfIssueLinkChunks, searchQuery, summary]);

	if (!searchQuery.trim() || !searchMatches.length) {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		return <div className={styles.ellipsis}>{summary}</div>;
	}

	const highlightedParts = ellipsisInfo.chunks.map((chunk: string, i) => {
		if (equalsIgnoreCase(chunk, searchQuery)) {
			const matchIndex = getInternalIndex(activeMatch.internalIndex);

			if (
				!activeMatch.linkMatch &&
				activeMatch.group === group &&
				isIssueWithActiveElement &&
				i === matchIndex - ellipsisInfo.offsetLeft
			) {
				return (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<span ref={activeElRef} className={`${styles.textMatch} ${styles.isActive}`} key="active">
						{chunk}
					</span>
				);
			}
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<span className={styles.textMatch} key={`${chunk}-${i}`}>
					{chunk}
				</span>
			);
		}
		return chunk;
	});

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	return <div className={styles.ellipsis}>{highlightedParts}</div>;
}

export function HighlightIssueLink({
	issueKey,
	projectKey,
	// @ts-expect-error - TS2739 - Type '{}' is missing the following properties from type 'SearchMatch': internalIndex, id, linkMatch
	activeMatch: { internalIndex, id, group: activeGroup } = {},
	searchQuery,
	issueLinkChunks,
	issueId,
	group,
}: HighlightIssueLinkProps) {
	const getLinkText = () => {
		if (issueLinkChunks.length) {
			return (
				<>
					{issueLinkChunks.map((chunk, i) => {
						if (equalsIgnoreCase(chunk, searchQuery)) {
							return activeGroup === group && issueId === id && i === internalIndex ? (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<span className={`${styles.textMatch} ${styles.isActive}`} key="active">
									{chunk}
								</span>
							) : (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<span className={styles.textMatch} key={`${chunk}-${i}`}>
									{chunk}
								</span>
							);
						}
						return chunk;
					})}
				</>
			);
		}
		return '';
	};

	return (
		<IssueLink
			issueKey={issueKey}
			projectKey={projectKey}
			xcssStyles={issueLinkStyles}
			issueId={issueId}
		>
			{getLinkText()}
		</IssueLink>
	);
}

const issueLinkStyles = xcss({
	display: 'flex',
	whiteSpace: 'nowrap',
	marginRight: 'space.050',
});
