.avatarWithTitle {
	align-items: center;
	display: inline-flex;
}

.userTitle {
	padding-left: var(--jpo-common-base-spacing);
}

.mutedColor {
	color: var(--ds-text-subtlest, var(--jpo-text-muted-color));
}
