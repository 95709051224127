import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.custom-field-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is the placeholder for no filter set',
	},
	searchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.custom-field-filter.search-placeholder',
		defaultMessage: 'Choose an option',
		description: 'This is the placeholder for the custom field filter search',
	},
	noMatchFoundText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.custom-field-filter.no-match-found-text',
		defaultMessage: 'No matches',
		description:
			'This is the text that is displayed when none of the options match the search query.',
	},
	noValue: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.custom-field-filter.no-value',
		defaultMessage: 'No value',
		description: 'This is the text displayed to select issues with no custom field value',
	},
});
