import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	startDependency: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.dependency-marker.start-dependency',
		defaultMessage: 'Start dependency',
		description:
			'Aria label text when focus is switched to start dependency button of a selected field',
	},
	endDependency: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.dependency-marker.end-dependency',
		defaultMessage: 'End dependency',
		description:
			'Aria label text when focus is switched to end dependency button of a selected field',
	},
});
