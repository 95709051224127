import React, { useCallback } from 'react';
import { useDependencySettings } from '@atlassian/jira-portfolio-3-dependency-lines/src/controllers/index.tsx';
import { DependencyLineDetail } from '@atlassian/jira-portfolio-3-dependency-lines/src/ui/index.tsx';
import { getIssueLinkIfOverlapping } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/util.tsx';
import { TABLE_ISSUE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import { DEPENDENCY_SETTINGS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Props } from './types.tsx';

export default function DependencyLinesLineDetail({
	isEditMode,
	issueStatusById,
	removeDependency,
	scope,
	syncStartEnabled,
}: Props) {
	const [dependencySettings] = useDependencySettings();
	const areIssuesOverlapping = useCallback(
		(fromItemScopeIndex: number, toItemScopeIndex: number): boolean => {
			const fromItem = scope[fromItemScopeIndex];

			if (!fromItem || fromItem.tag !== TABLE_ISSUE) {
				return false;
			}

			const toItem = scope[toItemScopeIndex];

			if (!toItem || toItem.tag !== TABLE_ISSUE) {
				return false;
			}

			return getIssueLinkIfOverlapping(
				fromItem.value,
				toItem.value,
				issueStatusById,
				syncStartEnabled,
			);
		},
		[issueStatusById, scope, syncStartEnabled],
	);

	return dependencySettings.display === DEPENDENCY_SETTINGS.LINES ? (
		<DependencyLineDetail
			onUnlink={removeDependency}
			canDelete={isEditMode}
			isOverlapping={areIssuesOverlapping}
		/>
	) : null;
}
