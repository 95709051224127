import React, { Component, type ReactNode } from 'react';
import { ExperienceFailureTracker as ViewExperienceFailureTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/result-declared/index.tsx';
import { ErrorPage as ErrorBoundaryPage } from '@atlassian/jira-error-boundary/src/ui/error-page/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { isEnvironmentError } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import {
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { genericError, type GenericErrorActionPayload } from '../command/errors/index.tsx';

type Props = {
	children: ReactNode;
	genericError: (arg1: GenericErrorActionPayload) => void;
};

const mapDispatchToProps = {
	genericError,
} as const;

class PortfolioErrorBoundary extends Component<
	Props,
	{
		hasError: boolean;
		error: Error | null;
	}
> {
	// eslint-disable-next-line react/sort-comp
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	static getDerivedStateFromError(error: Error) {
		return { hasError: true, error };
	}

	componentDidCatch(error: Error) {
		const { message, stack: stackTrace } = error;
		if (fg('improve_redux_saga_error_reporting_plans_batch_1')) {
			const id = isEnvironmentError(error)
				? ERROR_REPORTING_PACKAGE.ENVIRONMENT
				: ERROR_REPORTING_PACKAGE.APP;

			fireErrorAnalytics({
				error,
				meta: {
					id,
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				sendToPrivacyUnsafeSplunk: true,
			});
		} else {
			this.props.genericError({
				message,
				stackTrace,
				shouldReportError: false,
			});
		}
	}

	render() {
		if (this.state.hasError) {
			return (
				<>
					<ViewExperienceFailureTracker location="portfolio-3.simple-plans.view-error" />
					<ErrorBoundaryPage error={this.state.error} />
				</>
			);
		}

		return this.props.children;
	}
}

export default connect(null, mapDispatchToProps)(PortfolioErrorBoundary);
