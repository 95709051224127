import type { Options } from 'scroll-into-view-if-needed';

export const SCROLL_OPTIONS: Options = {
	// Only scroll element into view when outside the viewport
	scrollMode: 'if-needed',
	// Vertically align element to bottom the scrollable ancestor (stops from breaking the hidden scrollbars)
	block: 'end',
	// Horizontally align element to right of the scrollable ancestor
	inline: 'end',
};
