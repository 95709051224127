import * as R from 'ramda';
import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/index.tsx';
import { getExistingTeams } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/index.tsx';
import { TEAM_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { StateProps } from './types.tsx';

const mapStateToProps: MapStateToProps<StateProps> = createSelector(
	[getExistingTeams, getFilters],
	(teams, { [TEAM_FILTER_ID]: { id, value } }) => ({
		id,
		value,
		teams: R.sortBy(R.compose(R.toLower, R.prop('title')))(teams),
	}),
);

export default mapStateToProps;
