import React, { forwardRef, type Ref } from 'react';
import LoadingButton from '@atlaskit/button/loading-button';
import DefaultButton from '@atlaskit/button/standard-button';
import type { Props } from './types.tsx';

const Button = forwardRef((props: Props, ref?: Ref<HTMLElement>) => {
	const { ariaLabel, isLoading, ...restProps } = props;
	if (isLoading !== undefined && isLoading !== null) {
		return <LoadingButton ref={ref} isLoading={isLoading} {...restProps} />;
	}
	return <DefaultButton ref={ref} {...restProps} aria-label={ariaLabel} />;
});

export default Button;
