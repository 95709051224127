import React, { useState, useCallback } from 'react';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Popup, type TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import type { Props } from './types.tsx';

const WarningDetails = (props: Props) => {
	const { warnings, opacity, isOpen: defaultIsOpen, zIndex } = props;
	const [isOpen, setIsOpen] = useState(!!defaultIsOpen);
	const { formatMessage } = useIntl();

	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	const onHoverChanged = useCallback((isHovered: boolean) => {
		setIsOpen(isHovered);
	}, []);

	const renderTrigger = useCallback(
		({ ref, ...triggerProps }: TriggerProps) => (
			<HoverObserver onHoverChanged={onHoverChanged}>
				<div
					style={{ opacity }}
					ref={ref}
					data-testid="portfolio-3-portfolio.common.warning-details.warning-icon"
					{...triggerProps}
				>
					<WarningIcon
						label=""
						primaryColor={token('color.icon.warning', colors.Y300)}
						size="small"
					/>
				</div>
			</HoverObserver>
		),
		[onHoverChanged, opacity],
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const internationaliseNestedMessages = (values: { [key: string]: any }) => {
		const amount = values.amount ? formatMessage(values.amount.key, values.amount) : null;
		return { ...values, amount };
	};

	const renderContent = () => (
		<>
			{warnings &&
				warnings.map((warning, index) => (
					<Box key={index} xcss={entryStyles}>
						<Box xcss={headingStyles}>
							{warnings.length > 1 && `${index + 1}. `}
							<FormattedMessage
								{...warning.header}
								values={internationaliseNestedMessages(warning.messageValues)}
							/>
						</Box>
						{warning.message && (
							<FormattedMessage
								{...warning.message}
								values={internationaliseNestedMessages(warning.messageValues)}
							/>
						)}
					</Box>
				))}
		</>
	);

	return (
		<Popup
			zIndex={zIndex}
			isOpen={isOpen}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			placement="bottom-start"
			shouldUseCaptureOnOutsideClick
		/>
	);
};

export default WarningDetails;

const entryStyles = xcss({
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	paddingLeft: 'space.300',
	paddingRight: 'space.300',
	minWidth: '300px',
	maxWidth: '448px',
});

const headingStyles = xcss({
	fontWeight: 'bold',
	marginBottom: 'space.100',
});
