import {
	addFlag,
	removeFlag,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/flags/actions.tsx';

const mapDispatchToProps = {
	onAddFlag: addFlag,
	onRemoveFlag: removeFlag,
} as const;

export default mapDispatchToProps;
