.sprint-title-container {
	display: flex;
	align-items: center;
	max-width: calc(
		450px - calc(6 * var(--jpo-common-base-spacing)) - calc(1.5 * var(--jpo-icon-size-small))
	);
	padding-right: calc(2 * var(--jpo-common-base-spacing));
}

.sprint-title {
	padding-right: var(--jpo-common-base-spacing);
}
