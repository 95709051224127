import {
	getExportCsvScopeItems,
	planExportedAnalytics,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/index.tsx';
import type {
	CsvIntlMessages,
	AdditionalData,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/export-roadmap/types.tsx';
import { createShareReportUrl } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/share-report/index.tsx';
import {
	OPEN_SHARE_REPORT_DIALOG,
	CLOSE_SHARE_REPORT_DIALOG,
	UPDATE_SHARE_AS,
	type UpdateShareAsActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/share-report/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	getExportCsvScopeItems: (csvIntlMessages: CsvIntlMessages, additionalData?: AdditionalData) => {
		dispatch(getExportCsvScopeItems(csvIntlMessages, additionalData));
	},
	planExportedAnalytics: (payload: Attributes) => {
		dispatch(planExportedAnalytics(payload));
	},
	openShareReportDialog: () => {
		dispatch(createShareReportUrl());
		dispatch({
			type: OPEN_SHARE_REPORT_DIALOG,
		});
	},
	closeShareReportDialog: () => {
		dispatch({
			type: CLOSE_SHARE_REPORT_DIALOG,
		});
	},
	updateShareReportUrl: (payload: UpdateShareAsActionPayload) => {
		dispatch({
			type: UPDATE_SHARE_AS,
			payload,
		});
		// Warning! We have to create the report url *after* we've toggled the internal embed state above.
		dispatch(createShareReportUrl());
	},
});

export default mapDispatchToProps;
