.issueTitle {
	display: flex;
	align-items: center;
}

.issueIcon {
	width: var(--jpo-icon-size-small);
	min-width: var(--jpo-icon-size-small);
	height: var(--jpo-icon-size-small);
	margin-right: 5px;
}

.issueLinkType {
	margin-right: 5px;
}

.issueSummary {
	margin-left: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
