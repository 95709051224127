import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	DEPENDENCIES_FILTER_ID,
	type DependenciesFilter,
	type DependenciesFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { IssueStatus } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	getAllIssuesWithLinks,
	getInternalIncomingLinks,
	getInternalOutgoingLinks,
	type IssueLinksByIssueId,
} from '../../issue-links/index.tsx';
import { getIssueLinkIfOverlapping } from '../../issue-links/util.tsx';
import { getIssueStatusById } from '../../issue-statuses/index.tsx';
import { getSyncStartEnabled } from '../../plan/index.tsx';
import { type IssueMap, getIssueMapById } from '../../raw-issues/index.tsx';
import { getFiltersViewSettings } from '../../view-settings/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DEPENDENCIES_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

export const applyFilter = (issue: Issue, filter: DependenciesFilter): boolean => {
	const {
		value,
		issuesWithLinks = new Set(),
		specificIssueLinks = new Set(),
		offtrackIssueLinks = new Set(),
	} = filter;
	switch (value.type) {
		case 'on':
			return issuesWithLinks.has(issue.id);
		case 'specificIssue':
			return !value.issueId || specificIssueLinks.has(issue.id) || value.issueId === issue.id;
		case 'offtrack':
			return offtrackIssueLinks.has(issue.id);
		default:
			return true;
	}
};

export const getDependenciesFilterState = (
	state: State,
): Partial<DependenciesFilter> | null | undefined =>
	getFiltersViewSettings(state)[DEPENDENCIES_FILTER_ID] || {
		value: { type: 'off' },
	};

export const getSpecificIssueLinks = (
	value: DependenciesFilterValue,
	incomingLinks: IssueLinksByIssueId,
	outgoingLinks: IssueLinksByIssueId,
): Set<string> => {
	const specificIssueLinks: Set<string> = new Set();
	if (value.type === 'specificIssue' && value.issueId) {
		if (value.includeTransitive) {
			// Collect all the issues which are linked to the specific one via dependencies,
			// both immediate and transitive.
			// Handling cycles: skip met issues, but don't interrupt queue traversal.
			const queue = [value.issueId];
			while (queue.length > 0) {
				const id = queue.pop();
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				for (const link of incomingLinks[id!] || []) {
					// eslint-disable-next-line @typescript-eslint/no-shadow
					const id = link.sourceItemKey;
					if (!specificIssueLinks.has(id)) {
						specificIssueLinks.add(id);
						queue.push(id);
					}
				}
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				for (const link of outgoingLinks[id!] || []) {
					// eslint-disable-next-line @typescript-eslint/no-shadow
					const id = link.targetItemKey;
					if (!specificIssueLinks.has(id)) {
						specificIssueLinks.add(id);
						queue.push(id);
					}
				}
			}
		} else {
			for (const link of incomingLinks[value.issueId || ''] || []) {
				specificIssueLinks.add(link.sourceItemKey);
			}
			for (const link of outgoingLinks[value.issueId || ''] || []) {
				specificIssueLinks.add(link.targetItemKey);
			}
		}
	}
	return specificIssueLinks;
};

export const getOfftrackIssueLinksFunc = (
	issueMap: IssueMap,
	outgoingLinks: IssueLinksByIssueId,
	issueStatusById: {
		[id: string]: IssueStatus;
	},
	syncStartEnabled: boolean,
): Set<string> => {
	const offtrackIssueLinks: Set<string> = new Set();

	for (const issueLinks of Object.values(outgoingLinks)) {
		for (const { sourceItemKey, targetItemKey } of issueLinks) {
			const overlapping = getIssueLinkIfOverlapping(
				issueMap[sourceItemKey],
				issueMap[targetItemKey],
				issueStatusById,
				syncStartEnabled,
			);
			if (overlapping) {
				offtrackIssueLinks.add(sourceItemKey);
				offtrackIssueLinks.add(targetItemKey);
			}
		}
	}

	return offtrackIssueLinks;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getOfftrackIssueLinks = monitor.timeFunction<any, any>(
	getOfftrackIssueLinksFunc,
	'query_filters_dependenciesFilter_index_getOfftrackIssueLinks',
);

export const getDependenciesFilter = createSelector(
	[
		getDependenciesFilterState,
		getAllIssuesWithLinks,
		getInternalIncomingLinks,
		getInternalOutgoingLinks,
		getIssueMapById,
		getIssueStatusById,
		getSyncStartEnabled,
	],
	(
		filterState,
		issuesWithLinks: Set<string>,
		incomingLinks,
		outgoingLinks,
		issueMap,
		issueStatusById,
		syncStartEnabled,
	): DependenciesFilter => {
		const { value = { type: 'off' } } = filterState || {};
		return {
			id: DEPENDENCIES_FILTER_ID,
			value,
			issuesWithLinks,
			specificIssueLinks: getSpecificIssueLinks(value, incomingLinks, outgoingLinks),
			offtrackIssueLinks: getOfftrackIssueLinks(
				issueMap,
				outgoingLinks,
				issueStatusById,
				syncStartEnabled,
			),
		};
	},
);
