import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	setHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-sprint.set-header',
		defaultMessage: 'Update sprint',
		description: 'Modal dialog header for dialog sprint',
	},
	variousValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-sprint.various-values',
		defaultMessage: 'Various sprints',
		description: 'Label for various sprints values',
	},
	otherSprints: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-sprint.other-sprints',
		defaultMessage: 'Other sprints',
		description: "Category header for sprints that doesn't belong to a team",
	},
});
