import get from 'lodash/get';
import padStart from 'lodash/padStart';
import {
	parse,
	formatDistance,
	isEqual,
	subMonths,
	addWeeks,
	addMonths,
	addDays,
	subDays,
	subWeeks,
} from 'date-fns';
import * as R from 'ramda';
import { getLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { DEFAULT_WEEK_START_DAY, DateUnits } from './constants.tsx';
import type { CustomDateRange } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { CustomDateRange } from './types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	addDays,
	addWeeks,
	addMonths,
	addQuarters,
	addYears,
	endOfDay,
	endOfWeek,
	endOfMonth,
	endOfQuarter,
	endOfYear,
	getDaysInMonth,
	getDaysInYear,
	differenceInDays,
	formatDistanceToNow as distanceInWordsToNow,
	formatDistance as distanceInWords,
	format as formatDate,
	isAfter,
	isValid as isDateValid,
	isEqual as isDateEqual,
	isSameDay,
	isSameYear,
	startOfDay,
	startOfWeek,
	startOfMonth,
	startOfQuarter,
	startOfYear,
	subDays,
	subWeeks,
	subMonths,
	parse,
} from 'date-fns';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	formatDateUTC,
	getFullDayLabel,
	getFullMonthLabel,
	getDayLabel,
	getMonthLabel,
	useDateFormatter,
	formatTimestampWithIntl,
	formatDateWithIntl,
} from './format';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	longDateFormat,
	dateMonthFormat,
	monthYearFormat,
	longDateTimeFormat,
	shortDateFormat,
	weekdayFormat,
	weekdayFormatLong,
	DateUnits,
	DefaultDateFormat,
	CUSTOM_TIME_RANGE_TYPES,
	IsoDateFormat,
} from './constants';

export const ONE_HOUR = 60 * 60 * 1000;
export const ONE_DAY = 24 * 60 * 60 * 1000;
export const ONE_WEEK = 7 * ONE_DAY;
export const END_OF_DAY_OFFSET = ONE_DAY - 1;

export const ISO_DATE_REGEXP = /^\d{4}-\d{2}-\d{2}\s?/; // YYYY/MM/DD, YYYY/MM/DD GMT+0000

// Example: 15/Aug/21
export const SHORT_DATE_REGEXP = /^\d{2}\/\w{3}\/\d{2}\s?/; // DD/MMM/YY, DD/MMM/YY GMT+0000

// Example: 15/Aug/2021
export const LONG_DATE_REGEXP = /^\d{2}\/\w{3}\/\d{4}\s?/; // DD/MMM/YYYY, DD/MMM/YYYY GMT+0000

// IMPORTANT: This function is NOT safe to use in the application because it will fail on Firefox
//            It is safe to use in unit tests though
// Given a date string, return the UTC timestamp of that date...
export const getUtcTime = (str: string): number => new Date(`${str} UTC`).getTime();

export const createISODateString = (date: string): Date => {
	if (ISO_DATE_REGEXP.test(date)) {
		const trimmedDate = date.substring(0, 10);

		return new Date(trimmedDate);
	}

	if (LONG_DATE_REGEXP.test(date)) {
		const trimmedDate = date.substring(0, 11);
		const parsedDate: Date = parse(trimmedDate, 'dd/MMM/yyyy', new Date());
		const time: number = parsedDate.getTime();
		const timeOffset: number = parsedDate.getTimezoneOffset() * 60 * 1000;

		return new Date(time - timeOffset);
	}

	// convert DD/MMM/YY to ISO Date for datepicker
	if (SHORT_DATE_REGEXP.test(date)) {
		const trimmedDate = date.substring(0, 9);
		const parsedDate: Date = parse(trimmedDate, 'dd/MMM/yy', new Date());
		const time: number = parsedDate.getTime();
		const timeOffset: number = parsedDate.getTimezoneOffset() * 60 * 1000;

		return new Date(time - timeOffset);
	}

	throw new Error(`Non ISO date format provided to create UTC date: ${date}`);
};

export const isISOCompatible = (date: string): boolean =>
	SHORT_DATE_REGEXP.test(date) || ISO_DATE_REGEXP.test(date);

/**
 * Returns the diff in date between browser locale today with UTC one.
 *
 * @returns 0 when today date is same as UTC date
 * @returns 1 when today is tomorrow of the UTC date
 * @returns -1 when today is yesterday of the UTC date
 */
export const dateDiffFromUTC = () => {
	const now = new Date();
	const tzInHours = -now.getTimezoneOffset() / 60; // Timezone offset is in minutes, time in timezone +10:00 has -600 timezone offset
	const utcHours = (now.getHours() + now.getMinutes() / 60 - tzInHours + 24) % 24;

	if (utcHours + tzInHours > 24) {
		return 1;
	}

	if (utcHours + tzInHours < 0) {
		return -1;
	}

	return 0;
};

export const endOfUtcDay = (date: string | number): number =>
	new Date(R.is(Number, date) ? date : createISODateString(date.toString())).setUTCHours(
		23,
		59,
		59,
		999,
	);

export const startOfUtcDay = (date: string | number): number =>
	new Date(R.is(Number, date) ? date : createISODateString(date.toString())).setUTCHours(
		0,
		0,
		0,
		0,
	);

export const startOfUtcWeek = (value: number): Date => {
	const startOfDay = startOfUtcDay(new Date(value).getTime());

	const dayOfWeek = new Date(startOfDay).getUTCDay();
	const millisecondsToSubstract = ONE_DAY * dayOfWeek;
	const date = new Date(startOfDay - millisecondsToSubstract);

	const year = date.getUTCFullYear();
	const month = padStart(`${date.getUTCMonth() + 1}`, 2, '0'); // Add 1 to compensate for zero-index
	const day = padStart(`${date.getUTCDate()}`, 2, '0');

	const startOfWeek = new Date(`${year}-${month}-${day}T00:00:00Z`);
	return startOfWeek;
};

export const startOfUtcMonth = (value: number): Date => {
	const date = new Date(value);
	const year = date.getUTCFullYear();
	const month = padStart(`${date.getUTCMonth() + 1}`, 2, '0'); // Add 1 to compensate for zero-index
	const startOfMonth = new Date(`${year}-${month}-01T00:00:00Z`);
	return startOfMonth;
};

export const startOfUtcQuarter = (value: number): Date => {
	const date = new Date(value);
	const year = date.getUTCFullYear();
	const UTCmonth = date.getUTCMonth();
	let month;
	if (UTCmonth >= 0 && UTCmonth <= 2) {
		month = '01';
	} else if (UTCmonth >= 3 && UTCmonth <= 5) {
		month = '04';
	} else if (UTCmonth >= 6 && UTCmonth <= 8) {
		month = '07';
	} else {
		month = '10';
	}

	const startOfQuarter = new Date(`${year}-${month}-01T00:00:00Z`);
	return startOfQuarter;
};

export const buildStartOfUtcQuarter =
	(fyStartMonth: number) =>
	(value: number): Date => {
		const date = new Date(value);
		const year = date.getUTCFullYear();
		const month = date.getUTCMonth() + 1; // UTC month starts from 0
		const nextFYStartMonth = fyStartMonth + 12;
		let firstMonthOfThisQuarter = nextFYStartMonth;
		// stop when month >= firstMonthOfThisQuarter
		while (month < firstMonthOfThisQuarter) {
			firstMonthOfThisQuarter -= 3;
		}
		const willWrapToPreviousYear = firstMonthOfThisQuarter < 1;
		const dateString = `${willWrapToPreviousYear ? year - 1 : year}-${
			willWrapToPreviousYear
				? 12 + firstMonthOfThisQuarter
				: `0${firstMonthOfThisQuarter}`.slice(-2)
		}-01T00:00:00Z`;
		return new Date(dateString);
	};

export const startOfUtcYear = (value: number): Date => {
	const date = new Date(value);
	const startOfYear = new Date(`${date.getUTCFullYear()}-01-01T00:00:00Z`);
	return startOfYear;
};

export const buildStartOfUtcYear =
	(fyStartMonth: number) =>
	(value: number): Date => {
		const date = new Date(value);
		const currentUtcYear = date.getUTCFullYear();
		const currentUtcMonth = date.getUTCMonth() + 1; // zero-based
		return new Date(
			`${
				currentUtcYear - (currentUtcMonth >= fyStartMonth ? 0 : 1)
			}-${`0${fyStartMonth}`.slice(-2)}-01T00:00:00Z`,
		);
	};

/*
This hack is needed to make certain date-fns functions work in Jira.

In Jira `Date.prototype.setFullYear()` is overridden by an old JS library called JSCal2 (http://www.dynarch.com/jscal/).
The overridden setFullYear does not pass all the arguments through to the original method. This causes some date-fn functions to break.

To fix this problem, we re-override JSCal2's setFullYear() function to handle all parameters properly. This ensures
that any Jira code that uses that library should continue working.
*/

export const fixSetFullYear = () => {
	if (Date.prototype.__msh_oldSetFullYear) {
		// Need to disable linting and flow because we're overwriting a native method
		/* eslint-disable no-extend-native */
		Date.prototype.setFullYear = function setFullYear(...args): number {
			const d = new Date(this);
			d.__msh_oldSetFullYear && d.__msh_oldSetFullYear.call(d, ...args);
			if (d.getMonth() !== this.getMonth()) this.setDate(28);
			return this.__msh_oldSetFullYear ? this.__msh_oldSetFullYear.call(this, ...args) : Number.NaN;
		};
	}
};

// Added this to fix HOT-112249 (SSR issue) - polyfill is not needed in SSR
export const fixSetFullYearPolyfill = () => {
	if (globalThis.document) {
		if (globalThis.document.readyState === 'loading') {
			// This happens in production. JSCal2 is loaded after Portfolio, so we need to execute our fix after DOMContentLoaded
			globalThis.document.addEventListener('DOMContentLoaded', fixSetFullYear);
		} else {
			// This happens in in development (i.e. `gulp watch`). JSCal2 is already loaded and DOMContentLoaded has already fired.
			// So we immediately execute the fix.
			fixSetFullYear();
		}
	}
};
fixSetFullYearPolyfill();

export const distanceInWordsWithPolarity = (
	fromDate: Date | number,
	toDate: Date | number,
	locale?: string,
): string => {
	const sign = new Date(fromDate).getTime() < new Date(toDate).getTime() ? '+' : '-';
	const distanceStr = formatDistance(toDate, fromDate, {
		locale: locale !== undefined ? getLocale(locale) : undefined,
	});
	return distanceStr
		.split(' ')
		.map((str) => (Number.isNaN(Number(str)) ? str : `${sign}${str}`))
		.join(' ');
};

// Internationalised
export const distanceInWordsToNowWithPolarity = (locale: string, date: Date | number): string =>
	distanceInWordsWithPolarity(Date.now(), date, locale);

export const endOfUtcWeek = (value: number): Date => {
	const startOfWeek = startOfUtcWeek(value);
	const endOfWeek = startOfWeek.getTime() + 7 * ONE_DAY - 1;
	return new Date(endOfWeek);
};

export const endOfUtcMonth = (value: number): Date => {
	const date = new Date(value);
	let year = date.getUTCFullYear();
	let month = date.getUTCMonth();

	month++;
	if (month > 11) {
		month = 0;
		year++;
	}

	const paddedMonth = padStart(`${month + 1}`, 2, '0'); // Add 1 to compensate for zero-index
	const firstDayOfNextYear = new Date(`${year}-${paddedMonth}-01T00:00:00.000Z`);
	const endOfYear = new Date(firstDayOfNextYear.getTime() - 1);
	return endOfYear;
};

export const endOfUtcQuarter = (value: number): Date => {
	const date = new Date(value);
	const year = date.getUTCFullYear();
	const UTCmonth = date.getUTCMonth();
	let month;
	let day = '31';
	if (UTCmonth >= 0 && UTCmonth <= 2) {
		month = '03';
	} else if (UTCmonth >= 3 && UTCmonth <= 5) {
		month = '06';
		day = '30';
	} else if (UTCmonth >= 6 && UTCmonth <= 8) {
		month = '09';
		day = '30';
	} else {
		month = '12';
	}

	const lastDayOfQuarter = new Date(`${year}-${month}-${day}T23:59:59.999Z`);
	return lastDayOfQuarter;
};

export const buildEndOfUtcQuarter =
	(fyStartMonth: number) =>
	(value: number): Date => {
		const date = new Date(value);
		const year = date.getUTCFullYear();
		const month = date.getUTCMonth() + 1; // UTC month starts from 0
		const nextFYStartMonth = fyStartMonth + 12;
		let firstMonthOfThisQuarter = nextFYStartMonth;
		while (month < firstMonthOfThisQuarter) {
			firstMonthOfThisQuarter -= 3;
		}
		const firstMonthOfNextQuarter = firstMonthOfThisQuarter + 3;
		const willWrapToNextYear = firstMonthOfNextQuarter > 12;
		if (willWrapToNextYear) {
			return new Date(
				new Date(`${year + 1}-0${firstMonthOfNextQuarter % 12}-01T00:00:00Z`).getTime() - 1,
			);
		}
		return new Date(
			new Date(`${year}-${`0${firstMonthOfNextQuarter}`.slice(-2)}-01T00:00:00Z`).getTime() - 1,
		);
	};

export const endOfUtcYear = (value: number): Date => {
	const date = new Date(value);
	const currentUtcYear = date.getUTCFullYear();
	return new Date(`${currentUtcYear}-12-31T23:59:59.999Z`);
};

export const buildEndOfUtcYear =
	(fyStartMonth: number) =>
	(value: number): Date => {
		const date = new Date(value);
		const currentUtcYear = date.getUTCFullYear();
		const currentUtcMonth = date.getUTCMonth() + 1; // zero-based
		return new Date(
			new Date(
				`${
					currentUtcYear + (currentUtcMonth >= fyStartMonth ? 1 : 0)
				}-${`0${fyStartMonth}`.slice(-2)}-01T00:00:00Z`,
			).getTime() - 1,
		);
	};

export const isSameUTCWeek = (startTime: number, endTime: number): boolean =>
	isEqual(startOfUtcWeek(startTime), startOfUtcWeek(endTime));

// every week we only work from 1/7/2019 00:00:00 - 6/7/2019 00:00:00
export const getWorkingStartAndEndTime = (
	value: number,
): {
	start: number;
	end: number;
} => {
	const startTimeOfUtcWeek = startOfUtcWeek(value).getTime();
	return {
		start: startTimeOfUtcWeek + ONE_DAY,
		end: startTimeOfUtcWeek + 6 * ONE_DAY,
	};
};

/**
 * Get the working million seconds between two dates, only ignore the weekend and don't take the holiday into account
 * @param startTime
 * @param endTime
 * @returns {number}
 */
export const getWorkingMillionSecondsBetweenTwoDates = (
	startTime: number,
	endTime: number,
): number => {
	const { start: startWorkingTimeOfStartTime, end: endWorkingTimeOfStartTime } =
		getWorkingStartAndEndTime(startTime);
	// if the startTime and endTime is in the same week
	if (isSameUTCWeek(startTime, endTime)) {
		if (endTime < startWorkingTimeOfStartTime || startTime > endWorkingTimeOfStartTime) {
			return 0;
		}
		return (
			Math.min(endWorkingTimeOfStartTime, endTime) -
			Math.max(startWorkingTimeOfStartTime, startTime)
		);
	}

	const { start: startWorkingTimeOfEndTime, end: endWorkingTimeOfEndTime } =
		getWorkingStartAndEndTime(endTime);

	const weeks = Math.floor((startWorkingTimeOfEndTime - endWorkingTimeOfStartTime) / ONE_WEEK);
	const workingMillionSecondsOfMiddleWeeks = weeks * (5 * ONE_DAY);

	const workingMillionSecondsOfFirstWeek =
		startTime > endWorkingTimeOfStartTime
			? 0
			: endWorkingTimeOfStartTime - Math.max(startTime, startWorkingTimeOfStartTime);

	const workingMillionSecondsOfLastWeek =
		endTime < startWorkingTimeOfEndTime
			? 0
			: Math.min(endTime, endWorkingTimeOfEndTime) - startWorkingTimeOfEndTime;

	return (
		workingMillionSecondsOfFirstWeek +
		workingMillionSecondsOfLastWeek +
		workingMillionSecondsOfMiddleWeeks
	);
};

/**
 * Unify the singular and plural english date unit to singular unit. like months -> month, days -> day, years -> year
 * @param unitStr
 * @returns
 */
const getUnifiedEnglishDateUnit = (unitStr: string) => {
	const regx = /((\w+)(?=s))|([^s]+)/g;
	const resultArr = unitStr.match(regx);
	if (Array.isArray(resultArr) && resultArr.length > 0) {
		return resultArr[0];
	}
	return unitStr;
};

export const convertShortFormatDate = (
	number: number,
	unit: string,
	localeStr: string | undefined,
) =>
	new Intl.NumberFormat(localeStr, { style: 'unit', unit, unitDisplay: 'narrow' }).format(number);

/**
 * This method is converting the distance time into context but in short format, like +4 days -> +4d, over +1 month to over +1m.
 * at the same time, we need to internalization this short format.
 * @param fromDate
 * @param toDate
 * @param locale
 * @returns
 */
export const distanceInWordsByShortFormat = (
	fromDate: Date | number,
	toDate: Date | number,
	localeStr: string,
) => {
	const sign = new Date(fromDate).getTime() < new Date(toDate).getTime() ? '+' : '-';
	// step1: get the distanceStr which is only english without locale
	const distanceStr = formatDistance(toDate, fromDate);
	// step2: split to array, the last element of this array is the unit, it can be day/days, 'year/years','month/months'
	const distanceStrArray = distanceStr.split(' ');
	const locale = getLocale(localeStr);
	if (distanceStrArray.length === 2) {
		// step3: unitfy the english unit day/days->day, 'year/years'->year,'month/months'->month
		const unit = getUnifiedEnglishDateUnit(distanceStrArray[1]);
		const number = distanceStrArray[0];
		// step4: callconvertShortFormatDate(Number(number), unit, locale.code) to i18n the dates. English unit is for the  new Intl.NumberFormat method
		return `${sign}${convertShortFormatDate(Number(number), unit, locale.code)}`;
	}
	if (distanceStrArray.length === 3) {
		// get the localised suffix which can be above/almost/over, refer to https://date-fns.org/v2.30.0/docs/formatDistance
		const suffix = formatDistance(toDate, fromDate, {
			locale,
		}).split(' ')[0];
		const number = distanceStrArray[1];
		const unit = getUnifiedEnglishDateUnit(distanceStrArray[2]);
		return `${suffix} ${sign}${convertShortFormatDate(Number(number), unit, locale.code)}`;
	}
	return distanceStr;
};
export const getRelativeDates = (
	{ toNowUnitCount = 0, fromNowUnitCount = 0, toNowUnit, fromNowUnit }: CustomDateRange,
	today: Date,
) => {
	const start =
		toNowUnit === DateUnits.WEEKS
			? subWeeks(today, toNowUnitCount)
			: subMonths(today, toNowUnitCount);
	const end =
		fromNowUnit === DateUnits.WEEKS
			? addWeeks(today, fromNowUnitCount)
			: addMonths(today, fromNowUnitCount);

	// relative time range should include current day
	// if relative time range is 0 before the current day amd 0 after the current day
	// then timeline should include at least 1 day
	if (toNowUnitCount === 0 && fromNowUnitCount === 0) {
		return {
			start: startOfUtcDay(start.getTime()),
			end: endOfUtcDay(end.getTime()),
		};
	}
	// if relative time range is any range before the current day and any range after the current day
	// or only before the current day then add the current day
	if ((toNowUnitCount && fromNowUnitCount) || toNowUnitCount) {
		return {
			start: startOfUtcDay(addDays(start, 1).getTime()),
			end: endOfUtcDay(end.getTime()),
		};
	}

	return {
		start: startOfUtcDay(start.getTime()),
		end: endOfUtcDay(subDays(end, 1).getTime()),
	};
};

export const getWeekStartDay = (locale: string) =>
	get(getLocale(locale), 'options.weekStartsOn', DEFAULT_WEEK_START_DAY);
