export const SET_RANKING_STATE =
	'state.ui.main.tabs.releases.project-releases.releases-table.SET_RANKING_STATE' as const;

export type SetRankingStateActionPayload = boolean;

export type SetRankingStateAction = {
	type: typeof SET_RANKING_STATE;
	payload: SetRankingStateActionPayload;
};

export const isReleasesRanking = (payload: boolean): SetRankingStateAction => ({
	type: SET_RANKING_STATE,
	payload,
});
