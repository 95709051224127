import {
	clear,
	change,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters/index.tsx';
import {
	type AttributeFilterValue,
	ASSIGNEE_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	onRequestChange: (payload: AttributeFilterValue) =>
		dispatch(change({ id: ASSIGNEE_FILTER_ID, value: payload })),

	onRequestClear: () => dispatch(clear(ASSIGNEE_FILTER_ID)),
});

export default mapDispatchToProps;
