import { fetchAssigneeList } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/assignees/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapStateToProps from './query.tsx';
import View from './view.tsx';

const mapDispatchToProps = {
	fetchAssigneeList,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
