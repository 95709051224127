import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	sortAscAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.field.sort.sort-asc-aria-label',
		defaultMessage: 'Sorted in ascending direction',
		description: 'Aria label to sort this colum in ascending direction.',
	},
	sortDescAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.field.sort.sort-desc-aria-label',
		defaultMessage: 'Sorted in descending direction',
		description: 'Aria label to sort this colum in descending direction.',
	},
});
