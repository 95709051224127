import * as R from 'ramda';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { Plan } from '../../state/domain/plan/types.tsx';
import type { RankActionPayload as RankReleasePayload } from '../../state/domain/versions/actions.tsx';
import type { NewVersion, VersionPatch } from '../../state/domain/versions/types.tsx';

export const urls = {
	add: '/rest/jpo/1.0/versions/add',
	get: '/rest/jpo/1.0/versions/get',
	update: '/rest/jpo/1.0/versions/update',
	rank: '/rest/jpo/1.0/versions/rank',
	delete: '/rest/jpo/1.0/versions/delete',
	revertChanges: '/rest/jpo/1.0/versions/revert',
} as const;

export type AddReleaseCommandPayload = {
	projectId: number;
	version: NewVersion;
};

export type UpdateReleaseCommandPayload = VersionPatch;

export const addVersionBody = (plan: Plan, { projectId, version }: AddReleaseCommandPayload) => {
	const { id: planId, currentScenarioId: scenarioId } = plan;
	const versionAttributes = ['name', 'description', 'start', 'delta', 'end'] as const;
	const versionValues = R.reduce<
		(typeof versionAttributes)[number],
		Record<(typeof versionAttributes)[number], { value?: NewVersion[keyof NewVersion] }>
	>(
		(acc, attr) => {
			acc[attr] = isDefined(version[attr]) ? { value: version[attr] } : {};
			return acc;
		},
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{} as Record<(typeof versionAttributes)[number], { value?: NewVersion[keyof NewVersion] }>,
		versionAttributes,
	);

	return {
		planId,
		scenarioId,
		projectId,
		description: {
			...versionValues,
			crossProjectVersion: {},
		},
	};
};

type Value = string | number | null;
type ValueObject = { value?: string | number | null };

const asObject = (value: Value): ValueObject => (isDefined(value) ? { value } : {});

export const updateVersionBody = (
	plan: Plan,
	{ versionId, patch }: UpdateReleaseCommandPayload,
) => {
	const { id: planId, currentScenarioId: scenarioId } = plan;

	const copyOfPatch: Record<string, Value> = { ...patch };
	const versionValues = R.map<Record<string, Value>, Record<string, ValueObject>>(
		asObject,
		copyOfPatch,
	);

	return {
		planId,
		scenarioId,
		itemKeys: [versionId],
		description: {
			...versionValues,
		},
	};
};

export const rankVersionBody = (plan: Plan, payload: RankReleasePayload) => {
	const { id: planId, currentScenarioId: scenarioId } = plan;
	const { id, anchor, relation } = payload;
	return {
		operations: [
			{
				anchor,
				itemKeys: [id],
				operationType: relation,
			},
		],
		planId,
		scenarioId,
	};
};

export const deleteVersionBody = (
	{ id: planId, currentScenarioId: scenarioId }: Plan,
	versionId: string,
) => ({
	itemKeys: [versionId],
	planId,
	scenarioId,
});
