export const TOGGLE = 'state.domain.view-settings.highlighted-versions.TOGGLE' as const;
export const RESET = 'state.domain.view-settings.highlighted-versions.RESET' as const;

export type ToggleAction = {
	type: typeof TOGGLE;
	versionId: string;
};

export type ResetAction = {
	type: typeof RESET;
	versionIds: string[];
};

export const toggle = (versionId: string) => ({
	type: TOGGLE,
	versionId,
});

export const reset = (versionIds: string[]) => ({
	type: RESET,
	versionIds,
});
