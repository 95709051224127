import { memo, useEffect, useRef } from 'react';

type Props = {
	changesCount: number;
	onCountChanged: (hasCountChanged: boolean) => void;
};

const SpotlightChangesCountMonitor = memo(({ changesCount, onCountChanged }: Props) => {
	const prevChangesCount = useRef<number>(changesCount);

	useEffect(() => {
		if (changesCount !== 0 && changesCount !== prevChangesCount.current) {
			onCountChanged(true);
		}
		prevChangesCount.current = changesCount;
	}, [changesCount, onCountChanged]);

	return null;
});

export default SpotlightChangesCountMonitor;
