import * as R from 'ramda';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-associated-issues/src/common/types.tsx';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import { SUB_TASK_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import {
	isConfluenceMacro,
	isEmbed,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import {
	groupBy,
	indexBy,
	isDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	CustomFieldTypes,
	GROUPING,
	UNDEFINED_KEY,
	type Grouping,
	COMPLETED_SPRINTS_GROUP,
	COMPLETED_RELEASES_GROUP,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	isRoadmapGroupedByCustomField,
	getCustomFieldIdFromCustomFieldGrouping,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import type { Person } from '../../state/domain/assignees/types.tsx';
import type { CustomField } from '../../state/domain/custom-fields/types.tsx';
import type { LazyGoalsByARI } from '../../state/domain/issue-goals/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { OriginalIssues } from '../../state/domain/original-issues/types.tsx';
import type { Project } from '../../state/domain/projects/types.tsx';
import type { Person as ReporterPerson } from '../../state/domain/reporters/types.tsx';
import type {
	Group,
	Scope,
	ScopeIssue,
	GroupCombination,
	ScopeIssuesWithoutParent,
	GroupedScope,
	GroupAttribute,
	GroupId,
} from '../../state/domain/scope/types.tsx';
import type { SelectOption } from '../../state/domain/select-options/types.tsx';
import type { Sprint } from '../../state/domain/sprints/types.tsx';
import type { Team } from '../../state/domain/teams/types.tsx';
import {
	type ComponentGroup,
	ALL_OTHER_ISSUES,
	type ComponentGroupsState as ComponentGroups,
} from '../../state/domain/view-settings/component-groups/types.tsx';

import type { CustomFieldValuesGroup } from '../../state/domain/view-settings/custom-field-values-groups/types.tsx';
import {
	HIERARCHY_RANGE_FILTER_ID,
	ASSIGNEE_FILTER_ID,
	REPORTER_FILTER_ID,
	COMPONENT_FILTER_ID,
	LABEL_FILTER_ID,
	PROJECT_FILTER_ID,
	RELEASE_FILTER_ID,
	SPRINT_FILTER_ID,
	TEAM_FILTER_ID,
	type ResolvedFilters,
} from '../../state/domain/view-settings/filters/types.tsx';
import type {
	LabelGroup,
	LabelGroupsState as LabelGroups,
} from '../../state/domain/view-settings/label-groups/types.tsx';
import type { GroupingExpandedItem } from '../../state/domain/view-settings/visualisations/types.tsx';
import type { State } from '../../state/types.tsx';
import type { InlineCreateState } from '../../state/ui/main/tabs/roadmap/scope/inline-create/types.tsx';
import { isOptimizedMode } from '../app/index.tsx';
import { getAssigneeList } from '../assignees/index.tsx';
import { getAssociatedIssues } from '../associated-issues/index.tsx';
import {
	getCustomFieldsById,
	getSelectOptionsById,
	getUserPickerOptionsUserListById,
} from '../custom-fields/index.tsx';
import { getFilters } from '../filters/index.tsx';
import { getInlineCreateState } from '../inline-create/index.tsx';
import { getLazyGoalsByARI } from '../issue-goals/index.tsx';
import {
	getFilteredIssuesWithHierarchy,
	getFilteredIssuesWithHierarchyById,
	getIssueFilterMatcherWithFilterId,
	getOriginalIssues,
} from '../issues/index.tsx';
import { getIssueSources } from '../plan/index.tsx';
import { getProjects } from '../projects/index.tsx';
import type { ProjectsById } from '../projects/types.tsx';
import { getIssues, getAllIssues, getIssueMapById } from '../raw-issues/index.tsx';
import { getSort, type SortIssues } from '../raw-issues/sort-utils.tsx';
import { getUniqueReportersOfIssues } from '../reporters/index.tsx';
import { getSprintsWithFutureDates } from '../sprints/common.tsx';
import type { SprintsWithFutureDates, TimelineSprint } from '../sprints/types.tsx';
import { getSprintByIdMapPure, getSprintsForTeamPure } from '../sprints/utils.tsx';
import { isAtlasConnectInstalled } from '../system/index.tsx';
import { getTeams, getAllTeamsById } from '../teams/index.tsx';
import { getVersionsById } from '../versions/index.tsx';
import type { VersionsById } from '../versions/types.tsx';
import {
	getVisualisationViewSettings,
	getComponentGroupsViewSettings as getComponentGroups,
	getLabelsGroupViewSettings as getLabelGroups,
	getIssueExpansionsViewSettings,
} from '../view-settings/index.tsx';
import {
	getCustomFieldValuesGroups,
	getVisualisationGrouping,
	isGroupByMultiValueCustomField,
} from '../visualisations/index.tsx';
import type {
	IssueWithOriginalsAndOptimized,
	UnMatchedFilters,
	IssuesById,
	GroupDetailsMap,
	IssuesGroup,
	IssuesByGroup,
	GroupDetails,
	GroupOption,
	IssueSearchResults,
	GroupingData,
} from './types.tsx';
import {
	getIssuesHierarchy,
	getTeamsBySprintGroups,
	getGroupKey,
	getReleasesGroups,
	addIssueToGroup,
	getAncestorReleasesGroups,
	getAncestorReleasesGroupsOld,
	getTeamsByAncestorSprintGroups,
	getTeamsByAncestorSprintGroupsOld,
} from './util.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { getGroupKey } from './util';

export const getSprints = (state: State) => state.domain.sprints;
export const getIsExpanded = (state: State) => getIssueExpansionsViewSettings(state).isExpanded;

export const getSprintByIdMap = createSelector([getSprints], getSprintByIdMapPure);

export const getSprintsForTeam = createSelector(
	[getTeams, getIssueSources, getSprints],
	getSprintsForTeamPure,
);

export const getSortedIssuesPureFunc = (issues: Issue[], sort: SortIssues): Issue[] => sort(issues);

export const getSortedIssuesPure = monitor.timeFunction(
	getSortedIssuesPureFunc,
	'query_scope_index_getSortedIssuesPure',
);

export const getSortedIssues = createSelector(
	[getFilteredIssuesWithHierarchy, getSort],
	getSortedIssuesPure,
);

export const getIssuesWithOriginalsAndOptimizedPure = (
	issues: Issue[],
	originalIssues: OriginalIssues,
): IssueWithOriginalsAndOptimized[] =>
	issues.map((issue) =>
		R.merge(issue, {
			originals: originalIssues[issue.id],
		}),
	);

export const getGroup = (
	issue: Issue,
	groupAttribute: string,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isOptimizedMode: boolean,
): string | null => {
	const group =
		(isOptimizedMode && isDefined(issue.optimized) && issue.optimized[groupAttribute]) ||
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		issue[groupAttribute as keyof typeof issue] ||
		(issue.customFields &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			isRoadmapGroupedByCustomField(groupAttribute as Grouping) &&
			issue.customFields[
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				getCustomFieldIdFromCustomFieldGrouping(groupAttribute as Grouping)
			]) ||
		null;
	return group;
};

const getCustomFieldValues = (issue: Issue, valueAttribute: string) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	if (isRoadmapGroupedByCustomField(valueAttribute as Grouping)) {
		const values =
			issue.customFields &&
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			issue.customFields[getCustomFieldIdFromCustomFieldGrouping(valueAttribute as Grouping)];
		return values?.map((value: string) =>
			Number.isNaN(parseInt(value, 10)) ? value : parseInt(value, 10),
		);
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return issue[valueAttribute as keyof typeof issue];
};

export const getGroupsInWhichIssueBelongsBySeveralValues = (
	issue: Issue,
	groups: Array<ComponentGroup | LabelGroup | CustomFieldValuesGroup>,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isOptimizedMode: boolean,
	valueAttribute: string,
): (string | null)[] => {
	const values =
		(isOptimizedMode && isDefined(issue.optimized) && issue.optimized[valueAttribute]) ||
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		issue[valueAttribute as keyof typeof issue] ||
		getCustomFieldValues(issue, valueAttribute);

	// If issue does not have values.
	if (!isDefined(values)) {
		return [ALL_OTHER_ISSUES];
	}

	/** Set is used as a data structure. This is important because an issue can have multiple components/labels.
	 * And all components/labels can be part of one group. groupsWhereIssueValuesBelong can have
	 * same group multiple times if different components/labels belong to the same group. Hence set is used.
	 * It will add the group only once.
	 */
	const groupsWhereIssueValuesBelong = new Set<
		ComponentGroup | LabelGroup | CustomFieldValuesGroup
	>();
	values.forEach((value: number) =>
		groups
			.filter((group) =>
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(group[valueAttribute as keyof typeof group] as unknown as Array<number>).includes(value),
			)
			.forEach((group) => groupsWhereIssueValuesBelong.add(group)),
	);

	// If issue has components/labels, but they are not part any component group.
	if (groupsWhereIssueValuesBelong.size === 0) {
		return [ALL_OTHER_ISSUES];
	}

	return [...groupsWhereIssueValuesBelong].map(({ id }) => id);
};

// Iterates through an issues' ancestors, and returns the all groups (if any)
// its ancestors belong to, or an empty array if none can be found
export const getGroupsInWhichAncestorBelongsBySeveralValues = (
	issue: Issue,
	groups: Array<ComponentGroup | LabelGroup | CustomFieldValuesGroup>,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isOptimizedMode: boolean,
	valueAttribute: string,
	issuesById: IssuesById,
): (string | null)[] => {
	let ancestorGroups: (string | null)[] = [];
	const ancestorsIds: Array<string> = [];
	let parent = issuesById[issue.parent || ''];

	while (parent) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		ancestorGroups = ancestorGroups.concat(
			getGroupsInWhichIssueBelongsBySeveralValues(parent, groups, isOptimizedMode, valueAttribute),
		);

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return ancestorGroups;
};

// Iterates through an issues' ancestors, and returns the groups (if any) the
// closest ancestor belongs to, or an empty array if none can be found
export const getGroupsInWhichAncestorBelongsBySeveralValuesOld = (
	issue: Issue,
	groups: Array<ComponentGroup | LabelGroup | CustomFieldValuesGroup>,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isOptimizedMode: boolean,
	valueAttribute: string,
	issuesById: IssuesById,
): (string | null)[] => {
	let ancestorGroups: (string | null)[] = [];
	const ancestorsIds: Array<string> = [];
	let parent = issuesById[issue.parent || ''];

	while (parent && !ancestorGroups.length) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		ancestorGroups = getGroupsInWhichIssueBelongsBySeveralValues(
			parent,
			groups,
			isOptimizedMode,
			valueAttribute,
		).filter((group) => group !== ALL_OTHER_ISSUES);

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return ancestorGroups;
};

// This has been added to be used as a key for groups of issues that have no value for their group attribute.
// It should be safe FOR THE TIME BEING because keys are typically numbers
export const UNDEFINED_GROUP = UNDEFINED_KEY;
export const UNASSIGNED_GROUP = 'unassigned';

export const getAncestorsOfIssue = (issue: Issue, issuesById: IssuesById): Issue[] => {
	const ancestors: Issue[] = [];
	const ancestorsIds: Array<string> = [];
	let parent = issuesById[issue.parent || ''];
	while (parent) {
		if (ancestorsIds.includes(parent.id)) {
			// prevent infinite loop
			break;
		}
		ancestorsIds.push(parent.id);
		ancestors.push(parent);
		parent = issuesById[parent.parent || ''];
	}

	return ancestors;
};

// Returns an array of all groups for parent issues for this groupAttribute (if any)
// or an empty array if there are no groups assigned to any parent for the given attribute
export const getAncestorGroups = (
	issue: Issue,
	groupAttribute: string,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isOptimizedMode: boolean,
	issuesById: IssuesById,
): (string | null)[] => {
	let ancestorGroups: (string | null)[] = [];
	const ancestorsIds: Array<string> = [];
	let parent = issuesById[issue.parent || ''];

	while (parent) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		ancestorGroups = ancestorGroups.concat(getGroup(parent, groupAttribute, isOptimizedMode));

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return ancestorGroups;
};

// Returns the group of the closest parent issue for this groupAttribute
// or null, if there is no group assigned to any parent for the given attribute
export const getAncestorGroup = (
	issue: Issue,
	groupAttribute: string,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isOptimizedMode: boolean,
	issuesById: IssuesById,
): string | null => {
	let group: string | null = null;
	const ancestorsIds: Array<string> = [];
	let parent = issuesById[issue.parent || ''];

	while (parent && (group === null || group === UNASSIGNED_GROUP)) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		group = getGroup(parent, groupAttribute, isOptimizedMode);

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return group;
};

// Iterates through an issues' ancestors, and returns all goals (if any)
// its ancestors belong to, or an empty array if none can be found
export const getAncestorGoals = (
	issue: Issue,
	issuesById: IssuesById,
	lazyGoalsByARI: LazyGoalsByARI,
): string[] => {
	let goals: string[] = [];
	const ancestorsIds: Array<string> = [];
	let parent = issuesById[issue.parent || ''];

	while (parent) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		goals = goals.concat(
			parent.goals?.filter((goal) => {
				const lazyGoal = lazyGoalsByARI[goal];
				return lazyGoal && !lazyGoal.isLoading;
			}) ?? [],
		);

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return goals;
};

// Iterates through an issues' ancestors, and returns the goals (if any) the
// closest ancestor belongs to, or an empty array if none can be found
export const getAncestorGoalsOld = (
	issue: Issue,
	issuesById: IssuesById,
	lazyGoalsByARI: LazyGoalsByARI,
): string[] => {
	let goals: string[] = [];
	const ancestorsIds: Array<string> = [];
	let parent = issuesById[issue.parent || ''];

	while (parent && !goals.length) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		goals =
			parent.goals?.filter((goal) => {
				const lazyGoal = lazyGoalsByARI[goal];
				return lazyGoal && !lazyGoal.isLoading;
			}) ?? [];

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return goals;
};

// Iterates through an issues' ancestors, and returns all Ideas (if any)
// its ancestors are associated with, or an empty array if none can be found
export const getAncestorIdeas = (
	issue: Issue,
	associatedIssues: Record<string, AssociatedIssue> | undefined,
	issuesById: IssuesById,
): string[] => {
	let ancestorIdeaIds: string[] = [];
	const ancestorsIds: Array<string> = [];
	let parent = issuesById[issue.parent || ''];

	while (parent) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		ancestorIdeaIds = ancestorIdeaIds.concat(
			parent.associatedIssueIds?.filter((id) => associatedIssues?.[id]) || [],
		);

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return ancestorIdeaIds;
};

// Iterates through an issues' ancestors, and returns the Ideas (if any) the
// closest ancestor is associated with, or an empty array if none can be found
export const getAncestorIdeasOld = (
	issue: Issue,
	associatedIssues: Record<string, AssociatedIssue> | undefined,
	issuesById: IssuesById,
): string[] => {
	let nonEmptyIdeasId: string[] | undefined = [];
	const ancestorsIds: Array<string> = [];
	let parent = issuesById[issue.parent || ''];

	while (parent && !nonEmptyIdeasId?.length) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		nonEmptyIdeasId = parent.associatedIssueIds?.filter((id) => associatedIssues?.[id]);

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return nonEmptyIdeasId?.length ? nonEmptyIdeasId : [];
};

export const getGroupAttribute = (group: Grouping): GroupAttribute => {
	if (isRoadmapGroupedByCustomField(group)) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return group as GroupId;
	}

	switch (group) {
		case GROUPING.ASSIGNEE:
			return 'assignee';
		case GROUPING.REPORTER:
			return 'reporter';
		case GROUPING.COMPONENT:
			return 'components';
		case GROUPING.LABEL:
			return 'labels';
		case GROUPING.PROJECT:
			return 'project';
		case GROUPING.RELEASE:
			return 'release';
		case GROUPING.SPRINT:
			return 'sprint';
		case GROUPING.TEAM:
			return 'team';
		case GROUPING.GOALS:
			return 'goals';
		case GROUPING.IDEAS:
			return 'ideas';
		default:
			throw new Error(`Grouping by ${group} is not supported yet.`);
	}
};

export const addIssueAndItsAncestorsToGroup: (
	group: IssuesGroup,
	isOptimizedMode: boolean,
	issue: Issue,
	issuesByGroup: IssuesByGroup,
	issuesById: {
		[key: string]: Issue;
	},
	isAncestorGroupMatchCustom?: (
		groupAttrValue: string | number | null,
		ancestor: Issue,
		isOptimizedMode: boolean,
	) => boolean,
) => IssuesByGroup = (
	group,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isOptimizedMode,
	issue,
	issuesByGroup,
	issuesById,
	isAncestorGroupMatchCustom,
) => {
	let copiedIssuesByGroup = { ...issuesByGroup };
	copiedIssuesByGroup = addIssueToGroup(copiedIssuesByGroup, issue, group);

	// Here we may add issues that we have already skipped (as it did not fit into a group), but we add them as they are the parent of the issue we have just added.
	// Thus we can't preserve the initial sorting of the issues.
	getAncestorsOfIssue(issue, issuesById).forEach((ancestor) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const isAncestorGroupMatch = (Object.keys(group) as Array<keyof IssuesGroup>).every(
			(groupAttr) =>
				isDefined(isAncestorGroupMatchCustom)
					? isAncestorGroupMatchCustom(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							group[groupAttr] as unknown as string | number | null,
							ancestor,
							isOptimizedMode,
						)
					: group[groupAttr] === getGroup(ancestor, groupAttr, isOptimizedMode),
		);
		if (!isAncestorGroupMatch) {
			// NOTE: Intentionally adding ancestor to original issue group (we want the ancestor to appear for the group)
			copiedIssuesByGroup = addIssueToGroup(copiedIssuesByGroup, ancestor, group);
		}
	});

	return copiedIssuesByGroup;
};

export const getIssuesByGroupPure = (
	issues: Issue[],
	issuesById: IssuesById,
	grouping: Grouping = GROUPING.NONE,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isOptimizedMode: boolean,
	{
		componentGroups,
		labelGroups,
		teamsById,
		sprintsByIdMap,
		sprintsForTeam,
		versionsById,
		lazyGoalsByARI,
		customFieldValuesGroups = [],
		// eslint-disable-next-line @typescript-eslint/no-shadow
		isGroupByMultiValueCustomField = false,
		associatedIssues,
	}: GroupingData,
	isEmbedMode: boolean,
	isConfluenceMacroMode: boolean,
): IssuesByGroup => {
	let issuesByGroup: IssuesByGroup = {};
	const groupAttribute = grouping !== GROUPING.NONE ? getGroupAttribute(grouping) : '';
	const isEmbedOrMacro = isEmbedMode || isConfluenceMacroMode;

	switch (grouping) {
		case GROUPING.COMPONENT:
			issues.forEach((issue) => {
				// We add each issue to the component groups of ALL parent issues
				if (fg('plans_group_by_to_show_all_children')) {
					const ancestorGroups = getGroupsInWhichAncestorBelongsBySeveralValues(
						issue,
						componentGroups,
						isOptimizedMode,
						groupAttribute,
						issuesById,
					);

					ancestorGroups.forEach((ancestorGroup) => {
						issuesByGroup = addIssueAndItsAncestorsToGroup(
							{
								[`${groupAttribute}`]: ancestorGroup,
							},
							isOptimizedMode,
							issue,
							issuesByGroup,
							issuesById,
						);
					});
				}

				getGroupsInWhichIssueBelongsBySeveralValues(
					issue,
					componentGroups,
					isOptimizedMode,
					groupAttribute,
				).forEach((group) => {
					// If our issue is not assigned to a component group, we attempt to add it to the closest
					// ancestor component groups, before also adding it to the 'All Other Issues' group
					if (group === ALL_OTHER_ISSUES && !fg('plans_group_by_to_show_all_children')) {
						const ancestorGroups = getGroupsInWhichAncestorBelongsBySeveralValuesOld(
							issue,
							componentGroups,
							isOptimizedMode,
							groupAttribute,
							issuesById,
						);

						ancestorGroups.forEach((ancestorGroup) => {
							issuesByGroup = addIssueAndItsAncestorsToGroup(
								{
									[`${groupAttribute}`]: ancestorGroup,
								},
								isOptimizedMode,
								issue,
								issuesByGroup,
								issuesById,
							);
						});
					}

					issuesByGroup = addIssueAndItsAncestorsToGroup(
						{ [`${groupAttribute}`]: group },
						isOptimizedMode,
						issue,
						issuesByGroup,
						issuesById,
					);
				});
			});
			break;

		case GROUPING.LABEL:
			issues.forEach((issue) => {
				// We add each issue to the label groups of ALL parent issues
				if (fg('plans_group_by_to_show_all_children')) {
					const ancestorGroups = getGroupsInWhichAncestorBelongsBySeveralValues(
						issue,
						labelGroups,
						isOptimizedMode,
						groupAttribute,
						issuesById,
					);

					ancestorGroups.forEach((ancestorGroup) => {
						issuesByGroup = addIssueAndItsAncestorsToGroup(
							{
								[`${groupAttribute}`]: ancestorGroup,
							},
							isOptimizedMode,
							issue,
							issuesByGroup,
							issuesById,
						);
					});
				}

				getGroupsInWhichIssueBelongsBySeveralValues(
					issue,
					labelGroups,
					isOptimizedMode,
					groupAttribute,
				).forEach((group) => {
					// If our issue is not assigned to a label group, we attempt to add it to the
					// closest ancestor label groups, before also adding it to the 'All Other Issues' group
					if (group === ALL_OTHER_ISSUES && !fg('plans_group_by_to_show_all_children')) {
						const ancestorGroups = getGroupsInWhichAncestorBelongsBySeveralValuesOld(
							issue,
							labelGroups,
							isOptimizedMode,
							groupAttribute,
							issuesById,
						);

						ancestorGroups.forEach((ancestorGroup) => {
							issuesByGroup = addIssueAndItsAncestorsToGroup(
								{
									[`${groupAttribute}`]: ancestorGroup,
								},
								isOptimizedMode,
								issue,
								issuesByGroup,
								issuesById,
							);
						});
					}

					issuesByGroup = addIssueAndItsAncestorsToGroup(
						{ [`${groupAttribute}`]: group },
						isOptimizedMode,
						issue,
						issuesByGroup,
						issuesById,
					);
				});
			});
			break;

		case GROUPING.GOALS:
			issues.forEach((issue) => {
				// We add each issue to the goals groups of ALL parent issues
				if (fg('plans_group_by_to_show_all_children')) {
					const ancestorGoals = getAncestorGoals(issue, issuesById, lazyGoalsByARI);

					ancestorGoals.forEach((ancestorGoal) => {
						issuesByGroup = addIssueAndItsAncestorsToGroup(
							{
								[`${groupAttribute}`]: ancestorGoal,
							},
							isOptimizedMode,
							issue,
							issuesByGroup,
							issuesById,
						);
					});
				}
				const loadedGoals =
					issue.goals?.filter((goal) => {
						const lazyGoal = lazyGoalsByARI[goal];
						return lazyGoal && !lazyGoal.isLoading;
					}) ?? [];
				const goals = !loadedGoals.length ? [UNDEFINED_GROUP] : loadedGoals;
				goals.forEach((goal) => {
					// If our issue is not assigned to a goal, we attempt to add it to the
					// closest ancestor goal groups, before also adding it to the undefined group
					if (goal === UNDEFINED_GROUP && !fg('plans_group_by_to_show_all_children')) {
						const ancestorGoals = getAncestorGoalsOld(issue, issuesById, lazyGoalsByARI);
						ancestorGoals.forEach((ancestorGoal) => {
							issuesByGroup = addIssueAndItsAncestorsToGroup(
								{
									[`${groupAttribute}`]: ancestorGoal,
								},
								isOptimizedMode,
								issue,
								issuesByGroup,
								issuesById,
							);
						});
					}

					issuesByGroup = addIssueAndItsAncestorsToGroup(
						{ [`${groupAttribute}`]: goal },
						isOptimizedMode,
						issue,
						issuesByGroup,
						issuesById,
					);
				});
			});
			break;

		case GROUPING.RELEASE:
			issues.forEach((issue) => {
				// We add each issue to the release groups of ALL parent issues
				if (fg('plans_group_by_to_show_all_children')) {
					const ancestorGroups = getAncestorReleasesGroups(
						issue,
						isOptimizedMode,
						versionsById,
						issuesById,
					);

					ancestorGroups.forEach((ancestorGroup) => {
						issuesByGroup = addIssueAndItsAncestorsToGroup(
							ancestorGroup,
							isOptimizedMode,
							issue,
							issuesByGroup,
							issuesById,
						);
					});
				}

				getReleasesGroups(issue, isOptimizedMode, versionsById).forEach((group) => {
					// If our issue is not assigned to a release group, we attempt to add it to the
					// closest ancestor release groups, before also adding it to the null group
					if (group.release === null && !fg('plans_group_by_to_show_all_children')) {
						const ancestorGroups = getAncestorReleasesGroupsOld(
							issue,
							isOptimizedMode,
							versionsById,
							issuesById,
						);
						ancestorGroups.forEach((ancestorGroup) => {
							issuesByGroup = addIssueAndItsAncestorsToGroup(
								ancestorGroup,
								isOptimizedMode,
								issue,
								issuesByGroup,
								issuesById,
							);
						});
					}

					issuesByGroup = addIssueAndItsAncestorsToGroup(
						group,
						isOptimizedMode,
						issue,
						issuesByGroup,
						issuesById,
						// eslint-disable-next-line @typescript-eslint/no-shadow
						(groupAttrValue, ancestor, isOptimizedMode) => {
							const fixVersions =
								getGroup(ancestor, 'fixVersions', isOptimizedMode) || // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								([] as Array<string | number>);

							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							return fixVersions.includes(groupAttrValue as unknown as string);
						},
					);
				});
			});
			break;

		case GROUPING.SPRINT:
			issues.forEach((issue) => {
				// We add each issue to the sprint groups of ALL parent issues
				if (fg('plans_group_by_to_show_all_children')) {
					const ancestorGroups = getTeamsByAncestorSprintGroups(
						issue,
						isOptimizedMode,
						sprintsByIdMap,
						sprintsForTeam,
						teamsById,
						issuesById,
					);

					ancestorGroups.forEach((ancestorGroup) => {
						issuesByGroup = addIssueAndItsAncestorsToGroup(
							ancestorGroup,
							isOptimizedMode,
							issue,
							issuesByGroup,
							issuesById,
						);
					});
				}

				getTeamsBySprintGroups(
					issue,
					isOptimizedMode,
					sprintsByIdMap,
					sprintsForTeam,
					teamsById,
				).forEach((group) => {
					// If our issue is not assigned to a sprint group, we attempt to add it to the
					// closest ancestor sprint groups, before also adding it to the null group
					if (group.sprint === null && !fg('plans_group_by_to_show_all_children')) {
						const ancestorGroups = getTeamsByAncestorSprintGroupsOld(
							issue,
							isOptimizedMode,
							sprintsByIdMap,
							sprintsForTeam,
							teamsById,
							issuesById,
						);
						ancestorGroups.forEach((ancestorGroup) => {
							issuesByGroup = addIssueAndItsAncestorsToGroup(
								ancestorGroup,
								isOptimizedMode,
								issue,
								issuesByGroup,
								issuesById,
							);
						});
					}

					issuesByGroup = addIssueAndItsAncestorsToGroup(
						group,
						isOptimizedMode,
						issue,
						issuesByGroup,
						issuesById,
					);
				});
			});
			break;

		case GROUPING.TEAM:
			issues.forEach((issue) => {
				// We add each issue to the team groups of ALL parent issues
				if (fg('plans_group_by_to_show_all_children')) {
					const ancestorGroups = getAncestorGroups(
						issue,
						groupAttribute,
						isOptimizedMode,
						issuesById,
					);

					ancestorGroups.forEach((ancestorGroup) => {
						issuesByGroup = addIssueAndItsAncestorsToGroup(
							{
								[`${groupAttribute}`]: ancestorGroup,
							},
							isOptimizedMode,
							issue,
							issuesByGroup,
							issuesById,
						);
					});
				}

				if (issue.level === SUB_TASK_LEVEL) {
					const parentId = issue.parent;
					if (isDefined(parentId)) {
						const parentIssue = issuesById[parentId];
						if (parentIssue) {
							const group = getGroup(issue, groupAttribute, isOptimizedMode);
							// If our subtask is not assigned to a team, we attempt to add it to the
							// closest ancestor team, before also adding it to the null group
							if (
								(group === null || group === UNASSIGNED_GROUP) &&
								!fg('plans_group_by_to_show_all_children')
							) {
								const ancestorGroup = getAncestorGroup(
									issue,
									groupAttribute,
									isOptimizedMode,
									issuesById,
								);
								if (ancestorGroup !== null && ancestorGroup !== UNASSIGNED_GROUP) {
									issuesByGroup = addIssueAndItsAncestorsToGroup(
										{
											[`${groupAttribute}`]: ancestorGroup,
										},
										isOptimizedMode,
										issue,
										issuesByGroup,
										issuesById,
									);
								}
							}

							issuesByGroup = addIssueToGroup(issuesByGroup, issue, {
								[`${groupAttribute}`]: group,
							});
						}
					} else {
						// Sub-tasks whose parent aren't included in the plan also needed to be listed
						issuesByGroup = addIssueToGroup(issuesByGroup, issue, {
							[`${groupAttribute}`]: getGroup(issue, groupAttribute, isOptimizedMode),
						});
					}
				} else {
					const group = getGroup(issue, groupAttribute, isOptimizedMode);
					// If our issue is not assigned to a team, we attempt to add it to the
					// closest ancestor team, before also adding it to the null group
					if (
						(group === null || group === UNASSIGNED_GROUP) &&
						!fg('plans_group_by_to_show_all_children')
					) {
						const ancestorGroup = getAncestorGroup(
							issue,
							groupAttribute,
							isOptimizedMode,
							issuesById,
						);
						if (ancestorGroup !== null && ancestorGroup !== UNASSIGNED_GROUP) {
							issuesByGroup = addIssueAndItsAncestorsToGroup(
								{
									[`${groupAttribute}`]: ancestorGroup,
								},
								isOptimizedMode,
								issue,
								issuesByGroup,
								issuesById,
							);
						}
					}

					issuesByGroup = addIssueAndItsAncestorsToGroup(
						{
							[`${groupAttribute}`]: group,
						},
						isOptimizedMode,
						issue,
						issuesByGroup,
						issuesById,
					);
				}
			});
			break;
		case isRoadmapGroupedByCustomField(grouping) && grouping: {
			if (isGroupByMultiValueCustomField) {
				issues.forEach((issue) => {
					// We add each issue to the multi-value custom field groups of ALL parent issues
					if (fg('plans_group_by_to_show_all_children')) {
						const ancestorGroups = getGroupsInWhichAncestorBelongsBySeveralValues(
							issue,
							customFieldValuesGroups,
							isOptimizedMode,
							groupAttribute,
							issuesById,
						);

						ancestorGroups.forEach((ancestorGroup) => {
							issuesByGroup = addIssueAndItsAncestorsToGroup(
								{
									[`${groupAttribute}`]: ancestorGroup,
								},
								isOptimizedMode,
								issue,
								issuesByGroup,
								issuesById,
							);
						});
					}

					getGroupsInWhichIssueBelongsBySeveralValues(
						issue,
						customFieldValuesGroups,
						isOptimizedMode,
						groupAttribute,
					).forEach((group) => {
						// If our issue is not assigned to a custom field group, we attempt to add it to the closest
						// ancestor custom field groups, before also adding it to the 'All Other Issues' group
						if (group === ALL_OTHER_ISSUES && !fg('plans_group_by_to_show_all_children')) {
							const ancestorGroups = getGroupsInWhichAncestorBelongsBySeveralValuesOld(
								issue,
								customFieldValuesGroups,
								isOptimizedMode,
								groupAttribute,
								issuesById,
							);

							ancestorGroups.forEach((ancestorGroup) => {
								issuesByGroup = addIssueAndItsAncestorsToGroup(
									{
										[`${groupAttribute}`]: ancestorGroup,
									},
									isOptimizedMode,
									issue,
									issuesByGroup,
									issuesById,
								);
							});
						}

						issuesByGroup = addIssueAndItsAncestorsToGroup(
							{ [`${groupAttribute}`]: group },
							isOptimizedMode,
							issue,
							issuesByGroup,
							issuesById,
						);
					});
				});
			} else {
				issues.forEach((issue) => {
					// We add each issue to the custom field groups of ALL parent issues
					if (fg('plans_group_by_to_show_all_children')) {
						const ancestorGroups = getAncestorGroups(
							issue,
							groupAttribute,
							isOptimizedMode,
							issuesById,
						);

						ancestorGroups.forEach((ancestorGroup) => {
							issuesByGroup = addIssueAndItsAncestorsToGroup(
								{
									[`${groupAttribute}`]: ancestorGroup,
								},
								isOptimizedMode,
								issue,
								issuesByGroup,
								issuesById,
							);
						});
					}

					const group = getGroup(issue, groupAttribute, isOptimizedMode);
					// If our issue is not assigned to a custom field group, we attempt to add it to the closest
					// ancestor custom field group, before also adding it to the unassigned group
					if (
						(group === null || group === UNASSIGNED_GROUP) &&
						!fg('plans_group_by_to_show_all_children')
					) {
						const ancestorGroup = getAncestorGroup(
							issue,
							groupAttribute,
							isOptimizedMode,
							issuesById,
						);
						if (ancestorGroup !== null && ancestorGroup !== UNASSIGNED_GROUP) {
							issuesByGroup = addIssueAndItsAncestorsToGroup(
								{
									[`${groupAttribute}`]: ancestorGroup,
								},
								isOptimizedMode,
								issue,
								issuesByGroup,
								issuesById,
							);
						}
					}

					issuesByGroup = addIssueAndItsAncestorsToGroup(
						{ [`${groupAttribute}`]: group },
						isOptimizedMode,
						issue,
						issuesByGroup,
						issuesById,
					);
				});
			}
			break;
		}
		case GROUPING.IDEAS:
			if (ff('polaris-arj-eap-override') && !isEmbedOrMacro) {
				issues.forEach((issue) => {
					// We add each issue to the ideas groups of ALL parent issues
					if (fg('plans_group_by_to_show_all_children')) {
						const ancestorIdeas = getAncestorIdeas(issue, associatedIssues, issuesById);
						ancestorIdeas.forEach((ancestorIdea) => {
							issuesByGroup = addIssueAndItsAncestorsToGroup(
								{
									[`${groupAttribute}`]: ancestorIdea,
								},
								isOptimizedMode,
								issue,
								issuesByGroup,
								issuesById,
							);
						});
					}

					const nonEmptyIdeasId = issue.associatedIssueIds?.filter(
						(id) => associatedIssues && associatedIssues[id],
					);

					const ideaIds = !nonEmptyIdeasId?.length ? [UNDEFINED_GROUP] : nonEmptyIdeasId;
					// If our issue is not assigned to an Idea, we attempt to add it to the closest
					// ancestor Ideas, before also adding it to the UNDEFINED_GROUP
					if (!nonEmptyIdeasId?.length && !fg('plans_group_by_to_show_all_children')) {
						const ancestorIdeas = getAncestorIdeasOld(issue, associatedIssues, issuesById);
						ancestorIdeas.forEach((ancestorIdea) => {
							issuesByGroup = addIssueAndItsAncestorsToGroup(
								{
									[`${groupAttribute}`]: ancestorIdea,
								},
								isOptimizedMode,
								issue,
								issuesByGroup,
								issuesById,
							);
						});
					}

					ideaIds.forEach((ideaId) => {
						issuesByGroup = addIssueAndItsAncestorsToGroup(
							{ [`${groupAttribute}`]: ideaId },
							isOptimizedMode,
							issue,
							issuesByGroup,
							issuesById,
						);
					});
				});
			} else {
				issues.forEach((issue) => {
					issuesByGroup = addIssueAndItsAncestorsToGroup(
						{ [`${groupAttribute}`]: getGroup(issue, groupAttribute, isOptimizedMode) },
						isOptimizedMode,
						issue,
						issuesByGroup,
						issuesById,
					);
				});
			}
			break;

		// grouping by "Assignee", "Project"
		default:
			issues.forEach((issue) => {
				// We add each issue to the groups of ALL parent issues associated with this groupAttribute
				if (fg('plans_group_by_to_show_all_children')) {
					const ancestorGroups = getAncestorGroups(
						issue,
						groupAttribute,
						isOptimizedMode,
						issuesById,
					);

					ancestorGroups.forEach((ancestorGroup) => {
						issuesByGroup = addIssueAndItsAncestorsToGroup(
							{
								[`${groupAttribute}`]: ancestorGroup,
							},
							isOptimizedMode,
							issue,
							issuesByGroup,
							issuesById,
						);
					});
				}

				const group = getGroup(issue, groupAttribute, isOptimizedMode);
				// If our issue is unassigned for this group, we attempt to add it to the
				// closest ancestor group, before also adding it to the unassigned group
				if (
					(group === null || group === UNASSIGNED_GROUP) &&
					!fg('plans_group_by_to_show_all_children')
				) {
					const ancestorGroup = getAncestorGroup(
						issue,
						groupAttribute,
						isOptimizedMode,
						issuesById,
					);
					if (ancestorGroup !== null && ancestorGroup !== UNASSIGNED_GROUP) {
						issuesByGroup = addIssueAndItsAncestorsToGroup(
							{
								[`${groupAttribute}`]: ancestorGroup,
							},
							isOptimizedMode,
							issue,
							issuesByGroup,
							issuesById,
						);
					}
				}

				issuesByGroup = addIssueAndItsAncestorsToGroup(
					{ [`${groupAttribute}`]: group },
					isOptimizedMode,
					issue,
					issuesByGroup,
					issuesById,
				);
			});
			break;
	}

	return issuesByGroup;
};

// Get issues by group without providing an issue by id map
export const getIssuesByGroupWithoutMapPure = (
	issues: Issue[],
	grouping: Grouping = GROUPING.NONE,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isOptimizedMode: boolean,
	groupingData: GroupingData,
	isEmbedMode: boolean,
	isConfluenceMacroMode: boolean,
): IssuesByGroup => {
	const issuesById = indexBy(({ id }) => id, issues);
	return getIssuesByGroupPure(
		issues,
		issuesById,
		grouping,
		isOptimizedMode,
		groupingData,
		isEmbedMode,
		isConfluenceMacroMode,
	);
};

const getGroupingData = createStructuredSelector<State, GroupingData>({
	componentGroups: getComponentGroups,
	labelGroups: getLabelGroups,
	sprintsByIdMap: getSprintByIdMap,
	sprintsForTeam: getSprintsForTeam,
	teamsById: getAllTeamsById,
	versionsById: getVersionsById,
	lazyGoalsByARI: getLazyGoalsByARI,
	customFieldValuesGroups: getCustomFieldValuesGroups,
	isGroupByMultiValueCustomField,
	associatedIssues: getAssociatedIssues,
});

export const getIssuesByGroup = createSelector(
	[
		getFilteredIssuesWithHierarchy,
		getIssueMapById,
		getVisualisationGrouping,
		isOptimizedMode,
		getGroupingData,
		isEmbed,
		isConfluenceMacro,
	],
	getIssuesByGroupPure,
);

export const getAllIssuesByGroup = createSelector(
	[
		getAllIssues,
		getIssueMapById,
		getVisualisationGrouping,
		isOptimizedMode,
		getGroupingData,
		isEmbed,
		isConfluenceMacro,
	],
	getIssuesByGroupPure,
);

export const buildScope = (
	issues: Issue[],
	issuesByParent: {
		[key: string]: Issue[];
	},
	issuesById: IssuesById,
	isExpanded: {
		[key: string]: boolean;
	},
	start: number,
	end: number,
	group?: string | null,
	groupCombination?: GroupCombination,
	issuesInGroup?: Issue[],
	ignoreExpandingState?: boolean,
): Scope<ScopeIssue> => {
	const rootIssues = issues.filter(({ level }) => level === start);

	let issuesInGroupMap;

	if (issuesInGroup) {
		issuesInGroupMap = indexBy(R.prop('id'), issuesInGroup);
	}

	const startLevelIssues = {
		rootIssuesCount: rootIssues.length,
		issues: getIssuesHierarchy({
			issues: rootIssues,
			issuesByParent,
			isExpanded,
			group,
			groupCombination,
			issuesInGroupMap,
			ignoreExpandingState,
		}),
		level: start,
	};

	const issuesWithoutParent = issues.filter(
		({ level, parent }) => level < start && !(parent && issuesById[parent]),
	);
	const issuesWithoutParentByLevel = groupBy(({ level }) => level, issuesWithoutParent);

	const issuesWithoutParentFlattenForest: Array<ScopeIssuesWithoutParent<ScopeIssue>> = [];
	const headerIdSuffix = groupCombination ? `:${getGroupKey(groupCombination)}` : '';

	const headerId = `IssuesWithoutParentHeader${headerIdSuffix}`;
	for (const level of R.range(end, start).reverse()) {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const rootIssues = issuesWithoutParentByLevel[level];
		const idSuffix = groupCombination ? `:${getGroupKey(groupCombination)}` : '';
		const id = `IssuesWithoutParent-${level}${idSuffix}`;

		if (rootIssues) {
			issuesWithoutParentFlattenForest.push({
				id,
				isExpanded: isExpanded[id],
				issues: getIssuesHierarchy({
					issues: rootIssues,
					issuesByParent,
					isExpanded,
					group,
					groupCombination,
					issuesInGroupMap,
					ignoreExpandingState,
				}),
				level,
				rootIssuesCount: rootIssues.length,
			});
		} else {
			issuesWithoutParentFlattenForest.push({
				id,
				isExpanded: isExpanded[id],
				issues: [],
				level,
				rootIssuesCount: 0,
			});
		}
	}
	return {
		startLevelIssues,
		issuesWithoutParent: issuesWithoutParentFlattenForest,
		issuesWithoutParentHeaderData: {
			id: headerId,
			isExpanded: isExpanded[headerId],
		},
	};
};

// Because we can't localise unknown groups here, we just return an empty string for unknown group names as it is the
// safest way of identifying a group with no name
export const UNKNOWN_GROUP_NAME = '';

const getExpandedGroups = (state: State) => getVisualisationViewSettings(state).expandedItems;

export const isGroupExpanded = (
	grouping: Grouping,
	groupName: string,
	expandedState?: Partial<Record<Grouping, Record<string, boolean>> | null>,
) => expandedState?.[grouping]?.[groupName];

export const getGroupDetails = (
	componentGroups: ComponentGroups = [],
	labelGroups: LabelGroups = [],
	teamsById: {
		[key: string]: Team;
	} = {},
	projects: Project[] = [],
	assignees: Person[] = [],
	reporters: ReporterPerson[] = [],
	sprintsByIdMap: {
		[key: string]: Sprint;
	} = {},
	grouping: Grouping = GROUPING.TEAM,
	groupId: string | null | undefined | number,
	versionsById: VersionsById,
	lazyGoalsByARI: LazyGoalsByARI,
	associatedIssues?: Record<string, AssociatedIssue>,
): GroupDetails => {
	// Project id is a number. We have to convert it to string for all other types.
	// in case GROUPING.PROJECT we are using parseInt method which converts it back to number
	const group = groupId && groupId.toString();
	if (!group || group === UNDEFINED_GROUP) {
		return {
			name: UNKNOWN_GROUP_NAME,
			grouping,
			group: UNDEFINED_GROUP,
		};
	}

	switch (grouping) {
		case GROUPING.COMPONENT: {
			const componentGroup = componentGroups.find(({ id }) => id === group);
			const groupName = (isDefined(componentGroup) && componentGroup.name) || UNKNOWN_GROUP_NAME;

			return {
				name: groupName,
				group,
				grouping,
			};
		}
		case GROUPING.LABEL: {
			const labelGroup = labelGroups.find(({ id }) => id === group);
			const groupName = (isDefined(labelGroup) && labelGroup.name) || UNKNOWN_GROUP_NAME;

			return {
				name: groupName,
				group,
				grouping,
			};
		}
		case GROUPING.TEAM: {
			const team = teamsById[group];
			const teamName = (isDefined(team) && team.title) || UNKNOWN_GROUP_NAME;

			return {
				name: teamName,
				group,
				grouping,
				url: team && team.avatarUrl,
			};
		}
		case GROUPING.ASSIGNEE: {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const assignee = assignees.find((assignee) => assignee.jiraUser.accountId === group);
			const assigneeName = (isDefined(assignee) && assignee.jiraUser.title) || UNKNOWN_GROUP_NAME;

			return {
				name: assigneeName,
				group,
				grouping,
				url: assignee && assignee.jiraUser.avatarUrl,
			};
		}
		case GROUPING.REPORTER: {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const reporter = reporters.find((reporter) => reporter.jiraUser.accountId === group);
			const reporterName = (isDefined(reporter) && reporter.jiraUser.title) || UNKNOWN_GROUP_NAME;

			return {
				name: reporterName,
				group,
				grouping,
				url: reporter && reporter.jiraUser.avatarUrl,
			};
		}
		case GROUPING.PROJECT: {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const project = projects.find((project) => project.id === parseInt(group, 10));
			const projectName = (isDefined(project) && project.name) || UNKNOWN_GROUP_NAME;

			return {
				name: projectName,
				group,
				grouping,
				url: project && project.avatarUrl,
			};
		}
		case GROUPING.SPRINT: {
			return {
				name: sprintsByIdMap[group].title || UNKNOWN_GROUP_NAME,
				group,
				grouping,
			};
		}
		case GROUPING.RELEASE: {
			const release = versionsById[group];
			return {
				name: release ? release.name : UNKNOWN_GROUP_NAME,
				group,
				grouping,
			};
		}
		case GROUPING.GOALS: {
			const goal = lazyGoalsByARI[group];
			const name = goal && !goal.isLoading && goal.goal ? goal.goal.name : group;

			return {
				name,
				group,
				grouping,
			};
		}
		case GROUPING.IDEAS: {
			if (ff('polaris.possibility-of-updating-ideas-in-plans')) {
				const idea = associatedIssues && associatedIssues[group];
				const name = idea?.summary ? idea.summary : group;

				return {
					name,
					group,
					grouping,
				};
			}

			return {
				name: group,
				group,
				grouping,
			};
		}
		default: {
			return {
				name: UNKNOWN_GROUP_NAME,
				grouping,
				group,
			};
		}
	}
};

export const groupSortComparator = (
	group1: Group<ScopeIssue> | GroupOption,
	group2: Group<ScopeIssue> | GroupOption,
): number => {
	// This is a very primitive sort comparator but should be sufficient for our needs
	if (group1.groupName === '') {
		return 1;
	}
	if (group2.groupName === '') {
		return -1;
	}

	const g1: string = group1.groupName.toLowerCase();
	const g2: string = group2.groupName.toLowerCase();

	// put unassigned groups in the end of array
	if (g2 === UNASSIGNED_GROUP) {
		return -1;
	}
	if (g1 === UNASSIGNED_GROUP) {
		return 1;
	}
	if (g1 === g2) {
		return 0;
	}
	return g1 > g2 ? 1 : -1;
};

export const getSprintWithDates = (
	sprintsWithFutureDates: SprintsWithFutureDates,
	groupCombination: GroupCombination = {},
) => {
	const { team, sprint } = groupCombination;
	const findSprint = (arr: Array<TimelineSprint>) => arr && R.find(R.propEq('id', sprint), arr);
	return team
		? findSprint(sprintsWithFutureDates.team[team])
		: isDefined(sprint) && findSprint(sprintsWithFutureDates.sprint[sprint]);
};

export const groupSprintSortComparator =
	(
		teamsByIdMap: Record<string, Team> = {},
		sprintsWithFutureDates: SprintsWithFutureDates = { team: {}, sprint: {} },
	) =>
	(
		{ groupCombination: groupCombination1 }: Group<ScopeIssue>,
		{ groupCombination: groupCombination2 }: Group<ScopeIssue>,
	): number => {
		if (!groupCombination2) {
			return -1;
		}
		if (!groupCombination1) {
			return 1;
		}

		const sprint1 = getSprintWithDates(sprintsWithFutureDates, groupCombination1);
		const sprint2 = getSprintWithDates(sprintsWithFutureDates, groupCombination2);
		if (!sprint1) {
			return 1;
		}
		if (!sprint2) {
			return -1;
		}

		let g1;
		let g2;

		const team1 = groupCombination1.team && teamsByIdMap[groupCombination1.team];
		const team2 = groupCombination2.team && teamsByIdMap[groupCombination2.team];
		if (sprint1.startDate === sprint2.startDate) {
			// sort by team

			g1 = team1 && team1.title.toLowerCase();
			g2 = team2 && team2.title.toLowerCase();
			if (team1 && team2 && g1 === g2) {
				// sort by sprint title
				g1 = sprint1.title.toLowerCase();
				g2 = sprint2.title.toLowerCase();
			}
		} else {
			// sort by start date
			g1 = sprint1.startDate;
			g2 = sprint2.startDate;
		}

		// put unassigned groups in the end
		if (R.isNil(g1)) {
			return 1;
		}
		if (R.isNil(g2)) {
			return -1;
		}
		if (g1 === g2) {
			return 0;
		}
		return g1 > g2 ? 1 : -1;
	};

export const groupReleaseSortComparator =
	(versionsById: VersionsById) =>
	(
		{ groupCombination: groupCombination1 }: Group<ScopeIssue>,
		{ groupCombination: groupCombination2 }: Group<ScopeIssue>,
	): number => {
		if (!groupCombination2) {
			return -1;
		}
		if (!groupCombination1) {
			return 1;
		}
		const release1 =
			isDefined(groupCombination1.release) && versionsById[groupCombination1.release];
		const release2 =
			isDefined(groupCombination2.release) && versionsById[groupCombination2.release];
		if (!release1) {
			return 1;
		}
		if (!release2) {
			return -1;
		}

		// sort by start date
		const g1 = release1.end;
		const g2 = release2.end;

		// put unassigned groups in the end
		if (R.isNil(g1)) {
			return 1;
		}
		if (R.isNil(g2)) {
			return -1;
		}
		if (g1 === g2) {
			return 0;
		}
		return g1 > g2 ? 1 : -1;
	};

export const sortGroups = (
	groups: Group<ScopeIssue>[],
	grouping: Grouping,
	teamsById: Record<string, Team>,
	versionsById: VersionsById = {},
	sprintsWithFutureDates?: SprintsWithFutureDates,
): Group<ScopeIssue>[] => {
	if (grouping === GROUPING.SPRINT) {
		// Completed sprints should come first and be sorted separately
		return R.pipe(
			R.partition<Group<ScopeIssue>>(R.pathEq(['parentGroup'], COMPLETED_SPRINTS_GROUP)),
			R.map(R.sort(groupSprintSortComparator(teamsById, sprintsWithFutureDates))),
			R.reduce<Group<ScopeIssue>[], Group<ScopeIssue>[]>(R.concat, []),
		)(groups);
	}

	if (grouping === GROUPING.RELEASE) {
		// Completed releases should come first and be sorted separately
		return R.pipe(
			R.partition<Group<ScopeIssue>>(R.pathEq(['parentGroup'], COMPLETED_RELEASES_GROUP)),
			R.map(R.sort(groupReleaseSortComparator(versionsById))),
			R.reduce<Group<ScopeIssue>[], Group<ScopeIssue>[]>(R.concat, []),
		)(groups);
	}

	return groups.sort(groupSortComparator);
};

export const groupIsNotFilteredOut = (
	grouping: Grouping | undefined,
	group: string | null | undefined | number,
	filters: ResolvedFilters,
	issues: Issue[],
) => {
	if (!group) {
		return true;
	}

	switch (grouping) {
		case GROUPING.ASSIGNEE: {
			const filteredAssignees = filters[ASSIGNEE_FILTER_ID].value;
			return (
				filteredAssignees.length === 0 || filteredAssignees.some((assignee) => assignee === group)
			);
		}
		case GROUPING.REPORTER: {
			const filteredReporters = filters[REPORTER_FILTER_ID].value;
			return (
				filteredReporters.length === 0 || filteredReporters.some((reporter) => reporter === group)
			);
		}

		case GROUPING.COMPONENT: {
			const filteredComponents = filters[COMPONENT_FILTER_ID].value;

			if (filteredComponents.length === 0) {
				return true;
			}
			// the group should not be filtered out if there is at least one issue
			// that has at least one component matching one of the filtered components
			return issues.some(
				(issue) =>
					issue.components &&
					!R.isEmpty(issue.components) &&
					R.intersection(issue.components, filteredComponents).length > 0,
			);
		}

		case GROUPING.LABEL: {
			const filteredLabels = filters[LABEL_FILTER_ID].value;

			if (filteredLabels.length === 0) {
				return true;
			}

			// the group should not be filtered out if there is at least one issue
			// that has at least one label matching one of the filtered labels

			// if the "No label" value is one of the filtered labels
			if (filteredLabels.includes(UNDEFINED_GROUP)) {
				// then we check first if the issue labels is an empty array (to return earlier) before
				// looking for common labels in issue labels and filteredLabels
				return issues.some(
					(issue) =>
						issue.labels &&
						(R.isEmpty(issue.labels) || R.intersection(issue.labels, filteredLabels).length > 0),
				);
			}
			// if the "No label" value is not one of the filtered labels
			// then we only look for common labels in issue labels and filteredLabels if issue labels are not empty
			return issues.some(
				(issue) =>
					issue.labels &&
					!R.isEmpty(issue.labels) &&
					R.intersection(issue.labels, filteredLabels).length > 0,
			);
		}

		case GROUPING.PROJECT: {
			const filteredProjects = filters[PROJECT_FILTER_ID].value;
			return (
				filteredProjects.length === 0 ||
				filteredProjects.some((project) => project.toString() === group.toString())
			);
		}

		case GROUPING.RELEASE: {
			const {
				value: filteredReleases,
				versionsFromCrossProjectReleaseFilterValue: filteredCPReleases = [],
			} = filters[RELEASE_FILTER_ID];
			return (
				(filteredReleases.length === 0 ||
					filteredReleases.some((project) => project.toString() === group)) &&
				(filteredCPReleases.length === 0 ||
					filteredCPReleases.some((project) => project.toString() === group))
			);
		}

		case GROUPING.SPRINT: {
			const filteredSprints = filters[SPRINT_FILTER_ID].value;
			return filteredSprints.length === 0 || filteredSprints.some((sprint) => sprint === group);
		}

		case GROUPING.TEAM: {
			const filteredTeams = filters[TEAM_FILTER_ID].value;
			return filteredTeams.length === 0 || filteredTeams.some((team) => team === group);
		}

		default: {
			return true;
		}
	}
};

// Get scope of all issues whether expanded or not
export const getScopeFullPure = (
	issues: Issue[],
	isExpanded: {
		[key: string]: boolean;
	},
	filters: ResolvedFilters,
	sort: SortIssues,
	inlineCreateState: InlineCreateState,
	{
		// eslint-disable-next-line @typescript-eslint/no-shadow
		isAtlasConnectInstalled,
		grouping = GROUPING.NONE,
	}: { isAtlasConnectInstalled: boolean; grouping: Grouping },
	issuesByGroupArg: IssuesByGroup = {},
	teamsById: {
		[key: string]: Team;
	} = {},
	groupDetailsMap?: GroupDetailsMap,
	expandedGroups?: Partial<Record<Grouping, GroupingExpandedItem>>,
	sprintsWithFutureDates?: SprintsWithFutureDates,
	versionsById: VersionsById = {},
) => {
	const {
		[HIERARCHY_RANGE_FILTER_ID]: {
			value: { start, end },
		},
	} = filters;

	const issuesById = indexBy(({ id }) => id, issues);
	const issuesByParent = groupBy(
		({ parent }) => parent || 'Flow cannot get filter meaning',
		issues.filter(({ parent }) => parent),
	);

	let groups: Group<ScopeIssue>[] = [];
	let groupCount = 0;

	const getGroupDetailsFromMap = (id?: string | number | null): GroupDetails => {
		const unknownDetails = {
			name: UNKNOWN_GROUP_NAME,
			grouping,
			group: UNDEFINED_GROUP,
		};
		if (id) {
			return groupDetailsMap?.[id] ?? unknownDetails;
		}
		return unknownDetails;
	};

	if (grouping !== GROUPING.NONE && !(grouping === GROUPING.GOALS && !isAtlasConnectInstalled)) {
		const issuesByGroup = issuesByGroupArg;

		const groupAttribute = getGroupAttribute(grouping);

		for (const [groupMapKey, group] of Object.entries(issuesByGroup)) {
			if (
				groupIsNotFilteredOut(
					grouping,
					group[groupAttribute],
					filters,
					group.issues ? Array.from(group.issues) : [],
				)
			) {
				// eslint-disable-next-line @typescript-eslint/no-shadow
				const { issues, parentGroup, ...groupCombination } = group;
				const groupKey = groupMapKey;

				// Fix sorting after it was messed by getIssuesByGroup.
				const issuesInGroup: Issue[] = issues ? getSortedIssuesPure(Array.from(issues), sort) : [];
				groupCount++;
				const { name, url } = getGroupDetailsFromMap(groupKey);
				const groupExpanded = isGroupExpanded(grouping, groupKey, expandedGroups);
				const scope: Scope<ScopeIssue> = buildScope(
					issuesInGroup,
					issuesByParent,
					issuesById,
					isExpanded,
					start,
					end,
					groupKey,
					groupCombination,
					issuesInGroup,
					true,
				);
				let isParentGroupExpanded;
				if (parentGroup) {
					isParentGroupExpanded = isGroupExpanded(grouping, parentGroup, expandedGroups);
				}
				groups.push({
					groupName: name,
					grouping,
					groupUrl: url,
					scope,
					isExpanded: groupExpanded,
					group: groupKey,
					groupCombination,
					...(isDefined(parentGroup) ? { parentGroup, isParentGroupExpanded } : {}),
				});
			}
		}

		// add new group to scope when user tries to global create in grouping view
		const { groupCombination, startInlineCreateInEmptyGroup } = inlineCreateState;
		if (startInlineCreateInEmptyGroup && groupCombination) {
			const groupKey = getGroupKey(groupCombination);
			const { name, url } = getGroupDetailsFromMap(groupCombination[groupAttribute]);
			const groupExpanded = isGroupExpanded(grouping, groupKey, expandedGroups);
			const scope: Scope<ScopeIssue> = buildScope(
				[],
				issuesByParent,
				issuesById,
				isExpanded,
				start,
				end,
				groupKey,
				groupCombination,
				[],
			);
			groups.push({
				groupName: name,
				grouping,
				groupUrl: url,
				scope,
				isExpanded: groupExpanded,
				group: groupKey,
				groupCombination,
			});
			groupCount++;
		}

		groups = sortGroups(groups, grouping, teamsById, versionsById, sprintsWithFutureDates);

		// Create a dummy scope in order to support table rendering, this is done to avoid the necessity of building
		// a full scope for all issues. The data is not required when rendering issues in groups
		const scope: Scope<ScopeIssue> = {
			startLevelIssues: {
				level: start,
				issues: [],
				rootIssuesCount: 0,
			},
			issuesWithoutParent: [],
			issuesWithoutParentHeaderData: {
				id: 'itemsWithoutParentsHeader',
				isExpanded: true,
			},
		};

		return {
			groupCount,
			groups,
			...scope,
		};
	}
	const scope = buildScope(
		issues,
		issuesByParent,
		issuesById,
		isExpanded,
		start,
		end,
		undefined,
		undefined,
		undefined,
		true,
	);
	// Top level scope added as a group as well for use by search
	groups.push({
		groupName: UNDEFINED_GROUP,
		grouping: GROUPING.NONE,
		group: UNDEFINED_GROUP,
		scope,
	});
	return {
		groupCount,
		groups,
		...scope,
	};
};

// Get scope of only visible issues by filtering the already calculated full scope
export const getScopeCompositePure = (fullScope: Scope<ScopeIssue>): Scope<ScopeIssue> => {
	const visibleFilter = (scopeIssue: ScopeIssue): boolean => !!scopeIssue.parentsExpanded;
	const issuesWithoutParentMap = (
		scopeIssues: ScopeIssuesWithoutParent<ScopeIssue>,
	): ScopeIssuesWithoutParent<ScopeIssue> => ({
		...scopeIssues,
		issues: scopeIssues.issues.filter(visibleFilter),
		// rootIssueCount doesn't need to be updated as all root issues will be visible
	});

	return {
		startLevelIssues: {
			...fullScope.startLevelIssues,
			issues: fullScope.startLevelIssues.issues.filter(visibleFilter),
		},
		issuesWithoutParent: fullScope.issuesWithoutParent.map(issuesWithoutParentMap),
		issuesWithoutParentHeaderData: {
			...fullScope.issuesWithoutParentHeaderData,
		},
		extraIssues: fullScope.extraIssues ? fullScope.extraIssues.filter(visibleFilter) : undefined,
		groupCount: fullScope.groupCount,
		groups: fullScope.groups
			? fullScope.groups
					// Filter out none grouping created by full scope
					.filter((group) => group.grouping !== GROUPING.NONE)
					.map((group) =>
						group.isExpanded
							? {
									...group,
									scope: {
										startLevelIssues: {
											...group.scope.startLevelIssues,
											issues: group.scope.startLevelIssues.issues.filter(visibleFilter),
											// rootIssueCount doesn't need to be updated here as all root issues will be visible
										},
										issuesWithoutParent:
											group.scope.issuesWithoutParent.map(issuesWithoutParentMap),
										issuesWithoutParentHeaderData: {
											...group.scope.issuesWithoutParentHeaderData,
										},
										extraIssues: group.scope.extraIssues
											? group.scope.extraIssues.filter(visibleFilter)
											: undefined,
									},
								}
							: // If group is not expanded no issues should be in it's scope
								{
									...group,
									scope: {
										startLevelIssues: {
											...group.scope.startLevelIssues,
											issues: [],
											rootIssuesCount: 0,
										},
										issuesWithoutParent: group.scope.issuesWithoutParent.map((scopeIssues) => ({
											...scopeIssues,
											issues: [],
											rootIssuesCount: 0,
										})),
										issuesWithoutParentHeaderData: {
											...group.scope.issuesWithoutParentHeaderData,
										},
										extraIssues: [],
									},
								},
					)
			: undefined,
	};
};

export const getIssuesWithOriginalsAndOptimized = createSelector(
	[getSortedIssues, getOriginalIssues],
	getIssuesWithOriginalsAndOptimizedPure,
);

export const getAllIssuesWithOriginalAndOptimized = createSelector(
	[getIssues, getOriginalIssues],
	getIssuesWithOriginalsAndOptimizedPure,
);

export const getAllIssuesWithOriginalAndOptimizedById = createSelector(
	[getAllIssuesWithOriginalAndOptimized],
	(allIssuesWithOriginalAndOptimized) => R.indexBy(R.prop('id'), allIssuesWithOriginalAndOptimized),
);

const getIssuesWithOriginalsAndOptimizedByGroup = createSelector(
	[
		getIssuesWithOriginalsAndOptimized,
		getVisualisationGrouping,
		isOptimizedMode,
		getGroupingData,
		isEmbed,
		isConfluenceMacro,
	],
	getIssuesByGroupWithoutMapPure,
);

export const getIssuesByTeam = createSelector(
	[getIssuesWithOriginalsAndOptimized, isOptimizedMode],
	(issues: Issue[], isOptimized) =>
		R.groupBy((issue) => {
			const team = isOptimized
				? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(R.path(['optimized', 'team'], issue) as string)
				: issue.team;
			return team || '';
		}, issues),
);

export const getLabelAndComponentGroups = createSelector(
	[getLabelGroups, getComponentGroups],
	(labelGroups, componentGroups) => ({ labelGroups, componentGroups }),
);

// Return map of group details using same key as issuesByGroup
export const getGroupDetailsMapPure = (
	issuesByGroup: IssuesByGroup,
	grouping: Grouping,
	labelAndComponentGroups: {
		labelGroups?: LabelGroups;
		componentGroups?: ComponentGroups;
	} = {},
	teamsById: {
		[key: string]: Team;
	} = {},
	sprintsByIdMap: {
		[key: string]: Sprint;
	} = {},
	versionsById: VersionsById = {},
	groupByCustomFieldDetailsMap: GroupDetailsMap,
	lazyGoalsByARI: LazyGoalsByARI,
	projects?: Project[] | null,
	assignees?: Person[] | null,
	reporters?: ReporterPerson[] | null,
	associatedIssues?: Record<string, AssociatedIssue>,
): GroupDetailsMap => {
	if (Object.keys(groupByCustomFieldDetailsMap).length > 0) return groupByCustomFieldDetailsMap;
	const groupDetails: GroupDetailsMap = {};
	if (grouping !== GROUPING.NONE) {
		for (const [groupKey, group] of Object.entries(issuesByGroup)) {
			const groupAttribute = getGroupAttribute(grouping);
			if (groupKey) {
				const details = getGroupDetails(
					labelAndComponentGroups?.componentGroups ?? [],
					labelAndComponentGroups?.labelGroups ?? [],
					teamsById,
					projects ?? [],
					assignees ?? [],
					reporters ?? [],
					sprintsByIdMap,
					grouping,
					group[groupAttribute],
					versionsById,
					lazyGoalsByARI,
					associatedIssues,
				);
				groupDetails[groupKey] = details;
			}
		}
	}
	return groupDetails;
};

const getGroupDetailsMapForCustomField = (
	groupId: number | string | null | undefined,
	grouping: Grouping,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isGroupByMultiValueCustomField: boolean,
	customFieldValuesGroups: CustomFieldValuesGroup[],
	customFieldsById: { [key: string]: CustomField },
	userPickerOptionsById: { [key: string]: OptionType },
	selectOptionsById: { [key: string]: SelectOption },
) => {
	const group = groupId;
	if (!group || group === UNDEFINED_GROUP) {
		return {
			name: UNKNOWN_GROUP_NAME,
			grouping,
			group: UNDEFINED_GROUP,
		};
	}

	if (isGroupByMultiValueCustomField) {
		const customFieldValuesGroup = customFieldValuesGroups.find(({ id }) => id === group);

		return {
			name: customFieldValuesGroup?.name || UNKNOWN_GROUP_NAME,
			group,
			grouping,
		};
	}
	const fieldId = parseInt(getCustomFieldIdFromCustomFieldGrouping(grouping), 10);
	const customField = customFieldsById[fieldId];

	if (customField) {
		if (customField.type.key === CustomFieldTypes.UserPicker) {
			const option = userPickerOptionsById[group];
			return {
				name: option ? option.label : UNKNOWN_GROUP_NAME,
				group,
				grouping,
				url: option ? option.icon : '',
			};
		}
		const option = selectOptionsById[group];
		return {
			name: option ? option.value : UNKNOWN_GROUP_NAME,
			group,
			grouping,
		};
	}
	return {
		name: UNKNOWN_GROUP_NAME,
		grouping,
		group,
	};
};

const getGroupDetailsMapForCustomFieldSelector = createSelector<
	State,
	IssuesByGroup,
	Grouping,
	Record<string, CustomField>,
	Record<string, SelectOption>,
	CustomFieldValuesGroup[],
	boolean,
	{ [key: string]: OptionType },
	GroupDetailsMap
>(
	[
		getIssuesWithOriginalsAndOptimizedByGroup,
		getVisualisationGrouping,
		getCustomFieldsById,
		getSelectOptionsById,
		getCustomFieldValuesGroups,
		isGroupByMultiValueCustomField,
		getUserPickerOptionsUserListById,
	],
	(
		issuesByGroup: IssuesByGroup,
		grouping: Grouping,
		customFieldsById: { [key: string]: CustomField },
		selectOptionsById: { [key: string]: SelectOption },
		customFieldValuesGroups: CustomFieldValuesGroup[],
		// eslint-disable-next-line @typescript-eslint/no-shadow
		isGroupByMultiValueCustomField: boolean,
		userPickerOptionsById: { [key: string]: OptionType },
	): GroupDetailsMap => {
		const groupDetails: GroupDetailsMap = {};
		if (isRoadmapGroupedByCustomField(grouping)) {
			for (const [groupKey, issuesGroup] of Object.entries(issuesByGroup)) {
				const groupAttribute = getGroupAttribute(grouping);
				if (groupKey) {
					const details = getGroupDetailsMapForCustomField(
						issuesGroup[groupAttribute],
						grouping,
						isGroupByMultiValueCustomField,
						customFieldValuesGroups,
						customFieldsById,
						userPickerOptionsById,
						selectOptionsById,
					);
					groupDetails[groupKey] = details;
				}
			}
		}
		return groupDetails;
	},
);
const getGroupDetailsMap = createSelector(
	[
		getIssuesWithOriginalsAndOptimizedByGroup,
		getVisualisationGrouping,
		getLabelAndComponentGroups,
		getAllTeamsById,
		getSprintByIdMap,
		getVersionsById,
		getGroupDetailsMapForCustomFieldSelector,
		getLazyGoalsByARI,
		getProjects,
		getAssigneeList,
		getUniqueReportersOfIssues,
		getAssociatedIssues,
	],
	getGroupDetailsMapPure,
);

// createSelector supports up to max 12 dependencies, so we're just combining some into an object
const getGroupingWithIsAtlasConnectInstalled = createSelector(
	[isAtlasConnectInstalled, getVisualisationGrouping],
	// eslint-disable-next-line @typescript-eslint/no-shadow
	(isAtlasConnectInstalled: boolean, grouping: Grouping) => ({
		isAtlasConnectInstalled,
		grouping,
	}),
);

export const getScopeWithExpandingStateIgnored = createSelector(
	[
		getIssuesWithOriginalsAndOptimized,
		getIsExpanded,
		getFilters,
		getSort,
		getInlineCreateState,
		getGroupingWithIsAtlasConnectInstalled,
		getIssuesWithOriginalsAndOptimizedByGroup,
		getAllTeamsById,
		getGroupDetailsMap,
		getExpandedGroups,
		getSprintsWithFutureDates,
		getVersionsById,
	],
	getScopeFullPure,
);

export const getScope = createSelector([getScopeWithExpandingStateIgnored], getScopeCompositePure);

export const countVisibleIssuesInScopePure = (scope: Scope<ScopeIssue>): number => {
	if (scope.groupCount !== undefined && scope.groupCount > 0) {
		const issueIds = new Set<string>();
		(scope.groups ?? []).forEach((group) => {
			if (group.parentGroup !== undefined) {
				// parentGroup used for nested grouping of releases within the 'Completed Releases' section
				if (group.isParentGroupExpanded && group.isExpanded) {
					getVisibleIssuesIdsInGroup(group.scope).forEach((id) => {
						issueIds.add(id);
					});
				}
			} else if (group.isExpanded) {
				getVisibleIssuesIdsInGroup(group.scope).forEach((id) => {
					issueIds.add(id);
				});
			}
		});
		return issueIds.size;
	}
	// the 'ungrouped' group
	return getVisibleIssuesIdsInGroup(scope).size;
};

export const getVisibleIssuesIdsInGroup = (
	scope: Scope<ScopeIssue> | GroupedScope<ScopeIssue>,
): Set<string> => {
	const issueIds = new Set<string>();
	scope.startLevelIssues.issues.forEach((issue) => {
		issueIds.add(issue.id);
	});
	if (scope.issuesWithoutParentHeaderData.isExpanded) {
		(scope.issuesWithoutParent ?? []).forEach((section) => {
			if (section.isExpanded) {
				section.issues.forEach((issue) => {
					issueIds.add(issue.id);
				});
			}
		});
	}
	return issueIds;
};

// returns a count of the number of issue visible in the plan,
// after filtering, group collapsing and parent collapsing
export const countVisibleIssues = createSelector([getScope], countVisibleIssuesInScopePure);

export const getSearchQuery = (state: State) =>
	state.ui.Main.Tabs.Roadmap.Scope.Header.Search.searchQuery;
export const getActiveSearchResultIndex = (state: State) =>
	state.ui.Main.Tabs.Roadmap.Scope.Header.Search.activeSearchResultIndex;
export const getIsSearchBoxOpen = (state: State) =>
	state.ui.Main.Tabs.Roadmap.Scope.Header.Search.isSearchBoxOpen;

export const escapeRegExpFn = (string: string): string =>
	// eslint-disable-next-line no-useless-escape
	string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

export const splitRegExp = (searchQuery: string) =>
	new RegExp(`(${escapeRegExpFn(searchQuery)})`, 'gi');

export const splitString = (searchQuery: string, title: string): string[] =>
	R.compose(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		R.filter<string>(R.identity as (val: string) => boolean),
		R.split(splitRegExp(searchQuery)),
	)(title);

export const equalsIgnoreCase = (str1: string, str2: string): boolean =>
	R.equals(R.toLower(str1), R.toLower(str2));

export const buildIssueLink = (
	projectsById: ProjectsById,
	{ issueKey, project }: Issue,
): string => {
	let issueLink = `${issueKey || ''}`;
	if (isDefined(project) && isDefined(projectsById[project])) {
		issueLink = `${projectsById[project].key}${issueKey ? `-${issueKey}` : ''}`;
	}
	return issueLink;
};

export const totalMatches = (issueLink: string, searchQuery: string, issue: Issue): number =>
	R.length(
		`${issueLink} ${issue.summary}`.match(new RegExp(escapeRegExpFn(searchQuery), 'gi')) || [],
	);

export const searchForIssuesPure = (
	allIssuesByGroup: IssuesByGroup,
	scope: {
		groups: Group<ScopeIssue>[];
	},
	filteredIssuesById: IssuesById,
	projects: Project[],
	grouping: Grouping,
	isSearchBoxOpen: boolean,
	searchQuery: string,
	issueFilterMatcherWithFilterId: (issue: Issue) => UnMatchedFilters[],
): IssueSearchResults => {
	const results: IssueSearchResults = {
		hasNestedMatches: {},
		total: 0,
		resultsHiddenByFilter: [],
		searchMatches: [],
	};

	// Because of dependencies in the selectors that we cannot get rid of, this selector is called on init.
	// We don't want to calculate such heavy selector every time but only when the user starts using issue search
	if (!isSearchBoxOpen || searchQuery.trim() === '') {
		return results;
	}

	// projects indexed by id are needed for the "buildIssueLink" function
	const projectsById = indexBy(R.prop('id'), projects);

	// when the plan is grouped by a field, issues are displayed several times which can cause duplicates in the searchMatches
	const isChunkAlreadyInSearchMatches = (
		internalIndex: number,
		id: string,
		linkMatch: boolean,
		group: string,
	) =>
		results.searchMatches.find(
			(match) =>
				[match.internalIndex, match.id, match.linkMatch, match.group].join('|') ===
				[internalIndex, id, linkMatch, group].join('|'),
		);

	const searchForMatchingIssuesInGroup = (issue: Issue, group: string) => {
		const { id, summary, parent } = issue;
		const issueLink = buildIssueLink(projectsById, issue);
		const total = totalMatches(issueLink, searchQuery, issue);

		// if the issue matches the searchQuery and is not hidden by filters
		if (total && filteredIssuesById[id]) {
			if (parent) {
				results.hasNestedMatches = R.mergeDeepLeft(results.hasNestedMatches, {
					[`${group}`]: { [parent]: true },
				});
			}

			let internalIndex = 0;
			const linkChunks = splitString(searchQuery, issueLink);
			for (const chunk of linkChunks) {
				if (
					equalsIgnoreCase(chunk, searchQuery) &&
					!isChunkAlreadyInSearchMatches(internalIndex, id, true, group)
				) {
					results.searchMatches.push({
						internalIndex,
						id,
						linkMatch: true,
						group,
					});
					results.total += 1;
				}
				internalIndex++;
			}

			const summaryChunks = splitString(searchQuery, summary);
			for (const chunk of summaryChunks) {
				if (
					equalsIgnoreCase(chunk, searchQuery) &&
					!isChunkAlreadyInSearchMatches(internalIndex, id, false, group)
				) {
					results.searchMatches.push({
						internalIndex,
						id,
						linkMatch: false,
						group,
					});
					results.total += 1;
				}
				internalIndex++;
			}
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const browseIssuesInGroup = (scope: GroupedScope<ScopeIssue>, group: string) => {
		const { startLevelIssues, issuesWithoutParent } = scope;

		// the issuesWithoutParent is an array of objects
		// each object includes the issues without parent on a specific level of the hierarchy (Initiative, Epic, Story)
		// e.g. if there are 30 Initiative issues without parent and 23 story issues without parent:
		// issuesWithoutParent: [
		// { id: "IssuesWithoutParent-3", isExpanded: false, issues: Array(30), level: INITIATIVE_LEVEL, rootIssuesCount: 30 }
		// { id: "IssuesWithoutParent-2", isExpanded: true, issues: Array(0), level: EPIC_LEVEL, rootIssuesCount: 0 }
		// { id: "IssuesWithoutParent-1", isExpanded: false, issues: Array(23), level: STORY_LEVEL, rootIssuesCount: 23 }
		// ]
		// we map through each level, get the issues and then flatten the whole array
		const flattenIssuesWithoutParent = R.flatten(R.map(R.prop('issues'), issuesWithoutParent));

		// note: when the plan is grouped by None, issues have an EMPTY group
		const issueGroup = grouping !== GROUPING.NONE ? group : '';

		// all these issues are already sorted accordingly in the getScopeWithExpandingStateIgnored selector
		// and come bundled with their children also sorted accordingly
		// e.g. Epic 1 has no children, Epic 2 has 1 Story child, Epic 3 has 2 Story children, Epic 4 has no children
		// [ {Epic 1}, {Epic 2 > Story 1}, {Epic 3}, {Epic 3 > Story 1}, {Epic 3 > Story 2}, {Epic 4}, {Epic 5},  ... ]
		// therefore we only need to map through the array of issues and find potential matches
		const allIssues = [...startLevelIssues.issues, ...flattenIssuesWithoutParent];

		allIssues.forEach((issue) => {
			searchForMatchingIssuesInGroup(issue, issueGroup);
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-shadow
	scope.groups.forEach(({ scope, group }) => {
		browseIssuesInGroup(scope, group);
	});

	// looping through all issues to find the matching issues hidden by filters
	R.values(allIssuesByGroup).forEach((group) => {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		[...group.issues!].forEach((issue) => {
			const issueLink = buildIssueLink(projectsById, issue);
			const total = totalMatches(issueLink, searchQuery, issue);

			if (total && !filteredIssuesById[issue.id]) {
				results.resultsHiddenByFilter.push({
					id: issue.id,
					unmatchedFilters: issueFilterMatcherWithFilterId(issue),
				});
			}
		});
	});
	return results;
};

export const searchForIssues = createSelector(
	[
		getAllIssuesByGroup,
		getScopeWithExpandingStateIgnored,
		getFilteredIssuesWithHierarchyById,
		getProjects,
		getVisualisationGrouping,
		getIsSearchBoxOpen,
		getSearchQuery,
		getIssueFilterMatcherWithFilterId,
	],
	searchForIssuesPure,
);

export const getActiveSearchIssuePure = (
	{ searchMatches }: IssueSearchResults,
	activeSearchResultIndex: number,
) =>
	isDefined(searchMatches[activeSearchResultIndex])
		? searchMatches[activeSearchResultIndex]
		: undefined;

export const getActiveSearchIssue = createSelector(
	[searchForIssues, getActiveSearchResultIndex],
	getActiveSearchIssuePure,
);

export const getGroupCombinationByIssuePure = (issuesByGroup: IssuesByGroup) => {
	const map: Record<
		string,
		Array<{
			parentGroup?: IssuesGroup['parentGroup'];
			groupCombination: Omit<IssuesGroup, 'parentGroup'>;
		}>
	> = {};
	for (const { issues = new Set<Issue>(), parentGroup, ...groupCombination } of R.values(
		issuesByGroup,
	)) {
		for (const issue of issues) {
			if (!map[issue.id]) {
				map[issue.id] = [];
			}
			map[issue.id].push({ groupCombination, parentGroup });
		}
	}
	return map;
};

export const getGroupCombinationByIssueId = createSelector(
	getIssuesByGroup,
	getGroupCombinationByIssuePure,
);

export const getGroupOptions = createSelector(
	[getScope],
	({ groups }: Scope<ScopeIssue>): GroupOption[] =>
		groups?.map(({ scope, ...group }) => group) ?? [],
);
