import type { Action } from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

export const actions = {
	resize:
		(col: number, delta: number, commit = false): Action<State> =>
		({ setState, getState }) => {
			const { widths, onWidthsChange, resizer } = getState();

			if (!resizer) {
				return;
			}

			const preview = resizer({ column: col, delta })(widths);

			if (commit) {
				onWidthsChange(preview);
			}

			setState({ preview: commit ? undefined : preview });
		},

	setWidths:
		(next: number[]): Action<State> =>
		({ setState }) =>
			setState({ widths: next }),
};
