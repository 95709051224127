import type { ReactNode } from 'react';

export const HORIZONTAL = 'horizontal';
export const VERTICAL = 'vertical';
export const ALL = 'all';

export type Axis = typeof HORIZONTAL | typeof VERTICAL | typeof ALL;

export type Props = {
	children?: ReactNode;
	axis?: Axis;
	enabled?: boolean;
};
