import React, { useEffect, useRef, useState, type CSSProperties } from 'react';
import { createPortal } from 'react-dom';
import type { Props, Rect } from './types.tsx';

/**
 * Renders the inline dialog in a different node tree.
 *
 * The problem with the current inline dialog is that it tries to render the dialog in the same node tree with the trigger element (e.g. button)
 * However in some cases, the trigger element is under clipped container (overflow: hidden), even the dialog is set to have position: fixed, it doesn't show up
 * due to some unresolved bugs of the browsers
 *
 * This component tries to make sure to render the inline dialog outside of the clipped container by rendering in a newly created DOM node instead.
 */
export default function FixedDialog({ isOpen, renderDialog, renderTrigger, minLeft }: Props) {
	const ref = useRef<HTMLElement>(null);
	const [rect, setRect] = useState<Rect | null>(null);
	const fakeTriggerStyle: CSSProperties = {
		position: 'absolute',
		...rect,
		pointerEvents: 'none',
	};

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const containerElement = document.body;

	useEffect(() => {
		if (isOpen && ref.current != null) {
			const { top, left, width, height } = ref.current.getBoundingClientRect();

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const maxLeft = window.innerWidth;
			const finalLeft = Math.min(Math.max(left, minLeft), maxLeft);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			const maxWidth = window.innerWidth - finalLeft;
			const finalWidth = Math.min(width, maxWidth);

			setRect({ top, height, left: finalLeft, width: finalWidth });
		}
	}, [isOpen, minLeft]);

	return (
		<>
			{renderTrigger(ref)}
			{isOpen &&
				containerElement != null &&
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				createPortal(renderDialog(<div style={fakeTriggerStyle} />), containerElement)}
		</>
	);
}
