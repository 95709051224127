import type { Dispatch } from 'redux';
import {
	expandChange,
	collapseChange,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/actions.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch) => ({
	expandChange: (id: string, category: string) => {
		dispatch(expandChange(id, category));
	},
	collapseChange: (id: string, category: string) => {
		dispatch(collapseChange(id, category));
	},
});
