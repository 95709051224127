// To reduce code duplication and to improve styling consistency, export common components that are reusable across different filters.
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as TriggerButton } from './trigger-button';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as ClearFilterButton, type Props as ClearFilterButtonProps } from './clear-filter';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as FilterText } from './filter-text';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as TitleWithAvatar } from './title-with-avatar';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as NoMatchFound } from './no-match-text';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { calculateCategoryCheckboxStatus } from './utils';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { default as SearchField } from '../../../../../common/view/search-field';

export const FILTER_MAX_WIDTH = 450;
export const FILTER_WIDTH = 300;
