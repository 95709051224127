import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	chooseIdea: {
		id: 'portfolio-3-portfolio.common.ideas-cell.ideas-cell-select.choose-idea',
		defaultMessage: 'Link ideas',
		description: 'Label for unselected idea cell',
	},
	noIdeas: {
		id: 'portfolio-3-portfolio.common.ideas-cell.ideas-cell-select.no-ideas',
		defaultMessage: 'There are no ideas',
		description: 'Message to show when there is no ideas',
	},
	noMatch: {
		id: 'portfolio-3-portfolio.common.ideas-cell.ideas-cell-select.no-match',
		defaultMessage: 'No results match your search',
		description: 'Message to show when there is no idas matching search query',
	},
});
