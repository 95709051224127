.crossProjectReleaseOption {
	display: flex;
}

.crossProjectReleaseName {
	flex: 1 1 0%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-right: var(--jpo-common-base-spacing);
	min-width: 50px;
}

.crossProjectReleaseLozenge {
	flex: 0 1 0%;
	max-width: 100px;
}

.crossProjectReleaseLozenge > span {
	max-width: 100px;
}

.container {
	display: flex;
}

.release {
	flex-grow: 1;
}

.releaseDisabled {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	padding: 0 var(--jpo-common-base-spacing);

	& p {
		font-style: italic;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}

.infoIcon {
	margin-left: calc(var(--jpo-common-base-spacing) / 2);
	width: calc(2 * var(--jpo-common-base-spacing));
	height: calc(2 * var(--jpo-common-base-spacing));

	& span {
		width: 100%;
		height: 100%;
	}
}

.disabledContainer {
	color: var(--ds-text-disabled, var(--adg3-color-N70));
	height: 40px;
	display: flex;
	align-items: center;
	padding-left: calc(var(--jpo-common-base-spacing) * 1.75);
	white-space: nowrap;
}
