import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dependencyConfirmButtonAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-dependency.dependency-selector.dependency-confirm-button-aria-label',
		defaultMessage: 'Confirm',
		description:
			'Aria Label text when focus is switched to the confirm icon of a selected dependency field',
	},
	dependencyCancelButtonAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-dependency.dependency-selector.dependency-cancel-button-aria-label',
		defaultMessage: 'Clear',
		description:
			'Aria Label text when focus is switched to the clear icon of a selected dependency field',
	},
	dependencyTypeSelectorLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.add-dependency.dependency-selector.dependency-type-selector-label',
		defaultMessage: 'Issue type',
		description:
			'Aria label for dependency type selector that becomes available when user activates start dependency and end dependency buttons in the timeline screen',
	},
});
