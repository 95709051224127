export const NUM_OF_SCALABLE_COLUMNS = 4.5;
export const ESTIMATED_MIN_COLUMN_WIDTH = 120;
export const ESTIMATED_MAX_COLUMN_WIDTH = 250;
export const KEY_ARROW_LEFT = 37;
export const KEY_ARROW_RIGHT = 39;
export const SCROLL_BUTTON_STEP = 0.25;

// these column widths represent the amount of pixels the "column" takes on the screen
// they can be adjusted up / down based on customer feedback if need be
// the bigger the number, the wider a day renders in the timeline and therefore the less days are rendered
// e.g. (|| are timeline left and right edges)
// 252 => || D D D | D D D | D D D ||
// 552 => || D   D   D   |   D   D ||
export const ESTIMATED_MIN_COLUMN_WIDTH_WEEKS = 252; // 7 days * 36px per day
export const ESTIMATED_MIN_COLUMN_WIDTH_MONTHS = 240; // 30 days * 8px per day
export const ESTIMATED_MIN_COLUMN_WIDTH_QUARTERS = 252;
export const ESTIMATED_MIN_COLUMN_WIDTH_YEARS = 252;

/* Copied from jportfolio-3/portfolio/src/common/view/constant */
export const ZOOM_LEVELS = {
	WEEKS: 'weeks',
	MONTHS: 'months',
	QUARTERS: 'quarters',
	YEARS: 'years',
} as const;

export const ANALYTICS_SCROLL_TIMELINE = 'timeline scrolled';
export const ANALYITICS_TIMELINE_HORIZONTAL_SCROLLED =
	'timelineHorizontalScrollPerformance measured';

export const SPA_APP_NAME = 'advanced-roadmaps';
