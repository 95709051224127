import * as R from 'ramda';
import {
	RANK_TOP,
	RANK_BEFORE,
	RANK_LAST,
	RANK_AFTER,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { UpdateIssuePayload, RankIssuePayload, BulkRankIssueActionPayload } from './types.tsx';

const pickFieldsFromIssue = (fields: string[], issue: Issue): Partial<Issue> =>
	R.pick(fields, issue);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withNullDefaultValue = (partialIssue: Partial<Issue>): Record<string, any> =>
	R.map((value) => (isDefined(value) ? value : null), partialIssue);

export const getOriginalProperties = (issue: Issue, payload: UpdateIssuePayload) => {
	const originalCustomFields = Object.keys(payload.customFields || {}).reduce<
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		Record<string, any>
	>(
		(result, key) =>
			Object.assign(result, {
				[key]: R.path(['customFields', key], issue) || null,
			}),
		{},
	);

	const originalProperties =
		Object.keys(originalCustomFields).length === 0
			? withNullDefaultValue(pickFieldsFromIssue(Object.keys(payload), issue))
			: R.merge(withNullDefaultValue(pickFieldsFromIssue(Object.keys(payload), issue)), {
					customFields: originalCustomFields,
				});

	if (!R.equals(payload, originalProperties)) {
		return originalProperties;
	}
};

export const getAddedIssueRankPayload = (
	{ id, level, parent }: UpdateIssuePayload,
	issues: Issue[],
	sibling?: string | null,
) => {
	const siblings = issues.filter(
		parent
			? (issue) => issue.parent === parent && issue.id !== id
			: (issue) => issue.level === level && issue.id !== id,
	);
	if (!parent && siblings.length === 0) {
		return {
			itemKeys: [id],
			relation: RANK_LAST,
		};
	}

	if (sibling) {
		return {
			anchor: sibling,
			itemKeys: [id],
			relation: RANK_AFTER,
		};
	}

	return {
		anchor: siblings.length > 0 ? siblings[siblings.length - 1].id : parent,
		itemKeys: [id],
		relation: RANK_AFTER,
	};
};

export const getBulkRankPayload = (
	{ anchor, operationType }: BulkRankIssueActionPayload,
	itemKeys: string[],
	allIssues: Issue[],
): RankIssuePayload | null | undefined => {
	const itemKeysSet = new Set(itemKeys);
	// @ts-expect-error - TS2367 - This condition will always return 'false' since the types 'Relation' and '"TOP"' have no overlap.
	if (operationType === RANK_TOP) {
		// For RANK_TOP we need to rank BEFORE the issue with the lowest lexorank, otherwise it's a no-op.
		const topIssue = allIssues.find(({ id }) => !itemKeysSet.has(id));
		if (!topIssue) return null;
		return {
			anchor: topIssue.id,
			itemKeys,
			relation: RANK_BEFORE,
		};
	}
	if (operationType === RANK_LAST) {
		// For RANK_LAST we need to rank AFTER the issue with the highest lexorank, otherwise it's a no-op.
		// Note that RANK_LAST meaning is overloaded for the bulk case. Usually RANK_LAST is used
		// to get initial rank for the scenario issue without parent and siblings as there is no
		// suitable anchor to rank against.
		const bottomIssue = R.findLast(({ id }) => !itemKeysSet.has(id), allIssues);
		if (!bottomIssue) return null;
		return {
			anchor: bottomIssue.id,
			itemKeys,
			relation: operationType,
		};
	}
	return {
		anchor,
		itemKeys,
		relation: operationType,
	};
};
