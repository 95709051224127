import React, { type ReactNode } from 'react';
import { Stack, Text } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import {
	MODAL,
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useCtpOnboardingTour } from '../../../controllers/ctp-onboarding/index.tsx';
import { CtpSpotlights } from '../../../controllers/ctp-spotlights/index.tsx';
import { messages } from './messages.tsx';

export const CreateIssueSpotlight = () => {
	const { formatMessage } = useIntl();
	const [, { nextCtpStage }] = useCtpOnboardingTour();

	return (
		<ContextualAnalyticsData sourceName="crossTeamPlanningCreateIssueSpotlight" sourceType={MODAL}>
			<JiraSpotlight
				actionsBeforeElement="2/4"
				actions={[
					{
						text: formatMessage(messages.next),
						onClick: () => nextCtpStage(),
					},
				]}
				heading={formatMessage(messages.heading)}
				target={CtpSpotlights.CreateIssue}
				key={CtpSpotlights.CreateIssue}
				targetBgColor={token('elevation.surface', colors.N0)}
				targetRadius={3}
				dialogPlacement="bottom left"
				dialogWidth={350}
				messageId="portfolio-3-onboarding.ui.ctp-onboarding.create-issue-spotlight.jira-spotlight"
				messageType="engagement"
			>
				<Stack space="space.100">
					<Text as="p">
						<FormattedMessage {...messages.bodyFirst} />
					</Text>

					<Text as="p">
						{formatMessage(messages.bodyUnsavedChanges, {
							b: (chunks: ReactNode[]) => <Text weight="bold">{chunks}</Text>,
						})}
					</Text>

					<Text as="p">
						<FormattedMessage {...messages.bodyThird} />
					</Text>
				</Stack>
			</JiraSpotlight>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};
