export const UPDATE_HIDDEN_FLAG_DATE = 'state.domain.hiddenFlags.UPDATE_HIDDEN_FLAG_DATE' as const;
export const CLEAR_HIDDEN_FLAG_DATE = 'state.domain.hiddenFlags.CLEAR_HIDDEN_FLAG_DATE' as const;

export type HiddenFlagActionPayload = {
	key: string;
};

export type HiddenFlagDateAction = {
	type: typeof UPDATE_HIDDEN_FLAG_DATE | typeof CLEAR_HIDDEN_FLAG_DATE;
	payload: HiddenFlagActionPayload;
};

// Updates the date the flag was hidden along with its multiplier
export const updateHiddenFlagDate = (payload: HiddenFlagActionPayload): HiddenFlagDateAction => ({
	type: UPDATE_HIDDEN_FLAG_DATE,
	payload,
});

// Clear the date the flag was hidden along with its multiplier
export const clearHiddenFlagDate = (payload: HiddenFlagActionPayload): HiddenFlagDateAction => ({
	type: CLEAR_HIDDEN_FLAG_DATE,
	payload,
});
