import type { OriginalVersion, OriginalVersions } from './types.tsx';

export const RESET = 'state.domain.original-versions.RESET' as const;
export const UPDATE = 'state.domain.original-versions.UPDATE' as const;

export const UPDATE_REFERENCES = 'state.domain.original-versions.UPDATE_REFERENCES' as const;

export type ResetActionPayload = OriginalVersions;
export type UpdateActionPayload = {
	id: string;
	values: OriginalVersion;
};
export type UpdateReferencesPayload = {
	[key: string]: [string | null | undefined, string | null | undefined][];
};

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};

export type UpdateReferencesAction = {
	type: typeof UPDATE_REFERENCES;
	payload: UpdateReferencesPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const update = (payload: UpdateActionPayload) => ({
	type: UPDATE,
	payload,
});

export const updateReferences = (payload: UpdateReferencesPayload): UpdateReferencesAction => ({
	type: UPDATE_REFERENCES,
	payload,
});
