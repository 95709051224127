.viewsListSkeleton {
	height: 165px;
	min-width: 300px;
}

.viewsList {
	overflow-x: hidden;
	overflow-y: auto;
	/* This removes scrollbars that appear from overlapped elements in IE11 */
	-ms-overflow-style: auto;
	max-height: 180px; /* Enough to show 4.5 views */
	min-width: 250px;
}

.modifiedViewName {
	color: var(--ds-text, var(--adg3-color-text-light));
}

.modifiedViewActions {
	padding: calc(var(--jpo-common-base-spacing) / 2) var(--jpo-common-base-spacing) 0 0;
	font-size: 14px;
	& > *:not(:last-child) {
		margin-right: var(--jpo-common-base-spacing);
	}
}

.actionSeparator {
	display: inline-block;
	background: var(--ds-text-subtle, var(--adg3-color-N500));
	width: 3px;
	height: 3px;
	border-radius: 3px;
	vertical-align: middle;
}

.lozengeContainer {
	margin-left: calc(var(--jpo-common-base-spacing) * 2);
}

@media (min-height: 650px) {
	.viewsList {
		max-height: 260px; /* Enough to show 6.5 views */
	}
}

@media (min-height: 750px) {
	.viewsList {
		max-height: 300px; /* Enough to show 7.5 views */
	}
}

@media (min-height: 820px) {
	.viewsList {
		max-height: 340px; /* Enough to show 8.5 views */
	}
}

.viewName {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.viewTrigger {
	align-items: center;
	display: flex;

	& .view {
		max-width: 175px;
		display: flex;
		& > span:first-child {
			flex-shrink: 1;
		}
	}
}

.activeLozengeContainer {
	margin-left: calc(var(--jpo-common-base-spacing) / 2);
}

@media (min-width: 1280px) {
	.viewTrigger {
		& .view {
			max-width: 240px;
		}
	}
}

@media (min-width: 1440px) {
	.viewTrigger {
		& .view {
			max-width: 280px;
		}
	}
}
