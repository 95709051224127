import type { IssuePreview } from './types.tsx';

export const SET_PREVIEW_ISSUES =
	'state.ui.main.tabs.roadmap.timeline-preview.SET_PREVIEW_ISSUES' as const;
export const MOVE_ISSUE_AND_DESCENDENTS =
	'state.ui.main.tabs.roadmap.timeline-preview.MOVE_ISSUE_AND_DESCENDENTS' as const;
export const SET_ISSUES_START =
	'state.ui.main.tabs.roadmap.timeline-preview.SET_ISSUES_START' as const;
export const SET_ISSUES_END = 'state.ui.main.tabs.roadmap.timeline-preview.SET_ISSUES_END' as const;
export const CLEAR_PREVIEW = 'state.ui.main.tabs.roadmap.timeline-preview.CLEAR_PREVIEW' as const;

export type SetPreviewIssuesActionPayload = {
	activeIssueId: string;
	issueAndAncestors: {
		[key: string]: IssuePreview;
	};
	strictDescendents: {
		[key: string]: IssuePreview;
	};
};

export type SetPreviewIssuesAction = {
	type: typeof SET_PREVIEW_ISSUES;
	payload: SetPreviewIssuesActionPayload;
};

export type MoveIssueAndDescendentsActionPayload = {
	delta: number;
	targetStart?: number;
	targetEnd?: number;
};

export type MoveIssueAndDescendentsAction = {
	type: typeof MOVE_ISSUE_AND_DESCENDENTS;
	payload: MoveIssueAndDescendentsActionPayload;
};

export type SetIssuesStartActionPayload = {
	issueIds: string[];
	baselineStart: number;
};

export type SetIssuesStartAction = {
	type: typeof SET_ISSUES_START;
	payload: SetIssuesStartActionPayload;
};

export type SetIssuesEndActionPayload = {
	issueIds: string[];
	baselineEnd: number;
};

export type SetIssuesEndAction = {
	type: typeof SET_ISSUES_END;
	payload: SetIssuesEndActionPayload;
};

export type ClearPreviewAction = {
	type: typeof CLEAR_PREVIEW;
};

export const setPreviewIssues = (payload: SetPreviewIssuesActionPayload) => ({
	type: SET_PREVIEW_ISSUES,
	payload,
});

export const moveIssueAndDescendents = (payload: MoveIssueAndDescendentsActionPayload) => ({
	type: MOVE_ISSUE_AND_DESCENDENTS,
	payload,
});

export const setIssuesStart = (payload: SetIssuesStartActionPayload) => ({
	type: SET_ISSUES_START,
	payload,
});

export const setIssuesEnd = (payload: SetIssuesEndActionPayload) => ({
	type: SET_ISSUES_END,
	payload,
});

export const clearPreview = () => ({
	type: CLEAR_PREVIEW,
});
