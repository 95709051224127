import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import HiddenIssues from './hidden-issues/reducer.tsx';
import PlanSettings from './plan-settings/reducer.tsx';
import ShareReport from './share-report/reducer.tsx';
import ToolBar from './tool-bar/reducer.tsx';
import UpdateJira from './update-jira/reducer.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	PlanSettings,
	UpdateJira,
	ShareReport,
	HiddenIssues,
	ToolBar,
});
