import React from 'react';
import { isValid as isDateValid, isEqual as isDateEqual } from 'date-fns';
import * as R from 'ramda';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { token } from '@atlaskit/tokens';
import type { IntlShape } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import {
	distanceInWordsWithPolarity,
	isISOCompatible,
	startOfUtcDay,
	endOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { FlagData } from '@atlassian/jira-portfolio-3-common/src/flag/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import { ISSUE_INFERRED_DATE_SELECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import { DefaultDateFormat } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import messages from './messages.tsx';
import { type HandleChangeProps, type Props, DateTypes, type DateFieldTypes } from './types.tsx';

export const issueField = {
	[DateTypes.Start]: 'baselineStart',
	[DateTypes.End]: 'baselineEnd',
	[DateTypes.Other]: undefined,
} as const;

export const labels = {
	[DateTypes.Start]: messages.startDateLabel,
	[DateTypes.End]: messages.endDateLabel,
	[DateTypes.Other]: messages.dateLabel,
} as const;

export const getDateDiff = (
	stringDate1: null | string | number,
	stringDate2: null | string | number,
	locale: string,
): string => {
	if (stringDate1 == null || stringDate2 == null) {
		return '';
	}
	const date1 = new Date(stringDate1);
	const date2 = new Date(stringDate2);

	if (!isDateValid(date1) || !isDateValid(date2) || isDateEqual(date1, date2)) {
		return '';
	}

	return `(${distanceInWordsWithPolarity(date1.getTime(), date2.getTime(), locale)})`;
};

export const checkIsValid: (issue: ScopeIssue, type: string, date: number) => boolean = (
	issue,
	type,
	date,
) => {
	const targetStart: number | null | undefined = issue[issueField[DateTypes.Start]];
	const targetEnd: number | null | undefined = issue[issueField[DateTypes.End]];
	if (Number.isNaN(date)) {
		return false;
	}
	if (type === DateTypes.Start && targetEnd != null && date > targetEnd) {
		return false;
	}
	if (type === DateTypes.End && targetStart != null && targetStart > date) {
		return false;
	}
	return true;
};

export const getFormattedDate = (issue: Props['issue'], type: Props['type']): string => {
	let date = 0;
	if (type === DateTypes.Start) {
		if (!isDefined(issue.baselineStart)) {
			return '';
		}
		date = issue.baselineStart;
	} else if (type === DateTypes.End) {
		if (!isDefined(issue.baselineEnd)) {
			return '';
		}
		date = issue.baselineEnd;
	}

	return formatDateUTC(date, DefaultDateFormat);
};

export const getTheMethodOfDateInferrence = (
	issue: Props['issue'],
	type: Props['type'],
): number => {
	if (type !== DateTypes.Other) {
		const attribute: 'baselineStart' | 'baselineEnd' | '' = issueField[type] || '';
		const inferredFrom: Timestamp | null | undefined = R.path(['inferred', attribute], issue);

		return inferredFrom || ISSUE_INFERRED_DATE_SELECTION.NONE;
	}

	return ISSUE_INFERRED_DATE_SELECTION.NONE;
};

const getFlagData = (index: number, intl: IntlShape, type: DateFieldTypes): FlagData => {
	const timeOffset = 0;
	const warningIcon = (
		<WarningIcon label="" primaryColor={token('color.icon.warning', colors.Y300)} size="medium" />
	);

	const description =
		type === DateTypes.Start
			? commonMessages.invalidDateStartBeforeEnd
			: commonMessages.invalidDateEndAfterStart;

	return {
		created: Date.now() - timeOffset * 1000,
		title: intl.formatMessage(commonMessages.invalidDateTitle),
		description: intl.formatMessage(description),
		icon: warningIcon,
		id: index,
		key: index,
	};
};

const addFlag = (
	flags: FlagData[],
	setFlags: React.Dispatch<React.SetStateAction<FlagData[]>>,
	intl: IntlShape,
	type: DateFieldTypes,
) => {
	// Implementation as per Atlaskit example source code for Datepicker
	const nextFlags = flags.slice();
	nextFlags.unshift(getFlagData(flags.length + 1, intl, type));
	setFlags(nextFlags);
};

export const handleChange = (
	changedDate: string,
	{ onChange, issue, type, attribute, flags, setFlags, intl, setIsValid }: HandleChangeProps,
) => {
	if (changedDate === '' || !isISOCompatible(changedDate)) {
		onChange({
			issue,
			field: issueField[type] ?? attribute,
			date: undefined,
		});
	} else {
		// The date value we receive from the date-picker will always be an ISO value in the form 'YYYY-MM-DD',
		// but we want to convert this to the start and end of date as appropriate to store in the scenario in milliseconds...
		const date = type === DateTypes.Start ? startOfUtcDay(changedDate) : endOfUtcDay(changedDate);
		const validity = checkIsValid(issue, type, date);

		setIsValid(validity);

		if (!validity) {
			addFlag(flags, setFlags, intl, type);
		} else {
			onChange({
				issue,
				field: issueField[type] ?? attribute,
				date,
			});
		}
	}
};
