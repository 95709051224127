import React, { useEffect, useRef } from 'react';
import { attachClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Box, xcss } from '@atlaskit/primitives';
import { useViewport } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import { COLUMN } from '../constants.tsx';

export const HEADER_HEIGHT = 50;

type Props = {
	columnId: string;
	isDragging: boolean;
};

export const ColumnDropTarget = ({ columnId, isDragging }: Props) => {
	const [{ height }] = useViewport();

	const targetRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!targetRef.current) return;

		const target = targetRef.current;

		const data = { type: COLUMN, columnId };

		return dropTargetForElements({
			element: target,
			canDrop: ({ source }) => source.data.columnId !== columnId,
			getData: ({ input, element }) =>
				attachClosestEdge(data, {
					input,
					element,
					allowedEdges: ['left', 'right'],
				}),
		});
	}, [columnId]);

	return (
		<Box
			ref={targetRef}
			xcss={[indicatorStyles, isDragging && draggingStyles]}
			style={{ height: height - HEADER_HEIGHT }}
		/>
	);
};

const indicatorStyles = xcss({
	top: 'space.0',
	left: 'space.0',
	width: '100%',
	position: 'absolute',
});

const draggingStyles = xcss({
	backgroundColor: 'color.background.accent.gray.subtler',
	opacity: '0.5',
});
