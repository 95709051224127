import React, { type KeyboardEvent, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button/new';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { Text } from '@atlaskit/primitives';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { isScenarioIssue as getIsScenarioIssue } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/issue.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const RemoveIssueDialog = ({ issueId, issueKey, projectKey, onClose, removeFromPlan }: Props) => {
	const { formatMessage } = useIntl();
	const isScenarioIssue = getIsScenarioIssue(issueId);

	const issueIdentifier = projectKey && issueKey ? `${projectKey}-${issueKey}` : undefined;

	return (
		<ShortcutScope>
			<ModalDialog shouldScrollInViewport onClose={onClose} autoFocus={false}>
				<ModalHeader>
					<ModalTitle appearance="danger">
						{formatMessage(
							isScenarioIssue ? messages.removeScenarioIssueTitle : messages.removeSavedIssueTitle,
						)}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Text>
						<FormattedMessage
							{...(isScenarioIssue
								? messages.removeScenarioIssueMessage
								: messages.removeSavedIssueMessage)}
							values={{
								issueIdentifier,
								b: (chunk) => <b>{chunk}</b>,
							}}
						/>
					</Text>
				</ModalBody>
				<ModalFooter>
					<Button appearance="subtle" onClick={onClose}>
						{formatMessage(commonMessages.cancel)}
					</Button>
					<Button
						appearance="primary"
						onClick={(_: MouseEvent | KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
							removeFromPlan({ issueId });
							fireUIAnalytics(analyticsEvent, 'confirmInlineRemoveIssue', { isScenarioIssue });
							onClose();
						}}
					>
						{formatMessage(
							isScenarioIssue
								? messages.removeScenarioIssueActionButton
								: messages.removeSavedIssueActionButton,
						)}
					</Button>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
};

export default RemoveIssueDialog;
