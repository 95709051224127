export const PRESET_VIEWS_NAMES_V1 = {
	BASIC: 'Basic',
	SPRINT_CAPACITY_MANAGEMENT: 'Sprint capacity management',
	TOP_LEVEL_PLANNING: 'Top-level planning',
	DEPENDENCY_MANAGEMENT: 'Dependency management',
} as const;

export const PRESET_VIEWS_NAMES_V2 = {
	BASIC: 'Basic view',
	SPRINT_CAPACITY_MANAGEMENT: 'Sprint capacity management view',
	TOP_LEVEL_PLANNING: 'Top-level planning view',
	DEPENDENCY_MANAGEMENT: 'Dependency management view',
} as const;

export const PRESET_VIEWS_NAMES_V3 = {
	BASIC: 'Basic view',
	SPRINT_CAPACITY_MANAGEMENT: 'Sprint capacity view',
	TOP_LEVEL_PLANNING: 'Top-level view',
	DEPENDENCY_MANAGEMENT: 'Dependencies',
} as const;

export const PRESET_VIEWS_NAMES_V4 = {
	BASIC: 'Basic view',
	SPRINT_CAPACITY_MANAGEMENT: 'Capacity view',
	TOP_LEVEL_PLANNING: 'Top-level view',
	DEPENDENCY_MANAGEMENT: 'Dependencies',
} as const;
