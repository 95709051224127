import * as R from 'ramda';
import { UPDATE, RESET, type UpdateAction, type ResetAction } from './actions.tsx';
import type { OriginalResources } from './types.tsx';

type Action = UpdateAction | ResetAction;

const initialState: OriginalResources = {};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: OriginalResources = initialState, action: Action) => {
	switch (action.type) {
		case RESET:
			return { ...action.payload };
		case UPDATE: {
			const { itemKey, ...originals } = action.payload;
			return {
				...state,
				// NOTE merging state into "originals" and not vice versa because we want to keep old value if it was already set (real original)
				[itemKey]: R.mergeDeepRight(originals, state[itemKey] || {}),
			};
		}
		default:
			return state;
	}
};
