.radioSelect {
	margin-top: calc(var(--jpo-common-base-spacing) * 2);
}

.addTeamDialogBody {
	display: flex;
	gap: calc(var(--jpo-common-base-spacing) * 3);
	margin-top: 10px;
}

.imageIllustrationPanel {
	align-items: center;
	justify-content: center;
	align-self: flex-start;
	flex: 1;
	flex-direction: column;
	min-width: 0px;
	text-align: center;
	width: 365px;
	height: 295px;
	display: flex;

	& img {
		width: 230px;
	}
}

.teamSelectionPanel {
	flex: 1;
}

.teamField {
	margin-left: calc(var(--jpo-common-base-spacing) * 3.5);
}

.teamTypeOption {
	align-items: center;
	display: flex;
	gap: var(--jpo-common-base-spacing);
}

.teamTypeOptionIcon {
	flex: 0 0 32px;
}

.teamTypeOptionTitle {
	color: var(--ds-text, var(--adg3-color-N800));
	font-size: 14px;
	word-break: break-word;
}

.teamTypeOptionDescription {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	margin-top: 3px;
	word-break: break-word;
}
