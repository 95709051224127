.filters {
	font-size: 11px;
	align-items: center;
	display: flex;
}

.filterDetails {
	display: flex;
	justify-content: space-between;
}

.messageText {
	margin-bottom: 4px;
}
