export const OPEN_FLYOUT =
	'state.ui.main.tabs.releases.cross-project-releases.releases-table.project-releases.OPEN_FLYOUT' as const;

export const CLOSE_FLYOUT =
	'state.ui.main.tabs.releases.cross-project-releases.releases-table.project-releases.CLOSE_FLYOUT' as const;

export type SetRankingStateActionPayload = boolean;

export type OpenFlyoutActionPayload = {
	crossProjectVersionId: string;
};

export type OpenFlyoutAction = {
	type: typeof OPEN_FLYOUT;
	payload: OpenFlyoutActionPayload;
};

export type CloseFlyoutAction = {
	type: typeof CLOSE_FLYOUT;
};

export const openFlyout = (crossProjectVersionId: string): OpenFlyoutAction => ({
	type: OPEN_FLYOUT,
	payload: {
		crossProjectVersionId,
	},
});

export const closeFlyout = (): CloseFlyoutAction => ({
	type: CLOSE_FLYOUT,
});
