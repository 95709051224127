import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { Box, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { type MessageDescriptor, useIntl } from '@atlassian/jira-intl';
import { getConfiguredDateTooltip } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/messages.tsx';
import { getMatchingConfiguredDate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/utils.tsx';
import type { Props } from './types.tsx';

export const FieldAccessories = ({ column, dateConfiguration, children }: Props) => {
	const { formatMessage } = useIntl();

	const getColumnTitle = (title: string | MessageDescriptor) => {
		if (typeof title === 'string') {
			return title;
		}

		return formatMessage(title);
	};

	const getMatchingConfiguredDateFields = getMatchingConfiguredDate(dateConfiguration);
	const configurableDate = getMatchingConfiguredDateFields(column.id);
	const tooltip = getConfiguredDateTooltip(configurableDate);
	const columnTitle = getColumnTitle(column.title);
	const titleTooltip = tooltip ? formatMessage(tooltip, { title: columnTitle }) : columnTitle;
	const icon = column.HeaderContent ? (
		<column.HeaderContent />
	) : (
		configurableDate &&
		titleTooltip && (
			<Tooltip content={titleTooltip}>
				<Box xcss={fg('plan_timeline_drag_and_drop_field_columns') ? iconStyles : iconStylesOld}>
					<Lozenge>D</Lozenge>
				</Box>
			</Tooltip>
		)
	);

	return children({ title: columnTitle, icon, column });
};

const iconStyles = xcss({
	position: 'relative',
	cursor: 'pointer',
	marginLeft: 'space.050',
	marginRight: 'space.050',
	display: 'flex',
	alignItems: 'center',
});

const iconStylesOld = xcss({
	position: 'relative',
	cursor: 'pointer',
	marginLeft: 'space.050',
	marginRight: 'space.050',
	display: 'flex',
	alignItems: 'center',
	// @ts-expect-error - TS2322: Type number is not assignable to type
	zIndex: 999,
});
