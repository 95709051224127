import type { Dispatch } from 'redux';
import {
	deleteIssueLink,
	type DeleteAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue-links/index.tsx';
import type { RemoveActionPayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-links/actions.tsx';

const mapDispatchToProps = (dispatch: Dispatch<DeleteAction>) => ({
	removeDependency: (payload: RemoveActionPayload) => {
		dispatch(deleteIssueLink(payload));
	},
});

export default mapDispatchToProps;
