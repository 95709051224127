import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	chooseLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-label.choose-label',
		defaultMessage: 'Choose label',
		description: 'Placeholder for a label select when colour is picked',
	},
	selectVisibleLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-label.select-visible-label',
		defaultMessage: 'Select label',
		description:
			'The text is shown as label for the select by label when the colour is picked and which is displayed inside the view settings modal',
	},
	chooseColour: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-label.choose-colour',
		defaultMessage: 'Choose color',
		description: 'Placeholder for a label select when colour is not picked',
	},
	selectColour: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-label.select-colour',
		defaultMessage: 'Select color',
		description:
			'The text is shown as label for the select by label when the colour is picked and which is displayed inside the view settings modal',
	},
	addLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-label.add-label',
		defaultMessage: 'Add color',
		description: 'Label for a button to add a new colour',
	},
});
