import type { RowConfig } from '@atlassian/jira-portfolio-3-treegrid/src/common/types.tsx';
import type { VerticalBound } from './types.tsx';

/** Returns the row [top, bottom] bounds against the table top bound. */
export const getRowVerticalBound = (rowConfigs: RowConfig[], row: number): VerticalBound => {
	const lower = rowConfigs.slice(0, row).reduce((acc, { height }) => acc + height, 0);
	const upper = lower + rowConfigs[row].height;

	return [lower, upper];
};

/** Returns the distance that the viewport need to travel to show the element */
export const distanceIntoView = (viewport: VerticalBound, element: VerticalBound) => {
	// Element is below the viewport
	if (element[1] > viewport[1]) {
		return element[1] - viewport[1];
	}

	// Element is below the viewport
	if (element[0] < viewport[0]) {
		return element[0] - viewport[0];
	}

	return 0;
};
