import { useLayoutEffect } from 'react';
import {
	type ViewMode,
	VIEW_MODES,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { useToday } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { useContainerActions } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';

type Props = {
	viewId: number;
	viewMode: ViewMode;
};

export const ScrollReset = ({ viewId, viewMode }: Props) => {
	const [, { backToToday }] = useToday();
	const { scrollTo } = useContainerActions();
	useLayoutEffect(() => {
		if (viewMode === VIEW_MODES.TIMELINE) {
			backToToday();
		} else {
			scrollTo({ x: 0 });
		}
	}, [
		backToToday,
		scrollTo,
		viewId, // Since the container scope is {viewId}-{scenarioId}, we want to reset the scroll state when viewId changes
		viewMode,
	]);

	return null;
};
