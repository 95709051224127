.cell-invalid input {
	color: var(--ds-text-danger, red);
}

.cell-valid input {
	color: var(--ds-text, var(--adg3-color-N900));
}

.isReadOnly > div > div > div > div {
	border-color: transparent !important;
}

/* NOTE This style is aimed to match DatePicker styles, please keep it in sync during DatePicker upgrades */
.wireframe {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	max-width: 100%;
	min-height: 40px;
	& input {
		border: none;
		background-color: transparent;
		padding: 8px 9px;
		color: var(--ds-text, var(--adg3-color-N900));
		font-size: 14px;
		line-height: 24px;
		max-width: 100%;
		overflow: hidden;
		user-select: none;
		word-wrap: break-word;
		&:disabled {
			color: var(--ds-text-disabled, var(--adg3-color-N70));
		}
	}
}
.readOnly {
	& > div {
		color: var(--ds-text-disabled, var(--adg3-color-N70));
	}
}

.placeholder {
	color: var(--ds-text-subtlest, #757575);
}

.projectedDate {
	display: flex;
	align-items: center;
	font-style: italic;
	color: var(--ds-text-subtle, var(--adg3-color-N400));
	height: 20px;
	padding: 10px;
	justify-content: space-between;
}

.rollUpIcon {
	flex-shrink: 0;
}

.projectedDateText {
	margin-left: calc(var(--jpo-common-base-spacing) / 2);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.exportPNGLozenge {
	display: flex;
	align-items: center;
}
