import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	progress: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.components.progress.progress',
		description: 'How many changes are committed, the percentage completed',
		defaultMessage: '{countOfThingsDone} of {countOfThingsToDo} changes — {percentage}%',
	},
	label: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.components.progress.label',
		description: 'Label for saving progress card',
		defaultMessage: 'Saving in Jira...',
	},
});
