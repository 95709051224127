import { fg } from '@atlassian/jira-feature-gating';
import { isSmartLink } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getShowRolledUpOthers } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { RollupStateProps, StateProps } from './types.tsx';

export const mapStateToProps = (state: State): StateProps => ({
	isSmartLink: isSmartLink(state),
});

export const mapStateRollupSelect = (state: State): RollupStateProps => ({
	showRolledUpEstimate: getShowRolledUpOthers(state),
	...(fg('smart_links_for_plans') ? { isSmartLink: isSmartLink(state) } : {}),
});
