import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	from: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.fixed-date-range.from',
		defaultMessage: 'Start date',
		description: 'Label for the date picker field used to select the date that range starts from',
	},
	to: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.fixed-date-range.to',
		defaultMessage: 'End date',
		description: 'Label for the date picker field used to select the date that range runs until',
	},
	startDateAfterEndDateErrorMessage: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.fixed-date-range.start-date-after-end-date-error-message',
		defaultMessage: 'Start date must be before end date',
		description:
			'Error message informing users that selecting a date after today is not allowed. Will be displayed as red text underneath the text field',
	},
});
