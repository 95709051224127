import React, { type SyntheticEvent, createRef, useState } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button, { IconButton } from '@atlaskit/button/new';
import Icon from '@atlaskit/icon';
import CloseIcon from '@atlaskit/icon/core/close';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalFooter } from '@atlaskit/modal-dialog';
import { Box, xcss, Text, Stack, Inline } from '@atlaskit/primitives';
import UFOSegment from '@atlaskit/react-ufo/segment';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { FormattedMessage, useIntl, type MessageDescriptor } from '@atlassian/jira-intl';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import refreshSvg from '@atlassian/jira-portfolio-3-portfolio/src/common/icons/refresh.raw.svg';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import DocLink from '@atlassian/jira-portfolio-3-portfolio/src/common/view/doc-link/index.tsx';
import { reload } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	FireScreenAnalytics,
	MODAL,
} from '@atlassian/jira-product-analytics-bridge';
import withFilterItem from '../../filter-bar/filter-item-hoc/index.tsx';
import HiddenIssues from '../hidden-issues/index.tsx';
import ChangeStatusDialog from './change-status-dialog/index.tsx';
import ChangesCountMonitor from './changes-count-monitor/index.tsx';
import CommitWarningNotification from './changes-table/commit-warning-notification/view.tsx';
import { ROW_HEIGHT } from './changes-table/constants.tsx';
import ChangesTable from './changes-table/index.tsx';
import DiscardConfirmation from './discard-confirmation/index.tsx';
import EmptyDialog from './empty-dialog/index.tsx';
import UserFilterComponent from './filters/user-filter/index.tsx';
import messages from './messages.tsx';
import Footer from './modal-footer/index.tsx';
import type { Action } from './modal-footer/types.tsx';
import ModalHeader from './modal-header/index.tsx';
import { NotificationToggle } from './notification-toggle/index.tsx';
import QuickSelectors from './quick-selectors/index.tsx';
import RequiredFieldsDialog from './required-fields-dialog/index.tsx';
import ReviewChangesButton from './review-changes-button-wrapper/index.tsx';
import type { PropsWithCache } from './types.tsx';
import { getAttributesForIssuesWithTimeframeChanges } from './utils.tsx';

const DIALOG_NON_TABLE_HEIGHT = 200;
const DIALOG_MIN_HEIGHT = 500;

export const getFooterActions = (
	props: PropsWithCache,
	handleStopAction: () => void,
	openDiscardConfirmation: (
		e: SyntheticEvent<HTMLElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => void,
	formatMessage: (descriptor: MessageDescriptor) => string,
): Action[] => {
	const {
		isLoading,
		isSaving,
		isSavingInterrupted,
		isReadOnly,
		isReverting,
		isRevertingInterrupted,
		isOutOfSync,
		updateJira,
		selectedChangesCount,
		isNonPublishingEditor,
		isGicModalOpen = false,
		clearSummaryPageCache,
		changesCount,
	} = props;

	const discardAction: Action = {
		text: formatMessage(messages.updateJiraDialogRevertButtonText),
		onClick: openDiscardConfirmation,
		appearance: 'subtle',
		isDisabled: isOutOfSync || isLoading || selectedChangesCount === 0 || isReverting,
		isLoading: false,
		isVisible: !(isSaving || isReadOnly || isReverting),
		testId:
			'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.discard-selected-changes',
	};

	if (!(isSaving || isReverting)) {
		return [
			discardAction,
			{
				text: formatMessage(messages.updateJiraDialogUpdateButtonText),
				type: 'save',
				onClick: (e: SyntheticEvent<HTMLElement, Event>, analyticsEvent: UIAnalyticsEvent) => {
					updateJira(analyticsEvent, props.shouldNotifyWatchers);
					clearSummaryPageCache();
					const [actionSubject, action] =
						PRODUCT_ANALYTICS_EVENT_NAMES.SAVE_SELECTED_CHANGES_IN_JIRA_CLICKED.split(' ');
					fireUIAnalytics(
						analyticsEvent.update({ action, actionSubject }),
						PRODUCT_ANALYTICS_EVENT_NAMES.SAVE_SELECTED_CHANGES_IN_JIRA_CLICKED,
						{ changesCount },
					);
				},
				appearance: 'primary',
				isDisabled:
					isOutOfSync ||
					isLoading ||
					selectedChangesCount === 0 ||
					isNonPublishingEditor ||
					isGicModalOpen,
				isVisible: !isReadOnly,
				autoFocus: true,
				tooltip: isGicModalOpen
					? formatMessage(messages.disabledTooltipForUpdateJiraWhenGicIsOpen)
					: formatMessage(messages.disabledTooltipForUpdateJira),
				testId:
					'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.save-selected-changes',
			},
		];
	}

	return [
		{
			text: !(isSavingInterrupted || isRevertingInterrupted)
				? formatMessage(messages.updateJiraDialogStopButtonText)
				: formatMessage(messages.updateJiraDialogStoppingButtonText),
			onClick: () => {
				handleStopAction();
			},
			isLoading: isSavingInterrupted || isRevertingInterrupted,
			isDisabled: isSavingInterrupted || isRevertingInterrupted,
		},
		discardAction,
	];
};

export const UserFilter = withFilterItem(UserFilterComponent);

const UpdateJira = (props: PropsWithCache) => {
	const { formatMessage } = useIntl();

	const {
		changes,
		changesCount,
		isDialogOpen,
		isLoading,
		isSaving,
		isReadOnly,
		isOptimizing,
		isOptimized,
		isReverting,
		isOutOfSync,
		openDialog,
		onChangeListModified,
		hiddenIssuesDialogIsOpen,
		closeCommitWarningFlag,
		commitWarningMeta: {
			commitWarningsExists,
			shouldShowCommitWarningFlag,
			successfulCommitCount,
			failedCommitCount,
		},
		selectedChangesCount,
		currentScenarioColor,
		dateConfiguration,
		typeToLevel,
		shouldNotifyWatchers,
		isIssuesLoading,
		hasPlanOnlyTeams,
		interruptCommitting,
		interruptReverting,
		closeDialog,
	} = props;

	const buttonRef = createRef<HTMLButtonElement>();

	const [isDiscardConfirmationOpen, setIsDiscardConfirmationOpen] = useState(false);
	const [openedDialogWithChanges, setOpenedDialogWithChanges] = useState(false);

	const toggleNotification = (next: boolean) => {
		props.updateNotificationPreference(next);
	};

	const openDiscardConfirmation = (
		_: SyntheticEvent<HTMLElement>,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		setIsDiscardConfirmationOpen(true);
		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.DISCARD_SELECTED_CHANGES_CLICKED.split(' ');

		fireUIAnalytics(
			analyticsEvent.update({ action, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.DISCARD_SELECTED_CHANGES_CLICKED,
			{ changesCount },
		);
	};

	const closeDiscardConfirmation = () => {
		setIsDiscardConfirmationOpen(false);
	};

	const handleDiscardConfirmation = (
		_: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		setIsDiscardConfirmationOpen(false);
		props.revertChanges();

		const [actionSubject, action] = PRODUCT_ANALYTICS_EVENT_NAMES.REVERT_SCENARIO.split(' ');
		fireUIAnalytics(
			analyticsEvent.update({ action, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.REVERT_SCENARIO,
			{
				changesCount: props.selectedChangesCount,
			},
		);
	};

	const refresh = () => {
		reload();
	};

	const handleStopAction = () => {
		if (isSaving) {
			interruptCommitting();
		} else {
			interruptReverting();
		}
	};

	const handleCloseDialog = (
		_: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
		analyticsEvent: UIAnalyticsEvent,
	) => {
		if (isDefined(analyticsEvent)) {
			const [actionSubject, action] =
				PRODUCT_ANALYTICS_EVENT_NAMES.REVIEW_CHANGES_MODAL_CANCEL_CLICKED.split(' ');
			fireUIAnalytics(
				analyticsEvent.update({ action, actionSubject }),
				PRODUCT_ANALYTICS_EVENT_NAMES.REVIEW_CHANGES_MODAL_CANCEL_CLICKED,
				{ changesCount },
			);
		}

		closeDialog();
		buttonRef.current?.focus();
	};

	const renderDialog = (
		headerText: string,
		dialogContent: React.ReactNode,
		width?: number | string,
		height?: number | string,
	) => (
		<>
			<FireScreenAnalytics attributes={{ changesCount }} />
			<ShortcutScope>
				<UFOSegment name="jira-plan-save-scenario-change-dialog">
					<JSErrorBoundary
						id="jira-plan.save-scenario-change-dialog"
						packageName="jiraPortfolio3Portfolio"
						teamName="Advanced Roadmaps"
						fallback="flag"
					>
						<ModalDialog
							autoFocus
							shouldReturnFocus
							shouldCloseOnEscapePress={!(isSaving || isReverting)}
							onClose={handleCloseDialog}
							shouldCloseOnOverlayClick={false}
							width={width}
							height={height}
						>
							<Box xcss={headerStyles}>
								<ModalHeader>{headerText}</ModalHeader>
								<IconButton
									appearance="subtle"
									label={formatMessage(messages.closeDialog)}
									spacing="compact"
									icon={CloseIcon}
									onClick={handleCloseDialog}
									testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.selected-field-remove-button"
								/>
							</Box>
							<Box xcss={bodyStyles}>
								<Stack
									xcss={dialogContentStyles}
									testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-dialog-content"
								>
									{dialogContent}
								</Stack>
								<RequiredFieldsDialog />
								<ChangeStatusDialog />
							</Box>
							<Box testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-dialog-footer">
								<ModalFooter>
									<Footer
										leftActions={
											changesCount !== 0 ? (
												<NotificationToggle
													active={shouldNotifyWatchers}
													onChange={toggleNotification}
												/>
											) : null
										}
										actions={getFooterActions(
											props,
											handleStopAction,
											openDiscardConfirmation,
											formatMessage,
										)}
										selectedChangesCount={selectedChangesCount}
									/>
								</ModalFooter>
							</Box>
						</ModalDialog>
					</JSErrorBoundary>
				</UFOSegment>
			</ShortcutScope>
		</>
	);
	const handleOpenDialog = (e: KeyboardEvent | React.MouseEvent<Element, MouseEvent>) => {
		setOpenedDialogWithChanges(changesCount !== 0);

		openDialog(e);
	};

	if (isIssuesLoading) {
		return null;
	}

	let width = 'x-large';
	let height;
	let dialogContent;
	switch (true) {
		case isOutOfSync: {
			dialogContent = (
				<Stack xcss={outOfSyncStyles}>
					<Icon label="" dangerouslySetGlyph={refreshSvg} />
					<Box xcss={outOfSyncMessageStyles}>
						<Text as="p">{formatMessage(messages.outOfSyncMessageConcurrentEditing)}</Text>
						<Text as="p">{formatMessage(messages.refreshCta)}</Text>
						<Box xcss={refreshButtonStyles}>
							<Button appearance="primary" onClick={refresh}>
								{formatMessage(messages.refreshButton)}
							</Button>
						</Box>
					</Box>
				</Stack>
			);

			break;
		}
		case isLoading: {
			dialogContent = (
				<Stack xcss={spinnerStyles}>
					<Spinner size="large" />
				</Stack>
			);
			break;
		}
		default:
			// NOTE The problem is putting virtualized content (Table) into a growable Modal
			//
			// Contradictions posed by the problem:
			//
			// 1. Virtualized Table requires explicit height to be set on it, because it's the
			// essence of virtualization: render only content which is visible in the given area
			// and don't push parent / don't create scroll in parent by excessive content.
			//
			// 2. In case of small rows count we want our Modal to shrink and otherwise to grow
			// until it fills a viewport (sans padding).
			//
			// To cut this Gordian knot without overcomplications we manage Modal height not by
			// pushing it with Table's element (which is tricky because of [1]), but just doing
			// a rough estimation of desired size based on typical dialog styling and number of
			// changes to display. Growth is constrained by max-height in .body style. Table
			// then uses AutoSizer to fit into actual available space for it.
			width = '90vw';
			height = Math.max(DIALOG_NON_TABLE_HEIGHT + ROW_HEIGHT * changes.length, DIALOG_MIN_HEIGHT);
			dialogContent = (
				<>
					<Stack space="space.100" xcss={dialogContentContainerStyles}>
						<Text as="p">
							<FormattedMessage
								{...messages.updateJiraDialogBodyTop}
								values={{
									link: (chunks) => (
										<HelpPanelLink
											articleId="5jV03VyuxP6cfYQwcKWxWO"
											fallbackComponent={
												<DocLink href={DocLink.URLs.committingChangesToJira}>
													<FormattedMessage {...messages.updateJiraDialogReadMoreText} />
												</DocLink>
											}
											appearance="link"
											spacing="none"
											onClick={handleCloseDialog}
										>
											{chunks}
										</HelpPanelLink>
									),
								}}
							/>
						</Text>
						{hasPlanOnlyTeams && (
							<Text as="p">
								<FormattedMessage {...messages.updateJiraDialogBodyBottom} />
							</Text>
						)}
					</Stack>
					<Inline xcss={filterStyles}>
						<Inline grow="fill">
							<QuickSelectors changes={changes} />
						</Inline>
						<UserFilter />
					</Inline>
					<ChangesTable
						changes={changes}
						commitWarningsExists={commitWarningsExists}
						isUpdating={isSaving || isReverting}
						isReadOnly={isReadOnly}
						changesCount={changesCount}
					/>
				</>
			);
	}

	const headerText = formatMessage(messages.updateJiraDialogHeader, { changesCount });

	const shouldIncludeTimeframePlanningAnalyticsAttributes =
		changesCount > 0 && isDialogOpen && !isLoading && !isSaving && !isReverting;

	return (
		<Box xcss={updateJiraButtonStyles}>
			{isDefined(onChangeListModified) && isDialogOpen && (
				<ChangesCountMonitor
					onChangeListModified={onChangeListModified}
					changesCount={changesCount}
					isSaving={isSaving}
				/>
			)}
			<ContextualAnalyticsData
				sourceName="reviewChanges"
				sourceType={MODAL}
				attributes={
					shouldIncludeTimeframePlanningAnalyticsAttributes
						? {
								roadmapChangeDetails: getAttributesForIssuesWithTimeframeChanges(
									changes,
									dateConfiguration,
									typeToLevel,
								),
								changesCount,
							}
						: {}
				}
			>
				<ReviewChangesButton
					currentScenarioColor={currentScenarioColor}
					changesCount={changesCount}
					isOptimizing={isOptimizing}
					isOptimized={isOptimized}
					isReadOnly={isReadOnly}
					openDialog={handleOpenDialog}
					ref={buttonRef}
				/>
				{isDialogOpen && (
					<>
						{openedDialogWithChanges ? (
							renderDialog(headerText, dialogContent, width, height)
						) : (
							<EmptyDialog handleCloseDialog={handleCloseDialog} />
						)}
					</>
				)}
				{hiddenIssuesDialogIsOpen && <HiddenIssues />}
				<CommitWarningNotification
					successfulCommitCount={successfulCommitCount}
					failedCommitCount={failedCommitCount}
					show={shouldShowCommitWarningFlag}
					onClose={() => {
						closeCommitWarningFlag();
					}}
				/>
				<ContextualAnalyticsData sourceName="discardChanges" sourceType={MODAL}>
					<DiscardConfirmation
						isOpen={isDiscardConfirmationOpen}
						onConfirm={handleDiscardConfirmation}
						onCancel={closeDiscardConfirmation}
					/>
				</ContextualAnalyticsData>
			</ContextualAnalyticsData>
		</Box>
	);
};

const headerStyles = xcss({
	paddingTop: 'space.250',
	paddingRight: 'space.250',
	paddingLeft: 'space.250',
	paddingBottom: 'space.100',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
});

const dialogContentContainerStyles = xcss({
	marginTop: 'space.075',
	marginBottom: 'space.150',
});

const bodyStyles = xcss({
	position: 'relative',
	display: 'flex',
	flex: '1 1 auto',
	paddingBlock: 'space.025',
	paddingInline: 'space.250',
	maxHeight: 'calc(100% - 120px)',
	minHeight: '0',
});

const dialogContentStyles = xcss({
	flex: '1 1 auto',
});

const spinnerStyles = xcss({
	justifyContent: 'center',
	alignItems: 'center',
});

const outOfSyncStyles = xcss({
	paddingTop: 'space.250',
	alignItems: 'center',
});

const outOfSyncMessageStyles = xcss({
	paddingBlock: 'space.250',
	textAlign: 'center',
});

const refreshButtonStyles = xcss({
	paddingTop: 'space.100',
});

const filterStyles = xcss({
	paddingTop: 'space.100',
});

const updateJiraButtonStyles = xcss({
	marginLeft: 'space.050',
});

export default UpdateJira;
