.main {
	display: flex;
	height: 100%;
	position: absolute;
	width: 100%;
}

.withRightBorder {
	border-right: 1px solid var(--ds-border, var(--adg3-color-N40));
	box-sizing: border-box;
}
