import { getSupportedCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Props, StateProps } from './types.tsx';

const querySelector = createStructuredSelector<State, Props, StateProps>({
	customFieldValue: (state: State, props: Props) =>
		getSupportedCustomFields(state).find((field) => field.id.toString() === props.attribute),
});

export default querySelector;
