import React, { forwardRef } from 'react';
import { Checkbox as AKCheckbox } from '@atlaskit/checkbox';
import type { CheckboxProps } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { CheckboxSkeleton } from './skeleton';
export default forwardRef<HTMLInputElement, CheckboxProps>((props: CheckboxProps, ref) => {
	const { defaultChecked = false, ...rest } = props;
	return <AKCheckbox defaultChecked={defaultChecked} ref={ref} {...rest} />;
});
