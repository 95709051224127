import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	setTeamHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-teams.set-team-header',
		defaultMessage: 'Update team',
		description: 'Modal dialog header for dialog team',
	},
	variousValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-teams.various-values',
		defaultMessage: 'Various teams',
		description: 'Label for various team values',
	},
});
