import { useEnvironment } from '../environment/index.tsx';

// TODO: https://product-fabric.atlassian.net/browse/TC-10338 - delete this later
export const useTeamCentralUrl = (): string => '/gateway/api/watermelon/graphql';

export const useTeamCentralBaseUrl = (): string => {
	const env = useEnvironment();
	if (env === 'staging' || env === 'dev' || env === 'local') {
		return 'https://team.stg.atlassian.com';
	}
	return 'https://team.atlassian.com';
};
