export const TOGGLE_SHOW_DATE_CONSTRAINTS =
	'state.domain.view-settings.date-constraints-setting.TOGGLE_SHOW_DATE_CONSTRAINTS' as const;
export const RESET_SHOW_DATE_CONSTRAINTS =
	'state.domain.view-settings.date-constraints-setting.RESET_SHOW_DATE_CONSTRAINTS' as const;

export type ResetShowDateConstraintsActionPayload = {
	showDateConstraints: boolean;
};

export type ToggleShowDateConstraintsAction = {
	type: typeof TOGGLE_SHOW_DATE_CONSTRAINTS;
};

export type ResetShowDateConstraintsAction = {
	type: typeof RESET_SHOW_DATE_CONSTRAINTS;
	payload: ResetShowDateConstraintsActionPayload;
};

export const toggleShowDateConstraints = (): ToggleShowDateConstraintsAction => ({
	type: TOGGLE_SHOW_DATE_CONSTRAINTS,
});

export const resetShowDateConstraints = (
	payload: ResetShowDateConstraintsActionPayload,
): ResetShowDateConstraintsAction => ({
	type: RESET_SHOW_DATE_CONSTRAINTS,
	payload,
});
