// This function is based on an example provided here: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
const isStorageAvailable = (type: 'sessionStorage' | 'localStorage') => {
	let storage: Storage | undefined;
	try {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		storage = window[type];
		const x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		return (
			// NOTE: Flow is complaining it can't resolve name for DOMException (the commented line is the suggested one from MDN)
			// e instanceof DOMException &&
			e &&
			// everything except Firefox
			(e.code === 22 ||
				// Firefox
				e.code === 1014 ||
				// test name field too, because code might not be present
				// everything except Firefox
				e.name === 'QuotaExceededError' ||
				// Firefox
				e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
			// acknowledge QuotaExceededError only if there's something already stored
			storage &&
			storage.length !== 0
		);
	}
};

const localStorageAvailable = isStorageAvailable('localStorage');

// Create a simple object as a fallback for a lack of support for localstorage. This will allow data
const inMemoryStorage: {
	[key: string]: string;
} = {};

const setItemInLocalStorage = (key: string, value: string): void => {
	if (localStorageAvailable) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.localStorage.setItem(key, value);
	} else {
		inMemoryStorage[key] = value;
	}
};

const getItemFromLocalStorage = (key: string): string | null => {
	if (localStorageAvailable) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return window.localStorage.getItem(key);
	}
	return inMemoryStorage[key];
};

const removeItemFromLocalStorage = (key: string): void => {
	if (localStorageAvailable) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.localStorage.removeItem(key);
	} else {
		delete inMemoryStorage[key];
	}
};

export const storage = {
	getItem: (key: string): string | null => getItemFromLocalStorage(key),
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	setItem: (key: string, value: string): string | void => setItemInLocalStorage(key, value),
	removeItem: (key: string): void => removeItemFromLocalStorage(key),
} as const;

// A promise based storage based on the above. For use in redux-persist.
export const promiseStorage = {
	getItem: (key: string): Promise<string | null> => Promise.resolve(getItemFromLocalStorage(key)),
	// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	setItem: (key: string, value: string): Promise<string | void> =>
		Promise.resolve(setItemInLocalStorage(key, value)),
	removeItem: (key: string): Promise<void> => Promise.resolve(removeItemFromLocalStorage(key)),
} as const;
