import type { Effect } from 'redux-saga';
import { takeEvery, put, select } from 'redux-saga/effects';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { MessageDescriptor } from '@atlassian/jira-intl';
import { isEnvironmentError } from '@atlassian/jira-portfolio-3-portfolio/src/common/error/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	PACKAGE_NAME,
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { userAgent } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import { getSystemInfo } from '../../query/system/index.tsx';
import { showError } from '../../state/ui/errors/actions.tsx';
import type { RequestInfo, ErrorDetails } from '../../state/ui/errors/types.tsx';
import { shouldIgnoreError } from './utils.tsx';

// delete this constant when removing the last improve_redux_saga_error_reporting_plans_batch_x feature gate
export const GENERIC_ERROR = 'command.errors.GENERIC_ERROR' as const;

// delete this type when removing the last improve_redux_saga_error_reporting_plans_batch_x feature gate
export type GenericErrorActionPayload = Readonly<{
	message: string;
	stackTrace?: string;
	requestInfo?: RequestInfo;
	shouldReportError?: boolean;
	description?: MessageDescriptor;
}>;

export type GenericErrorAction = Readonly<{
	type: typeof GENERIC_ERROR;
	payload: GenericErrorActionPayload;
}>;

// delete this function when removing the last improve_redux_saga_error_reporting_plans_batch_x feature gate
export const genericError = (payload: GenericErrorActionPayload) => ({
	type: GENERIC_ERROR,
	payload,
});

// delete this function when removing the last improve_redux_saga_error_reporting_plans_batch_x feature gate
export function* doGenericError({
	payload: { message, stackTrace, requestInfo, shouldReportError = true, description }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: GenericErrorAction): Generator<Effect, any, any> {
	if (shouldIgnoreError(message)) {
		// eslint-disable-next-line no-console
		console.warn('Not showing JPO error dialog for exception:', message);
		return;
	}

	const error = new Error(message);

	if (stackTrace) {
		error.stack = stackTrace;
	}

	const details: ErrorDetails = {
		requestInfo,
		clientInfo: {
			userAgent,
			localTime: new Date().toString(),
		},
		systemInfo: yield select(getSystemInfo),
	};

	if (fg('plans-generic-error-sentry-logs')) {
		if (shouldReportError) {
			const id = isEnvironmentError(error)
				? ERROR_REPORTING_PACKAGE.ENVIRONMENT
				: ERROR_REPORTING_PACKAGE.REDUX_SAGA;
			fireErrorAnalytics({
				meta: {
					id,
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				error,
				sendToPrivacyUnsafeSplunk: !isDefined(requestInfo),
			});
		}
	} else {
		// For easy FF cleanup
		// eslint-disable-next-line no-lonely-if
		if (shouldReportError && !isDefined(requestInfo)) {
			const id = isEnvironmentError(error)
				? ERROR_REPORTING_PACKAGE.ENVIRONMENT
				: ERROR_REPORTING_PACKAGE.REDUX_SAGA;
			fireErrorAnalytics({
				meta: {
					id,
					packageName: PACKAGE_NAME,
					teamName: ERROR_REPORTING_TEAM,
				},
				error,
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}

	yield put(
		showError(
			message,
			stackTrace,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window?.location.hash,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window?.location.pathname,
			details,
			description,
		),
	);
}

export function httpRequestError(
	response: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	},
	error: {
		message: string;
		stackTrace: string;
	},
	requestObj: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	},
): GenericErrorAction {
	return genericError({
		...error,
		requestInfo: {
			url: response.url,
			type: requestObj.method,
			status: response.status,
			body: response.body || {},
		},
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* watchGenericError(): Generator<Effect, any, any> {
	yield takeEvery(GENERIC_ERROR, doGenericError);
}
