/* As mentioned in https://atlassian.design/components/textfield/examples#customization: */
/* Use the data-ds--text-field--input to customize the style of the text field container and input element. */
.textFieldWrapper [data-ds--text-field--input] {
	text-overflow: ellipsis;
}

.textFieldWrapper.readOnly [data-ds--text-field--input] {
	color: var(--ds-text-disabled, var(--adg3-color-N70));
	cursor: not-allowed;
	pointer-events: auto;
	text-overflow: ellipsis;
}
