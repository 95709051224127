import React from 'react';
import Avatar from '@atlaskit/avatar';
import Tooltip from '@atlaskit/tooltip';
import type { ViewUserInfo } from '../../api/types.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

export const ViewUser = (user?: ViewUserInfo | null) =>
	user ? (
		<Tooltip content={user.email}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.avatarWithTitle}>
				<Avatar src={user.avatarUrl} size="small" />
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<span className={styles.userTitle}>{user.title}</span>
			</div>
		</Tooltip>
	) : (
		''
	);
