import { getSelected } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import { getUserFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/filters.tsx';
import { getQuickSelectOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/quick-select.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { UserFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/types.tsx';
import {
	createStructuredSelector,
	createSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps, OwnProps } from './types.tsx';

const getUserFilterValuesPure = (userFilter: UserFilter): string[] => userFilter.value;

const getUserFilterValues = createSelector([getUserFilter], getUserFilterValuesPure);

export default createStructuredSelector<State, OwnProps, StateProps>({
	selectedIssues: getSelected,
	userFilter: getUserFilterValues,
	option: getQuickSelectOption,
});
