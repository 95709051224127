import React from 'react';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import { Box } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { FormattedMessage, injectIntl, useIntl } from '@atlassian/jira-intl';
import { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import {
	RANK_BEFORE,
	RANK_AFTER,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import messages from './messages.tsx';
import type { PropsWithInjected as PropsOld, Props } from './types.tsx';

const RankIssueMenuGroup = ({ bulkUpdateRank, issueId, isSelected }: Props) => {
	const { formatMessage } = useIntl();
	const rankAboveMenuItem = (
		<DropMenuItem
			onClick={() => {
				bulkUpdateRank({ operationType: RANK_BEFORE, anchor: issueId });
			}}
			isDisabled={isSelected}
		>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-above-option"
			>
				<FormattedMessage {...messages.rankAllSelectedIssuesAbove} />
			</Box>
		</DropMenuItem>
	);
	const rankBelowMenuItem = (
		<DropMenuItem
			onClick={() => {
				bulkUpdateRank({ operationType: RANK_AFTER, anchor: issueId });
			}}
			isDisabled={isSelected}
		>
			<Box
				as="span"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-below-option"
			>
				<FormattedMessage {...messages.rankAllSelectedIssuesBelow} />
			</Box>
		</DropMenuItem>
	);
	return (
		<DropMenuItemGroup>
			{isSelected ? (
				<Tooltip content={formatMessage(messages.noRankItself)}>{rankAboveMenuItem}</Tooltip>
			) : (
				rankAboveMenuItem
			)}

			{isSelected ? (
				<Tooltip content={formatMessage(messages.noRankItself)}>{rankBelowMenuItem}</Tooltip>
			) : (
				rankBelowMenuItem
			)}
		</DropMenuItemGroup>
	);
};

function RankIssueMenuGroupOld({ intl, bulkUpdateRank, issueId, isSelected }: PropsOld) {
	const rankAboveEl = (
		<Box
			as="span"
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-above-option"
		>
			<FormattedMessage {...messages.rankAllSelectedIssuesAbove} />
		</Box>
	);
	const rankBelowEl = (
		<Box
			as="span"
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.rank-issue.rank-below-option"
		>
			<FormattedMessage {...messages.rankAllSelectedIssuesBelow} />
		</Box>
	);
	return (
		<DropMenuItemGroup>
			<DropMenuItem
				onClick={() => {
					bulkUpdateRank({ operationType: RANK_BEFORE, anchor: issueId });
				}}
				isDisabled={isSelected}
			>
				{isSelected ? (
					<Tooltip content={intl.formatMessage(messages.noRankItself)}>{rankAboveEl}</Tooltip>
				) : (
					rankAboveEl
				)}
			</DropMenuItem>
			<DropMenuItem
				onClick={() => {
					bulkUpdateRank({ operationType: RANK_AFTER, anchor: issueId });
				}}
				isDisabled={isSelected}
			>
				{isSelected ? (
					<Tooltip content={intl.formatMessage(messages.noRankItself)}>{rankBelowEl}</Tooltip>
				) : (
					rankBelowEl
				)}
			</DropMenuItem>
		</DropMenuItemGroup>
	);
}

export default componentWithFG(
	'fix_tooltip_positioning_plans_meatball',
	RankIssueMenuGroup,
	injectIntl(RankIssueMenuGroupOld),
);
