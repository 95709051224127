import React from 'react';
import ChangeIndicator from '@atlassian/jira-portfolio-3-portfolio/src/common/view/change-indicator/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

function Capacity(props: Props) {
	const { sprintId, style, content, isUpdated } = props;

	return content && sprintId !== 'GAP' && sprintId !== 'PAST' ? (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div style={style}>
			{isUpdated && (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles.changeIndicator}>
					<ChangeIndicator appearance="triangle" />
				</div>
			)}
			{content}
		</div>
	) : (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
		<div style={style} />
	);
}

export default Capacity;
