import React, { type ReactNode, type KeyboardEvent } from 'react';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
// eslint-disable-next-line @atlaskit/design-system/no-unsupported-drag-and-drop-libraries
import { SortableElement } from 'react-sortable-hoc';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import FolderIcon from '@atlaskit/icon/glyph/folder';
import PeopleIcon from '@atlaskit/icon/glyph/people';
import ShipIcon from '@atlaskit/icon/glyph/ship';
import ChevronDown from '@atlaskit/icon/utility/migration/chevron-down';
import ChevronRight from '@atlaskit/icon/utility/migration/chevron-right';
import Lozenge from '@atlaskit/lozenge';
import Tooltip from '@atlaskit/tooltip';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useAssociatedIssues } from '@atlassian/jira-portfolio-3-associated-issues/src/controllers/index.tsx';
import { AssociatedIssuesView } from '@atlassian/jira-portfolio-3-associated-issues/src/ui/index.tsx';
import AvatarInitials from '@atlassian/jira-portfolio-3-common/src/avatar-initials/index.tsx';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { CellSkeleton } from '@atlassian/jira-portfolio-3-common/src/skeleton/cell.tsx';
import planCommonMessages from '@atlassian/jira-portfolio-3-plan-increment-common/src/messages.tsx';
import type { CrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/types.tsx';
import { UNDEFINED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import {
	TABLE_GROUP,
	type TableItem,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import type { EnrichedVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import SprintIcon from '@atlassian/jira-portfolio-3-portfolio/src/common/icons/sprint/index.tsx';
import type { FormatMessage } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import {
	CustomFieldTypes,
	GROUPING,
	COMPLETED_SPRINTS_GROUP,
	COMPLETED_RELEASES_GROUP,
	type Grouping,
	DEFAULT_FIELD_WIDTH,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isRoadmapGroupedByCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { Goal } from '@atlassian/jira-software-goals/src/ui/goal/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, GroupIconProps, PropsForGroupHeaderComponent } from './types.tsx';

const groupIcons: Record<Grouping, ReactNode | null | undefined> = {
	[GROUPING.ASSIGNEE]: undefined,
	[GROUPING.REPORTER]: undefined,
	[GROUPING.COMPONENT]: <FolderIcon key="component" label="Folder icon" />,
	[GROUPING.LABEL]: <FolderIcon key="label" label="Folder icon" />,
	[GROUPING.NONE]: undefined,
	[GROUPING.PROJECT]: undefined,
	[GROUPING.RELEASE]: <ShipIcon key="release" label="Ship icon" />,
	[GROUPING.SPRINT]: <SprintIcon key="sprint" label="Sprint icon" />,
	[GROUPING.TEAM]: undefined,
	// Goals handled separately as it requires state
	[GROUPING.GOALS]: undefined,
	// Ideas handled separately as it requires state
	[GROUPING.IDEAS]: undefined,
};

const UNASSIGNED_GOALS_GROUP = 'goals-UNDEFINED';
const UNASSIGNED_IDEAS_GROUP = 'ideas-UNDEFINED';

export const GroupIcon = ({
	grouping,
	isExportMode,
	url = '',
	hasChildGroups = false,
	groupName,
	customFieldsById,
}: GroupIconProps) => {
	const { formatMessage } = useIntl();
	if (hasChildGroups) {
		return <FolderIcon label={formatMessage(messages.folderIconLabel)} />;
	}
	if (grouping === GROUPING.TEAM) {
		// team avatars are not supported in export mode so we render an AK icon instead
		if (url && !isExportMode) {
			return <Avatar size="small" src={url} />;
		}

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.defaultTeamAvatar}>
				<PeopleIcon label={formatMessage(messages.peopleIconLabel)} size="small" />
			</div>
		);
	}

	if (isRoadmapGroupedByCustomField(grouping)) {
		const fieldId = parseInt(getCustomFieldIdFromCustomFieldGrouping(grouping), 10);

		if (
			!isNil(customFieldsById) &&
			!isNil(customFieldsById[fieldId]) &&
			customFieldsById[fieldId].type.key === CustomFieldTypes.UserPicker
		) {
			if (isExportMode) {
				return <AvatarInitials size={24} name={groupName} />;
			}
			return <Avatar size="small" src={url} />;
		}

		return <FolderIcon key={grouping} label={formatMessage(messages.folderIconLabel)} />;
	}

	if (typeof groupIcons[grouping] !== 'undefined') {
		return <>{groupIcons[grouping]}</>;
	}

	if (isExportMode) {
		return <AvatarInitials size={24} name={groupName} />;
	}
	// Always display Avatar if group by "Assignee" or group by "Project" and url is not null,undefined,empty string
	if (grouping === GROUPING.ASSIGNEE || !!url) {
		return <Avatar size="small" src={url} />;
	}
	return null;
};

const getTeamName = (id: string | null | undefined, allTeams: Team[]) => {
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const team = allTeams.find((team) => team.id === id);
	return team ? team.title : null;
};

const getGroupName = ({
	item,
	allTeams,
	sprints,
	formatMessage,
}: {
	item: TableItem;
	allTeams: Team[];
	sprints: Sprint[];
	formatMessage: FormatMessage;
}) => {
	if (item.tag !== TABLE_GROUP) {
		throw new Error(
			`renderGroup must be called only for TABLE_GROUP item, but ${item.tag} was provided.`,
		);
	}

	let { value: groupName } = item;
	const { grouping, group, childGroups } = item;

	if (group === UNDEFINED_GROUP) {
		groupName = formatMessage(commonMessages.unassigned);
	} else if (!groupName) {
		switch (grouping) {
			case GROUPING.COMPONENT: {
				groupName = formatMessage(commonMessages.allOtherIssues);
				break;
			}
			case GROUPING.LABEL: {
				groupName = formatMessage(commonMessages.allOtherIssues);
				break;
			}

			case GROUPING.GOALS: {
				groupName = formatMessage(commonMessages.allOtherIssues);
				break;
			}

			case GROUPING.IDEAS: {
				groupName = formatMessage(commonMessages.allOtherIssues);
				break;
			}

			case GROUPING.TEAM: {
				const teamId = get(item, ['groupCombination', 'team']);
				const teamName = getTeamName(teamId, allTeams);
				groupName = !isNil(teamName) ? teamName : formatMessage(messages.unknownTeam);
				break;
			}
			case GROUPING.RELEASE: {
				if (item.groupCombination.release === COMPLETED_RELEASES_GROUP) {
					groupName = formatMessage(messages.completedReleasesGroup, {
						count: childGroups ? childGroups.length : '',
					});
				}
				break;
			}
			case GROUPING.PROJECT: {
				groupName = formatMessage(commonMessages.unassigned);
				break;
			}
			case isRoadmapGroupedByCustomField(grouping) && grouping: {
				groupName = formatMessage(commonMessages.allOtherIssues);
				break;
			}
			default: {
				groupName = formatMessage(commonMessages.unassigned);
				break;
			}
		}
	}
	if (grouping === GROUPING.SPRINT) {
		if (item.groupCombination.sprint === COMPLETED_SPRINTS_GROUP) {
			groupName = formatMessage(messages.completedSprintsGroup, {
				count: childGroups
					? Object.keys(
							groupBy(
								childGroups,
								// eslint-disable-next-line @typescript-eslint/no-shadow
								(group) => (group.groupCombination && group.groupCombination.sprint) || '',
							),
						).length
					: '',
			});
		} else {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const sprint = sprints.find((sprint) => {
				const sprintId = get(item, ['groupCombination', 'sprint']);
				return sprint.id === sprintId;
			});
			groupName = sprint && sprint.title ? sprint.title : formatMessage(commonMessages.unassigned);
		}
	}
	return groupName;
};

const getSecondaryGroupName = (
	item: TableItem,
	enrichedVersionsById: EnrichedVersionsById,
	allTeams: Team[],
) => {
	if (item.tag !== TABLE_GROUP) {
		throw new Error(
			`renderGroup must be called only for TABLE_GROUP item, but ${item.tag} was provided.`,
		);
	}

	let secondaryGroupName;
	const { grouping } = item;
	if (grouping === GROUPING.RELEASE) {
		const versionId = get(item, ['groupCombination', 'release']);
		const release = !isNil(versionId) ? enrichedVersionsById[versionId] : undefined;
		secondaryGroupName =
			release &&
			(release.projects
				.filter(Boolean)
				.map((project: number | Project) => (typeof project !== 'number' ? project.name : null))
				.join(', ') ||
				null);
	}
	if (grouping === GROUPING.SPRINT && item.groupCombination.sprint !== COMPLETED_SPRINTS_GROUP) {
		secondaryGroupName = getTeamName(item.groupCombination.team, allTeams);
	}
	return secondaryGroupName;
};

const getLozengeTextForGroupByRelease = (
	item: TableItem,
	enrichedVersionsById: EnrichedVersionsById,
	crossProjectVersionsById: CrossProjectVersionsById,
) => {
	if (item.tag !== TABLE_GROUP) {
		throw new Error(
			`renderGroup must be called only for TABLE_GROUP item, but ${item.tag} was provided.`,
		);
	}

	const versionId = get(item, ['groupCombination', 'release']);
	const release = !isNil(versionId) ? enrichedVersionsById[versionId] : undefined;
	return (
		release?.crossProjectVersion &&
		get(crossProjectVersionsById, [release.crossProjectVersion, 'name'])
	);
};

export const AssociatedIssuesWrapper = ({ id }: { id: string }) => {
	const [associatedIssues] = useAssociatedIssues();
	if (!associatedIssues || !associatedIssues[id]) {
		return null;
	}
	return <AssociatedIssuesView associatedIssues={[associatedIssues[id]]} />;
};

function GroupHeaderComponent({
	item,
	toggleExpandGroup,
	allTeams,
	additionalTeamsById,
	isExportMode,
	componentGroupsById,
	componentsById,
	projectsById,
	enrichedVersionsById,
	crossProjectVersionsById,
	groupName,
	customFieldsById,
	lazyGoalsByARI,
	associatedIssues,
	viewMode,
}: PropsForGroupHeaderComponent) {
	const { formatMessage } = useIntl();
	if (item.tag !== TABLE_GROUP) {
		throw new Error(
			`renderGroup must be called only for TABLE_GROUP item, but ${item.tag} was provided.`,
		);
	}

	const secondaryGroupName = getSecondaryGroupName(item, enrichedVersionsById, allTeams);
	const lozengeText = getLozengeTextForGroupByRelease(
		item,
		enrichedVersionsById,
		crossProjectVersionsById,
	);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let componentNames: Array<any> | Array<string> = [];

	const { grouping, parentGroup, childGroups } = item;

	if (grouping === GROUPING.COMPONENT) {
		const componentGroup = componentGroupsById[item.groupCombination.components || ''];
		const { components = [] } = componentGroup;

		componentNames = components
			.map((componentId) => componentsById[componentId])
			.filter((component) => !isNil(component))
			.map(({ name, projectId }) => `${name} (${projectsById[projectId].key})`);
	}

	const isExternalTeam =
		!isNil(item.groupCombination.team) && !isNil(additionalTeamsById[item.groupCombination.team]);

	const toggle = () =>
		toggleExpandGroup({
			grouping: item.grouping,
			groupName: item.group,
			expand: !item.isExpanded,
		});

	const onKeyDownHandler = (e: KeyboardEvent<EventTarget>) => {
		if (e.keyCode === 13 || e.keyCode === 32) {
			toggle();
		}
	};

	const renderTooltipForExternalTeam = () => (
		<Tooltip
			content={
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles['tooltip-wrapper']}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<p className={styles['tooltip-title']}>
						{formatMessage(planCommonMessages.externalTeamTooltipTitle)}
					</p>
					<p>{formatMessage(planCommonMessages.externalTeamTooltipContent)}</p>
				</div>
			}
		>
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={isExportMode ? styles.exportPNGLozenge : styles['external-lozenge-wrapper']}
			>
				<Lozenge>{formatMessage(planCommonMessages.externalLozenge)}</Lozenge>
			</div>
		</Tooltip>
	);

	const isShareableTeamFromGroupItem = () => {
		if (!isNil(item.groupCombination.team)) {
			const teamId = item.groupCombination.team;
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const team = allTeams.find((team) => team.id === teamId);
			return team ? team.shareable : false;
		}
		return false;
	};

	const renderTooltipForPlanOnlyTeam = () => {
		const isShareableTeam = isShareableTeamFromGroupItem();
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { grouping, value } = item;

		if (grouping !== GROUPING.TEAM || value === '') {
			return null;
		}

		if (isExternalTeam || isShareableTeam) {
			return null;
		}

		return (
			<Tooltip
				content={
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles['tooltip-wrapper']}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<p className={styles['tooltip-title']}>
							{formatMessage(planCommonMessages.planOnlyTeamTooltipTitle)}
						</p>
						<p>{formatMessage(planCommonMessages.planOnlyTeamTooltipContent)}</p>
					</div>
				}
			>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={isExportMode ? styles.exportPNGLozenge : styles['external-lozenge-wrapper']}
				>
					<Lozenge>{formatMessage(planCommonMessages.planOnlyLozenge)}</Lozenge>
				</div>
			</Tooltip>
		);
	};

	const hasParentGroup = !!parentGroup;
	const hasChildGroups = !!childGroups;

	const renderGroupName = () => (
		<>
			<span>{groupName || ''}</span>
			{!isNil(secondaryGroupName) && <small> ({secondaryGroupName})</small>}
		</>
	);

	const renderGroupNameContent = () => {
		const { group } = item;

		if (grouping === GROUPING.GOALS && group !== UNASSIGNED_GOALS_GROUP) {
			// Goal ARI is the group with a prefix removed
			const goalARI = group.replace('goals-', '');
			const { isLoading, goal } = lazyGoalsByARI[goalARI] || {};

			if (isLoading) {
				return <CellSkeleton width={DEFAULT_FIELD_WIDTH.MEDIUM} />;
			}

			if (goal) {
				const { name, state } = goal;

				if (name && state) {
					return <Goal name={name} state={state} isExportMode={isExportMode} />;
				}
			}
			return null;
		}

		if (grouping === GROUPING.IDEAS && group !== UNASSIGNED_IDEAS_GROUP) {
			if (ff('polaris.possibility-of-updating-ideas-in-plans')) {
				// Idea ID is the group with a prefix removed
				const ideaId = group.replace('ideas-', '');
				return <AssociatedIssuesWrapper id={ideaId} />;
			}
			return <AssociatedIssuesWrapper id={groupName} />;
		}

		return renderGroupName();
	};

	const getTooltipContent = () => {
		if (grouping === GROUPING.GOALS) {
			return lazyGoalsByARI[groupName]?.goal?.name || groupName;
		}
		if (grouping === GROUPING.IDEAS && associatedIssues) {
			return associatedIssues[groupName]?.summary || groupName;
		}
		return groupName;
	};

	const isTransposed =
		ff('com.atlassian.rm.jpo.transposition') &&
		(viewMode === VIEW_MODES.LIST || ff('com.atlassian.rm.jpo.transposition.m2'));
	const className = isTransposed ? 'group-details-transposed' : 'group-details';

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles[className]}
			role="button"
			tabIndex={0}
			onClick={toggle}
			onKeyDown={onKeyDownHandler}
			data-role="header"
			data-testid="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.group.group-header"
			aria-expanded={item.isExpanded}
		>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<span className={`${styles['group-chevron']} ${hasParentGroup ? styles.hasParentGroup : ''}`}>
				{item.isExpanded ? (
					<ChevronDown label="" LEGACY_size="large" color="currentColor" />
				) : (
					<ChevronRight label="" LEGACY_size="large" color="currentColor" />
				)}
			</span>

			{grouping !== GROUPING.GOALS && grouping !== GROUPING.IDEAS && (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<span className={styles.groupingAvatar}>
					<GroupIcon
						customFieldsById={customFieldsById}
						grouping={item.grouping}
						url={item.url}
						isExportMode={isExportMode}
						hasChildGroups={hasChildGroups}
						groupName={groupName || ''}
					/>
				</span>
			)}
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles['group-title']}>
				<Tooltip
					position="mouse"
					content={
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.tooltip}>
							<Heading as="h6" size="xxsmall">
								{getTooltipContent() || ''}
							</Heading>
							{componentNames.length !== 0 && <p>{componentNames.join(', ')}</p>}
							{!isNil(secondaryGroupName) && <p>({secondaryGroupName})</p>}
						</div>
					}
				>
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={`group-name ${styles.groupName}`}
						id={`group-name-${item.group.replace(/\s/g, '')}`}
					>
						{renderGroupNameContent()}
					</div>
				</Tooltip>
			</div>
			{isExternalTeam ? renderTooltipForExternalTeam() : null}
			{renderTooltipForPlanOnlyTeam()}
			{lozengeText && (
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={isExportMode ? styles.exportPNGLozenge : styles['external-lozenge-wrapper']}
				>
					<Lozenge>{lozengeText}</Lozenge>
				</div>
			)}
		</div>
	);
}
const SortableGroupHeaderComponent = SortableElement(GroupHeaderComponent);

const Group = ({
	item,
	allTeams,
	sprints,
	isStickyGroupHeader = false,
	style,
	key = '',
	index,
	lazyGoalsByARI,
	...props
}: Props) => {
	const { group } = item;
	const { formatMessage } = useIntl();
	if (item.tag !== TABLE_GROUP) {
		throw new Error(
			`renderGroup must be called only for TABLE_GROUP item, but ${item.tag} was provided.`,
		);
	}
	const [associatedIssues] = useAssociatedIssues();
	const groupName = getGroupName({ item, allTeams, sprints, formatMessage }) ?? '';
	const elementKey = `${item.tag}-${group || ''}-${groupName || key || ''}`;
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div key={elementKey} {...(style && { style })} className={styles.groupRow}>
			{isStickyGroupHeader ? (
				<GroupHeaderComponent
					item={item}
					groupName={groupName}
					allTeams={allTeams}
					lazyGoalsByARI={lazyGoalsByARI}
					associatedIssues={associatedIssues}
					{...props}
				/>
			) : (
				<SortableGroupHeaderComponent
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					index={index as number}
					item={item}
					groupName={groupName}
					allTeams={allTeams}
					lazyGoalsByARI={lazyGoalsByARI}
					associatedIssues={associatedIssues}
					{...props}
				/>
			)}
		</div>
	);
};

export default Group;
