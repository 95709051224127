import React, {
	createContext,
	useEffect,
	useCallback,
	useRef,
	useContext,
	type ReactElement,
	type Ref,
} from 'react';

type ScrollContainerProps<Element extends HTMLElement> = {
	children: (ref: Ref<Element>) => ReactElement;
};

type ScrollLockContextValue = {
	setScrollLock: (locked: boolean) => void;
};

export const ScrollLockContext = createContext<ScrollLockContextValue>({
	setScrollLock: () => undefined,
});

export const ScrollContainer = <Element extends HTMLElement>({
	children,
}: ScrollContainerProps<Element>) => {
	// Lock count is used to keep track of how many components are requesting scroll lock.
	// In the case of the nested menu, there should be only one scroll lock at a time, however this guards against
	// potential race conditions where a new component requests a lock before the previous one has released it.
	const lockCount = useRef<number>(0);
	const ref = useRef<Element>(null);

	const setScrollLock = useCallback(
		(locked: boolean) => {
			if (locked) lockCount.current += 1;
			else lockCount.current -= 1;

			if (lockCount.current < 0) lockCount.current = 0;

			if (ref.current) ref.current.style.overflow = lockCount.current > 0 ? 'hidden' : '';
		},
		[lockCount],
	);

	return (
		<ScrollLockContext.Provider value={{ setScrollLock }}>
			{children(ref)}
		</ScrollLockContext.Provider>
	);
};

export const useScrollLock = (locked: boolean) => {
	const { setScrollLock } = useContext(ScrollLockContext);

	useEffect(() => {
		setScrollLock(locked);

		return () => setScrollLock(false);
	}, [locked, setScrollLock]);
};
