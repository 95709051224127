import type { Version } from '../versions/types.tsx';

export const RESET = 'state.domain.removed-versions.RESET' as const;
export const ADD = 'state.domain.removed-versions.ADD' as const;
export const REMOVE = 'state.domain.removed-versions.REMOVE' as const;

export type ResetActionPayload = Version[];

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type AddActionPayload = Version;

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export type RemoveAction = {
	type: typeof REMOVE;
	versionId: string;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const add: (arg1: AddActionPayload) => AddAction = (payload) => ({
	type: ADD,
	payload,
});

export const remove = (versionId: string): RemoveAction => ({
	type: REMOVE,
	versionId,
});
