import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
	type ComponentType,
	type ReactNode,
} from 'react';
import noop from 'lodash/noop';
import { xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import ScrollLongTaskAnalytics from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/scroll-long-task-analytics/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import type {
	ColumnConfig,
	RowConfig,
	RangeExtractor,
} from '@atlassian/jira-portfolio-3-treegrid/src/common/types.tsx';
import { Container } from '@atlassian/jira-portfolio-3-treegrid/src/ui/container/index.tsx';
import { Grid } from '@atlassian/jira-portfolio-3-treegrid/src/ui/grid/index.tsx';
import { Row } from '@atlassian/jira-portfolio-3-treegrid/src/ui/row/index.tsx';
import BodyRow from './body-row/view.tsx';
import HeaderRow from './header-row/index.tsx';
import messages from './messages.tsx';
import type { CustomColumnConfig, Props } from './types.tsx';
import VisuallyHiddenAnnouncement from './visually-hidden-announcement/index.tsx';

const stickyOffset = {
	vertical: 0,
	horizontal: 0,
};

export default function ChangesTable({
	changes,
	isUpdating,
	commitWarningsExists,
	isReadOnly,
	changesCount,
}: Props) {
	const { formatMessage } = useIntl();
	const [gridRows, setGridRows] = useState<
		{
			tag: string;
			content: string;
		}[]
	>([]);
	const [rowConfig, setRowConfig] = useState<RowConfig[]>([]);
	const [columnConfig, setColumnConfig] = useState<CustomColumnConfig[]>([]);

	const colConfigs = useCallback(() => {
		const configs = [
			{
				id: 'details',
				sticky: false,
				name: formatMessage(messages.titleColumnHeader),
				width: 120,
			},
			{
				id: 'category',
				sticky: false,
				name: formatMessage(messages.categoryColumnHeader),
				width: 40,
			},
			{
				id: 'type',
				sticky: false,
				name: formatMessage(messages.whatChangedColumnHeader),
				width: 45,
			},
			{ id: 'current', sticky: false, name: '', width: 32 },
			{ id: 'new', sticky: false, name: formatMessage(commonMessages.new), width: 32 },
			{
				id: 'lastModified',
				sticky: false,
				name: formatMessage(messages.lastModifiedColumnHeader),
				width: 28,
			},
		];
		if (!isReadOnly) {
			configs.unshift({ id: 'isSelected', sticky: false, name: '', width: 7 });
		}
		if (commitWarningsExists) {
			configs.unshift({ id: 'warnings', sticky: false, name: '', width: 7 });
		}
		return configs;
	}, [isReadOnly, commitWarningsExists, formatMessage]);

	useEffect(() => {
		const rowsList = [
			{
				tag: 'HEADER',
				content: 'header',
			},
		];
		const rowConfigs = [
			{
				sticky: true,
				height: 25,
				thead: true,
				top: 0,
			},
		];
		changes.forEach((x, i) => {
			rowsList.push({
				tag: 'BODY',
				content: `content${i + 1}`,
			});
			rowConfigs.push({
				sticky: false,
				height: 30,
				thead: false,
				top: 25 + i * 30,
			});
		});
		setGridRows(rowsList);
		setRowConfig(rowConfigs);
		setColumnConfig(colConfigs());
	}, [changes, colConfigs]);

	const renderRow = (_row: RowConfig, _columns: ColumnConfig[], index: number): ReactNode => {
		const row = gridRows[index];
		if (row.tag === 'HEADER') {
			return (
				<HeaderRow
					index={index}
					columns={_columns}
					columnConfig={columnConfig}
					changes={changes}
					commitWarningsExists={commitWarningsExists}
					isReadOnly={isReadOnly}
					isUpdating={isUpdating}
				/>
			);
		}
		return (
			<BodyRow
				key={index}
				rowIndex={index}
				columnConfig={columnConfig}
				change={changes[index - 1]}
				isUpdating={isUpdating}
			/>
		);
	};

	const columnWidths = useMemo((): number[] => {
		const widths: number[] = [];
		columnConfig.forEach((col) => {
			widths.push(col.width);
		});
		return widths;
	}, [columnConfig]);

	const emptyRow = (height?: number): ReactNode => <Row index={-1} height={height} />;

	const uniq = (a: number[]) => Array.from(new Set(a));

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const makeRangeExtractor =
		(_rowConfig: RowConfig[]): RangeExtractor =>
		({ above, within, below }) => {
			for (let cursor = (within[0] ?? 0) - 1; cursor >= 0; cursor--) {
				if (_rowConfig[cursor].sticky) {
					const additional = above.includes(cursor) ? [] : [cursor];
					return uniq([0, ...additional, ...above, ...within, ...below]);
				}
			}
			return uniq([0, ...above, ...within, ...below]);
		};

	const rangeExtractor = useMemo(
		() => makeRangeExtractor(rowConfig),
		[makeRangeExtractor, rowConfig],
	);

	return (
		<Container xcss={[gridContainerStyles]} data-scroll="lockable">
			<VisuallyHiddenAnnouncement changes={changes} changesCount={changesCount} />
			<Grid
				columnWidths={columnWidths}
				columnConfigs={columnConfig}
				rowConfigs={rowConfig}
				row={renderRow}
				empty={emptyRow}
				overscan={10}
				onColumnWidthsChange={noop}
				stickyOffset={stickyOffset}
				ariaLabel={formatMessage(messages.tableAriaLabel)}
				role="grid"
				rangeExtractor={rangeExtractor}
				testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table"
				dataName="update-jira-dialog-content"
			>
				{(table) => <>{table}</>}
			</Grid>
		</Container>
	);
}

export const withScrollTracking =
	<C extends ComponentType<any>>( // eslint-disable-line @typescript-eslint/no-explicit-any
		WrappedComponent: C,
	): ComponentType<any> => // eslint-disable-line @typescript-eslint/no-explicit-any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(props: any) => (
		<ScrollLongTaskAnalytics
			// @ts-expect-error Argument of type 'UIAnalyticsEvent' is not assignable to parameter of type 'InteractionMetricType'.
			analyticsEvent={PRODUCT_ANALYTICS_EVENT_NAMES.COMMIT_MODAL_SCROLLED}
		>
			{(handleScroll: () => void) => <WrappedComponent {...props} handleScroll={handleScroll} />}
		</ScrollLongTaskAnalytics>
	);

const gridContainerStyles = xcss({
	flex: '1 1 auto',
	overflowY: 'auto',
	marginTop: 'space.200',
	marginBottom: 'space.200',
	marginLeft: 'space.0',
	marginRight: 'space.0',
});
