.nowLineStaticAxisWrapper {
	position: absolute;
	top: 0;
	bottom: 0;
}

.nowLineStaticAxisSubWrapper {
	display: flex;
	justify-content: center;
}

.nowLine {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 4; /* remove this when cleaning up com.atlassian.rm.jpo.transposition.m2 */
	pointer-events: none;
	border-left: 2px solid var(--ds-background-accent-orange-subtle, var(--adg3-color-Y400));
	width: 2px;
}

.marker::before {
	content: '';
	position: sticky;
	height: 0;
	width: 0;
	top: 0;
	margin-left: -5px;
	border-left: 4px solid transparent;
	border-right: 4px solid transparent;
	border-top: 8px solid var(--ds-background-accent-orange-subtle, var(--adg3-color-Y400));
}
