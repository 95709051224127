.planningFields {
	display: flex;
	gap: calc(var(--jpo-common-base-spacing) * 2);

	/* allowing children fields to grow as much as their container allows */
	& > div {
		flex: 1;
	}
}

.hiddenSubmitButton {
	display: none;
}

.teamFormHeading {
	margin-bottom: var(--jpo-common-base-spacing);
	margin-top: calc(var(--jpo-common-base-spacing) * 3);
}

.teamInfoHeadingCont {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: var(--jpo-common-base-spacing);
}

.planningStyleOption {
	display: flex;
	justify-content: space-between;
}

.explainRequiredFields {
	color: var(--ds-text-subtle, #42526e);
	margin-bottom: calc(var(--jpo-common-base-spacing) * 3);
}
