import type { Entity } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Warning } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types.tsx';

export const ADD_BULK = 'state.domain.update-jira.changes-warnings.ADD_BULK' as const;

export const RESET = 'state.domain.update-jira.changes-warnings.RESET' as const;

export type AddActionPayload = {
	category: Entity;
	itemId: string;
	warnings: Warning[];
};

export type AddBulkActionPayload = AddActionPayload[];

export type AddBulkAction = {
	type: typeof ADD_BULK;
	payload: AddBulkActionPayload;
};

export const addBulk = (payload: AddBulkActionPayload) => ({
	type: ADD_BULK,
	payload,
});

export type ResetActionPayload = {
	category: Entity;
};

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});
