.form-group {
	display: flex;
}

.current-value-title {
	max-width: calc(var(--jpo-common-base-spacing) * 24);
	overflow: hidden;
	text-overflow: ellipsis;
}

.icon {
	padding-left: calc(var(--jpo-common-base-spacing) * 2);
	margin-top: 36px;
	width: 80px;
	text-align: center;
}

.new-value {
	width: 280px;
	margin-left: calc(var(--jpo-common-base-spacing) * 2);
}

.value-label {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	font-weight: 600;
	margin-top: 8px;
	margin-bottom: 4px;
}

.value-placeholder {
	padding-top: 8px;
	width: 136px;
}

.current-container {
	display: flex;
	flex-wrap: wrap;
}
