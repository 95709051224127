import type { SaveViewAsDialogStatus, Error } from './types.tsx';

export const ADD_ERROR = 'state.ui.top.view-bar.ADD_ERROR' as const;
export const REMOVE_ERROR = 'state.ui.top.view-bar.REMOVE_ERROR' as const;
export const SET_SAVE_VIEW_AS_DIALOG_STATUS =
	'state.ui.top.view-bar.SET_SAVE_VIEW_AS_DIALOG_STATUS' as const;

export type AddErrorAction = {
	type: typeof ADD_ERROR;
	payload: Error;
};

export type RemoveErrorAction = {
	type: typeof REMOVE_ERROR;
	payload: Error;
};

export type SetSaveViewAsDialogStatusAction = {
	type: typeof SET_SAVE_VIEW_AS_DIALOG_STATUS;
	payload: SaveViewAsDialogStatus;
};

export const addError = (error: Error) => ({
	type: ADD_ERROR,
	payload: error,
});

export const removeError = (error: Error) => ({
	type: REMOVE_ERROR,
	payload: error,
});

export const setSaveViewAsDialogStatus = (status: SaveViewAsDialogStatus) => ({
	type: SET_SAVE_VIEW_AS_DIALOG_STATUS,
	payload: status,
});
