import type { IssueLinkType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/system/types.tsx';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import messages from './messages.tsx';

const DEFAULT_ISSUE_LINK_TYPE = 10000;

/**
 * Returns the id of the default Issue Link Type
 * At the moment is implemented to return the first one in the list, but this may change in the future.
 */
export const getDefaultIssueLinkTypeId = (issueLinkTypes: IssueLinkType[]): number =>
	issueLinkTypes.length > 0 ? issueLinkTypes[0].id : DEFAULT_ISSUE_LINK_TYPE;

/**
 * Returns dependency label corresponding to start or end position.
 */
export const getDependencyLabel = (
	startOrEnd: 'start' | 'end',
	formatMessage: FormatMessage,
): string =>
	startOrEnd === 'start'
		? formatMessage(messages.startDependency)
		: formatMessage(messages.endDependency);
