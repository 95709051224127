// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import '@atlassian/jira-portfolio-3-common/src/css-variables/variables.css';
import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import TextField from '@atlassian/jira-portfolio-3-common/src/textfield/index.tsx';
import AssociatedIssuesWrapper from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/associated-issues-provider/index.tsx';
import FieldDatePicker from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/component-slot-content/field-date-picker/index.tsx';
import FieldMultiSelectCell from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/component-slot-content/field-multi-select-cell/index.tsx';
import FieldUserAvatarCell from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/component-slot-content/field-user-avatar-cell/index.tsx';
import IssueSearchWarningClearFilter from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/component-slot-content/issue-search-warning-clear-filter/index.tsx';
import FlagGroup from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/flag-group/index.tsx';
import AdvancedFieldsMenu from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/header/advanced-fields-menu/index.tsx';
import NowLine from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/timeline/now/index.tsx';
import type { Props } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/types.tsx';
import RoadmapWrapper from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/roadmap-wrapper/index.tsx';
import Toolbar from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/page-header/toolbar/index.tsx';
import { provideComponents } from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import FilterDependencyGraphFromRoadmap from '@atlassian/jira-portfolio-3-portfolio/src/common/view/filter-dependency-graph-from-roadmap/index.tsx';
import QuickFilter from '@atlassian/jira-portfolio-3-portfolio/src/common/view/quick-filter/index.tsx';
import {
	CellSelectWithRollup,
	AsyncSelectForColumnCell as FieldAsyncSelect,
	SelectForColumnCell as FieldPrioritySelect,
	SelectForColumnCell as FieldSelect,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/index.tsx';
import UserPicker from '@atlassian/jira-portfolio-3-portfolio/src/common/view/user-picker/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PlanAppBase } from '../../common/ui/app-base/index.tsx';
import DocumentTitle from '../../common/ui/document-title/index.tsx';
import { PlanOnboarding } from '../../common/ui/onboarding/index.tsx';
import { HeaderBar } from './main.tsx';
import messages from './messages.tsx';
import { HeaderContainer, RoadmapContainer } from './styled.tsx';

const PlanTimeline = ({ store, ...props }: Props) => {
	const { formatMessage } = useIntl();
	const pageHeading = formatMessage(messages.timeline);

	return (
		<UFOSegment name="plan-timeline-app">
			<PlanAppBase
				appKey="spa-app.advanced-roadmaps-plan.timeline"
				store={store}
				withReduxStore
				paddingRight={false}
				paddingLeft={false}
				planView="timeline"
			>
				<AssociatedIssuesWrapper>
					<DocumentTitle pageHeading={pageHeading} />
					<HeaderContainer>
						<HeaderBar />
					</HeaderContainer>
					<RoadmapContainer>
						<RoadmapWrapper {...props} />
					</RoadmapContainer>
					<PlanOnboarding>
						<FlagGroup />
					</PlanOnboarding>
				</AssociatedIssuesWrapper>
			</PlanAppBase>
		</UFOSegment>
	);
};

// Rename this component to PlanTimeline when cleaning getWillShowNav4
const PlanTimelineWithoutPageHeader = ({ store, ...props }: Props) => {
	const { formatMessage } = useIntl();
	const pageHeading = formatMessage(messages.timeline);

	return (
		<UFOSegment name="plan-timeline-app">
			<PlanAppBase
				appKey="spa-app.advanced-roadmaps-plan.timeline"
				store={store}
				paddingRight={false}
				withReduxStore
				planView="timeline"
			>
				<AssociatedIssuesWrapper>
					<DocumentTitle pageHeading={pageHeading} />
					<Box xcss={toolbarStyles}>
						<Toolbar />
					</Box>
					<Box xcss={roadmapContainerStyles}>
						<RoadmapWrapper {...props} />
					</Box>
					<PlanOnboarding>
						<FlagGroup />
					</PlanOnboarding>
				</AssociatedIssuesWrapper>
			</PlanAppBase>
		</UFOSegment>
	);
};

export default provideComponents<Props>({
	FieldSelect,
	FieldAsyncSelect,
	FieldPrioritySelect,
	FieldMultiSelectCell,
	FieldDatePicker,
	FieldUserAvatarCell,
	CellSelectWithRollup,
	CellTextField: TextField,
	AdvancedFieldsMenu,
	FilterDependencyGraphFromRoadmap,
	QuickFilter,
	IssueSearchWarningClearFilter,
	NowLine,
	UserPicker,
})(componentWithCondition(() => getWillShowNav4(), PlanTimelineWithoutPageHeader, PlanTimeline));

const toolbarStyles = xcss({
	paddingBlock: 'space.200',
	paddingRight: 'space.300',
});

const roadmapContainerStyles = xcss({
	borderTop: `1px solid ${token('color.border')}`,
	borderLeft: `1px solid ${token('color.border')}`,
	borderTopLeftRadius: token('space.050'),
	position: 'relative',
	flex: 1,
	display: 'flex',
});
