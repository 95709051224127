import uniqBy from 'lodash/uniqBy';
import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/assignees/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	type AssigneeFilter,
	ASSIGNEE_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import type { Person as ApiPerson } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getAssigneeList, getAssigneesOfIssues } from '../../assignees/index.tsx';
import { getTeamsPersons } from '../../persons/index.tsx';
import { getFiltersViewSettings } from '../../view-settings/index.tsx';
import { applyFilter as applyAttributeFilter } from '../attribute-filter/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { ASSIGNEE_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

export const applyFilter = (
	issue: Issue,
	filter: AssigneeFilter,
	optimizedMode: boolean,
): boolean => applyAttributeFilter(issue, filter.value, 'assignee', optimizedMode);

const getTeamsAssignees = createSelector(
	[getTeamsPersons, getAssigneeList],
	(persons: ApiPerson[], assignees: Person[]): Person[] => {
		const jiraUserIds = persons.reduce((acc, person) => {
			if (person.jiraUser) {
				acc.add(person.jiraUser.accountId);
			}
			return acc;
		}, new Set<string>());

		return assignees.filter((assignee) => jiraUserIds.has(assignee.jiraUser.accountId));
	},
);

export const getAssigneeForFilter = createSelector(
	[getTeamsAssignees, getAssigneesOfIssues],
	(teamsAssignees: Person[], issuesAssignees: Person[]): Person[] =>
		uniqBy<Person>(
			[...teamsAssignees, ...issuesAssignees],
			({ personId }: { personId: string }) => personId,
		),
);

export const getAssigneeFilterValue = (state: State): AssigneeFilter =>
	getFiltersViewSettings(state)[ASSIGNEE_FILTER_ID] || {
		id: ASSIGNEE_FILTER_ID,
		value: [],
	};

export const getAssigneeFilter = createSelector(
	[getAssigneeForFilter, getAssigneeFilterValue],
	(assignees, { id, value = [] }) => {
		const assigneeSet = new Set(assignees.map(({ personId }) => personId));

		return {
			id,
			value: value.filter((personId) => assigneeSet.has(personId)),
		};
	},
);
