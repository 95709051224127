.tabs-row-wrapper {
	& > div {
		flex-direction: row;
	}
	& > div > div:first-child {
		background: var(--ds-background-neutral, var(--adg3-color-N20));
	}
	& > div > div:first-child > span {
		display: none;
	}
	& > div > div:first-child > div {
		display: flex;
		flex-direction: column;
		padding: 0 calc(2 * var(--jpo-common-base-spacing)) calc(2 * var(--jpo-common-base-spacing));
		width: 240px;
	}
}

.tabs-container {
	padding-inline: var(--ds-space-100, 8px);
	width: 100%;
}
