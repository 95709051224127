import { getExpandedChanges } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Props, StateProps } from './types.tsx';

const querySelector = createStructuredSelector<State, Props, StateProps>({
	expandedChanges: getExpandedChanges,
});

export default querySelector;
