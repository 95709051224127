import React, { Component } from 'react';
import * as R from 'ramda';
import {
	getBody,
	removeNode,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import Select from '@atlassian/jira-portfolio-3-portfolio/src/common/view/select/view.tsx';
import { titleCase } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/string.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export default class TypeSelector extends Component<Props> {
	// eslint-disable-next-line react/sort-comp
	menuContainer: HTMLDivElement;

	constructor(props: Props) {
		super(props);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		this.menuContainer = document.createElement('div');
		this.menuContainer.style.pointerEvents = 'none';
		// menu container is stretched to the viewport size and clips menu
		// if it extends outside viewport to prevent breaking page layout and jumps
		this.menuContainer.classList.add(styles.menuContainer);
		getBody().appendChild(this.menuContainer);
	}

	componentWillUnmount() {
		removeNode(this.menuContainer);
	}

	onMenuOpen = () => {
		this.props.toggleDialogLocked(true);
	};

	onMenuClose = () => {
		this.props.toggleDialogLocked(false);
	};

	render() {
		const { addDependencyType, issueLinkTypes, direction, onChangeDependencyType, ariaLabel } =
			this.props;
		const options = issueLinkTypes.map((type) => ({
			label: titleCase(type[direction]),
			value: `${type.id}`,
			type,
		}));
		const value = addDependencyType
			? options.find(({ type }) => type.id === addDependencyType.id)
			: R.head(options);
		const select = (
			<>
				<Select
					value={value}
					menuPlacement="auto"
					menuPortalTarget={this.menuContainer}
					isDisabled={!value}
					isClearable={false}
					isSearchable={false}
					isTransparentBackground={false}
					options={options}
					onChange={onChangeDependencyType}
					styles={{
						// eslint-disable-next-line @typescript-eslint/no-shadow
						menuPortal: (styles) => ({
							...styles,
							zIndex: 1000,
							pointerEvents: 'initial',
						}),
					}}
					onMenuOpen={this.onMenuOpen}
					onMenuClose={this.onMenuClose}
					aria-label={ariaLabel}
				/>
			</>
		);
		return select;
	}
}
