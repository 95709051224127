import React, { Component } from 'react';
import Tags from '@atlassian/jira-portfolio-3-portfolio/src/common/view/tags/index.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export default class MultiSelectValues extends Component<Props> {
	getValue = (id: string) => {
		const { selectOptions } = this.props;
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const option = selectOptions.find((option) => option.id.toString() === id.toString());
		if (!option) {
			return {
				value: id,
			};
		}
		return option;
	};

	render() {
		const { value = [], attributeName } = this.props;
		const options = value.map(this.getValue).map((option) => option.value);
		const title = options.join(', ');
		return (
			<Tags
				tooltip={title}
				tags={options}
				isRemoved={attributeName.startsWith('customFieldValueRemoved')}
				width="150px"
			/>
		);
	}
}
