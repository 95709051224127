import * as R from 'ramda';
import {
	colourByOptions,
	colourPalette,
	priorityColourMap,
	statusColourMap,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import {
	ADD_COMPONENT_COLOUR,
	ADD_ISSUE_TYPE_COLOUR,
	ADD_LABEL_COLOUR,
	ADD_PRIORITY_COLOUR,
	ADD_SELECT_COLOUR,
	CLEAR_ALL_COMPONENT_COLOURS,
	CLEAR_ALL_ISSUE_TYPE_COLOURS,
	CLEAR_ALL_LABEL_COLOURS,
	CLEAR_ALL_PRIORITY_COLOURS,
	CLEAR_ALL_SELECT_COLOURS,
	REMOVE_COMPONENT_COLOUR,
	REMOVE_ISSUE_TYPE_COLOUR,
	REMOVE_LABEL_COLOUR,
	REMOVE_PRIORITY_COLOUR,
	REMOVE_SELECT_COLOUR,
	REMOVE_TEAM_ID,
	RESET,
	SET_COLOUR_BY_VALUE,
	SET_COLOUR_FOR_NEW_TEAM,
	SET_COLOUR_MAP,
	SET_PRIORITY_COLOUR_MAP,
	UPDATE_COMPONENT_COLOUR,
	UPDATE_ISSUE_TYPE_COLOUR,
	UPDATE_LABEL_COLOUR,
	UPDATE_PRIORITY_COLOUR,
	UPDATE_SELECT_COLOUR,
	UPDATE_TEAM_ID,
	type AddComponentAction,
	type AddIssueTypeColourAction,
	type AddLabelAction,
	type AddPriorityColourAction,
	type AddSelectColourAction,
	type ClearAllColourComponentsAction,
	type ClearAllColourIssueTypesAction,
	type ClearAllColourLabelsAction,
	type ClearAllColourPrioritiesAction,
	type ClearAllColourSelectsAction,
	type RemoveComponentColourAction,
	type RemoveIssueTypeColourAction,
	type RemoveLabelColourAction,
	type RemovePriorityColourAction,
	type RemoveSelectColourAction,
	type RemoveTeamIdAction,
	type ResetAction,
	type SetColourByValueAction,
	type SetColourForNewTeamAction,
	type SetColourMapAction,
	type SetPriorityColourMapAction,
	type UpdateComponentColourAction,
	type UpdateIssueTypeColourAction,
	type UpdateLabelColourAction,
	type UpdatePriorityColourAction,
	type UpdateSelectColourAction,
	type UpdateTeamIdAction,
} from './actions.tsx';
import type {
	ColourByState,
	ColourPriorityV0,
	ColourIssueType,
	ColourLabel,
	ColourComponent,
	ColourPriority,
	ColourSelect,
} from './types.tsx';

type Action =
	| AddComponentAction
	| AddIssueTypeColourAction
	| AddLabelAction
	| AddPriorityColourAction
	| AddSelectColourAction
	| ClearAllColourComponentsAction
	| ClearAllColourIssueTypesAction
	| ClearAllColourLabelsAction
	| ClearAllColourPrioritiesAction
	| ClearAllColourSelectsAction
	| RemoveComponentColourAction
	| RemoveIssueTypeColourAction
	| RemoveLabelColourAction
	| RemovePriorityColourAction
	| RemoveSelectColourAction
	| RemoveTeamIdAction
	| ResetAction
	| SetColourByValueAction
	| SetColourForNewTeamAction
	| SetColourMapAction
	| SetPriorityColourMapAction
	| UpdateComponentColourAction
	| UpdateIssueTypeColourAction
	| UpdateLabelColourAction
	| UpdatePriorityColourAction
	| UpdateSelectColourAction
	| UpdateTeamIdAction;

export const initialState: ColourByState = {
	colourByValue: colourByOptions.NONE,
	colourComponents: [],
	colourIssueTypes: [],
	colourIssueTypeKeys: [],
	colourLabels: [],
	colourMaps: {
		none: {},
		project: {},
		status: statusColourMap,
		team: {},
	},
	colourPriorities: [],
	colourSelects: {},
	lastUsed: 0,
};

export const reducer = (state: ColourByState = initialState, action: Action): ColourByState => {
	switch (action.type) {
		case RESET: {
			return { ...action.payload };
		}

		case SET_COLOUR_BY_VALUE: {
			const { id } = action.payload;
			return { ...state, colourByValue: id };
		}

		case SET_COLOUR_MAP: {
			const { id, map } = action.payload;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return { ...state, colourMaps: { ...state.colourMaps, [id as string]: map } };
		}

		case UPDATE_TEAM_ID: {
			const { oldId, newId } = action.payload;
			const map = state.colourMaps.team;
			const colour = map[oldId];
			return {
				...state,
				colourMaps: {
					...state.colourMaps,
					team: { ...R.dissoc(oldId, map), [newId]: colour },
				},
			};
		}

		case SET_COLOUR_FOR_NEW_TEAM: {
			const { id } = action.payload;
			const { lastUsed, colourMaps } = state;
			const nextColourIndex = (lastUsed + 1) % colourPalette.length;
			const colour = colourPalette[nextColourIndex];
			return {
				...state,
				colourMaps: {
					...state.colourMaps,
					team: { ...colourMaps.team, [id]: colour },
				},
				lastUsed: nextColourIndex,
			};
		}

		case REMOVE_TEAM_ID: {
			const { id } = action.payload;
			return {
				...state,
				colourMaps: {
					...state.colourMaps,
					team: R.dissoc(id, state.colourMaps.team),
				},
			};
		}

		case ADD_ISSUE_TYPE_COLOUR: {
			return { ...state, colourIssueTypes: [...state.colourIssueTypes, { issueTypes: [] }] };
		}

		case UPDATE_ISSUE_TYPE_COLOUR: {
			const { index, ...patch } = action.payload;
			return R.evolve(
				{
					colourIssueTypes: R.adjust(index, (item: ColourIssueType) => ({
						...item,
						...patch,
					})),
				},
				state,
			);
		}

		case REMOVE_ISSUE_TYPE_COLOUR: {
			const { index } = action.payload;
			return R.evolve({ colourIssueTypes: R.remove<ColourIssueType>(index, 1) }, state);
		}

		case ADD_LABEL_COLOUR: {
			return { ...state, colourLabels: [...state.colourLabels, { labels: [] }] };
		}

		case UPDATE_LABEL_COLOUR: {
			const { index, ...patch } = action.payload;
			return R.evolve(
				{ colourLabels: R.adjust(index, (item: ColourLabel) => ({ ...item, ...patch })) },
				state,
			);
		}

		case REMOVE_LABEL_COLOUR: {
			const { index } = action.payload;
			return R.evolve({ colourLabels: R.remove<ColourLabel>(index, 1) }, state);
		}

		case ADD_COMPONENT_COLOUR: {
			return { ...state, colourComponents: [...state.colourComponents, { components: [] }] };
		}

		case UPDATE_COMPONENT_COLOUR: {
			const { index, ...patch } = action.payload;
			return R.evolve(
				{
					colourComponents: R.adjust(index, (item: ColourComponent) => ({
						...item,
						...patch,
					})),
				},
				state,
			);
		}

		case REMOVE_COMPONENT_COLOUR: {
			const { index } = action.payload;
			return R.evolve({ colourComponents: R.remove<ColourComponent>(index, 1) }, state);
		}

		case ADD_PRIORITY_COLOUR: {
			return { ...state, colourPriorities: [...state.colourPriorities, { priorities: [] }] };
		}

		case SET_PRIORITY_COLOUR_MAP: {
			const { chunks, issuePrioritiesIdList } = action.payload;
			const priorities: Array<ColourPriorityV0> = [];
			let start = 0;
			priorityColourMap.forEach((colour, index) => {
				priorities.push({
					colour,
					priorities: issuePrioritiesIdList.slice(start, start + chunks[index]),
				});
				start += chunks[index];
			});
			return { ...state, colourPriorities: priorities };
		}

		case UPDATE_PRIORITY_COLOUR: {
			const { index, ...patch } = action.payload;
			return R.evolve(
				{
					colourPriorities: R.adjust(index, (item: ColourPriority) => ({
						...item,
						...patch,
					})),
				},
				state,
			);
		}

		case REMOVE_PRIORITY_COLOUR: {
			const { index } = action.payload;
			return R.evolve({ colourPriorities: R.remove<ColourPriority>(index, 1) }, state);
		}

		case ADD_SELECT_COLOUR: {
			return {
				...state,
				colourSelects: {
					...state.colourSelects,
					[state.colourByValue]: [
						...(state.colourSelects[state.colourByValue] || []),
						{
							options: [],
						},
					],
				},
			};
		}

		case UPDATE_SELECT_COLOUR: {
			const { index, ...patch } = action.payload;
			return R.evolve(
				{
					colourSelects: {
						[state.colourByValue]: R.adjust(index, (item: ColourSelect) => ({
							...item,
							...patch,
						})),
					},
				},
				state,
			);
		}

		case REMOVE_SELECT_COLOUR: {
			const { index } = action.payload;
			return R.evolve(
				{ colourSelects: { [state.colourByValue]: R.remove<ColourSelect>(index, 1) } },
				state,
			);
		}

		case CLEAR_ALL_COMPONENT_COLOURS: {
			return { ...state, colourComponents: [] };
		}

		case CLEAR_ALL_ISSUE_TYPE_COLOURS: {
			return { ...state, colourIssueTypes: [] };
		}

		case CLEAR_ALL_LABEL_COLOURS: {
			return { ...state, colourLabels: [] };
		}

		case CLEAR_ALL_PRIORITY_COLOURS: {
			return { ...state, colourPriorities: [] };
		}

		case CLEAR_ALL_SELECT_COLOURS: {
			return {
				...state,
				colourSelects: {
					...state.colourSelects,
					[state.colourByValue]: [],
				},
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

export default reducer;
