import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	widthLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.width-label',
		defaultMessage: 'Timeline width',
		description: 'Label of the timeline setting width controls',
	},
	smallLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.small-label',
		defaultMessage: 'Small',
		description: 'Label of the timeline setting small width button.',
	},
	mediumLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.medium-label',
		defaultMessage: 'Medium',
		description: 'Label of the timeline setting medium width button.',
	},
	largeLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.large-label',
		defaultMessage: 'Large',
		description: 'Label of the timeline setting large width button.',
	},
	customLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.custom-label',
		defaultMessage: 'Custom',
		description: 'Label of the timeline setting custom width button.',
	},
	customWidthPixelLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.custom-width-pixel-label',
		defaultMessage: 'px',
		description: 'Pixel value label for custom width text box',
	},
	hideTimelineTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.hide-timeline-tooltip',
		defaultMessage: 'Hide timeline',
		description: 'Tooltip of the hide timeline button',
	},
	smallTimelineTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.small-timeline-tooltip',
		defaultMessage: 'Small timeline',
		description: 'Tooltip for the small timeline button',
	},
	mediumTimelineTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.medium-timeline-tooltip',
		defaultMessage: 'Medium timeline',
		description: 'Tooltip for the medium timeline button',
	},
	largeTimelineTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.large-timeline-tooltip',
		defaultMessage: 'Large timeline',
		description: 'Tooltip for the large timeline button',
	},
	customWidthPixelLabelText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.custom-width-pixel-label-text',
		defaultMessage: 'Enter 400 to 5000 pixels',
		description: 'Aria label text when focus is switched to custom width text box',
	},
	timelineRangeLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.png-export.timeline-settings.timeline-range-label',
		defaultMessage: 'Timeline range',
		description: 'Label text for timeline range field under share plan',
	},
});

export default messages;
