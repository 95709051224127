import React, {
	Component,
	createRef,
	type Dispatch,
	type ReactNode,
	type SetStateAction,
} from 'react';
import classNames from 'classnames';
import ReactFocusLock from 'react-focus-lock';
import Badge from '@atlaskit/badge';
import FilterIcon from '@atlaskit/icon/glyph/filter';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage } from '@atlassian/jira-intl';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useBanner } from '@atlassian/jira-platform-controllers-global-banner/src/controllers/index.tsx';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import {
	HIERARCHY_FILTER_ID,
	GOAL_FILTER_ID,
	IDEA_FILTER_ID,
	ISSUE_WARNING_FILTER_ID,
	ASSIGNEE_FILTER_ID,
	COMPONENT_FILTER_ID,
	DATE_RANGE_FILTER_ID,
	DEPENDENCIES_FILTER_ID,
	ISSUE_PRIORITIES_FILTER_ID,
	ISSUE_TYPE_KEY_FILTER_ID,
	LABEL_FILTER_ID,
	PROJECT_FILTER_ID,
	RELEASE_FILTER_ID,
	REPORTER_FILTER_ID,
	SPRINT_FILTER_ID,
	STATUS_KEY_FILTER_ID,
	SUMMARY_FILTER_ID,
	TEAM_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { injectIntlWithDefaultProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/inject-intl-with-default-props/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import AssigneeFilter from './assignee-filter/index.tsx';
import ComponentFilter from './component-filter/index.tsx';
import CustomFieldFilter from './custom-field-filter/index.tsx';
import DateRangeFilter from './date-range-filter/index.tsx';
import DependenciesFilter from './dependencies-filter/index.tsx';
import withFilterItem from './filter-item-hoc/index.tsx';
import GoalFilter from './goal-filter/index.tsx';
import HierarchyFilter from './hierarchy-filter/index.tsx';
import IdeaFilter from './idea-filter/index.tsx';
import IssuePrioritiesFilter from './issue-priorities-filter/index.tsx';
import IssueTypesKeyFilter from './issue-types-key-filter/index.tsx';
import IssueWarningFilter from './issue-warning-filter/index.tsx';
import LabelFilter from './label-filter/index.tsx';
import messages from './messages.tsx';
import Options from './options/index.tsx';
import ProjectFilter from './project-filter/index.tsx';
import ReleaseFilter from './release-filter/index.tsx';
import ReporterFilter from './reporter-filter/index.tsx';
import SprintFilter from './sprint-filter/index.tsx';
import StatusKeyFilter from './status-key-filter/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import SummaryFilter from './summary-filter/index.tsx';
import TeamFilter from './team-filter/index.tsx';
import type { Props, State } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class FilterBar extends Component<Props, State> {
	static defaultProps = {
		AssigneeFilter: withFilterItem(AssigneeFilter),
		ComponentFilter: withFilterItem(ComponentFilter),
		CustomFieldFilter: withFilterItem(CustomFieldFilter),
		DependenciesFilter: withFilterItem(DependenciesFilter),
		HierarchyFilter,
		IssueTypesKeyFilter: withFilterItem(IssueTypesKeyFilter),
		IssuePrioritiesFilter: withFilterItem(IssuePrioritiesFilter),
		LabelFilter: withFilterItem(LabelFilter),
		ProjectFilter: withFilterItem(ProjectFilter),
		ReleaseFilter: withFilterItem(ReleaseFilter),
		SprintFilter: withFilterItem(SprintFilter),
		StatusKeyFilter: withFilterItem(StatusKeyFilter),
		SummaryFilter,
		TeamFilter: withFilterItem(TeamFilter),
		ReporterFilter: withFilterItem(ReporterFilter),
		GoalFilter: withFilterItem(GoalFilter),
		IdeaFilter: withFilterItem(IdeaFilter),
		DateRangeFilter,
		IssueWarningFilter: withFilterItem(IssueWarningFilter),
		Options,
	};

	state = {
		inlineDialogContentEl: null,
		isMenuOpen: false,
		isOpen: !!this.props.isDefaultOpenMenu,
	};

	buttonRef = createRef<HTMLButtonElement>();

	onFiltersRender = (el?: HTMLElement | null) => {
		if (this.state.inlineDialogContentEl) {
			return;
		}
		this.setState(() => ({
			inlineDialogContentEl: el,
		}));
	};

	onClose = () => {
		this.setState({ isOpen: false }, () => {
			!this.state.isOpen && this.buttonRef.current?.focus();
		});
	};

	toggleOpen = () => {
		const { isOpen, inlineDialogContentEl } = this.state;
		this.setState(
			{
				isOpen: !isOpen,
				inlineDialogContentEl: isOpen ? inlineDialogContentEl : null,
			},
			() => {
				!this.state.isOpen && this.buttonRef.current?.focus();
			},
		);
	};

	renderTriggerButton = (triggerProps?: TriggerProps) => {
		const { filtersAppliedCount } = this.props;
		const { ref, ...restTriggerProps } = triggerProps || { ref: null };
		return (
			<Button
				iconAfter={
					filtersAppliedCount > 0 ? (
						<Badge appearance="primary">{filtersAppliedCount}</Badge>
					) : (
						(isVisualRefreshEnabled() && <ChevronDownIcon label="" color="currentColor" />) ||
						undefined
					)
				}
				{...restTriggerProps}
				ref={mergeRefs(this.buttonRef, ref)}
				aria-expanded={this.state.isOpen}
				iconBefore={!isVisualRefreshEnabled() ? <FilterIcon label="" /> : undefined}
				isSelected={this.state.isOpen}
				onClick={this.toggleOpen}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.button"
			>
				<FormattedMessage {...(isVisualRefreshEnabled() ? messages.header : messages.headerOld)} />
			</Button>
		);
	};

	renderStandardFieldsFilters = (
		setInitialFocusRef?: Dispatch<SetStateAction<HTMLElement | null>>,
	) => {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			AssigneeFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			ReporterFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			ComponentFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			DependenciesFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			IssueTypesKeyFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			IssuePrioritiesFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			LabelFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			ProjectFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			ReleaseFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			SprintFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			StatusKeyFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			SummaryFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			HierarchyFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			TeamFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			GoalFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			IdeaFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			DateRangeFilter,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			IssueWarningFilter,
			isAtlasConnectInstalled,
		} = this.props;
		const { inlineDialogContentEl } = this.state;
		const isEmbedOrMacro = this.props.isEmbedMode || this.props.isConfluenceMacroMode;
		const filters = [
			{
				filter: HierarchyFilter && <HierarchyFilter />,
				label: <FormattedMessage {...messages[HIERARCHY_FILTER_ID]} />,
			},
			{
				filter: <SummaryFilter inputRef={setInitialFocusRef} />,
				label: <FormattedMessage {...messages[SUMMARY_FILTER_ID]} />,
			},
			{
				filter: <ReleaseFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[RELEASE_FILTER_ID]} />,
			},
			{
				filter: <TeamFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[TEAM_FILTER_ID]} />,
			},
			{
				filter: <AssigneeFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[ASSIGNEE_FILTER_ID]} />,
			},
			{
				filter: <SprintFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[SPRINT_FILTER_ID]} />,
			},
			{
				filter: <ProjectFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[PROJECT_FILTER_ID]} />,
			},
			{
				filter: <IssuePrioritiesFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[ISSUE_PRIORITIES_FILTER_ID]} />,
			},
			{
				filter: IssueTypesKeyFilter && (
					<IssueTypesKeyFilter parentScrollContainer={inlineDialogContentEl} />
				),
				label: <FormattedMessage {...messages[ISSUE_TYPE_KEY_FILTER_ID]} />,
			},
			{
				filter: <ComponentFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[COMPONENT_FILTER_ID]} />,
			},
			{
				filter: <LabelFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[LABEL_FILTER_ID]} />,
			},
			{
				filter: <DependenciesFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[DEPENDENCIES_FILTER_ID]} />,
			},
			{
				filter: <ReporterFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[REPORTER_FILTER_ID]} />,
			},
			{
				filter: <StatusKeyFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[STATUS_KEY_FILTER_ID]} />,
			},
		];

		const goalsFilterIndex = 7;
		if (isAtlasConnectInstalled) {
			// during clean up move it after project filter
			filters.splice(goalsFilterIndex, 0, {
				filter: <GoalFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[GOAL_FILTER_ID]} />,
			});
		}
		if (ff('polaris-arj-eap-override') && !isEmbedOrMacro) {
			const ideasFilterIndex = isAtlasConnectInstalled ? goalsFilterIndex + 1 : goalsFilterIndex;
			filters.splice(ideasFilterIndex, 0, {
				filter: <IdeaFilter parentScrollContainer={inlineDialogContentEl} />,
				label: <FormattedMessage {...messages[IDEA_FILTER_ID]} />,
			});
		}

		filters.push({
			filter: IssueWarningFilter && (
				<IssueWarningFilter parentScrollContainer={inlineDialogContentEl} />
			),
			label: <FormattedMessage {...messages[ISSUE_WARNING_FILTER_ID]} />,
		});

		filters.push({
			filter: DateRangeFilter && <DateRangeFilter parentScrollContainer={inlineDialogContentEl} />,
			label: <FormattedMessage {...messages[DATE_RANGE_FILTER_ID]} />,
		});

		return filters.map<ReactNode>(
			({ filter, label }, index) =>
				filter && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div key={index} className={styles.filterRow}>
						<div
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							className={classNames(styles.filterRowLabel, {
								[styles.hierarchyFilterLabel]: index === 0,
							})}
						>
							{label}
						</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.filterRowFilter}>{filter}</div>
					</div>
				),
		);
	};

	renderFilters = () => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { clearAllFilters, filtersAppliedCount, Options, intl } = this.props;

		return (
			<Popup
				content={({ setInitialFocusRef }) => (
					<ReactFocusLock>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.filtersDialog}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.filtersHeader}>
								<Button
									appearance="link"
									interactionName="arj-timeline-clear-all-filters"
									isDisabled={!filtersAppliedCount}
									onClick={clearAllFilters}
									testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.clear-all-filters"
								>
									<FormattedMessage {...messages.clearAllFiltersLabel} />
								</Button>
							</div>
							{getWillShowNav4() ? (
								<FiltersList ref={this.onFiltersRender}>
									{this.renderStandardFieldsFilters(setInitialFocusRef)}
									{this.renderCustomFieldsFilters()}
								</FiltersList>
							) : (
								/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */
								<div className={styles.filtersList} ref={this.onFiltersRender}>
									{this.renderStandardFieldsFilters(setInitialFocusRef)}
									{this.renderCustomFieldsFilters()}
								</div>
							)}

							{Options && (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<div className={styles.filterOptions}>
									<Options />
								</div>
							)}
						</div>
					</ReactFocusLock>
				)}
				isOpen={this.state.isOpen}
				onClose={this.onClose}
				trigger={this.renderTriggerButton}
				shouldRenderToParent
				role="dialog"
				placement="bottom-start"
				{...(fg('jira-portfolio-filter-bar-aria-dialog-name-a11y') && {
					label: intl.formatMessage(messages.dialogLabel),
				})}
			/>
		);
	};

	renderCustomFieldsFilters = () => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const { CustomFieldFilter, customFieldsWithFilteringAllowed } = this.props;
		const { inlineDialogContentEl } = this.state;

		return customFieldsWithFilteringAllowed.map<ReactNode>((field) => (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div key={field.id} className={styles.filterRow}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.filterRowLabel}>{field.title}</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.filterRowFilter}>
					<CustomFieldFilter field={field} parentScrollContainer={inlineDialogContentEl} />
				</div>
			</div>
		));
	};

	render() {
		return this.renderFilters();
	}
}

export default injectIntlWithDefaultProps(FilterBar);

// this should be re-combined into FilterBar when refactoring it into a functional component
const FiltersList = ({
	children,
	ref,
}: {
	children: ReactNode;
	ref: (el?: HTMLElement | null) => void;
}) => {
	const banner = useBanner();
	return (
		<Box
			xcss={
				banner.isEnabled ? [filtersListStyles, filtersListStylesWithBanner] : [filtersListStyles]
			}
			ref={ref}
		>
			{children}
		</Box>
	);
};

const filtersListStyles = xcss({
	/* (56px (Jira nav) + 100 px (ARJ header) + 40px (Clear all filters) + 41px (Show Full hierarchy) + 8px (bottom padding) + 64px (bottom offset) = 309px */
	maxHeight: 'calc(100vh - 309px)',
	/* This removes scrollbars that appear from overlapped elements in IE11 */
	msOverflowStyle: 'auto',
	overflowY: 'auto',
	paddingInline: 'space.300',
	paddingBlockEnd: 'space.100',
});

const filtersListStylesWithBanner = xcss({
	/* ^ calculation above of 309px + 64px (Banner above jira nav) = 373px */
	maxHeight: 'calc(100vh - 373px)',
});
