import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	warningCenterHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warning-center-header',
		defaultMessage: 'Warning center',
		description: 'Header text of the warning center',
	},
	warningsDialogHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warnings-dialog-header',
		defaultMessage: 'Show on timeline',
		description: 'It is a warning dialog problem header label.',
	},
	disabledIssueStatusText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.disabled-issue-status-text',
		defaultMessage: 'disabled',
		description: 'Status text to indicate that a warning is disabled',
	},
	warningCountText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warning-count-text',
		defaultMessage: '{count, plural, one {# issue} other {# issues}}',
		description: 'Text to indicate how many issues are affected by this warning',
	},
	zeroCountWarningText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.zero-count-warning-text',
		defaultMessage: '-',
		description: 'Hypen is used to indicate that 0 issues are affected by this warning',
	},
	warningsDialogDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warnings-dialog-description',
		defaultMessage: 'Configure which warnings you want to see in your plan. {link}',
		description: 'It is a warning dialog preamble text.',
	},
	warningsDialogDescriptionAndDocumentationLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.warnings-dialog-description-and-documentation-link',
		defaultMessage:
			'Configure which warnings you want to see in your plan. Use the filter menu to only show issues with warnings. {link}',
		description: 'It is a warning dialog preamble text.',
	},
	iphLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.iph-link',
		defaultMessage: 'How do I resolve these warnings?',
		description: 'Link to the IPH article about the warnings',
	},
	showWarningsOnYourTimeline: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.show-warnings-on-your-timeline',
		defaultMessage: 'Show warnings on your timeline',
		description:
			'Label of the "Show warnings on your timeline" toggle in the header of the Warnings Centre',
	},
	goBackToOverview: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.warnings.content.go-back-to-overview',
		defaultMessage: 'Go back to overview',
		description: 'Text of the back arrow',
	},
});
