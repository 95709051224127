// eslint-disable-next-line jira/restricted/react-component-props
import React, { Component, type ReactElement, type ComponentProps } from 'react';
import { ONE_DAY } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import {
	getDayUnits,
	getQuarterUnits,
	getYearUnits,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/index.tsx';
import { CUSTOM_DATE_RANGES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Units } from '../unit/types.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class TimelineAxis extends Component<Props> {
	getWeeksTimeline = (dayUnits: Units) =>
		dayUnits.map<ReactElement<ComponentProps<'div'>>>(({ widthPercentage }, index) => (
			<div
				data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.axis.${index}`}
				key={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.axis.${index}`}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.withRightBorder}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					boxSizing: 'border-box',
					width: `${widthPercentage}%`,
				}}
			/>
		));

	getMonthsTimeline = (dayUnits: Units) =>
		dayUnits.map<ReactElement<ComponentProps<'div'>>>(({ startTime, widthPercentage }, index) => {
			const nextDate = new Date(startTime + ONE_DAY);
			return (
				<div
					data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.axis.${index}`}
					key={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.axis.${index}`}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={
						// right border is rendered on last day of month
						nextDate.getUTCDate() === 1 ? styles.withRightBorder : ''
					}
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						boxSizing: 'border-box',
						width: `${widthPercentage}%`,
					}}
				/>
			);
		});

	getQuartersTimeline = (quarterUnits: Units) =>
		quarterUnits.map<ReactElement<ComponentProps<'div'>>>(({ widthPercentage }, index) => (
			<div
				data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.axis.${index}`}
				key={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.axis.${index}`}
				// right border is rendered on each quarter
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.withRightBorder}
				style={{
					width: `${widthPercentage}%`,
				}}
			/>
		));

	getYearsTimeline = (yearUnits: Units) =>
		yearUnits.map<ReactElement<ComponentProps<'div'>>>(({ widthPercentage }, index) => (
			<div
				data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.axis.${index}`}
				key={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.axis.${index}`}
				// right border is rendered on each year
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.withRightBorder}
				style={{
					width: `${widthPercentage}%`,
				}}
			/>
		));

	render() {
		const {
			timelineRange: { start: timelineRangeStart, end: timelineRangeEnd },
			fiscalMonth,
		} = this.props;

		const daysInRange = (timelineRangeEnd - timelineRangeStart) / ONE_DAY;
		const dayUnits = getDayUnits(timelineRangeStart, daysInRange);

		let timeline: Array<ReactElement<ComponentProps<'div'>>> | Array<never> = [];

		// this aligns with the infinite axis "weeks" behaviour
		if (dayUnits.length <= CUSTOM_DATE_RANGES.SMALL) {
			timeline = this.getWeeksTimeline(dayUnits);

			// this aligns with the infinite axis "months" behaviour
		} else if (dayUnits.length <= CUSTOM_DATE_RANGES.MEDIUM) {
			timeline = this.getMonthsTimeline(dayUnits);

			// this aligns with the infinite axis "quarters" behaviour
		} else if (dayUnits.length <= CUSTOM_DATE_RANGES.LARGE) {
			const quarterUnits = getQuarterUnits(timelineRangeStart, timelineRangeEnd, fiscalMonth);
			timeline = this.getQuartersTimeline(quarterUnits);

			// this aligns with the infinite axis "years" behaviour
		} else {
			const yearUnits = getYearUnits(timelineRangeStart, timelineRangeEnd, fiscalMonth);
			timeline = this.getYearsTimeline(yearUnits);
		}

		if (timeline.length) {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			return <div className={styles.main}>{timeline}</div>;
		}
		return null;
	}
}

export default TimelineAxis;
