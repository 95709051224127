import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	dragHandleLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.utils.draggable-column.drag-handle.drag-handle-label',
		defaultMessage: 'Drag handle',
		description: 'Accessible label for the drag handle',
	},
	dragHandleDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.utils.draggable-column.drag-handle.drag-handle-description',
		defaultMessage: 'Drag to reorder, click for more options',
		description: 'Description for the drag handle',
	},
	hideField: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.utils.draggable-column.drag-handle.hide-field',
		defaultMessage: 'Hide field',
		description: 'Menu option to hide field from current view',
	},
	moveFieldLeft: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.utils.draggable-column.drag-handle.move-field-left',
		defaultMessage: 'Move field left',
		description: 'Menu option to move field left in the horizontal ordering of fields',
	},
	moveFieldRight: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.utils.draggable-column.drag-handle.move-field-right',
		defaultMessage: 'Move field right',
		description: 'Menu option to move field right in the horizontal ordering of fields',
	},
});

export default messages;
