import { useEffect, type FC } from 'react';
import { HORIZONTAL, VERTICAL, ALL, type Props, type Axis } from './types.tsx';

/**
 * Lock/unlock scroll to support `position: fixed` hack.
 * This hack is used to prevent popup clipping in components like DatePicker or SingleSelect
 * when they have ancestors with `overflow: hidden` and `position: absolute`.
 * `position: fixed` allows to ignore such container boundaries (not always though), but pins popup
 * to the same position during scroll which breaks UX.
 * By locking scroll when popup is visible we partially mitigate this problem.
 */

const getLockableScrollAreas = () =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.querySelectorAll<HTMLElement>('[data-scroll="lockable"]');

const lockCounts = {
	horizontal: 0,
	vertical: 0,
};

export function isScrollLocked(axis: Axis = ALL) {
	switch (axis) {
		case HORIZONTAL:
			return lockCounts.horizontal > 0;
		case VERTICAL:
			return lockCounts.vertical > 0;
		default:
			return lockCounts.horizontal > 0 || lockCounts.vertical > 0;
	}
}

export function addScrollLock(axis: Axis) {
	const nodes = getLockableScrollAreas();

	const lockHorizontal = axis === HORIZONTAL || axis === ALL;
	const lockVertical = axis === VERTICAL || axis === ALL;

	if (lockHorizontal) lockCounts.horizontal++;
	if (lockVertical) lockCounts.vertical++;

	Array.from(nodes).forEach((node) => {
		// eslint-disable-next-line no-param-reassign
		if (isScrollLocked(HORIZONTAL)) node.style.overflowX = 'hidden';
		// eslint-disable-next-line no-param-reassign
		if (isScrollLocked(VERTICAL)) node.style.overflowY = 'hidden';
	});
}

export function removeScrollLock(axis: Axis) {
	const nodes = getLockableScrollAreas();

	const unlockHorizontal = axis === HORIZONTAL || axis === ALL;
	const unlockVertical = axis === VERTICAL || axis === ALL;

	if (isScrollLocked(HORIZONTAL) && unlockHorizontal) lockCounts.horizontal--;
	if (isScrollLocked(VERTICAL) && unlockVertical) lockCounts.vertical--;

	Array.from(nodes).forEach((node) => {
		// eslint-disable-next-line no-param-reassign
		if (!isScrollLocked(HORIZONTAL)) node.style.overflowX = 'auto';
		// eslint-disable-next-line no-param-reassign
		if (!isScrollLocked(VERTICAL)) node.style.overflowY = 'auto';
	});
}

const useScrollLock = (axis: Axis, enabled: boolean) => {
	useEffect(() => {
		if (enabled) {
			addScrollLock(axis);
			return () => removeScrollLock(axis);
		}
	}, [axis, enabled]);
};

const ScrollLock: FC<Props> = ({ children = null, axis = VERTICAL, enabled = true }) => {
	useScrollLock(axis, enabled);
	return children;
};

export default ScrollLock;
