import { useContext } from 'react';
import { getOutgoingLinks } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/index.tsx';
import { getDependencySettingsInfo } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import type { StateProps } from './types.tsx';
import { context } from './utils.tsx';
import View from './view.tsx';

export const useDepLinesDrag = () => useContext(context);

const mapStateToProps = createStructuredSelector<State, StateProps>({
	outgoingLinks: getOutgoingLinks,
	dependencySettings: getDependencySettingsInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
