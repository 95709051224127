import React from 'react';
import { StatusLozenge as IssueStatusFieldReadonly } from '@atlassian/jira-issue-field-status/src/ui/status-lozenge/index.tsx';
import type { StatusTransition } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { isScenarioIssue } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/issue.tsx';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import Cell from '../../column/cell/view.tsx';
import IssueStatusField from './issue-status-field/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export const IssueStatusCell = ({
	issue,
	issueStatuses,
	projectsById,
	onChange,
	isScrolling,
	showOptimizations,
	isReadOnly,
}: Props) => {
	const handleChange = (transition: StatusTransition) => {
		onChange(issue, transition);
	};

	if (isDefined(issue.status) && isDefined(issueStatuses[issue.status])) {
		const status = issueStatuses[issue.status];
		// Defend against rare occurrences of corrupt data
		// Cannot destructure property 'id' of 't[e.status]' as it is undefined
		if (!status) return null;

		const issueProject = issue.project && projectsById[issue.project];
		if (!issueProject) return null;

		const issueProjectAndKey = `${issueProject.key}-${issue.issueKey}`;

		const { id, name, categoryId } = status;

		return (
			<Cell
				attribute="status"
				issue={issue}
				isScrolling={isScrolling}
				showOptimizations={!!showOptimizations}
			>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.issueStatus}>
					{isScenarioIssue(issue.id) || isScrolling || isReadOnly ? (
						<IssueStatusFieldReadonly value={{ id, name, statusCategory: { id: categoryId } }} />
					) : (
						<IssueStatusField
							issue={issue}
							issueKey={toIssueKey(issueProjectAndKey)}
							value={{ id, name, statusCategory: { id: categoryId } }}
							onChange={handleChange}
							projectId={issueProject.id}
							issueStatuses={issueStatuses}
						/>
					)}
				</div>
			</Cell>
		);
	}
	return null;
};

export default IssueStatusCell;
