import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.placeholder',
		defaultMessage: 'Choose a sprint',
		description: 'The placeholder for the sprint selector',
	},
	sprintSelectorLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.sprint-selector-label',
		defaultMessage: 'Select sprint for issue {issue}',
		description: 'The label for the sprint selector',
	},
	issueIsSubtaskHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.issue-is-subtask-header',
		defaultMessage: 'Cannot change the sprint of sub-tasks.',
		description: 'This is a header of a message when an issue is a subtask',
	},
	issueIsSubtaskContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.issue-is-subtask-content',
		defaultMessage:
			'A sub-task is assigned to the same sprint as its parent story. To change this sprint, change the sprint of the parent story instead.',
		description: 'This is a content of a message when an issue is a subtask',
	},
	noTeamHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.no-team-header',
		defaultMessage: 'No team has been selected.',
		description: 'This is a header of a message when team is not defined on an issue',
	},
	noTeamContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.no-team-content',
		defaultMessage:
			'Choose a Scrum team first, and make sure the board for that team has sprints in it.',
		description: 'This is a content of a message when team is not defined on an issue',
	},
	externalTeamHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.external-team-header',
		defaultMessage: 'Team not in plan.',
		description: 'This is a header of a message when team is not in a plan',
	},
	externalTeamContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.external-team-content',
		defaultMessage: 'Add the team to the plan first, then try setting the sprint again.',
		description: 'This is a content of a message when team is not in a plan',
	},
	teamIsNotAScrumTeamHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.team-is-not-a-scrum-team-header',
		defaultMessage: 'The associated team is not a Scrum team.',
		description: 'This is a header of a message when team is not a scrum team',
	},
	teamIsNotAScrumTeamContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.team-is-not-a-scrum-team-content',
		defaultMessage: 'Since Scrum teams work in sprints, choose a Scrum team first.',
		description: 'This is a content of a message when team is not a scrum team',
	},
	noIssueSourceDefinedHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.no-issue-source-defined-header',
		defaultMessage: 'There are no Scrum boards for the associated team.',
		description: 'This is a header of a message when team is not associated with any board',
	},
	noIssueSourceDefinedContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.no-issue-source-defined-content',
		defaultMessage:
			'Make sure this team is associated with a Scrum board, to use the sprints of that board.',
		description: 'This is a content of a message when team is not associated with any board',
	},
	noSprintsInTeamHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.no-sprints-in-team-header',
		defaultMessage: 'There are no sprints in the associated board.',
		description: "This is a header of a message when board doesn't contain any sprints",
	},
	noSprintsInTeamContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.no-sprints-in-team-content',
		defaultMessage: 'Create a sprint for this board in Jira first.',
		description: "This is a content of a message when board doesn't contain any sprints",
	},
	numCompletedSprints: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.num-completed-sprints',
		defaultMessage: '{numCompletedSprints} completed',
		description:
			'Message to show on sprint select when the issue is only assigned to completed sprints',
	},
	completedSprintsHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.completed-sprints-header',
		defaultMessage: 'This issue was in',
		description: 'Label for completed sprints section of sprints dropdown',
	},
	completedSprintsLozenge: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.completed-sprints-lozenge',
		defaultMessage: 'Completed',
		description: 'Label for completed sprints lozenge of sprints dropdown',
	},
	futureSprints: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.future-sprints',
		defaultMessage: 'Future',
		description: 'Label for future sprints section of sprints dropdown',
	},
	futureAndCompletedSprints: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.future-and-completed-sprints',
		defaultMessage: '{sprint} <span>+ {numCompletedSprints} completed</span>',
		description:
			'Message to show on sprint select when the issue is assigned to completed sprints and a future sprint',
	},
	notInPlanAndCompletedSprints: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.not-in-plan-and-completed-sprints',
		defaultMessage: '<span>{sprint}</span> <span>+ {numCompletedSprints} completed</span>',
		description:
			'Message to show on sprint select when the issue is assigned to completed sprints and the selected sprint it not in plan',
	},
	otherSprints: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.other-sprints',
		defaultMessage: 'Other sprints',
		description: "Category header for sprints that doesn't belong to a team",
	},
	externalSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.external-sprint',
		defaultMessage: 'External sprint',
		description: 'This is the text shown next to an external sprint',
	},
	externalSprintTooltipTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.external-sprint-tooltip-title',
		defaultMessage: 'Sprint currently not in this plan',
		description: 'Tooltip title for the external sprint',
	},
	externalSprintTooltipContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.external-sprint-tooltip-content',
		defaultMessage:
			"While this sprint isn't in this plan, it's appearing because it's assigned to issues in the plan.",
		description: 'Tooltip content for the external sprint',
	},
	externalCompletedSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.sprint.external-completed-sprint',
		defaultMessage: 'completed (external)',
		description: 'Text for completed sprint title if sprint is external',
	},
});
