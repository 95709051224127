import {
	getIssuePrioritiesIdList,
	getIssuePriorities,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-priorities/index.tsx';
import { getColourPriorities } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { StateProps } from './types.tsx';

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	colourPriorities: getColourPriorities(state),
	issuePrioritiesIdList: getIssuePrioritiesIdList(state) ?? undefined,
	prioritiesById: getIssuePriorities(state),
});

export default mapStateToProps;
