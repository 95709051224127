import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	modalHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.apply-optimisation.dialog.modal-header',
		defaultMessage: 'Applying changes',
		description: 'Label for the modal header',
	},
	modalRefreshBtn: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.apply-optimisation.dialog.modal-refresh-btn',
		defaultMessage: 'Refresh',
		description: 'Label for the Refresh button',
	},

	notLatestMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.apply-optimisation.dialog.not-latest-message',
		defaultMessage: 'Changes are out of sync due to concurrent auto-scheduling.',
		description: 'Message shown when plan is out of sync due to concurrent auto-scheduling',
	},
	refreshMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.apply-optimisation.dialog.refresh-message',
		defaultMessage: '{refreshButton} to see the latest changes.',
		description: 'Message shown when plan is out of sync due to concurrent auto-scheduling',
	},
});
