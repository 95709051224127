.footer {
	display: flex;
	flex-grow: 1;
	align-items: center;
}

.footerLeft {
	flex-grow: 1;
}

.progressContainer {
	flex-shrink: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: var(--jpo-common-base-spacing);
}

.commitProgress {
	display: inline-block;
	margin: 0 calc(2 * var(--jpo-common-base-spacing));
}

.buttonWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: calc(0.5 * var(--jpo-common-base-spacing));
	cursor: pointer;
}
