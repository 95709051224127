import { TARGET_DATE_FIELD_TO_API_DATE_FIELD_MAP } from '../../state/domain/issues/types.tsx';
// NOTE Usually we use $Shape for such cases, but it doesn't play well with covariant keys/$ReadOnly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UpdatePlanPayload = any;

export type ToggleAutoSchedulerPayload = {
	planId: number;
	enabled: boolean;
};

export const urls = {
	update: (id: number) => `/rest/jpo/1.0/plans/${id}/update`,
	delete: (id: number) => `/rest/jpo/1.0/plans/${id}`,
	get: (planId: number, scenarioId: number) =>
		`/rest/jpo/1.0/planinfo/${planId}/scenario/${scenarioId}`,
	toggleAutoSchedule: () => '/rest/jpo/1.0/plans/autoSchedulerEnabled',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformUpdatePayload = (update: UpdatePlanPayload): any => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const result: Record<string, any> = {};
	for (const key of Object.keys(update)) {
		const value = update[key];
		if (key === 'autoScheduleConfiguration') {
			result.calculationConfiguration = { value };
		} else if (['baselineStartField', 'baselineEndField'].includes(key)) {
			if (typeof value === 'object' && typeof value.id === 'string') {
				const convertedId = TARGET_DATE_FIELD_TO_API_DATE_FIELD_MAP[value.id] || value.id;
				result[`${key}Id`] = { value: convertedId };
			}
		} else {
			result[key] = { value };
		}
	}

	// This is updated in separate api (toggleAutoSchedule) it will 400 if we accidentally include it here
	// but this is part of PlanInfo so is easy to accidentally include it in the payload
	delete result.autoSchedulerEnabled;

	return result;
};
