.root {
	margin-right: 6px; /* no constant can be used here to sastify the requirement */
}
.moreIconWrapper {
	display: flex;
	align-items: center;
	padding: 2px 3px;
	width: calc(var(--jpo-icon-size-small) + 8px);
	margin: 0;
}

.divider {
	background-color: var(--ds-border, var(--adg3-color-N30));
	height: 2px;
	margin-top: calc(var(--jpo-common-base-spacing) / 2);
	margin-bottom: calc(var(--jpo-common-base-spacing) / 2);
}
