import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { AnalyticsEventMeta } from '../../analytics/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { setScrollingScrollTop as setStickyHeaderScroll } from '../../state/ui/main/tabs/roadmap/sticky-header/actions';

export const VIEW_ROADMAP = 'command.roadmap.VIEW_ROADMAP' as const;

export type ViewRoadmapAction = {
	type: typeof VIEW_ROADMAP;
	meta: AnalyticsEventMeta;
};

export const viewRoadmap = (analyticsEvent: UIAnalyticsEvent): ViewRoadmapAction => ({
	type: VIEW_ROADMAP,
	meta: {
		analyticsEvent,
	},
});
