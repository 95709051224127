import { createStore, applyMiddleware, compose, type Middleware } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isPollinatorTenant } from '@atlassian/jira-portfolio-3-common/src/feature-flags/index.tsx';
import { isEnvironmentError } from '../../common/error/index.tsx';
import { errorReporterMiddleware } from '../../common/redux/index.tsx';
import {
	PACKAGE_NAME,
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
} from '../../common/view/constant.tsx';
import main from '../command/index.tsx';
import activeViewPersistor from '../observer/active-view-persistor/index.tsx';
import relationsLoader from '../observer/relations-loader/index.tsx';
import solutionValidityChecker from '../observer/solution-validity-checker/index.tsx';
import { getInitialMode } from './domain/app/reducer.tsx';
import { EXPORT } from './domain/app/types.tsx';
import suspenderEnhancer from './enhancers/suspender/index.tsx';
import analyticsMiddleware from './middleware/analytics/index.tsx';
import reducer from './reducer.tsx';
import { showError } from './ui/errors/actions.tsx';

const composeEnhancers =
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? // eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
			})
		: compose;

const middlewares: Middleware[] = [];

const sagaMiddleware = createSagaMiddleware({
	onError: (error: Error) => {
		const id = isEnvironmentError(error)
			? ERROR_REPORTING_PACKAGE.ENVIRONMENT
			: ERROR_REPORTING_PACKAGE.REDUX_SAGA;
		fireErrorAnalytics({
			meta: {
				id,
				packageName: PACKAGE_NAME,
				teamName: ERROR_REPORTING_TEAM,
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
			attributes: {
				synthetic: isPollinatorTenant(),
			},
		});
		store.dispatch(
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			showError(error.message, error.stack, window?.location.hash, window?.location.pathname),
		);
	},
});

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
middlewares.push(errorReporterMiddleware as unknown as Middleware);
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
middlewares.push(sagaMiddleware as unknown as Middleware);
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
middlewares.push(analyticsMiddleware as unknown as Middleware);

const store = createStore(
	reducer,
	composeEnhancers(applyMiddleware(...middlewares), suspenderEnhancer()),
);

export const persistor = persistStore(store);

export default store;

// Initialise "processes" which run in "parallel" with Redux loop.
sagaMiddleware.run(main);
relationsLoader(store);

if (getInitialMode() !== EXPORT) {
	// In export PNG mode, we don't want to persist the selected timeline range in the export PNG dialog
	activeViewPersistor(store);
}

solutionValidityChecker(store);
