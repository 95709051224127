import {
	OPEN_FLYOUT,
	CLOSE_FLYOUT,
	type OpenFlyoutAction,
	type CloseFlyoutAction,
} from './actions.tsx';
import type { ProjectReleases } from './types.tsx';

type Action = OpenFlyoutAction | CloseFlyoutAction;

export const initialState: ProjectReleases = {
	isFlyoutOpen: false,
	currentCrossProjectVersionId: null,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ProjectReleases = initialState, action: Action): ProjectReleases => {
	switch (action.type) {
		case OPEN_FLYOUT: {
			return {
				isFlyoutOpen: true,
				currentCrossProjectVersionId: action.payload.crossProjectVersionId,
			};
		}
		case CLOSE_FLYOUT: {
			return {
				...initialState,
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
