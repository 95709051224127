import type { Relation } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { Issue } from './types.tsx';

export const RESET = 'state.domain.issues.RESET' as const;
export const UPDATE = 'state.domain.issues.UPDATE' as const;
export const ADD = 'state.domain.issues.ADD' as const;
export const RANK = 'state.domain.issues.RANK' as const;
export const BULK_RANK = 'state.domain.issues.BULK_RANK' as const;
export const BULK_UPDATE = 'state.domain.issues.BULK_UPDATE' as const;
export const BULK_LABEL_UPDATE = 'state.domain.issues.BULK_LABEL_UPDATE' as const;
export const REMOVE_WITHOUT_ISSUE_KEY = 'state.domain.issues.REMOVE_WITHOUT_ISSUE_KEY' as const;

export type ResetActionPayload = Issue[];

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export const reset: (arg1: ResetActionPayload) => ResetAction = (payload) => ({
	type: RESET,
	payload,
});

// NOTE Usually we use $Shape for such cases, but it doesn't play well with covariant keys/$ReadOnly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UpdateActionPayload = any;

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};

export const update: (arg1: UpdateActionPayload) => UpdateAction = (payload) => ({
	type: UPDATE,
	payload,
});

export type BulkUpdateActionPayload = {
	[key: string]: UpdateActionPayload;
};

export type BulkUpdateAction = {
	type: typeof BULK_UPDATE | typeof BULK_LABEL_UPDATE;
	payload: BulkUpdateActionPayload;
};

export const bulkUpdate: (arg1: BulkUpdateActionPayload) => BulkUpdateAction = (payload) => ({
	type: BULK_UPDATE,
	payload,
});

export const bulkLabelUpdate: (arg1: BulkUpdateActionPayload) => BulkUpdateAction = (payload) => ({
	type: BULK_LABEL_UPDATE,
	payload,
});

// NOTE Usually we use $Shape for such cases, but it doesn't play well with covariant keys/$ReadOnly
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AddActionPayload = any;

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export const add: (arg1: AddActionPayload) => AddAction = (payload) => ({
	type: ADD,
	payload,
});

export type RankActionPayload = {
	anchor?: string;
	itemKeys: string[];
	relation: Relation;
	// dragAndDrop used for analytics to know if issue was reranked via drag and drop.
	dragAndDrop?: boolean;
};

export type RankAction = {
	type: typeof RANK;
	payload: RankActionPayload;
};

export type BulkRankAction = {
	type: typeof BULK_RANK;
	payload: RankActionPayload;
};

export const rank: (arg1: RankActionPayload) => RankAction = (payload) => ({
	type: RANK,
	payload,
});

export const bulkRank: (arg1: RankActionPayload) => BulkRankAction = (payload) => ({
	type: BULK_RANK,
	payload,
});

export type RemoveWithoutIssueKeyAction = {
	type: typeof REMOVE_WITHOUT_ISSUE_KEY;
};

export const removeWithoutIssueKey = () => ({ type: REMOVE_WITHOUT_ISSUE_KEY });
