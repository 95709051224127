import { useRouter, generatePath } from '@atlassian/react-resource-router';

const useBasePath = (): string => {
	const [{ match }] = useRouter();
	const { planId, scenarioId } = match.params;

	if (!planId) return '/jira/plans';
	if (!scenarioId) return generatePath('/jira/plans/:planId', { planId });

	return generatePath('/jira/plans/:planId/scenarios/:scenarioId', { planId, scenarioId });
};

export default useBasePath;
