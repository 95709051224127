import React, { Component, type ComponentType } from 'react';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import type { Props } from '../types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (ComposedComponent: ComponentType<Props>) =>
	// eslint-disable-next-line jira/react/no-class-components
	class extends Component<Props, {}> {
		getLabelOptions = (query: string) =>
			fetch('/rest/jpo/1.0/scenarios/labels', {
				method: 'POST',
				body: {
					limit: 50,
					planId: this.props.plan.id,
					scenarioId: this.props.plan.currentScenarioId,
					query: query || null,
				},
			})
				.then((response) => response.json())
				.then(
					(data) =>
						// data.items is an array of labels e.g. ["label1", "label2", ..., "label6"]
						// if data.items is undefined, we return an empty array, which will then
						// display a message "No available labels left" or "No labels in this project"
						// when the user clicks on the "Labels" cell in the fields section
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						data?.items?.map((item: any) => ({ value: item, label: item })) || [],
				);

		render() {
			return <ComposedComponent {...this.props} fetchOptions={this.getLabelOptions} />;
		}
	};
