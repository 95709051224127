import type { State } from '../../state/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	ISSUE_LIMIT_WARNING,
	ISSUE_SEARCH_WARNING,
	NO_JSW_ACCESS_FOR_USER,
	PROJECT_LIMIT_EXCEPTION,
	PROJECT_LIMIT_WARNING,
	SORTED_PREVENT_ISSUE_RANKING_WARNING,
	REPARENT_CMP_TO_TMP_WARNING,
	REPARENT_TMP_TO_EXTERNAL_WARNING,
	GOALS_ERROR,
	type Flag,
	type Flags,
	type FlagKey,
} from '../../state/domain/flags/types';

export const getFlags = (state: State) => state.domain.flags;
