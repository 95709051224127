import { useMemo } from 'react';
import type { Props, ChildProps, IssueSourceValues } from './types.tsx';

export const CtpOnboardingPlanDataSubscriber = ({
	children,
	planId,
	issueSources,
	projects,
}: Props) => {
	const transformedIssueSources: IssueSourceValues[] | undefined = useMemo(
		() => issueSources?.map(({ id, value, title }): IssueSourceValues => ({ id, value, title })),
		[issueSources],
	);

	const childrenProps: ChildProps = {
		planId,
		projectKey: projects?.[0]?.key,
		issueSources: transformedIssueSources,
	};
	return children(childrenProps);
};
