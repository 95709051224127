import {
	useZoomLevel,
	useRenderingWindow,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import type { Props } from './types.tsx';

export default function TimelineRangeSubscriber({
	children,
	timelineRange: containerTimelineRange,
}: Props) {
	const [zoomLevel] = useZoomLevel();
	const [{ timelineRange: renderingWindowTimelineRange }] = useRenderingWindow();

	if (!zoomLevel) {
		return children(containerTimelineRange);
	}

	return children(renderingWindowTimelineRange);
}
