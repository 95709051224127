import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

type Props = {
	backgroundColor: string;
};

const ColourByIcon = ({ backgroundColor }: Props) => <Icon backgroundColor={backgroundColor} />;

export default ColourByIcon;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Icon = styled.span<Props>({
	width: token('space.200', '16px'),
	height: token('space.200', '16px'),
	borderRadius: token('border.radius.050', '2px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ backgroundColor }) => backgroundColor,
});
