import { updatePlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/plan/index.tsx';
import type { AutoScheduleConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	updateAutoScheduleConfiguration: (payload: Partial<AutoScheduleConfiguration>) => {
		dispatch(
			updatePlan({
				autoScheduleConfiguration: payload,
			}),
		);
	},
});

export default mapDispatchToProps;
