import React, { useCallback, useMemo, useState, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import InformationIcon from '@atlaskit/icon/core/information';
import InlineMessage from '@atlaskit/inline-message';
import Link from '@atlaskit/link';
import Popup from '@atlaskit/popup';
import { Box, Text, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { getATLContextDomain } from '@atlassian/atl-context';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { useTeamCentralBaseUrl } from '@atlassian/jira-tenant-context-controller/src/components/team-central-url/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';
import type { GetSignUpRedirectionLinkProps } from './types.tsx';

export const getSignUpRedirectionLink = ({
	isOrgAdmin = false,
	orgId,
	defaultCloudId,
}: GetSignUpRedirectionLinkProps) => {
	const adminHubUrl = getATLContextDomain('admin');
	const jiraConnectExpandUrl = `${getATLContextDomain('team')}/your-work?startTrialProduct=townsquare&edition=free&sourceContext=atlas-jira-connect&targetTab=same`;
	// Requests townsquare access from org admin
	const nonAdminRedirect = `${jiraConnectExpandUrl}&startTrialCloudId=${defaultCloudId}`;
	// If org admin: redirected to adminhub to opt in for townsquare
	return isOrgAdmin && orgId && defaultCloudId
		? `${adminHubUrl}/o/${orgId}/townsquare?cloudId=${defaultCloudId}&isSignup=true`
		: nonAdminRedirect;
};

const GoalHeaderPermissionCheck = ({
	hasAtlasPermissions,
	isDefaultOpen = false,
}: {
	hasAtlasPermissions: boolean;
	isDefaultOpen?: boolean;
}) => {
	const { formatMessage } = useIntl();
	const isOrgAdmin = useIsSiteAdmin();
	const { data: orgId } = useOrgId();
	const { push } = useRouterActions();
	const cloudId = useCloudId();
	const atlasURL = `${useTeamCentralBaseUrl()}?cloudId=${useCloudId()}`;

	const onClickStopPropagation = useCallback((e: MouseEvent<HTMLElement>): void => {
		e.stopPropagation();
	}, []);
	const redirectionLink = useMemo(
		() =>
			getSignUpRedirectionLink({
				isOrgAdmin,
				orgId,
				defaultCloudId: cloudId,
			}),
		[cloudId, isOrgAdmin, orgId],
	);

	const onClickLink = useCallback(
		(_event: MouseEvent<HTMLElement>, analyticEvent: UIAnalyticsEvent): void => {
			fireUIAnalytics(analyticEvent, 'jpo-request-atlas');
			if (!fg('plans_fields_header_goals_404')) {
				push(atlasURL);
			}
		},
		[atlasURL, push],
	);

	const [isOpen, setIsOpen] = useState(isDefaultOpen);

	if (!hasAtlasPermissions) {
		return fg('plans_fields_header_goals_404') ? (
			<Box xcss={boxStyles} onClick={onClickStopPropagation}>
				<Popup
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}
					placement="bottom-start"
					label={formatMessage(messages.permissionCheckPlatformGoalsTooltip)}
					content={() => (
						<Stack space="space.050" xcss={contentStyles}>
							{formatMessage(messages.permissionCheckPlatformGoalsLink, {
								link: (chunks: React.ReactNode) => (
									<Link href={redirectionLink} onClick={onClickLink}>
										{chunks}
									</Link>
								),
								p: (chunks: React.ReactNode) => <Text>{chunks}</Text>,
							})}
						</Stack>
					)}
					trigger={(triggerProps) => (
						<IconButton
							label={formatMessage(messages.permissionCheckPlatformGoalsTooltip)}
							icon={(iconProps) => (
								<InformationIcon {...iconProps} color={token('color.icon.discovery')} />
							)}
							appearance="subtle"
							spacing="compact"
							isSelected={isOpen}
							isTooltipDisabled={isOpen}
							onClick={() => setIsOpen(!isOpen)}
							{...triggerProps}
						/>
					)}
				/>
			</Box>
		) : (
			<Box xcss={boxStylesLegacy} onClick={onClickStopPropagation}>
				<InlineMessage appearance="info">
					<Button appearance="link" spacing="none" onClick={onClickLink}>
						{formatMessage(messages.permissionCheckLink)}
					</Button>
				</InlineMessage>
			</Box>
		);
	}
	return null;
};

const boxStylesLegacy = xcss({
	marginLeft: 'space.100',
});

const boxStyles = xcss({
	marginLeft: 'space.050',
});

const contentStyles = xcss({
	padding: 'space.200',
});

export default GoalHeaderPermissionCheck;
