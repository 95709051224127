.filterButtonWrapper > button {
	text-align: left;
	width: 320px;
}

.buttonTextContainer {
	display: flex;
}

.buttonLabel {
	padding-right: var(--jpo-common-base-spacing);
}
