import {
	FETCH_ASSIGNEES_START,
	type FetchAssigneesStartAction,
	FETCH_ASSIGNEES_STOP,
	type FetchAssigneesStopAction,
} from './actions.tsx';
import type { Assignee } from './types.tsx';

const initialState: Assignee = {
	isFetchAssigneeListInProgress: false,
	issueIdForFetchAssigneeListProgress: '',
};

type Action = FetchAssigneesStartAction | FetchAssigneesStopAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Assignee = initialState, action: Action) => {
	switch (action.type) {
		case FETCH_ASSIGNEES_START:
			return {
				isFetchAssigneeListInProgress: true,
				issueIdForFetchAssigneeListProgress: action.payload.issueId,
			};
		case FETCH_ASSIGNEES_STOP:
			return {
				isFetchAssigneeListInProgress: false,
				issueIdForFetchAssigneeListProgress: action.payload.issueId,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
