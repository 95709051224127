import {
	SET_LATEST,
	SET_VALIDITY,
	RESET,
	SET_PROGRESS,
	type SetLatestAction,
	type SetValidityAction,
	type ResetAction,
	type SetProgressAction,
} from './actions.tsx';
import type { Solution } from './types.tsx';

type Action = SetLatestAction | SetValidityAction | ResetAction | SetProgressAction;

const initialState: Solution = {
	isLatest: false,
	isValid: false,
	applyingProgress: null,
	projects: {},
	teams: {},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Solution = initialState, action: Action): Solution => {
	switch (action.type) {
		case SET_LATEST: {
			const isLatest = action.payload;
			return { ...state, isLatest };
		}
		case SET_VALIDITY: {
			const isValid = action.payload;
			return { ...state, isValid };
		}
		case SET_PROGRESS: {
			const applyingProgress = action.payload;
			return { ...state, applyingProgress };
		}
		case RESET: {
			const solution = action.payload;
			return { ...state, ...solution, applyingProgress: null, isValid: false };
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
