import React from 'react';
import { Box } from '@atlaskit/primitives';
import VisuallyHidden from '@atlaskit/visually-hidden';
import { useIntl } from '@atlassian/jira-intl';
import type { Change } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types.tsx';
import messages from './messages.tsx';
import { useChangesInfo } from './utils.tsx';

type Props = {
	changes: Change[];
	changesCount: number | undefined;
};

const VisuallyHiddenAnnouncement = ({ changes, changesCount }: Props) => {
	const { formatMessage } = useIntl();
	const { listChangeAuthors, countCategories } = useChangesInfo();
	const { userName, userList, isSingleUser } = listChangeAuthors(changes);

	const isAllUsers = changes.length === changesCount;
	const userSelectOptions = isAllUsers ? 'all' : isSingleUser && 'one';

	return (
		<Box aria-live="polite">
			<VisuallyHidden>
				{formatMessage(messages.tableChangesAnnouncement, {
					users: userSelectOptions,
					userName,
					userList,
					changes: countCategories(changes),
				})}
			</VisuallyHidden>
		</Box>
	);
};

export default VisuallyHiddenAnnouncement;
