import { useEffect } from 'react';
import withViewExperienceTracker from '@atlassian/jira-portfolio-3-portfolio/src/common/view/experience-tracking/with-view-experience-tracker/index.tsx';

const TriggerExperienceSuccess: React.FC<{ onExperienceSuccess?: () => void }> = ({
	onExperienceSuccess,
}) => {
	useEffect(() => {
		onExperienceSuccess?.();
	}, [onExperienceSuccess]);

	return null;
};

export const ViewExperienceSuccessTracker = withViewExperienceTracker(
	TriggerExperienceSuccess,
	'portfolio-3.simple-plans.roadmap.table',
);
