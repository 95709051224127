import * as R from 'ramda';
import { isPageUnloading } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';

const ignoredErrorRegexes = [
	// Ignore localStorage.setItem() errors
	// ==================================
	// If localStorage is full, Portfolio behaves okay, but some non-JPO code can throw exceptions (e.g. atlassian-analytics).
	// So we ignore any localStorage write exceptions.

	// Chrome
	/Uncaught QuotaExceededError: Failed to execute 'setItem' on 'Storage': Setting the value of '.*' exceeded the quota/,

	// Firefox
	/NS_ERROR_DOM_QUOTA_REACHED: Persistent storage maximum size reached/,

	// Safari
	/QuotaExceededError: DOM Exception 22: An attempt was made to add something to storage that exceeded the quota/,

	// IE 10, Edge
	/^QuotaExceededError$/,

	// If XHR response status is 401, we reload the page to be redirected to the login page.
	// Hence, no need to show the error.
	/^common\.fetch\.XHR_UNAUTHORIZED_ERROR/,
];

export const shouldIgnoreError = (errorMessage: string) => {
	if (isPageUnloading()) return true;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const matchesError = (regex: any) => regex.test(errorMessage);
	return R.any(matchesError, ignoredErrorRegexes);
};
