import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import type { IssueSource } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { OptionsProviderProps } from '../types.tsx';

export const EXTERNAL = 'external';

export type OwnProps = OptionsProviderProps<GroupCombination>;

export type StateProps = {
	groups: GroupOption[];
	sprints: Sprint[];
	externalSprintsById: Record<string, Sprint>;
	sprintsByIdMap: Record<string, Sprint>;
	issueSourcesById: Record<string, IssueSource>;
};

export type Props = OwnProps & StateProps;
