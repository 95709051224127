import React from 'react';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import { CardAction } from '@atlaskit/smart-card';
import { HoverCard } from '@atlaskit/smart-card/hover-card';
import { GoalStateValues } from '@atlassian/jira-shared-types/src/goal.tsx';
import { extractStatusLabelWithoutScore } from '../../common/utils.tsx';
import { GoalIcon } from './icon/index.tsx';
import { GoalTracker } from './tracker/index.tsx';
import type { Goal as GoalType, GoalProps, BasicGoalProps } from './types.tsx';

export const Goal = ({
	name,
	state: { value, label, score } = {
		value: GoalStateValues.CANCELLED,
		label: 'Cancelled',
	},
	isExportMode,
}: GoalProps) => (
	<Inline space="space.050" alignBlock="center" alignInline="start" xcss={goalStyles}>
		<BasicGoal name={name} value={value} isExportMode={isExportMode} />
		<GoalTracker value={value} label={label} score={score} />
	</Inline>
);

export const BasicGoal = ({ name, value, label, isExportMode }: BasicGoalProps) => (
	<>
		<GoalIcon state={value} ariaLabel={label ? extractStatusLabelWithoutScore(label) : undefined} />
		<Box as="span" xcss={!isExportMode ? goalNameStyles : exportGoalNameStyles}>
			{name}
		</Box>
	</>
);

export const GoalHoverCard = ({
	goal,
	flexShrink,
	onClick,
	isExportMode,
}: {
	goal: GoalType;
	flexShrink: number;
	onClick?: () => void;
	isExportMode: boolean;
}) => {
	const { key, name, state, url } = goal;
	return (
		// eslint-disable-next-line jira/react/no-style-attribute
		<Box key={key} xcss={outerWrapperStyles} style={{ flexShrink }}>
			{url !== undefined ? (
				<HoverCard
					url={url ?? ''}
					actionOptions={{
						hide: false,
						exclude: [CardAction.ChangeStatusAction],
					}}
				>
					<Box xcss={innerWrapperStyles} onClick={onClick}>
						<Goal name={name} state={state} isExportMode={isExportMode} />
					</Box>
				</HoverCard>
			) : (
				<Box xcss={innerWrapperStyles}>
					<Goal name={name} state={state} isExportMode={isExportMode} />
				</Box>
			)}
		</Box>
	);
};

const outerWrapperStyles = xcss({
	display: 'inline',
	minWidth: '38px',
});

const innerWrapperStyles = xcss({
	borderWidth: 'border.width',
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius',
	backgroundColor: 'elevation.surface',
	height: '32px',
	marginLeft: 'space.025',
	marginRight: 'space.025',
});

const goalStyles = xcss({
	height: '30px',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	padding: 'space.050',
});

const goalNameStyles = xcss({
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden',
});

const exportGoalNameStyles = xcss({
	whiteSpace: 'nowrap',
	textOverflow: 'clip',
	overflow: 'hidden',
});
