import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	calendarIcon: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.fixed-date-range.date-input.calendar-icon',
		defaultMessage: 'Calendar icon',
		description: 'Label for calendar icon',
	},
	wrongFormatErrorMessageLocalised: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.fixed-date-range.date-input.wrong-format-error-message-localised',
		defaultMessage: 'Date format should be DD/MM/YYYY',
		description:
			'Error message informing users that date format is incorrect. Will be displayed as grey text underneath the text field.',
	},
});
