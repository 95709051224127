import * as R from 'ramda';
import { getMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/index.tsx';
import {
	getExternalIncomingLinks,
	getInternalIncomingLinks,
	getInternalIncomingLinkOriginals,
	getIncomingLinks,
	getIncomingLinkOriginals,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/index.tsx';
import {
	EDIT,
	OPTIMIZED,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import { DEPENDENCIES_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { IssueLink } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import { ISSUE_LINK_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import messages from './messages.tsx';
import type { OwnProps, StateProps } from './types.tsx';

const externalIssueLinksList: IssueLink[] = [];
const internalIssueLinksList: IssueLink[] = [];
const internalOriginalIssueLinksList: IssueLink[] = [];
const issueLinksList: IssueLink[] = [];
const originalIssueLinksList: IssueLink[] = [];
const mapStateToPropsNew: MapStateToProps<StateProps, OwnProps> = (state, { issue }) => {
	const issueId = issue?.id || '';
	const externalIssueLinks = getExternalIncomingLinks(state)[issueId] || externalIssueLinksList;
	const internalIssueLinks = getInternalIncomingLinks(state)[issueId] || internalIssueLinksList;
	const internalOriginalIssueLinks =
		getInternalIncomingLinkOriginals(state)[issueId] || internalOriginalIssueLinksList;
	const issueLinks = getIncomingLinks(state)[issueId] || issueLinksList;
	const originalIssueLinks = getIncomingLinkOriginals(state)[issueId] || originalIssueLinksList;
	const isChanged =
		R.symmetricDifference(
			issueLinks.map(({ itemKey }) => itemKey),
			originalIssueLinks.map(({ itemKey }) => itemKey),
		).length > 0;
	const mode = getMode(state);
	return {
		externalIssueLinks,
		isChanged,
		isReadOnly: mode !== EDIT,
		isOptimizedMode: mode === OPTIMIZED,
		internalIssueLinks,
		internalOriginalIssueLinks,
		filterValue: getFilters(state)[DEPENDENCIES_FILTER_ID].value,
		messages,
		direction: ISSUE_LINK_DIRECTION.INWARD,
	};
};

const mapStateToProps = mapStateToPropsNew;
export default mapStateToProps;
