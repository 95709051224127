import type { CrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/types.tsx';
import type { ProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/types.tsx';

export const HIERARCHY_FILTER_ID =
	'state.domain.view-settings.filters.HIERARCHY_FILTER_ID' as const;

export const HIERARCHY_RANGE_FILTER_ID =
	'state.domain.view-settings.filters.HIERARCHY_RANGE_FILTER_ID' as const;

export const PROJECT_FILTER_ID = 'state.domain.view-settings.filters.PROJECT_FILTER_ID' as const;

export const CROSS_PROJECT_RELEASE_FILTER_ID =
	'state.domain.view-settings.filters.CROSS_PROJECT_RELEASE_FILTER_ID' as const;

export const RELEASE_FILTER_ID = 'state.domain.view-settings.filters.RELEASE_FILTER_ID' as const;

export const TEAM_FILTER_ID = 'state.domain.view-settings.filters.TEAM_FILTER_ID' as const;

export const ASSIGNEE_FILTER_ID = 'state.domain.view-settings.filters.ASSIGNEE_FILTER_ID' as const;

export const REPORTER_FILTER_ID = 'state.domain.view-settings.filters.REPORTER_FILTER_ID' as const;

export const SUMMARY_FILTER_ID = 'state.domain.view-settings.filters.SUMMARY_FILTER_ID' as const;

export const DEPENDENCIES_FILTER_ID =
	'state.domain.view-settings.filters.DEPENDENCIES_FILTER_ID' as const;

export const LABEL_FILTER_ID = 'state.domain.view-settings.filters.LABEL_FILTER_ID' as const;

export const COMPONENT_FILTER_ID =
	'state.domain.view-settings.filters.COMPONENT_FILTER_ID' as const;

export const STATUS_FILTER_ID = 'state.domain.view-settings.filters.STATUS_FILTER_ID' as const;

export const STATUS_KEY_FILTER_ID =
	'state.domain.view-settings.filters.STATUS_KEY_FILTER_ID' as const;

export const ISSUE_WARNING_FILTER_ID =
	'state.domain.view-settings.filters.ISSUE_WARNING_FILTER_ID' as const;

export const SPRINT_FILTER_ID = 'state.domain.view-settings.filters.SPRINT_FILTER_ID' as const;

export const GOAL_FILTER_ID = 'state.domain.view-settings.filters.GOAL_FILTER_ID' as const;

export const IDEA_FILTER_ID = 'state.domain.view-settings.filters.IDEA_FILTER_ID' as const;

export const ISSUE_TYPES_FILTER_ID =
	'state.domain.view-settings.filters.ISSUE_TYPES_FILTER_ID' as const;

export const ISSUE_TYPE_KEY_FILTER_ID =
	'state.domain.view-settings.filters.ISSUE_TYPE_KEY_FILTER_ID' as const;

export const ISSUE_PRIORITIES_FILTER_ID =
	'state.domain.view-settings.filters.ISSUE_PRIORITIES_FILTER_ID' as const;

export const CUSTOM_FIELD_FILTER_ID =
	'state.domain.view-settings.filters.CUSTOM_FIELD_FILTER_ID' as const;

export const DATE_RANGE_FILTER_ID =
	'state.domain.view-settings.filters.DATE_RANGE_FILTER_ID' as const;

export const ROADMAP_FILTER_SOURCE = 'roadmap' as const;

export const RELEASE_FILTER_SOURCE = 'release' as const;

export type HierarchyFilterValueV0 = {
	start: number;
	end: number;
};
export type HierarchyFilterValue = HierarchyFilterValueV0;

export type HierarchyRangeFilterValueV0 = {
	start: number;
	end: number;
};

export type HierarchyRangeFilterValue = HierarchyRangeFilterValueV0;

export type ResetHierarchyFilterValue = Partial<HierarchyFilterValue>;

export type DependecyTypeSpecificIssue = {
	type: 'specificIssue';
	issueId?: string;
	includeTransitive?: boolean;
};

export type DependenciesFilterValueV1 =
	| {
			type: 'on';
	  }
	| {
			type: 'off';
	  }
	| DependecyTypeSpecificIssue
	| {
			type: 'offtrack';
	  };

export type DependenciesFilterValue = DependenciesFilterValueV1;

export type IssueTypeKeyFilterValueV0 = {
	level: number;
	name: string;
}[];
export type IssueTypeKeyFilterValue = IssueTypeKeyFilterValueV0;

export type StatusKeyFilterValueV0 = {
	category: string;
	name: string;
}[];
export type StatusKeyFilterValue = StatusKeyFilterValueV0;

export type NumberAttributeFilterValue = number[];
export type StringAttributeFilterValue = string[];
export type AttributeFilterValue = NumberAttributeFilterValue | StringAttributeFilterValue;

export type CustomFieldFilterValueV0 = {
	[fieldId: string]: StringAttributeFilterValue;
};
export type CustomFieldFilterValue = CustomFieldFilterValueV0;

export type CustomFieldFilterV0 = {
	id: typeof CUSTOM_FIELD_FILTER_ID;
	value: CustomFieldFilterValue;
};
export type CustomFieldFilter = CustomFieldFilterV0;

export type HierarchyFilterV0 = {
	id: typeof HIERARCHY_FILTER_ID;
	value: HierarchyFilterValue;
};
export type HierarchyFilter = HierarchyFilterV0;

export type HierarchyRangeFilterV0 = {
	id: typeof HIERARCHY_RANGE_FILTER_ID;
	value: HierarchyRangeFilterValue;
};

export type HierarchyRangeFilter = HierarchyRangeFilterV0;

export type ProjectFilterV0 = {
	id: typeof PROJECT_FILTER_ID;
	value: NumberAttributeFilterValue;
};
export type ProjectFilter = ProjectFilterV0;

export type PersistableCrossProjectReleaseFilterV0 = {
	id: typeof CROSS_PROJECT_RELEASE_FILTER_ID;
	value: StringAttributeFilterValue;
};
export type PersistableCrossProjectReleaseFilter = PersistableCrossProjectReleaseFilterV0;

export type CrossProjectReleaseFilter = PersistableCrossProjectReleaseFilter & {
	crossProjectVersionsById?: CrossProjectVersionsById;
};

export type PersistableReleaseFilterV0 = {
	id: typeof RELEASE_FILTER_ID;
	value: StringAttributeFilterValue;
};
export type PersistableReleaseFilter = PersistableReleaseFilterV0;

export type ReleaseFilter = PersistableReleaseFilter & {
	versionsFromCrossProjectReleaseFilterValue?: StringAttributeFilterValue;
};

export type TeamFilterV0 = {
	id: typeof TEAM_FILTER_ID;
	value: StringAttributeFilterValue;
};
export type TeamFilter = TeamFilterV0;

export type AssigneeFilterV0 = {
	id: typeof ASSIGNEE_FILTER_ID;
	value: StringAttributeFilterValue;
	isVisible?: boolean | null | undefined;
};
export type AssigneeFilter = AssigneeFilterV0;

export type ReporterFilterV0 = {
	id: typeof REPORTER_FILTER_ID;
	value: StringAttributeFilterValue;
	isVisible?: boolean | null | undefined;
};
export type ReporterFilter = ReporterFilterV0;

export type PersistableSummaryFilterV0 = {
	id: typeof SUMMARY_FILTER_ID;
	value: string;
};
export type PersistableSummaryFilter = PersistableSummaryFilterV0;

export type SummaryFilter = PersistableSummaryFilter & {
	queries: string[];
	projectsById?: ProjectsById;
};

export type DependenciesFilterV0 = {
	id: typeof DEPENDENCIES_FILTER_ID;
	value: DependenciesFilterValue;
	issuesWithLinks?: Set<string>;
	specificIssueLinks?: Set<string>;
};
export type DependenciesFilterV1 = {
	id: typeof DEPENDENCIES_FILTER_ID;
	value: DependenciesFilterValue;
	issuesWithLinks?: Set<string>;
	specificIssueLinks?: Set<string>;
	offtrackIssueLinks?: Set<string>;
};
export type DependenciesFilter = DependenciesFilterV1;

export type LabelFilterV0 = {
	id: typeof LABEL_FILTER_ID;
	value: StringAttributeFilterValue;
};
export type LabelFilter = LabelFilterV0;

export type ComponentFilterV0 = {
	id: typeof COMPONENT_FILTER_ID | 'state.domain.filters.COMPONENT_FILTER_ID';
	value: NumberAttributeFilterValue;
};
export type ComponentFilter = ComponentFilterV0;

export type StatusFilterV0 = {
	id: typeof STATUS_FILTER_ID;
	value: StringAttributeFilterValue;
};
export type StatusFilter = StatusFilterV0;

export type StatusKeyFilterV0 = {
	id: typeof STATUS_KEY_FILTER_ID;
	value: StatusKeyFilterValue;
};
export type StatusKeyFilter = StatusKeyFilterV0;
export type PersistableIssueWarningFilterV0 = {
	id: typeof ISSUE_WARNING_FILTER_ID;
	value: StringAttributeFilterValue;
};
export type PersistableIssueWarningFilter = PersistableIssueWarningFilterV0;

export type IssueWarningFilterV0 = PersistableIssueWarningFilterV0 & {
	issueIdsWithWarnings: string[];
};
export type IssueWarningFilter = IssueWarningFilterV0;

export type SprintFilterV0 = {
	id: typeof SPRINT_FILTER_ID;
	value: StringAttributeFilterValue;
};
export type SprintFilter = SprintFilterV0;

export type IssueTypesFilterV0 = {
	id: typeof ISSUE_TYPES_FILTER_ID;
	value: NumberAttributeFilterValue;
};
export type IssueTypesFilter = IssueTypesFilterV0;

export type IssueTypeKeyFilterV0 = {
	id: typeof ISSUE_TYPE_KEY_FILTER_ID;
	value: IssueTypeKeyFilterValue;
};
export type IssueTypeKeyFilter = IssueTypeKeyFilterV0;

export type IssuePrioritiesFilterV0 = {
	id: typeof ISSUE_PRIORITIES_FILTER_ID;
	value: StringAttributeFilterValue;
};
export type IssuePrioritiesFilter = IssuePrioritiesFilterV0;

export type GoalFilterV0 = {
	id: typeof GOAL_FILTER_ID;
	value: StringAttributeFilterValue;
};
export type GoalFilter = GoalFilterV0;

export type IdeaFilterV0 = {
	id: typeof IDEA_FILTER_ID;
	value: StringAttributeFilterValue;
};

export type IdeaFilter = IdeaFilterV0;

export type DateRangeFilterValueV0 = {
	startDate?: number;
	endDate?: number;
};
export type DateRangeFilterValue = DateRangeFilterValueV0;

export type DateRangeFilterV0 = {
	id: typeof DATE_RANGE_FILTER_ID;
	value: DateRangeFilterValue;
};
export type DateRangeFilter = DateRangeFilterV0;

const listOfFilterIdsV0 = [
	HIERARCHY_FILTER_ID,
	HIERARCHY_RANGE_FILTER_ID,
	PROJECT_FILTER_ID,
	CROSS_PROJECT_RELEASE_FILTER_ID,
	RELEASE_FILTER_ID,
	TEAM_FILTER_ID,
	ASSIGNEE_FILTER_ID,
	REPORTER_FILTER_ID,
	SUMMARY_FILTER_ID,
	DEPENDENCIES_FILTER_ID,
	LABEL_FILTER_ID,
	STATUS_KEY_FILTER_ID,
	COMPONENT_FILTER_ID,
	ISSUE_WARNING_FILTER_ID,
	SPRINT_FILTER_ID,
	ISSUE_TYPE_KEY_FILTER_ID,
	ISSUE_PRIORITIES_FILTER_ID,
	CUSTOM_FIELD_FILTER_ID,
	GOAL_FILTER_ID,
	IDEA_FILTER_ID,
	DATE_RANGE_FILTER_ID,
];

export const listOfFilterIds = listOfFilterIdsV0;

export type FilterId = (typeof listOfFilterIds)[number];

export type FilterV0 =
	| HierarchyFilter
	| HierarchyRangeFilter
	| ProjectFilter
	| CrossProjectReleaseFilter
	| ReleaseFilter
	| TeamFilter
	| AssigneeFilter
	| SummaryFilter
	| DependenciesFilter
	| LabelFilter
	| StatusKeyFilter
	| ComponentFilter
	| IssueWarningFilter
	| SprintFilter
	| IssueTypeKeyFilter
	| IssuePrioritiesFilter
	| CustomFieldFilter
	| GoalFilter
	| IdeaFilter
	| DateRangeFilter;

export type Filter = FilterV0;

export type Filters = {
	[HIERARCHY_FILTER_ID]?: HierarchyFilter;
	[HIERARCHY_RANGE_FILTER_ID]?: HierarchyRangeFilter;
	[PROJECT_FILTER_ID]?: ProjectFilter;
	[CROSS_PROJECT_RELEASE_FILTER_ID]?: CrossProjectReleaseFilter;
	[RELEASE_FILTER_ID]?: ReleaseFilter;
	[TEAM_FILTER_ID]?: TeamFilter;
	[ASSIGNEE_FILTER_ID]?: AssigneeFilter;
	[REPORTER_FILTER_ID]?: ReporterFilter;
	[SUMMARY_FILTER_ID]?: SummaryFilter;
	[DEPENDENCIES_FILTER_ID]?: DependenciesFilter;
	[LABEL_FILTER_ID]?: LabelFilter;
	[COMPONENT_FILTER_ID]?: ComponentFilter;
	[STATUS_FILTER_ID]?: StatusFilter;
	[STATUS_KEY_FILTER_ID]?: StatusKeyFilter;
	[ISSUE_WARNING_FILTER_ID]?: IssueWarningFilter;
	[SPRINT_FILTER_ID]?: SprintFilter;
	[ISSUE_TYPES_FILTER_ID]?: IssueTypesFilter;
	[ISSUE_TYPE_KEY_FILTER_ID]?: IssueTypeKeyFilter;
	[ISSUE_PRIORITIES_FILTER_ID]?: IssuePrioritiesFilter;
	[CUSTOM_FIELD_FILTER_ID]?: CustomFieldFilter;
	[GOAL_FILTER_ID]?: GoalFilter;
	[IDEA_FILTER_ID]?: IdeaFilter;
	[DATE_RANGE_FILTER_ID]?: DateRangeFilter;
};

export type PersistableFiltersV0 = {
	[HIERARCHY_FILTER_ID]?: HierarchyFilterV0;
	[HIERARCHY_RANGE_FILTER_ID]?: HierarchyRangeFilterV0;
	[PROJECT_FILTER_ID]?: ProjectFilterV0;
	[CROSS_PROJECT_RELEASE_FILTER_ID]?: PersistableCrossProjectReleaseFilterV0;
	[RELEASE_FILTER_ID]?: PersistableReleaseFilterV0;
	[TEAM_FILTER_ID]?: TeamFilterV0;
	[ASSIGNEE_FILTER_ID]?: AssigneeFilterV0;
	[REPORTER_FILTER_ID]?: ReporterFilterV0;
	[SUMMARY_FILTER_ID]?: PersistableSummaryFilterV0;
	[DEPENDENCIES_FILTER_ID]?: DependenciesFilterV0;
	[LABEL_FILTER_ID]?: LabelFilterV0;
	[COMPONENT_FILTER_ID]?: ComponentFilterV0;
	[STATUS_FILTER_ID]?: StatusFilterV0;
	[STATUS_KEY_FILTER_ID]?: StatusKeyFilterV0;
	[ISSUE_WARNING_FILTER_ID]?: PersistableIssueWarningFilterV0;
	[SPRINT_FILTER_ID]?: SprintFilterV0;
	[ISSUE_TYPES_FILTER_ID]?: IssueTypesFilterV0;
	[ISSUE_TYPE_KEY_FILTER_ID]?: IssueTypeKeyFilterV0;
	[ISSUE_PRIORITIES_FILTER_ID]?: IssuePrioritiesFilterV0;
	[CUSTOM_FIELD_FILTER_ID]?: CustomFieldFilterV0;
	[GOAL_FILTER_ID]?: GoalFilterV0;
	[IDEA_FILTER_ID]?: IdeaFilterV0;
	[DATE_RANGE_FILTER_ID]?: DateRangeFilterV0;
};

export type PersistableFiltersV1 = {
	[HIERARCHY_FILTER_ID]?: HierarchyFilterV0;
	[HIERARCHY_RANGE_FILTER_ID]?: HierarchyRangeFilterV0;
	[PROJECT_FILTER_ID]?: ProjectFilterV0;
	[CROSS_PROJECT_RELEASE_FILTER_ID]?: PersistableCrossProjectReleaseFilterV0;
	[RELEASE_FILTER_ID]?: PersistableReleaseFilterV0;
	[TEAM_FILTER_ID]?: TeamFilterV0;
	[ASSIGNEE_FILTER_ID]?: AssigneeFilterV0;
	[REPORTER_FILTER_ID]?: ReporterFilterV0;
	[SUMMARY_FILTER_ID]?: PersistableSummaryFilterV0;
	[DEPENDENCIES_FILTER_ID]?: DependenciesFilterV0;
	[LABEL_FILTER_ID]?: LabelFilterV0;
	[COMPONENT_FILTER_ID]?: ComponentFilterV0;
	[STATUS_FILTER_ID]?: StatusFilterV0;
	[STATUS_KEY_FILTER_ID]?: StatusKeyFilterV0;
	[ISSUE_WARNING_FILTER_ID]?: PersistableIssueWarningFilterV0;
	[SPRINT_FILTER_ID]?: SprintFilterV0;
	[ISSUE_TYPES_FILTER_ID]?: IssueTypesFilterV0;
	[ISSUE_TYPE_KEY_FILTER_ID]?: IssueTypeKeyFilterV0;
	[ISSUE_PRIORITIES_FILTER_ID]?: IssuePrioritiesFilterV0;
	[CUSTOM_FIELD_FILTER_ID]?: CustomFieldFilterV0;
	[GOAL_FILTER_ID]?: GoalFilterV0;
	[IDEA_FILTER_ID]?: IdeaFilterV0;
	[DATE_RANGE_FILTER_ID]?: DateRangeFilterV0;
};

export type ResolvedFilters = {
	[HIERARCHY_FILTER_ID]: HierarchyFilter;
	[HIERARCHY_RANGE_FILTER_ID]: HierarchyRangeFilter;
	[PROJECT_FILTER_ID]: ProjectFilter;
	[CROSS_PROJECT_RELEASE_FILTER_ID]: CrossProjectReleaseFilter;
	[RELEASE_FILTER_ID]: ReleaseFilter;
	[TEAM_FILTER_ID]: TeamFilter;
	[ASSIGNEE_FILTER_ID]: AssigneeFilter;
	[REPORTER_FILTER_ID]: ReporterFilter;
	[SUMMARY_FILTER_ID]: SummaryFilter;
	[DEPENDENCIES_FILTER_ID]: DependenciesFilter;
	[LABEL_FILTER_ID]: LabelFilter;
	[COMPONENT_FILTER_ID]: ComponentFilter;
	[STATUS_KEY_FILTER_ID]: StatusFilter;
	[ISSUE_WARNING_FILTER_ID]: IssueWarningFilter;
	[SPRINT_FILTER_ID]: SprintFilter;
	[ISSUE_TYPE_KEY_FILTER_ID]: IssueTypesFilter;
	[ISSUE_PRIORITIES_FILTER_ID]: IssuePrioritiesFilter;
	[CUSTOM_FIELD_FILTER_ID]: CustomFieldFilter;
	[GOAL_FILTER_ID]: GoalFilter;
	[IDEA_FILTER_ID]: IdeaFilter;
	[DATE_RANGE_FILTER_ID]: DateRangeFilter;
};

export type FilterFixtures = ResolvedFilters;

export type FilterValue =
	| ResetHierarchyFilterValue
	| HierarchyFilterValue
	| HierarchyRangeFilterValue
	| AttributeFilterValue
	| DependenciesFilterValue
	| IssueTypeKeyFilterValue
	| StatusKeyFilterValue
	| string
	| boolean
	| CustomFieldFilterValue
	| DateRangeFilterValue;

export type FilterValues = {
	[HIERARCHY_FILTER_ID]?: HierarchyFilterValue;
	[HIERARCHY_RANGE_FILTER_ID]?: HierarchyRangeFilterValue;
	[PROJECT_FILTER_ID]?: NumberAttributeFilterValue;
	[CROSS_PROJECT_RELEASE_FILTER_ID]?: StringAttributeFilterValue;
	[RELEASE_FILTER_ID]?: StringAttributeFilterValue;
	[TEAM_FILTER_ID]?: StringAttributeFilterValue;
	[ASSIGNEE_FILTER_ID]?: StringAttributeFilterValue;
	[REPORTER_FILTER_ID]?: StringAttributeFilterValue;
	[SUMMARY_FILTER_ID]?: string;
	[DEPENDENCIES_FILTER_ID]?: DependenciesFilterValue;
	[LABEL_FILTER_ID]?: StringAttributeFilterValue;
	[COMPONENT_FILTER_ID]?: NumberAttributeFilterValue;
	[STATUS_FILTER_ID]?: StringAttributeFilterValue;
	[STATUS_KEY_FILTER_ID]?: StatusKeyFilterValue;
	[ISSUE_WARNING_FILTER_ID]?: StringAttributeFilterValue;
	[SPRINT_FILTER_ID]?: StringAttributeFilterValue;
	[ISSUE_TYPES_FILTER_ID]?: NumberAttributeFilterValue;
	[ISSUE_TYPE_KEY_FILTER_ID]?: IssueTypeKeyFilterValue;
	[ISSUE_PRIORITIES_FILTER_ID]?: StringAttributeFilterValue;
	[CUSTOM_FIELD_FILTER_ID]?: CustomFieldFilter;
	[GOAL_FILTER_ID]?: StringAttributeFilterValue;
	[IDEA_FILTER_ID]?: StringAttributeFilterValue;
	[DATE_RANGE_FILTER_ID]?: DateRangeFilterValue;
};

export type FiltersStateV0 = PersistableFiltersV0;
export type FiltersStateV1 = PersistableFiltersV1;
export type FiltersState = FiltersStateV1;
