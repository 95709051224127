export const TARGET_TYPE_SINGLE = 'SINGLE' as const;
export const TARGET_TYPE_ALL = 'ALL' as const;
export const TARGET_TYPE_SINGLE_AND_DESCENDANTS = 'SINGLE_AND_DESCENDANTS' as const; // Select this and all descendant issues
export const TARGET_TYPE_DESCENDANTS = 'DESCENDANTS' as const; // Select only descendant issues

export type TargetType =
	| typeof TARGET_TYPE_SINGLE
	| typeof TARGET_TYPE_ALL
	| typeof TARGET_TYPE_SINGLE_AND_DESCENDANTS
	| typeof TARGET_TYPE_DESCENDANTS;

export type LastSelectedIssue = {
	id?: string;
	group?: string;
	isSelected?: boolean;
};
