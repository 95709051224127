import React, { type ReactElement, useMemo } from 'react';
import Button from '@atlaskit/button';
import EmptyState from '@atlaskit/empty-state';
import { getActiveTrace } from '@atlaskit/react-ufo/experience-trace-id-context';
import { ErrorHashAndTraceId } from '@atlassian/jira-errors-handling/src/ui/error-hash-and-trace-id/index.tsx';
import { ErrorHash } from '@atlassian/jira-errors-handling/src/ui/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import imageUrl from './assets/error-modal.svg';
import messages from './messages.tsx';

export type ErrorPageProps = {
	error: Error | null;
};

// @ts-expect-error - TS2554 - Expected 0 arguments, but got 1.

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const reloadPage = () => window.location.reload(true);

const contactSupport = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.location.href = 'https://support.atlassian.com/contact/';
};

/**
 * @deprecated Do not use directly, instead use `JSErrorBoundary` in
 * `@atlassian/jira-error-boundaries`, which will log appropriate errors to
 * Sentry/SFX/Splunk. See https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo/browse/jira/docs/playbook/uncaught-error-handling.md
 */
export const ErrorPage = ({ error }: ErrorPageProps) => {
	const { formatMessage } = useIntl();
	const traceId = useMemo(() => {
		const traceContext = getActiveTrace();

		return traceContext?.traceId;
	}, []);

	let description: ReactElement | string = formatMessage(messages.description);
	if (error) {
		description = (
			<>
				<div>{description}</div>
				{traceId && fg('jfp-trace-id-in-error-boundaries') ? (
					<ErrorHashAndTraceId error={error} traceId={traceId} />
				) : (
					<ErrorHash error={error} />
				)}
			</>
		);
	}

	return (
		<EmptyState
			imageUrl={imageUrl}
			size="narrow"
			header={formatMessage(messages.header)}
			description={description}
			primaryAction={
				<Button
					appearance="primary"
					onClick={reloadPage}
					testId="error-boundary.ui.error-page.primary-action-button"
				>
					{formatMessage(messages.reloadPageButton)}
				</Button>
			}
			tertiaryAction={
				<Button
					appearance="link"
					onClick={contactSupport}
					testId="error-boundary.ui.error-page.tertiary-action-button"
				>
					{formatMessage(messages.contactSupportButton)}
				</Button>
			}
		/>
	);
};
