/**
 * @generated SignedSource<<2f29303b4111d428691a54980e2f3723>>
 * @relayHash d2600789b9360e3861bf19099c55ef3f
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6fc42a8a59d9e005570fb33ce75889e4d19ff53c1b749ca765baebfde4b0f8a6

import type { ConcreteRequest, Query } from 'relay-runtime';
export type TownsquareGoalStateValue = "archived" | "at_risk" | "cancelled" | "done" | "off_track" | "on_track" | "paused" | "pending" | "%future added value";
export type searchAtlasGoalsQuery$variables = {
  cloudId: string;
  pageCursor?: string | null | undefined;
  query: string;
};
export type searchAtlasGoalsQuery$data = {
  readonly townsquare: {
    readonly goalTql: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly key: string;
          readonly name: string;
          readonly state: {
            readonly label: string;
            readonly score: number | null | undefined;
            readonly value: TownsquareGoalStateValue;
          };
          readonly url: string;
        };
      } | null | undefined>;
      readonly pageInfo: {
        readonly endCursor: string | null | undefined;
        readonly hasNextPage: boolean;
      };
    } | null | undefined;
  };
};
export type searchAtlasGoalsQuery = {
  response: searchAtlasGoalsQuery$data;
  variables: searchAtlasGoalsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageCursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "pageCursor"
  },
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "q",
    "variableName": "query"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "key"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "url"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
},
v8 = {
  "kind": "ScalarField",
  "name": "label"
},
v9 = {
  "kind": "ScalarField",
  "name": "score"
},
v10 = {
  "kind": "ScalarField",
  "name": "value"
},
v11 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "hasNextPage"
    },
    {
      "kind": "ScalarField",
      "name": "endCursor"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "searchAtlasGoalsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "TownsquareQueryApi",
          "kind": "LinkedField",
          "name": "townsquare",
          "plural": false,
          "selections": [
            {
              "args": (v3/*: any*/),
              "concreteType": "TownsquareGoalConnection",
              "kind": "LinkedField",
              "name": "goalTql",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "concreteType": "TownsquareGoalEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "concreteType": "TownsquareGoal",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v4/*: any*/),
                            (v5/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": (v6/*: any*/),
                              "action": "THROW",
                              "path": "townsquare.goalTql.edges.node.url"
                            },
                            (v7/*: any*/),
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "TownsquareGoalState",
                                "kind": "LinkedField",
                                "name": "state",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v8/*: any*/),
                                    "action": "THROW",
                                    "path": "townsquare.goalTql.edges.node.state.label"
                                  },
                                  (v9/*: any*/),
                                  {
                                    "kind": "RequiredField",
                                    "field": (v10/*: any*/),
                                    "action": "THROW",
                                    "path": "townsquare.goalTql.edges.node.state.value"
                                  }
                                ]
                              },
                              "action": "THROW",
                              "path": "townsquare.goalTql.edges.node.state"
                            }
                          ]
                        },
                        "action": "THROW",
                        "path": "townsquare.goalTql.edges.node"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "townsquare.goalTql.edges"
                },
                (v11/*: any*/)
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "townsquare"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "searchAtlasGoalsQuery",
    "selections": [
      {
        "concreteType": "TownsquareQueryApi",
        "kind": "LinkedField",
        "name": "townsquare",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "TownsquareGoalConnection",
            "kind": "LinkedField",
            "name": "goalTql",
            "plural": false,
            "selections": [
              {
                "concreteType": "TownsquareGoalEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "TownsquareGoal",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "concreteType": "TownsquareGoalState",
                        "kind": "LinkedField",
                        "name": "state",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/)
                        ]
                      }
                    ]
                  }
                ]
              },
              (v11/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "6fc42a8a59d9e005570fb33ce75889e4d19ff53c1b749ca765baebfde4b0f8a6",
    "metadata": {},
    "name": "searchAtlasGoalsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7972153b35561bfbcfc8d8ca931a2d4f";

export default node;
