import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-save-your-changes.heading',
		defaultMessage: 'A plan is a draft until you save changes',
		description:
			'The text is shown as a title in the spotlight card for the unsaved changes button. Spotlight is triggered when a change is made.',
	},
	spotlightBody: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-save-your-changes.spotlight-body',
		defaultMessage:
			'Use <b>Unsaved changes</b> to save issues, teams or releases created or changed in your plan so they update in projects.',
		description:
			'The text is shown as the body for the spotlight card for the unsaved changes button. Spotlight is triggered when a change is made.',
	},
});
