import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	pageTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.page-title',
		defaultMessage: 'Timeline',
		description: 'Page header for timeline',
	},
	breadcrumbTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.breadcrumb-title',
		defaultMessage: 'Plans',
		description: 'Title for breadcrumbs on plan page',
	},
	readOnly: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.read-only',
		defaultMessage: 'Read-Only',
		description:
			'It is a label for the Read-only lozenge displayed next to the title when the plan is in read-only mode.',
	},
	archived: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.page-header.archived',
		defaultMessage: 'Archived',
		description:
			'It is a label for the Archived lozenge displayed next to the title when the plan is Archived.',
	},
});
