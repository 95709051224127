import { toggleExpansionInHierarchy } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/scope/index.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	toggleExpansionIssuesInHierarchy: (isExpanded: boolean) => {
		dispatch(toggleExpansionInHierarchy({ isExpanded }));
	},
});

export default mapDispatchToProps;
