import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import {
	SUMMARY_FILTER_ID,
	type SummaryFilter,
	type PersistableSummaryFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getProjectsById } from '../../projects/index.tsx';
import type { ProjectsById } from '../../projects/types.tsx';
import { getFiltersViewSettings } from '../../view-settings/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { SUMMARY_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

export const applyFilter = (issue: Issue, filter: SummaryFilter): boolean => {
	const { queries, projectsById = {} } = filter;
	const { summary = '', issueKey = '', project } = issue;

	if (queries.length === 0) return true;

	let title = `${issueKey} ${summary}`;
	if (isDefined(project)) {
		const projectDetails: Project = projectsById[project];
		if (isDefined(projectDetails)) {
			const projectKey = projectDetails.key;
			title = `${projectKey}-${issueKey} ${summary}`;
		}
	}
	title = title.toLowerCase();

	return queries.some((term) => title.includes(term));
};

export const getSummaryFilterState = (state: State): PersistableSummaryFilter =>
	getFiltersViewSettings(state)[SUMMARY_FILTER_ID] || {
		id: SUMMARY_FILTER_ID,
		value: '',
	};

/**
 * Split query into normalized terms.
 *
 * Term separators: comma, semicolon.
 * Normalized term is non-empty, lowercase, trimmed string.
 */
export const prepareQuery = (query: string): string[] =>
	(query.match(/[^,;]+/g) || []).map((x) => x.trim().toLowerCase()).filter((x) => x.length > 0);

export const getSummaryFilter = createSelector(
	[getSummaryFilterState, getProjectsById],
	({ value = '' }: PersistableSummaryFilter, projectsById: ProjectsById): SummaryFilter => ({
		id: SUMMARY_FILTER_ID,
		value,
		queries: prepareQuery(value),
		projectsById,
	}),
);
