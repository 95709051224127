import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addTeamButton: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.add-team-button',
		defaultMessage: 'Add team',
		description: 'Label for the "Add Team" button',
	},
	addTeamToPlanHeading: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.add-team-to-plan-heading',
		defaultMessage: 'Add a team to your plan',
		description: 'Heading of the landing step of the "Add Team to plan" dialog',
	},
	addTeamToPlanDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.add-team-to-plan-description',
		defaultMessage:
			'Teams in your plan help you balance and distribute work evenly and efficiently.',
		description: 'Introductory text in the "Add a team to your plan" modal dialog',
	},
	addExistingTeamRadioLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.add-existing-team-radio-label',
		defaultMessage: 'Add existing team',
		description:
			'The text above is used as the label for the radio button to add an existing team option, which is displayed inside the add team dialog',
	},
	createANewTeamRadioLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.create-a-new-team-radio-label',
		defaultMessage: 'Create a new team',
		description: 'Label for the radio button to create a new team',
	},
	selectAnExistingTeamLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.select-an-existing-team-label',
		defaultMessage: 'Select an existing team',
		description:
			'The text above is used as the label for the field used to select existing team, which is displayed inside the add team dialog',
	},
	selectOrEnterTeamNamePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.select-or-enter-team-name-placeholder',
		defaultMessage: 'Select or enter team name',
		description:
			'The text above is used as the placeholder for the field used to select existing team, which is displayed inside the add team dialog',
	},
	selectNewTeamType: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.select-new-team-type',
		defaultMessage: 'Select new team type',
		description:
			'The text above is used as the label for the field used to select new team, which is displayed inside the add team dialog',
	},
	availableAcrossOrgTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.available-across-org-team',
		defaultMessage: 'Available across organization',
		description: 'Label for an option of the field used to select the scope of a team',
	},
	availableAcrossOrgTeamHelpText: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.available-across-org-team-help-text',
		defaultMessage: 'View and reference this team in other linked Atlassian products.',
		description: 'Description below the field used to select the scope of a team',
	},
	planOnlyTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.plan-only-team',
		defaultMessage: 'Plan-only',
		description: 'Label for an option of the field used to select the scope of a team',
	},
	planOnlyLimitedTeamHelpText: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.plan-only-limited-team-help-text',
		defaultMessage: 'This team only lives within your plan.',
		description:
			'Description for the Plan-only option which is displayed below the field used to select the scope of a team ',
	},
	createPlanOnlyTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.create-plan-only-team',
		defaultMessage: 'Create a plan-only team',
		description: 'Header label of the "Create a plan-only team" dialog',
	},
	labelForViewPlanBtn: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.label-for-view-plan-btn',
		defaultMessage: 'View your plan',
		description: 'label for the view plan button',
	},
	labelForConfigTeamBtn: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.label-for-config-team-btn',
		defaultMessage: 'Configure team',
		description: 'label for the config team button',
	},
});
