import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	placeholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.parent.placeholder',
		defaultMessage: 'Choose a parent',
		description: 'The placeholder for parent cell',
	},
	parentCellLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.parent.parent-cell-label',
		defaultMessage: 'Choose a parent for issue {issue}',
		description: 'Label for parent cell',
	},
});
