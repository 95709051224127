export const ReportStatus = {
	LOADING: 'loading',
	SUCCESS: 'success',
	WARNING: 'warning',
} as const;

export type ReportStatus = (typeof ReportStatus)[keyof typeof ReportStatus];

export type ReportState = {
	status: ReportStatus;
};
