import noop from 'lodash/fp/noop';
import {
	createHook,
	createStore,
	createContainer,
	createActionsHook,
} from '@atlassian/react-sweet-state';
import { actions } from './actions.tsx';
import {
	selectResizerVisible,
	selectColumnStickyOffset,
	selectRowStickyOffset,
	selectColSpan,
	getColumnWidths,
	getColWidth,
} from './selectors.tsx';
import type { State } from './types.tsx';

const store = createStore<State, typeof actions>({
	initialState: {
		columns: [],
		widths: [],
		layout: 'auto',
		onWidthsChange: noop,
		stickyOffset: {
			vertical: 0,
			horizontal: 0,
		},
		rows: [],
	},
	actions,
});

export const useGrid = createHook(store);

export const useColumnConfig = createHook(store, {
	selector: (state, col: number) => state.columns[col],
});

export const useRowConfig = createHook(store, {
	selector: (state, col: number) => state.rows[col],
});

/**
 * Returns the colSpan if the column(s) to the right is 0px width.
 * @returns 0 if the column width is 0
 * @returns the colSpan otherwise
 */
export const useColSpan = createHook(store, {
	selector: selectColSpan,
});

export const useColumnWidth = createHook(store, {
	selector: (state, { column, preview }: { column: number; preview: boolean }) =>
		getColumnWidths(state, preview).at(column),
});

/** Returns the <col /> width attribute */
export const useColWidth = createHook(store, {
	selector: (state, { column, viewportWidth }: { column: number; viewportWidth: number }) =>
		getColWidth(state, viewportWidth, column),
});

export const useColumnWidths = createHook(store, {
	selector: (state, { preview }: { preview: boolean }) => getColumnWidths(state, preview),
});

export const useColumnStickyOffset = createHook(store, {
	selector: selectColumnStickyOffset,
});

export const useRowStickyOffset = createHook(store, {
	selector: selectRowStickyOffset,
});

export const useIsResizing = createHook(store, {
	selector: (state) => state.preview !== undefined,
});

/** Returns whether the resizer is NOT covered by the sticky columns */
export const useResizerVisible = createHook(store, {
	selector: selectResizerVisible,
});

export const useColumns = createHook(store, {
	selector: (state) => ({ columns: state.columns, widths: state.widths }),
});

export const useGridActions = createActionsHook(store);

export const GridProvider = createContainer(store, {
	onInit:
		() =>
		({ setState }, state: State) =>
			setState(state),
	onUpdate:
		() =>
		({ setState }, state: State) =>
			setState(state),
});

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Resizer } from './types';
