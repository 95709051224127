import React, { Component } from 'react';
import ContentLoader from 'react-content-loader';
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer';
import UFOLoadHold from '@atlaskit/react-ufo/load-hold';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	DEFAULT_CONTENT_LOADER_PROPS,
	RECT_BORDER_RADIUS,
	RECT_ROW_HEIGHT,
} from './common/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { TimelineProps } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export default class TimelineSkeleton extends Component<TimelineProps> {
	static defaultProps = {
		rowHeight: RECT_ROW_HEIGHT,
		sidePadding: 8,
		topPadding: 8,
		animate: true,
	};

	render() {
		const { ariaLabel, rowHeight, sidePadding, topPadding, animate } = this.props;

		return (
			<UFOLoadHold name="timeline-skeleton">
				<AutoSizer>
					{({ width, height }) => (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.skeleton} style={{ width, height }}>
							<ContentLoader
								ariaLabel={ariaLabel}
								width={width}
								height={height}
								{...(ff('com.atlassian.rm.jpo.transposition') ? { animate } : {})}
								{...DEFAULT_CONTENT_LOADER_PROPS}
							>
								<rect
									x={sidePadding}
									y={topPadding}
									{...RECT_BORDER_RADIUS}
									width={width / 2}
									height={rowHeight}
								/>
								<rect
									x={sidePadding + 100}
									y={topPadding + 90}
									{...RECT_BORDER_RADIUS}
									width={width / 2}
									height={rowHeight}
								/>
								<rect
									x={sidePadding + 200}
									y={topPadding + 132}
									{...RECT_BORDER_RADIUS}
									width={width / 5}
									height={rowHeight}
								/>
								<rect
									x={sidePadding + 250}
									y={topPadding + 258}
									{...RECT_BORDER_RADIUS}
									width={width / 3}
									height={rowHeight}
								/>
								<rect
									x={sidePadding + 300}
									y={topPadding + 342}
									{...RECT_BORDER_RADIUS}
									width={width / 2}
									height={rowHeight}
								/>
								<rect
									x={sidePadding + 350}
									y={topPadding + 468}
									{...RECT_BORDER_RADIUS}
									width={width / 2}
									height={rowHeight}
								/>
							</ContentLoader>
						</div>
					)}
				</AutoSizer>
			</UFOLoadHold>
		);
	}
}
