import {
	STORE_ADDED_TWP_TEAM,
	RESET_ADDED_TWP_TEAM,
	LOAD_CREATE_TWP_FORM,
	RESET_CREATE_TWP_FORM,
	type StoreAddedTwpTeamAction,
	type ResetAddedTwpTeamAction,
	type LoadCreateTwpFormAction,
	type ResetCreateTwpFormAction,
} from './actions.tsx';
import type { CreateTeam } from './types.tsx';

type Action =
	| StoreAddedTwpTeamAction
	| ResetAddedTwpTeamAction
	| LoadCreateTwpFormAction
	| ResetCreateTwpFormAction;

const initialState = {
	addedTWPTeamTitle: undefined,
	addedTWPTeamId: undefined,
	isCreateTwpFormLoading: false,
} as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: CreateTeam = initialState, action: Action) => {
	switch (action.type) {
		case STORE_ADDED_TWP_TEAM:
			return {
				...state,
				addedTWPTeamTitle: action.payload.teamTitle,
				addedTWPTeamId: action.payload.teamId,
			};
		case RESET_ADDED_TWP_TEAM:
			return {
				...state,
				addedTWPTeamTitle: undefined,
				addedTWPTeamId: undefined,
			};
		case LOAD_CREATE_TWP_FORM:
			return {
				...state,
				isCreateTwpFormLoading: true,
			};
		case RESET_CREATE_TWP_FORM:
			return {
				...state,
				isCreateTwpFormLoading: false,
			};
		default:
			return state;
	}
};
