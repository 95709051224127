.list {
	display: flex;
	flex-direction: column;
}

.row {
	align-content: center;
	align-items: center;
	display: flex;
	margin-bottom: 5px;
}

.swatch {
	border-radius: 3px;
	height: 16px;
	width: 16px;
}

.addColourLink {
	margin-left: -12px;
}

.allOtherIssuesButton {
	background: none;
}

.prioritySelect {
	width: 100%;
}

.allOtherIssues {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	padding-left: 12px;
}

.priorityOption {
	align-items: center;
	display: flex;
	line-height: 16px;
}

.priorityOptionIcon {
	background-size: contain;
	background-repeat: no-repeat;
	height: 12px;
	padding-right: 4px;
	width: 12px;
}
