import type { ComponentType } from 'react';
import type {
	ActionMeta,
	OptionType,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import type { IssueStatusesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import type { Sprint as ApiSprint } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';

export const issueAttributeName = 'sprint' as const;

export type Props = OwnProps & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	SelectForColumnCell: ComponentType<any>;
};

export type OwnProps = {
	handleSelection: (
		newValue: SprintSelectValue | OptionType | null | undefined,
		actionMeta: ActionMeta,
	) => void;
	isScrolling: boolean;
	isReadOnly?: boolean;
	issue: ScopeIssue;
	showOptimizations?: boolean;
	sprints: Sprint[];
	issueStatuses: IssueStatusesById;
	sprintsByTeam: {
		[teamId: string]: Sprint[];
	};
	teams: Team[];
	externalSprintsById: {
		[sprintId: string]: Sprint;
	};
	allSprints: Sprint[];
	internalSprintIds: string[];
	sprintsByIssueSources: {
		[issueSourceId: string]: Sprint[];
	};
	additionalTeamsById: {
		[teamId: string]: Team;
	};
	teamsById: {
		[teamId: string]: Team;
	};
	message:
		| {
				header: string;
				content: string;
		  }
		| undefined;
	externalSprintLabel: string;
	sprintRollUpForIssue: string[] | null;
};

export type SprintSelectOption = {
	value: string;
	label: string;
	isExternal?: boolean;
	isDisabled?: boolean;
	sprint?: ApiSprint;
	boardName?: string;
};

export type SprintSelectValue = {
	value: string | null | undefined;
	label: string | null;
	isExternal?: boolean;
	sprint?: ApiSprint;
};

export type GroupedSelectOptions = {
	label: string;
	options: SprintSelectOption[];
};
