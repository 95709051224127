import {
	TARGET_TYPE_SINGLE,
	TARGET_TYPE_ALL,
	TARGET_TYPE_SINGLE_AND_DESCENDANTS,
	TARGET_TYPE_DESCENDANTS,
	type TargetType,
} from './types.tsx';

export const TargetTypes = {
	single: TARGET_TYPE_SINGLE,
	all: TARGET_TYPE_ALL,
	singleAndDescendants: TARGET_TYPE_SINGLE_AND_DESCENDANTS,
	descendants: TARGET_TYPE_DESCENDANTS,
} as const;

export const TOGGLE_SELECTED_ISSUES =
	'state.ui.main.tabs.roadmap.scope.issues.selectable-issue.TOGGLE_SELECTED_ISSUES' as const;

export type ToggleSelectedIssuesActionPayload = {
	currentIssueId?: string; // is used for deselecting current issue when only descendants should be chosen,
	group?: string;
	ids: string[];
	isSelected: boolean;
	targetType?: TargetType; // empty means programmatically (e.g.de-selecting all when applying filter),
	toggleAll?: boolean;
};

export type ToggleSelectedIssuesAction = {
	type: typeof TOGGLE_SELECTED_ISSUES;
	payload: ToggleSelectedIssuesActionPayload;
};

export const toggleSelectedIssues = (payload: ToggleSelectedIssuesActionPayload) => ({
	type: TOGGLE_SELECTED_ISSUES,
	payload,
});

export type Action = ToggleSelectedIssuesAction;
