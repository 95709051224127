import type { AddRemoveVersionFromCrossProjectVersionActionPayload } from '../cross-project-versions/actions.tsx';
import type { CrossProjectVersion } from '../cross-project-versions/types.tsx';

export const RESET = 'state.domain.removed-cross-project-versions.RESET' as const;
export const ADD = 'state.domain.removed-cross-project-versions.ADD' as const;
export const REMOVE = 'state.domain.removed-cross-project-versions.REMOVE' as const;
export const ADD_VERSION_IN_CROSS_PROJECT_VERSION =
	'state.domain.removed-cross-project-versions.ADD_VERSION_IN_CROSS_PROJECT_VERSION' as const;
export const REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION =
	'state.domain.removed-cross-project-versions.REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION' as const;

export type ResetActionPayload = CrossProjectVersion[];

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type AddActionPayload = CrossProjectVersion;

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export type RemoveAction = {
	type: typeof REMOVE;
	versionId: string;
};

export type AddVersionInCrossProjectVersionAction = {
	type: typeof ADD_VERSION_IN_CROSS_PROJECT_VERSION;
	payload: AddRemoveVersionFromCrossProjectVersionActionPayload;
};

export type RemoveVersionFromCrossProjectVersionAction = {
	type: typeof REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION;
	payload: AddRemoveVersionFromCrossProjectVersionActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const add: (arg1: AddActionPayload) => AddAction = (payload) => ({
	type: ADD,
	payload,
});

export const remove = (versionId: string): RemoveAction => ({
	type: REMOVE,
	versionId,
});

export const addVersionInCrossProjectVersion = (
	payload: AddRemoveVersionFromCrossProjectVersionActionPayload,
): AddVersionInCrossProjectVersionAction => ({
	type: ADD_VERSION_IN_CROSS_PROJECT_VERSION,
	payload,
});

export const removeVersionFromCrossProjectVersion = (
	payload: AddRemoveVersionFromCrossProjectVersionActionPayload,
): RemoveVersionFromCrossProjectVersionAction => ({
	type: REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION,
	payload,
});
