import { LOAD, CANCEL, SUBMIT, FAIL, type Action } from './actions.tsx';
import type { RequiredFields } from './types.tsx';

const initialState: RequiredFields = {
	isWaiting: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: RequiredFields = initialState, action: Action): RequiredFields => {
	switch (action.type) {
		case LOAD: {
			const { issueId, error } = action.payload;
			return {
				isWaiting: true,
				issueId,
				error,
			};
		}
		case CANCEL:
		case SUBMIT:
		case FAIL:
			return { isWaiting: false };
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
