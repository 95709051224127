import type { CustomLabelsByField } from './types.tsx';

export const ADD = 'state.domain.custom-labels.ADD' as const;
export const RESET = 'state.domain.custom-labels.RESET' as const;
export const RESET_ALL = 'state.domain.custom-labels.RESET_ALL' as const;

export type AddActionPayload = {
	customFieldId: number;
	labels: string[];
};

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export type ResetActionPayload = AddActionPayload;

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type ResetAllActionPayload = CustomLabelsByField;

export type ResetAllAction = {
	type: typeof RESET_ALL;
	payload: ResetAllActionPayload;
};

export const add = (payload: AddActionPayload): AddAction => ({
	type: ADD,
	payload,
});

export const reset = (payload: ResetActionPayload): ResetAction => ({
	type: RESET,
	payload,
});

export const resetAll = (payload: ResetAllActionPayload): ResetAllAction => ({
	type: RESET_ALL,
	payload,
});
