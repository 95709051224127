export const UNSENT = 'common.api.request.status.UNSENT' as const;
export const LOADING = 'common.api.request.status.LOADING' as const;
export const SUCCESS = 'common.api.request.status.SUCCESS' as const;
export const ERROR = 'common.api.request.status.ERROR' as const;

export type Status = typeof UNSENT | typeof LOADING | typeof SUCCESS | typeof ERROR;

export type Error = {
	code: number;
	// NOTE deliberately inexact
	message: {
		error: string;
	};
};

export type Request<Data> = Readonly<{
	status: Status;
	data?: Data;
	error?: Error;
}>;
