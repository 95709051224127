export const SUSPEND = 'state.enhancers.suspender.SUSPEND' as const;
export const RESUME = 'state.enhancers.suspender.RESUME' as const;

export const suspend = () => ({ type: SUSPEND });
export const resume = () => ({ type: RESUME });

interface GenericDispatch {
	type: string;
}

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => {
	let lockCounter = 0;
	let currentListeners: Array<Array<() => void>> = [];
	let nextListeners = currentListeners;

	const ensureCanMutateNextListeners = () => {
		if (nextListeners === currentListeners) {
			nextListeners = currentListeners.slice();
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const subscribe = (listener: any) => {
		if (typeof listener !== 'function') {
			throw new Error('Expected listener to be a function.');
		}

		// eslint-disable-next-line @typescript-eslint/no-shadow
		const entry = nextListeners.find((entry) => listener === entry[0]);

		if (entry) return entry[1];

		const unsubscribe = () => {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const index = nextListeners.findIndex((entry) => listener === entry[0]);
			if (index < 0) return;
			ensureCanMutateNextListeners();
			nextListeners.splice(index, 1);
		};

		ensureCanMutateNextListeners();
		nextListeners.push([listener, unsubscribe]);

		return unsubscribe;
	};

	const notifyListeners = () => {
		if (lockCounter > 0) return;

		currentListeners = nextListeners;
		for (let i = 0; i < currentListeners.length; i++) {
			currentListeners[i][0]();
		}
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (next: any) =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(...args: any[]) => {
			const store = next(...args);
			const subscribeImmediate = store.subscribe;
			const dispatch = <T extends GenericDispatch>(...dispatchArgs: T[]) => {
				const [action] = dispatchArgs;
				switch (action.type) {
					case RESUME:
						if (lockCounter <= 0) {
							throw new Error(`Unbalanced ${RESUME}`);
						}
						lockCounter--;
						notifyListeners();
						return action;
					case SUSPEND:
						lockCounter++;
						return action;
					default: {
						const result = store.dispatch(...dispatchArgs);
						notifyListeners();
						return result;
					}
				}
			};
			return {
				...store,
				dispatch,
				subscribe,
				subscribeImmediate,
			};
		};
};
