import React, { useCallback } from 'react';
import EditIcon from '@atlaskit/icon/core/migration/edit';
import { Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { StatusCategory } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { asyncInlineEditStatusModal } from '@atlassian/jira-inline-edit-status-modal/entrypoint.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { defaultModalProps } from '@atlassian/jira-issue-status-and-types-common/src/index.tsx';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger/src/ModalEntryPointPressableTrigger.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { messages } from './messages.tsx';

type Props = {
	status: {
		id: string;
		statusName: string;
		statusCategory: StatusCategory;
	};
	projectId: string;
	issueTypeId: string;
};

export const EditStatusButton = ({ status, projectId, issueTypeId }: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const onClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		event.stopPropagation(); // prevents transition from being selected
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'editStatusButton',
			}),
		);
	};

	const onCloseModal = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'editStatusModal',
				action: 'closed',
			}),
		);
	}, [createAnalyticsEvent]);

	const entryPointProps = {
		onClose: onCloseModal,
		status,
		issueTypeId,
		projectId,
	};

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={asyncInlineEditStatusModal}
			entryPointProps={entryPointProps}
			interactionName="edit-status-modal"
			modalProps={defaultModalProps}
			useInternalModal
		>
			{({ ref }) => (
				<Pressable
					tabIndex={0}
					xcss={iconButtonStyles}
					onClick={onClick}
					aria-label={formatMessage(messages.statusNameButtonLabel, {
						statusName: status.statusName,
					})}
					data-show-on-hover
					// ref returned from entrypoint is of Type 'RefObject<HTMLElement>' is not assignable to type 'Ref<HTMLButtonElement>
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					ref={ref as React.Ref<HTMLButtonElement>}
				>
					<Tooltip content={formatMessage(messages.statusEditNameTooltip)} position="bottom">
						<EditIcon label="" LEGACY_size="small" color={token('color.icon')} />
					</Tooltip>
				</Pressable>
			)}
		</ModalEntryPointPressableTrigger>
	);
};

const iconButtonStyles = xcss({
	borderRadius: 'border.radius',
	outlineOffset: 'space.0',
	padding: 'space.025',
	marginInline: 'space.100',
	opacity: 0,
	transition: 'opacity 0.3s ease',
	':focus': {
		boxShadow: 'elevation.shadow.overflow',
		opacity: 1,
	},
	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
		opacity: 1,
	},
});
