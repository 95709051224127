import { SUB_TASK_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { State } from '../../state/types.tsx';
import * as issueQuery from '../issues/index.tsx';
import type { IssueMap, DescendantsMap } from '../issues/types.tsx';

export const getTimelineState = (state: State) => state.ui.Main.Tabs.Roadmap.TimelinePreview;

// Here be dragons. It seems that if the props is properly typed, then flow expects ALL selectors to have that type for second arg!
// Super weird magical behaviour happens if you touch this :(
// TODO(ruslan / JPOS-2211) check this after upgrading flow.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getProp = (string: string) => (_: State, props: any) => props[string];

// Get the issue *and* the linear history of all its ancestors.
export const getAncestorsPure = (issueMap: IssueMap, issueId: string): Issue[] =>
	[issueMap[issueId]].concat(issueQuery.getAncestors(issueMap[issueId], issueMap, Infinity));

// Get the issue *and* all its transitive descendents.
export const getDescendentsPure = (
	issueMap: IssueMap,
	descendantsMap: DescendantsMap,
	issueId: string,
	isStrict?: boolean | null,
): Issue[] => {
	const descendants = issueQuery.getDescendants(
		issueMap[issueId],
		issueMap,
		descendantsMap,
		SUB_TASK_LEVEL,
	);
	return isStrict ? descendants : [issueMap[issueId]].concat(descendants);
};

export const getAncestors = createSelector(
	[issueQuery.getIssueMapById, getProp('issueId')],
	getAncestorsPure,
);

export const getDescendents = createSelector(
	[
		issueQuery.getIssueMapById,
		issueQuery.getChildrenIdsByParent,
		getProp('issueId'),
		getProp('isStrict'),
	],
	getDescendentsPure,
);
