import React, { type SyntheticEvent, useRef } from 'react';
import * as R from 'ramda';
import FocusLock from 'react-focus-lock';
import { injectIntl, type IntlShape } from '@atlassian/jira-intl';
import {
	DialogMenuContainer,
	DialogMenuItem,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import {
	sort,
	filter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees/utils.tsx';
import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/assignees/types.tsx';
import {
	AssigneeCheckbox as UserCheckbox,
	TriggerButtonText,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/filter-bar/assignee-filter/view.tsx';
import ClearFilterButton from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/filter-bar/common/clear-filter/index.tsx';
import { FILTER_MAX_WIDTH } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/filter-bar/common/index.tsx';
import NoMatchFound from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/top/filter-bar/common/no-match-text/index.tsx';
import { useHandleDialogClose } from '@atlassian/jira-portfolio-3-portfolio/src/common/hooks/use-handle-dialog-close.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import TriggerButton from './trigger-button/index.tsx';
import type { Props as BaseProps } from './types.tsx';

type Props = BaseProps & {
	intl: IntlShape;
};

function UserFilter({
	intl,
	searchQuery,
	onQueryChange,
	value,
	onRequestChange,
	onRequestClear,
	users,
	reviewChanges,
	currentUser,
}: Props) {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isSelected = (user: Person): boolean => R.includes(user.personId, value);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleCheckboxChange = (e: SyntheticEvent<any>) => {
		const { value: personId, checked } = e.currentTarget;
		const nextValue = ((): typeof value => {
			const withoutUser = R.filter(R.pipe(R.equals(personId), R.not))(value);

			if (!checked) {
				return withoutUser;
			}

			return [...withoutUser, personId];
		})();

		const visibleIssueIds = reviewChanges
			.filter((change) => {
				if (change.lastModified?.personId !== undefined) {
					return nextValue.includes(change.lastModified?.personId);
				}
				return false;
			})
			.map((change) => change.id);

		onRequestChange({ value: nextValue, visibleIssueIds });

		const [actionSubject, action] =
			PRODUCT_ANALYTICS_EVENT_NAMES.REVIEW_CHANGES_MODAL_USER_FILTER_CHANGED.split(' ');
		fireUIAnalytics(createAnalyticsEvent({ action, actionSubject }), {
			isCurrentUser: currentUser.key === personId,
			checked,
		});
	};

	const usersToDisplay = sort(filter(searchQuery, users));

	return (
		<FocusLock>
			<ClearFilterButton isVisible={!!value.length} onClearClick={onRequestClear} />
			<SearchField
				placeholder={intl.formatMessage(messages.searchPlaceholder)}
				searchQuery={searchQuery}
				onQueryChange={onQueryChange}
			/>
			{usersToDisplay.map((user, index) => (
				<DialogMenuItem key={index}>
					<UserCheckbox checked={isSelected(user)} onChange={handleCheckboxChange} person={user} />
				</DialogMenuItem>
			))}

			{R.isEmpty(usersToDisplay) && <NoMatchFound />}
		</FocusLock>
	);
}

function UserFilterDialog(props: Props) {
	const { isOpen, onOpenChange } = props;
	const dialogRef = useRef<HTMLDivElement>(null);
	useHandleDialogClose(dialogRef, onOpenChange, isOpen);

	return (
		<div ref={dialogRef}>
			<InlineDialog
				noPaddings
				maxWidth={FILTER_MAX_WIDTH}
				isOpen={isOpen}
				onClose={onOpenChange}
				content={
					<DialogMenuContainer>
						<UserFilter {...props} />
					</DialogMenuContainer>
				}
				testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.filters.user-filter"
			>
				<TriggerButton
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					triggerButtonText={<TriggerButtonText value={props.value} persons={props.users} />}
				/>
			</InlineDialog>
		</div>
	);
}

export default injectIntl(UserFilterDialog);
