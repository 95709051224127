import React from 'react';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { Props } from './types.tsx';

/** Gets the state from Redux and renders the children when its should show the CTT onboarding. */
export const ShouldShowCttOnboarding = ({
	planType,
	creatorAccountId,
	isPlanLoading,
	children,
}: Props) => {
	const { atlassianAccountId } = useTenantContext();
	const shouldShowCttOnboarding =
		!isPlanLoading && planType === 'CROSS_TEAM' && creatorAccountId === atlassianAccountId;

	if (isPlanLoading) return null;

	return <>{children(shouldShowCttOnboarding)}</>;
};
