import type { IssueExpansionState } from './types.tsx';

export const RESET = 'state.domain.view-settings.issue-expansions.RESET' as const;
export const UPDATE_EXPANDED_ISSUE_LINKS =
	'state.domain.view-settings.issue-expansions.UPDATE_EXPANDED_ISSUE_LINKS' as const;
export const SET_IS_EXPANDED =
	'state.domain.view-settings.issue-expansions.SET_IS_EXPANDED' as const;

type IssueId = string;
type IsExpanded = boolean;

export type SetIsExpandedActionPayload = Record<IssueId, IsExpanded>;

export type UpdateExpandedIssueLinksPayload = Record<IssueId, IssueId>;

export type SetIsExpandedAction = {
	type: typeof SET_IS_EXPANDED;
	payload: SetIsExpandedActionPayload;
};

export type ResetAction = {
	type: typeof RESET;
	payload: IssueExpansionState;
};

export type UpdateExpandedIssueLinksAction = {
	type: typeof UPDATE_EXPANDED_ISSUE_LINKS;
	payload: UpdateExpandedIssueLinksPayload;
};

export const setIsExpanded = (payload: SetIsExpandedActionPayload) => ({
	type: SET_IS_EXPANDED,
	payload,
});

export const updateExpandedIssueLinks = (payload: UpdateExpandedIssueLinksPayload) => ({
	type: UPDATE_EXPANDED_ISSUE_LINKS,
	payload,
});

export const reset = (payload: IssueExpansionState): ResetAction => ({
	type: RESET,
	payload,
});
