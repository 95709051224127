export const OPEN_DIALOG =
	'state.ui.main.tabs.releases.cross-project-releases.delete-release-dialog.OPEN_DIALOG' as const;

export const CLOSE_DIALOG =
	'state.ui.main.tabs.releases.cross-project-releases.delete-release-dialog.CLOSE_DIALOG' as const;

export const INITIATE_REQUEST =
	'state.ui.main.tabs.releases.cross-project-releases.delete-release-dialog.INITIATE_REQUEST' as const;

export const RESET_REQUEST =
	'state.ui.main.tabs.releases.cross-project-releases.delete-release-dialog.RESET_REQUEST' as const;

export type OpenDialogAction = {
	type: typeof OPEN_DIALOG;
	versionId: string;
};

export type CloseDialogAction = {
	type: typeof CLOSE_DIALOG;
};

export type InitiateRequsetAction = {
	type: typeof INITIATE_REQUEST;
};

export type ResetRequsetAction = {
	type: typeof RESET_REQUEST;
};

export const openDialog = (versionId: string) => ({
	type: OPEN_DIALOG,
	versionId,
});

export const closeDialog = () => ({
	type: CLOSE_DIALOG,
});

export const initiateRequest = () => ({
	type: INITIATE_REQUEST,
});

export const resetRequest = () => ({
	type: RESET_REQUEST,
});
