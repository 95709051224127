import type { Issue } from '../../state/domain/issues/types.tsx';
import type { OriginalIssues } from '../../state/domain/original-issues/types.tsx';

export const getAllAssociatedIssueIds = (issues: Issue[], originalIssues: OriginalIssues) => {
	const associatedIssuesIds = issues.map((issue) => issue.associatedIssueIds || []).flat();
	const issuesIds = issues.map((issue) => issue.id);
	const originalsIds = issuesIds.map((id) => originalIssues[id]?.associatedIssueIds || []).flat();

	// return only unique ids (without duplicates)
	return Array.from(new Set([...associatedIssuesIds, ...originalsIds])) || [];
};
