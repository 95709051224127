import React, { Component, type FocusEvent, type KeyboardEvent, type ChangeEvent } from 'react';
import classNames from 'classnames';
import AkTextField from '@atlaskit/textfield';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
export default class TextField extends Component<Props, State> {
	// @ts-expect-error - TS2564 - Property 'inputEl' has no initializer and is not definitely assigned in the constructor.
	// eslint-disable-next-line react/sort-comp
	inputEl: HTMLInputElement;

	state = {
		oldValue: this.props.value,
		value: this.props.value,
		valid: true,
	};

	componentDidMount() {
		if (this.inputEl && this.inputEl.type === 'number') {
			this.inputEl.addEventListener('input', this.onInputNative);
			this.inputEl.addEventListener('mousedown', this.onChildMouseDown);
			this.inputEl.setAttribute('step', (this.props.step && this.props.step.toString()) || 'any');
		}
	}

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.value !== this.props.value) {
			this.setState({ value: nextProps.value, oldValue: nextProps.value });
		}
	}

	componentWillUnmount() {
		if (this.inputEl && this.inputEl.type === 'number') {
			this.inputEl.removeEventListener('mousedown', this.onChildMouseDown);
			this.inputEl.removeEventListener('input', this.onInputNative);
		}
	}

	onChildMouseDown = () => {
		// workaround for JPOS-2334 Firefox: Using number spinner button doesn't show dorito
		// see more https://bugzilla.mozilla.org/show_bug.cgi?id=1012818
		this.inputEl.focus();
	};

	onBlur = (e: FocusEvent<HTMLInputElement>) => {
		const { oldValue, value, valid } = this.state;
		let newValue = value;
		if (valid) {
			if (this.props.type === 'number' && value !== undefined) {
				newValue = `${Math.round(parseFloat(value) * 1000) / 1000}`;
			}
			this.setState({
				value: newValue,
				oldValue: newValue,
				valid: true,
			});
		} else {
			this.setState({
				value: oldValue,
				valid: true,
			});
			newValue = oldValue;
		}
		if (this.props.onDone) {
			this.props.onDone(newValue);
		}
		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	};

	onInputNative = (e: Event) => {
		const target = e.target;
		if (target instanceof HTMLInputElement) {
			const {
				value,
				validity: { valid },
			} = target;
			this.setState({ value, valid });
		}
	};

	onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		const target = e.target;
		if (e.key === 'Escape') {
			this.setState({ value: this.props.value }, () => {
				if (target instanceof HTMLInputElement) {
					target.blur();
				}
			});
		}
		if (e.key === 'Enter') {
			if (target instanceof HTMLInputElement) {
				target.blur();
			}
		}
		if (this.props.onKeyDown) {
			this.props.onKeyDown(e);
		}
	};

	onFocus = (event: FocusEvent<HTMLInputElement>) => {
		if (this.props.isReadOnly) {
			event.preventDefault();
		} else if (this.props.onFocus) {
			this.props.onFocus(event);
		}
	};

	onChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target instanceof HTMLInputElement) {
			this.setState({ value: e.target.value });
			if (this.props.onChange) {
				this.props.onChange(e);
			}
		}
	};

	render() {
		const { isAlignRight, isTransparentBackground, isReadOnly, onDone, errorMessage, ...props } =
			this.props;
		const className = classNames({
			[styles.isTransparentBackground]: isTransparentBackground,
			[styles.isAlignRight]: isAlignRight,
			[styles.error]: !this.state.valid,
			[styles.isReadOnly]: isReadOnly,
		});
		const { value } = this.state;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={className}>
				<AkTextField
					{...props}
					isReadOnly={isReadOnly}
					onBlur={this.onBlur}
					onChange={this.onChange}
					onFocus={this.onFocus}
					onKeyDown={this.onKeyDown}
					ref={(inputEl?: HTMLInputElement | null) => {
						if (inputEl) {
							this.inputEl = inputEl;
						}
					}}
					value={value === null || value === undefined ? '' : value}
				/>
			</div>
		);
	}
}
