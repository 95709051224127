import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Box, Text } from '@atlaskit/primitives';

import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import type { Props } from './types.tsx';

export const AssociatedIssues = ({ associatedIssuesIds, associatedIssues }: Props) => {
	if (isEmpty(associatedIssuesIds)) {
		return <Box>-</Box>;
	}

	const associatedIssuesNames = associatedIssuesIds
		.map((id) => associatedIssues?.[id]?.summary)
		.filter((name) => !!name)
		.sort()
		.join(', ');

	return (
		<EllipsedWithTooltip content={associatedIssuesNames}>
			<Text>{associatedIssuesNames}</Text>
		</EllipsedWithTooltip>
	);
};
