import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createGroup: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.labels.create-group',
		defaultMessage: 'Create group',
		description: 'The label for the Create group link in the group-by-label feature',
	},
	labelPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.labels.label-placeholder',
		defaultMessage: 'Choose a label',
		description: 'The placeholder for the group-by label select',
	},
});
