import React, { useRef, useEffect, useContext } from 'react';
import { Flex, xcss } from '@atlaskit/primitives';
import {
	useHorizontalScrolling,
	useTimelineRuler,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import type { PlanInfo } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import { context as StickyHeaderContext } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/sticky-header/view.tsx';
import EditTeamModal from '../edit-team-modal/index.tsx';
import { getCapacityPlanningMessage } from '../utils.tsx';

type Props = {
	team?: Team;
	teamId: string;
	plan: PlanInfo;
	hasSprints?: boolean;
};

function useFixedContainer() {
	const elRef = useRef<HTMLDivElement>(null);
	const { sticky } = useContext(StickyHeaderContext);
	const [{ zoomLevel, viewport }] = useHorizontalScrolling();
	const [{ msToPx }] = useTimelineRuler();

	useEffect(() => {
		const el = elRef.current;

		if (el == null) {
			return;
		}

		el.style.left = `${msToPx(viewport.offset)}px`;
	}, [sticky, zoomLevel, msToPx, viewport.offset]);

	return elRef;
}

function SprintsPlaceholder({ team, teamId, plan, hasSprints }: Props) {
	const containerRef = useFixedContainer();

	const { planningUnit, issueSources } = plan ?? {};
	const { issueSource } = team ?? {};

	const assumedMode = (() => {
		// If the planning unit is StoryPoints it's not possible to use the Kanban view
		if (planningUnit === 'StoryPoints') {
			return 'Scrum';
		}

		if (hasSprints) {
			return 'Scrum';
		}

		return 'Kanban';
	})();

	const message = getCapacityPlanningMessage({
		projectType: assumedMode,
		teamAdded: !!team?.id,
		teamHasIssueSource: !!issueSource,
		planHasBoards: issueSources.some(({ sprintIds }) => sprintIds && sprintIds.length > 0),
	});

	return (
		<Flex
			testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.placeholder.sprint-placeholder"
			xcss={SprintsPlaceholderStyles}
			ref={containerRef}
		>
			<EditTeamModal team={team} teamId={teamId} mode={assumedMode} trigger={message} />
		</Flex>
	);
}

const SprintsPlaceholderStyles = xcss({
	position: 'absolute',
	height: '100%',
});

export default SprintsPlaceholder;
