.searchFieldContainer input {
	text-overflow: ellipsis;
}

.clearSearchField {
	padding-right: 14px;
}

.clearSearchField > button {
	display: flex;
}

.clearSearchField span:hover {
	color: var(--ds-text-disabled, var(--adg3-color-N50));
}

.hasInput input {
	padding-right: var(--jpo-common-base-spacing);
}
