import {
	ADD,
	RESET,
	RESET_ALL,
	type ResetAllAction,
	type AddAction,
	type ResetAction,
} from './actions.tsx';
import type { CustomLabelsByField } from './types.tsx';

const initialState: CustomLabelsByField = {};

type Action = AddAction | ResetAction | ResetAllAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: CustomLabelsByField = initialState, action: Action) => {
	switch (action.type) {
		case RESET_ALL: {
			return action.payload;
		}
		case RESET: {
			const { customFieldId, labels } = action.payload;
			return { ...state, [customFieldId]: labels.sort() };
		}
		case ADD: {
			const { customFieldId, labels } = action.payload;
			const labelsSet = new Set((state[customFieldId] || []).concat(labels));
			return { ...state, [customFieldId]: Array.from(labelsSet).sort() };
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
