import * as R from 'ramda';
import type {
	TeamValues,
	IssueSource,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	ENTITY,
	SCENARIO_TYPE,
	type ScenarioType,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

import type { Warnings } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types.tsx';
import type { OriginalTeams } from '../../state/domain/original-teams/types.tsx';
import type { Team } from '../../state/domain/teams/types.tsx';
import type { EntityMetadata } from '../../state/domain/update-jira/changes/types.tsx';
import { getPersons } from '../persons/index.tsx';
import { getIssueSources, findIssueSourceById } from '../plan/index.tsx';
import {
	getAllChangesForResource,
	getOriginalResources,
	getResourcesChangesMetaData,
	getCommitWarningsForResources,
} from '../resource/index.tsx';
import type {
	Change,
	TeamAndResourceChange as ViewTeamAndResourceChange,
} from '../update-jira/types.tsx';
import {
	findTeamById,
	getTeamChangesMetaData,
	getTeams,
	getOriginalTeams,
	getCommitWarningsForTeams,
} from './index.tsx';
import type { TeamAndResourceChange } from './types.tsx';

export type NullToUndefined<T> = Extract<T, null> extends never ? T : T | undefined;
export type NullToUndefinedObj<T> = { [K in keyof T]: NullToUndefined<T[K]> };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const nullToUndefined = <T extends Record<PropertyKey, any>>(
	values: T,
): NullToUndefinedObj<T> =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	R.map<Record<PropertyKey, any>, NullToUndefinedObj<Record<PropertyKey, any>>>(
		(value: T[keyof T]) => (value === null ? undefined : value),
		values,
	);

export const getChangeScenarioType = (
	id: string,
	issueMetadata: EntityMetadata,
): ScenarioType | undefined => {
	if (issueMetadata && issueMetadata[id]) {
		return issueMetadata[id].scenarioType;
	}
};

export const getTeamOrResourceChangeCount = createSelector(
	[getTeams, getOriginalTeams, getOriginalResources],
	(teams, originalTeams, originalResources) =>
		teams.reduce((total: number, team: Team) => {
			const hasResourceChange =
				team.resources.filter((resource) => originalResources[resource.itemKey]).length > 0;
			const hasTeamChange = originalTeams[team.id];

			if (hasResourceChange || hasTeamChange) {
				return total + 1;
			}

			return total;
		}, 0),
);

// finds the change details from the details object for a particular change
export const getDetailChange = (change: ViewTeamAndResourceChange) => {
	const teamDetails = change.details;
	const teamChange =
		change.category === ENTITY.RESOURCE
			? teamDetails.changes.find(
					(c) => c.attributeName === change.attributeName && c.resourceId === change.id,
				)
			: teamDetails.changes.find((c) => c.attributeName === change.attributeName);

	return teamChange;
};

export const getDisplayableValueChanges = (team: Team | TeamValues): TeamValues => {
	const values: TeamValues = R.map<
		NullToUndefinedObj<Team | TeamValues>,
		NullToUndefinedObj<TeamValues>
	>(
		(value) => (value === null ? undefined : value),
		R.omit(
			[
				'resources',
				'originals',
				'avatarUrl',
				'id',
				'shareable',
				'scenarioType',
				'externalId',
				'isPlanTeam',
			],
			team,
		),
	);

	return values;
};

export const filterDefinedValues = (teamValues: TeamValues): TeamValues =>
	R.reject(R.isNil, teamValues);

// overrides values with displayable values
// issue source id -> issue source name
export const applyDisplayValues = (
	attributeName: keyof TeamValues,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	attributeValue: any,
	issueSources: IssueSource[],
) => {
	if (attributeName === 'issueSource') {
		const issueSource = findIssueSourceById(issueSources, attributeValue);
		if (!issueSource) {
			return attributeValue;
		}
		return issueSource.title;
	}

	return attributeValue;
};

const getOriginals = (
	originalValues: Team,
	scenarioType: ScenarioType,
	valueChanges: TeamValues,
): TeamValues => {
	if (scenarioType === SCENARIO_TYPE.ADDED || scenarioType === SCENARIO_TYPE.ADDEDEXISTING) {
		return R.map(() => undefined, filterDefinedValues(valueChanges));
	}
	return nullToUndefined(originalValues);
};

const getScenarioTypeForTeam = (
	teamId: string,
	team: Team,
	teamsMeta: EntityMetadata,
): ScenarioType => {
	let scenarioType = getChangeScenarioType(teamId, teamsMeta) || SCENARIO_TYPE.NONE;
	if (scenarioType === SCENARIO_TYPE.DELETED) {
		scenarioType = team.shareable ? SCENARIO_TYPE.EXCLUDED : scenarioType;
	} else if (
		scenarioType === SCENARIO_TYPE.UPDATED &&
		Object.keys(team.originals).length === 0 &&
		team.shareable
	) {
		return SCENARIO_TYPE.ADDEDEXISTING;
	}
	return scenarioType;
};

export const getTeamChanges = (
	teamsMeta: EntityMetadata,
	teams: Team[],
	originalTeams: OriginalTeams,
	issueSources: IssueSource[],
	warnings: Warnings,
): {
	[key: string]: TeamAndResourceChange;
} => {
	const teamChangeKeys = Object.keys(teamsMeta);
	const changeDetails: {
		[key: string]: TeamAndResourceChange;
	} = {};

	teamChangeKeys.forEach((teamId) => {
		const team = findTeamById(teams, teamId);
		if (!team) {
			return;
		}

		const scenarioType = getScenarioTypeForTeam(teamId, team, teamsMeta);
		const originalTeamValues = originalTeams[teamId];
		if (!scenarioType || !originalTeamValues) {
			return;
		}

		const valueChanges = getDisplayableValueChanges(team);
		const originals = getDisplayableValueChanges(
			getOriginals(originalTeamValues, scenarioType, valueChanges),
		);

		const change: TeamAndResourceChange = {
			id: team.id,
			changeCount: 0,
			category: ENTITY.TEAM,
			type: scenarioType,
			attributeName: '',
			metaData: teamsMeta[team.id],
			warnings: warnings[teamId] || [],
			details: {
				changes: [],
				title: {
					name: team.title,
					avatarUrl: team.avatarUrl,
				},
			},
		};

		// if deleted,  no need to show any other changes
		if (scenarioType === SCENARIO_TYPE.DELETED || scenarioType === SCENARIO_TYPE.EXCLUDED) {
			changeDetails[teamId] = change;
			return changeDetails;
		}

		for (const [originalKey, originalValue] of Object.entries(originals)) {
			// Only show changes that are defined in the original or the value changes
			if (
				isDefined(originalValue) ||
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				isDefined(valueChanges[originalKey as keyof TeamValues])
			) {
				change.details.changes.push({
					category: ENTITY.TEAM,
					attributeName: originalKey,
					originalValue: applyDisplayValues(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						originalKey as keyof TeamValues,
						originalValue,
						issueSources,
					),
					currentValue: applyDisplayValues(
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						originalKey as keyof TeamValues,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						valueChanges[originalKey as keyof TeamValues],
						issueSources,
					),
					metaData: teamsMeta[team.id],
				});
			}
		}

		changeDetails[teamId] = change;
	});

	return changeDetails;
};

const isCreated = (change: TeamAndResourceChange) => change.type === SCENARIO_TYPE.ADDED;
const isAdded = (change: TeamAndResourceChange) => change.type === SCENARIO_TYPE.ADDEDEXISTING;
const isDeleted = (change: TeamAndResourceChange) => change.type === SCENARIO_TYPE.DELETED;

const getLatestMetaData = (change: TeamAndResourceChange) => {
	if (change.details.changes.length === 0) {
		return change.metaData;
	}

	const sortedChanges = R.sort(
		(first, second) =>
			(second.metaData.lastChangeTimestamp || 0) - (first.metaData.lastChangeTimestamp || 0),
		change.details.changes,
	);

	return sortedChanges[0].metaData;
};

// Gets the team change grouped by teamID and resource changes grouped by teamID and
// merges both of them together because in the UI we display resource changes also
// as an update to the team
export function mergeChanges(
	teamChangeByTeamId: {
		[key: string]: TeamAndResourceChange;
	},
	resourceChangesByTeamId: {
		[key: string]: TeamAndResourceChange[];
	},
): TeamAndResourceChange[] {
	const mergedTeamAndResourceChanges: {
		[key: string]: TeamAndResourceChange;
	} = teamChangeByTeamId;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Object.entries(resourceChangesByTeamId).forEach(([teamId, resourceChanges]: [any, any]) => {
		if (teamChangeByTeamId[teamId] && isDeleted(teamChangeByTeamId[teamId])) {
			// if a team is deleted, no need to show resource changes
			return;
		}

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const changesForResource = resourceChangesByTeamId[teamId].reduce<Array<any>>(
			(allChanges, current) => {
				allChanges.push(...current.details.changes);
				return allChanges;
			},
			[],
		);

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const warningsForResource = resourceChangesByTeamId[teamId].reduce<Array<any>>(
			(allWarnings, current) => {
				allWarnings.push(...current.warnings);
				return allWarnings;
			},
			[],
		);

		if (!mergedTeamAndResourceChanges[teamId]) {
			mergedTeamAndResourceChanges[teamId] = resourceChanges[0];
			mergedTeamAndResourceChanges[teamId].details.changes = changesForResource;
			mergedTeamAndResourceChanges[teamId].warnings = warningsForResource;
		} else {
			mergedTeamAndResourceChanges[teamId].details.changes.push(...changesForResource);
			mergedTeamAndResourceChanges[teamId].warnings.push(...warningsForResource);
		}
	});

	const tranformedMergedTeamAndResourceChanges: { [key: string]: TeamAndResourceChange } = {};
	Object.entries(mergedTeamAndResourceChanges).forEach(
		([teamId, change]: [string, TeamAndResourceChange]) => {
			const newChange = { ...change };

			const changeCount = newChange.details.changes.length;
			newChange.type =
				changeCount > 1 && !isCreated(newChange) && !isAdded(newChange)
					? SCENARIO_TYPE.MULTIPLE
					: newChange.type;
			newChange.attributeName = !isAdded(newChange)
				? newChange.details.changes[0]?.attributeName
				: undefined;
			newChange.changeCount = changeCount;
			// when the team and resources have different last updated meta data, we use the latest one
			newChange.metaData = getLatestMetaData(change);

			tranformedMergedTeamAndResourceChanges[teamId] = newChange;
		},
	);
	return Object.values(tranformedMergedTeamAndResourceChanges).reduce(
		(previous: TeamAndResourceChange[], current) => {
			previous.push(current);
			return previous;
		},
		[],
	);
}

export function expandChangesForTeam(change: ViewTeamAndResourceChange): Change[] {
	const { category, lastModified, ...rest } = change;
	return change.details.changes.map(
		(childChange): ViewTeamAndResourceChange => ({
			...rest,
			id: childChange.category === ENTITY.RESOURCE ? childChange.resourceId || '' : change.id,
			type: SCENARIO_TYPE.NONE,
			category: childChange.category,
			attributeName: childChange.attributeName,
		}),
	);
}

export const getTeamAndResourceChanges = createSelector(
	[
		getTeamChangesMetaData,
		getResourcesChangesMetaData,
		getTeams,
		getOriginalTeams,
		getCommitWarningsForTeams,
		getCommitWarningsForResources,
		getPersons,
		getIssueSources,
	],
	(
		teamChangesMeta,
		resourceChangesMeta,
		teams,
		originalTeams,
		commitWarnings,
		resourceCommitWarnings,
		persons,
		issueSources,
	) => {
		const teamChanges = teamChangesMeta
			? getTeamChanges(teamChangesMeta, teams, originalTeams, issueSources, commitWarnings)
			: {};

		const resourceChanges = resourceChangesMeta
			? getAllChangesForResource(teams, resourceChangesMeta, persons, resourceCommitWarnings)
			: {};

		return mergeChanges(teamChanges, resourceChanges);
	},
);
