import {
	applyChange,
	cancelOptimize,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/optimize/index.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	onApply: () => {
		dispatch(applyChange());
	},
	onCancel: () => {
		dispatch(cancelOptimize());
	},
});

export default mapDispatchToProps;
