import {
	clear,
	change,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters/index.tsx';
import {
	ISSUE_TYPE_KEY_FILTER_ID,
	type IssueTypeKeyFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';

import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	changeIssueTypeKeyFilter: (value: IssueTypeKeyFilterValue) => {
		dispatch(change({ id: ISSUE_TYPE_KEY_FILTER_ID, value }));
	},
	clearIssueTypeKeyFilter: () => {
		dispatch(clear(ISSUE_TYPE_KEY_FILTER_ID));
	},
});

export default mapDispatchToProps;
