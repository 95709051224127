const ignoredKeys: { [key: string]: boolean } = {
	Control: true,
	Meta: true,
	Shift: true,
	Alt: true,
	AltGraph: true,
	CapsLock: true,
	Fn: true,
	FnLock: true,
	Hyper: true,
	NumLock: true,
	OS: true,
	ScrollLock: true,
	Super: true,
	Symbol: true,
	SymbolLock: true,
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const isMacOS = () => Boolean(navigator && /^Mac/.test(navigator.platform));

const isEditable = (node: HTMLElement) =>
	node.nodeName === 'INPUT' || node.nodeName === 'TEXTAREA' || node.isContentEditable === true;

export const isRelevantKeyEvent = (e: KeyboardEvent) => {
	const { key, target } = e;
	return (
		target &&
		target instanceof HTMLElement &&
		typeof e.key === 'string' &&
		(e.key === 'Escape' || (!isEditable(target) && !ignoredKeys[key])) &&
		!((isMacOS() && e.metaKey) || (!isMacOS() && e.ctrlKey))
	);
};
