import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fg } from '@atlassian/jira-feature-gating';
import { sendViewRoadmapScreenAnalyticsEvent } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/analytics/index.tsx';
import { reportTimeToInteractive } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/time-to-interactive/index.tsx';
import type { ReportTimeToInteractivePayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/time-to-interactive/types.tsx';
import { updateRoadmapViewId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/views/index.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	onRoadmapView: (analyticsEvent: UIAnalyticsEvent) => {
		dispatch(sendViewRoadmapScreenAnalyticsEvent(analyticsEvent));
		if (fg('plans_timeline_-_always_persist_vid_in_url')) {
			dispatch(updateRoadmapViewId());
		}
	},
	reportTimeToInteractive: (payload: ReportTimeToInteractivePayload) =>
		dispatch(reportTimeToInteractive(payload)),
});

export default mapDispatchToProps;
