import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ReactRouterCompatibleHistory } from '@atlassian/jira-spa-router-adapters/src/common/types.tsx';

export const EDIT = 'state.domain.app.EDIT' as const;
export const REPORT = 'state.domain.app.REPORT' as const;
export const OPTIMIZING = 'state.domain.app.OPTIMIZING' as const;
export const OPTIMIZED = 'state.domain.app.OPTIMIZED' as const;
export const READONLY = 'state.domain.app.READONLY' as const;
export const EXPORT = 'state.domain.app.EXPORT' as const;

export type Mode =
	| typeof EDIT
	| typeof REPORT
	| typeof OPTIMIZING
	| typeof OPTIMIZED
	| typeof READONLY
	| typeof EXPORT;

export type App = Readonly<{
	mode: Mode;
	analyticsEvent: UIAnalyticsEvent;
	hasReportedInitialLoadAnalytics: boolean;
	isPlanInfoReady: boolean;
	isExportPreview: boolean;
	browserHistory?: ReactRouterCompatibleHistory;
}>;
