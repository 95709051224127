import React, { useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type { Props } from './types.tsx';
import { useCollapsibleWidths } from './utils/hooks.tsx';
import {
	CollapseProvider as CollapseProviderNew,
	CollapseProviderOld,
	useActions,
} from './utils/state.tsx';

/** Provides the ability to toggle the columns. */
export const Collapsible = ({
	columnIds,
	resizer,
	defaultColumnWidths,
	onColumnWidthsChange,
	children,
}: Props) => {
	const widths = useCollapsibleWidths({ preview: true });
	const CollapseProvider = fg('plans_performance_improvements')
		? CollapseProviderNew
		: CollapseProviderOld;

	return (
		<CollapseProvider
			singleton={false}
			widths={widths}
			defaultWidths={defaultColumnWidths}
			columnIds={columnIds}
			resizer={resizer}
			updateWidths={onColumnWidthsChange}
		>
			{children}
			<Observer />
		</CollapseProvider>
	);
};

/** Cache the pre-collapsed widths of the columns to expand after they are collapsed */
const Observer = () => {
	const widths = useCollapsibleWidths({ preview: false });
	const { cache } = useActions();

	useEffect(() => {
		cache(widths);
	}, [cache, widths]);

	return null;
};
