import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';

// This function is used to check whether the custom field is a scoped field or not
// use project ID as indicator, scoped field is attached to a specific project
export const checkIfCustomFieldIsScoped = (customField: CustomField): boolean =>
	customField.projectId !== undefined;

// This function is used to check whether an issue belong to a scoped custom field
// If it's not, we return true
export const shouldCustomFieldBeDisabledForScopedIssue = (
	customField: CustomField,
	issueTypeId: number,
	issueProjectId?: number,
): boolean => {
	// if the custom field has projectId attached that means the custom field is bound to
	// a specific project, so it's a scoped custom field (TMP)
	if (checkIfCustomFieldIsScoped(customField)) {
		// check both project id and issue types are matched
		// if they are not matched, so the issue does not belong to the project and we should disable it
		if (
			customField.projectId !== issueProjectId ||
			!(customField.issueTypeIds && customField.issueTypeIds.includes(issueTypeId))
		) {
			return true;
		}
	}
	return false;
};
