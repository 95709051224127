import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { Sprint } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/sprints/types.tsx';
import {
	SPRINT_FILTER_ID,
	type SprintFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { UNDEFINED_KEY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getSprints, getExternalSprints } from '../../sprints/common.tsx';
import { getFiltersViewSettings } from '../../view-settings/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SprintFilter } from '../../../state/domain/view-settings/filters/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { SPRINT_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

export const NO_SPRINT_ID = UNDEFINED_KEY;

export const applyFilter = (issue: Issue, { value }: SprintFilter): boolean => {
	if (value.length === 0) {
		return true;
	}

	const filterSprints = new Set(value);

	const issueSprints = [...(issue.completedSprints || []), issue.sprint].filter(isDefined);

	if (filterSprints.has(NO_SPRINT_ID) && issueSprints.length === 0) {
		return true;
	}

	filterSprints.delete(NO_SPRINT_ID);

	return issueSprints.some((id) => filterSprints.has(id));
};

export const getSprintFilterValue = (state: State): SprintFilter =>
	getFiltersViewSettings(state)[SPRINT_FILTER_ID] || {
		id: SPRINT_FILTER_ID,
		value: [],
	};

export const getSprintFilter = createSelector(
	[getSprints, getExternalSprints, getSprintFilterValue],
	(sprints: Sprint[], externalSprints: Sprint[], { value = [] }: SprintFilter): SprintFilter => {
		const sprintsInPlan = new Set([...sprints, ...externalSprints].map((x) => x.id));
		const existingSprints = value.filter(
			(sprintId) => sprintsInPlan.has(sprintId) || sprintId === NO_SPRINT_ID,
		);
		return {
			id: SPRINT_FILTER_ID,
			value: existingSprints,
		};
	},
);
