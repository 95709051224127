.issue-icon {
	width: var(--jpo-icon-size-small);
	height: var(--jpo-icon-size-small);
	margin-right: 5px;
	background-size: contain;
}

.breadCrumbItem {
	display: flex;
	margin: 0px;

	&::after {
		content: '/';
		display: flex;
		flex-shrink: 0;
		justify-content: center;
		margin-right: -4px;
		margin-top: 2px;
		padding: 0px var(--jpo-spacing);
		width: var(--jpo-spacing);
	}
}
