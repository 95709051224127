import type { IssueLink } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { IssueLinksState } from './types.tsx';

export const ADD = 'state.domain.issue-links.ADD' as const;
export const REMOVE = 'state.domain.issue-links.REMOVE' as const;
export const RESET = 'state.domain.issue-links.RESET' as const;

export type AddActionPayload = IssueLink;
export type RemoveActionPayload = IssueLink;
export type ResetActionPayload = IssueLinksState;

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: RemoveActionPayload;
};

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export const add = (payload: AddActionPayload): AddAction => ({
	type: ADD,
	payload,
});

export const remove = (payload: RemoveActionPayload): RemoveAction => ({
	type: REMOVE,
	payload,
});

export const reset = (payload: ResetActionPayload): ResetAction => ({
	type: RESET,
	payload,
});
