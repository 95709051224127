import type { Dispatch } from 'redux';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import {
	genericError,
	type GenericErrorActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/errors/index.tsx';
import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/index.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { DispatchProps, Action } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action>): DispatchProps => ({
	onChange: (
		issue: ScopeIssue,
		customFieldId: string,
		options: OptionType | OptionType[] | null | undefined,
	) => {
		const values = (() => {
			if (Array.isArray(options)) {
				return options.map((option) => option.value);
			}
			return [];
		})();
		dispatch(
			updateIssueOrInlineCreate({
				id: issue.id,
				customFields: {
					[customFieldId]: values,
				},
			}),
		);
	},
	genericError: (payload: GenericErrorActionPayload) => {
		dispatch(genericError(payload));
	},
});
