import type { State as AssociatedIssueState } from '@atlassian/jira-portfolio-3-associated-issues/src/controllers/types.tsx';

export const SET_ASSOCIATED_ISSUES =
	'state.domain.associated-issues.SET_ASSOCIATED_ISSUES' as const;

export type SetAssociatedIssuesAction = {
	type: typeof SET_ASSOCIATED_ISSUES;
	payload: AssociatedIssueState;
};

export const setAssociatedIssues = (payload: AssociatedIssueState): SetAssociatedIssuesAction => ({
	type: SET_ASSOCIATED_ISSUES,
	payload,
});
