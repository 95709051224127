import { getIssuePriorities } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-priorities/index.tsx';
import {
	getIssuePriorityIdsByProjectId,
	getDefaultIssuePriorityByProjectId,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, StateProps>({
	prioritiesByIdMap: getIssuePriorities,
	// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'ownProps' implicitly has an 'any' type.
	priorityIdsForProject: (state, ownProps) =>
		getIssuePriorityIdsByProjectId(state)[ownProps.issue.project] || [],
	// @ts-expect-error - TS7006 - Parameter 'state' implicitly has an 'any' type. | TS7006 - Parameter 'ownProps' implicitly has an 'any' type.
	defaultIssuePriority: (state, ownProps) =>
		getDefaultIssuePriorityByProjectId(state)[ownProps.issue.project],
});
