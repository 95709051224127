import React, { forwardRef, useState, useEffect, useCallback, type Ref } from 'react';
import DropdownMenu, { DropdownItem } from '@atlaskit/dropdown-menu';
import ArrowLeftIcon from '@atlaskit/icon/core/arrow-left';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import CloseIcon from '@atlaskit/icon/core/close';
import DragHandleIcon from '@atlaskit/icon/utility/drag-handle';
import { Pressable, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const ColumnDragHandle = forwardRef(
	(
		{ columnId, isVisible, isHidden, columnIds, moveColumn, hideColumn }: Props,
		ref: Ref<HTMLButtonElement>,
	) => {
		const { formatMessage } = useIntl();

		const [isOpen, setIsOpen] = useState(false);
		const [isTriggerHovered, setIsTriggerHovered] = useState(false);

		const showHandle = (isVisible || isOpen) && !isHidden;
		const showExpanded = isTriggerHovered || isOpen;

		const columnIndex = columnIds.indexOf(columnId);

		const handleHide = useCallback(() => {
			hideColumn(columnId);
		}, [columnId, hideColumn]);

		const handleMove = useCallback(
			(direction: 'left' | 'right') => {
				const targetIndex = direction === 'left' ? columnIndex - 1 : columnIndex + 1;
				moveColumn(columnId, columnIds[targetIndex]);
			},
			[columnId, columnIds, columnIndex, moveColumn],
		);

		const handleMoveLeft = useCallback(() => handleMove('left'), [handleMove]);
		const handleMoveRight = useCallback(() => handleMove('right'), [handleMove]);

		useEffect(() => {
			if (isHidden) setIsOpen(false);
		}, [isHidden]);

		return (
			<DropdownMenu
				trigger={({ triggerRef, ...props }) => (
					<HoverObserver onHoverChanged={setIsTriggerHovered}>
						<Pressable
							ref={mergeRefs(triggerRef, ref)}
							xcss={[triggerStyles, showHandle && visibleStyles, showExpanded && expandedStyles]}
							aria-label={formatMessage(messages.dragHandleLabel)}
							// eslint-disable-next-line jsx-a11y/aria-props
							aria-description={formatMessage(messages.dragHandleDescription)}
							testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.utils.draggable-column.drag-handle"
							{...props}
						>
							<Box xcss={[iconStyles, showExpanded && visibleStyles]}>
								<DragHandleIcon color={token('color.icon.inverse')} label="" />
							</Box>
						</Pressable>
					</HoverObserver>
				)}
				isOpen={isOpen}
				onOpenChange={(attrs) => setIsOpen(attrs.isOpen)}
			>
				<DropdownItem
					elemBefore={
						<ArrowLeftIcon
							color={columnIndex === 0 ? token('color.icon.disabled') : token('color.icon')}
							label=""
						/>
					}
					onClick={handleMoveLeft}
					isDisabled={columnIndex === 0}
				>
					{formatMessage(messages.moveFieldLeft)}
				</DropdownItem>
				<DropdownItem
					elemBefore={
						<ArrowRightIcon
							color={
								columnIndex === columnIds.length - 1
									? token('color.icon.disabled')
									: token('color.icon')
							}
							label=""
						/>
					}
					onClick={handleMoveRight}
					isDisabled={columnIndex === columnIds.length - 1}
				>
					{formatMessage(messages.moveFieldRight)}
				</DropdownItem>
				<DropdownItem
					elemBefore={<CloseIcon color={token('color.icon')} label="" />}
					onClick={handleHide}
				>
					{formatMessage(messages.hideField)}
				</DropdownItem>
			</DropdownMenu>
		);
	},
);

const triggerStyles = xcss({
	background: token('color.background.accent.gray.subtler'),
	height: '5px',
	width: '12px',
	border: `2px solid ${token('color.border.inverse')}`,
	borderRadius: '5px',
	visibility: 'hidden',
	boxSizing: 'content-box',
	position: 'absolute',
	top: '-5px',
	left: '50%',
	transform: 'translateX(-50%)',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	zIndex: 'card',
	cursor: 'grab',
});

const expandedStyles = xcss({
	background: token('color.background.accent.blue.subtle'),
	height: '14px',
	top: '-10px',
});

const iconStyles = xcss({
	display: 'flex',
	visibility: 'hidden',
	transform: 'rotate(90deg)',
});

const visibleStyles = xcss({
	visibility: 'visible',
});
