import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getHiddenIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';

const getDialogVisibilityState = (state: State) => state.ui.Top.TitleBar.HiddenIssues.isDialogOpen;

const mapStateToProps = createSelector(
	[getDialogVisibilityState, getProjects, getHiddenIssues],
	(isDialogOpen, projects, hiddenIssues) => ({
		isDialogOpen,
		projects,
		hiddenIssues,
	}),
);

export default mapStateToProps;
