import type { Sequence } from './types.tsx';

export const INCREMENT = 'state.domain.sequence.INCREMENT' as const;
export const RESET = 'state.domain.sequence.RESET' as const;
export const UPDATE = 'state.domain.sequence.UPDATE' as const;

export type IncrementActionPayload = string[];
export type ResetActionPayload = Sequence;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UpdateActionPayload = any;

export type IncrementAction = {
	type: typeof INCREMENT;
	payload: IncrementActionPayload;
};

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};

export const increment = (payload: IncrementActionPayload) => ({
	type: INCREMENT,
	payload,
});

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const update = (payload: UpdateActionPayload) => ({
	type: UPDATE,
	payload,
});
