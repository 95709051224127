import { combineReducers } from '../../common/redux/index.tsx';
import { RESET_REDUX_STORE, RESTORE_REDUX_STORE } from './actions.tsx';
import domain from './domain/reducer.tsx';
import ui from './ui/reducer.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appReducer = combineReducers<any, any>({
	domain,
	ui,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: any) => {
	if (action.type === RESTORE_REDUX_STORE) {
		/**
		 * When switching between different pages within the same plan,
		 * the state needs to be rehydrated from the cache to prevent
		 * unnecessary API calls to initial load the same plan again.
		 */
		return appReducer(action.payload, action);
	}

	if (action.type === RESET_REDUX_STORE) {
		/**
		 * In order to reset the redux store to the initial state, we are passing "undefined"
		 * value as state for the root reducer. This value will then be passed to all the child
		 * reducers. As this value is "undefined", all the child reducers will return their default
		 * state. Assumption is all the child reducers are using default initial state.
		 */
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

export default rootReducer;
