import React, {
	createContext,
	useRef,
	useState,
	useEffect,
	useContext,
	type ReactNode,
} from 'react';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { DependenciesDialogContext } from '../fields/columns/cells/dependencies/context.tsx';
import DialogContent from './dialog-content/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { ContextType, RectConvertible, FlyoutPayload } from './types.tsx';

const FLYOUT_MAX_WIDTH = 800;

const context = createContext<ContextType>({
	payload: null,
	triggerRect: null,
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	open: () => {},
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	close: () => {},
});

export const DependenciesFlyoutProvider = ({ children }: { children: ReactNode }) => {
	const [flyoutProps, setFlyoutProps] = useState<FlyoutPayload | null | undefined>(null);
	const [triggerRect, setTriggerRect] = useState<RectConvertible | null | undefined>(null);

	const open: ContextType['open'] = (payload, triggerEl) => {
		setFlyoutProps(payload);
		setTriggerRect(triggerEl.getBoundingClientRect());
	};
	const close: ContextType['close'] = () => setFlyoutProps(null);

	return (
		<context.Provider value={{ payload: flyoutProps, triggerRect, open, close }}>
			{children}
		</context.Provider>
	);
};

export const useDependenciesFlyout = () => useContext(context);

function useDialogLock() {
	const flagsRef = useRef<Set<string>>(new Set());

	/** Resets the lock by removing all of the flags. */
	const reset = () => {
		flagsRef.current = new Set();
	};
	/** Check if the lock is activated by checking if there is any flag available. */
	const isActivated = () => flagsRef.current.size > 0;

	/** Toggle a flag inside the lock  */
	const toggle = (lock: string) => (on: boolean) => {
		if (on) {
			flagsRef.current.add(lock);
		} else {
			flagsRef.current.delete(lock);
		}
	};

	return {
		reset,
		isActivated,
		toggle,
	};
}

type Rect = {
	top: number;
	left: number;
	width: number;
	height: number;
};

export const DependenciesFlyoutOverlay = () => {
	const ref = useRef<HTMLDivElement>(null);
	const dialogLock = useDialogLock();
	const [anchorRect, setAnchorRect] = useState<Rect | null>(null);
	const { payload, close, triggerRect } = useDependenciesFlyout();

	useEffect(() => {
		const containerEl = ref.current;

		if (containerEl == null) {
			return;
		}

		if (triggerRect == null) {
			return;
		}

		const containerRect = containerEl.getBoundingClientRect();
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const anchorRect = {
			top: triggerRect.top - containerRect.top,
			left: triggerRect.left - containerRect.left,
			width: triggerRect.width,
			height: triggerRect.height,
		};

		setAnchorRect(anchorRect);
	}, [payload, triggerRect]);

	useEffect(() => {
		dialogLock.reset();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payload]);

	function handleRequestClose() {
		if (dialogLock.isActivated()) {
			return;
		}

		close();
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div ref={ref} className={styles.overlay}>
			<DependenciesDialogContext.Provider
				value={{
					isDialogLocked: true,
					toggleDialogLocked: dialogLock.toggle,
				}}
			>
				{payload != null && (
					<InlineDialog
						content={<DialogContent {...payload} />}
						isOpen="true"
						// go/jfe-eslint
						// eslint-disable-next-line react/jsx-no-bind
						onClose={handleRequestClose}
						maxWidth={FLYOUT_MAX_WIDTH}
						placement="bottom-end"
						closeOnScroll
					>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.anchor} style={{ ...anchorRect }} />
					</InlineDialog>
				)}
			</DependenciesDialogContext.Provider>
		</div>
	);
};
