import React from 'react';
import * as R from 'ramda';
import {
	indexBy,
	isDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import NotInPlan from '../not-in-plan/index.tsx';
import type { Props } from './types.tsx';

export default function CrossProjectRelease(props: Props) {
	const { crossProjectVersionId, crossProjectVersions, removedCrossProjectVersions } = props;
	const crossProjectVersionsByIdMap = indexBy(R.prop('id'), [
		...crossProjectVersions,
		...removedCrossProjectVersions,
	]);
	const crossProjectVersion = crossProjectVersionsByIdMap[crossProjectVersionId];

	return isDefined(crossProjectVersion) ? (
		<EllipsedWithTooltip content={crossProjectVersion.name} id={crossProjectVersionId}>
			<span>{crossProjectVersion.name}</span>
		</EllipsedWithTooltip>
	) : (
		<span>
			<NotInPlan />
		</span>
	);
}
