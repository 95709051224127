/* eslint-disable jira/react/no-style-attribute */
import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useContainer, useZoomLevel } from '../../controllers/index.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line @typescript-eslint/no-shadow
const HorizontalScrollingContainer = ({ xcss, offset, children }: Props) => {
	const [zoomLevel] = useZoomLevel();
	const [{ width }] = useContainer();

	return (
		<Box
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
			xcss={[xcss, containerStyles]}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={zoomLevel ? { marginLeft: offset, width } : undefined}
		>
			{children}
		</Box>
	);
};

const containerStyles = xcss({
	position: 'relative',
});

export default HorizontalScrollingContainer;
