import { type MessageDescriptor, defineMessages } from '@atlassian/jira-intl';

export default defineMessages<
	| 'includeTransitive'
	| 'offtrackTrigger'
	| 'specificIssueTrigger'
	| 'onTrigger'
	| 'offTrigger'
	| 'issueSelectorPlaceholder'
	| 'offtrackDependencies'
	| 'specificIssue'
	| 'hasDependencies'
	| 'allIssues'
	| 'showIssues',
	MessageDescriptor
>({
	showIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.show-issues',
		defaultMessage: 'Show issues',
		description:
			'The title for the section in the dropdown for showing or hiding issues with dependencies',
	},
	allIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.all-issues',
		defaultMessage: 'All issues',
		description: 'Text for option to show all issues',
	},
	hasDependencies: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.has-dependencies',
		defaultMessage: 'Has dependencies',
		description: 'Text for option to filter for only issues with dependencies',
	},
	specificIssue: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.specific-issue',
		defaultMessage: 'Specific issue',
		description: 'Text for option to filter for only issues linked with specific issue',
	},
	offtrackDependencies: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.offtrack-dependencies',
		defaultMessage: 'Overlapping dates',
		description: 'Text for option to filter for only issues with overlapping dates',
	},
	issueSelectorPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.issue-selector-placeholder',
		defaultMessage: 'Choose issue...',
		description: 'Placeholder for issue selector',
	},
	offTrigger: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.off-trigger',
		defaultMessage: 'All issues',
		description: 'Text for filter trigger when all issues is selected',
	},
	onTrigger: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.on-trigger',
		defaultMessage: 'Issues with dependencies',
		description: 'Text for filter trigger when has dependencies is selected',
	},
	specificIssueTrigger: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.specific-issue-trigger',
		defaultMessage: 'Dependencies of {issueKey}',
		description: 'Text for filter trigger when has dependencies is selected',
	},
	offtrackTrigger: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.offtrack-trigger',
		defaultMessage: 'Issues with overlapping dates',
		description: 'Text for filter trigger when has overlapping dates is selected',
	},
	includeTransitive: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.dependencies-filter.include-transitive',
		defaultMessage: 'Include dependency chain',
		description:
			'A label for the checkbox to include transitive dependencies in specific issue dependencies',
	},
});
