import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/index.tsx';
import {
	getIssuePrioritiesIdList,
	getIssuePriorities,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-priorities/index.tsx';
import { ISSUE_PRIORITIES_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';

export default createSelector(
	[getIssuePriorities, getFilters, getIssuePrioritiesIdList, getIssuePriorities],
	(
		issuePriorities,
		{ [ISSUE_PRIORITIES_FILTER_ID]: { id, value } },
		issuePrioritiesIdList,
		prioritiesById,
	) => ({
		id,
		value,
		issuePriorities: isDefined(issuePrioritiesIdList)
			? // eslint-disable-next-line @typescript-eslint/no-shadow
				issuePrioritiesIdList.map((id) => prioritiesById[id])
			: Object.values(issuePriorities),
	}),
);
