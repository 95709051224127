.sprintLabels {
	height: 40px;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
}

.message {
	color: var(--ds-text-subtle, var(--adg3-color-subtleText-dark));
	padding: 8px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.bar {
	height: 20px;
	min-width: 5px;
	margin: 0 5px;
	border-radius: 3px;
	display: flex;
	align-items: flex-end;
	background-color: var(--ds-surface, var(--adg3-color-N0));

	& > div {
		width: 100%;
		border-radius: 5px;
		&.okay {
			background-color: var(--ds-background-success-bold, var(--adg3-color-G300));
		}
		&.overbooked {
			background-color: var(--ds-background-danger-bold, var(--adg3-color-R300));
		}
	}
}

.sprintBox {
	align-items: center;
	border-radius: 3px;
	box-sizing: border-box;
	cursor: pointer;
	display: flex;
	height: 30px;
	margin: 5px 2px;

	/* future sprints */
	background-color: var(--ds-background-neutral-hovered, var(--adg3-color-N40));
	color: var(--ds-text-subtle, var(--adg3-color-N400));

	&:hover {
		background-color: var(--ds-background-neutral-pressed, var(--adg3-color-N60));
	}

	&.overbooked {
		background-color: var(--ds-background-danger, var(--adg3-color-R50));
		color: var(--ds-text-danger, var(--adg3-color-R500));

		&:hover {
			background-color: var(--ds-background-danger-hovered, var(--adg3-color-R75));
		}
	}

	&.inconsistent {
		background-color: var(--ds-background-warning, var(--adg3-color-Y75));
		color: var(--ds-text-warning, var(--jpo-warning-color));

		&:hover {
			background-color: var(--ds-background-warning-hovered, var(--adg3-color-Y100));
		}
	}

	&.activeSprint {
		background-color: var(--ds-background-selected, var(--adg3-color-B50));
		color: var(--ds-text-selected, var(--adg3-color-B500));

		&:hover {
			background-color: var(--ds-background-selected-hovered, var(--adg3-color-B75));
		}
	}

	&.pastSprint,
	&.closedSprint {
		background-color: var(--ds-background-neutral, var(--adg3-color-N20));
		color: var(--ds-text-disabled, var(--adg3-color-N60));

		&:hover {
			background-color: var(--ds-background-neutral-hovered, var(--adg3-color-N30));
		}
	}
}

.sprintName {
	flex: 1 1 auto;
	font-size: 12px;
	font-weight: 700;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
