import mapKeys from 'lodash/mapKeys';
import {
	type Issue as ApiIssue,
	type IssueValues,
	TARGET_START_FIELD,
	TARGET_END_FIELD,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';

import { NOTIFY_WATCHERS_KEY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { userPreferenceAPI } from '@atlassian/jira-user-preferences-services/src/utils/index.tsx';

type MappedValues<T extends Partial<IssueValues>> = {
	[K in keyof T as K extends 'baselineStart'
		? typeof TARGET_START_FIELD
		: K extends 'baselineEnd'
			? typeof TARGET_END_FIELD
			: K]: T[K];
};

export const mapTargetDates = (issue: ApiIssue): ApiIssue => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const associateTargetDates = (_: any, key: string): string => {
		if (key === 'baselineStart') {
			return TARGET_START_FIELD;
		}
		if (key === 'baselineEnd') {
			return TARGET_END_FIELD;
		}
		return key;
	};

	return {
		...issue,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		values: mapKeys(issue.values, associateTargetDates) as MappedValues<IssueValues>,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		originals: mapKeys(issue.originals, associateTargetDates) as MappedValues<Partial<IssueValues>>,
	};
};

export const setNotifyWatchersPreference = (preference: boolean): Promise<void> =>
	userPreferenceAPI.set(NOTIFY_WATCHERS_KEY, preference);

export const getNotifyWatchersPreference = async (): Promise<boolean | undefined> => {
	const preference = await userPreferenceAPI.get(NOTIFY_WATCHERS_KEY);
	// This value should be a boolean. If it isn't, we'll set a default in our reducer.
	return typeof preference === 'boolean' ? preference : undefined;
};
