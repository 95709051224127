export const LOAD = 'state.domain.update-jira.change-status.LOAD' as const;

export const CANCEL = 'state.domain.update-jira.change-status.CANCEL' as const;

export const SUCCESS = 'state.domain.update-jira.change-status.SUCCESS' as const;

export const FAIL = 'state.domain.update-jira.change-status.FAIL' as const;

type LoadActionPayload = {
	issueId: string;
	issueKeyInJira: string;
	transitionId: string;
};

export type LoadAction = {
	type: typeof LOAD;
	payload: LoadActionPayload;
};

export type CancelAction = {
	type: typeof CANCEL;
};

export type SuccessAction = {
	type: typeof SUCCESS;
};

export type FailAction = {
	type: typeof FAIL;
};

export type Action = LoadAction | CancelAction | SuccessAction | FailAction;

export const load = (payload: LoadActionPayload) => ({
	type: LOAD,
	payload,
});

export const cancel = () => ({
	type: CANCEL,
});

export const success = () => ({
	type: SUCCESS,
});

export const fail = () => ({
	type: FAIL,
});
