import { getJquery, getJira, getAjs } from './globals.tsx';
import type { IssueCreatedResult, Function, IssueFormParams } from './types.tsx';

const createCreateIssueForm = ({ model, title, prefilledFields = {} }: IssueFormParams) => {
	const $ = getJquery();
	const JIRA = getJira();
	const CustomFormContainer = JIRA.Forms.Container.extend({
		lazyInit() {
			const options = this.options();

			options.prefilledValues.fieldsToRetain = Object.keys(options.prefilledValues);
			this.prefilledValues = $.param(options.prefilledValues, true);

			this.options = () => options;

			this._super();
		},

		render() {
			const deferred = $.Deferred();

			this.lazyInit();

			this.model
				.getUseConfigurableForm()
				.done((isConfigurable: boolean) => {
					if (isConfigurable) {
						// @ts-expect-error - TS7006 - Parameter 'el' implicitly has an 'any' type. | TS7006 - Parameter 'scripts' implicitly has an 'any' type.
						this.configurableForm.render(this.prefilledValues).done((_, scripts) => {
							deferred.resolveWith(this, [this.$element]);
							this.$element.append(scripts);
						});
					} else {
						this.unconfigurableForm.render
							.render(this.prefilledValues)
							// @ts-expect-error - TS7006 - Parameter 'el' implicitly has an 'any' type. | TS7006 - Parameter 'scripts' implicitly has an 'any' type.
							.done((_, scripts) => {
								deferred.resolveWith(this, [this.$element, scripts]);
								this.$element.append(scripts);
							});
					}
				})
				.fail(() => {
					deferred.resolveWith(this, [this.$element]);
				});

			return deferred.promise();
		},
	});

	return new CustomFormContainer(() => {
		const globalEventNamespaces = ['QuickCreateEpic'];

		const configurableForm = new JIRA.Forms.ConfigurableCreateIssueForm({
			model,
			title,
			globalEventNamespaces,
		});

		const unconfigurableForm = new JIRA.Forms.UnconfigurableCreateIssueForm({
			model,
			title,
			globalEventNamespaces,
		});

		return {
			globalEventNamespaces,
			model,
			configurableForm,
			unconfigurableForm,
			errorHandler: new JIRA.Forms.CreateIssueError(),
			prefilledValues: prefilledFields,
		};
	});
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const bind = (element: any, eventKey: string, func: Function) => {
	if (element) {
		getAjs().$(element).bind(eventKey, func);
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unbind = (element: any, eventKey: string, func?: Function) => {
	const AJS = getAjs();
	if (element && eventKey) {
		if (func) {
			AJS.$(element).unbind(eventKey, func);
		} else {
			AJS.$(element).unbind(eventKey);
		}
	}
};

const showDialog = ({
	model,
	prefilledFields,
	title,
	hideConfigureFields,
	hideCancelAllLink,
	deferred,
	onRenderCallback,
	onContentRefreshedCallback, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => {
	const JIRA = getJira();
	const $ = getJquery();
	const form = createCreateIssueForm({ model, title, prefilledFields });
	const dialog = form.asDialog({ id: 'jpo-dialogs-jira-create-issue' });
	const cancelAllLink = !hideCancelAllLink
		? $('<a href="javascript:void(0);" class="jpo-cancel-all">Cancel All</a>')
		: undefined;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onIssueCreated = (_: any, data: any) => {
		const issueData = data.createdIssueDetails;

		const result: IssueCreatedResult = {
			id: issueData.id,
			key: issueData.key,
			title: issueData.fields.summary,
			issuetypeId: issueData.fields.issuetype.id,
		};

		deferred.resolve(result);
	};

	const onContentRefreshed = () => {
		const quickForm = dialog.getQuickForm();
		const quickFormElement = quickForm.$element;

		onContentRefreshedCallback && onContentRefreshedCallback(quickFormElement);
	};

	const onValidationError = () => {
		const quickForm = dialog.getQuickForm();
		const quickFormElement = quickForm.$element;

		onContentRefreshedCallback && onContentRefreshedCallback(quickFormElement);
	};

	const onRender = () => {
		const quickForm = dialog.getQuickForm();
		const quickFormElement = quickForm.$element;

		$('label.qf-create-another', quickFormElement).remove();

		if (hideConfigureFields) {
			$('.qf-form-operations', quickFormElement).remove();
		}

		if (cancelAllLink !== undefined) {
			$('.form-footer .buttons', quickFormElement).append(cancelAllLink);

			cancelAllLink.on('click', () => {
				const undim = true;
				dialog.hide(undim, { reason: 'cancel-all' });
				dialog.destroy();
			});
		}

		onRenderCallback && onRenderCallback(dialog.$popup);

		bind(form.configurableForm, 'issueCreated', onIssueCreated);
		bind(form.unconfigurableForm, 'issueCreated', onIssueCreated);

		bind(form.configurableForm, 'validationError', onValidationError);
		bind(form.unconfigurableForm, 'validationError', onValidationError);

		unbind(form, 'configurableFormRendered', onRender);
		unbind(form, 'unconfigurableFormRendered', onRender);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onDialogHide = (event: any, dialogObj: any, reason: string) => {
		if (event && event.isDefaultPrevented()) {
			return;
		}
		let reasonValue = reason;

		if (reasonValue === 'esc') {
			reasonValue = 'cancel';
		}

		unbind(form.configurableForm, 'issueCreated', onIssueCreated);
		unbind(form.unconfigurableForm, 'issueCreated', onIssueCreated);

		unbind(form.configurableForm, 'validationError', onValidationError);
		unbind(form.unconfigurableForm, 'validationError', onValidationError);

		unbind(form, 'configurableFormRendered', onRender);
		unbind(form, 'unconfigurableFormRendered', onRender);

		unbind(form, 'contentRefreshed', onContentRefreshed);
		unbind(dialogObj, 'Dialog.hide', onDialogHide);
		unbind(document, 'QuickCreateIssueInEpic.validationError', onValidationError);

		if (JIRA.EpicPicker) {
			unbind(document, JIRA.EpicPicker.READY_EVENT);
		}

		if (cancelAllLink) {
			cancelAllLink.off('click');
		}

		if (reasonValue === 'cancel' || reasonValue === 'cancel-all') {
			deferred.reject({ reason: reasonValue });
		} else {
			deferred.reject({ type: 'cancel-operation-unknown' });
		}
	};

	bind(form, 'configurableFormRendered', onRender);
	bind(form, 'unconfigurableFormRendered', onRender);

	bind(form, 'contentRefreshed', onContentRefreshed);
	bind(dialog, 'Dialog.hide', onDialogHide);

	dialog.show();
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default { showDialog };
