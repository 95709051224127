// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	home: 'https://support.atlassian.com/jira-software-cloud/resources/',
	followOurProgress: 'https://jira.atlassian.com/browse/JSWCLOUD-20012',
	gettingStarted:
		'https://support.atlassian.com/jira-software-cloud/docs/get-started-with-advanced-roadmaps/',
	customFields:
		'https://support.atlassian.com/jira-software-cloud/docs/enable-custom-fields-used-in-advanced-roadmaps-in-jira/',
	schedulingIssues:
		'https://support.atlassian.com/jira-software-cloud/docs/estimate-and-schedule-issues-in-advanced-roadmaps/',
	schedulingWork:
		'https://support.atlassian.com/jira-software-cloud/docs/estimate-and-schedule-issues-in-advanced-roadmaps/',
	embedRoadmapInConfluenceMacro:
		'https://support.atlassian.com/jira-software-cloud/docs/embed-your-advanced-roadmap-plan-in-a-confluence-page/',
	viewSwitching:
		'https://support.atlassian.com/jira-software-cloud/docs/preconfigured-views-in-advanced-roadmaps/',
	supportLink: 'https://support.atlassian.com/contact/#/',
	exportPng:
		'https://support.atlassian.com/jira-software-cloud/docs/create-a-static-image-of-your-plan/',
	committingChangesToJira:
		'https://support.atlassian.com/jira-software-cloud/docs/save-changes-made-in-advanced-roadmaps/',
	manageTeamInJira:
		'https://support.atlassian.com/atlassian-account/docs/what-is-an-atlassian-team/',
	learnMoreAboutPermissions:
		'https://support.atlassian.com/jira-software-cloud/docs/how-do-jira-permissions-work/#Permissionsoverview-board-usagesprint-related-actionsBoardusage',
	teamsInOpenSites:
		'https://support.atlassian.com/jira-software-cloud/docs/limitations-of-teams-in-advanced-roadmaps-open-sites/',
	dependencyReport:
		'https://support.atlassian.com/jira-software-cloud/docs/what-are-dependencies-in-advanced-roadmaps/',
	warningsDialog:
		'https://support.atlassian.com/jira-software-cloud/docs/view-the-warnings-center-in-advanced-roadmaps/',
};
