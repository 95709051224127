import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.project-limit-warning-flag.title',
		defaultMessage: 'Your plan includes too many projects',
		description: 'The title for project-limit warning flag',
	},
	description: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.project-limit-warning-flag.description',
		defaultMessage:
			'The number of projects in your plan exceeds the limit set by your administrator. Select fewer issue sources to continue.',
		description: 'This is a description of project-limit warning flag',
	},
	updateEditIssueSources: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.project-limit-warning-flag.update-edit-issue-sources',
		defaultMessage: 'Configure issue sources',
		description: 'Label for "Configure issues sources" button',
	},
});

export default messages;
