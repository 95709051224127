import { getCrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/index.tsx';
import { getSelectedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import {
	getVersions,
	getVersionsById,
	getSortByHighestRank,
	getSortByLowestRank,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, StateProps>({
	selectedIssues: getSelectedIssues,
	versions: getVersions,
	versionsById: getVersionsById,
	crossProjectVersionsById: getCrossProjectVersionsById,
	sortVersionsByHighestRank: getSortByHighestRank,
	sortVersionsByLowestRank: getSortByLowestRank,
});
