import type { Effect } from 'redux-saga';
import { select } from 'redux-saga/effects';
import { getCrossProjectVersions } from '../../query/cross-project-versions/index.tsx';
import { getVersions } from '../../query/versions/index.tsx';
import messages from './messages.tsx';

export const defaultWarning = {
	header: messages.defaultWarningHeader,
	message: messages.defaultWarningContent,
	messageValues: {},
} as const;

export function getVersionErrorWarning(
	{
		error,
		errorMessages,
	}: {
		error: string;
		errorMessages: string[];
	},
	crossProjectVersionTitle?: string | null,
	versionTitle?: string | null,
) {
	switch (error) {
		case 'ENVIRONMENT_ENTITY_NOT_FOUND': {
			return {
				header: messages.releaseNotFoundHeader,
				message: messages.releaseNotFoundContent,
				messageValues: { versionTitle },
			};
		}
		case 'ENVIRONMENT_RELATED_ENTITY_NOT_FOUND': {
			return {
				header: messages.projectNotFoundHeader,
				message: messages.projectNotFoundContent,
				messageValues: {},
			};
		}
		case 'UNCOMMITTED_FOREIGN_KEY': {
			return {
				header: messages.crossProjectReleaseNotCommittedHeader,
				message: messages.crossProjectReleaseNotCommittedContent,
				messageValues: { crossProjectVersionTitle },
			};
		}
		case 'VERSION_API_EXCEPTION': {
			if (errorMessages.length > 0) {
				return {
					header: messages.apiExceptionHeader,
					message: messages.apiExceptionContent,
					messageValues: { messages: errorMessages.join(' ') },
				};
			}
			return {
				header: messages.defaultWarningHeader,
				message: messages.defaultWarningContent,
				messageValues: {},
			};
		}
		default:
			return defaultWarning;
	}
}

export function* inspectForCommitWarnings(
	error:
		| {
				error: string;
				errorMessages: string[];
		  }
		| null
		| undefined,
	versionId: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	if (error) {
		const versions: ReturnType<typeof getVersions> = yield select(getVersions);
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const version = versions.find((version) => version.id === versionId);
		let crossProjectVersionTitle: string | undefined;
		let versionTitle: string | undefined;

		if (version) {
			versionTitle = version.name;

			const crossProjectVersionId = version.crossProjectVersion;
			const crossProjectVersions: ReturnType<typeof getCrossProjectVersions> =
				yield select(getCrossProjectVersions);
			const crossProjectVersion = crossProjectVersions.find(
				// eslint-disable-next-line @typescript-eslint/no-shadow
				(crossProjectVersion) => crossProjectVersion.id === crossProjectVersionId,
			);

			if (crossProjectVersion) {
				crossProjectVersionTitle = crossProjectVersion.name;
			}
		}

		return [getVersionErrorWarning(error, crossProjectVersionTitle, versionTitle)];
	}

	return [];
}
