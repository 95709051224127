import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.header',
		defaultMessage:
			'{optimizedFieldsCount, plural, one {# value} other {# values}} will be updated:',
		description: 'Header message at the top of the auto-scheduled changes flyout',
	},
	targetDates: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.target-dates',
		defaultMessage: '{dateFieldTitle}',
		description: 'Label for the Target date field',
	},
	team: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.team',
		defaultMessage: 'Team',
		description: 'Label for the Team field',
	},
	releases: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.releases',
		defaultMessage: '{releaseCount, plural, one {Release} other {Releases}}',
		description: 'Label for the Releases field. Can be plural.',
	},
	unknownTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.unknown-team',
		defaultMessage: 'Unknown team {id}',
		description: 'Shown for teams we do not have information about',
	},
	unknownSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.unknown-sprint',
		defaultMessage: 'Unknown sprint {id}',
		description: 'Shown for sprints we do not have information about',
	},
	unknownVersion: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.unknown-version',
		defaultMessage: 'Unknown version {id}',
		description: 'Shown for verions we do not have information about',
	},
	before: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.before',
		defaultMessage: 'Before',
		description: 'Label for column header for values before optimization',
	},
	after: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.after',
		defaultMessage: 'After',
		description: 'Label for column header for values after optimization',
	},
	none: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.flyout.none',
		defaultMessage: 'None',
		description: 'Label shown in cell with no value',
	},
});
