import React, { Component } from 'react';
import Heading from '@atlaskit/heading';
import { Radio } from '@atlaskit/radio';
import HelpPanelLink from '@atlassian/jira-help-panel-button/src/index.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	AUTO_SCHEDULE_OVERWRITE,
	ISSUE_VALUES,
	IPH_IDS,
	type AutoScheduleOverwriteOptions,
	type IssueValues,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import DocLink from '@atlassian/jira-portfolio-3-portfolio/src/common/view/doc-link/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const OVERWRITE_OPTIONS = [
	{
		value: AUTO_SCHEDULE_OVERWRITE.ALL_VALUES,
		label: <FormattedMessage {...messages.overwriteAllValues} />,
	},
	{
		value: AUTO_SCHEDULE_OVERWRITE.EMPTY_VALUES_ONLY,
		label: <FormattedMessage {...messages.overwriteEmptyValuesOnly} />,
	},
];

const OVERWRITE_ISSUE_VALUES = [
	{
		value: ISSUE_VALUES.SPRINT,
		label: <FormattedMessage {...commonMessages.sprints} />,
	},
	{
		value: ISSUE_VALUES.RELEASE,
		label: <FormattedMessage {...commonMessages.releases} />,
	},
	{
		value: ISSUE_VALUES.TEAM,
		label: <FormattedMessage {...commonMessages.teams} />,
	},
];

const API_KEYS_FOR_OVERWRITE_ISSUE_VALUES = {
	[ISSUE_VALUES.SPRINT]: 'ignoreSprints',
	[ISSUE_VALUES.RELEASE]: 'ignoreReleases',
	[ISSUE_VALUES.TEAM]: 'ignoreTeams',
} as const;

// eslint-disable-next-line jira/react/no-class-components
export default class ConfigurationDialog extends Component<Props> {
	isOptionSelected = (issueValue: IssueValues, overwriteOption: AutoScheduleOverwriteOptions) => {
		const { autoScheduleConfiguration } = this.props;
		const apiKey = API_KEYS_FOR_OVERWRITE_ISSUE_VALUES[issueValue];

		return (
			isDefined(autoScheduleConfiguration) &&
			((autoScheduleConfiguration[apiKey] === true &&
				overwriteOption === AUTO_SCHEDULE_OVERWRITE.ALL_VALUES) ||
				(autoScheduleConfiguration[apiKey] === false &&
					overwriteOption === AUTO_SCHEDULE_OVERWRITE.EMPTY_VALUES_ONLY))
		);
	};

	onConfigurationChange = (
		issueValue: IssueValues,
		overwriteOption: AutoScheduleOverwriteOptions,
	): void => {
		this.props.updateAutoScheduleConfiguration({
			[API_KEYS_FOR_OVERWRITE_ISSUE_VALUES[issueValue]]:
				overwriteOption === AUTO_SCHEDULE_OVERWRITE.ALL_VALUES,
		});
	};

	render() {
		const { onPreviewResults, selectedIssuesCount } = this.props;

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.container}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.topDescription}>
					<>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.descriptionParagraph}>
							<FormattedMessage {...messages.selectiveSchedulingDescriptionParagraphOne} />
						</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.descriptionParagraph}>
							<FormattedMessage {...messages.selectiveSchedulingDescriptionParagraphTwo} />
						</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.descriptionParagraph}>
							<HelpPanelLink
								articleId={IPH_IDS.AUTO_SCHEDULE}
								fallbackComponent={
									<DocLink href={DocLink.URLs.schedulingWork}>
										<FormattedMessage {...messages.iphLink} />
									</DocLink>
								}
								appearance="link"
								spacing="none"
							>
								<FormattedMessage {...messages.iphLink} />
							</HelpPanelLink>
						</div>
						{selectedIssuesCount > 0 && (
							// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
							<div className={styles.selectedIssueCount}>
								<FormattedMessage
									{...messages.selectiveSchedulingSelectedIssues}
									values={{ count: selectedIssuesCount }}
								/>
							</div>
						)}
					</>
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.configurationSection}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.overwriteTitle}>
						<Heading size="xsmall" as="h2" id="overwrite-issue-values-title">
							<FormattedMessage {...messages.overwriteTitle} />
						</Heading>
					</div>
					<div
						id="overwrite-issue-values-desc"
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={`${styles.textSizeH200} ${styles.overwriteDescription}`}
					>
						<FormattedMessage {...messages.overwriteDescription} />
					</div>
					<div
						role="grid"
						aria-labelledby="overwrite-issue-values-title"
						aria-describedby="overwrite-issue-values-desc"
						aria-busy="true"
					>
						<div role="rowgroup">
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div role="row" className={styles.configurationTableHeader}>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<div className={styles.optionsHeader} />
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<div className={styles.issueValuesHeader}>
									{OVERWRITE_ISSUE_VALUES.map(({ label, value }, colIndex) => (
										<div
											id={`column-${colIndex}`}
											role="columnheader"
											key={value}
											// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
											className={styles.textSizeH200}
										>
											{label}
										</div>
									))}
								</div>
							</div>
						</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div role="rowgroup" className={styles.configurationTableContent}>
							{OVERWRITE_OPTIONS.map(({ label, value: optionValue }, rowIndex) => (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<div role="row" key={optionValue} className={styles.configurationOptionRow}>
									<div
										id={`row-${rowIndex}`}
										role="rowheader"
										// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
										className={`${styles.optionsContent} ${styles.textSizeH200}`}
									>
										{label}
									</div>
									{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
									<div className={styles.issueValueSelection}>
										{OVERWRITE_ISSUE_VALUES.map(({ value: issueValue }, colIndex) => (
											<div role="gridcell" key={issueValue}>
												<Radio
													isChecked={this.isOptionSelected(issueValue, optionValue)}
													aria-labelledby={`column-${colIndex} row-${rowIndex}`}
													name={issueValue}
													value={optionValue}
													onChange={() => this.onConfigurationChange(issueValue, optionValue)}
												/>
											</div>
										))}
									</div>
								</div>
							))}
						</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.actionFooter}>
							<Button
								testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.preview-results"
								appearance="primary"
								onClick={onPreviewResults}
							>
								<FormattedMessage {...messages.previewResults} />
							</Button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
