import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
	DialogMenuGroup,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { COMPONENT_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { Component as ApiComponent } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { chain } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import FilterText from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '../common/styles.module.css';
import TitleWithAvatar from '../common/title-with-avatar/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class ComponentFilter extends Component<Props> {
	constructFilterText = () => {
		const { value, projects } = this.props;
		return chain(({ components }) => components || [], projects)
			.filter(({ id }) => value.includes(id))
			.map(({ name }) => name)
			.join(', ');
	};

	renderFilterText = () => {
		const { value } = this.props;
		const filterText = this.constructFilterText();
		return value.length > 0 ? (
			<FilterText text={filterText} />
		) : (
			<FormattedMessage {...messages.emptyPlaceholder} />
		);
	};

	ariaText = () => {
		const { value, intl } = this.props;
		const filterText = this.constructFilterText();
		// Components, All
		return `${intl.formatMessage(filterMessages[COMPONENT_FILTER_ID])}, ${
			value.length > 0 ? filterText : intl.formatMessage(messages.emptyPlaceholder)
		} ${intl.formatMessage(filterMessages.selected)}`;
	};

	isComponentSelected = (component: number) => this.props.value.includes(component);

	onComponentClick = (clickedComponent: number): void => {
		const { value, changeFilter } = this.props;
		if (this.isComponentSelected(clickedComponent)) {
			changeFilter(value.filter((component) => component !== clickedComponent));
		} else {
			changeFilter([...value, clickedComponent]);
		}
	};

	renderComponents = (components: ApiComponent[]) =>
		components.map(({ id, name }) => (
			<DialogMenuItem key={id}>
				<Checkbox
					id={`${id}`}
					key={id}
					isChecked={this.isComponentSelected(id)}
					onChange={() => this.onComponentClick(id)}
					label={
						<EllipsedWithTooltip content={name} id={id}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<span className={styles.checkboxLabel}>{name}</span>
						</EllipsedWithTooltip>
					}
				/>
			</DialogMenuItem>
		));

	renderProjectGroups = () => {
		const filteredProjects = this.getFilteredProjects();
		return (
			<div>
				{filteredProjects.map(({ id, name, key, components, avatarUrl }) => {
					const projectTitle = (
						<TitleWithAvatar avatarUrl={avatarUrl} id={id} projectKey={key} name={name} />
					);
					return (
						<DialogMenuGroup heading={projectTitle} key={id}>
							{this.renderComponents(components)}
						</DialogMenuGroup>
					);
				})}
				{filteredProjects.length === 0 && <NoMatchFound />}
			</div>
		);
	};

	getFilteredProjects = () => {
		const { projects } = this.props;
		const searchQuery = this.props.searchQuery.toLowerCase();
		const filteredProjects = [];
		for (const project of projects) {
			const { components = [] } = project;
			const filteredComponents = components.filter(({ name }) =>
				name.toLowerCase().includes(searchQuery),
			);
			if (filteredComponents.length > 0) {
				filteredProjects.push({ ...project, components: filteredComponents });
			}
		}

		return filteredProjects;
	};

	render() {
		const { intl, onOpenChange, isOpen, value, clearFilter, searchQuery, onQueryChange } =
			this.props;
		return (
			<InlineDialog
				noPaddings
				maxWidth={FILTER_MAX_WIDTH}
				minWidth={FILTER_WIDTH}
				onClose={onOpenChange}
				isOpen={isOpen}
				content={
					<DialogMenuContainer>
						<ClearFilterButton isVisible={!!value.length} onClearClick={clearFilter} />
						<SearchField
							placeholder={intl.formatMessage(messages.searchComponentsPlaceholder)}
							searchQuery={searchQuery}
							onQueryChange={onQueryChange}
							ariaLabel={intl.formatMessage(messages.searchComponentsLabel)}
						/>
						{this.renderProjectGroups()}
					</DialogMenuContainer>
				}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.component-filter"
			>
				<TriggerButton
					isOpen={isOpen}
					onOpenChange={onOpenChange}
					testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.component-filter.trigger-btn"
					triggerButtonText={this.renderFilterText()}
					ariaLabel={this.ariaText()}
				/>
			</InlineDialog>
		);
	}
}

export default injectIntl(ComponentFilter);
