import groupBy from 'lodash/groupBy';
import * as R from 'ramda';
import { chain, indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { IssueType } from '../../state/domain/issue-types/types.tsx';
import type { Project } from '../../state/domain/projects/types.tsx';
import type { State } from '../../state/types.tsx';
import {
	getProjects,
	getCompanyManagedProjects,
	getTeamManagedProjects,
} from '../projects/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { IssueType } from '../../state/domain/issue-types/types';

export type IssueTypesById = {
	[key: string]: IssueType;
};

export const getIssueTypes = (state: State) => state.domain.issueTypes;

export const getIssueTypesById = createSelector([getIssueTypes], (issueTypes) =>
	indexBy(({ id }) => id, issueTypes),
);

export const getIssueTypesByName = createSelector<
	State,
	IssueType[],
	{ [key: string]: IssueType[] }
>([getIssueTypes], (issueTypes) => groupBy(issueTypes, ({ name }) => name));

export const getIssueTypesByProjectId = createSelector(
	[getProjects, getIssueTypesById],
	(projects, issueTypes): Record<string, IssueType[]> =>
		Object.fromEntries(
			projects.map((project) => [project.id, project.issueTypeIds.map((id) => issueTypes[id])]),
		),
);

export const getIssueTypeIds = createSelector([getIssueTypes], (issueTypes) =>
	R.map(({ id }) => id, issueTypes),
);

export const getIssueTypeIdsOfCompanyManagedProjectsPure = (projects: Project[]) => [
	...new Set(chain((x) => x.issueTypeIds, projects)),
];

export const getIssueTypeIdsOfCompanyManagedProjects = createSelector(
	[getCompanyManagedProjects],
	getIssueTypeIdsOfCompanyManagedProjectsPure,
);

export const getTeamManagedProjectByIssueTypeIdPure = (projects: Project[]) =>
	projects.reduce<Record<string, Project>>((acc, project) => {
		project.issueTypeIds.forEach((issueTypeId) => {
			acc[issueTypeId] = project;
		});
		return acc;
	}, {});

export const getTeamManagedProjectByIssueTypeId = createSelector(
	[getTeamManagedProjects],
	getTeamManagedProjectByIssueTypeIdPure,
);

export const getIssueTypesByIdForCompanyManagedProjects = createSelector(
	[getIssueTypes, getIssueTypeIdsOfCompanyManagedProjects],
	(issueTypes: IssueType[], issueTypeIdsOfCompanyManagedProjects: number[]) => {
		const issueTypesForCompanyManagedProjects = issueTypes.filter((issueType: IssueType) =>
			issueTypeIdsOfCompanyManagedProjects.includes(issueType.id),
		);
		return indexBy(({ id }) => id, issueTypesForCompanyManagedProjects);
	},
);

export const getIssueTypeIconByKeyPure = (issueTypes: IssueType[]): Record<string, string> =>
	issueTypes.reduce<Record<string, string>>((acc, { level, name, iconUrl }) => {
		const currentIconUrl = acc[`${level} ${name}`];
		if (currentIconUrl === undefined || currentIconUrl.localeCompare(iconUrl) === 1) {
			acc[`${level} ${name}`] = iconUrl;
		}
		return acc;
	}, {});

export const getIssueTypeIconByKey = createSelector<State, IssueType[], Record<string, string>>(
	[getIssueTypes],
	getIssueTypeIconByKeyPure,
);

export const getIssueTypeIconByNamePure = (issueTypes: IssueType[]): Record<string, string> => {
	const issueTypeLevelAndIconByName = issueTypes.reduce<
		Record<string, { level: number; iconUrl: string }>
	>((acc, { level, name, iconUrl }) => {
		const currentLevelAndIconUrl = acc[name];
		if (
			currentLevelAndIconUrl === undefined ||
			(level >= currentLevelAndIconUrl.level &&
				currentLevelAndIconUrl.iconUrl.localeCompare(iconUrl) === 1)
		) {
			acc[name] = { level, iconUrl };
		}
		return acc;
	}, {});

	return Object.entries(issueTypeLevelAndIconByName).reduce<Record<string, string>>(
		(acc, [name, { iconUrl }]) => {
			acc[name] = iconUrl;
			return acc;
		},
		{},
	);
};

/**
 * Same as getIssueTypeIconByKey, but does not require the level. Instead, it will pick the highest level that exists.
 */
export const getIssueTypeIconByName = createSelector<State, IssueType[], Record<string, string>>(
	[getIssueTypes],
	getIssueTypeIconByNamePure,
);
