import { getMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getIssueTypesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getIssueMapById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import { getWarningViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import {
	getAggregatedWarnings,
	getDisabledWarnings,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/warnings/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, {}, StateProps>({
	disabledWarnings: getDisabledWarnings,
	issueTypesById: getIssueTypesById,
	issuesById: getIssueMapById,
	mode: getMode,
	projectsById: getProjectsById,
	showWarning: (state: State) => getWarningViewSettings(state).showWarning,
	warnings: getAggregatedWarnings,
});
