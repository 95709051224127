export const TOGGLE_SCENARIOS_DIALOG_VISIBILITY =
	'state.ui.settings.scenarios.TOGGLE_SCENARIOS_DIALOG_VISIBILITY' as const;

export const INITIATE_REQUEST = 'state.ui.settings.scenarios.INITIATE_REQUEST' as const;

export const COMPLETE_REQUEST = 'state.ui.settings.scenarios.COMPLETE_REQUEST' as const;

export type ToggleDialogVisibilityAction = {
	type: typeof TOGGLE_SCENARIOS_DIALOG_VISIBILITY;
	payload: boolean;
};

export type InitiateRequestAction = {
	type: typeof INITIATE_REQUEST;
};

export type CompleteRequestAction = {
	type: typeof COMPLETE_REQUEST;
};

export const toggleDialogVisibility = (visibility: boolean) => ({
	type: TOGGLE_SCENARIOS_DIALOG_VISIBILITY,
	payload: visibility,
});

export const initiateRequest = () => ({
	type: INITIATE_REQUEST,
});

export const completeRequest = () => ({
	type: COMPLETE_REQUEST,
});
