import { getComponentsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/components/index.tsx';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getComponentGroupsViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Props, StateProps } from './types.tsx';

export default createStructuredSelector<State, Props, StateProps>({
	componentGroups: getComponentGroupsViewSettings,
	componentsById: getComponentsById,
	projectsById: getProjectsById,
});
