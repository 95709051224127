// Copied from /Users/jlu4/atlassian/atlassian-frontend-monorepo/jira/src/packages/admin-pages/custom-fields-directory/custom-fields-main/src/ui/cells/type/utils/constants.tsx
// Only a subset of field types are supported in Plans
// Keeping the whole list here so we don't have to update this when we add support more field types
const TypeKeyMap = {
	TEAM_CF_TYPE: 'com.atlassian.teams:rm-teams-custom-field-team',
	ATLASSIAN_TEAM_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:atlassian-team',
	ORGANIZATIONS_CF_TYPE: 'com.atlassian.servicedesk:sd-customer-organizations',
	MULTI_USER_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:multiuserpicker',
	SELECT_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:select',
	DATETIME_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:datetime',
	DATE_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:datepicker',
	STORY_POINT_ESTIMATE_CF_TYPE: 'com.pyxis.greenhopper.jira:jsw-story-points',
	PARENT_CF_TYPE: 'com.atlassian.jpo:jpo-custom-field-parent',
	BASELINE_START_CF_TYPE: 'com.atlassian.jpo:jpo-custom-field-baseline-start',
	BASELINE_END_CF_TYPE: 'com.atlassian.jpo:jpo-custom-field-baseline-end',
	SPRINT_CF_GLOBAL_TYPE: 'com.pyxis.greenhopper.jira:gh-sprint',
	MULTI_CHECKBOXES_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:multicheckboxes',
	NUMBER_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:float',
	USER_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:userpicker',
	URL_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:url',
	TEXT_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:textfield',
	TEXT_AREA_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:textarea',
	MULTI_VERSION_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:multiversion',
	RADIO_BUTTONS_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:radiobuttons',
	LABELS_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:labels',
	MULTI_GROUP_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:multigrouppicker',
	VERSION_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:version',
	GROUP_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:grouppicker',
	ASSETS_CF_TYPE: 'com.atlassian.servicedesk.assets-plugin:assetfield',
	SERVICE_ENTITY_CF_TYPE: 'com.atlassian.jira.plugins.service-entity:service-entity-field-cftype',
	REQUEST_PARTICIPANTS_CF_TYPE: 'com.atlassian.servicedesk:sd-request-participants',
	REQUEST_TYPE_CF_TYPE: 'com.atlassian.servicedesk:vp-origin',
	EPIC_NAME_TYPE: 'com.pyxis.greenhopper.jira:gh-epic-label',
	EPIC_STATUS_TYPE: 'com.pyxis.greenhopper.jira:gh-epic-status',
	EPIC_COLOR_TYPE: 'com.pyxis.greenhopper.jira:gh-epic-color',
	COLOR_CF_TYPE: 'com.pyxis.greenhopper.jira:jsw-issue-color',
	EPIC_TYPE: 'com.pyxis.greenhopper.jira:gh-epic-link',
	RANK_CF_TYPE: 'com.pyxis.greenhopper.jira:gh-lexo-rank',
	REQUEST_FEEDBACK_CF_TYPE: 'com.atlassian.servicedesk:sd-request-feedback',
	REQUEST_FEEDBACK_DATE_CF_TYPE: 'com.atlassian.servicedesk:sd-request-feedback-date',
	APPROVALS_CF_TYPE: 'com.atlassian.servicedesk.approvals-plugin:sd-approvals',

	REQUEST_LANGUAGE_CF_TYPE:
		'com.atlassian.servicedesk.servicedesk-lingo-integration-plugin:sd-request-language',

	MULTI_SELECT_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:multiselect',

	DEV_SUMMARY_CF_TYPE:
		'com.atlassian.jira.plugins.jira-development-integration-plugin:devsummarycf',

	FIRST_RESPONSE_DATE_CF_TYPE: 'com.atlassian.jira.ext.charting:firstresponsedate',
	TIME_IN_STATUS_CF_TYPE: 'com.atlassian.jira.ext.charting:timeinstatus',
	UUID_CF_TYPE: 'com.atlassian.jconnect.jconnect-plugin:uuid',
	GOOGLE_MAP_CF_TYPE: 'com.atlassian.jconnect.jconnect-plugin:location',
	SLA_CF_TYPE: 'com.atlassian.servicedesk:sd-sla-field',
	CASCADING_SELECT_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:cascadingselect',
	LAST_UPDATER_CF_TYPE: 'com.atlassian.jira.toolkit:lastupdaterorcommenter',
	USER_PROPERTY_CF_TYPE: 'com.atlassian.jira.toolkit:userproperty',
	TEXT_READONLY_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:readonlyfield',
	PROJECT_PICKER_CF_TYPE: 'com.atlassian.jira.plugin.system.customfieldtypes:project',
	PARTICIPANTS_CF_TYPE: 'com.atlassian.jira.toolkit:participants',
	COMMENTS_NUMBER_CF_TYPE: 'com.atlassian.jira.toolkit:comments',
	ATTACHMENTS_NUMBER_CF_TYPE: 'com.atlassian.jira.toolkit:attachments',
	MESSAGE_CF_TYPE: 'com.atlassian.jira.toolkit:viewmessage',
	MESSAGE_EDIT_CF_TYPE: 'com.atlassian.jira.toolkit:message',
	LAST_COMMENT_DATE_CF_TYPE: 'com.atlassian.jira.toolkit:LastCommentDate',
	LAST_COMMENT_UF_CF_TYPE: 'com.atlassian.jira.toolkit:lastusercommented',
	REPORTER_DOMAIN_CF_TYPE: 'com.atlassian.jira.toolkit:reporterdomain',
	ASSIGNEE_DOMAIN_CF_TYPE: 'com.atlassian.jira.toolkit:assigneedomain',
	LAST_COMMENT_DAYS_CF_TYPE: 'com.atlassian.jira.toolkit:dayslastcommented',

	RESPONDERS_CF_TYPE:
		'com.atlassian.jira.modules.servicemanagement.responders-entity:responders-entity-field-cftype',

	ENTITLEMENT_CF_TYPE: 'com.atlassian.servicedesk.customer-context:sd-csm-entitlement',

	WORK_CATEGORY_CF_TYPE:
		'com.atlassian.jira.plugins.work-category-field:work-category-field-cftype',

	// MARKETPLACE CONNECT ADDON
	MARKETPLACE_CF_TYPE: 'com.atlassian.plugins.atlassian-connect-plugin',

	CMDB_OBJECT_CF_TYPE: 'com.atlassian.jira.plugins.cmdb:cmdb-object-cftype',

	// FORGE APPS
	FORGE_CF_TYPE: 'ari:cloud:ecosystem::extension',

	// Proforma forms count fields
	FORMS_TOTAL_TYPE: 'com.atlassian.jira.plugins.proforma-managed-fields:forms-total-field-cftype',

	FORMS_SUBMITTED_TYPE:
		'com.atlassian.jira.plugins.proforma-managed-fields:forms-submitted-field-cftype',

	FORMS_OPEN_TYPE: 'com.atlassian.jira.plugins.proforma-managed-fields:forms-open-field-cftype',

	FORMS_LOCKED_TYPE: 'com.atlassian.jira.plugins.proforma-managed-fields:forms-locked-field-cftype',

	// JPD TYPES
	FORMULA_CF_TYPE: 'jira.polaris:formula',
	INTERVAL_CF_TYPE: 'jira.polaris:interval',
	REACTIONS_CF_TYPE: 'jira.polaris:reactions',
	SLIDER_CF_TYPE: 'jira.polaris:slider',
	RATING_CF_TYPE: 'jira.polaris:rating',
	BOOLEAN_CF_TYPE: 'jira.polaris:boolean',
};

export const CustomFieldTypeNameMap = {
	[TypeKeyMap.DEV_SUMMARY_CF_TYPE]: 'Dev Summary Custom Field',
	[TypeKeyMap.TEAM_CF_TYPE]: 'Team',
	[TypeKeyMap.ATLASSIAN_TEAM_CF_TYPE]: 'Team Picker (single team)',
	[TypeKeyMap.ORGANIZATIONS_CF_TYPE]: 'Organizations',
	[TypeKeyMap.MULTI_USER_CF_TYPE]: 'User Picker (multiple users)',
	[TypeKeyMap.SELECT_CF_TYPE]: 'Select List (single choice)',
	[TypeKeyMap.DATETIME_CF_TYPE]: 'Date Time Picker',
	[TypeKeyMap.REQUEST_TYPE_CF_TYPE]: 'Customer Request Type Custom Field',
	[TypeKeyMap.EPIC_NAME_TYPE]: 'Name of Epic',
	[TypeKeyMap.EPIC_STATUS_TYPE]: 'Status of Epic',
	[TypeKeyMap.EPIC_COLOR_TYPE]: 'Color of Epic',
	[TypeKeyMap.EPIC_TYPE]: 'Epic Link Relationship',
	[TypeKeyMap.DATE_CF_TYPE]: 'Date Picker',
	[TypeKeyMap.STORY_POINT_ESTIMATE_CF_TYPE]: 'Story point estimate value',
	[TypeKeyMap.COLOR_CF_TYPE]: 'Issue color',
	[TypeKeyMap.PARENT_CF_TYPE]: 'Parent Link',
	[TypeKeyMap.BASELINE_START_CF_TYPE]: 'Target start',
	[TypeKeyMap.BASELINE_END_CF_TYPE]: 'Target end',
	[TypeKeyMap.FIRST_RESPONSE_DATE_CF_TYPE]: 'Date of First Response',
	[TypeKeyMap.TIME_IN_STATUS_CF_TYPE]: 'Time in Status',
	[TypeKeyMap.SPRINT_CF_GLOBAL_TYPE]: 'Jira Sprint Field',
	[TypeKeyMap.RANK_CF_TYPE]: 'Global Rank',
	[TypeKeyMap.MULTI_CHECKBOXES_CF_TYPE]: 'Checkboxes',
	[TypeKeyMap.REQUEST_PARTICIPANTS_CF_TYPE]: 'Request Participants',
	[TypeKeyMap.UUID_CF_TYPE]: 'UUID Field',
	[TypeKeyMap.GOOGLE_MAP_CF_TYPE]: 'Custom Google Map Field',
	[TypeKeyMap.NUMBER_CF_TYPE]: 'Number Field',
	[TypeKeyMap.REQUEST_FEEDBACK_CF_TYPE]: 'Satisfaction',
	[TypeKeyMap.REQUEST_FEEDBACK_DATE_CF_TYPE]: 'Satisfaction date',
	[TypeKeyMap.APPROVALS_CF_TYPE]: 'Approvals',
	[TypeKeyMap.SLA_CF_TYPE]: 'SLA CustomField Type',
	[TypeKeyMap.REQUEST_LANGUAGE_CF_TYPE]: 'Request language',
	[TypeKeyMap.USER_CF_TYPE]: 'User Picker (single user)',
	[TypeKeyMap.URL_CF_TYPE]: 'Url Field',
	[TypeKeyMap.TEXT_CF_TYPE]: 'Text Field (single line)',
	[TypeKeyMap.TEXT_AREA_CF_TYPE]: 'Text Field (multi-line)',
	[TypeKeyMap.MULTI_SELECT_CF_TYPE]: 'Select List (multiple choices)',
	[TypeKeyMap.CASCADING_SELECT_CF_TYPE]: 'Select List (cascading)',
	[TypeKeyMap.RADIO_BUTTONS_CF_TYPE]: 'Radio Buttons',
	[TypeKeyMap.LABELS_CF_TYPE]: 'Labels',
	[TypeKeyMap.VERSION_CF_TYPE]: 'Version Picker (single version)',
	[TypeKeyMap.MULTI_VERSION_CF_TYPE]: 'Version Picker (multiple version)',
	[TypeKeyMap.LAST_UPDATER_CF_TYPE]: 'Username of last updater or commenter',
	[TypeKeyMap.USER_PROPERTY_CF_TYPE]: 'User Property Field (< 255 characters)',
	[TypeKeyMap.TEXT_READONLY_CF_TYPE]: 'Text Field (read only)',
	[TypeKeyMap.PROJECT_PICKER_CF_TYPE]: 'Project Picker (single project)',
	[TypeKeyMap.PARTICIPANTS_CF_TYPE]: 'Participants of an issue',
	[TypeKeyMap.COMMENTS_NUMBER_CF_TYPE]: 'Number of comments',
	[TypeKeyMap.ATTACHMENTS_NUMBER_CF_TYPE]: 'Number of attachments',
	[TypeKeyMap.MESSAGE_CF_TYPE]: 'Message Custom Field (for view)',
	[TypeKeyMap.MESSAGE_EDIT_CF_TYPE]: 'Message Custom Field (for edit)',
	[TypeKeyMap.LAST_COMMENT_DATE_CF_TYPE]: 'Last public comment date',
	[TypeKeyMap.LAST_COMMENT_UF_CF_TYPE]: 'Last commented by a User Flag',
	[TypeKeyMap.GROUP_CF_TYPE]: 'Group Picker (single group)',
	[TypeKeyMap.MULTI_GROUP_CF_TYPE]: 'Group Picker (multiple groups)',
	[TypeKeyMap.REPORTER_DOMAIN_CF_TYPE]: 'Domain of Reporter',
	[TypeKeyMap.ASSIGNEE_DOMAIN_CF_TYPE]: 'Domain of Assignee',
	[TypeKeyMap.LAST_COMMENT_DAYS_CF_TYPE]: 'Days since last comment',
	[TypeKeyMap.ASSETS_CF_TYPE]: 'External asset platform',
	[TypeKeyMap.CMDB_OBJECT_CF_TYPE]: 'Assets objects',
	[TypeKeyMap.RESPONDERS_CF_TYPE]: 'Responders',
	[TypeKeyMap.FORGE_CF_TYPE]: 'Forge',
	[TypeKeyMap.WORK_CATEGORY_CF_TYPE]: 'Work Category',
	[TypeKeyMap.FORMS_TOTAL_TYPE]: 'Total forms',
	[TypeKeyMap.FORMS_SUBMITTED_TYPE]: 'Submitted forms',
	[TypeKeyMap.FORMS_LOCKED_TYPE]: 'Locked forms',
	[TypeKeyMap.FORMS_OPEN_TYPE]: 'Open forms',
	[TypeKeyMap.ENTITLEMENT_CF_TYPE]: 'Entitlement',
	[TypeKeyMap.FORMULA_CF_TYPE]: 'Custom Formula (Jira Product Discovery)',
	[TypeKeyMap.INTERVAL_CF_TYPE]: 'Date Inverval (Jira Product Discovery)',
	[TypeKeyMap.REACTIONS_CF_TYPE]: 'Reactions (Jira Product Discovery)',
	[TypeKeyMap.RATING_CF_TYPE]: 'Rating (Jira Product Discovery)',
	[TypeKeyMap.SLIDER_CF_TYPE]: 'Slider (Jira Product Discovery)',
	[TypeKeyMap.BOOLEAN_CF_TYPE]: 'Checkbox (Jira Product Discovery)',
	default: 'Unknown',
} as const;
