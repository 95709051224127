import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapStateToProps, { mapStateToPropsOld } from './query.tsx';
import type { StateProps, OwnProps } from './types.tsx';
import View from './view.tsx';

const ReleaseOld = connect<StateProps, {}, OwnProps, State>(mapStateToPropsOld, {})(View);

const Release = connect<StateProps, {}, OwnProps, State>(mapStateToProps, {})(View);

export default componentWithFG('review_changes_error_message_display', Release, ReleaseOld);
