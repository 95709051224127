.container {
	height: 40px;
	padding: 12px 0 12px 20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
}

.containerOld {
	height: 40px;
	padding: 12px 0 12px 20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	background-color: var(--ds-surface, var(--adg3-color-N0));
}

.rank {
	color: var(--ds-text-subtlest, var(--adg3-color-subtleText-light));
	padding: 0;
}

.icon {
	width: var(--jpo-icon-size-medium);
	min-width: var(--jpo-icon-size-medium);
	height: var(--jpo-icon-size-medium);
	padding: 0;
	cursor: pointer;
}

.summary {
	/* flex items will refuse to shrink below their minimum intrinsic width, unless you explicitly specify "min-width" or "width" or "max-width" on them */
	/* we need to set both min-width and width to enable shrinking and to still have growth */
	min-width: 0;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.toggleButton {
	cursor: pointer;
	outline: none;
}
