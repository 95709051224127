import { startOfUtcDay } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import {
	createStore,
	createHook,
	createSelector,
	defaultRegistry,
	type BoundActions,
	type Action,
	type HookFunction,
} from '@atlassian/react-sweet-state';
import { ESTIMATED_MAX_COLUMN_WIDTH, SCROLL_BUTTON_STEP } from '../common/constants/index.tsx';
import type { Duration, ZoomLevel, Timestamp } from '../common/types.tsx';
import { getContainerDuration, getContainerWidth } from '../common/utils/index.tsx';

/** Used to determine the relative position of the viewport to the screen */
type Position = {
	top: number;
	left: number;
};

/**
 * Viewport clips the rendering to show what user can see visually.
 * Container is used as the coordisystem (using today as the origin) which we render
 * all elements onto, the elements are not neccessary be strictly rendered inside
 * the container.
 *
 * For example, if the issue bar starts from last year, it would be rendered with
 * X1 offset to the left (X1 would be a negative number) in the container.
 *
 * When the user tries to scroll to the left to see last year, the viewport offset
 * would be set to a navigative number (for example X2).
 *
 * Then, depends on how far the scroll position is (X2), the issue bar which is
 * located at X1 may or may not visisble on the viewport.
 *
 * ```
 * <-------- viewport offset (ms) ------->
 * -----------------------------------    today
 * |                                 |    --------------------------------
 * |                                 |    |      |      |      |      |  |
 * |    last year                    |    |      |      |      |      |  |
 * |    =====                        |    |        (4.5 columns)      |  |
 * |       \                         |    |      |      |      |      |  |
 * |        issue bar                |    |      |      |      |      |  |
 * |                                 |    |      |      |      |      |  |
 * |                                 |    --------------------------------
 * -----------------------------------    <-- container width/duration -->
 * <----- viewport width (px) ------->
 * ```
 */
type Viewport = {
	/** The viewport offset since today in milliseconds. */
	offset: Duration;
	/** The viewport width in px. */
	width: number;
	/** The position to the left and top of the screen in px. */
	position: Position;
};

/** The container which is used as the origin for the elements positions as well as the base for % width of the element. */
type Container = {
	/** The width in pixels of the rendering window. */
	width: number;
	/** The total time in milliseconds that is shown within the rendering window. */
	duration: Duration;
};

/** The callback to be executed when a scroll event is triggered */
type ScrollEventHandler = (arg0: {
	offsetInPx: number;
	smooth: boolean;
	source: ScrollingTrigger;
}) => void;

type ScrollingTrigger =
	| 'scrollbar'
	| 'mousewheel'
	| 'arrow'
	| 'todayButton'
	| 'scrollButton'
	| 'dragndrop'
	| 'zoomLevelChange'
	| 'keyboardArrows'
	| 'releaseNavButton'
	| 'external';

export type TimelineRuler = {
	pxToMs: (arg1: number) => Duration;
	msToPx: (arg1: Duration) => number;
};

export type State = {
	zoomLevel: ZoomLevel | undefined;
	viewport: Viewport;
	container: Container;
	smooth: boolean;
	isScrollingWithScrollBar: boolean;
	today: Timestamp;
	lastScrollTrigger: ScrollingTrigger | null;
	scrollEventHandlers: ScrollEventHandler[];
};

const initialState: State = {
	zoomLevel: undefined,
	viewport: {
		offset: 0,
		width: 0,
		position: {
			left: 0,
			top: 0,
		},
	},
	container: {
		duration: 0,
		width: 0,
	},
	smooth: false,
	isScrollingWithScrollBar: false,
	today: startOfUtcDay(Date.now()),
	lastScrollTrigger: null,
	scrollEventHandlers: [],
};

const actions = {
	nudge:
		(deltaX: number): Action<State> =>
		({ setState, getState, dispatch }) => {
			const state = getState();
			const { viewport, container } = state;
			const deltaT = (deltaX * container.duration) / container.width;
			const offset = viewport.offset + deltaT;

			setState({
				...state,
				lastScrollTrigger: 'mousewheel',
				viewport: {
					...viewport,
					offset,
				},
			});

			dispatch(actions.syncToday());
		},
	setViewportOffset:
		(offset: number | ((arg1: number) => number), source: ScrollingTrigger): Action<State> =>
		({ setState, getState, dispatch }) => {
			const state = getState();
			const nextOffset = typeof offset === 'function' ? offset(state.viewport.offset) : offset;

			setState({
				...state,
				lastScrollTrigger: source,
				viewport: {
					...state.viewport,
					offset: nextOffset,
				},
			});

			dispatch(actions.syncToday());
			dispatch(actions.triggerScrollEventHandlers(nextOffset, state.smooth, source));
		},
	resize:
		(payload: {
			viewport: {
				width: number;
				position: {
					left: number;
					top: number;
				};
			};
			container: Container;
		}): Action<State> =>
		({ setState, getState, dispatch }) => {
			const state = getState();

			setState({
				...state,
				viewport: {
					...state.viewport,
					...payload.viewport,
				},
				container: payload.container,
			});

			dispatch(actions.syncToday());
		},
	changeZoomLevel:
		(zoomLevel?: ZoomLevel): Action<State> =>
		({ setState, getState, dispatch }) => {
			const state = getState();
			const newScrollTrigger: ScrollingTrigger = 'zoomLevelChange';

			let newState = {
				...state,
				lastScrollTrigger: newScrollTrigger,
				zoomLevel,
			};

			if (zoomLevel) {
				newState = {
					...newState,
					container: {
						duration: getContainerDuration(zoomLevel),
						width: getContainerWidth(zoomLevel),
					},
				};
			}

			setState(newState);

			dispatch(actions.syncToday());
		},
	scrollTo:
		(offsetInPx: number, source: ScrollingTrigger): Action<State> =>
		({ setState, getState, dispatch }) => {
			const state = getState();
			const { viewport, container } = state;
			const offsetT = (offsetInPx * container.duration) / container.width;

			setState({
				...state,
				lastScrollTrigger: source,
				viewport: {
					...viewport,
					offset: offsetT,
				},
			});

			dispatch(actions.syncToday());
		},
	// this action is used by panning controls "<" and ">" to shift the timeline to the left / right
	shiftTimeline:
		(direction: string, source: ScrollingTrigger): Action<State> =>
		({ setState, getState, dispatch }) => {
			const state = getState();
			const { viewport, container } = state;

			const msToPx = (ms: Duration) => (ms * container.width) / (container.duration || 1);
			const offsetInPx =
				msToPx(viewport.offset) +
				viewport.width * SCROLL_BUTTON_STEP * (direction === 'left' ? -1 : 1);
			const offset = (offsetInPx * container.duration) / container.width;

			setState({
				...state,
				lastScrollTrigger: source,
				viewport: {
					...viewport,
					offset,
				},
			});

			dispatch(actions.syncToday());
			dispatch(actions.triggerScrollEventHandlers(offset, state.smooth, source));
		},
	scrollToView:
		(
			{
				timestamp,
			}: {
				timestamp: Timestamp;
			},
			source: ScrollingTrigger,
		): Action<State> =>
		({ getState, dispatch }) => {
			const { viewport, container, today } = getState();
			const msPerPx = container.duration / container.width;
			const viewportDuration = viewport.width * msPerPx;
			const margin = 24;

			const leftEdge = today + viewport.offset;
			const rightEdge = today + viewport.offset + viewportDuration;

			if (timestamp < leftEdge) {
				const nextViewportOffset =
					timestamp - today - margin * msPerPx; /** have some margin to the left */
				dispatch(actions.setViewportOffset(nextViewportOffset, source));
			}

			if (timestamp > rightEdge) {
				const nextViewportOffset =
					timestamp -
					today -
					viewportDuration +
					margin * msPerPx; /** have some margin to the right */
				dispatch(actions.setViewportOffset(nextViewportOffset, source));
			}

			dispatch(actions.syncToday());
		},
	setSmooth:
		(newSmoothState: boolean): Action<State> =>
		({ setState, getState, dispatch }) => {
			const state = getState();
			setState({
				...state,
				smooth: newSmoothState,
			});

			dispatch(actions.syncToday());
		},
	setScrollingWithScrollBar:
		(newScrollingState: boolean): Action<State> =>
		({ setState, getState, dispatch }) => {
			const state = getState();
			setState({
				...state,
				isScrollingWithScrollBar: newScrollingState,
			});

			dispatch(actions.syncToday());
		},
	backToToday:
		(): Action<State> =>
		({ getState, dispatch }) => {
			const { container } = getState();
			const msPerPx = container.duration / container.width;
			const margin = ESTIMATED_MAX_COLUMN_WIDTH;

			const nextViewportOffset = -margin * msPerPx; /** have some margin to the left */
			dispatch(actions.setViewportOffset(nextViewportOffset, 'todayButton'));

			dispatch(actions.syncToday());
		},

	// When you open a plan on day 1, then go to the same plan on day 2 without refreshing the page, and
	// scroll the plan horizontally, the timeline header will appear shifted by a month because startOfUtcDay(Date.now())
	// is different from the startOfUtcDay(Date.now()) stored in the horizontal scrolling state (= yesterday)
	// therefore this function resets horizontal scrolling's `today` to... today
	// see https://jdog.jira-dev.com/browse/JPO-20671 for future reference
	syncToday:
		(): Action<State> =>
		({ getState, setState }) => {
			const state = getState();
			const startOfToday = startOfUtcDay(Date.now());
			if (state.today !== startOfToday) {
				setState({
					...state,
					today: startOfToday,
				});
			}
		},
	triggerScrollEventHandlers:
		(offset: number, smooth: boolean, source: ScrollingTrigger): Action<State> =>
		({ getState }) => {
			const { container, scrollEventHandlers } = getState();
			const offsetInPx = (offset * container.width) / container.duration;

			scrollEventHandlers.forEach((callback) => callback({ offsetInPx, smooth, source }));
		},
	addScrollHandler:
		(callback: ScrollEventHandler): Action<State> =>
		({ getState, setState }) => {
			setState({
				scrollEventHandlers: getState().scrollEventHandlers.concat(callback),
			});
		},
	removeScrollHandler:
		(callback: ScrollEventHandler): Action<State> =>
		({ getState, setState }) => {
			setState({
				scrollEventHandlers: getState().scrollEventHandlers.filter(
					(eventHandler) => eventHandler !== callback,
				),
			});
		},
} as const;

export type Actions = typeof actions;

export const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'portfolio-3/horizontal-scrolling',
});

export const useHorizontalScrolling = createHook(Store);

export const createHorizontalScrollingHook = <SE, PR = undefined>(
	selector: null | ((state: State, props: PR) => SE),
): HookFunction<SE, BoundActions<State, Actions>, PR> => createHook(Store, { selector });

/**
 * Returns the selected zoom level, this hook can be used for indicating whether
 */
export const useZoomLevel = createHorizontalScrollingHook<ZoomLevel | undefined, undefined>(
	(state) => state.zoomLevel,
);

export const useToday = createHorizontalScrollingHook<Timestamp, undefined>((state) => state.today);

export const useSmooth = createHorizontalScrollingHook<boolean, undefined>((state) => state.smooth);

export const useContainer = createHorizontalScrollingHook<Container, undefined>(
	createSelector(
		({ container }) => container.duration,
		({ container }) => container.width,
		(duration, width) => ({ duration, width }),
	),
);

/** Utilities that help calculating the timeline. */
export const useTimelineRuler = createHorizontalScrollingHook<TimelineRuler, undefined>(
	createSelector(
		({ container }) => container.duration,
		({ container }) => container.width,
		(duration, width) => ({
			pxToMs: (px: number) => (px * duration) / (width || 1) /** avoiding dividing by 0 */,
			msToPx: (ms: Duration) => (ms * width) / (duration || 1) /** avoiding dividing by 0 */,
		}),
	),
);

const DEFAULT_OVERSCAN = 1;

export type RenderingWindowOptions = {
	overscan?: number;
};

export type RenderingWindowProps = {
	today: Timestamp;
	from: Duration;
	to: Duration;
	timelineRange: {
		start: Timestamp;
		end: Timestamp;
	};
};

const getViewportDuration = ({ container, viewport }: State) =>
	(container.duration / container.width) * viewport.width;

const getRenderingOverscan = createSelector(
	getViewportDuration,
	(viewportDuration): number => DEFAULT_OVERSCAN * viewportDuration,
);

const getRenderingFrom = createSelector(
	getRenderingOverscan,
	({ viewport }) => viewport.offset,
	(overscan, viewportOffset) => {
		const step = overscan / 2;
		return Math.floor(viewportOffset / step) * step - step;
	},
);

const getRenderingTo = createSelector(
	getRenderingOverscan,
	getViewportDuration,
	getRenderingFrom,
	(overscan, viewportDuration, renderingFrom) =>
		renderingFrom + overscan + viewportDuration + overscan,
);

/**
 * Returns the current rendering window.
 *
 * The rendering window is used for virtualization, it indicates the relative time range offset
 * that covers the viewport with some padding to both ends.
 */
export const useRenderingWindow = createHorizontalScrollingHook<RenderingWindowProps, undefined>(
	createSelector(
		getRenderingFrom,
		getRenderingTo,
		({ today }) => today,
		(from, to, today) => {
			const start: Timestamp = today + from;
			const end: Timestamp = today + to;

			return { today, from, timelineRange: { start, end }, to };
		},
	),
);

export type GetPercentageOffset = (arg1: Duration) => Duration;

/**  Returns a conversion: duration => container percentage. */
export const useHorizontalPercentageOffset = createHook(Store, {
	selector: createSelector(
		(state) => state.container.duration,
		(state) => state.today,
		(duration, today) => ({
			getPercentageOffset: (offset: Duration) => (100 * offset) / duration,
			getPercentageOffsetFromToday: (offset: Duration) => (100 * (offset - today)) / duration,
		}),
	),
});

type Unsubscribe = () => void;

export const subscribeHorizontalScrolling = (callback: (arg1: State) => void): Unsubscribe => {
	const { storeState } = defaultRegistry.getStore<State, Actions>(Store);
	return storeState.subscribe(() => {
		callback(storeState.getState());
	});
};
