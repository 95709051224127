import React, { useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { IsoDateFormat } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { isISOCompatible } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import {
	withSlots,
	slots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export const makeHandleOnChange =
	({
		attribute,
		onChange,
		issue,
	}: {
		attribute: Props['attribute'];
		onChange: Props['onChange'];
		issue: Props['issue'];
	}) =>
	(date: string) => {
		if (date === '') {
			return onChange(issue, attribute, undefined);
		}
		if (!isISOCompatible(date)) {
			return;
		}
		const dateUTC = new Date(date).getTime();
		onChange(issue, attribute, dateUTC || undefined);
	};

// eslint-disable-next-line @typescript-eslint/no-shadow
export const DatePicker = ({ attribute, isReadOnly, issue, DatePicker, onChange }: Props) => {
	const [isHovered, setIsHovered] = useState(false);
	const { formatMessage } = useIntl();
	const value = issue.customFields && issue.customFields[attribute];
	// for browser comparability (e.g Safari) we have to pass value to datepicker only ISO date format
	const dateValue = value ? formatDateUTC(parseFloat(value), IsoDateFormat) : '';

	const handleOnChange = makeHandleOnChange({ attribute, issue, onChange });

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={isReadOnly ? styles.isReadOnly : ''}>
			<HoverObserver onHoverChanged={setIsHovered}>
				<DatePicker
					isDisabled={isReadOnly}
					onChange={handleOnChange}
					placeholder={formatMessage(messages.placeholder)}
					styles={{
						// eslint-disable-next-line @typescript-eslint/no-shadow
						placeholder: (styles) => ({
							display: !isHovered && 'none',
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							width: 'calc(100% - 9px)',
							...styles,
						}),
					}}
					showClearIndicator
					value={dateValue}
				/>
			</HoverObserver>
		</div>
	);
};

export default withSlots({ DatePicker: slots.FieldDatePicker })(DatePicker);
