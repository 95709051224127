:root {
	--jpo-common-base-spacing: 8px;
	--jpo-common-min-app-width: 1000px;
	--adg3-color-R50: #ffebe6;
	--adg3-color-R75: #ffbdad;
	--adg3-color-R100: #ff8f73;
	--adg3-color-R200: #ff7452;
	--adg3-color-R300: #ff5630;
	--adg3-color-R400: #de350b;
	--adg3-color-R500: #bf2600;
	--adg3-color-Y50: #fffae6;
	--adg3-color-Y75: #fff0b3;
	--adg3-color-Y100: #ffe380;
	--adg3-color-Y200: #ffc400;
	--adg3-color-Y300: #ffab00;
	--adg3-color-Y400: #ff991f;
	--adg3-color-Y500: #ff8b00;
	--adg3-color-G50: #e3fcef;
	--adg3-color-G75: #abf5d1;
	--adg3-color-G100: #79f2c0;
	--adg3-color-G200: #57d9a3;
	--adg3-color-G300: #36b37e;
	--adg3-color-G400: #00875a;
	--adg3-color-G500: #006644;
	--adg3-color-B50: #deebff;
	--adg3-color-B75: #b3d4ff;
	--adg3-color-B100: #4c9aff;
	--adg3-color-B200: #2684ff;
	--adg3-color-B300: #0065ff;
	--adg3-color-B400: #0052cc;
	--adg3-color-B500: #0747a6;
	--adg3-color-P50: #eae6ff;
	--adg3-color-P75: #c0b6f2;
	--adg3-color-P100: #998dd9;
	--adg3-color-P200: #8777d9;
	--adg3-color-P300: #6554c0;
	--adg3-color-P400: #5243aa;
	--adg3-color-P500: #403294;
	--adg3-color-T50: #e6fcff;
	--adg3-color-T75: #b3f5ff;
	--adg3-color-T100: #79e2f2;
	--adg3-color-T200: #00c7e6;
	--adg3-color-T300: #00b8d9;
	--adg3-color-T400: #00a3bf;
	--adg3-color-T500: #008da6;
	--adg3-color-N0: #ffffff;
	--adg3-color-N10: #fafbfc;
	--adg3-color-N20: #f4f5f7;
	--adg3-color-N30: #ebecf0;
	--adg3-color-N40: #dfe1e6;
	--adg3-color-N50: #c1c7d0;
	--adg3-color-N60: #b3bac5;
	--adg3-color-N70: #a5adba;
	--adg3-color-N80: #97a0af;
	--adg3-color-N90: #8993a4;
	--adg3-color-N100: #7a869a;
	--adg3-color-N200: #6b778c;
	--adg3-color-N300: #5e6c84;
	--adg3-color-N400: #505f79;
	--adg3-color-N500: #42526e;
	--adg3-color-N600: #344563;
	--adg3-color-N700: #253858;
	--adg3-color-N800: #172b4d;
	--adg3-color-N900: #091e42;
	--adg3-color-N10A: rgba(9, 30, 66, 0.02);
	--adg3-color-N20A: rgba(9, 30, 66, 0.04);
	--adg3-color-N30A: rgba(9, 30, 66, 0.08);
	--adg3-color-N40A: rgba(9, 30, 66, 0.13);
	--adg3-color-N50A: rgba(9, 30, 66, 0.25);
	--adg3-color-N60A: rgba(9, 30, 66, 0.31);
	--adg3-color-N70A: rgba(9, 30, 66, 0.36);
	--adg3-color-N80A: rgba(9, 30, 66, 0.42);
	--adg3-color-N90A: rgba(9, 30, 66, 0.48);
	--adg3-color-N100A: rgba(9, 30, 66, 0.54);
	--adg3-color-N200A: rgba(9, 30, 66, 0.6);
	--adg3-color-N300A: rgba(9, 30, 66, 0.66);
	--adg3-color-N400A: rgba(9, 30, 66, 0.71);
	--adg3-color-N500A: rgba(9, 30, 66, 0.77);
	--adg3-color-N600A: rgba(9, 30, 66, 0.82);
	--adg3-color-N700A: rgba(9, 30, 66, 0.89);
	--adg3-color-N800A: rgba(9, 30, 66, 0.95);
	--adg3-color-DN900: #e6edfa;
	--adg3-color-DN800: #dce5f5;
	--adg3-color-DN700: #ced9eb;
	--adg3-color-DN600: #b8c7e0;
	--adg3-color-DN500: #abbbd6;
	--adg3-color-DN400: #9fb0cc;
	--adg3-color-DN300: #8c9cb8;
	--adg3-color-DN200: #7988a3;
	--adg3-color-DN100: #67758f;
	--adg3-color-DN90: #56637a;
	--adg3-color-DN80: #455166;
	--adg3-color-DN70: #3b475c;
	--adg3-color-DN60: #313d52;
	--adg3-color-DN50: #283447;
	--adg3-color-DN40: #202b3d;
	--adg3-color-DN30: #1b2638;
	--adg3-color-DN20: #121a29;
	--adg3-color-DN10: #0e1624;
	--adg3-color-DN0: #0d1424;
	--adg3-color-DN800A: rgba(13, 20, 36, 0.06);
	--adg3-color-DN700A: rgba(13, 20, 36, 0.14);
	--adg3-color-DN600A: rgba(13, 20, 36, 0.18);
	--adg3-color-DN500A: rgba(13, 20, 36, 0.29);
	--adg3-color-DN400A: rgba(13, 20, 36, 0.36);
	--adg3-color-DN300A: rgba(13, 20, 36, 0.4);
	--adg3-color-DN200A: rgba(13, 20, 36, 0.47);
	--adg3-color-DN100A: rgba(13, 20, 36, 0.53);
	--adg3-color-DN90A: rgba(13, 20, 36, 0.63);
	--adg3-color-DN80A: rgba(13, 20, 36, 0.73);
	--adg3-color-DN70A: rgba(13, 20, 36, 0.78);
	--adg3-color-DN60A: rgba(13, 20, 36, 0.81);
	--adg3-color-DN50A: rgba(13, 20, 36, 0.85);
	--adg3-color-DN40A: rgba(13, 20, 36, 0.89);
	--adg3-color-DN30A: rgba(13, 20, 36, 0.92);
	--adg3-color-DN20A: rgba(13, 20, 36, 0.95);
	--adg3-color-DN10A: rgba(13, 20, 36, 0.97);
	--adg3-color-background-light: #ffffff;
	--adg3-color-background-dark: #1b2638;
	--adg3-color-text-light: #091e42;
	--adg3-color-text-dark: #b8c7e0;
	--adg3-color-subtleText-light: #5e6c84;
	--adg3-color-subtleText-dark: #8c9cb8;
	--adg3-color-placeholderText-light: #7a869a;
	--adg3-color-placeholderText-dark: #7988a3;
	--adg3-color-heading-light: #172b4d;
	--adg3-color-heading-dark: #b8c7e0;
	--adg3-color-subtleHeading-light: #5e6c84;
	--adg3-color-subtleHeading-dark: #8c9cb8;
	--adg3-color-codeBlock-light: #f4f5f7;
	--adg3-color-codeBlock-dark: #283447;
	--adg3-color-link-light: #0052cc;
	--adg3-color-link-dark: #4c9aff;
	--adg3-color-linkHover-light: #0065ff;
	--adg3-color-linkHover-dark: #2684ff;
	--adg3-color-linkActive-light: #0747a6;
	--adg3-color-linkActive-dark: #4c9aff;
	--adg3-color-linkOutline-light: #4c9aff;
	--adg3-color-linkOutline-dark: #2684ff;
	--adg3-color-primary-light: #0052cc;
	--adg3-color-primary-dark: #4c9aff;
	--adg3-color-blue-light: #0052cc;
	--adg3-color-blue-dark: #4c9aff;
	--adg3-color-teal-light: #00b8d9;
	--adg3-color-teal-dark: #00c7e6;
	--adg3-color-purple-light: #6554c0;
	--adg3-color-purple-dark: #998dd9;
	--adg3-color-red-light: #ff5630;
	--adg3-color-red-dark: #ff5630;
	--adg3-color-yellow-light: #ffab00;
	--adg3-color-yellow-dark: #ffab00;
	--adg3-color-green-light: #36b37e;
	--adg3-color-green-dark: #36b37e;
	--jpo-icon-size-small: 16px;
	--jpo-icon-size-medium: 24px;
	--jpo-icon-size-large: 32px;
	--jpo-icon-size-xlarge: 40px;
	/* this variable is the sum of: */
	/* the height between the top screen edge and the inline dialog menu */
	/* the extra bottom spacing from the bottom of the screen */
	/* It is used to control the height of the Filters and View settings inline dialogs */
	--jpo-toolbar-dropdown-offset: 290px;
	--jira-top-navigation-height: 56px;
	--adg3-color-N20-transparent: rgba(35, 55, 95, 0.05);

	/* Alias variables */
	--jpo-spacing: var(--jpo-common-base-spacing);
	--jpo-theme-color: var(--adg3-color-B400);
	--jpo-text-default-color: var(--adg3-color-N800);
	--jpo-text-secondary-color: var(--adg3-color-N200);
	--jpo-text-muted-color: var(--adg3-color-N70);
	--jpo-text-error-color: var(--adg3-color-R400);
	--jpo-bg-default-color: var(--adg3-color-N0);
	--jpo-bg-reverse-color: var(--adg3-color-N20);
	--jpo-bg-warning-color: var(--adg3-color-Y50);
	--jpo-bg-dark-color: var(--adg3-color-N800);
	--jpo-border-default-color: var(--adg3-color-N50);
	--jpo-border-secondary-color: var(--adg3-color-N40);

	/* Z indexes */
	/* Consider removing these when cleaning com.atlassian.rm.jpo.transposition.m2 */
	--jpo-z-index-date-tooltip: 4;
	--jpo-z-index-dependency-marker-hovered: 2;
	--jpo-z-index-dependency-marker: 3;
	--jpo-z-index-dependency-bar: 3;
	--jpo-z-index-dependency-arrow: 3;
	--jpo-z-index-issue-arrow: 3;
	--jpo-z-index-dependency-handle: 10;
	--jpo-z-index-horizontal-scrolling-overlay: 10000;
}
