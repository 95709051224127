import type { Dispatch } from 'redux';
import { fetchUserPickerOptions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/custom-fields/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
	fetchUserList: (query?: string | null) => {
		dispatch(fetchUserPickerOptions(query || '', ''));
	},
});

export default mapDispatchToProps;
