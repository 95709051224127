import messages from './messages.tsx';

export const REMOVED_FROM_ACTIVE_SPRINT =
	'state.updateJira.changesWarnings.REMOVED_FROM_ACTIVE_SPRINT' as const;
export const ADDED_TO_ACTIVE_SPRINT =
	'state.updateJira.changesWarnings.ADDED_TO_ACTIVE_SPRINT' as const;

export const getChangeWarning = (error: string) => {
	switch (error) {
		case REMOVED_FROM_ACTIVE_SPRINT: {
			return [
				{
					header: messages.removedFromActiveSprintHeader,
					message: messages.removedFromActiveSprintMessage,
					messageValues: {},
				},
			];
		}
		case ADDED_TO_ACTIVE_SPRINT: {
			return [
				{
					header: messages.addedToActiveSprintHeader,
					message: messages.addedToActiveSprintMessage,
					messageValues: {},
				},
			];
		}
		default:
			return [];
	}
};
