import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	shareButtonText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.share-button-text',
		defaultMessage: 'Share',
		description:
			'Label of the "Share" button that triggers the "Share plan as" dialog window. Used in the new Advanced Roadmap Layout',
	},
	shareHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.share-header',
		defaultMessage: 'Share plan as',
		description:
			'Header text. There are multiple ways to share the roadmap, this header groups them together.',
	},
	shareAsLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.share-as-link',
		defaultMessage: 'Link',
		description: 'Label for the "Link" navigation item in the Share dialog',
	},
	shareAsEmbed: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.share-as-embed',
		defaultMessage: 'Embed',
		description: 'Label for the "Embed" navigation item in the Share dialog',
	},
	shareAsConfluence: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.share-as-confluence',
		defaultMessage: 'Confluence',
		description: 'Label for the "Confluence" navigation item in the Share dialog',
	},
	shareAsSpreadsheetCsv: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.share-as-spreadsheet-csv',
		defaultMessage: 'CSV',
		description: 'Label for the "CSV" navigation item in the Share dialog',
	},
	shareAsPng: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.share-as-png',
		defaultMessage: 'Image (.png)',
		description: 'Label for the "Image (.png)" navigation item in the Share dialog',
	},
	sharePlanLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.share-plan-label',
		defaultMessage: 'Share plan',
		description: 'Label for the Share plan dialog container',
	},
});

export default messages;
