import React from 'react';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import type { Props } from '@atlassian/jira-portfolio-3-common/src/button/types.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

export default function CellButton({ className, style, ariaExpanded, ...restProps }: Props) {
	const styleObject = style ?? {};
	return (
		<Button
			appearance="subtle"
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={`${className ?? ''} ${styles.cellButton}`}
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				width: '100%',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				textAlign: 'left',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				height: 'calc(100% - 4px)',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				padding: '0px',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				...styleObject,
			}}
			aria-expanded={ariaExpanded}
			{...restProps}
		/>
	);
}
