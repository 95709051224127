import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.conflict-dialog.heading',
		defaultMessage: 'Are you sure you want to save?',
		description: 'Label for view conflict dialog heading',
	},
	message: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.conflict-dialog.message',
		defaultMessage: '<strong>{name}</strong> was updated during your session by the following.',
		description: 'The message for view conflict dialog',
	},
	actionGroupHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.conflict-dialog.action-group-header',
		defaultMessage: 'Would you like to',
		description: 'Label for view conflict dialog action group header',
	},
	overwrite: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.conflict-dialog.overwrite',
		defaultMessage: 'Overwrite their changes',
		description: 'The message for overwrite option',
	},
	saveAs: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.conflict-dialog.save-as',
		defaultMessage: 'Save as a new view',
		description: 'The message for save-as option',
	},
	discardChanges: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.conflict-dialog.discard-changes',
		defaultMessage: 'Discard your changes',
		description: 'The message for discard-changes option',
	},
	modifiedByHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.conflict-dialog.modified-by-header',
		defaultMessage: 'Who',
		description: 'label for the header for "modified by" column',
	},
	modifiedAtHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.conflict-dialog.modified-at-header',
		defaultMessage: 'When',
		description: 'label for the header for "modified when" column',
	},
});
