.container {
	max-width: 363px;
}

.textSizeH200 {
	font-size: 12px;
	font-weight: 600;
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	line-height: 16px;
}

.topDescription {
	padding: calc(var(--jpo-common-base-spacing) * 2);
	background-color: var(--ds-background-neutral, var(--adg3-color-N20));
}

.descriptionParagraph:not(:last-child) {
	margin-bottom: var(--jpo-common-base-spacing);
}

.configurationSection {
	padding: calc(var(--jpo-common-base-spacing) * 2);
}

.overwriteTitle {
	font-size: 14px;
	font-weight: 600;
	color: var(--ds-text, var(--adg3-color-N800));
	line-height: 16px;
	margin-bottom: var(--jpo-common-base-spacing);
}

.overwriteDescription {
	margin-bottom: var(--jpo-common-base-spacing);
}

.configurationTableHeader {
	display: flex;
	border-bottom: 1px solid var(--ds-border, var(--adg3-color-N30));
}

.optionsHeader {
	flex: 1 1 0;
}

.issueValuesHeader {
	display: flex;
	flex: 1.5 1 0;

	& > div {
		flex: 1 1 0;
		margin: var(--jpo-common-base-spacing) 0;
		text-align: center;
	}
}

.configurationTableContent {
	margin-top: calc(var(--jpo-common-base-spacing) * 0.5);
	margin-bottom: calc(var(--jpo-common-base-spacing) * 3);
}

.configurationOptionRow {
	display: flex;
}

.optionsContent {
	flex: 1 1 0;
	margin: var(--jpo-common-base-spacing) 0;
}

.issueValueSelection {
	display: flex;
	flex: 1.5 1 0;
	align-items: center;
	justify-content: space-around;
}

.actionFooter {
	text-align: right;
}

.selectedIssueCount {
	padding: 10px;
	border-radius: 3px;
	background-color: var(--ds-background-neutral-bold, var(--adg3-color-N500));
	color: var(--ds-text-inverse, var(--adg3-color-N0));
	display: flex;
	align-items: center;
	margin-top: 15px;
	margin-bottom: 5px;
}
