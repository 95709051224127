import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import AddToCprDialog from './add-to-cpr-dialog/reducer.tsx';
import DeleteReleaseDialog from './delete-release-dialog/reducer.tsx';
import ReleaseDialog from './release-dialog/reducer.tsx';
import ReleasesTable from './releases-table/reducer.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	ReleasesTable,
	ReleaseDialog,
	DeleteReleaseDialog,
	AddToCprDialog,
});
