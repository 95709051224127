import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	breakdownByEstimateToolTip: {
		id: 'portfolio-3-portfolio.common.status-breakdown.breakdown-by-estimate-tool-tip',
		description: 'Tooltip for progress bar in sprints flyout',
		defaultMessage:
			'{done} ({donePercent}%) of {total} {planningUnit, select, Hours {hours} StoryPoints {points} Days {days} other {points}}',
	},
	statusBreakdownLabel: {
		id: 'portfolio-3-portfolio.common.status-breakdown.status-breakdown-label',
		description: 'Status Breakdown',
		defaultMessage: 'Label for Status breakdown',
	},
});
