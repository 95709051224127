export const GroupBy = {
	NONE: 'none',
	PROJECT: 'project',
	TEAM: 'team',
	SPRINT: 'sprint',
	EPIC_LEVEL: 'epic_level',
} as const;

export type GroupBy = (typeof GroupBy)[keyof typeof GroupBy];

export type Filters = {
	rollupBy: string | null;
	rollupHierarchyLevel: number;
	issueInFocus: string | null;
	groupBy: GroupBy | null;
	issueFilter: string | null;
	projectFilter: string[] | null;
	sprintFilter: string[] | null;
	issueLinkTypeFilter: string[] | null;
};
