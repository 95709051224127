.group {
	position: relative;
	display: flex;
	align-items: center;

	& > .icon {
		padding-top: 2px;
	}
}

.groupTitleSection {
	display: flex;
	align-items: center;
	flex: 1 1 0;
	padding-left: calc(var(--jpo-common-base-spacing) * 1.5);
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	max-width: 264px;
}

.expandCollapseChevron {
	position: absolute;
	cursor: pointer;
	top: 8px;
	left: -25px;
}

.groupTitle {
	flex: 1 1 0;
	min-width: 85%;

	& > form > div {
		margin-top: 0px;
		margin-left: -8px;
	}
}

.groupTitleReadViewContainer {
	color: var(--ds-text, var(--adg3-color-N900));
	display: flex;
	line-height: 1.42857;
	min-height: 1.42857em;
	padding: var(--jpo-common-base-spacing) 6px;
	word-break: break-all;
}

/* this class adds ellipsis when the group title gets too long */
.groupTitleReadViewContainer > span {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.trashButton {
	text-align: right;
	flex: 1 1 0;
}
