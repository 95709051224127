import { getMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { OPTIMIZED } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import {
	getCurrentValue,
	hasValueChanged,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util.tsx';
import type { OwnProps, StateProps } from './types.tsx';

/**
 * Originally implemented at getIssueClasses() in
 * src/packages/portfolio-3/portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/issue/view.tsx
 */
// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State, { issue }: OwnProps): StateProps => ({
	isOptimized: (() => {
		if (!issue) return false;
		if (getMode(state) === OPTIMIZED && issue.optimized != null) {
			for (const [optimizedKey, optimizedValue] of Object.entries(issue.optimized)) {
				if (hasValueChanged(getCurrentValue(issue, optimizedKey), optimizedValue)) {
					return true;
				}
			}
		}
		return false;
	})(),
});
