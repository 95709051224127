import type { IntlShape } from '@atlassian/jira-intl';
import type { EnrichedVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';

const UNRELEASED = 'UNRELEASED' as const;
const RELEASED = 'RELEASED' as const;
const ONTRACK = 'ONTRACK' as const;
const OFFTRACK = 'OFFTRACK' as const;

export const ICON_TYPES = {
	UNRELEASED,
	RELEASED,
	ONTRACK,
	OFFTRACK,
} as const;

export type Props = {
	endDate?: string;
	isHighlighted?: boolean;
	type: keyof typeof ICON_TYPES;
	totalCount: number;
	onClick: () => void;
	innerRef?: {
		current: HTMLElement | null;
	};
	release?: EnrichedVersion;
	showTooltip?: boolean;
};

export type PropsWithIntl = Props & {
	intl: IntlShape;
};
