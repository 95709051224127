import { getSupportedCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

const mapStateToProps = createStructuredSelector<State, StateProps>({
	customFields: getSupportedCustomFields,
});

export default mapStateToProps;
