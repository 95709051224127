import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	groupPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.common.group.group-placeholder',
		defaultMessage: 'Untitled group',
		description: 'Placeholder for untitled group title',
	},
	tooLongInputErrorMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.common.group.too-long-input-error-message',
		defaultMessage: 'Group title cannot be longer than 255 characters',
		description: 'Message to show when the user tries to enter a group title that is too long',
	},
	expandButtonLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.common.group.expand-button-label',
		defaultMessage: 'Expand',
		description: 'Label for expand group button',
	},
	collapseButtonLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.common.group.collapse-button-label',
		defaultMessage: 'Collapse',
		description: 'Label for collapse group button',
	},
	deleteButtonLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.common.group.delete-button-label',
		defaultMessage: 'Delete',
		description: 'Label for delete group button',
	},
});
