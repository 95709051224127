import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showingRolledUpDatesDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.showing-rolled-up-dates-description',
		defaultMessage: 'Show a roll-up of dates in the fields and timeline sections.',
		description: 'Description for checkbox which shows rolled up dates in issues',
	},
	showRolledUpOthers: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.show-rolled-up-others',
		defaultMessage: 'Others',
		description: 'Label for checkbox which shows rolled up values (other than dates) in issues',
	},
	showRolledUpOthersDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.show-rolled-up-others-description',
		defaultMessage:
			'Show a roll-up of sprints, releases, teams, and estimates in the fields section.',
		description:
			'Description for checkbox which shows rolled up values (other than dates) in issues',
	},
	rollupGroupLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.rollup-group-label',
		defaultMessage: 'roll-up',
		description: 'Description for the checkbox group',
	},

	// Old labels. Remove once rollup dates is launched and feature toggle is removed:
	showRolledUpLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.show-rolled-up-label',
		defaultMessage: 'Show rolled-up values in fields',
		description: 'Label for checkbox which shows rolled up values in issues',
	},
	showRolledUpLabelDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.rollup-settings.show-rolled-up-label-description',
		defaultMessage:
			'For parent issues, show a preview of the rolled-up values of the child issues in the corresponding fields.',
		description: 'Description for checkbox which shows rolled up values in issues',
	},
});
