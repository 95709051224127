.clearSearchField {
	position: absolute;
	top: 20px;
	right: 25px;
	cursor: pointer;
}

.clearSearchField span:hover {
	color: var(--ds-text-subtlest, var(--adg3-color-N50));
}

.hasInput input {
	padding-right: 24px;
}
