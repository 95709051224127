.dialogContent {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
}

.body {
	position: relative;
	display: flex;
	flex: 1 1 auto;
	padding: 2px 20px;
	max-height: calc(100% - 120px);
	min-height: 0;
}

.header {
	padding: 20px 20px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	min-height: calc(4 * var(--jpo-common-base-spacing));

	& .title {
		font-size: 20px;
		font-weight: 500;
		color: var(--ds-text, var(--adg3-color-N800));
		letter-spacing: -0.008em;
		line-height: 1;
	}
}

.button-label {
	display: flex;
	align-items: center;
}

.changeCountBadge {
	display: flex;
	margin: 0 2px 0 5px;
	outline: 0;
	border-radius: 2em;
}

.spinner {
	flex: 1 0 auto;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.out-of-sync {
	padding-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.out-of-sync-message {
	padding: 20px 0;
	& span {
		cursor: pointer;
		color: var(--ds-text-brand, var(--adg3-color-B400));
		&:hover {
			text-decoration: underline;
		}
	}
}

.filters {
	display: flex;
	padding-top: var(--jpo-common-base-spacing);
}

.updateJiraButton {
	margin-left: calc(var(--jpo-common-base-spacing) / 2);
}

.footerParent > div:first-child {
	box-shadow: 0 0 0 0;
}
