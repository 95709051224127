import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	setFieldGroupTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.bulk-actions-menu.set-field-group-title',
		defaultMessage: 'Update',
		description: 'Title for update group',
	},
	moreActionsGroupTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.bulk-actions-menu.more-actions-group-title',
		defaultMessage: 'More actions',
		description: 'Title for more actions group',
	},
});
