import * as R from 'ramda';
import { getEnrichedHierarchyLevels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy/index.tsx';
import { getInlineCreateState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/inline-create/index.tsx';
import { getIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import {
	getIssueFilterMatcher,
	isSavingIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import type { EnrichedHierarchyLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/hierarchy/types.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import {
	HIERARCHY_FILTER_ID,
	HIERARCHY_RANGE_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { INLINE_CREATE_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/reducer.tsx';
import type { InlineCreateState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/scope/inline-create/types.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { InlineCreateStateProps } from './types.tsx';

export const getOrderedIssueTypesForLevelPure = (
	hierarchyLevels: EnrichedHierarchyLevel[],
	state: InlineCreateState,
): IssueType[] => {
	const level = state.hierarchyLevel;
	const enrichedLevel: EnrichedHierarchyLevel | null | undefined = R.find(
		R.propEq('level', level),
		hierarchyLevels,
	);
	return enrichedLevel ? enrichedLevel.issueTypes : [];
};

export const getOrderedIssueTypesForLevel = createSelector(
	[getEnrichedHierarchyLevels, getInlineCreateState],
	getOrderedIssueTypesForLevelPure,
);

export const getInlineCreateFilterMatcher = createSelector(
	[getInlineCreateState, getIssueFilterMatcher],
	(state, matchFilter) => (summary: string) => {
		const issue = {
			...state.values,
			summary,
		};

		// @ts-expect-error - TS2345 - Argument of type '{ summary: string; status?: string | undefined; parent?: string | undefined; description?: string | undefined; project: number; labels?: string[] | null | undefined; components?: number[] | null | undefined; ... 24 more ...; inferred?: InferredFields | undefined; }' is not assignable to parameter of type 'Readonly<{ assignee: string; assignments: Assignment[]; annotations?: Annotation[] | undefined; baselineStart?: number | null | undefined; baselineEnd?: number | null | undefined; ... 30 more ...; inferred?: InferredFields | undefined; }>'.
		return matchFilter(issue, {
			exclude: [HIERARCHY_FILTER_ID, HIERARCHY_RANGE_FILTER_ID],
		});
	},
);

export const getInlineCreateDataPure = (
	issueTypes: IssueType[],
	projects: Project[],
	state: InlineCreateState,
	orderedIssueTypes: IssueType[],
	matchFilter: (arg1: string) => boolean,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	isSavingIssue: boolean,
): InlineCreateStateProps => {
	const id = R.prop('id');
	const issueTypesById = indexBy(id, issueTypes);
	const projectsById = indexBy(id, projects);
	return {
		id: INLINE_CREATE_ID,
		issueTypeId: state.issueTypeId,
		projectId: state.projectId,
		hierarchyLevel: state.hierarchyLevel,
		issueTypes: issueTypesById,
		orderedIssueTypes,
		projects: projectsById,
		isGlobalCreate: state.isGlobalCreateIssue,
		matchFilter,
		isSavingIssue,
		isOpenLocked: Object.values(state.openLockedMap).some((value) => !!value),
	};
};

export default createSelector(
	[
		getIssueTypes,
		getProjects,
		getInlineCreateState,
		getOrderedIssueTypesForLevel,
		getInlineCreateFilterMatcher,
		isSavingIssue,
	],
	getInlineCreateDataPure,
);
