import React from 'react';
import Icon from '@atlaskit/icon';
import type { CustomGlyphProps } from '@atlaskit/icon/types';

const Glyph = (props: CustomGlyphProps) => (
	<svg height="24" width="24" fill="none" viewBox="0 0 24 24" {...props}>
		<rect width="13" height="3" x="5" y="5" fill="currentColor" rx="1.5" />
		<rect width="10" height="3" x="10" y="10.5" fill="currentColor" rx="1.5" />
		<rect width="9" height="3" x="5" y="16" fill="currentColor" rx="1.5" />
	</svg>
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => <Icon glyph={Glyph} label="" />;
