import { getHierarchyFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/hierarchy-filter/index.tsx';
import { getHierarchyLevels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createSelector(
	[getHierarchyLevels, getHierarchyFilter],
	(hierarchy, { id, value: { start, end } }): StateProps => ({
		id,
		hierarchy,
		start,
		end,
	}),
);
