import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const LabelsComponent = ({
	attribute,
	fetchOptions,
	isReadOnly,
	issue,
	onChange,
	MultiSelectCell,
	getValues,
	menuIsOpen,
}: Props) => {
	const { formatMessage } = useIntl();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const customNoOptionsMessage = (inputValue: any) => {
		if (inputValue.includes(' ')) {
			return formatMessage(messages.invalidInputWithSpace);
		}
		if (inputValue.trim().length > 255) {
			return formatMessage(messages.invalidInputWithMaxLength);
		}
	};
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return (
		<MultiSelectCell
			menuIsOpen={menuIsOpen}
			attribute={attribute}
			messages={{
				placeholder: formatMessage(messages.chooseLabel),
				noLabels: formatMessage(messages.noLabels),
				noLabelsLeft: formatMessage(messages.noLabelsLeft),
				noMatch: formatMessage(messages.noMatch),
			}}
			getValues={getValues}
			fetchOptions={fetchOptions}
			isReadOnly={isReadOnly}
			issue={issue}
			onChange={onChange}
			isCreatable
			customNoOptionsMessage={customNoOptionsMessage}
			onCreateOption={() => {
				const [actionSubject, action] = PRODUCT_ANALYTICS_EVENT_NAMES.ADD_LABEL.split(' ');
				const analyticsEvent = createAnalyticsEvent({ action, actionSubject });
				fireTrackAnalytics(analyticsEvent, PRODUCT_ANALYTICS_EVENT_NAMES.ADD_LABEL);
			}}
			formatCreateLabel={(label: string) => `${label} (${formatMessage(messages.newLabel)})`}
			convertCreatedLabelToOption={(inputValue: string) => ({
				label: inputValue,
				value: inputValue,
			})}
			validateInputForCreation={(inputValue: string) => {
				if (
					inputValue.includes(' ') ||
					inputValue.trim().length > 255 ||
					inputValue.trim().length === 0
				) {
					return false;
				}
				return true;
			}}
		/>
	);
};

export default LabelsComponent;
