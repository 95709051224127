import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	permissionCheckLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.goals.permission-check-link',
		defaultMessage: 'Request or confirm Atlas access.',
		description: 'Link prompting user to visit Atlas',
	},
	permissionCheckPlatformGoalsTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.goals.permission-check-platform-goals-tooltip',
		defaultMessage: 'Request or confirm goals access',
		description:
			'Popup tooltip, error displayed when attempting to use the Goals field when user does not have the required permissions',
	},
	permissionCheckPlatformGoalsLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.goals.permission-check-platform-goals-link',
		defaultMessage:
			"<p>Looks like you don't have access to goals.</p> <p>You can request access by visiting <link>this link</link></p>",
		description:
			'Popup content, error displayed when attempting to use the Goals field when user does not have the required permissions',
	},
});
