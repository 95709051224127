.container {
	padding: var(--jpo-common-base-spacing) 0;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 40vh;
}

.item {
	line-height: initial;
	padding: var(--jpo-common-base-spacing) calc(var(--jpo-common-base-spacing) * 2);
}

.group-heading {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 11px;
	line-height: initial;
	text-transform: uppercase;
	font-weight: bold;
	padding: calc(var(--jpo-common-base-spacing) * 2) calc(var(--jpo-common-base-spacing) * 2)
		var(--jpo-common-base-spacing);
}
