import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	noMatchFoundText: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.menu-content.no-match-found-text',
		defaultMessage: 'No matches',
		description:
			'This is a text is displayed when none of the version name matches the search query.',
	},
});
