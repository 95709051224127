import React, { type ComponentType } from 'react';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import {
	CustomFieldTypes,
	GROUPING,
	type Grouping,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isRoadmapGroupedByCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import MenuContent from '../menu-content/view.tsx';
import AssigneeOptionsProvider from '../options-providers/assignee/index.tsx';
import CustomFieldOptionsProvider from '../options-providers/custom-field/index.tsx';
import CustomUserPickerOptionsProvider from '../options-providers/custom-user-picker/index.tsx';
import ProjectOptionsProvider from '../options-providers/project/index.tsx';
import ReleaseOptionsProvider from '../options-providers/release/index.tsx';
import SprintOptionsProvider from '../options-providers/sprint/index.tsx';
import TeamOptionsProvider from '../options-providers/team/index.tsx';
import type { OptionsProviderProps } from '../options-providers/types.tsx';
import type { Props } from './types.tsx';

const getOptionsProvider = (
	grouping: Grouping,
	customFieldsById: Record<number, CustomField>,
): ComponentType<OptionsProviderProps<GroupCombination>> => {
	if (isRoadmapGroupedByCustomField(grouping)) {
		const fieldId = parseInt(getCustomFieldIdFromCustomFieldGrouping(grouping), 10);
		const fieldType = customFieldsById[fieldId].type.key;

		switch (fieldType) {
			case CustomFieldTypes.UserPicker:
				return CustomUserPickerOptionsProvider;
			default:
				return CustomFieldOptionsProvider;
		}
	}

	switch (grouping) {
		case GROUPING.TEAM:
			return TeamOptionsProvider;
		case GROUPING.PROJECT:
			return ProjectOptionsProvider;
		case GROUPING.SPRINT:
			return SprintOptionsProvider;
		case GROUPING.RELEASE:
			return ReleaseOptionsProvider;
		case GROUPING.ASSIGNEE:
		default:
			return AssigneeOptionsProvider;
	}
};

const GroupMenu = ({ renderOption, grouping, customFieldsById }: Props) => {
	const OptionsProvider = getOptionsProvider(grouping, customFieldsById);

	return (
		<OptionsProvider>
			{(result) => (
				<MenuContent
					renderOption={renderOption}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.group-menu"
					{...result}
				/>
			)}
		</OptionsProvider>
	);
};

export default GroupMenu;
