import {
	isConfluenceMacro,
	isEmbed,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import {
	getSearchQuery,
	getActiveSearchResultIndex,
	searchForIssues,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, {}, StateProps>({
	searchQuery: getSearchQuery,
	issueSearchResults: searchForIssues,
	activeSearchResultIndex: getActiveSearchResultIndex,
	isConfluenceMacro,
	isEmbed,
});
