import React from 'react';
import {
	useZoomLevel,
	useHorizontalPercentageOffset,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapStateToProps from './query.tsx';
import type { OwnProps } from './types.tsx';
import { useMemoedSprint } from './utils.tsx';
import View from './view.tsx';

const Sprint = connect(mapStateToProps, {})(View);

const SprintWithHorizontalScrolling = ({ sprint, ...rest }: OwnProps) => {
	const [zoomLevel] = useZoomLevel();
	const [extraProps] = useHorizontalPercentageOffset();
	const memoedSprint = useMemoedSprint(sprint, zoomLevel);
	return <Sprint {...rest} {...extraProps} zoomLevel={zoomLevel} sprint={memoedSprint} />;
};

export default SprintWithHorizontalScrolling;
