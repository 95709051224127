import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/index.tsx';
import { getIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import {
	getIssues,
	getIssueMapById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import { DEPENDENCIES_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { StateProps } from './types.tsx';

const mapStateToProps: MapStateToProps<StateProps> = (state) => {
	const {
		[DEPENDENCIES_FILTER_ID]: { id, value },
	} = getFilters(state);
	return {
		id,
		issues: getIssues(state),
		issueMapById: getIssueMapById(state),
		issueTypes: getIssueTypes(state),
		projectsById: getProjectsById(state),
		value,
	};
};

export default mapStateToProps;
