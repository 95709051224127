import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.jsw-no-access-error-flag.title',
		defaultMessage: "This plan isn't available",
		description:
			'This is a header for error flag showing when the user does not have JSW Software access.',
	},
	descriptionSpork: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.jsw-no-access-error-flag.description-spork',
		defaultMessage:
			'Your Jira permissions have changed, and you can no longer view this plan. Contact an administrator to restore access.',
		description:
			'This is a description for error flag showing when the user does not have JSW Software access.',
	},
});

export default messages;
