import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	setReleaseHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-cross-project-versions.set-release-header',
		defaultMessage: 'Update release',
		description: 'Modal dialog header for dialog release',
	},
	variousValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-cross-project-versions.various-values',
		defaultMessage: 'Various releases',
		description: 'Label for various release values',
	},
});
