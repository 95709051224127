import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';

export const RESET = 'state.domain.persons.RESET' as const;
export const ADD = 'state.domain.persons.ADD' as const;

export type ResetActionPayload = Person[];
export type AddActionPayload = Person;

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const add = (payload: AddActionPayload) => ({
	type: ADD,
	payload,
});
