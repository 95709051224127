import * as R from 'ramda';
import { fg } from '@atlassian/jira-feature-gating';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	ENTITY,
	SCENARIO_TYPE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { PlanDefaults } from '../../state/domain/plan-defaults/types.tsx';
import type { Team } from '../../state/domain/teams/types.tsx';
import type { EntityMetadata } from '../../state/domain/update-jira/changes/types.tsx';
import type { State } from '../../state/types.tsx';
import type { DialogMode } from '../../state/ui/main/tabs/teams/list/actions.tsx';
import { getPlanDefaults, getDefaultTeamVelocity } from '../plan-defaults/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Team } from '../../state/domain/teams/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { OriginalTeams } from '../../state/domain/original-teams/types';

export const getTeams = (state: State) => state.domain.teams;

export const getExistingTeams = createSelector([getTeams], (teams: Team[]): Team[] =>
	teams.filter((team) => team.scenarioType !== SCENARIO_TYPE.DELETED),
);

export const getScenarioRemovedAtlassianTeams = createSelector(
	[getTeams],
	(teams: Team[]): Team[] =>
		teams.filter((team) => team.scenarioType === SCENARIO_TYPE.DELETED && team.shareable),
);

export const getAdditionalTeams = (state: State) => {
	return fg('eou_open_team_dialog_in_timeline')
		? state.domain.teamsAdditional.filter(
				({ id }) => !state.domain.teams.some((team) => team.id === id),
			)
		: state.domain.teamsAdditional;
};
export const getAdditionalExistingTeams = createSelector(
	[getAdditionalTeams],
	(teams: Team[]): Team[] => teams.filter((team) => team.scenarioType !== SCENARIO_TYPE.DELETED),
);

export const getAllTeams = createSelector(
	[getTeams, getAdditionalTeams],
	(teams: Team[], additionalTeams: Team[]): Team[] => teams.concat(additionalTeams),
);

export const getAllExistingTeams = createSelector([getAllTeams], (teams: Team[]): Team[] =>
	teams.filter((team) => team.scenarioType !== SCENARIO_TYPE.DELETED),
);

export const getOriginalTeams = (state: State) => state.domain.originalTeams;

export const getTeamById = (state: State, teamId?: string | null) => {
	const teams: Team[] = getTeams(state);
	return teams.find((team) => team.id === teamId);
};

export const getTeamsByIdPure = (
	teams: Team[],
): {
	[key: string]: Team;
} => indexBy((x) => x.id, teams);

export const getAllTeamsById = createSelector([getAllTeams], getTeamsByIdPure);

export const getTeamsById = createSelector([getTeams], getTeamsByIdPure);

export const getAdditionalTeamsById = createSelector([getAdditionalTeams], getTeamsByIdPure);

export const getTeamVelocityMapPure = (
	teams: Team[],
	defaultTeamVelocity: number,
): {
	[key: string]: number;
} => {
	const velocityMap: Record<string, number> = {};
	teams.forEach((team) => {
		velocityMap[team.id] =
			team.velocity || team.velocity === 0 ? team.velocity : defaultTeamVelocity;
	});
	return velocityMap;
};

export const getTeamVelocityMap = createSelector(
	[getTeams, getDefaultTeamVelocity],
	getTeamVelocityMapPure,
);

export const findTeamById = (teams: Team[], teamId: string) =>
	teams.find((team) => team.id === teamId);

export const findResourceFromTeam = (team: Team, resourceKey: string) =>
	team.resources.find((resource) => resource.itemKey === resourceKey);

export const findTeamAssociatedWithResource = (teams: Team[], resourceKey: string) =>
	teams.find((team) => findResourceFromTeam(team, resourceKey));

export const isTeamwithoutResources = (team: Team) =>
	R.isNil(team.resources) ||
	R.isEmpty(team.resources) ||
	team.resources.filter(({ scenarioType }) => scenarioType !== SCENARIO_TYPE.DELETED).length === 0;

export const getCommitWarningsForTeams = ({
	domain: {
		updateJira: { warnings },
	},
}: State) => warnings[ENTITY.TEAM];

export const getTeamChangesMetaData = ({
	domain: { updateJira },
}: State): EntityMetadata | undefined => {
	if (
		updateJira &&
		updateJira.changes &&
		updateJira.changes.data &&
		updateJira.changes.data.metaData &&
		updateJira.changes.data.metaData.teams
	) {
		return updateJira.changes.data.metaData.teams;
	}
};

export const getExpandedTeamChanges = (state: State) =>
	state.ui.Top.TitleBar.UpdateJira.expandedChanges.TEAM;

export const getSelectedTeamChanges = (state: State) =>
	state.ui.Top.TitleBar.UpdateJira.selectedChanges.TEAM;

const getWeeklyCapacity = (planDefaults: PlanDefaults) => (team: Team) => {
	if (team.weeklyHours || team.weeklyHours === 0) {
		return team.weeklyHours;
	}
	return planDefaults.defaultTeamWeeklyHours || 0;
};

export const getWeeklyCapacityByTeam = createSelector(
	[getTeamsById, getPlanDefaults],
	(teamsById, planDefaults) =>
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		R.map(getWeeklyCapacity(planDefaults), teamsById) as Record<string, number>,
);

export const getAddedTWPInfo = (state: State) => ({
	teamTitle: state.ui.Main.Tabs.Teams.CreateTeam.addedTWPTeamTitle,
	teamId: state.ui.Main.Tabs.Teams.CreateTeam.addedTWPTeamId,
});

export const getDialogMode = (state: State): DialogMode | null | undefined =>
	state.ui.Main.Tabs.Teams.TeamsList.dialogMode;

export const getEditedTeam = (state: State) => state.ui.Main.Tabs.Teams.TeamsList.editedTeam;
