import React, { useMemo, useCallback } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import JiraShareButton from '@atlassian/jira-share-button/src/index.tsx';
import { useShareIntegrations } from '@atlassian/jira-share-integrations/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { routeMapping } from './constants.tsx';

const ShareButton = ({ routeName }: { routeName: string }) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const [planId] = usePathParam('planId');

	const getIntegrationType = useCallback(() => {
		const shareInfo = routeMapping[routeName];

		if (shareInfo) {
			return { ...shareInfo, shareTitle: formatMessage(shareInfo.shareTitle) };
		}

		return { integrationType: '', shareTitle: '' };
	}, [formatMessage, routeName]);

	const { integrationType, shareTitle } = getIntegrationType();
	const { shareIntegrations } = useShareIntegrations(integrationType);

	const entityAri = useMemo(
		() =>
			createAri({
				resourceOwner: 'jira-software',
				cloudId,
				resourceType: 'plan',
				resourceId: String(planId),
			}),
		[cloudId, planId],
	);

	return (
		<Box xcss={wrapperStyles}>
			<JiraShareButton
				productId="jira"
				subProductId="jira-software"
				shareContentType={integrationType}
				integrationType={integrationType}
				shareTitle={shareTitle}
				shareIntegrations={shareIntegrations}
				integrationMode="tabs"
				objectAri={entityAri}
				triggerButtonStyle="icon-only"
			/>
		</Box>
	);
};

const wrapperStyles = xcss({
	display: 'inline-flex',
});

export default ShareButton;
