import React from 'react';
import NoIssuesEmptyState from '../../scope/issues/no-issues-empty-state/index.tsx';
import NoIssuesInFilterView from '../../scope/issues/no-issues-in-filter-empty-view/index.tsx';
import type { Props } from './types.tsx';

export const EmptyView = ({ issues, noIssuesInPlan, isReadOnly }: Props) => {
	if (issues.length === 0) {
		if (noIssuesInPlan) {
			return <NoIssuesEmptyState isReadOnly={isReadOnly} />;
		}
		return <NoIssuesInFilterView />;
	}
};
