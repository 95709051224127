export const FETCH_ASSIGNEES_START =
	'state.ui.main.tabs.roadmap.fields.assignee.FETCH_ASSIGNEES_START' as const;
export const FETCH_ASSIGNEES_STOP =
	'state.ui.main.tabs.roadmap.fields.assignee.FETCH_ASSIGNEES_STOP' as const;

type FetchAssignlistProgressPayload = {
	issueId?: string;
};

export type FetchAssigneesStartAction = {
	type: typeof FETCH_ASSIGNEES_START;
	payload: FetchAssignlistProgressPayload;
};

export type FetchAssigneesStopAction = {
	type: typeof FETCH_ASSIGNEES_STOP;
	payload: FetchAssignlistProgressPayload;
};

export const startUpdatingAssigneeList = (issueId?: string): FetchAssigneesStartAction => ({
	type: FETCH_ASSIGNEES_START,
	payload: { issueId: issueId || '' },
});
export const stopUpdatingAssigneeList = (issueId?: string): FetchAssigneesStopAction => ({
	type: FETCH_ASSIGNEES_STOP,
	payload: { issueId: issueId || '' },
});
