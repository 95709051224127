import type { Solution, CalculationConfiguration } from '../../state/domain/solution/types.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Solution } from '../../state/domain/solution/types';

export const getIsSolutionLatest = (state: State): boolean => state.domain.solution.isLatest;

export const getIsSolutionValid = (state: State): boolean => state.domain.solution.isValid;

export const getSolution = (state: State): Solution => state.domain.solution;

export const getApplyingProgress = (state: State): number | null | undefined =>
	state.domain.solution.applyingProgress;

export const getCalculationConfiguration = (
	state: State,
): CalculationConfiguration | null | undefined => state.domain.solution.calculationConfiguration;
