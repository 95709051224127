export type ScrollEventHandler = (arg0: Pick<State, 'scrollX' | 'scrollY'>) => void;

export const DOWNWARD = 'downward' as const;
export const UPWARD = 'upward' as const;

export interface State {
	top: number;
	left: number;
	width: number;
	height: number;
	scrollX: number;
	scrollY: number;
	scrollYDirection: typeof DOWNWARD | typeof UPWARD;
	scrollTo: (arg0: { x?: number; y?: number; smooth?: boolean }) => void;
	scrollEventHandlers: ScrollEventHandler[];
	isScrollingY: boolean;
}
