.title-group {
	display: flex;
	align-items: center;
	padding-right: var(--jpo-common-base-spacing);
}

.avatar {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 16px;
	display: flex;
}

.title {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	margin-left: var(--jpo-common-base-spacing);
}

.defaultTeamAvatar {
	background-color: var(--ds-background-neutral, var(--adg3-color-N40));
	border-radius: 50%;
	margin: 2px;
	padding: 2px;

	& > span {
		display: flex;
		height: 12px;
		width: 12px;
	}
}
