import {
	discardChanges,
	saveView,
	saveViewAs,
	deleteView,
	switchView,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/views/index.tsx';
import {
	setSaveViewAsDialogStatus,
	removeError,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/view-bar/actions.tsx';

export type DiscardChanges = typeof discardChanges;
export type SaveView = typeof saveView;
export type SaveViewAs = typeof saveViewAs;
export type DeleteView = typeof deleteView;
export type RemoveError = typeof removeError;
export type SetSaveViewAsDialogStatus = typeof setSaveViewAsDialogStatus;
export type SwitchView = typeof switchView;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default {
	discardChanges,
	saveView,
	saveViewAs,
	removeError,
	deleteView,
	setSaveViewAsDialogStatus,
	switchView,
};
