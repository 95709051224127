export const DELETE_RELEASE =
	'state.ui.main.tabs.releases.project-releases.delete-release-dialog.DELETE_RELEASE' as const;
export const REMOVE_RELEASE =
	'state.ui.main.tabs.releases.project-releases.delete-release-dialog.REMOVE_RELEASE' as const;

export const DELETE_DIALOG_ACTIONS = {
	DELETE_RELEASE,
	REMOVE_RELEASE,
} as const;

export type Mode = typeof DELETE_RELEASE | typeof REMOVE_RELEASE;

export type DeleteReleaseDialog = Readonly<{
	mode: Mode;
	isDialogOpen: boolean;
	versionId: string;
	isProcessingRequest: boolean;
}>;
