.spinner-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 480px;
	height: 480px;
	max-height: 100%;
}

.description-paragraphs {
	margin-bottom: calc(var(--jpo-common-base-spacing) * 3);
}
