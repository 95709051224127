import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	releaseNotFoundHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.versions.release-not-found-header',
		defaultMessage: 'Release {versionTitle} no longer exists in this plan',
		description:
			'Message to show in the header when release commit fails because the release is missing',
	},
	releaseNotFoundContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.versions.release-not-found-content',
		defaultMessage: 'Refresh your plan to reflect this change',
		description:
			'Message to show in the content when release commit fails because the release is missing',
	},
	projectNotFoundHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.versions.project-not-found-header',
		defaultMessage: 'Project no longer exists in this plan',
		description: 'Message to show in the header when commit fails due to missing project',
	},
	projectNotFoundContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.versions.project-not-found-content',
		defaultMessage: 'Refresh to update your plan',
		description: 'Message to show in the content when commit fails due to missing project',
	},
	crossProjectReleaseNotCommittedHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.versions.cross-project-release-not-committed-header',
		defaultMessage: 'Changes not saved',
		description:
			'Message to show in the header when version commit fails because the cross project release has not been committed',
	},
	crossProjectReleaseNotCommittedContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.versions.cross-project-release-not-committed-content',
		defaultMessage:
			'This release is linked to {crossProjectVersionTitle}, which is a cross-project release. You need to save {crossProjectVersionTitle} in Jira first, then save this release.',
		description:
			'Message to show in the content when version commit fails because the cross project release has not been committed',
	},
	defaultWarningHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.versions.default-warning-header',
		defaultMessage: 'Version could not be committed for unknown reasons.',
		description: 'Message to show in the header for unknown commit failures',
	},
	defaultWarningContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.versions.default-warning-content',
		defaultMessage: 'Please contact your administrator.',
		description: 'Message to show in the content for unknown commit failures',
	},
	apiExceptionContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.versions.api-exception-content',
		defaultMessage: '{messages}',
		description:
			'Message to show when unknown error occured, but we can show API exception messages',
	},
	apiExceptionHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.versions.api-exception-header',
		defaultMessage: 'Version could not be committed',
		description:
			'Message to show when unknown error occured, but we can show API exception messages',
	},
});
