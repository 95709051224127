import isNil from 'lodash/isNil';
import { UNASSIGNED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { Option, OptionGroup } from './types.tsx';

const nameComparator = (a?: string, b?: string) => {
	if (a === b) return 0;

	if (isNil(a)) return 1;
	if (isNil(b)) return -1;

	const aLower = a.toLowerCase();
	const bLower = b.toLowerCase();

	if (aLower === UNASSIGNED_GROUP) return 1;
	if (bLower === UNASSIGNED_GROUP) return -1;

	if (aLower === bLower) return 0;
	return aLower > bLower ? 1 : -1;
};

export const optionSortComparator = <Value,>(
	option1: Option<Value>,
	option2: Option<Value>,
): number => nameComparator(option1.name, option2.name);

export const optionGroupSortComparator = <Value,>(
	optionGroup1: OptionGroup<Value>,
	optionGroup2: OptionGroup<Value>,
) => {
	// Option groups are clustered by their sort value, and sorted by their name.
	// Default sort value is zero.
	const sortValue1 = optionGroup1.sortValue || 0;
	const sortValue2 = optionGroup2.sortValue || 0;

	if (sortValue1 === sortValue2) return nameComparator(optionGroup1.name, optionGroup2.name);
	return sortValue2 - sortValue1;
};
