import {
	getAssigneeList,
	getAssigneesById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees/index.tsx';
import { getSelectedIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, StateProps>({
	selectedIssues: getSelectedIssues,
	assignees: getAssigneeList,
	assigneesById: getAssigneesById,
});
