import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	releaseMenuTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.release.release-menu-title',
		defaultMessage: 'Choose release',
		description: 'This is a title for release menu',
	},
	releaseSearchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.release.release-search-placeholder',
		defaultMessage: 'Find releases...',
		description: 'This is a placeholder for search',
	},
});
