import { memo } from 'react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useParentIndicator } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/controller/hooks.tsx';
import type { Props } from './types.tsx';

/** Returns the issue row background. */
export const BackgroundProvider = memo(({ issue, index, isOptimized, children }: Props) => {
	const parentIndicator = useParentIndicator({ id: issue?.id, index });

	const isEven = issue?.rootIndex === undefined ? false : issue.rootIndex % 2 === 0;

	if (parentIndicator)
		return children({
			base: parentIndicator.blocked
				? token('color.background.danger', colors.R50)
				: token('color.background.selected', colors.B50),
		});

	if (!issue)
		return children({
			base: 'transparent',
		});

	if (isOptimized)
		return children({
			base: isEven ? 'transparent' : token('color.background.accent.gray.subtlest', colors.N20),
			hover: token('elevation.surface.sunken', '#f7f8f9'),
		});

	return children({
		base: isEven
			? token('elevation.surface', colors.N0)
			: token('color.background.accent.gray.subtlest', colors.N20),
		hover: token('elevation.surface.sunken', '#f7f8f9'),
	});
});
