import React from 'react';
import { getWeek, addDays, isSameDay, startOfDay } from 'date-fns';
import * as R from 'ramda';
import { useIntl } from '@atlassian/jira-intl';
import {
	shortDateFormat,
	weekdayFormatLong,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { useDateFormatter } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import {
	ONE_DAY,
	startOfUtcDay,
	dateDiffFromUTC,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import {
	getDayUnits,
	getMonthUnits,
	getQuarterUnits,
	getYearUnits,
	getTodayDate,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/index.tsx';
import { calculateQuarter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/quarter/index.tsx';
import {
	calculateFinancialYear,
	formatFY,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/year/index.tsx';
import { CUSTOM_DATE_RANGES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import messages from '../messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '../styles.module.css';
import {
	getTimeUnitsBarTopUnit,
	getTimeUnitsBarBottomUnit,
	testId,
	isWeekendDay,
} from '../utils.tsx';
import type { Props } from './types.tsx';

export default function StaticAxisUnitsBar({ timelineRange, fiscalMonth }: Props) {
	const { formatTimestamp } = useDateFormatter();
	const { formatMessage } = useIntl();
	const { start: timelineRangeStart, end: timelineRangeEnd } = timelineRange;

	const daysInRange = (timelineRangeEnd - timelineRangeStart) / ONE_DAY;
	const dayUnits = getDayUnits(timelineRangeStart, daysInRange);

	let topBar;
	let bottomBar;

	// the header bar of custom date ranges up to 14 days is similar to the infinite axis "weeks" header bar
	// Top bar:    | MMM YYYY                |
	// Bottom bar: | D D D D D D D D D ... D |
	if (dayUnits.length <= CUSTOM_DATE_RANGES.SMALL) {
		// top bar header
		const monthUnits = getMonthUnits(timelineRangeStart, timelineRangeEnd);
		topBar = monthUnits.map(({ startTime, widthPercentage }, index) => {
			const isCurrentYear = new Date().getUTCFullYear() === new Date(startTime).getUTCFullYear();
			const monthLabel = formatTimestamp(startTime, { month: 'short', timeZone: 'UTC' });
			const yearLabel = formatTimestamp(startTime, { year: '2-digit', timeZone: 'UTC' });
			return getTimeUnitsBarTopUnit({
				index,
				label: `${monthLabel}${isCurrentYear ? '' : ` ’${yearLabel}`}`,
				offset: 0,
				width: widthPercentage,
			});
		});

		// bottom bar header
		let width = 0;
		bottomBar = dayUnits.map(({ startTime, widthPercentage }, index) => {
			const startDate = new Date(startTime);
			const isToday = isSameDay(
				startOfDay(startDate),
				startOfDay(getTodayDate().getTime() - dateDiffFromUTC() * ONE_DAY),
			);
			// Monday DD/MM/YYYY
			const tooltipContent = `${formatTimestamp(startDate.getTime(), weekdayFormatLong)}`;
			const unit = getTimeUnitsBarBottomUnit({
				index,
				isToday,
				isWeekendDay: isWeekendDay(startDate),
				label: formatTimestamp(startTime, {
					day: 'numeric',
					timeZone: 'UTC',
				}),
				offset: width,
				showRightBorder: false,
				showLeftBorder: index > 0 && startDate.getUTCDay() === 1,
				width: widthPercentage,
				tooltipContent,
			});
			width += widthPercentage;
			return unit;
		});
	}

	// the header bar of custom date ranges up to 93 days is similar to the infinite axis "months" header bar
	// Top bar:    | MMM YYYY                     |
	// Bottom bar: | D      D      D  ...  D      |
	else if (dayUnits.length <= CUSTOM_DATE_RANGES.MEDIUM) {
		const monthUnits = getMonthUnits(timelineRangeStart, timelineRangeEnd);
		topBar = monthUnits.map(({ startTime, widthPercentage }, index) => {
			const isCurrentYear = new Date().getUTCFullYear() === new Date(startTime).getUTCFullYear();
			const monthLabel = formatTimestamp(startTime, { month: 'short', timeZone: 'UTC' });
			const yearLabel = formatTimestamp(startTime, { year: '2-digit', timeZone: 'UTC' });
			return getTimeUnitsBarTopUnit({
				index,
				label: `${monthLabel}${isCurrentYear ? '' : ` ’${yearLabel}`}`,
				offset: 0,
				width: widthPercentage,
			});
		});

		let dateCursor: number;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const bottomUnits = dayUnits.reduce<Record<string, any>>(
			(acc, { startTime, widthPercentage }, index) => {
				const startDate = new Date(startTime);
				if (index > 0 && startDate.getUTCDay() !== 1) {
					acc[dateCursor].widthPercentage += widthPercentage;
				} else {
					dateCursor = startTime;
					acc[dateCursor] = {
						startTime,
						widthPercentage,
					};
				}
				return acc;
			},
			{},
		);

		let width = 0;
		bottomBar = R.values(bottomUnits).map(({ startTime, widthPercentage }, index) => {
			const startDate = new Date(startTime);
			const isMonday = startDate.getUTCDay() === 1;
			// Week X DD/MM/YYYY to DD/MM/YYYY
			const tooltipContent = formatMessage(messages.week, {
				weekNumber: getWeek(startDate.getTime()),
				firstDay: formatTimestamp(startDate.getTime(), shortDateFormat),
				lastDay: formatTimestamp(addDays(startDate, 6).getTime(), shortDateFormat),
			});
			const unit = getTimeUnitsBarBottomUnit({
				alignTextToLeft: true,
				index,
				isToday:
					isMonday &&
					isSameDay(startOfUtcDay(startDate.getTime()), startOfUtcDay(getTodayDate().getTime())),
				label: isMonday ? startDate.getUTCDate() : '',
				offset: width,
				showLeftPadding: false,
				showRightBorder: false,
				width: widthPercentage,
				tooltipContent,
			});
			width += widthPercentage;
			return unit;
		});
	}

	// the header bar of custom date ranges up to 364 days is similar to the infinite axis "quarters" header bar
	// Top bar:    | QQ YYYY         |
	// Bottom bar: | MMM | MMM | MMM |
	else if (dayUnits.length <= CUSTOM_DATE_RANGES.LARGE) {
		const yearUnits = getYearUnits(timelineRangeStart, timelineRangeEnd, fiscalMonth);
		topBar = yearUnits.map(({ startTime, widthPercentage }, index) => {
			const startDate = new Date(startTime);
			return getTimeUnitsBarTopUnit({
				index,
				label:
					fiscalMonth !== 1
						? formatMessage(messages.financialYearPrefix, {
								financialYear: formatFY(calculateFinancialYear(startTime, fiscalMonth)),
							})
						: startDate.getUTCFullYear(),
				offset: 0,
				width: widthPercentage,
			});
		});

		const quarterUnits = getQuarterUnits(timelineRangeStart, timelineRangeEnd, fiscalMonth);
		let width = 0;
		bottomBar = quarterUnits.map(({ startTime, widthPercentage }, index) => {
			const startDate = new Date(startTime);
			const quarterNumber = calculateQuarter(startDate.getUTCMonth() + 1, fiscalMonth);
			let quarterStartMonth = fiscalMonth + (quarterNumber - 1) * 3;
			let quarterEndYear = startDate.getUTCFullYear();
			if (quarterStartMonth > 12) {
				quarterStartMonth %= 12;
			}
			if (quarterStartMonth > 10) {
				quarterEndYear++;
			}
			const quarterEndMonth =
				quarterStartMonth <= 10 ? quarterStartMonth + 2 : (quarterStartMonth + 2) % 12;
			const quarterStartTime = new Date(
				`${startDate.getUTCFullYear()}-${`0${quarterStartMonth}`.slice(-2)}-01T00:00:00Z`,
			);
			const quarterEndTime = new Date(
				`${quarterEndYear}-${`0${quarterEndMonth}`.slice(-2)}-01T00:00:00Z`,
			);
			const quarterStartMonthShortString = formatTimestamp(quarterStartTime.getTime(), {
				month: 'short',
				timeZone: 'UTC',
			});
			const quarterEndMonthShortString = formatTimestamp(quarterEndTime.getTime(), {
				month: 'short',
				timeZone: 'UTC',
			});
			const firstDay = formatTimestamp(startDate.getTime(), shortDateFormat);
			const nextQuarterStartMonth =
				quarterStartMonth <= 9 ? quarterStartMonth + 3 : (quarterStartMonth + 3) % 12;

			const lastDay = formatTimestamp(
				new Date(
					`${quarterEndYear}-${`0${nextQuarterStartMonth}`.slice(-2)}-01T00:00:00Z`,
				).getTime() - ONE_DAY,
				shortDateFormat,
			);
			// Quarter X DD/MM/YYYY to DD/MM/YYYY, Financial Year YYYY
			// Quarter X DD/MM/YYYY to DD/MM/YYYY
			const tooltipContent =
				fiscalMonth !== 1
					? formatMessage(messages.financialYearQuarter, {
							quarterNumber,
							firstDay,
							lastDay,
							financialYear: calculateFinancialYear(startTime, fiscalMonth),
						})
					: formatMessage(messages.calendarYearQuarter, {
							quarterNumber,
							firstDay,
							lastDay,
						});
			const unit = getTimeUnitsBarBottomUnit({
				index,
				label: formatMessage(messages.quarterDescriptorLong, {
					quarterNumber,
					quarterStartMonthShortString,
					quarterEndMonthShortString,
				}),
				offset: width,
				showRightBorder: false,
				showLeftBorder: index !== 0,
				width: widthPercentage,
				tooltipContent,
			});
			width += widthPercentage;
			return unit;
		});
	}

	// the header bar of custom date ranges higher than 364 days is similar to the infinite axis "years" header bar
	// Top bar:    | YYYY              |
	// Bottom bar: | Q1 | Q2 | Q3 | Q4 |
	else {
		const yearUnits = getYearUnits(timelineRangeStart, timelineRangeEnd, fiscalMonth);
		topBar = yearUnits.map(({ startTime, widthPercentage }, index) =>
			getTimeUnitsBarTopUnit({
				index,
				label:
					fiscalMonth !== 1
						? formatFY(calculateFinancialYear(startTime, fiscalMonth))
						: new Date(startTime).getUTCFullYear(),
				offset: 0,
				width: widthPercentage,
			}),
		);

		const quarterUnits = getQuarterUnits(timelineRangeStart, timelineRangeEnd, fiscalMonth);
		let width = 0;
		bottomBar = quarterUnits.map(({ startTime, widthPercentage }, index) => {
			const startDate = new Date(startTime);
			const firstDay = formatTimestamp(startDate.getTime(), shortDateFormat);
			const quarterNumber = calculateQuarter(startDate.getUTCMonth() + 1, fiscalMonth);
			let quarterStartMonth = fiscalMonth + (quarterNumber - 1) * 3;
			let quarterEndYear = startDate.getUTCFullYear();
			if (quarterStartMonth > 12) {
				quarterStartMonth %= 12;
			}
			if (quarterStartMonth > 10) {
				quarterEndYear++;
			}
			const nextQuarterStartMonth =
				quarterStartMonth <= 9 ? quarterStartMonth + 3 : (quarterStartMonth + 3) % 12;

			const lastDay = formatTimestamp(
				new Date(
					`${quarterEndYear}-${`0${nextQuarterStartMonth}`.slice(-2)}-01T00:00:00Z`,
				).getTime() - ONE_DAY,
				shortDateFormat,
			);
			// Quarter X DD/MM/YYYY to DD/MM/YYYY, Financial Year YYYY
			// Quarter X DD/MM/YYYY to DD/MM/YYYY
			const tooltipContent =
				fiscalMonth !== 1
					? formatMessage(messages.financialYearQuarter, {
							quarterNumber,
							firstDay,
							lastDay,
							financialYear: calculateFinancialYear(startTime, fiscalMonth),
						})
					: formatMessage(messages.calendarYearQuarter, {
							quarterNumber,
							firstDay,
							lastDay,
						});

			const unit = getTimeUnitsBarBottomUnit({
				index,
				label: formatMessage(messages.quarterDescriptorShort, { quarterNumber }),
				offset: width,
				// right border on each quarter
				showRightBorder: false,
				showLeftBorder: index > 0 && quarterNumber === 1,
				width: widthPercentage,
				tooltipContent,
			});
			width += widthPercentage;
			return unit;
		});
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.timeUnitsBar}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div data-testid={`${testId}.top`} className={styles.timeUnitsBarTop}>
				{topBar}
			</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div data-testid={`${testId}.bottom`} className={styles.timeUnitsBarBottom}>
				{bottomBar}
			</div>
		</div>
	);
}
