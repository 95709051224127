import {
	getUserPickerOptionsUserList,
	getUserPickerOptionsUserListById,
	getUserPickerUIState,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export default createStructuredSelector<State, OwnProps, StateProps>({
	userList: getUserPickerOptionsUserList,
	userListById: getUserPickerOptionsUserListById,
	userPickerUIState: getUserPickerUIState,
});
