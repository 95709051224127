.row > td {
	padding: 0 var(--jpo-common-base-spacing);
}

.customFieldValuesGroup {
	position: relative;
	display: flex;
	align-items: center;

	& > .icon {
		padding-top: 2px;
	}
}

.groupTitle {
	flex: 1 1 0;
	min-width: 85%;
	& input {
		cursor: initial;
	}
}

.customFieldValueSelect {
	margin-left: 36px;
}
