import React, { type ComponentType, Component } from 'react';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query.tsx';
import type { DateFieldTypes, Props, State } from './types.tsx';
import View from './view.tsx';

export default connect(mapStateToProps, mapDispatchToProps)(View);

export const createDateCell = (DateCellComponent: ComponentType<Props>, type: DateFieldTypes) =>
	// eslint-disable-next-line jira/react/no-class-components
	class extends Component<Props, State> {
		render() {
			return <DateCellComponent {...this.props} type={type} />;
		}
	};
