import {
	RESET_SEARCH_RESULTS,
	SET_ACTIVE_SEARCH_RESULT_INDEX,
	SET_SEARCH_QUERY,
	TOGGLE_ISSUE_SEARCH,
	type ResetSearchResults,
	type SetActiveSearchResultIndex,
	type SetSearchQuery,
	type ToggleIssueSearch,
} from './actions.tsx';
import type { State } from './types.tsx';

type Actions = ResetSearchResults | SetSearchQuery | SetActiveSearchResultIndex | ToggleIssueSearch;

const initialState: State = {
	activeSearchResultIndex: 1,
	isSearchBoxOpen: false,
	searchQuery: '',
};

const reducer = (state: State = initialState, action: Actions): State => {
	switch (action.type) {
		case RESET_SEARCH_RESULTS: {
			return initialState;
		}

		case SET_ACTIVE_SEARCH_RESULT_INDEX: {
			return {
				...state,
				activeSearchResultIndex: action.payload,
			};
		}

		case SET_SEARCH_QUERY: {
			return {
				...state,
				searchQuery: action.payload,
			};
		}

		case TOGGLE_ISSUE_SEARCH: {
			return {
				...state,
				isSearchBoxOpen: action.payload,
			};
		}

		default:
			return state;
	}
};

export default reducer;
