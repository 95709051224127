import { useEffect, useRef } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	getZoomLevel,
	getContainerDuration,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/utils/index.tsx';
import { useHorizontalScrolling } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import type { Props } from './types.tsx';

/**
 * Connects to the redux store and provide the horizontal scrolling when the timeScale state is set to be one of (weeks|months|quaters|years)
 */
export default function ZoomLevelSync({ timeScale }: Props) {
	const timeScaleselection = timeScale.mode;
	const zoomLevel = getZoomLevel(timeScaleselection);
	const [{ container, viewport }, { changeZoomLevel, setViewportOffset, backToToday }] =
		useHorizontalScrolling();

	// We want to avoid re-render on change of offset
	const nextOffsetRef = useRef<number>(0);

	const nextContainerDuration = zoomLevel !== undefined ? getContainerDuration(zoomLevel) : 0;

	const nextMsPerPx = nextContainerDuration / container.width;
	const nextViewportDuration = viewport.width * nextMsPerPx;
	nextOffsetRef.current = nextViewportDuration * 0.15 * -1;

	// We want to avoid re-render on resize of container after container width has been set
	const containerHasWidth: boolean = container.width > 0;

	useEffect(() => {
		changeZoomLevel(zoomLevel);

		if (ff('com.atlassian.rm.jpo.transposition.m2')) {
			backToToday();
		} else {
			setViewportOffset(nextOffsetRef.current, 'zoomLevelChange');
		}
	}, [zoomLevel, changeZoomLevel, backToToday, setViewportOffset, containerHasWidth]);

	return null;
}
