import React, { Component } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalHeader, ModalTitle, ModalBody } from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntl } from '@atlassian/jira-intl';
import { getIssueOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/utils.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { MODAL_DIALOG_WIDTH, MOVE_ISSUES } from '../../types.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import { getParentSelectOptions } from './utils.tsx';

// eslint-disable-next-line jira/react/no-class-components
class SetParentDialog extends Component<Props> {
	getCurrentValue = (): {
		currentValue: string;
		currentIssueTypeIcon?: string;
	} => {
		const {
			intl: { formatMessage },
			selectedIssues,
			issues,
			issueTypesById,
			projectsById,
		} = this.props;

		const firstValue = selectedIssues[0].parent || '';
		const isSameValue = !selectedIssues.some((issue) => (issue.parent || '') !== firstValue);

		if (isSameValue && !firstValue) {
			return { currentValue: formatMessage(commonMessages.none) };
		}

		const parentIssue = issues.find((issue) => issue.id === firstValue);
		if (isSameValue && parentIssue) {
			const issue = getIssueOption(parentIssue, issueTypesById, projectsById);
			return {
				currentValue: issue.label,
				currentIssueTypeIcon: issue.icon,
			};
		}
		return { currentValue: formatMessage(messages.variousParents) };
	};

	render() {
		const {
			bulkActionSuccess,
			bulkUpdateAttribute,
			intl,
			issueTypesById,
			toggleModalDialog,
			selectedIssues,
			UpdateAttributeForm,
			isSomeSelectedIssuesFromTMP,
			projectsById,
			issues,
			isSelectedIssuesFromMultipleProjects,
		} = this.props;
		const { formatMessage } = intl;
		const { currentValue, currentIssueTypeIcon } = this.getCurrentValue();
		return (
			<ShortcutScope>
				<ModalDialog
					autoFocus
					shouldScrollInViewport
					onClose={toggleModalDialog}
					width={MODAL_DIALOG_WIDTH}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-parent"
				>
					<ModalHeader>
						<ModalTitle>{formatMessage(messages.header)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<UpdateAttributeForm
							updatedAttributeType={MOVE_ISSUES}
							options={getParentSelectOptions(
								intl,
								issueTypesById,
								selectedIssues,
								issues,
								projectsById,
								isSomeSelectedIssuesFromTMP,
								isSelectedIssuesFromMultipleProjects,
							)}
							currentValue={currentValue}
							currentIssueTypeIcon={currentIssueTypeIcon}
							warningMessage={
								isSomeSelectedIssuesFromTMP && isSelectedIssuesFromMultipleProjects
									? formatMessage(messages.crossProjectReparentingWarning)
									: undefined
							}
							onCancel={toggleModalDialog}
							onSubmit={(formData: { [key: string]: string }, analyticsEvent: UIAnalyticsEvent) => {
								bulkUpdateAttribute(formData);
								toggleModalDialog();
								bulkActionSuccess(analyticsEvent);
							}}
						/>
					</ModalBody>
				</ModalDialog>
			</ShortcutScope>
		);
	}
}

export default injectIntl(SetParentDialog);
