import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { select, put, call, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import { fg } from '@atlassian/jira-feature-gating';
import type * as ApiTypes from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { parseQueryParams } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import { getIsSamplePlan, getIsCrossTeamPlanning } from '../../query/plan/index.tsx';
import { getFieldColumnsViewSettingsByViewMode } from '../../query/view-settings/index.tsx';
import {
	getViews,
	getDefaultView,
	getViewById,
	getPrunedActiveView,
	getActiveView,
} from '../../query/views/index.tsx';
import { reset as resetViewSettings } from '../../state/domain/view-settings/actions.tsx';
import type { IssueColumnState } from '../../state/domain/view-settings/field-columns/types.tsx';
import type { ViewSettingsState } from '../../state/domain/view-settings/types.tsx';
import * as actions from '../../state/domain/views/actions.tsx';
import type { ModifyViewPayload } from '../../state/domain/views/actions.tsx';
import { UNTITLED_VIEW_ID, type View, type ModifiedView } from '../../state/domain/views/types.tsx';
import { setRenameSaving } from '../../state/ui/settings/views/row/actions.tsx';
import * as uiActions from '../../state/ui/top/view-bar/actions.tsx';
import { getViewId } from '../../util/urls.tsx';
import batch from '../batch/index.tsx';
import { genericError } from '../errors/index.tsx';
import { withErrorTolerated } from '../http/index.tsx';
import { fetchHistoricalDoneIssues } from '../issue/index.tsx';
import * as utils from './utils.tsx';

const SWITCH_VIEW = 'command.views.SWITCH_VIEW';
const MODIFY_VIEW = 'command.views.MODIFY_VIEW';
const SAVE_VIEW = 'command.views.SAVE_VIEW';
const SAVE_VIEW_AS = 'command.views.SAVE_VIEW_AS';
const DISCARD_CHANGES = 'command.views.DISCARD_CHANGES';
const DELETE_VIEW = 'command.views.DELETE_VIEW';
const FETCH_HISTORICAL_ISSUE_FOR_VIEW = 'command.views.FETCH_HISTORICAL_ISSUE_FOR_VIEW';
const MARK_AS_DEFAULT_VIEW = 'command.views.MARK_AS_DEFAULT_VIEW';
const APPLY_MODIFICATIONS_FOR_INACTIVE_VIEWS =
	'command.views.APPLY_MODIFICATIONS_FOR_INACTIVE_VIEWS';
const UPDATE_ROADMAP_VIEW_ID = 'command.views.UPDATE_ROADMAP_VIEW_ID';

export type ViewId = View['id'];

type SwitchViewPayload = {
	id: ViewId;
};

type DiscardChangesPayload = {
	id: ViewId;
};

export type SaveViewPayload = {
	overwrite: boolean;
	view?: View;
	renameOnly?: boolean | null | undefined;
};

export type SaveViewAsPayload = {
	name: string;
	asDefault: boolean;
	duplicateSection?: (sourceSectionId: string, targetSectionId: string) => void;
	sourceViewId?: number;
};

type DeleteViewPayload = {
	id: ViewId;
	shouldCallAPI: boolean;
};

type MarkAsDefaultViewPayload = {
	id: ViewId;
};

export type SwitchViewAction = {
	type: typeof SWITCH_VIEW;
	payload: SwitchViewPayload;
};

type ModifyViewAction = {
	type: typeof MODIFY_VIEW;
	payload: ModifyViewPayload;
};

type DiscardChangesAction = {
	type: typeof DISCARD_CHANGES;
	payload: DiscardChangesPayload;
};

type SaveViewAction = {
	type: typeof SAVE_VIEW;
	payload: SaveViewPayload;
};

type SaveViewAsAction = {
	type: typeof SAVE_VIEW_AS;
	payload: SaveViewAsPayload;
};

type DeleteViewAction = {
	type: typeof DELETE_VIEW;
	payload: DeleteViewPayload;
};

type MarkAsDefaultViewAction = {
	type: typeof MARK_AS_DEFAULT_VIEW;
	payload: MarkAsDefaultViewPayload;
};

type FetchHistoricalIssuesForViewAction = {
	type: typeof FETCH_HISTORICAL_ISSUE_FOR_VIEW;
};

type ApplyModificationsForInactiveViews = {
	type: typeof APPLY_MODIFICATIONS_FOR_INACTIVE_VIEWS;
	payload: ApiTypes.SavedViewsInfo;
};

type UpdateRoadmapViewIdAction = {
	type: typeof UPDATE_ROADMAP_VIEW_ID;
};

export const switchView = (id: ViewId): SwitchViewAction => ({
	type: SWITCH_VIEW,
	payload: { id },
});

export const modifyView = (payload: ModifyViewPayload): ModifyViewAction => ({
	type: MODIFY_VIEW,
	payload,
});

export const discardChanges = (id: ViewId): DiscardChangesAction => ({
	type: DISCARD_CHANGES,
	payload: { id },
});

export const saveView = (payload: SaveViewPayload): SaveViewAction => ({
	type: SAVE_VIEW,
	payload,
});

export const saveViewAs = (payload: SaveViewAsPayload): SaveViewAsAction => ({
	type: SAVE_VIEW_AS,
	payload,
});

export const deleteView = (id: ViewId, shouldCallAPI = true): DeleteViewAction => ({
	type: DELETE_VIEW,
	payload: { id, shouldCallAPI },
});

export const markAsDefaultView = (id: ViewId): MarkAsDefaultViewAction => ({
	type: MARK_AS_DEFAULT_VIEW,
	payload: { id },
});

export const fetchHistoricalIssueForView = (): FetchHistoricalIssuesForViewAction => ({
	type: FETCH_HISTORICAL_ISSUE_FOR_VIEW,
});

export const applyModificationsForInactiveViews = (
	savedViewsInfo: ApiTypes.SavedViewsInfo,
): ApplyModificationsForInactiveViews => ({
	type: APPLY_MODIFICATIONS_FOR_INACTIVE_VIEWS,
	payload: savedViewsInfo,
});

export const updateRoadmapViewId = (): UpdateRoadmapViewIdAction => ({
	type: UPDATE_ROADMAP_VIEW_ID,
});

/**
 * Apply the view settings (preferences)
 * This is called by populateView()
 * @param {ViewSettingsState} viewSettings view preferences to apply
 * @returns new preferences if the preferences is changed after population (e.g. due to migration, new releases...)
 * @returns null if the preferences is not changed
 */
export function* populateViewSettings(
	viewSettings: ViewSettingsState,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-invalid-void-type
): Generator<Effect, ViewSettingsState | null | void, any> {
	const viewSettingsData = () => viewSettings;

	yield put(resetViewSettings(viewSettingsData()));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* populateReportViewSettings(viewSettings: any): Generator<Effect, void, any> {
	yield call(populateViewSettings, yield call(utils.getReportViewSettings, viewSettings));
}

/**
 * Apply the view settings
 * This is called when we:
 *  - Apply active view in initializing
 *  - Discarding changes of the view
 *  - Switching to another view
 * @param view view to apply
 * @returns view with updated preferences if the preferences is changed after population (e.g. due to migration, new releases...)
 * @returns null if the preferences is not changed
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* populateView(view: View): Generator<Effect, View | null | undefined, any> {
	const changedPrefs = yield call(populateViewSettings, view.preferences);

	if (changedPrefs) {
		const mergePrefs: (arg1: ViewSettingsState) => ViewSettingsState = R.mergeLeft(changedPrefs);
		const preferences = mergePrefs(view.preferences);

		return R.mergeLeft({ preferences })(view);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doSwitchView(action: SwitchViewAction): Generator<Effect, void, any> {
	const { id: viewId } = action.payload;
	yield* batch(function* () {
		const views: ReturnType<typeof getViews> = yield select(getViews);
		const selected = views.find(({ id }) => id === viewId);

		if (!selected) {
			return;
		}

		const updated: View | null = yield call(populateView, selected);

		if (updated) {
			yield put(actions.updateView(updated));
		}

		yield put(actions.setActiveView(selected));
		yield call(utils.replaceURLForViewId, viewId);
	});
	yield call(utils.pushActiveViewId, viewId);
	yield put(fetchHistoricalIssueForView());
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* initializeViews(payload: ApiTypes.SavedViewsInfo): Generator<Effect, void, any> {
	if (!payload || !payload.savedViews) {
		return;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return yield call(withErrorTolerated, function* (): Generator<Effect, void, any> {
		const isSample = yield select(getIsSamplePlan);
		const isCrossTeamPlanning = utils.isCrossTeamPlanningTemplateExperimentEnabled()
			? yield select(getIsCrossTeamPlanning)
			: undefined;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const { vid } = parseQueryParams(window.location.search);
		const views = yield call(utils.initializeViews, payload, {
			forSamplePlan: isSample,
			forCrossTeamPlanning: isCrossTeamPlanning,
		});
		const active = views.find(R.prop('active'));
		yield* batch(function* () {
			yield put(actions.reset(views));
			const updated: View | null | undefined = yield call(populateView, active);

			if (updated) {
				yield put(actions.updateView(updated));
			}
		});
		const viewForVid = views.find((view: ApiTypes.View) => view.id === Number(vid));

		if (isSample) {
			yield call(utils.replaceURLForSamplePlan);
		}

		if (viewForVid && active.id !== viewForVid.id) {
			yield call(doSwitchView, switchView(viewForVid.id));
		} else {
			if (fg('plans_timeline_-_always_persist_vid_in_url')) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				if (window.location.pathname.indexOf('timeline') > -1) {
					yield call(utils.replaceURLForViewId, active.id);
				}
			} else {
				yield call(utils.replaceURLForViewId, active.id);
			}

			// doSwitchView also fetches historical issues so we want to fetch it only when we are not switching views.
			yield put(fetchHistoricalIssueForView());
		}
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchSwitchView(): Generator<Effect, void, any> {
	yield takeLatest(SWITCH_VIEW, doSwitchView);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doModifyView(action: ModifyViewAction): Generator<Effect, void, any> {
	yield put(actions.modifyView(action.payload));

	const views: View[] = yield select(getViews);
	const modifiedView = views.find(({ id }) => id === action.payload.id);
	if (!modifiedView) {
		throw new Error('modified view not found');
	}

	yield call(utils.pushModifiedView, modifiedView);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchModifyView(): Generator<Effect, void, any> {
	yield takeEvery(MODIFY_VIEW, doModifyView);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doDiscardChanges(action: DiscardChangesAction): Generator<Effect, void, any> {
	const viewId = action.payload.id;
	const view: View | null | undefined = (yield select(getViews)).find(
		(one: View) => one.id === viewId,
	);

	if (!view) {
		throw new Error('view to discard not found');
	}

	if (view.original) {
		const updated: View | null | undefined = yield call(
			populateView,
			R.assoc('active', true)(view.original),
		);

		if (updated) {
			yield put(actions.updateView(updated));
		}
	}

	yield put(actions.discardChanges(view.id));
	yield call(utils.deleteModifiedView, view.id);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchDiscardChanges(): Generator<Effect, void, any> {
	yield takeEvery(DISCARD_CHANGES, doDiscardChanges);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doSaveView(action: SaveViewAction): Generator<Effect, void, any> {
	yield put(setRenameSaving(true));

	// when saving from the settings page, we send the source view in the payload
	// when saving from roadmap, we just get the active view
	const active: View = action.payload.view || (yield select(getPrunedActiveView));

	if (!active.modified && !action.payload.renameOnly) {
		throw new Error('cannot save unmodifed view');
	}

	try {
		const version =
			action.payload.overwrite && active.original ? active.original.version : active.version;
		const { version: nextVersion } = yield call(
			utils.updateView,
			R.merge(active, { version }),
			action.payload.renameOnly,
		);

		yield put(actions.saveView(active.id, nextVersion, active.name));

		if (!action.payload.renameOnly) {
			// only delete the view mods if it's a full save, not just a rename
			yield call(utils.deleteModifiedView, active.id);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		if (err instanceof utils.UpdateErrorConflicted) {
			/*
                Fetch the latest version of the view to be used
                when user decide to discard / overwrite the changes
            */
			const view = yield call(utils.fetchSavedView, active.id);
			yield put(
				actions.updateView({
					id: active.id,
					original: view,
				}),
			);

			yield put(
				uiActions.addError(new utils.UpdateErrorConflicted({ ...err, modifiedBy: view.updatedBy })),
			);
		} else if (err instanceof utils.UpdateErrorNotExists) {
			yield put(uiActions.addError(err));
		} else if (err instanceof utils.SaveAsErrorDuplicateView) {
			yield put(uiActions.addError(err));
		} else {
			yield put(
				genericError({
					message: err,
					stackTrace: err.stack,
				}),
			);
		}
	}

	yield put(setRenameSaving(false));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchSaveView(): Generator<Effect, void, any> {
	yield takeEvery(SAVE_VIEW, doSaveView);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doSaveViewAs(action: SaveViewAsAction): Generator<Effect, void, any> {
	const { name, asDefault: isDefault, duplicateSection, sourceViewId } = action.payload;

	// The choice to perform certain actions depends on whether we're
	// trying to 'save as' from the settings page or the roadmap page.
	// We only receive the sourceViewId arg from the settings page.
	const fromSettingsPage = !!sourceViewId;
	const currentView = isDefined(sourceViewId)
		? yield select(getViewById, sourceViewId)
		: yield select(getPrunedActiveView);
	const views = yield select(getViews);
	const currentJiraUser = yield call(utils.getCurrentUserInViewFormat);

	// Need to find unique negative number for temporary id
	const tempId = (function _r(id: number): number {
		if (views.some((one: View) => one.id === id)) {
			return _r(id - 1);
		}
		return id;
	})(-1);

	const newView: View = {
		id: tempId,
		name,
		preferences: currentView.preferences,
		persisted: currentView.persisted,
		version: 0,
		isDefault,
		active: false,
		modified: false,
		original: null,
		createdBy: currentJiraUser,
		updatedAt: new Date(),
		updatedBy: currentJiraUser,
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	yield call(withErrorTolerated, function* (): Generator<Effect, undefined, any> {
		yield put(uiActions.setSaveViewAsDialogStatus('SAVING'));
		const { id: actualId, errorCode } = yield call(utils.createView, newView);
		if (errorCode === 'DUPLICATE_VIEW') {
			const error = new utils.SaveAsErrorDuplicateView({
				viewId: tempId,
				viewName: currentView.name,
				newName: newView.name,
			});
			yield put(uiActions.addError(error));
			yield put(uiActions.setSaveViewAsDialogStatus('OPEN'));
			return;
		}
		yield put(uiActions.setSaveViewAsDialogStatus('CLOSED'));

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		yield* batch(function* (): Generator<Effect, void, any> {
			yield put(actions.addView(newView));

			if (!fromSettingsPage || (fromSettingsPage && currentView.active)) {
				yield put(actions.setActiveView({ id: tempId }));
			}

			if (currentView.id === UNTITLED_VIEW_ID) {
				// removing the view if it is an "Untitled" one
				yield put(actions.removeView(currentView.id));
			} else {
				// removing the view if we're saving as from the settings page
				// (we can only achieve save as from settings if the view we
				// were renaming was deleted from under us)
				if (fromSettingsPage) {
					yield put(actions.removeView(currentView.id));
				}
				// otherwise discarding changes of the view
				yield put(actions.discardChanges(currentView.id));
			}
		});

		yield call(utils.deleteModifiedView, currentView.id);
		yield put(actions.changeId(tempId, actualId));

		if (duplicateSection) {
			utils.duplicateSectionState({
				sourceViewId: currentView.id,
				targetViewId: actualId,
				duplicateSection,
			});
		}

		// we want to switch to the new view if we're on the roadmap page
		// or if we're on the settings page and the currentView (now removed)
		// was the active view
		if (!fromSettingsPage || (fromSettingsPage && currentView.active)) {
			yield call(doSwitchView, switchView(actualId));
		}
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchSaveViewAs(): Generator<Effect, void, any> {
	yield takeEvery(SAVE_VIEW_AS, doSaveViewAs);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doDeleteView(action: DeleteViewAction): Generator<Effect, void, any> {
	const { id: viewId } = action.payload;
	const views = yield select(getViews);
	const viewToRemove = views.find(({ id }: View) => id === viewId);
	const defaultView = yield select(getDefaultView);
	const { shouldCallAPI } = action.payload;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	yield call(withErrorTolerated, function* (): Generator<Effect, undefined, any> {
		if (shouldCallAPI && viewToRemove.id !== UNTITLED_VIEW_ID) {
			const deleteResponse = yield call(utils.deleteView, viewToRemove.id);

			if (isDefined(deleteResponse) && deleteResponse.errorCode === 'DEFAULT_VIEW') {
				yield put(actions.markAsDefaultView(viewToRemove.id));
				yield put(
					uiActions.addError(
						new utils.DeleteErrorDefaultView({
							viewId: viewToRemove.id,
							viewName: viewToRemove.name,
						}),
					),
				);
				return;
			}
		}

		yield call(doDiscardChanges, discardChanges(viewToRemove.id));
		yield put(actions.removeView(viewToRemove.id));
		if (viewToRemove.active) {
			yield call(doSwitchView, switchView(defaultView.id));
		}
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doFetchHistoricalIssuesForView(): Generator<Effect, void, any> {
	// we have to fetch historical issues only if the breakdown column is visible
	// TODO: [list view] double check if the fetch here needs to be based on view mode
	const fields = yield select(getFieldColumnsViewSettingsByViewMode);
	const hasBreakdown = fields.columns.find((column: IssueColumnState) => column.id === 'breakdown');
	const hasBreakdownByEstimate = fields.columns.find(
		(column: IssueColumnState) => column.id === 'progressByEstimation',
	);
	if (
		(hasBreakdown && hasBreakdown.isVisible === true) ||
		(hasBreakdownByEstimate && hasBreakdownByEstimate.isVisible === true)
	) {
		yield put(fetchHistoricalDoneIssues());
	}
}

export function* doApplyModificationsForInactiveViews(
	action: ApplyModificationsForInactiveViews, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	const { activeViewId, modifiedSavedViews } = action.payload;

	const modifiedInactiveViews: ModifiedView[] = [];

	for (const view of modifiedSavedViews) {
		// Apply modifications for only inactive views
		if (view.id !== activeViewId) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			modifiedInactiveViews.push(yield call(utils.prepareModifiedView, view as ModifiedView));
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	yield* batch(function* (): Generator<Effect, void, any> {
		for (const view of modifiedInactiveViews) {
			yield put(actions.modifyView(view));
		}
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchDeleteView(): Generator<Effect, void, any> {
	yield takeEvery(DELETE_VIEW, doDeleteView);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchFetchHistoricalIssuesForView(): Generator<Effect, void, any> {
	yield takeEvery(FETCH_HISTORICAL_ISSUE_FOR_VIEW, doFetchHistoricalIssuesForView);
}

export function* doMarkAsDefaultView(
	action: MarkAsDefaultViewAction, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	const { id: viewId } = action.payload;

	// marking this view as default in the reducer
	yield put(actions.markAsDefaultView(viewId));

	// marking this view as default in the backend
	yield call(utils.markAsDefaultView, {
		id: viewId,
		isDefault: true,
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchMarkAsDefaultView(): Generator<Effect, void, any> {
	yield takeEvery(MARK_AS_DEFAULT_VIEW, doMarkAsDefaultView);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchApplyModificationsForInactiveViews(): Generator<Effect, void, any> {
	yield takeEvery(APPLY_MODIFICATIONS_FOR_INACTIVE_VIEWS, doApplyModificationsForInactiveViews);
}

export function* doUpdateRoadmapViewId(): Generator<Effect, void, unknown> {
	if (getViewId() === undefined) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const activeView = (yield select(getActiveView)) as View | undefined;
		if (activeView) {
			yield call(utils.replaceURLForViewId, activeView.id);
		}
	}
}

export function* watchUpdateRoadmapViewAction(): Generator<Effect, void, unknown> {
	yield takeLatest(UPDATE_ROADMAP_VIEW_ID, doUpdateRoadmapViewId);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, void, any> {
	yield fork(watchSwitchView);
	yield fork(watchModifyView);
	yield fork(watchDiscardChanges);
	yield fork(watchSaveView);
	yield fork(watchSaveViewAs);
	yield fork(watchDeleteView);
	yield fork(watchMarkAsDefaultView);
	yield fork(watchFetchHistoricalIssuesForView);
	yield fork(watchApplyModificationsForInactiveViews);
	yield fork(watchUpdateRoadmapViewAction);
}
