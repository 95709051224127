import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	chooseGoal: {
		id: 'portfolio-3-portfolio.common.goal.goal-cell-select.choose-goal',
		defaultMessage: 'Choose a Goal',
		description: 'Label for unselected goal cell',
	},
	noGoals: {
		id: 'portfolio-3-portfolio.common.goal.goal-cell-select.no-goals',
		defaultMessage: 'There are no goals in this plan',
		description: 'Message to show when there is no goals configured',
	},
	noMatch: {
		id: 'portfolio-3-portfolio.common.goal.goal-cell-select.no-match',
		defaultMessage: 'No results match your search',
		description: 'Message to show when there is no goals matching search query',
	},
});
