import compose from 'lodash/fp/compose';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query.tsx';
import type { StateProps, DispatchProps, OwnProps } from './types.tsx';
import ChangesTable, { withScrollTracking } from './view.tsx';

export default compose(
	withScrollTracking,
	// @ts-expect-error - TS2589 - Type instantiation is excessively deep and possibly infinite.
	connect<StateProps, DispatchProps, OwnProps, State>(mapStateToProps, mapDispatchToProps),
)(ChangesTable);
