import React from 'react';
import noop from 'lodash/noop';
import FixedDialog from '@atlassian/jira-portfolio-3-common/src/fixed-dialog/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { useHorizontalScrolling } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { SCHEDULE_MODE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { AsyncKanbanFlyout, AsyncScrumFlyout, preloadFlyouts } from './flyout/async.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const DIALOG_MIN_WIDTH = 530;

function SprintFlyout({
	isOpen,
	onToggle,
	sprint,
	plannedCapacity,
	plan,
	KanbanFlyout,
	ScrumFlyout,
	children,
	teamObject,
	isShowTimerangeInconsistent = false,
}: Props) {
	// for scrum sprint, we don't display flyout for past sprints
	const isScrum = sprint.schedulingMode !== SCHEDULE_MODE.kanban;
	const [{ viewport }] = useHorizontalScrolling();

	const flyoutProps = {
		sprint,
		plannedCapacity,
		teamObject,
		plan,
		onCloseIconClick: onToggle,
	};

	return (
		<FixedDialog
			minLeft={viewport.position.left}
			isOpen={isOpen}
			renderDialog={(element) => (
				<InlineDialog
					overflow="visible"
					isOpen
					onClose={onToggle}
					placement="bottom-start"
					shouldFlip
					closeOnScroll
					minWidth={DIALOG_MIN_WIDTH}
					content={
						isScrum ? (
							<ScrumFlyout
								{...flyoutProps}
								isShowTimerangeInconsistent={isShowTimerangeInconsistent}
							/>
						) : (
							<KanbanFlyout {...flyoutProps} />
						)
					}
				>
					{element}
				</InlineDialog>
			)}
			renderTrigger={(ref) => (
				<div
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					ref={ref as React.RefObject<HTMLDivElement>}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.toggle}
					tabIndex={0}
					onClick={onToggle}
					onKeyDown={(e) => e.key === 'Escape' && onToggle()}
					onMouseEnter={preloadFlyouts}
					role="button"
					data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprint-flyout.sprint-flyout-trigger.${
						(teamObject && teamObject.id) || ''
					}-${isScrum ? sprint.id || '' : sprint.startDate}`}
				>
					{children}
				</div>
			)}
		/>
	);
}

SprintFlyout.defaultProps = {
	KanbanFlyout: AsyncKanbanFlyout,
	ScrumFlyout: AsyncScrumFlyout,
	onToggle: noop,
};

export default SprintFlyout;
