import { SET_REPORT_STATUS, type SetReportStatusAction } from './actions.tsx';
import { type ReportState, ReportStatus } from './types.tsx';

const initialState: ReportState = {
	// Set initial state as loading to prevent flickers
	status: ReportStatus.LOADING,
};

type Action = SetReportStatusAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ReportState = initialState, action: Action) => {
	switch (action.type) {
		case SET_REPORT_STATUS: {
			return {
				...state,
				status: action.payload,
			};
		}
		default:
			return state;
	}
};
