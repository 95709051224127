import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-associated-issues/src/common/types.tsx';

export const calculateFlexShrink = (names: string[]): number[] => {
	const nameLengths = names.map((name) => (name === undefined ? 0 : name.length));
	const shrinkCoefficient = 0.2; // A smaller number will make the components shrink closer to a similar rate.
	return nameLengths.map((length) => Math.pow(length, shrinkCoefficient));
};

export const isCellDirty = (
	originals: string[] | undefined,
	associatedIssues: AssociatedIssue[],
) => {
	if (!originals) return false;
	const associatedIssuesWithPermissionsIds = associatedIssues.map((el) => el.id);
	return !!(
		difference(originals, associatedIssuesWithPermissionsIds).length ||
		difference(associatedIssuesWithPermissionsIds, originals).length
	);
};

const arrayValuesEqual = (updated: string[], associatedIssuesOnIssue: string[]) => {
	if (updated.length !== associatedIssuesOnIssue.length) {
		return false;
	}
	return isEqual(updated.sort(), associatedIssuesOnIssue.sort());
};

const extendAssociatedIssueUpdateWithNotLoaded = (
	changedAssociatedIssues: string[],
	associatedIssuesOnIssue: string[],
	loadedAssociatedIssues: string[],
) => {
	const associatedIssuesWithoutPermissions = difference(
		associatedIssuesOnIssue,
		loadedAssociatedIssues,
	);
	return [...changedAssociatedIssues, ...associatedIssuesWithoutPermissions];
};

export const getAssociatedIssuesUpdate = (
	changedAssociatedIssues: string[],
	associatedIssuesOnIssue: string[],
	loadedAssociatedIssues: string[],
): string[] | undefined => {
	const updated = extendAssociatedIssueUpdateWithNotLoaded(
		changedAssociatedIssues,
		associatedIssuesOnIssue,
		loadedAssociatedIssues,
	);
	return arrayValuesEqual(updated, associatedIssuesOnIssue) ? undefined : updated;
};
