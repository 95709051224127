import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	activePointsSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.sprint.active-points-sprint',
		defaultMessage: '{used}/{available} points allocated',
		description: 'Label for active sprints',
	},
	activeUnitSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.sprint.active-unit-sprint',
		defaultMessage: '{unit} allocated',
		description: 'Label for active sprints',
	},
	days: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.sprint.days',
		defaultMessage:
			'{available, plural, one {{used}/{available} day} other {{used}/{available} days}}',
		description: 'Days label',
	},
	daysTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.sprint.days-title',
		defaultMessage: '{displayTitle} days of estimated work',
		description:
			'Title for Kanban iteration (days as unit). {displayTitle} will be something like 5/8 (5 out of 8).',
	},
	futureProjectedSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.sprint.future-projected-sprint',
		defaultMessage: 'Projected sprint',
		description: 'Label for projected sprints',
	},
	hours: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.sprint.hours',
		defaultMessage:
			'{available, plural, one {{used}/{available} hour} other {{used}/{available} hours}}',
		description: 'Hours label',
	},
	hoursTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.sprint.hours-title',
		defaultMessage: '{displayTitle} hours of estimated work',
		description:
			'Title for Kanban iteration (hours as unit). {displayTitle} will be something like 32/40 (32 out of 40)',
	},
	pastSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.sprint.past-sprint',
		defaultMessage: 'Past sprint',
		description: 'Label for past sprints',
	},
});
