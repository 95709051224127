import {
	SHOW_ADD_DEPENDENCY,
	HIDE_ADD_DEPENDENCY,
	SHOW_ADD_DEPENDENCY_FROM_ISSUE,
	type ShowAddDependencyAction,
	type HideAddDependencyAction,
	type ShowAddDependencyFromIssueAction,
} from './actions.tsx';
import type { AddDependency } from './types.tsx';

const initialState: AddDependency = {
	show: false,
};

type Action = ShowAddDependencyAction | HideAddDependencyAction | ShowAddDependencyFromIssueAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: AddDependency = initialState, action: Action) => {
	switch (action.type) {
		case SHOW_ADD_DEPENDENCY: {
			return {
				...state,
				show: true,
			};
		}
		case HIDE_ADD_DEPENDENCY: {
			return {
				...state,
				show: false,
				fromIssueId: undefined,
			};
		}
		case SHOW_ADD_DEPENDENCY_FROM_ISSUE: {
			return {
				...state,
				show: true,
				fromIssueId: action.payload,
			};
		}
		default:
			return state;
	}
};
