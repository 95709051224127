import React, { type ComponentPropsWithoutRef, forwardRef, useRef, useEffect } from 'react';
import { styled } from '@compiled/react';
import { xcss, Box, Flex } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { COMMAND_BODY_TEST_ID, COMPAL_MENU_ID } from '../constants.tsx';
import { messages } from './messages.tsx';
import { useSmoothExpand } from './utils.tsx';

const CommandPaletteSearchResultListWrapperNew = forwardRef<
	HTMLDivElement,
	ComponentPropsWithoutRef<typeof Box> & {
		noResultsFallback?: string | JSX.Element | null | undefined;
	}
>(({ noResultsFallback, children, as, ...props }, ref) => {
	const { formatMessage } = useIntl();
	const resizerRef = useRef<HTMLDivElement>(null);
	const height = useSmoothExpand(resizerRef);

	useEffect(() => {
		if (ref && 'current' in ref && ref.current) {
			const currentRef = ref.current;
			currentRef.style.height = `${height}px`;
		}
	}, [height, ref]);

	return (
		<Box
			xcss={wrapperStyles}
			testId={COMMAND_BODY_TEST_ID}
			id={COMPAL_MENU_ID}
			role={
				// eslint-disable-next-line no-nested-ternary
				noResultsFallback && fg('a11y_violations_fixes')
					? 'group'
					: fg('compal_listbox_to_menu')
						? 'menu'
						: 'listbox'
			}
			ref={ref}
			aria-label={formatMessage(messages.commandPaletteSearchResultListBoxLabel)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			<Flex ref={resizerRef} direction="column" gap="space.050">
				{children}
			</Flex>
		</Box>
	);
});

const CommandPaletteSearchResultListWrapperOld = forwardRef<
	HTMLDivElement,
	ComponentPropsWithoutRef<typeof Wrapper> & {
		noResultsFallback?: string | JSX.Element | null | undefined;
	}
>(({ noResultsFallback, ...props }, ref) => {
	const { formatMessage } = useIntl();
	return (
		<Wrapper
			data-testid={COMMAND_BODY_TEST_ID}
			id={COMPAL_MENU_ID}
			role={
				// eslint-disable-next-line no-nested-ternary
				noResultsFallback && fg('a11y_violations_fixes')
					? 'group'
					: fg('compal_listbox_to_menu')
						? 'menu'
						: 'listbox'
			}
			ref={ref}
			aria-label={formatMessage(messages.commandPaletteSearchResultListBoxLabel)}
			{...props}
		/>
	);
});

export const CommandPaletteSearchResultListWrapper = componentWithFG(
	'compal_smooth_transition_on_size_change',
	CommandPaletteSearchResultListWrapperNew,
	CommandPaletteSearchResultListWrapperOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	maxHeight: '376px',
	overflowY: 'auto',
	boxSizing: 'border-box',
	padding: `${token('space.100', '8px')} 0`,
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.050', '4px'),
	minHeight: '56px',
});

const wrapperStyles = xcss({
	padding: '0',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	transition: 'height 0.15s ease-out',
	overflowY: 'auto',
	maxHeight: '376px',
});
