import React from 'react';
import Tooltip from '@atlaskit/tooltip';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function EllipsedWithTooltip({ children, content, id, position = 'bottom' }: Props) {
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.ellipsed}>
			<Tooltip content={content} position={position} tag="span" key={id}>
				{children}
			</Tooltip>
		</div>
	);
}
