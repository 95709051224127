import React, { type ComponentType } from 'react';
import type { NamespacedIdProps } from './types.tsx';

// The redux store of the sections component (take a look at the ./reducer.js file) uses section IDs to store state of that particular section and this state is later saved to local storage.
// Before view-switching feature, the IDs were static and we only store one version of local storage. But after view-switching, users could switch between views and we want to store the state
// for each view. This HOC takes in a namespace prop and enhances the IDs (id and linkToParent) with the namespace. The namespace is usually the viewID and this would change when the user
// switches to different view.

export function getSectionNamespace(namespace: string, id: string) {
	return `${namespace}-${id}`;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function withNamespacedId(Component: ComponentType<any>) {
	return ({ namespace, id, linkToParent, ...props }: NamespacedIdProps) => (
		<Component
			{...props}
			id={getSectionNamespace(namespace, id)}
			linkToParent={
				linkToParent !== null && linkToParent !== undefined && `${namespace}-${linkToParent}`
			}
		/>
	);
}
