import * as R from 'ramda';
import { DateUnits } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import {
	ONE_DAY,
	ONE_WEEK,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { VersionValues } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import {
	SCENARIO_TYPE,
	DIRECTIONS,
	type ScenarioType,
	type Direction,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { OriginalVersion } from '../../state/domain/original-versions/types.tsx';
import {
	type DateReference,
	type DateMethodDescriptor,
	DATE_METHODS,
	type VersionsById,
} from './types.tsx';

const getDateReferenceFromMilliseconds = (delta: Timestamp): DateReference => {
	const days: number = delta / ONE_DAY;
	const direction: Direction = delta > 0 ? DIRECTIONS.AFTER : DIRECTIONS.BEFORE;
	const canBeDisplayedAsWeeks: boolean = days % 7 === 0;

	return canBeDisplayedAsWeeks
		? {
				quantity: Math.abs(days) / 7,
				unit: DateUnits.WEEKS,
				direction,
			}
		: {
				quantity: Math.abs(days),
				unit: DateUnits.DAYS,
				direction,
			};
};

export const getMillisecondsDateReference = ({
	quantity,
	unit,
	direction,
}: DateReference): Timestamp => {
	const milliseconds: Timestamp =
		unit === DateUnits.DAYS ? quantity * ONE_DAY : quantity * ONE_WEEK;
	return direction === DIRECTIONS.AFTER ? milliseconds : -1 * milliseconds;
};

export const getStartDateMethod = (
	start?: Timestamp | null,
	delta?: Timestamp | null,
): DateMethodDescriptor => {
	if (!isDefined(start) && !isDefined(delta)) {
		return {
			method: DATE_METHODS.AS_EARLY_AS_POSSIBLE,
		};
	}

	if (isDefined(start)) {
		return {
			method: DATE_METHODS.FIXED_DATE,
		};
	}

	let dateReference: DateReference | undefined;
	if (isDefined(delta)) {
		dateReference = getDateReferenceFromMilliseconds(delta);
	}

	return {
		method: DATE_METHODS.RELATIVE_TO_PREVIOUS_RELEASE,
		...dateReference,
	};
};

export const getEndDateMethod = (end?: Timestamp | null): DateMethodDescriptor => {
	if (isDefined(end)) {
		return {
			method: DATE_METHODS.FIXED_DATE,
		};
	}

	return {
		method: DATE_METHODS.AFTER_ALL_ISSUES_ARE_COMPLETED,
	};
};

export const getChangedAttributes = (
	values: Readonly<VersionValues>,
	originals: OriginalVersion,
	scenarioType: ScenarioType,
): Array<keyof VersionValues> => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const originalKeys = Object.keys(originals) as Array<keyof VersionValues>;
	if (scenarioType === SCENARIO_TYPE.ADDED) {
		const { delta, lexoRank, releaseStatusId, ...rest } = values;
		if (!isDefined(values.description)) {
			delete rest.description;
		}
		if (!isDefined(values.crossProjectVersion)) {
			delete rest.crossProjectVersion;
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return Object.keys(rest) as Array<keyof VersionValues>;
	}
	if (scenarioType === SCENARIO_TYPE.DELETED) {
		return [];
	}
	if (originalKeys.includes('delta')) {
		return originalKeys.includes('start')
			? originalKeys.filter((key) => key !== 'delta')
			: originalKeys.map((key) => (key === 'delta' ? 'start' : key));
	}

	return originalKeys;
};

export const getChangedAttributeName = (
	changedAttributes: (keyof VersionValues)[],
	scenarioType: ScenarioType,
): keyof VersionValues | undefined => {
	if (scenarioType === SCENARIO_TYPE.ADDED || changedAttributes.length !== 1) {
		return;
	}
	const attributeName = changedAttributes[0];
	return attributeName;
};

export const getTargetDatesFromReleases = (
	issue: Issue,
	releases: VersionsById,
): null | {
	startDate: number | null | undefined;
	endDate: number | null | undefined;
} => {
	if (R.isEmpty(issue.fixVersions) || !isDefined(issue.fixVersions)) {
		return null;
	}

	// JPO-22372 - Inherit start and end dates from multiple releases
	// if the issue has multiple releases, we're looking for the lowest start date and the lowest end date of all its releases
	if (issue.fixVersions.length > 1) {
		const { startDate, endDate } = issue.fixVersions.reduce(
			(acc, fixVersion) => {
				const fixVersionStartDate = releases[fixVersion]?.start;
				if (fixVersionStartDate) {
					acc.startDate = Math.min(acc.startDate, fixVersionStartDate);
					// if any release has an undefined start date, we keep an undefined start date
				} else {
					acc.startDate = -Infinity;
				}

				const fixVersionEndDate = releases[fixVersion]?.end;
				if (fixVersionEndDate) {
					acc.endDate = Math.min(acc.endDate, fixVersionEndDate);
				}
				return acc;
			},
			{ startDate: Infinity, endDate: Infinity },
		);

		return {
			startDate: Number.isFinite(startDate) ? startDate : null,
			endDate: Number.isFinite(endDate) ? endDate : null,
		};
	}

	const fixVersion = issue.fixVersions[0];

	const startDate = releases[fixVersion]?.start;
	const endDate = releases[fixVersion]?.end;

	return {
		startDate,
		endDate,
	};
};
