.isTransparentBackground > div {
	background-color: transparent;
	border-color: transparent;
}

.isAlignRight input {
	text-align: right;
}

.error > div {
	border-color: var(--ds-border-danger, var(--adg3-color-R400));
	margin-right: 1px;
	box-sizing: border-box;
}

.isReadOnly input {
	cursor: default;
}
