import type React from 'react';
import { useEffect, useCallback, type RefObject } from 'react';
import { monitorForDependencyCreate } from '@atlassian/jira-portfolio-3-dependency-line-drag-create/src/ui/index.tsx';
import { useViewport } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';
import { useColumnWidths } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { useScheduler, monitorForIssueBarUpdate } from './utils.tsx';

const MAX_SPEED = 0.5;

export const TimelineDragAutoScroll: React.FC<{
	containerRef: RefObject<HTMLElement | null>;
}> = ({ containerRef }) => {
	const [viewport] = useViewport();
	const [columnWidths] = useColumnWidths({ preview: false });

	const marginLeft = columnWidths.reduce(
		(totalWidth, curWidth, index) =>
			index < columnWidths.length - 1 ? totalWidth + curWidth : totalWidth,
		0,
	);

	const autoscroll = useCallback(
		(timeSinceLastFrame: number, { pageX, pageY }: { pageX: number; pageY: number }) => {
			const edgeDistances = {
				top: pageY - viewport.top - 90 /* Header & sub-header */,
				bottom: viewport.top + viewport.height - pageY,
				left: pageX - viewport.left - marginLeft,
				right: viewport.left + viewport.width - pageX,
			};

			const velocity = (distanceToTheEdge: number) =>
				distanceToTheEdge <= 0 ? 0 : Math.max(MAX_SPEED - distanceToTheEdge / 200, 0);

			const containerEl = containerRef.current;

			if (containerEl) {
				let xAmount = velocity(edgeDistances.right) * timeSinceLastFrame;

				if (xAmount === 0) xAmount = -velocity(edgeDistances.left) * timeSinceLastFrame;

				if (!Number.isNaN(xAmount) && xAmount !== 0) containerEl.scrollLeft += xAmount;

				let yAmount = velocity(edgeDistances.bottom) * timeSinceLastFrame;

				if (yAmount === 0) yAmount = -velocity(edgeDistances.top) * timeSinceLastFrame;

				if (!Number.isNaN(yAmount) && yAmount !== 0) containerEl.scrollTop += yAmount;
			}
		},
		[containerRef, marginLeft, viewport.height, viewport.left, viewport.top, viewport.width],
	);

	const { start, end, update } = useScheduler(autoscroll);

	useEffect(
		() =>
			monitorForIssueBarUpdate({
				onDragStart: start,
				onDragEnd: end,
				onDrag: update,
			}),
		[start, end, update],
	);
	useEffect(
		() =>
			monitorForDependencyCreate({
				onDragStart: start,
				onDropTargetChange: ({ location }) => update(location.current.input),
				onDrag: ({ location }) => update(location.current.input),
				onDrop: end,
			}),
		[start, end, update],
	);

	return null;
};
