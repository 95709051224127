import * as R from 'ramda';
import { updateRefs } from '../util.tsx';
import {
	RESET,
	type ResetAction,
	ADD,
	type AddAction,
	REMOVE,
	type RemoveAction,
	BULK_UPDATE,
	type BulkUpdateAction,
	ADD_VERSION_IN_CROSS_PROJECT_VERSION,
	type AddVersionInCrossProjectVersionAction,
	REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION,
	type RemoveVersionFromCrossProjectVersionAction,
	UPDATE_REFERENCES,
	type UpdateReferencesAction,
} from './actions.tsx';
import type { CrossProjectVersion } from './types.tsx';

const initialState: CrossProjectVersion[] = [];

type Action =
	| ResetAction
	| AddAction
	| RemoveAction
	| BulkUpdateAction
	| AddVersionInCrossProjectVersionAction
	| RemoveVersionFromCrossProjectVersionAction
	| UpdateReferencesAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: CrossProjectVersion[] = initialState,
	action: Action,
): CrossProjectVersion[] => {
	switch (action.type) {
		case RESET:
			return [...action.payload];
		case ADD: {
			return [...state, action.payload];
		}
		case REMOVE: {
			const removedId = action.payload;
			return state.filter(({ id }) => id !== removedId);
		}
		case BULK_UPDATE: {
			const { payload } = action;
			const result: Array<CrossProjectVersion> = [];
			for (const item of state) {
				const patch = payload[item.id];
				if (patch) {
					result.push(R.merge(item, patch));
				} else {
					result.push(item);
				}
			}
			return result;
		}
		case ADD_VERSION_IN_CROSS_PROJECT_VERSION: {
			const { crossProjectVersionId, versionId } = action.payload;
			// Array spreading is done to make flow understand the type.
			return [
				...state.map((crossProjectVersion: CrossProjectVersion) => {
					const { id, versions } = crossProjectVersion;
					if (id === crossProjectVersionId) {
						return {
							...crossProjectVersion,
							versions: [...versions, versionId],
						};
					}
					return crossProjectVersion;
				}),
			];
		}
		case REMOVE_VERSION_FROM_CROSS_PROJECT_VERSION: {
			const { crossProjectVersionId, versionId } = action.payload;
			// Array spreading is done to make flow understand the type.
			return [
				...state.map((crossProjectVersion: CrossProjectVersion) => {
					const { id, versions } = crossProjectVersion;
					if (id === crossProjectVersionId) {
						return {
							...crossProjectVersion,
							versions: versions.filter((vid) => vid !== versionId),
						};
					}
					return crossProjectVersion;
				}),
			];
		}
		case UPDATE_REFERENCES:
			return updateRefs(state, action.payload);
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
