import React from 'react';
import Icon from '@atlaskit/icon';

type Props = {
	label: string;
};

const getIconContents = () => (
	<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
		<path
			d="m14.586 14.988-2.293 2.293a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 0 0-1.414 1.414l2.293 2.293H10a1 1 0 0 1-1-1v-7a1 1 0 0 0-2 0v7a3 3 0 0 0 3 3h4.586Z"
			fill="currentColor"
		/>
	</svg>
);

const ChildIcon = ({ label }: Props) => (
	<Icon glyph={getIconContents} size="medium" label={label} />
);

export default ChildIcon;
