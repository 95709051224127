import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	scrumHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.edit-team-modal.scrum-header',
		defaultMessage: 'Set up sprint capacity planning',
		description: 'This is the header for the scrum section of the edit team modal',
	},
	scrumSubheading: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.edit-team-modal.scrum-subheading',
		defaultMessage: 'Connect a team to a scrum board',
		description: 'This is the subheading for the scrum section of the edit team modal',
	},
	scrumBodyCopy: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.edit-team-modal.scrum-body-copy',
		defaultMessage: 'Select a scrum board issue source to link to the team.',
		description: 'This is the body copy for the scrum section of the edit team modal',
	},

	kanbanHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.edit-team-modal.kanban-header',
		defaultMessage: 'Set up capacity planning',
		description: 'This is the header for the kanban section of the edit team modal',
	},
	kanbanSubheading: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.edit-team-modal.kanban-subheading',
		defaultMessage: 'Connect the team to an issue source',
		description: 'This is the subheading for the kanban section of the edit team modal',
	},
	kanbanBodyCopy: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.sprints.edit-team-modal.kanban-body-copy',
		defaultMessage: 'Select an issue source to link to the team.',
		description: 'This is the body copy for the kanban section of the edit team modal',
	},
});
