import findLast from 'lodash/findLast';
import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import {
	EPIC_LEVEL,
	SUB_TASK_LEVEL,
} from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import {
	DRAG_INDICATOR,
	DROP_INDICATOR,
	PARENT_INDICATOR,
	type Indicator,
	type DragIndicator,
} from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/types.tsx';
import {
	TABLE_BLANK_ROW,
	TABLE_GROUP_HEADER,
	TABLE_ISSUE,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/styles.module.css';
import {
	DROP_CHILD,
	DROP_NONE,
	DROP_SIBLING,
	type DropType,
	type Issues,
	type Item,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/types.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import { HEADER_ROWS } from './constants.tsx';
import {
	CHANGED_GROUP,
	ORPHANED_SUBTASK,
	REPARENT_CMP_TO_TMP,
	REPARENT_TMP_TO_EXTERNAL,
	type ActionState,
	type DropBlocker,
	type DropParameters,
	type DropState,
	type RelativePosition,
	type RelatedItems,
} from './types.tsx';

export const disableUserSelection = (disableSelection: boolean) => {
	const body = getBody();
	body.classList.toggle(styles.unselected, disableSelection);
};

const truncateSummary = (originalSummary: string, maxLength = 50): string => {
	if (originalSummary.length > maxLength) {
		return `${originalSummary.slice(0, maxLength - 3)}...`;
	}
	return originalSummary;
};

export const getIssueKey = (projectKey: string, issueNum: number | undefined, summary: string) => {
	const isScenarioIssue = issueNum === undefined;
	return isScenarioIssue ? truncateSummary(summary) : `${projectKey}-${issueNum}`;
};

export const getCorrectedIndex = (index: number): number => index - HEADER_ROWS;

export const getRelatedItems = (
	issues: Issues,
	dragIndex: number,
	dropIndex: number,
	closestEdge: Edge | null,
): RelatedItems => {
	const correctedDragIndex = getCorrectedIndex(dragIndex);
	const correctedDropIndex = getCorrectedIndex(dropIndex);

	const dragItem = issues[correctedDragIndex];
	const dropItem = issues[correctedDropIndex];

	if (dragItem?.tag !== TABLE_ISSUE)
		throw new Error(`Dragged item was expected to be ${TABLE_ISSUE}, but was ${dragItem.tag}.`);

	const isDraggingOverBottomEdge = closestEdge === 'bottom';

	const beforeDropPosition = isDraggingOverBottomEdge ? correctedDropIndex : correctedDropIndex - 1;
	const afterDropPosition = isDraggingOverBottomEdge ? correctedDropIndex + 1 : correctedDropIndex;

	const beforeDropItem = issues[beforeDropPosition];
	const afterDropItem = issues[afterDropPosition];

	const previousSiblingItemOrHeader = findLast(
		issues,
		(item) => item.tag !== TABLE_ISSUE || item.value.level >= dragItem.value.level,
		beforeDropPosition,
	);

	const previousSiblingItem =
		previousSiblingItemOrHeader?.tag === TABLE_ISSUE &&
		previousSiblingItemOrHeader.value.level === dragItem.value.level
			? previousSiblingItemOrHeader
			: undefined;

	return {
		dragItem,
		dropItem,
		beforeDropItem,
		afterDropItem,
		previousSiblingItem,
	};
};

export const getDropType = (relatedItems: RelatedItems, closestEdge: Edge | null): DropType => {
	const { dragItem, dropItem, beforeDropItem, afterDropItem } = relatedItems;

	// Case 1: If a dragged item is over its original position, do not show an indicator.
	if (dragItem === dropItem) return DROP_NONE;

	// Case 2: If a dragged item is over a group header of the same level, show a child drop indicator.
	if (dropItem.tag === TABLE_GROUP_HEADER) {
		const dragItemLevel = dragItem.value.level;
		const dropItemLevel = dropItem.value.level.level;

		if (dragItemLevel !== dropItemLevel) return DROP_NONE;

		return DROP_CHILD;
	}

	// Case 3: If a dragged item is over a blank row, show a child drop indicator. This operation will orphan the item.
	if (dropItem.tag === TABLE_BLANK_ROW) {
		const dragItemParent = dragItem.value.parent;

		if (!dragItemParent) return DROP_NONE;

		return DROP_CHILD;
	}

	// Case 4: If a dragged item is over anything but an issue, do not show an indicator.
	if (dropItem.tag !== TABLE_ISSUE) return DROP_NONE;

	const dragItemLevel = dragItem.value.level;
	const dropItemLevel = dropItem.value.level;

	// Case 5: If a dragged item is over an issue one above its level, show a child drop indicator.
	const isDraggingOverParent = dragItemLevel === dropItemLevel - 1;
	if (isDraggingOverParent) return DROP_CHILD;

	// Case 6: If the drop position is adjacent to the dragged item, do not show an indicator.
	if (dragItem === beforeDropItem || dragItem === afterDropItem) return DROP_NONE;

	// Case 7: If a dragged item is over an issue of the same level, but the next item is a lower level issue, do not show an indicator.
	const isDraggingOverSibling = dragItemLevel === dropItemLevel;
	const isDraggingOverLowerHalf = closestEdge === 'bottom';
	const isNextItemLowerLevelIssue =
		afterDropItem.tag === TABLE_ISSUE && afterDropItem.value.level < dragItemLevel;
	if (isDraggingOverSibling && isDraggingOverLowerHalf && isNextItemLowerLevelIssue)
		return DROP_NONE;

	// Case 8: If a dragged item is over an issue of the same level, show a sibling drop indicator.
	if (isDraggingOverSibling) return DROP_SIBLING;

	const isDraggingOverLowerLevel = dragItemLevel > dropItemLevel;

	// Case 9: If a dragged item is over the lower half of an issue of a lower level, and the next item is not an issue,
	// show a sibling drop indicator.
	if (afterDropItem.tag !== TABLE_ISSUE && isDraggingOverLowerHalf && isDraggingOverLowerLevel)
		return DROP_SIBLING;

	// Case 10: If a dragged item is over the lower half of an issue of a lower level, and the next item is an issue of
	// an equal or higher level, show a sibling drop indicator.
	if (afterDropItem.tag === TABLE_ISSUE) {
		const afterDropItemLevel = afterDropItem.value.level;
		const isAfterDropPositionOfEqualOrHigherLevel = afterDropItemLevel >= dragItemLevel;

		if (
			isDraggingOverLowerHalf &&
			isDraggingOverLowerLevel &&
			isAfterDropPositionOfEqualOrHigherLevel
		)
			return DROP_SIBLING;
	}

	// Default: If a dragged item is over anything else, do not show an indicator.
	return DROP_NONE;
};

export const getDropBlocker = (
	relatedItems: RelatedItems,
	type: DropType,
): DropBlocker | undefined => {
	const { dragItem, dropItem } = relatedItems;

	// Case 1: If the dragged item is over a different group to where it originated, do not allow the drop.
	if (dragItem.group !== dropItem.group) return { reason: CHANGED_GROUP };

	// Case 2: If the dragged item is a subtask, do not allow it to be orphaned.
	if (dragItem.value.level === SUB_TASK_LEVEL) {
		if (dropItem.tag === TABLE_GROUP_HEADER && dropItem.value.level.level === SUB_TASK_LEVEL)
			return { reason: ORPHANED_SUBTASK };

		if (
			dropItem.tag === TABLE_ISSUE &&
			dropItem.value.level === SUB_TASK_LEVEL &&
			dropItem.value.parent === undefined
		)
			return { reason: ORPHANED_SUBTASK };
	}

	// Case 3: If the dragged item is re-parented to an issue in a different project, do not allow the drop.
	if (dropItem.tag === TABLE_ISSUE) {
		const isDragOverParent = dragItem.value.level === dropItem.value.level - 1;
		const isDragOverOwnParent = dragItem.value.parent === dropItem.value.id;

		const isReparentOverParent = isDragOverParent && !isDragOverOwnParent;

		const isDragOverSibling = dragItem.value.level === dropItem.value.level;
		const isSiblingParentOwnParent = dragItem.value.parent === dropItem.value.parent;

		const isReparentOverSibling = isDragOverSibling && !isSiblingParentOwnParent;

		if (isReparentOverParent || isReparentOverSibling) {
			const parentId = getParentId(relatedItems, type);
			const level = dragItem.value.level;
			const project = dragItem.value.project;
			const parentProject = dropItem.value.project;

			if (parentId !== undefined) {
				if (level !== EPIC_LEVEL) {
					if (project.isSimplified && project.id !== parentProject.id)
						return {
							reason: REPARENT_TMP_TO_EXTERNAL,
							context: {
								projectName: project.name,
								destinationProjectName: parentProject.name,
								issueKey: getIssueKey(project.key, dragItem.value.issueKey, dragItem.value.summary),
							},
						};
					if (!project.isSimplified && parentProject.isSimplified)
						return {
							reason: REPARENT_CMP_TO_TMP,
							context: {
								projectName: project.name,
								destinationProjectName: parentProject.name,
								issueKey: getIssueKey(project.key, dragItem.value.issueKey, dragItem.value.summary),
							},
						};
				}
			}
		}
	}
};

export const getParentId = (relatedItems: RelatedItems, type: DropType): string | undefined => {
	const { dragItem, dropItem, previousSiblingItem } = relatedItems;

	if (dropItem.tag === TABLE_ISSUE) {
		// If the dragged item is dropped as a child, the parent is the drop item.
		if (type === DROP_CHILD) return dropItem.value.id;

		if (type === DROP_SIBLING) {
			// If the dragged item is dropped as a sibling, the parent is the parent of the sibling item.
			if (dragItem.value.level === dropItem.value.level) return dropItem.value.parent;
			if (previousSiblingItem?.tag === TABLE_ISSUE) return previousSiblingItem.value.parent;
		}
	}
};

export const getRelativePosition = (
	dropItem: Item,
	dropType: DropType,
	closestEdge: Edge | null,
): RelativePosition => {
	if (dropItem.tag === TABLE_ISSUE) {
		if (dropType === DROP_CHILD)
			return {
				anchor: dropItem.value.id,
				relation: 'AFTER',
			};

		if (dropType === DROP_SIBLING)
			return {
				anchor: dropItem.value.id,
				relation: closestEdge === 'bottom' ? 'AFTER' : 'BEFORE',
			};
	}

	return {
		anchor: undefined,
		relation: 'LAST',
	};
};

export const getDropAction = (
	relatedItems: RelatedItems,
	type: DropType,
	closestEdge: Edge | null,
): ActionState => {
	const { dragItem, dropItem } = relatedItems;

	const id = dragItem.value.id;
	const group = dragItem.group;
	const parentId = getParentId(relatedItems, type);
	const { anchor, relation } = getRelativePosition(dropItem, type, closestEdge);

	return {
		id,
		group,
		parentId,
		anchor,
		relation,
	};
};

export const getIndicators = (
	relatedItems: RelatedItems,
	type: DropType,
	dragIndex: number,
	dropIndex: number,
	closestEdge: Edge | null,
	blocked?: DropBlocker,
): Indicator[] => {
	const { dropItem } = relatedItems;

	const dragIndicator: DragIndicator = { type: DRAG_INDICATOR, index: dragIndex };

	const parentId = getParentId(relatedItems, type);

	if (type === DROP_CHILD) {
		if (dropItem.tag === TABLE_GROUP_HEADER || dropItem.tag === TABLE_BLANK_ROW)
			return [dragIndicator, { type: PARENT_INDICATOR, index: dropIndex, blocked: !!blocked }];

		return [dragIndicator, { type: PARENT_INDICATOR, id: parentId, blocked: !!blocked }];
	}

	if (type === DROP_SIBLING)
		return [
			dragIndicator,
			{
				type: DROP_INDICATOR,
				index: closestEdge === 'top' ? dropIndex : dropIndex + 1,
				edge: 'top',
				blocked: !!blocked,
			},
			{ type: PARENT_INDICATOR, id: parentId, blocked: !!blocked },
		];

	return [dragIndicator];
};

export const getNoDropTargetState = (parameters: DropParameters): DropState => ({
	type: DROP_NONE,
	parameters,
	indicators: [{ type: DRAG_INDICATOR, index: parameters.dragTarget.index }],
});

export const getNewDropState = (issues: Issues, parameters: DropParameters): DropState => {
	const { dragTarget, dropTarget } = parameters;

	if (!dropTarget) return getNoDropTargetState(parameters);

	const dragIndex = dragTarget.index;
	const dropIndex = dropTarget.index;
	const closestEdge = dropTarget.closestEdge;

	const relatedItems = getRelatedItems(issues, dragIndex, dropIndex, closestEdge);

	const type = getDropType(relatedItems, closestEdge);
	const action = getDropAction(relatedItems, type, closestEdge);
	const blocked = getDropBlocker(relatedItems, type);
	const indicators = getIndicators(relatedItems, type, dragIndex, dropIndex, closestEdge, blocked);

	return {
		type,
		parameters,
		action,
		blocked,
		indicators,
	};
};
