import React from 'react';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { token } from '@atlaskit/tokens';
import {
	AkFlag as Flag,
	FlagGroup,
	AkAutoDismissFlag as AutoDismissFlag,
} from '@atlassian/jira-flags';
import { FormattedMessage } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

function CommitWarningNotification(props: Props) {
	const { show, failedCommitCount, successfulCommitCount, onClose } = props;
	if (!show) return null;
	let title;
	let description;
	let icon;
	const onlySuccessful = successfulCommitCount && !failedCommitCount;
	if (onlySuccessful) {
		title = <FormattedMessage {...messages.successHeaderText} />;
		description = (
			<FormattedMessage {...messages.successContentText} values={{ successfulCommitCount }} />
		);
		icon = <SuccessIcon label="" primaryColor={token('color.icon.success', colors.G300)} />;
	} else {
		title = <FormattedMessage {...messages.warningHeaderText} values={{ successfulCommitCount }} />;
		if (!successfulCommitCount) {
			description = (
				<FormattedMessage {...messages.warningContentText} values={{ failedCommitCount }} />
			);
		} else {
			description = (
				<div>
					<div>
						<FormattedMessage
							{...messages.warningWithSuccessContentSuccessText}
							values={{ successfulCommitCount }}
						/>
					</div>
					<div>
						<FormattedMessage
							{...messages.warningWithSuccessContentWarningText}
							values={{ failedCommitCount }}
						/>
					</div>
				</div>
			);
		}
		icon = <WarningIcon label="" primaryColor={token('color.icon.warning', colors.Y300)} />;
	}
	return (
		<FlagGroup onDismissed={onClose}>
			{onlySuccessful ? (
				<AutoDismissFlag
					id="portfolio-3-app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification"
					title={title}
					description={description}
					icon={icon}
					testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.commit-warning-notification.auto-dismiss-flag"
				/>
			) : (
				<Flag
					id="commit-warning-modification-flag"
					title={title}
					description={description}
					icon={icon}
				/>
			)}
		</FlagGroup>
	);
}

export default CommitWarningNotification;
