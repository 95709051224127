import React, { type ComponentPropsWithoutRef } from 'react';
import { styled } from '@compiled/react';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ButtonContainer = ({
	children,
	...props
}: ComponentPropsWithoutRef<typeof BaseButtonContainer>) => (
	<BaseButtonContainer data-component-selector="ButtonContainer" {...props}>
		{children}
	</BaseButtonContainer>
);
const HeaderToggle = ({ mode, setMode }: Props) => {
	const { formatMessage } = useIntl();
	switch (mode) {
		case 'hidden':
			return (
				<Bar data-testId="portfolio-3-portfolio.app-simple-plans.top.page-header.header-toggle.bar">
					<BarSection
						key="Show toolbar"
						justifyContent="center"
						onClick={() => setMode('toolbar')}
						aria-expanded={false}
						data-testId="portfolio-3-portfolio.app-simple-plans.top.page-header.header-toggle.bar-section-show"
					>
						<ButtonWrapper>
							<Tooltip content={formatMessage(messages.showRefinementOptions)}>
								<ButtonContainer>
									<ChevronDownIcon label={formatMessage(messages.showRefinementOptions)} />
								</ButtonContainer>
							</Tooltip>
						</ButtonWrapper>
					</BarSection>
				</Bar>
			);
		case 'toolbar':
			return (
				<Bar data-testId="portfolio-3-portfolio.app-simple-plans.top.page-header.header-toggle.bar">
					<BarSection
						key="Hide toolbar"
						justifyContent="end"
						onClick={() => setMode('hidden')}
						aria-expanded
						data-testId="portfolio-3-portfolio.app-simple-plans.top.page-header.header-toggle.bar-section-hide"
					>
						<ButtonWrapper>
							<Tooltip content={formatMessage(messages.hideRefinementOptions)}>
								<ButtonContainer>
									<ChevronUpIcon label={formatMessage(messages.hideRefinementOptions)} />
								</ButtonContainer>
							</Tooltip>
						</ButtonWrapper>
					</BarSection>
					<BarSection
						key="Show header"
						justifyContent="start"
						onClick={() => setMode('visible')}
						aria-expanded={false}
						data-testId="portfolio-3-portfolio.app-simple-plans.top.page-header.header-toggle.bar-section-show"
					>
						<ButtonWrapper>
							<Tooltip content={formatMessage(messages.showHeader)}>
								<ButtonContainer>
									<ChevronDownIcon label={formatMessage(messages.showHeader)} />
								</ButtonContainer>
							</Tooltip>
						</ButtonWrapper>
					</BarSection>
				</Bar>
			);
		case 'visible':
			return (
				<Bar data-testId="portfolio-3-portfolio.app-simple-plans.top.page-header.header-toggle.bar">
					<BarSection
						key="Hide header"
						justifyContent="center"
						onClick={() => setMode('toolbar')}
						aria-expanded
						data-testId="portfolio-3-portfolio.app-simple-plans.top.page-header.header-toggle.bar-section-hide"
					>
						<ButtonWrapper>
							<Tooltip content={formatMessage(messages.hideHeader)}>
								<ButtonContainer>
									<ChevronUpIcon label={formatMessage(messages.hideHeader)} />
								</ButtonContainer>
							</Tooltip>
						</ButtonWrapper>
					</BarSection>
				</Bar>
			);
		default:
			return <></>;
	}
};

export default HeaderToggle;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	padding: token('space.150', '12px'),
	top: token('space.150', '12px'),
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BarSection = styled.button<{
	justifyContent?: string;
	'data-testId': string;
}>({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: gridSize * 3,
	padding: 0,
	alignItems: 'center',
	background: 'none',
	border: 'none',
	outline: 'none',
	boxShadow: 'none',
	cursor: 'pointer',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: ({ justifyContent }) => justifyContent,

	'&:focus': {
		boxShadow: 'none',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"&:hover [data-component-selector='ButtonContainer'], &:focus [data-component-selector='ButtonContainer']":
		{
			background: token('color.icon.brand', 'var(--adg3-color-B100)'),
			border: `1px solid ${token('color.border.brand', 'var(--adg3-color-B100)')}`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& svg': {
				color: token('color.text.inverse', 'var(--adg3-color-N0)'),
				fill: token('color.icon.inverse', 'var(--adg3-color-N0)'),
			},
		},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"&:active [data-component-selector='ButtonContainer']": {
		background: token('color.background.selected.bold.hovered', 'var(--adg3-color-B200)'),
		border: `1px solid ${token(
			'color.background.selected.bold.hovered',
			'var(--adg3-color-B200))',
		)}`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& svg': {
			color: token('color.text.inverse', 'var(--adg3-color-N0)'),
			fill: token('color.icon.inverse', 'var(--adg3-color-N0)'),
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Bar = styled.div({
	display: 'flex',
	justifyContent: 'center',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: gridSize * 3,
	zIndex: 1,
	position: 'relative',

	'&::before': {
		content: '',
		width: '100%',
		height: '2px',
		borderRadius: '2px',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		backgroundColor: token('color.border', colors.N30),
		position: 'absolute',
		left: 0,
		bottom: 0,
		pointerEvents: 'none',
		opacity: 0,
		transition: 'opacity 200ms ease',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > button': {
		opacity: 0,
		transition: 'opacity 200ms 500ms ease',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'& > button:focus:not(:focus-visible)': {
		outlineStyle: 'none',
	},

	'&:hover::before, &:focus-within::before': {
		opacity: 1,
		transition: 'opacity 200ms ease 100ms',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover > button, &:focus-within > button': {
		opacity: 1,
		transition: 'opacity 200ms ease 100ms',
	},
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BaseButtonContainer = styled.div({
	background: token('color.icon.inverse', 'var(--adg3-color-N0)'),
	borderRadius: '50%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: gridSize * 3,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: gridSize * 3,
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	boxShadow: '0 2px 4px 1px rgba(9, 30, 66, 0.08)',
	border: `1px solid ${token('color.border', 'var(--adg3-color-N50)')}`,
	transition: 'background 200ms ease, border 200ms ease',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& svg': {
		transition: 'color 200ms ease, fill 200ms ease',
	},
});
