import { getCrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/index.tsx';
import {
	getSelectedIssues,
	getCPRsForSelectedIssues,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, StateProps>({
	selectedIssues: getSelectedIssues,
	crossProjectVersionsById: getCrossProjectVersionsById,
	cpVersionsForSelectedIssues: getCPRsForSelectedIssues,
});
