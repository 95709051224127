import {
	bulkInlineCreateIssues,
	type BulkInlineCreateIssuePayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/inline-create/index.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	bulkInlineCreateIssues: (payload: BulkInlineCreateIssuePayload) => {
		dispatch(bulkInlineCreateIssues(payload));
	},
});

export default mapDispatchToProps;
