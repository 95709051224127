import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	add: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.field-action-select.add',
		defaultMessage: 'Add labels',
		description: 'Option to add label to existing labels',
	},
	replace: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.field-action-select.replace',
		defaultMessage: 'Replace all labels',
		description: 'Option to replace all existing labels',
	},
	clear: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.field-action-select.clear',
		defaultMessage: 'Clear all labels',
		description: 'Option to clear existing labels',
	},
	remove: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.field-action-select.remove',
		defaultMessage: 'Remove labels',
		description: 'Option to find and remove labels from existing labels',
	},
	fieldActionSelectLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.field-action-select.label',
		defaultMessage: 'Select field action',
		description: 'Select field action label',
	},
});
