export const TOGGLE_SHOW_WARNING =
	'state.domain.view-settings.warning-settings.TOGGLE_SHOW_WARNING' as const;
export const RESET_SHOW_WARNING =
	'state.domain.view-settings.warning-settings.RESET_SHOW_WARNING' as const;
export const TOGGLE_WARNING_TYPE =
	'state.domain.view-settings.warning-settings.TOGGLE_WARNING_TYPE' as const;

export const RESET_ENABLED_WARNING_TYPE =
	'state.domain.view-settings.warning-settings.RESET_ENABLED_WARNING_TYPE' as const;

export type ResetShowWarningActionPayload = {
	showWarning: boolean;
};

export type ToggleShowWarningAction = {
	type: typeof TOGGLE_SHOW_WARNING;
};

export type ResetShowWarningAction = {
	type: typeof RESET_SHOW_WARNING;
	payload: ResetShowWarningActionPayload;
};

export type ToggleWarningByTypeAction = {
	type: typeof TOGGLE_WARNING_TYPE;
	payload: string;
};

export type ResetEnabledWarningTypeAction = {
	type: typeof RESET_ENABLED_WARNING_TYPE;
	payload: Set<string>;
};

export const toggleShowWarning = (): ToggleShowWarningAction => ({
	type: TOGGLE_SHOW_WARNING,
});

export const resetShowWarning = (
	payload: ResetShowWarningActionPayload,
): ResetShowWarningAction => ({
	type: RESET_SHOW_WARNING,
	payload,
});

export const toggleWarningByType = (payload: string): ToggleWarningByTypeAction => ({
	type: TOGGLE_WARNING_TYPE,
	payload,
});

export const resetEnabledWarningType = (payload: Set<string>): ResetEnabledWarningTypeAction => ({
	type: RESET_ENABLED_WARNING_TYPE,
	payload,
});
