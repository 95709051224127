export type RelationsMap = {
	issueType: Set<number>;
	project: Set<number>;
	sprint: Set<string>;
	version: Set<number>;
};

export const makeRelationsMap = (): RelationsMap => ({
	issueType: new Set<number>(),
	project: new Set<number>(),
	sprint: new Set<string>(),
	version: new Set<number>(),
});

// Wrap the callback with a scheduler which:
// * Uses requestIdleCallback to defer callback execution.
// * Ensures that no more than one call is scheduled at any time.
// If requestIdleCallback is not available (i.e. on IE11) then scheduler uses setTimeout.
// Timeout is set to doubled callback execution time to keep callback time share reasonable.
// Tweak if necessary.
export const relaxedScheduler = (callback: () => void): (() => void) => {
	let executionTime = 25;
	const requestCallback =
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/jira-ssr/no-unchecked-globals-usage
		window.requestIdleCallback || ((f: any) => setTimeout(f, 2 * executionTime));

	let isScheduled = false;
	let scheduleNextCall = false;
	let wrapper: () => void;

	const schedule = () => {
		isScheduled = true;
		requestCallback(wrapper);
	};

	wrapper = () => {
		isScheduled = false;
		const startMark = performance.now();
		callback();
		executionTime = performance.now() - startMark;
		if (scheduleNextCall) {
			scheduleNextCall = false;
			schedule();
		}
	};

	return () => {
		if (scheduleNextCall) return;
		if (isScheduled) {
			scheduleNextCall = true;
			return;
		}
		schedule();
	};
};
