import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	errorFlagTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.goals-error-flag.error-flag-title',
		defaultMessage: 'Something went wrong',
		description: 'Title of error flag.',
	},
	errorFlagDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.goals-error-flag.error-flag-description',
		defaultMessage:
			'There was an error when loading in Atlas goals. Please try refreshing the page.',
		description: 'Description of error flag.',
	},
	errorFlagIconLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.flag-group.goals-error-flag.error-flag-icon-label',
		defaultMessage: 'Error',
		description: 'ARIA label for an error icon.',
	},
});

export default messages;
