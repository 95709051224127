import React from 'react';
import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { select } from 'redux-saga/effects';
import { Text } from '@atlaskit/primitives';
import { FormattedMessage } from '@atlassian/jira-intl';
import docLinks from '@atlassian/jira-portfolio-3-portfolio/src/common/view/doc-link/urls.tsx';
import type { Warning } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types.tsx';
import { getCustomFields } from '../../query/custom-fields/index.tsx';
import type { CustomField } from '../../state/domain/custom-fields/types.tsx';
import messages from './messages.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function inspectForUnCommittedFields(entity: { [key: string]: any }): string[] {
	const uncommitedKeysWithoutCustomFieldContainer = R.without<string>(
		['customFields'],
		R.keys<string>(entity.originals),
	);
	const uncommittedCustomFieldKeys = R.map<string, string>(
		(customFieldId) => `cf-${customFieldId}`,
		R.keys<string>(entity.originals.customFields),
	);

	return R.union(uncommitedKeysWithoutCustomFieldContainer, uncommittedCustomFieldKeys);
}

export function transformUnCommittedFieldToWarnings(
	warningKeys: string[],
	customFields: CustomField[],
): Warning[] {
	return warningKeys.map((key) => {
		if (key.startsWith('cf-')) {
			const customFieldId = key.slice(3);
			const fieldDef = customFields.find((field) => field.id.toString() === customFieldId);
			if (fieldDef) {
				return {
					header: messages.customFieldNotSupportedHeader,
					message: messages.customFieldNotSupportedContent,
					messageValues: { key: fieldDef.title },
				};
			}
		}
		switch (key) {
			case 'storyPoints':
				return {
					header: messages.storyPointsWarningHeader,
					message: messages.storyPointsWarningContent,
					messageValues: {},
				};
			case 'assignee':
				return {
					header: messages.assigneeWarningHeader,
					message: messages.assigneeWarningContent,
					messageValues: {},
				};
			case 'lexoRank':
				return {
					header: messages.lexoRankWarningHeader,
					message: messages.lexoRankWarningContent,
					messageValues: {},
				};
			case 'team':
				return {
					header: messages.teamWarningHeader,
					message: messages.teamWarningContent,
					messageValues: {},
				};
			case 'fixVersions':
				return {
					header: messages.releaseWarningHeader,
					message: messages.releaseWarningContent,
					messageValues: {},
				};
			case 'originalStoryPoints':
				return {
					header: messages.originalStoryPointsHeader,
					messageValues: {},
				};
			case 'originalTimeEstimate':
				return {
					header: messages.originalTimeEstimateHeader,
					messageValues: {},
				};

			case 'timeEstimate':
				return {
					header: messages.timeEstimateHeader,
					messageValues: {},
				};
			default:
				return {
					header: messages.defaultPartialCommitWarningHeader,
					messageValues: { key },
				};
		}
	});
}

export const defaultWarning: Warning = {
	header: messages.defaultWarningHeader,
	message: messages.defaultWarningContent,
	messageValues: {},
};

export function getErrorWarning({
	error,
	errorMessages,
}: {
	error: string;
	errorMessages: string[];
}): Warning {
	switch (error) {
		case 'ENVIRONMENT_ENTITY_NOT_FOUND': {
			return {
				header: messages.notFoundWarningHeader,
				message: messages.notFoundWarningContent,
				messageValues: {},
			};
		}
		case 'UNCOMMITTED_PARENT': {
			return {
				header: messages.uncommittedParentHeader,
				message: messages.uncommittedParentContent,
				messageValues: {},
			};
		}
		case 'SUBTASK_PARENT_NOT_DEFINED': {
			return {
				header: messages.subTaskParentNotDefinedHeader,
				message: messages.subTaskParentNotDefinedContent,
				messageValues: {},
			};
		}
		case 'SUBTASK_IN_WRONG_PROJECT': {
			return {
				header: messages.subTaskInWrongProjectHeader,
				message: messages.subTaskInWrongProjectContent,
				messageValues: {},
			};
		}
		case 'REQUIRED_FIELDS_MISSING': {
			return {
				header: messages.requiredFieldsMissingHeader,
				message: messages.requiredFieldsMissingContent,
				messageValues: {},
			};
		}
		case 'REQUIRE_STATUS_TRANSITION_SCREEN': {
			return {
				header: messages.requiredTransitionScreenMissingHeader,
				message: messages.requiredTransitionScreenMissingContent,
				messageValues: {},
			};
		}
		case 'ISSUE_API_EXCEPTION': {
			if (errorMessages.length > 0) {
				return {
					header: messages.unknownErrorHeader,
					message: messages.apiExceptionContent,
					messageValues: { messages: errorMessages.join(' ') },
				};
			}
			return {
				header: messages.unknownErrorHeader,
				message: messages.unknownErrorHeader,
				messageValues: {},
			};
		}
		case 'RANK_FAILURE_SCHEDULE_ISSUES_PERMISSION_MISSING': {
			return {
				header: messages.permissionMissingHeader,
				message: messages.permissionMissingContent,
				messageValues: {
					learnMoreAboutPermissions: (
						<a href={docLinks.learnMoreAboutPermissions} target="_blank" rel="noopener noreferrer">
							<FormattedMessage {...messages.learnMoreAboutPermissions} />
						</a>
					),
					permissionName: (
						<Text as="strong">
							<FormattedMessage {...messages.scheduleIssuesPermission} />
						</Text>
					),
				},
			};
		}
		default:
			return defaultWarning;
	}
}

export function* inspectForCommitWarnings(
	entity?: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	},
	error?: {
		error: string;
		errorMessages: string[];
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const customFields = yield select(getCustomFields);

	let unCommittedPropertyWarnings: Warning[] = [];
	if (entity) {
		unCommittedPropertyWarnings = transformUnCommittedFieldToWarnings(
			inspectForUnCommittedFields(entity),
			customFields,
		);
	}

	let errorWarning: Warning[] = [];
	if (error) {
		errorWarning = [getErrorWarning(error)];
	}

	return [...unCommittedPropertyWarnings, ...errorWarning];
}
