import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	timescaleCustom: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.time-scale.timescale-custom',
		defaultMessage: 'Custom',
		description: 'Label for a button to select a custom range for the roadmap',
	},
	timescaleCustomRange: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.time-scale.timescale-custom-range',
		defaultMessage: 'Custom range',
		description: 'Label for an option to select a custom range for the roadmap',
	},
	timescaleFrom: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.time-scale.timescale-from',
		defaultMessage: 'From {fromDate}',
		description: 'Label that indicates that the roadmap displayed is from the specified date',
	},
	timescaleTo: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.time-scale.timescale-to',
		defaultMessage: 'To {toDate}',
		description: 'Label that indicates that the roadmap displayed is up to the specified date',
	},
	timescaleWeeks: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.time-scale.timescale-weeks',
		defaultMessage: 'Weeks',
		description: 'Label for a button to adjust the timeframe to fit the roadmap',
	},
	timescaleMonths: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.time-scale.timescale-months',
		defaultMessage: 'Months',
		description: 'Label for a button to adjust the timeframe to fit the roadmap',
	},
	timescaleQuarters: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.time-scale.timescale-quarters',
		defaultMessage: 'Quarters',
		description: 'Label for a button to adjust the timeframe to fit the roadmap',
	},
	timescaleYears: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.time-scale.timescale-years',
		defaultMessage: 'Years',
		description: 'Label for a button to adjust the timeframe to fit the roadmap',
	},
	modalHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.time-scale.modal-header',
		defaultMessage: 'Select custom date range',
		description:
			'Heading of the custom date range modal, where users can select their custom date range',
	},
});
