import React from 'react';
import MenuContent from '../menu-content/view.tsx';
import IssueTypeOptionsProvider from '../options-providers/issue-type/index.tsx';
import { getProjectId } from '../utils.tsx';
import type { Props } from './types.tsx';

const IssueTypeMenu = ({ renderOption, enrichedVersions, selectedOptions }: Props) => {
	const projectId = getProjectId(selectedOptions, enrichedVersions);

	if (!projectId) throw Error('Project value is required');

	return (
		<IssueTypeOptionsProvider projectId={projectId}>
			{(result) => (
				<MenuContent
					renderOption={renderOption}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.issue-type-menu"
					{...result}
				/>
			)}
		</IssueTypeOptionsProvider>
	);
};

export default IssueTypeMenu;
