import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	dateLozenge: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.date-lozenge',
		defaultMessage: 'D',
		description:
			'This is a single letter abbreviation for the word "Date", ideally translations will also be a single character. It is used in the timeline to indicate that a column is being used as either the start or end date of an issue in the plan.',
	},
	advancedFieldsMenuSelectPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.advanced-fields-menu-select-placeholder',
		defaultMessage: 'Choose fields...',
		description: 'Placeholder text displayed in the Choose fields dropdown list',
	},
	advancedFieldsMenuNoFieldsMatchCriteria: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.advanced-fields-menu-no-fields-match-criteria',
		defaultMessage: 'No fields match the search criteria',
		description:
			'Text displayed in the Choose fields dropdown list when no options match the search criteria',
	},
	advancedFieldsMenuAllFieldsSelected: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.advanced-fields-menu-all-fields-selected',
		defaultMessage: 'All fields have been selected',
		description:
			'Text displayed in the Choose fields dropdown list when all options have been selected',
	},
	advancedFieldsMenuCustomFieldLozenge: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.advanced-fields-menu-custom-field-lozenge',
		defaultMessage: 'CUSTOM',
		description: 'Label for custom field lozenge',
	},
	advancedFieldsMenuManageCustomFieldsLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.advanced-fields-menu-manage-custom-fields-link',
		defaultMessage: 'Manage custom fields',
		description:
			'Text for the Manage custom fields link displayed at the bottom of the advanced fields menu',
	},
	advancedFieldsMenuAddFieldTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.advanced-fields-menu-add-field-tooltip',
		defaultMessage: 'Add fields',
		description: 'Label for add fields tooltip',
	},
	advancedFieldsMenuSelectLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.advanced-fields-menu-select-label',
		defaultMessage: 'Select fields',
		description:
			'Aria label for Choose fields combobox that appears on activating Fields button of the advanced fields menu',
	},
});
