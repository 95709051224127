import type { IssueLabels } from './types.tsx';

export const ADD = 'state.domain.issue-labels.ADD' as const;
export const RESET = 'state.domain.issue-labels.RESET' as const;
export const REMOVE = 'state.domain.issue-labels.REMOVE' as const;

export type AddRemoveActionPayload = string;

export type AddAction = {
	type: typeof ADD;
	payload: AddRemoveActionPayload;
};

export type ResetAction = {
	type: typeof RESET;
	payload: IssueLabels;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: AddRemoveActionPayload;
};

export const add = (payload: AddRemoveActionPayload): AddAction => ({
	type: ADD,
	payload,
});

export const reset = (payload: IssueLabels): ResetAction => ({
	type: RESET,
	payload,
});

export const remove = (payload: AddRemoveActionPayload): RemoveAction => ({
	type: REMOVE,
	payload,
});
