import {
	addIssueTypeColour,
	removeIssueTypeColour,
	type RemoveIssueTypeColourActionPayload,
	type UpdateIssueTypeColourActionPayload,
	updateIssueTypeColour,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	addIssueTypeColour: () => {
		dispatch(addIssueTypeColour());
	},
	removeIssueTypeColour: (payload: RemoveIssueTypeColourActionPayload) => {
		dispatch(removeIssueTypeColour(payload));
	},
	updateIssueTypeColour: (payload: UpdateIssueTypeColourActionPayload) => {
		dispatch(updateIssueTypeColour(payload));
	},
});

export default mapDispatchToProps;
