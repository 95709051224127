import * as R from 'ramda';
import { RESET, type ResetAction } from './actions.tsx';
import type { UserPickerOptions, UserPickerOption } from './types.tsx';

const initialState: UserPickerOptions = {
	userList: [],
};

type Action = ResetAction;

export const mergeUsers = (
	existingPeople: UserPickerOption[],
	newPeople: UserPickerOption[],
): UserPickerOption[] => {
	const indexedExistingPeople = R.indexBy(R.prop('value'), existingPeople);
	const indexedNewPeople = R.indexBy(R.prop('value'), newPeople);
	const mergedUsers = { ...indexedExistingPeople, ...indexedNewPeople };

	return R.sortBy((el) => R.toLower(el.label), R.values(mergedUsers));
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: UserPickerOptions = initialState, action: Action) => {
	switch (action.type) {
		case RESET: {
			return { ...state, userList: mergeUsers(state.userList, action.payload) };
		}
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
