import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	startDateLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.date-range-filter.start-date-label',
		defaultMessage: 'Start date',
		description: 'This is the label for the start date input',
	},
	endDateLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.date-range-filter.end-date-label',
		defaultMessage: 'End date',
		description: 'This is the label for the end date input',
	},
	noDatePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.date-range-filter.no-date-placeholder',
		defaultMessage: 'None',
		description:
			'This is displayed when the start or end date is not set for the date range filter',
	},
});
