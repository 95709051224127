import { INCLUDE_COMPLETED_ISSUE_FOR_DAYS } from '@atlassian/jira-portfolio-3-create-new-plan-wizard-drawer/src/common/constants.tsx';
import { tryExtractPlanTitleFromDocument } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/document.tsx';
import {
	DUE_DATE_FIELD,
	ISSUE_INFERRED_DATE_SELECTION,
	TARGET_START_DATE_FIELD,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	RESET,
	type ResetAction,
	UPDATE,
	type UpdateAction,
	ADD_PLAN_SCENARIO,
	type AddPlanScenarioAction,
	ENABLE_PLAN_SCENARIOS,
	type EnablePlanScenariosAction,
	REMOVE_PLAN_SCENARIO,
	type RemovePlanScenarioAction,
	DISABLE_PLAN_SCENARIOS,
	type DisablePlanScenariosAction,
	UPDATE_PLAN_SCENARIO,
	type UpdatePlanScenarioAction,
	UPDATE_AUTO_SCHEDULER_ENABLED,
	type UpdateAutoSchedulerEnabledPayload,
	type updateAutoSchedulerEnabled,
} from './actions.tsx';
import type { PlanInfo } from './types.tsx';

const initialState: PlanInfo = {
	title: tryExtractPlanTitleFromDocument(),
	planningUnit: 'StoryPoints',
	issueSources: [],
	includeCompletedIssuesFor: INCLUDE_COMPLETED_ISSUE_FOR_DAYS,
	autoScheduleConfiguration: {
		ignoreSprints: false,
		ignoreReleases: false,
		ignoreTeams: false,
	},
	syncStartEnabled: false,
	issueInferredDateSelection: ISSUE_INFERRED_DATE_SELECTION.SPRINT,
	baselineStartField: TARGET_START_DATE_FIELD, // Start Date CF not available yet
	baselineEndField: DUE_DATE_FIELD,
	isSamplePlan: false,
	autoSchedulerEnabled: false,
	planStatus: 'ACTIVE',
	planType: undefined,
	creatorAccountId: '',
};

type Action =
	| ResetAction
	| UpdateAction
	| AddPlanScenarioAction
	| RemovePlanScenarioAction
	| EnablePlanScenariosAction
	| DisablePlanScenariosAction
	| UpdatePlanScenarioAction
	| ReturnType<typeof updateAutoSchedulerEnabled>;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: PlanInfo = initialState, action: Action): PlanInfo => {
	switch (action.type) {
		case RESET:
			return { ...action.payload };
		case UPDATE:
			return { ...state, ...action.payload };
		case ENABLE_PLAN_SCENARIOS:
			return {
				...state,
				multiScenarioEnabled: true,
				scenarios: action.payload,
			};
		case ADD_PLAN_SCENARIO:
			return {
				...state,
				scenarios: [action.payload, ...(state.scenarios || [])],
			};
		case UPDATE_PLAN_SCENARIO: {
			if (!state.scenarios) return state;

			const { id, ...values } = action.payload;
			const scenarios = state.scenarios.map((scenario) => {
				if (scenario.id === id) {
					return {
						...scenario,
						...values,
					};
				}
				return scenario;
			});

			return { ...state, scenarios };
		}
		case REMOVE_PLAN_SCENARIO: {
			const { id } = action.payload;
			return {
				...state,
				scenarios: state.scenarios && state.scenarios.filter((scenario) => scenario.id !== id),
			};
		}
		case DISABLE_PLAN_SCENARIOS: {
			const { scenarioToKeepId } = action;
			return {
				...state,
				multiScenarioEnabled: false,
				scenarios:
					state.scenarios && state.scenarios.filter((scenario) => scenario.id === scenarioToKeepId),
			};
		}

		case UPDATE_AUTO_SCHEDULER_ENABLED: {
			return {
				...state,
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				...(action.payload as UpdateAutoSchedulerEnabledPayload),
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
