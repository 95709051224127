import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { IssuePriority, IssuePriorities } from '../../state/domain/issue-priorities/types';

export const getIssuePriorities = (state: State) => state.domain.issuePriorities;

export const getIssuePrioritiesById = createSelector([getIssuePriorities], (issuePriorities) =>
	indexBy(({ id }) => id, Object.values(issuePriorities)),
);

export const getIssuePrioritiesIdList = createSelector([getIssuePriorities], (issuePriorities) => {
	const issuePrioritiesIdList = Object.values(issuePriorities)
		.sort((a, b) => a.sequence - b.sequence)
		.map(({ id }) => id);

	return issuePrioritiesIdList.length ? issuePrioritiesIdList : null;
});
