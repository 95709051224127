import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.non-existing-dialog.heading',
		defaultMessage: 'Save deleted view',
		description: 'Label for view non-existing dialog heading',
	},
	actionGroupHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.non-existing-dialog.action-group-header',
		defaultMessage: 'Do you want to:',
		description: 'Label for view non-existing dialog action group header',
	},
	message: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.non-existing-dialog.message',
		defaultMessage: '<strong>{name}</strong> was deleted by someone else during your session.',
		description: 'The message for view non-existing dialog',
	},
	saveAs: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.non-existing-dialog.save-as',
		defaultMessage: 'Save as a new view',
		description: 'The message for save-as option',
	},
	discardChanges: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.non-existing-dialog.discard-changes',
		defaultMessage: 'Discard your changes',
		description: 'The message for discard-changes option',
	},
});
