import { getIssueSources, getPlan } from '../../query/plan/index.tsx';
import { getProjects } from '../../query/projects/index.tsx';
import type { State } from '../../state/types.tsx';
import type { StateProps } from './types.tsx';

export const mapStateToProps = (state: State): StateProps => ({
	planId: getPlan(state).id,
	projects: getProjects(state),
	issueSources: getIssueSources(state),
});
