import React from 'react';
import { styled } from '@compiled/react';
import Breadcrumbs, { BreadcrumbsItem } from '@atlaskit/breadcrumbs';
import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';
import AkPageHeader from '@atlassian/jira-common-components-page-header/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { OPTIMIZED } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import ApplyOptimisations from '../title-bar/apply-optimisation/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const PageHeader = ({
	mode,
	title,
	actions,
	titleDecorators,
	plan,
	showPlanTitle = false,
	isReadOnly = false,
	isArchived = false,
	withBreadcrumbs = true,
}: Props) => {
	const { formatMessage } = useIntl();

	if (mode === OPTIMIZED) return <ApplyOptimisations />;

	const { id, scenarios, currentScenarioId, title: planTitle, multiScenarioEnabled } = plan;

	const scenarioTitle =
		(scenarios && scenarios.find((s) => s.id === currentScenarioId)?.title) || '';

	const getTitle = () => {
		if (showPlanTitle) {
			return (
				<>
					{planTitle}
					{title && <> / {title}</>}
				</>
			);
		}
		return title;
	};

	const headerTitle = getTitle();

	const breadcrumbs = (
		<Breadcrumbs>
			<BreadcrumbsItem text={formatMessage(messages.breadcrumbTitle)} href="/jira/plans/" />
			{planTitle && (
				<BreadcrumbsItem truncationWidth={300} text={`${planTitle}`} href={`/jira/plans/${id}/`} />
			)}
			{multiScenarioEnabled && scenarioTitle && (
				<BreadcrumbsItem
					truncationWidth={300}
					text={`${scenarioTitle}`}
					href={`/jira/plans/${id}/scenarios/${currentScenarioId}/timeline`}
				/>
			)}
		</Breadcrumbs>
	);

	return (
		<>
			<AkPageHeader
				disableTitleStyles
				truncateTitle
				actions={<FiltersContainer>{actions}</FiltersContainer>}
				breadcrumbs={withBreadcrumbs ? breadcrumbs : undefined}
			>
				<TitleWrapper>
					{headerTitle && <Heading>{headerTitle}</Heading>}
					{isReadOnly && <Lozenge isBold>{formatMessage(messages.readOnly)}</Lozenge>}
					{isArchived && <Lozenge isBold>{formatMessage(messages.archived)}</Lozenge>}
					{titleDecorators}
				</TitleWrapper>
			</AkPageHeader>
		</>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Heading = styled.h1({
	marginTop: 0,
	lineHeight: token('space.400', '32px'),
	outline: 'none',
	overflowX: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	font: token('font.heading.large'),
	fontWeight: token('font.weight.medium', '500'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	display: 'flex',
	gap: token('space.100', '8px'),
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FiltersContainer = styled.div({
	display: 'flex',
	whiteSpace: 'normal',
	gap: token('space.050', '4px'),
});
