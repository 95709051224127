import type { ComponentType } from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import {
	type ViewMode,
	VIEW_MODES,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import type { ColumnId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/field-columns/types.tsx';
import {
	CustomFieldTypes,
	BREAKDOWN_SPRINT_CAPACITY_BY,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import AssigneeCell from './columns/cells/assignee/index.tsx';
import BreakdownCell, { createBreakdownCell } from './columns/cells/breakdown/index.tsx';
import ComponentsCell from './columns/cells/components/index.tsx';
import DateCell, { createDateCell } from './columns/cells/date/index.tsx';
import { DateTypes } from './columns/cells/date/types.tsx';
import {
	IncomingDependenciesCell,
	OutgoingDependenciesCell,
} from './columns/cells/dependencies-cells/index.tsx';
import EstimateCell from './columns/cells/estimate/index.tsx';
import GoalCell from './columns/cells/goal/index.tsx';
import IdeaCell from './columns/cells/ideas/index.tsx';
import IssueStatusCell from './columns/cells/issue-status/index.tsx';
import LabelsCell from './columns/cells/labels/index.tsx';
import ParentCell from './columns/cells/parent/index.tsx';
import PriorityCell from './columns/cells/priority/index.tsx';
import ReleaseCell from './columns/cells/release/index.tsx';
import ReporterCell from './columns/cells/reporter/index.tsx';
import SprintCell from './columns/cells/sprint/index.tsx';
import { issueAttributeName as SprintAttributeName } from './columns/cells/sprint/select/types.tsx';
import TeamCell from './columns/cells/team/index.tsx';
import GoalHeaderPermissionCheck from './header/goals/index.tsx';
import messages, {
	type Message,
	getEstimateColumnTitle,
	getProgressByEstimateColumnTitle,
} from './messages.tsx';
import type { IssueColumn } from './types.tsx';
import { getMinWidth } from './utils.tsx';

export type ColumnProperties = {
	title: Message;
	groupTitle?: Message;
	attribute: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	cell: ComponentType<any>;
	isReadOnly?: boolean;
	isSortable?: boolean;
	isCollapsible?: boolean;
	minWidth?: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	HeaderContent?: ComponentType<any>;
};

type Context = {
	dateConfiguration: DateConfiguration;
	planningUnit: string;
};

const EmptyCell = () => null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDateCell = (context: Context, issueAttribute: string): ComponentType<any> => {
	const { baselineStartField, baselineEndField } = context.dateConfiguration;

	if (baselineStartField.key === issueAttribute) {
		// @ts-expect-error Argument of type 'ConnectedComponentClass<{ (props: any): Element; displayName: string; WrappedComponent: ComponentType<any>; slotsByProps: Partial<Record<string, SlotName>>; }, Omit<...>>' is not assignable to parameter of type 'ComponentType<Props>'.ts(2345)
		return createDateCell(DateCell, DateTypes.Start);
	}

	if (baselineEndField.key === issueAttribute) {
		// @ts-expect-error Argument of type 'ConnectedComponentClass<{ (props: any): Element; displayName: string; WrappedComponent: ComponentType<any>; slotsByProps: Partial<Record<string, SlotName>>; }, Omit<...>>' is not assignable to parameter of type 'ComponentType<Props>'.ts(2345)
		return createDateCell(DateCell, DateTypes.End);
	}
	// @ts-expect-error Argument of type 'ConnectedComponentClass<{ (props: any): Element; displayName: string; WrappedComponent: ComponentType<any>; slotsByProps: Partial<Record<string, SlotName>>; }, Omit<...>>' is not assignable to parameter of type 'ComponentType<Props>'.ts(2345)
	return createDateCell(DateCell, DateTypes.Other);
};

const columnProperties = (
	columnId: ColumnId,
	context: Context,
	viewMode: ViewMode,
	isConfluenceMacro: boolean,
	isEmbed: boolean,
): ColumnProperties | null => {
	const isCollapsible = viewMode !== VIEW_MODES.LIST;
	const minWidth = getMinWidth(columnId, isCollapsible, viewMode);
	const isEmbedOrMacro = isConfluenceMacro || isEmbed;

	switch (columnId) {
		case 'assignee':
			return {
				title: commonMessages.assignee,
				attribute: 'assignee',
				cell: AssigneeCell,
				isSortable: true,
				isCollapsible,
				minWidth,
			};
		case 'reporter':
			return {
				title: commonMessages.reporter,
				attribute: 'reporter',
				cell: ReporterCell,
				isSortable: true,
				isCollapsible,
				minWidth,
			};
		case 'breakdown':
			return {
				title: messages.breakdownColumnHeader,
				attribute: 'breakdown', // <- Not a real attribute
				cell: createBreakdownCell(BreakdownCell, BREAKDOWN_SPRINT_CAPACITY_BY.STATUS),
				isCollapsible,
				minWidth,
			};
		case 'progressByEstimation':
			return {
				title: getProgressByEstimateColumnTitle(context.planningUnit),
				attribute: 'progressByEstimate', // <- Not a real attribute
				cell: createBreakdownCell(BreakdownCell, BREAKDOWN_SPRINT_CAPACITY_BY.ESTIMATE),
				isCollapsible,
				minWidth,
			};
		case 'estimate':
			return {
				title: getEstimateColumnTitle(context.planningUnit),
				attribute: 'estimate',
				cell: EstimateCell,
				isSortable: true,
				isCollapsible,
				minWidth,
			};
		case 'issueStatus':
			return {
				title: messages.issueStatusColumnHeader,
				attribute: 'status',
				cell: IssueStatusCell,
				isSortable: true,
				isCollapsible,
				minWidth,
			};
		case 'release':
			return {
				title: commonMessages.releases,
				attribute: 'fixVersions',
				cell: ReleaseCell,
				isCollapsible,
				minWidth,
			};
		case 'sprint':
			return {
				title: commonMessages.sprint,
				attribute: SprintAttributeName,
				cell: SprintCell,
				isCollapsible,
				minWidth,
			};
		case 'targetStart':
			return {
				title: messages.targetStartColumnHeader,
				attribute: 'targetStart',
				cell: getDateCell(context, 'targetStart'),
				isSortable: true,
				isCollapsible,
				minWidth,
			};
		case 'targetEnd':
			return {
				title: messages.targetEndColumnHeader,
				attribute: 'targetEnd',
				cell: getDateCell(context, 'targetEnd'),
				isSortable: true,
				isCollapsible,
				minWidth,
			};
		case 'dueDate':
			return {
				title: commonMessages.dueDate,
				attribute: 'dueDate',
				cell: getDateCell(context, 'dueDate'),
				isSortable: true,
				isCollapsible,
				minWidth,
			};
		case 'team':
			return {
				title: messages.teamColumnHeader,
				attribute: 'team',
				cell: TeamCell,
				isSortable: true,
				isCollapsible,
				minWidth,
			};
		case 'incomingDependencies':
			return {
				title: fg('plan_timeline_field_column_adjustments__a2f7')
					? messages.blockedByColumnHeader
					: messages.incomingDependenciesColumnHeader,
				attribute: 'incomingDependencies',
				groupTitle: messages.dependenciesColumnsHeader,
				cell: IncomingDependenciesCell,
				isCollapsible,
				minWidth,
			};
		case 'outgoingDependencies':
			return {
				title: fg('plan_timeline_field_column_adjustments__a2f7')
					? messages.blocksColumnHeader
					: messages.outgoingDependenciesColumnHeader,
				attribute: 'outgoingDependencies',
				groupTitle: messages.dependenciesColumnsHeader,
				cell: OutgoingDependenciesCell,
				isCollapsible,
				minWidth,
			};
		case 'components':
			return {
				title: messages.componentsColumnHeader,
				attribute: 'components',
				cell: ComponentsCell,
				isCollapsible,
				minWidth,
			};
		case 'labels':
			return {
				title: messages.labelsColumnHeader,
				attribute: 'labels',
				groupTitle: messages.labelsColumnHeader,
				cell: LabelsCell,
				isCollapsible,
				minWidth,
			};
		case 'parent':
			return {
				title: messages.parentColumnHeader,
				attribute: 'parent',
				groupTitle: messages.parentColumnHeader,
				cell: ParentCell,
				isCollapsible,
				minWidth,
			};
		case 'priority':
			return {
				title: messages.priorityColumnHeader,
				attribute: 'priority',
				groupTitle: messages.priorityColumnHeader,
				cell: PriorityCell,
				isSortable: true,
				isCollapsible,
				minWidth,
			};
		case 'goal':
			return {
				title: messages.goalColumnHeader,
				attribute: 'goal',
				groupTitle: messages.goalColumnHeader,
				cell: GoalCell,
				isSortable: false,
				isCollapsible,
				minWidth,
				HeaderContent: GoalHeaderPermissionCheck,
			};
		case 'ideas':
			return ff('polaris-arj-eap-override') && !isEmbedOrMacro
				? {
						title: messages.ideasColumnHeader,
						attribute: 'ideas',
						groupTitle: messages.ideasColumnHeader,
						cell: IdeaCell,
						isSortable: false,
						isCollapsible,
						minWidth,
					}
				: null;
		case 'addFields':
			return {
				title: messages.addFieldsColumnHeader,
				attribute: 'addFields',
				groupTitle: messages.addFieldsColumnHeader,
				cell: EmptyCell,
				isCollapsible: false,
				minWidth,
			};
		default:
			return null;
	}
};

export const customFieldColumnProperties = (fieldType?: string | null): Partial<IssueColumn> => {
	switch (fieldType) {
		case CustomFieldTypes.DatePicker:
		case CustomFieldTypes.NumberField:
		case CustomFieldTypes.SingleSelect:
			return {
				isSortable: true,
			};
		default:
			return {};
	}
};

export default columnProperties;
