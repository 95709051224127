import last from 'lodash/last';
import property from 'lodash/property';
import sortBy from 'lodash/sortBy';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { IssueType } from '../../state/domain/issue-types/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { Project } from '../../state/domain/projects/types.tsx';
import type { TimelineSprint } from '../sprints/types.tsx';
import { getTargetDatesFromSprints } from '../sprints/utils.tsx';

const getLatestIssueBasedOn =
	(field: 'baselineStart' | 'baselineEnd') =>
	(issues: Issue[]): Issue | null | undefined => {
		const sorted: Issue[] = sortBy(
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			issues.filter((issue) => !!issue[field]) as Required<Issue>[],
			property(field),
		);
		return last(sorted);
	};

export const getIssueStartingLastBasedOnBaselineStart = getLatestIssueBasedOn('baselineStart');

export const getIssueEndingLastBasedOnBaselineEnd = getLatestIssueBasedOn('baselineEnd');

export const getIssueEndingLastBasedOnSprintEndDate = (
	issues: Issue[],
	sprintsWithDates: {
		[key: string]: TimelineSprint[];
	},
): Issue | null | undefined => {
	const issuesWithTargetEndDate = issues.reduce<Array<Issue & { baselineEnd: number }>>(
		(results, issue) => {
			const { rawEndDate } = getTargetDatesFromSprints(
				issue,
				isDefined(issue.team) ? sprintsWithDates[issue.team] || [] : [],
			);

			if (rawEndDate) {
				results.push({ ...issue, baselineEnd: rawEndDate });
			}
			return results;
		},
		[],
	);

	if (issuesWithTargetEndDate.length === 0) {
		return;
	}

	return getIssueEndingLastBasedOnBaselineEnd(issuesWithTargetEndDate);
};

export const getIssueOption = (
	issue: Issue,
	issueTypesById: Record<number | string, IssueType>,
	projectsById: Record<number | string, Project>,
): OptionType => {
	const issueProject = issue.project && projectsById[issue.project];
	const issueProjectAndKey = issueProject
		? `${issueProject.key}${issue.issueKey ? `-${issue.issueKey} ` : ' '}`
		: '';
	return {
		value: issue.id,
		label: `${issueProjectAndKey}${issue.summary}`,
		icon: issueTypesById && issueTypesById[issue.type] && issueTypesById[issue.type].iconUrl,
	};
};

export const mergeOptimizedValueToIssue = (issue: Issue, isOptimizationMode: boolean): Issue => {
	if (isOptimizationMode) {
		return { ...issue, ...issue.optimized };
	}
	return issue;
};
