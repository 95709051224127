import React, { type ComponentType } from 'react';
import { ExperienceTracker as ViewExperienceTracker } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracker-consumer/tracker-base/index.tsx';

export type InjectedProps = {
	onExperienceSuccess?: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withViewExperienceTracker = <Config extends Record<any, any>>(
	WrappedComponent: ComponentType<Config>,
	location: string,
): ComponentType<Config & InjectedProps> => {
	function WithViewExperienceTracker(props: Config) {
		return (
			<ViewExperienceTracker location={location}>
				{({ onExperienceSuccess }) => (
					<WrappedComponent {...props} onExperienceSuccess={onExperienceSuccess} />
				)}
			</ViewExperienceTracker>
		);
	}

	return WithViewExperienceTracker;
};

export default withViewExperienceTracker;
