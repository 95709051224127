import * as R from 'ramda';
import {
	RESET,
	ADD,
	UPDATE,
	REMOVE,
	type ResetAction,
	type AddAction,
	type UpdateAction,
	type RemoveAction,
} from './actions.tsx';
import type { CustomField } from './types.tsx';

const initialState: CustomField[] = [];

type Action = ResetAction | AddAction | UpdateAction | RemoveAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: CustomField[] = initialState, action: Action): CustomField[] => {
	switch (action.type) {
		case RESET:
			return [...action.payload];
		case ADD:
			return [...state, ...action.payload];

		case UPDATE: {
			const { payload } = action;
			return state.map((customField) => {
				if (customField.id === payload.id) {
					return R.merge(customField, payload);
				}
				return customField;
			});
		}

		case REMOVE:
			return state.filter((field) => field.id !== action.payload);
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
