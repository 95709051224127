import type { IssueStatusCategory } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { RESET, type ResetAction } from './actions.tsx';

const initialState: IssueStatusCategory[] = [];

type Action = ResetAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: IssueStatusCategory[] = initialState, action: Action) => {
	switch (action.type) {
		case RESET:
			return [...action.payload];
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
