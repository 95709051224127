.section-message {
	margin: calc(2.5 * var(--jpo-common-base-spacing)) 0;
}

.inline-dialog-container {
	display: inline-block;
	max-width: 100%;
}

.close-icon {
	cursor: pointer;
	position: absolute;
	top: calc(-1.5 * var(--jpo-common-base-spacing));
	right: calc(-2.5 * var(--jpo-common-base-spacing));
}
.issue-option-wrapper {
	padding: var(--jpo-common-base-spacing) 0;
	min-width: auto;
	display: flex;
}

.issue-type-icon {
	display: inline-block;
	vertical-align: middle;
	width: calc(2 * var(--jpo-common-base-spacing));
	min-width: calc(2 * var(--jpo-common-base-spacing));
	height: calc(2 * var(--jpo-common-base-spacing));
	margin-right: var(--jpo-common-base-spacing);
	margin-top: 2px;
}

.issue-label {
	display: block;
	box-sizing: border-box;
}

.link {
	display: flex;
	white-space: nowrap;
	margin-right: var(--jpo-common-base-spacing);
}
