export const REPORT_TIME_TO_INTERACTIVE =
	'command.time-to-interactive.REPORT_TIME_TO_INTERACTIVE' as const;

export type ReportTimeToInteractivePayload = {
	isNewSidebarEnabled: boolean;
	isTransposed?: boolean;
};

export type ReportTimeToInteractiveAction = {
	type: typeof REPORT_TIME_TO_INTERACTIVE;
	payload: ReportTimeToInteractivePayload;
};
