import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import withViewExperienceTracker from '@atlassian/jira-portfolio-3-portfolio/src/common/view/experience-tracking/with-view-experience-tracker/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query.tsx';
import type { StateProps, DispatchProps, OwnProps } from './types.tsx';
import View from './view.tsx';

export default withViewExperienceTracker<OwnProps>(
	connect<StateProps, DispatchProps, OwnProps, State>(mapStateToProps, mapDispatchToProps)(View),
	'portfolio-3.simple-plans.sprints.edit-team-modal',
);
