import type { Entity } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

export const USER_FILTER_ID = 'USER_FILTER_ID' as const;

export type UserFilter = {
	id: typeof USER_FILTER_ID;
	value: string[];
};

export type FilterId = typeof USER_FILTER_ID;

export type Filter = UserFilter;

export type SortBy = 'lastModified';
export type SortDirection = 'ASC' | 'DESC';

export type ExpandedChanges = Record<Entity, string[]>;

export type SelectedChanges = Record<Entity, string[]>;

export type Filters = {
	USER_FILTER_ID: UserFilter;
};

export const QuickSelectOption = {
	MINE: 'MINE',
	TODAY: 'TODAY',
	TIMELINE: 'TIMELINE',
	NONE: 'NONE',
} as const;

export type QuickSelectOption = (typeof QuickSelectOption)[keyof typeof QuickSelectOption];

export type UpdateJira = {
	readonly isDialogOpen: boolean;
	readonly expandedChanges: ExpandedChanges;
	readonly isCommitWarningFlagClosed: boolean;
	readonly selectedChanges: SelectedChanges;
	readonly sortBy: 'lastModified';
	readonly sortDirection: 'ASC' | 'DESC';
	readonly filters: Filters;
	readonly shouldNotifyWatchers: boolean;
	readonly quickSelectOption: QuickSelectOption;
};
