.card {
	border-radius: 5px;
	background-color: var(--ds-surface-raised, var(--adg3-color-N0));
	box-shadow: var(
		--ds-shadow-raised,
		0 1px 1px rgba(9, 30, 66, 0.25),
		0 0 1px 1px rgba(9, 30, 66, 0.13)
	);
}

.cardHeader {
	height: 80px;
	background-position: center;
	background-size: cover;
	border-radius: 5px 5px 0 0;
}

.cardContent {
	padding: calc(var(--jpo-common-base-spacing) * 3);
	display: flex;
	align-items: flex-end;
}

.heading {
	font-size: 20px;
	font-weight: 500;
}

.subheading {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-weight: 400;
	margin: calc(var(--jpo-common-base-spacing) / 2) 0 calc(var(--jpo-common-base-spacing) * 2) 0;
	display: flex;
	align-items: center;
	gap: 5px;
}

.teamInfo {
	flex: 1;
}

.button {
	margin-top: 24px;
	flex-shrink: 0;
}

.loadingOrError {
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.errorContainer {
	text-align: center;
	margin-bottom: calc(var(--jpo-common-base-spacing) * 2);
}
