import type { PayloadError } from 'relay-runtime';
import FetchError, { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { stopCaptureGraphQlErrors } from '@atlassian/jira-relay-errors/src/index.tsx';

export type PayloadErrorWithExtensions = PayloadError & { extensions?: { statusCode?: number } };

export const getGraphQLErrorsForOperation = (
	errorCollector: string,
	operationName: string,
): PayloadErrorWithExtensions[] => {
	const errorsWithMeta = stopCaptureGraphQlErrors(errorCollector);
	if (Array.isArray(errorsWithMeta)) {
		return errorsWithMeta
			.filter((errorWithMeta) => errorWithMeta.meta.operationName === operationName)
			.flatMap((errorWithMeta) => errorWithMeta.errors);
	}
	return [];
};

export const getErrorDetails = (
	error: Error,
): { cause: string; statusCode: number | undefined; traceId?: string } => {
	if (error instanceof ValidationError || error instanceof FetchError) {
		return {
			cause: error.message ?? 'An unknown error has occurred.',
			statusCode: error.statusCode,
			traceId: error.traceId,
		};
	}

	if (error instanceof DOMException && error.name === 'AbortError') {
		// When abort() is called, the fetch() promise rejects with an Error of type DOMException, with name AbortError.
		// See https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort for more details
		return {
			cause: 'Query was aborted.',
			statusCode: 0,
		};
	}

	if (error instanceof SyntaxError) {
		// SyntaxError will usually be thrown when the json format is invalid.
		// Currently, no details are provided (traceId status code or content types).
		return {
			cause: 'Query response payload was invalid and could not be parsed.',
			statusCode: 500,
		};
	}

	return {
		cause: 'An unknown error has occurred.',
		statusCode: undefined,
	};
};

export const sendOperationalEvent = ({
	analyticKey,
	traceId,
	statusCode,
	cause,
	duration,
	sloThreshold,
	pages,
	group,
}: {
	analyticKey: string;
	traceId?: string;
	statusCode: number | undefined;
	cause: string;
	duration: number;
	sloThreshold: number;
	pages: number;
	group: string;
}) => {
	getAnalyticsWebClientPromise().then((client) => {
		const analyticsClient = client.getInstance();

		// SSR doesn't have an analytics client
		if (!analyticsClient) {
			return;
		}

		let statusCodeFamily = 'unknown';
		const statusCodeString = `${statusCode}`;
		if (statusCode === 0 || statusCode === 429) {
			// special status codes
			statusCodeFamily = statusCodeString;
		} else if (statusCodeString.match(/^[12345][0-9]{2}$/)) {
			statusCodeFamily = `${statusCodeString[0]}xx`;
		}

		analyticsClient.sendOperationalEvent({
			action: 'fetch',
			actionSubject: 'relay operation',
			attributes: {
				key: analyticKey,
				traceId,
				statusCode,
				statusCodeFamily,
				cause,
				duration,
				slo: duration <= sloThreshold,
				sloThreshold,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				frontendVersion: window.BUILD_KEY,
				pages,
				group,
			},
			source: 'relayOperation',
		});
	});
};
