import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { useColumnWidths } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';

export const AxisFirstTimeUnit = () => {
	const [columnWidths] = useColumnWidths({ preview: true });
	const [zoomLevel] = useZoomLevel();

	const marginLeft = columnWidths.reduce(
		(totalWidth, curWidth, index) =>
			index < columnWidths.length - 1 ? totalWidth + curWidth : totalWidth,
		0,
	);

	return (
		<ZIndex layer={Z_INDEX_LAYER.AXIS_STICKY_TIME_UNIT}>
			{(zIndex) => (
				<Box
					xcss={zoomLevel ? containerStyles : containerStylesFixed}
					style={{ zIndex, left: marginLeft }}
				>
					<Box
						xcss={layerAlignedStyles}
						/* to be used as a portal for the unit rendering done at view/main/tabs/roadmap/timeline/time-units-bar/utils.tsx */
						id="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.overlay.axis-first-time-unit.sticky-date"
					/>
				</Box>
			)}
		</ZIndex>
	);
};

const containerStyles = xcss({
	position: 'sticky',
	top: 'space.0',
	pointerEvents: 'none',
	height: 'space.0',
});

const containerStylesFixed = xcss({
	position: 'absolute',
	left: 'space.0',
	top: 'space.0',
	pointerEvents: 'none',
	height: 'space.0',
});

const layerAlignedStyles = xcss({
	position: 'relative',
	height: '100%',
});
