import React, { useEffect, useState } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { usePrevious } from '@atlassian/jira-platform-react-hooks-use-previous/src/common/utils/index.tsx';
import {
	groupSortComparator,
	UNASSIGNED_GROUP,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import type {
	Person,
	JiraUser,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/assignees/types.tsx';
import type {
	Group,
	ScopeIssue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import { GROUPING } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { UserSelectItem } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/user-picker/index.tsx';
import DialogContent from '../dialog-content/view.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const createGroupOptionFromAssignee = (
	assignee: Person,
	groups: Group<ScopeIssue>[],
): GroupOption => {
	const getGroupName = (person: Person) => {
		const jiraUser: JiraUser = person.jiraUser;
		if (jiraUser.title.toLowerCase() === UNASSIGNED_GROUP) {
			return UNASSIGNED_GROUP;
		}
		return [jiraUser.title, jiraUser.email].join(' ');
	};
	const startInlineCreateInEmptyGroup = !groups.some(
		({ groupCombination }) => groupCombination && groupCombination.assignee === assignee.personId,
	);
	const { title, email, avatarUrl } = assignee.jiraUser;
	return {
		group: `assignee-${assignee.personId}`,
		groupCombination: { assignee: assignee.personId },
		groupName: getGroupName(assignee),
		formattedTitle: <UserSelectItem email={email} label={title} icon={avatarUrl} />,
		grouping: GROUPING.ASSIGNEE,
		startInlineCreateInEmptyGroup,
	};
};

export const getGroupOptionsFromAssignees = (
	assignees: Person[],
	groups: Group<ScopeIssue>[],
): GroupOption[] =>
	assignees
		.map((person) => createGroupOptionFromAssignee(person, groups))
		.sort(groupSortComparator);

const AssigneeOptions = ({
	groups,
	assignees,
	onGroupClick,
	hierarchyItem,
	assigneeListFetchStatus,
	fetchAssigneeList,
	defaultLoadingState = true,
}: Props) => {
	const { formatMessage } = useIntl();
	// defaultLoadingState uses for story purposes
	const [isLoading, setIsLoading] = useState(defaultLoadingState);

	useEffect(() => {
		fetchAssigneeList({ query: '' });
	}, [fetchAssigneeList]);

	const previousAssigneeListFetchStatus = usePrevious(assigneeListFetchStatus);
	useEffect(() => {
		if (previousAssigneeListFetchStatus === true && assigneeListFetchStatus === false) {
			setIsLoading(false);
		}
	}, [assigneeListFetchStatus, previousAssigneeListFetchStatus]);

	const queryChange = (query: string) => {
		setIsLoading(true);
		fetchAssigneeList({ query });
	};

	return (
		<DialogContent
			groupOptions={getGroupOptionsFromAssignees(assignees, groups)}
			hierarchyItem={hierarchyItem}
			searchPlaceholder={formatMessage(messages.assigneeSearchPlaceholder)}
			onGroupClick={onGroupClick}
			onQueryChange={queryChange}
			isLoading={isLoading}
		/>
	);
};

export default AssigneeOptions;
