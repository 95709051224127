import React from 'react';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { AkFlag as Flag } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export const GoalsErrorFlag = () => {
	const { formatMessage } = useIntl();

	return (
		<Flag
			key="jpo-goals-error-flag"
			id="jpo-goals-error-flag"
			icon={
				<ErrorIcon
					label={formatMessage(messages.errorFlagIconLabel)}
					primaryColor={token('color.icon.danger', colors.R300)}
				/>
			}
			title={formatMessage(messages.errorFlagTitle)}
			description={formatMessage(messages.errorFlagDescription)}
		/>
	);
};
