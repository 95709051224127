import React, { memo, useCallback, useEffect, useRef } from 'react';
import { IconTile } from '@atlaskit/icon';
import ArrowLeftIcon from '@atlaskit/icon/core/arrow-left';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import ArrowLeftCircleIcon from '@atlaskit/icon/core/migration/arrow-left--arrow-left-circle';
import ArrowRightCircleIcon from '@atlaskit/icon/core/migration/arrow-right--arrow-right-circle';
import { token } from '@atlaskit/tokens';
import { IconButton } from '@atlassian/jira-portfolio-3-common/src/icon/index.tsx';
import { OPTIMIZED } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

/** Returns a click handler that is permanently unchanged. */
function useLazyClickHandler(onClick: Props['onClick']) {
	const cachedClickHandlerRef = useRef(onClick);
	useEffect(() => {
		cachedClickHandlerRef.current = onClick;
	}, [onClick]);
	return useCallback(() => cachedClickHandlerRef.current(), []);
}

export default function Arrow({
	direction,
	transparent,
	color,
	issueKey = 0,
	width,
	offsetY,
	margin,
	onClick,
}: Props) {
	const iconColor = transparent === true ? 'transparent' : color;
	const handleClick = useLazyClickHandler(onClick);

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={styles.root}
			style={{
				width,

				top: offsetY,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				marginTop: token('space.100', '8px'),

				left: direction === 'left' ? margin.left : undefined,

				right: direction === 'right' ? margin.right : undefined,
			}}
			data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.arrows.${direction}.${issueKey}`}
		>
			<Inner direction={direction} iconColor={iconColor} onClick={handleClick} />
		</div>
	);
}

/**
 * Returns the inner child for the arrow.
 * Rerendering costs heap, so we need to split and use the memo()
 */
const Inner = memo(
	({
		direction,
		iconColor,
		onClick,
	}: {
		direction: Props['direction'];
		iconColor: string | undefined;
		onClick: Props['onClick'];
	}) => {
		const ArrowIcon = direction === 'left' ? ArrowLeftCircleIcon : ArrowRightCircleIcon;
		// We need the iconColor to render the current icons
		// Refactor iconColor to pass in accepted appearance value by moving this logic out to the consumer when removing isVisualRefreshEnabled
		const iconTileAppearance = iconColor === OPTIMIZED ? 'purple' : 'grayBold';
		if (isVisualRefreshEnabled() && iconColor === 'transparent') {
			// IconTile does not accept transparent as an appearance value
			return null;
		}
		return (
			<IconButton onClick={onClick}>
				{isVisualRefreshEnabled() ? (
					<IconTile
						label=""
						size="24"
						icon={direction === 'left' ? ArrowLeftIcon : ArrowRightIcon}
						shape="circle"
						appearance={iconTileAppearance}
					/>
				) : (
					<ArrowIcon label="" LEGACY_primaryColor={iconColor} />
				)}
			</IconButton>
		);
	},
);
