import {
	openFlyout,
	closeFlyout,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/timeline/release-bar/actions.tsx';
import type { Mode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/roadmap/timeline/release-bar/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { updateFlyout } from '../../../../../../state/ui/main/tabs/roadmap/timeline/release-bar/actions';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	openFlyout: (versionIds: string[], type: Mode) => {
		dispatch(openFlyout(versionIds, type));
	},
	closeFlyout: () => {
		dispatch(closeFlyout());
	},
});

export default mapDispatchToProps;
