import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	illustrationAlt: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.twp-success-message.illustration-alt',
		defaultMessage: 'Illustration',
		description: 'Alt for illustration',
	},
	successMessageTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.twp-success-message.success-message-title',
		defaultMessage: 'You can now plan work for {teamTitle}',
		description: 'title for the success message',
	},
	plansSuccessMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.twp-success-message.plans-success-message',
		defaultMessage:
			'Remember: Plans is a sandbox environment. Any changes you make won’t be reflected in the rest of Jira until you save your changes.',
		description: 'body for the success message dialog',
	},
	planLinksToSavingChanges: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.twp-success-message.plan-links-to-saving-changes',
		defaultMessage: 'How do I save my changes in Plans?',
		description: 'Link to saving changes in Plans',
	},
});
