import React, { Component, useState, type FocusEvent } from 'react';
import Avatar from '@atlaskit/avatar';
import AkSelect, {
	components,
	createFilter as selectCreateFilter,
	CreatableSelect as AKCreatableSelect,
	CheckboxOption,
	CheckboxSelect,
	PopupSelect,
	AsyncSelect,
	type SelectComponentsConfig as AKSelectComponentsConfig,
	type SelectProps as AKSelectProps,
	type StylesConfig,
	type OptionProps,
	type MultiValueRemoveProps,
	type DropdownIndicatorProps,
	type ControlProps,
	type AsyncSelectProps,
} from '@atlaskit/select';
import { layers } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import colors from '../colors/index.tsx';
import type { CSSObject } from '../common/types/css-object.tsx';
import ScrollLock from '../scroll-lock/index.tsx';
import Input from './input.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as stylesFile from './styles.module.css';
import type {
	Props,
	OptionType,
	State,
	ReactSelectControlState,
	ReactSelectGroupHeaderState,
} from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports, @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	AkSelect,
	AsyncSelect,
	components as selectComponents,
	PopupSelect,
	CheckboxOption,
	CheckboxSelect,
	type OptionProps,
	type OptionType,
	type MultiValueRemoveProps,
	type AKSelectComponentsConfig,
	type AKSelectProps,
	type StylesConfig,
	type DropdownIndicatorProps,
	type ControlProps,
};

// Need to annotate selectUtils else Typecheck would give error Exported variable 'selectUtils'
// has or is using name 'Config' from external module "react-select" but cannot be named ts(4023)
export const selectUtils: { createFilter: typeof selectCreateFilter } = {
	createFilter: selectCreateFilter,
};

export const selectOptionWithAvatar = ({ icon, label }: OptionType) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<div className={stylesFile['select-option']}>
		{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
		<div className={stylesFile.avatar}>
			<Avatar size="xsmall" src={icon} borderColor="transparent" />
		</div>
		{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
		<div className={stylesFile.label}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<span className={stylesFile.truncated}>{label}</span>
		</div>
	</div>
);

export const selectIssueOption = ({ icon, label }: OptionType) => (
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
	<div className={stylesFile['select-option']}>
		{icon && (
			<div
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={stylesFile['issue-icon']}
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					backgroundSize: 'contain',
					backgroundImage: `url(${icon})`,
				}}
			/>
		)}
		{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
		<div className={`${stylesFile['issue-summary']} ${stylesFile.truncated}`}>{label}</div>
	</div>
);

export const injectMouseOverStyles = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	styles: any,
	shouldInjectMouseOverStyles: boolean,
	isFocused: boolean,
	isTransparentBackground: boolean,
) => {
	const mouseOver = {
		backgroundColor: colors.N20_TRANSPARENT,
		borderColor: colors.N20_TRANSPARENT,
	};

	const resultStyles = {
		...styles,
		':hover': shouldInjectMouseOverStyles
			? {
					...styles[':hover'],
					...mouseOver,
				}
			: styles[':hover'],
	};

	if (isTransparentBackground) {
		return {
			...resultStyles,
			backgroundColor: isFocused ? styles.backgroundColor : 'transparent',
			borderColor: isFocused ? styles.borderColor : 'transparent',
		};
	}
	return resultStyles;
};

const customStylesFunc = (
	ref: HTMLElement | null | undefined,
	isTransparentBackground: boolean | undefined,
	minSelectWidth: number | undefined,
) => ({
	container: (containerStyles: CSSObject) => ({
		...containerStyles,
		width: '100%',
		height: '100%',
		// need this padding otherwise the select overlaps with the border on the timeline fields

		paddingRight: ff('com.atlassian.rm.jpo.transposition') ? undefined : '1px',
	}),
	control: (controlStyles: CSSObject, state: ReactSelectControlState) => {
		const { isFocused } = state;

		const resultStyles = injectMouseOverStyles(
			controlStyles,
			!isFocused,
			isFocused,
			!!isTransparentBackground,
		);

		if (isTransparentBackground) {
			return {
				...resultStyles,
				padding: 0,
				flexWrap: 'nowrap',
			};
		}
		return resultStyles;
	},
	groupHeading: (groupHeadingStyles: CSSObject, state: ReactSelectGroupHeaderState) => ({
		...groupHeadingStyles,
		color: token('color.text.subtlest', colors.N200),
		padding: `0 ${token('space.100', '8px')} ${token('space.100', '8px')}`,
		display: state.data.label ? 'block' : 'none',
		font: token('font.heading.xxsmall'),
	}),
	menu: (menuStyles: CSSObject) => {
		// when min-width for select is defined we need to avoid hiding dropdown behind right border of the screen
		if (minSelectWidth) {
			const { left = 0 } = (ref && ref.getBoundingClientRect()) || {};
			return {
				...menuStyles,
				minWidth: minSelectWidth,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				right: left + minSelectWidth > window.innerWidth ? 0 : 'auto',
			};
		}
		return menuStyles;
	},
	menuPortal: (menuStyles: CSSObject) => ({
		...menuStyles,
		zIndex: ff('com.atlassian.rm.jpo.transposition') ? layers.dialog() : 10,
	}),
});

// PLEASE NOTE:
// We need to track the state of whether or not the menu is open ourselves because the onMenuOpen prop function is called every time
// the user hits a key. This caused issues (see https://bulldog.internal.atlassian.com/browse/JPOS-2070) so we need to manually
// control the menuIsOpen prop passed to the Select component

// eslint-disable-next-line jira/react/no-class-components
export class Select extends Component<Props, State> {
	// eslint-disable-next-line react/sort-comp
	state: State = {
		menuIsOpen: this.props.menuIsOpen,
	};

	static defaultProps = {
		isTransparentBackground: true,
		menuIsOpen: false,
		placeholder: '',
		scrollLock: false,
		isCreatable: false,
	};

	// eslint-disable-next-line react/sort-comp
	static createFilter: typeof selectCreateFilter = selectCreateFilter;

	ref: HTMLElement | null | undefined;

	handleWindowMouseDown = (e: MouseEvent) => {
		if (!this.state.menuIsOpen) return;
		if (!this.ref) return;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const node: HTMLElement = e.target as HTMLElement;
		if (this.ref.contains(node) || !node.parentNode) return;
		this.onMenuClose();
	};

	onMenuOpen = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('mousedown', this.handleWindowMouseDown);
		const { onMenuOpen } = this.props;
		const { menuIsOpen } = this.state;
		if (menuIsOpen) {
			return;
		}

		this.setState({ menuIsOpen: true });

		/* istanbul ignore else */
		if (onMenuOpen) {
			onMenuOpen();
		}
	};

	UNSAFE_componentWillReceiveProps(nextProps: Props) {
		if (nextProps.menuIsOpen !== this.props.menuIsOpen) {
			this.setState({
				menuIsOpen: nextProps.menuIsOpen,
			});
		}
	}

	onMenuClose = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mousedown', this.handleWindowMouseDown);
		const { onMenuClose } = this.props;

		this.setState({ menuIsOpen: false });

		/* istanbul ignore else */
		if (onMenuClose) {
			onMenuClose();
		}
	};

	onBlur = (e: FocusEvent<HTMLElement>) => {
		const { onBlur } = this.props;
		if (onBlur) {
			onBlur(e);
		}
	};

	onFocus = (e: FocusEvent<HTMLElement>) => {
		const { onFocus } = this.props;
		if (onFocus) {
			onFocus(e);
		}
	};

	render() {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			components = {},
			isDisabled,
			isSearchable,
			isTransparentBackground,
			minSelectWidth,
			placeholder,
			scrollLock,
			styles,
			isCreatable,
		} = this.props;
		const { menuIsOpen } = this.state;

		const customStyles = customStylesFunc(this.ref, isTransparentBackground, minSelectWidth);

		const SelectComponent = isCreatable ? AKCreatableSelect : AkSelect;
		return (
			<div
				ref={(node) => {
					if (node) {
						this.ref = node;
					}
				}}
			>
				<ScrollLock enabled={scrollLock && menuIsOpen} />
				<SelectComponent
					{...this.props}
					// Input props file (node_modules/@types/react-select/src/components/Input.d.ts) was automatically generated and does not contain selectProps, which do exist. Once the component is updated from react-select, the error will be fixed
					// @ts-expect-error - TS2322 - Type '({ className, cx, getStyles, innerRef, isHidden, isDisabled, selectProps, ...props }: InputProps) => JSX.Element' is not assignable to type 'ComponentType<InputProps> | undefined'.
					components={{ Input, ...components }}
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					styles={{ ...customStyles, ...styles } as Record<string, Function>}
					menuIsOpen={menuIsOpen}
					onMenuOpen={this.onMenuOpen}
					onMenuClose={this.onMenuClose}
					onBlur={this.onBlur}
					onFocus={this.onFocus}
					isSearchable={isSearchable}
					placeholder={!isDisabled && placeholder}
				/>
			</div>
		);
	}
}

export const MAX_ASYNC_SELECT_HEIGHT = 300;

export function AsyncSelectWithTimelineStyles(
	props: AsyncSelectProps<OptionType> & {
		minSelectWidth: number;
		isTransparentBackground?: boolean;
	},
) {
	const { minSelectWidth, isTransparentBackground, styles } = props;

	const [divRef, setDivRef] = useState<HTMLElement | null | undefined>(undefined);

	const customStyles = customStylesFunc(divRef, isTransparentBackground, minSelectWidth);

	const menuPlacement = () => {
		const { bottom = 0 } = (divRef && divRef.getBoundingClientRect()) || {};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return bottom + MAX_ASYNC_SELECT_HEIGHT + 1 > window.innerHeight ? 'top' : 'bottom';
	};
	return (
		<div
			ref={(node) => {
				if (node) {
					setDivRef(node);
				}
			}}
		>
			<AsyncSelect
				{...props}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				styles={{ ...customStyles, ...styles } as Record<string, Function>}
				menuPlacement={menuPlacement()}
				maxMenuHeight={MAX_ASYNC_SELECT_HEIGHT}
			/>
		</div>
	);
}
