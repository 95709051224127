import {
	createStore,
	createHook,
	type Action,
	createSubscriber,
	createContainer,
} from '@atlassian/react-sweet-state';

export type State = {
	isOpen: boolean;
};

const actions = {
	toggleMenu:
		(open?: boolean): Action<State> =>
		({ setState, getState }) => {
			setState({
				...getState(),
				isOpen: open ?? !getState().isOpen,
			});
		},
	closeMenu:
		(): Action<State> =>
		({ setState, getState }) => {
			setState({
				...getState(),
				isOpen: false,
			});
		},
};

export const PlanActionMenuStoreContainer = createContainer();

/**
 * This stores the state of the plan action menu. For both the sidebar and the horizontal navigation.
 * Using this one store you can programmatically open and close any plan action menu. This is useful for in onboarding.
 */
export const Store = createStore<State, typeof actions>({
	initialState: {
		isOpen: false,
	},
	actions,
	containedBy: PlanActionMenuStoreContainer,
	name: 'plan-action-menu-store',
});

export const usePlanActionMenuStore = createHook(Store);

export const PlanActionMenuStoreSubscriber = createSubscriber(Store);
