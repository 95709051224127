import { subDays } from 'date-fns';
import { INCLUDE_COMPLETED_ISSUE_FOR_DAYS } from '@atlassian/jira-portfolio-3-create-new-plan-wizard-drawer/src/common/constants.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { Plan } from '../../state/domain/plan/types.tsx';
import type { BacklogBody } from './types.tsx';

export const urls = {
	backlog: '/rest/jpo/1.0/backlog',
} as const;

export const backlogBody = ({
	id: planId,
	currentScenarioId: scenarioId,
	includeCompletedIssuesFor,
}: Plan): BacklogBody => ({
	planId,
	scenarioId,
	filter: {
		includeCompleted: includeCompletedIssuesFor !== 0,
		includeCompletedSince: subDays(
			Date.now(),
			isDefined(includeCompletedIssuesFor)
				? includeCompletedIssuesFor
				: INCLUDE_COMPLETED_ISSUE_FOR_DAYS,
		).getTime(),
		includeIssueLinks: true,
	},
	supportingDataOptions: {
		assignees: true,
		reporters: true,
		externalTeams: true,
		sprints: true,
	},
});
