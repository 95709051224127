import { type MessageDescriptor, defineMessages } from '@atlassian/jira-intl';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default defineMessages({
	chooseLabel: {
		id: 'portfolio-3-portfolio.common.label.choose-label',
		defaultMessage: 'Choose a label',
		description: 'Label for unselected labels cell',
	},
	noLabels: {
		id: 'portfolio-3-portfolio.common.label.no-labels',
		defaultMessage: 'There are no labels in this project',
		description: 'Message to show when there is no labels configured',
	},
	noLabelsLeft: {
		id: 'portfolio-3-portfolio.common.label.no-labels-left',
		defaultMessage: 'No available labels left',
		description: 'Message to show when  all labels are already used up',
	},
	noMatch: {
		id: 'portfolio-3-portfolio.common.label.no-match',
		defaultMessage: 'No results match your search',
		description: 'Message to show when there is no labels matching search query',
	},
	invalidInputWithSpace: {
		id: 'portfolio-3-portfolio.common.label.invalid-input-with-space',
		defaultMessage: "Invalid input, labels can't contain spaces",
		description: 'Message to show when there is no labels because query contains spaces',
	},
	invalidInputWithMaxLength: {
		id: 'portfolio-3-portfolio.common.label.invalid-input-with-max-length',
		defaultMessage: "Invalid input, labels can't have more than 255 characters",
		description:
			'Message to show when there is no labels because input has more than 255 characters',
	},
	newLabel: {
		id: 'portfolio-3-portfolio.common.label.new-label',
		defaultMessage: 'New label',
		description: 'Prompt for new label',
	},
}) as {
	invalidInputWithSpace: MessageDescriptor;
	invalidInputWithMaxLength: MessageDescriptor;
	noMatch: MessageDescriptor;
	noLabelsLeft: MessageDescriptor;
	noLabels: MessageDescriptor;
	chooseLabel: MessageDescriptor;
	newLabel: MessageDescriptor;
};
