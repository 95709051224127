import { useEffect, type FC } from 'react';
import type { Props } from './types.tsx';

/**
 * Lock/unlock scroll to support `position: fixed` hack.
 * This hack is used to prevent popup clipping in components like DatePicker or SingleSelect
 * when they have ancestors with `overflow: hidden` and `position: absolute`.
 * `position: fixed` allows to ignore such container boundaries (not always though), but pins popup
 * to the same position during scroll which breaks UX.
 * By locking scroll when popup is visible we partially mitigate this problem.
 */

const getLockableScrollAreas = () =>
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.querySelectorAll<HTMLElement>('[data-scroll="lockable"]');

let lockCount = 0;

export function addScrollLock() {
	const nodes = getLockableScrollAreas();
	lockCount++;
	Array.from(nodes).forEach((node) => {
		// eslint-disable-next-line no-param-reassign
		node.style.overflowY = 'hidden';
	});
}

export function removeScrollLock() {
	const nodes = getLockableScrollAreas();
	if (lockCount > 0) {
		lockCount--;
	}
	if (lockCount === 0) {
		Array.from(nodes).forEach((node) => {
			// eslint-disable-next-line no-param-reassign
			node.style.overflowY = 'auto';
		});
	}
}

export function isScrollLocked() {
	return lockCount > 0;
}

const useScrollLock = (enabled: boolean) => {
	useEffect(() => {
		if (enabled) {
			addScrollLock();
			return removeScrollLock;
		}
	}, [enabled]);
};

const ScrollLock: FC<Props> = ({ children = null, enabled = true }) => {
	useScrollLock(enabled);
	return children;
};

export default ScrollLock;
