export const getBody = (): HTMLElement => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const body = document.body;
	if (!body) {
		throw new Error('document body is not found');
	}
	return body;
};

// IE11 doesn't support el.remove() method on DOM elements, so we have to use this one approach.
export const removeNode = (node: HTMLElement) => {
	if (node.parentNode) {
		node.parentNode.removeChild(node);
	}
};

export const getViewportSize = (): {
	width: number;
	height: number;
} => {
	let width = 0;
	let height = 0;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (window.innerWidth !== undefined && window.innerHeight !== undefined) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		width = Math.max(width, window.innerWidth);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		height = Math.max(height, window.innerHeight);
	}

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	if (document.documentElement) {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const { clientWidth, clientHeight } = document.documentElement;
		width = Math.max(width, clientWidth);
		height = Math.max(height, clientHeight);
	}

	return { width, height };
};
