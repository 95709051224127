import type { State } from './types.tsx';

export const SET_SYSTEM_INFO = 'state.domain.system.SET_SYSTEM_INFO' as const;

export type SetSystemInfoActionPayload = State;

export type SetSystemInfoAction = {
	type: typeof SET_SYSTEM_INFO;
	payload: SetSystemInfoActionPayload;
};

export const setSystemInfo = (payload: State): SetSystemInfoAction => ({
	type: SET_SYSTEM_INFO,
	payload,
});
