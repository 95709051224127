import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-find-your-look.title',
		defaultMessage: 'Find your look',
		description: 'The title for the spotlight',
	},
	content: {
		id: 'portfolio-3-onboarding.onboarding.spotlight-find-your-look.content',
		defaultMessage:
			'Add a personal touch to your plan by changing how your issues are grouped, sorted or displayed.',
		description: 'The content for the spotlight',
	},
});
