import {
	genericError,
	type GenericErrorActionPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/errors/index.tsx';
import {
	update as updateCustomFieldFilter,
	clear as clearCustomFieldFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/custom-field-filter/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps, OwnProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps, OwnProps> = (
	dispatch,
	props,
) => ({
	onChange(value: string[]) {
		dispatch(
			updateCustomFieldFilter({
				customField: props.customField,
				value,
			}),
		);
	},
	onRequestClear() {
		dispatch(clearCustomFieldFilter({ customField: props.customField }));
	},
	genericError(payload: GenericErrorActionPayload) {
		dispatch(genericError(payload));
	},
});

export default mapDispatchToProps;
