import type { MessageDescriptor } from '@atlassian/jira-intl';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default {
	chooseAssigneePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.update-attribute-form.choose-assignee-placeholder',
		defaultMessage: 'Choose an assignee',
		description: 'Placeholder for assignee picklist',
	},
	chooseParentPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.update-attribute-form.choose-parent-placeholder',
		defaultMessage: 'Choose a parent',
		description: 'Placeholder for parent picklist',
	},
	chooseReleasePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.update-attribute-form.choose-release-placeholder',
		defaultMessage: 'Choose a release',
		description: 'Placeholder for release picklist',
	},
	chooseCrossProjectReleasePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.update-attribute-form.choose-cross-project-release-placeholder',
		defaultMessage: 'Choose a cross-project release',
		description: 'Placeholder for cross-project release picklist',
	},
	chooseSprintPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.update-attribute-form.choose-sprint-placeholder',
		defaultMessage: 'Choose a sprint',
		description: 'Placeholder for sprint picklist',
	},
	chooseTeamPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.update-attribute-form.choose-team-placeholder',
		defaultMessage: 'Choose a team',
		description: 'Placeholder for team picklist',
	},
} as {
	chooseAssigneePlaceholder: MessageDescriptor;
	chooseParentPlaceholder: MessageDescriptor;
	chooseReleasePlaceholder: MessageDescriptor;
	chooseCrossProjectReleasePlaceholder: MessageDescriptor;
	chooseSprintPlaceholder: MessageDescriptor;
	chooseTeamPlaceholder: MessageDescriptor;
};
