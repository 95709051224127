import { getDateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { getColumns } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/query.tsx';
import type { OwnProps } from './types.tsx';

export const mapStateToProps = (state: State, { columnId }: OwnProps) => ({
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	column: getColumns(state).find((column) => column.id === columnId)!,
	dateConfiguration: getDateConfiguration(state),
});
