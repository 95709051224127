import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	fields: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.fields',
		defaultMessage: 'Fields',
		description: 'Text to show vertically when all Fields is collapsed',
	},
	timeline: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.timeline',
		defaultMessage: 'Timeline',
		description: 'Text to show vertically when Timeline is collapsed',
	},
});
