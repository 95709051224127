import React from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import Heading from '@atlaskit/heading';
import { Anchor, Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip, { type TooltipPrimitiveProps, TooltipPrimitive } from '@atlaskit/tooltip';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-associated-issues/src/common/types.tsx';
import {
	useAssociatedIssues,
	useAssociatedIssuesInitialized,
} from '@atlassian/jira-portfolio-3-associated-issues/src/controllers/index.tsx';
import { CellSkeleton } from '@atlassian/jira-portfolio-3-common/src/skeleton/cell.tsx';
import { DEFAULT_FIELD_WIDTH } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Props } from './types.tsx';

const calculateFlexShrink = (names: string[]): number[] => {
	const nameLengths = names.map((name) => (name === undefined ? 0 : name.length));
	const shrinkCoefficient = 0.2; // A smaller number will make the components shrink closer to a similar rate.
	return nameLengths.map((length) => Math.pow(length, shrinkCoefficient));
};

const IssueCardContent = ({ data }: { data: AssociatedIssue }) => (
	<Box>
		<Box xcss={ideaContentCardTitle}>
			<Box xcss={ideaContentCardAvatar}>
				<Avatar src={data.iconUrl} size="xsmall" />
			</Box>
			<Anchor href={`/browse/${data.key}`} target="_blank" xcss={anchorStyles}>
				{data.key}
			</Anchor>
		</Box>
		<Heading as="div" size="xsmall">
			{data.summary}
		</Heading>
	</Box>
);

const Issue = ({ data, flexShrink }: { data: AssociatedIssue; flexShrink: number }) => (
	<Box xcss={container} style={{ flexShrink }}>
		<Tooltip
			content={() => <IssueCardContent data={data} />}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			component={TooltipLikeInlineDialog as React.ComponentType<TooltipPrimitiveProps>}
		>
			{(tooltipProps) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Box {...tooltipProps} xcss={contentWrapper}>
					<Box xcss={avatar}>
						<Avatar src={data.iconUrl} size="xsmall" />
					</Box>
					<Box xcss={summary}>{data.summary}</Box>
				</Box>
			)}
		</Tooltip>
	</Box>
);

const IdeasCell = ({ associatedIssues }: { associatedIssues: AssociatedIssue[] }) => {
	const names = associatedIssues.map((el) => el.summary);
	const values = calculateFlexShrink(names);

	return (
		<Box xcss={ideasCellWrapper}>
			{associatedIssues.map((associatedIssue, index) => (
				<Issue key={associatedIssue.key} data={associatedIssue} flexShrink={values[index]} />
			))}
		</Box>
	);
};

const View = (props: Props) => {
	const [associatedIssues] = useAssociatedIssues();
	const [isInitialized] = useAssociatedIssuesInitialized();

	if (!isInitialized) {
		return <CellSkeleton width={DEFAULT_FIELD_WIDTH.MEDIUM} />;
	}

	if (!associatedIssues || !props.issue.associatedIssueIds) {
		return null;
	}

	return (
		<IdeasCell
			associatedIssues={props.issue.associatedIssueIds
				?.map((issueId) => associatedIssues[issueId])
				.filter((issue) => !!issue)}
		/>
	);
};

export default View;

const container = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingTop: 'space.100',
	paddingBottom: 'space.100',
	paddingLeft: 'space.100',
	minWidth: 'size.300',
	height: '100%',
	flexBasis: 'content',
});

const avatar = xcss({
	paddingLeft: 'space.050',
	flexShrink: 0,
});

const summary = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

const ideasCellWrapper = xcss({
	display: 'flex',
});

const contentWrapper = xcss({
	display: 'flex',
	width: '100%',
});

const ideaContentCardTitle = xcss({
	display: 'flex',
	marginBottom: 'space.150',
});

const ideaContentCardAvatar = xcss({
	marginRight: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-unsafe-style-overrides
const TooltipLikeInlineDialog = styled(TooltipPrimitive)({
	background: 'white',
	borderRadius: token('border.radius', '4px'),
	boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)',
	boxSizing: 'content-box',
	padding: `${token('space.100', '8px')} ${token('space.150', '12px')}`,
	width: '308px',
});

const anchorStyles = xcss({
	textDecoration: 'none',

	':hover': {
		textDecoration: 'underline',
	},
});
