import type { FilterOptionsState } from './types.tsx';

export const TOGGLE_SHOW_FULL_HIERARCHY =
	'state.domain.view-settings.filter-options.TOGGLE_SHOW_FULL_HIERARCHY' as const;
export const TOGGLE_AUTO_EXPAND_TO_SHOW_ISSUES_MATCHING_FILTER =
	'state.domain.view-settings.filter-options.TOGGLE_AUTO_EXPAND_TO_SHOW_ISSUES_MATCHING_FILTER' as const;
export const RESET_FILTER_OPTIONS =
	'state.domain.view-settings.filter-options.RESET_FILTER_OPTIONS' as const;

export type ToggleShowFullHierarchyAction = {
	type: typeof TOGGLE_SHOW_FULL_HIERARCHY;
};

export type ResetFilterOptionsAction = {
	type: typeof RESET_FILTER_OPTIONS;
	payload: FilterOptionsState;
};

export const toggleShowFullHierarchy = (): ToggleShowFullHierarchyAction => ({
	type: TOGGLE_SHOW_FULL_HIERARCHY,
});

export const resetFilterOptions = (payload: FilterOptionsState): ResetFilterOptionsAction => ({
	type: RESET_FILTER_OPTIONS,
	payload,
});
