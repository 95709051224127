import * as R from 'ramda';
import { pickBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	RESET,
	type ResetAction,
	UPDATE,
	type UpdateAction,
	REVERT_FIELDS,
	type RevertFieldsAction,
	REMOVE,
	type RemoveAction,
} from './actions.tsx';
import type { OriginalIssues } from './types.tsx';

type Action = ResetAction | UpdateAction | RevertFieldsAction | RemoveAction;

const initialState: OriginalIssues = {};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: OriginalIssues = initialState, action: Action): OriginalIssues => {
	switch (action.type) {
		case RESET:
			return { ...action.payload };
		case UPDATE: {
			const { id, ...originals } = action.payload;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const mergeValues = (key: any, payloadValue: any, stateValue: any) =>
				key === 'customFields' ? R.merge(payloadValue, stateValue) : stateValue;

			return {
				...state,
				// NOTE merging state into "originals" and not vice versa because we want to keep old value if it was already set (real original)
				[id]: R.mergeWithKey(mergeValues, originals, state[id] || {}),
			};
		}
		case REVERT_FIELDS: {
			const { id, fields } = action.payload;
			const originalIssue = state[id];
			const newOriginalIssue = pickBy((_, key) => !fields.includes(key), originalIssue);

			if (Object.keys(newOriginalIssue).length === 0) {
				return R.dissoc(id, state);
			}

			return {
				...state,
				[id]: { ...newOriginalIssue },
			};
		}
		case REMOVE: {
			return R.omit(action.payload, state);
		}
		default:
			return state;
	}
};
