.container {
	background-color: var(--ds-background-neutral, var(--adg3-color-N40));
	border-radius: 5px;
	display: flex;
	height: 10px;
}

.bar {
	background-color: var(--ds-background-neutral-bold, var(--adg3-color-N500));
	border-radius: 5px;
}
