import type { ViewMode } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';

export const CHANGE_VIEW_MODE = 'state.domain.views.SET_VIEW_MODE' as const;

export type ChangeViewModePayload = {
	mode: ViewMode;
};

export type ChangeViewModeAction = {
	type: typeof CHANGE_VIEW_MODE;
	payload: ChangeViewModePayload;
};

export const changeViewMode = (mode: ViewMode): ChangeViewModeAction => ({
	type: CHANGE_VIEW_MODE,
	payload: { mode },
});
