import dropRight from 'lodash/fp/dropRight';
import sum from 'lodash/fp/sum';
import type { Resizer } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/types.tsx';

export const shrink =
	(minWidths: number[], squeezedWidths?: number[]) =>
	(widths: number[], delta: number): number[] => {
		const result: number[] = [];
		let deductedDelta = delta;

		for (let i = widths.length - 1; i >= 0; i--) {
			const current = widths[i];
			let next = current;

			if (current >= minWidths[i]) {
				next = Math.max(current + deductedDelta, minWidths[i]);
			} else {
				// We keep the current width as-is
			}

			result[i] = next;
			deductedDelta = Math.min(deductedDelta + current - next, 0); // deductedDelta should not be larger than 0 when shrinking
		}

		if (deductedDelta < 0 && squeezedWidths !== undefined) {
			return shrink(squeezedWidths)(result, deductedDelta);
		}

		return result;
	};

export const withLastColumnFitted =
	(viewportWidth: number, /** The last column min width. */ minWidth: number) =>
	(widths: number[]): number[] => {
		const result: number[] = [];
		const lastIndex = widths.length - 1;
		let remaining = viewportWidth;

		for (let i = 0; i < widths.length; i++) {
			const width = widths[i];

			if (i === lastIndex) {
				result[i] = Math.max(remaining, minWidth);
			} else {
				result[i] = width;
			}

			remaining -= widths[i];
		}

		return result;
	};

/**
 * Returns the resizer that resize the columns.
 *
 * When the delta is negative, shrink the widths to their min-widths then to the squeezed min-widths
 */
export const makeResizer =
	(
		viewport: { width: number; height: number },
		getMinWidths: (columnBeingResized: number, widths: number[]) => number[],
		/** When it's allowed to do the squeezing, columns are allowed to be squeeze through their min-widths */
		getSqueezedWidths: (columnBeingResized: number, widths: number[]) => number[] | undefined,
		/**
		 * Determines whether the squeezing is allowed
		 *
		 * - In the TIMELINE mode, the squeezing is only allowed when resizing the last field column.
		 * - In the LIST mode, there is no no squeezing.
		 */
		canSqueeze: (column: number) => boolean,
		prioritizeRestoring: (columnBeingResized: number) => boolean,
		/**
		 * Determines whether the columns are allowed to overflow the viewport,
		 * enable this will enforce the limit the delta when resizing.
		 *
		 * - In the TIMELINE mode, columns are not allowed to overflow the viewport.
		 * - In the LIST mode, columns are allowed to overflow the viewport.
		 */
		overflowAllowed: boolean,
	): Resizer =>
	({ column, delta }) =>
	(widths) => {
		const minWidths = getMinWidths(column, widths);
		const squeezedWidths = getSqueezedWidths?.(column, widths);
		/** The widths of columns on the left side of the active resize handle */
		const left = <T,>(arr: T[]): T[] => arr.slice(0, column + 1);

		/** The widths of columns on the right side of the active resize handle */
		const right = <T,>(arr: T[]): T[] => arr.slice(column + 1);

		let refinedDelta = delta;

		if (!overflowAllowed) {
			// Delta needs to have maximum value now
			/** Maximum delta allowed, when all right columns reach their min-width */
			const maxAllowedDelta = viewport.width - sum(left(widths)) - sum(right(minWidths));

			refinedDelta = Math.min(delta, maxAllowedDelta);
		}

		// When resize any column, prioritize restoring the squeezed columns
		if (prioritizeRestoring(column)) {
			for (let i = 0; i < widths.length; i++) {
				if (widths[i] < minWidths[i] && delta > 0) {
					const current = widths[i];
					const deltaForCurrent = Math.min(refinedDelta, minWidths[i] - widths[i]);
					return makeResizer(
						viewport,
						getMinWidths,
						getSqueezedWidths,
						canSqueeze,
						prioritizeRestoring,
						overflowAllowed,
					)({ column, delta: refinedDelta - deltaForCurrent })([
						...widths.slice(0, i),
						current + deltaForCurrent,
						...widths.slice(i + 1),
					]);
				}
			}
		}

		const leftResized = shrink(left(minWidths), canSqueeze(column) ? squeezedWidths : minWidths)(
			left(widths),
			refinedDelta,
		);

		let resized = [...leftResized, ...right(widths)];

		if (!overflowAllowed) {
			// Deduct the right column widths to prevent overflowing
			const exceeded = sum(resized) - viewport.width;
			resized = [...left(resized), ...shrink(right(minWidths))(right(widths), -exceeded)];
		}

		resized = withLastColumnFitted(viewport.width, minWidths.at(-1) ?? 0)(resized);

		return resized;
	};

/** This wrapper tricks the resizer think the timeline column is fixed size. */
export const withScrollableTimeline =
	(originalResizer: typeof makeResizer): typeof makeResizer =>
	(viewport, getMinWidths, getSqueezedWidths, canSqueeze, prioritizeRestoring) =>
	({ column, delta }) =>
	(widths) => {
		const minTimelineColumnWidth = 32;
		const proxiedMinWidths: typeof getMinWidths = (...args) =>
			dropRight(1)(getMinWidths(...args)).concat(minTimelineColumnWidth);

		let proxiedWidths = dropRight(1)(widths);
		proxiedWidths = proxiedWidths.concat(
			Math.max(viewport.width - sum(proxiedWidths), minTimelineColumnWidth),
		);

		const result = originalResizer(
			viewport,
			proxiedMinWidths,
			getSqueezedWidths,
			canSqueeze,
			prioritizeRestoring,
			false,
		)({ column, delta })(proxiedWidths);
		return dropRight(1)(result).concat(4e6);
	};
