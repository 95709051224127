import React from 'react';
import { SimpleTag } from '@atlaskit/tag';
import Tooltip from '@atlaskit/tooltip';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function Tags(props: Props) {
	const { tooltip, tags, isRemoved, width } = props;

	let componentClass = styles.component;
	if (isRemoved) {
		componentClass = `${componentClass} ${styles.componentRemoved}`;
	}

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.componentsContainer}>
			<Tooltip content={tooltip}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.tagContainer} style={{ maxWidth: width }}>
					{tags.map((tag, i) => (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={componentClass} key={i}>
							<SimpleTag text={tag} />
						</div>
					))}
				</div>
			</Tooltip>
		</div>
	);
}
