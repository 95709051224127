import type { ComponentType, ComponentPropsWithoutRef } from 'react';
import { injectIntl, type IntlShape } from '@atlassian/jira-intl';

export interface IntlProps {
	intl: IntlShape;
}

export function injectIntlWithDefaultProps<
	WrappedComponentType extends ComponentType<
		ComponentPropsWithoutRef<WrappedComponentType> & IntlProps
	>,
>(WrappedComponent: WrappedComponentType) {
	type Props = ComponentPropsWithoutRef<WrappedComponentType>;
	type DefaultProps = WrappedComponentType['defaultProps'];
	return injectIntl<'intl', IntlProps & Omit<Props, keyof DefaultProps> & Partial<DefaultProps>>(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		WrappedComponent as any,
	);
}
