import React, { createContext, type ComponentType, type Context } from 'react';

export type SlotName =
	| 'FieldComponents'
	| 'FieldDatePicker'
	| 'CellSelectWithRollup'
	| 'CellTextField'
	| 'FieldMultiSelectCell'
	| 'FieldSelect'
	| 'FieldAsyncSelect'
	| 'FieldPrioritySelect'
	| 'AdvancedFieldsMenu'
	| 'FilterDependencyGraphFromRoadmap'
	| 'QuickFilter'
	| 'IssueSearchWarningClearFilter'
	| 'NowLine'
	| 'UserPicker'
	| 'FieldUserAvatarCell';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Slots = Partial<Record<SlotName, ComponentType<any>>>;
// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const slots = {} as Record<SlotName, SlotName>;

export const slotNames: SlotName[] = [
	'FieldComponents',
	'FieldDatePicker',
	'CellSelectWithRollup',
	'CellTextField',
	'FieldMultiSelectCell',
	'FieldSelect',
	'FieldAsyncSelect',
	'FieldPrioritySelect',
	'AdvancedFieldsMenu',
	'FilterDependencyGraphFromRoadmap',
	'QuickFilter',
	'IssueSearchWarningClearFilter',
	'NowLine',
	'UserPicker',
	'FieldUserAvatarCell',
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/consistent-type-assertions
const contexts = {} as Record<SlotName, Context<ComponentType<any>>>;

slotNames.forEach((slotName) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const context = createContext<ComponentType<any>>(() => null);
	contexts[slotName] = context;
	slots[slotName] = slotName;
});

export function provideComponents<Props>(
	// eslint-disable-next-line @typescript-eslint/no-shadow
	slots: Slots,
): (WrappedComponent: ComponentType<Props>) => ComponentType<Props> {
	return (WrappedComponent: ComponentType<Props>): ComponentType<Props> =>
		function ComponentSlotsContentsProvider(props: Props) {
			// @ts-expect-error - Type 'Props' is not assignable to type 'IntrinsicAttributes & Props'.
			let acc = <WrappedComponent {...props} />;
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			for (const slotName of Object.keys(slots) as SlotName[]) {
				if (!slotNames.includes(slotName)) {
					throw new TypeError(`slot with name ${slotName} not supported`);
				}

				const { Provider } = contexts[slotName];
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				acc = <Provider value={slots[slotName]!}>{acc}</Provider>;
			}
			return acc;
		};
}

type PropName = string; // for type hinting

export function withSlots(slotsByProps: Partial<Record<PropName, SlotName>>) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (WrappedComponent: ComponentType<any>) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		function HoC(props: any) {
			const render = Object.entries(slotsByProps).reduce(
				(acc, [propName, slotName]) => {
					if (typeof slotName !== 'string') {
						throw new TypeError('slotName must be string');
					}

					if (!slotNames.includes(slotName)) {
						throw new TypeError(`slot with name ${slotName} not supported`);
					}

					const { Consumer } = contexts[slotName];

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					return (injecting: undefined | Record<any, any>) => (
						<Consumer>{(Slot) => acc({ ...injecting, [propName]: Slot })}</Consumer>
					);
				},
				(injecting: Record<PropName, SlotName>) => <WrappedComponent {...props} {...injecting} />,
			);

			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			return render();
		}

		const compName = WrappedComponent.displayName || WrappedComponent.name || '';

		HoC.displayName = `WithSlots(${compName})`;
		HoC.WrappedComponent = WrappedComponent;
		HoC.slotsByProps = slotsByProps;

		return HoC;
	};
}

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
export function useSlots() {}
