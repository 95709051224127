import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	weeks: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.relative-time-range.weeks',
		defaultMessage: '{count, plural, one {Week} other {Weeks}}',
		description: 'Select option label for the week unit',
	},
	months: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.relative-time-range.months',
		defaultMessage: '{count, plural, one {Month} other {Months}}',
		description: 'Select option label for the month unit',
	},
	toNowHeader: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.relative-time-range.to-now-header',
		defaultMessage: 'Show the past',
		description: 'Text for the "to now" header label',
	},
	fromNowHeader: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.relative-time-range.from-now-header',
		defaultMessage: 'Show the next',
		description: 'Text for "from now" header label',
	},
	timeRangeText: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.relative-time-range.time-range-text',
		defaultMessage: 'Selected time range is ',
		description: 'Time range text',
	},
	todayLabel: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.relative-time-range.today-label',
		defaultMessage: 'Today',
		description: 'Text for today label',
	},
	timescaleCustom: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.relative-time-range.timescale-custom',
		defaultMessage: 'Custom',
		description: 'Label for a button to select a custom range for the roadmap',
	},
	timescaleFrom: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.relative-time-range.timescale-from',
		defaultMessage: 'From {fromDate}',
		description: 'Label that indicates that the roadmap displayed is from the specified date',
	},
	timescaleTo: {
		id: 'portfolio-3-custom-date-range.ui.modal-content.relative-time-range.timescale-to',
		defaultMessage: 'To {toDate}',
		description: 'Label that indicates that the roadmap displayed is up to the specified date',
	},
});
