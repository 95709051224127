import { useScrollXSync, useViewportSync } from './utils.tsx';

interface Props {
	/** A large initial offset simulate the infinite scrolling. */
	offset: number;
	columnWidths: number[];
}

// TODO: Includes zoom level syncing here and remove the <ZoomLevelSync />
export const HorizontalScrollingSync = ({ offset, columnWidths }: Props) => {
	useScrollXSync(offset);
	useViewportSync(columnWidths);
	return null;
};
