import isEqual from 'lodash/isEqual';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';

import { fetchAssociatedIssues, fetchAssociatedIssuesById } from '../../services/index.tsx';

import { getAssociatedIssues } from '../selectors/index.tsx';
import type { StoreApi, ContainerProps } from '../types.tsx';
import messages from './messages.tsx';

const getIssueIdsToFetch = (
	issueIds: string[],
	availableIssues: Record<string, unknown> | undefined,
): string[] => {
	if (!issueIds || issueIds.length === 0) {
		return [];
	}
	if (!availableIssues) {
		return issueIds;
	}
	return issueIds.filter((id) => !availableIssues[id]);
};

export const loadMissingAssociatedIssues =
	() =>
	(
		{ getState, setState }: StoreApi,
		{ associatedIssueIds, cloudId, showFlag, intlApi, isEmbedOrMacro }: ContainerProps,
	) => {
		if (!ff('polaris-arj-eap-override') || (ff('polaris-arj-eap-override') && isEmbedOrMacro)) {
			setState({ associatedIssues: {} });
			return;
		}

		if (!associatedIssueIds) {
			return;
		}

		if (
			getState().lastAssociatedIssueIds &&
			isEqual(getState().lastAssociatedIssueIds, associatedIssueIds) &&
			fg('polaris-arj-ideas-limit')
		) {
			return;
		}
		// associatedIssueIds || [] should be cleaned up here with the FG
		const issueIdsToFetch = getIssueIdsToFetch(
			associatedIssueIds || [],
			getAssociatedIssues(getState()),
		);
		if (issueIdsToFetch.length === 0) {
			if (getAssociatedIssues(getState()) === undefined) {
				setState({ associatedIssues: {} });
			}
			return;
		}

		if (fg('polaris-arj-ideas-limit')) {
			fetchAssociatedIssuesById(issueIdsToFetch, cloudId)
				.then((fetchResult) => {
					setState({
						associatedIssues: {
							...getAssociatedIssues(getState()),
							...fetchResult.associatedIssues,
						},
					});
					if (fetchResult.appliedLimit) {
						showFlag({
							type: 'error',
							id: 'jpd-ideas-in-plans-fetch-limit',
							key: 'jpd-ideas-in-plans-fetch-limit',
							title: intlApi.formatMessage(messages.fetchLimitErrorFlagTitle),
							description: intlApi.formatMessage(messages.fetchLimitErrorFlagDescription),
						});
						fireErrorAnalytics({
							meta: {
								packageName: 'jiraPortfolio3AssociatedIssues',
								id: 'fetchAssociatedIssuesLimitExceeded',
								teamName: 'jpd-juno',
							},
							attributes: { requestedIssues: issueIdsToFetch.length },
							error: new Error('Ideas fetch limit exceeded'),
							sendToPrivacyUnsafeSplunk: false,
						});
					}
				})
				.catch((error) => {
					if (ff('polaris.possibility-of-updating-ideas-in-plans')) {
						showFlag({
							type: 'error',
							id: 'jpd-ideas-in-plans-error',
							key: 'jpd-ideas-in-plans-error',
							title: intlApi.formatMessage(messages.errorFlagTitle),
							description: intlApi.formatMessage(messages.errorFlagDescription),
						});
					}
					fireErrorAnalytics({
						meta: {
							packageName: 'jiraPortfolio3AssociatedIssues',
							id: 'fetchAssociatedIssues',
							teamName: 'jpd-juno',
						},
						error,
						sendToPrivacyUnsafeSplunk: false,
					});
				});
			return;
		}

		fetchAssociatedIssues(issueIdsToFetch, cloudId)
			.then((fetchedIssues) => {
				setState({
					associatedIssues: { ...getAssociatedIssues(getState()), ...fetchedIssues },
				});
			})
			.catch((error) => {
				setState({ associatedIssues: {} });

				if (ff('polaris.possibility-of-updating-ideas-in-plans')) {
					showFlag({
						type: 'error',
						id: 'jpd-ideas-in-plans-error',
						key: 'jpd-ideas-in-plans-error',
						title: intlApi.formatMessage(messages.errorFlagTitle),
						description: intlApi.formatMessage(messages.errorFlagDescription),
					});
				}

				fireErrorAnalytics({
					meta: {
						packageName: 'jiraPortfolio3AssociatedIssues',
						id: 'fetchAssociatedIssues',
						teamName: 'jpd-juno',
					},
					error,
					sendToPrivacyUnsafeSplunk: false,
				});
			});
	};
