import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	userMenuTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.custom-user-picker.user-menu-title',
		defaultMessage: 'Choose user',
		description: 'This is a title for user menu',
	},
	userSearchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.custom-user-picker.user-search-placeholder',
		defaultMessage: 'Find users...',
		description: 'This is a placeholder for search',
	},
});
