import React, { type ReactNode, useMemo } from 'react';
import getMeta from '@atlassian/jira-get-meta';
import { context } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/index.tsx';
import { setAnalyticsEvent } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/actions.tsx';
import store from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/store.tsx';
import {
	ContextualAnalyticsData,
	MountEvent,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { useRouter } from '@atlassian/react-resource-router';

/** Provides the Redux store with the analyticsEvent. */
export const AnalyticsInitializer = ({ children }: { children: ReactNode }) => {
	const [{ match }] = useRouter();
	const planId = match.params.planId ?? '';
	const scenarioId = match.params.scenarioId ?? '';

	/**
	 * When the plan is accessed through the Jira search bar, multiple search parameters are added to the
	 * query including the searchSessionId.
	 * In "src/packages/session-tracker/src/view/index.js#109", these parameters are then striped from the
	 * query params and added to the metadata on the history change. The previous session's metadata is also
	 * cleaned on the history change.
	 * During the SPA, transition the app can get rendered multiple times because of the prop changes in the
	 * SPA parent components. Because of this, the searchSessionId is available during the first render and
	 * gets cleaned after that.
	 * We use searchSessionId to track user's activity. To retain this value, we are using the "useMemo" hook
	 * with the "planId". So until the planId changes, it will not re-read the value and keeps the first value
	 * it read.
	 */
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const searchSessionId = useMemo(() => getMeta('ajs-search-session-id'), [planId]);

	const contextAnalyticsDataAttributes = {
		planId,
		scenarioId,
		context: context.PLAN,
		...(searchSessionId && { searchSessionId }),
	};

	return (
		<ContextualAnalyticsData
			sourceType={SCREEN}
			sourceName="portfolio3"
			attributes={contextAnalyticsDataAttributes}
		>
			<MountEvent onMount={(analyticsEvent) => store.dispatch(setAnalyticsEvent(analyticsEvent))} />
			{children}
		</ContextualAnalyticsData>
	);
};
