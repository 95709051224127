import { getIssueStatusById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-statuses/index.tsx';
import { getRollupMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/issues-tree.tsx';
import {
	getSprints,
	getSprintsForIssueSources,
	getSprintsForTeam as getSprintsForTeamQuery,
	getExternalSprintsById,
	getAllSprints,
	getInternalSprintIds,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/index.tsx';
import {
	getTeamsById,
	getTeams,
	getAdditionalTeamsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/index.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { StateProps } from './types.tsx';

export const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	sprints: getSprints(state),
	sprintsByTeam: getSprintsForTeamQuery(state),
	teamsById: getTeamsById(state),
	issueStatuses: getIssueStatusById(state),
	teams: getTeams(state),
	sprintsByIssueSources: getSprintsForIssueSources(state),
	additionalTeamsById: getAdditionalTeamsById(state),
	externalSprintsById: getExternalSprintsById(state),
	internalSprintIds: getInternalSprintIds(state),
	allSprints: getAllSprints(state),
	rollupValues: getRollupMap(state),
});
