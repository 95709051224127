.flyout {
	display: flex;

	& .column {
		margin: 8px;

		& .heading {
			color: var(--ds-text-subtlest, var(--adg3-color-N200));
			margin-bottom: 10px;
			white-space: nowrap;
		}
	}

	& .column:nth-child(2) {
		width: 24px;
	}

	& .value {
		white-space: nowrap;
	}

	& .no-value {
		color: var(--ds-text-subtlest, var(--adg3-color-N200));
	}
}
