// eslint-disable-next-line jira/restricted/redux-saga
import type { Task } from 'redux-saga';
import { type Effect, call, fork, take, cancel } from 'redux-saga/effects';
import { RESET_REDUX_STORE } from '../state/actions.tsx';

type Saga = () => Generator<Effect>;

export type options = { cancelAction?: string };

/**
 * A saga that listens for a specific action to cancel the provided saga and also restarts it
 * This is useful for sagas that need to be restarted when the redux store is reset
 *
 * If needed can extend this to put actions on RESTORE_REDUX_STORE, and also to take in params for the saga when its forked
 *
 * @param saga A saga which will cancel and restart upon a redux action
 * @param action The action to listen for to cancel the saga, defaults to RESET_REDUX_STORE
 */
export function* cancelOnReset(saga: Saga, options?: options): Generator<Effect, void, Task> {
	const { cancelAction = RESET_REDUX_STORE } = { ...options };
	const task = yield fork(saga);
	yield take(cancelAction);
	yield cancel(task);
	yield call(cancelOnReset, saga, options);
}
