import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { CTP_ONBOARDING_STAGE } from './constants.tsx';
import type { State } from './types.tsx';

export const selectCtpOnboardingTour = ({ ctpOnboarding }: State) => {
	const getVisibility = () => {
		if (!ctpOnboarding) return false;
		switch (ctpOnboarding.stage) {
			case CTP_ONBOARDING_STAGE.TIMELINE:
			case CTP_ONBOARDING_STAGE.CREATE_ISSUE:
			case CTP_ONBOARDING_STAGE.TEAMS:
			case CTP_ONBOARDING_STAGE.BOARDS:
				return true;
			case CTP_ONBOARDING_STAGE.CLOSED:
				return false;
			default:
				log.safeErrorWithoutCustomerData(
					'plans.ctp-onboarding.store.getVisibilityError',
					'Something went wrong going to the next stage',
					{
						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						path: window.location.pathname.replace(/\d+/g, '{id}'),
						errorMessage: "Didn't expect to get here",
					},
				);
				return false;
		}
	};

	return {
		stage: ctpOnboarding?.stage,
		planId: ctpOnboarding?.planId,
		visible: getVisibility(),
	};
};
