import React from 'react';
import { HORIZONTAL_SCROLL_OFFSET } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Props } from './types.tsx';

/** Returns an overflowed view to ensure the rows are hoverable with infinity horizontal scrolling. */
export default function Overflowed({ style, children }: Props) {
	return (
		<div
			data-testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.overflowed.overflowed"
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				...style,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				position: 'absolute',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				top: 0,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				bottom: 0,

				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				left: -HORIZONTAL_SCROLL_OFFSET,

				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				right: -HORIZONTAL_SCROLL_OFFSET,
			}}
		>
			{children}
		</div>
	);
}

/** Restores the position and the sizing of the overflowed. */
Overflowed.Restore = function RestoredOverflowed({ style, children }: Props) {
	return (
		<div
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				...style,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				position: 'absolute',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				top: 0,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				bottom: 0,

				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				left: HORIZONTAL_SCROLL_OFFSET,

				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				right: HORIZONTAL_SCROLL_OFFSET,
			}}
		>
			{children}
		</div>
	);
};

if (process.env.NODE_ENV === 'development') {
	Object.defineProperty(Overflowed.Restore, 'name', { value: 'Overflowed.Restore' });
}
