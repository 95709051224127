import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	shareDialogHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.share-dialog-header',
		defaultMessage: 'Share plan',
		description: 'It is a share dialog header text.',
	},
	urlLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.url-label',
		defaultMessage: 'Copy the link to share this plan:',
		description: 'It is a label for url input field.',
	},
	generatingUrl: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.generating-url',
		defaultMessage: 'Generating ...',
		description: 'It is a label for url input field during url generation.',
	},
	copyButtonText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.copy-button-text',
		defaultMessage: 'Copy',
		description: 'It is a label for url copy button.',
	},
	copiedButtonText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.copied-button-text',
		defaultMessage: 'Copied!',
		description: 'It is a label for url copied button.',
	},
	accessControlIcon: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.access-control-icon',
		defaultMessage: 'Access Control:',
		description: 'Label used for the aria-label attribute of the access control icon.',
	},
	accessControlNoteIcon: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.access-control-note-icon',
		defaultMessage: 'note',
		description: 'Label used for the aria-label attribute of the access control info icon.',
	},
	accessControlDescriptionTimeline: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.access-control-description-timeline',
		defaultMessage:
			'Anyone with access to this plan will be able to {action} this timeline. It will not be editable from the link (read-only).',
		description: 'It is a message shown next to access control icon.',
	},
	accessControlMacroDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.access-control-macro-description',
		defaultMessage:
			'The macro shares a view-only version of your plan. Viewers won’t be able to make changes from your Confluence page.',
		description: 'It is a message shown next to access control icon.',
	},
	accessControlView: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.access-control-view',
		defaultMessage: 'view',
		description: 'It is a message shown next to access control icon.',
	},
	confluenceTimelineMacroSharingMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.confluence-timeline-macro-sharing-message',
		defaultMessage: 'Share your timeline to a Confluence page.',
		description: 'Confluence macro sharing message in Timeline tab',
	},
	confluenceDependencyMacroSharingMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.confluence-dependency-macro-sharing-message',
		defaultMessage: 'Share your dependencies view to a Confluence page.',
		description: 'Confluence macro sharing message in Dependency tab',
	},
	confluenceMacroSharingInstructions: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.confluence-macro-sharing-instructions',
		defaultMessage: 'Copy the link below, and paste it into your Confluence page. {link}.',
		description: 'Confluence macro sharing instructions',
	},
	confluenceMacroIph: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.confluence-macro-iph',
		defaultMessage: 'Read more about sharing a plan to Confluence',
		description: 'Text for Viewing confluence macro instructions in IPH',
	},
	exportCsvIntro: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-intro',
		defaultMessage:
			'Create a spreadsheet from your plan that can be opened with programs like Excel.',
		description: 'Introduction text for the CSV export option in the Share dialog',
	},
	plansExportCsvIntro2: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.plans-export-csv-intro-2',
		defaultMessage:
			'When you export your plan to .csv, Plans will only export issues that are currently visible in your plan. Use filters, view settings, and sorting options to include or exclude issues from your .csv file.',
		description: 'Introduction text for the CSV export option in the Share dialog',
	},
	exportCsvButton: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-button',
		defaultMessage: 'Export to .csv',
		description: 'Label for the "Export as CSV" button in the Share dialog',
	},
	noIssuesToExportTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.no-issues-to-export-tooltip',
		defaultMessage: 'There are no issues to export',
		description: 'Tooltip displayed over the disabled "Export as CSV" button in the Share dialog',
	},
	exportCsvColumnHeadingHierarchy: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-hierarchy',
		defaultMessage: 'Hierarchy',
		description: 'Column heading of the CSV file for the "Hierarchy" field',
	},
	exportCsvColumnHeadingTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-title',
		defaultMessage: 'Title',
		description: 'Column heading of the CSV file for the "Title" field',
	},
	exportCsvColumnHeadingProject: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-project',
		defaultMessage: 'Project',
		description: 'Column heading of the CSV file for the "Project" field',
	},
	exportCsvColumnHeadingReleases: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-releases',
		defaultMessage: 'Releases',
		description: 'Column heading of the CSV file for the "Releases" field',
	},
	exportCsvColumnHeadingTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-team',
		defaultMessage: 'Team',
		description: 'Column heading of the CSV file for the "Team" field',
	},
	exportCsvColumnHeadingSprint: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-sprint',
		defaultMessage: 'Sprint',
		description: 'Column heading of the CSV file for the "Sprint" field',
	},
	exportCsvColumnHeadingTargetStartDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-target-start-date',
		defaultMessage: 'Target start date',
		description: 'Column heading of the CSV file for the "Target start date" field',
	},
	exportCsvColumnHeadingTargetEndDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-target-end-date',
		defaultMessage: 'Target end date',
		description: 'Column heading of the CSV file for the "Target end date" field',
	},
	exportCsvColumnHeadingEstimateDays: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-estimate-days',
		defaultMessage: 'Estimates (d)',
		description: 'Column heading of the CSV file for the "Estimate (d)" field',
	},
	exportCsvColumnHeadingEstimateHours: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-estimate-hours',
		defaultMessage: 'Estimates (h)',
		description: 'Column heading of the CSV file for the "Estimate (h)" field',
	},
	exportCsvColumnHeadingStoryPoints: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-story-points',
		defaultMessage: 'Story points',
		description: 'Column heading of the CSV file for the "Story points" field',
	},
	exportCsvColumnHeadingParent: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-parent',
		defaultMessage: 'Parent',
		description: 'Column heading of the CSV file for the "Parent" field',
	},
	exportCsvColumnHeadingPriority: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-priority',
		defaultMessage: 'Priority',
		description: 'Column heading of the CSV file for the "Priority" field',
	},
	exportCsvColumnHeadingLabels: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-labels',
		defaultMessage: 'Labels',
		description: 'Column heading of the CSV file for the "Labels" field',
	},
	exportCsvColumnHeadingComponents: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-components',
		defaultMessage: 'Components',
		description: 'Column heading of the CSV file for the "Components" field',
	},
	exportCsvColumnHeadingGoalName: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-goal-name',
		defaultMessage: 'Goal',
		description:
			'Column heading of the CSV file for the "Goal" field, specifically, the goal name only.',
	},
	exportCsvColumnHeadingGoalStatus: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-goal-status',
		defaultMessage: 'Goal status',
		description:
			'Column heading of the CSV file for the "Goal" field, specifically, the goal status only.',
	},
	exportCsvColumnHeadingIdea: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-idea',
		defaultMessage: 'Idea',
		description: 'Column heading of the CSV file for the "Idea" field',
	},
	exportCsvColumnHeadingIssueKey: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-issue-key',
		defaultMessage: 'Issue key',
		description: 'Column heading of the CSV file for the "Issue key" field',
	},
	exportCsvColumnHeadingIssueStatus: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-issue-status',
		defaultMessage: 'Issue status',
		description: 'Column heading of the CSV file for the "Issue status" field',
	},
	exportCsvColumnHeadingProgressPercentage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-progress-percentage',
		defaultMessage: 'Progress (%)',
		description: 'Column heading of the CSV file for the "Progress (%)" field',
	},
	exportCsvColumnHeadingProgressCompletedDays: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-progress-completed-days',
		defaultMessage: 'Progress completed (d)',
		description: 'Column heading of the CSV file for the "Progress completed (d)" field',
	},
	exportCsvColumnHeadingProgressCompletedHours: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-progress-completed-hours',
		defaultMessage: 'Progress completed (h)',
		description: 'Column heading of the CSV file for the "Progress completed (h)" field',
	},
	exportCsvColumnHeadingProgressCompletedStoryPoints: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-progress-completed-story-points',
		defaultMessage: 'Progress completed (sp)',
		description: 'Column heading of the CSV file for the "Progress completed (sp)" field',
	},
	exportCsvColumnHeadingProgressRemainingDays: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-progress-remaining-days',
		defaultMessage: 'Progress remaining (d)',
		description: 'Column heading of the CSV file for the "Progress remaining (d)" field',
	},
	exportCsvColumnHeadingProgressRemainingHours: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-progress-remaining-hours',
		defaultMessage: 'Progress remaining (h)',
		description: 'Column heading of the CSV file for the "Progress remaining (h)" field',
	},
	exportCsvColumnHeadingProgressRemainingStoryPoints: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-progress-remaining-story-points',
		defaultMessage: 'Progress remaining (sp)',
		description: 'Column heading of the CSV file for the "Progress remaining (sp)" field',
	},
	exportCsvColumnHeadingProgressIssueCountPercentage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-progress-issue-count-percentage',
		defaultMessage: 'Progress (%) issue count (IC)',
		description: 'Column heading of the CSV file for the "Progress (%) issue count (IC)" field',
	},
	exportCsvColumnHeadingToDoIssueCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-to-do-issue-count',
		defaultMessage: 'To do IC',
		description: 'Column heading of the CSV file for the "To do IC" field',
	},
	exportCsvColumnHeadingInProgressIssueCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-in-progress-issue-count',
		defaultMessage: 'In progress IC',
		description: 'Column heading of the CSV file for the "In progress IC" field',
	},
	exportCsvColumnHeadingDoneIssueCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-done-issue-count',
		defaultMessage: 'Done IC',
		description: 'Column heading of the CSV file for the "Done IC" field',
	},
	exportCsvColumnHeadingTotalIssueCount: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-total-issue-count',
		defaultMessage: 'Total IC',
		description: 'Column heading of the CSV file for the "Total IC" field',
	},

	exportCsvColumnHeadingRollUp: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-column-heading-roll-up',
		defaultMessage: '(roll-up)',
		description: 'Column part of heading of the CSV file added for roll-up values',
	},
	exportCsvExternalLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-external-label',
		defaultMessage: '[EXT]',
		description: 'Label appended to the external team or sprint name of an issue',
	},
	exportCsvCompletedLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-completed-label',
		defaultMessage: '[COMPLETED]',
		description: 'Label appended to the completed team or sprint name of an issue',
	},
	reportModeMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.report-mode-message',
		defaultMessage:
			'When viewing a plan from a shared link, you can only export data to a .csv file.',
		description: 'Message to display when share dialog is accessed in report mode.',
	},
	exportCsvFilename: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.export-csv-filename',
		defaultMessage: 'Plans {planTitle}',
		description: 'Filename used to export csv',
	},
	confluenceUrlLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.components.confluence-url-label',
		defaultMessage: 'Confluence URL',
		description:
			'This aria-label is associated with an input field where users are expected to get the URL of their Confluence page.',
	},
});

export default messages;
