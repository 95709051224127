import React, { type ComponentType, useState, useEffect } from 'react';
import { FlagGroup as AkFlagGroup } from '@atlassian/jira-flags';
import {
	ISSUE_LIMIT_WARNING,
	PROJECT_LIMIT_WARNING,
	REPARENT_TMP_TO_EXTERNAL_WARNING,
	REPARENT_CMP_TO_TMP_WARNING,
	ISSUE_SEARCH_WARNING,
	SORTED_PREVENT_ISSUE_RANKING_WARNING,
	NO_JSW_ACCESS_FOR_USER,
	GOALS_ERROR,
	type Flags,
	type FlagKey,
} from '../../state/domain/flags/types.tsx';
import {
	type Sorting,
	defaultSorting,
} from '../../state/domain/view-settings/visualisations/types.tsx';

import { useRedirectToSpaPlanPage } from '../redirect-to-spa-plan-page/index.tsx';
// Explicit functions because if Flag is not a direct descendant of FlagGroupResponsive, then it wont be dismissable. This includes injectIntl, connect etc.
import { GoalsErrorFlag } from './goals-error-flag/view.tsx';
import { getIssueLimitWarningFlag } from './issue-limit-warning-flag/view.tsx';
import { getIssueRankWarningFlag } from './issue-rank-warning-flag/view.tsx';
import { getIssueSearchWarningFlag } from './issue-search-warning-flag/view.tsx';
import { getJSWNoAccessErrorFlag } from './jsw-no-access-error-flag/view.tsx';
import { getProjectLimitWarningFlag } from './project-limit-warning-flag/view.tsx';
import { getReparentCMPWarningFlag } from './reparent-cmp-to-tmp-warning-flag/view.tsx';
import { getReparentTMPWarningFlag } from './reparent-tmp-to-external-warning-flag/view.tsx';
import type { Props, GetFlag } from './types.tsx';

const applyFlags = (
	flags: Flags,
	sortByRank: (arg1: { sorting: Sorting }) => void,
	redirectToSpaPlanPage: (page: string) => void,
): Array<GetFlag> =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	flags.reduce<Array<any>>((accum, { key, context = {} }) => {
		switch (key) {
			case NO_JSW_ACCESS_FOR_USER: {
				return accum.concat(getJSWNoAccessErrorFlag);
			}
			case ISSUE_SEARCH_WARNING: {
				return accum.concat(getIssueSearchWarningFlag);
			}
			case REPARENT_TMP_TO_EXTERNAL_WARNING: {
				return accum.concat(() => getReparentTMPWarningFlag({ context }));
			}
			case REPARENT_CMP_TO_TMP_WARNING: {
				return accum.concat(() => getReparentCMPWarningFlag({ context }));
			}
			case ISSUE_LIMIT_WARNING: {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				return accum.concat((Description?: ComponentType<Record<any, any>>) =>
					getIssueLimitWarningFlag(Description, redirectToSpaPlanPage),
				);
			}
			case PROJECT_LIMIT_WARNING: {
				return accum.concat(getProjectLimitWarningFlag);
			}
			case SORTED_PREVENT_ISSUE_RANKING_WARNING: {
				return accum.concat(() => getIssueRankWarningFlag({ sortByRank }));
			}
			case GOALS_ERROR: {
				return accum.concat(GoalsErrorFlag);
			}
			default:
				return accum;
		}
	}, []);

const FlagGroup = ({ flags, onDismissFlag, onChangeSort, sorting }: Props) => {
	const [shownFlags, setShownFlags] = useState<Array<GetFlag>>([]);
	const redirectToSpaPlanPage = useRedirectToSpaPlanPage();

	useEffect(
		() => {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const sortByRank = ({ sorting }: { sorting: Sorting }) => {
				onChangeSort({ sorting });
				onDismissFlag({ key: SORTED_PREVENT_ISSUE_RANKING_WARNING });
			};
			const filteredFlags = applyFlags(flags, sortByRank, redirectToSpaPlanPage);
			setShownFlags(filteredFlags);
		},
		// We dont want to rerun on new redirectToSpaPlanPage assignment
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[flags, onDismissFlag, onChangeSort],
	);

	useEffect(() => {
		if (
			sorting.field === defaultSorting.field &&
			flags.some(({ key }) => key === SORTED_PREVENT_ISSUE_RANKING_WARNING)
		) {
			onDismissFlag({ key: SORTED_PREVENT_ISSUE_RANKING_WARNING });
		}
	}, [sorting, flags, onDismissFlag]);

	const onDismissed = (flagKey: string | number) => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		onDismissFlag({ key: flagKey.toString() as FlagKey });
	};

	return (
		<AkFlagGroup onDismissed={onDismissed}>{shownFlags.map((getFlag) => getFlag())}</AkFlagGroup>
	);
};

export default FlagGroup;
