.markerOld {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2px;
	margin-bottom: 2px;
	min-width: 16px;
	height: 16px;
	border-radius: 8px;
	overflow: hidden;
	user-select: none;
}

.marker {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	min-width: 24px;
	height: 24px;
	overflow: hidden;
	user-select: none;
}

.marker-default > span {
	color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.marker-offtrack > span {
	color: var(--ds-text-danger, var(--adg3-color-R400));
}

.marker-none > span {
	color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.marker-dragging {
	visibility: hidden;
	outline: none;
}

.hitbox-left {
	margin-right: -4px;
	padding-right: 4px;
}

.hitbox-right {
	margin-left: -4px;
	padding-left: 4px;
}
