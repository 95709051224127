// eslint-disable-next-line jira/restricted/react
import React, { PureComponent } from 'react';
import { FormattedMessage, injectIntl, type IntlShape } from '@atlassian/jira-intl';
import { weekdayFormat } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import { formatTimestampWithIntl } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class DateFieldView extends PureComponent<Props & { intl: IntlShape }> {
	static defaultProps = {};

	render() {
		const { startOrEnd, date, isRolledUpValue, intl } = this.props;

		let formattedDate;

		if (!isDefined(date)) {
			if (startOrEnd === 'start') {
				formattedDate = <FormattedMessage {...messages.noStartDate} />;
			} else {
				formattedDate = <FormattedMessage {...messages.noEndDate} />;
			}
		} else {
			formattedDate = formatTimestampWithIntl(intl, date, weekdayFormat);
		}

		return (
			<span
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.dateField}
				data-testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.row.date-field.date-field"
			>
				{isRolledUpValue === true && <FormattedMessage {...messages.rolledUpLabel} />}
				{formattedDate}
			</span>
		);
	}
}

export default injectIntl(DateFieldView);
