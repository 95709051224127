export const STORE_ADDED_TWP_TEAM =
	'state.ui.main.tabs.teams.create-team.STORE_ADDED_TWP_TEAM' as const;
export const RESET_ADDED_TWP_TEAM =
	'state.ui.main.tabs.teams.create-team.RESET_ADDED_TWP_TEAM' as const;
export const LOAD_CREATE_TWP_FORM =
	'state.ui.main.tabs.teams.create-team.LOAD_CREATE_TWP_FORM' as const;
export const RESET_CREATE_TWP_FORM =
	'state.ui.main.tabs.teams.create-team.RESET_CREATE_TWP_FORM' as const;

type AddedTWPTeamPayload = {
	teamTitle: string;
	teamId: string;
};
export type StoreAddedTwpTeamAction = {
	type: typeof STORE_ADDED_TWP_TEAM;
	payload: AddedTWPTeamPayload;
};
export type ResetAddedTwpTeamAction = {
	type: typeof RESET_ADDED_TWP_TEAM;
};
export type LoadCreateTwpFormAction = {
	type: typeof LOAD_CREATE_TWP_FORM;
};
export type ResetCreateTwpFormAction = {
	type: typeof RESET_CREATE_TWP_FORM;
};

export const storeAddedTwpTeam = (payload: AddedTWPTeamPayload): StoreAddedTwpTeamAction => ({
	type: STORE_ADDED_TWP_TEAM,
	payload,
});
export const resetAddedTwpTeam = (): ResetAddedTwpTeamAction => ({
	type: RESET_ADDED_TWP_TEAM,
});
export const loadCreateTwpForm = (): LoadCreateTwpFormAction => ({
	type: LOAD_CREATE_TWP_FORM,
});
export const resetCreateTwpForm = (): ResetCreateTwpFormAction => ({
	type: RESET_CREATE_TWP_FORM,
});
