import type { Effect } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import { extractStartTime } from '@atlassian/jira-portfolio-3-common/src/analytics/utils.tsx';
import type {
	HierarchyInfo,
	LoadingLimitsInfoType,
	IssueStatusCategoryInfo,
	PlanInfo,
	SavedViewsInfo,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { PROJECT_LIMIT_EXCEPTION } from '../../state/domain/flags/types.tsx';
import type { PlanDefaults } from '../../state/domain/plan-defaults/types.tsx';
import type {
	AgileInfoType,
	DependencySettingsInfo,
	GenericReportInfoType,
	IssueLinkTypesInfo,
	SystemInfoType,
} from '../../state/domain/system/types.tsx';
import { type JsonResponse, json } from '../http/index.tsx';
import type { PluginUserInfoType, InfoMetadataResponseBody, SelectOption } from './api.tsx';

export type PageDataType = {
	systemInfo: SystemInfoType;
	agileInfo: AgileInfoType;
	issueLinkTypesInfo: IssueLinkTypesInfo;
	dependencySettingsInfo: DependencySettingsInfo;
	hierarchyInfo: HierarchyInfo;
	issueStatusCategoryInfo: IssueStatusCategoryInfo;
	planDefaultsInfo: PlanDefaults;
	planInfo: PlanInfo;
	reportInfo?: GenericReportInfoType;
	userProperties: PluginUserInfoType;
	loadingLimitsInfo: LoadingLimitsInfoType;
	savedViewsInfoFull: SavedViewsInfo;
	customFieldsOptions?: Array<{ customFieldId: number; options: SelectOption[] }>;
	isAtlasConnectInstalled: boolean;
	hasAtlasPermissions: boolean;
};

export type BasicRequiredPlanInfo = {
	planId: number;
	scenarioId: number;
	reportInfo?: GenericReportInfoType;
};

export type GetPageDataResults = PageDataType | typeof PROJECT_LIMIT_EXCEPTION;

export function* getPageDataFromInfoMetadata(
	basicPlanInfo?: BasicRequiredPlanInfo, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Promise<Response> | Effect, GetPageDataResults, any> {
	if (!isDefined(basicPlanInfo)) {
		throw new Error('Basic plan info missing for non-wrm page data loading');
	}

	const { planId, scenarioId, reportInfo } = basicPlanInfo;

	let response: JsonResponse<InfoMetadataResponseBody> | undefined;
	const url = '/rest/jpo/1.0/info/metadata';

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const earlyPreloadInfoMetadataPromise = window.__ARJ_INFO_METADATA_PROMISE__;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	delete window.__ARJ_INFO_METADATA_PROMISE__;

	if (earlyPreloadInfoMetadataPromise) {
		const responseObj = yield earlyPreloadInfoMetadataPromise;
		const ok = responseObj.ok;
		const data = yield responseObj.json();

		response = {
			ok,
			data,
		};

		/**
		 * Recording the performance information of the API before returning
		 * the response.
		 */
		const start = Math.floor(
			extractStartTime('jira.fe-spa.advanced-roadmaps.early-preload.info-metadata:start'),
		);
		const end = Math.ceil(
			extractStartTime('jira.fe-spa.advanced-roadmaps.early-preload.info-metadata:end'),
		);

		monitor.recordCustomProfile(url, {
			start,
			end,
		});
	}

	if (!isDefined(response)) {
		response = yield call(json, {
			url,
			method: 'POST',
			body: {
				planId,
				scenarioId,
				includeCustomFieldsOptions: true,
			},
			profile: url,
		});
	}

	if (isDefined(response) && response.ok) {
		if ('errorMessage' in response.data) {
			if (response.data.errorMessage === PROJECT_LIMIT_EXCEPTION) {
				return PROJECT_LIMIT_EXCEPTION;
			}
		} else {
			return {
				planDefaultsInfo: response.data.planDefaults,
				planInfo: response.data.simplePlan,
				hierarchyInfo: response.data.hierarchy,
				issueStatusCategoryInfo: response.data.issueStatus,
				systemInfo: response.data.system,
				agileInfo: response.data.agile,
				issueLinkTypesInfo: response.data.issueLinks,
				dependencySettingsInfo: response.data.dependencySettings,
				userProperties: response.data.pluginUser,
				loadingLimitsInfo: response.data.loadingLimits,
				savedViewsInfoFull: response.data.savedViewsInfoFull,
				customFieldsOptions: response.data.customFieldsOptions,
				isAtlasConnectInstalled: response.data.isAtlasConnectInstalled,
				hasAtlasPermissions: response.data.hasAtlasPermissions,
				reportInfo,
			};
		}
	}

	throw new Error('Failed to fetch page data for plan');
}
