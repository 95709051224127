import type * as Api from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';

export const SET_PLAN_INFO = 'state.domain.SET_PLAN_INFO';

// Reset entire state dependant on backlog
export const RESET_BACKLOG = 'state.domain.RESET_BACKLOG';

export const AFTER_RESET_BACKLOG = 'state.domain.AFTER_RESET_BACKLOG';

export const UPDATE_ISSUE_REFERENCES = 'state.domain.UPDATE_ISSUE_REFERENCES';

export type SetPlanInfoActionPayload = {
	planInfo: Api.PlanInfo;
	hierarchyInfo: Api.HierarchyInfo;
	issueStatusCategoryInfo: Api.IssueStatusCategoryInfo;
};

export type SetPlanInfoAction = {
	type: typeof SET_PLAN_INFO;
	payload: SetPlanInfoActionPayload;
};

export type ResetBacklogAction = {
	type: typeof RESET_BACKLOG;
	payload: Api.Backlog;
};

export type UpdateIssueReferencesActionPayload = {
	oldId: string;
	newId: string;
};

export type UpdateIssueReferencesAction = {
	type: typeof UPDATE_ISSUE_REFERENCES;
	payload: UpdateIssueReferencesActionPayload;
};

export const setPlanInfo = (payload: SetPlanInfoActionPayload) => ({
	type: SET_PLAN_INFO,
	payload,
});

export const resetBacklog = (payload: Api.Backlog) => ({
	type: RESET_BACKLOG,
	payload,
});

export const afterResetBacklog = () => ({
	type: AFTER_RESET_BACKLOG,
});

export const updateIssueReferences = (
	payload: UpdateIssueReferencesActionPayload,
): UpdateIssueReferencesAction => ({
	type: UPDATE_ISSUE_REFERENCES,
	payload,
});
