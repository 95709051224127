import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import type {
	OptionType,
	FormatOptionLabelMeta,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import type { CrossProjectVersionsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/types.tsx';
import type { Version } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/versions/types.tsx';
import { isDefined } from '../../ramda/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

export const createVersionOption: (arg1: Version, arg2: CrossProjectVersionsById) => OptionType = (
	{ id, name, crossProjectVersion },
	crossProjectVersionsById,
) => {
	if (isDefined(crossProjectVersion) && crossProjectVersionsById[crossProjectVersion]) {
		return {
			value: id,
			label: name,
			crossProjectVersionName: crossProjectVersionsById[crossProjectVersion].name,
		};
	}
	return {
		value: id,
		label: name,
	};
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatReleaseOptionLabel = (option: any, meta: FormatOptionLabelMeta) => {
	const { label: versionName, crossProjectVersionName } = option;
	const { context } = meta;
	if (context === 'menu') {
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.crossProjectReleaseOption}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.crossProjectReleaseName} title={versionName}>
					{versionName}
				</div>
				{isDefined(crossProjectVersionName) && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.crossProjectReleaseLozenge} title={crossProjectVersionName}>
						<Lozenge>{crossProjectVersionName}</Lozenge>
					</div>
				)}
			</div>
		);
	}
	return versionName;
};
