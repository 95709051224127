import type { Dispatch } from 'redux';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/index.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch: Dispatch) => ({
	onChange: (issue: ScopeIssue, goals: OptionType[]) => {
		const newGoals = goals.map((goal) => ({ id: goal.goal?.id, key: goal.goal?.key }));
		dispatch(
			updateIssueOrInlineCreate({
				id: issue.id,
				goals: newGoals,
			}),
		);
	},
});

export default mapDispatchToProps;
