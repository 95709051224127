import type { Warnings } from './types.tsx';

export const SET_WARNINGS = 'state.domain.warnings.SET_WARNINGS' as const;

export type SetWarningsAction = {
	type: typeof SET_WARNINGS;
	payload: Warnings;
};

export const setWarnings = (payload: Warnings): SetWarningsAction => ({
	type: SET_WARNINGS,
	payload,
});
