.teamTitle {
	display: flex;
	align-items: center;
}

.teamIcon {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 16px;
	height: 16px;
	margin: 2px 5px 2px 1px;
	background-size: 16px 16px;
	background-image: url('../../../../../../../../common/icons/team.svg');
}

.titleText {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.defaultTeamAvatar {
	background-color: var(--ds-background-neutral, var(--adg3-color-N40));
	border-radius: 50%;
	margin: 2px 5px 2px 1px;
	padding: 2px;

	& > span {
		display: flex;
		height: 12px;
		width: 12px;
	}
}
