import React, { Component, type KeyboardEvent, type MouseEvent, type ReactNode } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { CustomThemeButton, type ThemeTokens, type ThemeProps } from '@atlaskit/button';
import ButtonGroup from '@atlaskit/button/button-group';
import { Inline, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, injectIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	getCurrentValue,
	hasValueChanged,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/util.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import OptimizationDialog from './dialog/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class ApplyOptimisation extends Component<Props, State> {
	state = {
		isDialogOpen: false,
	};

	static getDerivedStateFromProps(props: Readonly<Props>, state: State): Partial<State> | null {
		if (isDefined(props.progress) && !props.isLatest && !state.isDialogOpen) {
			return { isDialogOpen: true };
		}
		return null;
	}

	closeDialog = () => {
		this.setState({ isDialogOpen: false });
	};

	isOptimized = (issue: Issue) => {
		let isOptimized = false;

		if (isDefined(issue.optimized)) {
			for (const [optimizedKey, optimizedValue] of Object.entries(issue.optimized)) {
				if (hasValueChanged(getCurrentValue(issue, optimizedKey), optimizedValue)) {
					isOptimized = true;
					break;
				}
			}
		}

		return isOptimized;
	};

	handleCancelClick = (_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
		this.props.onCancel();

		// triggering analytics
		const [actionSubject, eventAction] =
			PRODUCT_ANALYTICS_EVENT_NAMES.CLICKED_CANCEL_OPTIMIZATION.split(' ');
		fireUIAnalytics(analyticsEvent.update({ action: eventAction, actionSubject }));
	};

	render() {
		if (this.state.isDialogOpen) {
			return <OptimizationDialog closeDialog={this.closeDialog} />;
		}

		const { allIssues, filteredIssues, intl, onApply, progress } = this.props;

		const optimizedFilteredIssuesCount = filteredIssues.filter(this.isOptimized).length || 0;
		const optimizedAllIssuesCount = allIssues.filter(this.isOptimized).length || 0;

		const issueCountMessage =
			optimizedFilteredIssuesCount === optimizedAllIssuesCount
				? intl.formatMessage(messages.previewingAllIssues, {
						issueCount: optimizedFilteredIssuesCount,
						strong: (chunk: ReactNode) => (
							<Inline as="span" xcss={inlineDisplay}>
								{chunk}
							</Inline>
						),
					})
				: intl.formatMessage(messages.previewingFilteredIssues, {
						filteredIssueCount: optimizedFilteredIssuesCount,
						allIssueCount: optimizedAllIssuesCount,
						strong: (chunk: ReactNode) => (
							<Inline as="span" xcss={inlineDisplay}>
								{chunk}
							</Inline>
						),
					});

		const applyingButtonTheme = (
			current: (props: ThemeProps) => ThemeTokens,
			themeProps: ThemeProps,
		): ThemeTokens => ({
			...current(themeProps),
			buttonStyles: {
				...current(themeProps).buttonStyles,
				...{
					background: token('color.background.information', colors.N10A),
					color: token('color.text.information', colors.N60),
				},
			},
		});

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.spaPreviewResultsOverlay}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.spaPreviewHeaderSection}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.previewTitle}>{issueCountMessage}</div>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.actionButtons}>
						<ButtonGroup>
							<Button onClick={this.handleCancelClick}>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<span className={styles.cancelButton}>
									<FormattedMessage {...commonMessages.cancel} />
								</span>
							</Button>
							{isDefined(progress) ? (
								<CustomThemeButton
									isDisabled
									onClick={onApply}
									iconAfter={<Spinner />}
									theme={applyingButtonTheme}
								>
									<FormattedMessage {...messages.applying} values={{ progress }} />
								</CustomThemeButton>
							) : (
								<Button appearance="primary" onClick={onApply}>
									<FormattedMessage {...messages.apply} />
								</Button>
							)}
						</ButtonGroup>
					</div>
				</div>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.spaPreviewDescription}>
					<FormattedMessage {...messages.previewingIssuesDescription} />
				</div>
			</div>
		);
	}
}

export default injectIntl(ApplyOptimisation);

const inlineDisplay = xcss({ display: 'inline', fontWeight: 'bold' });
