.unit {
	border-right: 1px solid var(--ds-border, var(--adg3-color-N40));
	box-sizing: border-box;
	height: 100%;
	position: absolute;

	&.highlighted {
		background-color: var(--ds-background-neutral, var(--adg3-color-N20A));
	}
}
