import { RESET, type ResetAction } from './actions.tsx';
import type { Hierarchy } from './types.tsx';

const initialState: Hierarchy = {
	levels: [],
};

type Action = ResetAction;

const hierarchyReducer = (state: Hierarchy = initialState, action: Action): Hierarchy => {
	switch (action.type) {
		case RESET:
			return {
				levels: action.payload.levels,
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};

export default hierarchyReducer;
