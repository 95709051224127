import { getIssueSourcesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import { getGroupOptions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import {
	getAllSprints,
	getAllSprintsByIdMap,
	getExternalSprintsById,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, StateProps>({
	groups: getGroupOptions,
	sprints: getAllSprints,
	sprintsByIdMap: getAllSprintsByIdMap,
	externalSprintsById: getExternalSprintsById,
	issueSourcesById: getIssueSourcesById,
});
