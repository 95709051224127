.sprintTitle {
	display: flex;
	align-items: center;
}

.projectIcon {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 24px;
	height: 24px;
	margin: 2px var(--jpo-common-base-spacing) 2px 0;
}

.titleText {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	& a {
		cursor: pointer;
	}
}
