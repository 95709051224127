import React, { Component, type ComponentType } from 'react';
import { urls } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/custom-fields/api.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (ComposedComponent: ComponentType<Props>) =>
	// eslint-disable-next-line jira/react/no-class-components
	class extends Component<Props> {
		fetchOptions = (customFieldId: string, projectId: number, issueTypeId: string) =>
			fetch(urls.getCustomFieldSettableOptions, {
				method: 'POST',
				body: { customFieldId, projectId, issueTypeId },
			}).then((response) => response.json());

		render() {
			return <ComposedComponent {...this.props} fetchOptions={this.fetchOptions} />;
		}
	};
