import type { Dispatch } from 'redux';
import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/index.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { MapDispatchToProps } from './types.tsx';

const dispatchMethods: MapDispatchToProps = (dispatch: Dispatch) => ({
	onChange: ({
		issue,
		field,
		date,
	}: {
		issue: ScopeIssue;
		field: string;
		date: number | null | undefined;
	}) => {
		dispatch(
			updateIssueOrInlineCreate({
				id: issue.id,
				[field]: date,
			}),
		);
	},
});

export default dispatchMethods;
