import { useState, useRef, useEffect } from 'react';
import {
	monitorForElements,
	dropTargetForElements,
	draggable,
	type ElementDragPayload,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

const instance = Symbol('dependency-line-drag-create');

export const dropTargetForDependencyCreate: typeof dropTargetForElements = (opts) =>
	dropTargetForElements({
		...opts,
		canDrop: (...args) =>
			args[0].source.data.instance === instance && (opts.canDrop ? opts.canDrop(...args) : true),

		getData: (...args) => ({
			...opts.getData?.(...args),
			instance,
		}),
	});

export const draggableForDependencyCreate: typeof draggable = (opts) =>
	draggable({
		...opts,
		getInitialData: (...args) => ({
			...opts.getInitialData?.(...args),
			instance,
		}),
	});

export const monitorForDependencyCreate: typeof monitorForElements = (opts) =>
	monitorForElements({
		...opts,
		canMonitor: (...args) =>
			args[0].source.data.instance === instance &&
			(opts.canMonitor ? opts.canMonitor(...args) : true),
	});

export const useDependencyCreateDragSourceData = () => {
	const [source, setSource] = useState<ElementDragPayload>();
	const ref = useRef(source);

	ref.current = source;

	useEffect(() => {
		monitorForDependencyCreate({
			// eslint-disable-next-line @typescript-eslint/no-shadow
			onDrag: ({ source }) => {
				if (source !== ref.current) {
					setSource(source);
				}
			},
			onDrop: () => setSource(undefined),
		});
	}, []);

	return source?.data;
};
