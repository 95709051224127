import { initialLoad } from '../../command/index.tsx';
import { restoreReduxStore, resetReduxStore } from '../../state/actions.tsx';
import { changeActiveFilterView } from '../../state/domain/view-settings/active-filter-view/actions.tsx';

const mapDispatchToProps = {
	initialLoad,
	restoreReduxStore,
	resetReduxStore,
	changeActiveFilterView,
} as const;

export default mapDispatchToProps;
