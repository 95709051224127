import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/index.tsx';
import { getReporterForFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/reporter-filter/index.tsx';
import { REPORTER_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';

const mapStateToProps = createSelector(
	[getReporterForFilter, getFilters],
	(reporters, { [REPORTER_FILTER_ID]: { id, value } }) => ({ id, value, reporters }),
);

export default mapStateToProps;
