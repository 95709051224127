import React, { type MouseEvent } from 'react';
import uniqBy from 'lodash/uniqBy';
import SvgOverlay from '@atlassian/jira-aais-dependency-lines-overlay/src/common/ui/index.tsx';
import DependencyModal from '@atlassian/jira-portfolio-3-dependency-line-detail/src/common/ui/modal/index.tsx';
import type { ClickedLine, DependencyClickParams } from '../common/types.tsx';
import { useDependencyLines, useActiveLine } from '../controllers/index.tsx';
import type { LinesOverlayProps, LineDetailProps } from './types.tsx';

export function DependencyLinesOverlay({ getDrawableLine }: LinesOverlayProps) {
	const [dependencyLines] = useDependencyLines();
	const [activeLine, { setActiveLine }] = useActiveLine();

	/* There are some cases where there could be more than one dependency between the same 2 issues */
	const drawableLines =
		getDrawableLine &&
		uniqBy(
			dependencyLines.map(getDrawableLine).filter(Boolean),
			(d) => d && [d.from.id, d.to.id, d.from.y, d.to.y].join(),
		);

	const onDependencyClick = (event: MouseEvent<SVGPathElement>, params: DependencyClickParams) => {
		const clickedLine: ClickedLine = {
			fromId: params.fromId,
			fromIndexOnScope: params.fromIndexOnScope,
			toId: params.toId,
			toIndexOnScope: params.toIndexOnScope,
			x: event.clientX,
			y: event.clientY,
		};

		setActiveLine(clickedLine);
	};

	/* The clicked line */
	const selectedDependency = activeLine
		? {
				fromId: activeLine.fromId,
				toId: activeLine.toId,
				fromIndex: activeLine.fromIndexOnScope,
				toIndex: activeLine.toIndexOnScope,
			}
		: undefined;

	return drawableLines?.length > 0 ? (
		<SvgOverlay
			dependencies={drawableLines}
			onDependencyClick={onDependencyClick}
			selectedDependency={selectedDependency}
		/>
	) : null;
}

export function DependencyLineDetail({ onUnlink, canDelete, isOverlapping }: LineDetailProps) {
	const [linksDetails] = useDependencyLines();
	const [activeLine, { setActiveLine }] = useActiveLine();

	if (!activeLine || linksDetails.length === 0) {
		return null;
	}

	const { x, y, fromId, fromIndexOnScope, toId, toIndexOnScope } = activeLine;

	/* We get only the links relevant to the current active line */
	const currentLineLinks = linksDetails.filter(
		(item) =>
			item.fromIssue.id === fromId &&
			item.toIssue.id === toId &&
			item.fromIssue.indexOnScope === fromIndexOnScope &&
			item.toIssue.indexOnScope === toIndexOnScope,
	);

	return (
		<DependencyModal
			position={{
				left: x,

				top: y,
			}}
			links={currentLineLinks}
			canDelete={canDelete}
			onUnlink={onUnlink}
			isOverlapping={isOverlapping}
			onClose={() => setActiveLine(null)}
		/>
	);
}
