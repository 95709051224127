import {
	SET_MODE,
	OPEN_DIALOG,
	CLOSE_DIALOG,
	INITIATE_REQUEST,
	RESET_REQUEST,
	type SetModeAction,
	type OpenDialogAction,
	type CloseDialogAction,
	type InitiateRequsetAction,
	type ResetRequsetAction,
} from './actions.tsx';
import { CREATE_RELEASE, type ReleaseDialog } from './types.tsx';

const initialState: ReleaseDialog = {
	mode: CREATE_RELEASE,
	isDialogOpen: false,
	isProcessingRequest: false,
	versionId: null,
};

type Action =
	| SetModeAction
	| OpenDialogAction
	| CloseDialogAction
	| InitiateRequsetAction
	| ResetRequsetAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ReleaseDialog = initialState, action: Action) => {
	switch (action.type) {
		case SET_MODE:
			return {
				...state,
				mode: action.payload,
			};
		case OPEN_DIALOG: {
			const { versionId } = action.payload;
			return {
				...state,
				isDialogOpen: true,
				versionId,
			};
		}
		case CLOSE_DIALOG:
			return {
				...state,
				isDialogOpen: false,
				versionId: null,
			};
		case INITIATE_REQUEST:
			return {
				...state,
				isProcessingRequest: true,
			};
		case RESET_REQUEST:
			return {
				...state,
				isProcessingRequest: false,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
