import { groupBy, indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { HierarchyRangeFilter } from '../../state/domain/view-settings/filters/types.tsx';
import type { IssueExpandState } from '../../state/domain/view-settings/issue-expansions/types.tsx';
import { getHierarchyRangeFilter } from '../filters/hierarchy-range-filter/index.tsx';
import { buildScope, getIsExpanded, getIssuesWithOriginalsAndOptimized } from '../scope/index.tsx';

export const getScopeItemsForExportPure = (
	issuesWithOriginalsAndOptimized: Issue[],
	isExpanded: IssueExpandState,
	{ value: { start, end } }: HierarchyRangeFilter,
) => {
	const issuesById = indexBy(({ id }) => id, issuesWithOriginalsAndOptimized);
	const issuesByParent = groupBy(
		({ parent }) => parent || '',
		issuesWithOriginalsAndOptimized.filter(({ parent }) => parent),
	);

	const scopeItems = buildScope(
		issuesWithOriginalsAndOptimized,
		issuesByParent,
		issuesById,
		isExpanded,
		start,
		end,
		'',
		undefined,
		issuesWithOriginalsAndOptimized,
		true,
	);

	const issuesWithoutParent = scopeItems.issuesWithoutParent.flatMap((item) => item.issues);

	const items = [...scopeItems.startLevelIssues.issues, ...issuesWithoutParent];
	return items;
};

export const getScopeItemsForExport = createSelector(
	[getIssuesWithOriginalsAndOptimized, getIsExpanded, getHierarchyRangeFilter],
	getScopeItemsForExportPure,
);
