import {
	type ResetShowDateConstraintsAction,
	type ToggleShowDateConstraintsAction,
	RESET_SHOW_DATE_CONSTRAINTS,
	TOGGLE_SHOW_DATE_CONSTRAINTS,
} from './actions.tsx';
import type { DateConstraintsSettingState } from './types.tsx';

type Action = ResetShowDateConstraintsAction | ToggleShowDateConstraintsAction;

const initialState: DateConstraintsSettingState = {
	showDateConstraints: false,
};

const reducer = (
	state: DateConstraintsSettingState = initialState,
	action: Action,
): DateConstraintsSettingState => {
	switch (action.type) {
		case RESET_SHOW_DATE_CONSTRAINTS:
			return { ...state, showDateConstraints: action.payload.showDateConstraints };
		case TOGGLE_SHOW_DATE_CONSTRAINTS:
			return { ...state, showDateConstraints: !state.showDateConstraints };
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};

export default reducer;
