import { SET_WARNINGS, type SetWarningsAction } from './actions.tsx';
import type { WarningsState } from './types.tsx';

type Action = SetWarningsAction;

export const initialState: WarningsState = {
	byIssue: {},
	transitiveIssueIds: [],
	allWarningsByIssue: {},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: WarningsState = initialState, action: Action): WarningsState => {
	switch (action.type) {
		case SET_WARNINGS:
			return { ...state, ...action.payload };
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
