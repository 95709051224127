import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getErrors } from '../../query/errors/index.tsx';
import type { State } from '../../state/types.tsx';
import type { StateProps } from './types.tsx';
import View from './view.tsx';

const mapStateToProps = createStructuredSelector<State, StateProps>({ errors: getErrors });

export default connect(mapStateToProps, {})(View);
