import { getIssueTypesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, StateProps>({
	issues: getIssues,
	issueTypesById: getIssueTypesById,
	projectsById: getProjectsById,
});
