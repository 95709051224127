import * as R from 'ramda';
import {
	ADJUST_ISSUE_LEXORANK,
	type AdjustIssueLexoRankAction,
	type BulkAdjustIssueLexoRankAction,
	ADJUST_VERSION_LEXORANK,
	type AdjustVersionLexoRankAction,
	RESET_ADJUSTMENTS,
	BULK_ADJUST_ISSUE_LEXORANK,
	type ResetAdjustmentsAction,
	UPDATE_VERSION_LEXORANK_ADJUSTMENTS,
	type UpdateVersionLexorankAdjustmentsAction,
} from './actions.tsx';
import type { Adjustments, LexoRank } from './types.tsx';

const initialState: LexoRank = {
	issueAdjustments: [],
	versionAdjustments: [],
};

type Action =
	| AdjustIssueLexoRankAction
	| BulkAdjustIssueLexoRankAction
	| AdjustVersionLexoRankAction
	| ResetAdjustmentsAction
	| UpdateVersionLexorankAdjustmentsAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: LexoRank = initialState, action: Action): LexoRank => {
	const actionType = action.type;
	switch (actionType) {
		case RESET_ADJUSTMENTS: {
			return R.merge(state, { issueAdjustments: [...action.payload] });
		}
		case ADJUST_ISSUE_LEXORANK: {
			const {
				anchor,
				itemKeys: [id],
				relation,
			} = action.payload;
			if (anchor === undefined) {
				return state;
			}
			const adjustments = state.issueAdjustments.filter((x) => x !== id);

			const anchorAdjustment = adjustments.indexOf(anchor);

			if (anchorAdjustment < 0) {
				const items = relation === 'AFTER' ? [anchor, id] : [id, anchor];
				return R.assoc('issueAdjustments', [...adjustments, ...items], state);
			}

			const adjustment = anchorAdjustment + (relation === 'AFTER' ? 1 : 0);
			return R.assoc('issueAdjustments', R.insert(adjustment, id, adjustments), state);
		}
		case BULK_ADJUST_ISSUE_LEXORANK: {
			const { anchor, itemKeys, relation } = action.payload;
			const itemKeysSet = new Set(itemKeys);
			const adjustments = state.issueAdjustments.filter((id) => !itemKeysSet.has(id));

			if (relation === 'LAST') {
				return R.assoc('issueAdjustments', [...adjustments, ...itemKeys], state);
			}

			if (anchor === undefined) {
				return state;
			}
			const anchorAdjustment = adjustments.indexOf(anchor);

			if (anchorAdjustment < 0) {
				const items = relation === 'AFTER' ? [anchor, ...itemKeys] : [...itemKeys, anchor];
				return R.assoc('issueAdjustments', [...adjustments, ...items], state);
			}

			const adjustment = anchorAdjustment + (relation === 'AFTER' ? 1 : 0);
			return R.assoc('issueAdjustments', R.insertAll(adjustment, itemKeys, adjustments), state);
		}
		case ADJUST_VERSION_LEXORANK: {
			const { anchor, id, relation } = action.payload;
			if (anchor === undefined) {
				return state;
			}
			const adjustments = state.versionAdjustments.filter((x) => x !== id);

			const anchorAdjustment = adjustments.indexOf(anchor);

			if (anchorAdjustment < 0) {
				const items = relation === 'AFTER' ? [anchor, id] : [id, anchor];
				return R.assoc('versionAdjustments', [...adjustments, ...items], state);
			}

			const adjustment = anchorAdjustment + (relation === 'AFTER' ? 1 : 0);
			return R.assoc('versionAdjustments', R.insert(adjustment, id, adjustments), state);
		}
		case UPDATE_VERSION_LEXORANK_ADJUSTMENTS: {
			const { payload } = action;
			const patches: string[] = Object.keys(payload);
			const versionAdjustments: Adjustments = state.versionAdjustments.map((versionId: string) =>
				patches.includes(versionId) ? payload[versionId] : versionId,
			);

			return R.assoc('versionAdjustments', versionAdjustments, state);
		}
		default:
			// prettier-ignore
			(actionType);
			return state;
	}
};
