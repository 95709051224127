import { useEffect, useRef } from 'react';

const ChangesCountMonitor = ({
	changesCount,
	onChangeListModified,
	isSaving,
}: {
	changesCount: number;
	onChangeListModified: () => void;
	isSaving: boolean;
}) => {
	const lastCount = useRef<number | undefined>();
	useEffect(() => {
		if (!isSaving) {
			// No need to trigger it when the component first mounts.
			if (lastCount.current !== undefined && lastCount.current !== changesCount) {
				// trigger callback when changes are saved or discarded.
				onChangeListModified();
			}
			lastCount.current = changesCount;
		}
	}, [changesCount, onChangeListModified, isSaving]);
	return null;
};

export default ChangesCountMonitor;
