import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import {
	SET_CURRENT_USER,
	SET_TEAM_PERMISSIONS,
	TOGGLE_FETCH_PLAN_PERMISSIONS_IN_PROGRESS,
} from './actions.tsx';
import planPermissions from './plan-permissions/reducer.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const currentUser = (state = {}, action: any) => {
	switch (action.type) {
		case SET_CURRENT_USER: {
			const { displayName, key, emailAddress: email, avatarUrls } = action.payload;
			return { displayName, key, email, avatarUrl: avatarUrls['48x48'] };
		}
		default:
			return state;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFetchPlanPermissionsInProgress = (state = false, action: any) => {
	switch (action.type) {
		case TOGGLE_FETCH_PLAN_PERMISSIONS_IN_PROGRESS:
			return action.payload;
		default:
			return state;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const teamPermissions = (state = {}, action: any) => {
	switch (action.type) {
		case SET_TEAM_PERMISSIONS:
			return action.payload;
		default:
			return state;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	planPermissions,
	currentUser,
	isFetchPlanPermissionsInProgress,
	teamPermissions,
});
