import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getIsGoalsLoading } from '../../query/issue-goals/index.tsx';
import { getIsIssuesLoading } from '../../query/issues/index.tsx';
import type { State } from '../../state/types.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, StateProps>({
	isLoading: getIsIssuesLoading,
	isGoalsLoading: getIsGoalsLoading,
});
