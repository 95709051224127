import React from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { UNDEFINED_GROUP } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import type { GroupOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/types.tsx';
import { GROUPING } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import ReleaseDialogContentDI from './release-dialog-content/index.tsx';
import type { ProjectIdToGroupOptionTuple } from './release-dialog-content/types.tsx';
import type { Props } from './types.tsx';
import { getGroupOptionsFromReleasesByProject } from './utils.tsx';

export default function ReleaseOptions({
	groups,
	hierarchyItem,
	onGroupClick,
	enrichedVersions,
	enrichedVersionsById,
	completeVersionsById,
	ReleaseDialogContent = ReleaseDialogContentDI,
}: Props) {
	const { formatMessage } = useIntl();

	const groupOptionsFromReleasesByProject = getGroupOptionsFromReleasesByProject({
		groups,
		enrichedVersions,
		enrichedVersionsById,
		completeVersionsById,
	});

	const unassigned: GroupOption = {
		groupName: formatMessage(commonMessages.unassigned),
		grouping: GROUPING.RELEASE,
		isExpanded: true,
		group: UNDEFINED_GROUP,
		groupCombination: { release: null },
		startInlineCreateInEmptyGroup: !groups.some(({ group }) => group === UNDEFINED_GROUP),
	};

	const groupOptionsWithUnassigned: ProjectIdToGroupOptionTuple[] = [
		...groupOptionsFromReleasesByProject,
		[null, [unassigned]],
	];

	return (
		<ReleaseDialogContent
			groupOptions={groupOptionsWithUnassigned}
			hierarchyItem={hierarchyItem}
			onGroupClick={onGroupClick}
		/>
	);
}
