.container {
	width: 100%;
	max-width: 100%;
	overflow: hidden;
	height: 40px;
	display: flex;
	align-items: center;
}

.dialog {
	max-width: 800px;
	max-height: 400px;
	padding: var(--ds-space-200, 16px) var(--ds-space-300, 24px);
	box-sizing: border-box;
}

.issuesCount {
	cursor: pointer;
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	height: 100%;
	outline: none;
}

.issuesLabel {
	flex: 1 1 auto;
	padding-left: 8px;
	white-space: nowrap;
}
