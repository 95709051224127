import React from 'react';
import { FireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getErrorHash } from '../utils/error-hash.tsx';
import { CodeBlockWithCopyButton } from './code-block-with-actions/index.tsx';

export const ErrorHash = ({ error }: { error: Error }) => {
	const errorHash = getErrorHash(error);
	const source = `Hash ${errorHash}`;

	return (
		<>
			<FireOperationalAnalytics
				action="rendered"
				actionSubject="errorHash"
				attributes={{
					errorHash,
				}}
			/>
			<div role="note">
				<CodeBlockWithCopyButton source={source} copyCodeActionSubjectId="copyErrorHash" />
			</div>
		</>
	);
};
