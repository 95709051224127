import { CHECKBOX_STATES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

export const { CHECKED, UNCHECKED, INDETERMINATE } = CHECKBOX_STATES;

export const calculateCategoryCheckboxStatus = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	categoryStatuses: any[],
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	selectedStatuses: Set<any>,
): (typeof CHECKBOX_STATES)[keyof typeof CHECKBOX_STATES] => {
	if (selectedStatuses.size > 0 && categoryStatuses.every((el) => selectedStatuses.has(el))) {
		return CHECKED;
	}
	if (categoryStatuses.some((el) => selectedStatuses.has(el))) {
		return INDETERMINATE;
	}
	return UNCHECKED;
};
