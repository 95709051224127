import type { ReleaseStatus } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';

export const RESET = 'state.domain.version-statuses.RESET' as const;

export type ResetActionPayload = ReleaseStatus[];

// RESET
export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});
