import { getHierarchyRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy/index.tsx';
import {
	getSelectedIssues,
	getCPRsForSelectedIssues,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export default createStructuredSelector<State, OwnProps, StateProps>({
	cpVersionsForSelectedIssues: getCPRsForSelectedIssues,
	hierarchyRange: getHierarchyRange,
	projectsById: getProjectsById,
	selectedIssues: getSelectedIssues,
});
