import { persistReducer } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/persistence.tsx';
import { getPlanPrefixedKey } from '../../persistence.tsx';
import {
	UPDATE_HIDDEN_FLAG_DATE,
	CLEAR_HIDDEN_FLAG_DATE,
	type HiddenFlagDateAction,
} from './actions.tsx';
import type { HiddenFlags } from './types.tsx';

type Action = HiddenFlagDateAction;
type State = HiddenFlags;

const initialState: State = {};

// ms in one week
const oneWeek = 1000 * 60 * 60 * 24 * 7;

// avoid hiding the flag forever
const MAX_MULTIPLIER = 3;

/**
 * Calculates the timestamp for 2 weeks * multiplier from now
 * @param {number} multiplier
 * @returns {number}
 */
const calculateWeeksTime = (multiplier: number) => Date.now() + oneWeek * 2 * multiplier;

const updateHiddenFlagDate = (state: State, flag: string): State => {
	// If the flag was already hidden in the past, we increase its multiplier for the next time
	if (state[flag]) {
		const currentMultiplier = state[flag].multiplier;
		const newMultiplier =
			currentMultiplier < MAX_MULTIPLIER ? currentMultiplier + 1 : MAX_MULTIPLIER;

		return {
			...state,
			[flag]: {
				multiplier: newMultiplier,
				hiddenUntilTimestamp: calculateWeeksTime(newMultiplier),
			},
		};
	}

	return {
		...state,
		[flag]: {
			multiplier: 1,
			hiddenUntilTimestamp: calculateWeeksTime(1),
		},
	};
};

const clearHiddenFlagDate = (state: State, flag: string): State => {
	const nextState = { ...state };
	delete nextState[flag];

	return nextState;
};

function reducer(state: State = initialState, action: Action) {
	switch (action.type) {
		case UPDATE_HIDDEN_FLAG_DATE:
			return updateHiddenFlagDate(state, action.payload.key);
		case CLEAR_HIDDEN_FLAG_DATE:
			return clearHiddenFlagDate(state, action.payload.key);
		default:
			return state;
	}
}

// eslint-disable-next-line jira/redux/redux-persist-whitelist
export default persistReducer({ key: getPlanPrefixedKey('hidden-flags') }, reducer);
