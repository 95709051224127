import { getTableItems } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import { getTimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, StateProps>({
	scope: getTableItems,
	timelineRange: getTimelineRange,
});
