import {
	HIDE_COLUMN,
	move,
	DRAG_FIELD,
	HIDE_FIELD,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/field-columns/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

export const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	hideColumn: (id: string) => {
		dispatch({
			type: HIDE_COLUMN,
			id,
			meta: { source: HIDE_FIELD },
		});
	},
	moveColumn: (columnToMove: string, moveToTheIndexOf: string) => {
		dispatch(
			move(
				{
					columnToMove,
					moveToTheIndexOf,
				},
				{ source: DRAG_FIELD },
			),
		);
	},
});
