export const SET_STICKY_HEADER_SCROLL =
	'state.ui.main.tabs.roadmap.sticky-header.SET_STICKY_HEADER_SCROLL' as const;

export type SetScrollingActionPayload = {
	scrollTop?: number;
};

export type SetScrollAction = {
	type: typeof SET_STICKY_HEADER_SCROLL;
	payload: SetScrollingActionPayload;
};

export const setScrollingScrollTop = (payload: SetScrollingActionPayload) => ({
	type: SET_STICKY_HEADER_SCROLL,
	payload,
});
