import { defineMessages } from '@atlassian/jira-intl';

/* eslint-disable jira/i18n/id-named-by-package */
export default defineMessages({
	sortByLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.sort-by.sort-by-label',
		defaultMessage: 'Sort by',
		description: 'A label that prefixes a control to select how issues are sorted',
	},
	Hours: {
		id: 'simple-plans.view.main.tabs.roadmap.sort-by.option.estimate-hours',
		defaultMessage: 'Estimate (h)',
		description: 'The label to display for the estimate in hours option in the sort by dropdown',
	},
	Days: {
		id: 'simple-plans.view.main.tabs.roadmap.sort-by.option.estimate-days',
		defaultMessage: 'Estimate (d)',
		description: 'The label to display for the estimate in days option in the sort by dropdown',
	},
	StoryPoints: {
		id: 'simple-plans.view.main.tabs.roadmap.sort-by.option.estimate-story-points',
		defaultMessage: 'Story points',
		description:
			'The label to display for the estimate in story points option in the sort by dropdown',
	},
	rank: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.sort-by.rank',
		defaultMessage: 'Rank (default)',
		description: 'The label to display for the default rank option in the sort by dropdown',
	},
	ascending: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.sort-by.ascending',
		defaultMessage: 'Ascending',
		description:
			'The label to display for the ascending sort direction when a sort option is selected',
	},
	descending: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.sort-by.descending',
		defaultMessage: 'Descending',
		description:
			'The label to display for the descending sort direction when a sort option is selected',
	},
});
