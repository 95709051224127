import * as R from 'ramda';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ViewSettings = any;
type Key = string;
type Version = string;
type Migrate<PrevState, NextState> = (
	state: PrevState,
	options?: {
		to: Version;
	},
) => NextState;

type Step<PrevState, NextState> = {
	from: Key;
	to: Key;
	migrate: Migrate<PrevState, NextState>;
};

type StepsInfo<PrevState, NextState> = {
	steps: Step<PrevState, NextState>[];
	started: boolean;
	ended: boolean;
};

export const createScript: <PrevState, NextState>(
	arg1: Step<PrevState, NextState>,
) => Migrate<ViewSettings, ViewSettings> =
	({ from, to, migrate }: { from: Key; to: Key; migrate: Migrate<ViewSettings, ViewSettings> }) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(viewSettings: any) => {
		const prevState = viewSettings[from];
		const nextState: unknown = migrate(prevState);
		return R.pipe(R.omit([from]), R.assoc(to, nextState))(viewSettings);
	};

// Please check out migrate.test.js for the examples
export const createMigrate =
	<PrevState, NextState>(
		...steps: Step<PrevState, NextState>[]
	): Migrate<ViewSettings, ViewSettings> =>
	(viewSettings, options) => {
		// need to pick only the steps from the current version in viewSettings to the required version
		const currentVersion = R.pipe<
			Step<PrevState, NextState>[],
			Step<PrevState, NextState>[],
			string[],
			string[],
			unknown
		>(
			R.reverse,
			R.map(R.prop('to')),
			steps[0] ? R.append(steps[0].from) : R.identity,
			R.find((key: Key) => R.has(key)(viewSettings)),
		)(steps);

		// we will rely on initialState since the attribute is not found in the view settings blob
		if (!currentVersion) {
			return viewSettings;
		}

		const { steps: requiredSteps } = steps.reduce(
			(
				// eslint-disable-next-line @typescript-eslint/no-shadow
				{ steps, started, ended }: StepsInfo<PrevState, NextState>,
				{ from, to, migrate }: Step<PrevState, NextState>,
			) => {
				if (!started && currentVersion && from !== currentVersion) {
					return { steps, started, ended };
				}

				if (ended) {
					return { steps, started, ended };
				}

				const needSkipPrevSteps = from === currentVersion;
				const prevSteps = needSkipPrevSteps ? [] : steps;

				return {
					steps: [...prevSteps, { from, to, migrate }],
					started: true,
					ended: ended || to === (options && options.to),
				};
			},
			{ steps: [], started: false, ended: false },
		);

		const migrate =
			requiredSteps.length > 0 ? R.call(R.pipe, ...requiredSteps.map(createScript)) : R.identity; // in case there is no migration to apply

		return migrate(viewSettings);
	};

export const incremental = (
	migrations: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[version: string]: Migrate<any, any>;
	},
	options: {
		prefix: string;
		from: string;
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Step<any, any>[] => {
	const { prefix } = options;
	const getVersionNo = (Vx: string) => Number((Vx.match(/^V([0-9]+)$/i) || [])[1]);
	const makeKey = (ver: number) => `${prefix}V${ver}`;

	const versionNos: number[] = Object.keys(migrations).map(getVersionNo).sort();

	if (versionNos.find(Number.isNaN) !== undefined) {
		throw new Error(`wrong version name in the migrations of ${prefix}`);
	}

	const fromVerNo = getVersionNo(options.from);

	if (Number.isNaN(fromVerNo)) {
		throw new Error('options.from must be correct version name');
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [steps] = versionNos.reduce<[Step<any, any>[], number]>(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		([acc, prevVer]: [any, any], ver) => {
			const from = makeKey(prevVer);
			const to = makeKey(ver);

			return [[...acc, { from, to, migrate: migrations[`V${ver}`] }], ver];
		},
		[[], fromVerNo],
	);

	return steps;
};
