import {
	clear,
	change,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters/index.tsx';
import {
	ISSUE_WARNING_FILTER_ID,
	type StringAttributeFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	onWarningFilterChange: (payload: StringAttributeFilterValue) => {
		dispatch(change({ id: ISSUE_WARNING_FILTER_ID, value: payload }));
	},
	onClearWarningFilter: () => {
		dispatch(clear(ISSUE_WARNING_FILTER_ID));
	},
});

export default mapDispatchToProps;
