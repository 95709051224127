.sprintTooltip {
	margin-top: 12px; /* This is the additional style needed for JPO-20817 */
	/* The rest are there to mimic the current AtlasKit tooltip style */
	border-radius: 4px;
	box-sizing: content-box;
	padding: 2px 6px;
	background-color: var(--ds-background-neutral-bold, var(--adg3-color-N800));
	color: var(--ds-text-inverse, var(--adg3-color-N0));
	font-size: 12px;
}
