import { type DateField, type PlanInfo, DATEFIELD_TYPES } from './types.tsx';

export const extractStartDateField = (planInfo: PlanInfo): DateField => {
	const startDateCustomField = planInfo.customFields.find(
		(field) => field.defaultStartDateCustomField,
	);
	if (!startDateCustomField) {
		throw new Error('missing Start Date Custom field in server response');
	}
	return {
		id: `customfield_${startDateCustomField.id}`,
		key: `${startDateCustomField.id}`,
		type: DATEFIELD_TYPES.CUSTOM,
	};
};
