import { clearAll } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters/index.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapStateToProps from './query.tsx';
import type { DispatchProps } from './types.tsx';
import View from './view.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	clearAllFilters: () => {
		dispatch(clearAll());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
