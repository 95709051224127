import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	updateJiraButtonText: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.review-changes-button-wrapper.review-changes-button.update-jira-button-text',
		defaultMessage: 'Unsaved changes',
		description:
			'The label for the button that is used to show a dialog of all the changes that can be committed to Jira',
	},
});

export default messages;
