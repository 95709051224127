import {
	type ProjectType,
	CORE_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useIsSimplifiedProject } from '@atlassian/jira-project-context-service/src/main.tsx';
import { useCanAdministerProject } from '@atlassian/jira-project-permissions-service/src/main.tsx';
import type { GetProjectSettingsUrl } from './types.tsx';

export const getProjectSettingsIssueTypesUrl = ({
	projectKey,
	projectType,
}: GetProjectSettingsUrl) => {
	switch (projectType?.toLowerCase()) {
		case 'service_desk':
			return `/plugins/servlet/project-config/${projectKey}/issueTypes`;
		case 'business':
			return `/jira/core/projects/${projectKey}/settings/issuetypes`;
		default:
			return `/jira/software/projects/${projectKey}/settings/issuetypes`;
	}
};

export const defaultModalProps = {
	width: 'small',
};

export const useCanConfigureStatus = (projectKey: string) => {
	const isProjectAdmin = useCanAdministerProject(projectKey);
	const isTeamManaged = useIsSimplifiedProject(projectKey);
	return isProjectAdmin && isTeamManaged;
};

export const shouldShowConfigureStatusOption = (projectType: ProjectType | null) => {
	return projectType === CORE_PROJECT || projectType === SOFTWARE_PROJECT;
};
