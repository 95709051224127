import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	removeFromPlanTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.remove-issues.remove-from-plan-title',
		defaultMessage: 'Remove issues',
		description: 'Bulk actions title',
	},
	removeFromPlanActionButton: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.remove-issues.remove-from-plan-action-button',
		defaultMessage: 'Remove',
		description: 'Remove bulk actions button',
	},
	removeIssueFromPlanMessageOld: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.remove-issues.remove-issue-from-plan-message-old',
		defaultMessage:
			'Issues saved in Jira will be removed from the plan, but can be re-included on {removedIssuesPageLink}. Unsaved issues will be permanently deleted.',
		description: 'Remove bulk actions message',
	},
	removeIssueFromPlanMessage: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.remove-issues.remove-issue-from-plan-message',
		defaultMessage:
			'Draft issues will be permanently deleted from the plan. Issues from your project will be stored in <b>Removed issues</b> within <b>Plan settings</b>.',
		description: 'Remove bulk actions message',
	},
});
