import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	noFields: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.subheader.add-fields.no-fields',
		defaultMessage: 'Use the Fields button to add columns to this view.',
		description: 'Header when no fields have been selected to be displayed',
	},
});

export default messages;
