import { fg } from '@atlassian/jira-feature-gating';
import getMeta from '@atlassian/jira-get-meta';
import { getBooleanFeatureFlag } from './utils.tsx';

export const isPollinatorTenant = () =>
	getBooleanFeatureFlag('jsw.pollinator.tenants') ||
	fg('is_synthetic_tenant') ||
	getMeta('ajs-is-synthetic') === 'true';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	getBooleanFeatureFlag,
	getFeatureFlagValue,
	getMultivariateFeatureFlag,
	injectCallbackIntoGetBooleanFeatureFlag,
} from './utils';
