import React, { Component, useCallback } from 'react';
import ReactDOM from 'react-dom';
import isEmpty from 'lodash/fp/isEmpty';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { JPO_DIALOGS_CREATE_ISSUE_TRIGGER } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants.tsx';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import messages from './messages.tsx';
import type { Props, ClassProps, ActiveProps, ShowDialog } from './types.tsx';
import UncontrolledLegacyDialog from './uncontrolled-legacy-dialog/deferred.tsx';
import type { IssueCreatedResult } from './uncontrolled-legacy-dialog/types.tsx';
import { getOpenIssueCreateModalPayload } from './utils.tsx';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, jira/jira-ssr/no-unchecked-globals-usage
const getDocumentRoot = () => document.body as HTMLElement;

const tryMoveElementToContainer = (container: HTMLElement | null | undefined, el: HTMLElement) => {
	if (container) {
		el.remove();
		container.appendChild(el);
	}
};

const DEFAULT_OPEN_GIC_MODAL_VALUES = {
	id: 'jpo-dialogs-jira-create-issue',
	triggerPointKey: JPO_DIALOGS_CREATE_ISSUE_TRIGGER,
	disableCreationSuccessFlag: true,
	disableProjectDropdown: true,
	disableIssueTypeDropdown: true,
	displayOnlyRequiredFields: false,
	displayCreateAnotherIssueToggle: false,
};

const useGlobalIssueCreateModal = () => {
	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	const { formatMessage } = useIntl();
	const showDialog: ShowDialog = useCallback(
		({ payload, handleSubmit, handleCancel, isSubTask, errorMessages }) => {
			openIssueCreateModal({
				...DEFAULT_OPEN_GIC_MODAL_VALUES,
				payload,
				title: formatMessage(messages.requiredFieldsDialogTitle),
				isSubTaskCreationOpen: isSubTask,
				onIssueCreate: (data) => handleSubmit(data.issueId.toString()),
				onClose: () => handleCancel(),
				submitErrorMessage: isEmpty(errorMessages) ? undefined : errorMessages?.join('\n'),
			});
		},
		[formatMessage, openIssueCreateModal],
	);
	return { showDialog };
};

const RequiredFieldsDialog = (props: Props) => {
	const { showDialog } = useGlobalIssueCreateModal();
	const { formatMessage } = useIntl();

	if (fg('refactor_update_jira_to_functional_component')) {
		if (props.isOpen) {
			return (
				<RequiredFieldsDialogClass
					{...props}
					formatMessage={formatMessage}
					showDialog={showDialog}
				/>
			);
		}
		return <RequiredFieldsDialogClass {...props} formatMessage={formatMessage} />;
	}
	return (
		// @ts-expect-error No overload matches this call.
		<RequiredFieldsDialogClass {...props} showDialog={showDialog} formatMessage={formatMessage} />
	);
};
// eslint-disable-next-line jira/react/no-class-components
class RequiredFieldsDialogClass extends Component<ClassProps> {
	// eslint-disable-next-line react/sort-comp
	containerRef: HTMLElement | null | undefined = null;

	createLegacyDialogInRefContainer(activeProps: ActiveProps) {
		const { formatMessage, issue, issueTypesById, agileConfig, jiraConfig, projectsById } =
			activeProps;

		const jQueryDeferred = UncontrolledLegacyDialog.create({
			formatMessage,
			issue,
			issueTypesById,
			agileConfig,
			jiraConfig,
			isIssueSimplified: projectsById[issue.values.project].isSimplified,
			onRenderCallback: (dialog) => {
				tryMoveElementToContainer(this.containerRef, dialog);
			},
		});

		return jQueryDeferred;
	}

	/**
	 * There are a few things happening here:
	 * - This AUI / jQuery UI dialog is rendered on top of an AtlasKit React dialog;
	 * - The AtlasKit dialog is wrapped in a focus lock component, which will block events
	 *    to any element that isn't a direct child;
	 * - The AUI / jQuery dialog will by default render at the bottom of the page outside of
	 *   React's dom, which means it will by blocked out by the focus lock component;
	 * - We can't just make the AUI dialog component a direct child of the AtlasKit component,
	 *   because then it will be blocked by the AUI blanket which blocks inputs outside of the
	 *   AUI dialog, and is rendered at the bottom of the page.
	 *
	 * The workaround is then to:
	 * - Render an empty container as a child of the atlas kit dialog and get a ref to it. If
	 *   we render the AUI dialog inside this container, it will be accepted by the AtlasKit
	 *   focus lock;
	 * - Portal the ref container to the bottom of the page, so that it can get around the
	 *   AUI dialog blanket;
	 * - Since we can't control where the AUI dialog renders by default (it's an internal
	 *   detail in the jira-quick-edit-plugin), wait for it to render, and then manually
	 *   move it into our portalled ref container.
	 */
	// eslint-disable-next-line react/sort-comp
	render() {
		const root = getDocumentRoot();

		const container = (
			<div
				ref={(ref) => {
					this.containerRef = ref;
				}}
			/>
		);

		return ReactDOM.createPortal(container, root);
	}

	shouldComponentUpdate(nextProps: Props) {
		return nextProps.isOpen !== this.props.isOpen;
	}

	async componentDidUpdate() {
		if (this.containerRef) {
			this.containerRef.innerHTML = '';
		}

		if (!this.props.isOpen) {
			return;
		}

		const activeProps = this.props;
		const {
			handleSubmit,
			handleCancel,
			handleCancelAll,
			handleFailure,
			issue,
			agileConfig,
			issueTypesById,
			error,
			parentIssue,
			startDateCustomField,
			planInfo,
			projectsById,
			teamsById,
		} = activeProps;

		const isIssueSimplified = projectsById[issue.values.project].isSimplified;

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const isModernDialogEnabled = window.IssueCreate && window.IssueCreate.Visibility.isEnabled;
		if (isModernDialogEnabled && this.props.showDialog) {
			const { payload, isSubTask } = await getOpenIssueCreateModalPayload({
				issue,
				agileConfig,
				issueTypesById,
				planInfo,
				startDateCustomField,
				parentIssue,
				isIssueSimplified,
				teamsById,
			});

			this.props.showDialog({
				payload,
				handleSubmit,
				handleCancel,
				isSubTask,
				errorMessages: error?.errorMessages,
			});
		} else {
			this.createLegacyDialogInRefContainer(activeProps)
				.done(({ id }: IssueCreatedResult) => handleSubmit(id))
				.fail(({ reason }: { reason: string | undefined }) => {
					if (reason === 'cancel') {
						handleCancel();
					} else if (reason === 'cancel-all') {
						handleCancelAll();
					} else {
						handleFailure();
					}
				});
		}
	}
}

export default RequiredFieldsDialog;
