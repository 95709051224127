import React, { type CSSProperties } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { B300, Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useDropIndicator } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/controller/hooks.tsx';
import {
	useCellOld,
	useCellNew,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/cell/index.tsx';
import { useRow } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/row/index.tsx';

const getIconColor = (type: 'allowed' | 'blocked') => {
	switch (type) {
		case 'blocked':
			return token('color.icon.danger', Y300);
		case 'allowed':
		default:
			return token('color.icon.brand', B300);
	}
};

const getBorderColor = (type: 'allowed' | 'blocked') => {
	switch (type) {
		case 'blocked':
			return token('color.border.danger', Y300);
		case 'allowed':
		default:
			return token('color.border.brand', B300);
	}
};

export const DropIndicator = () => {
	const [{ column }] =
		// eslint-disable-next-line react-hooks/rules-of-hooks
		fg('plans_performance_improvements_3') ? useCellNew() : useCellOld();
	let row;
	if (fg('plans_performance_improvements_3')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		({ row } = useRow());
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[{ row }] = useCellOld();
	}

	const indicator = useDropIndicator(row);

	if (!indicator) return null;

	const { edge, blocked } = indicator;
	const type = blocked ? 'blocked' : 'allowed';

	return (
		<Box
			xcss={[
				lineStyles,
				column === 0 && terminalStyles,
				edge === 'top' && topStyles,
				edge === 'bottom' && bottomStyles,
			]}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				{
					'--drop-border-color': getBorderColor(type),
					'--drop-icon-color': getIconColor(type),
				} as CSSProperties
			}
		/>
	);
};

const lineStyles = xcss({
	pointerEvents: 'none',
	position: 'absolute',
	width: '100%',
	height: '2px',
	background: 'var(--drop-border-color)',
});

const terminalStyles = xcss({
	'::before': {
		content: '""',
		position: 'absolute',
		height: '0',
		width: '0',
		top: '-3px',
		borderTop: `${token('space.050', '4px')} solid transparent`,
		borderBottom: `${token('space.050', '4px')} solid transparent`,
		borderLeft: `${token('space.100', '8px')} solid var(--drop-icon-color)`,
	},
});

const topStyles = xcss({
	top: '-1px',
});

const bottomStyles = xcss({
	bottom: '-1px',
});
