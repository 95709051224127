.select-option {
	display: flex;
	align-items: center;
}

.icon-wrapper {
	display: inline-flex;
	margin-right: 8px;
}

.label {
	display: inline-flex;
	overflow: hidden;
}

.truncated {
	overflow: hidden;
	text-overflow: ellipsis;
}

.not-in-plan {
	color: var(--ds-text-disabled, var(--adg3-color-N70));
	font-style: italic;
	display: inline-block;
	width: 100%;
}

.icon {
	width: 16px;
	height: 16px;
	background: center no-repeat;
	background-size: contain;
}
