import type { CttSpotlights } from '../ctt-spotlights/index.tsx';

export type ContainerProps = {
	accountId: string;
};

export type State = {
	_rehydrated: boolean;
	_rehydratePromise?: Promise<void>;
	cttOnboarding?: CttOnboarding;
	spotlight?: CttSpotlights;
	isTakingOnboardingTour?: boolean;
};

export const CttOnboardingStage = {
	CREATE_INITATIVE: 'CREATE_INITATIVE',
	INPUT_TITLE: 'INPUT_TITLE',
	NAVIGATE_ISSUE_SOURCES: 'NAVIGATE_ISSUE_SOURCES',
	SHOW_ISSUE_SOURCES: 'SHOW_ISSUE_SOURCES',
	NAVIGATE_PLAN: 'NAVIGATE_PLAN',
	EPIC_REPARENT: 'EPIC_REPARENT',
	INPUT_EPIC_REPARENT: 'INPUT_EPIC_REPARENT',
	REVIEW_CHANGES: 'REVIEW_CHANGES',
	FINISH_FLAG: 'FINISH_FLAG',
	CLOSED: 'CLOSED',
} as const;

export const CttOnboardingVariant = {
	TMP_EPIC: 'TMP_EPIC',
	NO_EPIC: 'NO_EPIC',
	EPIC: 'EPIC',
	NO_INITIATIVE: 'NO_INITIATIVE',
} as const;

export type CttOnboardingStageType = (typeof CttOnboardingStage)[keyof typeof CttOnboardingStage];

export type CttOnboardingVariantType =
	(typeof CttOnboardingVariant)[keyof typeof CttOnboardingVariant];

export type CttOnboarding = {
	stage: CttOnboardingStageType;
	planId: number;
};

export type WithSilent<T> = T & {
	/** Determines whether the closed state is persisted without the actual UI changing. */
	silent: boolean;
};
