import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'portfolio-3-onboarding.ctp-onboarding.timeline-spotlight.heading',
		defaultMessage: 'Welcome to your cross-team plan',
		description: 'Heading for the cross team planning template timeline spotlight card.',
	},
	bodyFirst: {
		id: 'portfolio-3-onboarding.ctp-onboarding.timeline-spotlight.body-first',
		defaultMessage: 'Get a complete view of the work of multiple teams as a list or timeline.',
		description:
			'First line of the body for the cross team planning template timeline spotlight card.',
	},
	bodySecond: {
		id: 'portfolio-3-onboarding.ctp-onboarding.timeline-spotlight.body-second',
		defaultMessage:
			'Start by adding issues, then applying filters to track, plan or report on work.',
		description:
			'Second line of the body for the cross team planning template timeline spotlight card.',
	},
	next: {
		id: 'portfolio-3-onboarding.ctp-onboarding.timeline-spotlight.next',
		defaultMessage: 'Next',
		description: 'Button copy for the cross team planning template timeline spotlight card.',
	},
});
