import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	summaryTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.summary-title',
		defaultMessage: 'summary',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans summary page',
	},
	timelineTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.timeline-title',
		defaultMessage: 'timeline',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans timeline page',
	},
	calendarTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.calendar-title',
		defaultMessage: 'calendar',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans calendar page',
	},
	programTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.program-title',
		defaultMessage: 'program',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans program page',
	},
	dependenciesTitle: {
		id: 'horizontal-nav-plans.plans-navigation.header-menu-far.share-button.dependencies-title',
		defaultMessage: 'dependencies',
		description:
			'Title for the share dialog that appears after clicking the share button at the top-right corner of the plans dependencies page',
	},
});
