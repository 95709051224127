import * as R from 'ramda';
import type { Component } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { chain, indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Project } from '../../state/domain/projects/types.tsx';
import { getProjects, getProjectsById } from '../projects/index.tsx';

export type ComponentsById = Record<number, Component>;

export const getComponentsPure = (projects: Project[]): Component[] =>
	chain(({ components }) => components || [], projects);

export const getComponents = createSelector([getProjects], getComponentsPure);

export const getComponentsById = createSelector(
	[getComponents],
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	(components): ComponentsById => indexBy(R.prop('id'), components) as unknown as ComponentsById,
);

export const getComponentsByProjectId = createSelector([getProjectsById], (projectsById) =>
	R.map<Record<number, Project>, Record<number, Component[]>>(
		(project) => project.components ?? [],
		projectsById,
	),
);
