export const ARCHIVED = 'archived';
export const AT_RISK = 'at_risk';
export const CANCELLED = 'cancelled';
export const DONE = 'done';
export const OFF_TRACK = 'off_track';
export const ON_TRACK = 'on_track';
export const PAUSED = 'paused';
export const PENDING = 'pending';
// eslint-disable-next-line @atlassian/relay/no-future-added-value
const FUTURE_ADDED_VALUE = '%future added value';

export const GoalStateValues = {
	ARCHIVED,
	AT_RISK,
	CANCELLED,
	DONE,
	OFF_TRACK,
	ON_TRACK,
	PAUSED,
	PENDING,
} as const;

export type GoalStateValue =
	| typeof ARCHIVED
	| typeof AT_RISK
	| typeof CANCELLED
	| typeof DONE
	| typeof ON_TRACK
	| typeof OFF_TRACK
	| typeof PAUSED
	| typeof PENDING
	| typeof FUTURE_ADDED_VALUE;

export interface GoalState {
	readonly label: string;
	readonly score?: number | null;
	readonly value: GoalStateValue;
}

export interface Goal {
	readonly id: string;
	readonly uuid: string;
	readonly key: string;
	readonly name: string;
	readonly state: GoalState;
}
