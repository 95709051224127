import * as R from 'ramda';
import {
	UPDATE_ISSUE_ASSIGNEE_MAP,
	type UpdateIssueAssigneeMapAction,
	UPDATE_ASSIGNEES_LIST,
	type UpdateAssigneesListAction,
} from './actions.tsx';
import type { MergeUsers, State, Person } from './types.tsx';

const initialState: State = {
	issueAssigneeMap: {},
	assigneeList: [],
};

type Action = UpdateIssueAssigneeMapAction | UpdateAssigneesListAction;

export const mergeUsers: MergeUsers = (existingPeople: Person[], newPeople: Person[]) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const indexedExistingPeople = R.indexBy(R.path<any>(['jiraUser', 'accountId']), existingPeople);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const indexedNewPeople = R.indexBy(R.path<any>(['jiraUser', 'accountId']), newPeople);
	const mergedUsers = { ...indexedExistingPeople, ...indexedNewPeople };

	return R.values(mergedUsers);
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action) => {
	switch (action.type) {
		case UPDATE_ISSUE_ASSIGNEE_MAP:
			return {
				...state,
				issueAssigneeMap: {
					...state.issueAssigneeMap,
					...action.payload,
				},
			};
		case UPDATE_ASSIGNEES_LIST: {
			const { people } = action.payload;
			return {
				...state,
				assigneeList: mergeUsers(state.assigneeList, people),
			};
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
