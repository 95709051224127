.crossProjectReleaseOption {
	display: flex;
}

.crossProjectReleaseName {
	flex: 1 1 0%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	margin-right: var(--jpo-common-base-spacing);
}

.crossProjectReleaseLozenge {
	flex: 0 1 0%;
	max-width: 100px;
}

.crossProjectReleaseLozenge > span {
	max-width: 100px;
}

.moreReleases {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: var(--ds-text-disabled, var(--adg3-color-N70));
}
