import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	labelPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.label-select.label-placeholder',
		defaultMessage: 'Choose a label',
		description: 'Placeholder for the label select dropdown',
	},
	newLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.label-select.new-label',
		defaultMessage: 'New label',
		description: 'Text shown when user creating a new label',
	},
	invalidInputWithSpace: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.label-select.invalid-input-with-space',
		defaultMessage: "Invalid input, labels can't contain spaces",
		description: 'Message to show when there is no labels because query contains spaces',
	},
	invalidInputWithMaxLength: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-label.label-select.invalid-input-with-max-length',
		defaultMessage: "Invalid input, labels can't have more than 255 characters",
		description:
			'Message to show when there is no labels because input has more than 255 characters',
	},
});
