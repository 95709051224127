import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	lessThanOneDayLeadTime: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.cells.lead-time.less-than-one-day-lead-time',
		defaultMessage: '+ < 1 day',
		description: 'Text for the label when the lead time is less than a day',
	},
	zeroLeadTime: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.cells.lead-time.zero-lead-time',
		defaultMessage: '0 days',
		description: 'Label to show when lead time is zero',
	},
});
