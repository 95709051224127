import React from 'react';
import { DatePicker } from '@atlaskit/datetime-picker';
import { Label } from '@atlaskit/form';
import { Inline, Stack, xcss } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import { convertToDateString, createAndHandleDateRangeChange } from './utils.tsx';

export const DateRangeFilter = ({
	startDate: startTimestamp,
	endDate: endTimestamp,
	onDateRangeChange,
}: Props) => {
	const { locale, formatMessage } = useIntl();
	const startDate = convertToDateString(startTimestamp);
	const endDate = convertToDateString(endTimestamp);

	const onStartInputChange = (newStartDate: string | undefined) => {
		onDateRangeChange(createAndHandleDateRangeChange(newStartDate, endDate));
	};

	const onEndInputChange = (newEndDate: string | undefined) => {
		onDateRangeChange(createAndHandleDateRangeChange(startDate, newEndDate));
	};

	return (
		<Inline xcss={filterContainerStyles} space="space.100">
			<Stack grow="fill">
				<Label htmlFor="date-range-filter-from">{formatMessage(messages.startDateLabel)}</Label>
				<DatePicker
					locale={locale}
					value={startDate}
					onChange={onStartInputChange}
					placeholder={formatMessage(messages.noDatePlaceholder)}
					maxDate={endDate}
					id="date-range-filter-from"
				/>
			</Stack>
			<Stack grow="fill">
				<Label htmlFor="date-range-filter-to">{formatMessage(messages.endDateLabel)}</Label>
				<DatePicker
					locale={locale}
					value={endDate}
					minDate={startDate}
					placeholder={formatMessage(messages.noDatePlaceholder)}
					onChange={onEndInputChange}
					id="date-range-filter-to"
				/>
			</Stack>
		</Inline>
	);
};

const filterContainerStyles = xcss({
	width: '278px',
});
