// Note: We use block comment at top of the file because we get strange error in `SSR Image Build`
/* eslint-disable @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766 */

/** @jsx jsx */
import React, { type ComponentType, useEffect, useState, useMemo, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import debounce from 'lodash/debounce';
import differenceBy from 'lodash/differenceBy';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { Box, xcss, Inline } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	selectComponents,
	type MultiValueRemoveProps,
	type ControlProps,
} from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type { InputActionMeta } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import type { LazyGoal } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-goals/types.tsx';
import Cell from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/fields/columns/column/cell/view.tsx';
import {
	withSlots,
	slots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import { GoalStateValues } from '@atlassian/jira-shared-types/src/goal.tsx';
import { GoalHoverCard, BasicGoal } from '@atlassian/jira-software-goals/src/ui/goal/index.tsx';
import { MIN_SELECT_WIDTH } from '../../select/view.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';
import { selectStyles } from './utils.tsx';

/**
 * Custom MultiValueRemove component for GoalCellSelect (ref: https://react-select.com/components#replaceable-components)
 */
const MultiValueRemove = (props: MultiValueRemoveProps<LazyGoal>) => {
	const innerProps = {
		...props.innerProps,
		'aria-label': `Remove ${props.data.goal?.name ?? 'goal'}`,
	};
	return <selectComponents.MultiValueRemove {...props} innerProps={innerProps} />;
};

const Control = (props: ControlProps<LazyGoal>) => {
	const {
		selectProps: { menuIsOpen, value, onMenuOpen, renderMultipleGoals },
	} = props;

	const isDefaultControl = menuIsOpen || isEmpty(value);

	return (
		<selectComponents.Control {...props} isFocused={menuIsOpen}>
			{isDefaultControl && props.children}
			<div
				css={[componentContainerStyles, menuIsOpen && hiddenContainerStyles]}
				role="button"
				tabIndex={0}
				onClick={onMenuOpen}
				onKeyDown={noop}
			>
				{renderMultipleGoals({ onMenuOpen })}
			</div>
		</selectComponents.Control>
	);
};

export function GoalCellSelect({
	lazyGoals,
	onChange,
	issue,
	flexShrinkValues,
	isDisabled,
	FieldSelect,
	isReadOnly,
	isCellDirty,
	isExportMode,
	searchesInProgress,
	goals,
	allLazyGoals,
	searchGoals,
}: Props & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FieldSelect: ComponentType<any>;
}) {
	const [query, setQuery] = useState<string>('');
	const [selectedOptions, setSelectedOptions] = useState<LazyGoal[]>(goals);
	const { formatMessage } = useIntl();

	const searchLazyGoalsByARI = allLazyGoals.filter((lazyGoal) =>
		lazyGoal.goal?.name.toLowerCase().includes(query),
	);

	const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

	useEffect(() => {
		if (!menuIsOpen) setSelectedOptions(lazyGoals);
	}, [menuIsOpen, lazyGoals]);

	const getOptions = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		async (query: string) => {
			const trimmedQuery = query.trim().toLowerCase();
			setQuery(trimmedQuery);
			if (!isEmpty(trimmedQuery)) searchGoals(trimmedQuery);
		},
		[searchGoals],
	);

	const debounceGetOptions = useMemo(() => debounce(getOptions, 250), [getOptions]);
	const handleInputChange = (value: string, { action }: InputActionMeta) => {
		if (action === 'input-change') {
			debounceGetOptions(value);
		}
	};

	const handleMenuOpen = () => {
		setMenuIsOpen(true);
	};

	const handleMenuClose = () => {
		setMenuIsOpen(false);
		onChange(issue, selectedOptions);
	};

	const onItemChanged = (options?: LazyGoal | LazyGoal[] | null) => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const selectedOptions = Array.isArray(options) ? options : [];
		setSelectedOptions(selectedOptions);
		setQuery('');
	};

	const renderMultipleGoals = useCallback(
		({ onMenuOpen }: { onMenuOpen: () => void }) => (
			<>
				{selectedOptions.map((lazyGoal, index) => {
					if (lazyGoal.goal === undefined) {
						return null;
					}
					const { goal } = lazyGoal;
					return (
						<GoalHoverCard
							goal={goal}
							flexShrink={flexShrinkValues[index]}
							key={goal.key}
							onClick={onMenuOpen}
							isExportMode={isExportMode}
						/>
					);
				})}
			</>
		),
		[flexShrinkValues, isExportMode, selectedOptions],
	);

	const filterOptions = () =>
		differenceBy(allLazyGoals, selectedOptions, (goal: LazyGoal) => goal.goal?.id);

	const getFieldSelect = () =>
		isDisabled || isReadOnly ? (
			<div css={labelsContainerStyles}>
				<div css={itemsWrapperStyles}>
					{lazyGoals.length > 0 && renderMultipleGoals({ onMenuOpen: noop })}
				</div>
			</div>
		) : (
			<FieldSelect
				isMulti
				isLoading={searchesInProgress > 0 && query.length > 0}
				value={selectedOptions}
				options={searchLazyGoalsByARI}
				formatOptionLabel={(option: LazyGoal) => {
					if (option.goal) {
						const {
							goal: {
								key,
								name,
								state: { value, label } = {
									value: GoalStateValues.CANCELLED,
									label: 'Cancelled',
								},
							},
						} = option;
						return (
							<Box key={key} xcss={outerWrapperStyles}>
								<Box>
									<Inline
										space="space.050"
										alignBlock="center"
										alignInline="start"
										xcss={goalSelectStyles}
									>
										<BasicGoal
											name={name}
											value={value}
											label={label}
											isExportMode={isExportMode}
										/>
									</Inline>
								</Box>
							</Box>
						);
					}
					return <></>;
				}}
				onMenuOpen={handleMenuOpen}
				onMenuClose={handleMenuClose}
				onChange={onItemChanged}
				closeMenuOnSelect={false}
				styles={selectStyles}
				onInputChange={handleInputChange}
				minSelectWidth={MIN_SELECT_WIDTH}
				placeholder={formatMessage(messages.chooseGoal)}
				noOptionsMessage={() => formatMessage(messages.noMatch)}
				renderMultipleGoals={renderMultipleGoals}
				issueId={issue.id}
				components={{
					Control,
					MultiValueRemove,
					LoadingIndicator: () => <Spinner size="small" />,
				}}
				getOptionLabel={(goal: LazyGoal) => goal.goal?.name}
				getOptionValue={(goal: LazyGoal) => goal.goal?.key}
				filterOption={filterOptions}
			/>
		);

	return (
		<Cell attribute="goal" issue={issue} isScrolling={false} isCellDirty={isCellDirty}>
			{getFieldSelect()}
		</Cell>
	);
}

const goalSelectStyles = xcss({
	height: '30px',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
});

const outerWrapperStyles = xcss({
	display: 'inline',
	minWidth: '38px',
});

const itemsWrapperStyles = css({
	minHeight: '40px',
	width: '100%',
	display: 'flex',
	alignItems: 'center',
});

const labelsContainerStyles = css({
	'& > .itemsWrapper > div': {
		width: '100%',
		padding: `0 ${token('space.100', '8px')}`,
	},
});

const componentContainerStyles = css({
	display: 'flex',
	flexDirection: 'row',
	padding: `0 ${token('space.100', '8px')}`,
	cursor: 'pointer',
	width: '100%',
	boxSizing: 'border-box',
	'& > div': {
		maxWidth: 'calc(100% - 8px)',
	},
});

const hiddenContainerStyles = css({
	display: 'none',
});

export default withSlots({ FieldSelect: slots.FieldSelect })(GoalCellSelect);
