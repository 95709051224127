import React from 'react';
import Heading from '@atlaskit/heading';
import { useIntl } from '@atlassian/jira-intl';
import DocLink from '@atlassian/jira-portfolio-3-portfolio/src/common/view/doc-link/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import Illustration from './team-creation-success.svg';
import type { Props } from './types.tsx';

export default function SuccessMessageDialog(props: Props) {
	const { teamTitle } = props;

	const { formatMessage } = useIntl();

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.dialogWrapper}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.imageIllustrationPanel}>
				<img src={Illustration} alt={formatMessage(messages.illustrationAlt)} />
			</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.messagePanel}>
				<Heading as="h2" size="large">
					{formatMessage(messages.successMessageTitle, { teamTitle })}
				</Heading>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<p className={styles.prompt}>{formatMessage(messages.plansSuccessMessage)}</p>
				<p>
					<DocLink href={DocLink.URLs.committingChangesToJira}>
						{formatMessage(messages.planLinksToSavingChanges)}
					</DocLink>
				</p>
			</div>
		</div>
	);
}
