import { ff } from '@atlassian/jira-feature-flagging';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import ViewOld from './view-old.tsx';
import View from './view.tsx';

export default componentWithCondition(
	() => ff('com.atlassian.rm.jpo.transposition'),
	View,
	ViewOld,
);
