import * as R from 'ramda';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Project } from '../../state/domain/projects/types.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Project } from '../../state/domain/projects/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { EnrichedProject, ProjectsById } from './types';

export const getProjects = (state: State): Project[] => state.domain.projects;

export const getProjectsById = createSelector<State, Project[], Record<number, Project>>(
	[getProjects],
	(projects) => indexBy(R.prop('id'), projects),
);

export const getCompanyManagedProjects = createSelector(
	[getProjects],
	R.filter<Project>(({ isSimplified }) => !isSimplified),
);

export const getTeamManagedProjects = createSelector(
	[getProjects],
	R.filter<Project>(({ isSimplified }) => isSimplified),
);

export const getTeamManagedProjectsSortedByName = createSelector(
	[getTeamManagedProjects],
	R.sort((a, b) => a.name.localeCompare(b.name)),
);

export const getIsSimplifiedProjectAvailable = createSelector(
	[getProjects],
	R.any(R.prop('isSimplified')),
);

const getDefaultProjectIdPure = (projects: Project[]): number | undefined => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const defaultProject = (R.find(R.propEq('isDefault', true))(projects as Required<Project>[]) as
		| Project
		| undefined) || {
		id: undefined,
	};
	return defaultProject.id;
};

export const getDefaultProjectId = createSelector([getProjects], getDefaultProjectIdPure);

export const getIssuePriorityIdsByProjectIdPure = (projects: Project[]) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	projects.reduce<Record<string, any>>(
		(acc, project) =>
			Object.assign(acc, {
				[project.id]: project.issuePriorityIds,
			}),
		{},
	);

export const getIssuePriorityIdsByProjectId = createSelector(
	[getProjects],
	getIssuePriorityIdsByProjectIdPure,
);

export const getDefaultIssuePriorityByProjectIdPure = (projects: Project[]) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	projects.reduce<Record<string, any>>(
		(acc, project) =>
			Object.assign(acc, {
				[project.id]: project.defaultIssuePriorityId,
			}),
		{},
	);

export const getDefaultIssuePriorityByProjectId = createSelector(
	[getProjects],
	getDefaultIssuePriorityByProjectIdPure,
);

export const getReleasesDisabledProject = createSelector(
	[getProjects],
	R.filter<Project>(({ isReleasesEnabled }) => !isReleasesEnabled),
);

export const getIssueTypesByProjectId = createSelector(
	[getProjectsById],
	(projectsById) => (projectId: number) => {
		return projectsById[projectId]?.issueTypeIds;
	},
);
