import React from 'react';
import Icon from '@atlaskit/icon';
import type { IconProps, CustomGlyphProps } from '@atlaskit/icon/types';

const Glyph = (props: CustomGlyphProps) => (
	<svg width="24" height="24" viewBox="0 0 24 24" version="1.1" {...props}>
		<path
			fill="currentColor"
			d="M14.586 11l-2.293-2.293a1 1 0 1 1 1.414-1.414l4 4a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414L14.586 13H10a1 1 0 0 0-1 1v7a1 1 0 0 1-2 0v-7a3 3 0 0 1 3-3h4.586z"
		/>
	</svg>
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (props: IconProps) => <Icon glyph={Glyph} {...props} />;
