import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { type ChangeViewModeAction, CHANGE_VIEW_MODE } from './actions.tsx';
import type { ViewModeState } from './types.tsx';

type Action = ChangeViewModeAction;

const initialState: ViewModeState = {
	mode: VIEW_MODES.LIST,
};

const reducer = (state: ViewModeState = initialState, action: Action): ViewModeState => {
	switch (action.type) {
		case CHANGE_VIEW_MODE:
			return { ...state, mode: action.payload.mode };
		default:
			return { ...state, mode: state.mode || VIEW_MODES.TIMELINE };
	}
};

export default reducer;
