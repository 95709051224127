import type { Effect } from 'redux-saga';
import { put, call, fork, takeEvery } from 'redux-saga/effects';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import {
	SET_INTRO_OPEN,
	SET_ONBOARDING_SHOWN,
	type SetOnBoardingShownAction,
} from '../../state/ui/onboarding/actions.tsx';
import { POST, parseError } from '../api.tsx';
import { genericError } from '../errors/index.tsx';
import { urls, setUserPropertyBody } from './api.tsx';

export const setIntroOpen = (payload: boolean) => ({
	type: SET_INTRO_OPEN,
	payload,
});

export function* doSetOnboardingShown({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: SetOnBoardingShownAction): Generator<Effect, any, any> {
	try {
		const url = urls.set;
		const body = setUserPropertyBody('onboadingShown', payload);
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});
		if (!response.ok) {
			yield put(
				genericError({
					...parseError(response, yield call(response.text.bind(response))),
					requestInfo: {
						url,
						type: POST,
						status: response.status,
						body,
					},
				}),
			);
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchSetOnboarding(): Generator<Effect, any, any> {
	yield takeEvery(SET_ONBOARDING_SHOWN, doSetOnboardingShown);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchSetOnboarding);
}
