import React, { Component } from 'react';
import * as R from 'ramda';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, { ModalHeader, ModalTitle, ModalBody } from '@atlaskit/modal-dialog';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { injectIntl } from '@atlassian/jira-intl';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { MODAL_DIALOG_WIDTH, SET_ASSIGNEE } from '../../types.tsx';
import messages from './messages.tsx';
import type { Props, Value } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class SetAssigneeDialog extends Component<Props> {
	getCurrentValue = (): Value => {
		const {
			selectedIssues,
			assigneesById,
			intl: { formatMessage },
		} = this.props;
		const firstValue = selectedIssues[0].assignee;
		const isSameValue = !selectedIssues.some((issue) => !R.equals(issue.assignee, firstValue));

		if (isSameValue && !firstValue) {
			return { currentValue: formatMessage(commonMessages.none) };
		}

		if (isSameValue && firstValue) {
			const person = assigneesById[firstValue];
			return {
				currentValue: person.jiraUser.title,
				avatarIcon: person.jiraUser.avatarUrl,
			};
		}
		return { currentValue: formatMessage(messages.variousValues) };
	};

	render() {
		const {
			intl: { formatMessage },
			toggleModalDialog,
			bulkUpdateAttribute,
			UpdateAttributeForm,
			bulkActionSuccess,
		} = this.props;
		const { currentValue, avatarIcon } = this.getCurrentValue();
		return (
			<ShortcutScope>
				<ModalDialog
					autoFocus
					shouldScrollInViewport
					onClose={toggleModalDialog}
					width={MODAL_DIALOG_WIDTH}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-assignee"
				>
					<ModalHeader>
						<ModalTitle>{formatMessage(messages.setHeader)}</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<UpdateAttributeForm
							updatedAttributeType={SET_ASSIGNEE}
							currentValue={currentValue}
							avatarIcon={avatarIcon}
							onCancel={toggleModalDialog}
							onSubmit={(formData: { [key: string]: string }, analyticsEvent: UIAnalyticsEvent) => {
								bulkUpdateAttribute(formData);
								toggleModalDialog();
								bulkActionSuccess(analyticsEvent);
							}}
						/>
					</ModalBody>
				</ModalDialog>
			</ShortcutScope>
		);
	}
}

export default injectIntl(SetAssigneeDialog);
