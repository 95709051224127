import React, { useCallback } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import {
	getPlanId,
	getScenarioId,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/util/urls.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { isDefined } from '../../ramda/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function FilterDependencyGraphFromRoadmap({
	children,
	ariaLabel,
	issueId,
	filterDependencyGraphByIssue,
}: Props) {
	const { push } = useRouterActions();
	const planId = getPlanId();
	const scenarioId = getScenarioId();

	const handleClick = useCallback(() => {
		if (fg('smart_links_for_plans')) {
			// issueId should always be defined (even if it is scenario issue), but better be safe than sorry
			if (isDefined(issueId) && planId && scenarioId)
				push(`/jira/plans/${planId}/scenarios/${scenarioId}/dependencies?issueFilter=${issueId}`);

			return;
		}

		// issueId should always be defined (even if it is scenario issue), but better be safe than sorry
		if (isDefined(issueId)) {
			// redirecting the user to the dependencies tab
			if (planId && scenarioId) {
				push(`/jira/plans/${planId}/scenarios/${scenarioId}/dependencies`);
			}

			// setting the issue id in the Filter by issue field of the dependencies tab
			filterDependencyGraphByIssue(issueId);
		}
	}, [push, planId, scenarioId, filterDependencyGraphByIssue, issueId]);

	return (
		<>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.footerActionsSeparator}>·</div>
			<Button
				appearance="link"
				spacing="none"
				onClick={handleClick}
				ariaLabel={ariaLabel}
				testId={`portfolio-3-portfolio.common.filter-dependency-graph-from-roadmap.issue-${issueId}`}
			>
				{children}
			</Button>
		</>
	);
}
