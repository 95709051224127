.warningsButtonIconOnly {
	display: flex;
}

.warningsButtonIconLabel {
	display: none;
}

/* this applies only to plan NOT in REPORT or READONLY modes */
@media (min-width: 1366px) {
	.warningsButtonIconOnly {
		display: none;
	}

	.warningsButtonIconLabel {
		display: flex;
	}
}
