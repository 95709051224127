import React from 'react';
import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';
import type { AssociatedIssue } from '../common/types.tsx';

const Issue = (props: { data: AssociatedIssue }) => (
	<Box xcss={container}>
		<Box xcss={avatar}>
			<Avatar src={props.data.iconUrl} size="xsmall" />
		</Box>
		<Box xcss={summary}>{props.data.summary}</Box>
	</Box>
);

export const AssociatedIssuesView = (props: { associatedIssues: AssociatedIssue[] }) => (
	<div>
		{props.associatedIssues.map((associatedIssue) => (
			<Issue key={associatedIssue.key} data={associatedIssue} />
		))}
	</div>
);

const container = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingInlineEnd: 'space.100',
	height: '100%',
});

const avatar = xcss({
	paddingInlineEnd: 'space.050',
	flexShrink: 0,
});

const summary = xcss({
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	flexBasis: 'content',
	flexShrink: 1,
});
