import sum from 'lodash/fp/sum';
import logs from '@atlassian/jira-common-util-logging/src/log.tsx';
import {
	VIEW_MODES,
	type ViewMode,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import {
	type TIMELINE,
	SCOPE,
	ADD_FIELDS,
	EMPTY,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/constants.tsx';
import type { ColumnId as FieldColumnId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/field-columns/types.tsx';

export type ColumnId = typeof ADD_FIELDS | typeof SCOPE | typeof TIMELINE | FieldColumnId;

const LOCALSTORAGE_PERSIST_KEY = 'persist:@portfolio/common/sections2';

/**
 * Load the column widths from localStorage.
 *
 * @returns column widths and the total fields width
 */
export const load = (
	viewId: number,
	viewMode: ViewMode,
	columnIds: string[],
	defaultWidths: number[],
	minWidths: number[],
): [number[], number | undefined] => {
	try {
		const storedData = JSON.parse(localStorage.getItem(LOCALSTORAGE_PERSIST_KEY) ?? '');
		const sections = JSON.parse(storedData.sections ?? '{}');
		const viewPrefix = viewMode === VIEW_MODES.LIST ? '-list' : '';
		const mainKey = `view-${viewId}${viewPrefix}-main-sections`;
		const fieldsKey = `view-${viewId}${viewPrefix}-fields-section`;

		const normalizeWidth = <T = number,>(
			width: unknown,
			defaultWidth: T,
			minWidth: number,
		): number | T => {
			const numbered = Number(width);
			return Number.isNaN(numbered) ? defaultWidth : Math.max(numbered, minWidth);
		};

		const totalFieldsWidth = normalizeWidth(
			sections?.[mainKey]?.widths['fields-column'] ?? sections?.[fieldsKey]?.totalWidth,
			undefined,
			0,
		);

		const columnWidths = columnIds.map((columnId, index): number => {
			if (columnId === SCOPE) {
				return normalizeWidth(
					sections?.[mainKey]?.widths?.['scope-column'],
					defaultWidths[index],
					minWidths[index],
				);
			}

			if (columnId === ADD_FIELDS && viewMode === VIEW_MODES.LIST) {
				return minWidths[index];
			}

			if (columnId === EMPTY && viewMode === VIEW_MODES.TIMELINE) {
				return totalFieldsWidth ?? 136; // Can't be undefined for EMPTY column
			}

			return normalizeWidth(
				sections?.[fieldsKey]?.widths?.[columnId],
				defaultWidths[index],
				minWidths[index],
			);
		});

		return [columnWidths, totalFieldsWidth];
	} catch (error: unknown) {
		if (error instanceof Error) {
			logs.safeErrorWithoutCustomerData(
				'portfolio-3.table.column-widths-provider',
				'Failed to get stored values for column widths',
				error,
			);
		}

		return [defaultWidths, undefined];
	}
};

export const persist =
	(
		viewId: number,
		viewMode: ViewMode,
		columnIds: string[],
		defaultWidths: number[],
		minWidths: number[],
	) =>
	(columnWidths: number[]) => {
		try {
			const storedData = JSON.parse(localStorage.getItem(LOCALSTORAGE_PERSIST_KEY) || '{}');
			const sections = JSON.parse(storedData.sections || '{}');
			const viewPrefix = viewMode === VIEW_MODES.LIST ? '-list' : '';
			const mainKey = `view-${viewId}${viewPrefix}-main-sections`;
			const fieldsKey = `view-${viewId}${viewPrefix}-fields-section`;

			if (!sections[mainKey]?.widths) {
				sections[mainKey] = { widths: {} };
			}

			if (!sections[fieldsKey]?.widths) {
				sections[fieldsKey] = { widths: {} };
			}

			sections[fieldsKey].totalWidth = sum(
				columnWidths.slice(
					1 /* skips SCOPE */,
					columnWidths.length - 1 /* skips ADD_FIELDS | TIMELINE */,
				),
			);

			sections[mainKey].widths['fields-column'] = sections[fieldsKey].totalWidth;

			columnIds.forEach((columnId, index) => {
				const width = Math.max(columnWidths[index], minWidths[index]);

				if (columnId === ADD_FIELDS && viewMode === VIEW_MODES.LIST) {
					return undefined;
				}

				if (columnId === SCOPE) {
					sections[mainKey].widths['scope-column'] = width;
				}

				sections[fieldsKey].widths[columnId] = width;
			});

			storedData.sections = JSON.stringify(sections);

			localStorage.setItem(LOCALSTORAGE_PERSIST_KEY, JSON.stringify(storedData));
		} catch (error: unknown) {
			if (error instanceof Error) {
				logs.safeErrorWithoutCustomerData(
					'portfolio-3.table.column-widths-provider',
					'Failed to set stored values for column widths',
					error,
				);
			}
		}
	};
