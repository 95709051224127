export const FETCH_USER_START =
	'state.ui.main.tabs.roadmap.fields.custom-fields.user-picker.FETCH_USER_START' as const;
export const FETCH_USER_STOP =
	'state.ui.main.tabs.roadmap.fields.custom-fields.user-picker.FETCH_USER_STOP' as const;

type FetchUserPickerOptionProcessPayload = {
	issueId: string;
};

export type FetchUserStartAction = {
	type: typeof FETCH_USER_START;
	payload: FetchUserPickerOptionProcessPayload;
};

export type FetchUserStopAction = {
	type: typeof FETCH_USER_STOP;
	payload: FetchUserPickerOptionProcessPayload;
};

export const fetchUserStart = (issueId: string): FetchUserStartAction => ({
	type: FETCH_USER_START,
	payload: { issueId },
});

export const fetchUserStop = (issueId: string): FetchUserStopAction => ({
	type: FETCH_USER_STOP,
	payload: { issueId },
});
