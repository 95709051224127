.select-option {
	display: flex;
	align-items: center;
}

.avatar {
	display: inline-flex;
	margin-right: 8px;
}

.label {
	display: inline-flex;
	align-items: center;
	overflow: hidden;
	line-height: 20px;
}

.truncated {
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.issue-icon {
	width: var(--jpo-icon-size-small);
	min-width: var(--jpo-icon-size-small);
	height: var(--jpo-icon-size-small);
	margin-right: 5px;
}

.issue-summary {
	margin-left: 5px;
	word-break: break-all;
	overflow: hidden;
}
