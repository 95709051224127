import type { Effect } from 'redux-saga';
import { identity } from 'ramda';
import { fork, select, takeEvery } from 'redux-saga/effects';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import { getFilteredIssuesWithHierarchy } from '../../query/issues/index.tsx';
import { countVisibleIssues } from '../../query/scope/index.tsx';
import type { IssueLinks } from '../../state/domain/issue-links/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { State } from '../../state/types.tsx';
import {
	type ReportTimeToInteractiveAction,
	type ReportTimeToInteractivePayload,
	REPORT_TIME_TO_INTERACTIVE,
} from './types.tsx';

const numberOfIssueLinks = (values: IssueLinks): number => {
	let count = 0;
	for (const issueId of Object.keys(values)) {
		count += Object.keys(values[issueId] ?? {}).length;
	}
	// issue links (within a plan) are counted twice, one per issue they touch.
	// external issue links (that point to issues outside of a plan) will be only worth 0.5 here
	// but this makes sense as they only render as a single badge, vs the usual 2 (or line)
	return Math.floor(count / 2);
};

export const reportTimeToInteractive = (
	payload: ReportTimeToInteractivePayload,
): ReportTimeToInteractiveAction => ({
	type: REPORT_TIME_TO_INTERACTIVE,
	payload,
});

function* doReportTimeToInteractive({
	payload: { isNewSidebarEnabled, isTransposed = false }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ReportTimeToInteractiveAction): Generator<Effect, void, any> {
	const {
		domain: {
			assignees,
			crossProjectVersions,
			customFields,
			issues,
			issueLinks,
			persons,
			projects,
			sprints,
			teams,
			versions,
			views,
		},
	}: State = yield select(identity);

	const visibleIssueCount: number = yield select(countVisibleIssues);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const filteredIssueCount = ((yield select(getFilteredIssuesWithHierarchy)) as Issue[]).length;

	monitor.addContextualAnalyticsData({ isNewSidebarEnabled, isTransposed });
	monitor.finishInitialRender({
		scalingFactors: {
			assignees: assignees.assigneeList.length,
			crossProjectReleases: crossProjectVersions.length,
			customFields: customFields.length,
			issues: issues.length,
			issuesPostFiltering: filteredIssueCount,
			visibleIssues: visibleIssueCount,
			issueLinks: numberOfIssueLinks(issueLinks.values),
			persons: persons.length,
			projects: projects.length,
			releases: versions.length,
			sprints: sprints.length,
			teams: teams.length,
			views: views.length,
		},
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* watchReportTimeToInteractive(): Generator<Effect, void, any> {
	yield takeEvery(REPORT_TIME_TO_INTERACTIVE, doReportTimeToInteractive);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, void, any> {
	yield fork(watchReportTimeToInteractive);
}
