import type { Effect } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import { suspend, resume } from '../../state/enhancers/suspender/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (callback: any): Generator<Effect, any, any> {
	yield put(suspend());
	try {
		yield call(callback);
	} finally {
		yield put(resume());
	}
}
