import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { RESET, type ResetAction, ADD, type AddAction } from './actions.tsx';

const initialState: Person[] = [];

type Action = ResetAction | AddAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Person[] = initialState, action: Action) => {
	switch (action.type) {
		case RESET:
			return action.payload;
		case ADD:
			return [...state, action.payload];
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
