import React from 'react';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { StatusLozenge as IssueStatusFieldReadonly } from '@atlassian/jira-issue-field-status/src/ui/status-lozenge/index.tsx';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import {
	DialogMenuContainer,
	DialogMenuItem,
	DialogMenuGroup,
} from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import InlineDialog from '@atlassian/jira-portfolio-3-common/src/inline-dialog/index.tsx';
import { UNSAVED_STATUS } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/status-key-filter/pure/index.tsx';
import { STATUS_KEY_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import { CHECKBOX_STATES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import SearchField from '@atlassian/jira-portfolio-3-portfolio/src/common/view/search-field/index.tsx';
import ClearFilterButton from '../common/clear-filter/index.tsx';
import FilterText from '../common/filter-text/index.tsx';
import { FILTER_MAX_WIDTH, FILTER_WIDTH } from '../common/index.tsx';
import NoMatchFound from '../common/no-match-text/index.tsx';
import TriggerButton from '../common/trigger-button/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import '../common/styles.module.css';
import filterMessages from '../messages.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, StatusKey } from './types.tsx';

const isUnsaved = (statusName: string): boolean => statusName === UNSAVED_STATUS.name;

const StatusKeyFilter = (props: Props) => {
	const {
		value: selectedStatusKeys,
		statusOptionsByCategory,
		categoryNameById,
		allCategoryCheckedState,
		searchQuery,
		isOpen,
		changeFilter,
		clearFilter,
		onQueryChange,
		onOpenChange,
	} = props;

	const { formatMessage } = useIntl();

	const isStatusKeySelected = (statusKey: StatusKey) =>
		selectedStatusKeys.some((selectedStatusKey) => isEqual(selectedStatusKey, statusKey));

	const onStatusKeyClick = (clickedStatusKey: StatusKey) => {
		const newValue = isStatusKeySelected(clickedStatusKey)
			? selectedStatusKeys.filter(
					(selectedStatusKey) => !isEqual(selectedStatusKey, clickedStatusKey),
				)
			: [...selectedStatusKeys, clickedStatusKey];
		changeFilter(newValue);
	};

	const onCategoryClick = (clickedCategory: string) => {
		const allStatusNamesFromCategory = statusOptionsByCategory.get(clickedCategory) ?? [];
		const isCategorySelected =
			allStatusNamesFromCategory.length > 0 &&
			allStatusNamesFromCategory.every((statusName) =>
				isStatusKeySelected({ category: clickedCategory, name: statusName }),
			);
		if (isCategorySelected) {
			const newValue = selectedStatusKeys.filter(
				({ category }) => category !== `${clickedCategory}`,
			);
			changeFilter(newValue);
		} else {
			changeFilter(
				uniqWith(
					[
						...selectedStatusKeys,
						...allStatusNamesFromCategory.map((statusName) => ({
							category: `${clickedCategory}`,
							name: statusName,
						})),
					],
					isEqual,
				),
			);
		}
	};

	const onClearFilter = () => {
		clearFilter();
	};

	const filterStatusesWithSearchQuery = (statusName: string): boolean =>
		statusName.toLowerCase().includes(searchQuery.toLowerCase());

	const filteredStatusOptionsByCategory = Array.from(statusOptionsByCategory.entries()).reduce<
		Map<string, string[]>
	>((acc, [category, statusNames]) => {
		const filteredStatusNames = statusNames.filter(filterStatusesWithSearchQuery);
		if (filteredStatusNames.length > 0) {
			acc.set(category, filteredStatusNames);
		}
		return acc;
	}, new Map());

	const renderStatus = (name: string, category: number) => (
		<IssueStatusFieldReadonly value={{ id: 'N/A', name, statusCategory: { id: category } }} />
	);

	const renderTriggerButton = () => {
		const text =
			selectedStatusKeys.length > 0
				? selectedStatusKeys
						.map(({ name }) => (isUnsaved(name) ? formatMessage(messages.unsavedIssues) : name))
						.join(', ')
				: formatMessage(messages.emptyPlaceholder);
		const ariaText = `${formatMessage(
			filterMessages[STATUS_KEY_FILTER_ID],
		)}, ${text} ${formatMessage(filterMessages.selected)}`;

		const renderFilterText = () => {
			if (selectedStatusKeys.length > 0) {
				return <FilterText text={text} />;
			}
			return <FormattedMessage {...messages.emptyPlaceholder} />;
		};

		return (
			<TriggerButton
				isOpen={isOpen}
				onOpenChange={onOpenChange}
				testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter.trigger-btn"
				triggerButtonText={renderFilterText()}
				ariaLabel={ariaText}
			/>
		);
	};

	const renderDropList = () => {
		if (filteredStatusOptionsByCategory.size === 0) {
			return <NoMatchFound />;
		}

		return (
			<>
				{Array.from(filteredStatusOptionsByCategory.keys()).map((category) => (
					<DialogMenuItem key={`${category}-categoryCheckboxes`}>
						<Checkbox
							testId={`portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter.${category}`}
							isChecked={
								allCategoryCheckedState[category] === CHECKBOX_STATES.CHECKED ||
								allCategoryCheckedState[category] === CHECKBOX_STATES.INDETERMINATE
							}
							isIndeterminate={allCategoryCheckedState[category] === CHECKBOX_STATES.INDETERMINATE}
							label={
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<span className={styles.categoriesLabel}>
									<FormattedMessage
										{...messages.categoriesLabel}
										values={{
											status: renderStatus(categoryNameById.get(category) ?? '', Number(category)),
										}}
									/>
								</span>
							}
							onChange={() => onCategoryClick(category)}
						/>
					</DialogMenuItem>
				))}

				{Array.from(filteredStatusOptionsByCategory.entries()).map(([category, statusNames]) => {
					if (statusNames.length > 0) {
						return (
							<DialogMenuGroup
								heading={categoryNameById.get(category) ?? ''}
								key={`${category}-statusCheckboxes`}
							>
								{statusNames.map((statusName) => (
									<DialogMenuItem key={statusName}>
										<Checkbox
											testId={`portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter.${statusName}`}
											isChecked={isStatusKeySelected({
												category,
												name: statusName,
											})}
											onChange={() =>
												onStatusKeyClick({
													category,
													name: statusName,
												})
											}
											label={
												isUnsaved(statusName)
													? formatMessage({
															...messages.unsavedIssues,
														})
													: renderStatus(statusName, Number(category))
											}
										/>
									</DialogMenuItem>
								))}
							</DialogMenuGroup>
						);
					}
					return null;
				})}
			</>
		);
	};

	return (
		<InlineDialog
			noPaddings
			maxWidth={FILTER_MAX_WIDTH}
			minWidth={FILTER_WIDTH}
			onClose={onOpenChange}
			isOpen={isOpen}
			content={
				<DialogMenuContainer>
					<ClearFilterButton
						isVisible={selectedStatusKeys.length > 0}
						onClearClick={onClearFilter}
					/>
					<SearchField
						placeholder={formatMessage(messages.searchStatusPlaceholder)}
						searchQuery={searchQuery}
						onQueryChange={onQueryChange}
						ariaLabel={formatMessage(messages.searchStatusLabel)}
					/>
					{renderDropList()}
				</DialogMenuContainer>
			}
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.status-key-filter"
		>
			{renderTriggerButton()}
		</InlineDialog>
	);
};

StatusKeyFilter.defaultProps = {
	isOpen: false,
};

export default StatusKeyFilter;
