import type { Dispatch } from 'redux';
import {
	clear,
	change,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters/index.tsx';
import {
	SPRINT_FILTER_ID,
	type StringAttributeFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { Action, DispatchProps } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action>): DispatchProps => ({
	changeFilter: (payload: StringAttributeFilterValue) => {
		dispatch(change({ id: SPRINT_FILTER_ID, value: payload }));
	},
	clearFilter: () => {
		dispatch(clear(SPRINT_FILTER_ID));
	},
});
