export const HOVER_TIMELINE_ISSUE = 'command.timeline-preview.HOVER_TIMELINE_ISSUE' as const;
export const MOVE_TIMELINE_ISSUE = 'command.timeline-preview.MOVE_TIMELINE_ISSUE' as const;
export const RESIZE_TIMELINE_ISSUE = 'command.timeline-preview.RESIZE_TIMELINE_ISSUE' as const;
export const COMMIT_TIMELINE_PREVIEW = 'command.timeline-preview.COMMIT_TIMELINE_PREVIEW' as const;
export const CANCEL_TIMELINE_PREVIEW = 'command.timeline-preview.CANCEL_TIMELINE_PREVIEW' as const;

export type HoverTimelineIssuePayload = {
	issueId: string;
	defaultStart: number | null | undefined;
	defaultEnd: number | null | undefined;
};

export type HoverTimelineIssueAction = {
	type: typeof HOVER_TIMELINE_ISSUE;
	payload: HoverTimelineIssuePayload;
};

export type MoveTimelineIssuePayload = {
	issueId: string;
	delta: number;
	targetStart?: number;
	targetEnd?: number;
};

export type MoveTimelineIssueAction = {
	type: typeof MOVE_TIMELINE_ISSUE;
	payload: MoveTimelineIssuePayload;
};

export type ResizeTimelineIssuePayload = {
	issueId: string;
	baselineStart: number | null | undefined;
	baselineEnd: number | null | undefined;
};

export type ResizeTimelineIssueAction = {
	type: typeof RESIZE_TIMELINE_ISSUE;
	payload: ResizeTimelineIssuePayload;
};
