import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	resetSortingOrder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.header.fields.reset-sorting-order.reset-sorting-order',
		defaultMessage: 'Reset sorting order',
		description: 'Label for button which triggers a reset of sort order',
	},
});

export default messages;
