/**
 * Icon and tooltip wrapper
 */

import React, { type ReactNode } from 'react';
import classNames from 'classnames';
import Tooltip from '@atlaskit/tooltip';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

type IconMode = 'collapsed' | 'header' | 'inline';

const TitleWithIcon = ({
	icon,
	tooltip = '',
	mode,
	children,
}: {
	icon?: ReactNode;
	tooltip?: string;
	mode: IconMode;
	children: ReactNode;
}) => {
	const titleStyle = classNames({
		[styles.title]: true,

		[styles.__header]: mode === 'header',
	});

	const iconStyle = classNames({
		[styles['title-icon']]: true,

		[styles.__collapsed]: mode === 'collapsed',
	});

	const element = (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={titleStyle}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<span className={styles['truncate-text']}>{children}</span>
			{icon !== undefined && (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<span className={iconStyle} data-testid="portfolio-3-common.sections.title-icon">
					{icon}
				</span>
			)}
		</div>
	);

	return tooltip ? <Tooltip content={tooltip}>{element}</Tooltip> : element;
};

export default TitleWithIcon;
