import type { Dispatch } from 'redux';
import {
	updateIssueOrInlineCreate,
	updateIssueRaw,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/index.tsx';
import type { UpdateIssuePayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/types.tsx';
import { setIssueBeingEdited } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-modal/actions.tsx';
import type { DispatchProps, Action } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (dispatch: Dispatch<Action>): DispatchProps => ({
	setIssueBeingEdited: (issueId: string | null) => {
		dispatch(setIssueBeingEdited(issueId));
	},
	setDescription: (issueId, description) => {
		dispatch(
			updateIssueOrInlineCreate({
				id: issueId,
				description,
			}),
		);
	},
	onChange: (payload: UpdateIssuePayload) => {
		dispatch(updateIssueRaw(payload));
	},
});
