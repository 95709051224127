import { useIntl } from '@atlassian/jira-intl';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type { GroupCombination } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import { type Option, UNGROUPED } from '../types.tsx';
import { optionSortComparator } from '../utils.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ProjectOptionsProvider = ({ projects, children }: Props) => {
	const { formatMessage } = useIntl();

	const createOptionFromProjects = (project: Project): Option<GroupCombination> => ({
		id: `project-${project.id}`,
		name: project.name,
		value: { project: project.id },
		url: project.avatarUrl,
		urlType: 'avatar',
	});

	const getOptionsFromProjects = (): Option<GroupCombination>[] =>
		projects.map(createOptionFromProjects).sort(optionSortComparator);

	return children({
		options: {
			type: UNGROUPED,
			options: getOptionsFromProjects(),
		},
		menuTitle: formatMessage(messages.projectMenuTitle),
		searchPlaceholder: formatMessage(messages.projectSearchPlaceholder),
	});
};

export default ProjectOptionsProvider;
