.addDependencyType {
	padding-left: 0;
	padding-right: 0;
	min-width: 140px;
}

.addDependencyIssue {
	min-width: 300px;
	display: flex;
	flex-wrap: nowrap;
}

button.addDependencyButton {
	width: 40px;
	height: 40px;
}
