import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.create-twp-team-form.title',
		defaultMessage: 'Create a new team',
		description: 'Dialog title for the create new TWP team',
	},
	descriptionParagraph1: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.create-twp-team-form.description-paragraph-1',
		defaultMessage:
			'Stay connected with @mentions, collaborate on work, and efficiently manage everything in one place.',
		description: 'Description paragraph 1',
	},
	descriptionParagraph2: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.create-twp-team-form.description-paragraph-2',
		defaultMessage: 'Once created, you can manage this team from the Teams homepage.',
		description: 'Description paragraph 2',
	},
	docLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.create-twp-team-form.doc-link',
		defaultMessage: 'What can Teams do?',
		description: 'Text for doc link',
	},
	createTeam: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.teams.list.team-dialog.create-twp-team-form.create-team',
		defaultMessage: 'Create team',
		description: 'Label for the create team button',
	},
});
