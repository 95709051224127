import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createGroup: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.custom-fields.create-group',
		defaultMessage: 'Create group',
		description: 'The label for the Create group link in the group-by-custom-fields feature',
	},
	componentPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.custom-fields.component-placeholder',
		defaultMessage: 'Choose a value',
		description: 'The placeholder for the group-by custom field select',
	},
});
