import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	spotlightGotIt: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-review-changes.spotlight-got-it',
		defaultMessage: 'Got it',
		description: 'The got it button for cross team templates onboarding spotlight card.',
	},
	spotlightUnsavedChangesTitle: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-review-changes.spotlight-unsaved-changes-title',
		defaultMessage: 'A plan is a draft until you save changes',
		description: 'The title for cross team templates onboarding unsaved changes spotlight card.',
	},
	spotlightReviewChangesBodyP2: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-review-changes.spotlight-review-changes-body-p-2',
		defaultMessage: 'Use <b>Review changes</b> to save or discard your edits.',
		description: 'Part two of body for cross team templates onboarding review changes card.',
	},
	spotlightUnsavedChangesBody: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-review-changes.spotlight-unsaved-changes-body',
		defaultMessage:
			'Use <b>Unsaved changes</b> to save issues, teams or releases created or changed in your plan so they update in projects.',
		description: 'Body for cross team templates onboarding unsaved changes spotlight card',
	},
});
