export const SET_DELETE_DIALOG_VISIBILITY =
	'state.ui.top.title-bar.plan-settings.TOGGLE_DELETE_DIALOG_VISIBILITY' as const;

export const ENABLE_DELETE = 'state.ui.top.title-bar.plan-settings.ENABLE_DELETE' as const;

export const DISABLE_DELETE = 'state.ui.top.title-bar.plan-settings.DISABLE_DELETE' as const;

export const REDIRECT_TO_SETTINGS =
	'state.ui.top.title-bar.plan-settings.REDIRECT_TO_SETTINGS' as const;

export type RedirectToSettingsAction = {
	type: typeof REDIRECT_TO_SETTINGS;
	payload: string;
};

export const redirectToSettings = (redirectFrom: string): RedirectToSettingsAction => ({
	type: REDIRECT_TO_SETTINGS,
	payload: redirectFrom,
});

export type SetDeleteDialogVisibilityAction = {
	type: typeof SET_DELETE_DIALOG_VISIBILITY;
	payload: boolean;
};

export const setDeleteDialogVisibility = (
	visibility: boolean,
): SetDeleteDialogVisibilityAction => ({
	type: SET_DELETE_DIALOG_VISIBILITY,
	payload: visibility,
});

export type EnableDeleteAction = {
	type: typeof ENABLE_DELETE;
};

export type DisableDeleteAction = {
	type: typeof DISABLE_DELETE;
};
