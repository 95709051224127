.dialogWrapper {
	flex: 1;
	display: flex;
	align-items: center;
	height: 300px;
}

.imageIllustrationPanel {
	flex: 1;
	display: flex;
	justify-content: center;
	margin-top: 40px;

	& img {
		width: 215px;
	}
}

.messagePanel {
	flex: 1;
	margin-top: 40px;
}

.prompt {
	color: var(--ds-text-subtle, var(--jpo-text-secondary-color));
}
