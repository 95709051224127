import {
	SET_ACTIVE_CARD,
	SET_INTRO_OPEN,
	type SetActiveCardAction,
	type SetIntroOpenAction,
} from './actions.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	isIntroOpen: false,
	activeCard: '',
};

type Action = SetActiveCardAction | SetIntroOpenAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action) => {
	switch (action.type) {
		case SET_ACTIVE_CARD:
			return {
				...state,
				activeCard: action.payload,
			};
		case SET_INTRO_OPEN:
			return {
				...state,
				isIntroOpen: action.payload,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
