.dependencies {
	user-select: none;
	position: absolute;

	&.start {
		text-align: right;
	}

	&.end {
	}

	&.unhovered {
		z-index: var(--jpo-z-index-dependency-marker);
	}

	&.hovered {
		z-index: var(--jpo-z-index-dependency-marker-hovered);
	}
}

.badge-count {
	font-size: 10px;
	line-height: 16px;
}
