.container {
	/* This sets up the layout of the bar in order to correctly place it within a cell */
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	box-sizing: border-box;

	& .breakdown {
		width: 100%;

		& .bar {
			border-radius: 3px;
			overflow: hidden;
			height: 8px;
			display: flex;
			width: 100%;

			& .completed {
				background-color: var(--ds-border-success, var(--adg3-color-G300));
			}

			& .completed.overbooked {
				background-color: var(--ds-background-danger-bold, var(--adg3-color-R300));
			}

			& .remaining {
				background-color: var(--ds-border, var(--adg3-color-N50));
			}

			& > * + * {
				margin-left: 2px;
			}
		}
	}
}
