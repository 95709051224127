import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	changeboardingFlagTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.associated-issues-provider.associated-issues-changeboarding-flag.changeboarding-flag-title',
		defaultMessage: 'Filter or group linked work by ideas',
		description: 'A title for changeboarding flag',
	},
	changeboardingFlagDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.associated-issues-provider.associated-issues-changeboarding-flag.changeboarding-flag-description',
		defaultMessage:
			'<a>Ideas by Jira Product Discovery</a> will now show up in your plan as a field.',
		description: 'A description for changeboarding flag',
	},
	changeboardingFlagDismiss: {
		id: 'portfolio-3-portfolio.app-simple-plans.associated-issues-provider.associated-issues-changeboarding-flag.changeboarding-flag-dismiss',
		defaultMessage: 'Dismiss',
		description: 'A dismiss text for changeboarding flag',
	},
	changeboardingFlagReadMore: {
		id: 'portfolio-3-portfolio.app-simple-plans.associated-issues-provider.associated-issues-changeboarding-flag.changeboarding-flag-read-more',
		defaultMessage: 'Read more',
		description: 'A "read more" text for changeboarding flag',
	},
});
