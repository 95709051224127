import React, { useEffect, useContext } from 'react';
import isNil from 'lodash/isNil';
import { useRelayEnvironment } from 'react-relay';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import { DropdownItemGroup as DropMenuItemGroup } from '@atlaskit/dropdown-menu';
import InfoIcon from '@atlaskit/icon/core/migration/information--editor-panel';
import Lozenge from '@atlaskit/lozenge';
import { Box, Flex, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { AiIcon } from '@atlassian/jira-atlassian-intelligence/src/common/ui/ai-icon/index.tsx';
import { convertToJiraProjectType } from '@atlassian/jira-common-constants/src/project-types.tsx';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { fetchAiOptInData } from '@atlassian/jira-issue-fetch-services/src/services/ai-optin-data/index.tsx';
import {
	ISSUE_HIERARCHY_LEVEL_BASE,
	ISSUE_HIERARCHY_LEVEL_SUBTASK,
} from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import {
	steps,
	useIssueBreakdown,
} from '@atlassian/jira-portfolio-3-ai-work-breakdown/src/controllers/context.tsx';
import { IssueBreakdownEntryPointContext } from '@atlassian/jira-portfolio-3-ai-work-breakdown/src/controllers/entrypoint-context.tsx';
import { DropMenuItem } from '@atlassian/jira-portfolio-3-common/src/drop-menu/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { isScenarioIssue as getIsScenarioIssue } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/issue.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const SuggestChildIssues = ({
	issue,
	childrenIdsByParent,
	projectsById,
	toggleAiWorkBreakdownPopup,
}: Props) => {
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const environment = useRelayEnvironment();
	const [
		{ aiOptInEnabledByProject },
		{ getAiOptInEnabled, setAiOptInEnabled, setIssueBreakdownStep, updateStreamingStatus },
	] = useIssueBreakdown();
	const { entryPointActions } = useContext(IssueBreakdownEntryPointContext);
	const { key: projectKey, projectTypeKey: projectType } = projectsById[issue.project.id];

	/*
	 * Atlassian Intelligence is available and automatically activated for all products on Premium and Enterprise plans.
	 * However, org admins can disable AI in the admin preferences, therefore we still need to check its enablement.
	 *
	 * When the component mounts, we make a GraphQL query in "issue-fetch-services/src/services/ai-optin-data/index.tsx"
	 * and provide the project type, cloud ID, and environment to fetch the AI opt-in data.
	 */
	useEffect(() => {
		const fetchAiOptInEnablement = async () => {
			try {
				const isAiOptInEnabled = await fetchAiOptInData(
					convertToJiraProjectType(projectType),
					cloudId,
					environment,
				);
				setAiOptInEnabled(projectKey, isAiOptInEnabled);
			} catch (error: unknown) {
				log.safeErrorWithoutCustomerData(
					'issue.fetch.services.ai.optin.service',
					'Error fetching ai opt in value',
					error instanceof Error ? error : undefined,
				);
				setAiOptInEnabled(projectKey, false);
			}
		};

		/*
		 * To avoid making multiple requests for the same project, we check if the AI opt-in data is already available in the
		 * "aiOptInEnabledByProject" object (aiOptInEnabledByProject: { [projectKey: string]: boolean }).
		 */
		if (isNil(aiOptInEnabledByProject[projectKey])) {
			fetchAiOptInEnablement();
		}
	}, [aiOptInEnabledByProject, cloudId, environment, projectKey, projectType, setAiOptInEnabled]);

	const triggerAnalytics = (analyticsEvent: UIAnalyticsEvent) => {
		const [actionSubject, eventAction] =
			PRODUCT_ANALYTICS_EVENT_NAMES.AI_SUGGEST_CHILD_ISSUES.split(' ');
		fireUIAnalytics(
			analyticsEvent.update({ action: eventAction, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.AI_SUGGEST_CHILD_ISSUES,
			{
				hierarchyLevel: issue.level,
				hasChildIssues: !!childrenIdsByParent[issue.id],
			},
		);
	};

	const isAiOptInEnabled = getAiOptInEnabled(projectKey);

	const isDisabled =
		getIsScenarioIssue(issue.id) || isDefined(issue.originals) || !isAiOptInEnabled;

	const storyLevel = issue.level === ISSUE_HIERARCHY_LEVEL_BASE;

	const getTooltipContent = () => {
		if (!isAiOptInEnabled) {
			return formatMessage(messages.tooltipForAiOptInDisabled);
		}
		if (isDisabled) {
			return formatMessage(messages.tooltipForDisabledSuggestChildIssues);
		}
		if (storyLevel) {
			return formatMessage(messages.tooltipSuggestSubtasks);
		}
		return formatMessage(messages.tooltipSuggestChildIssues);
	};

	const renderDisabledInfoIconTooltip = () => {
		return (
			<Tooltip content={getTooltipContent()} position="top">
				{(tooltipProps) => (
					<IconButton
						{...tooltipProps}
						appearance="subtle"
						icon={InfoIcon}
						label={formatMessage(commonMessages.moreInformation)}
						testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.icon-button"
					/>
				)}
			</Tooltip>
		);
	};

	if (issue.level === ISSUE_HIERARCHY_LEVEL_SUBTASK) {
		return null;
	}

	return (
		<DropMenuItemGroup>
			<Tooltip content={isDisabled ? null : getTooltipContent()} position="right">
				<DropMenuItem
					id={`suggest-child-issues-${issue.level}`}
					key={`suggest-child-issues-${issue.level}`}
					isDisabled={isDisabled}
					elemBefore={<AiIcon size="medium" label="" isDisabled={isDisabled} />}
					elemAfter={isDisabled ? renderDisabledInfoIconTooltip() : null}
					onClick={(_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
						entryPointActions.load();
						// Open the AI work breakdown popup
						toggleAiWorkBreakdownPopup && toggleAiWorkBreakdownPopup({ isOpen: true });

						// Trigger the suggestion of child issues for the current parent issue
						setIssueBreakdownStep(steps.defaultStep);
						updateStreamingStatus(true);

						if (analyticsEvent) {
							triggerAnalytics(analyticsEvent);
						}
					}}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.issues.issue.drop-menu.ai-suggest-child-issues.button"
				>
					<Flex>
						<FormattedMessage
							{...(storyLevel ? messages.suggestSubtasks : messages.suggestChildIssues)}
						/>
						<Box xcss={lozengeStyles}>
							<Lozenge appearance="new">{formatMessage(messages.betaLozenge)}</Lozenge>
						</Box>
					</Flex>
				</DropMenuItem>
			</Tooltip>
		</DropMenuItemGroup>
	);
};

export default SuggestChildIssues;

const lozengeStyles = xcss({
	alignSelf: 'center',
	marginLeft: 'space.100',
});
