import React from 'react';
import ProgressBar from '../progress-bar/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function ProgressCard(props: Props) {
	const { label, percentage, progressMessage } = props;
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.container}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.header}>
				<div>{label}</div>
				<div>{progressMessage}</div>
			</div>
			<ProgressBar percentage={percentage} />
		</div>
	);
}
