import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	shiftTimelineLeft: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.panning-control.shift-timeline-left',
		defaultMessage: 'Shift timeline left',
		description:
			'Label for button which shifts the timeline to the left (date range to later date)',
	},
	shiftTimelineRight: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.panning-control.shift-timeline-right',
		defaultMessage: 'Shift timeline right',
		description:
			'Label for button which shifts the timeline to the right (date range to earlier date)',
	},
});
