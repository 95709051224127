import {
	bulkIssuesCRPUpdate,
	bulkIssuesUpdate,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/index.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	bulkIssuesCRPUpdate: (cprId: string) => {
		dispatch(bulkIssuesCRPUpdate(cprId));
	},
	bulkUnsetVersion: () => {
		dispatch(bulkIssuesUpdate({ fixVersions: [] }));
	},
});

export default mapDispatchToProps;
