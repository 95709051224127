import React from 'react';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import RoadmapAsync from '../main/tabs/roadmap/async.tsx';
import { PlanTable } from '../main/tabs/roadmap/table/index.tsx';
import { TableSkeleton } from '../main/tabs/roadmap/table/skeleton/index.tsx';
import { PlanTableWrapper } from '../main/tabs/roadmap/table/wrapper/index.tsx';
import UseLocalStorageCommand from '../main/tabs/roadmap/use-local-storage-command/index.tsx';
import type { Props } from './types.tsx';

// When cleaning up FF com.atlassian.rm.jpo.transposition we can remove this component entirely
const RoadmapWrapper = ({ isLoading, ...props }: Props) => {
	if (ff('com.atlassian.rm.jpo.transposition')) {
		return isLoading ? (
			<TableSkeleton />
		) : (
			<>
				{fg('plans_performance_improvements_2') ? (
					<PlanTableWrapper>
						<PlanTable />
					</PlanTableWrapper>
				) : (
					<PlanTable />
				)}

				<UseLocalStorageCommand />
			</>
		);
	}

	return <RoadmapAsync {...props} />;
};

export default RoadmapWrapper;
