import React, { Component, type ReactNode } from 'react';
import keycode from 'keycode';

/*
 * This component provides an easy way to handle 'enter' and 'escape' and prevent the
 * keydown events from bubbling past this component at a DOM level.
 *
 * This should only be used when needing to block events keyboard events that aren't making use
 * of the existing Shortcuts component.
 */
type Props = {
	children: ReactNode;
	onEscape: null | ((e: KeyboardEvent) => void);
	onEnter: null | ((e: KeyboardEvent) => void);
	onBlur: null | (() => void);
	className?: string;
};

/**
 * This component enhances user interaction within UI elements like modals by listening for
 * specific keyboard events (enter and escape keys). It facilitates the execution of custom
 * callbacks, improving accessibility and UX by allowing keyboard navigation. Ideal for
 * implementing intuitive closing and submitting actions without relying on traditional
 * event handling mechanisms that may not stop event propagation effectively in React.
 */
// eslint-disable-next-line jira/react/no-class-components
export default class EnterEscapeHandler extends Component<Props> {
	static defaultProps = {
		onEscape: null,
		onEnter: null,
		onBlur: null,
		className: undefined,
	};

	componentDidMount() {
		// AK Modal uses document.addEventListener() so we also have to use addEventListener.
		// We can't rely on React Synthetic events as stopPropagation won't work
		// This is due to the way React handles events
		// https://github.com/facebook/react/issues/7094
		if (this.ref) {
			this.ref.addEventListener('keydown', this.handleKeyDown);
		}
	}

	componentWillUnmount() {
		if (this.ref) {
			this.ref.removeEventListener('keydown', this.handleKeyDown);
		}
	}

	setRef = (ref: HTMLDivElement | null) => {
		this.ref = ref;
	};

	// @ts-expect-error - TS2564 - Property 'ref' has no initializer and is not definitely assigned in the constructor.
	ref: HTMLDivElement | null;

	handleKeyDown = (event: KeyboardEvent) => {
		switch (event.keyCode) {
			case keycode('escape'):
				if (this.props.onEscape) {
					this.props.onEscape(event);
					event.stopPropagation();
				}
				break;
			case keycode('enter'):
				if (this.props.onEnter) {
					this.props.onEnter(event);
					event.stopPropagation();
				}
				break;
			default:
				break;
		}
	};

	render() {
		return (
			// @ts-expect-error - TS2322 - Type '(() => void) | null' is not assignable to type 'FocusEventHandler<HTMLDivElement> | undefined'.
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div onBlur={this.props.onBlur} ref={this.setRef} className={this.props.className}>
				{this.props.children}
			</div>
		);
	}
}
