.externalSprint {
	padding-right: var(--jpo-common-base-spacing);
	display: flex;
	width: 100%;
}

.externalSprintText {
	display: inline-block;
	max-width: calc(100% - 30px);
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: middle;
	white-space: nowrap;
	min-width: 14px;
}

.externalSprintLozenge {
	flex-shrink: 0;
	align-items: baseline;
	display: inline-flex;
	margin-left: var(--jpo-common-base-spacing);
	overflow: hidden;
	& > div {
		padding-right: calc(0.5 * var(--jpo-common-base-spacing));
	}
}

.completedSprintsChunk {
	color: var(--ds-text-disabled, var(--adg3-color-N70));
}

.sprintTitlePrefix {
	padding: 0 calc(0.5 * var(--jpo-common-base-spacing));
}

.sprintTitle {
	overflow: hidden;
	text-overflow: ellipsis;
}

.menuLabelDisabled {
	color: var(--ds-text-disabled, var(--adg3-color-N70));
	cursor: not-allowed;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.tooltip {
	padding: var(--jpo-common-base-spacing);
	text-align: center;
}

.tooltip > h4 {
	font-weight: bold;
	color: var(--ds-text-inverse, var(--adg3-color-N0));
}

.completedAndUncompletedSprints {
	display: flex;
	& > span {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
