import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { fork, takeEvery, put, call, select } from 'redux-saga/effects';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import {
	type ShareAs,
	SHARE_AS,
	TIMELINE_SHARE_TYPE,
	DEPENDENCY_REPORT_SHARE_TYPE,
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getLocation } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import { getDependencyFilters } from '../../query/dependencies/index.tsx';
import { getShareAsState } from '../../query/share-report/index.tsx';
import { getViewSettings } from '../../query/view-settings/index.tsx';
import type { State } from '../../state/types.tsx';
import {
	updateShareReportUrl,
	setLoadingShareReport,
} from '../../state/ui/top/title-bar/share-report/actions.tsx';
import { POST, parseError } from '../api.tsx';
import { genericError as deprecatedGenericError } from '../errors/index.tsx';
import { toErrorID } from '../util.tsx';
import { urls } from './api.tsx';

export const CREATE_SHARE_REPORT_URL = 'command.share-report.CREATE_SHARE_REPORT_URL' as const;

export const createShareReportUrl = () => ({
	type: CREATE_SHARE_REPORT_URL,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getViewConfig(shareAs: ShareAs): Generator<Effect, any, any> {
	switch (shareAs) {
		case SHARE_AS.DEPENDENCY_REPORT_CONFLUENCE:
			return { filtersV1: yield select(getDependencyFilters) };
		default:
			return yield select(getViewSettings);
	}
}

function getViewType(shareAs: ShareAs) {
	switch (shareAs) {
		case SHARE_AS.DEPENDENCY_REPORT_CONFLUENCE:
			return DEPENDENCY_REPORT_SHARE_TYPE;
		default:
			return TIMELINE_SHARE_TYPE;
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doCreateShareReportUrl(): Generator<Effect, any, any> {
	const {
		domain: {
			plan: { id, currentScenarioId },
		},
	}: State = yield select(R.identity);

	const shareAs: ShareAs = yield select(getShareAsState);
	const viewConfig = yield call(getViewConfig, shareAs);

	if (!id) {
		throw new Error('No plan to share');
	}
	try {
		yield put(setLoadingShareReport(true));
		const url = urls.share;
		const body = {
			entityId: id,
			entityType: 'PLAN',
			parameters: {
				// Add your configurations here!
				viewConfig,
				scenarioId: currentScenarioId,
				version: 3,
				view: getViewType(shareAs),
			},
		};
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});
		if (response.ok) {
			const json = yield call(response.json.bind(response));
			const location = yield call(getLocation);
			const domain = location.origin;
			const hash = json.hash;
			// eslint-disable-next-line @typescript-eslint/no-shadow
			let url = `${domain}/jira/plans/reports/${hash}`;
			if (shareAs === SHARE_AS.EMBED) {
				url = `${domain}/secure/PlanEmbeddedReport.jspa?r=${hash}`;
			}

			if (shareAs === SHARE_AS.CONFLUENCE || shareAs === SHARE_AS.DEPENDENCY_REPORT_CONFLUENCE) {
				url = `${domain}/secure/PortfolioRoadmapConfluence.jspa?r=${hash}`;
			}

			yield put(updateShareReportUrl(url));
		} else {
			// eslint-disable-next-line no-lonely-if
			if (fg('improve_redux_saga_error_reporting_plans_batch_3')) {
				const error = new Error(response.text());
				fireErrorAnalytics({
					error,
					meta: {
						id: toErrorID(error, 'do-create-share-report-fetch-failed'),
						packageName: PACKAGE_NAME,
						teamName: ERROR_REPORTING_TEAM,
					},
					sendToPrivacyUnsafeSplunk: true,
				});
			} else {
				yield put(
					deprecatedGenericError({
						...parseError(response, yield call(response.text.bind(response))),
						requestInfo: {
							url,
							type: POST,
							status: response.status,
							body,
						},
					}),
				);
			}
		}
	} finally {
		yield put(setLoadingShareReport(false));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchCreateShareReportUrl(): Generator<Effect, any, any> {
	yield takeEvery(CREATE_SHARE_REPORT_URL, doCreateShareReportUrl);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchCreateShareReportUrl);
}
