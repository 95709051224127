import { changeGroup } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/visualisations/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { Grouping } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	changeGroup: (group: Grouping) => {
		dispatch(
			changeGroup({
				grouping: group,
			}),
		);
	},
});

export default mapDispatchToProps;
