/* NOTE This style is aimed to match Select styles, please keep it in sync during Select upgrades */
.wireframe {
	pointer-events: none;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	max-width: 100%;
	min-height: 40px;

	& > div {
		text-align: start;
		padding: 10px;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
.readOnly {
	color: var(--ds-text-disabled, var(--adg3-color-N70));
}
