import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import { getErrors, getErrorLogDialogState } from '../../query/errors/index.tsx';
import type { State } from '../../state/types.tsx';
import {
	hideError,
	hideAllErrors,
	showErrorLogDialog,
	hideErrorLogDialog,
} from '../../state/ui/errors/actions.tsx';
import type { Errors } from '../../state/ui/errors/types.tsx';
import type { DispatchProps, OwnProps, StateProps } from './types.tsx';
import View from './view.tsx';

const mapStateToProps = createSelector<State, Errors, boolean, StateProps>(
	[getErrors, getErrorLogDialogState],
	(errors, isErrorLogDialogOpen) => ({
		errors,
		isErrorLogDialogOpen,
	}),
);

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	hideError: () => {
		dispatch(hideError());
	},
	hideAllErrors: () => {
		dispatch(hideAllErrors());
	},
	showErrorLogDialog: () => {
		dispatch(showErrorLogDialog());
	},
	hideErrorLogDialog: () => {
		dispatch(hideErrorLogDialog());
	},
});

const ErrorNotification = connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToProps,
	mapDispatchToProps,
)(View);

export default ErrorNotification;
