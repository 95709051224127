.unit {
	display: inline-block;
	box-sizing: border-box;
	height: 100%;
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
}

.innerContent {
	margin: 10px 5px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
