export const CREATE_RELEASE =
	'state.ui.main.tabs.releases.project-releases.release-dialog.CREATE_RELEASE' as const;
export const EDIT_RELEASE =
	'state.ui.main.tabs.releases.project-releases.release-dialog.EDIT_RELEASE' as const;

export type Mode = typeof CREATE_RELEASE | typeof EDIT_RELEASE;

export type ReleaseDialog = Readonly<{
	mode: Mode;
	isDialogOpen: boolean;
	projectId: number;
	versionId?: string;
	isProcessingRequest: boolean;
}>;
