import React, { Component, type ChangeEvent, useRef, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import CrossCircleIconOld from '@atlaskit/icon/glyph/cross-circle';
import CrossCircleIcon from '@atlaskit/icon/utility/cross-circle';
import { Box } from '@atlaskit/primitives';
import AkTextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useIntl, injectIntl } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import { IconButton } from '@atlassian/jira-portfolio-3-common/src/icon/index.tsx';
import { DialogMenuItem } from '@atlassian/jira-portfolio-3-common/src/inline-dialog/dialog-menu/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, PropsOld } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class SearchFieldOld extends Component<PropsOld> {
	onChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.props.onQueryChange(e.target.value);
	};

	onClear = () => {
		this.props.onQueryChange('');
	};

	render() {
		const { placeholder, searchQuery, autoFocus = true, ariaLabel, intl } = this.props;

		const hasInput = searchQuery.length > 0;
		const styleName = classNames({ [styles.hasInput]: hasInput });

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<DialogMenuItem style={{ position: 'relative' }} className={styleName}>
				<AkTextField
					autoFocus={autoFocus}
					onChange={this.onChange}
					placeholder={placeholder}
					value={searchQuery}
					aria-label={ariaLabel}
				/>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.clearSearchField} style={{ display: hasInput ? 'block' : 'none' }}>
					<IconButton
						onClick={this.onClear}
						tabIndex={-1}
						ariaLabel={intl.formatMessage(messages.clearButtonLabel)}
					>
						<CrossCircleIconOld
							label=""
							size="small"
							primaryColor={token('color.icon.subtle', colors.N40)}
						/>
					</IconButton>
				</div>
			</DialogMenuItem>
		);
	}
}

const SearchField = (props: Props) => {
	const intl = useIntl();
	const inputRef = useRef(null);
	const {
		onQueryChange,
		placeholder,
		searchQuery,
		autoFocus = true,
		ariaLabel,
		setInitialFocusRef,
	} = props;

	useEffect(() => {
		if (setInitialFocusRef) {
			setInitialFocusRef(inputRef.current);
		}
	}, [setInitialFocusRef]);

	const onChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			onQueryChange(e.target.value);
		},
		[onQueryChange],
	);

	const onClear = useCallback(() => {
		onQueryChange('');
	}, [onQueryChange]);

	const hasInput = searchQuery.length > 0;

	return (
		<Box paddingInline="space.200" paddingBlock="space.100">
			<AkTextField
				autoFocus={autoFocus}
				onChange={onChange}
				placeholder={placeholder}
				value={searchQuery}
				aria-label={ariaLabel}
				ref={inputRef}
				elemAfterInput={
					hasInput ? (
						<Box padding="space.100">
							<IconButton
								onClick={onClear}
								tabIndex={-1}
								ariaLabel={intl.formatMessage(messages.clearButtonLabel)}
							>
								<CrossCircleIcon color={token('color.icon.subtle', colors.N40)} label="" />
							</IconButton>
						</Box>
					) : null
				}
			/>
		</Box>
	);
};

export default componentWithFG(
	'migrate_plan_filter_to_popup',
	SearchField,
	injectIntl(SearchFieldOld),
);
