export const RESET_SEARCH_RESULTS =
	'state.ui.main.tabs.roadmap.scope.header.search.RESET_SEARCH_RESULTS' as const;
export const SEARCH_ICON_CLICK =
	'state.ui.main.tabs.roadmap.scope.header.search.SEARCH_ICON_CLICK' as const;
export const SET_ACTIVE_SEARCH_RESULT_INDEX =
	'state.ui.main.tabs.roadmap.scope.header.search.SET_ACTIVE_SEARCH_RESULT_INDEX' as const;
export const SET_SEARCH_QUERY =
	'state.ui.main.tabs.roadmap.scope.header.search.SET_SEARCH_QUERY' as const;
export const STARTED_SEARCH_RESULT_NAVIGATION =
	'state.ui.main.tabs.roadmap.scope.header.search.STARTED_SEARCH_RESULT_NAVIGATION' as const;
export const TOGGLE_ISSUE_SEARCH =
	'state.ui.main.tabs.roadmap.scope.header.search.TOGGLE_ISSUE_SEARCH' as const;

export type SetSearchQuery = {
	type: typeof SET_SEARCH_QUERY;
	payload: string;
};

export type SetActiveSearchResultIndex = {
	type: typeof SET_ACTIVE_SEARCH_RESULT_INDEX;
	payload: number;
};

export type ResetSearchResults = {
	type: typeof RESET_SEARCH_RESULTS;
};

export type SearchIconClick = {
	type: typeof SEARCH_ICON_CLICK;
};

export type StartedSearchResultNavigation = {
	type: typeof STARTED_SEARCH_RESULT_NAVIGATION;
};

export type ToggleIssueSearch = {
	type: typeof TOGGLE_ISSUE_SEARCH;
	payload: boolean;
};

export const setSearchQuery = (payload: string) => ({
	type: SET_SEARCH_QUERY,
	payload,
});

export const setActiveSearchResultIndex = (payload: number) => ({
	type: SET_ACTIVE_SEARCH_RESULT_INDEX,
	payload,
});

export const resetSearchResults = () => ({
	type: RESET_SEARCH_RESULTS,
});

export const searchIconClick = () => ({
	type: SEARCH_ICON_CLICK,
});

export const startedSearchResultNavigation = () => ({
	type: STARTED_SEARCH_RESULT_NAVIGATION,
});

export const toggleIssueSearch = (payload: boolean) => ({
	type: TOGGLE_ISSUE_SEARCH,
	payload,
});
