import * as R from 'ramda';
import type { IssueLink } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	ADD,
	type AddAction,
	RESET,
	type ResetAction,
	REMOVE,
	type RemoveAction,
} from './actions.tsx';
import type { IssueLinksState } from './types.tsx';

const initialState: IssueLinksState = {
	values: {},
	originals: {},
};

type Action = AddAction | ResetAction | RemoveAction;

const updateLink = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	f: any,
	{ sourceItemKey, targetItemKey }: Pick<IssueLink, 'sourceItemKey' | 'targetItemKey'>,
	state: IssueLinksState,
): IssueLinksState => R.evolve({ values: { [sourceItemKey]: f, [targetItemKey]: f } }, state);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: IssueLinksState = initialState, action: Action) => {
	const { values } = state;
	switch (action.type) {
		case ADD: {
			const { pendingKey, ...link } = action.payload;
			const { itemKey, sourceItemKey, targetItemKey } = link;
			// R.evolve short-circuits on missing keys thus we ensure that key is present
			// this is harmless mutation as we return a new state object anyway
			if (!values[sourceItemKey]) {
				values[sourceItemKey] = {};
			}
			if (!values[targetItemKey]) {
				values[targetItemKey] = {};
			}

			const optimisticState = updateLink(R.assoc(itemKey, link), link, state);

			if (isDefined(pendingKey)) {
				// remove optimistic update issue links
				return updateLink(R.dissoc(pendingKey), link, optimisticState);
			}

			return optimisticState;
		}
		case RESET:
			return action.payload;
		case REMOVE: {
			const link = action.payload;
			return updateLink(R.dissoc(link.itemKey), link, state);
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
