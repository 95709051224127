export const getFlyoutPosition = (
	timelineWidth: number,
	leftPositionPercentage: number,
	flyoutWidth: number,
): string => {
	if (
		timelineWidth &&
		timelineWidth - (timelineWidth * leftPositionPercentage) / 100 <= flyoutWidth
	) {
		return 'bottom-end';
	}
	return 'bottom-start';
};
