const environmentErrorMessagesRegexes = [
	'Failed to fetch$',
	'Failed to fetch page data for plan',
	"Cannot read property 'claim' of undefined$",
	'NetworkError when attempting to fetch resource.$',
	'cancelled$',
	'^Not enough storage is available to complete this operation.$',
	'The operation was aborted.$',
	"^undefined is not an object \\(evaluating 'window.WRM.data.claim'\\)$",
	'The request timed out.$',
	'The network connection was lost.$',
];
const environmentErrorMessagesRegex = new RegExp(environmentErrorMessagesRegexes.join('|'));
export const isEnvironmentError = (error: { message: string }) =>
	error.message.match(environmentErrorMessagesRegex);

export const RESPONSE_ERROR_CODE = {
	REQUIRE_STATUS_TRANSITION_SCREEN: 'REQUIRE_STATUS_TRANSITION_SCREEN',
};
