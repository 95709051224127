import groupBy from 'lodash/groupBy';
import { useIntl } from '@atlassian/jira-intl';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import { type Option, GROUPED, type OptionGroup } from '../types.tsx';
import { optionGroupSortComparator, optionSortComparator } from '../utils.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const IssueTypeOptionsProvider = ({
	issueTypesByProjectId,
	hierarchyRangeFilter,
	projectId,
	children,
}: Props) => {
	const { formatMessage } = useIntl();

	const isLevelWithinHierarchyRangeFilter = (level: number): boolean =>
		level >= hierarchyRangeFilter.value.end && level <= hierarchyRangeFilter.value.start;

	const createOptionFromIssueType = (issueType: IssueType): Option<IssueType> => ({
		id: `issue-type-${issueType.id}`,
		name: issueType.name,
		value: issueType,
		url: issueType.iconUrl,
		urlType: 'icon',
	});

	const getOptionsFromIssueTypes = (issueTypes: IssueType[]): Option<IssueType>[] =>
		issueTypes.map(createOptionFromIssueType).sort(optionSortComparator);

	const getOptionGroupsFromIssueTypes = (): OptionGroup<IssueType>[] => {
		const issueTypes = issueTypesByProjectId[projectId];

		const issueTypesByLevel = groupBy(issueTypes, (issueType) => issueType.level);

		return Object.entries(issueTypesByLevel)
			.filter(([hierarchyLevel]) => isLevelWithinHierarchyRangeFilter(parseInt(hierarchyLevel, 10)))
			.map(([hierarchyLevel, hierarchyIssueTypes]) => ({
				id: `issue-type-group-${hierarchyLevel}`,
				sortValue: parseInt(hierarchyLevel, 10),
				options: getOptionsFromIssueTypes(hierarchyIssueTypes),
			}))
			.sort(optionGroupSortComparator);
	};

	return children({
		options: {
			type: GROUPED,
			groups: getOptionGroupsFromIssueTypes(),
		},
		menuTitle: formatMessage(messages.issueTypeMenuTitle),
		searchPlaceholder: formatMessage(messages.issueTypeSearchPlaceholder),
	});
};

export default IssueTypeOptionsProvider;
