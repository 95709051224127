.dateTooltipContent {
	display: flex;
	justify-content: space-between;
	pointer-events: none;
}

.dateTooltipTrigger {
	position: absolute;
	top: var(--space-100, 8px);
	height: var(--space-300, 24px);
	display: flex;
	justify-content: space-between;
	visibility: hidden;
}

.dateTooltip {
	position: absolute;
	top: 32px;
	display: flex;
	justify-content: space-between;
	z-index: var(--jpo-z-index-date-tooltip);
}
