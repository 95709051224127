import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { getIssueLabels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-labels/index.tsx';
import { getLabelsGroupViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import type { IssueLabels } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-labels/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export const getSelectOptionsPure = (issueLabels: IssueLabels): OptionType[] =>
	issueLabels.map((label) => ({
		value: label,
		label,
	}));

const getSelectOptions = createSelector([getIssueLabels], getSelectOptionsPure);

export default createStructuredSelector<State, OwnProps, StateProps>({
	labelGroups: getLabelsGroupViewSettings,
	selectOptions: getSelectOptions,
	groupAttribute: () => 'labels',
});
