import React, { useState } from 'react';
import InformationIcon from '@atlaskit/icon/utility/migration/information--editor-panel';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import colors from '@atlassian/jira-portfolio-3-common/src/colors/index.tsx';
import { AkSelect } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	FormatOptionLabelMeta,
	OptionType,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

function ViewSettingSelect({
	classNamePrefix,
	customFieldsOptions,
	handleOnChange,
	isDefaultMenuOpen = false, // always false in PROD, potentially true in StoryBook
	isDisabled = false,
	onMenuToggle,
	selectedFieldId,
	standardFieldsOptions,
	label,
}: Props) {
	const { formatMessage } = useIntl();
	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(isDefaultMenuOpen);

	const groupedOptions = [
		{
			label: '',
			options: standardFieldsOptions,
		},
	];

	if (customFieldsOptions.length > 0) {
		const sortedCustomFieldsOptions = customFieldsOptions.sort((a, b) =>
			a.label.localeCompare(b.label),
		);
		groupedOptions.push({
			label: formatMessage(commonMessages.customFields),
			options: sortedCustomFieldsOptions,
		});
	}

	const selectedValue = [...standardFieldsOptions, ...customFieldsOptions].find(
		({ id }) => id === selectedFieldId,
	);

	const formatOptionLabel = (
		// eslint-disable-next-line @typescript-eslint/no-shadow
		{ content, isDisabled, tooltipContent }: OptionType,
		{ context }: FormatOptionLabelMeta,
	) => {
		if (context === 'menu') {
			if (isDisabled) {
				return (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.option}>
						<span>{content}</span>
						<span>
							<Tooltip content={formatMessage(tooltipContent)} position="top">
								<InformationIcon
									color="currentColor"
									label={formatMessage(tooltipContent)}
									LEGACY_primaryColor={token('color.blanket', colors.N500)}
									LEGACY_size="small"
								/>
							</Tooltip>
						</span>
					</div>
				);
			}
			return content;
		}
		return content;
	};

	const tooltipContent = () => {
		if (isDisabled) {
			return formatMessage(messages.optionDisabledInListView);
		}

		if (!isMenuOpen) {
			return selectedValue?.label;
		}

		return null;
	};

	return (
		<Tooltip content={tooltipContent()}>
			<AkSelect
				blurInputOnSelect
				classNamePrefix={classNamePrefix}
				closeMenuOnSelect
				formatOptionLabel={formatOptionLabel}
				isDisabled={isDisabled}
				menuIsOpen={isMenuOpen}
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				menuPortalTarget={window.STORYBOOK_ENV ? undefined : getBody()}
				onChange={handleOnChange}
				onMenuClose={() => {
					setIsMenuOpen(false);
					onMenuToggle(false);
				}}
				onMenuOpen={() => {
					setIsMenuOpen(true);
					onMenuToggle(true);
				}}
				options={groupedOptions}
				styles={{
					menuPortal: (base) => ({
						...base,
						zIndex: 999,
					}),
				}}
				value={selectedValue}
				aria-label={formatMessage(messages.selectAriaLabel, {
					label,
					selectedValue: selectedValue?.label,
				})}
			/>
		</Tooltip>
	);
}

export default ViewSettingSelect;
