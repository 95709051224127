import { type MessageDescriptor, defineMessages } from '@atlassian/jira-intl';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default defineMessages({
	colorBy: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.color-by-legend.color-by',
		defaultMessage: 'Color by ({colorByValueTitle})',
		description: 'Label for color by legend section',
	},
}) as {
	colorBy: MessageDescriptor;
};
