import React, { useState, type ChangeEvent, type KeyboardEvent, type MouseEvent } from 'react';
import * as R from 'ramda';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { ErrorMessage, Field } from '@atlaskit/form';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { xcss, Box } from '@atlaskit/primitives';
import { RadioGroup } from '@atlaskit/radio';
import { token } from '@atlaskit/tokens';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import {
	DefaultDateFormat,
	IsoDateFormat,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/constants.tsx';
import {
	formatDateUTC,
	useDateFormatter,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import {
	endOfUtcDay,
	startOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import DatePicker from '@atlassian/jira-portfolio-3-common/src/date-picker/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	DUE_DATE,
	TARGET_END_FIELD,
	TARGET_START_FIELD,
	DATEFIELD_TYPES,
	type DateField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import InfoMessage from './info-message/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import {
	CLEAR_DATE,
	END_DATE,
	KEEP_EXISTING,
	SET_BY_CHILD_DATES,
	SET_CUSTOM_DATE,
	START_DATE,
	type Props,
	UPDATE_DATE_OPTIONS,
} from './types.tsx';
import { getDateForValidation, getRollUpDatesForSelectedIssues } from './utils.tsx';

export default function SetDateDialog({
	bulkActionSuccess,
	bulkUpdateAttribute,
	childrenByParent,
	customFieldsById,
	dateConfiguration,
	issueTypesById,
	projectsById,
	selectedIssues,
	toggleModalDialog,
	updateMultipleIssues,
}: Props) {
	const [startDateMethod, setStartDateMethod] = useState<string>(KEEP_EXISTING);
	const [endDateMethod, setEndDateMethod] = useState<string>(KEEP_EXISTING);

	const [fixedStartDate, setFixedStartDate] = useState<number | undefined>(undefined);
	const [fixedEndDate, setFixedEndDate] = useState<number | undefined>(undefined);

	const { formatMessage } = useIntl();
	const { formatTimestamp } = useDateFormatter();

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const getPatchForSelectedIssues = (): any =>
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[START_DATE, END_DATE].reduce<Record<string, any>>((result, type) => {
			const attribute: string = type === START_DATE ? 'baselineStart' : 'baselineEnd';

			const updateDateMethod = type === START_DATE ? startDateMethod : endDateMethod;
			switch (updateDateMethod) {
				case CLEAR_DATE:
					return Object.assign(result, {
						[attribute]: null,
					});
				case SET_CUSTOM_DATE:
					return Object.assign(result, {
						[attribute]: type === START_DATE ? fixedStartDate : fixedEndDate,
					});
				default:
					return result;
			}
		}, {});

	const onSubmit = (_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
		let modifiedIssues: Issue[] = selectedIssues;

		/**
		 * This part handles cases such as CLEAR_DATE and SET_CUSTOM_DATE.
		 */
		const payload = getPatchForSelectedIssues();
		if (Object.keys(payload).length > 0) {
			bulkUpdateAttribute(payload);
			modifiedIssues = selectedIssues.map((issue) => R.merge(issue, payload));
		}

		/**
		 * This part handles SET_BY_CHILD_DATES case.
		 */
		const rollUpDatesForSelectedIssues = getRollUpDatesForSelectedIssues(
			modifiedIssues,
			childrenByParent,
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow
		const shouldPickTheDate = (_: any, key: any) =>
			(key === 'baselineStart' && startDateMethod === SET_BY_CHILD_DATES) ||
			(key === 'baselineEnd' && endDateMethod === SET_BY_CHILD_DATES);

		updateMultipleIssues(R.map(R.pickBy(shouldPickTheDate), rollUpDatesForSelectedIssues));
		toggleModalDialog();
		bulkActionSuccess(analyticsEvent);
	};

	const getCurrentValue = (type: string): string => {
		const attribute = type === START_DATE ? 'baselineStart' : 'baselineEnd';
		const firstValue = selectedIssues[0][attribute];
		const isSameValue = selectedIssues.every((issue) => R.equals(issue[attribute], firstValue));

		if (isSameValue && !firstValue) {
			return formatMessage(commonMessages.none);
		}

		if (isSameValue && firstValue) {
			return formatDateUTC(firstValue, DefaultDateFormat);
		}

		return formatMessage(messages.variousValues);
	};

	const getDateFieldTitle = (type: string) => {
		const dateField: DateField =
			type === START_DATE
				? dateConfiguration.baselineStartField
				: dateConfiguration.baselineEndField;

		// if the date is a custom field, we get its title
		if (dateField.type === DATEFIELD_TYPES.CUSTOM) {
			const customField = customFieldsById[dateField.key];
			if (customField) {
				return customField.title;
			}
			return '';
		}

		// if the date is a built-in field, we get its translated message
		switch (dateField.key) {
			case DUE_DATE:
				return formatMessage(commonMessages.dueDate);
			case TARGET_START_FIELD:
				return formatMessage(commonMessages.targetStart);
			case TARGET_END_FIELD:
				return formatMessage(commonMessages.targetEnd);
			default:
				return '';
		}
	};

	const updateDateOptions = UPDATE_DATE_OPTIONS.map((option) => ({
		isDisabled: false,
		label: formatMessage(messages[option]),
		name: option,
		value: option,
	}));

	const formatDatePickerValue = (date: number | undefined) => {
		if (date) {
			return formatDateUTC(date, IsoDateFormat);
		}
		return undefined;
	};

	const lastEndDate = getDateForValidation(selectedIssues, true);

	const isInvalidDateStartBeforeEnd =
		(endDateMethod !== SET_CUSTOM_DATE &&
			isDefined(fixedStartDate) &&
			isDefined(lastEndDate) &&
			fixedStartDate > lastEndDate) ||
		(endDateMethod === SET_CUSTOM_DATE &&
			isDefined(fixedStartDate) &&
			isDefined(fixedEndDate) &&
			fixedStartDate > fixedEndDate);

	const earliestStartDate = getDateForValidation(selectedIssues, false);
	const isInvalidDateEndAfterStart =
		(startDateMethod !== SET_CUSTOM_DATE &&
			isDefined(earliestStartDate) &&
			isDefined(fixedEndDate) &&
			fixedEndDate < earliestStartDate) ||
		(startDateMethod === SET_CUSTOM_DATE &&
			isDefined(fixedStartDate) &&
			isDefined(fixedEndDate) &&
			fixedEndDate < fixedStartDate);

	const renderCustomDateFieldErrorMessage = (dateType: typeof START_DATE | typeof END_DATE) => {
		if (dateType === START_DATE && isInvalidDateStartBeforeEnd) {
			return <ErrorMessage>{formatMessage(commonMessages.invalidDateStartBeforeEnd)}</ErrorMessage>;
		}

		if (dateType === END_DATE && isInvalidDateEndAfterStart) {
			return <ErrorMessage>{formatMessage(commonMessages.invalidDateEndAfterStart)}</ErrorMessage>;
		}

		return null;
	};

	const renderDateSection = (dateType: typeof START_DATE | typeof END_DATE) => {
		const dateFieldTitles = {
			baselineStartField: getDateFieldTitle(START_DATE),
			baselineEndField: getDateFieldTitle(END_DATE),
		};
		const currentDatesValues = {
			baselineStartField: getCurrentValue(START_DATE),
			baselineEndField: getCurrentValue(END_DATE),
		};

		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={dateType === START_DATE ? styles.startDate : styles.endDate} key={dateType}>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.dateTitle}
					data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.date-title-${dateType}`}
				>
					{dateFieldTitles[dateType]}
				</div>

				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.values}>
					{/* Current value(s) container */}
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.currentValue}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.valueTitle}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.title}>{formatMessage(commonMessages.current)}</div>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.arrow}>
								{isVisualRefreshEnabled() ? (
									<Box xcss={iconWrapperStyle}>
										<ArrowRightIcon label="" color={token('color.icon')} />
									</Box>
								) : (
									<ArrowRightIcon label="" color={token('color.icon')} />
								)}
							</div>
						</div>
						<div
							data-testid={`portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.current-value-${dateType}`}
						>
							{currentDatesValues[dateType]}
						</div>
					</div>

					{/* New value container */}
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.newValue}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.valueTitle}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles.title}>{formatMessage(commonMessages.new)}</div>
						</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.newValueForm}>
							<Field name={`${dateType}Method`}>
								{({ fieldProps }) => (
									<RadioGroup
										{...fieldProps}
										defaultValue={KEEP_EXISTING}
										onChange={(evt: ChangeEvent<HTMLInputElement>) => {
											if (dateType === START_DATE) {
												setStartDateMethod(evt.target.value);
											} else {
												setEndDateMethod(evt.target.value);
											}
										}}
										options={updateDateOptions}
										value={dateType === START_DATE ? startDateMethod : endDateMethod}
									/>
								)}
							</Field>

							{((dateType === START_DATE && startDateMethod === SET_CUSTOM_DATE) ||
								(dateType === END_DATE && endDateMethod === SET_CUSTOM_DATE)) && (
								<>
									<Field name={`fixed${dateType}`}>
										{({ fieldProps }) => (
											<DatePicker
												{...fieldProps}
												isInvalid={
													dateType === START_DATE
														? isInvalidDateStartBeforeEnd
														: isInvalidDateEndAfterStart
												}
												isTransparentBackground={false}
												onChange={(date) => {
													if (dateType === START_DATE) {
														setFixedStartDate(date ? startOfUtcDay(date) : undefined);
													} else {
														setFixedEndDate(date ? endOfUtcDay(date) : undefined);
													}
												}}
												placeholder={formatMessage(messages.datePlaceholder, {
													date: formatTimestamp(Date.now()),
												})}
												showClearIndicator
												value={formatDatePickerValue(
													dateType === START_DATE ? fixedStartDate : fixedEndDate,
												)}
											/>
										)}
									</Field>
									{renderCustomDateFieldErrorMessage(dateType)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	};

	// when the user selects the "Set by child issue dates" option for the new start or end date, some magic will happen behind the scene, resulting
	// in potential changes to the dates of the parent issues, therefore we display an information message to prevent users to enter into PANIC mode.
	const renderInfoMessage = () => {
		if (startDateMethod === SET_BY_CHILD_DATES || endDateMethod === SET_BY_CHILD_DATES) {
			let modifiedIssues: Issue[] = selectedIssues;
			const payload = getPatchForSelectedIssues();
			if (Object.keys(payload).length > 0) {
				modifiedIssues = selectedIssues.map((issue) => R.merge(issue, payload));
			}

			const rollUpDatesForSelectedIssues = getRollUpDatesForSelectedIssues(
				modifiedIssues,
				childrenByParent,
			);

			const issuesWithoutDates = selectedIssues.filter(
				({ id }) =>
					isDefined(rollUpDatesForSelectedIssues[id]) &&
					((startDateMethod === SET_BY_CHILD_DATES &&
						!isDefined(rollUpDatesForSelectedIssues[id].baselineStart)) ||
						(endDateMethod === SET_BY_CHILD_DATES &&
							!isDefined(rollUpDatesForSelectedIssues[id].baselineEnd))),
			);

			return (
				<InfoMessage
					issuesWithoutDates={issuesWithoutDates}
					issueTypesById={issueTypesById}
					projectsById={projectsById}
					isDefaultFlyoutOpen={false}
				/>
			);
		}

		return null;
	};

	const isFormInValid = () => {
		if (startDateMethod === KEEP_EXISTING && endDateMethod === KEEP_EXISTING) {
			return true;
		}

		if (
			startDateMethod === SET_CUSTOM_DATE &&
			(!isDefined(fixedStartDate) || isInvalidDateStartBeforeEnd)
		) {
			return true;
		}

		if (
			endDateMethod === SET_CUSTOM_DATE &&
			(!isDefined(fixedEndDate) || isInvalidDateEndAfterStart)
		) {
			return true;
		}

		return false;
	};

	return (
		<ShortcutScope>
			<ModalDialog
				autoFocus={false}
				onClose={toggleModalDialog}
				shouldScrollInViewport
				width="large"
				testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-date.modal-dialog"
			>
				<ModalHeader>
					<ModalTitle>{formatMessage(messages.header)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.content}>
						{[START_DATE, END_DATE].map((type) => renderDateSection(type))}
					</div>
					{renderInfoMessage()}
				</ModalBody>
				<ModalFooter>
					<Button appearance="subtle" onClick={toggleModalDialog}>
						{formatMessage(commonMessages.cancel)}
					</Button>
					<Button appearance="primary" isDisabled={isFormInValid()} onClick={onSubmit}>
						{formatMessage(commonMessages.apply)}
					</Button>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
}

const iconWrapperStyle = xcss({
	paddingTop: 'space.075',
	paddingBottom: 'space.050',
});
