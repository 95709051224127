import React, { useMemo, type RefObject } from 'react';
import { monitorForDependencyCreate } from '@atlassian/jira-portfolio-3-dependency-line-drag-create/src/ui/index.tsx';
import { useColumnWidths } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/index.tsx';
import { DragAutoScroll, type MonitorCallback } from '../drag-auto-scroll/index.tsx';
import { monitorForIssueBarUpdate } from './utils.tsx';

type Props = {
	containerRef: RefObject<HTMLElement | null>;
};

export const TimelineDragAutoScroll = ({ containerRef }: Props) => {
	const [columnWidths] = useColumnWidths({ preview: false });

	// This component is used in the timeline view. Since the timeline only occupies a small portion of the viewport,
	// we need to offset the scroll hit box by the width of all columns except the last one.
	const marginLeft = columnWidths.reduce(
		(totalWidth, curWidth, index) =>
			index < columnWidths.length - 1 ? totalWidth + curWidth : totalWidth,
		0,
	);

	const monitors = useMemo<MonitorCallback[]>(
		() => [
			({ start, update, end }) =>
				monitorForIssueBarUpdate({
					onDragStart: start,
					onDragEnd: end,
					onDrag: update,
				}),
			({ start, update, end }) =>
				monitorForDependencyCreate({
					onDragStart: start,
					onDropTargetChange: ({ location }) => update(location.current.input),
					onDrag: ({ location }) => update(location.current.input),
					onDrop: end,
				}),
		],
		[],
	);

	return (
		<DragAutoScroll
			allowedAxis="both"
			containerRef={containerRef}
			insetHitbox={{ top: 90, left: marginLeft }}
			monitors={monitors}
		/>
	);
};
