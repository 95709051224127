import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	disabledScopedFieldTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.custom-field.renderers.disabled-custom-field.disabled-scoped-field-tooltip',
		defaultMessage:
			'You can only apply the {customFieldName} custom field from project {projectName} to issue types: {issueTypeNames} in that project.',
		description: 'The tooltip shows up when the user hovers on a field that can not be edited.',
	},
	disabledScopedFieldNa: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.custom-field.renderers.disabled-custom-field.disabled-scoped-field-na',
		defaultMessage: 'N/A',
		description:
			'This message indicates a non-editable field - N/A means the user can not edit it.',
	},
});
