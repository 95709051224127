import React from 'react';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const TriggerButton = ({ isOpen, onOpenChange, triggerButtonText }: Props) => {
	const intl = useIntl();
	const handleOpenChange = () => {
		onOpenChange({ isOpen: !isOpen });
	};

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.filterButtonWrapper}>
			<Button
				iconAfter={<ChevronDownIcon label="" />}
				isSelected={isOpen}
				onClick={handleOpenChange}
				testId="portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.filters.user-filter.trigger-button"
			>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.buttonTextContainer}
					aria-live="polite"
					aria-atomic="true"
				>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.buttonLabel}>{intl.formatMessage(messages.filterByLabel)}:</div>
					{triggerButtonText}
				</div>
			</Button>
		</div>
	);
};

export default TriggerButton;
