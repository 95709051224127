import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	changedByMeLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.quick-selectors.changed-by-me-label',
		defaultMessage: 'Changed by me',
		description:
			'The label for the button that is used to toggle changes made by the user in Plans',
	},
	changedTodayLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.quick-selectors.changed-today-label',
		defaultMessage: 'Changed today',
		description: 'The label for the button that is used to toggle items were updated today',
	},
	selectedOnTimelineLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.quick-selectors.selected-on-timeline-label',
		defaultMessage: 'Selected on timeline',
		description:
			'The tooltip for the button that is used to toggle changes that were selected on the timeline',
	},
});

export default messages;
