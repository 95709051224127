import sum from 'lodash/fp/sum';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import {
	ADD_FIELDS,
	FIELD,
	EMPTY,
	SECTION_COLLAPSE_THRESHOLD,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/constants.tsx';
import type { GridColumn } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/types.tsx';
import type { State } from './types.tsx';

type UseColspanParams = {
	row: 'header' | 'subheader' | 'body' | 'group';
	column: number;
};

export const selectColspan = (
	{ viewMode, columns, columnWidths }: State,
	{ row, column }: UseColspanParams,
) => {
	const numOfFields =
		columns.length -
		2; /* First column is always SCOPE, last column is always ADD_FIELDS or TIMELINE */

	if (row === 'header') {
		const isFieldColumn = ({ tag }: GridColumn) =>
			tag === FIELD || tag === ADD_FIELDS || tag === EMPTY;

		if (column === 1) {
			if (viewMode === VIEW_MODES.TIMELINE) {
				return numOfFields;
			}
			return numOfFields + 1 /* The ADD_FIELDS column */;
		}

		return isFieldColumn(columns[column]) ? 0 : 1;
	}

	if (viewMode === VIEW_MODES.TIMELINE) {
		if (columnWidths[column] === 0) {
			return 0;
		}

		const fieldsTotalWidth = sum(columnWidths.slice(1, columnWidths.length - 1));
		const fieldsCollapsed = fieldsTotalWidth <= SECTION_COLLAPSE_THRESHOLD;

		if (fieldsCollapsed) {
			if (column === 1) {
				return numOfFields;
			}

			if (column > 1 && column < 1 /* SCOPE */ + numOfFields) {
				return 0;
			}
		} else {
			let numberOfNextZeroColumns = 0;
			while (columnWidths[column + numberOfNextZeroColumns + 1] === 0) {
				numberOfNextZeroColumns++;
			}

			return numberOfNextZeroColumns + 1;
		}
	}

	return 1;
};
