import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	multipleChanges: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.value-change.multiple-changes',
		defaultMessage: 'Multiple',
		description: 'The label indicating that multiple attribute values are included in a change',
	},
	weeks: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.value-change.weeks',
		defaultMessage: 'Weeks',
		description: 'The label to show next to iteration length',
	},
});
