import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import UFOCustomData from '@atlaskit/react-ufo/custom-data';
import { Client, Provider as SmartCardProvider } from '@atlaskit/smart-card';
import { ViewExperienceTrackingProvider } from '@atlassian/jira-common-experience-tracking-viewing/src/view/experience-tracking-provider/index.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { VIEW_MODES } from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { useNativeScrollbarHeight } from '@atlassian/jira-portfolio-3-common/src/custom-scrollbar/utils.tsx';
import { isPollinatorTenant } from '@atlassian/jira-portfolio-3-common/src/feature-flags/index.tsx';
import HorizontalScrollBarOverlay from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/ui/scroll-bar-overlay/index.tsx';
import { useCttSpotlight } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-onboarding/index.tsx';
import { CttSpotlights } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-spotlights/index.tsx';
import {
	Spotlights,
	useCurrentSpotlight as useCurrentSpotlightNew,
	useCurrentSpotlightOld,
} from '@atlassian/jira-portfolio-3-onboarding/src/controllers/spotlights/index.tsx';
import { SpotlightTarget } from '@atlassian/jira-portfolio-3-onboarding/src/ui/spotlight-target/index.tsx';
import { EXPORT } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import ViewExperienceFailureTracker from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/failure-tracker/index.tsx';
import {
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
	PACKAGE_NAME,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { ZIndexProvider } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/index.tsx';
import {
	ContextualAnalyticsData,
	MountEvent,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { JIRA_PORTFOLIO } from '@atlassian/jira-shared-types/src/application.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import {
	DependenciesFlyoutOverlay,
	DependenciesFlyoutProvider,
} from '../../dependencies-flyout/index.tsx';
import IssueModal from '../../scope/issues/issue-modal/index.tsx';
import { getExperienceName } from '../../util.tsx';
import { SPOTLIGHT_TARGET_Z_INDEX } from '../constants.tsx';
import DependencyLinesProvider from '../overlay/dependency-lines/provider/index.tsx';
import { TableToolbar } from '../toolbar/index.tsx';
import { getViewportInset, getAdditionalHeightToHideNativeScrollbar } from '../utils.tsx';
import { AutoScrollObserver } from '../utils/drag-and-drop/auto-scroll-observer/index.tsx';
import { DragScrollLock } from '../utils/drag-and-drop/drag-scroll-lock/index.tsx';
import { DragAndDropProvider } from '../utils/drag-and-drop/index.tsx';
import { ColumnDragManager } from '../utils/draggable-column/drag-manager/index.tsx';
import { ColumnDragScrollLock } from '../utils/draggable-column/drag-scroll-lock/index.tsx';
import { EnhancedContainer } from '../utils/enhanced-container/index.tsx';
import { ScrollIntoView } from '../utils/scroll-into-view/index.tsx';
import { ViewExperienceSuccessTracker } from '../utils/view-experience-success-tracker/index.tsx';
import type { Props } from './types.tsx';

export const PlanTableWrapper: React.FC<Props> = ({
	mode,
	viewMode,
	isReportMode,
	isConfluenceMacro,
	isEmbed,
	reportTimeToInteractive,
	onRoadmapView,
	exportAppWidth,
	children,
}) => {
	const shouldShowNav4 = getWillShowNav4();
	const useCurrentSpotlight = shouldShowNav4 ? useCurrentSpotlightNew : useCurrentSpotlightOld;
	const { software: edition } = useAppEditions();
	let isFullscreen;
	let setIsFullscreen;
	if (shouldShowNav4) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[isFullscreen, { setIsFullscreen }] = useIsFullscreen();
	} else {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		[isFullscreen, setIsFullscreen] = useState<boolean>(false);
	}
	const [{ spotlight: onboardingSpotlight }] = useCurrentSpotlight();
	const [cttOnboardingSpotlight] = useCttSpotlight();

	const spotlightSelection =
		onboardingSpotlight === Spotlights.Inactive ? cttOnboardingSpotlight : onboardingSpotlight;

	const isExportMode = mode === EXPORT;
	const nativeScrollbarHeight = useNativeScrollbarHeight() ?? 0;

	const shouldHideScrollbar = !isExportMode && viewMode === VIEW_MODES.TIMELINE;

	const viewportInset = useMemo(
		() => (shouldHideScrollbar ? getViewportInset(nativeScrollbarHeight) : undefined),
		[shouldHideScrollbar, nativeScrollbarHeight],
	);

	const additionalHeightToHideNativeScrollbar = shouldHideScrollbar
		? getAdditionalHeightToHideNativeScrollbar(nativeScrollbarHeight)
		: 0;

	const toggleFullscreen = useCallback(() => {
		setIsFullscreen(!isFullscreen);
	}, [isFullscreen, setIsFullscreen]);

	const ref = useRef<HTMLDivElement>(null);

	const withSpotlight =
		spotlightSelection === Spotlights.HeartOfPlan || spotlightSelection === CttSpotlights.Scope;

	useLayoutEffect(() => {
		reportTimeToInteractive({
			isNewSidebarEnabled: true,
			isTransposed: true,
		});
	}, [reportTimeToInteractive]);

	// NOTE: Firefox does not reset the scroll when switching view modes (timeline / list)
	useLayoutEffect(() => {
		if (ref?.current && viewMode === VIEW_MODES.LIST) {
			ref.current.scrollLeft = 0;
		}
	}, [viewMode]);

	const [smartCardClient] = useState(() => new Client());

	return (
		<UFOSegment name="view-advanced-roadmaps-timeline">
			<ViewExperienceTrackingProvider
				experience={getExperienceName(isReportMode, isConfluenceMacro, isEmbed)}
				analyticsSource="portfolio3"
				application={JIRA_PORTFOLIO}
				edition={edition}
				additionalAttributes={{
					synthetic: isPollinatorTenant(),
				}}
			>
				<ViewExperienceFailureTracker
					location="portfolio-3.simple-plans.roadmap.state-or-global-error"
					locationType="path"
					locationPrefix="roadmap"
				/>
				<SmartCardProvider client={smartCardClient}>
					<ContextualAnalyticsData
						sourceType={SCREEN}
						sourceName={ERROR_REPORTING_PACKAGE.ROADMAP}
						attributes={{
							isTransposed: true,
						}}
					>
						<ReportErrors
							id={ERROR_REPORTING_PACKAGE.ROADMAP}
							packageName={PACKAGE_NAME}
							teamName={ERROR_REPORTING_TEAM}
							sendToPrivacyUnsafeSplunk
							attributes={{
								isTransposed: 'true',
							}}
						>
							{!isExportMode && (
								<UFOSegment name="view-advanced-roadmaps-timeline-overlay">
									<TableToolbar
										viewMode={viewMode}
										isCollapsible={viewMode === VIEW_MODES.TIMELINE}
										onToggleFullscreen={toggleFullscreen}
										isFullscreen={isFullscreen}
									/>
								</UFOSegment>
							)}
							<ZIndexProvider>
								<DragAndDropProvider>
									{fg('plan_timeline_drag_and_drop_field_columns') && <ColumnDragManager />}
									{fg('plan_timeline_drag_and_drop_field_columns') && <ColumnDragScrollLock />}
									<AutoScrollObserver scrollRef={ref} />
									<DragScrollLock />
									<DependenciesFlyoutProvider>
										<DependencyLinesProvider>
											{withSpotlight && !shouldShowNav4 && (
												<SpotlightTarget name={Spotlights.HeartOfPlan}>
													<SpotlightTarget name={CttSpotlights.Scope}>
														<Box xcss={spotlightPlaceholderStyles} />
													</SpotlightTarget>
												</SpotlightTarget>
											)}
											<Box xcss={hiddenOverflowStyles}>
												<EnhancedContainer
													xcss={[
														withSpotlight && !shouldShowNav4 && spotlightContainerStyles,
														isFullscreen && fullscreenContainerStyles,
														isExportMode && exportContainerStyles,
													]}
													width={exportAppWidth}
													style={{ bottom: -additionalHeightToHideNativeScrollbar }}
													viewportInset={viewportInset}
													outerRef={ref}
													data-scroll="lockable"
													testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.wrapper.table"
												>
													{children}
													<IssueModal />
													<ScrollIntoView />
													<DependenciesFlyoutOverlay />
													<HorizontalScrollBarOverlay />
												</EnhancedContainer>
											</Box>
										</DependencyLinesProvider>
									</DependenciesFlyoutProvider>
								</DragAndDropProvider>
							</ZIndexProvider>
							<MountEvent onMount={onRoadmapView} />
							<ViewExperienceSuccessTracker />
						</ReportErrors>
					</ContextualAnalyticsData>
				</SmartCardProvider>
				<UFOCustomData data={{ viewMode, isTransposed: true }} />
			</ViewExperienceTrackingProvider>
		</UFOSegment>
	);
};

const spotlightPlaceholderStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	pointerEvents: 'none',
});

/* Used to hide the native scrollbars that we push beyond the viewport via negative offsets */
const hiddenOverflowStyles = xcss({
	overflow: 'hidden',
	position: 'relative',
	width: '100%',
});

const fullscreenContainerStyles = xcss({
	position: 'fixed',
	background: 'elevation.surface',
	// @ts-expect-error - TS2322: Type number is not assignable to type
	zIndex: 1,

	'::before': {
		content: '""',
		background: 'elevation.surface',
		position: 'absolute',
		// @ts-expect-error - TS2322: Type number is not assignable to type
		zIndex: -1,
	},
});

const spotlightContainerStyles = xcss({
	pointerEvents: 'none',
	// @ts-expect-error - TS2322: Type number is not assignable to type
	zIndex: SPOTLIGHT_TARGET_Z_INDEX + 1, // eslint-disable-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
});

const exportContainerStyles = xcss({
	position: 'relative',
	// @ts-expect-error - TS2322: Type number is not assignable to type
	zIndex: 1,
});
