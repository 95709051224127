import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	spotlightGotToIssueSources: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-navigate-issue-sources.spotlight-got-to-issue-sources',
		defaultMessage: 'Go to Issue Sources',
		description:
			'The Go to Issue Sources button for cross team templates onboarding spotlight card.',
	},
	spotlightNavigateIssueSourcesTitle: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-navigate-issue-sources.spotlight-navigate-issue-sources-title',
		defaultMessage: "Let's keep going!",
		description: 'The title for cross team templates onboarding navigate issue sources card.',
	},
	spotlightNavigateIssueSourcesBodyP1: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-navigate-issue-sources.spotlight-navigate-issue-sources-body-p-1',
		defaultMessage:
			'Now add another project, board, or filter that has the work items you want to track with your Initiative.',
		description:
			'Part one of body for cross team templates onboarding navigate issue sources card.',
	},
	spotlightNavigateIssueSourcesBodyP2: {
		id: 'portfolio-3-onboarding.ctt-onboarding.spotlight-navigate-issue-sources.spotlight-navigate-issue-sources-body-p-2',
		defaultMessage: 'Select <b>Plan settings</b>, then <b>Issue sources</b>.',
		description:
			'Part two of body for cross team templates onboarding navigate issue sources card.',
	},
});
