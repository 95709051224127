import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	setHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-assignee.set-header',
		defaultMessage: 'Update assignee',
		description: 'Modal dialog header for dialog assignee',
	},
	variousValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.bulk-actions.dialog.set-assignee.various-values',
		defaultMessage: 'Various assignees',
		description: 'Label for various assignees values',
	},
});
