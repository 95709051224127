import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

const querySelector = createStructuredSelector<State, StateProps>({
	plan: getPlan,
});

export default querySelector;
