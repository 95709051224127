import concat from 'lodash/concat';
import isEqual from 'lodash/isEqual';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	PROJECT_FILTER_ID,
	CROSS_PROJECT_RELEASE_FILTER_ID,
	RELEASE_FILTER_ID,
	TEAM_FILTER_ID,
	ASSIGNEE_FILTER_ID,
	REPORTER_FILTER_ID,
	LABEL_FILTER_ID,
	COMPONENT_FILTER_ID,
	STATUS_FILTER_ID,
	ISSUE_WARNING_FILTER_ID,
	SPRINT_FILTER_ID,
	ISSUE_TYPES_FILTER_ID,
	ISSUE_PRIORITIES_FILTER_ID,
	GOAL_FILTER_ID,
	IDEA_FILTER_ID,
	HIERARCHY_FILTER_ID,
	HIERARCHY_RANGE_FILTER_ID,
	SUMMARY_FILTER_ID,
	DEPENDENCIES_FILTER_ID,
	STATUS_KEY_FILTER_ID,
	ISSUE_TYPE_KEY_FILTER_ID,
	CUSTOM_FIELD_FILTER_ID,
	DATE_RANGE_FILTER_ID,
} from '../../state/domain/view-settings/filters/types.tsx';
import type { ViewSettingsState } from '../../state/domain/view-settings/types.tsx';
import { getActiveView } from '../views/index.tsx';

const ARRAY_FILTERS = [
	PROJECT_FILTER_ID,
	CROSS_PROJECT_RELEASE_FILTER_ID,
	RELEASE_FILTER_ID,
	TEAM_FILTER_ID,
	ASSIGNEE_FILTER_ID,
	REPORTER_FILTER_ID,
	LABEL_FILTER_ID,
	COMPONENT_FILTER_ID,
	STATUS_FILTER_ID,
	ISSUE_WARNING_FILTER_ID,
	SPRINT_FILTER_ID,
	ISSUE_TYPES_FILTER_ID,
	ISSUE_PRIORITIES_FILTER_ID,
	GOAL_FILTER_ID,
	IDEA_FILTER_ID,
] as const;

export type UrlPersistenceMetrics = {
	keyCount: number;
	totalValueLength: number;
	unsavedSettingsMetrics: UnsavedSettingsMetrics[];
};

type UnsavedSettingsMetrics = {
	key: string;
	valueLength: number;
};

export const getUrlPersistenceEstimate = createSelector(
	[getActiveView],
	(activeView): UrlPersistenceMetrics | undefined => {
		try {
			const originalPreferences = activeView.original?.preferences;
			const currentPreferences = activeView.preferences;

			return getUrlPersistenceEstimatePure(originalPreferences, currentPreferences);
		} catch (error) {
			log.safeErrorWithoutCustomerData(
				'app-simple-plans.query.url-persistance',
				'Failed to compute url estimates',
			);
			return undefined;
		}
	},
);

export const getUrlPersistenceEstimatePure = (
	originalPreferences?: ViewSettingsState,
	currentPreferences?: ViewSettingsState,
): UrlPersistenceMetrics => {
	if (originalPreferences === undefined) {
		// when there are no original preferences, it is because there are no changes at all.
		return {
			keyCount: 0,
			totalValueLength: 0,
			unsavedSettingsMetrics: [],
		};
	}

	const unsavedSettingsMetrics = concat(
		computeArrayFilterEstimate(originalPreferences, currentPreferences),
		computeSpecialFilterEstimate(originalPreferences, currentPreferences),
		computeViewSettingsEstimate(originalPreferences, currentPreferences),
	);

	const totalValueLength = unsavedSettingsMetrics.reduce<number>(
		(accumulator, { valueLength }) => accumulator + valueLength,
		0,
	);

	return {
		keyCount: unsavedSettingsMetrics.length,
		totalValueLength,
		unsavedSettingsMetrics,
	};
};

export const computeViewSettingsEstimate = (
	originalPreferences?: ViewSettingsState,
	currentPreferences?: ViewSettingsState,
): UnsavedSettingsMetrics[] => {
	const result: UnsavedSettingsMetrics[] = [];
	const computeEstimateUsingToString = computeEstimateUsingToStringTransformer(
		originalPreferences,
		currentPreferences,
	);
	const computeEstimateUsingJsonStringify = computeEstimateUsingJsonStringifyTransformer(
		originalPreferences,
		currentPreferences,
	);

	computeEstimateUsingJsonStringify(result, 'colourByV2', (preferences) => preferences?.colourByV2);
	computeEstimateUsingJsonStringify(
		result,
		'timeScaleV1',
		(preferences) => preferences?.timeScaleV1,
	);
	computeEstimateUsingJsonStringify(
		result,
		'issueExpansionsV0',
		(preferences) => preferences?.issueExpansionsV0,
	);
	computeEstimateUsingJsonStringify(
		result,
		'fieldColumnsV0',
		(preferences) => preferences?.fieldColumnsV0,
	);
	computeEstimateUsingJsonStringify(
		result,
		'listFieldColumnsV0',
		(preferences) => preferences?.listFieldColumnsV0,
	);
	computeEstimateUsingJsonStringify(
		result,
		'filterOptionsV1',
		(preferences) => preferences?.filterOptionsV1,
	);
	computeEstimateUsingJsonStringify(
		result,
		'rollupSettingsV0',
		(preferences) => preferences?.rollupSettingsV0,
	);
	computeEstimateUsingJsonStringify(
		result,
		'highlightedVersionsV0',
		(preferences) => preferences?.highlightedVersionsV0,
	);
	computeEstimateUsingJsonStringify(
		result,
		'componentGroupsV0',
		(preferences) => preferences?.componentGroupsV0,
	);
	computeEstimateUsingJsonStringify(
		result,
		'labelGroupsV0',
		(preferences) => preferences?.labelGroupsV0,
	);
	computeEstimateUsingJsonStringify(
		result,
		'customFieldValuesGroupsV0',
		(preferences) => preferences?.customFieldValuesGroupsV0,
	);
	computeEstimateUsingJsonStringify(
		result,
		'warningSettingsV1',
		(preferences) => preferences?.warningSettingsV1,
	);
	computeEstimateUsingJsonStringify(
		result,
		'dateConstraintsV0',
		(preferences) => preferences?.dateConstraintsV0,
	);
	computeEstimateUsingJsonStringify(
		result,
		'visualisationsV0',
		(preferences) => preferences?.visualisationsV0,
	);
	computeEstimateUsingJsonStringify(
		result,
		'dependencySettingsV0',
		(preferences) => preferences?.dependencySettingsV0,
	);
	computeEstimateUsingToString(
		result,
		'viewModeV0',
		(preferences) => preferences?.viewModeV0?.mode,
	);

	return result;
};

export const computeArrayFilterEstimate = (
	originalPreferences?: ViewSettingsState,
	currentPreferences?: ViewSettingsState,
): UnsavedSettingsMetrics[] => {
	const result: UnsavedSettingsMetrics[] = [];

	ARRAY_FILTERS.forEach((key) => {
		const originalValue = originalPreferences?.filtersV1?.[key]?.value;
		const currentValue = currentPreferences?.filtersV1?.[key]?.value;

		if (!isEqual(originalValue, currentValue)) {
			result.push({
				key,
				valueLength: currentValue?.join(',')?.length ?? 0,
			});
		}
	});

	return result;
};

export const computeSpecialFilterEstimate = (
	originalPreferences?: ViewSettingsState,
	currentPreferences?: ViewSettingsState,
): UnsavedSettingsMetrics[] => {
	const result: UnsavedSettingsMetrics[] = [];

	const hierarchyFilterOriginalValue = originalPreferences?.filtersV1?.[HIERARCHY_FILTER_ID]?.value;
	const hierarchyFilterCurrentValue = currentPreferences?.filtersV1?.[HIERARCHY_FILTER_ID]?.value;

	if (!isEqual(hierarchyFilterOriginalValue, hierarchyFilterCurrentValue)) {
		result.push({
			key: HIERARCHY_FILTER_ID,
			valueLength:
				(hierarchyFilterCurrentValue?.start?.toString().length ?? 0) +
				(hierarchyFilterCurrentValue?.end?.toString().length ?? 0),
		});
	}

	const hierarchyRangeFilterOriginalValue =
		originalPreferences?.filtersV1?.[HIERARCHY_RANGE_FILTER_ID]?.value;
	const hierarchyRangeFilterCurrentValue =
		currentPreferences?.filtersV1?.[HIERARCHY_RANGE_FILTER_ID]?.value;

	if (!isEqual(hierarchyRangeFilterOriginalValue, hierarchyRangeFilterCurrentValue)) {
		result.push({
			key: HIERARCHY_RANGE_FILTER_ID,
			valueLength:
				(hierarchyRangeFilterCurrentValue?.start?.toString().length ?? 0) +
				(hierarchyRangeFilterCurrentValue?.end?.toString().length ?? 0),
		});
	}

	const computeEstimateUsingToString = computeEstimateUsingToStringTransformer(
		originalPreferences,
		currentPreferences,
	);
	computeEstimateUsingToString(
		result,
		SUMMARY_FILTER_ID,
		(preferences) => preferences?.filtersV1?.[SUMMARY_FILTER_ID]?.value,
	);

	computeEstimateUsingToString(
		result,
		DEPENDENCIES_FILTER_ID,
		(preferences) => preferences?.filtersV1?.[DEPENDENCIES_FILTER_ID]?.value?.type,
	);

	const statusKeyFilterOriginalValue =
		originalPreferences?.filtersV1?.[STATUS_KEY_FILTER_ID]?.value;
	const statusKeyFilterCurrentValue = currentPreferences?.filtersV1?.[STATUS_KEY_FILTER_ID]?.value;

	if (!isEqual(statusKeyFilterOriginalValue, statusKeyFilterCurrentValue)) {
		result.push({
			key: STATUS_KEY_FILTER_ID,
			valueLength: statusKeyFilterCurrentValue?.map(({ name }) => name)?.join(',')?.length ?? 0,
		});
	}

	const issueTypeKeyFilterOriginalValue =
		originalPreferences?.filtersV1?.[ISSUE_TYPE_KEY_FILTER_ID]?.value;
	const issueTypeKeyFilterCurrentValue =
		currentPreferences?.filtersV1?.[ISSUE_TYPE_KEY_FILTER_ID]?.value;

	if (!isEqual(issueTypeKeyFilterOriginalValue, issueTypeKeyFilterCurrentValue)) {
		result.push({
			key: ISSUE_TYPE_KEY_FILTER_ID,
			valueLength: issueTypeKeyFilterCurrentValue?.map(({ name }) => name)?.join(',')?.length ?? 0,
		});
	}

	const customFieldFilterOriginalValue =
		originalPreferences?.filtersV1?.[CUSTOM_FIELD_FILTER_ID]?.value;
	const customFieldFilterCurrentValue =
		currentPreferences?.filtersV1?.[CUSTOM_FIELD_FILTER_ID]?.value;

	if (!isEqual(customFieldFilterOriginalValue, customFieldFilterCurrentValue)) {
		Object.keys(customFieldFilterCurrentValue ?? {}).forEach((key) => {
			const originalValue = customFieldFilterOriginalValue?.[key];
			const currentValue = customFieldFilterCurrentValue?.[key];
			if (!isEqual(originalValue, currentValue)) {
				result.push({
					key: `${CUSTOM_FIELD_FILTER_ID}.${key}`,
					valueLength: currentValue?.join(',')?.length ?? 0,
				});
			}
		});
	}

	const dateRangeFilterOriginalValue =
		originalPreferences?.filtersV1?.[DATE_RANGE_FILTER_ID]?.value;
	const dateRangeFilterCurrentValue = currentPreferences?.filtersV1?.[DATE_RANGE_FILTER_ID]?.value;

	if (!isEqual(dateRangeFilterOriginalValue, dateRangeFilterCurrentValue)) {
		result.push({
			key: DATE_RANGE_FILTER_ID,
			valueLength:
				(dateRangeFilterCurrentValue?.startDate?.toString()?.length ?? 0) +
				(dateRangeFilterCurrentValue?.endDate?.toString()?.length ?? 0),
		});
	}

	return result;
};

const computeEstimateUsingJsonStringifyTransformer =
	(orginalPreferences?: ViewSettingsState, currentPreferences?: ViewSettingsState) =>
	(
		accumulator: UnsavedSettingsMetrics[],
		key: string,
		selector: (preferences?: ViewSettingsState) => unknown,
	): void => {
		const originalValue = selector(orginalPreferences);
		const currentValue = selector(currentPreferences);

		if (!isEqual(originalValue, currentValue)) {
			accumulator.push({
				key,
				valueLength: JSON.stringify(currentValue).length ?? 0,
			});
		}
	};

const computeEstimateUsingToStringTransformer =
	(orginalPreferences?: ViewSettingsState, currentPreferences?: ViewSettingsState) =>
	(
		accumulater: UnsavedSettingsMetrics[],
		key: string,
		selector: (preferences?: ViewSettingsState) => unknown,
	): void => {
		const originalValue = selector(orginalPreferences);
		const currentValue = selector(currentPreferences);

		if (!isEqual(originalValue, currentValue)) {
			accumulater.push({
				key,
				valueLength: String(currentValue)?.length ?? 0,
			});
		}
	};
