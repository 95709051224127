.searchSummaryContainer {
	display: flex;
	align-items: center;
}

.summaryCount {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
}

.searchIconWrapper {
	margin-right: 8px;
}

.iconWrapper {
	cursor: pointer;
	width: 20px;
	display: flex;
	align-items: center;

	&:hover svg {
		color: var(--ds-text-subtle, var(--adg3-color-N500));
	}

	& button {
		height: 24px;
	}
}

.justifyCentered {
	justify-content: center;
}

.disabled:hover svg {
	color: var(--ds-text-disabled, var(--adg3-color-N90));
}
