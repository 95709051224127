export const HEADER = 'HEADER' as const;
export const SUBHEADER = 'SUBHEADER' as const;
export const TABLE_ITEM = 'TABLE_ITEM' as const;

export const SCOPE = 'SCOPE' as const;
export const ADD_FIELDS = 'ADD_FIELDS' as const;
export const EMPTY = 'EMPTY' as const;
export const FIELD = 'FIELD' as const;
export const TIMELINE = 'TIMELINE' as const;

export const COLUMN_COLLAPSE_THRESHOLD = 24;
export const SECTION_COLLAPSE_THRESHOLD = 32;
export const TIMELINE_DEFAULT_EXPANDABLE_WIDTH = 300;
