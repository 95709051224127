import React from 'react';
import { xcss } from '@atlaskit/primitives';
import JiraIssueLink from '@atlassian/jira-portfolio-3-common/src/issue-link/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import EllipsedWithTooltip from '@atlassian/jira-portfolio-3-portfolio/src/common/view/ellipsed-with-tooltip/index.tsx';
import { titleCase } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/utils/string.tsx';
import NotInPlan from '../not-in-plan/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function IssueLink(props: Props) {
	const {
		issue: issueId,
		issues,
		issuesInChangeData,
		issueLinkTypes,
		issueTypes,
		linkType,
		direction,
		projects,
	} = props;
	const NotInPlanComponent = (
		<div>
			<NotInPlan />
		</div>
	);

	if (!issueId) {
		return <div>-</div>;
	}

	const targetIssueFromChangeData = issuesInChangeData.find(
		(currentIssue) => currentIssue.id === issueId,
	);
	const targetIssueFromBacklog = issues.find((currentIssue) => currentIssue.id === issueId);

	if (!(isDefined(targetIssueFromChangeData) || isDefined(targetIssueFromBacklog))) {
		return NotInPlanComponent;
	}

	let summary: Issue['summary'] | undefined;
	let project: Issue['project'] | undefined;
	let type: Issue['type'] | undefined;
	let issueKey: Issue['issueKey'] | undefined;
	if (isDefined(targetIssueFromChangeData)) {
		({
			values: { summary, project, type },
			issueKey,
		} = targetIssueFromChangeData);
	} else if (isDefined(targetIssueFromBacklog)) {
		({ summary, project, type, issueKey } = targetIssueFromBacklog);
	}

	const targetProject = projects.find((currentProject) => currentProject.id === project);
	const issueType = issueTypes.find((currentIssueType) => currentIssueType.id === type);
	const link = linkType && issueLinkTypes.find(({ id }) => id === linkType);
	const issueLinkType = link && direction && titleCase(link[direction]);

	if (targetProject && issueType) {
		const { key: projectKey } = targetProject;
		const title = `${issueLinkType ? `${issueLinkType} ` : ''}${projectKey}${
			issueKey ? `-${issueKey}` : ''
		} ${summary || ''}`;
		return (
			<EllipsedWithTooltip content={title} id={issueId}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.issueTitle}>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					{issueLinkType ? <div className={styles.issueLinkType}>{issueLinkType}</div> : null}
					<div
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.issueIcon}
						style={{
							// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
							backgroundSize: 'contain',
							backgroundImage: issueType && `url(${issueType.iconUrl})`,
						}}
					/>
					<JiraIssueLink projectKey={projectKey} issueKey={issueKey} xcssStyles={issueLinkStyles} />
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.issueSummary}>{summary}</div>
				</div>
			</EllipsedWithTooltip>
		);
	}
	return NotInPlanComponent;
}

const issueLinkStyles = xcss({
	whiteSpace: 'nowrap',
});
