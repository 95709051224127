import {
	getAssigneeListFetchStatus,
	getAssigneeList,
	getAssigneesOfIssues,
	getIssueIdForFetchAssigneeListProgress,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/assignees/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export const mapStateToProps = createStructuredSelector<State, {}, StateProps>({
	assigneeList: getAssigneeList,
	assigneeListFetchStatus: getAssigneeListFetchStatus,
	currentAssignees: getAssigneesOfIssues,
	issueIdForFetchAssigneeListProgress: getIssueIdForFetchAssigneeListProgress,
});

export default mapStateToProps;
