.list {
	display: flex;
	flex-direction: column;
}

.row {
	display: flex;
	align-content: center;
	align-items: center;
	margin-bottom: 5px;
}

.swatch {
	width: 16px;
	height: 16px;
	border-radius: 3px;
}

.allOtherIssuesButton {
	background: none;
}

.labelSelect {
	width: 100%;
}

.allOtherIssues {
	padding-left: 12px;
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
}

.addLabelButton button {
	margin-left: -8px;
}

.addLabel {
	padding-left: 6px;
}
