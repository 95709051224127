import type { State } from '../../state/types.tsx';
import type { Errors } from '../../state/ui/errors/types.tsx';

export const getErrors = (state: State): Errors => state.ui.Errors.errors;

export const getErrorLogDialogState = (state: State): boolean =>
	state.ui.Errors.isErrorLogDialogOpen;

export const getIsProjectLimitExceeded = (state: State): boolean =>
	state.ui.Errors.isProjectLimitExceeded;
