import { RESET, type ResetAction } from './actions.tsx';
import type { HiddenIssue } from './types.tsx';

const initialState: HiddenIssue[] = [];

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: HiddenIssue[] = initialState, action: ResetAction) => {
	switch (action.type) {
		case RESET:
			return [...action.payload];
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
