import * as R from 'ramda';
import type {
	SchedulingMode,
	Resource,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { Team } from '../../state/domain/teams/types.tsx';

export const urls = {
	addNewTeam: '/rest/jpo/1.0/team/addNew',
	addAtlassianTeam: ' /rest/jpo/1.0/team/addExternal',
	updateTeam: '/rest/jpo/1.0/team/update',
	revertTeamChanges: '/rest/jpo/1.0/team/revert',
	deleteTeam: '/rest/jpo/1.0/team/delete',
	revertResourceChanges: '/rest/jpo/1.0/resource/revertMember',
	addTeamMember: '/rest/jpo/1.0/resource/addMember',
	deleteTeamMember: '/rest/jpo/1.0/resource/deleteMember',
	setShared: (id: string) => `/rest/teams/1.0/teams/${id}/setShared`,
	setSharedNew: '/rest/jpo/1.0/team/setShared',
	// This is the endpoint which will add team member directly to the team without scenario change
	addTeamMemberWithoutScenarioChange: '/rest/jpo/1.0/team/addResources',
	getTWPTeam: (id: string) => `/gateway/api/v3/teams/${id}`,
	getTWPTeamV4: (id: string, siteId?: string) =>
		`/gateway/api/v4/teams/${id}?siteId=${siteId ?? 'None'}`,
} as const;

export type AddTeamMemberPayload = {
	personItemKey: string;
	teamItemKey: string;
	weeklyHours?: number;
};

export type DeleteTeamMemberPayload = {
	removedResources: Resource[];
	teamId: string;
};

export type Member = {
	value: number;
	label: string;
	avatarUrl: string;
};

export type TeamPayload = {
	title: string;
	avatarUrl?: string;
	schedulingMode: SchedulingMode;
	velocity: number | null | undefined;
	weeklyHours: number | null | undefined;
	iterationLength: number | null | undefined;
	issueSource?: number;
	members?: Member[];
};

export type AtlassianTeamPayload = {
	id: string;
	title?: string;
	members?: string[];
};

export type EnhancedTeam = Team & {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	members: any[];
};

export type EditTeamPayload = {
	enhancedTeam: EnhancedTeam;
	formFields: Partial<TeamPayload>;
	teamId: string;
};

export type DeleteTeamPayload = {
	id: string;
};

export const getTeamDescriptionPayload = (teamData: Partial<TeamPayload>) => {
	const description: Record<keyof TeamPayload, { value?: TeamPayload[keyof TeamPayload] }> =
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{} as Record<keyof TeamPayload, { value?: TeamPayload[keyof TeamPayload] }>;

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	for (const key of Object.keys(teamData) as Array<keyof TeamPayload>) {
		const value = teamData[key];
		description[key] = R.isNil(value) ? {} : { value };
	}
	return description;
};

export type AddMembersAndShareTeamPayload = {
	teamId: string;
	personIds: number[];
};
