.blue {
	color: var(--ds-text-brand, var(--adg3-color-B400));
}

.row {
	& > td {
		padding: 0 var(--jpo-common-base-spacing);
	}
}

.componentGroup {
	position: relative;
	display: flex;
	align-items: center;

	& > .icon {
		padding-top: 2px;
	}
}

.groupTitleSection {
	display: flex;
	align-items: center;
	flex: 1 1 0;
	padding-left: calc(var(--jpo-common-base-spacing) * 1.5);
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	max-width: 264px;
}

.expandCollapseChevron {
	position: absolute;
	top: 8px;
	left: -25px;
}

.groupTitle {
	flex: 1 1 0;
	min-width: 85%;
	& input {
		cursor: initial;
	}
}

.trashButton {
	text-align: right;
	flex: 1 1 0;
}

.componentSelect {
	margin-left: 36px;
}
