import type { CustomField } from './types.tsx';

export const RESET = 'state.domain.custom-fields.RESET' as const;
export const ADD = 'state.domain.custom-fields.ADD' as const;
export const UPDATE = 'state.domain.custom-fields.UPDATE' as const;
export const REMOVE = 'state.domain.custom-fields.REMOVE' as const;

export type ResetActionPayload = CustomField[];
export type AddActionPayload = CustomField[];
export type UpdateActionPayload = CustomField;
export type RemoveActionPayload = number;

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type AddAction = {
	type: typeof ADD;
	payload: AddActionPayload;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: RemoveActionPayload;
};

export const reset = (payload: ResetActionPayload) => ({
	type: RESET,
	payload,
});

export const add = (payload: AddActionPayload) => ({
	type: ADD,
	payload,
});

export const update = (payload: UpdateActionPayload) => ({
	type: UPDATE,
	payload,
});

export const remove = (payload: RemoveActionPayload) => ({
	type: REMOVE,
	payload,
});
