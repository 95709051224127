import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';

export const RESET = 'state.domain.view-settings.filters.custom-field-filter.RESET' as const;

export const UPDATE = 'state.domain.view-settings.filters.custom-field-filter.UPDATE' as const;

export const CLEAR = 'state.domain.view-settings.filters.custom-field-filter.CLEAR' as const;

export const CLEAR_ALL =
	'state.domain.view-settings.filters.custom-field-filter.CLEAR_ALL' as const;

export type ResetActionPayload = {
	customFields: CustomField[];
};

export type UpdateActionPayload = {
	customField: CustomField;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: any;
};

export type ClearActionPayload = {
	customField: CustomField;
};

export type ClearAllActionPayload = {
	customFields: CustomField[];
};

export type ResetAction = {
	type: typeof RESET;
	payload: ResetActionPayload;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: UpdateActionPayload;
};

export type ClearAction = {
	type: typeof CLEAR;
	payload: ClearActionPayload;
};

export type ClearAllAction = {
	type: typeof CLEAR_ALL;
	payload: ClearAllActionPayload;
};

export const reset = (payload: ResetActionPayload): ResetAction => ({
	type: RESET,
	payload,
});

export const update = (payload: UpdateActionPayload): UpdateAction => ({
	type: UPDATE,
	payload,
});

export const clear = (payload: ClearActionPayload): ClearAction => ({
	type: CLEAR,
	payload,
});

export const clearAll = (payload: ClearAllActionPayload): ClearAllAction => ({
	type: CLEAR_ALL,
	payload,
});
