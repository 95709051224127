.component {
	margin-right: 5px;
}

.componentRemoved span {
	text-decoration: line-through;
}

.tagContainer {
	display: flex;
}

.componentsContainer {
	display: flex;
	flex-direction: row;
}
