import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	buttonTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.collapse-menu.button-title',
		defaultMessage: 'Issue',
		description: 'Header text for the scope section, label for issues in the plan.',
	},
	collapseHierarchy: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.collapse-menu.collapse-hierarchy',
		defaultMessage: 'Collapse all',
		description: 'Click on this label to collapse all issues',
	},
	expandHierarchy: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.collapse-menu.expand-hierarchy',
		defaultMessage: 'Expand all',
		description: 'Click on this label to expand all issues',
	},
});
