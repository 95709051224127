import React, { forwardRef, useCallback } from 'react';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { Link as RouterLink } from '@atlassian/react-resource-router';
import messages from '../messages.tsx';

const HierarchyLink = () => {
	const { formatMessage } = useIntl();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClick = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'click',
			actionSubject: 'hierarchyLink',
		});
		fireUIAnalytics(analyticsEvent);
	}, [createAnalyticsEvent]);

	return (
		<DropdownItem
			testId="portfolio-3-portfolio.app-simple-plans.top.filter-bar.hierarchy-filter.hierarchy-link.hierarchy-link"
			component={CustomComponentButton}
			onClick={onClick}
		>
			{formatMessage(messages.hierarchyFooterLink)}
		</DropdownItem>
	);
};

const CustomComponentButton = forwardRef<HTMLButtonElement, React.PropsWithChildren<{}>>(
	({ children, ...props }, ref) => (
		<RouterLink {...props} ref={ref} href="/jira/settings/issues/issue-hierarchy">
			{children}
		</RouterLink>
	),
);

export default HierarchyLink;
