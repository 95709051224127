import { Component, cloneElement, Children, type PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import type { Props, State, Position } from './types.tsx';

export const DRAG_THRESHOLD = 3;

// This is a higher order component that allows reacting to the dragging of it's children.
// eslint-disable-next-line jira/react/no-class-components
export default class DragObserver extends Component<PropsWithChildren<Props>, State> {
	static defaultProps = {
		dragHandler: {},
	};

	state: State = {
		dragging: false,
	};

	// eslint-disable-next-line react/sort-comp
	mounted = false;

	node: Element | null | undefined = null;

	componentDidMount() {
		this.node = this.getDomNode();
		this.mounted = true;
		// @ts-expect-error - TS2769 - No overload matches this call.
		this.node.addEventListener('mousedown', this.onChildMouseDown);
	}

	componentWillUnmount() {
		// remove listeners in case this component is unmounted during a drag operation

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mousemove', this.onWindowMouseMove);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mouseup', this.onWindowMouseUp);
		if (this.node) {
			// @ts-expect-error - TS2769 - No overload matches this call.
			this.node.removeEventListener('mousedown', this.onChildMouseDown);
		}
		this.mounted = false;
	}

	// Must only be called after the component has been mounted
	getDomNode(): Element {
		// findDOMNode is discouraged but is sometimes the best way to implement behavior-based
		// higher order components. See https://github.com/yannickcr/eslint-plugin-react/issues/678#issuecomment-238090846
		// In this case we can't just use a ref from the child, because the child may not be an HTML element, and thus the
		// ref won't point to a DOM node.
		// eslint-disable-next-line react/no-find-dom-node
		const node = ReactDOM.findDOMNode(this);
		if (!(node instanceof Element)) {
			throw new Error('Only plain DOM elements could be observed');
		}
		return node;
	}

	onChildMouseDown = (e: MouseEvent) => {
		if (Object.keys(this.props.dragHandler).length === 0) {
			// No drag handler. Return early
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('mousemove', this.onWindowMouseMove);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('mouseup', this.onWindowMouseUp);

		const dragStart = this.getMousePosition(e);

		this.setState({
			dragging: false,
			dragStart,
			previousDragPosition: dragStart,
		});

		this.onMouseDown(e);
	};

	onWindowMouseMove = (e: MouseEvent) => {
		e.preventDefault();

		const mouse = this.getMousePosition(e);

		const { dragging, dragStart, previousDragPosition } = this.state;

		if (!dragStart || !previousDragPosition) {
			throw Error('dragStart and previousDragPosition should be set');
		}

		if (!dragging) {
			if (
				Math.abs(mouse.x - dragStart.x) > DRAG_THRESHOLD ||
				Math.abs(mouse.y - dragStart.y) > DRAG_THRESHOLD
			) {
				this.setState({ dragging: true });

				this.onDragStart(dragStart);
			}
		}

		if (dragging) {
			const from = previousDragPosition;
			const to = mouse;

			this.setState({ previousDragPosition: mouse });
			this.onDrag(from, to, dragStart);
		}
	};

	onWindowMouseUp = (e: MouseEvent) => {
		const { dragging, dragStart } = this.state;

		if (!dragStart) {
			// Make flow happy
			throw Error('Illegal state: dragStart must be set here');
		}
		if (dragging) {
			const mouse = this.getMousePosition(e);
			const start = dragStart;
			const end = mouse;

			if (this.mounted) {
				this.setState({ dragging: false });
			}
			this.onDragEnd(start, end);
		} else {
			this.onClick(e);
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mousemove', this.onWindowMouseMove);

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.removeEventListener('mouseup', this.onWindowMouseUp);
	};

	onMouseDown = (e: MouseEvent) => {
		if (this.props.dragHandler.onMouseDown) {
			this.props.dragHandler.onMouseDown(e);
		}
	};

	onDragStart = (from: Position) => {
		if (this.props.dragHandler.onDragStart) {
			this.props.dragHandler.onDragStart(from);
		}
	};

	onDrag = (from: Position, to: Position, start: Position) => {
		if (this.props.dragHandler.onDrag) {
			this.props.dragHandler.onDrag(from, to, start);
		}
	};

	onDragEnd = (from: Position, to: Position) => {
		if (this.props.dragHandler.onDragEnd) {
			this.props.dragHandler.onDragEnd(from, to);
		}
	};

	onClick = (e: MouseEvent) => {
		if (this.props.dragHandler.onClick) {
			this.props.dragHandler.onClick(e);
		}
	};

	getMousePosition = (e: MouseEvent) => ({
		x: e.pageX,
		y: e.pageY,
	});

	render() {
		const { dragging } = this.state;
		const { children } = this.props;
		const childProps = dragging ? { dragging } : {};
		// @ts-expect-error - TS2769 - No overload matches this call.
		return cloneElement(Children.only(children), { ...childProps });
	}
}
