import React, { type PropsWithChildren, useCallback, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useDragAndDropMonitor } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/bindings/index.tsx';
import { DragAndDropContainer } from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/controller/store.tsx';
import type {
	Indicator,
	DragTarget,
	DropTarget,
} from '@atlassian/jira-portfolio-3-issue-drag-and-drop/src/types.tsx';
import {
	REPARENT_CMP_TO_TMP_WARNING,
	REPARENT_TMP_TO_EXTERNAL_WARNING,
	SORTED_PREVENT_ISSUE_RANKING_WARNING,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/flags/types.tsx';
import { DROP_NONE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/types.tsx';
import { isSortByRank } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/util/index.tsx';
import { initialDropState } from './constants.tsx';
import {
	type DropParameters,
	type DropState,
	type Props,
	REPARENT_CMP_TO_TMP,
	REPARENT_TMP_TO_EXTERNAL,
} from './types.tsx';
import { disableUserSelection, getNewDropState } from './utils.tsx';

const DragAndDropProviderInternal = (props: PropsWithChildren<Props>) => {
	const { children, issues, sorting, onMove, onInvalidRanking } = props;

	const dropStateRef = useRef<DropState>(initialDropState);

	const getDropState = useCallback(() => dropStateRef.current, [dropStateRef]);

	const setDropState = useCallback(
		(dropState: DropState) => {
			dropStateRef.current = dropState;
		},
		[dropStateRef],
	);

	const computeDropState = useCallback(
		(dragTarget: DragTarget, dropTarget?: DropTarget) => {
			if (!isSortByRank(sorting.field)) return initialDropState;

			const parameters: DropParameters = {
				dragTarget,
				dropTarget,
			};

			const oldParameters = dropStateRef.current.parameters;

			if (!isEqual(parameters, oldParameters))
				dropStateRef.current = getNewDropState(issues, parameters);

			return dropStateRef.current;
		},
		[issues, sorting, dropStateRef],
	);

	const getIndicators = useCallback(
		(dragTarget: DragTarget, dropTarget?: DropTarget): Indicator[] | undefined => {
			const { indicators } = computeDropState(dragTarget, dropTarget);

			return indicators;
		},
		[computeDropState],
	);

	const onDragStart = useCallback(() => {
		disableUserSelection(true);

		if (!isSortByRank(sorting.field)) {
			onInvalidRanking({ key: SORTED_PREVENT_ISSUE_RANKING_WARNING });
		}
	}, [onInvalidRanking, sorting]);

	const onDrag = useCallback(
		(dragTarget: DragTarget, dropTarget?: DropTarget) => {
			computeDropState(dragTarget, dropTarget);
		},
		[computeDropState],
	);

	const onDrop = useCallback(
		(dragTarget: DragTarget, dropTarget?: DropTarget) => {
			disableUserSelection(false);

			if (!dropTarget) {
				setDropState(initialDropState);
				return;
			}

			const { type, action, blocked } = getDropState();

			if (type !== DROP_NONE && action && !blocked && isSortByRank(sorting.field))
				onMove({ ...action, dragAndDrop: true });

			if (blocked !== undefined) {
				switch (blocked.reason) {
					case REPARENT_TMP_TO_EXTERNAL: {
						const { context } = blocked;
						onInvalidRanking({
							key: REPARENT_TMP_TO_EXTERNAL_WARNING,
							context: {
								...context,
								issueKey: <b>{context.issueKey}</b>,
							},
						});
						break;
					}
					case REPARENT_CMP_TO_TMP: {
						const { context } = blocked;
						onInvalidRanking({
							key: REPARENT_CMP_TO_TMP_WARNING,
							context: {
								...context,
								issueKey: <b>{context.issueKey}</b>,
							},
						});
						break;
					}
					default:
						break;
				}
			}

			setDropState(initialDropState);
		},
		[getDropState, onInvalidRanking, onMove, setDropState, sorting],
	);

	useDragAndDropMonitor({
		onDragStart,
		onDrag,
		onDrop,
		getIndicators,
	});

	return <>{children}</>;
};

export const DragAndDropProvider = ({ children, ...props }: PropsWithChildren<Props>) => (
	<DragAndDropContainer>
		<DragAndDropProviderInternal {...props}>{children}</DragAndDropProviderInternal>
	</DragAndDropContainer>
);
