import type { Effect } from 'redux-saga';
import flow from 'lodash/flow';
import * as R from 'ramda';
import { select, call, all } from 'redux-saga/effects';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { getSectionNamespace } from '@atlassian/jira-portfolio-3-common/src/sections/namespaced-id.tsx';
import type {
	ViewUserInfo,
	SavedViewsInfo,
	View as ViewApi,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { aggressiveFetch } from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { SECTION_KEYS } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getPresetViewNames } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/preset-view-names/index.tsx';
import { updateQueryString } from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import { isReadOnly, isSmartLink } from '../../query/app/index.tsx';
import { getCurrentUser } from '../../query/permissions/index.tsx';
import { getPlan } from '../../query/plan/index.tsx';
import { getViewById, getNamespaceForView } from '../../query/views/index.tsx';
import getPresetPreferences from '../../query/views/presets.tsx';
import type { FieldColumnsState } from '../../state/domain/view-settings/field-columns/types.tsx';
import type { FiltersState } from '../../state/domain/view-settings/filters/types.tsx';
import type { WarningSettingsState } from '../../state/domain/view-settings/warning-settings/types.tsx';
import {
	UNTITLED_VIEW_ID,
	type View,
	type ModifiedView,
	type PredefinedView,
} from '../../state/domain/views/types.tsx';
import {
	UpdateErrorConflicted,
	UpdateErrorNotExists,
	SaveAsErrorDuplicateView,
} from '../../state/ui/top/view-bar/types.tsx';
import { updateProjectColorMap, updateTeamColorMap } from '../colour-by/index.tsx';
import {
	urls,
	createViewBody,
	updateViewBody,
	markAsDefaultViewBody,
	renameViewBody,
	type UpdateResponseBody,
} from './api.tsx';
import { migratePrefs } from './migration/index.tsx';
import { getReportMigratableBlob } from './migration/utils.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	UpdateErrorConflicted,
	UpdateErrorNotExists,
	SaveAsErrorDuplicateView,
	DeleteErrorDefaultView,
} from '../../state/ui/top/view-bar/types';

// eslint-disable-next-line jira/ff/inline-usage
export const isCrossTeamPlanningTemplateExperimentEnabled = () =>
	expValEquals('second_premium_template_experiment', 'cohort', 'variation');

type ViewId = View['id'];
type ViewSettings = View['preferences'];

// this function takes a timestamp integer as parameter and returns a date object
export const convertTimestampToDate = (timestamp?: number | null) => {
	// if the timestamp is defined and is a number
	if (isDefined(timestamp) && R.is(Number, timestamp)) {
		// instantiating a new Date object set to timestamp * 1000 to convert it to milliseconds
		return new Date(timestamp * 1e3);
	}
	return null;
};

// updates project and team colour maps with view preferences
export function* updateColourMapsForView(
	view: View | ModifiedView, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, View | ModifiedView, any> {
	const colourMaps =
		view.preferences && view.preferences.colourByV2 && view.preferences.colourByV2.colourMaps;

	if (R.isNil(colourMaps)) {
		return view;
	}

	const projectColourMap = yield call(
		updateProjectColorMap,
		// the updateProjectColorMap function always expects an object including the "project" key,
		// therefore this check is necessary in order to prevent views not loading in the plan (see JPO-16893)
		colourMaps.project && R.is(Object, colourMaps.project) ? colourMaps : { project: {} },
	);
	const teamColourMap = yield call(
		updateTeamColorMap,
		// the updateTeamColorMap function always expects an object including the "team" key,
		// therefore this check is necessary in order to prevent views not loading in the plan (see JPO-16893)
		colourMaps.team && R.is(Object, colourMaps.team) ? colourMaps : { team: {} },
	);

	return R.mergeDeepRight(view, {
		preferences: {
			colourByV2: { colourMaps: { project: projectColourMap, team: teamColourMap } },
		},
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* getCurrentUserInViewFormat(): Generator<Effect, ViewUserInfo, any> {
	const {
		displayName: title,
		key: jiraUserId,
		name: jiraUsername = '',
		email = '',
		avatarUrl = '',
	} = yield select(getCurrentUser);
	return { title, jiraUserId, jiraUsername, email, avatarUrl };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* getPlanId(): Generator<Effect, number, any> {
	const { id: planId } = yield select(getPlan);

	if (!planId) {
		throw new Error('plan not found');
	}

	return planId;
}

function* remoteFetch(
	key: string,
	errMessage = 'Failed to fetch remoteFetch',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, unknown, any> {
	if (fg('smart_links_for_plans')) {
		if (yield select(isSmartLink)) {
			return;
		}
	}

	// JPO-20477 Checks if the plan ID exists in the state to prevent plan not found error
	// when user loads a new plan before the previous plan finished loading
	const plan = yield select(getPlan);
	if (!isDefined(plan.id)) {
		return undefined;
	}

	const url = urls.userProperties(plan.id, key);
	const { value: result } = yield call(
		aggressiveFetch,
		url,
		{
			method: 'GET',
			profile: urls.userProperties(0, ':prop'),
			json: true,
		},
		errMessage,
	);
	return result;
}

export function* remotePush(
	key: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	value: Record<any, any>,
	errMessage = 'Failed to fetch remotePush',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, unknown, any> {
	if (fg('smart_links_for_plans')) {
		if (yield select(isSmartLink)) {
			return;
		}
	}

	const isKeyExisting = !!(yield call(remoteFetch, key));

	// JPO-20477 Checks if the plan ID exists in the state to prevent plan not found error
	// when user loads a new plan before the previous plan finished loading
	const plan = yield select(getPlan);
	if (!isDefined(plan.id)) {
		return undefined;
	}

	const url = urls.userProperties(plan.id, key);
	return yield call(
		aggressiveFetch,
		url,
		{
			method: isKeyExisting ? 'PUT' : 'POST',
			body: { value },
		},
		errMessage,
	);
}

export function* remoteDelete(
	key: string,
	errMessage = 'Failed to fetch remoteDelete',
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, unknown, any> {
	if (fg('smart_links_for_plans')) {
		if (yield select(isSmartLink)) {
			return;
		}
	}

	const url = urls.userProperties(yield call(getPlanId, 'remoteDelete'), key);
	return yield call(aggressiveFetch, url, { method: 'DELETE' }, errMessage);
}

export type Preferences = {
	fieldColumnsV0: FieldColumnsState;
	listFieldColumnsV0?: FieldColumnsState;
	filtersV1?: FiltersState;
	warningSettingsV1?: WarningSettingsState;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any;
};

/**
 * TODO: https://hello.atlassian.net/browse/JPO-25656
 * Review if we want to keep this util that removes the fallback for inaccessible list field columns cases
 */
const extendFieldColumnsForListView = (preferences: Preferences): Required<Preferences> => {
	const { fieldColumnsV0, listFieldColumnsV0 } = preferences;
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	let updatedPrefs = { ...preferences } as Required<Preferences>;

	// If list field columns are not accessible, we should copy over the data from field columns as a fallback
	if (!listFieldColumnsV0) {
		updatedPrefs = R.assoc('listFieldColumnsV0', fieldColumnsV0, updatedPrefs);
	}

	return updatedPrefs;
};

const customMigrations = flow([extendFieldColumnsForListView]);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* prepareModifiedView(payload: ModifiedView): Generator<Effect, ModifiedView, any> {
	const { id, version, preferences } = payload;
	const migratedPrefs = yield call(migratePrefs, customMigrations(preferences));
	const migratedView: ModifiedView = R.assoc('preferences', migratedPrefs, payload);
	const { preferences: preferencesWithUpdatedColorMaps } = yield call(
		updateColourMapsForView,
		migratedView,
	);

	return {
		id,
		version,
		preferences: preferencesWithUpdatedColorMaps,
	};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* pushActiveViewId(id: ViewId): Generator<Effect, void, any> {
	// pushing the active view id to the db
	yield call(remotePush, 'activeView', { value: { id } }, 'Failed to fetch pushActiveViewId');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* pushModifiedView(view: View): Generator<Effect, void, any> {
	const { id, version, preferences, persisted } = view;
	yield call(
		remotePush,
		`modifiedView-${id}`,
		{
			version,
			preferences: R.mergeRight(persisted.preferences, preferences),
		},
		'Failed to fetch pushModifiedView',
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* deleteModifiedView(id: View['id']): Generator<Effect, void, any> {
	yield call(remoteDelete, `modifiedView-${id}`, 'Failed to fetch deleteModifiedView');
}

export const prepareSavedView = (payload: ViewApi): View => {
	const {
		id,
		version,
		name,
		preferences: persistedPrefs,
		isDefault,
		createdBy,
		lastModifiedBy,
		lastModifiedTimestamp,
	} = payload;
	let updatedPersistedPrefs = persistedPrefs;

	const preferences: View['preferences'] = (() => {
		if (persistedPrefs) {
			updatedPersistedPrefs = customMigrations(persistedPrefs);

			return migratePrefs(updatedPersistedPrefs);
		}

		throw new Error(`preferences not available on the view with id ${id}`);
	})();

	return {
		id,
		version,
		name,
		preferences,
		persisted: {
			preferences: updatedPersistedPrefs || preferences,
		},
		isDefault,
		active: false,
		modified: false,
		original: null,
		createdBy,
		updatedBy: lastModifiedBy,
		// If the predefined view has never been updated, the "lastModifiedTimestamp" will be "undefined".
		// Once the predefined view is updated, the "lastModifiedTimestamp" will always been defined and should be a number (integer).
		// NB: user-defined views always have a lastModifiedTimestamp.
		updatedAt: convertTimestampToDate(lastModifiedTimestamp),
	};
};

type CreateViewParams = Pick<
	View,
	'name' | 'isDefault' | 'preferences' | 'persisted' | 'isPredefined'
>;

export function* createView({
	name,
	isDefault,
	preferences,
	persisted,
	isPredefined = false,
}: CreateViewParams): Generator<
	Effect,
	{
		id: ViewId;
		version: View['version'];
		errorCode: string;
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any
> {
	const url = urls.create(yield call(getPlanId, 'createView'));
	const persistedPrefs = persisted && persisted.preferences;
	const body = createViewBody({
		name,
		isDefault,
		preferences: R.mergeRight(persistedPrefs || {}, preferences),
		isPredefined,
	});

	const { id, version, errorCode } = yield call(
		aggressiveFetch,
		url,
		{
			method: 'POST',
			body,
			json: true,
		},
		'Failed to fetch createView',
	);

	return { id, version, errorCode };
}

type UpdateViewParams = Pick<
	View,
	| 'id'
	| 'name'
	| 'version'
	| 'isDefault'
	| 'preferences'
	| 'original'
	| 'persisted'
	| 'isPredefined'
>;

export function* updateView(
	{
		id,
		name,
		version,
		isDefault,
		preferences,
		original,
		persisted,
		isPredefined,
	}: UpdateViewParams,
	renameOnly?: boolean | null, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, UpdateResponseBody, any> {
	const url = urls.view(yield call(getPlanId, 'updateView'), id);

	// In case of rename, we want to make sure our errors reflect the current view name,
	// not the rename value we're passing in
	const currentView = yield select(getViewById, id);
	const { name: currentName = name } = currentView || {};

	let updatedPreferences = preferences;
	let body;

	if (!renameOnly) {
		if (!isPredefined) {
			if (!original) {
				throw new Error('original must be defined');
			}

			updatedPreferences = R.pipe(
				R.mergeRight(original.persisted.preferences),
				R.mergeRight(persisted.preferences),
			)(preferences);
		}

		body = updateViewBody({
			name,
			version,
			isDefault,
			preferences: updatedPreferences,
			isPredefined,
		});
	} else {
		body = renameViewBody({ name });
	}

	const payload = yield call(
		aggressiveFetch,
		url,
		{ method: 'PUT', body, json: true },
		'Failed to fetch updateView',
	);

	if (payload.updateErrorCode === 'VIEW_NOT_FOUND') {
		throw new UpdateErrorNotExists({ viewId: id, viewName: currentName });
	}

	if (payload.updateErrorCode === 'STALE_VERSION') {
		throw new UpdateErrorConflicted({
			viewId: id,
			viewName: currentName,
			modifiedBy: payload.previouslyModifiedBy,
			modifiedAt: new Date(payload.previouslyModifiedTimestamp * 1e3),
		});
	}

	if (payload.updateErrorCode === 'DUPLICATE_VIEW') {
		throw new SaveAsErrorDuplicateView({ viewId: id, viewName: currentName, newName: name });
	}

	return {
		id: payload.id,
		version: payload.newVersion,
	};
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* createPredefinedView(predefined: PredefinedView): Generator<Effect, View, any> {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const payload = yield call(createView, {
		...predefined,
		isPredefined: true,
	} as Required<CreateViewParams>);

	return {
		...predefined,
		...payload,
	};
}

export function createReadOnlyView(predefined: PredefinedView, id: number, version: number) {
	return {
		...predefined,
		id,
		version,
	};
}

export function* updateUnsavedPredefinedRemoteView(
	view: ViewApi, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, ViewApi | null | undefined, any> {
	try {
		// @ts-expect-error - TS2769 - No overload matches this call.
		yield call(updateView, R.merge(view, { isPredefined: true }));
		return view;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (err: any) {
		// if we didn't successfully update remotely, we don't want to add our view
		// to the list of saved views.
		if (err instanceof UpdateErrorNotExists || err instanceof UpdateErrorConflicted) {
			return null;
		}
		throw err;
	}
}

export function* prepareSavedViews(
	payload: ViewApi[],
	// When cleaning up second_premium_template_experiment, make forCrossTeamPlanning required
	options?: { forSamplePlan: boolean; forCrossTeamPlanning?: boolean }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, View[], any> {
	// Predefined views are saved for the first time from the front end.

	// If a new plan is created, the WRM payload should be empty of predefined views

	// If an existing plan is loaded, the WRM may contain predefined views without preferences
	// which we can identify by the absence of the createdTimestamp

	// If a plan is subsequently loaded, the WRM payload should now contain any predefined views complete with preferences
	const predefinedPreferences = yield select(getPresetPreferences);
	const [payloadUnsavedPredefinedViews, payloadUserCreatedViews] = R.partition(
		(view) =>
			!isDefined(view.createdTimestamp) &&
			!isDefined(view.preferences) &&
			isDefined(predefinedPreferences[view.name]),
		payload,
	);

	const payloadDefault = payload.filter((view) => view.isDefault);

	let savedPredefinedViews;
	if (payloadUnsavedPredefinedViews.length > 0) {
		// we will have unsavedPredefinedViews on plans that existed before we moved
		// the saving of predefined views from the BE to the FE.
		// We want to update these with the corresponding predefined view preferences
		savedPredefinedViews = yield all(
			payloadUnsavedPredefinedViews.map<Effect>((payloadUnsavedPredefinedView) => {
				const viewName = payloadUnsavedPredefinedView.name;

				// update this view with prefs
				return call(updateUnsavedPredefinedRemoteView, {
					...payloadUnsavedPredefinedView,
					preferences: predefinedPreferences[viewName],
				});
			}),
		);
	} else if (payloadUserCreatedViews.length > 0) {
		// plans existing prior to the above mentioned change may not
		// have any unsaved predefined views, but have some user created views
		// (predefined views may have already been saved, or been deleted)
		// in this case, we don't want to create or update any predefined views
		savedPredefinedViews = [];
	} else {
		const isReadOnlyMode = yield select(isReadOnly);

		const presetNames = getPresetViewNames();

		const defaultDefaultViewName =
			options?.forSamplePlan || options?.forCrossTeamPlanning
				? presetNames.SPRINT_CAPACITY_MANAGEMENT
				: presetNames.BASIC;

		// when no unsavedPredefinedViews or userCreatedViews exist, we can assume
		// we're on a newly created plan, and need to generate all of the predefined views
		savedPredefinedViews = yield all(
			Object.keys(predefinedPreferences).map<Effect>((name: View['name'], i) => {
				const newPredefinedView = {
					name,
					preferences: predefinedPreferences[name],
					// if we didn't have a default view in the payload, Basic should be the plan default
					isDefault: payloadDefault.length > 0 ? false : name === defaultDefaultViewName,
				};

				// When in readOnly mode, we don't try to create views in the server since this will result in
				// 401 error because the user only has read-only permission.
				if (isReadOnlyMode) {
					// The current index (i + 1) is used as view ID and version is always 0 which is ok since
					// a read-only user won't be able to change these views.
					// i + 1 because view ID 0 is considered as untitled view (see UNTITLED_VIEW_ID)
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					return call(createReadOnlyView, newPredefinedView as PredefinedView, i + 1, 0);
				}

				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return call(createPredefinedView, newPredefinedView as PredefinedView);
			}),
		);
	}

	const allSavedViews = [
		...payloadUserCreatedViews,
		...savedPredefinedViews.filter((predefinedView: string) => isDefined(predefinedView)),
	].map((view) => prepareSavedView(view));

	// The preference may not include the local project colour maps, so need to add these missed preferences in there
	return yield all(allSavedViews.map((view) => call(updateColourMapsForView, view)));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* fetchSavedViews(): Generator<Effect, View[], any> {
	const url = urls.create(yield call(getPlanId, 'fetchSavedViews'));
	const payload = yield call(
		aggressiveFetch,
		url,
		{ method: 'GET', json: true },
		'Failed to fetch fetchSavedViews',
	);
	return yield call(prepareSavedViews, payload);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* fetchSavedView(id: ViewId): Generator<Effect, View | null | undefined, any> {
	// TODO: Ask backend for GET {item} REST API
	return (yield call(fetchSavedViews)).find((one: { id: ViewId }) => one.id === id);
}

export function* prepareUntitledView(
	modifiedUntitled: ModifiedView, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, View | null | undefined, any> {
	if (!modifiedUntitled) {
		return undefined;
	}
	const currentJiraUser = yield call(getCurrentUserInViewFormat);

	const result: View = {
		id: UNTITLED_VIEW_ID,
		name: 'Untitled',
		version: 0,
		preferences: modifiedUntitled.preferences,
		isDefault: false,
		active: false,
		modified: true,
		original: null,
		persisted: { preferences: {} },
		createdBy: currentJiraUser,
		updatedBy: currentJiraUser,
		updatedAt: new Date(),
	};

	return result;
}

/**
 * Initializing views
 * @param {SavedViewsInfo} payload - The views data from query endpoint.
 * @returns {Saga<View[]>} All views (including saved views and untitled view).
 *
 * The returning list of views contains the latest version of the view only for active view,
 * modified active view will have modified flag set to true and original property set to original data
 *
 * Breakdown:
	- Extract all remote views and modifications (saved ones, untitled one and active view id) from query data
	- if active view is available
		-> return remote views
	- if active view is not available
		- if there is remote untitled view available (in remote views)
			- make it as active view
			-> return remote views
		- otherwise
			- find default view in the remote view
			- if there is no default view, throw an error
			- otherwise
			- make it as active view
			-> return remote views
 */

export function* initializeViews(
	payload: SavedViewsInfo,
	// When cleaning up second_premium_template_experiment, make forCrossTeamPlanning required
	options?: { forSamplePlan: boolean; forCrossTeamPlanning?: boolean }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, View[], any> {
	const { savedViews, modifiedSavedViews, activeViewId: remoteActiveViewId } = payload;
	const remoteSaved = yield call(prepareSavedViews, savedViews, options);
	const untitleViewModifications = modifiedSavedViews.find(({ id }) => id === UNTITLED_VIEW_ID);
	let remoteUntitled;

	const isEmbedSmartLink = fg('smart_links_for_plans') ? yield select(isSmartLink) : false;

	if (isDefined(untitleViewModifications) && (!isEmbedSmartLink || !fg('smart_links_for_plans'))) {
		const modifiedUntitled = yield call(
			prepareModifiedView,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			untitleViewModifications as ModifiedView,
		);
		remoteUntitled = yield call(prepareUntitledView, modifiedUntitled);
	}
	const remoteAll = remoteSaved.concat(remoteUntitled || []);

	const remoteActiveView =
		remoteActiveViewId && remoteAll.find((one: View) => one.id === remoteActiveViewId);

	const [activeViewId, result] = ((): [ViewId, View[]] => {
		if (remoteActiveView) {
			return [remoteActiveView.id, remoteAll];
		}

		if (remoteUntitled) {
			return [UNTITLED_VIEW_ID, remoteAll];
		}

		try {
			return [remoteSaved.find((one: View) => one.isDefault).id, remoteAll];
		} catch {
			throw new Error('default view not found');
		}
	})();

	if (activeViewId !== remoteActiveViewId) {
		yield call(pushActiveViewId, activeViewId);
	}

	// Process the view modification just for an active view
	const activeViewModifications = modifiedSavedViews.find(({ id }) => id === activeViewId);
	let modifiedActiveView: ModifiedView;
	if (isDefined(activeViewModifications) && (!isEmbedSmartLink || !fg('smart_links_for_plans'))) {
		modifiedActiveView = yield call(
			prepareModifiedView,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			activeViewModifications as ModifiedView,
		);
	}

	return result.map((original: View) => {
		const active = original.id === activeViewId;

		if (active && modifiedActiveView && (!isEmbedSmartLink || !fg('smart_links_for_plans'))) {
			const { version, preferences } = modifiedActiveView;
			return R.mergeRight(original, {
				version,
				preferences,
				modified: true,
				persisted: {
					preferences,
				},
				original,
				active,
			});
		}

		return R.merge(original, { active });
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* getReportViewSettings(blob: any): Generator<Effect, ViewSettings, any> {
	const migratableReportBlob = getReportMigratableBlob(blob);
	const reportViewSetting = yield call(migratePrefs, migratableReportBlob);

	return reportViewSetting;
}

export function* deleteView(id: ViewId): Generator<
	Effect,
	{
		errorCode: string;
		// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
	} | null | void,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	any
> {
	const url = urls.view(yield call(getPlanId, 'deleteView'), id);
	return yield call(
		aggressiveFetch,
		url,
		{ method: 'DELETE', json: true },
		'Failed to fetch deleteView',
	);
}

export function* markAsDefaultView(
	{
		id,
		isDefault,
	}: {
		id: ViewId;
		isDefault: View['isDefault'];
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	const url = urls.view(yield call(getPlanId, 'markAsDefaultView'), id);
	const body = markAsDefaultViewBody({ isDefault });
	// this marks the view as default in the backend
	yield call(
		aggressiveFetch,
		url,
		{ method: 'PUT', body, json: true },
		'Failed to fetch markAsDefaultView',
	);
}

export function getURLForView(search: string, activeViewId: number) {
	return updateQueryString(search, { vid: activeViewId });
}

export const replaceURLForViewId = (id: number) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const search = getURLForView(window.location.search, id);

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const url = `${window.location.origin}${window.location.pathname}${search}${window.location.hash}`;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.history.replaceState({}, window.title, url);
};

/** Removes &isSample=true from the URL. */
export const replaceURLForSamplePlan = () => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const search = updateQueryString(window.location.search, { isSample: undefined });

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const url = `${window.location.origin}${window.location.pathname}${search}${window.location.hash}`;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	window.history.replaceState({}, window.title, url);
};

export function duplicateSectionState({
	sourceViewId,
	targetViewId,
	duplicateSection,
}: {
	sourceViewId: number;
	targetViewId: number;
	duplicateSection: (sourceSectionId: string, targetSectionId: string) => void;
}) {
	duplicateSection(
		getSectionNamespace(getNamespaceForView(sourceViewId), SECTION_KEYS.FIELD),
		getSectionNamespace(getNamespaceForView(targetViewId), SECTION_KEYS.FIELD),
	);
	duplicateSection(
		getSectionNamespace(getNamespaceForView(sourceViewId), SECTION_KEYS.MAIN),
		getSectionNamespace(getNamespaceForView(targetViewId), SECTION_KEYS.MAIN),
	);
	duplicateSection(
		getSectionNamespace(getNamespaceForView(sourceViewId), SECTION_KEYS.LIST_FIELD),
		getSectionNamespace(getNamespaceForView(targetViewId), SECTION_KEYS.LIST_FIELD),
	);
	duplicateSection(
		getSectionNamespace(getNamespaceForView(sourceViewId), SECTION_KEYS.LIST_MAIN),
		getSectionNamespace(getNamespaceForView(targetViewId), SECTION_KEYS.LIST_MAIN),
	);
}
