import {
	submit as submitRequiredFields,
	cancel as cancelRequiredFields,
	fail as failRequiredFields,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/update-jira/required-fields/actions.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	handleSubmit: (id: string) => dispatch(submitRequiredFields({ generatedItemId: id })),
	handleCancel: () => dispatch(cancelRequiredFields()),
	handleCancelAll: () => dispatch(cancelRequiredFields()),
	handleFailure: () => dispatch(failRequiredFields()),
});

export default mapDispatchToProps;
