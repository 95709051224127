import * as R from 'ramda';
import {
	SET_SAVE_VIEW_AS_DIALOG_STATUS,
	ADD_ERROR,
	REMOVE_ERROR,
	type SetSaveViewAsDialogStatusAction,
	type AddErrorAction,
	type RemoveErrorAction,
} from './actions.tsx';
import type { State } from './types.tsx';

type Action = AddErrorAction | RemoveErrorAction | SetSaveViewAsDialogStatusAction;
const initialState: State = { errors: [], saveViewAsDialogStatus: 'CLOSED' };

export default function reducer(state: State = initialState, action: Action): State {
	switch (action.type) {
		case ADD_ERROR:
			// @ts-expect-error - TS2349 - This expression is not callable. | TS2344 - Type 'State' does not satisfy the constraint 'Evolver<any>'.
			return R.evolve<State>({
				errors: R.append(action.payload),
			})(state);

		case REMOVE_ERROR:
			// @ts-expect-error - TS2349 - This expression is not callable. | TS2344 - Type 'State' does not satisfy the constraint 'Evolver<any>'.
			return R.evolve<State>({
				errors: R.filter((err) => !R.equals(err, action.payload)),
			})(state);

		case SET_SAVE_VIEW_AS_DIALOG_STATUS:
			return {
				...state,
				saveViewAsDialogStatus: action.payload,
			};

		default:
			return state;
	}
}
