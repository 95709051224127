import React, { Component } from 'react';
import noop from 'lodash/noop';
import {
	ONE_DAY,
	endOfUtcDay,
	startOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { DragHandler } from '@atlassian/jira-portfolio-3-common/src/drag-observer/types.tsx';
import HoverObserver from '@atlassian/jira-portfolio-3-common/src/hover-observer/index.tsx';
import { ISSUE_INFERRED_DATE_SELECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { ISSUE_LINK_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getPositionsForBar } from '../bar/utils.tsx';
import Bar from '../bar/view.tsx';
import DateField from '../date-field/index.tsx';
import DateTooltip from '../date-tooltips/index.tsx';
import DependencyMarker from '../dependency-marker/index.tsx';
import IssueLength from '../issue-length/index.tsx';
import Overflowed from '../overflowed/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props, State } from './types.tsx';

const { ROLL_UP } = ISSUE_INFERRED_DATE_SELECTION;

const snapWithRound = (val: number, snap: number) => Math.round(val / snap) * snap;

// eslint-disable-next-line jira/react/no-class-components
export default class BothDatesRow extends Component<Props, State> {
	static defaultProps = {
		DependencyMarker,
		Bar,
		isReadOnly: false,
		showStripesInBar: { left: false, right: false },
		isBeingInteractedWith: false,
		commitTimelinePreview: noop,
		moveTimelineIssue: noop,
		resizeTimelineIssue: noop,
		disableOtherRows: noop,
		reEnableOtherRows: noop,
		renderFlyout: noop,
	};

	state = {
		hovered: false,
	};

	render() {
		const {
			issue,
			baselineStart,
			baselineEnd,
			forcedGradients,
			previewForcedGradients,
			timelineRange,
			barColor,
			barColors,
			showStripesInBar,
			preview,
			hasDependencyLineDragPreview,
			renderFlyout,
			isReadOnly,
			isBeingInteractedWith,
			externalIncomingLinks,
			externalOutgoingLinks,
			internalIncomingLinks,
			internalIncomingLinksOriginal,
			internalOutgoingLinks,
			internalOutgoingLinksOriginal,
			width,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			DependencyMarker,
			// eslint-disable-next-line @typescript-eslint/no-shadow
			Bar,
			opacity,
			sprints,
			showActiveSprintOnTooltip,
			datesInferredFrom,
			topOffset,
		} = this.props;
		const { hovered } = this.state;

		if (!isDefined(baselineStart) || !isDefined(baselineEnd)) {
			throw Error('Issue must have both target dates specified to use this component');
		}

		const barPositions = getPositionsForBar(
			{ baselineStart, baselineEnd },
			timelineRange,
			forcedGradients,
		);

		const isInferredStart = datesInferredFrom && datesInferredFrom.baselineStart === ROLL_UP;
		const isInferredEnd = datesInferredFrom && datesInferredFrom.baselineEnd === ROLL_UP;
		const isFadingStart = barPositions.leftAnchorPercentage !== 0;
		const isFadingEnd = barPositions.rightAnchorPercentage !== 100;
		// We don't want to drag any side is fading (same as non-rolledup)
		const canDragMove = !isFadingStart && !isFadingEnd;

		const effectiveBaselineStart = barPositions.leftAnchorPercentage === 0 ? baselineStart : null;
		const startDate =
			preview && isDefined(preview.baselineStart) ? preview.baselineStart : effectiveBaselineStart;
		const effectiveBaselineEnd = barPositions.rightAnchorPercentage === 100 ? baselineEnd : null;
		const endDate =
			preview && isDefined(preview.baselineEnd) ? preview.baselineEnd : effectiveBaselineEnd;

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const barAdditionalStyles: Record<string, any> = {};
		if (!isReadOnly && canDragMove) {
			barAdditionalStyles.cursor = 'move';
		}

		let barDragHandler: DragHandler | undefined;
		let leftHandleDragHandler: DragHandler | undefined;
		let rightHandleDragHandler: DragHandler | undefined;

		if (!isReadOnly) {
			const baseDragHandler = {
				onDragStart: () => {
					this.props.disableOtherRows(issue.id);
				},
				onDragEnd: () => {
					this.props.commitTimelinePreview();
					this.props.reEnableOtherRows();
				},
			};

			// Drag start
			leftHandleDragHandler = {
				...baseDragHandler,
				onDrag: (_, to, start) => {
					if (!baselineStart || !baselineEnd) {
						throw Error('baseline dates must be set'); // Flow check
					}
					const timelineWidth = timelineRange.end - timelineRange.start;
					const deltaRatio = (to.x - start.x) / width;
					const delta = snapWithRound(deltaRatio * timelineWidth, ONE_DAY);
					const newBaselineStart = startOfUtcDay(Math.min(baselineStart + delta, baselineEnd || 0));
					this.props.resizeTimelineIssue(
						newBaselineStart,
						isFadingEnd || isInferredEnd === true ? undefined : baselineEnd,
					);
				},
			};

			// Drag end
			rightHandleDragHandler = {
				...baseDragHandler,
				onDrag: (_, to, start) => {
					if (!baselineStart || !baselineEnd) {
						throw Error('baseline dates must be set'); // Flow check
					}
					const timelineWidth = timelineRange.end - timelineRange.start;
					const deltaRatio = (to.x - start.x) / width;
					const delta = snapWithRound(deltaRatio * timelineWidth, ONE_DAY);
					const newBaselineEnd = endOfUtcDay(Math.max(baselineEnd + delta, baselineStart || 0));
					this.props.resizeTimelineIssue(
						isFadingStart || isInferredStart === true ? undefined : baselineStart,
						newBaselineEnd,
					);
				},
			};

			if (canDragMove) {
				barDragHandler = {
					...baseDragHandler,
					onDrag: (from, to, start) => {
						const timelineWidth = timelineRange.end - timelineRange.start;
						const deltaRatio = (to.x - start.x) / width;
						const delta = snapWithRound(deltaRatio * timelineWidth, ONE_DAY);
						if (isInferredStart === isInferredEnd) {
							this.props.moveTimelineIssue(delta, baselineStart, baselineEnd);
						} else if (isInferredStart === true) {
							// @ts-expect-error - TS7005 - Variable 'rightHandleDragHandler' implicitly has an 'any' type.
							rightHandleDragHandler.onDrag(from, to, start);
						} else if (isInferredEnd === true) {
							// @ts-expect-error - TS7005 - Variable 'leftHandleDragHandler' implicitly has an 'any' type.
							leftHandleDragHandler.onDrag(from, to, start);
						}
					},
				};
			}
		}

		const bar = (
			<Bar
				{...barPositions}
				color={barColor}
				colors={barColors}
				showStripes={showStripesInBar}
				opacity={opacity}
				additionalStyles={barAdditionalStyles}
				showHandles={!isReadOnly && hovered && !isBeingInteractedWith}
				barDragHandler={barDragHandler}
				leftHandleDragHandler={leftHandleDragHandler}
				rightHandleDragHandler={rightHandleDragHandler}
				renderFlyout={renderFlyout}
				id={issue.id}
				topOffset={topOffset}
			/>
		);

		let previewBar;
		let previewPositions;
		if (preview) {
			previewPositions = getPositionsForBar(preview, timelineRange, previewForcedGradients);
			previewBar = (
				<Bar
					{...previewPositions}
					showStripes={showStripesInBar}
					color={barColor}
					colors={barColors}
					opacity={0.8}
					id={issue.id}
					topOffset={topOffset}
				/>
			);
		}

		const datePosition = previewPositions || barPositions;

		let startDateField;
		let endDateField;
		let numDays;
		let dates;

		const incomingDependencies = !hasDependencyLineDragPreview && (
			<DependencyMarker
				externalIncomingLinks={externalIncomingLinks}
				internalIncomingLinks={internalIncomingLinks}
				originalIssueLinks={internalIncomingLinksOriginal}
				startOrEnd="start"
				direction={ISSUE_LINK_DIRECTION.INWARD}
				datePosition={datePosition}
				width={width}
				issue={issue}
				isHovered={hovered}
				barColor={barColor}
				barColors={barColors}
			/>
		);

		const outgoingDependencies = !hasDependencyLineDragPreview && (
			<DependencyMarker
				externalOutgoingLinks={externalOutgoingLinks}
				internalOutgoingLinks={internalOutgoingLinks}
				originalIssueLinks={internalOutgoingLinksOriginal}
				startOrEnd="end"
				direction={ISSUE_LINK_DIRECTION.OUTWARD}
				datePosition={datePosition}
				width={width}
				issue={issue}
				isHovered={hovered}
				barColor={barColor}
				barColors={barColors}
			/>
		);

		if ((hovered || isBeingInteractedWith) && !hasDependencyLineDragPreview) {
			const completedSprints = issue.completedSprints || [];
			const activeSprint = sprints.find((sprint) => sprint.id === issue.sprint);

			startDateField = (
				<DateField startOrEnd="start" date={startDate} isRolledUpValue={isInferredStart} />
			);

			endDateField = <DateField startOrEnd="end" date={endDate} isRolledUpValue={isInferredEnd} />;

			numDays = (
				<IssueLength
					startDate={startDate}
					endDate={endDate}
					activeSprint={activeSprint}
					completedSprintCount={completedSprints.length}
					showActiveSprintOnTooltip={showActiveSprintOnTooltip}
				/>
			);

			dates = (
				<DateTooltip datePosition={datePosition} width={width}>
					{startDateField}
					{numDays}
					{endDateField}
				</DateTooltip>
			);
		}

		return (
			// eslint-disable-next-line @typescript-eslint/no-shadow
			<HoverObserver onHoverChanged={(hovered) => this.setState({ hovered })}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.spaBothDatesRow}>
					{bar}
					{previewBar}
					{incomingDependencies}
					{outgoingDependencies}
					{dates}
					<Overflowed />
				</div>
			</HoverObserver>
		);
	}
}
