import React from 'react';
import { xcss } from '@atlaskit/primitives';
import { Container } from '@atlassian/jira-portfolio-3-treegrid/src/ui/container/index.tsx';
import { useRouter } from '@atlassian/react-resource-router';

export const EnhancedContainer: typeof Container = ({ xcss: xcssStyles, ...restProps }) => {
	const [
		{
			match: { params },
		},
	] = useRouter();
	const { planId, scenarioId } = params;

	return (
		<Container
			scope={`${planId}-${scenarioId}`}
			xcss={[containerStyles, ...(Array.isArray(xcssStyles) ? xcssStyles : [xcssStyles])]}
			{...restProps}
		/>
	);
};

const containerStyles = xcss({
	position: 'absolute',
	inset: 'space.0',
	// @ts-expect-error - TS2322: Type number is not assignable to type
	zIndex: 0,
});
