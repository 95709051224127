import React from 'react';
import * as R from 'ramda';
import Avatar from '@atlaskit/avatar';
import { useIntl } from '@atlassian/jira-intl';
import SelectWireframe from '@atlassian/jira-portfolio-3-common/src/select-wireframe/index.tsx';
import {
	withSlots,
	slots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export const makeHandleUserChange =
	({
		issue,
		attribute,
		onChange,
	}: {
		issue: Props['issue'];
		attribute: Props['attribute'];
		onChange: Props['onChange'];
	}) =>
	(userId?: string | null) => {
		onChange(issue, attribute, userId);
	};

export const getSelectedUser = ({
	attribute,
	issue,
	userListById,
}: {
	issue: Props['issue'];
	attribute: Props['attribute'];
	userListById: Props['userListById'];
}) => {
	const userPickerValue = R.path(['customFields', attribute], issue);
	// @ts-expect-error - TS2538 - Type 'unknown' cannot be used as an index type.
	if (userPickerValue && userListById[userPickerValue]) {
		// @ts-expect-error - TS2538 - Type 'unknown' cannot be used as an index type.
		return userListById[userPickerValue];
	}
	return null;
};

export const UserPickerRenderer = ({
	attribute,
	issue,
	userListById,
	isScrolling,
	isReadOnly = false,
	userList = [],
	UserPicker,
	userPickerUIState,
	onChange,
	fetchUserList,
}: Props) => {
	const { formatMessage } = useIntl();
	const selectedUser = getSelectedUser({ attribute, issue, userListById });
	const handleUserChange = makeHandleUserChange({ issue, attribute, onChange });

	const onFetchUserList = (query: string | null = '') => {
		fetchUserList(query, issue.id);
	};

	if (isScrolling) {
		return (
			<SelectWireframe
				content={
					selectedUser ? (
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						<div className={styles.wireframeWrapper}>
							<Avatar size="xsmall" src={selectedUser.icon} />
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<span className={styles.wireframeTitle}>{selectedUser.label}</span>
						</div>
					) : (
						''
					)
				}
				isReadOnly={isReadOnly}
			/>
		);
	}

	const { issueId, isFetchUserListInProgress } = userPickerUIState;
	return (
		<UserPicker
			attribute={attribute}
			issueId={issue.id}
			onFetchUserList={onFetchUserList}
			onUserChange={handleUserChange}
			placeholder={formatMessage(messages.placeholder)}
			selectProps={{
				isClearable: true,
				isDisabled: isReadOnly,
				isLoading: isDefined(issueId) && issue.id === issueId && isFetchUserListInProgress,
			}}
			user={selectedUser}
			userList={userList}
		/>
	);
};

// For Unit testing purposes temporarily
export const UserPickerRendererWithSlots = withSlots({ UserPicker: slots.UserPicker })(
	UserPickerRenderer,
);

export default withSlots({ UserPicker: slots.UserPicker })(UserPickerRenderer);
