import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	teamNotFoundHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.teams.team-not-found-header',
		defaultMessage: 'This team no longer exists in the plan',
		description: 'Message to show in the header when team commit fails',
	},
	teamNotFoundContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.teams.team-not-found-content',
		defaultMessage: 'Refresh your plan to reflect this change',
		description: 'Message to show in the content when team commit fails',
	},
	memberNotFoundHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.teams.member-not-found-header',
		defaultMessage: 'Member {personTitle} could not be found',
		description: 'Message to show in the header when member commit fails',
	},
	memberNotFoundContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.teams.member-not-found-content',
		defaultMessage: 'Refresh to update your plan',
		description: 'Message to show in the content when member commit fails',
	},
	memberOrTeamNotFoundHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.teams.member-or-team-not-found-header',
		defaultMessage: 'Member {personTitle} or team could not be found',
		description: 'Message to show in the header when team commit fails when updating a member',
	},
	memberOrTeamNotFoundContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.teams.member-or-team-not-found-content',
		defaultMessage: 'Refresh to update your plan',
		description: 'Message to show in the content when team commit fails when updating a member',
	},
	defaultWarningHeader: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.teams.default-warning-header',
		defaultMessage: 'Could not be committed for unknown reasons.',
		description: 'Message to show in the header for unknown commit failures',
	},
	defaultWarningContent: {
		id: 'portfolio-3-portfolio.app-simple-plans.command.teams.default-warning-content',
		defaultMessage:
			'Team could not be committed for unknown reasons. Please contact your administrator.',
		description: 'Message to show in the content for unknown commit failures',
	},
});
