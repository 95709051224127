.sprintLabels {
	height: 40px;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	display: flex;
}

.message {
	color: var(--ds-text-subtlest, var(--adg3-color-subtleText-dark));
	padding: var(--jpo-common-base-spacing);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
