import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function ExportUserPicker({ name, size = 24 }: Props) {
	if (!name) return null;

	const names = name.split(' ');

	if (names.length === 0) {
		return null;
	}

	const displayText = `${names[0][0]}${names.length > 1 ? names[names.length - 1][0] : ''}`;

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.wrapper} style={{ width: size, height: size }}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.initials} style={{ fontSize: size * 0.525 }}>
				{displayText}
			</div>
		</div>
	);
}
