.container {
	display: flex;
	justify-content: flex-end;
}

.label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.label::first-letter {
	text-transform: capitalize;
}

.avatar {
	margin-left: 5px;
}
