import type {
	Permission,
	SetCurrentUserActionPayload,
	PermissionActionPayload,
	SetTeamPermissionsActionPayload,
} from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Permission } from './types';

export const SET_PERMISSIONS = 'state.domain.permissions.SET_PERMISSIONS' as const;

export const TOGGLE_FETCH_PLAN_PERMISSIONS_IN_PROGRESS =
	'state.domain.permissions.TOGGLE_FETCH_PLAN_PERMISSIONS_IN_PROGRESS' as const;

export const ADD_PERMISSION = 'state.domain.permissions.ADD_PERMISSION' as const;

export const REMOVE_PERMISSION = 'state.domain.permissions.REMOVE_PERMISSION' as const;

export const SET_CURRENT_USER = 'state.domain.permissions.SET_CURRENT_USER' as const;

export const SET_TEAM_PERMISSIONS = 'state.domain.teams.SET_TEAM_PERMISSIONS' as const;

export type SetPermissionsAction = {
	type: typeof SET_PERMISSIONS;
	payload: Permission[];
};

export type ToggleFetchInProggressAction = {
	type: typeof TOGGLE_FETCH_PLAN_PERMISSIONS_IN_PROGRESS;
	payload: boolean;
};

export type AddPermissionAction = {
	type: typeof ADD_PERMISSION;
	payload: PermissionActionPayload;
};

export type RemovePermissionAction = {
	type: typeof REMOVE_PERMISSION;
	payload: PermissionActionPayload;
};

export type SetCurrentUserAction = {
	type: typeof SET_CURRENT_USER;
	payload: SetCurrentUserActionPayload;
};

export type SetTeamPermissionsAction = {
	type: typeof SET_TEAM_PERMISSIONS;
	payload: SetTeamPermissionsActionPayload;
};

export const setPermissions = (payload: Permission[]): SetPermissionsAction => ({
	type: SET_PERMISSIONS,
	payload,
});

export const toggleFetchInProggress = (payload: boolean): ToggleFetchInProggressAction => ({
	type: TOGGLE_FETCH_PLAN_PERMISSIONS_IN_PROGRESS,
	payload,
});

export const addPermission = (payload: PermissionActionPayload): AddPermissionAction => ({
	type: ADD_PERMISSION,
	payload,
});

export const removePermission = (payload: PermissionActionPayload): RemovePermissionAction => ({
	type: REMOVE_PERMISSION,
	payload,
});

export const setCurrentUser = (payload: SetCurrentUserActionPayload): SetCurrentUserAction => ({
	type: SET_CURRENT_USER,
	payload,
});

export const setTeamPermissions = (payload: SetTeamPermissionsActionPayload) => ({
	type: SET_TEAM_PERMISSIONS,
	payload,
});
