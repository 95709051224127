import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import AddDependency from './add-dependency/reducer.tsx';
import Filters from './filters/reducer.tsx';
import ReportState from './report-state/reducer.tsx';
import StagePosAndScale from './stage-position-scale/reducer.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	AddDependency,
	Filters,
	ReportState,
	StagePosAndScale,
});
