/**
 * @generated SignedSource<<5f843c568cd094fe015fc62ccd215aa2>>
 * @relayHash bade98391454175f9805ddf17d406605
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fda43bbb082e0ed3c0d8f3aa102808422464ab3ae968c9859862796e65b5f452

import type { ConcreteRequest, Query } from 'relay-runtime';
export type TownsquareGoalStateValue = "archived" | "at_risk" | "cancelled" | "done" | "off_track" | "on_track" | "paused" | "pending" | "%future added value";
export type fetchAtlasGoalsQuery$variables = {
  goalARIs: ReadonlyArray<string>;
};
export type fetchAtlasGoalsQuery$data = {
  readonly townsquare: {
    readonly goalsByAri: ReadonlyArray<{
      readonly id: string;
      readonly key: string;
      readonly name: string;
      readonly state: {
        readonly label: string;
        readonly score: number | null | undefined;
        readonly value: TownsquareGoalStateValue;
      };
      readonly url: string;
    } | null | undefined> | null | undefined;
  };
};
export type fetchAtlasGoalsQuery = {
  response: fetchAtlasGoalsQuery$data;
  variables: fetchAtlasGoalsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "goalARIs"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "aris",
    "variableName": "goalARIs"
  }
],
v2 = {
  "kind": "ScalarField",
  "name": "key"
},
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "ScalarField",
  "name": "url"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "label"
},
v7 = {
  "kind": "ScalarField",
  "name": "score"
},
v8 = {
  "kind": "ScalarField",
  "name": "value"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "fetchAtlasGoalsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "TownsquareQueryApi",
          "kind": "LinkedField",
          "name": "townsquare",
          "plural": false,
          "selections": [
            {
              "args": (v1/*: any*/),
              "concreteType": "TownsquareGoal",
              "kind": "LinkedField",
              "name": "goalsByAri",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "kind": "RequiredField",
                  "field": (v4/*: any*/),
                  "action": "THROW",
                  "path": "townsquare.goalsByAri.url"
                },
                (v5/*: any*/),
                {
                  "kind": "RequiredField",
                  "field": {
                    "concreteType": "TownsquareGoalState",
                    "kind": "LinkedField",
                    "name": "state",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": (v6/*: any*/),
                        "action": "THROW",
                        "path": "townsquare.goalsByAri.state.label"
                      },
                      (v7/*: any*/),
                      {
                        "kind": "RequiredField",
                        "field": (v8/*: any*/),
                        "action": "THROW",
                        "path": "townsquare.goalsByAri.state.value"
                      }
                    ]
                  },
                  "action": "THROW",
                  "path": "townsquare.goalsByAri.state"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "townsquare"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchAtlasGoalsQuery",
    "selections": [
      {
        "concreteType": "TownsquareQueryApi",
        "kind": "LinkedField",
        "name": "townsquare",
        "plural": false,
        "selections": [
          {
            "args": (v1/*: any*/),
            "concreteType": "TownsquareGoal",
            "kind": "LinkedField",
            "name": "goalsByAri",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "concreteType": "TownsquareGoalState",
                "kind": "LinkedField",
                "name": "state",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "fda43bbb082e0ed3c0d8f3aa102808422464ab3ae968c9859862796e65b5f452",
    "metadata": {},
    "name": "fetchAtlasGoalsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "fad199abf7afe37c2713cb9a5c0b89ed";

export default node;
