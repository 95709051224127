import { getOutgoingLinks } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/index.tsx';
import { getDependencySettingsInfo } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import { getTableItems } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { StateProps } from './types.tsx';

export const mapStateToProps = createStructuredSelector<State, StateProps>({
	items: getTableItems,
	outgoingLinks: getOutgoingLinks,
	dependencySettings: getDependencySettingsInfo,
});
