import {
	OPEN_TEAMS_DIALOG,
	CLOSE_TEAMS_DIALOG,
	INITIATE_REQUEST,
	RESET_REQUEST,
	SET_MODE,
	SHOW_SHARED_TEAM_SUCCESS_FLAG,
	type SetModeAction,
	type InitiateRequestAction,
	type ResetRequestAction,
	type OpenTeamsDialogAction,
	type CloseTeamsDialogAction,
	type ShowSharedTeamSuccessFlagAction,
} from './actions.tsx';
import type { TeamsList } from './types.tsx';

const initialState = {
	isDialogOpen: false,
	isProcessingRequest: false,
	showSharedTeamSuccessFlag: false,
} as const;

type Action =
	| OpenTeamsDialogAction
	| CloseTeamsDialogAction
	| InitiateRequestAction
	| SetModeAction
	| ShowSharedTeamSuccessFlagAction
	| ResetRequestAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: TeamsList = initialState, action: Action): TeamsList => {
	switch (action.type) {
		case OPEN_TEAMS_DIALOG: {
			return {
				...state,
				...action.payload,
				isDialogOpen: true,
			};
		}
		case CLOSE_TEAMS_DIALOG: {
			return {
				...state,
				isDialogOpen: false,
			};
		}
		case INITIATE_REQUEST:
			return {
				...state,
				isProcessingRequest: true,
			};
		case RESET_REQUEST:
			return {
				...state,
				isProcessingRequest: false,
			};
		case SET_MODE:
			return {
				...state,
				dialogMode: action.payload,
			};
		case SHOW_SHARED_TEAM_SUCCESS_FLAG:
			return {
				...state,
				showSharedTeamSuccessFlag: action.payload.showSharedTeamSuccessFlag,
			};
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
