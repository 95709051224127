import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	removeDependency: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.dependencies.flyout.cells.remove.remove-dependency',
		defaultMessage: 'Remove dependency',
		description:
			'A tooltip displayed for a button to indicate that clicking the button will remove the associated dependency',
	},
});
