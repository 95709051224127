import {
	isConfluenceMacro,
	isEmbed,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getCurrentScenarioColor } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import { getViewMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { StateProps } from './types.tsx';

const mapStateToProps: MapStateToProps<StateProps> = (state) => ({
	currentScenarioColor: getCurrentScenarioColor(state),
	viewMode: getViewMode(state),
	isConfluenceMacro: isConfluenceMacro(state),
	isEmbed: isEmbed(state),
});

export default mapStateToProps;
