import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'portfolio-3-onboarding.ctp-onboarding.teams-spotlight.heading',
		defaultMessage: 'Change teams',
		description: 'Heading for the cross team planning template teams spotlight card.',
	},
	bodyFirst: {
		id: 'portfolio-3-onboarding.ctp-onboarding.teams-spotlight.body-first',
		defaultMessage: 'By default, this plan includes two new teams.',
		description:
			'First line of the body for the cross team planning template teams spotlight card.',
	},
	bodySecond: {
		id: 'portfolio-3-onboarding.ctp-onboarding.teams-spotlight.body-second',
		defaultMessage:
			'Use the <b>Teams</b> tab to remove or add an existing team from your organization.',
		description:
			'Second line of the body for the cross team planning template teams spotlight card.',
	},
	next: {
		id: 'portfolio-3-onboarding.ctp-onboarding.teams-spotlight.next',
		defaultMessage: 'Next',
		description: 'Button copy for the cross team planning template teams spotlight card.',
	},
});
