import React from 'react';
import SelectWireframe from '@atlassian/jira-portfolio-3-common/src/select-wireframe/index.tsx';
import { getSprintName } from '../utils.tsx';
import type { Props } from './types.tsx';

const SprintWireframe = (props: Props) => {
	const {
		issue: { optimized, sprint },
		showOptimizations,
		sprints,
	} = props;
	const sprintId =
		showOptimizations === true && optimized && optimized.sprint ? optimized.sprint : sprint;
	return <SelectWireframe content={getSprintName(sprintId, sprints)} />;
};

export default SprintWireframe;
