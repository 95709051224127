.email-link {
	& a {
		color: var(--ds-text, var(--adg3-color-N0));
		text-decoration: underline;
	}
}

.error-log {
	max-width: 100%;
	overflow-x: auto;
}
