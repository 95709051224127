.marker {
	position: relative;
	display: flex;
	flex-flow: column;
	align-content: center;
	align-items: center;
	cursor: pointer;
}

.icon {
	height: 24px;

	& button {
		height: 24px;
		width: 24px;
	}
}

.number {
	bottom: 6px;
	color: var(--ds-surface, var(--adg3-color-N0));
	font-size: 8px;
	font-weight: 700;
	pointer-events: none;
	position: absolute;
}

.timeline-highlight {
	position: absolute;
	left: calc(50% - 1px);
	top: 50%;
	height: 70%;
	border: 1px solid;
	z-index: -1;
	&.released {
		border-color: var(--ds-icon-subtle, var(--adg3-color-N70)); /* Same color as the marker */
	}
	&.releasedSpa {
		border-color: var(--ds-border-success, var(--adg3-color-G400));
	}

	&.unreleased,
	&.onTrack {
		border-color: var(--ds-border-success, var(--adg3-color-G300));
	}
	&.unreleasedSpa,
	&.onTrackSpa {
		border-color: var(--ds-border-information, var(--adg3-color-B400));
	}
	&.offTrack {
		border-color: var(--ds-border-danger, var(--adg3-color-R300));
	}
	&.offTrackSpa {
		border-color: var(--ds-border-danger, var(--adg3-color-R400));
	}
}

.releaseTooltip {
	align-items: center;
	display: flex;
	gap: 4px;
}

.releaseTooltipName {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.releaseTooltipDate {
	/* Unfortunately, there's no --ds-text colour that goes well here */
	color: var(--ds-background-accent-gray-subtler, var(--adg3-color-N50));
}

.button {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
