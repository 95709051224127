import React from 'react';
import classNames from 'classnames';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

type Props = {
	left: number | string;
	noMarker?: boolean;
	width: number | string;
	isHighlighted?: boolean;
};

const InfiniteAxisUnit = ({ left, width, isHighlighted }: Props) => (
	<div
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={classNames(styles.unit, { [styles.highlighted]: isHighlighted })}
		style={{
			left,
			width,
		}}
	/>
);

export default InfiniteAxisUnit;
