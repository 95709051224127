import { getIssueTypesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import { getProjectsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { StateProps, OwnProps } from './types.tsx';

export const getIssueTypeNames = (
	customField: CustomField,
	issueTypesMapById: Record<number, IssueType>,
): string =>
	customField.issueTypeIds
		? customField.issueTypeIds
				.map((id) => issueTypesMapById[id]?.name)
				.filter((name) => isDefined(name))
				.join(', ')
				.replace(/,([^,]*)$/, ' &$1')
		: '';

export const getProjectName = (
	customField: CustomField,
	projectMapById: Record<number, Project>,
): string => {
	if (customField.projectId && projectMapById[customField.projectId]) {
		return projectMapById[customField.projectId].name;
	}
	return '';
};

const mapStateToProps: MapStateToProps<StateProps, OwnProps> = (state, { customField }) => ({
	projectName: getProjectName(customField, getProjectsById(state)),
	issueTypeNames: getIssueTypeNames(customField, getIssueTypesById(state)),
});

export default mapStateToProps;
