export const ALL_OTHER_ISSUES = 'all_other_issues' as const;

export type ComponentGroupV0 = {
	id: string;
	name: string;
	components: number[];
	isExpanded: boolean;
};
export type ComponentGroup = ComponentGroupV0;

export type ComponentGroupsStateV0 = ComponentGroupV0[];
export type ComponentGroupsState = ComponentGroupsStateV0;
