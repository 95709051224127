import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { CustomFieldTypes } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import Label from './label/index.tsx';
import MultiSelect from './multi-select/index.tsx';
import SingleSelect from './single-select/index.tsx';
import type { Props } from './types.tsx';

export default function CustomFieldFilter({
	field,
	parentScrollContainer,
	...filterItemHocProps
}: Props) {
	const fieldKey = field.type && field.type.key;
	switch (fieldKey) {
		case CustomFieldTypes.Labels:
			return (
				<Label
					customField={field}
					parentScrollContainer={parentScrollContainer}
					{...filterItemHocProps}
				/>
			);
		case CustomFieldTypes.AssetObject:
		case CustomFieldTypes.MultiSelect:
			if (!fg('asset-custom-field-internal-support')) {
				if (fieldKey === CustomFieldTypes.AssetObject) {
					return null;
				}
			}

			return (
				<MultiSelect
					customField={field}
					parentScrollContainer={parentScrollContainer}
					{...filterItemHocProps}
				/>
			);
		case CustomFieldTypes.MultiCheckboxes:
		case CustomFieldTypes.RadioButtons:
		case CustomFieldTypes.SingleSelect:
			return (
				<SingleSelect
					customField={field}
					parentScrollContainer={parentScrollContainer}
					{...filterItemHocProps}
				/>
			);
		default:
			return null;
	}
}
