import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	componentsLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.custom-field.renderers.multi-select.components-label',
		defaultMessage: 'Choose an\xa0option', // Non breaking space (\xa0) else the cell splits into 3 rows making it illegible
		description: 'Label for unselected multi select cell',
	},
	noLabels: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.custom-field.renderers.multi-select.no-labels',
		defaultMessage: 'There are no options for this custom field',
		description: 'Message to show when there is no labels configured',
	},
	noLabelsLeft: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.custom-field.renderers.multi-select.no-labels-left',
		defaultMessage: 'No available options left',
		description: 'Message to show when  all labels are already used up',
	},
	noMatch: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.custom-field.renderers.multi-select.no-match',
		defaultMessage: 'No results match your search',
		description: 'Message to show when there is no labels matching search query',
	},
});
