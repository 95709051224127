import type { SchedulingMode } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { TeamKey } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import type { PlanningUnit } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';

export const urls = {
	addPlannedCapacity: '/rest/jpo/1.0/plannedcapacity/add',
	updatePlannedCapacity: '/rest/jpo/1.0/plannedcapacity/update',
	deletePlannedCapacity: '/rest/jpo/1.0/plannedcapacity/delete',
	revertPlannedCapacity: '/rest/jpo/1.0/plannedcapacity/revert',
} as const;

export type UpdatePlannedCapacityPayload = {
	teamKey: {
		value: TeamKey;
	};
	// Note: although we represent iterationId as a string in the frontend, the API
	// treats it as an integer, hence the number type here
	iterationId: {
		value: number;
	};
	schedulingMode: {
		value: SchedulingMode;
	};
	capacity: {
		value: number | null | undefined;
	};
	planningUnit: {
		value: PlanningUnit;
	};
};
