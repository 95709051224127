import * as R from 'ramda';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import { EPIC_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type {
	SolutionVersion,
	Interval,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	isDefined,
	filterMap,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	UNDEFINED_KEY,
	COMPLETED_SPRINTS_GROUP,
	COMPLETED_RELEASES_GROUP,
	RELEASE_STATUSES,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { ScopeIssue, GroupAttribute } from '../../state/domain/scope/types.tsx';
import type { Solution } from '../../state/domain/solution/types.tsx';
import type { Sprint } from '../../state/domain/sprints/types.tsx';
import type { Team } from '../../state/domain/teams/types.tsx';
import type { VersionsById } from '../versions/types.tsx';
import type { IssuesById, GetIssueHierarchy, IssuesGroup, IssuesByGroup } from './types.tsx';

export const UNDEFINED_GROUP = UNDEFINED_KEY;

export const getGroupKey = (groupCombination: Partial<Record<GroupAttribute, unknown>>): string =>
	filterMap(
		(grouping) => isDefined(grouping[1]),
		([attr, group]) => `${attr}-${String(group)}`,
		// Sort the array of entries to maintain the specific order
		Object.entries(groupCombination).sort(([attr1], [attr2]) => attr1.localeCompare(attr2)),
	).join('-') || UNDEFINED_GROUP;

export const getIssuesHierarchyFunc: GetIssueHierarchy = ({
	depth = 0,
	hierarchyIssueIds = [],
	issues,
	issuesByParent,
	isExpanded,
	issuesInGroupMap,
	rootIndex: parentRootIndex,
	group = '',
	groupCombination,
	ignoreExpandingState = false,
	parentsExpanded = true,
}) => {
	const result: Array<ScopeIssue> = [];

	for (let index = 0; index < issues.length; index++) {
		const issue = issues[index];
		const { id } = issue;
		let children = issuesByParent[id];
		const rootIndex = R.isNil(parentRootIndex) ? index : parentRootIndex;
		const idSuffix = groupCombination ? `:${getGroupKey(groupCombination)}` : '';
		const expandId = `${id}${idSuffix}`;

		if (children) {
			// prevent infinite loop
			if (isDefined(issuesInGroupMap)) {
				children = children.filter(
					(child) => !!issuesInGroupMap[child.id] && !hierarchyIssueIds.includes(child.id),
				);
			} else {
				children = children.filter((child) => !hierarchyIssueIds.includes(child.id));
			}
		}

		const isIssueExpanded = isExpanded && !!isExpanded[expandId];
		const processChildren = ignoreExpandingState || isIssueExpanded;

		// R.merge is faster than Babel's object spread polyfill
		// which becomes noticeable on large plans because this merge is a hot spot
		// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Grouping | undefined'.
		const scopeIssue: ScopeIssue = R.merge(issue, {
			depth,
			isExpanded: isIssueExpanded,
			isExpandable: !!children && children.length > 0,
			rootIndex,
			group,
			parentsExpanded,
		});
		result.push(scopeIssue);
		hierarchyIssueIds.push(scopeIssue.id);

		if (processChildren && children) {
			Array.prototype.push.apply(
				result,
				getIssuesHierarchyFunc({
					depth: depth + 1,
					issues: children,
					issuesByParent,
					isExpanded,
					issuesInGroupMap,
					rootIndex,
					// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'Grouping | undefined'.
					group,
					hierarchyIssueIds,
					groupCombination,
					ignoreExpandingState,
					parentsExpanded: parentsExpanded && isIssueExpanded,
				}),
			);
		}
	}

	return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getIssuesHierarchy = monitor.timeFunction<any, any>(
	getIssuesHierarchyFunc,
	'query_scope_util_getIssuesHierarchy',
);

/** Get fixVersions for optimized issue by following rules:

 * If there are already assigned release values and solution.calculationConfiguration.ignoreReleases
   is false, return the assigned values.
 * If there are no assignments for the given story then release should stay unchanged.
 * If there is one assignment with a different version value then we should use it and show as
   unchanged/changed/unassigned.
 * If there is one assignment without version value present then we should show the release as
   unassigned (assignments version is undefined).
 * If there is one assignment with later version value then we should show the release as unassigned.
 * If there are many assignments, and at least one of them with later version value then we should
   show the release as unassigned (test note: assign a very big story points to an issue which
   expands it to many sprints and all the releases will be excluded).
 * If there are many assignments with version value present then we should pick the latest version
   (we compare versions by release end date, pick fixEnd or end is biggest).
 * If assignment contains dynamic version (without fixed end date) and is not 'later' version, return this version.
 * If we have assignments with dynamic and fixed releases, we should omit dynamic release and pick the
   latest fixed version.
 */
export const getOptimizedFixVersion = (
	issue: Issue,
	solution: Solution,
): string[] | null | undefined => {
	const { assignments } = issue;

	if (
		issue.fixVersions &&
		issue.fixVersions.length &&
		!R.path(['calculationConfiguration', 'ignoreReleases'], solution)
	) {
		return issue.fixVersions;
	}
	if (assignments.length === 0) {
		return issue.fixVersions;
	}

	const { projects } = solution;
	const projectVersions =
		(projects[Number(issue.project)] && projects[Number(issue.project)].versions) || [];

	const endedAtForVersion = (version: string): number | null | undefined => {
		const projectVersion = R.find<SolutionVersion>(R.propEq('key', version))(projectVersions);

		if (!isDefined(projectVersion)) {
			return undefined;
		}

		if (isDefined(projectVersion.fixedEnd)) {
			return projectVersion.fixedEnd;
		}

		return projectVersion.end;
	};

	const { laterVersions, latestVersion } = assignments.reduce<{
		laterVersions: string[];
		latestVersion: { version?: string; endedAt: number };
	}>(
		// eslint-disable-next-line @typescript-eslint/no-shadow
		({ laterVersions, latestVersion }, { version }) => {
			if (isDefined(version)) {
				if (version.endsWith('-later')) {
					laterVersions.push(version);
				} else {
					const endedAt = endedAtForVersion(version);

					if (isDefined(endedAt) && endedAt > latestVersion.endedAt)
						return { laterVersions, latestVersion: { version, endedAt } };
					if (latestVersion.endedAt === 0)
						return { laterVersions, latestVersion: { version, endedAt: 0 } };
				}
			}
			return { laterVersions, latestVersion };
		},
		{ laterVersions: [], latestVersion: { version: undefined, endedAt: 0 } },
	);

	if (laterVersions.length === 0 && isDefined(latestVersion.version)) {
		return [latestVersion.version];
	}
};

/** Get sprint for optimized issue by followind rules:

 * If there are no assignments for the given story then sprint should stay unchanged.
 * If there is one assignment with interval value and team value present then we should use it and show as
   unchanged/changed/unassigned.
 * If there is one assignment with interval value present but the team value is not presented then we should use the original value.
 * If there is one assignment without interval value present then we should show the sprint as
   unassigned.
 * If there is assignment returned with interval, but the interval is pointing to future sprint not
   existing in Jira (sprintId is empty), there are two different cases.
       Case A - if the scheduler configuration is to override all the values, then we should show sprint as unassigned.
      Case B -  if the scheduler configuration is to override empty the values only, then we should show origin sprint.
 * If there are many assignments then we should pick the earliest sprint (sprint with the smallest
   start, also need to check if the smallest sprint does exist in Jira. If it doesn't, also apply to the case A and case B. otherwise show the sprint).
 */
export const getOptimizedSprint = (issue: Issue, solution: Solution): string | null | undefined => {
	const { teams } = solution;
	const { assignments } = issue;

	if (
		assignments.length === 0 ||
		issue.level >= EPIC_LEVEL ||
		// If there is one assignment with interval value present but the team value is not presented then we should use the original value.
		(assignments.length === 1 &&
			!isDefined(assignments[0].team) &&
			isDefined(assignments[0].interval))
	) {
		return issue.sprint;
	}

	const { sprintId } = assignments.reduce<Partial<Interval>>(
		(earliestSprint, { interval, team }) => {
			if (isDefined(team) && isDefined(interval)) {
				const sprint = R.path<(typeof teams)[string]['intervals'][number]>(
					[team, 'intervals', interval],
					teams,
				);

				if (
					isDefined(sprint) &&
					(!isDefined(earliestSprint.start) || sprint.start < earliestSprint.start)
				) {
					return sprint;
				}
			}

			return earliestSprint;
		},
		{ start: undefined, sprintId: undefined },
	);

	if (
		solution.calculationConfiguration &&
		!solution.calculationConfiguration.ignoreSprints &&
		!isDefined(sprintId)
	) {
		return issue.sprint;
	}
	return sprintId;
};

export const addIssueToGroup = (
	issuesByGroup: IssuesByGroup,
	issue: Issue,
	issueGroup: IssuesGroup,
) => {
	const copiedIsssueByGroup = { ...issuesByGroup };
	const { issues, parentGroup, ...group } = issueGroup;
	const groupKey = getGroupKey(group);

	const grouping = copiedIsssueByGroup[groupKey];

	if (grouping && grouping.issues) {
		grouping.issues.add(issue);
	} else {
		const newGroup = {
			...group,
			issues: new Set([issue]),
			...(isDefined(parentGroup) ? { parentGroup } : {}),
		};
		copiedIsssueByGroup[groupKey] = newGroup;
	}

	return copiedIsssueByGroup;
};

export const getTeamsBySprintGroups = (
	issue: Issue,
	isOptimizedMode: boolean,
	sprintsByIdMap: {
		[key: string]: Sprint;
	},
	sprintsForTeam: {
		[key: string]: Sprint[];
	},
	teamsById: {
		[key: string]: Team;
	},
) => {
	const groups: IssuesGroup[] = [];

	const issueSprint =
		(isOptimizedMode && isDefined(issue.optimized) && issue.optimized.sprint) ||
		issue.sprint ||
		null;

	const issueTeam =
		(isOptimizedMode && isDefined(issue.optimized) && issue.optimized.team) || issue.team || null;

	if (issue.completedSprints && issue.completedSprints.length > 0) {
		issue.completedSprints.forEach((completedSprintId) => {
			if (R.has(completedSprintId, sprintsByIdMap)) {
				groups.push({ sprint: completedSprintId, parentGroup: COMPLETED_SPRINTS_GROUP });
			}
		});
	}

	if (issue.completedSprints && issue.completedSprints.includes(issueSprint)) {
		// sprint is already added as a completed sprint
	} else if (issueSprint && R.has(issueSprint, sprintsByIdMap)) {
		// sprint is in a plan
		groups.push({ sprint: issueSprint });
	} else if (!issue.completedSprints || !issue.completedSprints.length) {
		// add it to unassigned group
		groups.push({ sprint: null });
	}

	return groups.map((group) => {
		const newGroup = { ...group };
		const sprintWithTeam =
			newGroup.sprint &&
			issueTeam &&
			sprintsForTeam &&
			sprintsForTeam[issueTeam] &&
			sprintsForTeam[issueTeam].find((sprint) => sprint.id === newGroup.sprint);

		const isKanbanTeam =
			issueTeam &&
			teamsById &&
			teamsById[issueTeam] &&
			teamsById[issueTeam].schedulingMode === 'Kanban';

		if (sprintWithTeam && !isKanbanTeam) {
			newGroup.team = issueTeam;
		}

		return newGroup;
	});
};

// Iterates through an issues' ancestors, and returns all sprint groups (if any)
// its ancestors belong to, or an empty array if none can be found
export const getTeamsByAncestorSprintGroups = (
	issue: Issue,
	isOptimizedMode: boolean,
	sprintsByIdMap: {
		[key: string]: Sprint;
	},
	sprintsForTeam: {
		[key: string]: Sprint[];
	},
	teamsById: {
		[key: string]: Team;
	},
	issuesById: IssuesById,
) => {
	let groups: IssuesGroup[] = [];
	let parent = issuesById[issue.parent || ''];
	const ancestorsIds: Array<string> = [];

	while (parent) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		groups = groups.concat(
			getTeamsBySprintGroups(parent, isOptimizedMode, sprintsByIdMap, sprintsForTeam, teamsById),
		);

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return groups;
};

// Iterates through an issues' ancestors, and returns the sprint groups (if any)
// the closest ancestor belongs to, or an empty array if none can be found
export const getTeamsByAncestorSprintGroupsOld = (
	issue: Issue,
	isOptimizedMode: boolean,
	sprintsByIdMap: {
		[key: string]: Sprint;
	},
	sprintsForTeam: {
		[key: string]: Sprint[];
	},
	teamsById: {
		[key: string]: Team;
	},
	issuesById: IssuesById,
) => {
	let groups: IssuesGroup[] = [];
	let parent = issuesById[issue.parent || ''];
	const ancestorsIds: Array<string> = [];

	while (parent && !groups.length) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		groups = getTeamsBySprintGroups(
			parent,
			isOptimizedMode,
			sprintsByIdMap,
			sprintsForTeam,
			teamsById,
		).filter(({ sprint }) => sprint !== null);

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return groups;
};

export const getReleasesGroups = (
	issue: Issue,
	isOptimizedMode: boolean,
	versionsById: VersionsById,
) => {
	const groups: IssuesGroup[] = [];

	let issueFixVersions: string[] | null | undefined;

	if (isOptimizedMode && isDefined(issue.optimized) && R.has('fixVersions', issue.optimized)) {
		issueFixVersions = issue.optimized.fixVersions;
	} else {
		issueFixVersions = issue.fixVersions;
	}

	// No release assigned
	if (!issueFixVersions || issueFixVersions.length === 0) {
		return [{ release: null }];
	}

	issueFixVersions.forEach((fixVersion) => {
		const version = versionsById[fixVersion];
		if (version && version.releaseStatusId === RELEASE_STATUSES.RELEASED) {
			groups.push({ release: fixVersion, parentGroup: COMPLETED_RELEASES_GROUP });
		} else if (version) {
			groups.push({ release: fixVersion });
		}
	});

	// Assigned release are not in plan
	if (groups.length === 0) {
		return [{ release: null }];
	}

	return groups;
};

// Iterates through an issues' ancestors, and returns all releases groups (if any)
// its ancestors belong to, or an empty array if none can be found
export const getAncestorReleasesGroups = (
	issue: Issue,
	isOptimizedMode: boolean,
	versionsById: VersionsById,
	issuesById: IssuesById,
) => {
	let groups: IssuesGroup[] = [];
	let parent = issuesById[issue.parent || ''];
	const ancestorsIds: Array<string> = [];

	while (parent) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		groups = groups.concat(getReleasesGroups(parent, isOptimizedMode, versionsById));

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return groups;
};

// Iterates through an issues' ancestors, and returns the releases groups (if any)
// the closest ancestor belongs to, or an empty array if none can be found
export const getAncestorReleasesGroupsOld = (
	issue: Issue,
	isOptimizedMode: boolean,
	versionsById: VersionsById,
	issuesById: IssuesById,
) => {
	let groups: IssuesGroup[] = [];
	let parent = issuesById[issue.parent || ''];
	const ancestorsIds: Array<string> = [];

	while (parent && !groups.length) {
		if (ancestorsIds.includes(parent.id)) {
			// We've already looked at this parent.
			break;
		}

		groups = getReleasesGroups(parent, isOptimizedMode, versionsById).filter(
			({ release }) => release !== null,
		);

		ancestorsIds.push(parent.id);
		parent = issuesById[parent.parent || ''];
	}

	return groups;
};
