export const SHOW_ADD_DEPENDENCY =
	'state.ui.main.tabs.dependencies.addDependency.SHOW_ADD_DEPENDENCY' as const;
export const HIDE_ADD_DEPENDENCY =
	'state.ui.main.tabs.dependencies.addDependency.HIDE_ADD_DEPENDENCY' as const;
export const SHOW_ADD_DEPENDENCY_FROM_ISSUE =
	'state.ui.main.tabs.dependencies.addDependency.SHOW_ADD_DEPENDENCY_FROM_ISSUE' as const;

export type ShowAddDependencyAction = {
	type: typeof SHOW_ADD_DEPENDENCY;
};
export type HideAddDependencyAction = {
	type: typeof HIDE_ADD_DEPENDENCY;
};
export type ShowAddDependencyFromIssueAction = {
	type: typeof SHOW_ADD_DEPENDENCY_FROM_ISSUE;
	payload: string;
};

export const showAddDependency = (): ShowAddDependencyAction => ({
	type: SHOW_ADD_DEPENDENCY,
});

export const hideAddDependency = (): HideAddDependencyAction => ({
	type: HIDE_ADD_DEPENDENCY,
});

export const showAddDependencyFromIssue = (id: string): ShowAddDependencyFromIssueAction => ({
	type: SHOW_ADD_DEPENDENCY_FROM_ISSUE,
	payload: id,
});
