import * as R from 'ramda';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import type { Change } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/types.tsx';
import type { TypeToLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/hierarchy/types.tsx';
import type { DateConfiguration } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import {
	type IssueValues,
	DATEFIELD_TYPES,
	type DateField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	isDefined,
	filterMap,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import {
	ENTITY,
	SCENARIO_TYPE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { DateChanges, TimeframeAnalyticsAttributes, Item } from './types.tsx';

export const getExpandedChanges = (changes: Change[]) => {
	const result: Item[] = [];
	const uniqIds = new Set();
	const addItem = (item: { category: string; id: string }) => {
		const uniqId = `${item.category}:${item.id}`;
		if (!uniqIds.has(uniqId)) {
			uniqIds.add(uniqId);
			result.push(item);
		}
	};
	for (const change of changes) {
		if (change.type !== SCENARIO_TYPE.NONE) {
			if (change.category === ENTITY.TEAM) {
				addItem({
					id: change.id,
					category: change.category,
				});
			}

			if (change.category === ENTITY.TEAM || change.category === ENTITY.RESOURCE) {
				change.details.changes
					.filter((additionalChange) => additionalChange.category === ENTITY.RESOURCE)
					.forEach((additionalChange) => {
						addItem({
							id: additionalChange.resourceId || '0',
							category: ENTITY.RESOURCE,
						});
					});
			} else {
				addItem({
					id: change.id,
					category: change.category,
				});
			}
		}
	}

	return result;
};

const formatDate = (timestamp?: Timestamp | null): string | null | undefined => {
	if (!isDefined(timestamp)) {
		return timestamp;
	}
	return formatDateUTC(timestamp, 'YYYY-MM-DD HH:mm:ss');
};

// the key will not exist in the originals object if there's no change
const hasDateChange = (dateField: DateField, originals: IssueValues): boolean => {
	if (
		dateField.type === DATEFIELD_TYPES.BUILT_IN &&
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		originals[dateField.key as keyof IssueValues] !== undefined
	) {
		return true;
	}
	if (dateField.type === DATEFIELD_TYPES.CUSTOM) {
		if (R.path([`customField-${dateField.key}`, 'value'], originals) !== undefined) {
			return true;
		}
	}
	return false;
};

const getConfiguredDateValues = (
	dateField: DateField,
	originals: IssueValues,
	values: IssueValues,
): DateChanges => {
	if (dateField.type === DATEFIELD_TYPES.CUSTOM) {
		return {
			current: formatDate(R.path([`customField-${dateField.key}`, 'value'], originals) || null),
			new: formatDate(R.path([`customField-${dateField.key}`, 'value'], values) || null),
		};
	}
	return {
		current: formatDate(R.path([dateField.key], originals) || null),
		new: formatDate(R.path([dateField.key], values) || null),
	};
};

export const getAttributesForIssuesWithTimeframeChanges = (
	changes: Change[],
	dateConfiguration: DateConfiguration,
	typeToLevel: TypeToLevel,
): TimeframeAnalyticsAttributes[] => {
	const { baselineStartField, baselineEndField } = dateConfiguration;

	const isIssueWithTimeframeChanges = (change: Change): boolean => {
		const originals: IssueValues | undefined = R.path(['details', 'originals'], change);
		if (change.category !== ENTITY.ISSUE || change.type === 'NONE' || originals === undefined)
			return false;
		if (hasDateChange(baselineStartField, originals) || hasDateChange(baselineEndField, originals))
			return true;
		return false;
	};

	const changeAnalyticsAttributes = (change: Change): TimeframeAnalyticsAttributes => {
		let start: DateChanges = { current: null, new: null };
		let end: DateChanges = { current: null, new: null };
		const originals: IssueValues | undefined = R.path(['details', 'originals'], change);
		const values: IssueValues | undefined = R.path(['details', 'values'], change);

		if (originals !== undefined && values !== undefined) {
			start = getConfiguredDateValues(baselineStartField, originals, values);
			end = getConfiguredDateValues(baselineEndField, originals, values);
		}
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const type = R.path(['details', 'values', 'type'], change) as number;
		const hierarchyLevel = typeToLevel(type);

		return {
			issueId: change.id,
			lastModifiedDate: formatDate(change.lastModified?.date),
			lastModifiedUser: change.lastModified?.personId,
			start: {
				type: baselineStartField.type,
				...start,
				changed: start.current !== start.new,
			},
			end: {
				type: baselineEndField.type,
				...end,
				changed: end.current !== end.new,
			},
			hierarchyLevel,
		};
	};

	return filterMap(isIssueWithTimeframeChanges, changeAnalyticsAttributes, changes);
};

export const getIdForChangeCategory = (id: string, category: string): string => {
	switch (category) {
		case ENTITY.ISSUE:
			return `issue_change_${id}`;
		case ENTITY.RELEASE:
			return `release_change_${id}`;
		case ENTITY.CROSS_PROJECT_RELEASE:
			return `cross_project_release_change_${id}`;
		case ENTITY.TEAM:
			return `team_change_${id}`;
		case ENTITY.RESOURCE:
			return `resource_change_${id}`;
		case ENTITY.PLANNED_CAPACITY:
			return `planned_capacity_change_${id}`;
		case ENTITY.SPRINT:
			return `sprint_change_${id}`;
		default:
			return id;
	}
};
