import React, { type ReactNode } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';

type Props = {
	count: number;
	total: number;
	label: ReactNode;
};

export default function Percentage({ count, total, label }: Props) {
	const { formatMessage } = useIntl();
	const percentage = total && Math.round((count / total) * 100);

	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.totalValue}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.countLabel}>{label}</div>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.percentage}>{formatMessage(messages.percentage, { percentage })}</div>
		</div>
	);
}
