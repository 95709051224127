import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { useHorizontalScrolling } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { Z_INDEX_LAYER } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/types.tsx';
import { ZIndex } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/z-index/view.tsx';
import { useViewport } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';

export const Blanket = () => {
	const [viewport] = useViewport();
	const [{ isScrollingWithScrollBar, zoomLevel }] = useHorizontalScrolling();

	// We only show the blanket when scrolling with scroll bar
	if (!zoomLevel || !isScrollingWithScrollBar) {
		return null;
	}

	return (
		<ZIndex layer={Z_INDEX_LAYER.HORIZONTAL_SCROLL_BAR}>
			{(zIndex) => (
				<Box
					xcss={wrapperStickyStyles}
					style={{ zIndex }}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.table.overlay.blanket"
				>
					<Box
						xcss={wrapperAbsoluteStyles}
						style={{ width: viewport.width, height: viewport.height }}
					/>
				</Box>
			)}
		</ZIndex>
	);
};

const wrapperStickyStyles = xcss({
	position: 'sticky',
	top: 'space.0',
	left: 'space.0',
});

const wrapperAbsoluteStyles = xcss({
	position: 'absolute',
});
