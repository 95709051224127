import { getCustomFields } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import { getColorByValue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getColorByList } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/color-by-legend/query.tsx';
import type { StateProps } from './types.tsx';

export default createStructuredSelector<State, {}, StateProps>({
	colorByValue: getColorByValue,
	colorByList: getColorByList,
	customFields: getCustomFields,
});
