import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	customFieldMenuTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.custom-field.custom-field-menu-title',
		defaultMessage: 'Choose option',
		description: 'This is a title for custom field menu',
	},
	customFieldSearchPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.scope.header.create-issue.options-providers.custom-field.custom-field-search-placeholder',
		defaultMessage: 'Find options...',
		description: 'This is a placeholder for search',
	},
});
