import { type FC, useEffect } from 'react';
import { COMMAND_STORAGE_KEY } from '../../services/storage/constants.tsx';
import { storage } from '../../services/storage/index.tsx';
import type { DispatchProps } from '../../types.tsx';

type Command = {
	type: string;
	values: Array<string | number | boolean | object>;
};

export const LocalStorageCommandSubscriber: FC<Partial<DispatchProps>> = (props) => {
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const commands = (storage.get(COMMAND_STORAGE_KEY) as Command[]) || null;

		if (Array.isArray(commands) && commands.length) {
			commands.forEach(({ type: commandType, values }) => {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				const command = props[commandType as keyof DispatchProps];
				if (typeof command === 'function') {
					type argsType = Parameters<DispatchProps[keyof DispatchProps]>;
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					(command as (...args: argsType) => void)(...(values as argsType));
				}
			});

			storage.clearAll();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return null;
};
