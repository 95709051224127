.stickHeaderLayout {
	background: transparent;
	z-index: 1;
	box-sizing: border-box;
	height: 100%;
}
.columnHeaderWrapper {
	background: var(--ds-surface, var(--jpo-bg-default-color));
	height: 100%;
	border-bottom: 1px solid var(--ds-border, var(--jpo-border-secondary-color));
	width: 100%;
	box-sizing: border-box;
	position: relative;
}

.columnHeaderSection {
	height: 100%;
}

.portalContainer {
	width: 100%;
	/* z-index should be bigger than 2 as the section's separator zIndex is 2 to prevent the flyout of the sticky header to be overlay */
	z-index: 3;
	height: 100%;
}

/*
 * These classes are used in src/packages/portfolio-3/portfolio/src/app-simple-plans/view/main/tabs/roadmap/sticky-header/view.js 
 * A simple string search would not find it since they are computed through template literals
 */
.fieldColumnHeaderWrapper {
	margin: 0px 1px;
	width: calc(100% - 2px);
}

.scopeColumnHeaderWrapper {
	margin: 0px 1px 0px 0px;
	width: calc(100% - 1px);
}
