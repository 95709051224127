import React, { type ComponentType, useMemo } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import {
	slots,
	withSlots,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/component-slots/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import CommonLabel from '@atlassian/jira-portfolio-3-portfolio/src/common/view/label/index.tsx';
import OptionsProviderLabel from './options-provider-label.tsx';
import { OptionsProvider } from './options-provider.tsx';
import type { Props, InjectProps, InjectedComponentProps, Messages } from './types.tsx';

export const MultiSelectRenderer = ({
	issue,
	messages,
	attribute,
	isReadOnly,
	onChange,
	fetchOptions,
	useValueAsLabel,
	selectOptions,
	MultiSelectCell,
	menuIsOpen,
}: Props) => {
	const { formatMessage } = useIntl();

	const getDisplayValue = (id: string) => {
		if (useValueAsLabel === true) {
			return id;
		}

		const displayValue = selectOptions.find(
			(field) => field.id.toString() === (id && id.toString()),
		);
		return displayValue?.value || '';
	};

	const getValues = () => {
		const values = (issue.customFields && issue.customFields[attribute]) || [];
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return values.map((value: any) => ({
			label: getDisplayValue(value),
			value,
		}));
	};

	const formattedMessages = useMemo(() => {
		const result: Record<string, string> = {};

		for (const [key, v] of Object.entries(messages)) {
			const formattedMessage = formatMessage(v);
			result[key] = formattedMessage;
		}
		return result;
	}, [messages, formatMessage]);

	return (
		<MultiSelectCell
			menuIsOpen={menuIsOpen}
			attribute={attribute}
			issue={issue}
			isReadOnly={isReadOnly}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			messages={formattedMessages as Record<keyof Messages, string>}
			getValues={getValues}
			fetchOptions={(query: string) =>
				isDefined(fetchOptions) &&
				fetchOptions(attribute, issue.project, issue.type.toString(), query)
			}
			isDisabled={false}
			// eslint-disable-next-line @typescript-eslint/no-shadow
			onChange={(issue: ScopeIssue, options: OptionType | OptionType[] | null | undefined) =>
				onChange(issue, attribute, options)
			}
		/>
	);
};

export const MultiSelect = OptionsProvider(
	withSlots({
		MultiSelectCell: slots.FieldMultiSelectCell,
	})(MultiSelectRenderer),
);

export const LabelRenderer = (props: Props) => {
	const { attribute, issue, fetchOptions, onChange } = props;
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const getValues = (issue: ScopeIssue, attribute: string) => {
		const values = (issue.customFields && issue.customFields[attribute]) || [];
		return values.map((value: string) => ({
			label: value,
			value,
		}));
	};
	return (
		<CommonLabel
			{...props}
			getValues={getValues}
			fetchOptions={(query: string) => {
				if (fetchOptions !== undefined) {
					return fetchOptions(attribute, issue.project, issue.type.toString(), query);
				}
			}}
			// eslint-disable-next-line @typescript-eslint/no-shadow
			onChange={(issue: ScopeIssue, labels: OptionType[]) => onChange(issue, attribute, labels)}
		/>
	);
};

const LabelComponent = OptionsProviderLabel(
	withSlots({ MultiSelectCell: slots.FieldMultiSelectCell })(LabelRenderer),
);

export const Label: ComponentType<Omit<Props, keyof InjectProps | keyof InjectedComponentProps>> =
	LabelComponent;
