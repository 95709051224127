.dropdown {
	margin-right: 10px;
}

.icon {
	width: var(--jpo-icon-size-small);
	min-width: var(--jpo-icon-size-small);
	height: var(--jpo-icon-size-small);
	margin: var(--jpo-common-base-spacing) 0;
}

.assertiveIcon {
	margin-right: 20px;
}

.projectIcon {
	padding-left: 25px;
	background-position: left center;
	background-repeat: no-repeat;
	background-size: var(--jpo-icon-size-small);
}

.inlineEdit {
	padding-right: var(--jpo-common-base-spacing);
	/* flex items will refuse to shrink below their minimum intrinsic width, unless you explicitly specify "min-width" or "width" or "max-width" on them */
	/* we need to set both min-width and width to enable shrinking and to still have growth */
	min-width: 0;
	width: 100%;

	& > form > div {
		margin-top: 0px;
	}

	& input {
		cursor: initial;
	}
}

.validationText {
	display: flex;
}

.validationIcon {
	margin-top: calc(var(--jpo-common-base-spacing) * -1 / 2);
	margin-right: calc(var(--jpo-common-base-spacing) / 2);
}
.validationTextMessage {
	flex: auto;
}

.container {
	display: flex;
	flex-grow: 1;
}

.spinner {
	padding: var(--jpo-common-base-spacing);
	margin-right: var(--jpo-common-base-spacing);
}
