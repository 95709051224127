import {
	getVersions,
	getRemovedVersions,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { StateProps, OwnProps } from './types.tsx';

export const mapStateToPropsOld = (state: State): StateProps => ({
	versions: [...getVersions(state), ...getRemovedVersions(state)],
});

const getVersionsMemoized = () =>
	createSelector(getVersions, getRemovedVersions, (versions, removedVersions) => [
		...versions,
		...removedVersions,
	]);

const mapStateToPropsFactory: () => MapStateToProps<StateProps, OwnProps> = () => {
	const getVersionsInstance = getVersionsMemoized();
	return (state: State): StateProps => ({
		versions: getVersionsInstance(state),
	});
};

const mapStateToProps = mapStateToPropsFactory;
export default mapStateToProps;
