import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	confirm: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.discard-confirmation.confirm',
		defaultMessage: 'Discard changes',
		description: "Label for Update Jira discard changes dialog's confirmation button",
	},
	title: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.discard-confirmation.title',
		defaultMessage: 'Confirm discard',
		description: "Discard confirmation dialog's title text",
	},
	message: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.discard-confirmation.message',
		defaultMessage: 'Are you sure you want to discard the selected changes?',
		description: "Discard confirmation dialog's message text",
	},
});
