import { getAutoSchedulerEnabled } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import View, { type StateProps } from './view.tsx';

const mapStateToProps = createStructuredSelector<State, {}, StateProps>({
	isAutoSchedulerEnabled: getAutoSchedulerEnabled,
});

export default connect(mapStateToProps, {})(View);
