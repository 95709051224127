import pick from 'lodash/fp/pick';
import defaultOptions from '@atlassian/jira-common-constants/src/fetch-default-options.tsx';
import { performPutRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { Action } from '@atlassian/react-sweet-state';
import {
	type ContainerProps,
	type State,
	type CttOnboarding,
	CttOnboardingStage,
	type CttOnboardingStageType,
} from './types.tsx';

const PERSIST_KEY = 'jira.user.arj.ctt-onboarding';

const getCttOnboardingUserProperties = async (accountId: string): Promise<unknown> => {
	const fetchResponse: Response = await fetch(
		`/rest/api/3/user/properties/${PERSIST_KEY}?accountId=${accountId}`,
		defaultOptions,
	);

	// ok is true only when status code is 2xx.
	if (fetchResponse.ok) {
		const responseObj = await fetchResponse.json();
		return responseObj.value;
	}

	const { status } = fetchResponse;

	// 404: API deliberately returns 404 if user property is not found, which implies the user has not migrated their data
	if (status === 404) {
		return undefined;
	}

	throw new Error(
		`Fetching ${PERSIST_KEY} user property failed. Status code: ${fetchResponse.status}`,
	);
};

export const rehydrate: Action<State, ContainerProps> = async (
	{ getState, setState },
	{ accountId },
) => {
	if (getState()._rehydrated) {
		return;
	}

	// Mark as any since it's assumed to be opaque, we ensure type safety in the code below.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let raw: {} | any = {};

	const response = await getCttOnboardingUserProperties(accountId);

	if (response) {
		raw = response;
	} else {
		raw = {};
	}

	const cttOnboarding: CttOnboarding | undefined = (() => {
		const stage: CttOnboardingStageType = raw.cttOnboarding?.stage;
		if (stage && Object.values(CttOnboardingStage).includes(stage)) {
			return { stage, planId: raw.cttOnboarding?.planId };
		}

		return undefined;
	})();

	setState({
		_rehydrated: true,
		cttOnboarding,
	});
};

export const persist =
	(update?: Partial<State>): Action<State, ContainerProps> =>
	async ({ getState }, { accountId }) => {
		const serialize = pick(['cttOnboarding']);

		await performPutRequest(`/rest/api/3/user/properties/${PERSIST_KEY}?accountId=${accountId}`, {
			body: JSON.stringify(
				serialize({
					...getState(),
					...update,
				}),
			),
		});
	};

export const update =
	(options: { ui: boolean; persist: boolean } = { ui: true, persist: true }) =>
	(partial: Partial<State>): Action<State> =>
	async ({ getState, setState, dispatch }) => {
		const { _rehydrated, _rehydratePromise } = getState();

		if (!_rehydrated) {
			await _rehydratePromise;
		}

		if (options.ui) {
			setState(partial);
		}

		if (options.persist) {
			dispatch(persist({ ...getState(), ...partial }));
		}
	};
