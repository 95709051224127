import EventEmitter from 'events';

type IssueBarDragEventPayload = {
	/** */
	pageX: number;

	/** */
	pageY: number;
};

export type IsueBarDragEventHandler = (arg0: IssueBarDragEventPayload) => void;

export class IssueBarDragEmitter {
	private emitter = new EventEmitter();

	public addEventListener(name: 'dragstart', callback: IsueBarDragEventHandler): void;

	public addEventListener(name: 'drag', callback: IsueBarDragEventHandler): void;

	public addEventListener(name: 'dragend', callback: IsueBarDragEventHandler): void;

	public addEventListener(name: string, callback: IsueBarDragEventHandler) {
		this.emitter.on(name, callback);
	}

	public removeEventListener(name: 'dragstart', callback: IsueBarDragEventHandler): void;

	public removeEventListener(name: 'drag', callback: IsueBarDragEventHandler): void;

	public removeEventListener(name: 'dragend', callback: IsueBarDragEventHandler): void;

	public removeEventListener(name: string, callback: IsueBarDragEventHandler) {
		this.emitter.off(name, callback);
	}

	public emit(name: 'dragstart', payload: IssueBarDragEventPayload): void;

	public emit(name: 'drag', payload: IssueBarDragEventPayload): void;

	public emit(name: 'dragend', payload: IssueBarDragEventPayload): void;

	public emit(name: string, payload: unknown) {
		this.emitter.emit(name, payload);
	}
}

const issueBarDragEmitter = new IssueBarDragEmitter();
export default issueBarDragEmitter;
