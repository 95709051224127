import { SET_RANKING_STATE, type SetRankingStateAction } from './actions.tsx';
import type { ReleasesTable } from './types.tsx';

type Action = SetRankingStateAction;

export const initialState: ReleasesTable = {
	isRanking: false,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ReleasesTable = initialState, action: Action): ReleasesTable => {
	switch (action.type) {
		case SET_RANKING_STATE: {
			return {
				isRanking: action.payload,
			};
		}
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
