import {
	getSortBy,
	getSortDirection,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/update-jira/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export default createStructuredSelector<State, OwnProps, StateProps>({
	sortBy: getSortBy,
	sortDirection: getSortDirection,
});
