import * as R from 'ramda';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	type Relation,
	RANK_AFTER,
	RANK_LAST,
	RANK_TOP,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';

export const createMapWithNewRank = (
	anchor: Issue,
	issues: Issue[],
	selectedIdsSet: Set<string>,
): {
	[key: string]: Issue;
} => {
	const allSortedIssuesWithNewRank: Issue[] = issues.map((issue) => {
		if (selectedIdsSet.has(issue.id)) {
			return {
				...issue,
				lexoRank: anchor.lexoRank,
			};
		}
		return issue;
	});

	return indexBy((issue) => issue.id, allSortedIssuesWithNewRank);
};

export const findSelectedIssuesWithLowerPosition = ({
	allSortedIssues,
	anchorFieldValue,
	getAncestors,
	issues,
	operationTypeFieldValue,
	selectedIdsSet,
	selectedIssues,
}: {
	allSortedIssues: Issue[];
	anchorFieldValue: string | undefined;
	getAncestors: (arg1: string) => Issue[];
	issues: Issue[];
	operationTypeFieldValue: 'TOP' | Relation;
	selectedIdsSet: Set<string>;
	selectedIssues: Issue[];
}): {
	issuesWithLowerPosition: Issue[];
	issuesWithHigherPosition: Issue[];
} => {
	switch (operationTypeFieldValue) {
		case RANK_TOP: {
			// if some of issue ancestor is not selected it means that issue will have lower position than new rank
			const issuesWithLowerPosition = selectedIssues.filter(({ id }) => {
				const ancestors = getAncestors(id);
				return R.differenceWith((x, y) => x.id === y.id, ancestors, selectedIssues).length;
			});
			return { issuesWithLowerPosition, issuesWithHigherPosition: [] };
		}

		case RANK_LAST: {
			const anchor = R.findLast(({ id }) => !selectedIdsSet.has(id), allSortedIssues);

			if (!anchor) {
				return { issuesWithLowerPosition: [], issuesWithHigherPosition: [] };
			}

			const issuesMapByIdWithNewRank = createMapWithNewRank(anchor, issues, selectedIdsSet);
			const issuesWithHigherPosition = selectedIssues.filter(({ id }) => {
				// applying new lexorank for ancestors, filter out selected ancestors and compare
				// if some issue has lower lexoRank than some unselected ancestor
				// that means we have at least one issue placed after selected issue
				const ancestors = getAncestors(id)
					// eslint-disable-next-line @typescript-eslint/no-shadow
					.map(({ id }) => issuesMapByIdWithNewRank[id])
					.filter((el) => !!el);

				return ancestors.length
					? ancestors.some(({ lexoRank: ancestorLexoRank, id: ancestorId }) =>
							issues.some(
								// eslint-disable-next-line @typescript-eslint/no-shadow
								({ lexoRank, id }) => lexoRank >= ancestorLexoRank && ancestorId !== id,
							),
						)
					: [];
			});
			return { issuesWithLowerPosition: [], issuesWithHigherPosition };
		}

		default: {
			const anchor = anchorFieldValue && allSortedIssues.find(({ id }) => id === anchorFieldValue);

			if (!anchor) {
				return { issuesWithLowerPosition: [], issuesWithHigherPosition: [] };
			}

			const issuesMapByIdWithNewRank = createMapWithNewRank(anchor, issues, selectedIdsSet);
			const issuesWithLowerPosition: Array<Issue> = [];
			const issuesWithHigherPosition: Array<Issue> = [];
			for (const issue of selectedIssues) {
				const { id, lexoRank: selectedLexoRank } = selectedIdsSet.has(issue.id)
					? { ...issue, lexoRank: anchor.lexoRank }
					: issue;
				const ancestors = getAncestors(id)
					.map((el) => issuesMapByIdWithNewRank[el.id])
					.filter((el) => !!el);

				if (!ancestors.length) {
					return { issuesWithLowerPosition, issuesWithHigherPosition };
				}

				if (
					// check if issue will has lower position than new rank
					ancestors.some(({ lexoRank }) =>
						operationTypeFieldValue === RANK_AFTER
							? selectedLexoRank < lexoRank
							: selectedLexoRank <= lexoRank,
					)
				) {
					issuesWithLowerPosition.push(issue);
				} else if (
					// check if issue will has higher position than new rank
					ancestors.some(({ lexoRank: ancestorLexoRank, id: ancestorId }) =>
						issues.some(
							// eslint-disable-next-line @typescript-eslint/no-shadow
							({ lexoRank, id }) => lexoRank >= ancestorLexoRank && ancestorId !== id,
						),
					)
				) {
					issuesWithHigherPosition.push(issue);
				}
			}
			return { issuesWithLowerPosition, issuesWithHigherPosition };
		}
	}
};
