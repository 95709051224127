import { getIssueSourcesById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { ISSUE_SOURCE_TYPES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { OwnProps } from './types.tsx';

export default createSelector(
	[getIssueSourcesById, (_: State, ownProps: OwnProps) => ownProps],
	(issueSourcesById, ownProps) => {
		const { sprint } = ownProps;
		const issueSourceId = sprint.issueSources && sprint.issueSources[0];
		const issueSource = issueSourcesById[issueSourceId];
		const boardId =
			issueSource && issueSource.type === ISSUE_SOURCE_TYPES.BOARD ? issueSource.value : undefined;

		return {
			boardId,
		};
	},
);
