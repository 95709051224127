.timelineSettingsFieldset {
	border: none;
	margin-bottom: -2px;
	margin-top: 7px;
}

.timelineSettingsLegend {
	padding: 0;
	font-size: 12px;
	font-weight: var(--ds-font-weight-semibold, 600);
	margin-bottom: 4px;
	color: var(--ds-text-subtle, #6b778c);
}
