import {
	getCrossProjectVersions,
	getRemovedCrossProjectVersions,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/cross-project-versions/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export default createStructuredSelector<State, OwnProps, StateProps>({
	crossProjectVersions: getCrossProjectVersions,
	removedCrossProjectVersions: getRemovedCrossProjectVersions,
});
