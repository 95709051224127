import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	whenAccepted: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.column.cell.flyout.when-accepted',
		defaultMessage: 'When accepted',
		description:
			'Label for the value that the field would be after accepting auto-scheduled change',
	},
	noValue: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.column.cell.flyout.no-value',
		defaultMessage: 'None',
		description: 'Label when there is no value',
	},
});
