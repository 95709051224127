import * as R from 'ramda';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/reporters/types.tsx';
import {
	type ReporterFilter,
	REPORTER_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getUniqueReportersOfIssues } from '../../reporters/index.tsx';
import { getFiltersViewSettings } from '../../view-settings/index.tsx';
import { applyFilter as applyAttributeFilter } from '../attribute-filter/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { REPORTER_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

export const applyFilter = (
	issue: Issue,
	filter: ReporterFilter,
	optimizedMode: boolean,
): boolean => applyAttributeFilter(issue, filter.value, 'reporter', optimizedMode);

export const getReporterForFilter = createSelector(
	[getUniqueReportersOfIssues],
	(issuesReporters: Person[]): Person[] =>
		R.uniqBy<Person, string>(R.prop('personId'))([...issuesReporters]),
);

export const getReporterFilterValue = (state: State): ReporterFilter =>
	getFiltersViewSettings(state)[REPORTER_FILTER_ID] || {
		id: REPORTER_FILTER_ID,
		value: [],
	};

export const getReporterFilter = createSelector(
	[getReporterForFilter, getReporterFilterValue],
	(reporters, { id, value = [] }) => {
		const reporterSet = new Set(R.map(R.prop('personId'), reporters));

		return {
			id,
			value: value.filter((personId) => reporterSet.has(personId)),
		};
	},
);
