import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createGroup: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.components.create-group',
		defaultMessage: 'Create group',
		description: 'The label for the Create group link in the group-by-component feature',
	},
	componentPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.group-by.components.component-placeholder',
		defaultMessage: 'Choose a component',
		description: 'The placeholder for the group-by component select',
	},
});
