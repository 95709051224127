import type {
	IterationId,
	TeamKey,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import type { OriginalPlannedCapacities, OriginalPlannedCapacity } from './types.tsx';

export const RESET = 'state.domain.original-planned-capacities.RESET' as const;
export const UPDATE = 'state.domain.original-planned-capacities.UPDATE' as const;
export const REMOVE = 'state.domain.original-planned-capacities.REMOVE' as const;
export const UPDATE_TEAM_ID = 'state.domain.original-planned-capacities.UPDATE_TEAM_ID' as const;

export type ResetAction = {
	type: typeof RESET;
	payload: OriginalPlannedCapacities;
};

export type UpdateAction = {
	type: typeof UPDATE;
	payload: OriginalPlannedCapacity;
};

export type RemoveAction = {
	type: typeof REMOVE;
	payload: {
		teamId: TeamKey;
		iterationId: IterationId;
	};
};

export type UpdateTeamIdAction = {
	type: typeof UPDATE_TEAM_ID;
	payload: {
		oldId: TeamKey;
		newId: TeamKey;
	};
};

export const reset = (payload: OriginalPlannedCapacities) => ({
	type: RESET,
	payload,
});

export const update = (payload: OriginalPlannedCapacity) => ({
	type: UPDATE,
	payload,
});

export const remove = (payload: { teamId: TeamKey; iterationId: IterationId }) => ({
	type: REMOVE,
	payload,
});

export const updateTeamId = (payload: { oldId: string; newId: string }) => ({
	type: UPDATE_TEAM_ID,
	payload,
});
