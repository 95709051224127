import {
	getCapacityForTeamAndIteration,
	getOriginalCapacityForTeamAndIteration,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/index.tsx';
import { getJiraHoursPerDay } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/system/index.tsx';
import { getTimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/timeline/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { TimelineRange } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import { SCHEDULE_MODE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type {
	OwnProps,
	StateProps,
	PlannedCapacityNullable,
	OriginalPlannedCapacityNullable,
} from './types.tsx';

const getPlannedCapacity = (
	state: State,
	{ sprint, teamObject }: OwnProps,
): PlannedCapacityNullable => {
	if (!teamObject) {
		return undefined;
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return getCapacityForTeamAndIteration(state, {
		teamId: teamObject.id,
		iterationId:
			teamObject.schedulingMode === SCHEDULE_MODE.scrum ? sprint.id : `${sprint.startDate}`,
	}) as PlannedCapacityNullable;
};

const getOriginalPlannedCapacity = (
	state: State,
	{ sprint, teamObject }: OwnProps,
): OriginalPlannedCapacityNullable => {
	if (!teamObject) {
		return undefined;
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return getOriginalCapacityForTeamAndIteration(state, {
		teamId: teamObject.id,
		iterationId:
			teamObject.schedulingMode === SCHEDULE_MODE.scrum ? sprint.id : `${sprint.startDate}`,
	}) as OriginalPlannedCapacityNullable;
};

export const mapStateToPropsPure = (
	scenarioPlannedCapacity: PlannedCapacityNullable,
	originalPlannedCapacity: OriginalPlannedCapacityNullable,
	timelineRange: TimelineRange,
	workingHours: number,
): {
	isUpdated: boolean;
	plannedCapacity: PlannedCapacityNullable;
	timelineRange: TimelineRange;
	workingHours: number;
} => ({
	isUpdated: !!originalPlannedCapacity,
	plannedCapacity: scenarioPlannedCapacity,
	timelineRange,
	workingHours,
});

export default createSelector<
	State,
	OwnProps,
	PlannedCapacityNullable,
	OriginalPlannedCapacityNullable,
	TimelineRange,
	number,
	StateProps
>(
	[getPlannedCapacity, getOriginalPlannedCapacity, getTimelineRange, getJiraHoursPerDay],
	mapStateToPropsPure,
);
