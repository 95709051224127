import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	selectiveSchedulingDescriptionParagraphOne: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.selective-scheduling-description-paragraph-one',
		defaultMessage:
			'Select which estimated issues you want to auto-schedule using the checkboxes in the Scope section of your plan.',
		description:
			'The first paragraph of the description text for the auto-schedule dialog with selective scheduling functionality highlighted.',
	},
	selectiveSchedulingDescriptionParagraphTwo: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.selective-scheduling-description-paragraph-two',
		defaultMessage:
			'If you select none, the auto-scheduler will create a plan for all of your estimated issues based on their ranking, the velocity of teams in your plan, and any dependencies you set.',
		description:
			'The second paragraph of the description text for the auto-schedule dialog with selective scheduling functionality highlighted.',
	},
	iphLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.iph-link',
		defaultMessage: 'See what the auto-scheduler does',
		description: 'Subscript link to the IPH article about the auto-scheduler',
	},
	selectiveSchedulingSelectedIssues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.selective-scheduling-selected-issues',
		defaultMessage: '{count, plural, one {# issue} other {# issues}} selected',
		description: 'The description text to indicate how many issues are being auto-scheduled.',
	},
	overwriteTitle: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.overwrite-title',
		defaultMessage: 'Overwriting issue values',
		description: 'This is the title of the configuration section.',
	},
	overwriteDescription: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.overwrite-description',
		defaultMessage:
			'You can choose to overwrite fields that have existing values, or to set values only if the fields are empty.',
		description: 'This is the description of the configuration section.',
	},
	overwriteAllValues: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.overwrite-all-values',
		defaultMessage: 'All values',
		description: 'It is a label of the option for overwriting all values.',
	},
	overwriteEmptyValuesOnly: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.overwrite-empty-values-only',
		defaultMessage: 'Empty values only',
		description: 'It is a label of the option for overwriting empty values only.',
	},
	previewResults: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.optimize.configuration-dialog.preview-results',
		defaultMessage: 'Preview results',
		description: 'It is a label of preview results button.',
	},
});
