import {
	startOfUtcDay,
	endOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import {
	useTimelineRuler,
	useHorizontalScrolling,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import { TIMELINE_MODES } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Bounding, ClickHandler, DestinationGetter, Props } from './types.tsx';

/** Returns the new value of viewport start time that the left arrow is trying to get to when being clicked.  */
const getDestinationForLeftArrow: DestinationGetter = ({
	issue,
	viewport,
	viewportWidth,
	containerWidth,
	paddingSize,
	shouldTimelineRangeExpand = false,
}) => {
	const { start: issueStart, end: issueEnd } = issue;
	const { start: viewportStart, end: viewportEnd } = viewport;

	const msPerPx = ((): number => {
		if (shouldTimelineRangeExpand) {
			if (isDefined(issueStart)) {
				return (viewportEnd - issueStart) / (viewportWidth - paddingSize);
			}

			if (isDefined(issueEnd)) {
				const issueBarWidth = containerWidth * 0.3;
				return (viewportEnd - issueEnd) / (viewportWidth - paddingSize - issueBarWidth);
			}

			return viewport.start;
		}

		return (viewportEnd - viewportStart) / viewportWidth;
	})();

	const paddingSizeInMs = paddingSize * msPerPx;

	if (isDefined(issueStart)) {
		return issueStart - paddingSizeInMs;
	}

	if (isDefined(issueEnd)) {
		const issueBarWidth = containerWidth * 0.3;
		const issueBarWidthInMs = issueBarWidth * msPerPx;
		return issueEnd - issueBarWidthInMs - paddingSizeInMs;
	}

	return viewport.start;
};

/** Returns the new value of viewport end time that the right arrow is trying to get to when being clicked.  */
const getDestinationForRightArrow: DestinationGetter = ({
	issue,
	viewport,
	viewportWidth,
	containerWidth,
	paddingSize,
	shouldTimelineRangeExpand = false,
}) =>
	// Flip the timeline in a mirror and click on the left arrow instead.
	0 -
	getDestinationForLeftArrow({
		issue: {
			start: issue.end == null ? issue.end : -issue.end,
			end: issue.start == null ? issue.start : -issue.start,
		},
		viewport: {
			start: -viewport.end,
			end: -viewport.start,
		},
		viewportWidth,
		containerWidth,
		paddingSize,
		shouldTimelineRangeExpand,
	});
/**
 * Returns the destination getter depends on quick direction the arrow is.
 * checkout `getDestinationForLeftArrow` and `getDestinationForRightArrow` for more information.
 */
const getDestination: (direction: 'left' | 'right') => DestinationGetter = (direction) => {
	switch (direction) {
		case 'left':
			return getDestinationForLeftArrow;

		case 'right':
			return getDestinationForRightArrow;

		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return (direction as never);
	}
};

/**
 * Returns the timeline range of the viewport when horizontal scrolling mode is on/off
 */
export function useViewportTimelineRange(fallback: { start: number; end: number }) {
	const [{ zoomLevel, viewport, today }] = useHorizontalScrolling();
	const [{ pxToMs }] = useTimelineRuler();
	return zoomLevel
		? {
				start: today + viewport.offset,
				end: today + viewport.offset + pxToMs(viewport.width),
			}
		: fallback;
}

/**
 * Returns the viewport width depends on whether the timeline mode support scrolling or not
 */
export function useViewportWidth(fallback: number) {
	const [{ zoomLevel, viewport }] = useHorizontalScrolling();

	if (zoomLevel === undefined) {
		return fallback;
	}

	return viewport.width;
}

/**
 * Returns the container width depends on whether the timeline mode support scrolling or not
 */
export function useContainerWidth(fallback: number) {
	const [{ zoomLevel, container }] = useHorizontalScrolling();

	if (zoomLevel === undefined) {
		return fallback;
	}

	return container.width;
}

/** Returns a click handing function for when the arrow is clicked. */
export function useArrowClickHandler({
	changeTimeScaleCustomDates,
	changeTimeScaleOption,
	viewportWidth,
	containerWidth,
	viewport,
}: {
	changeTimeScaleCustomDates: Props['onTimeScaleCustomDatesChange'];
	changeTimeScaleOption: Props['onTimeScaleOptionChange'];
	viewportWidth: number;
	containerWidth: number;
	viewport: Bounding<Timestamp>;
}): ClickHandler {
	const [{ zoomLevel }, { setViewportOffset }] = useHorizontalScrolling();
	const isHorizontalScrollable = zoomLevel !== undefined;

	return (
		direction: 'left' | 'right',
		issue: Bounding<Timestamp | null | undefined>,
		paddingSize: number,
	) => {
		// Get the timestamp that the arrow are trying to get to.
		const destination = getDestination(direction)({
			issue,
			viewport,
			viewportWidth,
			containerWidth,
			paddingSize,
			shouldTimelineRangeExpand: !isHorizontalScrollable,
		});
		if (!isHorizontalScrollable) {
			changeTimeScaleOption(TIMELINE_MODES.CUSTOM);

			switch (direction) {
				case 'left':
					changeTimeScaleCustomDates({
						fromDate: startOfUtcDay(destination),
						toDate: viewport.end,
					});
					break;

				case 'right':
					changeTimeScaleCustomDates({
						fromDate: viewport.start,
						toDate: endOfUtcDay(destination) + 1,
					});
					break;

				default:
					// prettier-ignore
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					return (direction as never);
			}
		} else {
			switch (direction) {
				case 'left':
					return setViewportOffset((offset) => offset + destination - viewport.start, 'arrow');

				case 'right':
					return setViewportOffset((offset) => offset + destination - viewport.end, 'arrow');

				default:
					// prettier-ignore
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					return (direction as never);
			}
		}
	};
}
