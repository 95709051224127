import React from 'react';
import Button from '@atlaskit/button';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/glyph/arrow-up';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { SORT_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

export const Sort = ({ sortDirection, onClick, isSorted }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<Button
			appearance="subtle"
			style={{
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				display: 'flex',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				alignItems: 'center',
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				width: token('space.200', '16px'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				height: token('space.200', '16px'),
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				padding: 0,
			}}
			onClick={onClick}
			aria-label={formatMessage(
				sortDirection === SORT_DIRECTION.ASCENDING
					? messages.sortAscAriaLabel
					: messages.sortDescAriaLabel,
			)}
		>
			<Box
				xcss={alignedStyles}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				style={{ color: isSorted ? undefined : token('color.text.subtlest', colors.N200) }}
			>
				{sortDirection === SORT_DIRECTION.ASCENDING ? (
					<ArrowUpIcon size="small" label="" />
				) : (
					<ArrowDownIcon size="small" label="" />
				)}
			</Box>
		</Button>
	);
};

const alignedStyles = xcss({
	display: 'block',
	marginTop: 'space.025',
	marginLeft: 'space.negative.025',
});
