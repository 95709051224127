import {
	clear,
	change,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/filters/index.tsx';
import {
	STATUS_KEY_FILTER_ID,
	type StatusKeyFilterValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	changeFilter: (payload: StatusKeyFilterValue) => {
		dispatch(change({ id: STATUS_KEY_FILTER_ID, value: payload }));
	},
	clearFilter: () => {
		dispatch(clear(STATUS_KEY_FILTER_ID));
	},
});

export default mapDispatchToProps;
