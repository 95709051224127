import { useEffect, useRef } from 'react';
import dropRight from 'lodash/fp/dropRight';
import {
	getContainerWidth,
	getContainerDuration,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/utils/index.tsx';
import {
	useTimelineRuler,
	useHorizontalScrolling,
	useZoomLevel,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import {
	useScrollX,
	useViewport,
} from '@atlassian/jira-portfolio-3-treegrid/src/controllers/container/index.tsx';

export const useScrollXSync = (leftPadding: number) => {
	const [
		,
		{
			scrollTo: horizontalScrollTo,
			addScrollHandler: horizontalAddEventListener,
			removeScrollHandler: horizontalRemoveEventListener,
		},
	] = useHorizontalScrolling();

	const horizontalScrollXRef = useRef<number>();
	const [
		,
		{
			scrollTo: treegridScrollTo,
			addScrollHandler: treegridAddEventListener,
			removeScrollHandler: treegridRemoveEventListener,
		},
	] = useScrollX();

	// Add event handler to treegrid scroll event
	useEffect(() => {
		const handler = ({ scrollX }: { scrollX: number }) => {
			if (scrollX - leftPadding !== horizontalScrollXRef.current) {
				horizontalScrollTo(scrollX - leftPadding, 'external');
				horizontalScrollXRef.current = scrollX - leftPadding;
			}
		};

		treegridAddEventListener(handler);

		return () => {
			treegridRemoveEventListener(handler);
		};
	}, [horizontalScrollTo, leftPadding, treegridAddEventListener, treegridRemoveEventListener]);

	// Add event handler to horizontal scrolling scroll event
	useEffect(() => {
		const handler = ({
			offsetInPx,
			smooth,
			source,
		}: {
			offsetInPx: number;
			smooth: boolean;
			source: string;
		}) => {
			const nextTreegridScrollX = Math.round(offsetInPx) + leftPadding;
			if (source !== 'external') {
				horizontalScrollXRef.current = Math.round(offsetInPx);
				treegridScrollTo({ x: nextTreegridScrollX, smooth });
			}
		};

		horizontalAddEventListener(handler);

		return () => {
			horizontalRemoveEventListener(handler);
		};
	}, [horizontalAddEventListener, horizontalRemoveEventListener, treegridScrollTo, leftPadding]);
};

/** Syncs the grid viewport and horizontal scrolling viewport */
export const useViewportSync = (columnWidths: number[]) => {
	const [zoomLevel] = useZoomLevel();
	const [viewport] = useViewport();
	const [, { resize }] = useTimelineRuler();

	const horizontalScrollViewportWidth =
		viewport.width - dropRight(1)(columnWidths).reduce((a, b) => a + b, 0);

	// Reset the horizontal scroll viewport
	useEffect(() => {
		if (!zoomLevel) {
			return;
		}

		resize({
			viewport: {
				width: horizontalScrollViewportWidth,
				position: {
					// TODO: Do we need these?
					left: 0,
					top: 0,
				},
			},
			container: {
				width: getContainerWidth(zoomLevel),
				duration: getContainerDuration(zoomLevel),
			},
		});
	}, [zoomLevel, resize, horizontalScrollViewportWidth]);

	return null;
};
