import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';

const SCROLLBAR_HEIGHT = 10;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Track = styled.div({
	position: 'absolute',
	height: `${SCROLLBAR_HEIGHT}px`,
	backgroundColor: `${token('elevation.shadow.overlay', 'rgba(0,0,0,0)')}`,
	borderRadius: `${SCROLLBAR_HEIGHT}px`,
	left: 0,
	right: 0,
	bottom: 0,

	'&:hover': {
		transition: 'background 0.1s ease-in 0s',
		backgroundColor: `${token('color.background.inverse.subtle.pressed', 'rgba(0,0,0,0.3)')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Thumb = styled.div<{ width: number; left: number }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: ({ width }) => `${width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	transform: ({ left }) => `translateX(${left}px)`,
	position: 'absolute',
	height: `${SCROLLBAR_HEIGHT}px`,
	borderRadius: `${SCROLLBAR_HEIGHT}px`,
	backgroundColor: `${token('color.background.inverse.subtle.pressed', 'rgba(0,0,0,0.4)')}`,
});
