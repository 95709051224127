import {
	isConfluenceMacro,
	isEmbed,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getAssociatedIssueIds } from '../../query/issue-associated-issues/index.tsx';
import type { State } from '../../state/types.tsx';
import type { StateProps } from './types.tsx';

const mapStateToProps = (state: State): StateProps => ({
	associatedIssueIds: getAssociatedIssueIds(state),
	isConfluenceMacro: isConfluenceMacro(state),
	isEmbed: isEmbed(state),
});

export default mapStateToProps;
