import React, { type ReactNode } from 'react';
import {
	PlanActionMenuStoreContainer,
	PlanActionMenuStoreSubscriber,
} from '@atlassian/jira-jet-plan-action-menu/src/controllers/menu-state/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { CtpOnboardingProvider } from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctp-onboarding/index.tsx';
import {
	CttOnboardingProvider,
	useCttOnboardingTour,
} from '@atlassian/jira-portfolio-3-onboarding/src/controllers/ctt-onboarding/index.tsx';
import {
	useOnboardingTour,
	OnboardingProvider,
} from '@atlassian/jira-portfolio-3-onboarding/src/controllers/index.tsx';
import { CtpOnboarding } from '@atlassian/jira-portfolio-3-onboarding/src/ui/ctp-onboarding/index.tsx';
import { CttOnboarding } from '@atlassian/jira-portfolio-3-onboarding/src/ui/ctt-onboarding/index.tsx';
import { FlagGroupGuard } from '@atlassian/jira-portfolio-3-onboarding/src/ui/flag-group-guard/index.tsx';
import { Onboarding } from '@atlassian/jira-portfolio-3-onboarding/src/ui/onboarding/index.tsx';
import CtpOnboardingPlanDataSubscriber from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/ctp-onboarding-plan-data-subscriber/index.tsx';
import CttOnboardingPlanDataSubscriber from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/ctt-onboarding-plan-data-subscriber/index.tsx';
import ShouldShowCtpOnboarding from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/should-show-ctp-onboarding/index.tsx';
import ShouldShowCttOnboarding from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/should-show-ctt-onboarding/index.tsx';
import UserIsOnboarded from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/user-is-onboarded/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { isCrossTeamPlanningTemplateExperimentEnabled } from './utils.tsx';

interface Props {
	/** Renders only when there is no onboarding disruption. */
	children: ReactNode;
}

export const PlanOnboarding = ({ children }: Props) => {
	const [{ visible }, { closeOnboarding }] = useOnboardingTour();
	const [{ isTakingOnboardingTour }, { resetCttTakingTour }] = useCttOnboardingTour();

	const isCrossTeamPlanningTemplateEnabled = isCrossTeamPlanningTemplateExperimentEnabled();

	const accountId = useAccountId();
	// Short-circuit CTP/CTT changeboarding if there is active onboarding tour
	if (!accountId || visible) return null;

	const withCrossTeamTemplateOnboarding = (onboarding: ReactNode) => (
		<ShouldShowCttOnboarding>
			{(enabled) => (
				<>
					{enabled && (
						<CttOnboardingProvider isGlobal accountId={accountId}>
							<CttOnboardingPlanDataSubscriber>
								{(props) => {
									if (getWillShowNav4()) {
										if (props.planId == null) return null;

										return (
											<PlanActionMenuStoreContainer scope="main-plan">
												<PlanActionMenuStoreSubscriber>
													{(_state, { toggleMenu }) => (
														<CttOnboarding
															setOpenHorizontalPlanActionMenu={() => toggleMenu(true)}
															{...props}
														/>
													)}
												</PlanActionMenuStoreSubscriber>
											</PlanActionMenuStoreContainer>
										);
									}
									return <CttOnboarding {...props} />;
								}}
							</CttOnboardingPlanDataSubscriber>
							<Onboarding
								visible={isTakingOnboardingTour}
								onClose={() => {
									closeOnboarding();
									resetCttTakingTour();
								}}
							/>
						</CttOnboardingProvider>
					)}
					{!enabled && onboarding}
				</>
			)}
		</ShouldShowCttOnboarding>
	);

	const withCrossTeamPlanningOnboarding = (onboarding: ReactNode) => {
		if (!isCrossTeamPlanningTemplateEnabled) {
			return onboarding;
		}
		return (
			<ShouldShowCtpOnboarding>
				{(enabled) => (
					<>
						{enabled && (
							<CtpOnboardingProvider isGlobal accountId={accountId}>
								<Onboarding />
								<CtpOnboardingPlanDataSubscriber>
									{(props) => <CtpOnboarding {...props} />}
								</CtpOnboardingPlanDataSubscriber>
							</CtpOnboardingProvider>
						)}
						{!enabled && onboarding}
					</>
				)}
			</ShouldShowCtpOnboarding>
		);
	};

	return (
		<OnboardingProvider>
			<FlagGroupGuard
				forChangeboarding={false}
				forCttOnboarding
				forCtpOnboarding={isCrossTeamPlanningTemplateEnabled}
			>
				{children}
			</FlagGroupGuard>
			{withCrossTeamTemplateOnboarding(
				withCrossTeamPlanningOnboarding(
					<UserIsOnboarded>
						{(userIsOnboarded) => (userIsOnboarded ? null : <Onboarding />)}
					</UserIsOnboarded>,
				),
			)}
		</OnboardingProvider>
	);
};
