import * as R from 'ramda';
import { SUB_TASK_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import {
	mapGroupsToIds,
	isDefined,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { RollUps, DescendantsMap, IssueRollUp } from '../issues/types.tsx';
import { getPlanningUnit } from '../plan/index.tsx';
import { getShowRolledUpOthers } from '../view-settings/index.tsx';
import { getAllIssues, getIssues, getIssueMapById, type IssueMap } from './index.tsx';

// CAUTION: This file is created for resolving circular dependencies
// when using getRollupMap in getSort from "sort-utils.js"
// all these selectors are reexported in "app-simple-plans/query/issues" supposed to be used as before,
// except the cases with circular dependencies
export const getSprintWithDescendantsSprints = (
	sprints: string[] | undefined | null,
	descendant: Issue,
) => {
	if (
		(!isDefined(descendant.sprint) &&
			descendant.completedSprints &&
			descendant.completedSprints.length === 0) ||
		descendant.level === SUB_TASK_LEVEL
	) {
		return sprints;
	}

	if (!isDefined(descendant.sprint)) {
		return [...(sprints || []), ...(descendant.completedSprints || [])];
	}
	return [...(sprints || []), descendant.sprint, ...(descendant.completedSprints || [])] as const;
};

// Note: configured date rollups handled elsewhere
export const getRollupMapPure = (
	issues: Issue[],
	descendantsMap: {
		[key: string]: Issue[];
	},
	planningUnit: string,
	showRolledUpOthers: boolean,
): RollUps => {
	const estimateAttribute = planningUnit === 'StoryPoints' ? 'storyPoints' : 'timeEstimate';

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const rollupMap: Record<string, any> = {};

	if (!showRolledUpOthers) return rollupMap;

	const getEstimate = (estimateSum: number | null, descendant: Issue) => {
		if (estimateSum == null && R.isNil(descendant[estimateAttribute])) {
			return null;
		}
		if (R.isNil(descendant[estimateAttribute])) {
			return estimateSum;
		}
		return (estimateSum || 0) + (descendant[estimateAttribute] || 0);
	};

	const getReleases = (releases: null | string[], descendant: Issue) => {
		if (releases === null && R.isNil(descendant.fixVersions)) {
			return null;
		}
		if (R.isNil(descendant.fixVersions)) {
			return releases;
		}
		return [...(releases || []), ...(descendant.fixVersions || [])] as const;
	};

	// notice the shared team
	const getTeam = (
		team: Array<string | null | undefined> | null | undefined,
		descendant: Issue,
	) => {
		if (team === null && R.isNil(descendant.team)) {
			return null;
		}

		if (R.isNil(descendant.team) || descendant.level === SUB_TASK_LEVEL) {
			return team;
		}

		return [...(team || []), descendant.team] as const;
	};

	for (const issue of issues) {
		rollupMap[issue.id] =
			descendantsMap[issue.id] &&
			descendantsMap[issue.id].reduce<IssueRollUp>(
				({ estimate, release, team, sprint }, descendant) => ({
					release: [...new Set(getReleases(release, descendant))],
					estimate: getEstimate(estimate, descendant),
					team: [...new Set(getTeam(team, descendant))],
					sprint: [...new Set(getSprintWithDescendantsSprints(sprint, descendant))],
				}),
				{
					estimate: null,
					release: null,
					team: null,
					sprint: null,
				},
			);
	}
	return rollupMap;
};

export const getChildrenByParentPure = (issues: Issue[]): Record<string, Issue[]> =>
	// @ts-expect-error Ramda types are not very good for working with objects
	R.pipe(R.filter(R.has('parent')), R.groupBy(R.prop('parent')))(issues);

export const getChildrenByParent = createSelector([getIssues], getChildrenByParentPure);

export const getChildrenIdsByParent = createSelector([getChildrenByParent], mapGroupsToIds);

export const getDescendants = (
	issue: Issue,
	issueMap: IssueMap,
	descendantsMap: DescendantsMap,
	maxDescendantsLevel: number,
	descendants: Issue[] = [],
	descendantParents: Set<string> = new Set(),
): Issue[] => {
	descendantParents.add(issue.id);
	for (const descendantId of descendantsMap[issue.id] || []) {
		const issueObj = issueMap[descendantId];
		if (issueObj.level >= maxDescendantsLevel && !descendantParents.has(issueObj.id)) {
			descendants.push(issueObj);
			getDescendants(
				issueObj,
				issueMap,
				descendantsMap,
				maxDescendantsLevel,
				descendants,
				descendantParents,
			);
		}
	}
	return descendants;
};

export const getDescendantsByParentPure = (
	issuesById: IssueMap,
	childrenIdsByParent: DescendantsMap,
): Record<string, Issue[]> =>
	R.map(
		(issue) => getDescendants(issue, issuesById, childrenIdsByParent, SUB_TASK_LEVEL),
		issuesById,
	);

export const getDescendantsByParent = createSelector(
	[getIssueMapById, getChildrenIdsByParent],
	getDescendantsByParentPure,
);

export const getRollupMap = createSelector(
	[getAllIssues, getDescendantsByParent, getPlanningUnit, getShowRolledUpOthers],
	getRollupMapPure,
);
