import * as status from '@atlassian/jira-portfolio-3-portfolio/src/common/api/request.tsx';
import {
	SENT,
	type SentAction,
	SUCCESS,
	type SuccessAction,
	ERROR,
	type ErrorAction,
} from './actions.tsx';
import type { Changes } from './types.tsx';

const initialState = {
	status: status.UNSENT,
} as const;

export type Action = SentAction | SuccessAction | ErrorAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Changes = initialState, action: Action) => {
	switch (action.type) {
		case SENT:
			return { status: status.LOADING };
		case SUCCESS:
			return { status: status.SUCCESS, data: action.payload };
		case ERROR:
			return { status: status.ERROR, error: action.payload };
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
