import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.deleting-dialog.heading',
		defaultMessage: 'Delete saved view',
		description: 'Label for view deleting confirming dialog heading',
	},
	message: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.deleting-dialog.message',
		defaultMessage:
			'Deleting <strong>{name}</strong> will remove it from this plan, and everyone currently using this view will no longer see it.',
		description: 'The message for view deleting confirming dialog',
	},
});
