import { updateIssueStatus } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue-status/index.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { StatusTransition } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dispatchMethods = (dispatch: any) => ({
	onChange: (issue: ScopeIssue, transition: StatusTransition) => {
		dispatch(updateIssueStatus({ issue, transition }));
	},
});

export default dispatchMethods;
