export const PACKAGE_NAME = 'jiraPortfolio3CreateNewPlanWizardDrawer';
export const ERROR_REPORTING_TEAM = 'advanced-roadmaps';
export const ERROR_REPORTING_PACKAGE = 'planWizard';

export const INCLUDE_COMPLETED_ISSUE_FOR_DAYS = 30;

export const PERFORMANCE_CREATE_PLAN_WIZARD_LOAD_TIME = 'create-plan-wizard.load-time';
export const PLANNING_UNITS_DAYS = 'Days';
export const RELEASE_STATUSES_RELEASED = '1';
export const IsoDateFormat = 'YYYY-MM-DD';
export const DefaultDateFormat = 'DD/MMM/YY';
