import { getSprintFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/sprint-filter/index.tsx';
import { getPlan } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/plan/index.tsx';
import { getTeamsWorkingWithSprints } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import {
	getSprintStreamsUnitsForTeams,
	getSprintStreamsUnitsGetter,
	getSprintIdsWithDatesIsInconsistent,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/sprints/index.tsx';
import { getTeamsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/teams/index.tsx';
import { getWarningViewSettings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Props, StateProps } from './types.tsx';

export default createStructuredSelector<State, Props, StateProps>({
	filteredIds: (state) => getSprintFilter(state).value,
	getStreamsUnits: getSprintStreamsUnitsGetter,
	plan: getPlan,
	showWarning: (state) => getWarningViewSettings(state).showWarning,
	sprintIdsWithDatesIsInconsistent: getSprintIdsWithDatesIsInconsistent,
	streams: (state: State, props: Props) => getSprintStreamsUnitsForTeams(state)[props.team],
	teamObject: (state: State, props: Props) => getTeamsById(state)[props.team],
	teamsWithSprints: (state: State) => getTeamsWorkingWithSprints(state),
});
