/*
* the Filters inlineDialog width is 496px, minus 48px padding
* labels get 170px width of which 16px of right padding
* filters get 496 - 48 - 170 = 278px width
*/
.filterButtonWrapper > button {
	text-align: left;
	width: 278px;
}

.filterButton {
	display: flex;
	align-items: center;
}

.filterButtonApplied {
	font-weight: bold;
}

.buttonText {
	overflow: hidden;
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.issueSelector {
	line-height: normal;
	min-width: 232px;
	padding-left: 44px;
	padding-right: 24px;
}

.includeTransitive {
	padding-left: 36px;
	padding-right: 24px;
}
