export const OPEN_HIDDEN_ISSUES_DIALOG =
	'state.ui.top.title-bar.hidden-issues.OPEN_HIDDEN_ISSUES_DIALOG' as const;

export const CLOSE_HIDDEN_ISSUES_DIALOG =
	'state.ui.top.title-bar.hidden-issues.CLOSE_HIDDEN_ISSUES_DIALOG' as const;

/** OPEN_HIDDEN_ISSUES_DIALOG */
export type OpenDialogAction = {
	type: typeof OPEN_HIDDEN_ISSUES_DIALOG;
};

/** CLOSE_HIDDEN_ISSUES_DIALOG */
export type CloseDialogAction = {
	type: typeof CLOSE_HIDDEN_ISSUES_DIALOG;
};
