import type { EnrichedVersion } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/versions/types.tsx';
import type { CreateIssueOptions } from './types.tsx';

const getReleaseProjectId = (
	releaseId: string,
	enrichedVersions: EnrichedVersion[],
): number | undefined => {
	const release = enrichedVersions.find((version) => version.id === releaseId);

	if (!release) return;

	const project = release.projects[0];

	return typeof project === 'number' ? project : project.id;
};

export const getProjectId = (
	selectedOptions: Partial<CreateIssueOptions>,
	enrichedVersions: EnrichedVersion[],
): number | undefined => {
	const projectOption = selectedOptions.project;

	const projectId = projectOption?.value?.project;

	if (!projectId) {
		const groupOption = selectedOptions.group;
		const releaseId = groupOption?.value?.release;

		if (!releaseId) return undefined;

		return getReleaseProjectId(releaseId, enrichedVersions);
	}

	return projectId;
};
