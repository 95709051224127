import React from 'react';
import { Text } from '@atlaskit/primitives';
import { FormattedMessage } from '@atlassian/jira-intl';
import ProgressCard from '@atlassian/jira-portfolio-3-common/src/progress-card/index.tsx';
import Spinner from '@atlassian/jira-portfolio-3-common/src/spinner/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function Progress(props: Props) {
	const { label, countOfThingsDone, countOfThingsToDo } = props;
	const percentage =
		countOfThingsToDo === 0 ? 0 : Math.floor((100 * countOfThingsDone) / countOfThingsToDo);
	return (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles.container}>
			<ProgressCard
				label={
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.labelWrapper}>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<span className={styles.spinnerWrapper}>
							<Spinner size="small" />
						</span>
						<Text as="strong">
							<FormattedMessage {...label} />
						</Text>
					</div>
				}
				percentage={percentage}
				progressMessage={
					<FormattedMessage
						{...messages.progress}
						values={{
							countOfThingsDone,
							countOfThingsToDo,
							percentage,
						}}
					/>
				}
			/>
		</div>
	);
}
