import {
	ONE_DAY,
	ONE_WEEK,
	startOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import type { ZoomLevel } from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/common/types.tsx';
import type { Unit, RelativeTimeRangeOffset } from './types.tsx';

/** Makes timeline units.
 * @param from the offset (in ms) of the start of the rendering window considering today as origin.
 * @param to the offset (in ms) of the end of the rendering window considering today as origin.
 * @param initialize a function to get the initial start date of first unit base on the current estimated start date of the rendering window.
 * @param next a function to get the next unit's start date from the current unit start date.
 */
const makeUnits = (
	{ from, to }: RelativeTimeRangeOffset,
	type: Unit['type'],
	initialize: (arg1: Date) => Date,
	next: (arg1: Date) => Date,
): Unit[] => {
	const today = startOfUtcDay(Date.now());
	const fromTs = today + from;
	const toTs = today + to;

	let dateCursor = new Date(fromTs);
	dateCursor.setUTCHours(0, 0, 0, 0);
	dateCursor = initialize(dateCursor);

	const units: Unit[] = [];

	while (dateCursor.getTime() < toTs) {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const from = dateCursor.getTime() - today;
		dateCursor = next(dateCursor);
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const to = dateCursor.getTime() - today;
		units.push({ from, to, type });
	}

	return units;
};

/** Gets timeline units to get days within the rendering window. */
export const getDayUnits = ({ from, to }: RelativeTimeRangeOffset) => {
	const initialize = (date: Date) => new Date(date.getTime() - (date.getUTCDay() - 1) * ONE_DAY); // Reset to Monday of the week
	const next = (date: Date) => new Date(date.getTime() + ONE_DAY);

	return makeUnits({ from, to }, 'week', initialize, next);
};

/** Gets timeline units to render weeks within the rendering window. */
const getWeekUnits = ({ from, to }: RelativeTimeRangeOffset) => {
	const initialize = (date: Date) => new Date(date.getTime() - (date.getUTCDay() - 1) * ONE_DAY); // Reset to Monday of the week
	const next = (date: Date) => new Date(date.getTime() + ONE_WEEK);

	return makeUnits({ from, to }, 'week', initialize, next);
};

/** Gets timeline units to render months within the rendering window. */
const getMonthUnits = ({ from, to }: RelativeTimeRangeOffset) => {
	const initialize = (date: Date) => {
		date.setUTCDate(1); // Reset to 1st of the month
		return date;
	};
	const next = (date: Date) => {
		date.setUTCMonth(date.getUTCMonth() + 1, 1);
		return date;
	};

	return makeUnits({ from, to }, 'month', initialize, next);
};

/** Gets timeline units to render quarters within the rendering window. */
const getQuarterUnits = ({ from, to }: RelativeTimeRangeOffset, fiscalMonth: number) => {
	const offset = fiscalMonth - 1;
	const initialize = (date: Date) => {
		const dateMonth = date.getUTCMonth();
		// setting the date to the first day of the quarter it belongs to
		// e.g. if date is "Wed Jun 09 2021", it gets converted to "Thu Apr 01 2021"
		date.setUTCMonth(dateMonth - (dateMonth % 3) + offset, 1);
		if (fiscalMonth > 1) {
			// if our FY starts after january, we'll always need to see a portion of the previous calendar year
			const dateYear = date.getUTCFullYear();
			date.setUTCFullYear(dateYear - 1);
		}
		return date;
	};
	const next = (date: Date) => {
		date.setUTCMonth(date.getUTCMonth() + 3, 1);
		return date;
	};

	return makeUnits({ from, to }, 'quarter', initialize, next);
};

/** Gets timeline units to render years within the rendering window. */
const getYearUnits = ({ from, to }: RelativeTimeRangeOffset, fiscalMonth: number) => {
	const initialize = (date: Date) => {
		// setting the date to the first day of the year
		date.setUTCMonth(fiscalMonth - 1, 1);
		if (fiscalMonth > 1) {
			const dateYear = date.getUTCFullYear();
			date.setUTCFullYear(dateYear - 1);
		}

		return date;
	};
	const next = (date: Date) => {
		date.setUTCFullYear(date.getUTCFullYear() + 1);
		return date;
	};

	return makeUnits({ from, to }, 'year', initialize, next);
};

/**
 * Gets timeline units to render within the rendering window
 * @param from the offset (in ms) of the start of the rendering window considering today as origin.
 * @param to the offset (in ms) of the end of the rendering window considering today as origin.
 * @param zoomLevel the current zoomLevel setting.
 * @param fiscalMonth the financial / fiscal year start month, defaulted to 1 (i.e. Jan).
 */
export const getUnits = (
	{ from, to }: RelativeTimeRangeOffset,
	zoomLevel: ZoomLevel,
	fiscalMonth = 1,
) => {
	switch (zoomLevel) {
		case 'weeks':
			return getWeekUnits({ from, to });

		case 'months':
			return getMonthUnits({ from, to });

		case 'quarters':
			return getQuarterUnits({ from, to }, fiscalMonth);

		case 'years':
			return getYearUnits({ from, to }, fiscalMonth);

		default:
			// prettier-ignore
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			return (zoomLevel as never);
	}
};
