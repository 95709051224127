import { type MessageDescriptor, defineMessages } from '@atlassian/jira-intl';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export default defineMessages({
	// Section Titles
	general: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.general',
		defaultMessage: 'General',
		description: 'Label for general legend section',
	},
	issueBars: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.issue-bars',
		defaultMessage: 'Issue bars',
		description: 'Label for issue bars legend section',
	},
	releases: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.releases',
		defaultMessage: 'Releases',
		description: 'Label for releases legend section',
	},
	dependencies: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.dependencies',
		defaultMessage: 'Dependencies',
		description: 'Label for dependencies legend section',
	},
	// General
	sprints: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.sprints',
		defaultMessage: 'Sprints',
		description: 'Label for sprints icon',
	},
	rollup: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.rollup',
		defaultMessage: 'Inferred value (roll-up)',
		description: 'Label for rollup value icon',
	},
	sprintDates: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.sprint-dates',
		defaultMessage: 'Sprint dates',
		description: 'Label for sprint dates icon',
	},
	releaseDates: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.release-dates',
		defaultMessage: 'Release dates',
		description: 'Label for release dates icon',
	},
	dateFields: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.date-fields',
		defaultMessage: 'Date fields',
		description: 'Label for date fields icon',
	},
	unsavedChange: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.unsaved-change',
		defaultMessage: 'Unsaved change',
		description: 'Label for unsaved change icon',
	},
	// Issue bars
	noEndDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.no-end-date',
		defaultMessage: 'No end date',
		description: 'Label for no end date icon',
	},
	noStartDate: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.no-start-date',
		defaultMessage: 'No start date',
		description: 'Label for no start date icon',
	},
	rollupBar: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.rollup-bar',
		defaultMessage: 'Inferred dates (roll-up)',
		description: 'Label for rollup bar icon',
	},
	// Releases
	released: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.released',
		defaultMessage: 'Released',
		description: 'Label for released icon',
	},
	onTrack: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.on-track',
		defaultMessage: 'On-track',
		description: 'Label for on-track icon',
	},
	offTrack: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.off-track',
		defaultMessage: 'Off-track',
		description: 'Label for off-track icon',
	},
	// Dependencies
	dependency: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.dependency',
		defaultMessage: 'Dependency',
		description: 'Label for dependency icon',
	},
	dependencyWarning: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.dependency-warning',
		defaultMessage: 'Dependency warning',
		description: 'Label for dependency warning icon',
	},
	dependencyOffTrack: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.dependency-off-track',
		defaultMessage: 'Off-track dependencies',
		description: 'Label for off-track dependency icon',
	},
	numberOfDependencies: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.legend.number-of-dependencies',
		defaultMessage: 'Number of dependencies',
		description: 'Label for dependency count icon',
	},
}) as {
	general: MessageDescriptor;
	issueBars: MessageDescriptor;
	releases: MessageDescriptor;
	dependencies: MessageDescriptor;
	sprints: MessageDescriptor;
	rollup: MessageDescriptor;
	sprintDates: MessageDescriptor;
	releaseDates: MessageDescriptor;
	dateFields: MessageDescriptor;
	unsavedChange: MessageDescriptor;
	noEndDate: MessageDescriptor;
	noStartDate: MessageDescriptor;
	rollupBar: MessageDescriptor;
	released: MessageDescriptor;
	onTrack: MessageDescriptor;
	offTrack: MessageDescriptor;
	dependency: MessageDescriptor;
	dependencyWarning: MessageDescriptor;
	dependencyOffTrack: MessageDescriptor;
	numberOfDependencies: MessageDescriptor;
};
