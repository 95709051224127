import React from 'react';
import { xcss } from '@atlaskit/primitives';
import HorizontalScrollContainer from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/ui/container/index.tsx';

/** Acts as containing block to align elements which is relative to the timeline. */
const TimelineAligned: typeof HorizontalScrollContainer = (props) => (
	<HorizontalScrollContainer {...props} xcss={containerStyles} />
);

const containerStyles = xcss({
	height: '100%',
});

export { TimelineAligned };
