import memoizeOne from 'memoize-one';
import { getFilters } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/index.tsx';
import {
	getExternalOutgoingLinks,
	getExternalIncomingLinks,
	getInternalOutgoingLinks,
	getInternalIncomingLinks,
	getInternalOutgoingLinkOriginals,
	getInternalIncomingLinkOriginals,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-links/index.tsx';
import { DEPENDENCIES_FILTER_ID } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { IssueLink } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { MapStateToProps } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import { ISSUE_LINK_DIRECTION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { OwnProps, StateProps } from './types.tsx';

const externalIncomingLinksList: IssueLink[] = [];
const externalOutgoingLinksList: IssueLink[] = [];
const internalIncomingLinksList: IssueLink[] = [];
const internalIncomingLinksOriginalList: IssueLink[] = [];
const internalOutgoingLinksList: IssueLink[] = [];
const internalOutgoingLinksOriginalList: IssueLink[] = [];
const mapStateToPropsFactory: () => MapStateToProps<StateProps, OwnProps> = () => {
	const linksFactory = memoizeOne(
		(
			direction: OwnProps['direction'],
			externalIncomingLinks: IssueLink[],
			internalIncomingLinks: IssueLink[],
			internalIncomingLinksOriginal: IssueLink[],
			externalOutgoingLinks: IssueLink[],
			internalOutgoingLinks: IssueLink[],
			internalOutgoingLinksOriginal: IssueLink[],
		) =>
			direction === ISSUE_LINK_DIRECTION.INWARD
				? [externalIncomingLinks, internalIncomingLinks, internalIncomingLinksOriginal]
				: [externalOutgoingLinks, internalOutgoingLinks, internalOutgoingLinksOriginal],
	);
	return (state, props) => {
		const issueId = props.issue.id;

		const externalIncomingLinks =
			getExternalIncomingLinks(state)[issueId] || externalIncomingLinksList;
		const externalOutgoingLinks =
			getExternalOutgoingLinks(state)[issueId] || externalOutgoingLinksList;
		const internalIncomingLinks =
			getInternalIncomingLinks(state)[issueId] || internalIncomingLinksList;
		const internalIncomingLinksOriginal =
			getInternalIncomingLinkOriginals(state)[issueId] || internalIncomingLinksOriginalList;
		const internalOutgoingLinks =
			getInternalOutgoingLinks(state)[issueId] || internalOutgoingLinksList;
		const internalOutgoingLinksOriginal =
			getInternalOutgoingLinkOriginals(state)[issueId] || internalOutgoingLinksOriginalList;

		const filterValue = getFilters(state)[DEPENDENCIES_FILTER_ID].value;

		const [externalIssueLinks, internalIssueLinks, originalIssueLinks] = linksFactory(
			props.direction,
			externalIncomingLinks,
			internalIncomingLinks,
			internalIncomingLinksOriginal,
			externalOutgoingLinks,
			internalOutgoingLinks,
			internalOutgoingLinksOriginal,
		);

		return {
			internalIssueLinks,
			externalIssueLinks,
			originalIssueLinks,
			filterValue,
		};
	};
};
export const mapStateToProps = mapStateToPropsFactory;

export default mapStateToProps;
