import { setIssueFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/dependencies/filters/actions.tsx';
import type { MapDispatchToPropsSimple } from '../../types/redux.tsx';
import type { DispatchProps } from './types.tsx';

// eslint-disable-next-line jira/react-use-short-dispatch
const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	filterDependencyGraphByIssue: (issueId: string) => dispatch(setIssueFilter(issueId)),
});

export default mapDispatchToProps;
