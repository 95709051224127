import { defineMessages } from '@atlassian/jira-intl';

export const teamProfileMessages = defineMessages({
	members: {
		id: 'portfolio-3-portfolio.common.team-profile.members',
		defaultMessage:
			'Team • {members, plural, one {{members} member} other {{members} members}}{includingYou, select, true {, including you} other {}}',
		description:
			'The label "team" plus the number of members in a team, and whether or not the current user is included in the team',
	},
	teamProfile: {
		id: 'portfolio-3-portfolio.common.team-profile.team-profile',
		defaultMessage: 'View profile',
		description: 'Label for the link to go the the team profile page',
	},
	teamErrorTitle: {
		id: 'portfolio-3-portfolio.common.team-profile.team-error-title',
		defaultMessage: 'We’re having trouble retrieving this teams information',
		description: 'Title for an error view explaining that data for this team cannot be accessed',
	},
	teamErrorText: {
		id: 'portfolio-3-portfolio.common.team-profile.team-error-text',
		defaultMessage: 'Wait a few moments, then try again.',
		description: 'Second line suggesting how to fix the issue',
	},
	loadingLabel: {
		id: 'portfolio-3-portfolio.common.team-profile.loading-label',
		defaultMessage: 'Loading team profile',
		description: 'Label for the loading state of the team profile',
	},
});
