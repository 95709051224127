import { TOGGLE_TOOLBAR } from './actions.tsx';
import type { ToggleToolBarAction, ToolBar } from './types.tsx';

const initialState = {
	showToolBar: true,
} as const;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ToolBar = initialState, action: ToggleToolBarAction) => {
	switch (action.type) {
		case TOGGLE_TOOLBAR:
			return {
				...state,
				showToolBar: !state.showToolBar,
			};
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
