import * as R from 'ramda';
import type { ViewSettingsState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/types.tsx';
import type { View } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/views/types.tsx';
import fromV0 from './from-v0.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function migratePrefs(blob: any): ViewSettingsState {
	return fromV0(blob);
}

export default function migrate(view: View): View {
	const preferences = migratePrefs(view.preferences);

	return R.mergeLeft({
		preferences,
		original: view.original && migrate(view.original),
	})(view);
}
