import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	startDateAgo: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.arrows.tooltip.start-date-ago',
		defaultMessage: 'Start: {date} ({dateDistance} ago)',
		description: '',
	},
	endDateAgo: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.arrows.tooltip.end-date-ago',
		defaultMessage: 'End: {date} ({dateDistance} ago)',
		description: '',
	},
	startDateFromNow: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.arrows.tooltip.start-date-from-now',
		defaultMessage: 'Start: {date} ({dateDistance} from now)',
		description: '',
	},
	endDateFromNow: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.schedule.arrows.tooltip.end-date-from-now',
		defaultMessage: 'End: {date} ({dateDistance} from now)',
		description: '',
	},
});
