import { ISSUE_HIERARCHY_LEVEL_EPIC } from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import {
	type HierarchyRangeFilterValue,
	type HierarchyRangeFilter,
	HIERARCHY_RANGE_FILTER_ID,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getHierarchyRange, type HierarchyRange } from '../../hierarchy/index.tsx';
import { getFiltersViewSettings, getIsCalendarView } from '../../view-settings/index.tsx';

export type HierarchyRangeFilterState = {
	id: typeof HIERARCHY_RANGE_FILTER_ID;
	value: HierarchyRangeFilterValue;
};

type HierarchyRangeFilterStateWithFallback = Omit<HierarchyRangeFilterState, 'value'> & {
	value: HierarchyRangeFilterValue | undefined;
};

export const getHierarchyRangeFilterState = (
	state: State,
): HierarchyRangeFilterStateWithFallback => {
	const { value } = getFiltersViewSettings(state)[HIERARCHY_RANGE_FILTER_ID] || {};

	return {
		id: HIERARCHY_RANGE_FILTER_ID,
		value,
	};
};

export const getHierarchyRangeFilterPure = (
	hierarchyFilter: HierarchyRangeFilterStateWithFallback,
	hierarchyRange: HierarchyRange,
	isCalendarView: boolean,
): HierarchyRangeFilter => {
	const { min, max } = hierarchyRange;
	const currentValue: Partial<HierarchyRangeFilterValue> = hierarchyFilter.value || {
		start: undefined,
		end: undefined,
	};

	if (!isDefined<number>(currentValue.start)) {
		currentValue.start = isCalendarView ? ISSUE_HIERARCHY_LEVEL_EPIC : max;
	}

	if (!isDefined<number>(currentValue.end)) {
		currentValue.end = isCalendarView ? ISSUE_HIERARCHY_LEVEL_EPIC : min;
	}

	const clamp = (val: number, minValue: number, maxValue: number) =>
		Math.min(Math.max(val, minValue), maxValue);

	const clampedStart = clamp(currentValue.start, min, max);
	const clampedEnd = isCalendarView ? clampedStart : clamp(currentValue.end, min, clampedStart);

	const newValue = {
		start: clampedStart,
		end: clampedEnd,
	};
	return {
		...hierarchyFilter,
		value: newValue,
	};
};

export const getHierarchyRangeFilter = createSelector(
	[getHierarchyRangeFilterState, getHierarchyRange, getIsCalendarView],
	getHierarchyRangeFilterPure,
);

export const applyHierarchyRangeFilter = (issue: Issue, filter: HierarchyRangeFilter): boolean => {
	const { start, end } = filter.value;
	return start >= issue.level && issue.level >= end;
};
