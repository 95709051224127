/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import { xcss, Box } from '@atlaskit/primitives';
import type { ControlProps } from '@atlaskit/select';
import type { AssociatedIssue } from '@atlassian/jira-portfolio-3-associated-issues/src/common/types.tsx';
import { selectComponents } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';

export type Props = ControlProps<AssociatedIssue, true> & {
	renderMultipleIdeas: () => React.ReactNode;
};

const ControlComponent = ({ isDisabled, renderMultipleIdeas, ...props }: Props) => {
	const {
		selectProps: { menuIsOpen, value, onMenuOpen },
	} = props;

	const isDefaultControl = menuIsOpen || (isEmpty(value) && !isDisabled);

	return (
		<selectComponents.Control {...props} isFocused={menuIsOpen} isDisabled={isDisabled}>
			<>
				{isDefaultControl && props.children}
				<Box
					xcss={[
						componentContainerStyles,
						isDisabled && componentsContainerDisabledStyles,
						menuIsOpen && hiddenContainerStyles,
					]}
					role="button"
					tabIndex={0}
					onClick={onMenuOpen}
					onKeyDown={noop}
				>
					{renderMultipleIdeas()}
				</Box>
			</>
		</selectComponents.Control>
	);
};

export default ControlComponent;

const componentContainerStyles = xcss({
	display: 'flex',
	flexDirection: 'row',
	paddingTop: 'space.0',
	paddingBottom: 'space.0',
	paddingLeft: 'space.100',
	paddingRight: 'space.100',
	cursor: 'pointer',
	width: '100%',
	boxSizing: 'border-box',
});

const componentsContainerDisabledStyles = xcss({
	cursor: 'default',
	':active': {
		outline: 'none',
	},
	':focus': {
		outline: 'none',
	},
});

const hiddenContainerStyles = xcss({
	display: 'none',
});
