import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import CrossProjectReleases from './cross-project-releases/reducer.tsx';
import ReleasesFilter from './filter/reducer.tsx';
import ProjectReleases from './project-releases/reducer.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	CrossProjectReleases,
	ProjectReleases,
	ReleasesFilter,
});
