import React, { useMemo } from 'react';
import LegendItem from '@atlassian/jira-aais-timeline-legend/src/ui/legend-item/index.tsx';
import LegendSection from '@atlassian/jira-aais-timeline-legend/src/ui/legend-section/index.tsx';
import IssueTypeIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/issue-type-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const IssueTypesLegend = ({ enrichedHierarchyLevels, filteredIssues }: Props) => {
	const { formatMessage } = useIntl();

	const issueTypes = useMemo(() => {
		const visibleIssueTypes = new Set(filteredIssues.map((issue) => issue.type));

		return [...enrichedHierarchyLevels]
			.sort((a, b) => a.level - b.level)
			.flatMap((level) => level.issueTypes)
			.filter((issueType) => visibleIssueTypes.has(issueType.id));
	}, [enrichedHierarchyLevels, filteredIssues]);

	return (
		<LegendSection title={formatMessage(messages.issueTypes)}>
			{issueTypes.map(({ name, iconUrl }, index) => (
				<LegendItem key={index} symbol={<IssueTypeIcon backgroundUrl={iconUrl} />} label={name} />
			))}
		</LegendSection>
	);
};

export default IssueTypesLegend;
