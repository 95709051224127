.dateField {
	user-select: none;
	white-space: nowrap;
	color: var(--ds-text-inverse, var(--adg3-color-N0));
	background-color: var(--ds-background-neutral-bold, var(--adg3-color-N800));
	border-radius: 3px;
	border: 1px solid var(--ds-background-neutral-bold, var(--adg3-color-N800)); /* Same as background color */
	padding: 0 5px;
	height: 20px;
}
