import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	headingCreate: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.heading-create',
		defaultMessage: 'Create a new view',
		description: 'Label for the "Create a new view" dialog header',
	},
	messageCreate: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.message-create',
		defaultMessage:
			'Make a new view of your plan, and change the view settings to tell a different story. This new view will inherit the filters and settings of the currently selected view.',
		description: 'The message for view save as dialog',
	},
	title: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.title',
		defaultMessage: 'Title',
		description: 'The title for the new view',
	},
	titlePlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.title-placeholder',
		defaultMessage: 'Capacity planning',
		description: 'The title placeholder for the new view',
	},
	titleHint: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.title-hint',
		defaultMessage: 'Choose a name that others will recognize',
		description: 'Hint message for the title for the new view',
	},
	makeDefaultCheckboxLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.make-default-checkbox-label',
		defaultMessage: 'Make default for everyone',
		description: 'Label for make default checkbox',
	},
	makeDefaultCheckboxHint: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.make-default-checkbox-hint',
		defaultMessage: 'Use as initial view for everyone',
		description: 'Hint message for make default checkbox',
	},
	save: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.save',
		defaultMessage: 'Save',
		description: 'Label for view save as dialog save button',
	},
	duplicateView: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.duplicate-view',
		defaultMessage: 'This name is already being used. Enter another name.',
		description: 'The error message when there is already a view with the same name',
	},
	maximumNameLengthExceeded: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.save-as-dialog.maximum-name-length-exceeded',
		defaultMessage: 'This name exceeds {number, plural, one {# character} other {# characters}}',
		description:
			'The error message when the view name is longer than the specified maximum number.',
	},
});
