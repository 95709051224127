export const ALL_OTHER_ISSUES = 'all_other_issues' as const;

export type LabelGroupV0 = {
	id: string;
	name: string;
	labels: string[];
	isExpanded: boolean;
};
export type LabelGroup = LabelGroupV0;

export type LabelGroupsStateV0 = LabelGroup[];
export type LabelGroupsState = LabelGroupsStateV0;
