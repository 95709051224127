.table {
	display: block;
	max-height: 400px;
	overflow-y: auto;

	& thead,
	& tbody {
		border: none;
		vertical-align: top;
	}
	& th {
		white-space: nowrap;
		color: var(--ds-text-subtlest, var(--adg3-color-N200));
	}
	& td,
	& th {
		height: 28px;
	}
}

.typeCell {
	min-width: 140px;
}

.assigneeCell {
	padding-top: 0;
}

.change-indicator {
	position: relative;
	display: block;
	padding: 0;
	width: 5px;
	min-width: 5px;
	max-width: 5px;
}

.externalDependenciesHeader {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	text-transform: uppercase;
	font-weight: bold;
}

.footerActions {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
}

.remove-filter {
	display: flex;
	align-items: center;
	& > *:first-child {
		padding-right: var(--jpo-common-base-spacing);
	}
}
