.afm-custom-popup-container {
	display: block;
	box-sizing: border-box;
	flex: 1 1 auto;
	background-color: var(--ds-surface-overlay, var(--adg3-color-N0));
	border-radius: 3px;
	box-shadow: var(
		--ds-shadow-overlay,
		0 4px 8px -2px var(--adg3-color-N50A),
		0 0 1px var(--adg3-color-N60A)
	);
	overflow: visible;
	&:focus {
		outline: none;
	}
}
