import React, { Component } from 'react';
import { injectIntl } from '@atlassian/jira-intl';
import Checkbox from '@atlassian/jira-portfolio-3-common/src/checkbox/index.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

// eslint-disable-next-line jira/react/no-class-components
class ShowFullHierarchyOption extends Component<Props> {
	toggleShowFullHierarchy = () => {
		this.props.toggleShowFullHierarchy();
	};

	render() {
		const { showFullHierarchy, intl } = this.props;
		return (
			<Checkbox
				onChange={this.toggleShowFullHierarchy}
				label={intl.formatMessage(messages.showFullHierarchy)}
				isChecked={showFullHierarchy}
			/>
		);
	}
}

export default injectIntl(ShowFullHierarchyOption);
