import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	january: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.january',
		defaultMessage: 'Jan',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of January',
	},
	february: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.february',
		defaultMessage: 'Feb',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of February',
	},
	march: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.march',
		defaultMessage: 'Mar',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of March',
	},
	april: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.april',
		defaultMessage: 'Apr',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of April',
	},
	may: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.may',
		defaultMessage: 'May',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of May',
	},
	june: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.june',
		defaultMessage: 'Jun',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of June',
	},
	july: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.july',
		defaultMessage: 'Jul',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of July',
	},
	august: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.august',
		defaultMessage: 'Aug',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of August',
	},
	september: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.september',
		defaultMessage: 'Sep',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of September',
	},
	october: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.october',
		defaultMessage: 'Oct',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of October',
	},
	november: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.november',
		defaultMessage: 'Nov',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of November',
	},
	december: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.december',
		defaultMessage: 'Dec',
		description:
			'Column heading text. Column is a visual guide for a users relative position on a timeline. Proper noun. Abbreviation for the month of December',
	},
	week: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.week',
		defaultMessage: 'Week {weekNumber} {firstDay} to {lastDay}',
		description: 'Week descriptor',
	},
	financialYearQuarter: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.financial-year-quarter',
		defaultMessage:
			'Quarter {quarterNumber} {firstDay} to {lastDay}, Financial Year {financialYear}',
		description: 'Financial year quarter descriptor',
	},
	calendarYearQuarter: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.calendar-year-quarter',
		defaultMessage: 'Quarter {quarterNumber} {firstDay} to {lastDay}',
		description: 'Calendar year quarter descriptor',
	},
	financialYearPrefix: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.financial-year-prefix',
		defaultMessage: 'FY{financialYear}',
		description: 'Financial year prefix',
	},
	quarterDescriptorShort: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.quarter-descriptor-short',
		defaultMessage: 'Q{quarterNumber}',
		description: 'Quarter descriptor (short)',
	},
	quarterDescriptorLong: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.timeline.time-units-bar.quarter-descriptor-long',
		defaultMessage:
			'Q{quarterNumber} {quarterStartMonthShortString} - {quarterEndMonthShortString}',
		description: 'Quarter descriptor (long)',
	},
});

const inQuarterOrder = [
	{ startMonth: messages.january, endMonth: messages.march },
	{ startMonth: messages.april, endMonth: messages.june },
	{ startMonth: messages.july, endMonth: messages.september },
	{ startMonth: messages.october, endMonth: messages.december },
];

const quarters = [messages.january, messages.april, messages.july, messages.october];

export default messages;
export { quarters, inQuarterOrder };
