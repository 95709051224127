import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { View } from '../../state/domain/views/types.tsx';

type ViewId = View['id'];

export const urls = {
	userProperties: (planId: number, propName?: string) =>
		`/rest/jpo/1.0/plans/${planId}/userProperties${
			isDefined(propName) ? `/viewSwitching-${propName}` : ''
		}`,
	create: (planId: number) => `/rest/jpo/1.0/plans/${planId}/savedviews`,
	view: (planId: number, viewId: ViewId) => `/rest/jpo/1.0/plans/${planId}/savedviews/${viewId}`,
} as const;

// isPredefined is a new optional flag that the BE accepts for POST and PUT (JPOS-5106)
// If this is true, the view will be updated or created without generating the
// createdBy property or incrementing the version.
// isPredefined will only ever be true when we save a predefined view for
// the first time from the front end.

export const createViewBody = ({
	name,
	isDefault,
	preferences,
	isPredefined = false,
}: {
	name: View['name'];
	isDefault: View['isDefault'];
	preferences: View['preferences'];
	isPredefined: View['isPredefined'];
}) => ({
	viewName: name,
	viewPreferences: preferences,
	isDefault,
	isPredefined,
});

export const updateViewBody = ({
	name,
	version,
	isDefault,
	preferences,
	isPredefined = false,
}: {
	name: View['name'];
	version: View['version'];
	isDefault: View['isDefault'];
	preferences: View['preferences'];
	isPredefined: View['isPredefined'];
}) => ({
	viewName: name,
	viewVersion: version,
	viewPreferences: preferences,
	markAsDefault: isDefault,
	isPredefined,
});

export const markAsDefaultViewBody = ({ isDefault }: { isDefault: View['isDefault'] }) => ({
	markAsDefault: isDefault,
});

export const renameViewBody = ({ name }: { name: View['name'] }) => ({
	viewName: name,
});

export type UpdateResponseBody = {
	id: ViewId;
	version: View['version'];
};
