import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import type { InitialConfig } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/types.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { DefaultDateFormat } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { SET_SYSTEM_INFO, type SetSystemInfoAction } from './actions.tsx';
import type { State, IssueLinkType } from './types.tsx';

type Action = SetSystemInfoAction;

const defaultInitialConfig: InitialConfig = {
	locale: defaultLocale,
};

export const initialState: State = {
	systemInfo: {
		lookAndFeel: {
			dateFormat: DefaultDateFormat,
		},
		jiraHoursPerDay: 8,
		jiraDaysPerWeek: 5,
		jiraTitle: '',
		// viewer is the most restricted option we can take as a default (See PluginPermission type).
		// If the user does not have view permissions they won't even load the state.
		permission: 'Viewer',
		isAdmin: false,
		fiscalMonth: 1,
		accountId: '',
		userName: '',
		userEmailAddress: '',
		avatarUrl: '',
		...defaultInitialConfig,
	},
	agileInfo: {
		version: '',
		epicLinkField: '',
		epicLabelField: '',
		sprintsField: '',
		storyPointsField: '',
		storyPointFieldName: '',
		storyPointEstimateField: '',
		storyPointEstimateFieldName: '',
		targetStartField: '',
		targetEndField: '',
		teamField: '',
	},
	issueLinkTypesInfo: {
		isIssueLinkingEnabled: true,
		issueLinkTypes: [],
	},
	dependencySettingsInfo: {
		dependencyIssueLinkTypes: [],
	},
	loadingLimitsInfo: {
		absoluteIssueLimit: Infinity,
		defaultAbsoluteIssueLimit: Infinity,
		defaultHierarchyIssueLimit: Infinity,
		defaultProjectLimit: Infinity,
		projectLimit: Infinity,
	},
	isAtlasConnectInstalled: false,
	hasAtlasPermissions: false,
};

export const swapDependencyIssueLinks = ({
	issueLinkTypesInfo,
	dependencySettingsInfo,
	...rest
}: State) => {
	const { issueLinkTypes } = issueLinkTypesInfo;
	const { dependencyIssueLinkTypes } = dependencySettingsInfo;

	const dependencyIssueLinkTypeById = indexBy(
		(dependencyIssueLinkType) => dependencyIssueLinkType.issueLinkTypeId,
		dependencyIssueLinkTypes,
	);

	const remappedIssueLinkTypes = issueLinkTypes.map<IssueLinkType>((issueLinkType) => {
		const dependencyIssueLinkType = dependencyIssueLinkTypeById[issueLinkType.id];

		if (dependencyIssueLinkType && !dependencyIssueLinkType.isOutward) {
			return {
				...issueLinkType,
				outward: issueLinkType.inward,
				inward: issueLinkType.outward,
			};
		}
		return issueLinkType;
	});

	return {
		...rest,
		dependencySettingsInfo,
		issueLinkTypesInfo: {
			...issueLinkTypesInfo,
			issueLinkTypes: remappedIssueLinkTypes,
		},
	};
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: State = initialState, action: Action): State => {
	switch (action.type) {
		case SET_SYSTEM_INFO: {
			return swapDependencyIssueLinks(action.payload);
		}
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
