import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query.tsx';
import type { OwnProps, StateProps, DispatchProps } from './types.tsx';
import View from './view.tsx';

export default connect<StateProps, DispatchProps, OwnProps, State>(
	mapStateToProps,
	mapDispatchToProps,
)(View);
