import React, { useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { ARTICLE_TYPE } from '@atlaskit/help';
import { Pressable, xcss } from '@atlaskit/primitives';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button/src/index.tsx';

type Props = {
	onClick?: (
		event: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
		analyticsEvent: UIAnalyticsEvent,
	) => void;
	articleId: string;
	articleType?: keyof typeof ARTICLE_TYPE;
	children: React.ReactNode;
};

const HelpPanelButton = ({ onClick, articleId, articleType = 'HELP_ARTICLE', children }: Props) => {
	const onOpenInProductHelpArticle = useHelpPanelLink();

	const handleClick = useCallback(
		(
			event: React.MouseEvent | React.KeyboardEvent | KeyboardEvent,
			analyticsEvent: UIAnalyticsEvent,
		) => {
			onClick?.(event, analyticsEvent);
			onOpenInProductHelpArticle?.(articleId, articleType);
		},
		[onClick, onOpenInProductHelpArticle, articleType, articleId],
	);

	return (
		<Pressable
			xcss={pressableStyles}
			onClick={handleClick}
			backgroundColor="color.background.neutral.subtle"
		>
			{children}
		</Pressable>
	);
};

const pressableStyles = xcss({
	color: 'color.link',

	':hover': {
		textDecoration: 'underline',
	},
});

export default HelpPanelButton;
