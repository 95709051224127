import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { ff } from '@atlassian/jira-feature-flagging';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { ISSUE_STATUS_CATEGORIES, PLANNING_UNITS } from '../../constant.tsx';
import type { Props } from '../types.tsx';
import messages from './messages.tsx';
import Percentage from './percentage.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import { roundToTwoDigit } from './util.tsx';

const { TODO, INPROGRESS, DONE } = ISSUE_STATUS_CATEGORIES;

const StatusFlyout = (props: Props) => {
	const { issueCount, planningUnit, byCategoryId } = props;
	const { formatMessage } = useIntl();

	const getBreakdown = () => ({
		todo: byCategoryId[String(TODO)],
		inprogress: byCategoryId[String(INPROGRESS)],
		done: byCategoryId[String(DONE)],
	});

	const getLabel = (count: number) => {
		let label = formatMessage(messages.issueCountLabel, { numberOfIssues: count });
		const countToTwoDigit = roundToTwoDigit(count);
		if (planningUnit === PLANNING_UNITS.STORY_POINTS) {
			label = formatMessage(messages.storyPointsCountLabel, {
				storyPoints: countToTwoDigit,
			});
		} else if (planningUnit === PLANNING_UNITS.DAYS) {
			label = formatMessage(messages.daysCountLabel, {
				days: countToTwoDigit,
			});
		} else if (planningUnit === PLANNING_UNITS.HOURS) {
			label = formatMessage(messages.hoursCountLabel, {
				hours: countToTwoDigit,
			});
		}
		return label;
	};

	const { todo, inprogress, done } = getBreakdown();
	const total = todo + inprogress + done;

	return (
		<div
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={ff('com.atlassian.rm.jpo.transposition') ? styles.flyout : styles.flyoutOld}
			data-testid="portfolio-3-portfolio.common.status-breakdown.components.status-flyout"
		>
			<table>
				<tbody>
					<tr>
						<td>
							<Lozenge appearance="success">
								<FormattedMessage {...messages.done} />
							</Lozenge>
						</td>
						<td
							data-testid="portfolio-3-portfolio.common.status-breakdown.components.done"
							data-count={done}
						>
							<Percentage count={done} total={total} label={getLabel(done)} />
						</td>
					</tr>
					<tr>
						<td>
							<Lozenge appearance="inprogress">
								<FormattedMessage {...messages.inprogress} />
							</Lozenge>
						</td>
						<td
							data-testid="portfolio-3-portfolio.common.status-breakdown.components.inprogress"
							data-count={inprogress}
						>
							<Percentage count={inprogress} total={total} label={getLabel(inprogress)} />
						</td>
					</tr>
					<tr>
						<td>
							<Lozenge appearance="default">
								<FormattedMessage {...messages.todo} />
							</Lozenge>
						</td>
						<td
							data-testid="portfolio-3-portfolio.common.status-breakdown.components.todo"
							data-count={todo}
						>
							<Percentage count={todo} total={total} label={getLabel(todo)} />
						</td>
					</tr>
					<tr>
						<td>
							<FormattedMessage {...messages.total} />
						</td>
						<td>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<span className={styles.countLabel}>{getLabel(total)}</span>
						</td>
					</tr>
					{isDefined(issueCount) && (
						<tr>
							<td>
								<FormattedMessage {...messages.totalIssuesLabel} />
							</td>
							<td>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
								<span className={styles.countLabel}>{issueCount}</span>
							</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default StatusFlyout;
