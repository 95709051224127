import React from 'react';
import { G400, N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const ReleasedIcon = ({ totalCount }: { totalCount: number }) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24}>
		<g fill="none" fillRule="evenodd">
			<circle fill={token('color.icon.success', G400)} cx={12} cy={12} r={7} />
			{totalCount === 1 && (
				<path
					d="M10.28 11.91a.698.698 0 0 0-1.06 0 .9.9 0 0 0 0 1.18l1.5 1.666a.698.698 0 0 0 1.06 0l3-3.333a.9.9 0 0 0 0-1.179.698.698 0 0 0-1.06 0l-2.47 2.744-.97-1.077z"
					fill={token('color.icon.inverse', N0)}
				/>
			)}
		</g>
	</svg>
);
export { ReleasedIcon };
