import * as R from 'ramda';
import {
	OPEN_UPDATE_JIRA_DIALOG,
	type OpenDialogAction,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/top/title-bar/update-jira/actions.tsx';
import { ENTITY } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	START as COMMIT_START,
	type StartAction as CommitStartAction,
} from '../commit/actions.tsx';
import { RESET, ADD_BULK, type ResetAction, type AddBulkAction } from './actions.tsx';
import type { ChangesWarnings } from './types.tsx';

type Action = CommitStartAction | OpenDialogAction | ResetAction | AddBulkAction;

const initialState: ChangesWarnings = {
	[ENTITY.ISSUE]: {},
	[ENTITY.RELEASE]: {},
	[ENTITY.CROSS_PROJECT_RELEASE]: {},
	[ENTITY.TEAM]: {},
	[ENTITY.RESOURCE]: {},
	[ENTITY.SPRINT]: {},
	[ENTITY.PLANNED_CAPACITY]: {},
	[ENTITY.NONE]: {},
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ChangesWarnings = initialState, action: Action): ChangesWarnings => {
	switch (action.type) {
		case RESET: {
			return {
				...state,
				[action.payload.category]: {},
			};
		}

		case ADD_BULK: {
			return action.payload.reduce((nextState, { category, itemId, warnings }): ChangesWarnings => {
				const nextWarnings = (nextState[category][itemId] || []).concat(warnings);
				return R.assocPath([category, itemId], nextWarnings, nextState);
			}, state);
		}
		case COMMIT_START:
		case OPEN_UPDATE_JIRA_DIALOG: {
			return initialState;
		}
		default: {
			const _exhaustiveCheck: never = action;
			return state;
		}
	}
};
