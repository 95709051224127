import {
	EXPORT_VIEW_DEFAULT_TIMELINE_WIDTH,
	EXPORT_VIEW_DEFAULT_SCOPE_WIDTH,
	EXPORT_VIEW_DEFAULT_FIELDS_WIDTH,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	type ColumnWidthsState,
	UPDATE_COLUMN_WIDTHS,
	type UpdateColumnWidthsAction,
} from './types.tsx';

const initialState: ColumnWidthsState = {
	scope: EXPORT_VIEW_DEFAULT_SCOPE_WIDTH,
	fields: EXPORT_VIEW_DEFAULT_FIELDS_WIDTH,
	timeline: 0, // currently unused, it's just here for completeness
	timelineExport: EXPORT_VIEW_DEFAULT_TIMELINE_WIDTH,
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (
	state: ColumnWidthsState = initialState,
	action: UpdateColumnWidthsAction,
): ColumnWidthsState => {
	switch (action.type) {
		case UPDATE_COLUMN_WIDTHS:
			return { ...state, ...action.payload };
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
