import React from 'react';
import { xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import IssueLink from '@atlassian/jira-portfolio-3-common/src/issue-link/index.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

export default function IssueCell(props: Props) {
	const { issue, issueType, projectKey } = props;
	return (
		<Tooltip content={issue.summary}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles.issueTitle}>
				<div
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={styles.issueIcon}
					style={{ backgroundImage: issueType && `url(${issueType.iconUrl})` }}
				/>

				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.issueSummary}>
					<IssueLink
						issueKey={issue.issueKey}
						projectKey={projectKey}
						xcssStyles={issueLinkStyles}
					/>
					{issue.summary}
				</div>
			</div>
		</Tooltip>
	);
}

const issueLinkStyles = xcss({
	whiteSpace: 'nowrap',
	marginRight: 'space.075',
});
