.emptyView {
	text-align: center;
	padding-top: 50px;
	margin: auto;
	height: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.line2 {
	padding-top: 20px;
}
