export const GET = 'GET';
export const POST = 'POST';
export const DELETE = 'DELETE';

export const parseError = (
	response: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	},
	message: string,
): {
	message: string;
	stackTrace: string;
} => {
	const contentType = response.headers.get('Content-Type');

	if (typeof contentType === 'string' && contentType.indexOf('application/json') === 0) {
		try {
			const errorObj: {
				type: string;
				stackTrace: Array<{ className: string; methodName: string; line: string }>;
			} = JSON.parse(message).exception;
			const stackTrace = errorObj.stackTrace.reduce(
				(accumulator, trace) =>
					`${accumulator}${trace.className}.${trace.methodName}:${trace.line}\n`,
				'',
			);
			return {
				message: `Type: ${errorObj.type}`,
				stackTrace,
			};
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (e: any) {
			return {
				message,
				stackTrace: '',
			};
		}
	}
	return {
		message,
		stackTrace: '',
	};
};
