import React, {
	useCallback,
	useRef,
	type RefObject,
	type Ref,
	type ReactNode,
	type UIEvent,
	useEffect,
} from 'react';
import { Box, xcss, type BoxProps, type XCSS } from '@atlaskit/primitives';
import useMergeRefs from '@atlassian/jira-merge-refs/src/index.tsx';
import { useResizeObserver } from '@atlassian/jira-react-use-resize-observer/src/index.tsx';
import { useContainerActions, ContainerProvider } from '../../controllers/container/index.tsx';
import { IDLE_THRESHOLD } from './constants.tsx';

type Rect = {
	top: number;
	right: number;
	bottom: number;
	left: number;
};

type Props = {
	children?: ReactNode;
	viewportInset?: Rect;
	xcss?: XCSS | Array<XCSS | false | undefined>;
	outerRef?: Ref<HTMLDivElement>;
} & Omit<BoxProps<'div'>, 'xcss'>;

type ContainerProps = {
	width?: number;
	height?: number;
	scope?: string;
};

const defaultViewportInset = { top: 0, bottom: 0, right: 0, left: 0 };

/**
 * The grid "container" is responsible for reacting to changes in the viewport's scroll offset or dimensions.
 * Most of the logic exists to proxy when scrolling is active or inactive, since the scrollend event is not
 * yet available by our supported browsers.
 *
 */
const Inner = ({
	xcss: xcssStyles,
	children,
	containerRef,
	viewportInset = defaultViewportInset,
	outerRef = null,
	...props
}: Props & { containerRef: RefObject<HTMLDivElement> }) => {
	const ref = useMergeRefs(containerRef, outerRef);

	const frameId = useRef<number>();
	const initialY = useRef(0);
	const scrollStart = useRef(-1);

	const { scrollTo, setRect, updateIsScrollingY } = useContainerActions();

	const onScrollEnd = useCallback(() => {
		scrollStart.current = -1;
		initialY.current = 0;

		updateIsScrollingY(false);

		if (frameId.current) {
			cancelAnimationFrame(frameId.current);
			frameId.current = undefined;
		}
	}, [updateIsScrollingY]);

	/* From an initiating scroll event, recursively schedule animation frames until the
	 * user stops scrolling (indicated by no scroll events over a small time period).
	 *
	 * To minimise the number of updates and reduce flickering, we spend an additional
	 * frame to first determine which axis is being scrolled, and whether the offset has
	 * changed, before updating the store.
	 */
	const scrollTick = useCallback(() => {
		if (!containerRef.current) return;

		const now = performance.now();
		const scrollTime = now - scrollStart.current;

		const { scrollLeft, scrollTop } = containerRef.current;

		if (scrollTime >= IDLE_THRESHOLD) {
			onScrollEnd();
		} else {
			if (initialY.current !== scrollTop) {
				updateIsScrollingY(true);
			}
			scrollTo({ x: scrollLeft, y: scrollTop, passive: true });
			frameId.current = requestAnimationFrame(scrollTick);
		}
	}, [containerRef, scrollTo, onScrollEnd, updateIsScrollingY]);

	const handleScroll = useCallback(
		({ currentTarget }: UIEvent<HTMLElement>) => {
			scrollStart.current = performance.now();

			if (!frameId.current) {
				initialY.current = currentTarget.scrollTop;
				frameId.current = requestAnimationFrame(scrollTick);
			}
		},
		[scrollTick],
	);

	const handleResize = useCallback(() => {
		if (!containerRef.current) return;

		const { top, left } = containerRef.current.getBoundingClientRect();

		// In Firefox, using MacBook 16 inch results in non-integer in height value for the container.
		// E.g. the container height is reported to be 600.5px on Firefox, in this case, getting `container.clientHeight` returns 601px.
		//
		// This resulted in wrong scrollbar visibility where the table with this container clientHeight has 0.5px bigger height than the container.
		//
		// The benefits of preventing regression reported at JPO-28522 surpass the harmless 1px shorter in state compared with viewport,
		// We decided to go ahead with this weird implementation.
		const trimingSize = 1;

		return setRect({
			top: top + viewportInset.top,
			left: left + viewportInset.left,
			width:
				containerRef.current.clientWidth - (viewportInset.left + viewportInset.right) - trimingSize,
			height:
				containerRef.current.clientHeight -
				(viewportInset.top + viewportInset.bottom) -
				trimingSize,
		});
	}, [
		containerRef,
		setRect,
		viewportInset.bottom,
		viewportInset.left,
		viewportInset.right,
		viewportInset.top,
	]);

	useResizeObserver({ ref: containerRef, onResize: handleResize });

	useEffect(
		() => () => {
			frameId.current && cancelAnimationFrame(frameId.current);
		},
		[],
	);

	const withInset = (inner: typeof children) => {
		const hasViewportInset =
			viewportInset?.top !== 0 &&
			viewportInset?.right !== 0 &&
			viewportInset?.bottom !== 0 &&
			viewportInset?.left !== 0;

		if (!hasViewportInset) {
			return inner;
		}

		return (
			<Box
				// eslint-disable-next-line jira/react/no-style-attribute
				style={{
					marginTop: viewportInset.top,
					marginRight: viewportInset.right,
					marginBottom: viewportInset.bottom,
					marginLeft: viewportInset.left,
				}}
			>
				{inner}
			</Box>
		);
	};

	return (
		<Box
			ref={ref}
			onScroll={handleScroll}
			xcss={[containerStyles, ...(Array.isArray(xcssStyles) ? xcssStyles : [xcssStyles])]}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		>
			{withInset(children)}
		</Box>
	);
};

export const Container = ({ scope, width, height, ...innerProps }: Props & ContainerProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const scrollTo = useCallback(({ x, y, smooth }: { x?: number; y?: number; smooth?: boolean }) => {
		const containerEl = containerRef.current;

		if (!containerEl) {
			return;
		}

		if (smooth) {
			containerEl.style.scrollBehavior = 'smooth';
		}

		if (x !== undefined) {
			containerEl.scrollLeft = x;
		}

		if (y !== undefined) {
			containerEl.scrollTop = y;
		}

		if (smooth) {
			containerEl.style.scrollBehavior = 'auto';
		}
	}, []);

	const containerProps = {
		scrollTo,
		...(scope !== undefined && { scope }),
		...(width !== undefined && { width }),
		...(height !== undefined && { height }),
	};

	return (
		<ContainerProvider {...containerProps}>
			<Inner {...innerProps} containerRef={containerRef} />
		</ContainerProvider>
	);
};

const containerStyles = xcss({
	display: 'flex',
	alignItems: 'stretch',
	flexDirection: 'column',
	overflow: 'auto',
	minWidth: '0',
	minHeight: '0',
});
