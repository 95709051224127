import {
	type CrossProjectReleaseFilter,
	CROSS_PROJECT_RELEASE_FILTER_ID,
	type PersistableCrossProjectReleaseFilter,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/filters/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getCrossProjectVersionsById } from '../../cross-project-versions/index.tsx';
import type { CrossProjectVersionsById } from '../../cross-project-versions/types.tsx';
import { getFiltersViewSettings } from '../../view-settings/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { CROSS_PROJECT_RELEASE_FILTER_ID } from '../../../state/domain/view-settings/filters/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	CrossProjectReleaseFilter,
	StringAttributeFilterValue as CrossProjectReleaseFilterValue,
} from '../../../state/domain/view-settings/filters/types';

export const getCrossProjectReleaseFilterPure = (
	state: State,
): PersistableCrossProjectReleaseFilter =>
	getFiltersViewSettings(state)[CROSS_PROJECT_RELEASE_FILTER_ID] || {
		id: CROSS_PROJECT_RELEASE_FILTER_ID,
		value: [],
	};

export const getCrossProjectReleaseFilter = createSelector(
	[getCrossProjectVersionsById, getCrossProjectReleaseFilterPure],
	(
		crossProjectVersionsById: CrossProjectVersionsById,
		pureFilter: PersistableCrossProjectReleaseFilter,
	): CrossProjectReleaseFilter => {
		const { value = [] } = pureFilter;

		return {
			id: CROSS_PROJECT_RELEASE_FILTER_ID,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			value: value.reduce<Array<any>>((result, cprId) => {
				const crossProjectVersion = crossProjectVersionsById[cprId];
				if (isDefined(crossProjectVersion)) {
					result.push(cprId);
				}
				return result;
			}, []),
			crossProjectVersionsById,
		};
	},
);
