import React, { type ReactNode } from 'react';
import { useCtpOnboardingTour } from '../../controllers/ctp-onboarding/index.tsx';
import { useCttOnboardingTour } from '../../controllers/ctt-onboarding/index.tsx';
import { useChangeboarding, useRehydrated } from '../../controllers/index.tsx';

interface Props {
	forChangeboarding?: boolean;
	forOnboarding?: boolean;
	forCttOnboarding?: boolean;
	forCtpOnboarding?: boolean;
	children: ReactNode;
}

/** Suppress ARJ FlagGroup when any of the onboarding/changeboarding is in progress. */
export const FlagGroupGuard = ({
	forChangeboarding,
	forCttOnboarding,
	forCtpOnboarding,
	children,
}: Props) => {
	const [rehydrated] = useRehydrated();
	const [changeboarding] = useChangeboarding();
	const [cttOnboarding] = useCttOnboardingTour();
	const [ctpOnboarding] = useCtpOnboardingTour();

	if (!rehydrated) {
		return null;
	}

	if (forChangeboarding && changeboarding.visible) {
		return null;
	}

	if (forCttOnboarding && cttOnboarding.visible) {
		return null;
	}

	if (forCtpOnboarding && ctpOnboarding.visible) {
		return null;
	}

	return <>{children}</>;
};
