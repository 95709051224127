import type { IntlShape } from '@atlassian/jira-intl';
import { getIssueOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/utils.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import { NONE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';

export const isSelectedSubTaskType = (
	issueTypesById: Record<number | string, IssueType>,
	selectedIssues: Issue[],
) => {
	const { type } = selectedIssues[0];
	const selectedType = issueTypesById[type];
	return selectedType && selectedType.subTask;
};

export const getPossibleParentIssues = (
	selectedIssues: Issue[],
	issues: Issue[],
	projectsById: Record<number | string, Project>,
	isSomeSelectedIssuesFromTMP: boolean,
	isSelectedIssuesFromMultipleProjects: boolean,
): Issue[] => {
	if (selectedIssues.length === 0) {
		return [];
	}

	const { level: selectedLevel, project: selectedProject } = selectedIssues[0];

	// Ensure only one hierarchy level is selected
	for (let i = 0; i < selectedIssues.length; i++) {
		if (selectedIssues[i].level !== selectedLevel) {
			return [];
		}
	}

	// It's not possible to reparent issues from different TMPs, or issues from mix of TMP and CMP.
	// Only issues from same TMP, or issues from various CMPs is possible.
	if (isSomeSelectedIssuesFromTMP && isSelectedIssuesFromMultipleProjects) {
		return [];
	}

	if (isSomeSelectedIssuesFromTMP) {
		// there can only be a single TMP at this point
		return issues.filter(
			(issue) => issue.level === selectedLevel + 1 && issue.project === selectedProject,
		);
	}

	// there can only be CMPs at this point
	return issues.filter(
		(issue) =>
			issue.level === selectedLevel + 1 && projectsById[issue.project]?.isSimplified !== true,
	);
};

export const getParentSelectOptions = (
	intl: IntlShape,
	issueTypesById: Record<number | string, IssueType>,
	selectedIssues: Issue[],
	issues: Issue[],
	projectsById: Record<number | string, Project>,
	isSomeSelectedIssuesFromTMP: boolean,
	isSelectedIssuesFromMultipleProjects: boolean,
) => {
	const { formatMessage } = intl;
	const possibleParentOptions = getPossibleParentIssues(
		selectedIssues,
		issues,
		projectsById,
		isSomeSelectedIssuesFromTMP,
		isSelectedIssuesFromMultipleProjects,
	).map((issue) => getIssueOption(issue, issueTypesById, projectsById));

	if (!isSelectedSubTaskType(issueTypesById, selectedIssues)) {
		possibleParentOptions.unshift({
			label: formatMessage(commonMessages.none),
			value: NONE,
		});
	}
	return possibleParentOptions;
};
