import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	plans: {
		id: 'spa-apps-advanced-roadmaps-plan.common.document-title.plans',
		defaultMessage: 'Plans',
		description: 'Feature name in the title of the browser',
	},
});

export default messages;
