import identity from 'lodash/fp/identity';
import noop from 'lodash/fp/noop';
import sum from 'lodash/fp/sum';
import {
	SCOPE,
	TIMELINE,
	SECTION_COLLAPSE_THRESHOLD,
	COLUMN_COLLAPSE_THRESHOLD,
	TIMELINE_DEFAULT_EXPANDABLE_WIDTH,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/constants.tsx';
import type { Resizer } from '@atlassian/jira-portfolio-3-treegrid/src/controllers/grid/types.tsx';
import {
	createStore,
	createSelector,
	createContainer,
	createHook,
	createActionsHook,
	type Action,
} from '@atlassian/react-sweet-state';

export type Precollapsed = Record<string /* column ID */, number /* Its width */>;

export interface State {
	/** Determines whether the state is on the default registry or being within a container. */
	singleton: boolean;
	columnIds: string[];
	defaultWidths: number[];
	widths: number[];
	/** Map of collapsed states for each column. FIELDS refers to the entire fields state */
	collapsed?: Record<number | 'FIELDS', boolean>;
	updateWidths: (arg0: number[]) => void;
	resizer: Resizer;

	/** The widths of the columns before their collapsing. */
	columnsPrecollapsed: Precollapsed;

	/** The widths of the fields before the section collapsing. */
	fieldsPrecollapsed: Precollapsed;
}

const isFieldColumn = (columnId: string) => columnId !== TIMELINE && columnId !== SCOPE;

const getCollapsed = (widths: State['widths']): State['collapsed'] => {
	const result: State['collapsed'] = {
		FIELDS: isCollapsed(widths, {
			column: 1,
			colSpan: widths.length - 2 /* Except the scope and timeline */,
		}),
	};

	for (let column = 0; column < widths.length; column++) {
		result[column] = isCollapsed(widths, { column, colSpan: 1 });
	}

	return result;
};

export const actions = {
	/** Cache the widths when they are not collapsed to be used when expanding after they are collapsed. */
	cache:
		(widths: number[]): Action<State> =>
		({ getState, setState }) => {
			const { columnIds, columnsPrecollapsed, fieldsPrecollapsed } = getState();

			const columns: Precollapsed = { ...columnsPrecollapsed };
			const fields: Precollapsed = { ...fieldsPrecollapsed };

			const isFieldsCollapsed = isCollapsed(widths, {
				column: 1,
				colSpan: columnIds.length - 2 /* Except the scope and timeline */,
			});

			columnIds.forEach((columnId, column) => {
				if (isFieldColumn(columnId) && !isFieldsCollapsed) {
					fields[columnId] = widths[column];
				}

				if (!isCollapsed(widths, { column, colSpan: 1 })) {
					columns[columnId] = widths[column];
				}
			});

			setState({ columnsPrecollapsed: columns, fieldsPrecollapsed: fields });
		},

	expand:
		(column: number): Action<State> =>
		({ getState, dispatch }) => {
			const { defaultWidths, widths, columnIds, columnsPrecollapsed } = getState();
			const columnId = columnIds[column];
			const defaultWidth =
				columnId === TIMELINE ? TIMELINE_DEFAULT_EXPANDABLE_WIDTH : defaultWidths[column];
			const width = columnsPrecollapsed[columnId] ?? defaultWidth;

			dispatch(actions.resize(column, width - widths[column]));
		},

	collapse:
		(column: number): Action<State> =>
		({ getState, dispatch }) => {
			const { widths } = getState();

			dispatch(actions.resize(column, COLUMN_COLLAPSE_THRESHOLD - widths[column]));
		},

	expandFields:
		(): Action<State> =>
		({ getState }) => {
			const { defaultWidths, widths, columnIds, fieldsPrecollapsed, updateWidths } = getState();
			const nextWidths = columnIds.map((columnId, index) => {
				if (!isFieldColumn(columnId)) {
					return widths[index];
				}

				if (columnIds.length === 3) {
					// When it's the only one field column being collapsed.
					return defaultWidths[index];
				}

				return fieldsPrecollapsed[columnId] ?? defaultWidths[index];
			});

			updateWidths(nextWidths);
		},

	collapseFields:
		(): Action<State> =>
		({ getState, dispatch }) => {
			const { widths, columnIds } = getState();
			const lastFieldColumnIndex = columnIds.length - 2;

			const fieldsSectionWidth = sum(widths.slice(1, widths.length - 1));
			const delta = SECTION_COLLAPSE_THRESHOLD - fieldsSectionWidth;
			dispatch(actions.resize(lastFieldColumnIndex, delta));
		},

	resize:
		(column: number, delta: number): Action<State> =>
		({ getState, dispatch }) => {
			const { widths, resizer, updateWidths } = getState();
			if (column === widths.length - 1) {
				// Timeline column resizing needs to rely on the last field column resizing.
				return dispatch(actions.resize(column - 1, -delta));
			}

			let nextWidths = resizer({ column, delta })(widths);

			const remaining = delta - (nextWidths[column] - widths[column]);

			if (remaining !== 0 && column > 0) {
				// Try to expand to the left if the right space is not enough.
				nextWidths = resizer({ column: column - 1, delta: -remaining })(nextWidths);
				nextWidths = resizer({ column, delta: remaining })(nextWidths);
			}

			updateWidths(nextWidths);
		},
};

export const initialState = {
	singleton: true,
	columnIds: [],
	fieldsPrecollapsed: {},
	columnsPrecollapsed: {},
	defaultWidths: [],
	collapsed: { FIELDS: false },
	widths: [],
	resizer: () => identity,
	updateWidths: noop,
};

const store = createStore<State, typeof actions>({
	initialState,
	actions,
});

export const CollapseProviderOld = createContainer(store, {
	onInit:
		() =>
		(
			{ setState },
			state: Omit<State, 'precollapsed' | 'fieldsPrecollapsed' | 'columnsPrecollapsed'>,
		) =>
			setState(state),
	onUpdate:
		() =>
		(
			{ setState },
			state: Omit<State, 'precollapsed' | 'fieldsPrecollapsed' | 'columnsPrecollapsed'>,
		) =>
			setState(state),
});

export const CollapseProvider = createContainer(store, {
	onInit:
		() =>
		(
			{ setState },
			state: Omit<
				State,
				'collapsed' | 'precollapsed' | 'fieldsPrecollapsed' | 'columnsPrecollapsed'
			>,
		) =>
			setState({ ...state, collapsed: getCollapsed(state.widths) }),
	onUpdate:
		() =>
		(
			{ setState },
			state: Omit<State, 'precollapsed' | 'fieldsPrecollapsed' | 'columnsPrecollapsed'>,
		) =>
			setState({ ...state, collapsed: getCollapsed(state.widths) }),
});

export const useActions = createActionsHook(store);

const isCollapsed = (
	widths: State['widths'],
	{ column, colSpan }: { column: number; colSpan: number },
) => {
	const accumulatedColumnWidth /* includes colspan */ = widths
		.slice(column, column + colSpan)
		.reduce(
			(acc: number, width = Infinity) =>
				Number.isFinite(acc) && Number.isFinite(width) ? acc + width : Infinity,
			0,
		);

	const fieldsSectionWidth = sum(widths.slice(1, widths.length - 1));

	if (column === 1) {
		// Fields
		return (
			accumulatedColumnWidth <= COLUMN_COLLAPSE_THRESHOLD ||
			fieldsSectionWidth <= SECTION_COLLAPSE_THRESHOLD
		);
	}

	if (column === widths.length - 1) {
		return accumulatedColumnWidth <= SECTION_COLLAPSE_THRESHOLD;
	}

	return accumulatedColumnWidth <= COLUMN_COLLAPSE_THRESHOLD;
};

const selectIsCollapsed = createSelector(
	[
		(state: State) => state.widths,
		(state: State) => state.singleton,
		(_, { column, colSpan }: { column: number; colSpan: number }) => ({ column, colSpan }),
	],
	(widths, singleton, { column, colSpan }) => {
		if (singleton) {
			// LIST mode doesn't have the state provided in a container
			// We want to leverage this fact to disallow the collapse on LIST view.
			return false;
		}

		return isCollapsed(widths, { column, colSpan });
	},
);

// Clean up when cleaning up fg('plans_performance_improvements')
export const useCollapsedOld = createHook(store, {
	selector: selectIsCollapsed,
});

/**
 * Selector to determine column collapsed state, given column index.
 * The second column of the header section is designated for the fields section
 */
export const selectIsCollumnCollapsed = createSelector(
	[
		(state: State) => state.collapsed,
		(_, { isHeader, column }: { isHeader: boolean; column: number }) => ({ isHeader, column }),
	],
	(collapsed, { isHeader, column }) =>
		collapsed?.[isHeader && column === 1 ? 'FIELDS' : column] ?? false,
);

export const useColumnCollapsed = createHook(store, {
	selector: selectIsCollumnCollapsed,
});
