import * as R from 'ramda';
import { ISSUE_HIERARCHY_LEVEL_BASE } from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import { puppies } from '@atlassian/jira-mocks-assets-collection/src/index.tsx';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import {
	startOfUtcDay,
	endOfUtcDay,
} from '@atlassian/jira-portfolio-3-common/src/date-manipulation/index.tsx';
import { SUB_TASK_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import { proxyContextSafeUrl } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/index.tsx';
import * as Api from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type { ReleaseStatus } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	isDefined,
	indexBy,
	pluck,
	values,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { SCENARIO_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { isLabelCustomField } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import type { CrossProjectVersion } from './cross-project-versions/types.tsx';
import type { CustomField } from './custom-fields/types.tsx';
import type { CustomLabelsByField } from './custom-labels/types.tsx';
import type { FlagKey } from './flags/types.tsx';
import type { HiddenFlags } from './hidden-flags/types.tsx';
import type { HierarchyLevel } from './hierarchy/types.tsx';
import { getTypeToLevelPure } from './hierarchy/util.tsx';
import type { IssueLinksState } from './issue-links/types.tsx';
import type { IssueType } from './issue-types/types.tsx';
import type { Issue } from './issues/types.tsx';
import type { OriginalCrossProjectVersions } from './original-cross-project-versions/types.tsx';
import type { OriginalIssues, OriginalIssue } from './original-issues/types.tsx';
import type {
	OriginalPlannedCapacity,
	OriginalPlannedCapacities,
} from './original-planned-capacities/types.tsx';
import type { OriginalResources } from './original-resources/types.tsx';
import type { OriginalTeams } from './original-teams/types.tsx';
import type { OriginalVersions } from './original-versions/types.tsx';
import type { DateConfiguration } from './plan/types.tsx';
import type { PlannedCapacity, PlannedCapacities } from './planned-capacities/types.tsx';
import type { Project } from './projects/types.tsx';
import type { Sprint } from './sprints/types.tsx';
import type { DependencySettingsInfo } from './system/types.tsx';
import type { Team } from './teams/types.tsx';
import { offTrack, onTrack } from './version-statuses/types.tsx';
import type { Version } from './versions/types.tsx';

export const DEFAULT_PROJECT_ID = -1;

// Keeping this variable for now as all of the stories and integration tests rely on this
export const SCRATCH_STORY_ISSUE_TYPE: IssueType = {
	id: -2,
	name: 'Story',
	iconUrl: proxyContextSafeUrl(puppies.puppy1),
	level: ISSUE_HIERARCHY_LEVEL_BASE,
};

// Keeping this variable for now as all of the integration stories rely on this
export const DEFAULT_SCRATCH_ISSUE_TYPE = SCRATCH_STORY_ISSUE_TYPE;

export function getNormalizeDatesFn(dateConfiguration: DateConfiguration) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return function normalizeDates<T extends Record<PropertyKey, any>>(x: T): T {
		const result = { ...x };
		const { baselineStartField, baselineEndField } = dateConfiguration;
		for (const [f, props] of [
			[startOfUtcDay, ['start', baselineStartField.key]],
			[endOfUtcDay, ['end', baselineEndField.key]],
		] as const) {
			for (const prop of props) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				if (isDefined(x[prop as keyof T])) {
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					result[prop as keyof T] = f(x[prop as keyof T]) as T[keyof T];
				} else if (isDefined(x.customFields) && isDefined(x.customFields[prop])) {
					result.customFields[prop] = f(x.customFields[prop]);
				}
			}
		}
		return result;
	};
}

function normalizeParentTeamAndSprintForSubtasks(issuesById: {
	[id: string]: Issue;
}): (arg1: Issue) => Issue {
	return (issue) => {
		const parentIssue: Issue | null | undefined = issuesById[issue.parent || ''];

		if (!isDefined(parentIssue)) {
			return issue;
		}

		if (issue.level >= parentIssue.level) {
			return R.omit(['parent'], issue);
		}

		if (
			issue.level === SUB_TASK_LEVEL &&
			(issue.team !== parentIssue.team || issue.sprint !== parentIssue.sprint)
		) {
			const changes = {
				team: parentIssue.team,
				sprint: parentIssue.sprint,
			};
			return R.merge(issue, changes);
		}

		return issue;
	};
}

export const prepareIssues = (
	apiIssues: Api.Issue[] = [],
	{
		levels,
		issueTypes,
		dateConfiguration,
		releasesDisabledProject,
	}: {
		levels: HierarchyLevel[];
		issueTypes: IssueType[];
		dateConfiguration: DateConfiguration;
		releasesDisabledProject: number[];
	},
): Issue[] => {
	const typeToLevel = getTypeToLevelPure(levels, issueTypes);
	const normalizeDates = getNormalizeDatesFn(dateConfiguration);
	const issues: Issue[] = apiIssues.map(
		({
			id,
			issueKey,
			assignments,
			annotations,
			issueSources,
			values: {
				assignee,
				associatedIssueIds,
				reporter,
				baselineStart,
				baselineEnd,
				color,
				customFields,
				description,
				dueDate,
				earliestStart,
				excluded,
				fixVersions,
				goals,
				labels,
				lexoRank,
				originalStoryPoints,
				originalTimeEstimate,
				parent,
				priority,
				project,
				sprint,
				completedSprints,
				status,
				storyPoints,
				summary,
				team,
				timeEstimate,
				timeSpent,
				type,
				components,
				associatedIssues,
			},
			originals: { completedSprints: ogCompletedSprints },
		}) =>
			normalizeDates({
				assignments: assignments?.map(normalizeDates) ?? [],
				annotations: annotations ?? [],
				assignee: assignee || 'unassigned',
				associatedIssueIds: associatedIssueIds || [],
				associatedIssues: associatedIssues || [],
				reporter,
				color,
				customFields,
				description,
				dueDate,
				earliestStart,
				excluded,
				fixVersions: releasesDisabledProject.includes(project) ? [] : fixVersions,
				goals,
				id,
				issueKey,
				level: typeToLevel(type),
				labels,
				lexoRank,
				issueSources,
				originalStoryPoints,
				originalTimeEstimate,
				parent,
				priority,
				project,
				sprint,
				completedSprints: [...(completedSprints || []), ...(ogCompletedSprints || [])],
				status,
				storyPoints,
				summary,
				// switch baselineStart/End out for TargetStart/End, as we store the configured start field in baselineStart/End later
				[Api.TARGET_START_FIELD]: baselineStart,
				[Api.TARGET_END_FIELD]: baselineEnd,
				team,
				timeEstimate,
				timeSpent,
				components,
				type: isDefined(type) || project ? type : SCRATCH_STORY_ISSUE_TYPE.id, // Default to story if the data is borked.
			}),
	);
	const issuesById = indexBy(R.prop('id'), issues);
	return issues.map<Issue>(normalizeParentTeamAndSprintForSubtasks(issuesById));
};

export const prepareIssueGoals = (issues: Issue[], originalIssues: OriginalIssues): string[] => {
	const result = new Set<string>();

	for (const { goals } of issues) {
		for (const goal of goals || []) {
			result.add(goal);
		}
	}

	for (const { goals } of values(originalIssues)) {
		for (const goal of goals || []) {
			result.add(goal);
		}
	}

	return Array.from(result).sort();
};

export const prepareIssueLabels = (issues: Issue[], originalIssues: OriginalIssues): string[] => {
	const result = new Set<string>();

	for (const { labels } of issues) {
		for (const label of labels || []) {
			result.add(label);
		}
	}

	for (const { labels } of values(originalIssues)) {
		for (const label of labels || []) {
			result.add(label);
		}
	}

	return Array.from(result).sort();
};

export const prepareCustomLabels = (
	issues: Issue[],
	originalIssues: OriginalIssues,
	customFields: CustomField[],
): CustomLabelsByField => {
	const result: CustomLabelsByField = {};
	const labelFieldIds = customFields
		.filter((field) => isLabelCustomField(field?.type?.key))
		.map((field) => field.id);
	if (labelFieldIds.length < 1) {
		return result;
	}
	const getCustomLabels = (issue: Issue | OriginalIssue) => {
		labelFieldIds.forEach((fieldId) => {
			const customFieldValue = issue?.customFields?.[fieldId];
			if (Array.isArray(customFieldValue) && isDefined(fieldId)) {
				const customLabels = result[fieldId];
				if (Array.isArray(customLabels)) {
					result[Number(fieldId)] = R.uniq(customLabels.concat(customFieldValue));
				} else {
					result[Number(fieldId)] = customFieldValue;
				}
			}
		});
	};
	issues.forEach(getCustomLabels);
	values(originalIssues).forEach(getCustomLabels);
	return result;
};
/**
 * merge two customLabelsByField objects.
 * For example:
 * const currentCustomLabels = {10001: ["label1-1","label1-2"], 10002: ["label2-1","label2-2"]};
 * const newCustomLabels =  {10001: ["label1-1","label1-3"], 10002: ["label2-1"], 10003:["label3-1"]}
 * return {"10001": ["label1-1", "label1-2", "label1-3"], "10002": ["label2-1", "label2-2"], "10003": ["label3-1"]};
 * for the array labels of a field, we should concat them(R.concat) and then filter the duplicated labels(R.uniq),
 * using the R.pipe to connect R.concat and R.uniq. Finally use the R.mergeDeepWith to deep merge two objects.
 *
 * @param currentCustomLabels
 * @param newCustomLabels
 * @returns
 */
export const mergeCustomLabels = (
	currentCustomLabels: CustomLabelsByField,
	newCustomLabels: CustomLabelsByField,
): CustomLabelsByField =>
	R.mergeDeepWith(R.pipe(R.concat<string>, R.uniq<string>), currentCustomLabels, newCustomLabels);

export const prepareIssueComponents = (
	issues: Issue[],
	originalIssues: OriginalIssues,
): number[] => {
	const result = new Set<number>();

	for (const { components } of issues) {
		for (const component of components || []) {
			result.add(component);
		}
	}

	for (const { components } of values(originalIssues)) {
		for (const component of components || []) {
			result.add(component);
		}
	}

	return Array.from(result).sort();
};

export const prepareIssueLinks = (
	issues: Api.Issue[],
	dependencySettingsInfo: DependencySettingsInfo,
): IssueLinksState => {
	const result: IssueLinksState = {
		values: {},
		originals: {},
	};
	const modes = ['values', 'originals'] as const;
	const linkTypes = new Set(
		pluck('issueLinkTypeId', dependencySettingsInfo.dependencyIssueLinkTypes),
	);

	const dependencyIssueLinkTypeById = indexBy(
		(dependencyIssueLinkType) => dependencyIssueLinkType.issueLinkTypeId,
		dependencySettingsInfo.dependencyIssueLinkTypes,
	);

	for (const issue of issues) {
		const { id } = issue;
		for (const mode of modes) {
			let links = issue[mode].issueLinks;
			if (links) {
				links = links.map((link) => {
					if (
						dependencyIssueLinkTypeById[link.type] &&
						!dependencyIssueLinkTypeById[link.type].isOutward
					) {
						return {
							...link,
							sourceItemKey: link.targetItemKey,
							targetItemKey: link.sourceItemKey,
						};
					}
					return link;
				});

				result[mode][id] = indexBy(
					(x) => x.itemKey,
					links.filter((x) => linkTypes.has(x.type)),
				);
			}
		}
	}
	return result;
};

export const prepareOriginalIssues = (
	issues: Api.Issue[],
	dateConfiguration: DateConfiguration,
	scenarioRemovedIssues?: Api.Issue[], // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const originalIssues: Record<string, any> = {};
	const normalizeDates = getNormalizeDatesFn(dateConfiguration);
	for (const { id, issueKey, originals: rawOriginals } of [
		...issues,
		...(scenarioRemovedIssues || []),
	]) {
		const { issueLinks, statusTransition, ...originals } = rawOriginals;

		if (!R.isEmpty(originals)) {
			const { baselineStart, baselineEnd, ...otherOriginals } = originals;
			const originalValues = { ...otherOriginals };

			if (R.has('baselineStart')(originals)) {
				originalValues[Api.TARGET_START_FIELD] = baselineStart;
			}
			if (R.has('baselineEnd')(originals)) {
				originalValues[Api.TARGET_END_FIELD] = baselineEnd;
			}
			originalIssues[id] = normalizeDates(originalValues);
		} else if (!isDefined(issueKey)) {
			// issue not committed to Jira has no originals, but we still want it to present in originalIssues
			originalIssues[id] = {};
		}
	}

	return originalIssues;
};

export const prepareOriginalTeams = (teams: Api.Team[]): OriginalTeams => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const originalTeams: Record<string, any> = {};
	for (const { id, originals, itemKey, scenarioType } of teams) {
		if (!R.isEmpty(originals)) {
			originalTeams[itemKey] = originals;
		} else if (!itemKey) {
			originalTeams[id] = {};
		} else if (scenarioType) {
			originalTeams[itemKey] = {};
		}
	}
	return originalTeams;
};

export const prepareOriginalResources = (teams: Api.Team[]): OriginalResources => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const originalResources: Record<string, any> = {};
	for (const { resources } of teams) {
		resources
			.filter((resource) => resource.scenarioType)
			.forEach((resource) => {
				originalResources[resource.itemKey] = {};
			});
	}
	return originalResources;
};

export const preparePlan = ({
	currentScenarioId,
	plan: {
		id,
		title,
		planningUnit,
		issueSources,
		multiScenarioEnabled,
		includeCompletedIssuesFor,
		calculationConfiguration,
		syncStartEnabled,
		issueInferredDateSelection,
		baselineStartField,
		baselineEndField,
		createdTimestamp,
		creatorAccountId,
		leadAccountId,
	},
	scenarios,
	// Default value for safety due to how the FF is managed
	isSamplePlan = false,
	autoSchedulerEnabled = true,
	planStatus = 'ACTIVE',
	planType = undefined,
}: Api.PlanInfo) => ({
	id,
	title,
	currentScenarioId,
	planningUnit,
	issueSources,
	multiScenarioEnabled,
	scenarios,
	includeCompletedIssuesFor,
	autoScheduleConfiguration: calculationConfiguration,
	syncStartEnabled,
	issueInferredDateSelection,
	baselineStartField,
	baselineEndField,
	createdTimestamp,
	isSamplePlan,
	autoSchedulerEnabled,
	planStatus,
	creatorAccountId,
	leadAccountId,
	planType,
});

const getPrioritiesForProject = (
	issuePriorityInformation: Api.IssuePriorityInformation,
	projectId: number,
) => {
	const { issuePrioritySchemes, projectToPrioritySchemeAssociations } = issuePriorityInformation;
	const prioritySchemeId = projectToPrioritySchemeAssociations[projectId];
	return issuePrioritySchemes[prioritySchemeId];
};

export const convertApiProjectToDomain = (
	{
		id,
		key,
		name,
		avatarUrl,
		versions,
		issueTypeIds,
		issueStatusIds,
		components,
		isSimplified,
		projectTypeKey,
		isReleasesEnabled,
	}: Api.Project,
	defaultIssuePriorityScheme: Api.IssuePriorityScheme,
): Project => {
	const {
		defaultIssuePriorityId,
		issuePriorityIds = [],
		id: prioritySchemaId,
	} = defaultIssuePriorityScheme;
	return {
		id,
		key,
		name,
		avatarUrl,
		versions: versions
			.filter(R.complement(R.propEq('scenarioType', SCENARIO_TYPE.DELETED)))
			.map(R.prop('itemKey')),
		components,
		issueStatusIds,
		issueTypeIds: issueTypeIds.map((x) => parseInt(x, 10)),
		issuePriorityIds,
		defaultIssuePriorityId,
		prioritySchemaId,
		isSimplified,
		projectTypeKey,
		isReleasesEnabled: isReleasesEnabled ?? true,
	};
};

export const prepareProjects = (
	projects: Api.Project[],
	issuePriorityInformation: Api.IssuePriorityInformation,
): Project[] =>
	projects.map((project) =>
		convertApiProjectToDomain(
			project,
			getPrioritiesForProject(issuePriorityInformation, project.id),
		),
	);

export const prepareReleaseStatuses = (releaseStatuses: ReleaseStatus[]): ReleaseStatus[] => [
	// the releaseStatuses array includes the following statuses:
	// [{ id: "0", name: "Unreleased" }, { id: "1", name: "Released" }]
	...releaseStatuses,
	// to which we're adding these two ARJ "special" statuses
	// note that the "name" isn't actually used in the codebase, we keep it just for reference
	offTrack,
	onTrack,
];

export const prepareSprintsFunc = (sprints: Api.Sprint[], planInfo: Api.PlanInfo): Sprint[] => {
	const sprintIssueSources: {
		[sprintId: number]: Set<number>;
	} = {};
	planInfo.plan.issueSources.forEach((issueSource) => {
		if (issueSource.sprintIds && Array.isArray(issueSource.sprintIds)) {
			issueSource.sprintIds.forEach((sprintId) => {
				if (!sprintIssueSources[sprintId]) {
					sprintIssueSources[sprintId] = new Set();
				}
				sprintIssueSources[sprintId].add(issueSource.id);
			});
		}
	});
	return R.pipe<Api.Sprint[], Api.Sprint[], Api.Sprint[], Api.Sprint[], Api.Sprint[]>(
		R.map((sprint: Sprint) => ({
			...sprint,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			issueSources: Array.from(sprintIssueSources[sprint.id as unknown as number] || []),
			// Make the sprint ID be a string instead of a number. This keeps it consistent with the Issue.sprint type.
			id: `${sprint.id}`,
		})),
		// Reason for double-reverse - https://stash.atlassian.com/projects/JPOS/repos/portfolio-server/pull-requests/2153/overview?commentId=1711287
		R.reverse,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		R.uniqBy((s: Sprint) => s.id) as (s: Sprint[]) => Sprint[],
		R.reverse,
	)(sprints);
};

export const prepareSprints = monitor.timeFunction(
	prepareSprintsFunc,
	'state_domain_util_prepareSprints',
);

type AnyPlannedCapacity = PlannedCapacity | OriginalPlannedCapacity;
type PlannedCapacitiesBySprintId<T extends AnyPlannedCapacity> = {
	[sprintId: string]: T;
};
type GroupedPlannedCapacities<T extends AnyPlannedCapacity> = {
	[teamId: string]: PlannedCapacitiesBySprintId<T>;
};

const groupPlannedCapacities = <T extends AnyPlannedCapacity>(
	plannedCapacities: T[],
): GroupedPlannedCapacities<T> =>
	R.compose<T[], Record<string, T[]>, GroupedPlannedCapacities<T>>(
		R.map<Record<string, T[]>, GroupedPlannedCapacities<T>>((plannedCapacitiesInGroup: T[]) =>
			indexBy(R.prop('iterationId'), plannedCapacitiesInGroup),
		),
		R.groupBy(R.prop('teamId')),
	)(plannedCapacities);

export const preparePlannedCapacities = (arr: PlannedCapacity[]): PlannedCapacities =>
	groupPlannedCapacities<PlannedCapacity>(arr);

export const prepareOriginalPlannedCapacities = (
	arr: OriginalPlannedCapacity[],
): OriginalPlannedCapacities => groupPlannedCapacities<OriginalPlannedCapacity>(arr);

export const prepareSolution = ({
	id,
	solution,
	calculationConfiguration,
}: Api.CalculationResult) => {
	if (!solution) {
		throw new Error('Expected calculationResult with solution');
	}
	const { projects, teams } = solution;
	return {
		id,
		projects,
		teams,
		calculationConfiguration,
	};
};

export const prepareTeam = ({
	itemKey,
	resources,
	shareable,
	scenarioType,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	values,
	originals,
	externalId,
	isPlanTeam,
}: Api.Team): Team => ({
	id: itemKey,
	resources,
	shareable,
	originals,
	scenarioType,
	// Casted since there are fields on TeamValues that aren't on Team should be fixed up
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	...(values as any),
	avatarUrl: values.avatarUrl || '',
	externalId,
	isPlanTeam,
});

export const prepareTeams = (teams: Api.Team[]): Team[] => teams.map(prepareTeam);

export const mergeApiVersions = (
	allVersions: {
		[key: string]: Version;
	},
	projectId: number,
	versions: Api.Version[],
	isPreparingRemovedVersions: boolean,
) => {
	const newAllVersions = allVersions;
	let versionsToProcess;

	if (isPreparingRemovedVersions) {
		versionsToProcess = versions.filter(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			R.propEq<string, any>('scenarioType', SCENARIO_TYPE.DELETED),
		);
	} else {
		versionsToProcess = versions.filter(
			R.complement(R.propEq('scenarioType', SCENARIO_TYPE.DELETED)),
		);
	}
	// eslint-disable-next-line @typescript-eslint/no-shadow
	for (const { itemKey, values } of versionsToProcess) {
		const mappedValues = { ...values };
		if (isDefined(mappedValues.end)) {
			mappedValues.end = endOfUtcDay(mappedValues.end);
		}

		const prev = allVersions[itemKey] || { projects: [] };
		newAllVersions[itemKey] = {
			...prev,
			...mappedValues,
			id: itemKey,
			projects: Array.from(new Set([...prev.projects, projectId])),
		};
	}

	return newAllVersions;
};

export const prepareVersions = (
	projects: Api.Project[],
	crossProjectVersions: Api.CrossProjectVersion[],
	{
		isPreparingRemovedVersions,
	}: {
		isPreparingRemovedVersions: boolean;
	},
): Version[] => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	let allVersions: Record<string, any> = {};
	const crossProjectVersionsByIdMap = indexBy(R.prop('itemKey'), crossProjectVersions);
	for (const { id, versions } of projects) {
		allVersions = mergeApiVersions(allVersions, id, versions, isPreparingRemovedVersions);
	}

	for (const versionId of Object.keys(allVersions)) {
		const crossProjectVersion = allVersions[versionId].crossProjectVersion;

		if (
			isDefined(crossProjectVersion) &&
			!isDefined(crossProjectVersionsByIdMap[crossProjectVersion])
		) {
			allVersions[versionId].crossProjectVersion = null;
		}
	}

	return R.values(allVersions);
};

export const prepareOriginalVersions = (
	projects: Api.Project[],
	crossProjectVersions: Api.CrossProjectVersion[],
): OriginalVersions => {
	const allVersions: Api.Version[] = [];
	const crossProjectVersionsByIdMap = indexBy(R.prop('itemKey'), crossProjectVersions);

	for (const { versions } of projects) {
		allVersions.push(...versions);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const originalVersions: Record<string, any> = {};
	allVersions.forEach(({ itemKey: id, originals, scenarioType }) => {
		if (scenarioType) {
			const originalValues = {
				...originals,
			};
			const { crossProjectVersion } = originals;

			/** A version can be assigned to a cross-project version in another plan. In that case it won't be
			 *  part of our crossProjectVersionsByIdMap. In that situation we want to set the originalValue.crossProjectVersion to null.
			 */
			if (
				isDefined(crossProjectVersion) &&
				!isDefined(crossProjectVersionsByIdMap[crossProjectVersion])
			) {
				originalValues.crossProjectVersion = null;
			}

			originalVersions[id] = originalValues;
		}

		return originalVersions;
	});

	return originalVersions;
};

export const getVersionsOfCrossProjectVersion = (
	crossProjectVersionId: string,
	versions: Version[],
): string[] =>
	versions
		.filter(
			({ crossProjectVersion }) =>
				isDefined(crossProjectVersion) && crossProjectVersion === crossProjectVersionId,
		)
		.map(({ id }) => id);

export const prepareCrossProjectVersions = (
	crossProjectVersions: Api.CrossProjectVersion[],
	versions: Version[],
	{
		isPreparingDeletedCrossProjectVersions,
	}: {
		isPreparingDeletedCrossProjectVersions: boolean;
	},
): CrossProjectVersion[] => {
	const [deletedCrossProjectVersions, existingCrossProjectVersions] =
		R.partition<Api.CrossProjectVersion>(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			R.propEq<string | number, any>('scenarioType', SCENARIO_TYPE.DELETED),
			crossProjectVersions,
			// eslint-disable-next-line @typescript-eslint/no-shadow
		).map((crossProjectVersions) =>
			crossProjectVersions.map(({ itemKey, values: { name } }) => ({
				id: itemKey,
				name,
				versions: getVersionsOfCrossProjectVersion(itemKey, versions),
			})),
		);

	if (isPreparingDeletedCrossProjectVersions) {
		return deletedCrossProjectVersions;
	}

	return existingCrossProjectVersions;
};

export const prepareOriginalCrossProjectVersions = (
	crossProjectVersions: Api.CrossProjectVersion[],
): OriginalCrossProjectVersions => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const originalCrossProjectVersions: Record<string, any> = {};
	crossProjectVersions.forEach(({ itemKey: id, originals, scenarioType }) => {
		if (scenarioType) {
			originalCrossProjectVersions[id] = {
				...originals,
			};
		}
		return originalCrossProjectVersions;
	});

	return originalCrossProjectVersions;
};

export const isFlagHidden = (flagName: FlagKey, hiddenFlags: HiddenFlags): boolean => {
	const currentHiddenFlag = hiddenFlags[flagName];
	const currentTimeStamp = Date.now();

	return !!(currentHiddenFlag && currentTimeStamp < currentHiddenFlag.hiddenUntilTimestamp);
};

const plannedCapacityIsDeleted = (plannedCapacity: Api.WRMPlannedCapacity): boolean =>
	plannedCapacity.scenarioType === SCENARIO_TYPE.DELETED;

const mapWRMPlannedCapacity = (plannedCapacity: Api.WRMPlannedCapacity): PlannedCapacity => {
	const {
		itemKey,
		values: { teamKey, iterationId, schedulingMode, planningUnit, capacity },
	} = plannedCapacity;

	return {
		itemKey,
		teamId: teamKey,
		iterationId: String(iterationId),
		schedulingMode,
		planningUnit,
		capacity,
	};
};

const mapWRMOriginalPlannedCapacity = (
	plannedCapacity: Api.WRMPlannedCapacity,
): PlannedCapacity => {
	const {
		itemKey,
		values: { teamKey, iterationId, schedulingMode, planningUnit, capacity },
		originals,
	} = plannedCapacity;

	if (plannedCapacityIsDeleted(plannedCapacity)) {
		return {
			itemKey,
			teamId: teamKey,
			iterationId: String(iterationId),
			schedulingMode,
			planningUnit,
			capacity,
		};
	}

	return {
		itemKey,
		teamId: teamKey,
		iterationId: String(iterationId),
		schedulingMode,
		planningUnit,
		capacity: typeof originals.capacity === 'number' ? originals.capacity : null,
	};
};

const indexBySprintId = (
	capacityArray: PlannedCapacity[],
): {
	[key: string]: PlannedCapacity;
} => indexBy(R.prop('iterationId'), capacityArray);

// Check that the capacity has values
// This is a workaround for this issue: https://bulldog.internal.atlassian.com/browse/JPOS-5179
// TODO: remove this check once that's fixed
const validCapacity = (plannedCapacity: Api.WRMPlannedCapacity) => !!plannedCapacity.values.teamKey;

export const prepareWRMPlannedCapacities = (
	plannedCapacityList: Api.WRMPlannedCapacity[],
): PlannedCapacities =>
	R.pipe<
		Api.WRMPlannedCapacity[],
		Api.WRMPlannedCapacity[],
		Api.WRMPlannedCapacity[],
		PlannedCapacity[],
		Record<string, PlannedCapacity[]>,
		PlannedCapacities
	>(
		R.filter(validCapacity),
		R.filter(
			(plannedCapacity: Api.WRMPlannedCapacity) => !plannedCapacityIsDeleted(plannedCapacity),
		),
		R.map(mapWRMPlannedCapacity),
		R.groupBy((it) => it.teamId),
		R.map<Record<string, PlannedCapacity[]>, PlannedCapacities>(indexBySprintId),
	)(plannedCapacityList);

export const prepareWRMOriginalPlannedCapacities = (
	plannedCapacityList: Api.WRMPlannedCapacity[],
): PlannedCapacities =>
	R.pipe<
		Api.WRMPlannedCapacity[],
		Api.WRMPlannedCapacity[],
		Api.WRMPlannedCapacity[],
		PlannedCapacity[],
		Record<string, PlannedCapacity[]>,
		PlannedCapacities
	>(
		R.filter(validCapacity),
		R.filter((c: Api.WRMPlannedCapacity) => !!c.scenarioType),
		R.map(mapWRMOriginalPlannedCapacity),
		R.groupBy((it) => it.teamId),
		R.map<Record<string, PlannedCapacity[]>, PlannedCapacities>(indexBySprintId),
	)(plannedCapacityList);

const updateSingleRef = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any,
	key: string | number,
	oldRef?: string | null,
	newRef?: string | null, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
	if (item[key] !== oldRef) return item;

	return {
		...item,
		[key]: newRef,
	};
};

const updateMultiRef = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any,
	key: string | number,
	oldRef?: string | null,
	newRef?: string | null, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
	const refs = item[key];
	const index = refs.indexOf(oldRef);

	if (index < 0) return item;

	return {
		...item,
		[key]: newRef === null ? R.remove(index, 1, refs) : R.update(index, newRef, refs),
	};
};

const updateRef = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any,
	key: string | number,
	oldRef?: string | null,
	newRef?: string | null, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
	if (oldRef === newRef) return item;
	if (Array.isArray(item[key])) return updateMultiRef(item, key, oldRef, newRef);
	return updateSingleRef(item, key, oldRef, newRef);
};

export function updateRefs<T>(
	items: T,
	refs: {
		[key: string]: [string | null | undefined, string | null | undefined][];
	},
): T {
	return R.map(
		(item) =>
			R.keys(refs).reduce(
				// eslint-disable-next-line @typescript-eslint/no-shadow
				(item, key) =>
					refs[key].reduce(
						// eslint-disable-next-line @typescript-eslint/no-shadow
						(item, [oldRef, newRef]) => updateRef(item, key, oldRef, newRef),
						item,
					),
				item,
			),
		items,
	);
}
