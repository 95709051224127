import * as R from 'ramda';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Person } from '../../state/domain/assignees/types.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import type { State } from '../../state/types.tsx';
import { getIssues } from '../raw-issues/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Person } from '../../state/domain/assignees/types';
export const getAssigneeListFetchStatus = (state: State): boolean =>
	state.ui.Main.Tabs.Roadmap.Fields.Assignee.isFetchAssigneeListInProgress;

export const getIssueIdForFetchAssigneeListProgress = (state: State): string =>
	state.ui.Main.Tabs.Roadmap.Fields.Assignee.issueIdForFetchAssigneeListProgress;

export const getAssigneeList = (state: State): Person[] => state.domain.assignees.assigneeList;

export const getAssigneesById = createSelector([getAssigneeList], (assignees) =>
	indexBy(R.prop('personId'), assignees),
);

export const getAssigneesOfIssuesPure = (issues: Issue[], assigneeList: Person[]): Person[] => {
	const assignees: Set<Person> = new Set();
	issues.forEach((issue) => {
		const userId = issue.assignee;
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const assignee = assigneeList.find((assignee) => assignee.jiraUser.accountId === userId);
		if (assignee) {
			assignees.add(assignee);
		}
	});

	return Array.from(assignees);
};

export const getAssigneesOfIssues = createSelector(
	[getIssues, getAssigneeList],
	getAssigneesOfIssuesPure,
);
