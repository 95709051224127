import { type RefObject, useEffect } from 'react';

export function useOnClickOutside(
	ref: RefObject<HTMLDivElement>,
	handler: (event: MouseEvent) => void,
) {
	useEffect(() => {
		const listener = (event: MouseEvent) => {
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || (event.target instanceof Node && ref.current.contains(event.target))) {
				return;
			}

			handler(event);
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('mousedown', listener);

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.removeEventListener('mousedown', listener);
		};
	}, [ref, handler]);
}
