import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	plannedCapacityUnits: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.title-bar.update-jira.changes-table.value-change.sprint-velocity.planned-capacity-units',
		defaultMessage:
			'{planningUnit, select, Hours {hours} StoryPoints {points} Days {days} other {points}}',
		description: 'Planning units',
	},
});
