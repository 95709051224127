import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	advancedFieldsMenuNoSelectedFields: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.advanced-fields-menu-no-selected-fields',
		defaultMessage: 'No fields have been selected.',
		description:
			'Text displayed when no fields match the keywords typed in the Choose fields dropdown list',
	},
	advancedFieldsMenuCustomFieldLozenge: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.advanced-fields-menu-custom-field-lozenge',
		defaultMessage: 'CUSTOM',
		description: 'Label for custom field lozenge',
	},
	advancedFieldsMenuSelectedFieldDragTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.advanced-fields-menu-selected-field-drag-tooltip',
		defaultMessage: 'Rearrange field order',
		description: 'Tooltip text displayed when hovering over the drag icon of a selected field',
	},
	advancedFieldsMenuSelectedFieldRemoveTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.advanced-fields-menu-selected-field-remove-tooltip',
		defaultMessage: 'Remove field',
		description: 'Tooltip text displayed when hovering over the remove icon of a selected field',
	},
	advancedFieldsMenuSelectedFieldRemoveAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.advanced-fields-menu-selected-field-remove-aria-label',
		defaultMessage: 'Remove {fieldName} field',
		description:
			'Aria Label text when focus is switched to the remove icon of a selected field. fieldName is the name of an issue field such as Assignee, Sprint, or Summary.',
	},
	advancedFieldMenuFocusedFieldAnnouncement: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.advanced-field-menu-focused-field-announcement',
		defaultMessage:
			'Draggable item. Ensure your screen reader is not in browse mode and then press the space bar to lift.',
		description: 'Screen reader announcement when user focuses draggable item with keyboard.',
	},
	advancedFieldMenuGrabbedFieldAnnouncement: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.advanced-field-menu-grabbed-field-announcement',
		defaultMessage:
			'You have lifted {fieldName} field. Use the arrow keys to move, space bar to drop, and escape to cancel.',
		description:
			'Screen reader announcement when user grabs the draggable item with keyboard. fieldName is the name of the field grabbed',
	},
	advancedFieldMenuDroppedFieldAnnouncement: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.advanced-field-menu-dropped-field-announcement',
		defaultMessage: 'You have moved {fieldName} field to Position {position}.',
		description:
			'Screen reader announcement when user drops the draggable item. `fieldName` is the name of the field grabbed,  `position` is the position where it is dropped',
	},
	advancedFieldMenuMovedFieldAnnouncement: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.advanced-fields-menu.selected-fields.advanced-field-menu-moved-field-announcement',
		defaultMessage:
			'You have moved an item from the Position {initialPosition} to Position {currentPosition}. Use the arrow keys to move, space bar to drop, and escape to cancel."',
		description:
			'Screen reader announcement when user drops the draggable item. `initialPosition` is the initial position when grabbed, `currentPosition` is the new position of the item',
	},
});
