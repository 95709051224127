import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { connect } from '@atlassian/jira-react-redux/src/index.tsx';
import mapDispatchToProps from './command.tsx';
import mapStateToProps from './query.tsx';
import ViewOld from './view-old.tsx';
import View from './view.tsx';

// Remove OldComponent and NewComponent when cleaning up FG migrate_plan_filter_to_popup
const OldComponent = connect(mapStateToProps, mapDispatchToProps)(ViewOld);
const NewComponent = connect(mapStateToProps, mapDispatchToProps)(View);

// Replace with the below when cleaning up FG migrate_plan_filter_to_popup
// export default connect(mapStateToProps, mapDispatchToProps)(View);
export default componentWithFG('migrate_plan_filter_to_popup', NewComponent, OldComponent);
