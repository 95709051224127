import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';

const getChangeStatusDialog = (state: State) => state.domain.updateJira.changeStatus;

const mapStateToProps = createSelector([getChangeStatusDialog], (changeStatus) => ({
	...changeStatus,
}));

export default mapStateToProps;
