import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	createANewView: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.create-a-new-view',
		defaultMessage: 'Create a new view',
		description: 'Label for create a new view menu item',
	},
	draft: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.draft',
		defaultMessage: 'draft',
		description: 'Label for draft lozenge',
	},
	edited: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.edited',
		defaultMessage: 'edited',
		description: 'Label for edited lozenge',
	},
	manageViews: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.manage-views',
		defaultMessage: 'Manage saved views',
		description: 'Label for manage views menu item',
	},
	reset: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.reset',
		defaultMessage: 'Reset',
		description: 'Label for the reset view button',
	},
	delete: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.delete',
		defaultMessage: 'Delete',
		description: 'Label for the delete draft view button',
	},
	saveChanges: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.save-changes',
		defaultMessage: 'Save',
		description: 'Label for the save button',
	},
	savedViews: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.saved-views',
		defaultMessage: 'Saved views',
		description: 'Label for Roadmap saved views dropdown group heading',
	},
	untitled: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.untitled',
		defaultMessage: 'Untitled',
		description: 'View name for untitled view',
	},
	iphLink: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.iph-link',
		defaultMessage: 'Read more about saved views',
		description: 'Link to the IPH article about saved views',
	},
	skeletonAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.saved-view-switcher.skeleton-aria-label',
		defaultMessage: 'Loading',
		description: 'Aria label text for skeleton, when waiting for the views to be ready',
	},
});
