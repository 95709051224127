import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	default: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.dependency-settings.default',
		defaultMessage: 'Badges',
		description: 'An option indicating the default display for dependencies',
	},
	lines: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.dependency-settings.lines',
		defaultMessage: 'Lines',
		description: 'An option that tells the app to display lines for dependencies',
	},
	count: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.dependency-settings.count',
		defaultMessage: 'Show number of dependencies',
		description: 'An option that tells the app to display counts for dependencies',
	},
	disabledTooltip: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.dependency-settings.disabled-tooltip',
		defaultMessage: 'This option is unavailable in list view',
		description:
			'Label for dependency settings disabled tooltip message in view settings for the list view',
	},
	dependencySettingAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.dependency-settings.dependency-setting-aria-label',
		defaultMessage: 'Dependency style {selectedOption}',
		description:
			'AriaLabel for dependency settings in view settings, where {selectedOption} is the dependency selected',
	},
});
