import set from 'lodash/set';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { Payload } from '@atlassian/jira-issue-create/src/common/types/index.tsx';
import { formatDateUTC } from '@atlassian/jira-portfolio-3-common/src/date-manipulation/format.tsx';
import { EPIC_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import { urls } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/api.tsx';
import type { CustomField } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/custom-fields/types.tsx';
import type { PlanInfo } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';
import type { AgileInfoType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/system/types.tsx';
import type { Team } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/teams/types.tsx';
import type {
	Issue,
	IssueType,
	CustomIssueTypeValue,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import {
	CustomFieldTypes,
	PACKAGE_NAME,
	ERROR_REPORTING_PACKAGE,
	ERROR_REPORTING_TEAM,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { ParentIssue } from './types.tsx';

type ArjCustomFieldKey = `customField-${number}`;
type CustomFieldKey = `customfield_${number | string}`;

const timestampToFormattedDate = (timestampValue: string | number | undefined) =>
	timestampValue != null && formatDateUTC(timestampValue, 'YYYY-MM-DD');

const isCustomFieldKey = (
	key: string,
): key is
	| `customFieldValueAdded-${number}`
	| `customFieldValueRemoved-${number}`
	| `customField-${number}` => key.startsWith('customField');

export type FetchDescriptionParams = {
	planId: PlanInfo['id'];
	scenarioId: PlanInfo['currentScenarioId'];
	itemKey: string;
};

export const fetchDescriptionDI = (payload: FetchDescriptionParams): Promise<{ value: string }> =>
	fetch(urls.getDescription, {
		method: 'POST',
		body: { ...payload, renderRaw: true },
	}).then((response) => response.json());

// Maps custom field to the type similar to src/packages/issue-create/new-issue-create-trigger/src/constants.tsx
const CUSTOM_FIELD_MAP: { [key: string]: string } = {
	[CustomFieldTypes.DatePicker]: 'datePickerFields',
	[CustomFieldTypes.TextField]: 'singleLineTextFields',
	[CustomFieldTypes.UserPicker]: 'singleSelectUserPickerFields',
	[CustomFieldTypes.NumberField]: 'numberFields',
	[CustomFieldTypes.MultiSelect]: 'multipleSelectFields',
	[CustomFieldTypes.Labels]: 'labelsFields',
	/**
	 * Unsupported in ARJ with new issues
	 */
	// Gic doesn't seem to have an option to show this but we will pass it through anyway incase they support it one day
	[CustomFieldTypes.MultiCheckboxes]: 'multipleSelectFields',
	// New issues cannot have single select fields (drop down wont populate)
	[CustomFieldTypes.SingleSelect]: 'singleSelectFields',
	// New issues cannot have radio select fields (drop down wont populate)
	[CustomFieldTypes.RadioButtons]: 'singleSelectFields',
	// New issues cannot have url (read-only) field
	[CustomFieldTypes.Url]: 'urlFields',
	// Not supported in ARJ
	// 'com.atlassian.jira.plugin.system.customfieldtypes:datetime': 'dateTimePickerFields',
};

const transformCustomFieldKey = (ckey: ArjCustomFieldKey): CustomFieldKey =>
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	ckey?.replace(
		/customFieldValueAdded-|customFieldValueRemoved-|customField-/gi,
		'customfield_',
	) as unknown as CustomFieldKey;

export type GetOpenIssueCreateModalPayloadParams = {
	issue: Issue;
	agileConfig: AgileInfoType;
	issueTypesById: {
		[key: number]: IssueType;
	};
	planInfo: PlanInfo;
	teamsById: { [key: string]: Team };
	startDateCustomField?: CustomField;
	parentIssue?: ParentIssue;
	isIssueSimplified: boolean;
};

export const getOpenIssueCreateModalPayload = async (
	{
		issue,
		agileConfig,
		issueTypesById,
		planInfo,
		teamsById,
		startDateCustomField,
		parentIssue,
		isIssueSimplified,
	}: GetOpenIssueCreateModalPayloadParams,
	// DI for testing easier than jest mock
	fetchDescription: typeof fetchDescriptionDI = fetchDescriptionDI,
): Promise<{ payload: Payload; isSubTask: boolean }> => {
	const {
		epicLinkField,
		storyPointsField,
		sprintsField,
		storyPointEstimateField,
		targetStartField,
		targetEndField,
		teamField,
	} = agileConfig;
	const { values } = issue;
	const { type, project } = values;

	// Do nothing if its NaN
	if (type == null) {
		throw Error('Error opening GIC, no issue type');
	}

	const isSubTask = !!issueTypesById[type]?.subTask;

	const payload = Object.entries(values).reduce(
		(result, [key, val]) => {
			/**
			 * Custom Field
			 */

			// Not possible, type hinting
			if (!result.defaultValues || !result.customFieldsToPreserveOnFormRecreation) {
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return result as never;
			}

			// Dont handle empty values
			if (val == null) return result;

			if (isCustomFieldKey(key)) {
				// We dont prefill removed multi-select values
				if (key.includes('customFieldValueRemoved')) return result;

				const {
					id: customFieldId,
					typeKey = '',
					value: customFieldValue,
				} = val as CustomIssueTypeValue; // eslint-disable-line @typescript-eslint/consistent-type-assertions

				// Don't handle empty values
				if (customFieldValue == null) return result;

				const fieldType = CUSTOM_FIELD_MAP[typeKey];

				// No mapper found unsupported
				// There could actually be things we dont support, which we will need to silence the error
				if (!fieldType) {
					fireErrorAnalytics({
						meta: {
							id: ERROR_REPORTING_PACKAGE.GIC,
							packageName: PACKAGE_NAME,
							teamName: ERROR_REPORTING_TEAM,
						},
						error: new Error(`Unsupported custom field ${typeKey}`),
						sendToPrivacyUnsafeSplunk: false, // Sentry is enough for now
					});
					return result;
				}

				const newVal = (() => {
					switch (fieldType) {
						case 'singleLineTextFields': {
							return {
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								fieldId: transformCustomFieldKey(key as ArjCustomFieldKey),
								text: customFieldValue,
							};
						}
						case 'datePickerFields': {
							return {
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								fieldId: transformCustomFieldKey(key as ArjCustomFieldKey),
								date: {
									formattedDate: timestampToFormattedDate(
										// Date picker values are always number
										// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
										customFieldValue as number,
									),
								},
							};
						}
						case 'singleSelectUserPickerFields': {
							return {
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								fieldId: transformCustomFieldKey(key as ArjCustomFieldKey),
								user: {
									accountId: customFieldValue,
								},
							};
						}
						case 'numberFields': {
							return {
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								fieldId: transformCustomFieldKey(key as ArjCustomFieldKey),
								value: customFieldValue,
							};
						}
						case 'singleSelectFields': {
							return {
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								fieldId: transformCustomFieldKey(key as ArjCustomFieldKey),
								option: { optionId: customFieldValue },
							};
						}
						case 'multipleSelectFields': {
							return {
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								fieldId: transformCustomFieldKey(key as ArjCustomFieldKey),
								options: Array.isArray(customFieldValue)
									? // eslint-disable-next-line @typescript-eslint/no-shadow
										customFieldValue.map((val) => ({ optionId: val }))
									: [{ optionId: customFieldValue }],
							};
						}
						case 'urlFields': {
							return {
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								fieldId: transformCustomFieldKey(key as ArjCustomFieldKey),
								url: customFieldValue,
							};
						}
						case 'labelsFields': {
							return {
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								fieldId: transformCustomFieldKey(key as ArjCustomFieldKey),
								labels: Array.isArray(customFieldValue)
									? // eslint-disable-next-line @typescript-eslint/no-shadow
										customFieldValue.map((val) => ({ name: val }))
									: [{ name: customFieldValue }],
							};
						}
						default:
							return result;
					}
				})();

				if (!newVal) return result;

				// Add the values
				result.customFieldsToPreserveOnFormRecreation.push(`customfield_${customFieldId}`);
				// eslint-disable-next-line no-param-reassign
				result.defaultValues[fieldType] = (result.defaultValues[fieldType] || []).concat(newVal);

				return result;
			}

			/**
			 * Non Custom Field, basically all bespoke handling
			 */

			switch (key) {
				case 'summary': {
					// eslint-disable-next-line no-param-reassign
					result.defaultValues.singleLineTextFields = (
						result.defaultValues.singleLineTextFields || []
					).concat({
						fieldId: key,
						text: val,
					});
					break;
				}
				case 'storyPoints': {
					// eslint-disable-next-line no-param-reassign
					result.defaultValues.numberFields = (result.defaultValues.numberFields || []).concat({
						fieldId: transformCustomFieldKey(
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							(isIssueSimplified ? storyPointEstimateField : storyPointsField) as ArjCustomFieldKey,
						),
						value: val,
					});

					break;
				}
				case 'dueDate': {
					if (typeof val === 'number' || typeof val === 'string') {
						// eslint-disable-next-line no-param-reassign
						result.defaultValues.datePickerFields = (
							result.defaultValues.datePickerFields || []
						).concat({
							// Lower case
							fieldId: 'duedate',
							date: { formattedDate: timestampToFormattedDate(val) },
						});
					}
					break;
				}
				case 'startDate': {
					if (typeof val === 'number' || typeof val === 'string') {
						// eslint-disable-next-line no-param-reassign
						result.defaultValues.datePickerFields = (
							result.defaultValues.datePickerFields || []
						).concat({
							// Lower case
							fieldId: startDateCustomField
								? `customField-${startDateCustomField.id}`
								: 'startdate',
							date: { formattedDate: timestampToFormattedDate(val) },
						});
					}
					break;
				}
				case 'targetStart':
				case 'targetEnd': {
					if (!(typeof val === 'number' || typeof val === 'string')) {
						break;
					}
					const fieldIdKey = key === 'targetStart' ? targetStartField : targetEndField;
					// eslint-disable-next-line no-param-reassign
					result.defaultValues.datePickerFields = (
						result.defaultValues.datePickerFields || []
					).concat({
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						fieldId: transformCustomFieldKey(fieldIdKey as ArjCustomFieldKey),
						date: { formattedDate: timestampToFormattedDate(val) },
					});
					break;
				}
				case 'sprint': {
					// eslint-disable-next-line no-param-reassign
					result.defaultValues.sprintsField = {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						fieldId: transformCustomFieldKey(sprintsField as ArjCustomFieldKey),
						sprints: Array.isArray(val)
							? val.map((sprint) => ({ sprintId: sprint }))
							: [{ sprintId: val }],
					};
					break;
				}
				case 'assignee':
				case 'reporter': {
					// eslint-disable-next-line no-param-reassign
					result.defaultValues.singleSelectUserPickerFields = (
						result.defaultValues.singleSelectUserPickerFields || []
					).concat({
						fieldId: key,
						user: { accountId: val },
					});
					break;
				}
				case 'parent': {
					// eslint-disable-next-line no-param-reassign
					result.defaultValues.parentField = { issueId: val };
					if (isSubTask && val != null) {
						// We only want to send parentId when the issue is a subtask
						// If we send parentId when the issue is an epic, the modal
						// will not allow creation of the epic issue due to rules
						// imposed by the GIC team
						// eslint-disable-next-line no-param-reassign
						result.parentId = { parentId: String(val) };
					}
					break;
				}
				case 'labels': {
					const labelValue = {
						fieldId: key,
						labels: Array.isArray(val) ? val.map((label) => ({ name: label })) : [{ name: val }],
					};
					// eslint-disable-next-line no-param-reassign
					result.defaultValues.labelsFields = Array.isArray(result.defaultValues.labelsFields)
						? result.defaultValues.labelsFields.concat(labelValue)
						: [labelValue];
					break;
				}
				case 'fixVersions': {
					// eslint-disable-next-line no-param-reassign
					result.defaultValues.multipleVersionPickerFields = {
						fieldId: key,
						versions: Array.isArray(val)
							? val.map((sprint) => ({ versionId: sprint }))
							: [{ versionId: val }],
					};
					break;
				}
				case 'components': {
					// eslint-disable-next-line no-param-reassign
					result.defaultValues.components = Array.isArray(val)
						? // eslint-disable-next-line @typescript-eslint/no-shadow
							val.map((val) => ({ componentId: val }))
						: [{ componentId: val }];
					break;
				}
				case 'team': {
					const team = values.team ? teamsById[values.team] : null;
					// For plan only team, teamId is set to empty as it doesn't exist on GIC team field
					const teamId = team && team.externalId ? team.externalId : '';

					const teamVal = {
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						fieldId: transformCustomFieldKey(teamField as ArjCustomFieldKey),
						team: { teamId },
					};

					// eslint-disable-next-line no-param-reassign
					result.defaultValues.atlassianTeamFields = teamVal;

					break;
				}
				// Things unhandled or handled else where
				case 'type':
				case 'project':
				case 'lexoRank':
				case 'issueLink':
				case 'description':
				case 'color':
				default:
					break;
			}

			return result;
		},
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		{
			defaultValues: {},
			customFieldsToPreserveOnFormRecreation: [],
		} as Payload,
	);

	/**
	 * Handle the rest of the meta data
	 */

	payload.issueType = { issueTypeId: String(type) };

	if (project) payload.project = { projectId: String(project) };

	if (values.description) {
		const { value } = await fetchDescription({
			planId: planInfo.id,
			scenarioId: planInfo.currentScenarioId,
			itemKey: issue.id,
		});

		const descriptionVal = {
			fieldId: 'description',
			richText: { wikiText: value },
		};

		set(
			payload,
			'defaultValues.richTextFields',
			Array.isArray(payload.defaultValues?.richTextFields)
				? payload.defaultValues?.richTextFields.concat(descriptionVal)
				: [descriptionVal],
		);
	}

	if (parentIssue?.hierarchyLevel === EPIC_LEVEL) {
		set(payload, 'defaultValues.epicLinkField', {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			fieldId: transformCustomFieldKey(epicLinkField as ArjCustomFieldKey),
			epicLink: { issueKey: parentIssue.key },
		});
	}

	return { payload, isSubTask };
};
