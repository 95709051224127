import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	priorityCellLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.priority.priority-cell-label',
		defaultMessage: 'Choose a priority for issue {issue}',
		description: 'Label for priority cell',
	},
	notInPlan: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.priority.not-in-plan',
		defaultMessage: 'Not in plan',
		description: 'Label for option when priority no more available',
	},
	tooltipText: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.priority.tooltip-text',
		defaultMessage: 'This priority is no longer available',
		description: 'text for tooltip for priority that is not available',
	},
	placeholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.columns.cells.priority.placeholder',
		defaultMessage: 'Choose a priority',
		description: 'The placeholder for the priority selector',
	},
});
