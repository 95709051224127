import React from 'react';
import ExpandIcon from '@atlaskit/icon/glyph/chevron-down';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import Pulse from './pulse/index.tsx';
import { DefaultButton, DoneButton, InProgressButton, ToDoButton } from './styled.tsx';
import type { Props } from './types.tsx';

const getButton = (categoryId: number) => {
	switch (categoryId) {
		case 2:
			return ToDoButton;
		case 3:
			return DoneButton;
		case 4:
			return InProgressButton;
		default:
			return DefaultButton;
	}
};

export const StatusButton = (props: Props) => {
	const {
		ariaLabel,
		isEditable,
		isOpen,
		status,
		targetRef,
		statusButtonRef,
		shouldFitContainer = true,
	} = props;
	const Button = getButton(status.statusCategory.id);

	return (
		<div ref={targetRef}>
			<Tooltip position="top" content={status.name}>
				<Pulse status={status}>
					<Button
						id="issue.fields.status-view.status-button"
						ref={statusButtonRef}
						aria-label={ariaLabel}
						aria-expanded={isOpen}
						isDisabled={!isEditable}
						iconAfter={<ExpandIcon label="" />}
						testId="issue-field-status.ui.status-view.status-button.status-button"
						shouldFitContainer={shouldFitContainer}
						// When the popup is open we don't want to track the click on this button as it solely closes
						// the popup.
						// The logic here may look incorrect, but it turns out that when clicking this button the
						// `isOpen` toggle happens before the `onClick` handler that sends the press tracing.
						{...(fg('issue-jiv-18579-status-field-interaction-name')
							? {
									// When cleaning up we will need to disable the `jira/ufo/valid-labels` lint rule
									// because for this particular interaction we only want to track
									// opening the status popup.
									interactionName: isOpen ? 'issue.fields.status.popup.open' : undefined,
								}
							: {})}
					>
						{status.name}
					</Button>
				</Pulse>
			</Tooltip>
		</div>
	);
};
