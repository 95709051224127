import React from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import IssueGroup from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/view/main/tabs/roadmap/scope/issues/group/index.tsx';
import type { Props } from './types.tsx';

export const Group = ({ item }: Props) => (
	<Box xcss={containerStyles}>{item && <IssueGroup isStickyGroupHeader item={item} />}</Box>
);

const containerStyles = xcss({
	position: 'relative',
});
