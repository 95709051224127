import isNil from 'lodash/isNil';
import { ISSUE_HIERARCHY_LEVEL_BASE } from '@atlassian/jira-issue-type-hierarchies/src/index.tsx';
import { createMigrate, incremental } from '../common/migrate.tsx';
import {
	HIERARCHY_FILTER_ID,
	HIERARCHY_RANGE_FILTER_ID,
	type FiltersStateV0,
	type FiltersStateV1,
} from './types.tsx';

// note: this function is only used in this file but exported for testing purposes in ./migrate.test.tsx
export function fromV0ToV1(prevState: FiltersStateV0): FiltersStateV1 {
	return {
		...prevState,
		[HIERARCHY_RANGE_FILTER_ID]: {
			id: HIERARCHY_RANGE_FILTER_ID,
			value:
				!isNil(prevState[HIERARCHY_FILTER_ID]?.value?.start) &&
				!isNil(prevState[HIERARCHY_FILTER_ID]?.value?.end)
					? {
							start: prevState[HIERARCHY_FILTER_ID].value.start - 1,
							end: prevState[HIERARCHY_FILTER_ID].value.end - 1,
						}
					: {
							start: ISSUE_HIERARCHY_LEVEL_BASE,
							end: ISSUE_HIERARCHY_LEVEL_BASE,
						},
		},
	};
}

export const migrateCalendarFilters = createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: FiltersStateV0): FiltersStateV0 {
				return prevState;
			},
			V1: fromV0ToV1,
		},
		{ prefix: 'calendarFilters', from: 'V0' },
	),
);

export default createMigrate(
	...incremental(
		{
			V0: function fromV0ToV0(prevState: FiltersStateV0): FiltersStateV0 {
				return prevState;
			},
			V1: fromV0ToV1,
		},
		{ prefix: 'filters', from: 'V0' },
	),
);
