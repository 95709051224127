export const ISSUE_LIMIT_WARNING = 'state.domain.flags.ISSUE_LIMIT_WARNING' as const;
export const PROJECT_LIMIT_WARNING = 'state.domain.flags.PROJECT_LIMIT_WARNING' as const;
export const REPARENT_TMP_TO_EXTERNAL_WARNING =
	'state.domain.flags.REPARENT_TMP_TO_EXTERNAL_WARNING' as const;
export const REPARENT_CMP_TO_TMP_WARNING =
	'state.domain.flags.REPARENT_CMP_TO_TMP_WARNING' as const;
export const ISSUE_SEARCH_WARNING = 'state.domain.flags.ISSUE_SEARCH_WARNING' as const;

export const SORTED_PREVENT_ISSUE_RANKING_WARNING =
	'state.domain.flags.SORTED_PREVENT_ISSUE_RANKING_WARNING' as const;

export const PROJECT_LIMIT_EXCEPTION = 'project-limit-exceeded' as const;
export const NO_JSW_ACCESS_FOR_USER = 'no-jira-software-access-for-user' as const;
export const GOALS_ERROR = 'jpo-goals-error-flag' as const;

export type FlagKey =
	| typeof ISSUE_LIMIT_WARNING
	| typeof PROJECT_LIMIT_WARNING
	| typeof ISSUE_SEARCH_WARNING
	| typeof SORTED_PREVENT_ISSUE_RANKING_WARNING
	| typeof NO_JSW_ACCESS_FOR_USER
	| typeof REPARENT_TMP_TO_EXTERNAL_WARNING
	| typeof REPARENT_CMP_TO_TMP_WARNING
	| typeof GOALS_ERROR;

export type Flag = {
	key: FlagKey;
	context?: Record<string, string>;
};

export type Flags = Flag[];
