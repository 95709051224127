export const SET_INTRO_OPEN = 'state.ui.onboarding.SET_INTRO_OPEN' as const;

export type SetIntroOpenAction = {
	type: typeof SET_INTRO_OPEN;
	payload: boolean;
};

export const SET_ONBOARDING_SHOWN = 'state.ui.onboarding.SET_ONBOARDING_SHOWN' as const;

export const SET_ACTIVE_CARD = 'state.ui.onboarding.SET_ACTIVE_CARD' as const;

export type SetOnBoardingShownAction = {
	type: typeof SET_ONBOARDING_SHOWN;
	payload: boolean;
};

export type SetActiveCardAction = {
	type: typeof SET_ACTIVE_CARD;
	payload: string;
};

export const setOnBoardingShown = (payload: boolean) => ({
	type: SET_ONBOARDING_SHOWN,
	payload,
});

export const setActiveCard = (payload: string) => ({
	type: SET_ACTIVE_CARD,
	payload,
});
