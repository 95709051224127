import React, { useState } from 'react';
import * as R from 'ramda';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog, {
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter,
} from '@atlaskit/modal-dialog';
import { SimpleTag } from '@atlaskit/tag';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { useIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import type { OptionType } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import { type AnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { MODAL_DIALOG_WIDTH } from '../../types.tsx';
import FieldActionSelect from './field-action-select/index.tsx';
import LabelSelect from './label-select/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import { type Props, FieldAction, type FieldActionType } from './types.tsx';

const SetLabelDialog = ({
	plan,
	selectedIssues,
	bulkUpdateAttribute,
	toggleModalDialog,
}: Props) => {
	const { formatMessage } = useIntl();

	const [fieldAction, setFieldAction] = useState<FieldActionType>(FieldAction.ADD);
	const [editedLabels, setEditedLabels] = useState<OptionType[]>([]);

	const getCurrentValue = () => {
		const firstValue = selectedIssues[0].labels;
		const isSameValue = !selectedIssues.some(
			(issue) => !R.equals(issue.labels?.sort(), firstValue?.sort()),
		);

		if (isSameValue && !firstValue?.length) {
			return <>{formatMessage(messages.noValues)}</>;
		}

		if (isSameValue && firstValue) {
			return (
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				<div className={styles['current-container']}>
					{firstValue.map((label, i) => (
						<SimpleTag key={i} text={label} />
					))}
				</div>
			);
		}
		return <>{formatMessage(messages.variousValues)}</>;
	};

	const currentContent = (
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		<div className={styles['value-placeholder']}>
			{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
			<div className={styles['current-value-title']}>{getCurrentValue()}</div>
		</div>
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onLabelChange = (option: any) => {
		setEditedLabels(option);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onSubmit = (_: any, analyticsEvent: AnalyticsEvent) => {
		// editedLabels are in the form { label: string, value: string }
		// We transform them into an array
		const labelsArray = editedLabels.map((editedLabel) => editedLabel.value);
		bulkUpdateAttribute({
			labels: {
				value: fieldAction !== FieldAction.CLEAR ? labelsArray : [],
				fieldAction,
			},
		});

		// Analytics
		const [actionSubject, eventAction] =
			PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_ACTION_SUCCEEDED.split(' ');
		fireUIAnalytics(
			analyticsEvent.update({ action: eventAction, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.BULK_ACTIONS_ACTION_SUCCEEDED,
			{
				selectedOption: 'Labels',
				fieldAction,
			},
		);

		toggleModalDialog();
	};

	return (
		<ShortcutScope>
			<ModalDialog
				autoFocus
				shouldScrollInViewport
				onClose={toggleModalDialog}
				width={MODAL_DIALOG_WIDTH}
			>
				<ModalHeader>
					<ModalTitle>{formatMessage(messages.setHeader)}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles['form-group']}>
						<span>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles['value-label']}>{formatMessage(commonMessages.current)}</div>
							{currentContent}
						</span>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.icon}>
							<ArrowRightIcon label={formatMessage(messages.arrowRightLabel)} />
						</div>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles['new-value']}>
							{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
							<div className={styles['value-label']}>{formatMessage(commonMessages.new)}</div>
							<FieldActionSelect fieldAction={fieldAction} setFieldAction={setFieldAction} />
							{fieldAction !== FieldAction.CLEAR && (
								<LabelSelect
									plan={plan}
									editedLabels={editedLabels}
									onLabelChange={onLabelChange}
								/>
							)}
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button appearance="subtle" onClick={toggleModalDialog}>
						{formatMessage(commonMessages.cancel)}
					</Button>
					<Button
						appearance="primary"
						onClick={onSubmit}
						isDisabled={editedLabels.length <= 0 && fieldAction !== FieldAction.CLEAR}
					>
						{formatMessage(commonMessages.apply)}
					</Button>
				</ModalFooter>
			</ModalDialog>
		</ShortcutScope>
	);
};

export default SetLabelDialog;
