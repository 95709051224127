import {
	type ViewMode,
	VIEW_MODES,
} from '@atlassian/jira-portfolio-3-common/src/common/types/view-mode.tsx';
import { getColumnConfigs } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/grid/index.tsx';
import { getViewMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import { createSelector } from '../../reselect/index.tsx';
import { Z_INDEX_LEVEL_OLD, Z_INDEX_LAYER, type Params, type StateProps } from './types.tsx';

export const getNumberOfColumns = createSelector([getColumnConfigs], (columns) => columns.length);

const STATIC_LEVEL_SIZE = 4;
const BUFFER_SIZE = 1;

/**
 * This file is behind fg('plans_performance_improvements'), see src/packages/portfolio-3/portfolio/src/app-simple-plans/view/main/tabs/roadmap/table/view.tsx
 * For changes behind fg('plan_timeline_drag_and_drop_field_columns') see query.tsx and query.test.tsx
 */

export const getZIndexPure = (numOfColumns: number, viewMode: ViewMode): StateProps['zIndex'] => {
	const Z_INDEX_LEVEL = Z_INDEX_LEVEL_OLD;

	const lift = (level: number) => (input: number) => {
		const levelSize = numOfColumns + BUFFER_SIZE * 2;
		const levelsBelow = level * levelSize;
		const offset = input + BUFFER_SIZE;

		return offset + levelsBelow + STATIC_LEVEL_SIZE;
	};

	return (props: Params) => {
		if ('layer' in props) {
			switch (props.layer) {
				case Z_INDEX_LAYER.DEPENDENCY_LINES: {
					return 1;
				}

				case Z_INDEX_LAYER.ISSUE_BARS:
					return 2;

				case Z_INDEX_LAYER.ISSUE_BARS_ARROWS: {
					return 3;
				}

				case Z_INDEX_LAYER.AXIS_STICKY_TIME_UNIT: {
					return lift(Z_INDEX_LEVEL.HEADER)(1); // Above the final header cell
				}

				case Z_INDEX_LAYER.STICKY_HEADER_SCROLL_SHADOW:
				case Z_INDEX_LAYER.HORIZONTAL_SCROLL_BAR:
				case Z_INDEX_LAYER.DEPENDENCY_LINE_DRAG_PREVIEW: {
					return lift(Z_INDEX_LEVEL.SUBHEADER)(-1); // Just under the subheader cells
				}

				case Z_INDEX_LAYER.EMPTY_LIST_OVERLAY: {
					return lift(Z_INDEX_LEVEL.SUBHEADER)(-1);
				}

				case Z_INDEX_LAYER.STICKY_GROUP_SCROLL_SHADOW: {
					return lift(Z_INDEX_LEVEL.STICKY)(-1); // Just under the sticky cells
				}

				default:
					return 0;
			}
		}

		if ('rowType' in props) {
			const { column, rowType, level } = props;

			const isTimelineColumn = viewMode === VIEW_MODES.TIMELINE && column === numOfColumns - 1;
			const reversedColumnIndex = numOfColumns - 1 - column;

			if (column === 0) {
				if (rowType === 'header') {
					return lift(Z_INDEX_LEVEL.HEADER)(reversedColumnIndex);
				}

				if (rowType === 'subheader') {
					return lift(Z_INDEX_LEVEL.SUBHEADER)(reversedColumnIndex);
				}

				if (rowType === 'group') {
					return lift(Z_INDEX_LEVEL.STICKY)(reversedColumnIndex);
				}

				return lift(Z_INDEX_LEVEL.DEFAULT)(reversedColumnIndex);
			}

			if (rowType === 'header') {
				return lift(Z_INDEX_LEVEL.HEADER)(reversedColumnIndex);
			}

			if (rowType === 'subheader') {
				return lift(Z_INDEX_LEVEL.SUBHEADER)(reversedColumnIndex);
			}

			if (rowType === 'group') {
				return lift(Z_INDEX_LEVEL.STICKY)(reversedColumnIndex);
			}

			if (isTimelineColumn) {
				return undefined;
			}

			return lift(level ?? Z_INDEX_LEVEL.DEFAULT)(reversedColumnIndex);
		}
		return 0;
	};
};

export const getZIndex = createSelector([getNumberOfColumns, getViewMode], getZIndexPure);

export const mapStateToProps = createSelector([getZIndex], (zIndex) => ({
	zIndex,
}));
