import React, { useEffect, type ReactElement, useState } from 'react';
import { SpotlightTransition } from '@atlaskit/onboarding';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { usePageLayoutResize } from '@atlassian/jira-navigation-system/src/index.tsx';
import { CTP_ONBOARDING_STAGE } from '../../controllers/ctp-onboarding/constants.tsx';
import { useCtpOnboardingTour } from '../../controllers/ctp-onboarding/index.tsx';
import { useOnboardingTour } from '../../controllers/index.tsx';
import { BoardsSpotlight } from './boards-spotlight/index.tsx';
import { CreateIssueSpotlight } from './create-issue-spotlight/index.tsx';
import { TeamsSpotlight } from './teams-spotlight/index.tsx';
import { TimelineSpotlight } from './timeline-spotlight/index.tsx';
import type { Props } from './types.tsx';

export const CtpOnboarding = ({
	planId,
	issueSources,
	projectKey,
}: Props): ReactElement<Props> | null => {
	const [{ stage }, { initCtpOnboarding }] = useCtpOnboardingTour();
	const [{ closed: isPlansOnboardingClosed }] = useOnboardingTour();
	const { expandLeftSidebar: expandLeftSidebarChangingEveryRender } = usePageLayoutResize();
	const [expandLeftSidebar] = useState(() => expandLeftSidebarChangingEveryRender);

	useEffect(() => {
		if (planId && isPlansOnboardingClosed) initCtpOnboarding(planId);
	}, [initCtpOnboarding, planId, isPlansOnboardingClosed]);

	useEffect(() => {
		// Ensure the left sidebar is open for each CTP onboarding step
		expandLeftSidebar();
	}, [stage, expandLeftSidebar]);

	if (!planId) return null;
	if (!stage) return null;

	const getSpotlightStage = () => {
		switch (stage) {
			case CTP_ONBOARDING_STAGE.TIMELINE:
				return <TimelineSpotlight />;
			case CTP_ONBOARDING_STAGE.CREATE_ISSUE:
				return <CreateIssueSpotlight />;
			case CTP_ONBOARDING_STAGE.TEAMS:
				return <TeamsSpotlight />;
			case CTP_ONBOARDING_STAGE.BOARDS:
				return <BoardsSpotlight issueSources={issueSources} projectKey={projectKey} />;
			case CTP_ONBOARDING_STAGE.CLOSED:
				return null;
			default:
				log.safeErrorWithoutCustomerData(
					'plans.ctp-onboarding.spotlightMountError',
					'Spotlight did not mount getSpotlightStage',
					{
						stage,
						planId,

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						path: window.location.pathname.replace(/\d+/g, '{id}'),
						errorMessage: "Didn't expect to get here",
					},
				);
				return null;
		}
	};

	return <SpotlightTransition>{getSpotlightStage()}</SpotlightTransition>;
};
