import React, { Component } from 'react';
import * as R from 'ramda';
import AddIcon from '@atlaskit/icon/glyph/editor/add';
import Tooltip from '@atlaskit/tooltip';
import { injectIntl } from '@atlassian/jira-intl';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { AkSelect } from '@atlassian/jira-portfolio-3-common/src/select/index.tsx';
import type {
	OptionType,
	ValueType,
} from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';
import type { ColourPriority } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/colour-by/types.tsx';
import { getBody } from '@atlassian/jira-portfolio-3-portfolio/src/common/dom/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import ColourPicker from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colour-picker/view.tsx';
import {
	colourPickerPaletteLabels,
	DEFAULT_COLOR,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import viewSettingsMessages from '../messages.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { PropsWithInjectedIntl as Props } from './types.tsx';
import {
	getAvailablePriorities,
	getOptionsToPriorityIds,
	getPriorityIdsToOptions,
	onColourChange,
} from './utils.tsx';

// eslint-disable-next-line jira/react/no-class-components
export class ColourByPriority extends Component<Props> {
	onPrioritiesChange = (index: number, options: ValueType) => {
		const { updatePriorityColour, removePriorityColour } = this.props;
		if (R.isNil(options) || R.isEmpty(options)) {
			removePriorityColour({ index });
		} else {
			updatePriorityColour({ index, priorities: getOptionsToPriorityIds(options) });
		}
	};

	renderPriorityOption = (option: OptionType) => {
		const priorityIcon = this.props.prioritiesById[option.value]?.iconUrl;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.priorityOption}>
				{priorityIcon && (
					<div
						style={{
							backgroundImage: `url(${priorityIcon})`,
						}}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.priorityOptionIcon}
					/>
				)}
				<div>{option.label}</div>
			</div>
		);
	};

	renderRow = (colourPriority: ColourPriority, index: number) => {
		const {
			intl,
			onMenuToggle,
			prioritiesById,
			colourPriorities,
			issuePrioritiesIdList,
			updatePriorityColour,
			removePriorityColour,
		} = this.props;
		const { colour, priorities = [] } = colourPriority;

		const availablePriorities = getAvailablePriorities(colourPriorities, issuePrioritiesIdList);

		return (
			<div
				key={`${index}-${colour || ''}`}
				data-testid={`select-priority-wrapper-${index}`}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={styles.row}
			>
				<ColourPicker
					colour={colour}
					onColourChange={onColourChange(index, removePriorityColour, updatePriorityColour)}
					position="left"
				/>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.prioritySelect}>
					<AkSelect
						aria-label={intl.formatMessage(messages.choosePriority)}
						classNamePrefix={`portfolio-3-portfolio_app-simple-plans_main_tabs_roadmap_view-settings_colour-by-priority_select-priority-${index}`}
						closeMenuOnSelect={false}
						defaultValue={getPriorityIdsToOptions(priorities, prioritiesById)}
						formatOptionLabel={this.renderPriorityOption}
						isDisabled={!colour}
						isMulti
						menuPlacement="auto"
						inputId="priority-select"
						menuPortalTarget={getBody()}
						onChange={(options: ValueType) => this.onPrioritiesChange(index, options)}
						onMenuClose={() => onMenuToggle(false)}
						onMenuOpen={() => onMenuToggle(true)}
						options={getPriorityIdsToOptions(availablePriorities, prioritiesById)}
						placeholder={intl.formatMessage(
							isDefined(colour) ? messages.choosePriority : messages.chooseColour,
						)}
						styles={{
							// eslint-disable-next-line @typescript-eslint/no-shadow
							menuPortal: (styles) => ({
								...styles,
								zIndex: 1000,
							}),
						}}
					/>
				</div>
			</div>
		);
	};

	renderAddColourLink = () => {
		const {
			addPriorityColour,
			intl: { formatMessage },
		} = this.props;
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.addColourLink}>
				<Button
					appearance="link"
					iconBefore={<AddIcon label={formatMessage(messages.addColour)} />}
					onClick={() => addPriorityColour()}
					testId="portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-priority.add-color-link"
				>
					{formatMessage(messages.addColour)}
				</Button>
			</div>
		);
	};

	render() {
		const {
			colourPriorities,
			intl: { formatMessage },
		} = this.props;
		const isAddingNewPriorityColour = colourPriorities.some(({ colour }) => !colour);
		return (
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			<div className={styles.list}>
				{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
				<div className={styles.row}>
					<Button
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className={styles.allOtherIssuesButton}
						iconAfter={
							<Tooltip
								content={formatMessage(colourPickerPaletteLabels[DEFAULT_COLOR])}
								position="top"
							>
								{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop, @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */}
								<div className={styles.swatch} style={{ backgroundColor: DEFAULT_COLOR }} />
							</Tooltip>
						}
						isDisabled
						ariaLabel={formatMessage(viewSettingsMessages.allOtherIssuesButtonLabel)}
					/>
					{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
					<div className={styles.allOtherIssues}>
						{formatMessage(commonMessages.allOtherIssues)}
					</div>
				</div>
				{colourPriorities.map(this.renderRow)}
				{!isAddingNewPriorityColour && this.renderAddColourLink()}
			</div>
		);
	}
}

export default injectIntl(ColourByPriority);
