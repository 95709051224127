import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import Dependencies from './dependencies/reducer.tsx';
import Releases from './releases/reducer.tsx';
import Roadmap from './roadmap/reducer.tsx';
import Teams from './teams/reducer.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	Roadmap,
	Releases,
	Teams,
	Dependencies,
});
