import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	deleteErrorIconLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.delete-error-notification.delete-error-icon-label',
		defaultMessage: 'Delete error warning',
		description: 'Label for warning icon on delete error notification',
	},
	title: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.delete-error-notification.title',
		defaultMessage: 'Unable to delete view',
		description: 'Title for the delete error notification',
	},
	description: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.view-bar.delete-error-notification.description',
		defaultMessage:
			'<strong>{viewName}</strong> could not be deleted as it has been set as the default view for this plan',
		description: 'Description for the delete error notification',
	},
});
