import { RESET, type ResetAction } from './actions.tsx';
import type { Sprint } from './types.tsx';

const initialState: Sprint[] = [];

type Action = ResetAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: Sprint[] = initialState, action: Action): Sprint[] => {
	switch (action.type) {
		case RESET:
			return [...action.payload];
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
