import React, { useState, useCallback } from 'react';
import ViewWorkflowContextService from '@atlassian/jira-issue-field-status/src/services/view-workflow-service/index.tsx';
import StatusView from '@atlassian/jira-issue-field-status/src/ui/status-view/index.tsx';
import ViewWorkflow from '@atlassian/jira-issue-field-status/src/ui/view-workflow/index.tsx';
import WorkflowModal from '@atlassian/jira-issue-field-status/src/ui/workflow-modal/index.tsx';
import type { StatusTransition } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import {
	SCOPE_GLOBAL,
	SCOPE_PROJECT,
} from '@atlassian/jira-project-configuration-commons/src/model/constants.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { transitionsProvider } from './transition-provider.tsx';
import type { Props } from './types.tsx';

export const StatusField = ({
	issue,
	onChange,
	issueKey,
	value,
	projectId,
	transitions,
	loading,
	fetchTransitions,
	isMenuDefaultOpen,
}: Props) => {
	const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
	const [isWorkflowModalMounted, setIsWorkflowModalMounted] = useState(false);
	const tenantContext = useTenantContext();
	const handleChange = useCallback(
		(transition: StatusTransition) => {
			onChange(transition);
		},
		[onChange],
	);

	const onOpenModal = useCallback(() => {
		setIsWorkflowModalOpen(true);
	}, []);

	const onCloseModal = useCallback(() => {
		setIsWorkflowModalOpen(false);
	}, []);

	const onModalMounted = useCallback(() => {
		setIsWorkflowModalMounted(true);
	}, []);

	return (
		<ViewWorkflowContextService baseUrl={tenantContext.baseUrl} issueKey={issueKey}>
			{(workflowServiceApi) => (
				<>
					<StatusView
						appearance="lozenge"
						{...(isMenuDefaultOpen === true ? { defaultOpen: true } : {})}
						value={value}
						transitions={transitions}
						onChange={handleChange}
						isLoading={loading}
						popperProps={{ strategy: 'absolute' }}
						onOpen={() => {
							fetchTransitions();
							const hasFetchedWorkflowService =
								workflowServiceApi.data && workflowServiceApi.data.hasPermission !== undefined;
							if (hasFetchedWorkflowService !== true) {
								workflowServiceApi.fetch();
							}
						}}
						footer={
							<ViewWorkflow
								baseUrl={tenantContext.baseUrl}
								issueKey={issueKey}
								workflowServiceApi={workflowServiceApi}
								onOpenModal={onOpenModal}
								isModalMounted={isWorkflowModalMounted}
							/>
						}
					/>
					<WorkflowModal
						onModalMounted={onModalMounted}
						issueKey={issueKey}
						issueTypeId={`${issue.type}`}
						baseUrl={tenantContext.baseUrl}
						locale={tenantContext.locale}
						cloudId={tenantContext.cloudId}
						projectId={`${projectId}`}
						isOpen={isWorkflowModalOpen}
						onClose={onCloseModal}
						scope={workflowServiceApi.data?.isSimplified === true ? SCOPE_PROJECT : SCOPE_GLOBAL}
					/>
				</>
			)}
		</ViewWorkflowContextService>
	);
};

export default transitionsProvider(StatusField);
