import type { Effect } from 'redux-saga';
import { fork, takeEvery, select, put, call } from 'redux-saga/effects';
import type { StatusTransition } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { ORIGINAL_STATUS_TRANSITION } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getPlan } from '../../query/plan/index.tsx';
import { update as updateIssue } from '../../state/domain/issues/actions.tsx';
import * as originalIssueActions from '../../state/domain/original-issues/actions.tsx';
import type { ScopeIssue } from '../../state/domain/scope/types.tsx';
import { doResetIssueRequest, updateIssueOrInlineCreate } from '../issue/index.tsx';

const UPDATE_ISSUE_STATUS = 'command.issue-status.UPDATE_ISSUE_STATUS' as const;

type UpdateIssueStatusPayload = {
	issue: ScopeIssue;
	transition: StatusTransition;
};

type UpdateIssueStatusAction = {
	type: typeof UPDATE_ISSUE_STATUS;
	payload: UpdateIssueStatusPayload;
};

export const updateIssueStatus = (payload: UpdateIssueStatusPayload) => ({
	type: UPDATE_ISSUE_STATUS,
	payload,
});

function* resetIssueStatus(
	issueId: string,
	originalIssueStatusId: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const { id: planId, currentScenarioId: scenarioId } = yield select(getPlan);
	const body = {
		itemKeys: [issueId],
		planId,
		scenarioId,
		description: { status: { reset: true } }, // hardly reset the issue status in the BE
	};
	const resetStatusResponse = yield call(doResetIssueRequest, body);
	if (resetStatusResponse?.ok) {
		yield put(updateIssue({ id: issueId, status: originalIssueStatusId }));
		yield put(
			originalIssueActions.revertFields({
				id: issueId,
				fields: ['status'],
			}),
		);
	}
}

export function* doUpdateIssueStatus({
	payload: { issue, transition }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UpdateIssueStatusAction): Generator<Effect, any, any> {
	const originalIssueStatusId = issue?.originals?.status;
	const newStatus = transition.to;
	// if the use select back the original status, we should reset the scenario change
	if (transition.id === ORIGINAL_STATUS_TRANSITION.id) {
		yield call(resetIssueStatus, issue.id, originalIssueStatusId);
	} else {
		yield put(
			updateIssueOrInlineCreate({
				id: issue.id,
				status: newStatus.id,
				statusTransition: transition.id,
			}),
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* watchUpdateIssueStatus(): Generator<Effect, any, any> {
	yield takeEvery(UPDATE_ISSUE_STATUS, doUpdateIssueStatus);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchUpdateIssueStatus);
}
