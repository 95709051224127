import React, { type ReactNode } from 'react';
import {
	useHorizontalPercentageOffset,
	useRenderingWindow,
	useZoomLevel,
} from '@atlassian/jira-portfolio-3-horizontal-scrolling/src/controllers/index.tsx';
import { getTodayColumnIndex } from '../utils.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import Unit from './unit/index.tsx';
import { getUnits } from './utils.tsx';

export default function InfiniteAxis({ fiscalMonth }: { fiscalMonth: number }) {
	const [{ getPercentageOffset }] = useHorizontalPercentageOffset();
	const [zoomLevel] = useZoomLevel();
	const [{ today, from, to }] = useRenderingWindow();

	if (!zoomLevel) {
		return null;
	}

	let units: ReactNode[] = [];

	const timeUnits = getUnits({ from, to }, zoomLevel, fiscalMonth);
	const todayColumnIndex = getTodayColumnIndex(timeUnits);

	units = timeUnits.map((unit, index) => {
		const date = new Date(today + unit.from);

		const offset = getPercentageOffset(unit.from);
		const width = getPercentageOffset(unit.to) - offset;
		const isTodayColumn = index === todayColumnIndex;

		return (
			<Unit
				key={date.getTime()}
				left={`${offset}%`}
				width={`${width}%`}
				isHighlighted={isTodayColumn}
			/>
		);
	});

	if (units.length) {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		return <div className={styles.infiniteAxisTimeline}>{units}</div>;
	}
	return null;
}
