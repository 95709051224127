import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	emptyPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.sprint-filter.empty-placeholder',
		defaultMessage: 'All',
		description: 'This is a placeholder for no filter set',
	},
	searchSprintPlaceholder: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.sprint-filter.search-sprint-placeholder',
		defaultMessage: 'Show issues from sprints',
		description: 'This is a placeholder for search',
	},
	categoriesLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.sprint-filter.categories-label',
		defaultMessage: 'All {state} sprints',
		description: 'This is a label for groups by sprint state',
	},
	searchSprintLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.top.filter-bar.sprint-filter.search-sprint-label',
		defaultMessage: 'Choose from sprints',
		description: 'Aria label for sprints search filed that appears on activating Filters button',
	},
});
