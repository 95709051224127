import { SET_PLAN_DEFAULTS, type SetPlanDefaultsAction } from './actions.tsx';
import type { PlanDefaults } from './types.tsx';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: PlanDefaults = {}, action: SetPlanDefaultsAction) => {
	if (action.type === SET_PLAN_DEFAULTS) {
		return action.payload;
	}
	return state;
};
