import type { Dispatch } from 'redux';
import {
	fetchAssigneeList,
	type FetchAssigneeListForQueryPayload,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/assignees/index.tsx';
import { updateIssueOrInlineCreate } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/index.tsx';
import { updateIssueAssigneeMap } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/assignees/actions.tsx';
import type { Person } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/assignees/types.tsx';
import type { ScopeIssue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const dispatchMethods: MapDispatchToPropsSimple<DispatchProps> = (dispatch: Dispatch) => ({
	onChange: (issue: ScopeIssue, assignee?: Person | null) => {
		if (assignee) {
			dispatch(
				updateIssueOrInlineCreate({
					id: issue.id,
					assignee: assignee.jiraUser.accountId,
				}),
			);
		}
		dispatch(
			// @ts-expect-error - TS2345 - Argument of type '{ [x: string]: Person | null | undefined; }' is not assignable to parameter of type 'IssueAssigneeMap'.
			updateIssueAssigneeMap({
				[issue.id]: assignee,
			}),
		);
	},
	fetchAssigneeList: (payload: FetchAssigneeListForQueryPayload) => {
		dispatch(fetchAssigneeList(payload));
	},
});

export default dispatchMethods;
