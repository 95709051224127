import * as R from 'ramda';
import { addSpanToAll } from '@atlaskit/react-ufo/interaction-metrics';
import type * as Api from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import { isReadOnlyPure } from '../../query/app/index.tsx';
import {
	SET_PLAN_INFO,
	type SetPlanInfoAction,
	RESET_BACKLOG,
	type ResetBacklogAction,
	UPDATE_ISSUE_REFERENCES,
	type UpdateIssueReferencesAction,
} from './actions.tsx';
import app from './app/reducer.tsx';
import type { Mode } from './app/types.tsx';
import assignees from './assignees/reducer.tsx';
import { associatedIssuesDomainReducer as associatedIssues } from './associated-issues/reducer.tsx';
import { reset as resetCrossProjectVersions } from './cross-project-versions/actions.tsx';
import crossProjectVersions from './cross-project-versions/reducer.tsx';
import type { CrossProjectVersion } from './cross-project-versions/types.tsx';
import { reset as resetCustomFields } from './custom-fields/actions.tsx';
import customFields from './custom-fields/reducer.tsx';
import { resetAll as resetAllCustomFieldLabels } from './custom-labels/actions.tsx';
import customLabelsByField from './custom-labels/reducer.tsx';
import type { CustomLabelsByField } from './custom-labels/types.tsx';
import { reset as resetDisabledSprints } from './disabled-sprints/actions.tsx';
import disabledSprints from './disabled-sprints/reducer.tsx';
import externalIssues from './external-issues/reducer.tsx';
import externalSprints from './external-sprints/reducer.tsx';
import { removeFlag, pushFlag, type FlagAction } from './flags/actions.tsx';
import flags from './flags/reducer.tsx';
import { ISSUE_LIMIT_WARNING } from './flags/types.tsx';
import hiddenFlags from './hidden-flags/reducer.tsx';
import type { HiddenFlags } from './hidden-flags/types.tsx';
import { reset as resetHierarchy } from './hierarchy/actions.tsx';
import hierarchy from './hierarchy/reducer.tsx';
import historyIssues from './history-issues/reducer.tsx';
import goals from './issue-goals/reducer.tsx';
import { reset as resetIssueLabels } from './issue-labels/actions.tsx';
import issueLabels from './issue-labels/reducer.tsx';
import { reset as resetIssueLinks } from './issue-links/actions.tsx';
import issueLinks from './issue-links/reducer.tsx';
import type { IssueLinksState } from './issue-links/types.tsx';
import issueModal from './issue-modal/reducer.tsx';
import { reset as resetIssuePriorities } from './issue-priorities/actions.tsx';
import issuePriorities from './issue-priorities/reducer.tsx';
import { reset as resetIssueStatusCategories } from './issue-status-categories/actions.tsx';
import issueStatusCategories from './issue-status-categories/reducer.tsx';
import { reset as resetIssueStatuses } from './issue-statuses/actions.tsx';
import issueStatuses from './issue-statuses/reducer.tsx';
import { reset as resetIssueTypes } from './issue-types/actions.tsx';
import issueTypes from './issue-types/reducer.tsx';
import { reset as resetIssues, bulkUpdate as bulkIssueUpdate } from './issues/actions.tsx';
import issues from './issues/reducer.tsx';
import type { Issue } from './issues/types.tsx';
import * as lexorankActions from './lexorank/actions.tsx';
import lexorank from './lexorank/reducer.tsx';
import { reset as resetOriginalCrossProjectVersions } from './original-cross-project-versions/actions.tsx';
import originalCrossProjectVersions from './original-cross-project-versions/reducer.tsx';
import { reset as resetOriginalIssues } from './original-issues/actions.tsx';
import originalIssues from './original-issues/reducer.tsx';
import { reset as resetOriginalPlannedCapacities } from './original-planned-capacities/actions.tsx';
import originalPlannedCapacities from './original-planned-capacities/reducer.tsx';
import { reset as resetOriginalResources } from './original-resources/actions.tsx';
import originalResources from './original-resources/reducer.tsx';
import { reset as resetOriginalTeams } from './original-teams/actions.tsx';
import originalTeams from './original-teams/reducer.tsx';
import { reset as resetOriginalVersions } from './original-versions/actions.tsx';
import originalVersions from './original-versions/reducer.tsx';
import permissions from './permissions/reducer.tsx';
import { reset as resetPersons } from './persons/actions.tsx';
import persons from './persons/reducer.tsx';
import planDefaults from './plan-defaults/reducer.tsx';
import { reset as resetPlan } from './plan/actions.tsx';
import plan from './plan/reducer.tsx';
import type { DateConfiguration } from './plan/types.tsx';
import { reset as resetPlannedCapacities } from './planned-capacities/actions.tsx';
import plannedCapacities from './planned-capacities/reducer.tsx';
import { reset as resetProjects } from './projects/actions.tsx';
import projects from './projects/reducer.tsx';
import { reset as resetRemovedCrossProjectVersions } from './removed-cross-project-versions/actions.tsx';
import removedCrossProjectVersions from './removed-cross-project-versions/reducer.tsx';
import { reset as resetRemovedVersions } from './removed-versions/actions.tsx';
import removedVersions from './removed-versions/reducer.tsx';
import reporters from './reporters/reducer.tsx';
import { reset as resetScenarioRemovedIssues } from './scenario-removed-issues/actions.tsx';
import scenarioRemovedIssues from './scenario-removed-issues/reducer.tsx';
import selectOptions from './select-options/reducer.tsx';
import { reset as resetSequence } from './sequence/actions.tsx';
import sequence from './sequence/reducer.tsx';
import { reset as resetSolution } from './solution/actions.tsx';
import solution from './solution/reducer.tsx';
import { reset as resetSprints } from './sprints/actions.tsx';
import sprints from './sprints/reducer.tsx';
import type { Sprint } from './sprints/types.tsx';
import system from './system/reducer.tsx';
import type { LoadingLimitsInfo } from './system/types.tsx';
import teamsAdditional from './teams-additional/reducer.tsx';
import { reset as resetTeams } from './teams/actions.tsx';
import teams from './teams/reducer.tsx';
import type { Domain } from './types.tsx';
import updateJira from './update-jira/reducer.tsx';
import userIsOnboarded from './user-is-onboarded/reducer.tsx';
import userPickerOptions from './user-picker-options/reducer.tsx';
import {
	prepareIssues,
	prepareIssueLabels,
	prepareCustomLabels,
	prepareIssueLinks,
	prepareOriginalIssues,
	preparePlan,
	prepareIssueGoals,
	prepareProjects,
	prepareVersions,
	prepareOriginalVersions,
	prepareCrossProjectVersions,
	prepareOriginalCrossProjectVersions,
	prepareReleaseStatuses,
	prepareSolution,
	prepareSprints,
	prepareTeams,
	prepareOriginalTeams,
	prepareOriginalResources,
	prepareWRMPlannedCapacities,
	prepareWRMOriginalPlannedCapacities,
	isFlagHidden,
} from './util.tsx';
import { reset as resetReleaseStatuses } from './version-statuses/actions.tsx';
import releaseStatuses from './version-statuses/reducer.tsx';
import { reset as resetVersions } from './versions/actions.tsx';
import versions from './versions/reducer.tsx';
import type { Version } from './versions/types.tsx';
import * as filterActions from './view-settings/filters/actions.tsx';
import {
	type FiltersState,
	type DependenciesFilter,
	DEPENDENCIES_FILTER_ID,
} from './view-settings/filters/types.tsx';
import viewSettings from './view-settings/reducer.tsx';
import views from './views/reducer.tsx';
import warnings from './warnings/reducer.tsx';

type Action = SetPlanInfoAction | ResetBacklogAction | UpdateIssueReferencesAction;

type ExtractIssueFn = (backlog: Api.Backlog) => Api.Issue[];

type StateIssueData = {
	issues: Issue[];
	scenarioRemovedIssues: Issue[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	originalIssues: Record<string, any>;
	issueLabels: string[];
	issueLinks: IssueLinksState;
	customLabelsByField: CustomLabelsByField;
	goalARIs: string[];
};

// Get issue limit flag actions to be applied
const getIssueLimitFlagActions = (
	appMode: Mode,
	numIssues: number,
	limits: LoadingLimitsInfo,
	more: boolean,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	hiddenFlags: HiddenFlags,
): FlagAction[] => {
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const flags = [];
	if (!isReadOnlyPure(appMode)) {
		if (
			(numIssues > limits.absoluteIssueLimit || more) &&
			!isFlagHidden(ISSUE_LIMIT_WARNING, hiddenFlags)
		) {
			flags.push(pushFlag({ key: ISSUE_LIMIT_WARNING }));
		} else if (numIssues <= limits.absoluteIssueLimit) {
			flags.push(removeFlag({ key: ISSUE_LIMIT_WARNING }));
		}
	}
	return flags;
};

// Get view settings actions depending on issue list being set
const getViewSettingsActions = (
	// eslint-disable-next-line @typescript-eslint/no-shadow
	issues: Issue[],
	filters: FiltersState,
): filterActions.ChangeAction[] => {
	const dependenciesFilterValue = filters[DEPENDENCIES_FILTER_ID]?.value;

	// Remove filter if we are filtering for a specific issue that is not in the list
	const removeFilter =
		dependenciesFilterValue &&
		dependenciesFilterValue.type === 'specificIssue' &&
		!issues.some(({ id }) => id === dependenciesFilterValue.issueId);

	return removeFilter
		? [filterActions.change({ id: DEPENDENCIES_FILTER_ID, value: { type: 'off' } })]
		: [];
};

// Transform backlog issues into data that will be put into state
const getIssueData = (
	state: Domain,
	// eslint-disable-next-line @typescript-eslint/no-shadow
	issues: Api.Issue[],
	// eslint-disable-next-line @typescript-eslint/no-shadow
	scenarioRemovedIssues?: Api.Issue[],
): StateIssueData => {
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const { originalIssues, hierarchy, issueTypes, projects } = state;
	const { baselineStartField, baselineEndField } = state.plan;
	const dateConfiguration: DateConfiguration = {
		baselineStartField,
		baselineEndField,
	};

	const releasesDisabledProject = projects.reduce((acc: number[], { isReleasesEnabled, id }) => {
		if (!isReleasesEnabled) {
			acc.push(id);
		}
		return acc;
	}, []);
	const stateIssues = prepareIssues(issues, {
		levels: hierarchy.levels,
		issueTypes,
		dateConfiguration,
		releasesDisabledProject,
	});
	const stateScenarioRemovedIssues = prepareIssues(scenarioRemovedIssues, {
		levels: hierarchy.levels,
		issueTypes,
		dateConfiguration,
		releasesDisabledProject,
	});
	const stateOriginalIssues = prepareOriginalIssues(
		issues,
		dateConfiguration,
		scenarioRemovedIssues,
	);

	const stateIssueGoals = prepareIssueGoals(stateIssues, stateOriginalIssues);
	const stateIssueLabels = prepareIssueLabels(stateIssues, originalIssues);
	const stateIssueLinks = prepareIssueLinks(issues, state.system.dependencySettingsInfo);
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const customLabelsByField = prepareCustomLabels(stateIssues, originalIssues, state.customFields);

	return {
		issues: stateIssues,
		scenarioRemovedIssues: stateScenarioRemovedIssues,
		originalIssues: stateOriginalIssues,
		issueLabels: stateIssueLabels,
		issueLinks: stateIssueLinks,
		customLabelsByField,
		goalARIs: stateIssueGoals,
	};
};

const resetBacklog = (state: Domain, payload: Api.Backlog, extractIssueFn: ExtractIssueFn) => {
	// eslint-disable-next-line @typescript-eslint/no-shadow
	const { app, hiddenFlags, issueTypes, projects } = state;
	const calculationResult = payload.calculationResult;

	const issuesToProcess = extractIssueFn(payload);
	const stateIssueData = getIssueData(state, issuesToProcess, payload.scenarioRemovedIssues);

	const flagActions = getIssueLimitFlagActions(
		app.mode,
		stateIssueData.issues.length,
		state.system.loadingLimitsInfo,
		payload.more,
		hiddenFlags,
	);
	const viewSettingsActions = getViewSettingsActions(
		stateIssueData.issues,
		state.viewSettings.filtersV1,
	);

	return {
		viewSettings: viewSettingsActions,
		flags: flagActions,
		issueTypes: [resetIssueTypes(issueTypes)],
		projects: [resetProjects(projects)],
		issues: [resetIssues(stateIssueData.issues)],
		issueLabels: [resetIssueLabels(stateIssueData.issueLabels)],
		customLabelsByField: [resetAllCustomFieldLabels(stateIssueData.customLabelsByField)],
		issueLinks: [resetIssueLinks(stateIssueData.issueLinks)],
		lexorank: [lexorankActions.resetAdjustments([])],
		scenarioRemovedIssues: [resetScenarioRemovedIssues(stateIssueData.scenarioRemovedIssues)],
		solution:
			calculationResult && !calculationResult.error
				? [resetSolution(prepareSolution(calculationResult))]
				: [],
		originalIssues: [resetOriginalIssues(stateIssueData.originalIssues)],
	};
};

const composite = (state: Domain, action: Action) => {
	switch (action.type) {
		case SET_PLAN_INFO: {
			const {
				planInfo,
				planInfo: {
					// eslint-disable-next-line @typescript-eslint/no-shadow
					crossProjectVersions,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					customFields,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					issueStatuses,
					issuePriorityInformation,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					issueTypes,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					projects,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					sequence,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					sprints,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					teams,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					plannedCapacities,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					persons,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					releaseStatuses,
				},
				hierarchyInfo,
				// eslint-disable-next-line @typescript-eslint/no-shadow
				issueStatusCategoryInfo: { issueStatusCategories },
			} = action.payload;

			// Measure the time to set the plan info.
			const SET_PLAN_INFO_START_MARK = performance.now();

			// eslint-disable-next-line @typescript-eslint/no-shadow
			const versions: Version[] = prepareVersions(projects, crossProjectVersions, {
				isPreparingRemovedVersions: false,
			});
			// eslint-disable-next-line @typescript-eslint/no-shadow
			const removedVersions: Version[] = prepareVersions(projects, crossProjectVersions, {
				isPreparingRemovedVersions: true,
			});
			const allReleaseStatuses: Api.ReleaseStatus[] = prepareReleaseStatuses(releaseStatuses);

			const existingCrossProjectVersions: CrossProjectVersion[] = prepareCrossProjectVersions(
				crossProjectVersions,
				versions,
				{
					isPreparingDeletedCrossProjectVersions: false,
				},
			);
			const deletedCrossProjectVersions: CrossProjectVersion[] = prepareCrossProjectVersions(
				crossProjectVersions,
				versions,
				{
					isPreparingDeletedCrossProjectVersions: true,
				},
			);

			const preparedPlannedCapacities = prepareWRMPlannedCapacities(plannedCapacities);

			const preparedOriginalPlannedCapacities =
				prepareWRMOriginalPlannedCapacities(plannedCapacities);

			// eslint-disable-next-line @typescript-eslint/no-shadow
			const [preparedSprints, disabledSprints] = R.partition((x: Sprint) => x.isDisabled !== true)(
				prepareSprints(sprints, planInfo),
			);

			/**
			 * Note: the backend provides the Jira hierarchy level for each hierarchy used in Plans:
			 * [{ title: 'Sub-Task', issueTypes: [10004, 10002], level: -1 }, { title: 'Story', issueTypes: [10003], level: 0 }, ...]
			 */
			const preparedHierarchyInfo = { levels: hierarchyInfo.levels };

			const result = {
				crossProjectVersions: [resetCrossProjectVersions(existingCrossProjectVersions)],
				customFields: [resetCustomFields(customFields)],
				hierarchy: [resetHierarchy(preparedHierarchyInfo)],
				issueTypes: [resetIssueTypes(issueTypes)],
				plan: [resetPlan(preparePlan(planInfo))],
				projects: [resetProjects(prepareProjects(projects, issuePriorityInformation))],
				sequence: sequence ? [resetSequence(sequence)] : [],
				versions: [resetVersions(versions)],
				sprints: [resetSprints(preparedSprints)],
				disabledSprints: [resetDisabledSprints(disabledSprints)],
				plannedCapacities: [resetPlannedCapacities(preparedPlannedCapacities)],
				teams: [resetTeams(prepareTeams(teams))],
				issueStatusCategories: issueStatusCategories
					? [resetIssueStatusCategories(issueStatusCategories)]
					: [],
				issuePriorities: [resetIssuePriorities(issuePriorityInformation.issuePriorities)],
				issueStatuses: [resetIssueStatuses(issueStatuses)],
				releaseStatuses: [resetReleaseStatuses(allReleaseStatuses)],
				removedCrossProjectVersions: [
					resetRemovedCrossProjectVersions(deletedCrossProjectVersions),
				],
				removedVersions: [resetRemovedVersions(removedVersions)],
				originalVersions: [
					resetOriginalVersions(prepareOriginalVersions(projects, crossProjectVersions)),
				],
				originalCrossProjectVersions: [
					resetOriginalCrossProjectVersions(
						prepareOriginalCrossProjectVersions(crossProjectVersions),
					),
				],
				originalPlannedCapacities: [
					resetOriginalPlannedCapacities(preparedOriginalPlannedCapacities),
				],
				originalTeams: [resetOriginalTeams(prepareOriginalTeams(teams))],
				originalResources: [resetOriginalResources(prepareOriginalResources(teams))],
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
				persons: [resetPersons(persons as any)],
			};

			addSpanToAll('reducer', SET_PLAN_INFO, [{ name: 'redux' }], SET_PLAN_INFO_START_MARK);

			return result;
		}
		case RESET_BACKLOG: {
			return resetBacklog(state, action.payload, (backlog) => backlog.issues);
		}
		case UPDATE_ISSUE_REFERENCES: {
			const { oldId, newId } = action.payload;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const actions: Record<string, any> = {};

			// Updates dependency filter
			const dependenciesFilterValue = R.path<DependenciesFilter['value']>(
				[DEPENDENCIES_FILTER_ID, 'value'],
				state.viewSettings.filtersV1,
			);
			if (
				dependenciesFilterValue &&
				dependenciesFilterValue.type === 'specificIssue' &&
				dependenciesFilterValue.issueId === oldId
			) {
				actions.viewSettings = [
					filterActions.change({
						id: DEPENDENCIES_FILTER_ID,
						value: { ...dependenciesFilterValue, issueId: newId },
					}),
				];
			}

			// Update the issue id
			let bulkIssuesUpdates: { [id: string]: { id?: string; parent?: string } } = {
				[oldId]: { id: newId },
			};

			bulkIssuesUpdates = state.issues
				.filter(({ parent }) => parent === oldId)
				.reduce((payload, { id }) => {
					// eslint-disable-next-line no-param-reassign
					payload[id] = { parent: newId };
					return payload;
				}, bulkIssuesUpdates);

			actions.issues = [bulkIssueUpdate(bulkIssuesUpdates)];

			return actions;
		}
		default: {
			const _exhaustiveCheck: never = action;
			return {};
		}
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>(
	{
		app,
		assignees,
		crossProjectVersions,
		customFields,
		externalIssues,
		flags,
		goals,
		hiddenFlags,
		hierarchy,
		issues,
		issueLabels,
		issueLinks,
		issueModal,
		issuePriorities,
		issueStatusCategories,
		issueStatuses,
		issueTypes,
		lexorank,
		originalCrossProjectVersions,
		originalIssues,
		originalPlannedCapacities,
		originalTeams,
		originalResources,
		originalVersions,
		permissions,
		persons,
		plan,
		planDefaults,
		projects,
		releaseStatuses,
		reporters,
		removedCrossProjectVersions,
		removedVersions,
		scenarioRemovedIssues,
		sequence,
		solution,
		sprints,
		plannedCapacities,
		system,
		teams,
		teamsAdditional,
		updateJira,
		versions,
		warnings,
		historyIssues,
		selectOptions,
		customLabelsByField,
		userPickerOptions,
		userIsOnboarded,
		externalSprints,
		disabledSprints,
		viewSettings,
		views,
		associatedIssues,
	},
	composite,
);
