import { combineReducers } from '@atlassian/jira-portfolio-3-portfolio/src/common/redux/index.tsx';
import ConfluenceMacro from './confluence-macro/reducer.tsx';
import Errors from './errors/reducer.tsx';
import Main from './main/reducer.tsx';
import Onboarding from './onboarding/reducer.tsx';
import SectionWidths from './section-widths/reducer.tsx';
import Settings from './settings/reducer.tsx';
import Top from './top/reducer.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default combineReducers<any, any>({
	Top,
	Main,
	Errors,
	Settings,
	Onboarding,
	SectionWidths,
	ConfluenceMacro,
});
