import * as R from 'ramda';
import { monitor } from '@atlassian/jira-portfolio-3-common/src/analytics/performance.tsx';
import { getMode } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import { getCustomFieldsById } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import { getHierarchyLevelsByLevel } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/hierarchy/index.tsx';
import { getIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import { getIsIssuesLoading } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issues/index.tsx';
import { getProjects } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import { getIssues } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/raw-issues/index.tsx';
import { getScope } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/scope/index.tsx';
import {
	TABLE_GROUP,
	getTableItems,
	getScrollToIndex,
	TABLE_ISSUE,
	TABLE_GROUP_HEADER,
	type TableItem,
	getDisableDnD,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/table/index.tsx';
import {
	getWarningViewSettings,
	getVisualisationViewSettings,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/view-settings/index.tsx';
import { getVisualisationSorting } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import { getWarnings } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/warnings/index.tsx';
import { EDIT } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import type { IssueType } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issue-types/types.tsx';
import type { Project } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/projects/types.tsx';
import type {
	ScopeIssue,
	GroupCombination,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/scope/types.tsx';
import { DEFAULT_SCRATCH_ISSUE_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/util.tsx';
import type { HierarchyLevel } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isMultiSelectCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import type { Issue } from './issue/drop-menu/create-issue/types.tsx';
import type { Issues, Item, TableGroupItem, EnhancedGroupHeaderData } from './types.tsx';

export const getIsGroupingByMultiSelectCustomField = createSelector(
	[getVisualisationViewSettings, getCustomFieldsById],
	(viewSetting, customFieldsById) => {
		const grouping = viewSetting.grouping;
		const customFieldId = getCustomFieldIdFromCustomFieldGrouping(grouping);
		if (customFieldId.length > 0) {
			const fieldType = customFieldsById[Number(customFieldId)]?.type?.key;
			return isMultiSelectCustomField(fieldType);
		}
		return false;
	},
);

export const getEnhancedIssuesPureFunc = (
	tableItems: TableItem[],
	issueTypes: IssueType[],
	projects: Project[],
	hierarchyLevelsByLevel: {
		[level: number]: HierarchyLevel;
	},
): Issues => {
	const id = R.prop('id');
	const issueTypesById = indexBy(id, issueTypes);
	const projectsById = indexBy(id, projects);
	// NOTE `enhance` is written in ill-typed and mutable style for the sake of performance
	// this code turned out to be a hot spot and we want to reduce GC and computational load
	const enhance = (issue: ScopeIssue): Issue => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const result: Record<string, any> = {};
		for (const key of Object.keys(issue)) {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			result[key] = issue[key as keyof ScopeIssue];
		}
		result.type = issueTypesById[issue.type] || DEFAULT_SCRATCH_ISSUE_TYPE; // May not exist if issue sources have changed.
		result.project = projectsById[issue.project];
		// unsafe cast, but scope of `result` is small which helps to ensure it's proper structure
		// by inspecting the code above
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
		return result as any;
	};
	return tableItems.map((item) => {
		switch (item.tag) {
			case TABLE_ISSUE: {
				const { tag, value: issue, group, parentGroup, groupCombination } = item;
				const enhancedIssue: {
					tag: typeof TABLE_ISSUE;
					value: Issue;
					group?: string;
					parentGroup?: string | null | undefined;
					groupCombination?: GroupCombination;
				} = {
					tag,
					value: enhance(issue),
					group,
					parentGroup,
					groupCombination,
				};
				return enhancedIssue;
			}
			case TABLE_GROUP_HEADER: {
				const {
					tag,
					// eslint-disable-next-line @typescript-eslint/no-shadow
					value: { id, isExpanded, level, rootIssuesCount },
					group,
					parentGroup,
				} = item;
				const enhancedHeader: {
					tag: typeof TABLE_GROUP_HEADER;
					value: EnhancedGroupHeaderData;
					group?: string;
					parentGroup?: string | null | undefined;
				} = {
					tag,
					value: {
						id,
						isExpanded,
						level: hierarchyLevelsByLevel[level],
						rootIssuesCount,
					},
					group,
					parentGroup,
				};
				return enhancedHeader;
			}
			default:
				return item;
		}
	});
};

export const getEnhancedIssuesPure = monitor.timeFunction<
	Parameters<typeof getEnhancedIssuesPureFunc>,
	Issues
>(getEnhancedIssuesPureFunc, 'roadmap_scope_issues_query_getEnhancedIssuesPure');

export const getEnhancedIssues = createSelector(
	[getTableItems, getIssueTypes, getProjects, getHierarchyLevelsByLevel],
	getEnhancedIssuesPure,
);

export const getTagTableItemsPure = (issues: Issues): TableGroupItem[] =>
	issues.filter((item: Item): item is TableGroupItem => item.tag === TABLE_GROUP);

export const getTagTableItems = createSelector([getEnhancedIssues], getTagTableItemsPure);

const getGroupBySetting = createSelector(
	[getVisualisationViewSettings],
	(viewSetting) => viewSetting.grouping,
);

export default createSelector(
	[
		getEnhancedIssues,
		getScrollToIndex,
		getDisableDnD,
		getMode,
		getIssues,
		getScope,
		getIsIssuesLoading,
		getWarnings,
		getWarningViewSettings,
		getVisualisationSorting,
		getIsGroupingByMultiSelectCustomField,
		getGroupBySetting,
	],
	(
		issues,
		scrollToIndex,
		disableDnD,
		mode,
		issuesInPlan,
		scope,
		isLoading,
		warnings,
		warningSetting,
		sorting,
		isGroupingByMultiSelectCustomField,
		// eslint-disable-next-line @typescript-eslint/no-shadow
		getGroupBySetting,
	) => ({
		isLoading,
		scrollToIndex,
		disableDnD,
		isReadOnly: mode !== EDIT,
		issues,
		noIssuesInPlan: issuesInPlan.length === 0,
		rankDigits: scope.startLevelIssues.rootIssuesCount.toString().length,
		startLevel: scope.startLevelIssues.level,
		warnings,
		showWarning: warningSetting.showWarning,
		sorting,
		isGroupingByMultiSelectCustomField,
		grouping: getGroupBySetting,
	}),
);
