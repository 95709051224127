import React, { useMemo } from 'react';
import LegendItem from '@atlassian/jira-aais-timeline-legend/src/ui/legend-item/index.tsx';
import LegendSection from '@atlassian/jira-aais-timeline-legend/src/ui/legend-section/index.tsx';
import ColorByIcon from '@atlassian/jira-aais-timeline-legend/src/ui/symbols/color-by-icon/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { isColorByCustomFieldOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/visualisations/index.tsx';
import commonMessagesForColorBy from '@atlassian/jira-portfolio-3-portfolio/src/common/view/color-by-legend/messages.tsx';
import {
	colourByOptions,
	DEFAULT_COLOR,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/colours/index.tsx';
import commonMessages from '@atlassian/jira-portfolio-3-portfolio/src/common/view/messages.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const ColourByLegend = ({ colorByList, colorByValue, customFields }: Props) => {
	const { formatMessage } = useIntl();

	const colorByValueTitle = useMemo(() => {
		if (colorByValue === colourByOptions.NONE || !colorByValue) return;
		if (Object.keys(colorByList).length === 0) return;

		const optionMessages = {
			...commonMessagesForColorBy,
			allOtherIssues: commonMessages.allOtherIssues,
			issueType: commonMessages.issueType,
			label: commonMessages.label,
		};

		if (!isColorByCustomFieldOption(colorByValue))
			return formatMessage(optionMessages[colorByValue]);

		const customField = customFields.find((field) => field.id === Number(colorByValue));
		if (customField) return customField.title;
	}, [colorByList, colorByValue, customFields, formatMessage]);

	if (!colorByValueTitle) return null;

	const showAllOtherIssuesColorLabel =
		colorByValue === colourByOptions.COMPONENT ||
		colorByValue === colourByOptions.ISSUE_TYPE ||
		colorByValue === colourByOptions.LABEL ||
		colorByValue === colourByOptions.PRIORITY ||
		isColorByCustomFieldOption(colorByValue);

	return (
		<LegendSection title={formatMessage(messages.colorBy, { colorByValueTitle })}>
			{showAllOtherIssuesColorLabel && (
				<LegendItem
					key={0}
					symbol={<ColorByIcon backgroundColor={DEFAULT_COLOR} />}
					label={formatMessage(commonMessages.allOtherIssues)}
				/>
			)}
			{Object.entries(colorByList).map(([color, labels], index) => (
				<LegendItem
					key={index}
					symbol={<ColorByIcon backgroundColor={color} />}
					label={labels.join(', ')}
				/>
			))}
		</LegendSection>
	);
};

export default ColourByLegend;
