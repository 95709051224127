export type BridgePayload = Record<string | number, unknown>;

export const BridgeEventValue = {
	ADD_ISSUE: 0,
	UPDATE_ISSUE: 1,
	UPDATE_ISSUES: 2,
	ADD_ISSUE_LINK: 3,
	REMOVE_ISSUE_LINK: 4,
	RANK_ISSUE: 5,
	UPDATE_PLANNED_CAPACITY: 6,
	REMOVE_PLANNED_CAPACITY: 7,
	REVIEW_CHANGES: 8,
	UPDATE_ISSUE_DATES_OPTIMISTIC: 9,
} as const;

export type BridgeEventValueType = (typeof BridgeEventValue)[keyof typeof BridgeEventValue];

export type BridgeEvent = {
	type: BridgeEventValueType;
	payload: BridgePayload;
	resolve: (result?: unknown) => void;
	reject: (error?: unknown) => void;
};
