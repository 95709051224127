.issueTitle {
	display: flex;
}

.issueIcon {
	width: var(--jpo-icon-size-small);
	min-width: var(--jpo-icon-size-small);
	height: var(--jpo-icon-size-small);
	margin-top: 2px;
	margin-right: 5px;
}

.issueSummary {
	margin-left: 5px;
	min-width: 250px;
	word-break: break-all;
}
