.form-group {
	display: flex;
}

.current-value {
	padding-top: calc(var(--jpo-common-base-spacing) + 2px);
}

.current-value-title {
	max-width: calc(var(--jpo-common-base-spacing) * 24);
	overflow: hidden;
	text-overflow: ellipsis;
}

.icon {
	vertical-align: -5px;
	padding-left: calc(var(--jpo-common-base-spacing) * 2);
	margin-top: 36px;
	width: 80px;
	text-align: center;
}

.checkbox {
	margin-top: var(--jpo-common-base-spacing);
}

.new-value {
	min-width: 280px;
	margin-left: calc(var(--jpo-common-base-spacing) * 2);
}

.value-label {
	color: var(--ds-text-subtlest, var(--adg3-color-N200));
	font-size: 12px;
	font-weight: 600;
}

.value-placeholder {
	padding-top: calc(var(--jpo-common-base-spacing) / 2);
	line-height: 40px;
	overflow: hidden;
	text-overflow: ellipsis;
	box-sizing: border-box;
	white-space: nowrap;
	display: flex;
	align-items: center;
	min-width: 136px;
	max-width: 216px;
}
