import {
	defaultMemoize,
	createSelectorCreator,
	createStructuredSelector as originalCreateStructuredSelector,
} from 'reselect';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { OutputSelector } from 'reselect';

const EQUALS_DEPTH_LIMIT = 1;

// semi-deep equals, built with heart and balance
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const equals = (a: any, b: any, maxDepth: number = EQUALS_DEPTH_LIMIT, depth = 0) => {
	if (a === b) {
		return true;
	}
	if (depth > maxDepth) {
		return false;
	}
	if (Array.isArray(a) && Array.isArray(b)) {
		if (a.length !== b.length) {
			return false;
		}
		for (let i = 0; i < a.length; i++) {
			if (!equals(a[i], b[i], maxDepth, depth + 1)) {
				return false;
			}
		}
		return true;
	}
	if (a && b && typeof a === 'object' && typeof b === 'object') {
		// NOTE Object.keys returns [] for any set thus we need to treat Set instances specially
		if (a instanceof Set && b instanceof Set) {
			if (a.size !== b.size) {
				return false;
			}
			const c = new Set(b);
			for (const x of a) {
				if (!c.has(x)) {
					return false;
				}
				c.delete(x);
			}
			if (c.size > 0) {
				return false;
			}
			return true;
		}
		const k1 = Object.keys(a);
		const k2 = Object.keys(b);
		if (k1.length !== k2.length) {
			return false;
		}
		// Micro-optimization relying on the assumption that if keys are out-of-order
		// then chances are that object was rebuilt and changed.
		// (It's okay for selector's `equals` to give false negatives)
		// k1.sort();
		// k2.sort();
		for (let i = 0; i < k1.length; i++) {
			if (k1[i] !== k2[i]) {
				return false;
			}
			if (!equals(a[k1[i]], b[k2[i]], maxDepth, depth + 1)) {
				return false;
			}
		}
		return true;
	}
	return false;
};

export const createSelector = createSelectorCreator(defaultMemoize, equals);

export const createStructuredSelector: typeof originalCreateStructuredSelector = (
	selectors: Parameters<typeof originalCreateStructuredSelector>[0],
	selectorCreator = createSelector,
) => originalCreateStructuredSelector(selectors, selectorCreator);
