import * as R from 'ramda';
import { indexBy } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	createSelector,
	createStructuredSelector,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type {
	IterationId,
	TeamKey,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import { OPTIMIZED } from '../../state/domain/app/types.tsx';
import type {
	PlannedCapacities,
	PlannedCapacity,
} from '../../state/domain/planned-capacities/types.tsx';
import type { Sprint } from '../../state/domain/sprints/types.tsx';
import type { EntityMetadata } from '../../state/domain/update-jira/changes/types.tsx';
import { SPRINT_FILTER_ID } from '../../state/domain/view-settings/filters/types.tsx';
import type { State } from '../../state/types.tsx';
import { getMode } from '../app/index.tsx';
import { getFilters } from '../filters/index.tsx';
import { getIssueStatusById } from '../issue-statuses/index.tsx';
import { getIssueTypesById } from '../issue-types/index.tsx';
import { getIssueLexoRankAdjustments as getLexoRankAdjustments } from '../lexorank/index.tsx';
import { getDefaultIterationLength, getDefaultTeamVelocity } from '../plan-defaults/index.tsx';
import { getIssueSources, getPlanningUnit, getIssueSourcesById } from '../plan/index.tsx';
import { getIssues } from '../raw-issues/index.tsx';
import { getIssuesByTeam } from '../scope/index.tsx';
import { getSolution } from '../solution/index.tsx';
import {
	getAllExistingTeams,
	getTeams,
	getExistingTeams,
	getTeamsById,
	getWeeklyCapacityByTeam,
} from '../teams/index.tsx';
import { getZoomLevel, getScrollableTimelineRange } from '../timeline/index.tsx';
import { getSprintsAreShown } from '../visualisations/index.tsx';
import {
	type SprintStreamsForTeamsContext,
	getSprintsForTeamPure,
	getIssuesByKanbanIterationPure,
	getIssuesBySprintOptimizedPure,
	getIssuesBySprintPure,
	getSprintsAndTeamsByIssueSourcesPure,
	getSprintsForIssueSourcesPure,
	getSprintStreamsForTeamsPure,
	getSprintsStreamsGetterPure,
	getSprintStreamsUnitsForTeamsPure,
	getSprintStreamsUnitsGetterPure,
	getSprintByIdMapPure,
	getSprintStatesCategoriesPure,
	getSprintsWithFutureDatesPure,
	getSprintIdsWithDatesIsInconsistentPure,
	getSprintsCapacityOnTimelinePure,
	getPlannedCapacityChangesPure,
	getSprintChangesPure,
} from './utils.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Sprint } from '../../state/domain/sprints/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { OriginalPlannedCapacity } from '../../state/domain/original-planned-capacities/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { PlannedCapacity } from '../../../common/api/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SprintId } from '../../../common/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	getSprintById,
	getTargetDatesFromSprints,
	convertCapacityByPlanningUnit,
	isSameYear,
	formatSprintUTCTime,
	formatDateRange,
	isSameMonth,
	getLozengeContent,
	getLozengeAppearance,
	getSprintStatesCategoriesPure,
} from './utils';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	SprintStreamsForTeams,
	SprintStreamsUnitsGetter,
	SprintStreamsUnitsForTeams,
	SprintUnit,
	TimelineSprintExtra,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractFromProps = (key: keyof CapSelProps) => (_: any, props: CapSelProps) =>
	props && props[key];

export const getSprints = (state: State) => state.domain.sprints;
export const getExternalSprints = (state: State) => state.domain.externalSprints;
export const getDisabledSprints = (state: State) => state.domain.disabledSprints;
export const getPlannedCapacities = (state: State): PlannedCapacities =>
	state.domain.plannedCapacities;
export const getOriginalPlannedCapacities = (state: State) =>
	state.domain.originalPlannedCapacities;
export const getPlannedCapacityMetaData = (state: State): EntityMetadata | null | undefined =>
	R.path(['domain', 'updateJira', 'changes', 'data', 'metaData', 'plannedcapacity'], state);

// Capacity selector props
// Had to condense the name to CapSelProps so the dreaded Prettier wouldn't split the
// selectors' type parameters onto multiple lines
type CapSelProps = {
	teamId: TeamKey;
	iterationId?: IterationId | null;
};

export const getCapacityForTeamAndIteration = createSelector(
	[getPlannedCapacities, extractFromProps('teamId'), extractFromProps('iterationId')],
	(plannedCapacities, teamId, iterationId): PlannedCapacity | undefined =>
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		R.path([teamId!, iterationId!], plannedCapacities),
);

export const getOriginalCapacityForTeamAndIteration = createSelector(
	[getOriginalPlannedCapacities, extractFromProps('teamId'), extractFromProps('iterationId')],
	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	(plannedCapacities, teamId, iterationId) => R.path([teamId!, iterationId!], plannedCapacities),
);

export const getAllSprints = createSelector(
	[getSprints, getExternalSprints],
	(sprints, externalSprints) => [...sprints, ...externalSprints],
);

export const getAllSprintsById = createSelector([getAllSprints], (sprints) =>
	indexBy(R.prop('id'), sprints),
);

export const getAllSprintsIncludingDisabled = createSelector(
	[getSprints, getExternalSprints, getDisabledSprints],
	(sprints, externalSprints, disabledSprints) => [
		...sprints,
		...externalSprints,
		...disabledSprints,
	],
);

export const getSprintsForTeam = createSelector(
	[getTeams, getIssueSources, getSprints],
	getSprintsForTeamPure,
);

export const getSprintByIdMap = createSelector([getSprints], getSprintByIdMapPure);

export const getAllSprintsByIdMap = createSelector([getAllSprints], getSprintByIdMapPure);
export const getAllSprintsIncludingDisabledByIdMap = createSelector(
	[getAllSprintsIncludingDisabled],
	getSprintByIdMapPure,
);

export const getExternalSprintsById = createSelector([getExternalSprints], (externalSprints) =>
	indexBy(R.prop('id'), externalSprints),
);

export const getDisabledSprintsById = createSelector([getDisabledSprints], (disabledSprints) =>
	indexBy(R.prop('id'), disabledSprints),
);

export const getExternalSprintsIds = createSelector([getExternalSprints], (externalSprints) =>
	R.map(R.prop('id'), externalSprints),
);

export const getInternalSprintIds = createSelector([getSprints], (sprints) =>
	sprints.map((sprint) => sprint.id),
);

export const getAllSprintIds = createSelector([getAllSprints], (sprints) =>
	sprints.map((sprint) => sprint.id),
);

export const getSprintsIdsByState = createSelector([getSprints], (sprints) =>
	R.reduceBy<Sprint, string[]>(
		(acc, sprint) => acc.concat(sprint.id),
		[],
		({ state }) => state,
		sprints,
	),
);
export const getUniqueFilterSprints = createSelector([getFilters], (filters) => {
	const {
		[SPRINT_FILTER_ID]: { value },
	} = filters;
	return new Set(value);
});

// change
export const getSprintsForIssueSources = createSelector(
	[getIssueSourcesById, getSprintByIdMap, getDisabledSprintsById],
	getSprintsForIssueSourcesPure,
);

export const getSprintsAndTeamsByIssueSources = createSelector(
	[getIssueSourcesById, getSprintByIdMap, getAllExistingTeams, getDisabledSprintsById],
	getSprintsAndTeamsByIssueSourcesPure,
);

export const getSprintStatesCategories = createSelector(
	[getSprintsIdsByState, getExternalSprintsIds, getUniqueFilterSprints],
	getSprintStatesCategoriesPure,
);

export const getIssuesByKanbanIteration = createSelector(
	[getIssues, getMode],
	getIssuesByKanbanIterationPure,
);

export const getIssuesBySprint = createSelector(
	[getIssues, getMode, getSolution],
	(issues, mode, solution) =>
		mode === OPTIMIZED
			? getIssuesBySprintOptimizedPure(issues, solution)
			: getIssuesBySprintPure(issues),
);

export const getSprintStreamsForTeamsContext = createStructuredSelector<
	State,
	SprintStreamsForTeamsContext
>({
	defaultIterationLength: getDefaultIterationLength,
	defaultTeamVelocity: getDefaultTeamVelocity,
	issueStatusById: getIssueStatusById,
	issuesByKanbanIteration: getIssuesByKanbanIteration,
	issuesBySprint: getIssuesBySprint,
	mode: getMode,
	planningUnit: getPlanningUnit,
	sprintsForTeam: getSprintsForTeam,
	timelineRange: getScrollableTimelineRange,
	weeklyCapacityByTeam: getWeeklyCapacityByTeam,
	issueSources: getIssueSources,
	issues: getIssues,
	issueTypeById: getIssueTypesById,
	issueLexoRankAdjustments: getLexoRankAdjustments,
	issuesByTeam: getIssuesByTeam,
	plannedCapacities: getPlannedCapacities,
	zoomLevel: getZoomLevel,
	filteredSprints: getUniqueFilterSprints,
});

export const getSprintStreamsForTeams = createSelector(
	[getSprintsAreShown, getExistingTeams, getSprintStreamsForTeamsContext],
	getSprintStreamsForTeamsPure,
);

export const getSprintStreamsUnitsForTeams = createSelector(
	[getSprintStreamsForTeams, getScrollableTimelineRange],
	getSprintStreamsUnitsForTeamsPure,
);

export const getSprintsStreamsGetter = createSelector(
	[getSprintsAreShown, getExistingTeams, getSprintStreamsForTeamsContext],
	getSprintsStreamsGetterPure,
);

export const getSprintStreamsUnitsGetter = createSelector(
	[getSprintsStreamsGetter, getZoomLevel],
	getSprintStreamsUnitsGetterPure,
);

export const getSprintsWithFutureDates = createSelector(
	[getSprintsForTeam, getTeams, getIssueSources, getSprints, getDefaultIterationLength],
	getSprintsWithFutureDatesPure,
);

export const getSprintIdsWithDatesIsInconsistent = createSelector(
	[getSprintStreamsUnitsForTeams],
	getSprintIdsWithDatesIsInconsistentPure,
);

export const getSprintsCapacityOnTimeline = createSelector(
	[getExistingTeams, getSprintStreamsForTeamsContext],
	getSprintsCapacityOnTimelinePure,
);

export const getPlannedCapacityChanges = createSelector(
	[
		getPlannedCapacities,
		getOriginalPlannedCapacities,
		getSprintsWithFutureDates,
		getPlanningUnit,
		getDefaultTeamVelocity,
		getWeeklyCapacityByTeam,
		getTeamsById,
		getPlannedCapacityMetaData,
	],
	getPlannedCapacityChangesPure,
);
export const getSprintChanges = createSelector(
	[getPlannedCapacityChanges, getSprintsWithFutureDates],
	getSprintChangesPure,
);

export const getSprintChangeCount = createSelector([getSprintChanges], (changes) => changes.length);
