.afm-dropdown-menu-body {
	max-height: calc(55vh - 180px);
	overflow-y: auto;
	margin-bottom: var(--jpo-common-base-spacing);
}

.afm-dropdown-menu-body .afm-no-selected-fields {
	padding: calc(var(--jpo-common-base-spacing) / 2) calc(2 * var(--jpo-common-base-spacing));
}

/*
    This ensures that the selected fields remain visible when the user drags and drops them
    See https://github.com/clauderic/react-sortable-hoc/issues/87
*/
.afm-custom-field-lozenge {
	text-align: right;
}

.afm-selected-field-item-old {
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: space-between;
	cursor: move;
	background-color: var(--ds-background-neutral-subtle, var(--adg3-color-N0));
	padding: 0 calc(2 * var(--jpo-common-base-spacing));
	gap: 0 calc(0.5 * var(--jpo-common-base-spacing));
}

.afm-selected-field-item {
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: space-between;
	cursor: move;
	background-color: var(--ds-background-neutral-subtle, var(--adg3-color-N0));
	padding: 0 calc(1.5 * var(--jpo-common-base-spacing));
	gap: 0 calc(0.5 * var(--jpo-common-base-spacing));
}

.afm-selected-field-item-section {
	display: flex;
	align-items: center;
	gap: 0 calc(0.5 * var(--jpo-common-base-spacing));
}

.afm-selected-field-item:hover {
	background: var(--ds-background-neutral-subtle-hovered, var(--adg3-color-N20));
}

.afm-sortable-helper {
	z-index: 1200;
	background: var(--ds-background-selected, var(--adg3-color-B50));
}

.afm-selected-field-item-drag-icon {
	display: flex;
	flex-shrink: 0;
}

.afm-selected-field-item-title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	flex-grow: 2;
}

.afm-selected-field-item-custom-lozenge {
	margin-bottom: 3px;
	margin-left: 3px;
}

.afm-selected-field-item-remove-icon {
	display: flex;
	flex-shrink: 0;
	cursor: pointer;
}

.afm-selected-field-item-remove-icon-button {
	display: flex;
}
