import type { PropsWithChildren } from 'react';

export const Z_INDEX_LEVEL = {
	TOP: 9,
	SCOPE_HEADER: 8,
	SCOPE_SUBHEADER: 7,
	SCOPE_STICKY: 6,
	SCOPE: 5,
	SUBHEADER: 4,
	HEADER: 3,
	STICKY: 2,
	FOCUSED: 1,
	DEFAULT: 0,
} as const;

export const Z_INDEX_LEVEL_OLD = {
	TOP: 5,
	HEADER: 4,
	SUBHEADER: 3,
	STICKY: 2,
	FOCUSED: 1,
	DEFAULT: 0,
};

export const Z_INDEX_LAYER = {
	AXIS_STICKY_TIME_UNIT: 'AXIS_STICKY_TIME_UNIT',
	DEPENDENCY_LINES: 'DEPENDENCY_LINES',
	ISSUE_BARS: 'ISSUE_BARS',
	ISSUE_BARS_ARROWS: 'ISSUE_BARS_ARROWS',
	DEPENDENCY_LINE_DRAG_PREVIEW: 'DEPENDENCY_LINE_DRAG_PREVIEW',
	STICKY_HEADER_SCROLL_SHADOW: 'STICKY_HEADER_SCROLL_SHADOW',
	EMPTY_LIST_OVERLAY: 'EMPTY_LIST_OVERLAY',
	STICKY_GROUP_SCROLL_SHADOW: 'STICKY_GROUP_SCROLL_SHADOW',
	HORIZONTAL_SCROLL_BAR: 'HORIZONTAL_SCROLL_BAR',
} as const;

export type ZIndexLevel = (typeof Z_INDEX_LEVEL)[keyof typeof Z_INDEX_LEVEL];

export type ZIndexLayer = (typeof Z_INDEX_LAYER)[keyof typeof Z_INDEX_LAYER];

export type Params =
	| {
			rowType: 'header' | 'subheader' | 'group' | 'body';
			column: number;
			level?: ZIndexLevel;
	  }
	| {
			row: number;
			column: number;
			level?: ZIndexLevel;
	  }
	| {
			layer: ZIndexLayer;
	  };

export type StateProps = {
	zIndex: (arg0: Params) => number | undefined;
};

export type Props = PropsWithChildren<StateProps>;

export type ZIndexProps = Params & {
	children: (zIndex: number | undefined) => ReturnType<React.FC>;
};
