import type { UpdateIssuePayload } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/command/issue/types.tsx';
import type { Issue } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/issues/types.tsx';
import type { PlanInfo } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/plan/types.tsx';

export const FieldAction = {
	SET: 'SET',
	ADD: 'ADD',
	REMOVE: 'REMOVE',
	CLEAR: 'CLEAR',
} as const;

export type FieldActionType = (typeof FieldAction)[keyof typeof FieldAction];

export type StateProps = {
	plan: PlanInfo;
	selectedIssues: Issue[];
};

export type Props = StateProps & {
	bulkUpdateAttribute: (arg1: UpdateIssuePayload) => void;
	toggleModalDialog: () => void;
};
