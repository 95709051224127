import { getInlineCreateState } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/inline-create/index.tsx';
import { getIssueTypes } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/issue-types/index.tsx';
import { getIssueTypesByProjectId } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/projects/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export default createStructuredSelector<State, OwnProps, StateProps>({
	issueTypes: getIssueTypes,
	isCreatingIssues: (state: State) => !!getInlineCreateState(state).isCreatingIssues,
	issueTypesFromProject: (state: State, props: OwnProps) =>
		getIssueTypesByProjectId(state)(props.issue?.project?.id),
});
