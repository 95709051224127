import * as R from 'ramda';
import { getSelectOptions } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/custom-fields/index.tsx';
import { getCustomFieldFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/custom-field-filter/index.tsx';
import type { SelectOption } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/select-options/types.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

// this function fetches the options of all Multi Select custom fields enabled in the plan and
// returns only the options belonging to the custom field based on its id
export const getFilterOptions = (state: State, { customField }: OwnProps): SelectOption[] => {
	// "selectOptions" is an array including e.g.
	// [{id: 10200, value: "Brisbane", fieldId: 10400},
	// {id: 10201, value: "Melbourne", fieldId: 10400},
	// {id: 10202, value: "Sydney", fieldId: 10400},
	// {id: 10300, value: "Kangaroo", fieldId: 10500},
	// {id: 10301, value: "Koala", fieldId: 10500},
	// {id: 10302, value: "Echidna", fieldId: 10500},
	// {id: 10303, value: "Cockatoo", fieldId: 10500}]
	const selectOptions = getSelectOptions(state);

	// the "selectOptions" are then filtered to keep only the options of the custom field
	// e.g. if fieldId = 10400 the returned array is
	// [{id: 10200, value: "Brisbane", fieldId: 10400},
	// {id: 10201, value: "Melbourne", fieldId: 10400},
	// {id: 10202, value: "Sydney", fieldId: 10400}]
	return selectOptions.filter((opt) => opt.fieldId === customField.id);
};

// this function gets the selected options of the Multi Select custom fields filters enabled in the plan
// returns only the options belonging to the custom field based on its id
export const getFilterValue = (state: State, { customField }: OwnProps): string[] => {
	// "customFieldFilter" is an object including
	// an "id" which is the custom field filter id
	// a "value" object including all the custom fields id's, which in turn include the selected custom fields filters id's
	//  e.g.
	// {
	//   id: "state.domain.view-settings.filters.CUSTOM_FIELD_FILTER_ID",
	//   value: { 10400: ["10201", "10200"], 10500: ["10302"] }
	// }
	// in this instance, "Melbourne" and "Brisbane" are selected for fieldId "10400", "Echidna" is selected for fieldId "10500"
	const customFieldFilter = getCustomFieldFilter(state);

	// this looks for the value object in the customFieldFilter object, then looks for the array of selected filter values corresponding to the custom field id
	// in this instance, it will return
	// ["10201", "10200"] for custom field id "10400"
	// ["10302"] for custom field id "10500"
	return R.path(['value', String(customField.id)], customFieldFilter) || [];
};

// both functions are then used to create a dropdown list selector including all the possible options as well as the ones that are selected
export default createStructuredSelector<State, OwnProps, StateProps>({
	options: getFilterOptions,
	value: getFilterValue,
});
