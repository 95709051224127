import {
	add as addCustomFieldValuesGroup,
	remove as removeCustomFieldValuesGroup,
	update as updateCustomFieldValuesGroup,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/custom-field-values-groups/actions.tsx';
import type { CustomFieldValuesGroup } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/view-settings/custom-field-values-groups/types.tsx';
import type { MapDispatchToPropsSimple } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/redux.tsx';
import type { DispatchProps } from './types.tsx';

const mapDispatchToProps: MapDispatchToPropsSimple<DispatchProps> = (dispatch) => ({
	addGroup: (grouping: string, group: CustomFieldValuesGroup) => {
		dispatch(addCustomFieldValuesGroup({ grouping, group }));
	},
	updateGroup: (grouping: string, groupId: string, patch: Partial<CustomFieldValuesGroup>) => {
		dispatch(
			updateCustomFieldValuesGroup({
				grouping,
				id: groupId,
				// @ts-expect-error-Type-2322 'undefined' is not assignable to type 'string'.
				patch,
			}),
		);
	},
	removeGroup: (grouping: string, groupId: string) => {
		dispatch(removeCustomFieldValuesGroup({ grouping, id: groupId }));
	},
});

export default mapDispatchToProps;
