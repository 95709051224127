import React from 'react';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import { CustomFieldTypes } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import DatePickerRenderer from './renderers/date-picker/view.tsx';
import MultiSelectRenderer from './renderers/multi-select/index.tsx';
import SingleSelectRenderer from './renderers/single-select/index.tsx';
import TextRenderer from './renderers/text/view.tsx';
import UserPickerRenderer from './renderers/user-picker/index.tsx';
import type { Props } from './types.tsx';

export default function CustomField(props: Props) {
	const { customFields, id, value, attributeName } = props;
	if (!isDefined(value)) {
		return <>-</>;
	}

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const field = customFields.find((field) => field.id.toString() === id.toString());
	const emptyElement = <div />;
	if (!field) {
		return emptyElement;
	}

	if (typeof value === 'string' || typeof value === 'number') {
		const valueString = value === 'string' ? value : String(value);
		switch (field.type.key) {
			case CustomFieldTypes.TextField: {
				return <TextRenderer value={valueString} />;
			}
			case CustomFieldTypes.NumberField: {
				return <TextRenderer value={valueString} />;
			}
			case CustomFieldTypes.SingleSelect:
			case CustomFieldTypes.RadioButtons: {
				return <SingleSelectRenderer value={valueString} />;
			}
			case CustomFieldTypes.DatePicker: {
				return <DatePickerRenderer value={value} />;
			}
			case CustomFieldTypes.UserPicker: {
				return <UserPickerRenderer value={valueString} />;
			}
			default: {
				return emptyElement;
			}
		}
	} else {
		// value is an array
		switch (field.type.key) {
			case CustomFieldTypes.Labels:
			case CustomFieldTypes.MultiSelect:
			case CustomFieldTypes.MultiCheckboxes: {
				return <MultiSelectRenderer value={value} attributeName={attributeName} />;
			}
			default: {
				return emptyElement;
			}
		}
	}
}
