import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import type { ElementDragPayload } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import type { DropTargetRecord } from '@atlaskit/pragmatic-drag-and-drop/types';
import type { DragTarget, DropTarget } from '../types.tsx';

export const getDragTarget = (target: ElementDragPayload): DragTarget => {
	const { index, instance } = target.data;

	if (typeof index !== 'number' || typeof instance !== 'symbol')
		throw Error('Invalid field in drag target');

	return { index, instance };
};

export const getDropTarget = (target: DropTargetRecord | undefined): DropTarget | undefined => {
	if (target === undefined) return undefined;

	const { index, instance } = target.data;
	const closestEdge = extractClosestEdge(target.data);

	if (typeof index !== 'number' || typeof instance !== 'symbol')
		throw Error('Invalid field in drop target');

	return { index, instance, closestEdge };
};
