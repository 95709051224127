import type { CommonProps } from '@atlassian/jira-portfolio-3-common/src/select/types.tsx';

export const selectStyles = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	container: (styles: any, { selectProps: { menuIsOpen } }: CommonProps) => ({
		...styles,
		...(menuIsOpen ? { zIndex: 1 } : {}),
	}),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: (styles: any, { hasValue, selectProps: { menuIsOpen } }: CommonProps) =>
		menuIsOpen
			? { ...styles, zIndex: 99999 }
			: {
					...styles,
					background: 'none',
					borderColor: 'transparent',
					// eslint-disable-next-line no-useless-computed-key
					[':hover']: {
						...styles[':hover'],
						background: 'none',
						...(hasValue ? { borderColor: 'transparent' } : {}),
					},
				},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dropdownIndicator(styles: any) {
		return {
			...styles,
			alignSelf: 'flex-start',
		};
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	valueContainer(styles: any) {
		return {
			...styles,
			maxHeight: 150,
			overflow: 'auto',
			flex: '1',
		};
	},
} as const;
