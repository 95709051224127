import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import { getIsIssuesLoading } from '../issues/index.tsx';
import { getAllIssues, getOriginalIssues } from '../raw-issues/index.tsx';
import { getAllAssociatedIssueIds } from './utils.tsx';

export const getAssociatedIssueIds = createSelector(
	[getIsIssuesLoading, getAllIssues, getOriginalIssues],
	(isIssuesLoading, issues, originalIssues) =>
		isIssuesLoading ? undefined : getAllAssociatedIssueIds(issues, originalIssues),
);
