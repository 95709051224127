import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { State } from '../../state/types.tsx';
import type { InlineCreateState } from '../../state/ui/main/tabs/roadmap/scope/inline-create/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { INLINE_CREATE_ID } from '../../state/ui/main/tabs/roadmap/scope/inline-create/reducer';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { InlineCreateState } from '../../state/ui/main/tabs/roadmap/scope/inline-create/types';

export const getInlineCreateState = (state: State) => state.ui.Main.Tabs.Roadmap.Scope.InlineCreate;

export type InlineCreatePosition = {
	isOpen: boolean;
	parentId: string | null | undefined;
	hierarchyLevel: number;
};

const getInlineCreatePositionPure = (inlineState: InlineCreateState): InlineCreatePosition => ({
	isOpen: inlineState.isOpen,
	parentId: inlineState.parentId,
	hierarchyLevel: inlineState.hierarchyLevel,
});

export const getInlineCreatePosition = createSelector(
	[getInlineCreateState],
	getInlineCreatePositionPure,
);
