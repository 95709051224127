import type { Effect } from 'redux-saga';
import * as R from 'ramda';
import { put, call, select, fork, takeEvery } from 'redux-saga/effects';
import type { Sequence } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import fetch from '@atlassian/jira-portfolio-3-portfolio/src/common/fetch/index.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import type { Timestamp } from '@atlassian/jira-portfolio-3-portfolio/src/common/types/index.tsx';
import {
	ENTITY,
	SCENARIO_ISSUE_ID_PREFIX,
	RELEASE_STATUSES,
	SCENARIO_TYPE,
	type ScenarioType,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import { getReleaseFilterPure } from '../../query/filters/release-filter/index.tsx';
import { getIssuesByVersionMap, getOriginalIssues } from '../../query/issues/index.tsx';
import {
	getIssueStartingLastBasedOnBaselineStart,
	getIssueEndingLastBasedOnBaselineEnd,
	getIssueEndingLastBasedOnSprintEndDate,
} from '../../query/issues/utils.tsx';
import { getPlan } from '../../query/plan/index.tsx';
import { getSprintsWithFutureDates } from '../../query/sprints/index.tsx';
import {
	getVersions,
	getVersionsById,
	getOriginalVersions,
	getReleasesTableRankingStatus,
	getVersionWithHighestRankInProject,
	getCommitWarningsForVersions,
	getRemovedVersionsById,
} from '../../query/versions/index.tsx';
import type { VersionsById } from '../../query/versions/types.tsx';
import * as crossProjectVersionsActions from '../../state/domain/cross-project-versions/actions.tsx';
import * as issueActions from '../../state/domain/issues/actions.tsx';
import type { Issue } from '../../state/domain/issues/types.tsx';
import {
	adjustVersionLexoRank,
	updateVersionLexorankAdjustments,
} from '../../state/domain/lexorank/actions.tsx';
import * as originalCrossProjectVersionsActions from '../../state/domain/original-cross-project-versions/actions.tsx';
import * as originalIssueActions from '../../state/domain/original-issues/actions.tsx';
import { reset as resetOriginalVersions } from '../../state/domain/original-versions/actions.tsx';
import * as originalVersionsActions from '../../state/domain/original-versions/actions.tsx';
import {
	addVersionInProject,
	removeVersionFromProject,
} from '../../state/domain/projects/actions.tsx';
import * as removedVersionsActions from '../../state/domain/removed-versions/actions.tsx';
import * as scenarioRemovedIssuesActions from '../../state/domain/scenario-removed-issues/actions.tsx';
import { update as updateSequence } from '../../state/domain/sequence/actions.tsx';
import { add as addCommitWarning } from '../../state/domain/update-jira/warnings/actions.tsx';
import * as versionsActions from '../../state/domain/versions/actions.tsx';
import type {
	RankActionPayload as RankReleasePayload,
	Relation,
} from '../../state/domain/versions/actions.tsx';
import type { Version } from '../../state/domain/versions/types.tsx';
import {
	RELEASE_FILTER_ID,
	CROSS_PROJECT_RELEASE_FILTER_ID,
} from '../../state/domain/view-settings/filters/types.tsx';
import type { State } from '../../state/types.tsx';
import { closeDialog as closeAddToCprDialog } from '../../state/ui/main/tabs/releases/project-releases/add-to-cpr-dialog/actions.tsx';
import {
	closeDialog as closeDeleteDialog,
	initiateRequest as initiateDeleteRequest,
	resetRequest as resetDeleteRequest,
} from '../../state/ui/main/tabs/releases/project-releases/delete-release-dialog/actions.tsx';
import {
	closeDialog,
	initiateRequest,
	resetRequest,
} from '../../state/ui/main/tabs/releases/project-releases/release-dialog/actions.tsx';
import { isReleasesRanking } from '../../state/ui/main/tabs/releases/project-releases/releases-table/actions.tsx';
import { POST, parseError } from '../api.tsx';
import batch from '../batch/index.tsx';
import type { BulkCommitResponseEntity } from '../commit-bulk/types.tsx';
import { revertBody } from '../commit/api.tsx';
import { genericError } from '../errors/index.tsx';
import { change as changeFilter, clear as clearFilter } from '../filters/index.tsx';
import * as http from '../http/index.tsx';
import {
	urls,
	addVersionBody,
	updateVersionBody,
	rankVersionBody,
	deleteVersionBody,
	type AddReleaseCommandPayload,
	type UpdateReleaseCommandPayload,
} from './api.tsx';
import { inspectForCommitWarnings } from './warnings.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	setMode,
	openDialog,
	closeDialog,
} from '../../state/ui/main/tabs/releases/project-releases/release-dialog/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	CREATE_RELEASE,
	EDIT_RELEASE,
} from '../../state/ui/main/tabs/releases/project-releases/release-dialog/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	setMode as setDeleteDialogMode,
	openDialog as openDeleteDialog,
	closeDialog as closeDeleteDialog,
} from '../../state/ui/main/tabs/releases/project-releases/delete-release-dialog/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { DELETE_DIALOG_ACTIONS } from '../../state/ui/main/tabs/releases/project-releases/delete-release-dialog/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	openDialog as openAddToCprDialog,
	closeDialog as closeAddToCprDialog,
} from '../../state/ui/main/tabs/releases/project-releases/add-to-cpr-dialog/actions';

// TODO This is a horrendous hack to temporarily workaround mutual dependency between command/versions and command/cross-project-versions via injection at common parent. Proper solution pending.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let crossProjectVersionsCommands: Record<string, any> = {};

export const ADD_RELEASE = 'command.versions.ADD_RELEASE' as const;
export const UPDATE_RELEASE = 'command.versions.UPDATE_RELEASE' as const;
export const RANK_RELEASE = 'command.versions.RANK_RELEASE' as const;
export const DELETE_RELEASE = 'command.versions.DELETE_RELEASE' as const;
export const CALCULATE_RELEASE_STATUS = 'command.versions.CALCULATE_RELEASE_STATUS' as const;
export const CALCULATE_STATUS_OF_ALL_RELEASES =
	'command.versions.CALCULATE_STATUS_OF_ALL_RELEASES' as const;
export const ADD_RELEASE_TO_CROSS_PROJECT_RELEASE =
	'command.versions.ADD_RELEASE_TO_CROSS_PROJECT_RELEASE' as const;
export const VIEW_RELEASE_IN_ROADMAP = 'command.versions.VIEW_RELEASE_IN_ROADMAP' as const;
export const GET_RELEASES_FOR_IDS = 'command.versions.GET_RELEASES_FOR_IDS' as const;

type AddReleaseCommand = {
	type: typeof ADD_RELEASE;
	payload: AddReleaseCommandPayload;
};

export type UpdateReleaseCommand = {
	type: typeof UPDATE_RELEASE;
	payload: UpdateReleaseCommandPayload;
};

type RankReleaseCommand = {
	type: typeof RANK_RELEASE;
	payload: RankReleasePayload;
};

type DeleteReleaseCommand = {
	type: typeof DELETE_RELEASE;
	versionId: string;
};

type CalculateReleaseStatusCommand = {
	type: typeof CALCULATE_RELEASE_STATUS;
	versionId: string;
};

type CalculateStatusOfAllReleasesCommand = {
	type: typeof CALCULATE_STATUS_OF_ALL_RELEASES;
};

type AddReleaseToCrossProjectReleasePayload = {
	versionId: string;
	crossProjectVersionId: string;
};

export type AddReleaseToCrossProjectReleaseCommand = {
	type: typeof ADD_RELEASE_TO_CROSS_PROJECT_RELEASE;
	payload: AddReleaseToCrossProjectReleasePayload;
};

type ViewReleaseInRoadmapCommand = {
	type: typeof VIEW_RELEASE_IN_ROADMAP;
	payload: string[];
};

type GetReleasesForIdsAction = {
	type: typeof GET_RELEASES_FOR_IDS;
	payload: string[];
};

export const addRelease = (version: AddReleaseCommandPayload): AddReleaseCommand => ({
	type: ADD_RELEASE,
	payload: version,
});

export const updateRelease = (payload: UpdateReleaseCommandPayload): UpdateReleaseCommand => ({
	type: UPDATE_RELEASE,
	payload,
});

export const rankRelease = (
	id: string,
	anchor: string,
	relation: Relation,
): RankReleaseCommand => ({
	type: RANK_RELEASE,
	payload: {
		id,
		anchor,
		relation,
	},
});

export const deleteRelease = (versionId: string): DeleteReleaseCommand => ({
	type: DELETE_RELEASE,
	versionId,
});

export const calculateReleaseStatus = (versionId: string): CalculateReleaseStatusCommand => ({
	type: CALCULATE_RELEASE_STATUS,
	versionId,
});

export const calculateStatusOfAllReleases = (): CalculateStatusOfAllReleasesCommand => ({
	type: CALCULATE_STATUS_OF_ALL_RELEASES,
});

export const addReleaseToCrossProjectRelease = (
	versionId: string,
	crossProjectVersionId: string,
) => ({
	type: ADD_RELEASE_TO_CROSS_PROJECT_RELEASE,
	payload: {
		versionId,
		crossProjectVersionId,
	},
});

export const viewReleaseInRoadmap = (filterValue: string[]): ViewReleaseInRoadmapCommand => ({
	type: VIEW_RELEASE_IN_ROADMAP,
	payload: filterValue,
});

export const getReleasesForIds = (payload: string[]): GetReleasesForIdsAction => ({
	type: GET_RELEASES_FOR_IDS,
	payload,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* addVersionInStore(version: Version): Generator<Effect, any, any> {
	const { id, projects, crossProjectVersion } = version;

	// Add a new release to state.domain.versions.
	yield put(versionsActions.add(version));

	// Add a new release to each project that it belongs to.
	for (const projectId of projects) {
		yield put(
			addVersionInProject({
				projectId,
				versionId: id,
			}),
		);
	}

	// Add a new release to each crossProjectVersion that it belongs to.
	if (isDefined(crossProjectVersion)) {
		yield call(
			crossProjectVersionsCommands.addVersionInCrossProjectVersion,
			id,
			crossProjectVersion,
		);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* removeVersionFromStore(versionId: string): Generator<Effect, any, any> {
	const versionbyIdMap: VersionsById = yield select(getVersionsById);
	const version: Version = versionbyIdMap[versionId];
	const { id, projects, crossProjectVersion } = version;

	// Remove a release from each project
	for (const projectId of projects) {
		yield put(
			removeVersionFromProject({
				projectId,
				versionId: id,
			}),
		);
	}

	// Remove a release from crossProjectVersion
	if (isDefined(crossProjectVersion)) {
		yield call(
			crossProjectVersionsCommands.removeVersionFromCrossProjectVersion,
			id,
			crossProjectVersion,
		);
	}

	// Remove a release from state.domain.versions
	yield put(versionsActions.remove(id));
}

export function* addRemovedVersionToMainVersionsList(
	removedVersion: Version, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const { id } = removedVersion;
	yield* batch(function* () {
		// Remove version from removed versions' list
		yield put(removedVersionsActions.remove(id));

		// Add it back to versions list
		yield call(addVersionInStore, removedVersion);
	});
}

export function* updateDetailsOfNewlyAddedVersion(
	scenarioId: string,
	versionId: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const versionbyIdMap: VersionsById = yield select(getVersionsById);
	const version = versionbyIdMap[scenarioId];
	const releaseFilter = yield select(getReleaseFilterPure);
	yield* batch(function* () {
		yield put(
			removeVersionFromProject({
				projectId: version.projects[0],
				versionId: scenarioId,
			}),
		);
		yield put(
			addVersionInProject({
				projectId: version.projects[0],
				versionId,
			}),
		);
		yield put(versionsActions.bulkUpdate({ [scenarioId]: { id: versionId } }));
		if (releaseFilter.value && releaseFilter.value.includes(scenarioId)) {
			const rest = releaseFilter.value.filter(
				// eslint-disable-next-line @typescript-eslint/no-shadow
				(versionId: string) => versionId !== scenarioId,
			);
			yield put(changeFilter({ id: RELEASE_FILTER_ID, value: [...rest, versionId] }));
		}
		yield put(
			crossProjectVersionsActions.updateReferences({ versions: [[scenarioId, versionId]] }),
		);
		yield put(
			originalCrossProjectVersionsActions.updateReferences({
				versions: [[scenarioId, versionId]],
			}),
		);
		yield put(
			updateVersionLexorankAdjustments({
				[scenarioId]: versionId,
			}),
		);
	});
}

export function* handleVersionWarnings(
	entityResponse: BulkCommitResponseEntity, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	const versionId = entityResponse.generatedId;
	const id = entityResponse.itemKey;

	if (isDefined(versionId)) {
		yield call(updateDetailsOfNewlyAddedVersion, id, versionId);
	}

	const warnings = yield call(inspectForCommitWarnings, entityResponse.error, id);
	if (warnings.length) {
		yield put(
			addCommitWarning({
				category: ENTITY.RELEASE,
				itemId: id,
				warnings,
			}),
		);
	}
}

export function* handleVersionCommitResponse(
	entityResponse: BulkCommitResponseEntity, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, void, any> {
	yield call(handleVersionWarnings, entityResponse);

	const id = entityResponse.itemKey;
	const warnings = yield select(getCommitWarningsForVersions);
	const removedVersionsById = yield select(getRemovedVersionsById);
	const removedVersion = removedVersionsById[id];
	if (!isDefined(warnings[id])) {
		const originals = yield select(getOriginalVersions);
		yield put(resetOriginalVersions(R.dissoc(id, originals)));
		if (isDefined(removedVersion)) {
			yield put(removedVersionsActions.remove(id));
		}
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* revertChange(id: string): Generator<Effect, http.JsonResponse<any>, any> {
	const {
		domain: {
			plan: { id: planId, currentScenarioId },
			sequence,
		},
	}: State = yield select(R.identity);
	const body = revertBody({ id: planId, currentScenarioId }, sequence, id);

	const response = yield* http.json({ url: urls.revertChanges, method: 'POST', body });

	if (response.ok) {
		const {
			// eslint-disable-next-line @typescript-eslint/no-shadow
			change: { sequence },
		} = response.data;
		yield put(updateSequence(sequence));
	}

	return response;
}

export function* updateReferences(
	payload: versionsActions.UpdateReferencesPayload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	yield put(versionsActions.updateReferences(payload));
	yield put(originalVersionsActions.updateReferences(payload));
}

export function* revertReferences(
	payload: {
		[key: string]: string[];
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const versions = yield select(getVersions);
	let originalVersions = R.merge({}, yield select(getOriginalVersions));
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const versionPatches: Record<string, any> = {};
	for (const version of versions) {
		const { id } = version;
		const originalVersion = originalVersions[id];
		for (const key of Object.keys(payload)) {
			for (const ref of payload[key]) {
				if (version[key] === ref) {
					versionPatches[id] = R.merge(versionPatches[id], {
						[key]: originalVersion[key] || null,
					});
					delete originalVersion[key];
					if (Object.keys(originalVersion).length === 0) {
						originalVersions = R.dissoc(id, originalVersions);
					}
				}
			}
		}
	}
	yield put(versionsActions.bulkUpdate(versionPatches));
	yield put(originalVersionsActions.reset(originalVersions));
}

export function* updateStateAfterAddingVersion(
	{ projectId, version }: AddReleaseCommandPayload,
	newVersionId: string,
	sequence: Sequence, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	yield* batch(function* () {
		// Add a new release to client-side model
		const highestRankedVersion: Version | null | undefined = yield select(
			getVersionWithHighestRankInProject,
			projectId,
		);
		yield call(addVersionInStore, {
			...version,
			id: newVersionId,
			crossProjectVersion: null,
			releaseStatusId: '0',
			lexoRank: isDefined(highestRankedVersion) ? highestRankedVersion.lexoRank : '',
			projects: [projectId],
		});

		// Rank a newly added release such that it has the highest rank
		if (isDefined(highestRankedVersion)) {
			const rankPayload: RankReleasePayload = {
				id: newVersionId,
				anchor: highestRankedVersion.id,
				relation: 'AFTER',
			};
			yield put(versionsActions.rank(rankPayload));
			yield put(adjustVersionLexoRank(rankPayload));
		}

		// Update the sequence
		yield put(updateSequence(sequence));

		/** Update the original versions model so that it shows newly added release in
		 *  the update-jira dialog.
		 */
		yield put(originalVersionsActions.update({ id: newVersionId, values: {} }));

		// Close the create release dialog
		yield put(closeDialog());
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doAddVersion({ payload }: AddReleaseCommand): Generator<Effect, any, any> {
	yield put(initiateRequest());
	try {
		const url = urls.add;
		const body = addVersionBody(yield select(getPlan), payload);
		const response = yield call(fetch, url, {
			method: POST,
			body,
			profile: url,
		});
		if (!response.ok) {
			yield put(
				genericError({
					...parseError(response, yield call(response.text.bind(response))),
					requestInfo: {
						url,
						type: POST,
						status: response.status,
						body,
					},
				}),
			);
		} else {
			const {
				itemKey,
				change: { sequence },
			} = yield call(response.json.bind(response));
			yield call(updateStateAfterAddingVersion, payload, itemKey, sequence);
		}
		yield put(resetRequest());
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(resetRequest());
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}

export function* updateVersion({
	versionId,
	patch, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: UpdateReleaseCommandPayload): Generator<Effect, any, any> {
	const versionsById: VersionsById = yield select(getVersionsById);
	const version: Version = versionsById[versionId];
	const updatedAttributes: string[] = Object.keys(patch);
	const originalValues = R.pick(updatedAttributes, { ...version });

	// Update a release in client-side model
	yield put(
		versionsActions.bulkUpdate({
			[versionId]: patch,
		}),
	);

	// Calculate the new release status
	if (updatedAttributes.includes('start') || updatedAttributes.includes('end')) {
		yield put(calculateReleaseStatus(versionId));
	}

	// Update cross-project version
	if (updatedAttributes.includes('crossProjectVersion')) {
		if (isDefined(version.crossProjectVersion)) {
			// Remove a release from crossProjectVersion
			yield call(
				crossProjectVersionsCommands.removeVersionFromCrossProjectVersion,
				versionId,
				version.crossProjectVersion,
			);
		}

		if (isDefined(patch.crossProjectVersion)) {
			// Add a new release to each crossProjectVersion that it belongs to.
			yield call(
				crossProjectVersionsCommands.addVersionInCrossProjectVersion,
				versionId,
				patch.crossProjectVersion,
			);
		}
	}

	/** Update the original versions model so that it shows newly added release in
	 *  the update-jira dialog.
	 */
	yield put(originalVersionsActions.update({ id: versionId, values: originalValues }));
}

export function* updateStateAfterUpdatingVersion(
	payload: UpdateReleaseCommandPayload,
	sequence: Sequence, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	yield* batch(function* () {
		// Update the version
		yield call(updateVersion, payload);

		// Update the sequence
		yield put(updateSequence(sequence));

		// Close the update release dialog
		yield put(closeDialog());
		yield put(closeAddToCprDialog());
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doUpdateVersion({ payload }: UpdateReleaseCommand): Generator<Effect, any, any> {
	yield put(initiateRequest());
	try {
		const url = urls.update;
		const body = updateVersionBody(yield select(getPlan), payload);
		const response = yield call(fetch, url, {
			method: POST,
			body,
			profile: url,
		});
		if (!response.ok) {
			yield put(
				genericError({
					...parseError(response, yield call(response.text.bind(response))),
					requestInfo: {
						url,
						type: POST,
						status: response.status,
						body,
					},
				}),
			);
		} else {
			const {
				change: { sequence },
			} = yield call(response.json.bind(response));
			yield call(updateStateAfterUpdatingVersion, payload, sequence);
		}
		yield put(resetRequest());
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(resetRequest());
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doRankVersion({ payload }: RankReleaseCommand): Generator<Effect, any, any> {
	const isRanking = yield select(getReleasesTableRankingStatus);
	try {
		const url = urls.rank;
		const body = rankVersionBody(yield select(getPlan), payload);
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});
		if (!response.ok) {
			yield put(
				genericError({
					...parseError(response, yield call(response.text.bind(response))),
					requestInfo: {
						url,
						type: POST,
						status: response.status,
						body,
					},
				}),
			);
			/** When a release is ranked in the releases table and the call fails, the dynamic-table that we are using
			 * remembers the new poisiton and do not put the release back to it's original position. In order to achieve that
			 * we have to re-render dynamic table. This state is updated to re-render the releases dynamic table. This
			 * happens in all failure scenario. */
			yield put(isReleasesRanking(!isRanking));
			return;
		}
		const {
			change: { sequence },
			failed,
		} = yield call(response.json.bind(response));
		if (failed > 0) {
			yield put(genericError({ message: 'Failed to rank release' }));
			yield put(isReleasesRanking(!isRanking));
			return;
		}
		yield* batch(function* () {
			const versions: ReturnType<typeof getVersions> = yield select(getVersions);
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-shadow
			const { id, lexoRank } = versions.find(({ id }) => id === payload.id)!;
			if (!id.startsWith(SCENARIO_ISSUE_ID_PREFIX)) {
				yield put(originalVersionsActions.update({ id, values: { lexoRank } }));
			}
			yield put(versionsActions.rank(payload));
			yield put(updateSequence(sequence));
			yield put(adjustVersionLexoRank(payload));
		});
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
		yield put(isReleasesRanking(!isRanking));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* unassignVersionInIssues(versionId: string): Generator<Effect, any, any> {
	const issuesByVersion: {
		[key: string]: Issue[];
	} = yield select(getIssuesByVersionMap);
	const issuesInCurrentVersion: Issue[] = issuesByVersion[versionId];
	const originalIssues = yield select(getOriginalIssues);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const bulkUpdatePayload: Record<string, any> = {};
	for (const { id } of issuesInCurrentVersion) {
		bulkUpdatePayload[id] = {
			fixVersions: originalIssues[id].fixVersions,
		};
		yield put(
			originalIssueActions.revertFields({
				id,
				fields: ['fixVersions'],
			}),
		);
	}
	yield put(issueActions.bulkUpdate(bulkUpdatePayload));
}

export function* updateStateAfterDeletingVersion(
	versionId: string,
	sequence: Sequence, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	yield* batch(function* () {
		// Reset version assginments to Issue.
		yield call(unassignVersionInIssues, versionId);

		// Remove version from store
		yield call(removeVersionFromStore, versionId);

		// Remove version from original versions as we don't commit such changes
		const originals: ReturnType<typeof getOriginalVersions> = yield select(getOriginalVersions);
		yield put(originalVersionsActions.reset(R.dissoc(versionId, originals)));

		// Update the sequence
		yield put(updateSequence(sequence));

		// Close the delete release dialog
		yield put(closeDeleteDialog());
	});
}

export function* updateStateAfterRemovingVersion(
	versionId: string,
	sequence: Sequence, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const versionsById: VersionsById = yield select(getVersionsById);
	const version: Version = versionsById[versionId];
	const issuesByVersionid = yield select(getIssuesByVersionMap);

	yield* batch(function* () {
		// Remove version from store
		yield call(removeVersionFromStore, versionId);

		// Add version to removed version
		yield put(removedVersionsActions.add(version));

		// Add issues to scenario removed issues that are assigned to removed version
		/**
		 * We have intentionally not removed these issues from the "issues" branch of the state
		 * because we are removed issues that are assinged to scenario removed issues in the
		 * "getIsssues" selector.
		 */
		yield put(scenarioRemovedIssuesActions.bulkAdd(issuesByVersionid[versionId]));

		/** Update the original versions model so that it shows removed release in
		 *  the update-jira dialog.
		 */
		yield put(originalVersionsActions.update({ id: versionId, values: {} }));

		// Update the sequence
		yield put(updateSequence(sequence));

		// Close the delete release dialog
		yield put(closeDeleteDialog());
	});
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doDeleteVersion({ versionId }: DeleteReleaseCommand): Generator<Effect, any, any> {
	yield put(initiateDeleteRequest());
	try {
		const url = urls.delete;
		const body = deleteVersionBody(yield select(getPlan), versionId);
		const response = yield call(fetch, url, {
			method: POST,
			body,
		});
		if (!response.ok) {
			yield put(
				genericError({
					...parseError(response, yield call(response.text.bind(response))),
					requestInfo: {
						url,
						type: POST,
						status: response.status,
						body,
					},
				}),
			);
		} else {
			const {
				change: { sequence },
			} = yield call(response.json.bind(response));
			if (versionId.startsWith(SCENARIO_ISSUE_ID_PREFIX)) {
				yield call(updateStateAfterDeletingVersion, versionId, sequence);
			} else {
				yield call(updateStateAfterRemovingVersion, versionId, sequence);
			}
		}
		yield put(resetDeleteRequest());
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (e: any) {
		yield put(resetDeleteRequest());
		yield put(genericError({ message: e.message, stackTrace: e.stack }));
	}
}

export function* setStatusOfVersion(
	versionId: string,
	issueDate?: Timestamp | null,
	releaseDate?: Timestamp | null, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	/** If target start/end date of the issue is after release date then set the
	 * release status as OFFTRACK, else set as ONTRACK.
	 */
	const releaseStatusId =
		isDefined(issueDate) && isDefined(releaseDate) && issueDate > releaseDate
			? RELEASE_STATUSES.OFFTRACK
			: RELEASE_STATUSES.ONTRACK;

	yield put(
		versionsActions.bulkUpdate({
			[versionId]: {
				releaseStatusId,
			},
		}),
	);
}

export function* doCalculateVersionStatus(
	{ versionId }: CalculateReleaseStatusCommand,
	issuesByVersionCache?: {
		[key: string]: Issue[];
	}, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	const versionbyIdMap: VersionsById = yield select(getVersionsById);
	const issuesByVersion: {
		[key: string]: Issue[];
	} = issuesByVersionCache || (yield select(getIssuesByVersionMap));
	const version: Version = versionbyIdMap[versionId];

	if (!version) {
		return;
	}

	const releaseDate: Timestamp | null | undefined = version.end;

	// This additional check is used when we directly call this saga.
	if (version.releaseStatusId === RELEASE_STATUSES.RELEASED) {
		return;
	}
	// Calculate the release status based the target end date of the issues.
	const issueEndingLastBasedOnBaselineEnd: Issue | null | undefined = yield call(
		getIssueEndingLastBasedOnBaselineEnd,
		issuesByVersion[versionId],
	);
	if (isDefined(issueEndingLastBasedOnBaselineEnd)) {
		const { baselineEnd } = issueEndingLastBasedOnBaselineEnd;
		yield call(setStatusOfVersion, versionId, baselineEnd, releaseDate);
		return;
	}

	/** If target end date is not set for all the issues then calculate the release status
	 * based on the target start date of the issues.
	 */
	const issueStartingLastBasedOnBaselineStart: Issue | null | undefined = yield call(
		getIssueStartingLastBasedOnBaselineStart,
		issuesByVersion[versionId],
	);
	if (isDefined(issueStartingLastBasedOnBaselineStart)) {
		const { baselineStart } = issueStartingLastBasedOnBaselineStart;
		yield call(setStatusOfVersion, versionId, baselineStart, releaseDate);
		return;
	}

	/** If target start and targetEnd dates are not set for all the issues then calculate the release status
	 * based on the sprint date end  of the issues.
	 */
	const sprints = yield select(getSprintsWithFutureDates);

	const issueEndingLastBasedOnSprintEndDate: Issue | null | undefined = yield call(
		getIssueEndingLastBasedOnSprintEndDate,
		issuesByVersion[versionId],
		sprints,
	);
	if (isDefined(issueEndingLastBasedOnSprintEndDate)) {
		const { baselineEnd } = issueEndingLastBasedOnSprintEndDate;
		yield call(setStatusOfVersion, versionId, baselineEnd, releaseDate);
		return;
	}

	/** If none of the issues are set target start and target end date then we can't calculate
	 * the release status. Hence set the release status as ONTRACK.
	 */
	yield put(
		versionsActions.bulkUpdate({
			[versionId]: {
				releaseStatusId: RELEASE_STATUSES.ONTRACK,
			},
		}),
	);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* doCalculateStatusOfAllVersions(): Generator<Effect, any, any> {
	const versions: ReturnType<typeof getVersions> = yield select(getVersions);
	const issuesByVersion: Record<string, Issue[]> = yield select(getIssuesByVersionMap);

	const unreleasedVersionsWithReleaseDateAndIssues: string[] = [];
	const onTrackPayload = {
		releaseStatusId: RELEASE_STATUSES.ONTRACK,
	};
	const onTrackVersions: Record<string, typeof onTrackPayload> = {};
	for (const { id, releaseStatusId, end } of versions) {
		if (releaseStatusId !== RELEASE_STATUSES.RELEASED) {
			if (
				!isDefined(end) || // without a release date
				issuesByVersion[id].length === 0 // or without issues assigned to it
			) {
				onTrackVersions[id] = onTrackPayload;
			} else {
				unreleasedVersionsWithReleaseDateAndIssues.push(id);
			}
		}
	}

	yield* batch(function* () {
		// Set the release status as ONTRACK for all the unreleased releases without
		// release date and with none of the issues tagged to them.
		yield put(versionsActions.bulkUpdate(onTrackVersions));
		// Now calculate the release status of the unreleased releases with release
		// date and having issues tagged to them.
		for (const versionId of unreleasedVersionsWithReleaseDateAndIssues) {
			yield call(doCalculateVersionStatus, calculateReleaseStatus(versionId), issuesByVersion);
		}
	});
}

export function* doAddReleaseToCrossProjectRelease({
	payload: { versionId, crossProjectVersionId }, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: AddReleaseToCrossProjectReleaseCommand): Generator<Effect, any, any> {
	yield put(
		updateRelease({
			versionId,
			patch: {
				crossProjectVersion: crossProjectVersionId,
			},
		}),
	);
}

export function* doViewReleaseInRoadmap({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ViewReleaseInRoadmapCommand): Generator<Effect, any, any> {
	yield put(changeFilter({ id: RELEASE_FILTER_ID, value: payload }));
	yield put(clearFilter(CROSS_PROJECT_RELEASE_FILTER_ID));
}

export function* doGetReleasesForIds({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: GetReleasesForIdsAction): Generator<Effect, any, any> {
	const { id: planId, currentScenarioId: scenarioId }: ReturnType<typeof getPlan> =
		yield select(getPlan);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const response: http.JsonResponse<any> = yield* http.json({
		url: urls.get,
		method: 'POST',
		body: { planId, scenarioId, ids: payload },
	});
	if (response.ok) {
		yield* batch(function* () {
			for (const version of response.data.filter(
				// eslint-disable-next-line @typescript-eslint/no-shadow
				(version: { scenarioType: ScenarioType }) => version.scenarioType !== SCENARIO_TYPE.DELETED,
			)) {
				yield put(
					versionsActions.add({
						projects: [version.projectId],
						id: version.itemKey,
						...version.values,
					}),
				);
				yield put(calculateReleaseStatus(version.values.id));
			}
		});
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAddVersion(): Generator<Effect, any, any> {
	yield takeEvery(ADD_RELEASE, doAddVersion);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchUpdateVersion(): Generator<Effect, any, any> {
	yield takeEvery(UPDATE_RELEASE, doUpdateVersion);
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchRankVersion(): Generator<Effect, any, any> {
	yield takeEvery(RANK_RELEASE, doRankVersion);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchDeleteVersion(): Generator<Effect, any, any> {
	yield takeEvery(DELETE_RELEASE, doDeleteVersion);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchCalculateVersionStatus(): Generator<Effect, any, any> {
	yield takeEvery(CALCULATE_RELEASE_STATUS, doCalculateVersionStatus);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchCalculateStatusOfAllVersions(): Generator<Effect, any, any> {
	yield takeEvery(CALCULATE_STATUS_OF_ALL_RELEASES, doCalculateStatusOfAllVersions);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchAddReleaseToCrossProjectRelease(): Generator<Effect, any, any> {
	yield takeEvery(ADD_RELEASE_TO_CROSS_PROJECT_RELEASE, doAddReleaseToCrossProjectRelease);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchViewReleaseInRoadmap(): Generator<Effect, any, any> {
	yield takeEvery(VIEW_RELEASE_IN_ROADMAP, doViewReleaseInRoadmap);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchGetReleasesForIds(): Generator<Effect, any, any> {
	yield takeEvery(GET_RELEASES_FOR_IDS, doGetReleasesForIds);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function (crossProjectVersionsCommandsModule: any) {
	crossProjectVersionsCommands = crossProjectVersionsCommandsModule;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return function* (): Generator<Effect, any, any> {
		yield fork(watchAddVersion);
		yield fork(watchUpdateVersion);
		yield fork(watchRankVersion);
		yield fork(watchDeleteVersion);
		yield fork(watchCalculateVersionStatus);
		yield fork(watchCalculateStatusOfAllVersions);
		yield fork(watchAddReleaseToCrossProjectRelease);
		yield fork(watchViewReleaseInRoadmap);
		yield fork(watchGetReleasesForIds);
	};
}
