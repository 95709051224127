import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	heading: {
		id: 'portfolio-3-onboarding.ctp-onboarding.boards-spotlight.heading',
		defaultMessage: 'A board for each team',
		description: 'Heading for the cross team planning template boards spotlight card.',
	},
	bodyFirst: {
		id: 'portfolio-3-onboarding.ctp-onboarding.boards-spotlight.body-first',
		defaultMessage: 'Bookmark these two team boards for quick access.',
		description:
			'First line of the body for the cross team planning template boards spotlight card.',
	},
	board: {
		id: 'portfolio-3-onboarding.ctp-onboarding.boards-spotlight.board',
		defaultMessage: 'Board',
		description:
			'Issue entity type to be shown on the cross team planning template teams spotlight card.',
	},
	ok: {
		id: 'portfolio-3-onboarding.ctp-onboarding.boards-spotlight.ok',
		defaultMessage: 'OK',
		description: 'Button copy for the cross team planning template teams spotlight card.',
	},
});
