import { isExpandedObservationData, type ObservationData } from '../types.tsx';
import { generateLongTaskAnalyticsSummary, triggerAnalytics } from './common.tsx';

export const genericReporter = (
	observationData: ObservationData,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	featureFlags: Record<any, any> = {},
) => {
	if (isExpandedObservationData(observationData)) {
		const triggerSoftwareAnalytics = triggerAnalytics(`software.${observationData.appName}`);
		const startTime = observationData.events.map((event) => event.timestamp);
		const analyticsData = {
			...generateLongTaskAnalyticsSummary(`${observationData.type}`, observationData.events),
			measureTimespan: performance.now() - Math.min(observationData.startTimestamp, ...startTime),
			...featureFlags,
		};
		triggerSoftwareAnalytics(`long-tasks-${observationData.type}`, analyticsData);
	}
};
