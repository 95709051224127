import { type Effect, delay } from 'redux-saga';
import * as R from 'ramda';
import { call, fork, takeEvery, put, select } from 'redux-saga/effects';
import {
	getRandomColor,
	toBackendColor,
} from '@atlassian/jira-issue-epic-color/src/common/utils.tsx';
import { EPIC_LEVEL } from '@atlassian/jira-portfolio-3-common/src/hierarchy/index.tsx';
import type { Component } from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import { isDefined } from '@atlassian/jira-portfolio-3-portfolio/src/common/ramda/index.tsx';
import {
	GROUPING,
	type Grouping,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	getCustomFieldIdFromCustomFieldGrouping,
	isRoadmapGroupedByCustomField,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/custom-fields/index.tsx';
import { getComponentsByProjectId } from '../../query/components/index.tsx';
import { getInlineCreateState } from '../../query/inline-create/index.tsx';
import {
	getDefaultProjectId,
	getDefaultIssuePriorityByProjectId,
} from '../../query/projects/index.tsx';
import { UNDEFINED_GROUP } from '../../query/scope/index.tsx';
import { getGroupKey } from '../../query/scope/util.tsx';
import {
	type ComponentGroupsById,
	getComponentGroupsById,
	getLabelGroupsById,
} from '../../query/view-settings/index.tsx';
import {
	getCustomFieldValuesGroupsById,
	getVisualisationGrouping,
	isGroupByMultiValueCustomField,
} from '../../query/visualisations/index.tsx';
import type { GroupCombination } from '../../state/domain/scope/types.tsx';
import { setIsExpanded } from '../../state/domain/view-settings/issue-expansions/actions.tsx';
import {
	startInlineCreate as startInlineCreateUi,
	type StartInlineCreateActionPayload,
	setIsCreatingIssues,
} from '../../state/ui/main/tabs/roadmap/scope/inline-create/actions.tsx';
import type { InlineCreateState } from '../../state/ui/main/tabs/roadmap/scope/inline-create/types.tsx';
import batch from '../batch/index.tsx';
import { doAddIssue } from '../issue/index.tsx';
import { type NewIssue, type AddIssuePayload, ADD_ISSUE } from '../issue/types.tsx';
import type { PayloadWithExternalPromise } from '../util.tsx';

export const addIssue = (payload: AddIssuePayload) => ({
	type: ADD_ISSUE,
	payload,
});

export const addIssueWithExternalPromise = (
	payload: PayloadWithExternalPromise<AddIssuePayload>,
) => ({
	type: ADD_ISSUE,
	payload,
});

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	clearInlineCreate,
	setIssueTypeId,
	setProjectId,
	updateInlineCreate,
	setSiblingId,
	setIssueTypeIdForHierarchy,
	setGlobalCreateIssue,
	setOpenLocked,
} from '../../state/ui/main/tabs/roadmap/scope/inline-create/actions';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { StartInlineCreateActionPayload } from '../../state/ui/main/tabs/roadmap/scope/inline-create/actions';

export const INLINE_CREATE_ISSUE = 'command.inline-create.INLINE_CREATE_ISSUE' as const;

export const BULK_INLINE_CREATE_ISSUES = 'command.inline-create.BULK_INLINE_CREATE_ISSUES' as const;

export const START_INLINE_CREATE = 'command.inline-create.START_INLINE_CREATE' as const;

type InlineCreateIssuePayload = {
	title: string;
};

export type BulkInlineCreateIssuePayload = {
	parentIssue: {
		group?: string;
		groupCombination?: GroupCombination;
		hierarchyLevel: number;
		parentId: string;
		projectId: number;
	};
	suggestedIssues: {
		title: string;
		description?: string;
		issueTypeId: number;
		id: string;
	}[];
	resolve?: (ids: string[]) => void;
};

type InlineCreateIssueAction = {
	type: typeof INLINE_CREATE_ISSUE;
	payload: InlineCreateIssuePayload;
};

export type StartCreateIssueAction = {
	type: typeof START_INLINE_CREATE;
	payload: StartInlineCreateActionPayload;
};

export const inlineCreateIssue = (title: string) => ({
	type: INLINE_CREATE_ISSUE,
	payload: { title },
});

export type BulkInlineCreateIssuesAction = {
	type: typeof BULK_INLINE_CREATE_ISSUES;
	payload: BulkInlineCreateIssuePayload;
};

export const bulkInlineCreateIssues = (
	payload: BulkInlineCreateIssuePayload,
): BulkInlineCreateIssuesAction => ({
	type: BULK_INLINE_CREATE_ISSUES,
	payload,
});

export const startInlineCreate = (
	payload: StartInlineCreateActionPayload,
): StartCreateIssueAction => ({
	type: START_INLINE_CREATE,
	payload,
});

export function* getIssueValuesFromGrouping(
	group: string | null | undefined,
	groupCombination: GroupCombination = {},
	projectId: number, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<Effect, any, any> {
	if (group === UNDEFINED_GROUP) {
		return {};
	}
	const grouping: Grouping = yield select(getVisualisationGrouping);
	switch (grouping) {
		case GROUPING.ASSIGNEE:
		case GROUPING.TEAM:
		case GROUPING.SPRINT:
			return groupCombination;
		case GROUPING.COMPONENT: {
			const componentGroupsById: ComponentGroupsById = yield select(getComponentGroupsById);
			if (!isDefined(groupCombination.components)) {
				return {};
			}
			/*
               we could include components from different projects in one component group, so when presetting
               the components value to a new issue, we should filter the components in the component group by the project id to
               prevent assigning other project's componentto the new issue
            */
			const componentsByProjectId: Record<number, Component[] | undefined> =
				yield select(getComponentsByProjectId);
			const componentIdsOfProject = (componentsByProjectId[projectId] || []).map(
				(component: Component) => component.id,
			);
			const componentsValues = (
				R.path<ComponentGroupsById[string]['components']>(
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					[groupCombination.components!, 'components'],
					componentGroupsById,
				) || []
			).filter((componentId) => componentIdsOfProject.includes(componentId));

			return {
				components: componentsValues,
			};
		}
		case GROUPING.LABEL: {
			const labelGroupsById = yield select(getLabelGroupsById);
			if (!isDefined(groupCombination.labels)) {
				return {};
			}
			return { labels: R.path([groupCombination.labels, 'labels'], labelGroupsById) };
		}
		case GROUPING.RELEASE:
			return { fixVersions: [groupCombination.release] };

		case (isRoadmapGroupedByCustomField(grouping) && // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			(grouping as keyof typeof groupCombination)) ||
			'': {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			if (!isDefined(groupCombination[grouping as keyof typeof groupCombination])) {
				return {};
			}

			const groupAttribute = getCustomFieldIdFromCustomFieldGrouping(grouping);
			const groupByMultiValueCustomField = yield select(isGroupByMultiValueCustomField);
			const customFieldValuesGroupsById = yield select(getCustomFieldValuesGroupsById);

			if (groupByMultiValueCustomField) {
				const groupValues: string[] | undefined = R.path(
					[groupCombination[grouping] ?? '', grouping],
					customFieldValuesGroupsById,
				);

				return {
					customFields: {
						[groupAttribute]: groupValues?.map((value) =>
							typeof value === 'string' ? value : R.toString(value),
						),
					},
				};
			}

			return {
				customFields: {
					[groupAttribute]: groupCombination[grouping],
				},
			};
		}
		default:
			return {};
	}
}

export function* doStartInlineCreate({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: StartCreateIssueAction): Generator<Effect, any, any> {
	const { hierarchyLevel, parentId, group, groupCombination, grouping, projectId } = payload;
	const defaultProjectId = yield select(getDefaultProjectId);
	const { isGlobalCreateIssue } = yield select(getInlineCreateState);
	const valuesFromGrouping = yield call(
		getIssueValuesFromGrouping,
		group,
		groupCombination,
		projectId,
	);
	if (!isDefined(parentId)) {
		const groupKey = groupCombination ? `:${getGroupKey(groupCombination)}` : '';

		// Expand the issuesWithoutParentHeader when creating a new issue
		yield put(setIsExpanded({ [`IssuesWithoutParentHeader${groupKey}`]: true }));
		// If the issue is created from the button in the scope header make sure the relevant hierarchy level is expanded
		yield put(
			setIsExpanded({
				[`IssuesWithoutParent-${hierarchyLevel}${groupKey}`]: true,
			}),
		);

		// we want to prevent batching actions because virtual list should first update its dimensions
		// after expanding and only then to scroll
		yield call(delay, 0);
	}
	// if the parentId and siblingId are all null and it's not grouped by project, it is the global create issue,
	// so should use the defaultProject to init the create inline issue UI.

	if (isGlobalCreateIssue && grouping !== GROUPING.PROJECT) {
		yield put(
			startInlineCreateUi({
				...payload,
				projectId: defaultProjectId,
				valuesFromGrouping,
			}),
		);
	} else {
		yield put(startInlineCreateUi({ ...payload, valuesFromGrouping }));
	}
}

export function* doInlineCreateIssue({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: InlineCreateIssueAction): Generator<Effect, any, any> {
	const {
		group,
		groupCombination = {},
		hierarchyLevel,
		issueTypeId,
		projectId,
		parentId,
		values,
		siblingId,
	}: InlineCreateState = yield select(getInlineCreateState);

	const defaultIssuePriority = (yield select(getDefaultIssuePriorityByProjectId))[projectId];
	const color = hierarchyLevel === EPIC_LEVEL ? { color: toBackendColor(getRandomColor()) } : {};

	const issue: NewIssue = R.merge(values, {
		priority: values.priority || defaultIssuePriority,
		level: hierarchyLevel,
		summary: payload.title,
		type: issueTypeId,
		project: projectId,
		...color,
	});

	yield put(
		startInlineCreateUi({
			group,
			groupCombination,
			hierarchyLevel,
			issueTypeId,
			parentId,
			projectId,
			siblingId,
			valuesFromGrouping: values,
			source: 'INLINE',
		}),
	);

	if (parentId) {
		const expandedKey = group ? `${parentId}:${group}` : parentId;
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		(issue as { parent?: string }).parent = parentId;

		yield put(setIsExpanded({ [expandedKey]: true }));
	}

	yield put(addIssue({ issue, sibling: siblingId }));
}

export const transformerIssueDescriptionForSuggestIssue = (description: string) => {
	const issueDescriptionInAdfFormat = {
		version: 1,
		type: 'doc',
		content: [
			{
				type: 'paragraph',
				content: [
					{
						type: 'text',
						text: description,
					},
				],
			},
		],
	};
	return JSON.stringify(issueDescriptionInAdfFormat);
};

/**
 * This function is used to bulk create child issues from a parent issue.
 * Unlike the previous inline create issue, which only cares about the creation but ignores the result of ranking issue.
 * The creation issue and rank issue in this function should be done and then it will continue to create the next one.
 * At the time of adding this comment, it is only used in the ai-work-breakdown-popup feature.
 * @param payload
 */
export function* doBulkInlineCreateIssues({
	payload, // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: BulkInlineCreateIssuesAction): Generator<Effect, any, any> {
	try {
		const {
			resolve,
			parentIssue: { group, groupCombination, hierarchyLevel, parentId, projectId },
			suggestedIssues,
		} = payload;
		const valuesFromGrouping = yield call(
			getIssueValuesFromGrouping,
			group,
			groupCombination,
			projectId,
		);
		const childIssueHierarchyLevel = hierarchyLevel - 1;
		const color =
			childIssueHierarchyLevel === EPIC_LEVEL ? { color: toBackendColor(getRandomColor()) } : {};
		const expandedKey = group ? `${parentId}:${group}` : parentId;

		yield put(setIsExpanded({ [expandedKey]: true }));
		yield put(setIsCreatingIssues(true));

		const createdIssueIds: string[] = [];
		yield* batch(function* () {
			for (const suggestIssue of suggestedIssues) {
				const newIssue = {
					...valuesFromGrouping,
					level: childIssueHierarchyLevel,
					summary: suggestIssue.title,
					description: transformerIssueDescriptionForSuggestIssue(suggestIssue.description || ''),
					type: suggestIssue.issueTypeId,
					project: projectId,
					...color,
					parent: parentId,
				};
				const { itemKey } = yield call(doAddIssue, {
					payload: {
						issue: newIssue,
						skipRanking: false,
						batchRanking: true,
					},
					type: ADD_ISSUE,
				});
				if (itemKey?.length > 0) {
					createdIssueIds.push(suggestIssue.id);
				}
			}
		});
		resolve && resolve(createdIssueIds);
	} finally {
		yield put(setIsCreatingIssues(false));
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* watchInlineCreateIssue(): Generator<Effect, any, any> {
	yield takeEvery(INLINE_CREATE_ISSUE, doInlineCreateIssue);
	yield takeEvery(START_INLINE_CREATE, doStartInlineCreate);
	yield takeEvery(BULK_INLINE_CREATE_ISSUES, doBulkInlineCreateIssues);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/import/no-anonymous-default-export
export default function* (): Generator<Effect, any, any> {
	yield fork(watchInlineCreateIssue);
}
