import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { isEmbedSmartLinkView } from '@atlassian/jira-portfolio-3-common/src/window/index.tsx';
import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import {
	type PlanUserRole,
	PLAN_USER_ROLE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	isConfluenceReportView,
	isEmbedReportView,
	isConfluenceReportProxyView,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/window/index.tsx';
import {
	type Mode,
	OPTIMIZED,
	READONLY,
	REPORT,
	EXPORT,
	EDIT,
} from '../../state/domain/app/types.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	EDIT,
	EXPORT,
	REPORT,
	OPTIMIZING,
	OPTIMIZED,
	READONLY,
} from '../../state/domain/app/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Mode } from '../../state/domain/app/types';

export const getMode = (state: State): Mode => state.domain.app.mode;

export const getArchived = (state: State): boolean => state.domain.plan.planStatus === 'ARCHIVED';
export const getIsExportPreview = (state: State): boolean => state.domain.app.isExportPreview;
export const getHasReportedInitialLoadAnalytics = (state: State): boolean =>
	state.domain.app.hasReportedInitialLoadAnalytics;
export const getAnalyticsEvent = (state: State): UIAnalyticsEvent =>
	state.domain.app.analyticsEvent;

export const isOptimizedMode = createSelector(
	[getMode],
	(mode: Mode): boolean => mode === OPTIMIZED,
);

// These three modes are hierarchical
// If you're in export mode, you're _also_ in report mode
// If you're in report mode, you're _also_ in readonly mode
export const isReadOnlyPure = (mode: Mode) =>
	mode === READONLY || mode === REPORT || mode === EXPORT;

export const isReadOnly = createSelector([getMode], isReadOnlyPure);

export const isArchived = createSelector([getArchived], (archived: boolean) => archived);

export const isReportMode = createSelector(
	[getMode],
	(mode: Mode) => mode === REPORT || mode === EXPORT,
);
export const isExportMode = createSelector([getMode], (mode: Mode) => mode === EXPORT);
export const isExportPreview = createSelector(
	[getMode, getIsExportPreview],
	(mode: Mode, isPreview: boolean) => mode === EXPORT && isPreview,
);

export const isEditMode = createSelector([getMode], (mode: Mode) => mode === EDIT);
export const isConfluenceMacro = createSelector(
	[getMode],
	(mode: Mode) => mode === REPORT && isConfluenceReportView(),
);
export const isEmbed = createSelector(
	[getMode],
	(mode: Mode) => mode === REPORT && isEmbedReportView(),
);
export const isConfluenceMacroProxy = createSelector(
	[getMode],
	(mode: Mode) => mode === REPORT && isConfluenceReportProxyView(),
);
export const isSmartLink = createSelector(
	[getMode],
	(mode: Mode) => mode === READONLY && isEmbedSmartLinkView(),
);

export const getPlanUserRole = createSelector([getMode], (mode: Mode) => {
	switch (mode) {
		case REPORT:
		case EXPORT:
			return isConfluenceReportView()
				? PLAN_USER_ROLE.CONFLUENCE_CONSUMER
				: PLAN_USER_ROLE.REPORT_CONSUMER;
		case READONLY:
			return PLAN_USER_ROLE.VIEWER;
		default:
			return PLAN_USER_ROLE.EDITOR;
	}
});

export const isPlanUserEditor = createSelector(
	[getPlanUserRole],
	(planUserRole: PlanUserRole) => planUserRole === PLAN_USER_ROLE.EDITOR,
);

export const MODE_ANALYTICS = {
	REPORT: 'REPORT',
	EXPORT: 'EXPORT',
	READONLY: 'READ-ONLY',
	EDIT: 'EDIT',
} as const;

export const getPlanModeForAnalytics = createSelector([getMode], (mode) => {
	switch (mode) {
		case REPORT:
			return MODE_ANALYTICS.REPORT;
		case EXPORT:
			return MODE_ANALYTICS.EXPORT;
		case READONLY:
			return MODE_ANALYTICS.READONLY;
		default:
			return MODE_ANALYTICS.EDIT;
	}
});

export const getIsPlanInfoReady = (state: State): boolean => state.domain.app.isPlanInfoReady;
