import type { PlanDefaults } from '../../state/domain/plan-defaults/types.tsx';
import type { State } from '../../state/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { PlanDefaults } from '../../state/domain/plan-defaults/types';

export const getDefaultTeamVelocity = (state: State): number =>
	state.domain.planDefaults.defaultTeamVelocity || 0;

export const getDefaultIterationLength = (state: State): number | null | undefined =>
	state.domain.planDefaults.defaultIterationLength;

export const getPlanDefaults = (state: State): PlanDefaults => state.domain.planDefaults;

export const getDefaultSyncStartEnabled = (state: State): boolean =>
	!!state.domain.planDefaults.defaultSyncStartEnabled;
