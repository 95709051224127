import { type AddAction, ADD } from './actions.tsx';
import type { ExternalIssues } from './types.tsx';

const initialState: ExternalIssues = {};

type Action = AddAction;

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ExternalIssues = initialState, action: Action): ExternalIssues => {
	switch (action.type) {
		case ADD:
			return { ...state, ...action.payload };
		default: {
			const _exhaustiveCheck: never = action.type;
			return state;
		}
	}
};
