import type { Edge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/types';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';

// Should show indicator if the drag is over the right side of the current column, or over the left side of the next column.
// It should not show an indicator if it is bordering the source column.
export const shouldShowIndicator = (
	sourceColumnId: string,
	targetColumnId: string,
	columnIds: string[],
	closestEdge: Edge,
	columnId?: string,
) => {
	const sourceIndex = columnIds.indexOf(sourceColumnId);
	const targetIndex = columnIds.indexOf(targetColumnId);

	const destinationIndex = getReorderDestinationIndex({
		startIndex: sourceIndex,
		indexOfTarget: targetIndex,
		closestEdgeOfTarget: closestEdge,
		axis: 'horizontal',
	});

	// Do not show indicator if the column will end up in the same position.
	if (sourceIndex === destinationIndex) return false;

	// Show indicator if the column is being dragged over the left edge of the first column.
	if (!columnId) {
		return destinationIndex === 0 && closestEdge === 'left';
	}

	const index = columnIds.indexOf(columnId);

	// Show indicator if the column is being dragged over the right edge of the current column.
	if (index === targetIndex) return closestEdge === 'right';

	// Show indicator if the column is being dragged over the left edge of the next column.
	if (index + 1 === targetIndex) return closestEdge === 'left';

	return false;
};
