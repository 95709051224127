export const SET_TEXT_FILTER = 'state.ui.main.tabs.releases.filter.SET_TEXT_FILTER' as const;

export const SET_STATUS_FILTER = 'state.ui.main.tabs.releases.filter.SET_STATUS_FILTER' as const;

export type SetTextFilterAction = {
	type: typeof SET_TEXT_FILTER;
	textFilter: string;
};

export type SetStatusFilterAction = {
	type: typeof SET_STATUS_FILTER;
	statusFilter: string[];
};

export const setTextFilter = (textFilter: string): SetTextFilterAction => ({
	type: SET_TEXT_FILTER,
	textFilter,
});

export const setStatusFilter = (statusFilter: string[]): SetStatusFilterAction => ({
	type: SET_STATUS_FILTER,
	statusFilter,
});
