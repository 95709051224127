.spaNoDatesRow {
	height: 100%;
	padding-top: 8px;
	cursor: copy;

	&.readOnly {
		cursor: default;
	}
}

.spaBarContent {
	text-align: center;
	position: relative;
	top: 3px;
}
