import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	canContactPrompt: {
		id: 'portfolio-3-feedback-collector.ui.can-contact-prompt',
		defaultMessage: 'Atlassian may contact me about this feedback.',
		description: 'Prompt asking user if they give consent to be contacted',
	},
	canContactPromptLink: {
		id: 'portfolio-3-feedback-collector.ui.can-contact-prompt-link',
		defaultMessage: 'Privacy policy',
		description: 'Privacy policy for user to visit and read',
	},
	contactSupport: {
		id: 'portfolio-3-feedback-collector.ui.contact-support',
		defaultMessage: 'contact our support team',
		description: 'Label for contact support team link',
	},
	enrollInResearchPrompt: {
		id: 'portfolio-3-feedback-collector.ui.enroll-in-research-prompt',
		defaultMessage: "I'd like to help improve Atlassian products by joining the {link}",
		description: 'A prompt that allows the customer to enrol in product research.',
	},
	enrollInResearchLink: {
		id: 'portfolio-3-feedback-collector.ui.enroll-in-research-link',
		defaultMessage: 'Atlassian Research Group',
		description:
			'A link in the prompt that allows the customer to see information about the research group',
	},
	giveFeedback: {
		id: 'portfolio-3-feedback-collector.ui.give-feedback',
		defaultMessage: 'Give feedback',
		description: 'It is a label for button that allows users to provide feedback.',
	},
	reportBug: {
		id: 'portfolio-3-feedback-collector.ui.report-bug',
		defaultMessage:
			'If you have technical issues or find a bug in {productName}, please {contactSupportLink}.',
		description: 'Text for report Bug',
	},
	plansReportBug: {
		id: 'portfolio-3-feedback-collector.ui.plans-report-bug',
		defaultMessage:
			'If you have technical issues or find a bug in Plans, please {contactSupportLink}.',
		description: 'Text for report Plans Bug',
	},
});

export default messages;
