.radioOptions {
	padding: var(--jpo-common-base-spacing) 0;
}

.configuredDateLozenge {
	margin-left: calc(var(--jpo-common-base-spacing) * 0.5);
}

.row td {
	padding-bottom: calc(2 * var(--jpo-common-base-spacing));
}
