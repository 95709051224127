// Keys for use with UFO interaction metrics. These need to be unique across JFE
export const KEYS = {
	// timeline tab
	EXPAND_ALL_ISSUES: 'arj-timeline-expand-all-issues',
	COLLAPSE_ALL_ISSUES: 'arj-timeline-collapse-all-issues',
	EXPAND_ISSUE: 'arj-timeline-expand-issue',
	COLLAPSE_ISSUE: 'arj-timeline-collapse-issue',
	EXPAND_ISSUE_WITHOUT_PARENT: 'arj-timeline-expand-issue-without-parent',
	CREATE_ISSUE: 'arj-timeline-create-issue',
	COLLAPSE_ISSUE_WITHOUT_PARENT: 'arj-timeline-collapse-issue-without-parent',
	CHANGE_COLOR_BY_SETTING: 'arj-timeline-change-color-by-setting',
	CHANGE_GROUP_BY_SETTING: 'arj-timeline-change-group-by-setting',
	CHANGE_FILTER_VALUE: 'arj-timeline-change-filter-value',
	SORT_BY_FIELD: 'arj-timeline-sort-by-field',
	OPEN_REVIEW_CHANGES: 'arj-timeline-open-review-changes',
	SAVE_REVIEW_CHANGES: 'arj-timeline-save-review-changes',
	SWITCH_VIEW: 'arj-timeline-switch-view',
	// teams tab
	CREATE_TEAM: 'arj-teams-create-team',
	UPDATE_TEAM: 'arj-teams-update-team',
	// releases tab
	CREATE_RELEASE: 'arj-releases-create-release',
	UPDATE_RELEASE: 'arj-releases-update-release',
} as const;
