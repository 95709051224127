import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	findLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-select.find-label',
		defaultMessage: 'Choose option',
		description: 'Placeholder for a label select when colour is picked',
	},
	selectLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-select.select-label',
		defaultMessage: 'Choose color',
		description: 'Placeholder for a label select when colour is not picked',
	},
	addLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-select.add-label',
		defaultMessage: 'Add color',
		description: 'Label for a button to add a new colour',
	},
});
