import { createSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Warnings, WarningIssueHash } from '../../state/domain/warnings/types.tsx';
import type { State } from '../../state/types.tsx';
import { getWarningViewSettings } from '../view-settings/index.tsx';

export const getWarnings = (state: State): Warnings => state.domain.warnings;
export const getAllWarningsIssueHash = (state: State): WarningIssueHash =>
	state.domain.warnings.allWarningsByIssue ?? {};

export const getAggregatedWarningsPure = (warnings: Warnings): Record<string, string[]> => {
	const byType: Record<string, string[]> = {};
	for (const [issueId, value] of Object.entries(warnings.byIssue)) {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const warnings = value;
		for (const warning of warnings) {
			const type = warning.type;
			if (!byType[type]) {
				byType[type] = [];
			}
			byType[type].push(issueId);
		}
	}
	return byType;
};

export const getAggregatedWarnings = createSelector([getWarnings], getAggregatedWarningsPure);

export const getDisabledWarnings = createSelector(
	[getWarningViewSettings],
	(warningViewSettings) => warningViewSettings.disabledWarnings,
);
