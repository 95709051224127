:root {
	--dialog-menu-container-max-width: 450px;
}

.checkboxLabel {
	display: inline-block;
	/*
        450px - max-width of filter container
        calc(1.5 * var(--jpo-icon-size-small)) - it's the icon size
        calc(6 * var(--jpo-common-base-spacing)) - paddings and one margin
    */
	padding-right: calc(2 * var(--jpo-common-base-spacing));
	max-width: calc(
		var(--dialog-menu-container-max-width) - calc(6 * var(--jpo-common-base-spacing)) -
			calc(1.5 * var(--jpo-icon-size-small))
	);
	text-overflow: ellipsis;
	overflow: hidden;
}

.noMatchFoundText {
	margin: var(--jpo-common-base-spacing) 0;
	padding: var(--jpo-common-base-spacing) calc(2 * var(--jpo-common-base-spacing));
	color: var(--ds-text-disabled, var(--adg3-color-N200));
	text-align: center;
}

.teamAvatarItem {
	align-items: center;
	display: flex;
	gap: var(--jpo-common-base-spacing);
}

.defaultTeamAvatar {
	background-color: var(--ds-background-neutral, var(--adg3-color-N40));
	border-radius: 50%;
	margin: 0px 2px;
	padding: 4px 4px;

	& > span {
		display: flex;
	}
}
