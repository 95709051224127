import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import {
	isReadOnly,
	isSmartLink,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/app/index.tsx';
import {
	getViews,
	getActiveView,
	getErrors,
	getSaveViewAsDialogStatus,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/views/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { Props, StateProps } from './types.tsx';

const selector = createStructuredSelector<State, Props, StateProps>({
	views: getViews,
	activeView: getActiveView,
	errors: getErrors,
	saveViewAsDialogStatus: getSaveViewAsDialogStatus,
	readOnly: isReadOnly,
	isSmartLink,
});

const selectorOld = createStructuredSelector<State, Props, StateProps>({
	views: getViews,
	activeView: getActiveView,
	errors: getErrors,
	saveViewAsDialogStatus: getSaveViewAsDialogStatus,
	readOnly: isReadOnly,
});

export default functionWithCondition(() => fg('smart_links_for_plans'), selector, selectorOld);
