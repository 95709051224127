import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	addColour: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-priority.add-colour',
		defaultMessage: 'Add color',
		description: 'Label for a button to add a new colour',
	},
	chooseColour: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-priority.choose-colour',
		defaultMessage: 'Choose color',
		description: 'Placeholder for a priority select when colour is not picked',
	},
	choosePriority: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-priority.choose-priority',
		defaultMessage: 'Choose priority',
		description: 'Placeholder for a priority select when colour is picked',
	},

	chooseLabelColorAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-priority.choose-label-color-aria-label',
		defaultMessage: 'Choose a label color',
		description:
			'This is an aria-label text, displayed while hovering on the color picker or screen reading when no colors are selected',
	},
	editLabelColorAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-priority.edit-label-color-aria-label',
		defaultMessage: 'Edit label color',
		description:
			'This is an aria-label text, displayed while hovering on the color picker or screen reading when a color is already selected',
	},
});
