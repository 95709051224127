import React, { type MouseEvent, type KeyboardEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { IconButton } from '@atlaskit/button/new';
import ShareIcon from '@atlaskit/icon/glyph/share';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import ModalDialog from '@atlaskit/modal-dialog';
import {
	SideNavigation,
	NavigationHeader,
	NavigationContent,
	Section,
	Header,
	ButtonItem,
} from '@atlaskit/side-navigation';
import Tooltip from '@atlaskit/tooltip';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import Button from '@atlassian/jira-portfolio-3-common/src/button/index.tsx';
import { PRODUCT_ANALYTICS_EVENT_NAMES } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/analytics/types.tsx';
import {
	READONLY,
	REPORT,
} from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/domain/app/types.tsx';
import { ReportStatus } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/ui/main/tabs/dependencies/report-state/types.tsx';
import {
	TIMELINE_SHARE_TYPE,
	SHARE_AS,
	type ShareAs,
	DEPENDENCY_REPORT_SHARE_TYPE,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	ContextualAnalyticsData,
	fireUIAnalytics,
	MODAL,
	AnalyticsEventToProps,
} from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import Content from './components/content.tsx';
import PNGExportContent from './components/png-export/index.tsx';
import messages from './messages.tsx';
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
import * as styles from './styles.module.css';
import type { Props } from './types.tsx';

const ButtonItemWithAnalytics = AnalyticsEventToProps('tab', {
	onClick: 'clicked',
})(ButtonItem);

function ShareReport({
	analyticsEvent,
	closeShareReportDialog,
	confluenceMacroType,
	dependencyReportStatus,
	getExportCsvScopeItems,
	isIssuesLoading,
	isLoading,
	isOpen = false,
	isReportMode,
	mode,
	noIssuesInPlan,
	openShareReportDialog,
	planExportedAnalytics,
	planModeForAnalytics,
	planTitle,
	readOnly,
	shareAs,
	updateShareReportUrl,
	url,
	definedGoalsByARI,
	associatedIssues,
}: Props) {
	const intl = useIntl();

	// eslint-disable-next-line @typescript-eslint/no-shadow
	const open = (_: MouseEvent | KeyboardEvent, analyticsEvent: UIAnalyticsEvent) => {
		// Reset Redux to Link or DependencyConfluence share type depending on which tab has been selected
		resetShareAsReduxState();

		openShareReportDialog();

		if (readOnly && shareAs !== SHARE_AS.PNG && shareAs !== SHARE_AS.CSV) {
			updateShareReportUrl({
				shareAs: SHARE_AS.CSV,
			});
		}

		const [actionSubject, action] = PRODUCT_ANALYTICS_EVENT_NAMES.SHARE_REPORT.split(' ');
		fireUIAnalytics(
			analyticsEvent.update({ action, actionSubject }),
			PRODUCT_ANALYTICS_EVENT_NAMES.SHARE_REPORT,
		);
	};

	const close = () => closeShareReportDialog();

	const resetShareAsReduxState = () => {
		if (
			confluenceMacroType === TIMELINE_SHARE_TYPE &&
			shareAs === SHARE_AS.DEPENDENCY_REPORT_CONFLUENCE
		) {
			updateShareReportUrl({
				shareAs: SHARE_AS.LINK,
			});
		} else if (
			confluenceMacroType === DEPENDENCY_REPORT_SHARE_TYPE &&
			shareAs !== SHARE_AS.DEPENDENCY_REPORT_CONFLUENCE
		) {
			updateShareReportUrl({
				shareAs: SHARE_AS.DEPENDENCY_REPORT_CONFLUENCE,
			});
		}
	};

	const getShareDialogNavigationItems = () => {
		const navigationItems: {
			action: ShareAs;
			label: string;
		}[] = [
			{
				action: SHARE_AS.LINK,
				label: intl.formatMessage(messages.shareAsLink),
			},
			{
				action: SHARE_AS.EMBED,
				label: intl.formatMessage(messages.shareAsEmbed),
			},
			{
				action: SHARE_AS.CONFLUENCE,
				label: intl.formatMessage(messages.shareAsConfluence),
			},
			{
				action: SHARE_AS.CSV,
				label: intl.formatMessage(messages.shareAsSpreadsheetCsv),
			},
			{
				action: SHARE_AS.PNG,
				label: intl.formatMessage(messages.shareAsPng),
			},
		];

		return navigationItems.map(({ action, label }) => {
			const isSelected = shareAs === action;
			const isDisabled =
				// only CSV available in report mode
				(isReportMode && action !== SHARE_AS.CSV) ||
				// only CSV and PNG available in read only mode
				(readOnly &&
					![
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						SHARE_AS.CSV as string,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						SHARE_AS.PNG as string,
					].includes(action));

			return (
				<ButtonItemWithAnalytics
					isDisabled={isDisabled}
					isSelected={isSelected}
					key={action}
					// eslint-disable-next-line @typescript-eslint/no-shadow
					onClick={(_: KeyboardEvent | MouseEvent, analyticsEvent: UIAnalyticsEvent) => {
						fireUIAnalytics(analyticsEvent, action, { planMode: planModeForAnalytics });
						updateShareReportUrl({
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							shareAs: action as ShareAs,
						});
					}}
					testId={`portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.share-as-${action.toLowerCase()}`}
				>
					{label}
				</ButtonItemWithAnalytics>
			);
		});
	};

	const isDependencyReportReadonlyOrReportMode = () =>
		confluenceMacroType === DEPENDENCY_REPORT_SHARE_TYPE && (mode === READONLY || mode === REPORT);

	if (isDependencyReportReadonlyOrReportMode()) {
		return null;
	}

	const shareAsButtonTextFormatted = intl.formatMessage(messages.shareButtonText);

	const isDisabled =
		isIssuesLoading ||
		(confluenceMacroType === DEPENDENCY_REPORT_SHARE_TYPE &&
			dependencyReportStatus !== ReportStatus.SUCCESS);

	return (
		<ContextualAnalyticsData sourceName="shareReport" sourceType={MODAL}>
			<div data-testid="portfolio-3-portfolio.app-simple-plans.top.title-bar.share-report.share-report-button">
				{/* in REPORT and READONLY mode, the Share button is always displayed with labels since there is enough space */}
				{!getWillShowNav4() && mode !== REPORT && mode !== READONLY && (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={styles.shareButtonIconOnly}>
						<Tooltip content={shareAsButtonTextFormatted}>
							<Button
								ariaLabel={shareAsButtonTextFormatted}
								iconBefore={<ShareIcon label="" />}
								onClick={open}
								appearance="subtle"
								isDisabled={isDisabled}
							/>
						</Tooltip>
					</div>
				)}
				{getWillShowNav4() ? (
					<IconButton
						icon={ShareIcon}
						onClick={open}
						appearance={
							isVisualRefreshEnabled() && fg('jira_nav4_beta_drop_1') ? 'default' : 'subtle'
						}
						isDisabled={isDisabled}
						label={shareAsButtonTextFormatted}
						isTooltipDisabled={false}
					/>
				) : (
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					<div className={mode !== REPORT && mode !== READONLY ? styles.shareButtonLabelOnly : ''}>
						<Button
							iconBefore={<ShareIcon label="" />}
							onClick={open}
							appearance="subtle"
							isDisabled={isDisabled}
						>
							{shareAsButtonTextFormatted}
						</Button>
					</div>
				)}
			</div>
			{isOpen && (
				<ShortcutScope>
					<ModalDialog
						autoFocus={false}
						onClose={close}
						width="x-large"
						label={intl.formatMessage(messages.sharePlanLabel)}
					>
						{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
						<div className={styles.modalBody}>
							{confluenceMacroType === TIMELINE_SHARE_TYPE ? (
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								<div className={styles.sideNavigation}>
									<SideNavigation label={intl.formatMessage(messages.shareHeader)}>
										<NavigationHeader>
											<Header>{intl.formatMessage(messages.shareHeader)}</Header>
										</NavigationHeader>
										<NavigationContent>
											<Section>{getShareDialogNavigationItems()}</Section>
										</NavigationContent>
									</SideNavigation>
								</div>
							) : null}
							<Content
								intl={intl}
								isLoading={isLoading}
								onClose={close}
								shareAs={shareAs}
								updateShareReportUrl={updateShareReportUrl}
								url={url}
								getExportCsvScopeItems={getExportCsvScopeItems}
								noIssuesInPlan={noIssuesInPlan}
								PNGExportContentComponent={PNGExportContent}
								planMode={planModeForAnalytics}
								isReportMode={isReportMode}
								analyticsEvent={analyticsEvent}
								planExportedAnalytics={planExportedAnalytics}
								confluenceMacroType={confluenceMacroType}
								planTitle={planTitle}
								definedGoalsByARI={definedGoalsByARI}
								associatedIssues={associatedIssues}
							/>
						</div>
					</ModalDialog>
				</ShortcutScope>
			)}
		</ContextualAnalyticsData>
	);
}

export default ShareReport;
