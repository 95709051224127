import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	advancedFieldsMenuCollapseFields: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.fields.header.collapse-button.advanced-fields-menu-collapse-fields',
		defaultMessage: 'Collapse fields',
		description: 'Label for icon button that triggers the collapse of all view fields',
	},
});

export default messages;
