import type {
	SolutionVersion,
	ReleaseStatus,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/api/types.tsx';
import type {
	Direction,
	Entity,
} from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import type { Warning } from '@atlassian/jira-portfolio-3-portfolio/src/common/warning-details/types.tsx';
import type { Project } from '../../state/domain/projects/types.tsx';
import type { ChangeMetadata } from '../../state/domain/update-jira/changes/types.tsx';
import type { Version } from '../../state/domain/versions/types.tsx';
import type { VersionDetails } from '../update-jira/types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Timestamp } from '../../../common/types';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { ReleaseStatus, ReleaseStatusValue } from '../../../common/api/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { VersionDetails } from '../update-jira/types';

export type EnrichedVersion = Readonly<
	Omit<Version, 'projects'> & {
		projects: Array<Project | number>;
	}
>;

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Version, NewVersion, VersionPatch } from '../../state/domain/versions/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { Relation } from '../../../common/api/types';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { OriginalVersion, OriginalVersions } from '../../state/domain/original-versions/types';

export type VersionsById = {
	[key: string]: Version;
};
export type ReleaseStatusesById = {
	[key: string]: ReleaseStatus;
};
export type SolutionVersionsById = {
	[key: string]: SolutionVersion;
};
export type EnrichedVersionsById = {
	[key: string]: EnrichedVersion;
};

export type VersionChange = {
	id: string;
	category: Entity;
	metaData: ChangeMetadata;
	changeCount: number;
	warnings: Warning[];
	attributeName: string | null | undefined;
	details: VersionDetails;
};

export const DATE_METHODS = {
	LEAVE_AS_IS: 'leaveAsIs',
	AS_EARLY_AS_POSSIBLE: 'asEarlyAsPossible',
	RELATIVE_TO_PREVIOUS_RELEASE: 'relativeToPreviousRelease',
	FIXED_DATE: 'fixedDate',
	AFTER_ALL_ISSUES_ARE_COMPLETED: 'afterAllIssuesAreCompleted',
};

export type DateReference = {
	quantity: number;
	unit: string;
	direction: Direction;
};

export type DateMethodDescriptor = {
	method: string;
	quantity?: number;
	unit?: string;
	direction?: Direction;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type {
	Mode as MarkerMode,
	ReleaseBar,
} from '../../state/ui/main/tabs/roadmap/timeline/release-bar/types';
