import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	findComponent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-component.find-component',
		defaultMessage: 'Choose component',
		description: 'Placeholder for a component select when colour is picked',
	},
	selectComponentLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-component.select-component-label',
		defaultMessage: 'Select component',
		description:
			'The text is shown as label for the component select when the colour is picked and which is displayed inside the view settings modal',
	},
	chooseColour: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-component.choose-colour',
		defaultMessage: 'Choose color',
		description: 'Placeholder for a component select when colour is not picked',
	},
	selectColour: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-component.select-colour',
		defaultMessage: 'Select color',
		description:
			'The text is shown as label for the component select when the colour is not picked and which is displayed inside the view settings modal',
	},
	addComponent: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-component.add-component',
		defaultMessage: 'Add color',
		description: 'Label for a button to add a new colour',
	},
	chooseLabelColorAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-component.choose-label-color-aria-label',
		defaultMessage: 'Choose a label color',
		description:
			'This is an aria-label text, displayed while hovering on the color picker or screen reading when no colors are selected',
	},
	editLabelColorAriaLabel: {
		id: 'portfolio-3-portfolio.app-simple-plans.main.tabs.roadmap.view-settings.colour-by-component.edit-label-color-aria-label',
		defaultMessage: 'Edit label color',
		description:
			'This is an aria-label text, displayed while hovering on the color picker or screen reading when a color is already selected',
	},
});
