import * as R from 'ramda';
import { getCustomFieldFilter } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/query/filters/custom-field-filter/index.tsx';
import type { State } from '@atlassian/jira-portfolio-3-portfolio/src/app-simple-plans/state/types.tsx';
import { createStructuredSelector } from '@atlassian/jira-portfolio-3-portfolio/src/common/reselect/index.tsx';
import type { OwnProps, StateProps } from './types.tsx';

export const getCustomLabelsByFields = (state: State) => state.domain.customLabelsByField;

const getLabelsForField = (state: State, { customField }: OwnProps) =>
	getCustomLabelsByFields(state)[customField.id];

const getValue = (state: State, { customField }: OwnProps): string[] => {
	const customFieldFilter = getCustomFieldFilter(state);
	return R.path(['value', customField.id], customFieldFilter) ?? [];
};

export default createStructuredSelector<State, OwnProps, StateProps>({
	options: getLabelsForField,
	value: getValue,
});
